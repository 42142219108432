import {AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {SiteService} from '../../services/site.service';
import {Site, SiteWrapperRequest} from '../../../models/site.model';
import {Folder} from '../../../models/folder.model';
import {ConfigCodeMapsEnum} from '../../services/configuration-item-enum';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {allConfiguration} from '../../../store/configuration/configuration.selectors';
import {take} from 'rxjs/operators';
import {Unsubscriber} from '../../../unsubscriber';
import {AddressType} from '../../../models/enums/addressType.enum';
import {countryCode} from '../../../store/organization/organization.selectors';
import {SnackBarService} from '../../services/snack-bar.service';

@Component({
    selector: 'app-edit-management-site-modal',
    templateUrl: './edit-management-site-modal.component.html',
    styleUrls: ['./edit-management-site-modal.component.scss']
})
export class EditManagementSiteModalComponent extends Unsubscriber implements OnInit, AfterViewInit {

    selection = new SelectionModel<Site>(false, []);
    dataSource = new MatTableDataSource<any>();
    displayedColumns: string[] = ['code', 'label', 'action'];
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

    sites: Site[];
    siteSelected: Site;

    zipCode: string;
    folder: Folder;
    managementSiteForm: any = {
        form: null
    };
    @Input() siteSearchWithoutModal: boolean;
    @Input() folderDetails: Folder;
    @Input() fromSiteSearchzipCode;
    @Output() siteSelectedEmitter = new EventEmitter<Site>();
    countryCode: string;
    constructor(public dialogRef: MatDialogRef<EditManagementSiteModalComponent>,
                private siteService: SiteService,
                private snackBarService: SnackBarService,
                private store$: Store<AppState>,
                @Inject(MAT_DIALOG_DATA) public data) {
        super();
    }

    ngOnInit(): void {
        this.initFormManagementSite();
        if (this.siteSearchWithoutModal) {
            this.folder = this.folderDetails;
            this.managementSiteForm.form.get('searchSite').setValue(this.fromSiteSearchzipCode);
            this.searchSite();
        } else {
            this.folder = this.data.folder;
        }
        this.anotherSubscription = this.store$.pipe(select(countryCode))
            .subscribe(code => {
                this.countryCode = code;
            });
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
    }

    private initFormManagementSite(): void {
        this.managementSiteForm.form = new FormGroup({
            searchSite: new FormControl(null, Validators.required),
        });
    }

    initDataTable(): void {
        this.sites = [];
        this.dataSource = new MatTableDataSource<any>([]);
        this.dataSource.paginator = this.paginator;
        this.selection = new SelectionModel<Site>(false, []);
        this.siteSelected = null;
    }

    searchSite(): void {
        this.initDataTable();
        const inputForm = this.managementSiteForm.form.get('searchSite').value;
        if (!!inputForm && inputForm.toUpperCase().startsWith('F')) {
            this.findSiteByCode(inputForm);
        } else {
            this.searchSites(inputForm);
        }
    }

    searchSites(inputForm): void {
        const siteWrapperRequest: SiteWrapperRequest = {
            organizationCode: this.folder.organization.code,
            folderId: this.folder.id
        };
        this.anotherSubscription = this.store$.pipe(
            select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.SITE}),
            take(1))
            .subscribe(it => {
                const isSiteCodeSelected = it.filter(site => site.code === inputForm).length > 0;
                if (isSiteCodeSelected) {
                    siteWrapperRequest.code = inputForm;
                } else {
                    siteWrapperRequest.address = {
                        zipCode: inputForm,
                        countryCode: this.getCountryCode()
                    };
                }
                this.findNearestSites(siteWrapperRequest);
            });
        this.store$.dispatch(new StartLoading());
    }

    findNearestSites(siteWrapperRequest: SiteWrapperRequest): void {
        this.siteService.findNearestSites(siteWrapperRequest).subscribe(sitesList => {
            this.store$.dispatch(new StopLoading());
            if (sitesList.length > 0) {
                this.sites = sitesList;
                this.dataSource = new MatTableDataSource<any>(this.sites);
                this.dataSource.paginator = this.paginator;
            } else {
                this.snackBarService.openAtEnd('Info', 'SITE.NOT_FOUND');
            }
        }, error => {
            this.store$.dispatch(new StopLoading());
        });
    }

    findSiteByCode(inputForm): void {
        this.store$.dispatch(new StartLoading());
        this.siteService.findNearestSiteByCode(inputForm.toUpperCase()).subscribe(site => {
            this.sites = [];
            this.sites.push(site);
            this.dataSource = new MatTableDataSource<any>(this.sites);
            this.dataSource.paginator = this.paginator;
            this.store$.dispatch(new StopLoading());
            this.selection.select(site);
            this.siteSelected = site;
            if (this.siteSearchWithoutModal) {
                this.siteSelectedEmitter.emit(this.siteSelected);
            }
        }, error => {
            this.store$.dispatch(new StopLoading());
        });
    }

    onSubmit(): void {
        if (this.siteSelected) {
            this.dialogRef.close(this.siteSelected);
        }
    }

    onCloseModal(): void {
        this.dialogRef.close();
    }

    showSelection(site: Site): void {
        this.siteSelected = site;
        if (this.siteSearchWithoutModal) {
            this.siteSelectedEmitter.emit(this.siteSelected);
        }
    }

    private getCountryCode(): string {
        return this.getAddressByType(AddressType.INTERVENTION).length > 0 ?
            this.getAddressByType(AddressType.INTERVENTION)[0].countryCode :
            this.getAddressByType(AddressType.DEFAULT).length > 0 ?
                this.getAddressByType(AddressType.DEFAULT)[0].countryCode :
                    this.countryCode;
    }

    private getAddressByType(type: AddressType) {
        return this.folder.customer.addresses.filter(it => it.type === type);
    }
}
