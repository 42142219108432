<mat-form-field class="filter w-100-p" floatLabel="never" appearance="outline">
    <mat-label>{{ 'PRODUCT.PREVIEW_CARD.LABELS.PRODUCT_BRAND'|translate }}</mat-label>
    <input type="text" matInput [id]="'brands'"
           [placeholder]="'PRODUCT.PREVIEW_CARD.LABELS.PRODUCT_BRAND'|translate"
           [formControl]="brandCtrl"
           [matAutocomplete]="auto"
           (change)="brandSelected($event)">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayBrand"  (optionSelected)="onSelectBrand($event)" >
        <mat-option *ngFor="let brand of filteredBrands | async"
                    [id]="brand.code"
                    [value]="brand.code">
            {{brand.code}} - {{brand.label}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>