    <div fxLayout="column" fxLayoutGap="30px" ngClass.gt-md="w-100-p" ngClass.lt-md="w-100-p" ngClass.lt-sm="w-100-p">
        <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
                <div class="text-center pt-44 pb-44 w-100-p">
                    <h2 class=" text-bold">{{'FOLDER.WORKFLOW.FOLDER_CLOSED' | translate}}</h2>
                    <ng-container *ngIf="IsFolderClosedByDiagnostic(); else FOLDER_CLOSED_STATUS">
                        <h2 class=" text-bold" [innerHTML]="getReasonClosedFolderByDiagnostic()"></h2>
                    </ng-container>
                    <ng-template #FOLDER_CLOSED_STATUS>
                        <h2 class=" text-bold">{{'COMPONENT.'+ folder.currentStatus.status | translate}}</h2>
                    </ng-template>

                </div>
            </div>
        </mat-card-content>
    </div>