import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {LandLinePhoneInput, MobilePhoneInput} from '../../../../../../models/customer/customer.model';
import {EMAIL_FIELD} from '../../../../../../models/customer/customer.constants';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {landlinePhoneInput, mobilePhoneInput} from '../../../../../../store/customer/customer.selectors';
import {Observable} from 'rxjs';
import {CustomerApiInfo} from '../../../../../../models/customer/customer-api-info.model';

@Component({
    selector: 'customer-contact',
    templateUrl: './customer-contact.component.html'
})
export class CustomerContactComponent implements OnInit {

    @Input() isRequiredField: boolean;
    @Input() customerApiInfo: CustomerApiInfo;
    @Output() search = new EventEmitter();

    contactForm: FormGroup;
    mobilePhoneInput$: Observable<MobilePhoneInput>;
    landlinePhoneInput$: Observable<LandLinePhoneInput>;
    emailField = EMAIL_FIELD;

    constructor(private rootFormGroup: FormGroupDirective,
                private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.contactForm = this.rootFormGroup.control.get('contact') as FormGroup;
        this.mobilePhoneInput$ = this.store$.pipe(select(mobilePhoneInput));
        this.landlinePhoneInput$ = this.store$.pipe(select(landlinePhoneInput));
    }

    searchCustomer() {
        this.search.emit();
    }
}
