<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="text-bold">
        {{'NAV.PUSH.NOTIFICATION.TITLE' | translate}}</h2>
    <button mat-icon-button class="ml-40" (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div class="page-layout simple fullwidth  mat-elevation-z4">
        <table mat-table [dataSource]="dataSourcePushNotification" class="w-100-p">
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header"> {{'FOLDER.PUSH.NOTIFICATION.TYPE' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container [ngSwitch]="element.type">
                        <div *ngSwitchCase="'ERROR'">
                            <mat-icon class="red-icon">error</mat-icon>
                        </div>
                        <div *ngSwitchCase="'INFO'">
                            <mat-icon class="green-icon">info</mat-icon>
                        </div>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{'FOLDER.PUSH.NOTIFICATION.CONTENT' | translate}}</th>
                <td mat-cell *matCellDef="let pushNotification">
                    <ng-container [ngSwitch]="pushNotification.type">
                        <div class="flex align-items-center">
                            <div class="message-content">
                                {{ getNotificationMessage(pushNotification) | async }}
                            </div>
                        </div>
                        <div *ngIf="pushNotification.withDetail">
                            {{ getNotificationMessageDetails(pushNotification) | async }}
                        </div>
                    </ng-container>
                </td>

            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header"> {{'FOLDER.PUSH.NOTIFICATION.DATE' | translate}}</th>
                <td mat-cell *matCellDef="let pushNotification">
                    {{pushNotification.creationDate | moment: ('GENERAL.DATE.PUSH.NOTIFICATION.CREATION_DATE' | translate) : (currentLanguage$ | async) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header"> {{'FOLDER.PUSH.NOTIFICATION.ACTION' | translate}}</th>
                <td mat-cell *matCellDef="let pushNotification">
                    <div class="button-container">
                        <ng-container
                            *ngIf="pushNotification.status==='RESOLVED' || pushNotification.status ==='IGNORED' ; else StatusPushNotification">

                            {{('FOLDER.PUSH.NOTIFICATION.' + pushNotification.status) |translate}}
                        </ng-container>
                        <ng-template #StatusPushNotification>
                            <button mat-mini-fab color="primary" (click)="updateStatus('RESOLVED', pushNotification)"
                                    type="submit"
                                    class="mr-12 mt-4 ">
                                <mat-icon>done</mat-icon>
                            </button>
                            <button mat-mini-fab color="warn" (click)="updateStatus('IGNORED',pushNotification)"
                                    type="submit"
                                    class="mt-4 ">
                                <mat-icon>close</mat-icon>
                            </button>
                        </ng-template>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator *ngIf="!!dataSource && !! dataSource.page"
                       [length]="dataSource.page.totalElements"
                       (page)="paginate($event)" showFirstLastButtons
                       [pageSize]="dataSource.page.size"
                       [pageSizeOptions]="dataSource.pageSizeOptions"
                       class="w-100-p"></mat-paginator>
    </div>

</mat-dialog-content>


