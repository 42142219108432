import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {LoadCustomerApiInfo, ResetCustomer, ResetCustomerState, SetCreationWithoutCustomer, SetStockClaimCreation} from '../../../../store/customer/customer.actions';
import {customerSearchResult, isCreationWithoutCustomer, isStockClaimCreation} from '../../../../store/customer/customer.selectors';
import {takeUntil} from 'rxjs/operators';
import {PagedResult} from '../../../../models/paged-result.model';
import {Customer} from '../../../../models/customer/customer.model';
import {enableClaimWithoutCustomer, enableStockClaim} from '../../../../store/organization/organization.selectors';

@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html'
})
export class CustomerComponent implements OnInit, OnDestroy {

    showCustomerForm = true;
    customers: PagedResult<Customer>;

    _onDestroy = new Subject<void>();
    isStockClaimCreation$: Observable<boolean>;
    isClaimWithoutCustomer$: Observable<boolean>;
    enableStockClaim$: Observable<boolean>;
    enableClaimWithoutCustomer$: Observable<boolean>;

    constructor(private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.isStockClaimCreation$ = this.store$.pipe(select(isStockClaimCreation), takeUntil(this._onDestroy));
        this.isClaimWithoutCustomer$ = this.store$.pipe(select(isCreationWithoutCustomer), takeUntil(this._onDestroy));
        this.enableStockClaim$ = this.store$.pipe(select(enableStockClaim), takeUntil(this._onDestroy));
        this.enableClaimWithoutCustomer$ = this.store$.pipe(select(enableClaimWithoutCustomer), takeUntil(this._onDestroy));
        this.store$.dispatch(new LoadCustomerApiInfo());
        this.store$.pipe(select(customerSearchResult), takeUntil(this._onDestroy))
            .subscribe(customers => {
                this.customers = customers;
                this.showCustomerForm = this.customers?.items?.length <= 1;
            });
    }

    setCreationWithoutCustomer(event: MatCheckboxChange): void {
        this.store$.dispatch(new SetCreationWithoutCustomer(event.checked));
    }
    seStockClaimCreation(event: MatCheckboxChange): void {
        this.store$.dispatch(new SetStockClaimCreation(event.checked));
    }
    backToCustomerList(): void {
        this.store$.dispatch(new ResetCustomer(true));
        this.showCustomerForm = false;
    }

    ngOnDestroy(): void {
        this.store$.dispatch(new ResetCustomerState());
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}
