    <mat-card-content>
        <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <mat-radio-group formControlName="choice" class="ml-20" fxLayout="row" fxLayoutAlign="space-between center"
                             labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button [id]="status.choice" class="full-width-radio"
                                  *ngFor="let status of placeholders"
                                  [value]="status.choice">
                    <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div>{{ status.value | translate}}</div>

                            <div *ngIf="status.isLoading">
                                <mat-spinner diameter="30"></mat-spinner>
                            </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>

            <!-- BEGIN CUSTOMER VOUCHER OPTIONAL SECTION-->
            <ng-container *ngIf="form.get('customerVoucherReason')">

                <div fxLayout="row" fxLayoutGap="20px">
                    <div fxLayoutAlign="center center">
                        {{ 'COMPONENT.VALIDATE_SWAP_IN_STORE.CUSTOMER_VOUCHER_REASON' | translate }}
                    </div>
                    <mat-form-field appearance="outline">
                        <mat-select [formControl]="customerVoucherReasonCtrl">
                            <mat-option>
                                <ngx-mat-select-search
                                    [formControl]="customerVoucherReasonFilterCtrl"
                                    [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                    [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"
                                ></ngx-mat-select-search>
                            </mat-option>

                            <mat-option *ngFor="let voucherReason of filteredCustomerVoucherReasons | async"
                                        [value]="voucherReason.code">
                                {{ voucherReason.code }} - {{ voucherReason.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- END CUSTOMER VOUCHER OPTIONAL SECTION-->
            </ng-container>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        [ngClass]="invalidForm() ? 'aster_btn_disabled':'aster_btn'"
                        [disabled]="invalidForm()">
                    {{ 'BUTTON.VALIDATE_EXCHANGE' | translate }}
                </button>
            </div>
        </form>
    </mat-card-content>