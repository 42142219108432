import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PhoneNumberPipe} from '../../../../@fuse/pipes/phone-number.pipe';
import {SharedService} from '../../services/shared.service';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import libphonenumber from 'google-libphonenumber';
import {CountriesUtils} from '../../data/countries-utils';
import {FolderService} from '../../services/folder.service';
import {countryCode} from '../../../store/organization/organization.selectors';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {Unsubscriber} from '../../../unsubscriber';
import {MobilePhoneInput} from '../../../models/customer/customer.model';
import {CustomerApiInfo} from '../../../models/customer/customer-api-info.model';
import PhoneNumberFormat = libphonenumber.PhoneNumberFormat;

@Component({
    selector: 'mobile-phone-input-form',
    templateUrl: './mobile-phone-form.component.html',
    styleUrls: ['./mobile-phone-form.component.scss']
})
export class MobilePhoneFormComponent extends Unsubscriber implements OnInit {

    @Input() mobilePhoneInput: MobilePhoneInput;
    @Input() searchable = false;
    @Input() isRequiredFields = true;
    @Input() customerApiInfo = new CustomerApiInfo();
    @Output() search = new EventEmitter();

    phoneConfigCountry = '';
    contactForm: FormGroup;
    countryCode: string;

    @Input() parentFormGroup: FormGroup;

    constructor(private phoneNumberPipe: PhoneNumberPipe,
                private sharedService: SharedService,
                private folderService: FolderService,
                private rootFormGroup: FormGroupDirective,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        // le parent group est soit à injecter automatiquement grace à FormGroupDirective soit
        // il faut le déclarer manuellement dans parentFormGroup
        this.contactForm = this.rootFormGroup.control ? this.rootFormGroup.control : this.parentFormGroup;
        this.init();
        this.anotherSubscription = this.store$.pipe(select(countryCode)).subscribe(code => this.countryCode = code);
        this.anotherSubscription = this.sharedService.getUpdateMobilePhoneEvent().subscribe(data => {
            if (!data) {
                return;
            }
            if (data && !data.mobileNumber) {
                this.initTelDetailForm();
            } else {
                this.mobilePhoneInput = data;
                this.initInputTelDetailWithData();
            }
        });
    }

    private init(): void {
        this.initTelDetailForm();
        this.initInputTelDetailWithData();
        this.updatePhoneInput();
    }

    initInputTelDetailWithData(): void {
        if (!!this.mobilePhoneInput && this.mobilePhoneInput.mobileNumber && this.mobilePhoneInput.countryCode) {
            this.contactForm.get('mobileNumber')
                .setValue(this.phoneNumberPipe.getFormattedMobilePhone(this.mobilePhoneInput.mobileNumber,
                    this.mobilePhoneInput.countryCode
                    , PhoneNumberFormat.INTERNATIONAL).mobileNumber);
        } else if (!this.mobilePhoneInput) {
            this.mobilePhoneInput = {
                mobileNumber: '',
                countryCode: ''
            };
        }
    }

    initTelDetailForm(): void {
        this.folderService.findInvalidControls(this.contactForm);
    }

    notifyInputTelFormChanged(): void {
        const isNumberValid = this.contactForm.get('mobileNumber').valid;
        this.sharedService.updateMobilePhone(isNumberValid ? this.mobilePhoneInput : null);
        this.sharedService.updateMobilePhoneInputValidity(isNumberValid);
    }

    updatePhoneInput(event?: Event): void {
        if (!!this.mobilePhoneInput.countryCode) {
            this.phoneConfigCountry = CountriesUtils.getCountryCode(this.mobilePhoneInput.countryCode);
        } else {
            this.phoneConfigCountry = this.countryCode;
        }

        const inputPhoneNumber = this.phoneNumberPipe.getFormattedMobilePhone(this.contactForm.value.mobileNumber,
            this.phoneConfigCountry
            , PhoneNumberFormat.E164);

        if (this.mobilePhoneInput.countryCode !== null && this.mobilePhoneInput.countryCode !== 'fr') {
            this.phoneConfigCountry = this.mobilePhoneInput.countryCode;
        } else {
            this.phoneConfigCountry = 'fr';
            this.mobilePhoneInput.countryCode = 'fr';
        }
        if (!event) {
            this.contactForm.get('mobileNumber').setValue(inputPhoneNumber.mobileNumber);
        }
        this.mobilePhoneInput.mobileNumber = inputPhoneNumber.mobileNumber;
        this.mobilePhoneInput = this.phoneNumberPipe.getFormattedMobilePhone(this.mobilePhoneInput.mobileNumber, this.mobilePhoneInput.countryCode
            , PhoneNumberFormat.INTERNATIONAL);
        this.verifyPhoneNumberType();
        this.notifyInputTelFormChanged();
        this.searchIfAutomaticField();
    }

    isSearchable = () => this.customerApiInfo?.searchableFields?.includes('mobileNumber');

    searchIfAutomaticField(): void {
        if (this.customerApiInfo?.automaticFields?.includes('mobileNumber') &&
            this.contactForm.get('mobileNumber').valid &&
            this.mobilePhoneInput.mobileNumber) {
            this.search.emit();
        }
    }

    verifyPhoneNumberType(): void {
        if (!this.mobilePhoneInput.mobileNumber) {
            this.contactForm.get('mobileNumber').setErrors(this.isRequiredFields  ? {'incorrect': true} : null);
        } else {
            const isMobileNumber = this.phoneNumberPipe.isMobileNumber(this.mobilePhoneInput.mobileNumber,
                this.mobilePhoneInput.countryCode);
            if (!isMobileNumber) {
                this.contactForm.get('mobileNumber').setErrors({'incorrect': true});
            } else {
                this.contactForm.get('mobileNumber').setErrors(null);
            }
        }
    }

    onCountryChange(country: any): void {
        this.phoneConfigCountry = country.iso2;
        this.contactForm.get('mobileNumber').setValue(null);
        this.mobilePhoneInput.countryCode = country.iso2;
        this.mobilePhoneInput.mobileNumber = '';
    }

    get mobileNumber(): any {
        return this.contactForm.get('mobileNumber');
    }

    isValidCountryCode(countryCode: string): boolean {
        if (!countryCode) {
            return false;
        }
        return CountriesUtils.isValidCountryCode(countryCode);
    }
}
