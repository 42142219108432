import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DashboardV2Service} from '../../../shared/services/dashboard-v2.service';
import {take} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {hasScope} from '../../../store/user/user.selectors';
import {Observable} from 'rxjs';
import {userDashboards} from '../../../store/dashboard/dashboard.selectors';

@Component({
    selector: 'dashboard-home-page',
    templateUrl: './dashboard-home-page.component.html'
})
export class DashboardHomePageComponent implements OnInit {

    isCreationMode: boolean;
    canCreate: Observable<boolean>;

    constructor(private dashboardService: DashboardV2Service,
                private store$: Store<AppState>,
                private router: Router) {
    }

    ngOnInit() {
        this.store$.pipe(select(userDashboards), take(1))
            .subscribe(dashboards =>
                dashboards.length > 0
                    ? this.router.navigate(['dashboard-v2', dashboards[0].dashboardId])
                    : this.isCreationMode = true);
        this.canCreate = this.store$.pipe(select(hasScope, {scope: 'configuration.dashboard-v2'}));
    }

    create() {
        this.router.navigate(['dashboard-v2/create']);
    }
}



