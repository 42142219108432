import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MapService {

    public objToMap(obj): Map<any, any> {
        if (!!obj) {
            return new Map(
                Object
                    .keys(obj)
                    .map(
                        key => [key, obj[key]]
                    )
            );
        }
        return new Map<any, any>();
    }

    public mapToObj(strMap) {
        const obj = Object.create(null);
        strMap.forEach((v, k) => {
            obj[k] = v;
        });
        return obj;
    }
}
