<div fxLayout="row" fxLayout.lt-lg="row" fxLayout.lt-md="column" fxLayoutGap="8px" fusePerfectScrollbar>
    <div fxFlex="50">
        <app-public-supplier-header [folder]="folder"></app-public-supplier-header>
    </div>
    <div fxFlex="50">
        <mat-card class="mat-elevation-aster">

            <mat-card-content>
                <form [formGroup]="reparationAgreementForm"
                      fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px"
                      (ngSubmit)="onSubmit()">
                    <mat-card-title class="h3 mt-12 mb-24">
                        {{'COMPONENT.WAITING_REPARATION_AGREEMENT' | translate}}
                    </mat-card-title>

                    <mat-radio-group fxLayout="column" fxLayoutGap="20px"
                                     formControlName="choice"
                                     (change)="onChoiceChange($event.value)">
                        <mat-radio-button [id]="'accept_agreement'" [value]="true">
                            {{'COMPONENT.WAITING_REPARATION_AGREEMENT.ACCEPTED'|translate}}
                        </mat-radio-button>
                        <mat-radio-button [id]="'refuse_agreement'" [value]="false">
                            {{'COMPONENT.WAITING_REPARATION_AGREEMENT.REFUSED'|translate}}
                        </mat-radio-button>
                    </mat-radio-group>


                    <ng-container *ngIf="displayAgreementNumber()">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'COMPONENT.WAITING_REPARATION_AGREEMENT.NUMBER'|translate}}</mat-label>
                            <input matInput [placeholder]="'COMPONENT.WAITING_REPARATION_AGREEMENT.NUMBER'|translate"
                                   [id]="'agreement_number'"
                                   formControlName="agreementNumber"
                                   required>
                        </mat-form-field>
                    </ng-container>
                    <!-- Supplier comment -->
                    <div fxLayout="row" fxLayoutAlign="space-between" class="mt-12">
                        <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                            <mat-label>{{'MODAL.SUPPLIER.COMMENT.DESCRIPTION' | translate}}</mat-label>
                            <textarea matInput rows="4" cols="7"
                                      [placeholder]="'MODAL.SUPPLIER.COMMENT.DESCRIPTION' | translate"
                                      formControlName="supplierComment"></textarea>
                        </mat-form-field>
                    </div>

                    <div fxLayoutAlign="end center">
                        <button type="submit" mat-stroked-button
                                class="py-8 px-24 font-weight-900 font-size-30"
                                [ngClass]="reparationAgreementForm.invalid ?'aster_btn_disabled':'aster_btn'"
                                [disabled]="reparationAgreementForm.invalid">
                            {{'BUTTON.VALIDATE' | translate}}
                        </button>
                    </div>

                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>