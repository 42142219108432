import { Component, OnInit } from '@angular/core';
import { getModOpIconNameByCode, WARRANTY_CONFIGS } from '../../../shared/data/static.config';
import { Folder, OperationMode } from '../../../models/folder.model';
import { FolderService } from '../../../shared/services/folder.service';
import { BackOfficeService } from '../../../shared/services/back-office.service';
import { FolderSubjectService } from '../folder-subject.service';
import { Unsubscriber } from '../../../unsubscriber';
import { OriginEnum } from '../../../models/enums/origin.enum';
import { select, Store } from '@ngrx/store';
import {hasScope, isProductRecall, isSAV} from '../../../store/user/user.selectors';
import { AppState } from '../../../store/app.state';
import { TranslateService } from '@ngx-translate/core';
import { GLOBAL } from '../../../app-config';
import {Scopes} from '../../../models/scopes/scopes.model';

@Component({
    selector: 'app-warranty-card',
    templateUrl: './warranty-card.component.html',
    styleUrls: ['./warranty-card.component.scss']
})
export class WarrantyCardComponent extends Unsubscriber implements OnInit {

    folder: Folder;
    listWarranty: any = WARRANTY_CONFIGS;
    lastWarranty: any;
    isProductRecall = false;
    isSAV = false;
    lastOperationMode: OperationMode;
    Scopes = Scopes;
    constructor(private store$: Store<AppState>,
                private folderSubjectService: FolderSubjectService) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(isProductRecall))
            .subscribe(isProductRecall => this.isProductRecall = isProductRecall);
        this.anotherSubscription = this.store$.pipe(select(isSAV))
            .subscribe(isSAV => this.isSAV = isSAV);
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            if (!!folder) {
                this.folder = folder;
                this.lastWarranty = this.listWarranty.filter(el => el.key === this.folder.newWarranty.warranty.toString())[0];
                this.lastOperationMode = this.getLastOperationMode();
            }
        });
        this.lastOperationMode = this.getLastOperationMode();
    }

    private getLastOperationMode() {
        return this.folder.oldOperationModes.length > 0 ? this.folder.oldOperationModes.reduce((oldOperatingMode, currentOperatingMode) => {
            return oldOperatingMode.switchedBy.actionDate > currentOperatingMode.switchedBy.actionDate ? oldOperatingMode : currentOperatingMode;
        }) : null;
    }

    getIconName(modOpCOde: any) {
        return getModOpIconNameByCode(modOpCOde);
    }

    openFolderInFOF() {
        if (this.folder.shortUrl) {
            window.open(this.buildShortUrlOfTheFolder(), '_blank');
        }
    }

    buildShortUrlOfTheFolder(): string {
        return this.folder.shortUrl.includes('http') ? this.folder.shortUrl : 'https://' + this.folder.shortUrl;
    }


    canOpenFolderInFOF(): boolean {
        return this.isProductRecall ? true : this.folder.origin !== OriginEnum.BO;
    }

    openPublicPage(option: string) {
        window.open(`${GLOBAL.savBackOfficeFrontend}/public/${option}/` + this.folder.externalUid.id);
    }
    public hasScope = (scope) => this.store$.pipe(select(hasScope, {scope: scope}));
}
