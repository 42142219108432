import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-quotation-acceptation',
    templateUrl: './quotation-acceptation.component.html',
    styleUrls: ['./quotation-acceptation.component.scss']
})
export class QuotationAcceptationComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;

    constructor() {
    }

    ngOnInit(): void {
    }

    completeTask(dataToComplete): void {
        this.inputMap.emit(dataToComplete);
    }

}
