import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-health-impact',
  templateUrl: './health-impact.component.html',
  styleUrls: ['./health-impact.component.css']
})
export class HealthImpactComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Output() previewTask = new EventEmitter<any>();

    @Input() variablesTask: any;
    healthImpactForm: any = {
        form: FormGroup,
        placeholders: []
    };

  constructor() { }

  ngOnInit(): void {
      this.initImpactHealthForm();
  }

    private initImpactHealthForm(): void {
        this.healthImpactForm.form = new FormGroup({
            healthImpact: new FormControl(null, Validators.required)
        });
        this.healthImpactForm.placeholders = [
            {
                healthImpact: true,
                value: 'COMPONENT.PRODUCT_HEALTH_IMPACT.TRUE',
            },
            {
                healthImpact: false,
                value: 'COMPONENT.PRODUCT_HEALTH_IMPACT.FALSE',
            }
        ];
    }

    completeTask(): void {
        this.inputMap.emit({
            'healthImpact': this.healthImpactForm.form.value.healthImpact
        });
    }
}
