<app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
    <mat-card-content class="mt-25">
        <div fxLayout="row" fxLayoutAlign="center center" >
            <div class="bg-card_gris text-center pt-44 pb-44 w-100-p">
                <mat-icon class="h-44 w-44 icon-timer_wait">timer</mat-icon>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <div *ngIf="!!managementSiteAppointment" class="description-font-ars">{{
                        ('FOLDER.SITE.DESCRIPTION_PREFIX') | translate:{
                            appointmentDateday: managementSiteAppointment.day | moment: ('GENERAL.DATE_FORMAT_CONFIRMATION' | translate) : (currentLanguage$ | async),
                            appointmentDatebeginHour: getHours(managementSiteAppointment.beginHour),
                            siteName: site.name
                        } }}
                    </div>
                    <div *ngIf="managementSiteAppointment === null" class="description-font-ars">{{
                        ('FOLDER.SITE.DESCRIPTION_PREFIX_WITHOUT_APPOINTMENT') | translate:
                            {siteName: site.name} }}
                    </div>

                    <div *ngIf="!!site.address " class="description-font-ars ">
                        <div *ngIf="!!site.address.address">
                            <div class="Teaser-content mt-8 text-center">
                                {{site.address.address}}
                            </div>
                        </div>
                        <div *ngIf="!!site.address.outSideInformation">
                            <div class="Teaser-content text-center">
                                {{site.address.outSideInformation}}
                            </div>
                        </div>
                        <div *ngIf="!!site.address.zipCode || !!site.address.city">
                            <div class="Teaser-content text-center ">
                                {{site.address.zipCode}} {{site.address.city}}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <ng-container>
            <div class="mt-24">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-checkbox  [(ngModel)]="productDeposit">
                        {{'BUTTON.PRODUCT_DEPOSIT' | translate}}
                    </mat-checkbox>

                    <button mat-stroked-button (click)="onSubmit()"
                            type="submit" [ngClass]="productDeposit? 'aster_btn': 'aster_btn_disabled' "
                            class="aster_btn">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </div>
        </ng-container>
    </mat-card-content>
