import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {WorkOrder} from '../../../../models/folder.model';
import {FormControl, Validators} from '@angular/forms';

interface DialogData {
    workOrders: WorkOrder[];
}

@Component({
    selector: 'app-fetch-work-order-numbers-dialog',
    templateUrl: './select-work-order-dialog.component.html',
    styleUrls: ['./select-work-order-dialog.component.scss']
})
export class SelectWorkOrderDialogComponent implements OnInit {

    workOrderControl = new FormControl(null, Validators.required);
    selectedWorkOrderId: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    ngOnInit(): void {
    }

    sortByDateAsc(workOrders: WorkOrder[]): WorkOrder[] {
        return workOrders.sort((first, second) =>
            new Date(first.createdDate).getTime() - new Date(second.createdDate).getTime());
    }
}
