<div fxLayout="row wrap"
     fxLayoutAlign.lt-sm="row wrap"
     fxLayoutAlign="space-between center" fxLayoutGap="10px"
     [formGroup]="contactForm">

    <mobile-phone-input-form fxFlex="45"
                             [isRequiredFields]="isRequiredField"
                             [mobilePhoneInput]="mobilePhoneInput$ | async"
                             [customerApiInfo]="customerApiInfo"
                             (search)="searchCustomer()">
    </mobile-phone-input-form>

    <landline-phone-input-form fxFlex="45" [isRequiredFields]="false"
                               [landLinePhoneInput]="landlinePhoneInput$ | async">
    </landline-phone-input-form>

    <searchable-field fxFlex
                      [field]="emailField"
                      [customerApiInfo]="customerApiInfo"
                      (onEnterKey)="searchCustomer()"
                      (onBlur)="searchCustomer()">
    </searchable-field>
</div>