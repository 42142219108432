import {UserAction} from './userAction.model';
import {Payer} from './enums/payer.enum';
import {FileInfo} from './file-info.model';
import {Amount} from './amount.model';
import {Vat} from './vat.model';
import {SparePartStock} from './spare-parts/stock.model';
import {OrderStatus} from './enums/orderStatus.enum';
import {Image, Supplier} from './spare-parts/relatedSparePart.model';
import {Folder} from './folder.model';
import {OrderItem} from './order.model';

export class Quotation {
    id?: string;
    code?: string;
    quotationLines: QuotationLine[];
    discount?: number;
    totalPrice?: Amount; // Total TTC
    totalPriceHT?: Amount; // Total net HT
    totalDiscount?: Amount;
    totalPriceHTAfterDiscount?: Amount; //Total HT
    totalVAT?: Amount;
    downPayment?: Amount;
    feesRefusedQuotationWithRestitution?: Amount;
    feesRefusedQuotationWithoutRestitution?: Amount;
    quotationAttachments?: string[];
    status?: string;
    target?: string;
    userAction?: UserAction;
    static getEmptyQuotation(currency) {
        return {
            code: '',
            quotationLines: [],
            discount: 0,
            totalPrice: {
                value: 0,
                currency: currency
            },
            quotationAttachments: [],
            target: 'CLIENT',
            userAction: null
        };
    }

    static getLastQuotation(folder: Folder){
        return folder.quotations.length > 0 ? folder.quotations.reduce((quotation1, quotation2) => {
            return quotation1.userAction.actionDate > quotation2.userAction.actionDate ? quotation1 : quotation2;
        }) : null;
    }
}



export class DataSourceLine {
    code?: string;
    label?: string;
    payer?: Payer;
    quantity: number;
    price?: number;
    purchasePrice?: number;
    marginPercentage?: number;
    currency?: string;
    vat?: number;
    stock?: SparePartStock;
    type: string;
    discountReason?: string;
    discount?: number;
    totalLinePrice: number;
    ean?: string;
    images?: Image[];
    supplier?: Supplier;
    weight?: number;
    orderItemId?: string;
}

export class QuotationLine {
    code?: string;
    label?: string;
    payer?: Payer;
    quantity: number;
    stock?: SparePartStock;
    purchasePrice?: Amount;
    marginPercentage?: number;
    priceAmount?: Amount; // price before VAT calculation
    vat?: Vat;
    type: string;
    discountReason?: string;
    discount?: number;
    totalLinePrice: Amount;  // price after VAT calculation
    totalPriceHTAfterDiscount?: Amount;  // total price HT
    sparePartReceived?: boolean;
    supplierName?: string;
    status?: OrderStatus;
    orderItemId?: string;

    static toQuotationLines(orderItems: OrderItem[], currency: string) {
       const quotationLines: QuotationLine[] = [];
        orderItems.forEach(orderItem => {
            const quotationLine: QuotationLine = {
                code: orderItem.code,
                label: orderItem.label,
                quantity: orderItem.quantityOrdered,
                type: orderItem.type,
                payer: orderItem.payer,
                discount: orderItem.discountPercent,
                discountReason: orderItem.discountReason,
                vat: {rate: String(orderItem.taxPercent)},
                priceAmount: {
                    value: orderItem.price,
                    currency: currency
                },
                totalLinePrice: {
                    value: orderItem.rowTotal,
                    currency: currency
                }
            };
            quotationLines.push(quotationLine);
        });
        return quotationLines;
    }
}

export class PublicQuotation {
    id?: string;
    code: string;
    quotationLines: PublicQuotationLine[];
    discount: number;
    totalPrice?: Amount; // Total TTC
    totalPriceHT?: Amount; // Total net HT
    totalDiscount?: Amount;
    totalPriceHTAfterDiscount?: Amount; //Total HT
    totalVAT?: Amount;
    feesRefusedQuotationWithRestitution?: Amount;
    feesRefusedQuotationWithoutRestitution?: Amount;
    quotationAttachments?: FileInfo[];
    target: string;
    userAction?: UserAction;
    agentComment?: string;
    status?: string;

}
export class PublicQuotationLine {
    code?: string;
    label?: string;
    payer?: Payer;
    quantity: number;
    priceAmount?: Amount; // price before VAT calculation
    totalPriceHTAfterDiscount?: Amount;  // total price HT
    vat?: Vat;
    type: string;
    discountReason?: string;
    discount?: number;
    totalLinePrice: Amount;  // price after VAT calculation

}
