    <app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
    <mat-card-content class="mt-25">
        <ng-container>
            <div class="mt-24">
                <mat-card-subtitle>{{'FOLDER.WORKFLOW.REPARATION_CONFIRMATION.DESCRIPTION' |translate}}</mat-card-subtitle>
                <div class="mt-24">
                    <mat-checkbox [(ngModel)]="reparationConfirmation">
                        {{'BUTTON.REPARATION_CONFIRMATION' | translate}}
                    </mat-checkbox>
                </div>

                <app-comment-area [commentForm]="commentControl"></app-comment-area>

                <div fxLayout="row" fxLayoutAlign="end">
                    <button mat-stroked-button (click)="onSubmit()"
                            type="submit"
                            [disabled]="!reparationConfirmation"
                            [ngClass]="!reparationConfirmation?'h-60 mt-4  aster_btn_disabled':'h-60 mt-4 aster_btn'">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </div>
        </ng-container>
    </mat-card-content>