<mat-card class="w-100-p h-100-p transparent-mat-card">
    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <label class="h3  text-bold">{{widgetData.name |translate}}</label>
            <ng-container *ngIf="!!columnCount">
                <mat-form-field appearance="outline" [style.width.px]=60>
                    <input matInput
                           type="number"
                           min="1"
                           numberOnly
                           oninput="validity.valid||(value='');"
                           [(ngModel)]="columnCount"
                           matTooltip="{{'DASHBOARD.WIDGET.FORM.WIDGET_COLUMN' | translate }}"
                           (blur)="update()">
                </mat-form-field>
                <button matSuffix mat-icon-button color="warn" type="button"
                        matTooltip="{{'DASHBOARD.WIDGET.DELETE'|translate}}"
                        (click)="deleteWidget()">
                    <mat-icon>delete_forever</mat-icon>
                </button>
                <button matSuffix mat-icon-button color="primary" type="button"
                        matTooltip="{{'DASHBOARD.WIDGET.UPDATE'|translate}}"
                        (click)="editWidget()">
                    <mat-icon>edit</mat-icon>
                </button>
            </ng-container>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="mt-12 card-content">
            <ngx-charts-advanced-pie-chart
                *fuseIfOnDom
                [scheme]="colorScheme"
                [results]="widgetData.data"
                (select)="showDetails($event)">
            </ngx-charts-advanced-pie-chart>
        </div>
    </mat-card-content>
</mat-card>
