import {Component, Input} from '@angular/core';
import {Product} from '../../../../models/product.model';

@Component({
    selector: 'app-product-general-info',
    templateUrl: './product-general-info.component.html'
})
export class ProductGeneralInfoComponent {

    @Input() product: Product;

}
