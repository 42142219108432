import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Product} from '../../../../models/product.model';
import {ProductService} from '../../../../shared/services/product.service';
import {debounceTime, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {MatSelectChange} from '@angular/material/select';

@Component({
    selector: 'app-product-identification',
    templateUrl: './product-identification.component.html',
    styleUrls: ['./product-identification.component.scss']
})
export class ProductIdentificationComponent implements OnInit, OnDestroy {

    @Output() public productCtrlEmitter = new EventEmitter<FormControl>();
    public productCtrl: FormControl = new FormControl();

    public productsFilteringCtrl: FormControl = new FormControl();
    public searching = false;
    public filteredProducts: ReplaySubject<Product[]> = new ReplaySubject<Product[]>(1);
    private destroy$ = new Subject<void>();

    constructor(private productService: ProductService) {
    }

    ngOnInit(): void {
        this.autocompleteSearch();
    }

    private autocompleteSearch(): void {
        this.productsFilteringCtrl.valueChanges
            .pipe(
                filter(searchValue => !!searchValue),
                tap(() => this.searching = true),
                takeUntil(this.destroy$),
                debounceTime(800),
                switchMap(searchValue => this.getProductsByLabel(searchValue))
            )
            .subscribe((products: Product[]) => {
                    this.filteredProducts.next(products);
                    this.searching = false;
                },
                () => {
                    this.searching = false;
                });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private getProductsByLabel(searchValue: string): Observable<Product[]> {
        return this.productService.findProductsByLabel(searchValue)
            .pipe(
                map(page => page.content));
    }

    selectProduct(_$event: MatSelectChange): void {
        this.productCtrlEmitter.emit(this.productCtrl);
    }
}
