    <app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
    <mat-card-content class="mt-25">

<div class="h3 mt-24 mb-12"><strong>{{'LIST.REPAIRERS' | translate}}</strong></div>
        <div class="mat-elevation-z4 w-100-p mt-12 mb-16">
            <ejs-grid #grid [dataSource]="repairers"
                      [allowPaging]="true"
                      [pageSettings]="{pageSize: 5}"
                      [filterSettings]="{type: 'CheckBox'}"
                      [selectionSettings]="{type: 'Multiple', checkboxOnly: true }"
            >
                <e-columns>
                    <e-column
                        field="code"
                        [headerText]="'TABLE.CODE' | translate">
                        <ng-template #template let-data>
                            <span>{{data.code}}</span>
                        </ng-template>
                    </e-column>
                    <e-column
                        field="label"
                        [headerText]="'TABLE.LABEL' | translate">
                        <ng-template #template let-data>
                            <span>{{data.label}}</span>
                        </ng-template>
                    </e-column>
                    <e-column
                        field="selection"
                        [headerText]="'BUTTON.SELECTION' | translate">
                        <ng-template #template let-data>
                            <mat-radio-group [(ngModel)]="selectedRepairer" fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="space-between center">
                                <mat-radio-button [id]="data.code" [value]="data"></mat-radio-button>
                            </mat-radio-group>
                        </ng-template>
                    </e-column>
                </e-columns>
            </ejs-grid>

        </div>

        <div fxLayoutAlign="end center">
            <button (click)="onSubmit()" mat-stroked-button
                    class="py-8 px-24 font-weight-900 font-size-30"
                    [ngClass]="isFormInvalid() ? 'aster_btn_disabled':'aster_btn'"
                    [disabled]="isFormInvalid()">
                {{'BUTTON.VALIDATE' | translate}}
            </button>

        </div>
</mat-card-content>
