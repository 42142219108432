import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Folder, Payment, PaymentWrapper, RefundRequest} from '../../../../../../models/folder.model';
import {OperationModeEnum} from '../../../../../../models/enums/operationMode.enum';
import {PaymentMode} from '../../../../../../models/enums/PaymentMode';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BackOfficeService} from '../../../../../../shared/services/back-office.service';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {TranslateService} from '@ngx-translate/core';
import {CurrentWorkFlowStatusEnum} from '../../../../../../models/enums/currentWorkFlowStatus.enum';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {AppState} from '../../../../../../store/app.state';
import {RegimeWarrantyEnum} from '../../../../../../models/warrantyRule.model';
import {Store} from '@ngrx/store';

@Component({
    selector: 'dialog-data-cancel-intervention.component',
    templateUrl: 'dialog-data-cancel-intervention.component.html',
})
export class DialogDataCancelInterventionComponent implements OnInit, OnDestroy {
    private readonly _onDestroy = new Subject();
    registerForm: FormGroup;
    folder: Folder;
    paymentsWrapper = new Array<PaymentWrapper>();
    folderClosed: boolean;
    isPaymentSelected: boolean;

    constructor(private dialogRef: MatDialogRef<DialogDataCancelInterventionComponent>,
                private translateService: TranslateService,
                private dialog: MatDialog,
                public folderSubjectService: FolderSubjectService,
                private backOfficeService: BackOfficeService,
                private store$: Store<AppState>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private formBuilder: FormBuilder) {
        this.initFormCancelIntervention();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    ngOnInit(): void {
        this.folderSubjectService.folder$
            .pipe(takeUntil(this._onDestroy))
            .subscribe(res => {
                this.folder = res;
                this.folder.payments.forEach(item => {
                    this.paymentsWrapper.push(new PaymentWrapper(item, false));
                });
            });
        this.folderClosed = this.folder.currentStatus.status.toString().startsWith('FOLDER_CLOSED');
    }

    initFormCancelIntervention(): void {
        this.registerForm = this.formBuilder.group({
            cancelIntervention: [null, Validators.required]
        });
    }

    isRefundEnabled(payment: Payment): boolean {
        return (!payment.refunds || payment.refunds.length === 0) && !this.folderClosed && payment.paymentMode === PaymentMode.INTERNET;
    }

    isRefundSuggestionPossible(): boolean {
        return this.folder.currentWorkflowStatus.status === CurrentWorkFlowStatusEnum.PROGRESS_REPAIR &&
            this.folder.operationMode === OperationModeEnum.HOME_REPAIR &&
            this.folder.newWarranty.warranty === RegimeWarrantyEnum.HG &&
            this.folder.payments &&
            this.folder.payments.length > 0 &&
            this.isRemotePayment() ;
    }

    private isRemotePayment() {
        return !!this.folder.payments.find(value => 'INTERNET' === value.paymentMode);
    }

    refund(): void {
        const selectedRefunds = this.paymentsWrapper.filter(item => item.selected);
        if (this.isRefundSuggestionPossible() && selectedRefunds.length > 0) {
            selectedRefunds.forEach(paymentWrapper => {
                const refundRequest: RefundRequest = {
                    folderId: this.folder.id,
                    paymentReference: paymentWrapper.payment.paymentReference,
                    refundAmount: {
                        value: paymentWrapper.payment.amount,
                        currency: paymentWrapper.payment.currency
                    },
                    refundReason: 'Refund :' + paymentWrapper.payment.paymentReason
                };
                this.store$.dispatch(new StartLoading());
                // TODO Think to adapt to support multiple payments
                this.backOfficeService.refund(refundRequest).subscribe(folder => {
                    this.store$.dispatch(new StopLoading());
                    this.folderSubjectService.folderLoaded(folder);
                    this.dialogRef.close(this.registerForm.value.cancelIntervention);
                }, () => this.store$.dispatch(new StopLoading()));
            });
        } else {
            this.dialogRef.close(this.registerForm.value.cancelIntervention);
        }

    }
}
