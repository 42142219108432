<div class="text-center">
    <mat-dialog-content>
        <div *ngIf="isLoading" class="spinner">
            <mat-spinner diameter="150"></mat-spinner>
        </div>
        <img [hidden]="isLoading" [attr.src]="images[currentImageIndex]" (load)="onLoad()" (error)="onImageError($event)" alt="image">
    </mat-dialog-content>

    <div mat-dialog-actions fxLayoutAlign="end">
        <button mat-stroked-button
                [ngClass]="isLoading || isFirstImage() ? 'aster_btn_disabled' : 'aster_btn'"
                [disabled]="isLoading || isFirstImage()"
                (click)="showPreviousImage()">
            {{'BUTTON.PREVIOUS' | translate}}
        </button>
        <button mat-stroked-button
                [ngClass]="isLoading || isLastImage() ? 'aster_btn_disabled' : 'aster_btn'"
                [disabled]="isLoading || isLastImage()"
                (click)="showNextImage()">
            {{'BUTTON.NEXT' | translate}}
        </button>
        <button mat-stroked-button mat-dialog-close="null" class="aster_btn">{{'BUTTON.CLOSE' | translate}}</button>
    </div>
</div>
