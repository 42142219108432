import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-diagnostic-delay',
    templateUrl: './diagnostic-delay.component.html',
    styleUrls: ['./diagnostic-delay.component.scss']
})
export class DiagnosticDelayComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    form: FormGroup;
    buttons: any[];

    constructor() {
    }

    ngOnInit(): void {
        this.initForm();
        this.initButtons();
    }

    private initForm(): void {
        this.form = new FormGroup({
            status: new FormControl(null, Validators.required),
        });
    }

    private initButtons(): void {
        this.buttons = [
            {
                status: false,
                value: 'BUTTON.NO_OUT_OF_TIME', // response received from repairer
            },
            {
                status: true,
                value: 'BUTTON.IS_OUT_OF_TIME',
            }
        ];
    }

    onSubmit(): void {
        this.inputMap.emit({'diagnosisIsOutOfTime': this.form.value.status});
    }

    disabledButtonForm(): boolean {
        return this.form.invalid;
    }

}
