<div class="tab-public_details mat-elevation-z2 " *ngIf="!!folder">
    <!-- Info Section -->
    <section id="productInfoSection" (click)="updateSection('productInfoSection')">
        <input type="radio" name="sections" id="productInfo" checked>
        <label for="productInfo" class="text-center">
            <mat-icon class="mt-20">info</mat-icon>
            <p class="h4">  {{'FOLDER.INFOS_TITLE'| translate}} </p>
        </label>
        <article class="article-scroll">
            <app-repairer-product-header [folder]="folder"></app-repairer-product-header>
        </article>
    </section>
    <!-- Quotation Section -->
    <section id="quotation" (click)="updateSection('quotation')">
        <input type="radio" name="sections" id="quotationDetails" [disabled]="!hasPublicQuotations()">
        <label for="quotationDetails" class="text-center">
            <mat-icon  matBadgeColor="red" matBadgePosition="above after"
                       [ngClass]="hasPublicQuotations() ?  'mt-20 ' :  'mt-20 disabled-tab_section'"
                     >assignment
            </mat-icon>
            <p [ngClass]="(folder?.publicQuotations?.length === 0)  ? 'h4 disabled-tab_section' : ' h4'"> {{'FOLDER.COMMENT.QUOTATION_CR.LABEL' | translate}} </p>
        </label>
        <article class="article-scroll" disabled="folder.quotations.length > 0 && activeSection=='quotation'">
            <app-public-quotation-report-card [folder]="folder"></app-public-quotation-report-card>
        </article>
    </section>
</div>