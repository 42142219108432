import {Component, OnInit} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import {AddChartsConfigDialogComponent} from '../add-charts-config-dialog/add-charts-config-dialog.component';
import {ChartsResponse} from '../../../model/charts-response.model';
import {ChartRequest, DashboardRule} from '../../../model/dashboard.model';
import {DashboardService} from '../../../../../../shared/services/dashboard.service';
import {CloseModal} from '../../../../../../shared/modal/CloseModal';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {Unsubscriber} from '../../../../../../unsubscriber';

@Component({
    selector: 'app-dashboard-config',
    templateUrl: './dashboard-config.component.html',
    styleUrls: ['./dashboard-config.component.scss']
})
export class DashboardConfigComponent extends Unsubscriber implements OnInit {
    private closeModal = new CloseModal();
    chartsResponse: ChartsResponse[];
    chartRequest: {
        charts: ChartRequest[]
    } = {charts: []};

    charts: Map<number, ChartsResponse[]>;
    showWidgetList = true;
    private dashboardId: string;
    private dashboardRule: DashboardRule;

    constructor(private dashboardService: DashboardService,
                private activatedRoute: ActivatedRoute,
                private router: Router, public dialog: MatDialog,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.activatedRoute.params.subscribe(params => {
            this.loadDashboard(params?.id);
            this.dashboardId = params?.id;
        });
    }

    loadDashboard(dashboardId: string): void {
        this.store$.dispatch(new StartLoading());
        this.dashboardService.getAllWidgets().subscribe(chartRequest => {
            if (chartRequest && chartRequest.length > 0) {
                chartRequest.forEach(value => {
                    value.position = 1;
                    value.line = 1;
                    value.columnCount = 1;
                });
                this.chartRequest = {
                    charts: chartRequest
                };
                this.dashboardService.getWidgetsData(this.chartRequest).subscribe(chartsResponse => {
                    this.store$.dispatch(new StopLoading());
                    this.chartsResponse = chartsResponse;
                    this.charts = new Map<number, ChartsResponse[]>();
                    this.addNewLine();
                    if (dashboardId) {
                        this.dashboardService.getDashboard(dashboardId).subscribe(value => {
                            this.dashboardRule = value;
                            this.charts = new Map<number, ChartsResponse[]>();
                            value.dashboardItems.forEach(item => {
                                if (!this.charts.get(item.line)) {
                                    this.charts.set(item.line, []);
                                }
                                const index = chartsResponse.findIndex(value1 => value1.code === item.code);
                                if (index > -1) {
                                    this.charts.get(item.line).push(chartsResponse[index]);
                                    this.chartsResponse.splice(index, 1);
                                }
                            });
                        });
                    }
                }, () => this.store$.dispatch(new StopLoading()));
            } else {
                this.store$.dispatch(new StopLoading());
                this.chartsResponse = [];
            }
        }, () => this.store$.dispatch(new StopLoading()));
    }


    getAreasChart(key: number): string {
        let areas = '';
        const chart: ChartsResponse[] = this.charts.get(key);
        chart.forEach(value => {
            for (let i = 0; i < this.chartRequest.charts.find(value1 => value1.name.startsWith(value.name)).columnCount; i++) {
                areas += ' ' + this.getChartName(value.name);
            }
        });
        return areas;
    }

    getChart(widgetName: string): ChartRequest {
        return this.chartRequest.charts.find(value => value.name.startsWith(widgetName));
    }

    getChartName(name: string): string {
        return name.split(' ').join('')
            .split('.').join('')
            .split('\'').join('')
            .split('é').join('e')
            .split('è').join('e')
            .split('-').join('')
            .split('_').join('').toLocaleLowerCase();
    }


    drop(event: CdkDragDrop<ChartsResponse[]>, index: string) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }

        const charts = this.charts;
        this.charts = new Map<number, ChartsResponse[]>();
        setTimeout(() => {
            this.charts = charts;
        }, 1);
    }


    updateChartColumn(widgetName: string, value: number): void {
        const charts = this.charts;
        this.chartRequest.charts.find(value1 => value1.name.startsWith(widgetName)).columnCount = value;
        this.charts = new Map<number, ChartsResponse[]>();
        setTimeout(() => {
            this.charts = charts;
        }, 1);
    }

    addNewLine(): void {
        const keys = [];
        if (!!this.charts) {
            if (this.charts.size === 0) {
                this.charts.set(1, []);
            } else {
                this.charts.forEach((value, key) => {
                    keys.push(key);
                });
                this.charts.set(_.max(keys) + 1, []);
            }
        }

    }

    deleteChartLine(key: number): void {
        this.charts.delete(key);
    }

    dropWidget(widgetName: string): void {
        const charts = new Map<number, ChartsResponse[]>();
        this.charts.forEach((value, key) => {
            const removedChartFromLine = value.find(value1 => value1.name === widgetName);
            this.chartsResponse.push(removedChartFromLine);
            charts.set(key, value.filter(e => e.name !== widgetName));
        });
        // avoid returning undefined object to the list of widgets
        this.chartsResponse = this.chartsResponse.filter(item => !!item);

        this.showWidgetList = false;
        setTimeout(() => {
            this.charts = charts;
            this.showWidgetList = true;
        }, 1);
    }

    saveRule(): void {
        if (this.dashboardId) {
            this.dashboardRule.dashboardItems = this.dashboardService.buildDashboardItems({
                'chart': this.charts,
                'chartRequest': this.chartRequest
            });
            this.dashboardService.updateDashboard(this.dashboardRule).subscribe(data => {
                this.dashboardService.showSuccessSave();
            }, error => {
                this.dashboardService.showError();
            });
        } else {
            const dialogRef = this.dialog.open(AddChartsConfigDialogComponent, {
                data: {
                    chart: this.charts,
                    chartRequest: this.chartRequest,
                    save: this.dashboardService.saveDashboard
                },
                width: '30%',
                disableClose: true
            });
            this.closeModal.closeWithEscape(dialogRef);
        }

    }
}
