<mat-card class="w-100-p h-100-p transparent-mat-card">
    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <label fxFlex="80" class="h3  text-bold">{{widgetData.name |translate}}</label>
                <ng-container *ngIf="!!columnCount">
                    <div  fxFlex>
                        <mat-form-field  appearance="outline" class="w-45-p">
                            <mat-label>{{'DASHBOARD.WIDGET.FORM.WIDGET_COLUMN' | translate }}</mat-label>
                            <button matSuffix mat-icon-button type="button" class="mx-4"
                                    (click)="update()">
                                <mat-icon>done</mat-icon>
                            </button>
                            <input matInput
                                   type="number" [min]="1"
                                   [(ngModel)]="columnCount">

                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
            <div>
                <button mat-icon-button [matMenuTriggerFor]="card20Menu" aria-label="more">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #card20Menu="matMenu" style="background: white !important;">
                    <div class="h-240">
                        <ng-container *ngFor="let status of allStatusFolder">
                            <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item>
                                <mat-checkbox [checked]="statusDefaultSelected.includes(status)"
                                              (change)="refreshDataTable(status)">
                                    {{'COMPONENT.' + status | translate}}
                                </mat-checkbox>
                            </button>
                        </ng-container>
                    </div>

                </mat-menu>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <table class="simple clickable">
                <thead>
                <tr>
                    <th>{{'DASHBOARD.WIDGET.LIST_TABLE.STATUS' |translate}}</th>
                    <th class="text-right">{{'DASHBOARD.WIDGET.LIST_TABLE.COUNT' |translate}}</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let line of widgetData.data">
                    <ng-container *ngIf="statusDefaultSelected.includes(line.name)">
                        <tr                 (click)="showDetails(line)">
                            <td>{{'COMPONENT.' + line.name | translate}}</td>
                            <td class="text-right" [countUp]="line.value">0</td>
                        </tr>
                    </ng-container>
                </ng-container>
                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>