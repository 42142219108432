import {NgModule} from '@angular/core';
import {AddressFormComponent} from './address-form/address-form.component';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {QuotationModalComponent} from './quotation-modal/quotation-modal.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MaterialModule} from '../../../@fuse/material/material.module';
import {MobilePhoneFormComponent} from './input-tel-form/mobile-phone-form.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {CalendarRdvComponent} from './calendar-rdv/calendar-rdv.component';
import {ReportModalComponent} from './report-modal/report-modal.component';
import {EditManagementSiteModalComponent} from './edit-managment-site-modal/edit-management-site-modal.component';
import {ProductPreviewCardAllDetailsComponent} from './product-preview-card-all-details/product-preview-card-all-details.component';
import {LandLinePhoneInputFormComponent} from './landline-phone-input-form/land-line-phone-input-form.component';
import {CountriesFlagsComponent} from './countries-flags-component/countries-flags.component';
import {SparePartsOrderModalComponent} from './spare-parts-order-modal/spare-parts-order-modal.component';
import {VisioModalComponent} from './visio-modal/visio-modal.component';
import {OrderedSparePartsComponent} from './ordered-spare-parts/ordered-spare-parts.component';
import {SubElementsComponent} from '../../main/folder/sub-elements/sub-elements.component';
import {AccessoriesComponent} from '../../main/folder/accessories/accessories.component';
import {ProfessionalFolderFormComponent} from './professional-folder-form/professional-folder-form.component';
import {ProductStateComponent} from './product-state/product-state/product-state.component';
import {ProductPreviewCardComponent} from './product-preview-card-all-details/product-preview-card/product-preview-card.component';
import {GenericProductPreviewCardComponent} from './product-preview-card-all-details/generic-product-preview-card/generic-product-preview-card.component';
import {ProductEditableFieldComponent} from './product-preview-card-all-details/product-editable-field/product-editable-field.component';
import {ProductSupplierFieldComponent} from './product-preview-card-all-details/product-supplier-field/product-supplier-field.component';
import {CommentAreaComponent} from '../../models/comment-area/comment-area.component';
import {SearchableFieldComponent} from './searchable-text-input/searchable-field.component';
import {RepairerTaskTitleComponent} from './repairer-task-title/repairer-task-title.component';
import {WorkflowStatusProgressBarComponent} from './workflow-status-progress-bar/workflow-status-progress-bar.component';
import {UserTaskHeaderComponent} from '../../main/folder/folder-workflow/user-task-title/user-task-header.component';
import {ProductImageComponent} from '../../main/product-image/product-image.component';
import {SwitchOperatingModeModalComponent} from './switch-operating-mode/switch-operating-mode-modal.component';
import {CardElementComponent} from './card-element/card-element.component';
import {ProductAccessoriesComponent} from './product-accessories/product-accessories.component';
import {AccessoriesLabelListComponent} from './accessories-label-list/accessories-label-list.component';
import { ConfirmationWithCommentComponent } from './switch-to-swap/confirmation-with-comment.component';
import {ReportsUserTaskComponent} from '../../main/folder/folder-workflow/reports-user-task/reports-user-task.component';
import {UserTaskFooterComponent} from '../../main/folder/folder-workflow/user-task-footer/user-task-footer.component';
import {FormGroupDirective} from '@angular/forms';
import {GridModule} from '@syncfusion/ej2-angular-grids';
import { ChooseCarrierComponent } from './choose-carrier/choose-carrier.component';

@NgModule({
    declarations: [
        ReportsUserTaskComponent,
        AddressFormComponent,
        CalendarRdvComponent,
        MobilePhoneFormComponent,
        SearchableFieldComponent,
        LandLinePhoneInputFormComponent,
        ProductPreviewCardAllDetailsComponent,
        GenericProductPreviewCardComponent,
        ProductEditableFieldComponent,
        ProductSupplierFieldComponent,
        ProductPreviewCardComponent,
        QuotationModalComponent,
        ReportModalComponent,
        EditManagementSiteModalComponent,
        CountriesFlagsComponent,
        SparePartsOrderModalComponent,
        VisioModalComponent,
        OrderedSparePartsComponent,
        SubElementsComponent,
        AccessoriesComponent,
        ProfessionalFolderFormComponent,
        ProductStateComponent,
        CommentAreaComponent,
        RepairerTaskTitleComponent,
        WorkflowStatusProgressBarComponent,
        UserTaskHeaderComponent,
        ProductImageComponent,
        SwitchOperatingModeModalComponent,
        CardElementComponent,
        ProductAccessoriesComponent,
        AccessoriesLabelListComponent,
        ConfirmationWithCommentComponent,
        UserTaskFooterComponent,
        ChooseCarrierComponent
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MaterialModule,
        Ng2TelInputModule,
        GridModule
    ],
    exports: [
        AddressFormComponent,
        CountriesFlagsComponent,
        MobilePhoneFormComponent,
        LandLinePhoneInputFormComponent,
        CalendarRdvComponent,
        ProductPreviewCardAllDetailsComponent,
        EditManagementSiteModalComponent,
        OrderedSparePartsComponent,
        ProfessionalFolderFormComponent,
        ProductStateComponent,
        SubElementsComponent,
        AccessoriesComponent,
        CommentAreaComponent,
        SearchableFieldComponent,
        ProductEditableFieldComponent,
        RepairerTaskTitleComponent,
        WorkflowStatusProgressBarComponent,
        UserTaskHeaderComponent,
        ReportsUserTaskComponent,
        ProductImageComponent,
        CardElementComponent,
        ProductImageComponent,
        ProductAccessoriesComponent,
        AccessoriesLabelListComponent,
        ProductSupplierFieldComponent,
        UserTaskFooterComponent,
        ChooseCarrierComponent
    ],
    entryComponents: [
        QuotationModalComponent,
        ReportModalComponent
    ],
    providers: [
        FormGroupDirective,
        {provide: MAT_DIALOG_DATA, useValue: {}},
        {provide: MatDialogRef, useValue: {}}
    ]
})
export class SharedGenericModule {
}
