import {PublicWaitingSupplierConfirmationComponent} from './public-waiting-supplier-confirmation/public-waiting-supplier-confirmation.component';
import {PublicWaitingCreditSupplierComponent} from './public-waiting-credit-supplier/public-waiting-credit-supplier.component';
import {PublicWaitingReparationAgreementComponent} from './public-waiting-reparation-agreement/public-waiting-reparation-agreement.component';
import {PublicOldWaitingReparationAgreementComponent} from './public-old-waiting-reparation-agreement/public-old-waiting-reparation-agreement.component';

export const GENERIC_SUPPLIER_COMPONENTS_TASKS = {

    // Supplier WF
    'WAITING_SUPPLIER_CONFIRMATION': PublicWaitingSupplierConfirmationComponent,
    'WAITING_CREDIT_SUPPLIER': PublicWaitingCreditSupplierComponent,
    'WAITING_REPARATION_AGREEMENT': PublicWaitingReparationAgreementComponent,
    'OLD_WAITING_REPARATION_AGREEMENT': PublicOldWaitingReparationAgreementComponent
};
