<div mat-dialog-title style="font-size: medium" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="text-bold">
        {{'FOLDER.PRODUCT.DIALOG.TITLE' | translate}}: {{product.label}}
    </div>
    <button mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutGap="20px">
            <app-product-general-info fxFlex="40"
                                      [product]="product"
            ></app-product-general-info>

            <app-product-nomenclature fxFlex="30"
                                      [product]="product"
            ></app-product-nomenclature>

            <app-product-documents fxFlex="30"
                                   [documents]="product.documents"
            ></app-product-documents>

        </div>
        <div fxLayout="row" fxLayoutGap="20px">
            <app-product-warranty fxFlex="40"
                                  [product]="product"
            ></app-product-warranty>
            <app-product-repairability fxFlex="30"
                                       [product]="product"></app-product-repairability>
            <div fxFlex="30"></div>
        </div>

        <ejs-tab>
            <e-tabitems>
                <e-tabitem [header]="{text: 'PRODUCT.SPARE_PARTS' | translate}">
                    <ng-template #content>
                        <app-product-spare-parts [product]="product" [managementSiteCode]="managementSiteCode"></app-product-spare-parts>
                    </ng-template>
                </e-tabitem>
                <e-tabitem [header]="{text:'COMPONENT.SUB_ELEMENTS.TITLE' | translate}">
                    <ng-template #content>
                        <app-product-sub-element-warranty [product]="product"
                                                          [purchaseDate]="purchaseDate"
                        ></app-product-sub-element-warranty>
                    </ng-template>
                </e-tabitem>
            </e-tabitems>
        </ejs-tab>
    </div>

</mat-dialog-content>