import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// @ts-ignore
import leaflet, {Control, Icon, Map} from 'leaflet';
import 'leaflet-routing-machine';
import {TripService} from '../../../../shared/services/trip.service';
import {environment} from '../../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import 'leaflet.icon.glyph';
import {IDetail} from '../../../../models/repair/detail.model';
import {IIntervention} from '../../../../models/repair/intervention.model';
import {ICustomer} from '../../../../models/repair/customer.model';
import {TechnicianTrip} from '../../../../models/repair/Trip/technicianTrip.model';
import {TechnicianService} from '../../../../shared/services/technician.service';
import {ITechnician} from '../../../../models/itinerant/technician.model';

@Component({
    selector: 'app-technician-intervention-map',
    templateUrl: './technician-intervention-map.component.html',
    styleUrls: ['./technician-intervention-map.component.scss']
})
export class TechnicianInterventionMapComponent implements OnInit, OnDestroy {

    @Input() technicianId: string;
    @Input() tripId: string;
    @Input() selectedInterventions;
    @Input() allDashBoardDayDetails: IDetail[];
    currentTechnicianTrip: any;
    currentTechnicianDetails: ITechnician;

    LAYER_OSM = {
        id: 'openstreetmap',
        name: 'Open Street Map',
        enabled: false,
        layer: leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            maxZoom: 22
        })
    };

    // Values to bind to Leaflet Directive
    layersControlOptions: Control.LayersOptions = {position: 'bottomright'};
    baseLayers = {
        'Open Street Map': this.LAYER_OSM.layer
    };
    options = {
        zoom: 8,
        center: leaflet.latLng([47.34035834900857, 2.4030247656745862])
    };


    // Marker cluster stuff
    markerClusterData: leaflet.Marker[] = [];

    positions: any[];



    constructor(private tripService: TripService,
                private technicianService: TechnicianService,
                private translateService: TranslateService,
                private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.technicianService.getTechnician(this.technicianId).subscribe(technician => {
            if (!!technician) {
                this.currentTechnicianDetails = technician;
                this.positions = [];
                this.getTechnicianTrip();
            }
        });

    }


    refreshData(position): void {
        this.markerClusterData = this.prepareData(position);
    }

    prepareData(position): leaflet.Marker[] {
        this.positions = [];
        this.options.center = leaflet.latLng([position.latitude, position.longitude]);
        this.options.zoom = 8;
        const data: leaflet.Marker[] = [];
        let index = 0;
        let marker = null;
        this.currentTechnicianTrip.technicianTrips
            .forEach(intervention => {
            const position = this.getTechnicianPositions(this.currentTechnicianTrip.technicianTrips);
            marker = leaflet.marker([Number.parseFloat(position[0]), Number.parseFloat(position[1])], {icon: this.selectIconColor('red')});
            this.positions.push(
                leaflet.latLng(Number.parseFloat(position[0]), Number.parseFloat(position[1]))
            );
            intervention.tours.forEach(tour => {
                tour.activities.forEach(activity => {

                    const activityName = this.getCustomerName(activity.id);
                    if (!!activityName || !activity.type.includes('BREAK')) {
                        index++;
                        const customerPopupDetails = this.buildPopupCustomerInfo(activity);
                        const technicianPopupDetails = this.buildPopupTechnicianInfo(intervention, tour);
                        switch (activity.type) {
                            case 'SERVICE': {
                                marker = leaflet.marker([Number.parseFloat(activity.location.latitude), Number.parseFloat(activity.location.longitude)],
                                    {icon: leaflet.icon.glyph({glyph: index.toString()})})
                                    .bindPopup(customerPopupDetails);
                                break;
                            }
                            case 'END': {
                                 marker = leaflet.marker([Number.parseFloat(activity.location.latitude), Number.parseFloat(activity.location.longitude)],
                                     {icon: this.selectIconColor('red')})
                                    .bindPopup(technicianPopupDetails);
                              break;
                            }
                            default: {
                                 marker = leaflet.marker([Number.parseFloat(activity.location.latitude), Number.parseFloat(activity.location.longitude)],
                                     {icon: this.selectIconColor('bleu')})
                                    .bindPopup(technicianPopupDetails);
                                break;
                            }
                        }
                        data.push(marker);
                        this.positions.push(
                            leaflet.latLng(Number.parseFloat(activity.location.latitude), Number.parseFloat(activity.location.longitude))
                        );
                    }
                });
            });
        });
        return data;
    }

    private buildPopupCustomerInfo(activity): string {
        const customerDetails = this.getCustomerDetail(activity);
        return '<br/><b>' + this.translateService.instant('TECHNICIAN.MAP.CLAIM_REFERENCE') + ': </b>' + (!!customerDetails ? this.getClaimReference(customerDetails.externalId) : '-') +
            '<br/><b>' + this.translateService.instant('TECHNICIAN.MAP.FULLNAME') + ': </b>' + (!!customerDetails ? this.getCustomerInfo(customerDetails.customer) : '-') +
            '<br/><b>' + this.translateService.instant('TECHNICIAN.MAP.FROM') + ': </b>' + (!!activity.beginTime ? activity.beginTime : '-') +
            '<br/><b>' + this.translateService.instant('TECHNICIAN.MAP.TO') + ': </b>' + (!!activity.endTime ? activity.endTime : '-');
    }
    private getClaimReference = (externalId: string) => !!externalId ? externalId : '-';

    private getCustomerInfo = (customer: ICustomer) => !!customer ? (!!customer.lastName ? customer.lastName : '-') + ' ' + (!!customer.firstName ? customer.firstName : '-') : '-';

    private getCustomerDetail = (activity: IIntervention) => this.allDashBoardDayDetails.find(d => d.tripId === activity.id);

    private buildPopupTechnicianInfo(currentTripTechnicianIntervention: TechnicianTrip, tour): string {
        if (!!currentTripTechnicianIntervention){
            const firstIntervention = tour.activities.filter(ac => ac.type === 'SERVICE');
            const lastIntervention = tour.activities.find(ac => ac.type === 'END');
            return '<br/><b>' + this.translateService.instant('TECHNICIAN.MAP.FULLNAME') + ': </b>' + (!!this.currentTechnicianDetails ? this.getTechnicianFullName(this.currentTechnicianDetails) : '-') +
                '<br/><b>' + this.translateService.instant('TECHNICIAN.MAP.FROM') + ': </b>' + (!!firstIntervention ? firstIntervention[0].beginTime : '-') +
                '<br/><b>' + this.translateService.instant('TECHNICIAN.MAP.TO') + ': </b>' + (!!lastIntervention ? lastIntervention.endTime : '-');
        }

    }

    private getTechnicianFullName = (currentTechnicianDetails: ITechnician) =>  {
        const lastName = !!currentTechnicianDetails.lastName ? currentTechnicianDetails.lastName : '-';
        const firstName = !!currentTechnicianDetails.firstName ? currentTechnicianDetails.firstName : '-';
        return lastName + ' ' + firstName;
    }

    selectIconColor(colorIcon: string): Icon {
        if (colorIcon === 'red') {
            return new leaflet.Icon({
                iconUrl: 'assets/images/map/marker-icon-2x-red.png',
                shadowUrl: 'assets/images/map/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
            });
        }
        if (colorIcon === 'bleu') {

            return new leaflet.Icon({
                iconUrl: 'assets/images/map/marker-icon-2x.png',
                shadowUrl: 'assets/images/map/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
            });
        }
    }

    getTechnicianPositions(technicianTrips): any[] {
        const position = [];
        if (!!technicianTrips) {
            technicianTrips.forEach(item => {
                if (!!item) {
                    item.tours.forEach(tour => {
                        if (!!tour) {
                            const endPosition = tour.activities.find(activity => activity.type === 'END');
                            if (!!endPosition) {
                                position[0] = endPosition.location.latitude;
                                position[1] = endPosition.location.longitude;
                                return position;
                            }
                        }
                    });
                }
            });
        }
        return position;
    }
    getCentreMpaPosition(technicianTrips): any {
        const position = {
            latitude: null,
            longitude: null
        };
        if (!!technicianTrips) {
            technicianTrips.forEach(item => {
                if (!!item) {
                    item.tours.forEach(tour => {
                        if (!!tour) {
                            const endPosition = tour.activities.find(activity => activity.type === 'END');
                            if (!!endPosition) {
                                position.latitude = endPosition.location.latitude;
                                position.longitude = endPosition.location.longitude;
                                return position;
                            }
                        }

                    });
                }
            });
        }
        return position;
    }

    getTechnicianTrip(): void {
        if (!!this.tripId) {
            this.store$.dispatch(new StartLoading());
            this.tripService.getTrip(this.technicianId, this.tripId).subscribe(data => {
                this.store$.dispatch(new StopLoading());
                this.currentTechnicianTrip = data;
                const position = this.getCentreMpaPosition(this.currentTechnicianTrip.technicianTrips);
                this.refreshData(position);
                this.store$.dispatch(new StopLoading());
            }, error => {
                this.store$.dispatch(new StopLoading());
            });
        }
    }

    getCustomerName(id): string {
        const filteredIntervention = this.selectedInterventions.filter(item => item.id === id);
        return filteredIntervention.length > 0 ? filteredIntervention[0].customer.firstName +
            ' ' + (filteredIntervention[0].customer.lastName ? '' : '') : id;
    }


    ngOnDestroy(): void {
    }
    // TODO change the serviceUrl call from BOF to BOS and from BOS to localization-service
    onMapReady(map: Map): void {
        if (this.positions.length > 0) {
            // @ts-ignore
            const customRouter = leaflet.Routing.osrmv1({serviceUrl: environment.openstreetmap_routing_url , useHints : false ,
                requestParameters: {
                    'api-key':  environment.localizationApiKey
                },
            });

            // @ts-ignore
            const  control =      leaflet.Routing.control({
                router: customRouter,
                waypoints: this.positions,
                lineOptions: {
                    styles: [
                        {color: 'red', opacity: 4, weight: 4, luminosity: 'dark'}
                    ]
                },
                createMarker (i, wp): any {
                    return null;
                },
                show: false,
                collapsible: false,
                addWaypoints: false,
                routeWhileDragging: true,
                fitBounds: true,
                draggableWaypoints: false,
            }).addTo(map);
            control._container.style.display = 'None';
        }

    }
}
