import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Folder, IFolderUpdateRequest} from '../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FolderService} from '../../../../shared/services/folder.service';
import {CommentType} from '../../../../models/enums/CommentType.enum';
import {FolderSubjectService} from '../../folder-subject.service';
import {Comment} from '../../../../models/comment.model';
import {User} from '../../../../models/user.model';
import {MapService} from '../../../../shared/utils/map-service';

@Component({
    selector: 'app-folder-comments-modal',
    templateUrl: './folder-comments-modal.component.html',
    styleUrls: ['./folder-comments-modal.component.scss']
})
export class FolderCommentsModalComponent {
    folder: Folder;
    commentForm: FormGroup;
    mergedMaps: Map<CommentType | string, Comment[]> = new Map<CommentType | string, Comment[]>();

    constructor(
        public dialogRef: MatDialogRef<FolderCommentsModalComponent>,
        private  folderService: FolderService,
        private mapService: MapService,
        private folderSubjectService: FolderSubjectService,
        @Inject(MAT_DIALOG_DATA) public data) {
        this.folder = data.folder;
        this.mergeComments();
        this.initAddCommentForm();
    }

    initAddCommentForm(): void {
        this.commentForm = new FormGroup({
            description: new FormControl(null, Validators.required)
        });
    }

    editFolderDetails(): void {
        const comment = {
            content: this.commentForm.value.description,
            type: CommentType.COMMENT_GENERAL_AGENT
        };
        const folderUpdateRequest: IFolderUpdateRequest = {
            agentComment: comment
        };
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
            this.folder.agentComments = folder.agentComments;
            this.mergeComments();
            this.folderSubjectService.folderLoaded(this.folder);
            Object.keys(this.commentForm.controls).forEach(key => {
                this.commentForm.controls[key].setValue(null);
                this.commentForm.controls[key].setErrors(null);
            });
            this.commentForm.setErrors({'invalid': true});
        }).catch(reason => {
            console.log('ERROR Update Folder with id :' + this.folder.id);
        });
    }

    onClose(): void {
        this.dialogRef.close();
    }

    private mergeComments() {
        const repairerComment = this.mapService.objToMap(this.folder.repairerComments);
        const supplierComment = this.mapService.objToMap(this.folder.supplierComments);
        const clientComment = this.mapService.objToMap(this.folder.customerComments);
        const agentComment = this.mapService.objToMap(this.folder.agentComments);
        this.setFullName(clientComment, 'CLIENT');
        this.setFullName(repairerComment, 'REPAIRER');
        this.setFullName(supplierComment, 'SUPPLIER');
        this.mergedMaps = new Map((([...Array.from(clientComment.entries())].concat([...Array.from(agentComment.entries())])))
            .concat([...Array.from(repairerComment.entries())]).concat([...Array.from(supplierComment.entries())]));
    }

    private setFullName(clientComment: Map<any, any>, type: string) {
        if (clientComment.size > 0) {
            const user: User = {
                fullName: 'COMMENT.USER.' + type
            };

            for (const [key, comments] of clientComment.entries()) {
                comments.forEach(comment => {
                    comment.createdBy.user = user;
                });
            }
        }
    }
}
