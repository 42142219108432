import {initialUserState, UserState} from './user.state';
import {UserActions, UserActionType} from './user.actions';

const toJson = (value) => value ? JSON.parse(value) : '';

export function userReducer(state: UserState = initialUserState, action: UserActions): UserState {

    switch (action.type) {
        case UserActionType.USER_LOADED_SUCCESSFULLY:
            const user = action.userProfile;
            const attributes = user.attributes;
            const organizations = JSON.parse(attributes.organizations);
            const contexts = JSON.parse(attributes.context);
            return {
                ...state,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                username: user.username,
                attributes: {
                    organizations: organizations,
                    contexts: contexts,
                    businessLinks: toJson(attributes.businessLink)
                },
                affectedSite: attributes.site?.[0] ?? '',
                accessSites: JSON.parse(attributes.accessSites),
                isAuthenticated: true
            };

        case UserActionType.FIRST_LOAD:
            return {
                ...state,
                isFirstLoad: action.isFirst
            };

        case UserActionType.LOGOUT:
            return {
                ...state,
                isAuthenticated: false
            };

        case UserActionType.ROLES_LOADED_SUCCESSFULLY:
            return {
                ...state,
                roles: action.roles
            };

        case UserActionType.SCOPES_LOADED_SUCCESSFULLY:
            return {
                ...state,
                scopes: action.scopes
            };

        case UserActionType.SWITCH_ORGANIZATION:
            return {
                ...state,
                organizationCode: action.organization.organizationCode,
                organizationLabel: action.organization.label
            };

        case UserActionType.SWITCH_CONTEXT:
            return {
                ...state,
                context: action.context,
            };

        case UserActionType.SWITCH_BUSINESS_LINK:
            return {
                ...state,
                businessLink: action.businessLink,
            };

        case UserActionType.RESET_BUSINESS_LINK:
            return {
                ...state,
                businessLink: '',
                affectedBusinessLinks: [],
            };

        case UserActionType.UPDATE_BUSINESS_LINKS:
            return {
                ...state,
                businessLink: state.businessLink && state.businessLink !== '' ? state.businessLink : action.businessLinks?.[0].code,
                affectedBusinessLinks: action.businessLinks
            };

        default:
            return state;
    }
}
