import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileService} from '../../../../../../shared/services/file.service';
import {NgxImageCompressService} from 'ngx-image-compress';
import {MatDialog} from '@angular/material/dialog';
import {UploadFileDialogResponse} from '../../../../../../shared/upload-modal/upload.file.dialog.response';
import {ShowImageModalComponent} from '../../../../../image-modal/show-image-modal.component';
import {AttachmentTypeEnum} from '../../../../../../models/enums/attachmentType.enum';
import {Folder, IFolderUpdateRequest} from '../../../../../../models/folder.model';
import {Product} from '../../../../../../models/product.model';
import {UploadModalComponent} from '../../../../../../shared/upload-modal/upload-modal.component';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {of} from 'rxjs';

@Component({
    selector: 'app-product-photo-task',
    templateUrl: './product-photo-task.component.html',
    styleUrls: ['./product-photo-task.component.css']
})
export class ProductPhotoTaskComponent {
    attachmentsFiles = [];
    @Output() inputMap = new EventEmitter<any>();
    @Output() previewTask = new EventEmitter<any>();

    @Input() folder: Folder;
    @Input() product: Product;
    @Input() workflowStatus: string;
    @Input() variablesTask: any;

    constructor(private fileService: FileService,
                private imageCompress: NgxImageCompressService,
                private matDialog: MatDialog,
                private folderService: FolderService) {
    }

    onUploadImage(fileId?): void {
        const dialogRef = this.matDialog.open(UploadModalComponent, {
            disableClose: true,
            data: {
                acceptedFile: 'image/png,image/jpeg',
                hasDeleteOption: false,
                showPreviews: true
            }
        });
        dialogRef.afterClosed().subscribe(
            (data: UploadFileDialogResponse) => {
                if (!!data) {
                    if (!!fileId) {
                        this.removeFileFromAttachment(fileId);
                    }
                    this.onFileChange(data.files);
                }
            }
        );
    }

    showImageModal(srcImage): void {
        this.matDialog.open(ShowImageModalComponent, {
            height: 'auto',
            width: '100vh',
            minWidth: '800px',
            data: of([srcImage])
        });
    }

    removeFileFromAttachment(fileId): void {
        this.attachmentsFiles = this.attachmentsFiles.filter(attachment => attachment.id !== fileId);
    }

    private onFileChange(event): void {
        const reader = new FileReader();
        let fileEvent;
        fileEvent = event[0];
        if (event && event.length) {
            const [file] = event;
            reader.readAsDataURL(file);

            reader.onload = () => {
                if (file.type
                    .toLocaleString()
                    .toLowerCase()
                    .split('/')[0] === 'image') {
                    this.compressFile(reader.result, fileEvent);
                } else {
                    this.updateFile(reader.result.toString().split(',')[1], fileEvent);
                }
            };
            event = null;
        }
    }

    private updateFile(file, event): void {
        const imageBlob = this.dataURItoBlob(file);
        this.saveFile(imageBlob, event);
    }

    private saveFile(file, event): void {
        this.fileService.uploadAttachment(file, event, this.folder.organization.code, AttachmentTypeEnum.PRODUCT_IMAGE).subscribe(data => {
            if (data) {
                this.attachmentsFiles.push({
                    id: data.id,
                    name: data.name,
                    uploadDate: data.uploadDate,
                    src: data.url
                });
            }
        });
    }

    private dataURItoBlob(dataURI): any {
        const byteString = atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], {type: ''});
        return blob;
    }

    private compressFile(image, event): void {
        this.imageCompress.compressFile(image, 1, 100, 50).then(result => {
            this.updateFile(result.toString().split(',')[1], event);
        }).catch(err => {
            // this.dismissLoading();
        });
    }

    isEmptyAttachmentFiles(): boolean {
        return this.attachmentsFiles.length < 1 || !this.attachmentsFiles;
    }

    completeTask(): void {

        const folderUpdateRequest: IFolderUpdateRequest = {
            attachment: this.attachmentsFiles[0]
        };
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest);
        this.inputMap.emit({});
    }

}
