import {Component, Input, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {isAdminPlatana} from '../../../../../store/user/user.selectors';
import {Observable, Subscription} from 'rxjs';
import {Unsubscriber} from '../../../../../unsubscriber';

@Component({
    selector: 'supplier-confirm-dialog',
    templateUrl: './confirm-dialog-for-supplier.component.html'
})
export class SupplierConfirmDialogComponent extends Unsubscriber implements OnInit{

    isAdminPlatana$: Observable<boolean>;

    replayNotifications = true;
    replayReports = true;

    ngOnInit() {
        this.isAdminPlatana$ = this.store$.pipe(select(isAdminPlatana));

    }
    constructor(public dialogRef: MatDialogRef<SupplierConfirmDialogComponent>,
                private store$: Store<AppState>) {
        super();
    }

    closeDialog(isConfirmed: boolean) {
        this.dialogRef.close({
            isConfirmed,
            replayNotifications: this.replayNotifications,
            replayReports: this.replayReports
        });
    }
}
