import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Unsubscriber} from '../../../../../unsubscriber';
import {Comment} from '../../../../../models/comment.model';
import {User} from '../../../../../models/user.model';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-validate-refus-reparation-by-store',
    templateUrl: './validate-refus-reparation-by-store.component.html',
    styleUrls: ['./validate-refus-reparation-by-store.component.scss']
})
export class ValidateRefusReparationByStoreComponent extends Unsubscriber implements OnInit {
    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();

    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() folder: Folder;
    public form: FormGroup;
    placeholdersDecision = [];
    outOfWarranty: boolean;
    comments: Comment[] = [];
    currentLanguage$: Observable<string>;
    reason: string;

    constructor(private translateService: TranslateService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.initChoices();
        this.createForm();
        this.outOfWarranty = this.variablesTask['outOfWarranty'];

        if (!!this.outOfWarranty) {
            this.reason = this.translateService.instant('REFUS.REASON.DETAILS.' + this.outOfWarranty);
        }

        this.mergeComments();
    }

    private createForm(): void {
        this.form = new FormGroup({
            decision: new FormControl(null, [Validators.required]),
            comment: new FormControl(''),
        });
    }

    private initChoices() {
        this.placeholdersDecision = [
            {
                status: 'REFUS_ACCEPTED',
                value: 'COMPONENT.VALIDATE_THE_REFUSAL_OF_AGREEMENT',
            },
            {
                status: 'RESEND',
                value: 'COMPONENT.RESEND_THE_APPROVAL_REQUEST',
            }
        ];
    }

    private mergeComments() {
        const agentComment = !!this.folder.agentComments && this.folder.agentComments['REASON_OF_REFUS_REPARATION'].length > 0 ?  [...this.folder.agentComments['REASON_OF_REFUS_REPARATION']].pop() : null;
        const repairerComment = !!this.folder.repairerComments && this.folder.repairerComments['REASON_OF_REFUS_REPARATION_REPAIRER'].length > 0 ? [...this.folder.repairerComments['REASON_OF_REFUS_REPARATION_REPAIRER']].pop() : null;
        const supplierComment = !!this.folder.supplierComments && this.folder.supplierComments['REASON_OF_REFUS_REPARATION_SUPPLIER'].length > 0 ? [...this.folder.supplierComments['REASON_OF_REFUS_REPARATION_SUPPLIER']].pop() : null;

        if (!!agentComment) {
            this.comments.push(agentComment);
        }

        if (!!repairerComment) {
            repairerComment.createdBy.user = {
                fullName: 'COMMENT.USER.REPAIRER'
            };
            this.comments.push(repairerComment);
        }

        if (!!supplierComment) {
            supplierComment.createdBy.user = {
                fullName: 'COMMENT.USER.SUPPLIER'
            };
            this.comments.push(supplierComment);
        }
    }

    onSubmit(): void {
        if (!!this.form.value.comment && this.form.value.comment.length > 0) {
            this.commentGiven.emit({
                value: this.form.value.comment,
                type: 'REASON_OF_RESEND_REPARATION'
            });
        }
        this.inputMap.emit({
            storeAcceptationDecision: this.form.value.decision
        });
    }

    getStatusDate(): string {
        return this.folder.currentWorkflowStatus.workflowStatusDate;
    }

    private setFullName(clientComment: Map<any, any>, type: string) {
        if (clientComment.size > 0) {
            const user: User = {
                fullName: 'COMMENT.USER.' + type
            };

            for (const [key, comments] of clientComment.entries()) {
                comments.forEach(comment => {
                    comment.createdBy.user = user;
                });
            }
        }
    }
}
