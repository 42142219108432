<div fxLayout="row" fxLayout.lt-lg="row" fxLayout.lt-md="column" fxLayoutGap="8px" fusePerfectScrollbar>
    <div fxFlex="50">
        <app-public-detail-tabs-card [folder]="folder"></app-public-detail-tabs-card>
    </div>
    <div fxFlex="50">
        <mat-card class="mat-elevation-aster">
        <mat-card-content >
        <fieldset>
            <legend class="h3 title-fieldset">{{'SPARE_PARTS_ORDER_WAITING_FOR_VALIDATION' | translate}} </legend>
            <ng-container *ngIf="!!customer">
                <fieldset>
                    <legend class="h3 title-fieldset">{{'CUSTOMER.DETAILS' | translate}} </legend>
                    <ng-container *ngIf="!!customer.reference">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class="mb-4">
                            <mat-icon>local_offer</mat-icon>
                            <div>
                                {{ customer.reference }}
                            </div>
                        </div>
                    </ng-container>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class="mb-4">
                        <mat-icon>person_outline</mat-icon>
                        <div>
                            {{!!customer.gender ? ('CUSTOMER.CIVILITY.' + customer.gender|translate) : ''}}
                            {{!!customer.firstName ? customer.firstName : '-' }}
                            {{ !!customer.lastName ? customer.lastName : '-' }}
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
                        <mat-icon>local_phone</mat-icon>
                        <mat-icon class="mr-8"> public</mat-icon>
                        <div *ngIf="!!customer.contact && !!customer.contact.mobileNumber">
                            {{customer.contact.mobileNumber}}
                        </div>
                        <div *ngIf="!!customer.contact && !!customer.contact.phoneNumber">
                            {{customer.contact.phoneNumber}}
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
                        <mat-icon>email</mat-icon>
                        <div>
                            {{ !!customer.contact ? customer.contact.email : '-' }}
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="30px" class="mt-12">

                        <div>
                            <div><label class="ml-4 mt-12 text-bold"> {{"RECEIVER.ORDER.ADDRESS_INFO"| translate}}</label>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
                                <mat-icon>person_pin_circle</mat-icon>
                                <div>
                                    {{!!customerSparePartsShipmentAddress ?
                                    (!!customerSparePartsShipmentAddress.address ? customerSparePartsShipmentAddress.address  : '')
                                    : '-'}}
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
                                <mat-icon>person_pin_circle</mat-icon>
                                <div>
                                    {{!!customerSparePartsShipmentAddress ?
                                    !!customerSparePartsShipmentAddress.secondAddress ? customerSparePartsShipmentAddress.secondAddress : ''
                                    : '-'}}
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
                                <mat-icon>location_city</mat-icon>
                                <div>
                                    {{ !!customerSparePartsShipmentAddress ?
                                    (!!customerSparePartsShipmentAddress.zipCode ? customerSparePartsShipmentAddress.zipCode + "," : '') +
                                    (!!customerSparePartsShipmentAddress.city ? customerSparePartsShipmentAddress.city : '') + "-" +
                                    (!!customerSparePartsShipmentAddress.countryName ? customerSparePartsShipmentAddress.countryName : '')
                                    : '-'}}
                                </div>
                            </div>

                        </div>

                    </div>
                </fieldset>
            </ng-container>
            <table mat-table [dataSource]="reportLineDataTable" class="mat-elevation-z2 w-100-p mt-16 mb-16">
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.CODE' | translate}} </th>
                    <td mat-cell *matCellDef="let sparePart"> {{sparePart.code}} </td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.TYPE' | translate}} </th>
                    <td mat-cell
                        *matCellDef="let sparePart"> {{'MODAL.QUOTATION.TYPE.' + sparePart.type | translate}} </td>
                </ng-container>
                <ng-container matColumnDef="label">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.LABEL' | translate}} </th>
                    <td mat-cell *matCellDef="let sparePart"> {{sparePart.label}} </td>
                </ng-container>
                <ng-container matColumnDef="payer">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center">  {{'MODAL.QUOTATION.PAYER' | translate}} </th>
                    <td mat-cell
                        *matCellDef="let sparePart"> {{!!sparePart.payer ? ('RULE_WARRANTIES.CONFIGS.PAYERS_CONFIGS.' + sparePart.payer | translate) : "-"}} </td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center">  {{'MODAL.QUOTATION.QUANTITY' | translate}} </th>
                    <td mat-cell
                        *matCellDef="let sparePart">  {{sparePart.quantity}}  </td>
                </ng-container>

                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center">  {{'MODAL.QUOTATION.PRICE' | translate}} </th>
                    <td mat-cell
                        *matCellDef="let sparePart">  {{sparePart.priceAmount.value | currency: sparePart.priceAmount.currency :'symbol-narrow' | space}}  </td>
                </ng-container>

                <ng-container matColumnDef="tva">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center">  {{'MODAL.QUOTATION.TVA' | translate}} </th>
                    <td mat-cell
                        *matCellDef="let sparePart">  {{sparePart.vat.rate}}% </td>
                </ng-container>

                <ng-container matColumnDef="discount">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center">  {{'MODAL.QUOTATION.DISCOUNT' | translate}} </th>
                    <td mat-cell
                        *matCellDef="let sparePart">  {{sparePart.discount}}  </td>
                </ng-container>

                <ng-container matColumnDef="totalLinePrice">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center">  {{'MODAL.QUOTATION.TOTAL_PRICE' | translate}} </th>
                    <td mat-cell
                        *matCellDef="let sparePart">  {{sparePart.totalLinePrice?.value  |currency:sparePart.totalLinePrice.currency :'symbol-narrow' | space}}  </td>
                </ng-container>

                <ng-container matColumnDef="discountReason">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center">  {{'MODAL.QUOTATION.DISCOUNT_REASON' | translate}} </th>
                    <td mat-cell
                        *matCellDef="let sparePart">  {{sparePart.discountReason}}  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="reportLineColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: reportLineColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="12px">
                <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="30" fxFlex.lt-md="100">
                    <div fxLayout="column" class="zone-total">
                        <div fxLayoutAlign="space-between center">
                            <strong>{{'MODAL.QUOTATION.TOTAL_HT' | translate}} : </strong>
                            <div>{{getTotalPriceHT()  |currency: currency :'symbol-narrow' | space}}
                            </div>
                        </div>
                        <div fxLayoutAlign="space-between center">
                            <strong>{{'MODAL.QUOTATION.TOTAL_DISCOUNT' | translate}} : </strong>
                            <div>{{getTotalDiscount()  |currency: currency :'symbol-narrow' | space}}
                            </div>
                        </div>
                        <div fxLayoutAlign="space-between center">
                            <strong>{{'MODAL.QUOTATION.TOTAL_NET_HT' | translate}}  : </strong>
                            <div>{{getTotalNetHT()  |currency: currency :'symbol-narrow' | space}}
                            </div>
                        </div>
                        <div fxLayoutAlign="space-between center">
                            <strong>{{'MODAL.QUOTATION.TOTAL_TVA' | translate}}  : </strong>
                            <div>
                                {{getTotalPriceTVA()  |currency: currency :'symbol-narrow' | space}}
                            </div>
                        </div>
                        <div fxLayoutAlign="space-between center">
                            <strong> {{'MODAL.QUOTATION.TOTAL_TTC' | translate}} : </strong>
                            <div>{{computeTotalPrice |currency: currency :'symbol-narrow' | space}}
                            </div>
                        </div>
                    </div>

                </mat-card>
            </div>

        </fieldset>

        </mat-card-content>
        <mat-dialog-actions class="mt-24 mb-0" >
            <div class="w-100-p">
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="end end">
                    <div fxLayoutAlign="end center" class="mt-24">
                        <button type="submit" mat-stroked-button
                                class="aster_btn" (click)="onSubmit()">
                            {{'BUTTON.CONFIRM_ORDER' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </mat-dialog-actions>
    </mat-card>
    </div>
</div>
