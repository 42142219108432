import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../models/folder.model';
import {StartLoading} from '../../../../../store/loader/loader.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
  selector: 'app-manual-credit-agreement-request',
  templateUrl: './manual-credit-agreement-request.component.html',
  styleUrls: ['./manual-credit-agreement-request.component.scss']
})
export class ManualCreditAgreementRequestComponent implements OnInit {


    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();
    form: FormGroup;
  constructor(private store$: Store<AppState>) { }

  ngOnInit(): void {
      this.initForm();
  }

    private initForm(): void {
        this.form = new FormGroup({
            requestSubmitted: new FormControl(null, [Validators.required]),
            comment: new FormControl(null)
        });
    }

    private hasCommentValue(): boolean {
        return this.form.get('comment').value?.trim() !== '';
    }
    onSubmit(): void {
        this.store$.dispatch(new StartLoading());
        if (this.hasCommentValue()) {
            this.commentGiven.emit({
                    value: this.form.value.comment,
                }
            );
        }
        this.inputMap.emit({
            'requestSubmitted': this.form.value.requestSubmitted
        });
    }

}
