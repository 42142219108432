import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {RepairStatusType} from '../../../../models/enums/repairStatusType.enum';
import {FolderPublic} from '../../../../models/folder.public.model';
import {CommentAuthor} from '../../../../models/enums/commentAuthor.enum';
import {CommentType} from '../../../../models/enums/CommentType.enum';
import {InstructionUserTask} from '../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-diagnosis-in-progress',
    templateUrl: './diagnosis-in-progress.component.html',
    styleUrls: ['./diagnosis-in-progress.component.scss']
})
export class DiagnosisInProgressComponent implements OnInit, OnDestroy {

    @Input() folder: FolderPublic;
    @Input() instructionUserTask: InstructionUserTask;

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();

    destroy$ = new Subject<void>();

    form: FormGroup;

    warrantyCode: string;
    breakDownButtons: any[];

    warrantyButtons: any[];
    productButtons: any[];
    warrantyDisclaimerOptions: any[];

    constructor() {
    }

    ngOnInit(): void {
        this.initForm();
        this.initButtons();

        this.warrantyCode = this.folder.warranty.warranty;
        this.changeWarrantyValidator();
        this.changeDisclaimerReasonValidator();
    }

    private changeDisclaimerReasonValidator() {
        this.form.get('status').valueChanges.subscribe(status => {
            if (!!status) {
                const warrantyDisclaimerOption = this.form.get('warrantyDisclaimerOption');

                if (status === RepairStatusType.WARRANTY_DISCLAIMER) {
                    warrantyDisclaimerOption.setValidators(Validators.required);
                } else {
                    warrantyDisclaimerOption.clearValidators();
                    warrantyDisclaimerOption.setValue(null);
                }
                warrantyDisclaimerOption.updateValueAndValidity();
            }
        });
    }

    private changeWarrantyValidator() {
        this.form.get('breakDownStatus')
            .valueChanges
            .subscribe(breakDownStatus => {
                if (!!breakDownStatus) {
                    const productStatus = this.form.get('productStatus');
                    const status = this.form.get('status');

                    if (breakDownStatus === 'BREAKDOWN_CONFIRMED') {
                        productStatus.setValidators(Validators.required);
                        if (this.warrantyCode === 'SG') {
                            status.setValidators(Validators.required);
                        } else {
                            this.clearStatusValidator(status);
                        }
                    } else {
                        this.clearProductStatusValidator(productStatus);
                        this.clearStatusValidator(status);
                    }
                    status.updateValueAndValidity();
                    productStatus.updateValueAndValidity();
                }
            });
    }

    private clearProductStatusValidator(productStatus: AbstractControl) {
        productStatus.clearValidators();
        productStatus.setValue(null);
    }

    private clearStatusValidator(status: AbstractControl) {
        status.clearValidators();
        status.setValue(null);
    }

    private initForm(): void {
        this.form = new FormGroup({
            status: new FormControl(null),
            warrantyDisclaimerOption: new FormControl(null),
            breakDownStatus: new FormControl(null, Validators.required),
            productStatus: new FormControl(null),
            description: new FormControl(''),
            comment: new FormControl('')
        });
    }

    private initButtons(): void {
        this.breakDownButtons = [
            {
                status: 'BREAKDOWN_CONFIRMED',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.BREAKDOWN_CONFIRMED',
            },
            {
                status: 'NO_BREAKDOWN',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.NO_BREAKDOWN',
            }
        ];

        this.warrantyButtons = [
            {
                status: 'WARRANTY_ACCEPTED',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.WARRANTY_ACCEPTED'
            },
            {
                status: 'WARRANTY_DISCLAIMER',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.WARRANTY_DISCLAIMER'
            }
        ];

        this.productButtons = [
            {
                status: 'REPAIRABLE',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.REPAIRABLE'
            },
            {
                status: 'NOT_REPAIRABLE',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.NOT_REPAIRABLE'
            }
        ];

        this.warrantyDisclaimerOptions = [
            'USAGE_PROBLEM',
            'NOT_COVERED_BY_WARRANTY'
        ];
    }

    onSubmit(): void {
        if (this.form.value.comment.length > 0) {
            this.commentGiven.emit({
                value: this.form.value.comment,
                type: this.toCommentType(),
                from: CommentAuthor.REPAIRER
            });
        }

        this.inputMap.emit({
            'breakDownStatus': this.form.value.breakDownStatus,
            'reparationStatus': this.form.value.status,
            'productStatus': this.form.value.productStatus,
            'warrantyDisclaimerOption': this.form.controls['warrantyDisclaimerOption'].value?.key
        });
    }

    private toCommentType(): string  {
        if (this.form.value.productStatus === 'NOT_REPAIRABLE') {
            return CommentType.COMMENT_NOT_REPAIRABLE;
        } else if (this.form.value.productStatus === 'REPAIRABLE') {
            return CommentType.COMMENT_REPAIRABLE;
        } else if (this.form.value.status === 'WARRANTY_DISCLAIMER') {
            return CommentType.COMMENT_WARRANTY_DISCLAIMER;
        } else if (this.form.value.breakDownStatus === 'NO_BREAKDOWN') {
            return CommentType.COMMENT_NO_BREAKDOWN;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
