<ng-container *ngIf="!siteSearchWithoutModal;else siteSearch">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="font-weight-900">
            {{'MODAL.EDIT_MANAGEMENT_SITE' | translate}}
        </h2>
        <button mat-icon-button class="ml-40" (click)="onCloseModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</ng-container>
<ng-template #siteSearch>

    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutAlign="start">
            <mat-card-title class="product-title text-bold pr-24 pt-20 pl-10">
                {{'FOLDER.SITE.LOCATOR.TITLE' | translate}} {{fromSiteSearchzipCode}}
            </mat-card-title>
        </div>
    </div>

</ng-template>
<form *ngIf="managementSiteForm.form" [formGroup]="managementSiteForm.form" (ngSubmit)="onSubmit()" class="mt-25">

    <mat-dialog-content>
        <ng-container *ngIf="!siteSearchWithoutModal">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div>
                    <mat-icon class="icon-site">store_mall_directory</mat-icon>
                </div>
                <div class="card-references_title">
                    <div class="font-weight-600 site_name ml-4" *ngIf="data.site">
                        {{data.site.code}}
                        {{data.site.name}}
                    </div>
                </div>
            </div>
        </ng-container>
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline" class="w-100-p  mt-12">
                <mat-label>{{'MODAL.EDIT_MANAGEMENT_SITE.SEARCH_SITE' | translate}}</mat-label>
                <input matInput [placeholder]="'MODAL.EDIT_MANAGEMENT_SITE.CHOICE_OTHER_SITE'| translate"
                       formControlName="searchSite" (keyup)="initDataTable()" required>
                <button *ngIf="managementSiteForm.form.get('searchSite')" mat-icon-button matSuffix
                        (click)="searchSite()">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </div>


        <div class="mat-elevation-z4 w-100-p mt-12 mb-16">
            <table mat-table [dataSource]="dataSource" class="w-100-p">
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> {{'FOLDER.TABLE.LAST_NAME' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer">
                        {{element.code}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="label">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> {{'FOLDER.TABLE.LAST_NAME' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer">
                        {{element.name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> {{'BUTTON.ACTION' | translate}}</th>
                    <td mat-cell *matCellDef="let row; let i = index">
                        <mat-radio-button (click)="$event.stopPropagation(); showSelection(row)" id="{{'site-'+i}}"
                                          (change)="$event ? selection.toggle(row) : null"
                                          [checked]="selection.isSelected(row)"
                        ></mat-radio-button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20, 30]" showFirstLastButtons></mat-paginator>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions *ngIf="!siteSearchWithoutModal" fxLayoutAlign="end end">
            <div fxLayout="row" >
                <button mat-button [mat-dialog-close]="null" class="aster-modal-btn_cancel">
                    {{'BUTTON.CANCEL' | translate}}
                </button>
                <button mat-raised-button type="submit" [disabled]="!siteSelected"
                        [ngClass]="siteSelected? 'aster_btn' : 'aster_btn_disabled'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>

            </div>
    </mat-dialog-actions>

</form>