import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-go-to-store',
  templateUrl: './go-to-store.component.html',
  styleUrls: ['./go-to-store.component.css']
})
export class GoToStoreComponent implements OnInit {
    refundInStore: boolean;
    @Output() inputMap = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
    }

    onSubmit() {
        this.inputMap.emit({})
    }

}
