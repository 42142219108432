import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FolderPublic} from '../../../../models/folder.public.model';
import {PublicService} from '../../../../shared/services/public.service';
import {CommentType} from '../../../../models/enums/CommentType.enum';

export const AGREEMENT_NUMBER = 'agreementNumber';
export const CHOICE = 'choice';

@Component({
    selector: 'app-public-old-waiting-reparation-agreement',
    templateUrl: './public-old-waiting-reparation-agreement.component.html',
    styleUrls: ['./public-old-waiting-reparation-agreement.component.scss']
})
export class PublicOldWaitingReparationAgreementComponent implements OnInit {

    @Input() folder: FolderPublic;
    @Output() inputMap = new EventEmitter<any>();
    public reparationAgreementForm: FormGroup;

    constructor(private folderService: PublicService) {
        // empty
    }

    ngOnInit(): void {
        this.createForm();
    }

    onSubmit(): void {
        this.inputMap.emit({
            'confirmSwap': !this.reparationAgreementForm.value.choice, // refused => confirmSwap
            'reparationAgreementNumber': this.reparationAgreementForm.value.agreementNumber
        });
        if (this.readyToSent()) {
            this.folderService.updateFolderWithSupplierComment(this.folder.externalUid, this.reparationAgreementForm.value.supplierComment, CommentType.COMMENT_GENERAL_SUPPLIER)
                .subscribe(folder => {
                    this.reparationAgreementForm.controls['supplierComment'].setValue(null);
                    this.reparationAgreementForm.controls['supplierComment'].setErrors(null);
                });
        }
    }

    private createForm(): void {
        this.reparationAgreementForm = new FormGroup({
            choice: new FormControl(null, [Validators.required]),
            supplierComment: new FormControl('')
        });
    }

    onChoiceChange(value: boolean): void {
        this.reparationAgreementForm.get(CHOICE).setValue(value);
        if (value) {
            this.reparationAgreementForm.addControl(AGREEMENT_NUMBER, new FormControl(null, [Validators.required]));
        } else {
            this.reparationAgreementForm.removeControl(AGREEMENT_NUMBER);
        }
    }

    displayAgreementNumber(): boolean {
        return this.reparationAgreementForm.contains(AGREEMENT_NUMBER);
    }

    readyToSent(): boolean {
        return !!this.reparationAgreementForm.value.supplierComment;
    }
}
