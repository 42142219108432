import {FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {Directive, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../store/app.state';
import {isProfessional} from '../store/customer/customer.selectors';
import {Unsubscriber} from '../unsubscriber';

@Directive({
    selector: '[addCompanyForm]'
})
export class AddCompanyFormDirective extends Unsubscriber implements OnInit {

    constructor(private formGroupDirective: FormGroupDirective,
                private store$: Store<AppState> ) {
        super();
    }

    ngOnInit() {
        this.anotherSubscription = this.store$.pipe(select(isProfessional))
            .subscribe(isProfessional => {
                if (isProfessional) {
                    this.formGroupDirective.form.addControl('company',
                        new FormGroup({
                            label: new FormControl(null, Validators.required),
                            legalForm: new FormControl(null, Validators.required)
                        }));
                } else {
                    this.formGroupDirective.form.removeControl('company');
                }
            });
    }
}
