import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {DetailSupplierDialogComponent} from '../../../../main/folder/folder-detail-tabs-card/folder-details-card/detail-supplier-dialog/detail-supplier-dialog.component';
import {Constants} from '../../../../Constants';
import {debounceTime, distinctUntilChanged, filter, map, mergeMap, startWith, switchMap, tap} from 'rxjs/operators';
import {SupplierService} from '../../../services/supplier.service';
import {Supplier} from '../../../../models/supplier.model';
import {FormControl, Validators} from '@angular/forms';
import {MatOptionSelectionChange} from '@angular/material/core';
import {MapService} from '../../../utils/map-service';
import {currentUser} from '../../../../store/user/user.selectors';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {UserState} from '../../../../store/user/user.state';
import {Unsubscriber} from '../../../../unsubscriber';
import {ClaimSensibleHiddenField} from '../../../../models/claim-sensible/claim-sensible-hidden-field.model';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';

export const SUPPLIER_CODE = 'supplierCode';
export const SUPPLIER_LABEL = 'supplierLabel';

@Component({
    selector: 'app-product-supplier-field',
    templateUrl: './product-supplier-field.component.html',
    styleUrls: ['./product-supplier-field.component.scss']
})
export class ProductSupplierFieldComponent extends Unsubscriber implements OnInit {
    @Output() save = new EventEmitter();
    @Input() supplier: Supplier;
    @Input() isEditable;
    @Input() isSearch = false;
    @Input() disable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    UNKNOWN_SUPPLIER = Constants.UNKNOWN_SUPPLIER;

    currentUser: UserState;
    suppliers: Supplier[] = [];
    filteredSuppliers: Observable<Supplier[]>;
    supplierCtrl: FormControl;
    initialSupplier: Supplier;
    editMode: boolean;
    claimSensibleHiddenField = ClaimSensibleHiddenField;
    constructor(private supplierService: SupplierService,
                private dialog: MatDialog,
                private store$: Store<AppState>,
                private mapService: MapService) {
        super();
    }

    ngOnInit(): void {
        this.initSuppliersAutocomplete();
        this.init();
        this.initialSupplier = this.supplier;
        this.editMode = !(!this.isUnknownSupplier() || this.suppliers.length === 0);
        this.disableAndClearInput();
    }

    private disableAndClearInput() {
        this.disable$.subscribe(value => {
            this.supplierCtrl.patchValue(null);
            if (value) {
                this.supplierCtrl.disable();
            } else {
                this.supplierCtrl.enable();
            }
        });
    }

    private init(): void {
        this.anotherSubscription = this.store$.pipe(
            select(currentUser),
            tap(user => {
                this.currentUser = user;
            }),
            filter(() => this.canSearchSuppliers()),
            mergeMap(user => {
                return this.supplierService.search(0, 30, user.organizationCode, user.context, new Map<string, any>());
            })
        ).subscribe(suppliers => {
            this.suppliers = suppliers.content;
            this.handleSuppliersAutocompleteSearch(this.currentUser.organizationCode, this.currentUser.context);
        });
    }


    canSearchSuppliers() {
        return this.isSearch ? true : (this.isEditable ? true : this.isUnknownSupplier());
    }

    filter(searchValue: string, organizationCode: string, context: string): Observable<Supplier[]> {
        const filterValue = searchValue.toLowerCase();
        const filters: Map<string, any> = new Map<string, any>();
        if (!!filterValue) {
            filters.set(SUPPLIER_CODE, filterValue);
            filters.set(SUPPLIER_LABEL, filterValue);
        }
        return this.supplierService.search(0, 30, organizationCode, context, this.mapService.mapToObj(filters))
            .pipe(
                map(page => {
                    this.suppliers = page.content;
                    return page.content;
                })
            );
    }

    private initSuppliersAutocomplete(): void {
        this.filteredSuppliers = new Observable<Supplier[]>();
        if (this.isSearch){
            this.supplierCtrl = new FormControl(null);
        }else{
            this.supplierCtrl = new FormControl(null, [Validators.required]);
        }
       
    }

    private handleSuppliersAutocompleteSearch(organizationCode: string, context: string): void {
        this.filteredSuppliers = this.supplierCtrl.valueChanges
            .pipe(
                startWith(''),
                debounceTime(500),
                distinctUntilChanged(),
                switchMap(searchValue => this.filter(searchValue || '', organizationCode, context))
            );
    }

    isUnknownSupplier(): boolean {
        if (!this.isSearch) {
        return this.supplier.code === this.UNKNOWN_SUPPLIER;
        }
    }

    showSupplierDetail(): void {
        if (this.isUnknownSupplier()) {
            return;
        }

        this.store$.dispatch(new StartLoading());
        this.supplierService.getByCode(this.supplier.code, this.currentUser.organizationCode, this.currentUser.context)
            .subscribe(supplierDocument => {
                this.dialog.open(DetailSupplierDialogComponent, {
                    hasBackdrop: true,
                    disableClose: false,
                    autoFocus: false,
                    width: '600px',
                    data: {
                        supplier: supplierDocument
                    }
                });
                this.store$.dispatch(new StopLoading());
            });
    }

    onSelectSupplier($event: MatOptionSelectionChange): void {
        this.supplier = this.suppliers.find(x => x.code === $event.source.value);
        this.initialSupplier = this.supplier;
        this.save.emit(this.supplier);
        this.switchEditMode();
    }

    cancel(): void {
        this.supplier = this.initialSupplier;
        this.switchEditMode();
    }

    displayFn = (supplierCode: string) => {
        const supplier = this.suppliers.find(x => x.code === supplierCode);
        return supplier ? supplier.label : '';
    }

    switchEditMode(): void {
        this.editMode = !this.editMode;
    }
    supplierSelected($event: any) {
        if (!$event.target.value){
            this.save.emit(null);
        }
    }
}
