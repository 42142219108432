import {initialLoaderState, LoaderState} from './loader.state';
import {LoaderActions, LoaderActionType} from './loader.actions';

export function loaderReducer(state: LoaderState = initialLoaderState, action: LoaderActions): LoaderState {
    switch (action.type) {
        case LoaderActionType.STOP_LOADING:
            return {
                isLoading: false
            };

        case LoaderActionType.START_LOADING:
            return {
                isLoading: true
            };

        default:
            return state;
    }
}

