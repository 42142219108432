<app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
    <mat-card-content>

        <div fxLayout="column" fxLayoutAlign="start start" class="mt-25">

            <div fxLayout="column" class="w-100-p mt-8">
                <mat-form-field appearance="outline">
                    <mat-label>{{'FOLDER.WORKFLOW.REPAIRER_AND_CARRIER_TO_BE_CONFIRMED.REPAIRER' | translate }}</mat-label>
                    <mat-select [(ngModel)]="selectedRepairer"
                                [placeholder]="'FOLDER.WORKFLOW.REPAIRER_AND_CARRIER_TO_BE_CONFIRMED.REPAIRER' | translate">
                        <mat-option
                            *ngFor="let repairerItem of repairers "
                            (onSelectionChange)="setRepairer($event , repairerItem)"
                            [value]="repairerItem"><span>{{ repairerItem.code + ' - ' + repairerItem.label }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxLayout="column" class="w-100-p mt-10">
                <mat-form-field appearance="outline">
                    <mat-label>{{'FOLDER.WORKFLOW.REPAIRER_AND_CARRIER_TO_BE_CONFIRMED.CARRIER' | translate }}</mat-label>
                    <mat-select [(ngModel)]="selectedCarrier"
                                [placeholder]="'FOLDER.WORKFLOW.REPAIRER_AND_CARRIER_TO_BE_CONFIRMED.CARRIER' | translate">
                        <mat-option *ngFor="let carrierItem of carriers "
                                    (onSelectionChange)="setCarrier($event , carrierItem)"
                                    [value]="carrierItem">
                            <span>{{ carrierItem.code + ' - ' + carrierItem.label }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div fxLayoutAlign="end center">
            <button mat-stroked-button (click)="onSubmit()"
                    [disabled]="!selectedRepairer.code && !selectedCarrier.code"
                    [ngClass]="!!selectedRepairer.code && !!selectedCarrier.code?'aster_btn':'aster_btn_disabled'">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>
    </mat-card-content>