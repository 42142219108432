import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

/**This Pipe is wrapped from Fuel-UI library
 * link: https://stackoverflow.com/a/36574265
 * link: https://www.fueltravel.com/blog/migrating-from-angular-1-to-2-part-1-pipes/
 * link: http://fuelinteractive.github.io/fuel-ui/#/pipe/orderby
 */
@Pipe({name: 'moment', pure: false})
export class MomentPipe implements PipeTransform {

    transform(date: string, format: string, language?: string): string {
        return moment(date, moment.ISO_8601, language).format(format);
    }
}
