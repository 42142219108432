import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-check-spare-parts-before-reparation',
    templateUrl: './check-spare-parts-before-reparation.component.html',
    styleUrls: ['./check-spare-parts-before-reparation.component.css']
})
export class CheckSparePartsBeforeReparationComponent implements OnInit {
    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    submitInProgress = false;
    checkConformityForm: any = {
        form: null,
        placeholders: []
    };

    constructor() {
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.checkConformityForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
        });
        this.checkConformityForm.placeholders = [
            {
                status: 'START_REPARATION',
                value: 'BUTTON.START_REPARATION',
            },
            {
                status: 'NEED_SPARE_PARTS',
                value: 'BUTTON.NEED_SPARE_PARTS_ORDER',
            }
        ];
    }

    onSubmit() {
        this.submitInProgress = true;
        this.inputMap.emit({
            "needSpareParts": this.checkConformityForm.form.value.status === 'NEED_SPARE_PARTS',
        });
        this.submitInProgress = false;
    }
}
