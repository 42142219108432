
    <div fxLayout="row" fxLayout.lt-lg="row" fxLayout.lt-md="column" fxLayoutGap="8px" fusePerfectScrollbar>
        <div fxFlex="50">
            <app-public-detail-tabs-card [folder]="folder"></app-public-detail-tabs-card>
        </div>
        <div fxFlex="50">
            <mat-card class="mat-elevation-aster">
                <mat-card-title class="product-title text-bold pr-24 pt-20 pl-10">
                    {{'MODAL.QUOTATION.WAIT_ACCEPT_QUOTATION'|translate}}
                </mat-card-title>
                <mat-card-content>
                    <div *ngIf="!!quotationLines">
                        <table mat-table [dataSource]="quotationLines" class="mat-elevation-z2 w-100-p mt-16 mb-16">
                            <ng-container matColumnDef="code">
                                <th mat-header-cell *matHeaderCellDef
                                    class="table-header">  {{'MODAL.QUOTATION.CODE' | translate}}</th>
                                <td mat-cell *matCellDef="let quotationLine"> {{quotationLine.code}} </td>
                            </ng-container>
                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef
                                    class="table-header">  {{'MODAL.QUOTATION.TYPE' | translate}}</th>
                                <td mat-cell
                                    *matCellDef="let quotationLine"> {{'MODAL.QUOTATION.TYPE.' + quotationLine.type | translate}} </td>
                            </ng-container>
                            <ng-container matColumnDef="label">
                                <th mat-header-cell *matHeaderCellDef
                                    class="table-header">  {{'MODAL.QUOTATION.LABEL' | translate}}</th>
                                <td mat-cell *matCellDef="let quotationLine"> {{quotationLine.label}} </td>
                            </ng-container>
                            <ng-container matColumnDef="quantity">
                                <th mat-header-cell *matHeaderCellDef
                                    class="table-header">  {{'MODAL.QUOTATION.QUANTITY' | translate}}</th>
                                <td mat-cell *matCellDef="let quotationLine"> {{quotationLine.quantity}} </td>
                            </ng-container>
                            <ng-container matColumnDef="price">
                                <th mat-header-cell *matHeaderCellDef
                                    class="table-header">  {{'MODAL.QUOTATION.PRICE' | translate}}</th>
                                <td mat-cell *matCellDef="let quotationLine">
                                    {{normalize(quotationLine?.priceAmount?.value)   |currency:currency :'symbol-narrow' | space}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="vat">
                                <th mat-header-cell *matHeaderCellDef
                                    class="table-header">  {{'MODAL.QUOTATION.TVA' | translate}}</th>
                                <td mat-cell *matCellDef="let quotationLine"> {{quotationLine.vat.rate}}</td>
                            </ng-container>
                            <ng-container matColumnDef="discount">
                                <th mat-header-cell *matHeaderCellDef
                                    class="table-header">  {{'MODAL.QUOTATION.DISCOUNT' | translate}}</th>
                                <td mat-cell *matCellDef="let quotationLine"> {{quotationLine.discount}} </td>
                            </ng-container>
                            <ng-container matColumnDef="totalPrice">
                                <th mat-header-cell *matHeaderCellDef
                                    class="table-header"> {{'MODAL.QUOTATION.TOTAL_PRICE' | translate}}</th>
                                <td class="element" mat-cell *matCellDef="let quotationLine">
                                    {{normalize(quotationLine?.totalLinePrice?.value)   |currency:currency :'symbol-narrow' | space}}
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="12px">
                        <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="50" fxFlex.lt-md="100">
                            <table mat-table [dataSource]="attachmentQuotationDataTable" class="w-100-p mt-8">
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef> {{'FOLDER.FILE.TITLE' | translate}}</th>
                                    <td mat-cell *matCellDef="let element" class="text-left">
                                        <a (click)="downloadFile(element)" class="cursor-pointer">
                                            <p class="file_name">
                                                <mat-icon>file_copy</mat-icon>
                                                {{!!element.info ? element.info.name : element.name}}
                                            </p>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsAttachment"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsAttachment;"></tr>
                            </table>
                        </mat-card>

                        <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="30" fxFlex.lt-md="100">
                            <div fxLayout="column" class="zone-total">
                                <div fxLayoutAlign="space-between center">
                                    <strong>{{'MODAL.QUOTATION.TOTAL_HT' | translate}} : </strong>
                                    <div>{{normalize(totalPriceHT?.value)   |currency:currency :'symbol-narrow' | space}}
                                    </div>
                                </div>
                                <div fxLayoutAlign="space-between center">
                                    <strong>{{'MODAL.QUOTATION.TOTAL_DISCOUNT' | translate}} : </strong>
                                    <div>{{normalize(totalDiscount?.value )  |currency:currency :'symbol-narrow' | space}}
                                    </div>
                                </div>
                                <div fxLayoutAlign="space-between center">
                                    <strong>{{'MODAL.QUOTATION.TOTAL_NET_HT' | translate}}  : </strong>
                                    <div>{{normalize(totalPriceHT?.value)   |currency:currency :'symbol-narrow' | space}}
                                    </div>
                                </div>
                                <div fxLayoutAlign="space-between center">
                                    <strong>{{'MODAL.QUOTATION.TOTAL_TVA' | translate}}  : </strong>
                                    <div>
                                        {{normalize(totalVAT?.value )  |currency:currency :'symbol-narrow' | space}}
                                    </div>
                                </div>
                                <div fxLayoutAlign="space-between center">
                                    <strong> {{'MODAL.QUOTATION.TOTAL_TTC' | translate}} : </strong>
                                    <div>{{normalize(totalPriceTTC?.value) |currency:currency :'symbol-narrow' | space}}
                                    </div>
                                </div>
                            </div>

                        </mat-card>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
