<div *ngIf="folder">
    <ng-container *ngIf="folder?.publicQuotations?.length > 0">
        <h2>
            <mat-icon class="mt-20">assignment</mat-icon>
            {{'FOLDER.COMMENT.QUOTATION.LABEL' | translate}}
        </h2>
        <ng-container *ngFor="let quotation of folder.publicQuotations">
            <fieldset>
                <legend
                    class="h4 title-fieldset quotation-creation_date"
                    *ngIf="!!quotation.userAction && !!quotation.userAction.actionDate">
                    <ng-container *ngIf="!!quotation.target">{{getPublicQuotationTitle(quotation)}} : </ng-container> {{quotation.userAction.actionDate | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE.CREATION_DATE' | translate) : (currentLanguage$ | async)}}</legend>
                <div class="page-layout simple fullwidth mat-elevation-z4" >
                    <table mat-table [dataSource]="quotation.quotationLines" multiTemplateDataRows>
                        <ng-container matColumnDef="code">
                            <th mat-header-cell *matHeaderCellDef class="table-header">  {{'MODAL.QUOTATION.CODE' | translate}}</th>
                            <td mat-cell *matCellDef="let quotationLine">{{quotationLine.code}}</td>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef class="table-header">{{'MODAL.QUOTATION.TYPE' | translate}}</th>
                            <td mat-cell
                                *matCellDef="let quotationLine">{{'MODAL.QUOTATION.TYPE.' + quotationLine.type | translate}} </td>
                        </ng-container>
                        <ng-container matColumnDef="label">
                            <th mat-header-cell *matHeaderCellDef class="table-header">  {{'MODAL.QUOTATION.LABEL' | translate}}</th>
                            <td mat-cell *matCellDef="let quotationLine">{{quotationLine.label}}</td>
                        </ng-container>

                        <ng-container matColumnDef="totalPrice">
                            <th mat-header-cell *matHeaderCellDef class="table-header"> {{'FOLDER.QUOTATION.REPORT.TOTAL_PRICE.TTC' | translate}}</th>
                            <td mat-cell *matCellDef="let quotationLine">
                                {{quotationLine.totalLinePrice?.value| currency:quotationLine.totalLinePrice.currency :'symbol-narrow' | space}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                <div class="quotation-line-element-detail"
                                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                    <div class="w-100-p p-20">
                                        <div fxLayout="row" fxLayoutAlign="space-between">
                                            <div fxFlex="33">
                                        <span class="h4 text-bold">
                                            {{'MODAL.QUOTATION.QUANTITY' | translate}}
                                            : </span>
                                                {{element.quantity}}
                                            </div>
                                            <div fxFlex="33"><span
                                                class="h4 text-bold">{{'MODAL.QUOTATION.PRICE' | translate}}
                                                : </span>{{element.priceAmount.value|currency: element.priceAmount.currency :'symbol-narrow' | space}}</div>
                                            <div fxFlex="33"><span
                                                class="h4 text-bold">{{'MODAL.QUOTATION.TVA' | translate}}
                                                : </span>{{element.vat.rate}} %
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-between">
                                            <div fxFlex="33">
                                            <span class="h4 text-bold">{{'MODAL.QUOTATION.DISCOUNT' | translate}}
                                                : </span>
                                                {{element.discount}}%
                                            </div>
                                            <div fxFlex="67">
                                        <span class="h4 text-bold">{{'MODAL.QUOTATION.DISCOUNT_REASON' | translate}}
                                            : </span>
                                                {{element.discountReason}}
                                            </div>
                                        </div>
                                        <ng-container *ngIf="!!element.payer">
                                            <div fxLayout="row" fxLayoutAlign="space-between">
                                                <div fxFlex="60">
                                                <span class="h4 text-bold">{{'MODAL.QUOTATION.PAYER'| translate}}
                                                    : </span>
                                                    {{'MODAL.QUOTATION.PAYER.' + element.payer| translate}}
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let quotationLine; columns: displayedColumns;"
                            class="quotation-line-element-row cursor-pointer"
                            [class.example-expanded-row]="expandedElement === quotationLine"
                            (click)="expandedElement = expandedElement === quotationLine ? null : quotationLine">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

                    </table>

                </div>
                <div fxLayout="row" fxLayoutAlign="space-between start">
                    <div fxFlex="60" fxFlex.lt-md="100">
                        <mat-card class="mat-elevation-z2 mt-16 mb-16" >
                            <div fxLayout="column" >
                                <div fxLayoutAlign="space-between center">
                                    <strong> {{'QUOTATION.FEES_REFUSED_QUOTATION_WITHOUT_RESTITUTION' | translate}} : </strong>
                                    <div class="h3">{{quotation.feesRefusedQuotationWithoutRestitution?.value |currency:quotation.feesRefusedQuotationWithoutRestitution?.currency :'symbol-narrow' | space}}
                                    </div>
                                </div>
                                <div fxLayoutAlign="space-between center">
                                    <strong> {{'QUOTATION.FEES_REFUSED_QUOTATION_WITH_RESTITUTION' | translate}} : </strong>
                                    <div class="h3">{{quotation.feesRefusedQuotationWithRestitution?.value |currency:quotation.feesRefusedQuotationWithRestitution?.currency :'symbol-narrow' | space}}
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                    <div fxFlex="30" fxFlex.lt-md="100">
                        <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex.lt-md="100">
                            <div fxLayout="column" class="zone-total">
                                <div fxLayoutAlign="space-between center">
                                    <strong> {{'MODAL.QUOTATION.TOTAL_TTC' | translate}} : </strong>
                                    <div class="h3">{{quotation.totalPrice.value |currency:quotation.totalPrice.currency :'symbol-narrow' | space}}
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </fieldset>
        </ng-container>
    </ng-container>
</div>