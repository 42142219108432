import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {GridComponent, GroupSettingsModel, PageSettingsModel} from '@syncfusion/ej2-angular-grids';
import {AnimationSettingsModel, ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {BackOfficeService} from '../../../../shared/services/back-office.service';
import {InvoiceItem} from '../../../../models/invoice.model';
import {RowSelectEventArgs} from '@syncfusion/ej2-grids';
import * as moment from 'moment';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FolderCreationService} from '../folder-creation.service';
import {Unsubscriber} from '../../../../unsubscriber';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {currentUser} from '../../../../store/user/user.selectors';
import {switchMap} from 'rxjs/operators';
import {UserState} from '../../../../store/user/user.state';
import {GrowthbookAttributes} from '../../../../shared/features/growthbook-attributes';
import {AppFeatures} from '../../../../shared/features/app-features';
import {GrowthbookService} from '../../../../shared/services/growthbook.service';
import {AppState} from '../../../../store/app.state';
import {SnackBarService} from '../../../../shared/services/snack-bar.service';

export const INVOICE_NUMBER = 'invoiceNumber';
export const DATE_FORMAT = 'YYYY-MM-DD';

@Component({
    selector: 'app-invoices-items-list',
    templateUrl: './invoices-items-list.component.html',
    styleUrls: ['./invoices-items-list.component.scss'],
})
export class InvoicesItemsListComponent extends Unsubscriber implements OnInit {

    public invoicesItems: InvoiceItem[];
    public groupOptions: GroupSettingsModel;
    public pageSettings: PageSettingsModel;
    public toolbar: string[];
    public refresh: Boolean;
    @ViewChild('grid')
    public grid: GridComponent;
    @ViewChild('alertDialog')
    public alertDialog: DialogComponent;
    public hidden: Boolean = false;
    public alertWidth = '200px';
    public alertHeader = 'COMPONENT.CUSTOMER_INVOICES_ITEMS.GROUPING';
    public alertContent = 'COMPONENT.CUSTOMER_INVOICES_ITEMS.GROUPING_IS_NOT_ALLOWED';
    public animationSettings: AnimationSettingsModel = {effect: 'Zoom'};

    loading: boolean;
    public years: number[] = [];
    public currentYear: number;

    alertDlgButtons: ButtonPropsModel[];
    customerReference: string;
    showYearsFeature: Observable<boolean>;

    public alertDlgBtnClick = () => {
        this.alertDialog.hide();
    }


    constructor(private backOfficeService: BackOfficeService,
                private folderCreationService: FolderCreationService,
                private dialogRef: MatDialogRef<InvoicesItemsListComponent>,
                private growthBookService: GrowthbookService,
                private store$: Store<AppState>,
                private snackBar: SnackBarService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        super();
        this.currentYear = moment().year();
        this.customerReference = this.data.customerReference;
    }

    ngOnInit(): void {
        this.getData(null, null);
        this.fetchFeatureFlags();
        this.populateLastSevenYears();
        this.alertDlgButtons = [{click: this.alertDlgBtnClick.bind(this), buttonModel: {content: 'OK', isPrimary: true}}];
        this.groupOptions = {showGroupedColumn: false, columns: [INVOICE_NUMBER]};
        this.pageSettings = {pageCount: 10};
        this.toolbar = ['Search'];
    }

    populateLastSevenYears() {
        for (let i = 0; i < 7; i++) {
            const year = this.currentYear - i;
            this.years.push(year);
        }
    }

    dataBound() {
        if (this.refresh) {
            this.grid.groupColumn(INVOICE_NUMBER);
            this.refresh = false;
        }
    }

    load() {
        this.refresh = (<any>this.grid).refreshing;
    }

    created() {
        this.grid.on('columnDragStart', this.columnDragStart, this);
    }

    public columnDragStart(args: any) {
        if (args.column.field !== INVOICE_NUMBER) {
            this.alertDialog.show();
        }
    }

    onSelect($event: RowSelectEventArgs) {
        const invoiceItem = $event.data as InvoiceItem;

        // populate the invoice form
        this.folderCreationService.invoiceChanged({
            id: invoiceItem.invoiceNumber,
            siteCode: invoiceItem.site.siteCode,
            creationDate: invoiceItem.purchaseDate
        });

        // populate the product form to fire the search
        this.folderCreationService.invoiceItemChanged(invoiceItem);

        // notify the create-folder component of the invoice item line number to store it when creating a folder
        this.folderCreationService.lineNumberSelected(invoiceItem.lineNumber);

        this.dialogRef.close();
    }

    onSelectYear($event: any) {
        const selectedYear = $event.itemData.value;
        const beginDate = moment({year: selectedYear, month: 0, day: 1}).format(DATE_FORMAT); // Year-01-01
        const endDate = moment({year: selectedYear, month: 11, day: 31}).format(DATE_FORMAT); // Year-12-31
        this.getData(beginDate, endDate);
    }

    private fetchFeatureFlags() {
        this.anotherSubscription = this.showYearsFeature = this.store$.pipe(select(currentUser))
            .pipe(
                switchMap((user: UserState) => {
                    const attributes: GrowthbookAttributes = {
                        organizationCode: user.organizationCode,
                        context: user.context,
                    };
                    return this.growthBookService.isOn(AppFeatures.SEARCH_CUSTOMER_INVOICES_YEARS_DROPDOWN_LIST, attributes);
                }));
    }

    private getData(beginDate: string, endDate: string) {
        this.loading = true;
        this.anotherSubscription = this.backOfficeService.searchInvoiceByClientReference(this.customerReference.trim(), beginDate, endDate)
            .subscribe((data: InvoiceItem[]) => {
                if (!data || data.length === 0) {
                    this.snackBar.openAtStart('Info', 'FOLDER.CREATE.NO_INVOICES');
                } else {
                    this.invoicesItems = data;
                }
                this.loading = false;
            }, () => {
                this.loading = false;
            });
    }
}
