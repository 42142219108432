import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TypeOfBenefit} from '../../models/TypeOfBenefit.model';
import {GLOBAL} from '../../app-config';

@Injectable({
    providedIn: 'root'
})
export class TypeOfBenefitService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api/configs/type-of-benefit`;

    constructor(private httpClient: HttpClient) {
    }

    public all(): Observable<TypeOfBenefit[]> {
        return this.httpClient.get<TypeOfBenefit[]>(`${this.path}/all`);
    }
}
