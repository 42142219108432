import {AddressType} from '../enums/addressType.enum';
import {Gender} from '../enums/gender.enum';
import {CustomerType} from '../enums/CustomerType.enum';
import {Contact} from '../contact.model';
import {Accessibility} from '../accessibility.model';

export class Customer {
    reference: string;
    loyaltyCardNumber: string;
    type: CustomerType;
    communication: ICommunication;
    gender: Gender;
    firstName: string;
    lastName: string;
    preferredLanguage: string;
    company?: Company;
    contact: Contact;
    address: Address;
    addresses: Address[];

    constructor() {
        this.contact = new Contact();
    }
}

export class Address {
    countryName?: string;
    countryCode?: string;
    city?: string;
    zipCode?: string;
    address?: string;
    secondAddress?: string;
    fullAddress?: string;
    type?: AddressType;
    outSideInformation?: string;
    location?: Location;
    accessibility?: Accessibility;
}

export class MobilePhoneInput {
    mobileNumber?: string;
    countryCode?: string;
}
export class LandLinePhoneInput {
    phoneNumber?: string;
    countryCode?: string;
}

export class Location {
    lon?: string;
    lat?: string;
    distance?: string;
}

interface ICommunication {
    phoneNumber: string;
    mobileNumber: string;
    email: string;
}

export class Company { // TODO change label and type->string
    label: string;
    legalForm: string;
}
