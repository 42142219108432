<div fxLayout="row" fxLayout.lt-lg="row" fxLayout.lt-md="column" fxLayoutGap="8px" fusePerfectScrollbar>
    <div fxFlex="50">
        <app-public-detail-tabs-card [folder]="folder"></app-public-detail-tabs-card>
    </div>
    <div fxFlex="50">
        <mat-card class="mat-elevation-aster">

        <mat-card-content>
            <div fxLayout="column" fxLayoutAlign="center center">
                <div>
                    <mat-icon class="timer-icon">timer</mat-icon>
                </div>
                <div>
                    <div class="h2 ml-8  pt-4">
                        {{'FOLDER.PARCEL_TO_SEND.TITLE' | translate}}
                    </div>
                </div>
            </div>
            <div>
                <mat-checkbox [(ngModel)]="shipmentInProgress">{{'BUTTON.SHIPMENT_SENT' | translate}}</mat-checkbox>
            </div>
            <div class="mt-24">
                <div fxLayout="row" fxLayoutAlign="end">
                    <button mat-stroked-button (click)="onSubmit()"
                            type="submit"
                            [disabled]="!shipmentInProgress"
                            [ngClass]="!shipmentInProgress?'aster_btn_disabled':'aster_btn'">
                        {{'BUTTON.SHIPMENT_SENT'| translate}}
                    </button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    </div>
</div>