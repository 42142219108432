import {FolderIndex} from './folder.index.model';

export class FolderSearchResponse {
    content?: FolderIndex[];
    totalElements?: number;
    totalPages?: number;
    first?: boolean;
    last?: boolean;
    size?: number;
    number = 0;
    numberOfElements?: number;
}

export class Filter {
    operator?: FilterOperator;
    value?: any;
    constructor(value: any, filterOperator = FilterOperator.EQUAL) {
        this.value = value;
        this.operator = filterOperator;
    }
}

export enum FilterOperator {

    EQUAL = 'EQUAL', NOT_EQUAL = 'NOT_EQUAL', IN = 'IN', NOT_IN = 'NOT_IN', LET = 'LET', LT = 'LT', GET = 'GET', GT = 'GT', BETWEEN = 'BETWEEN', START_WITH = 'START_WITH'
}

export class DashboardFolderFilters {
    filters: any;
}

export class Range {
    from: number;
    to: number;
}
