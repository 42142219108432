<mat-form-field appearance="outline" id="warrantyReason" fxFlex style="font-size: 14px" (click)="loadWarrantyReasons()">
    <mat-label>{{'FOLDER.TABLE.FILTER.WARRANTY_REASON' | translate}}</mat-label>
    <mat-select
        [ngModel]="selectedWarrantyReasons"
        (ngModelChange)="updateSelectedWarrantyReasons($event)"
        multiple
        #warrantyReasonSelect>
        <mat-select-trigger *ngIf="selectedWarrantyReasons">
            <ng-container *ngFor="let warrantyReason of selectedWarrantyReasons">
                {{labelFromCode(warrantyReason)}},
            </ng-container>
        </mat-select-trigger>
        <mat-option>
            <ngx-mat-select-search [(ngModel)]="warrantyReasonSearchField"
                                   (ngModelChange)="onWarrantyReasonSearch($event)"
                                   [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                   [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate">
            </ngx-mat-select-search>
        </mat-option>
        <div fxLayout="row" fxLayoutAlign="center center" class="mt-12 mb-12"
             fxLayoutGap="12px">
            <button mat-raised-button class="mat-primary fill text-sm"
                    (click)="selectAllWarrantyReason(warrantyReasonSelect)">
                {{'FOLDER.TABLE.FILTER.SELECT_ALL_STATUS' | translate}}
            </button>
            <button mat-raised-button class="mat-accent fill text-sm"
                    (click)="deselectAllWarrantyReason(warrantyReasonSelect)">
                {{'FOLDER.TABLE.FILTER.DESELECT_SELECT_ALL' | translate}}
            </button>
        </div>
        <mat-option
            *ngFor="let warrantyReason of filteredWarrantyReasons | async"
            [value]="warrantyReason.code">
            {{warrantyReason.label}}
        </mat-option>
    </mat-select>
</mat-form-field>