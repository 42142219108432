import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../models/folder.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FolderSubjectService} from '../../../folder-subject.service';
import {RepairStatusType} from '../../../../../models/enums/repairStatusType.enum';
import {CommentType} from '../../../../../models/enums/CommentType.enum';
import * as moment from 'moment';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-diagnostic-in-progress',
    templateUrl: './diagnostic-in-progress.component.html',
    styleUrls: ['./diagnostic-in-progress.component.scss']
})
export class DiagnosticInProgressComponent implements OnInit {
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();

    form: FormGroup;
    warrantyCode: string;
    breakDownButtons: any[];
    diagnosticButtons: any[];
    minDate = new Date();

    warrantyButtons: any[];
    productButtons: any[];
    warrantyDisclaimerOptions: any[];

    folder: Folder;

    destroy$ = new Subject<void>();
    hasAgenda = true;
    showCalender = false;

    diagnosticStatus: string;
    warrantyDisclaimer = false;


    constructor(private folderSubjectService: FolderSubjectService) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initButtons();
        this.warrantyCode = this.variablesTask['warrantyCode'];
        this.hasAgenda = this.variablesTask['hasAgenda'];
        this.changeWarrantyValidator();
        this.changeDisclaimerReasonValidator();
        this.changeConfirmedDateValidator();

        this.folderSubjectService.folder$
            .pipe(takeUntil((this.destroy$)))
            .subscribe(folder => this.folder = folder);
    }

    private initForm(): void {
        this.form = new FormGroup({
            warrantyStatus: new FormControl(null),
            confirmedAppointment: new FormControl(null),
            warrantyDisclaimerOption: new FormControl(null),
            breakDownStatus: new FormControl(null),
            status: new FormControl(null, Validators.required),
            productStatus: new FormControl(null),
            comment: new FormControl(''),
        });
    }

    private initButtons(): void {
        this.diagnosticButtons = [
            {
                value: 'PERFORMED',
            },
            {
                value: 'FIX_APPOINTMENT',
            },
            {
                value: 'REPAIR_DECISION_CANCEL',
            }
        ];

        this.breakDownButtons = [
            {
                status: 'BREAKDOWN_CONFIRMED',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.BREAKDOWN_CONFIRMED',
            },
            {
                status: 'NOTHING',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.NOTHING',
            }
        ];

        this.warrantyButtons = [
            {
                status: 'WARRANTY_ACCEPTED',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.WARRANTY_ACCEPTED'
            },
            {
                status: 'WARRANTY_DISCLAIMER',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.WARRANTY_DISCLAIMER'
            }
        ];

        this.productButtons = [
            {
                status: 'TO_REPAIR',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.REPAIRABLE'
            },
            {
                status: 'PRODUCT_NOT_REPAIRABLE',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.NOT_REPAIRABLE'
            }
        ];

        this.warrantyDisclaimerOptions = [
            'USAGE_PROBLEM',
            'NOT_COVERED_BY_WARRANTY'
        ];
    }

    private changeConfirmedDateValidator() {
        this.form.get('status')
            .valueChanges
            .subscribe(value => {
                if (!!value) {
                    const confirmedAppointment = this.form.get('confirmedAppointment');
                    const breakDownStatus = this.form.get('breakDownStatus');
                    if (value === 'FIX_APPOINTMENT') {
                        this.resetForm();
                        if (!this.hasAgenda) {
                            this.showCalender = true;
                            confirmedAppointment.setValidators(Validators.required);
                        }
                        this.diagnosticStatus = 'FIX_APPOINTMENT';
                    } else if (value === 'REPAIR_DECISION_CANCEL') {
                        this.resetForm();
                        this.diagnosticStatus = 'REPAIR_DECISION_CANCEL';
                    } else {
                        breakDownStatus.setValidators(Validators.required);
                        this.clearValidator(confirmedAppointment);
                    }
                    breakDownStatus.updateValueAndValidity();
                    confirmedAppointment.updateValueAndValidity();
                }
            });
    }

    private changeWarrantyValidator() {
        this.form.get('breakDownStatus')
            .valueChanges
            .subscribe(breakDownStatus => {
                if (!!breakDownStatus) {
                    const productStatus = this.form.get('productStatus');
                    const warrantyStatus = this.form.get('warrantyStatus');
                    this.warrantyDisclaimer = false;
                    if (breakDownStatus === 'BREAKDOWN_CONFIRMED') {
                        productStatus.setValidators(Validators.required);
                        if (this.warrantyCode === 'SG') {
                            warrantyStatus.setValidators(Validators.required);
                        } else {
                            this.clearValidator(warrantyStatus);
                        }
                    } else {
                        if (warrantyStatus.value === 'WARRANTY_DISCLAIMER') {
                            const warrantyDisclaimerOption = this.form.get('warrantyDisclaimerOption');
                            this.clearValidator(warrantyDisclaimerOption);
                            warrantyDisclaimerOption.updateValueAndValidity();
                        }
                        this.diagnosticStatus = 'DO_NOTHING';
                        this.clearValidator(productStatus);
                        this.clearValidator(warrantyStatus);
                    }
                    warrantyStatus.updateValueAndValidity();
                    productStatus.updateValueAndValidity();
                }
            });
    }

    private clearValidator(ValidatorValue: AbstractControl) {
        ValidatorValue.clearValidators();
        ValidatorValue.setValue(null);
    }

    private changeDisclaimerReasonValidator() {
        this.form.get('warrantyStatus')
            .valueChanges
            .subscribe(status => {
                if (!!status) {
                    const warrantyDisclaimerOption = this.form.get('warrantyDisclaimerOption');

                    if (status === RepairStatusType.WARRANTY_DISCLAIMER) {
                        warrantyDisclaimerOption.setValidators(Validators.required);
                        this.warrantyDisclaimer = true;
                        if (!!this.form.get('productStatus').value && this.form.get('productStatus').value === 'TO_REPAIR') {
                            this.diagnosticStatus = 'REPAIR_ON_OUT_OF_WARRANTY';
                        }
                    } else {
                        if (!!this.form.get('productStatus').value && this.form.get('productStatus').value === 'TO_REPAIR') {
                            this.diagnosticStatus = 'TO_REPAIR';
                        }
                        this.warrantyDisclaimer = false;
                        this.clearValidator(warrantyDisclaimerOption);
                    }
                    warrantyDisclaimerOption.updateValueAndValidity();
                }
            });
    }


    onSubmit(): void {
        if (this.form.value.comment.length > 0) {
            this.commentGiven.emit({
                value: this.form.value.comment,
                type: this.toCommentType(),
            });
        }

        const date = moment(this.form.value.confirmedAppointment).format('YYYY-MM-DD');
        const confirmedAppointment = {
            day: date,
            beginHour: '00:00:00',
            endHour: '23:59:59'
        };
        this.inputMap.emit({
            'diagnosticPerformed': this.form.value.status === 'PERFORMED',
            'confirmedDate': JSON.stringify(confirmedAppointment),
            'diagnosticStatus': this.diagnosticStatus,
            'warrantyDisclaimerOption': this.form.controls['warrantyDisclaimerOption'].value,
            'warrantyDisclaimer': this.warrantyDisclaimer
        });
    }

    getTaskDuration(): string {
        return this.variablesTask['diagnosisMaxDuration'];
    }

    getStatusDate(): string {
        return this.folder.currentWorkflowStatus.workflowStatusDate;
    }

    toCommentType(): string {
        if (this.diagnosticStatus === 'PRODUCT_NOT_REPAIRABLE') {
            return CommentType.COMMENT_NOT_REPAIRABLE;
        } else if (this.diagnosticStatus === 'TO_REPAIR') {
            return CommentType.COMMENT_REPAIRABLE;
        } else if (this.diagnosticStatus === 'REPAIR_ON_OUT_OF_WARRANTY') {
            return CommentType.COMMENT_WARRANTY_DISCLAIMER;
        } else if (this.diagnosticStatus === 'DO_NOTHING') {
            return CommentType.COMMENT_NO_BREAKDOWN;
        } else if (this.diagnosticStatus === 'REPAIR_DECISION_CANCEL'){
            return CommentType.COMMENT_CANCEL_INTERVENTION;
        }
    }

    updateDiagnoticStatus(value: any) {
        if (value === 'TO_REPAIR') {
            if (this.form.get('warrantyStatus').value === 'WARRANTY_DISCLAIMER') {
                this.diagnosticStatus = 'REPAIR_ON_OUT_OF_WARRANTY';
            } else if (this.form.get('warrantyStatus').value === 'WARRANTY_ACCEPTED' || this.warrantyCode === 'HG') {
                this.diagnosticStatus = 'TO_REPAIR';
            }
        } else {
            this.diagnosticStatus = value;
        }
    }

    private resetForm() {
        const breakDownStatus = this.form.get('breakDownStatus');
        const productStatus = this.form.get('productStatus');
        const warrantyStatus = this.form.get('warrantyStatus');
        const warrantyDisclaimerOption = this.form.get('warrantyDisclaimerOption');
        this.clearValidator(breakDownStatus);
        this.clearValidator(warrantyStatus);
        this.clearValidator(productStatus);
        this.clearValidator(warrantyDisclaimerOption);
        warrantyStatus.updateValueAndValidity();
        productStatus.updateValueAndValidity();
        warrantyDisclaimerOption.updateValueAndValidity();
    }
}
