import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SparePartModel} from '../../../models/visio/spare-part.model';
import {Vat} from '../../../models/vat.model';
import {select, Store} from '@ngrx/store';
import {vats} from '../../../store/organization/organization.selectors';
import {AppState} from '../../../store/app.state';
import {SparePartCriteria} from '../../../models/spare-parts/spare-part.model';
import {SparePartService} from '../../services/spare-part.service';

@Component({
    selector: 'app-spare-parts-order-modal',
    templateUrl: './spare-parts-order-modal.component.html',
    styleUrls: ['./spare-parts-order-modal.component.css']
})
export class SparePartsOrderModalComponent implements OnInit, OnDestroy {
    protected _onDestroy = new Subject<void>();

    vatsList$: Observable<Vat[]>;
    reportLineParts = [];
    sparePartLineColumns: string[] = ['reference', 'label', 'quantity', 'price', 'tva', 'totalLinePrice', 'action'];
    isLoading = false;

    dataSourceSparePartLine = [];
    sparePartDataTable = new MatTableDataSource<any>();

    sparePartLineForm: any = {
        form: null
    };
    organizationCode: string;
    context: string;
    currency: string;

    constructor(private dialogRef: MatDialogRef<SparePartsOrderModalComponent>,
                private sparePartService: SparePartService,
                private store$: Store<AppState>,
                @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.organizationCode = data.organizationCode;
        this.context = data.context;
        this.currency = data.currency;
    }

    ngOnInit(): void {
        this.vatsList$ = this.store$.pipe(select(vats));
        this.initSparePartLineForm();
        if (this.data) {
            this.sparePartDataTable.data = this.data.sparePartLine;
            this.dataSourceSparePartLine = this.data.sparePartLine;
        }
    }

    initSparePartLineForm(): void {
        this.sparePartLineForm.form = new FormGroup({
            reference: new FormControl(null, Validators.required),
            label: new FormControl(null, Validators.required),
            quantity: new FormControl(1, Validators.required),
            price: new FormControl(null, Validators.required),
            tva: new FormControl(20, Validators.required),
            totalLinePrice: new FormControl(0)
        });
    }
    addIntoSparePartList(): void {
        this.dataSourceSparePartLine.push(this.sparePartLineForm.form.value);
        this.reportLineParts = [];
        this.refreshDataTableSparePart();
        this.refreshDataForm(this.sparePartLineForm.form);
    }

    refreshDataForm(form: FormGroup): void {
        Object.keys(form.controls).forEach(key => {
            if (key !== 'quantity') {
                form.controls[key].setValue(null);
            }
            form.controls[key].setErrors(null);
        });
        form.setErrors({'invalid': true});
    }

    refreshDataTableSparePart(): void {
        this.sparePartDataTable.data = [];
        this.sparePartDataTable = new MatTableDataSource<any>(this.dataSourceSparePartLine);
    }

    changeCode(): void {
        const inputCode = this.sparePartLineForm.form.get('reference').value;
        if (inputCode && inputCode.length > 0) {
            const inputs: SparePartCriteria = {
                code: inputCode
            };
            this.bulkSearchSpareParts(inputs);
        }
    }

    changeLabel(): void {
        const inputLabel = this.sparePartLineForm.form.get('label').value;
        if (inputLabel && inputLabel.length > 0) {
            const inputs: SparePartCriteria = {
                code: inputLabel
            };
            this.bulkSearchSpareParts(inputs);
        }
    }

    bulkSearchSpareParts(inputs): void {
        this.isLoading = true;
        this.sparePartService.search(0, 50, inputs, 'ANY')
            .subscribe(data => {
                this.reportLineParts = data.content;
                this.isLoading = false;
            }, err => {
                console.log('ERROR' + err);
            });
    }

    sparePartChanged(event): void {
        this.sparePartLineForm.form.get('reference').setValue(event.option.value.code);
        this.sparePartLineForm.form.get('label').setValue(event.option.value.label);
        this.sparePartLineForm.form.get('price').setValue(event.option.value.price);
        this.calculationReportLine();
    }

    calculationReportLine(): void {
        const totalLinePrice = this.getTotalLinePrice(this.sparePartLineForm.form.value);
        this.sparePartLineForm.form.get('totalLinePrice').setValue(totalLinePrice);
    }

    getTotalLinePrice(sparePart: SparePartModel): number {
        const productPrice = sparePart.price;
        const quantity = sparePart.quantity;

        let totalQuantityPrice;
        let totalPrice;
        if (productPrice && quantity) {
            totalQuantityPrice = quantity * productPrice;
            totalPrice = totalQuantityPrice;
        }
        if (sparePart.tva > 0) {
            totalPrice = totalPrice + totalPrice * (sparePart.tva / 100);
        }
        return totalPrice;
    }

    validateForm(): void {
        this.sparePartDataTable.data.forEach(item => {
            item['currency'] = this.currency;
        });
        this.dialogRef.close({
            spareLine: this.sparePartDataTable.data,
        });
    }

    deleteLineSparePart(indexLine: any): void {
        this.dataSourceSparePartLine = this.sparePartDataTable.data.filter((item, index) => index !== indexLine);
        this.refreshDataTableSparePart();
    }

    onCloseModal(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}
