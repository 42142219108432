import {Component, Input, OnInit, EventEmitter, Output, SimpleChanges, OnChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GLOBAL} from '../../../../../app-config';
import {SerialNumberRule} from '../../../../../models/folder.model';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-serial-number',
    templateUrl: './serial-number.component.html',
    styleUrls: ['./serial-number.component.scss']
})
export class SerialNumberComponent implements OnInit, OnChanges {

    showSerialNumberActionEdit = true;
    @Input() serialNumber!: string;
    @Input() serialNumberInfo: SerialNumberRule;
    @Input() title!: string;
    @Input() serialNumberPattern: string;

    @Output() serialNumberChange = new EventEmitter<string>();
    @Output() serialNumberEdit = new EventEmitter<string>();
    previousSerialNumber: string;
    form: FormGroup;


  constructor() { }

  ngOnInit(): void {
      this.initSerialNumberForm();
  }

    ngOnChanges(changes: SimpleChanges): void {
      if (!!changes.serialNumber?.currentValue) {
          this.initSerialNumberForm();
      }
    }

    initSerialNumberForm() {
        this.form = new FormGroup({
            serialNumber: new FormControl(this.serialNumber, Validators.pattern(GLOBAL.validationPatterns.serialNumber))
        });
    }

    openEditSerialNumberMode(): void {
        console.log('Opening edit mode...');
        this.previousSerialNumber = this.form.value.serialNumber;
        this.showSerialNumberActionEdit = !this.showSerialNumberActionEdit;
        this.serialNumberEdit.emit(this.form.value.serialNumber);
    }

    closeEditSerialNumberMode(): void {
        this.form.get('serialNumber').setValue(this.previousSerialNumber);
        this.showSerialNumberActionEdit = !this.showSerialNumberActionEdit;
    }

    updateSerialNumberFolder(): void {
        console.log('Updating serial number folder...');
        if (this.form.valid) {
            console.log('Emitting serialNumberChange event with:', this.form.value.serialNumber);
            this.serialNumberChange.emit(this.form.value.serialNumber);
            this.showSerialNumberActionEdit = !this.showSerialNumberActionEdit;
            // this.serialNumberSubject.next(this.serialNumber);

        } else {
            console.log('Form is not valid. Serial number not updated.');
        }
    }


}
