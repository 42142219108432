    <mat-card-content>
        <form [formGroup]="creditSupplierStatusForm.form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <mat-radio-group formControlName="status" class="ml-20" fxLayout="row" fxLayoutAlign="space-between center"
                             labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button [id]="status.status" class="full-width-radio" *ngFor="let status of creditSupplierStatusForm.placeholders"
                                  [value]="status.status">
                    <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div fxFlex>
                            <div>{{ status.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
                <ng-container *ngIf="creditSupplierStatusForm.form.value.status === 'FOLDER_CLOSED_CREDIT_SUPPLIER_CANCELED'">
                    <div class="ml-44">
                        <app-comment-area [commentForm]="creditSupplierStatusForm.form.get('comment')"
                                          [isRequired]="true"
                                          [title]="this.commentTitle"></app-comment-area>
                    </div>
                </ng-container>
            </mat-radio-group>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        [ngClass]="creditSupplierStatusForm.form.invalid?'aster_btn_disabled':'aster_btn'"
                        [disabled]="creditSupplierStatusForm.form.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>
