import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../models/folder.model';
import {FolderSubjectService} from '../../../folder-subject.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CommentType} from '../../../../../models/enums/CommentType.enum';
import {RepairStatusType} from '../../../../../models/enums/repairStatusType.enum';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {StartLoading} from '../../../../../store/loader/loader.actions';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';
import {LoadingScreenService} from '../../../../../shared/services/loading-screen.service';

@Component({
    selector: 'app-diagnosis-in-progress',
    templateUrl: './diagnosis-in-progress.component.html',
    styleUrls: ['./diagnosis-in-progress.component.scss']
})
export class DiagnosisInProgressComponent implements OnInit, OnDestroy {
    @Input() variablesTask: any;

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();
    @Input() instructionUserTask: InstructionUserTask;

    form: FormGroup;
    warrantyCode: string;
    breakDownButtons: any[];

    warrantyButtons: any[];
    productButtons: any[];
    warrantyDisclaimerOptions: any[];

    folder: Folder;

    destroy$ = new Subject<void>();

    constructor(private folderSubjectService: FolderSubjectService,
                private loadingScreenService: LoadingScreenService) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initButtons();

        this.warrantyCode = this.variablesTask['warrantyCode'];
        this.changeWarrantyValidator();
        this.changeDisclaimerReasonValidator();

        this.folderSubjectService.folder$
            .pipe(takeUntil((this.destroy$)))
            .subscribe(folder => this.folder = folder);
    }

    private changeWarrantyValidator() {
        this.form.get('breakDownStatus')
            .valueChanges
            .subscribe(breakDownStatus => {
                if (!!breakDownStatus) {
                    const productStatus = this.form.get('productStatus');
                    const status = this.form.get('status');

                    if (breakDownStatus === 'BREAKDOWN_CONFIRMED') {
                        productStatus.setValidators(Validators.required);
                        if (this.warrantyCode === 'SG') {
                            status.setValidators(Validators.required);
                        } else {
                            this.clearValidator(status);
                        }
                    } else {
                        if (status.value === 'WARRANTY_DISCLAIMER') {
                            const warrantyDisclaimerOption = this.form.get('warrantyDisclaimerOption');
                            this.clearValidator(warrantyDisclaimerOption);
                            warrantyDisclaimerOption.updateValueAndValidity();
                        }
                        this.clearValidator(productStatus);
                        this.clearValidator(status);
                    }
                    status.updateValueAndValidity();
                    productStatus.updateValueAndValidity();
                }
            });
    }

    private clearValidator(ValidatorValue: AbstractControl) {
        ValidatorValue.clearValidators();
        ValidatorValue.setValue(null);
    }

    private changeDisclaimerReasonValidator() {
        this.form.get('status')
            .valueChanges
            .subscribe(status => {
                if (!!status) {
                    const warrantyDisclaimerOption = this.form.get('warrantyDisclaimerOption');

                    if (status === RepairStatusType.WARRANTY_DISCLAIMER) {
                        warrantyDisclaimerOption.setValidators(Validators.required);
                    } else {
                        this.clearValidator(warrantyDisclaimerOption);
                    }
                    warrantyDisclaimerOption.updateValueAndValidity();
                }
            });
    }

    private initForm(): void {
        this.form = new FormGroup({
            status: new FormControl(null),
            warrantyDisclaimerOption: new FormControl(null),
            breakDownStatus: new FormControl(null, Validators.required),
            productStatus: new FormControl(null),
            comment: new FormControl(''),
        });
    }

    private initButtons(): void {
        this.breakDownButtons = [
            {
                status: 'BREAKDOWN_CONFIRMED',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.BREAKDOWN_CONFIRMED',
            },
            {
                status: 'NO_BREAKDOWN',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.NO_BREAKDOWN',
            }
        ];

        this.warrantyButtons = [
            {
                status: 'WARRANTY_ACCEPTED',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.WARRANTY_ACCEPTED'
            },
            {
                status: 'WARRANTY_DISCLAIMER',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.WARRANTY_DISCLAIMER'
            }
        ];

        this.productButtons = [
            {
                status: 'REPAIRABLE',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.REPAIRABLE'
            },
            {
                status: 'NOT_REPAIRABLE',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.NOT_REPAIRABLE'
            }
        ];

        this.warrantyDisclaimerOptions = [
            'USAGE_PROBLEM',
            'NOT_COVERED_BY_WARRANTY'
        ];
    }

    onSubmit(): void {
        this.loadingScreenService.startLoading();
        if (this.form.value.comment.length > 0) {
            this.commentGiven.emit({
                value: this.form.value.comment,
                type: this.toCommentType(),
            });
        }
        this.inputMap.emit({
            'breakDownStatus': this.form.value.breakDownStatus,
            'reparationStatus': this.form.value.status,
            'productStatus': this.form.value.productStatus,
            'warrantyDisclaimerOption': this.form.controls['warrantyDisclaimerOption'].value
        });
    }

    toCommentType(): string {
        if (this.form.value.productStatus === 'NOT_REPAIRABLE') {
            return CommentType.COMMENT_NOT_REPAIRABLE;
        } else if (this.form.value.productStatus === 'REPAIRABLE') {
            return CommentType.COMMENT_REPAIRABLE;
        } else if (this.form.value.status === 'WARRANTY_DISCLAIMER') {
            return CommentType.COMMENT_WARRANTY_DISCLAIMER;
        } else if (this.form.value.breakDownStatus === 'NO_BREAKDOWN') {
            return CommentType.COMMENT_NO_BREAKDOWN;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getTaskDuration(): string {
       return  this.variablesTask['diagnosisMaxDuration'];
    }

    getStatusDate(): string {
        return this.folder.currentWorkflowStatus.workflowStatusDate;
    }
}
