import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'validate-edit-wished-appointment.component',
    templateUrl: 'validate-edit-wished-appointment.component.html',
})
export class ValidateEditWishedAppointmentComponent {

    constructor(public dialogRef: MatDialogRef<ValidateEditWishedAppointmentComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    onClose(): void {
        this.dialogRef.close();
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }
}
