import {DashboardState, initialDashboardState} from './dashboard.state';
import {DashboardActions, DashboardActionType} from './dashboard.actions';


export function dashboardReducer(state: DashboardState = initialDashboardState, action: DashboardActions): DashboardState {
    switch (action.type) {
        case DashboardActionType.LOAD_DASHBOARD:
            return {
                ...state,
                mode: action.options.embedContainerId
            };

        case DashboardActionType.SET_DASHBOARDS:
            return {
                ...state,
                dashboards: action.dashboards
            };
        default:
            return state;
    }
}
