import {Address} from './customer/customer.model';
import {Contact} from './contact.model';
import {SparePartStock} from './spare-parts/stock.model';
import {OrderStatus} from './enums/orderStatus.enum';
import {Image, Supplier} from './spare-parts/relatedSparePart.model';
import {Payer} from './enums/payer.enum';

export class Order {

    id: string;
    referenceNumber: string;
    externalId: string;
    organizationCode: string;
    orderItems: OrderItem[];
    orderExtraItems: OrderItem[];
    shippingAddress: Address;
    shippingContact: Contact;
    remark: string;
    currency: string;
    orderStatus: string;
    siteCode: string;
    creationDate: Date;
    grandTotal: number;
    expirationDate: string;

}

export class OrderItem {
    id?: string;
    code?: string;
    label?: string;
    type?: string;
    ean?: string;

    currency?: string;
    price?: number;
    priceUnitwithTax?: number;
    purchasePrice?: number;
    marginPercentage?: number;
    taxPercent?: number;
    discountPercent?: number;
    discountReason?: string;
    rowTotal?: number;

    weight?: number;
    weightUnit?: string;

    supplier?: Supplier;

    quantityDelivered?: number;
    quantityPacked?: number;
    quantityAvailable?: number;
    quantityOrdered?: number;
    quantityInvoiced?: number;
    isAvailable?: boolean;

    images?: Image[];

    displayType?: string;

    isSelected?: boolean;

    stock?: SparePartStock;

    itemStatus?: OrderStatus;
    payer?: Payer;
}

export interface OrderUpdateRequest {
    orderItems?: OrderItem[];
    orderItemsStatuses?: OrderItem[];
}
