import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-swap-confirmation-home-repair',
    templateUrl: './swap-confirmation-home-repair.component.html',
    styleUrls: ['./swap-confirmation-home-repair.component.scss']
})
export class SwapConfirmationHomeRepairComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();
    @Input() instructionUserTask: InstructionUserTask;
    @Input() folder: Folder;

    form: FormGroup;
    buttons: any[];

    constructor() {
    }

    ngOnInit(): void {
        this.initForm();
        this.initButtons();
    }

    private initForm(): void {
        this.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            comment: new FormControl(''),
        });
    }

    private initButtons(): void {
        this.buttons = [
            {
                status: true,
                value: 'BUTTON.CONFIRMATION_SWAP',
            },
            {
                status: false,
                value: 'BUTTON.REFUSE_SWAP',
            }
        ];
    }

    onSubmit(): void {
        if (this.form.value.comment.length > 0) {
            this.commentGiven.emit({value: this.form.value.comment});
        }

        this.inputMap.emit({'confirmSwap': this.form.value.status});
    }

    disabledButtonForm(): boolean {
        return this.form.invalid;
    }
}
