import {Component, Input, OnInit} from '@angular/core';
import {BackOfficeService} from '../../services/back-office.service';

@Component({
    selector: 'app-workflow-status-progress-bar',
    templateUrl: './workflow-status-progress-bar.component.html',
    styleUrls: ['./workflow-status-progress-bar.component.scss']
})
export class WorkflowStatusProgressBarComponent implements OnInit {
    @Input() taskTimeoutDuration: string;
    @Input() currentStatusDate: string;

    constructor(private backOfficeService: BackOfficeService) {
    }

    ngOnInit(): void {
    }

    computeTimerValue(): string {
        return this.backOfficeService.computeProgressBarValue(this.taskTimeoutDuration, this.currentStatusDate);
    }

    showProgressBar(): boolean {
        return this.taskTimeoutDuration && this.backOfficeService.showProgressBar(this.taskTimeoutDuration);
    }

    getRemainingDays(): number {
        return this.backOfficeService.computeRemainingDays(this.taskTimeoutDuration, this.currentStatusDate);
    }

    getRemainingHours(): number {
        return this.backOfficeService.computeRemainingHours(this.taskTimeoutDuration, this.currentStatusDate);
    }

}
