<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" cdkDragHandle>
        <h2>
            <mat-icon>note_add</mat-icon>
            {{quotationModalConfig.titleModal | translate}}
        </h2>
        <button mat-icon-button class="ml-40" (click)="onCloseModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <form *ngIf="quotationForm && quotationModalConfig.showFormAddQuotation" [formGroup]="quotationForm" autocomplete="off">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                <div
                    fxFlex="{{!hideSupplier ? 19.5 : 25}}"
                    fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.TYPE'|translate}}</mat-label>
                        <mat-select formControlName="type" [placeholder]="'MODAL.QUOTATION.TYPE'|translate" required
                                    (selectionChange)="refreshDataForm($event.value)">
                            <mat-option *ngFor="let type of filteredSparePartsTypes" [value]="type.code">
                                <span > {{type.label}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div
                    fxFlex="{{!hideSupplier ? 19.5 : 30}}"
                    fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.CODE' | translate }}</mat-label>
                        <input matInput placeholder="{{'MODAL.QUOTATION.CODE' | translate }}"
                               formControlName="code" inputControl
                               required [matAutocomplete]="autoCode" (keyup)="onCodeChanges()">
                        <mat-autocomplete #autoCode="matAutocomplete" (optionSelected)='sparePartChanged($event)'>
                            <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <div translate="yes" class="hide-translate-text">
                                <mat-option *ngFor="let option of filteredData | async" [value]="option"  >
                                    {{option.code}} - {{option.label}}
                                </mat-option>
                                </div>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div
                    fxFlex="{{!hideSupplier ? 37 : 40}}"
                    fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.LABEL'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.LABEL'|translate" (keyup)="onLabelChanges()"
                               formControlName="label" inputControl type="text" required [matAutocomplete]="autoLabel">
                        <mat-autocomplete #autoLabel="matAutocomplete" (optionSelected)='sparePartChanged($event)'>
                            <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <div translate="yes" class="hide-translate-text">
                                    <mat-option *ngFor="let sparePart of filteredData | async" [value]="sparePart"  >
                                        {{sparePart.code}} - {{sparePart.label}}
                                    </mat-option>
                                </div>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div *ngIf="(quotationModalConfig.showSupplier$ |async ) && !hideSupplier"
                     fxFlex="37"
                     fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.SUPPLIER' | translate }}</mat-label>
                        <input matInput placeholder="{{'MODAL.QUOTATION.SUPPLIER' | translate }}"
                               [formControl]="supplierFormControl"
                               [matAutocomplete]="autoSupplier"
                               (keyup)="filterSupplier($event)"
                               required
                        >
                        <mat-autocomplete #autoSupplier="matAutocomplete" [displayWith]="displayFn" (optionSelected)='supplierChanged($event)'>
                            <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <div class="notranslate">
                                <mat-option *ngFor="let supplier of filteredSuppliers | async" [value]="supplier">
                                    {{supplier.code}} - {{supplier.name}}
                                </mat-option>
                                </div>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                <div fxFlex="15" fxFlex.lt-md="100"
                     *ngIf="!quotationModalConfig.hidePayers$ || !(quotationModalConfig.hidePayers$ | async)">

                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{ 'MODAL.QUOTATION.PAYER'|translate }}</mat-label>
                        <mat-select formControlName="payer" [placeholder]="'MODAL.QUOTATION.PAYER'|translate"
                                    [disabled]="!!quotationModalConfig.payer">
                            <mat-option *ngFor="let payer of payersData" [value]="payer.key">
                                <span> {{ payer.label.i18nKey | translate }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div fxFlex="10" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.QUANTITY'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.QUANTITY'|translate" formControlName="quantity"
                               (change)="formatAndCalculateTotalQuotationLine()" NumberOnlyNotIncludeZeros min="1"
                               required
                               type="number">
                    </mat-form-field>
                </div>
                <div fxFlex="10" fxFlex.lt-md="100"
                     *ngIf="(quotationModalConfig.showPurchasePrice$ | async ) && !hidePurchasePrice">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.PURCHASE.PRICE'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.PURCHASE.PRICE'|translate"
                               [value]="quotationForm.get('purchasePrice').value" type="number"
                               (change)="calculatePrices()" formControlName="purchasePrice" min="0" required>
                    </mat-form-field>
                </div>
                <div fxFlex="10" fxFlex.lt-md="100"
                     *ngIf="( quotationModalConfig.showMargin$ | async )  && !hideMarge">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label> {{'MODAL.QUOTATION.MARGIN'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.MARGIN'|translate" formControlName="marginPercentage" min="0"
                               (change)="calculatePrice()" [value]="displayMargin()" type="number" numberOnly [decimal]="true"
                               >
                        <mat-icon class="s-16" matSuffix>%</mat-icon>
                    </mat-form-field>
                </div>
                <div fxFlex="10" fxFlex.lt-md="100"
                     *ngIf="!quotationModalConfig.hidePrice$ || !(quotationModalConfig.hidePrice$ | async)">
                    <mat-form-field appearance="outline" class="w-100-p" >
                        <mat-label>{{'MODAL.QUOTATION.PRICE'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.PRICE'|translate"
                               [value]="quotationForm.get('price').value"
                               (change)="calculateMarge()" formControlName="price" min="0"  numberOnly required>
                    </mat-form-field>
                </div>
                <div fxFlex="10" fxFlex.lt-md="100"
                     *ngIf="!quotationModalConfig.hideDiscount$ || !(quotationModalConfig.hideDiscount$ | async)">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.DISCOUNT'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.DISCOUNT'|translate" formControlName="discount"
                               (change)="formatAndCalculateTotalQuotationLine()"
                               percentage>
                        <mat-icon class="s-16" matSuffix>%</mat-icon>
                    </mat-form-field>
                </div>

                <div fxFlex="10" fxFlex.lt-md="100"
                     *ngIf="!quotationModalConfig.hideTva$ || !(quotationModalConfig.hideTva$ | async)">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label> {{'MODAL.QUOTATION.TVA'|translate}}</mat-label>
                        <mat-select formControlName="vat" (selectionChange)="formatAndCalculateTotalQuotationLine()">
                            <mat-option *ngFor="let vat of (vatsList$ | async)" [value]="vat.rate">
                                {{vat.rate}} %
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div *ngIf = "!(quotationModalConfig.showTotalUnitPrice$ | async)" fxFlex="20" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.TOTAL_PRICE'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.TOTAL_PRICE'|translate"
                               required disabled readonly="true"
                               [value]="quotationForm.get('totalLinePrice').value"
                               [ngClass]="'input-disabled'"
                               formControlName="totalLinePrice"
                               (change)="formatAndCalculateTotalQuotationLine()">

                    </mat-form-field>
                </div>
                <div *ngIf = "quotationModalConfig.showTotalUnitPrice$ | async" fxFlex="20" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.TOTAL_UNIT_PRICE'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.TOTAL_UNIT_PRICE'|translate"
                               required
                               numberOnly
                               min="0"
                               [value]="quotationForm.get('totalUnitLinePrice').value"
                               formControlName="totalUnitLinePrice"
                               (change)="calculatePriceAndMargin()">

                    </mat-form-field>
                </div>
            </div>
            <ng-container *ngIf="this.quotationForm.value.discount>0">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                    <div fxFlex="100" fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'MODAL.QUOTATION.DISCOUNT_REASON'|translate}}</mat-label>
                            <input matInput [placeholder]="'MODAL.QUOTATION.DISCOUNT_REASON' | translate"
                                   formControlName="discountReason" type="text">
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
            <div fxLayoutAlign="end center">
                <div>
                    <button mat-stroked-button class="aster-btn_border" type="button"
                            (click)="addIntoQuotationLines()"
                            [disabled]="quotationForm.invalid || isSparePartAdd  "
                            [ngClass]="quotationForm.invalid || isSparePartAdd   ? 'aster-btn_border-disabled' : 'aster-btn_border'">
                        <mat-icon>add</mat-icon>
                        {{'BUTTON.ADD' | translate}}
                    </button>
                </div>
            </div>
        </form>

        <div *ngIf="!stockSparePartAvailable">
            <span class="not-available">{{"STOCK.SPARE_PART.NOT_AVAILABLE"|translate}}</span>
        </div>
        <div *ngIf="!stockSparePartsAvailable">
            <span class="not-available">{{"STOCK.SPARE_PARTS.NOT_AVAILABLE"|translate}}</span>
        </div>

        <div  translate="yes" class="hide-translate-text">
            <table mat-table [dataSource]="quotationLines" class="mat-elevation-z4 w-100-p mt-16 mb-16" >
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef class="table-header">  {{'MODAL.QUOTATION.CODE' | translate}}</th>
                    <td mat-cell *matCellDef="let quotationLine" class="element">{{quotationLine.code}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef class="table-header">
                        <span  class="notranslate" >{{'MODAL.QUOTATION.TYPE' | translate}}</span>
                    </th>
                    <td mat-cell
                        class="element"
                        *matCellDef="let quotationLine">{{'MODAL.QUOTATION.TYPE.' + quotationLine.type | translate}}</td>
                </ng-container>

                <ng-container matColumnDef="label">
                    <th mat-header-cell *matHeaderCellDef class="table-header">{{'MODAL.QUOTATION.LABEL' | translate}}</th>
                    <td class="element" mat-cell *matCellDef="let quotationLine">{{quotationLine.label}}</td>
                </ng-container>

                <ng-container matColumnDef="stock">
                    <th mat-header-cell *matHeaderCellDef  class="table-header">
                        <span class="notranslate" > {{'MODAL.QUOTATION.STOCK' | translate}} </span>
                    </th>
                    <td mat-cell *matCellDef="let quotationLine" class="element">
                        <div *ngIf="quotationLine.stock?.isLoading" class="spinner-wrapper">
                            <mat-spinner diameter="20"></mat-spinner>
                        </div>
                        <span *ngIf="!quotationLine.stock?.isLoading"
                              [ngClass]="getStockIndicatorClass(quotationLine.stock?.status)"
                              matTooltipClass="multi-line-tooltip"
                              [matTooltip]="hasStock(quotationLine.stock?.status) ?
                              stockDetailsTooltip(quotationLine.stock?.stockDetails, quotationLine.stock?.totalStock) :
                              ''">
                            {{displayStockStatus(quotationLine.stock?.status,quotationLine.type) | translate}}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef
                        class="table-header">  {{'MODAL.QUOTATION.QUANTITY' | translate}}</th>
                    <td mat-cell *matCellDef="let quotationLine" class="element">{{quotationLine.quantity}} </td>
                </ng-container>
                <ng-container matColumnDef="purchasePrice">
                    <th mat-header-cell *matHeaderCellDef
                        class="table-header">  {{'MODAL.QUOTATION.PURCHASE.PRICE' | translate}}</th>
                    <td class="element" mat-cell *matCellDef="let quotationLine"> {{displayPurchasePriceInTable(quotationLine)}} </td>
                </ng-container>
                <ng-container matColumnDef="marginPercentage">
                    <th mat-header-cell *matHeaderCellDef
                        class="table-header">  {{'MODAL.QUOTATION.MARGIN' | translate}} %</th>
                    <td class="element" mat-cell *matCellDef="let quotationLine"> {{displayMarginInTable(quotationLine)}} </td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef class="table-header">{{'MODAL.QUOTATION.PRICE' | translate}}</th>
                    <td mat-cell *matCellDef="let quotationLine" class="element">
                        <ng-container *ngIf="quotationModalConfig.showFormAddQuotation;else EDIT_PRICE">
                            {{ normalize(quotationLine.price || 0) }}
                        </ng-container>
                        <ng-template #EDIT_PRICE>
                            <mat-form-field appearance="outline" class="mt-8">
                                <mat-label>{{'MODAL.QUOTATION.PRICE'|translate}}</mat-label>
                                <input matInput [placeholder]="'MODAL.QUOTATION.PRICE'|translate"
                                       [(ngModel)]="quotationLine.price"
                                       [disabled]="isFeesQuotation(quotationLine)" (change)="changeLinePrice()"
                                       min="0" required>
                            </mat-form-field>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="vat">
                    <th mat-header-cell *matHeaderCellDef class="table-header">  {{'MODAL.QUOTATION.TVA' | translate}}</th>
                    <td class="element" mat-cell *matCellDef="let quotationLine"> {{quotationLine.vat}} </td>
                </ng-container>

                <ng-container matColumnDef="discount">
                    <th mat-header-cell *matHeaderCellDef
                        class="table-header">  {{'MODAL.QUOTATION.DISCOUNT' | translate}}</th>
                    <td class="element" mat-cell *matCellDef="let quotationLine"> {{quotationLine.discount}} </td>
                </ng-container>

                <ng-container matColumnDef="payer">
                    <th mat-header-cell *matHeaderCellDef
                        class="table-header"> {{'MODAL.QUOTATION.PAYER' | translate}}</th>
                    <td class="element" mat-cell *matCellDef="let quotationLine">
                        <mat-form-field appearance="outline" class="mt-8">
                            <mat-label>{{'MODAL.QUOTATION.PAYER'|translate}}</mat-label>
                            <mat-select [(ngModel)]="quotationLine.payer" [placeholder]="'MODAL.QUOTATION.TYPE'|translate"
                                        [disabled]="isFeesQuotation(quotationLine)">
                                <mat-option *ngFor="let payer of payers" [value]="payer">
                                    <span> {{'MODAL.QUOTATION.PAYER_CODE.' + payer | translate}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>

                <ng-container matColumnDef="totalPrice">
                    <th mat-header-cell *matHeaderCellDef
                        class="table-header"> {{'MODAL.QUOTATION.TOTAL_PRICE' | translate}}</th>
                    <td class="element" mat-cell *matCellDef="let quotationLine">
                        {{normalize(quotationLine.totalLinePrice)   |currency:currency :'symbol-narrow' | space}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef
                        class="table-header"> <span class="notranslate">  {{'MODAL.QUOTATION.ACTION' | translate}} </span> </th>
                    <td class="element" mat-cell *matCellDef="let quotationLine;let indexLine = index;">
                        <button matSuffix mat-icon-button color="warn"
                                type="button" class="mx-8"
                                [disabled]="isFeesQuotation(quotationLine)" (click)="deleteLine(indexLine)">
                            <mat-icon>delete_forever</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>


        <div fxLayout="row" fxLayoutAlign="end end">
            <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="30" fxFlex.lt-md="100">
                <div fxLayout="column" class="zone-total">
                    <div fxLayoutAlign="space-between center">
                        <strong>{{'MODAL.QUOTATION.TOTAL_HT' | translate}} : </strong>
                        <div>{{getTotalPriceHT()  |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <strong>{{'MODAL.QUOTATION.TOTAL_DISCOUNT' | translate}} : </strong>
                        <div>{{getTotalDiscount()  |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <strong>{{'MODAL.QUOTATION.TOTAL_NET_HT' | translate}}  : </strong>
                        <div>{{getTotalNetHT()  |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <strong>{{'MODAL.QUOTATION.TOTAL_TVA' | translate}}  : </strong>
                        <div>
                            {{getTotalPriceTVA()  |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <strong> {{'MODAL.QUOTATION.TOTAL_TTC' | translate}} : </strong>
                        <div>{{computeTotalPrice?.value |currency:computeTotalPrice?.currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                </div>

            </mat-card>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mt-16">
        <div class="w-100-p">
            <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap ="10px">
                <ng-container *ngIf="computeTotalPrice?.value < 0">
                    <p class="red-color">{{'FOLDER.REPAIR_IN_PROGRESS.NEGATIVE_QUOTATION' | translate}}</p>
                </ng-container>
                <button type="submit" mat-stroked-button (click)="validateForm()"
                        [disabled]=" disabledForm()"
                        [ngClass]=" disabledForm()? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </div>
    </mat-dialog-actions>
</div>
