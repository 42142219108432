
    <mat-card-content>
        <form [formGroup]="reparationAgreementForm"
              fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px"
              (ngSubmit)="onSubmit()">
            <app-workflow-status-progress-bar taskTimeoutDuration="{{getTaskDuration()}}"
                                              currentStatusDate="{{getStatusDate()}}">
            </app-workflow-status-progress-bar>

            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>

            <mat-radio-group fxLayout="column" fxLayoutGap="20px"
                             formControlName="choice"
                             (change)="onChoiceChange($event.value)">
                <mat-radio-button [id]="'accept_agreement'" [value]="true">
                    {{'COMPONENT.WAITING_REPARATION_AGREEMENT.ACCEPTED'|translate}}
                </mat-radio-button>
                <mat-radio-button [id]="'refuse_agreement'" [value]="false">
                    {{'COMPONENT.WAITING_REPARATION_AGREEMENT.REFUSED'|translate}}
                </mat-radio-button>
            </mat-radio-group>


            <ng-container *ngIf="displayAgreementNumber()">
                <mat-form-field appearance="outline" class="w-100-p">
                    <mat-label>{{'COMPONENT.WAITING_REPARATION_AGREEMENT.NUMBER'|translate}}</mat-label>
                    <input matInput [placeholder]="'COMPONENT.WAITING_REPARATION_AGREEMENT.NUMBER'|translate"
                           [id]="'agreement_number'"
                           formControlName="agreementNumber"
                           required>
                </mat-form-field>
            </ng-container>


            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        [ngClass]="reparationAgreementForm.invalid ?'aster_btn_disabled':'aster_btn'"
                        [disabled]="reparationAgreementForm.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>

        </form>
    </mat-card-content>