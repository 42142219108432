    <mat-card-content>
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>

        <ng-container *ngIf="folder?.warrantyEndDate">
            <label class="h3"><span
                class="text-bold"> {{ 'FOLDER.WARRANTY_END_DATE' | translate }}
                :</span> {{ folder.warrantyEndDate  | moment: 'L' : (currentLanguage$ | async) }}
            </label>
        </ng-container>

        <ng-container>
            <div class="mt-12">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-checkbox [checked]="productRetrieved"  [(ngModel)]="productRetrieved">
                        {{'BUTTON.CLIENT_RETRIEVE_NOT_REPAIRED_PRODUCT' | translate}}
                    </mat-checkbox>
                </div>
            </div>
        </ng-container>

        <div *ngIf="file" style="margin: 30px 0 10px 0">
            {{'FOLDER.REPORT.DOWNLOAD' | translate}}:
            <a (click)="getReportAttachment()" class="cursor-pointer">
                    <span>
                        <mat-icon>file_copy</mat-icon>
                        {{file.name}}
                    </span>
            </a>
        </div>

        <ng-container *ngIf="!!description$">
            <h3 class="text-bold">
                <mat-icon class="icon-color">warning</mat-icon>
                {{description$ | async}}</h3>
        </ng-container>

        <div fxLayout="row" fxLayoutAlign="end end">
            <button mat-stroked-button (click)="onSubmit()"
                    type="submit"
                    [disabled]="!productRetrieved"
                    [ngClass]="!productRetrieved ? 'aster_btn_disabled' : 'aster_btn'">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>

    </mat-card-content>