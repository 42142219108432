import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FileService} from '../../shared/services/file.service';
import {GLOBAL} from '../../app-config';

class Image {
    id?: string;
    fullUrl: string;
}
@Component({
    selector: 'app-multi-image-modal',
    templateUrl: './multi-image-modal.component.html',
    styleUrls: ['./multi-image-modal.component.scss']
})
export class MultiImageModalComponent implements OnInit {

    images: string[] = [];
    currentImageIndex = 0;
    isLoading = true;

    constructor(@Inject(MAT_DIALOG_DATA) public imagesSrc: Image[],
                private fileService: FileService) {

    }
    ngOnInit(): void {
        const images = this.imagesSrc.map(image => this.fetchImages(image));
        Promise.all(images).then(images => {
            this.images = images;
        });
    }
    async fetchImages(image) {
        if (image.id) {
            const imageAsBlob = await this.fileService.getAttachmentFile(image.id).toPromise();
            const reader = new FileReader();
            reader.readAsDataURL(imageAsBlob);
            return new Promise((resolve) => {
                reader.onloadend = () => {
                    resolve(reader.result.toString());
                };
            });
        } else if (image.fullUrl) {
            return image.fullUrl;
        } else {
            this.isLoading = false;
            return GLOBAL.imageNotFound;
        }
    }
    onLoad() {
        this.isLoading = false;
    }
    showPreviousImage() {
        this.isLoading = true;
        this.currentImageIndex--;
    }

    showNextImage() {
        this.isLoading = true;
        this.currentImageIndex++;
    }

    isLastImage() {
        return this.images.length - 1 === this.currentImageIndex;
    }

    isFirstImage() {
        return this.currentImageIndex === 0;
    }

    onImageError(event: ErrorEvent) {
        (event.target as HTMLImageElement).src = GLOBAL.imageNotFound;
    }

}
