import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {ActivatedRoute} from '@angular/router';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';
import {TaskVariables} from '../../task.variables';
import {EXPEDITION, PICKUP} from '../../service-center-repair-process/confirm-carrier-and-repairer/confirm-carrier-and-repairer.component';
import {LoadingScreenService} from '../../../../../shared/services/loading-screen.service';

@Component({
    selector: 'app-waiting-supplier-confirmation',
    templateUrl: './waiting-supplier-confirmation.component.html',
    styleUrls: ['./waiting-supplier-confirmation.component.css']
})
export class WaitingSupplierConfirmationComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();
    folderId: string;
    isDestructionProduct = false;
    isShipmentToSupplier = false;
    commentTitle: string;
    refundStatusForm: any = {
        form: null,
        placeholders: []
    };
    carrierCode: string;
    carrierExtraData: any;
    TransportMode: string;
    private isPickupSelected: boolean;

    constructor(private  route: ActivatedRoute,
                private loadingScreenService: LoadingScreenService) {}

    ngOnInit(): void {
        this.commentTitle = 'FOLDER.REFUSED_SUPPLIER_CONFIRMATION.REASON';
        this.initForm();
        this.folderId = this.route.snapshot.parent.params.folderId;
    }

    private initForm(): void {
        this.refundStatusForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            sendProduct: new FormControl(null),
            refundAgreementNumber: new FormControl(null),
            comment: new FormControl(null),
        });
        this.refundStatusForm.placeholders = [
            {
                status: 'true',
                value: 'FOLDER.WAITING_SUPPLIER_CONFIRMATION.ACCEPTED',
            },
            {
                status: 'false',
                value: 'FOLDER.WAITING_SUPPLIER_CONFIRMATION.REFUSED'
            }
        ];
        this.refundStatusForm.form.get('status').valueChanges.subscribe(acceptedRequest => {
            if (acceptedRequest === null){
                return;
            }
            if (acceptedRequest === 'true') {
                this.refundStatusForm.form.get('refundAgreementNumber').setValidators([Validators.required]);
                this.resetValidator(this.refundStatusForm.form.get('comment'));
                this.initSupplierChoice();
            }
            if (acceptedRequest === 'false')  {
                this.refundStatusForm.form.get('comment').setValidators(Validators.required);
                this.resetValidator(this.refundStatusForm.form.get('refundAgreementNumber'));
            }
            this.refundStatusForm.form.get('refundAgreementNumber').updateValueAndValidity();
            this.refundStatusForm.form.get('comment').updateValueAndValidity();
        });
    }

    private resetValidator(ValidatorValue: AbstractControl) {
        ValidatorValue.setValue(null);
        ValidatorValue.clearValidators();
    }
    onSubmit(): void {
        this.loadingScreenService.startLoading();
        if (this.refundStatusForm.form.invalid) {
            return;
        }
        if (!!this.refundStatusForm.form.value.comment && this.refundStatusForm.form.value.comment.length > 0) {
            this.commentGiven.emit({
                value: this.refundStatusForm.form.value.comment,
                type: 'REASON_FOR_REFUSAL_SUPPLIER'
            });
        }
        let dataToComplete;
        if (this.refundStatusForm.form.value.status === 'true') {
            dataToComplete = {
                acceptRefundSupplier : this.refundStatusForm.form.value.status,
                sendProduct : this.refundStatusForm.form.value.sendProduct,
                destroyedByCustomer: this.refundStatusForm.form.value.sendProduct === 'true' ? 'false' : 'true',
                refundAgreementNumber : this.refundStatusForm.form.value.refundAgreementNumber,
                refundRefusedBySupplier : false,
                hasInsurance : this.carrierExtraData?.insurance,
                carrierCode : this.carrierCode,
                isPickUpCarrier: this.isPickupSelected,
            };
            if (this.carrierExtraData?.insurance) {
                dataToComplete[TaskVariables.INSURANCE_PRICE] = String(this.carrierExtraData?.insuranceValue);
            }
            if (this.carrierExtraData?.isProductDanger) {
                dataToComplete[TaskVariables.IS_PRODUCT_DANGER] = String(this.carrierExtraData?.isProductDanger);
            }
        } else {
            dataToComplete = {
                acceptRefundSupplier : this.refundStatusForm.form.value.status,
                refundRefusedBySupplier : false,
                // refundRefusedBySupplier -> false : on force le refus de remboursement ds BO
            };
        }
        this.inputMap.emit(dataToComplete);

    }
    initSupplierChoice(){
        const isForwardedToSupplier = this.isForwardedToSupplier();
        const isDestroyedByCustomer = this.isDestroyedByCustomer();

        if ((isForwardedToSupplier && isDestroyedByCustomer) || (!isForwardedToSupplier && !isDestroyedByCustomer)){
            this.isShipmentToSupplier = false;
            this.isDestructionProduct = false;
            this.refundStatusForm.form.get('sendProduct').setValidators([Validators.required]);
        } else {
            this.isShipmentToSupplier = isForwardedToSupplier;
            this.isDestructionProduct = isDestroyedByCustomer;
            this.refundStatusForm.form.get('sendProduct').setValue(String(this.isShipmentToSupplier));
        }
    }

    isForwardedToSupplier(): boolean {
        return JSON.parse(this.variablesTask['sendProduct']);
    }
    isDestroyedByCustomer(): boolean {
        return JSON.parse(this.variablesTask['destroyedByCustomer']);
    }
    getTaskDuration(): string {
        return  this.variablesTask['durationSupplier'];
    }

    getStatusDate(): string {
        return this.folder.currentWorkflowStatus.workflowStatusDate;
    }

    changeCarrierCode($event: any) {
        this.carrierCode = $event;
    }

    changeCarrierExtraEvent($event: any) {
        this.carrierExtraData = $event;
    }

    changeTransportModeEvent($event: any) {
        this.TransportMode = $event;
        this.isPickupSelected = this.TransportMode === PICKUP;
    }

    clearCarrierInfo() {
        this.carrierExtraData = null;
    }

    sendToSupplier(): boolean {
        return this.refundStatusForm.form.value.status === 'true' && this.refundStatusForm.form.value?.sendProduct === 'true';
    }

    isFormInvalid(): boolean {
        return !this.refundStatusForm.form.valid ||
            (this.sendToSupplier() && ( !this.isInsurancePriceValid() || !this.TransportMode || ((!this.carrierCode && this.TransportMode === EXPEDITION))));
    }

    isInsurancePriceValid(): boolean {
        return (this.carrierExtraData?.insurance && this.carrierExtraData?.insuranceValue > 0) || !this.carrierExtraData?.insurance ;
    }
}
