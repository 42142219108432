import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {GrowthbookService} from '../../../../../../shared/services/growthbook.service';
import {currentLanguage} from '../../../../../../store/organization/organization.selectors';
import {currentUser} from '../../../../../../store/user/user.selectors';
import {map, switchMap} from 'rxjs/operators';
import {GrowthbookAttributes} from '../../../../../../shared/features/growthbook-attributes';
import {AppFeatures} from '../../../../../../shared/features/app-features';
import {FolderCreationService} from '../../../folder-creation.service';
import {MatSelectChange} from '@angular/material/select';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-preferred-languages',
    templateUrl: './preferred-languages.component.html',
    styleUrls: ['./preferred-languages.component.scss']
})
export class PreferredLanguagesComponent extends Unsubscriber implements OnInit {

    languageCtrl: FormControl;
    preferredLanguages: string[] = [];
    currentLangKey: string;

    constructor(private folderCreationService: FolderCreationService,
                private growthBookService: GrowthbookService,
                private store$: Store<AppState>) {
        super();
        this.languageCtrl = new FormControl(null);
    }

    ngOnInit() {
        this.handlePreferredLanguages();
    }

    private handlePreferredLanguages() {
        const language$ = this.store$.pipe(select(currentLanguage));
        const user$ = this.store$.pipe(select(currentUser));

        this.anotherSubscription = language$.pipe(
            switchMap((language: string) => {
                this.currentLangKey = language;
                return user$;
            }),
            switchMap(user => {
                const attributes: GrowthbookAttributes = {
                    organizationCode: user.organizationCode,
                    context: user.context,
                };
                return this.growthBookService.getFeatureValue(AppFeatures.ORGANIZATION_LANGUAGES, attributes, this.currentLangKey);
            })
        ).pipe(
            map((featureValue: string[]) => {
                if (Array.isArray(featureValue) && featureValue.length === 0) {
                    return [];
                } else {
                    return featureValue;
                }
            }))
            .subscribe((featureLanguages: string[]) => {
                this.preferredLanguages = featureLanguages;
                this.languageCtrl.setValue(this.currentLangKey);
                this.folderCreationService.customerPreferredLanguageChanged(this.currentLangKey);
            });

    }


    onSelectLanguage($event: MatSelectChange) {
        this.folderCreationService.customerPreferredLanguageChanged($event.value);
    }
}
