import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ReportModalComponent} from '../../../../../../shared/generic/report-modal/report-modal.component';
import {MatTableDataSource} from '@angular/material/table';
import {ReparationReport} from '../../../../../../models/reparationReport/reparation-report.model';
import {MatDialog} from '@angular/material/dialog';
import {TaskVariables} from '../../../task.variables';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {Subscription} from 'rxjs';
import {Folder} from '../../../../../../models/folder.model';
import {Quotation} from '../../../../../../models/quotation.model';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-intervention-report',
    templateUrl: './intervention-report.component.html',
    styleUrls: ['./intervention-report.component.css']
})
export class InterventionReportComponent implements OnInit, OnDestroy {
    reportLineColumns: string[] = ['code', 'label', 'type', 'payer'];
    reportLineDataTable = new MatTableDataSource<any>();

    irisColumns: string[] = ['symptom', 'condition', 'repair', 'defect', 'section'];
    irisDataTable = new MatTableDataSource<any>();

    reparationReport: ReparationReport;
    private folderSubscription: Subscription;
    folder: Folder;
    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;
    comment: string;
    constructor(private matDialog: MatDialog,
                private folderSubjectService: FolderSubjectService) {
    }

    ngOnInit(): void {
        this.folderSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
            if (this.folder.quotations.length > 0) {
                this.reportLineDataTable.data = this.getQuotationDistributor().quotationLines;
            }else if (this.variablesTask[TaskVariables.sparePartsOrderList]) {
                this.reportLineDataTable.data = JSON.parse(this.variablesTask[TaskVariables.sparePartsOrderList]);
            }
            this.irisDataTable.data = !!this.folder.reparationReport?.iris ? this.folder.reparationReport.iris : [];
        });
    }

    onSubmit(): void {
        this.reparationReport = {
            reportLine: this.reportLineDataTable.data,
            iris: []
        };
        this.irisDataTable.data.forEach(irisLine => {
            this.reparationReport.iris.push({
                symptom: irisLine.symptom,
                condition: irisLine.condition,
                reparation: irisLine.reparation,
                defect: irisLine.defect,
                section: irisLine.section,
            });
        });
        this.inputMap.emit({
            'reparationStatus': 'FINISHED',
            'report': JSON.stringify(this.reparationReport),
            'comment': this.comment
        });
        return;
    }
    getQuotationDistributor(): Quotation {
        let quotationDistributorId = this.variablesTask['quotationDistributorId'];
        if (!quotationDistributorId) {
            quotationDistributorId = this.variablesTask['quotationSuggestionId'];
        }
        return this.folder.quotations.filter((x) => x.id === quotationDistributorId)[0];
    }


    openQuotationModal(): void {
        const initData = {
            reportLine: this.reportLineDataTable.data,
            iris: this.irisDataTable.data,
            loadSparePartCatalog: false,
            hideIris: false,
            loadTypeOfBenefit: false
        };
        const dialogRef = this.matDialog.open(ReportModalComponent, {
            height: '90vh', width: '100vh', minWidth: '80%', data: initData
        });
        dialogRef.afterClosed().subscribe(dataReport => {
                if (!!dataReport) {
                    this.reportLineDataTable.data = dataReport.reportLine;
                    this.irisDataTable.data = dataReport.iris;
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.folderSubscription.unsubscribe();
    }

}
