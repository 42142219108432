import {Pipe, PipeTransform} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {configurationItem} from '../../app/store/configuration/configuration.selectors';
import {Observable} from 'rxjs';

@Pipe({name: 'codeToLabel'})
export class CodeToLabelPipe implements PipeTransform {

    constructor(private store$: Store<AppState>) {
    }

    transform(code: string, codeConfig: string): Observable<string> {
        return this.store$.pipe(select(configurationItem, {configurationItemCode: codeConfig, code: code}));
    }

}
