<h2 mat-dialog-title>{{'FOLDER.DIALOG.TITLE.FETCH_WORK_ORDER_NUMBERS' | translate}}</h2>
<mat-dialog-content class="mb-20 w-100-p" fxLayout="column">
    <mat-form-field appearance="outline">
        <mat-label>{{'FOLDER.DIALOG.CONTENT.WORK_ORDER' | translate}}</mat-label>
        <mat-select
            [(ngModel)]="selectedWorkOrderId">
            <mat-option
                *ngFor="let workOrder of sortByDateAsc(data.workOrders)"
                [value]="workOrder.id">
                {{'FOLDER.DIALOG.CONTENT.WORK_ORDER_OPTION' | translate: {date: workOrder.createdDate, id: workOrder.id}
                }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end end">
    <button mat-button type="reset" mat-stroked-button class="aster_btn_cancel"
            [mat-dialog-close]="false">{{'BUTTON.CANCEL' | translate}}</button>
    <button mat-button
            type="submit" mat-stroked-button
            [disabled]="!selectedWorkOrderId"
            [ngClass]="!selectedWorkOrderId ? 'aster_btn_disabled' : 'aster_btn'"
            [mat-dialog-close]="selectedWorkOrderId">{{'BUTTON.VALIDATE' | translate}}</button>
</mat-dialog-actions>