import {Component, Input, OnInit} from '@angular/core';
import {SupplierContact} from '../../../../../../models/SupplierDocument';

@Component({
  selector: 'app-supplier-contact-card',
  templateUrl: './supplier-contact-card.component.html',
  styleUrls: ['./supplier-contact-card.component.scss']
})
export class SupplierContactCardComponent implements OnInit {

    @Input() contact: SupplierContact;
  constructor() { }

  ngOnInit(): void {
  }

}
