    <mat-card-content>
        <form [formGroup]="waitProductReception.form">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <div fxLayout="row" *ngIf="shipment" class="mb-12">
                <span class="material-icons" style="margin-right: 7px;">local_shipping</span>
                <div>
                    <span class="font-weight-600">{{'CARRIER.TITLE' | translate}} :</span>
                    <span class="font-weight-600"> {{shipment.carrier}}</span>
                </div>
            </div>
            <div fxLayout="row" *ngIf="shipment" class="mb-12">
                <mat-icon svgIcon="bar_code"></mat-icon>
                <div>
                    <span class="font-weight-600">{{'FOLDER.TRACKING_NUMBER' | translate}} :</span>
                    <span class="font-weight-600"> {{shipment.trackingNumber}}</span>
                </div>
            </div>
            <div>
                <mat-radio-group formControlName="status" class="ml-20" labelPosition="after" fxLayout="column"
                                 fxLayoutAlign="start stretch" fxLayoutGap="10px">
                    <mat-radio-button class="full-width-radio" *ngFor="let status of waitProductReception.placeholders"
                                      [value]="status.status">
                        <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                            <div fxFlex>
                                <div>{{ status.value | translate }}</div>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="waitProductReception.form.value.status === 'CLOSE_FOLDER'">
                <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                    <mat-label>{{'FOLDER_CLOSING_REASON_PLACEHOLDER' | translate}}</mat-label>
                    <textarea matInput rows="2" cols="7"
                              [placeholder]="'FOLDER_CLOSING_REASON_PLACEHOLDER' | translate"
                              formControlName="description"></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="end end">
                <button mat-stroked-button (click)="onSubmit()"
                        type="submit"
                        [disabled]="waitProductReception.form.invalid"
                        [ngClass]="waitProductReception.form.invalid ? 'aster_btn_disabled' : 'aster_btn'"
                        >
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>