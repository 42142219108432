
    <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="start">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="12px">
                    <label class="h3 text-bold">
                        {{'FOLDER.REPAIR_IN_PROGRESS.LIST_SPARE_PARTS' | translate}}
                    </label>
                    <button type="button" mat-stroked-button class="aster-btn_border"
                            (click)="openQuotationModal()">
                        <ng-container
                            *ngIf="reportLineDataTable.data.length>0 || irisDataTable.data.length>0;else SHOW_ADD_BUTTON">
                            <mat-icon class="mr-8">edit</mat-icon>
                            {{'BUTTON.EDIT' | translate}}
                        </ng-container>
                        <ng-template #SHOW_ADD_BUTTON>
                            <mat-icon class="mr-8">add</mat-icon>
                            {{'BUTTON.ADD' | translate}}
                        </ng-template>

                    </button>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                    <table mat-table [dataSource]="reportLineDataTable"
                           class="mat-elevation-z2 w-100-p mt-16 mb-16">
                        <ng-container matColumnDef="code">
                            <th mat-header-cell *matHeaderCellDef>  {{'FOLDER.REPAIR_IN_PROGRESS.CODE' | translate}}  </th>
                            <td mat-cell *matCellDef="let sparePart"> {{sparePart.code}} </td>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef>  {{'FOLDER.REPAIR_IN_PROGRESS.TYPE' | translate}}  </th>
                            <td mat-cell
                                *matCellDef="let sparePart"> {{'MODAL.QUOTATION.TYPE.' + sparePart.type | translate}} </td>
                        </ng-container>
                        <ng-container matColumnDef="label">
                            <th mat-header-cell *matHeaderCellDef>  {{'FOLDER.REPAIR_IN_PROGRESS.LABEL' | translate}}  </th>
                            <td mat-cell *matCellDef="let sparePart"> {{sparePart.label}} </td>
                        </ng-container>
                        <ng-container matColumnDef="payer">
                            <th mat-header-cell *matHeaderCellDef>  {{'MODAL.QUOTATION.PAYER' | translate}}   </th>
                            <td mat-cell *matCellDef="let sparePart" > {{sparePart.payer}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="reportLineColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: reportLineColumns;"></tr>
                    </table>

                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                    <label class="h3 text-bold">
                        {{'FOLDER.REPAIR_IN_PROGRESS.IRIS' | translate}}
                    </label>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                    <table mat-table [dataSource]="irisDataTable" class="mat-elevation-z2 w-100-p mt-16 mb-16">
                        <ng-container matColumnDef="symptom">
                            <th mat-header-cell *matHeaderCellDef
                                class="text-center">{{'FOLDER.REPAIR_IN_PROGRESS.SYMPTOM' | translate}} </th>
                            <td mat-cell *matCellDef="let sparePart"
                                class="text-center"> {{sparePart.symptom?.label}} </td>
                        </ng-container>
                        <ng-container matColumnDef="condition">
                            <th mat-header-cell *matHeaderCellDef
                                class="text-center"> {{'FOLDER.REPAIR_IN_PROGRESS.CONDITION' | translate}} </th>
                            <td mat-cell
                                *matCellDef="let sparePart" class="text-center">{{ sparePart.condition.label}} </td>
                        </ng-container>
                        <ng-container matColumnDef="repair">
                            <th mat-header-cell *matHeaderCellDef
                                class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.REPARATION' | translate}} </th>
                            <td mat-cell *matCellDef="let sparePart"
                                class="text-center"> {{sparePart.reparation?.label}} </td>
                        </ng-container>
                        <ng-container matColumnDef="defect">
                            <th mat-header-cell *matHeaderCellDef
                                class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.DEFECT' | translate}} </th>
                            <td mat-cell *matCellDef="let sparePart"
                                class="text-center"> {{sparePart.defect?.label}} </td>
                        </ng-container>
                        <ng-container matColumnDef="section">
                            <th mat-header-cell *matHeaderCellDef
                                class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.SECTION' | translate}} </th>
                            <td mat-cell *matCellDef="let sparePart"
                                class="text-center"> {{sparePart.section?.label}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="irisColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: irisColumns;"></tr>
                    </table>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                <form  fxLayout="column" >
                    <div fxLayout="row" fxLayoutAlign="end end">
                        <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                            <mat-label>{{'FOLDER.COMMENT' | translate}}</mat-label>
                            <textarea matInput rows="4" cols="7"
                                      [(ngModel)]="comment"></textarea>
                        </mat-form-field>
                    </div>
                </form>
            </div>

            <div fxLayoutAlign="end center" class="mt-24">
                <button (click)="onSubmit()" mat-stroked-button
                        class="aster_btn">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </div>
    </mat-card-content>