<ng-container *ngIf="accessoriesLabel?.length > 0">
    <fieldset class="mb-16">
        <legend class="h3 title-fieldset"><mat-icon class="icon-color">warning</mat-icon>
            {{'COMPONENT.ACCESSORIES_LABEL_LIST.ACCESSORIES' | translate}}</legend>
        <mat-list>
            <mat-list-item *ngFor="let label of accessoriesLabel">
                <span class="font-weight-600">- {{label}}</span>
            </mat-list-item>
        </mat-list>
    </fieldset>
</ng-container>