<div *ngIf="!!repairer" fxLayout="row" fxLayoutAlign="space-between center center" fxLayoutGap="20px"
     mat-dialog-title style="font-size: medium">
    <div class="text-bold link-underline">
        {{'FOLDER.REPAIR.DIALOG.TITLE' | translate}}: {{repairer.code}} - {{repairer.label}}
    </div>
    <button mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <form *ngIf="addressForm.form" [formGroup]="addressForm.form" fxLayout="column"
          fxLayoutAlign="start" autocomplete="off" id="form1">

        <div fxLayout="row wrap" gdColumns="repeat(auto-fit, minmax(100%, 1fr))" gdGap="10px"
             formGroupName="countryCode">
            <app-generic-address-form [addressesInput]="this.data?.repairer?.completeAddress" ></app-generic-address-form>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: flex-end;">
    <div>
        <button mat-stroked-button (click)="onClose()" type="button"
                class="h-60 mt-4 mr-8 aster_btn_cancel">
            {{'BUTTON.CANCEL' | translate}}
        </button>
        <button mat-stroked-button type="button"
                [disabled]="isInvalidForm()"
                [ngClass]="'h-60 mt-4 '+( isInvalidForm() ?' aster_btn_disabled':' aster_btn')"
                (click)="validateAddress()">
            {{'BUTTON.VALIDATE' | translate}}
        </button>
    </div>
</mat-dialog-actions>

