
    <div id="error-404" fxLayout="column" fxLayoutAlign="center center">

        <div class="content" fxLayout="column" fxLayoutAlign="center center">

            <div class="error-code">404</div>

            <div class="message mb-48 ">{{"FOLDER.NOT_FOUND" |translate}}</div>

            <button mat-button (click)="gotToFolderList()"
                    class="mt-48 nav-bar-button_new_folder">{{"FOLDER.GO_BACK_LIST" |translate}}
            </button>
        </div>

    </div>
