
    <app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
    <mat-card-content>
        <form [formGroup]="chooseProductShipmentModeForm.form" fxLayout="column"
              *ngIf="chooseProductShipmentModeForm.placeholders"
              fxLayoutAlign="start" (ngSubmit)="onSubmit()" class="mt-25">
            <mat-radio-group formControlName="mode" class="ml-20" fxLayout="column"
                    id="choose_product_shipment_mode"         (ngModelChange)="changeMode()" fxLayoutAlign="start stretch"
                             labelPosition="after" fxLayoutGap="10px">
                <mat-radio-button class="full-width-radio"
                                  *ngFor="let mode of chooseProductShipmentModeForm.placeholders"
                                  [value]="mode.value"
                                  id="{{'choose_product_shipment_mode_' + mode.value}}">
                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" [id]="'choose_product_shipment_mode_'+mode.value+'_value'">
                        <div fxFlex>
                            <div>{{  mode.mode | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>

            <ng-container [ngSwitch]="chooseProductShipmentModeForm.form.value.mode">
                <div *ngSwitchCase="'CUSTOMER_TO_REPAIRER'">
                    <app-generic-address-form [addressesInput]=getCustomerDefaultAddress()></app-generic-address-form>
                </div>
                <div *ngSwitchCase="'CUSTOMER_TO_DISTRIBUTOR'">
                    <app-generic-address-form [addressesInput]=getCustomerDefaultAddress()></app-generic-address-form>
                </div>
                <div *ngSwitchCase="'ALREADY_IN_STORE'" class="ml-32">
                    <mat-radio-group class="ml-20" fxLayout="column" formControlName="siteCode"
                                     [id]="'choose_product_shipment_siteCode'"  fxLayoutAlign="start stretch" labelPosition="after">
                        <mat-radio-button class="radio-site"
                                          *ngFor="let siteCode of sitesOfCurrentUser$ | async"
                                          [value]="siteCode"
                                          id="{{'choose_product_shipment_mode_' + siteCode}}">
                            <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" >
                                <div fxFlex>
                                    <div>
                                        {{siteCode}}  {{ getConfigValueSite(siteCode) ? " - " + getConfigValueSite(siteCode).label : ''}}
                                    </div>
                                </div>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngSwitchCase="'CUSTOMER_TO_SUPPLIER'">
                    <app-generic-address-form [addressesInput]=getCustomerDefaultAddress()></app-generic-address-form>
                </div>
            </ng-container>

            <div fxLayoutAlign="end center" *ngIf="chooseProductShipmentModeForm.form">
                <button mat-stroked-button
                        matTooltip="{{'BUTTON.VALIDATE' | translate}}" type="submit"
                        [disabled]="disabledForm()"
                        [id]="'choose_product_shipment_mode_submitButton'"
                        [ngClass]=" !!disabledForm() ? 'aster_btn_disabled' : 'aster_btn' ">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>