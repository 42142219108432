    <mat-card-content *ngIf="repairStatusForm.form">
        <form [formGroup]="repairStatusForm.form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>{{'FOLDER.TRACKING_NUMBER'|translate}}</mat-label>
                    <input matInput placeholder="{{'FOLDER.TRACKING_NUMBER'|translate}}"
                           formControlName="trackingNumber">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>{{'FOLDER.CARRIER_CODE'|translate}}</mat-label>
                    <mat-select formControlName="carrier">
                        <mat-option *ngFor="let carrierItem of carriers$ | async"
                                    [value]="carrierItem">
                            <span>{{  carrierItem.code + ' - ' + carrierItem.label }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayoutAlign="end center" class="mt-24">
                <button type="submit" mat-stroked-button [disabled]="repairStatusForm.form.invalid"
                        [ngClass]="repairStatusForm.form.invalid ? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>