<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="text-bold">
        {{'PRODUCT_LOCATION' | translate}}</h2>
    <button mat-icon-button class="ml-40" (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>


<mat-dialog-content>
<div  fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field class="py-4 mt-32" fxFlex="40" appearance="outline">
            <mat-label>{{'PRODUCT_LOCATION' | translate}}</mat-label>
            <mat-select [(value)]="newLocation" (selectionChange)="clearPosition($event.value)">
                <mat-option *ngFor="let location of productLocations"
                            [value]="location">
                    {{('PRODUCT_LOCATION_' + location) |translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <ng-container *ngIf="newLocation ==='STORE'">
            <mat-form-field class="py-4 mt-32" appearance="outline" fxFlex="40">
                <mat-label>{{'MODAL.LOCATION.HISTORY.POSITION'|translate}}</mat-label>
                <input matInput placeholder="{{'MODAL.LOCATION.HISTORY.POSITION'|translate}}"
                       id="position" [(ngModel)]="newPosition">
            </mat-form-field>
        </ng-container>
        <div (click)="onChangeLocation(newLocation)" [ngClass]="'cursor-pointer pr-30'" fxLayout="row" fxLayoutGap="5px"
             fxLayoutAlign="end center">
            <mat-icon >edit</mat-icon>
            <span [ngClass]="'link-underline'">{{'CHANGE_PRODUCT_LOCATION' | translate}}
</span>
        </div>
    </div>
   

    <table mat-table [dataSource]="dataSourceLocations" class="mat-elevation-z2 w-100-p mt-16 mb-16">
        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef class="table-header">{{'MODAL.LOCATION.HISTORY.DATE' | translate}}</th>
            <td mat-cell *matCellDef="let locationHistory" >
                {{locationHistory.eventTimestamp | date: (getFormatDate() | translate) }}
            </td>
        </ng-container>
        <ng-container matColumnDef="Location">
            <th mat-header-cell *matHeaderCellDef class="table-header">{{'MODAL.LOCATION.HISTORY.LOCATION' | translate}}</th>
            <td mat-cell
                *matCellDef="let locationHistory">{{'PRODUCT_LOCATION_' + locationHistory.location | translate}}
            </td>
        </ng-container>
        <ng-container matColumnDef="Coordinates">
            <th mat-header-cell *matHeaderCellDef class="table-header">{{'MODAL.LOCATION.HISTORY.COORDINATES' | translate}}
            </th>
            <td mat-cell *matCellDef="let locationHistory">
                {{ locationHistory.coordinates ? (locationHistory.coordinates | formatLocation) : '-' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="Position">
            <th mat-header-cell *matHeaderCellDef class="table-header">{{'MODAL.LOCATION.HISTORY.POSITION' | translate}}
            </th>
            <td mat-cell
                *matCellDef="let locationHistory">{{locationHistory.position}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
<div>
    <mat-paginator *ngIf="!!dataSourceLocations.data"
                   [pageSizeOptions]="[5, 10, 20, 30]"
                   [pageSize]="5"
                   showFirstLastButtons></mat-paginator>
</div>

</div>


</mat-dialog-content>


