import {Quotation} from '../../../models/quotation.model';
import {Observable} from 'rxjs';
import {PayerModel} from '../../../models/payer.model';
import {Order} from '../../../models/order.model';
import {Payer} from '../../../models/enums/payer.enum';
import {Site} from '../../../models/site.model';
import {Product} from '../../../models/product.model';


export class QuotationModalConfig {
    initialQuotation: Quotation;
    initOrder?: Order;
    warrantyCode: string;
    operatingMode: string;
    showFormAddQuotation: boolean;
    titleModal: string;
    folderId: string;
    hideTva$?: Observable<boolean>;
    hideDiscount$?: Observable<boolean>;
    hidePayers$?: Observable<boolean>;
    hidePrice$?: Observable<boolean>;
    showSupplier$?: Observable<boolean>;
    showPurchasePrice$?: Observable<boolean>;
    showMargin$?: Observable<boolean>;
    showTotalUnitPrice$?: Observable<boolean>;
    payers?: PayerModel;
    organizationCode: string;
    context: string;
    product: Product;
    managementSite: Site;
    loadSparePartCatalog = false;
    workforcesRule ? = [];
    loadTypeOfBenefit = false;
    payer?: Payer;
}
