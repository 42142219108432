import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {RuleEvaluationContext} from '../../../../models/rules/RuleEvaluationContext';
import {BackOfficeService} from '../../../../shared/services/back-office.service';
import {Product} from '../../../../models/product.model';
import {currentUser} from '../../../../store/user/user.selectors';
import {take} from 'rxjs/operators';
import {GridComponent} from '@syncfusion/ej2-angular-grids';
import {WarrantyRule} from '../../../../models/warrantyRule.model';

@Component({
    selector: 'app-product-sub-element-warranty',
    templateUrl: './product-sub-element-warranty.component.html',
    styleUrls: ['./product-sub-element-warranty.component.scss']
})
export class ProductSubElementWarrantyComponent implements OnInit {
    @ViewChild('grid') grid: GridComponent;

    @Input() product: Product;
    @Input() purchaseDate: string;

    subElements = [];

    constructor(private store$: Store<AppState>,
                private backOfficeService: BackOfficeService) {
    }

    ngOnInit(): void {
        this.store$.pipe(
            select(currentUser),
            take(1)
        ).subscribe(currentUser => {
            const ruleEvaluationContext = RuleEvaluationContext
                .fromProduct(this.product, currentUser);
            ruleEvaluationContext.purchaseDate = this.purchaseDate;
            this.backOfficeService.getSubElements(ruleEvaluationContext)
                .pipe(take(1))
                .subscribe(subElements => {
                    this.subElements = subElements;
                   // this.getSubElementWarrantyRule(ruleEvaluationContext);
                });
        });

    }

    private getSubElementWarrantyRule(ruleEvaluationContext: RuleEvaluationContext) {
        this.subElements.forEach(subElement => {
            this.backOfficeService.getSubElementWarrantyRule({
                ...ruleEvaluationContext,
                productSubElementCode: subElement.code,
            })
                .pipe(take(1))
                .subscribe(warranty => {
                        this.completeWithWarranty(subElement, warranty);
                    }
                );
        });
    }

    private completeWithWarranty(subElement, warranty: WarrantyRule) {
        if (warranty?.warrantyReasonDetails) {
            subElement.warrantyDuration = warranty?.warrantyReasonDetails?.defaultDuration;
        } else {
            subElement.warranty = this.product.warranty;
        }
        this.grid.refresh();
    }
}
