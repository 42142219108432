<div fxLayout="row" fxLayout.lt-lg="row" fxLayout.lt-md="column" fxLayoutGap="8px" fusePerfectScrollbar >
    <div fxFlex="50">
        <app-public-detail-tabs-card [folder]="folder"></app-public-detail-tabs-card>
    </div>
    <div fxFlex="50">
        <mat-card class="mat-elevation-aster">
        <mat-card-content *ngIf="prepareOrderForm">
            <form [formGroup]="prepareOrderForm" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
                <mat-card-title
                    class="h3 mt-12 mb-24">{{'COMPONENT.REPAIR_IN_PROGRESS' | translate}}</mat-card-title>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch"
                     fxLayoutGap="12px">
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>{{'FOLDER.TRACKING_NUMBER'|translate}}</mat-label>
                        <input matInput placeholder="{{'FOLDER.TRACKING_NUMBER'|translate}}"
                               formControlName="trackingNumber">
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>{{'FOLDER.CARRIER_CODE'|translate}}</mat-label>
                        <mat-select formControlName="carrier">
                            <mat-option *ngFor="let carrierItem of carriers "
                                        [value]="carrierItem">
                                <span>{{  carrierItem.code + ' - ' + carrierItem.label }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </mat-card-content>
        <mat-dialog-actions class="mt-24 mb-0" >
            <div class="w-100-p">
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="end end">
                    <button type="submit" mat-stroked-button (click)="onSubmit()"
                            [disabled]="prepareOrderForm.invalid"
                            [ngClass]="prepareOrderForm.invalid ? 'aster_btn_disabled' : 'aster_btn'">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </div>
        </mat-dialog-actions>

    </mat-card>
    </div>
</div>