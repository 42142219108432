import {Injectable, Renderer2} from '@angular/core';
import {Actions, Effect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {catchError, concatMap, filter, map, mergeMap, skipWhile, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {StartLoading, StopLoading} from '../loader/loader.actions';
import {BehaviorSubject, of} from 'rxjs';
import {UserService} from '../../shared/services/store/user.service';
import {
    FirstLoad,
    LoadInfoCampaignLinks,
    LoadRoles,
    LoadScopes,
    LoadUser,
    Login,
    ResetBusinessLink,
    RolesLoadedSuccessfully,
    RouteToFolderList,
    ScopesLoadedSuccessfully,
    SwitchContext,
    SwitchOrganization,
    UpdateBusinessLinks,
    UserActionType,
    UserLoadedSuccessfully
} from './user.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {Context} from '../../models/enums/context.enum';
import {Router} from '@angular/router';
import {CampaignService} from '../../shared/services/campaign.service';
import {InitializeLanguage, LoadClaimSensibleInfo, SetCountryConfiguration} from '../organization/organization.actions';
import {LoadConfiguration} from '../configuration/configuration.actions';
import {BackOfficeService} from '../../shared/services/back-office.service';
import {currentUser, isFirstLoad} from './user.selectors';
import {KeycloakEventType} from 'keycloak-angular';
import {UpdateDashboardNavigation} from '../dashboard/dashboard.actions';
import {UserState} from './user.state';

@Injectable()
export class UserEffects {



    constructor(private actions$: Actions,
                private store$: Store<AppState>,
                private userService: UserService,
                private campaignService: CampaignService,
                private backOfficeService: BackOfficeService,
                private router: Router) {
    }

    @Effect()
    init$ = this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        filter(() => window.location.href.indexOf('/public/') === -1),
        switchMap(() => this.userService.init()
            .pipe(
                mergeMap(() => this.userService.isLoggedIn()),
                switchMap((isLoggedIn) => [
                    new StartLoading(),
                    isLoggedIn ? new LoadUser() : new Login()
                ])
            ))
    );

    @Effect()
    whenLoggedOut$ = this.userService.events().pipe(
        filter(event => KeycloakEventType.OnAuthLogout === event.type),
        map(() => new Login())
    );

    @Effect()
    login$ = this.actions$.pipe(
        ofType(UserActionType.LOGIN),
        switchMap(() => this.userService.login().pipe(
            map(() => new LoadUser())
        ))
    );

    @Effect()
    loadUser$ = this.actions$.pipe(
        ofType(UserActionType.LOAD_USER),
        concatMap(() => this.userService.loadUser()
            .pipe(
                switchMap(userProfile => {
                    this.userService.lemonUserLoaded$.next(userProfile);
                    return [
                        new UserLoadedSuccessfully(userProfile),
                        new FirstLoad(true),
                        new InitializeLanguage(),
                        new LoadRoles(),
                        new StopLoading()
                    ];
                })
            )
        )
    );

    @Effect()
    loadRoles$ = this.actions$.pipe(
        ofType(UserActionType.LOAD_ROLES),
        map(() => new RolesLoadedSuccessfully(this.userService.getRoles()))
    );

    @Effect()
    switchOrganization$ = this.actions$.pipe(
        ofType(UserActionType.SWITCH_ORGANIZATION),
        concatMap((action: SwitchOrganization) =>
            [
                new LoadScopes(),
                new UpdateDashboardNavigation(),
                new SetCountryConfiguration(action.organization.countryCode),
                new LoadConfiguration(true),
                new RouteToFolderList(),
                new LoadClaimSensibleInfo()
            ])
    );

    @Effect()
    loadScopes$ = this.actions$.pipe(
        ofType(UserActionType.LOAD_SCOPES),
        withLatestFrom(this.store$.select(currentUser)),
        switchMap(([, user]) => this.backOfficeService.getScopes(user)
            .pipe(
                concatMap(scopes => [
                    new ScopesLoadedSuccessfully(scopes),
                    new LoadConfiguration(true)
                ]),
                catchError(err => of(new StopLoading()))
            )
        )
    );

    @Effect()
    switchContext$ = this.actions$.pipe(
        ofType(UserActionType.SWITCH_CONTEXT),
        concatMap((action: SwitchContext) =>
            [
                new LoadScopes(),
                new UpdateDashboardNavigation(),
                action.context === Context.PRODUCT_RECALL
                    ? new LoadInfoCampaignLinks()
                    : new ResetBusinessLink(),
                new RouteToFolderList(),
                new LoadConfiguration(true)
            ]
        )
    );

    @Effect()
    loadInfoCampaignLinks$ = this.actions$.pipe(
        ofType(UserActionType.LOAD_INFO_CAMPAIGN_LINKS),
        switchMap(() => this.campaignService.getInfoCampaignLinks()
            .pipe(
                map(businessLinks => new UpdateBusinessLinks(businessLinks)),
                catchError((error) => {
                    console.error('COULD NOT GET INFO LINKS', error);
                    return of(new ResetBusinessLink());
                })
            ))
    );

    @Effect()
    switchBusinessLink$ = this.actions$.pipe(
        ofType(UserActionType.SWITCH_BUSINESS_LINK),
        map(() => new RouteToFolderList())
    );

    @Effect()
    logout$ = this.actions$.pipe(
        ofType(UserActionType.LOGOUT),
        map(() => this.userService.logout())
    );

    @Effect({dispatch: false})
    routeToFolderList$ = this.actions$.pipe(
        ofType(UserActionType.ROUTE_TO_FOLDER_LIST),
        withLatestFrom(this.store$.select(isFirstLoad)),
        skipWhile(([, isFirstLoad]) => isFirstLoad),
        tap(() => this.router.navigate(['/folder/list']))
    );


}
