<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<form [formGroup]="unknownProductForm" (ngSubmit)="onSubmit()">
    <div fxLayout="row" fxLayoutAlign="space-between center" cdkDragHandle>
        <div fxFlex>
            <h1>
                <mat-icon>class</mat-icon>
                {{'PRODUCT.SEARCH.UNKNOWN' | translate}}
            </h1>
        </div>

        <button mat-icon-button [mat-dialog-close] aria-label="Close">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div fxLayout="row"
         fxLayoutGap="30px"
         fxLayoutAlign="space-between center"
         class="w-100-p">
        <mat-form-field class="filter w-100-p" floatLabel="never" fxFlex="100" appearance="outline">
            <mat-label>{{'UNKNOWN.PRODUCT.LABEL' | translate}}</mat-label>
            <input matInput [formControlName]="this.LABEL_CONTROL"
                   [placeholder]="'UNKNOWN.PRODUCT.LABEL'| translate" required>
        </mat-form-field>
    </div>


    <div fxLayout="column" class="w-100-p">
        <div *ngFor="let row of this.rows" fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="start center">
            <div *ngFor="let nomenclatureField of row;" [fxFlex]="row.length === 1 ? '100%' : '50%'">
                <mat-form-field appearance="outline" class="w-100-p" [id]="nomenclatureField.code">
                    <mat-icon matPrefix>filter_{{this.extractNumber(nomenclatureField.code)}}</mat-icon>
                    <mat-label>{{'UNKNOWN.PRODUCT.' + nomenclatureField.code |translate}}</mat-label>
                    <input type="text" matInput [id]="nomenclatureField.code"
                           [required]="nomenclatureField.required"
                           [placeholder]="'UNKNOWN.PRODUCT.'+ nomenclatureField.code | translate"
                           [formControlName]="nomenclatureField.code"
                           [matAutocomplete]="auto1">
                    <mat-autocomplete #auto1="matAutocomplete" [autoActiveFirstOption]="true" [displayWith]="valueMapper">
                        <mat-option *ngFor="let family of this.filteredfamiliesValuesMap.get(nomenclatureField.code)"
                                    [id]="family.code"
                                    [value]="family">
                            {{family.code}} - {{family.label}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
    </div>

        <div fxLayout="column" class="w-100-p" *ngIf="currentUser.organizationCode !== 'BL'">
            <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="start center">
                <mat-form-field class="w-100-p" appearance="outline">
                    <mat-label>{{'PRODUCT.PREVIEW_CARD.LABELS.SUPPLIER'|translate}}</mat-label>
                    <input type="text" matInput [id]="'suppliers'"
                           [placeholder]="'PRODUCT.PREVIEW_CARD.LABELS.SUPPLIER'|translate"
                           [formControlName]="SUPPLIER_CONTROL"
                           [matAutocomplete]="auto"
                           [required]="true">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySupplierFn">
                        <mat-option *ngFor="let supplier of filteredSuppliers | async"
                                    (onSelectionChange)="onSelectSupplier($event);"
                                    [id]="supplier.code"
                                    [value]="supplier.code">
                            {{supplier.code}} - {{supplier.label}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>


        <div fxLayout="row"
         fxLayoutGap="30px"
         fxLayoutAlign="space-between center"
         class="w-100-p">

        <mat-form-field class="filter w-100-p" floatLabel="never" fxFlex="50" appearance="outline">
            <mat-label>{{'UNKNOWN.PRODUCT.MODEL' | translate}}</mat-label>
            <input matInput [formControlName]="this.MODEL_CONTROL" [placeholder]="'UNKNOWN.PRODUCT.MODEL' | translate">
        </mat-form-field>

        <mat-form-field class="filter w-100-p" floatLabel="never" fxFlex="50" appearance="outline">
            <mat-label>{{'UNKNOWN.PRODUCT.BRAND' | translate}}</mat-label>
            <input matInput  inputControl [formControlName]="this.BRAND_CONTROL" [placeholder]="'UNKNOWN.PRODUCT.BRAND' | translate">
        </mat-form-field>
    </div>

    <div fxLayoutAlign="end center" fxLayoutGap="10px" class="mt-12">
        <button [mat-dialog-close] mat-stroked-button color="accent"
                class="py-12 px-24 mb-8 mr-8 font-weight-900 font-size-30">
            {{'BUTTON.CLOSE'  | translate}}
        </button>

        <button type="submit" mat-stroked-button color="accent" [disabled]="!this.unknownProductForm.valid"
                class="py-12 px-24 mb-8 mr-8 font-weight-900 font-size-30">
            {{'BUTTON.VALIDATE' | translate}}
        </button>
    </div>
</form>
</div>