    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutAlign="start">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
        </div>
    </div>

<mat-card-content class="w-100-p px-20">

    <form *ngIf="customerDetailForm.form" [formGroup]="customerDetailForm.form" (ngSubmit)="onSubmit()"
              id="form1">
            <mat-radio-group formControlName="existNewCustomerDetails" class="ml-20" fxLayoutGap="10px"
                             labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch">
                <mat-radio-button class="full-width-radio"
                                  *ngFor="let exist of customerDetailForm.placeholders"
                                  [value]="exist.existCustomer">
                    <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                        <div fxFlex>
                            <div>{{ exist.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>

            <ng-container *ngIf="customerDetailForm.form.get(['existNewCustomerDetails']).value ">
                <div gdGap="16px"
                     gdAreas="gender gender |   firstName  lastName|  phone email"
                     gdAreas.lt-lg="gender gender |firstName  lastName | phone email"
                     gdAreas.lt-md="gender | firstName | lastName | phone | email ">

                    <div gdArea="gender" fxLayout="row wrap" fxLayoutAlign="start center">
                        <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                            <mat-label>{{'CUSTOMER.CIVILITY'|translate}}</mat-label>
                            <mat-select formControlName="gender">
                                <mat-option
                                    *ngFor="let civility of civilitySelectBoxOptions"
                                    [value]="civility.key">
                                    <span>{{ civility.label | translate }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div gdArea="firstName" fxLayout="row wrap" fxLayoutAlign="start center">
                        <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                            <mat-label>{{'CUSTOMER.FIRST_NAME'|translate}}</mat-label>
                            <input matInput placeholder="{{'CUSTOMER.FIRST_NAME'|translate}}"
                                   formControlName="firstName">
                        </mat-form-field>
                    </div>
                    <div gdArea="lastName" fxLayout="row wrap" fxLayoutAlign="start center">
                        <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                            <mat-label>{{'CUSTOMER.LAST_NAME'|translate}}</mat-label>
                            <input matInput placeholder="{{'CUSTOMER.LAST_NAME'|translate}}"
                                   formControlName="lastName">
                        </mat-form-field>
                    </div>
                    <div gdArea="phone" fxLayout="row wrap" fxLayoutAlign="start center">
                        <mat-form-field class="py-4" appearance="outline" fxFlex="100" floatLabel="always">
                            <mat-label>{{'CUSTOMER.PHONE'|translate}}</mat-label>
                            <input type="text" matInput ng2TelInput formControlName="phone"
                                   [ng2TelInputOptions]="{ preferredCountries: ['fr'],
           localizedCountries: { fr: 'France' }, utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'}"
                                   (countryChange)="onCountryChange($event)"
                                   (hasError)="hasError($event)"
                                   placeholder="{{'CUSTOMER.PHONE'|translate}}"/>
                            <mat-error
                                *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)">{{"FROM.ERROR.MESSAGE.PHONE_NUMBER" |translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div gdArea="email" fxLayout="row wrap" fxLayoutAlign="start center">
                        <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                            <mat-label>{{'CUSTOMER.EMAIL'|translate}}</mat-label>
                            <input matInput placeholder="{{'CUSTOMER.EMAIL'|translate}}"
                                   formControlName="email"
                                   type="email" isEmail>
                        </mat-form-field>
                    </div>

                </div>
            </ng-container>

            <div fxLayoutAlign="space-between center">
                <button type="button" mat-button matTooltip="{{'BUTTON.PREVIEW.STEP' | translate}}"
                        (click)="getPreviewTask()"
                        class="h-60 mt-16 mr-16">
                    <mat-icon>keyboard_return</mat-icon>
                    {{'BUTTON.PREVIEW.STEP' | translate}}
                </button>
                <ng-container>
                    <button mat-stroked-button type="submit"
                            [disabled]="customerDetailForm.form.get(['existNewCustomerDetails']).value===null"
                            [ngClass]=" customerDetailForm.form.get(['existNewCustomerDetails']).value===null?' aster_btn_disabled':' aster_btn'"
                            class="h-60 mt-4 mb-20">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </ng-container>
            </div>
        </form>
</mat-card-content>
