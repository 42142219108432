<div class="text-center" [id]="id">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
        <h2>
            <mat-icon>class</mat-icon>
            {{title}}
        </h2>
        <button mat-icon-button class="ml-40" (click)="onCloseModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <div class="w-100-p mb-20" fxLayout="row" fxLayoutAlign="start start">
            <div *ngIf="!!description" [innerHTML]="description"></div>
        </div>
        <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start" >
            <ng-container *ngIf="withCommentReason">
                <mat-form-field appearance="outline" fxFlex="45">
                    <mat-label>{{reasonTitle | translate}}</mat-label>
                    <mat-select [id]="'reason'" (selectionChange)="changeCommentValidator($event.value)"
                                formControlName="reason" required>
                        <mat-option [id]="reason" *ngFor="let reason of reasonsChange" [value]="reason">
                            {{'SPARE_PART.SWAP_OR_CLOSE.' + reason | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                <mat-label>{{commentMessage}}</mat-label>
                <textarea matInput inputControl rows="4" cols="7"
                          [placeholder]="commentMessage"
                          formControlName="comment"></textarea>
            </mat-form-field>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions class="mt-16">
        <div class="w-100-p" fxLayout="row" fxLayoutAlign="end end">
            <button mat-button (click)="onCloseModal()" class="aster-modal-btn_cancel" type="reset">
                {{cancelButtonLabel}}
            </button>
            <button type="submit" mat-stroked-button (click)="validateForm()"
                    [disabled]="form.invalid"
                    [ngClass]="form.invalid? 'aster_btn_disabled' : 'aster_btn'">
                {{confirmButtonLabel}}
            </button>
        </div>
    </mat-dialog-actions>
</div>
