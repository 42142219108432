<table mat-table [dataSource]="quotationSummary" class="w-100-p">
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef
            class="text-center"> {{'QUOTATION.CONTENT.TYPE' | translate}}</th>
        <td mat-cell *matCellDef="let quotationLine"
            class="text-left"> {{'QUOTATION.CONTENT.TYPE.' + quotationLine.type | translate}}</td>
    </ng-container>
    <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef
            class="text-center"> {{'QUOTATION.CONTENT.NUMBER' | translate}}</th>
        <td mat-cell *matCellDef="let quotationLine" class="text-left"> {{quotationLine.number}} </td>
    </ng-container>
    <ng-container matColumnDef="totalPriceHT">
        <th mat-header-cell *matHeaderCellDef
            class="text-center"> {{'QUOTATION.CONTENT.TOTAL_HT' | translate}}</th>
        <td mat-cell *matCellDef="let quotationLine"
            class="text-left"> {{quotationLine.totalHT |currency:currency :'symbol-narrow' | space}} </td>
    </ng-container>
    <ng-container matColumnDef="totalPrice">
        <th mat-header-cell *matHeaderCellDef
            class="text-center"> {{'QUOTATION.CONTENT.TOTAL_TTC' | translate}}</th>
        <td mat-cell *matCellDef="let quotationLine"
            class="text-left"> {{quotationLine.totalTTC |currency:currency :'symbol-narrow' | space}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>