import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder, IFolderUpdateRequest} from '../../../../../models/folder.model';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FolderSubjectService} from '../../../folder-subject.service';
import {CommentType} from '../../../../../models/enums/CommentType.enum';
import {FolderService} from '../../../../../shared/services/folder.service';
import {Comment} from '../../../../../models/comment.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';

@Component({
    selector: 'app-validate-supplier-delay',
    templateUrl: './validate-supplier-delay.component.html',
    styleUrls: ['./validate-supplier-delay.component.scss']
})
export class ValidateSupplierDelayComponent implements OnInit {

    @Input() folder: Folder;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();

    folderId: string;
    comment: string;
    buttonValid = false;
    supplierExceededAgreement: any = {
        form: FormGroup,
        placeholders: []
    };
    commentForm: FormGroup;

    constructor(private route: ActivatedRoute,
                private store$: Store<AppState>,
                private folderSubjectService: FolderSubjectService,
                private folderService: FolderService,
    ) {
    }

    ngOnInit(): void {
        this.initForm();
        this.folderId = this.route.snapshot.parent.params.folderId;
    }

    private initForm(): void {
        this.supplierExceededAgreement.form = new FormGroup({
            options: new FormControl(null, Validators.required),
            sendProduct: new FormControl(null),
        });
        this.commentForm = new FormGroup({
            comment: new FormControl(null, [Validators.required])
        });
        this.supplierExceededAgreement.placeholders = [
            {
                status: 'true',
                value: 'FOLDER.SUPPLIER_RESPONSE_RECEIVED',
            },
            {
                status: 'false',
                value: 'FOLDER.SUPPLIER_CONFIRM_SWAP',
            },
        ];
    }


    radioChange($event: any): void {
        if ($event) {
            this.buttonValid = true;
        }

    }
    activateButton(): boolean{
        if (this.supplierExceededAgreement.form.get('options') === 'true'){
            return true;
        }
        return this.supplierExceededAgreement.form.value.options === 'true' || this.buttonValid;
    }
    onSubmit(): void {
        this.store$.dispatch(new StartLoading());
        if (this.supplierExceededAgreement.form.value.options === 'true') {
            this.inputMap.emit({
                supplierResponseReceived: this.supplierExceededAgreement.form.value.options
            });
        } else {
            this.inputMap.emit({
                supplierResponseReceived: this.supplierExceededAgreement.form.value.options,
                destroyedByCustomer: this.supplierExceededAgreement.form.value.sendProduct === 'true' ? 'false' : 'true',
                sendProduct: this.supplierExceededAgreement.form.value.sendProduct,
            });
        }
        if (!!this.commentForm.get('comment').value){

            const folderUpdateRequest = {
                agentComment: {
                    content: this.commentForm.get('comment').value,
                    type: CommentType.COMMENT_GENERAL_AGENT
                } as Comment
            } as IFolderUpdateRequest;
            this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then((folder) => {
                this.folder = folder;
                this.folderSubjectService.folderLoaded(folder);
            });
        }
        this.store$.dispatch(new StopLoading());

    }
}
