import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html'
})
export class FuseConfirmDialogComponent {
    title = 'Confirm';
    message = 'Are you sure?';
    confirmButtonLabel = 'BUTTON.CONFIRM';
    cancelButtonLabel = 'BUTTON.CANCEL';

    constructor(public dialogRef: MatDialogRef<FuseConfirmDialogComponent>) {
    }

}
