    <mat-card-content>
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>

        <form [formGroup]="productDiagnosticForm.iris" (ngSubmit)="addIntoIrisList()" autocomplete="off">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                <div fxFlex fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>Symptom</mat-label>
                        <mat-select placeholder="symptom" formControlName="symptom">
                            <mat-option>
                            <ngx-mat-select-search formControlName="symptomCodeCtrl"
                                                   [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                                   [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>
                                </mat-option>
                            <mat-option *ngFor="let symptom of filteredSymptomList | async" [value]="symptom">
                                <span [attr.class]="'flag-icon h-24 w-32 flag-icon-' + symptom.code | lowercase"></span>
                                <span class="ml-8 ngx-select_label">{{ symptom.code + '-' + symptom.label }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>Condition</mat-label>

                        <mat-select placeholder="symptom" formControlName="condition">
                            <mat-option>
                            <ngx-mat-select-search formControlName="conditionCodeCtrl"
                                                   [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                                   [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>
                                </mat-option>
                            <mat-option *ngFor="let condition of filteredConditionList | async" [value]="condition">
                                <span
                                    [attr.class]="'flag-icon h-24 w-32 flag-icon-' + condition.code | lowercase"></span>
                                <span class="ml-8 ngx-select_label">{{condition.code + '-' + condition.label }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayoutAlign="end center">
                <div>
                    <button mat-stroked-button
                            class="aster-btn_border">
                        <mat-icon>add</mat-icon>
                        {{'BUTTON.ADD' | translate}}
                    </button>
                </div>
            </div>


            <table mat-table [dataSource]="irisDataTable" class="mat-elevation-z2 w-100-p mt-16 mb-16">
                <ng-container matColumnDef="symptom">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center"> {{'FOLDER.REPAIR_IN_PROGRESS.SYMPTOM' | translate}} </th>
                    <td mat-cell *matCellDef="let sparePart"> {{sparePart.symptom?.label}} </td>
                </ng-container>
                <ng-container matColumnDef="condition">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.CONDITION' | translate}} </th>
                    <td mat-cell
                        *matCellDef="let sparePart">{{ sparePart.condition.label}} </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center">  {{'BUTTON.ACTION' | translate}}</th>
                    <td mat-cell *matCellDef="let indexLine = index;">
                        <button matSuffix mat-icon-button color="warn"
                                type="button" class="mx-8"
                                (click)="deleteLineDataIris(indexLine)">
                            <mat-icon>delete_forever</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="irisColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: irisColumns;"></tr>
            </table>
            <mat-radio-group formControlName="status" class="ml-20" labelPosition="after" fxLayout="column"
                             fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button class="full-width-radio" *ngFor="let status of productDiagnosticForm.placeholders"
                                  [value]="status.status">
                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                        <div fxFlex>
                            <div>{{ status.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="productDiagnosticForm.iris.value.status === 'USAGE_FAULT'">
                <div fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px"  class="ml-20">
                    <label class="h3  text-bold" *ngIf="!isChooseOtherCarrier">{{'PRODUCT_RETURNED_WITH_CHRONOPOST' |translate}}</label>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center">
                        <mat-checkbox (change)="chooseOtherCarrier()"
                                      [checked]="isChooseOtherCarrier">
                            {{'CHOOSE_OTHER_CARRIER' | translate}}
                        </mat-checkbox>
                    </div>
                    <div *ngIf="isChooseOtherCarrier" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px"  class="ml-20">
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>{{'FOLDER.TRACKING_NUMBER'|translate}}</mat-label>
                            <input matInput placeholder="{{'FOLDER.TRACKING_NUMBER'|translate}}"
                                   formControlName="trackingNumber">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>{{'FOLDER.CARRIER_CODE'|translate}}</mat-label>
                            <input matInput placeholder="{{'FOLDER.CARRIER_CODE'|translate}}"
                                   formControlName="carrier">
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
            <div>
                <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                    <mat-label>{{'FOLDER.COMMENT' | translate}}</mat-label>
                    <textarea matInput rows="2" cols="7"
                              [placeholder]="'FOLDER.COMMENT' | translate"
                              formControlName="description"></textarea>
                </mat-form-field>
            </div>
        </form>

        <div fxLayout="row" fxLayoutAlign="end end">
            <button mat-stroked-button (click)="onSubmit()"
                    type="submit"
                    [disabled]="productDiagnosticForm.iris.invalid || (!dataSourceIris || dataSourceIris.length === 0)"
                    [ngClass]="productDiagnosticForm.iris.invalid || (!dataSourceIris || dataSourceIris.length === 0)? 'aster_btn_disabled' : 'aster_btn'">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>
    </mat-card-content>