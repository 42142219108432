    <mat-card-content class="w-100-p">
        <div fxLayout="row" fxLayoutAlign="start center">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="mt-16">
            <form *ngIf="quantityForm" [formGroup]="quantityForm" class="w-100-p" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="outline" class="w-100-p">
                    <mat-label>{{'COMPONENT.PRODUCT_QUANTITY_CHECK.INPUT.QUANTITY' | translate}}</mat-label>
                    <input matInput type="number"
                           placeholder="{{'COMPONENT.PRODUCT_QUANTITY_CHECK.INPUT.QUANTITY' | translate}}"
                           formControlName="quantity">
                </mat-form-field>
            </form>
        </div>
    </mat-card-content>

    <div class="mt-24">
        <div fxLayout="row" fxLayoutAlign="end end">
            <button mat-stroked-button (click)="onSubmit()"
                    type="submit" [ngClass]="!quantityForm.invalid? 'aster_btn': 'aster_btn_disabled' "
                    class="h-60 mt-4 aster_btn">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>
    </div>
