import {Action} from '@ngrx/store';

export enum LoaderActionType {
    START_LOADING =  '[Loader] Start Loading',
    STOP_LOADING =  '[Loader] Stop Loading'
}

export class StartLoading implements Action {
    readonly type = LoaderActionType.START_LOADING;
}

export class StopLoading implements Action {
    readonly type  = LoaderActionType.STOP_LOADING;
}

export type LoaderActions = StartLoading | StopLoading;
