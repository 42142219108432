<div class="mat-calendar-header">
    <div class="mat-calendar-controls">
        <button cdkAriaLive="polite" class="mat-calendar-period-button mat-button" mat-button="" type="button"
                (click)="currentPeriodClicked()"
                [attr.aria-label]="periodButtonLabel"><span
            class="mat-button-wrapper">  {{periodButtonText}}
            <div class="mat-calendar-arrow" [class.mat-calendar-invert]="calendar.currentView != 'month'"></div></span>
            <div class="mat-button-ripple mat-ripple"
            ></div>
            <div class="mat-button-focus-overlay"></div>
        </button>

        <div class="mat-calendar-spacer"></div>

        <ng-content></ng-content>

        <button class="mat-calendar-previous-button mat-icon-button"
                mat-icon-button type="button"
                [disabled]="!previousEnabled()" (click)="previousButton()"
                [attr.aria-label]="prevButtonLabel">
            <span class="mat-button-wrapper"></span>
            <div class="mat-button-ripple mat-ripple mat-button-ripple-round"></div>
            <div class="mat-button-focus-overlay"></div>
        </button>


        <button class="mat-calendar-next-button mat-icon-button"
                mat-icon-button type="button"
                (click)="nextButton()" [disabled]="!nextEnabled()"
                [attr.aria-label]="nextButtonLabel">
            <span class="mat-button-wrapper"></span>
            <div class="mat-button-ripple mat-ripple mat-button-ripple-round"></div>
            <div class="mat-button-focus-overlay"></div>
        </button>

    </div>
</div>
