import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Folder} from '../../../../models/folder.model';
import {MatTableDataSource} from '@angular/material/table';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {Observable} from 'rxjs';
import {currentLanguage} from '../../../../store/organization/organization.selectors';

@Component({
    selector: 'app-old-folder-reparations',
    templateUrl: './old-folder-reparations.component.html',
    styleUrls: ['./old-folder-reparations.component.css']
})
export class OldFolderReparationsComponent implements OnInit {
    dataSourceOldFolders = new MatTableDataSource<Folder>([]);
    displayedColumns = ['INCREMENTAL_REFERENCE', 'REFERENCE', 'CREATE_DATE', 'SYMPTOM', 'ACTION'];
    selectedFolder: Folder;
    currentLanguage$: Observable<string>;

    constructor(private dialogRef: MatDialogRef<OldFolderReparationsComponent>,
                private store$: Store<AppState>,
                @Inject(MAT_DIALOG_DATA) public data: Folder[]) {
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        if (this.data && this.data.length > 0) {
            this.dataSourceOldFolders.data = this.data;
        }
    }

    onClose(): void {
        this.dialogRef.close({createNew: true});
    }

    validate(): void {
        this.dialogRef.close({selected: this.selectedFolder});
    }

    changeSelected(element: Folder): void {
        this.selectedFolder = element;
    }

    getUrl(element: Folder): string {
        return 'folder/' + element.id;
    }
}
