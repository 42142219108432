import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder, IFolderUpdateRequest, Payment} from '../../../../../../models/folder.model';
import {Observable, of, Subscription} from 'rxjs';
import {TypeOfServiceEnum} from '../../../../../../models/typeOfService.model';
import {MatTableDataSource} from '@angular/material/table';
import {Quotation, QuotationLine} from '../../../../../../models/quotation.model';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {WorkflowService} from '../../../../../../shared/services/workflow.service';
import {FileService} from '../../../../../../shared/services/file.service';
import {QuotationService} from '../../../../../../shared/services/quotation.service';
import {MatDialog} from '@angular/material/dialog';
import {TaskVariables} from '../../../task.variables';
import {QuotationModalConfig} from '../../../../../../shared/generic/quotation-modal/quotationModal.model';
import {QuotationModalComponent} from '../../../../../../shared/generic/quotation-modal/quotation-modal.component';
import {saveAs} from 'file-saver';
import {FileInfo} from '../../../../../../models/file-info.model';
import * as uuid from 'uuid';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {currentLanguage} from '../../../../../../store/organization/organization.selectors';
import {SiteType} from '../../../../../../models/enums/siteType.enum';
import {Payer} from '../../../../../../models/enums/payer.enum';
import {PaymentReason} from '../../../../../../models/enums/paymentReason.enum';
import {QuotationType} from '../../../../../../models/enums/quotationType.enum';
import {AmountUtils} from '../../../../../../shared/utils/amount-utils';
import {TranslateService} from '@ngx-translate/core';
import {Vat} from '../../../../../../models/vat.model';
import {ConfirmationWithoutClientQuotationComponent} from '../confirmation-without-client-quotation/confirmation-without-client-quotation.component';
import {FuseConfirmDialogComponent} from '../../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import * as moment from 'moment/moment';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';


@Component({
    selector: 'app-quotation-details',
    templateUrl: './quotation-details.component.html',
    styleUrls: ['./quotation-details.component.scss']
})
export class QuotationDetailsComponent implements OnInit, OnDestroy {

    @ViewChild('dropzone') dropzone: ElementRef;
    @Output() inputMapSubComponent = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;

    customerAcceptQuotationProcess: boolean;
    showResetVariableButton: boolean;

    folder: Folder;
    private folderSubscription: Subscription;
    currentLanguage$: Observable<string>;

    displayedColumns: string[] = ['type', 'number', 'totalPrice'];
    displayedColumnsAttachment: string[] = ['name', 'date', 'action'];

    private sparePartsTypes = Object.keys(TypeOfServiceEnum);
    attachmentQuotationDataTable = new MatTableDataSource<any>();
    quotationSummary = new MatTableDataSource<any>();
    currentDate: string = moment().format();

    quotationInProgress = false;
    quotation: Quotation;
    quotationId: String;
    quotationDraftId: string;
    attachments = new Array<any>();

    fileUploadForm: any = {
        file: null,
        hasFile: false
    };

    quotationChoiceForm: any = {
        form: null,
        placeholders: []
    };
    operatingMode: string;
    currency: string;
    warrantyCode: string;
    vat: Vat;
    transformedToClientQuotation = false;

    constructor(private folderSubjectService: FolderSubjectService,
                private folderService: FolderService,
                private translateService: TranslateService,
                private workflowService: WorkflowService,
                private fileService: FileService,
                private quotationService: QuotationService,
                private store$: Store<AppState>,
                private matDialog: MatDialog) {
    }


    ngOnInit(): void {
        this.vat = JSON.parse(this.variablesTask[TaskVariables.vat]);
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.currency = this.variablesTask[TaskVariables.currency];
        this.customerAcceptQuotationProcess = this.variablesTask[TaskVariables.quotationProcess] === 'CLIENT';
        if (!this.customerAcceptQuotationProcess) {
            this.initQuotationChoseForm();
            this.quotation = Quotation.getEmptyQuotation(this.currency);
            this.folderSubscription = this.folderSubjectService.folder$.subscribe(folder => {
                this.folder = folder;
                this.operatingMode = this.folder.operationMode.valueOf();
                this.warrantyCode = this.folder.newWarranty.warranty.valueOf();
            });
            this.initQuotationVariable();
        }
    }

    initQuotationVariable(): void {
        if (this.folder.quotations) {
            this.getDataQuotation();
            this.showResetVariableButton = false;
        }
    }

    private getLastQuotation(): Quotation {
        return this.folder.quotations.length > 0 ? this.folder.quotations.reduce((quotation1, quotation2) => {
            return quotation1.userAction.actionDate > quotation2.userAction.actionDate ? quotation1 : quotation2;
        }) : null;
    }

    private getDataQuotation(): void {
        // recuperer quotation
        this.quotationId = this.variablesTask[TaskVariables.quotationId];
        this.quotation = this.getLastQuotation();
        this.attachmentQuotationDataTable.data = this.quotation.quotationAttachments;
        this.updateQuotationFees();
        this.initDataTable();
    }

    private initQuotationChoseForm(): void {
        this.quotationChoiceForm.form = new FormGroup({
            quotationAccepted: new FormControl(null, Validators.required),
        });
        this.quotationChoiceForm.placeholders = [
            {
                quotationAccepted: 'true',
                value: 'FOLDER.WORKFLOW.QUOTATION_ACCEPTATION.ACCEPT_QUOTATION',
            },
            {
                quotationAccepted: 'false',
                value: 'FOLDER.WORKFLOW.QUOTATION_ACCEPTATION.REFUSE_QUOTATION',
            },
        ];
    }

    ngOnDestroy(): void {
        if (!!this.folderSubscription) {
            this.folderSubscription.unsubscribe();
        }
    }

    private initDataTable(): void {
        const dataSource = [];
        let numberSpareParts = 0;
        let totalTTC = 0;
        this.quotation.totalPrice = {
            value: 0,
            currency: this.currency
        };
        for (const sparePartsCode of this.sparePartsTypes) {
            numberSpareParts = this.getNumberSparePartsOfQuotation(sparePartsCode);
            totalTTC = this.getTotalPriceSparePartsOfQuotation(sparePartsCode);
            if (numberSpareParts !== 0) {
                dataSource.push({
                    type: sparePartsCode,
                    number: numberSpareParts,
                    totalTTC: totalTTC
                });
                this.quotation.totalPrice.value += totalTTC;
            }
        }
        this.quotationSummary = new MatTableDataSource<any>(dataSource);
    }

    private updateQuotationFees(): void {
        const feesPaymentQuotation = this.folder.payments.find(value => value.paymentReason.startsWith(PaymentReason.QUOTATION_FEES));
        if (feesPaymentQuotation) {
            const feesQuotation = this.quotation.quotationLines.length > 0
                ? this.quotation.quotationLines.find(value => value.type.startsWith(QuotationType.QUOTATION_FEES))
                : null;
            if (feesQuotation) {
                let value = feesQuotation.totalLinePrice.value - feesPaymentQuotation.amount;
                value = AmountUtils.convertTTCtoHT(value, Number(this.vat.rate));
                feesQuotation.priceAmount.value = value;
            } else {
                this.quotation.quotationLines.push(this.fromPayementToQuotationLine(feesPaymentQuotation));
            }
        }
    }

    private fromPayementToQuotationLine(feesPaymentQuotation: Payment): QuotationLine {
        const quotationFeesAmount = feesPaymentQuotation.amount;
        const quotationLine: QuotationLine = {
            code: '-',
            label: this.translateService.instant('MODAL.QUOTATION.FEES_QUOTATION'),
            payer: Payer.CLIENT,
            quantity: 1,
            priceAmount: {
                value: AmountUtils.convertTTCtoHT(quotationFeesAmount, Number(this.vat.rate)) * -1,
                currency: this.currency
            },
            vat: this.vat,
            type: QuotationType.QUOTATION_FEES,
            discount: 0,
            totalLinePrice: {
                value: quotationFeesAmount * -1,
                currency: this.currency
            }
        };
        return quotationLine;
    }

    getNumberSparePartsOfQuotation(sparePartsCode): number {
        return this.quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === sparePartsCode ? total + 1 : total, 0);
    }

    getTotalPriceSparePartsOfQuotation(sparePartsCode): any {
        return this.quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === sparePartsCode ? total + this.quotationService.computeTotalPriceQuotationLine(line) : total, 0);
    }

    completeTask(): void {

        if (this.quotationChoiceForm.form.value.quotationAccepted === 'true' && !this.transformedToClientQuotation) {
            const dialogRef = this.matDialog.open(ConfirmationWithoutClientQuotationComponent);
            dialogRef.afterClosed().subscribe(
                data => {
                    if (!!data) {
                        this.quotationInProgress = true;
                        this.updateQuotationAndFireWorkflow();
                    }
                }
            );
        } else if (this.quotationChoiceForm.form.value.quotationAccepted === 'true' && this.transformedToClientQuotation) {
            this.quotationInProgress = true;
            this.updateQuotationAndFireWorkflow();
        } else {
            this.inputMapSubComponent.emit({
                quotationAccepted: this.quotationChoiceForm.form.value.quotationAccepted
            });
        }
    }

    private updateQuotationAndFireWorkflow() {
        if (!this.variablesTask['quotationDistributorId']) {
            this.quotationDraftId = uuid.v4();
        } else {
            this.quotationDraftId = this.quotation.id;
        }

        this.quotation.id = this.quotationDraftId;
        this.store$.dispatch(new StartLoading());
        this.folderService.updateQuotationToFolder(this.folder.id, this.attachments, this.quotation).subscribe(value => {
            this.inputMapSubComponent.emit({
                quotationId: this.quotation.id,
                quotationAccepted: this.quotationChoiceForm.form.value.quotationAccepted
            });
            this.store$.dispatch(new StopLoading());
        });
    }

    openQuotationModal(): void {
        const quotationModalConfig: QuotationModalConfig = {
            showFormAddQuotation: false,
            initialQuotation: this.quotation,
            warrantyCode: this.warrantyCode,
            operatingMode: this.operatingMode,
            titleModal: 'MODAL.QUOTATION.EDIT_QUOTATION',
            folderId: this.folder.id,
            organizationCode: this.folder.organization.code,
            context: this.folder.context,
            product: this.folder.product,
            hideTva$: of(true),
            managementSite: this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE),
            loadSparePartCatalog: false,
            loadTypeOfBenefit: false,
            payer: Payer.CLIENT
        };
        const dialogRef = this.matDialog.open(QuotationModalComponent, {
            height: '90vh', width: '100vh', minWidth: '80%', data: quotationModalConfig
        });
        dialogRef.afterClosed().subscribe(quotation => {
                if (!this.variablesTask['quotationDistributorId']) {
                    this.quotationDraftId = uuid.v4();
                } else {
                    this.quotationDraftId = this.quotation.id;
                }
                if (!!quotation) {
                    this.quotation.id = this.quotationDraftId;
                    this.quotation.quotationLines = quotation.quotationLines;
                    this.quotation.discount = quotation.discount;
                    this.quotation.totalPrice = !!quotation.totalPrice ? quotation.totalPrice : null;
                    this.setQuotationTarget();
                    this.store$.dispatch(new StartLoading());
                    this.updateQuotation();
                    this.initDataTable();
                }
                dialogRef.componentInstance.ngOnDestroy();

            }
        );
    }

    private setQuotationTarget() {
        if (this.quotation.quotationLines
            .filter(value => value.type ===  QuotationType.QUOTATION_FEES)
            .some(value =>
            (value.payer === Payer.CLIENT && value.totalLinePrice.value > 0))) {
            this.quotation.target = Payer.CLIENT;
        } else {
            this.quotation.target = Payer.DISTRIBUTOR;
        }
    }

    updateQuotation(): void {
        const folderUpdateRequest: IFolderUpdateRequest = {
            quotation: this.quotation
        };
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
            this.folderSubjectService.folderLoaded(folder);
            this.folder = folder;
            this.workflowService.updateQuotationDistributorId(this.folder.id, this.quotation.id).subscribe(() => {
                this.variablesTask['quotationDistributorId'] = this.quotation.id;
                this.store$.dispatch(new StopLoading());
            }, () => {
                this.store$.dispatch(new StopLoading());
            });
            this.transformedToClientQuotation = true;
        }).catch(reason => {
            console.log('ERROR Update Folder with id :' + this.folder.id);
            this.store$.dispatch(new StopLoading());
        });
    }

    onFilesAdded($event: any): void {
        this.fileUploadForm.hasFile = true;
        this.fileUploadForm.file = $event[0].name;
        const fileChange = this.fileService.onFileChange($event);
        fileChange.reader.onload = () => {
            const blobFile = this.fileService.dataURItoBlob(fileChange.reader.result.toString().split(',')[1]);
            const newAttachement = {file: blobFile, info: $event[0]};
            this.attachments.push(newAttachement);
            this.attachmentQuotationDataTable.data = this.attachmentQuotationDataTable.data.concat(newAttachement);
            // @ts-ignore
            this.dropzone.reset();
            this.fileUploadForm.hasFile = false;
        };
    }


    removeFile(fileId): void {
        const dialog = this.matDialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });
        dialog.componentInstance.title = this.translateService.instant('CONFIRMATION.MODAL.DELETE_DIALOG.TITLE');
        dialog.componentInstance.message = this.translateService.instant('FOLDER.FILE.DELETE_DIALOG.MESSAGE');
        dialog.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.DELETE');
        dialog.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialog.afterClosed().subscribe(isDelete => {
            if (isDelete) {
                if (this.quotation.quotationAttachments.length !== 0 && !fileId.info) {
                    this.folderService.deleteAttachmentFileFolder(this.folder.id, fileId).then(resFile => {
                        this.folder.attachments = this.folder.attachments.filter(file => file.id !== fileId);
                        this.quotation.quotationAttachments = this.quotation.quotationAttachments.filter(file => file !== fileId);
                        this.attachmentQuotationDataTable.data = this.attachmentQuotationDataTable.data.filter(attachment => attachment !== fileId);
                    });
                } else {
                    this.attachmentQuotationDataTable.data = this.attachmentQuotationDataTable.data.filter(attachment => attachment !== fileId);
                }
            }
        });
    }

    getFileInfo(fileId: string): FileInfo {
        return this.folder.attachments.find(file => file.id.startsWith(fileId));
    }

    downloadFile(fileId: string): void {
        this.fileService.getAttachmentFile(fileId).subscribe(value => {
            saveAs(value, this.getFileInfo(fileId).name);
        });
    }

    stopPropagation(): boolean {
        return this.quotationInProgress || this.quotationChoiceForm.form.invalid || this.quotation.quotationLines.length <= 0 || this.quotation?.totalPrice?.value < 0;
    }

}
