    <mat-card-content *ngIf="form">
        <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <app-workflow-status-progress-bar taskTimeoutDuration="{{getTaskDuration()}}"
                                              currentStatusDate="{{getStatusDate()}}">
            </app-workflow-status-progress-bar>
            <repairer-task-title [folder]="folder" [instructionUserTask]="instructionUserTask" [productLocation]="variablesTask.productLocation"></repairer-task-title>
            <fieldset>
                <legend class="h3 title-fieldset">{{'COMPONENT.DIAGNOSIS_IN_PROGRESS.BREAKDOWN' | translate}}</legend>
                <ng-container>
                    <mat-radio-group formControlName="breakDownStatus" class="ml-20" labelPosition="after" fxLayout="column"
                                     fxLayoutAlign="start stretch" fxLayoutGap="10px">
                        <mat-radio-button [id]="button.status" class="full-width-radio"
                                          *ngFor="let button of breakDownButtons"
                                          [value]="button.status">
                            <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                <div fxFlex>
                                    <div>{{ button.value | translate }}</div>
                                </div>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-container>
            </fieldset>
            <ng-container *ngIf="form.value.breakDownStatus === 'BREAKDOWN_CONFIRMED'">
                <ng-container *ngIf="warrantyCode === 'SG'">
                    <fieldset>
                        <legend
                            class="h3 title-fieldset">{{'COMPONENT.DIAGNOSIS_IN_PROGRESS.WARRANTY' | translate}}</legend>
                        <ng-container>
                            <mat-radio-group formControlName="status" class="ml-20"
                                             labelPosition="after"
                                             fxLayout="column"
                                             fxLayoutAlign="start stretch" fxLayoutGap="10px">
                                <mat-radio-button [id]="button.status" class="full-width-radio"
                                                  *ngFor="let button of warrantyButtons"
                                                  [value]="button.status">
                                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                        <div fxFlex>
                                            <div>{{ button.value | translate }}</div>
                                        </div>
                                    </div>
                                </mat-radio-button>
                            </mat-radio-group>

                            <div *ngIf="form.value.status === 'WARRANTY_DISCLAIMER'" class="ml-20">
                                <mat-form-field class="py-4 mt-16" fxFlex="50" appearance="outline">
                                    <mat-label>{{'COMPONENT.DIAGNOSIS_IN_PROGRESS.WARRANTY_DISCLAIMER_REASON' | translate}}</mat-label>
                                    <mat-select formControlName="warrantyDisclaimerOption">
                                        <mat-option *ngFor="let option of warrantyDisclaimerOptions"
                                                    [value]="option">
                                            {{('COMPONENT.DIAGNOSIS_IN_PROGRESS.' + option) |translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-container>
                    </fieldset>
                </ng-container>

                <fieldset>
                    <legend class="h3 title-fieldset">{{'COMPONENT.DIAGNOSIS_IN_PROGRESS.PRODUCT' | translate}}</legend>
                    <ng-container>
                        <mat-radio-group formControlName="productStatus" class="ml-20" labelPosition="after"
                                         fxLayout="column"
                                         fxLayoutAlign="start stretch" fxLayoutGap="10px">
                            <mat-radio-button [id]="button.status" class="full-width-radio"
                                              *ngFor="let button of productButtons"
                                              [value]="button.status">
                                <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                    <div fxFlex>
                                        <div>{{ button.value | translate }}</div>
                                    </div>
                                </div>
                            </mat-radio-button>
                        </mat-radio-group>
                    </ng-container>
                </fieldset>
            </ng-container>

            <app-comment-area [commentForm]="form.get('comment')"></app-comment-area>

            <div fxLayoutAlign="end center" class="mt-24">
                <button type="submit" mat-stroked-button [disabled]="form.invalid"
                        [ngClass]="form.invalid ? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>
