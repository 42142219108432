
    <app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
    <mat-card-content class="mt-25">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50px" fxLayout.lt-sm="column">
            <div *ngIf="watchDates()" fxFlex="50">
                <mat-card>
                    <mat-calendar (selectedChange)="onDateChanged($event)"
                                  (click)="changeDateCellColor($event)"
                                  [dateFilter]="filterDate"
                                  [dateClass]="setClass()"
                                  [minDate]="today"></mat-calendar>
                </mat-card>
            </div>
            <ng-container *ngIf="hasError">
                <p>{{'COMPONENT.BOOK_APPOINTMENT.LOADING_ERROR'|translate}}</p>
            </ng-container>

            <ng-container *ngIf="selectedDate" >
                <div fxLayout="row wrap" class="scrolling-list-timeslots" >
                    <div *ngFor="let timeSlot of timeslots" fxLayoutGap="5px">
                        <button mat-stroked-button type="button"
                                (click)="selectTimeSlot(timeSlot)"
                                [ngClass]="timeSlot===selectedTimeSlot?'aster_btn':'timeslot-btn'">
                            {{timeSlot.startTime.toString().substring(0, 5)}}
                            - {{timeSlot.endTime.toString().substring(0, 5)}}
                        </button>
                    </div>

                </div>
            </ng-container>
        </div>

        <div fxLayout="row" fxLayoutAlign="end end" class="mt-24" fxLayoutGap="10px">
            <div>
                <button type="button" mat-button matTooltip="{{'BUTTON.CHANGE_OTHER_OPERATION_MODE' | translate}}"
                        id="panne_confirmed_previewButton" (click)="getPreviewTask()"
                        class="aster-btn_border">
                    {{'BUTTON.CHANGE_OTHER_OPERATION_MODE' | translate}}
                </button>
            </div>

            <div >
                <button mat-stroked-button
                        matTooltip="{{'BUTTON.VALIDATE' | translate}}"
                        [disabled]="isFormInvalid()"
                        [ngClass]="isFormInvalid() ? 'aster_btn_disabled' : 'aster_btn' "
                        class="py-8 px-24 font-weight-900 font-size-30"
                        (click)="completeTask()">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </div>
    </mat-card-content>