import {Component} from '@angular/core';

@Component({
    selector: 'app-spare-parts-order-in-progress',
    templateUrl: './spare-parts-order-in-progress.component.html',
    styleUrls: ['./spare-parts-order-in-progress.component.css']
})
export class SparePartsOrderInProgressComponent {

    // todo to be implement with the new logic of spare part odrer
    constructor() {
    }

}
