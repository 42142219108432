
    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start center">
            <div>
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>
        </div>
        <ng-container>
            <div class="mt-24">
                <div fxLayout="column" fxLayoutAlign="start start">
                        <div [innerHTML]="getDescription() | async"></div>
                </div>
                    <div fxLayout="row" fxLayoutAlign="end">
                        <button mat-stroked-button (click)="redirectToPartnerSite()"
                                type="submit"
                                [ngClass]="'h-60 mt-12 aster_btn'">
                            {{'BUTTON.CLICK_HERE' | translate}}
                        </button>
                    </div>

            </div>
        </ng-container>
    </mat-card-content>