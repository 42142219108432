import {Injectable} from '@angular/core';
import {GLOBAL} from '../../app-config';
import {HttpClient} from '@angular/common/http';
import {ITechnician} from '../../models/itinerant/technician.model';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TechnicianService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api/resources/`;

    constructor(public httpClient: HttpClient) {
    }

    getTechnician(technicianId: string): Observable<ITechnician> {
        return this.httpClient.get<ITechnician>(`${this.path}technician/${technicianId}`);
    }

}
