<mat-radio-group [(ngModel)]="selectedSubElement">
    <mat-radio-button #button
                      class="radio-button"
                      *ngFor="let entry of subElementsWarranties | keyvalue"
                      [value]="entry.key"
                      (click)="checkStateAndEmit($event, button)">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="overflow-text"
             [matTooltip]="entry.key.code | codeToLabel: 'SAV.CONFIG.SUB_ELEMENTS' | async"
             matTooltipPosition="above">
            <ng-container *ngIf="isUnderWarranty(entry.value); else OUT_OF_WARRANTY">
                <mat-icon class="s-20 teal-600-fg no-margin">
                    beenhere
                </mat-icon>
            </ng-container>

            <ng-template #OUT_OF_WARRANTY>
                <mat-icon class="s-20 rotate-270 warn-A700-fg no-margin">
                    backspace
                </mat-icon>
            </ng-template>
            <div>
                {{entry.key.label.length > maxNumberOfSubElement ? (entry.key.label | slice:0:maxNumberOfSubElement) + '...' : entry.key.label}}
            </div>
        </div>
    </mat-radio-button>
</mat-radio-group>
