    <mat-card-content fxLayout="column">

        <div>
            <app-workflow-status-progress-bar taskTimeoutDuration="{{maxDurationReceptionSparePart}}"
                                              currentStatusDate="{{statusDate}}">
            </app-workflow-status-progress-bar>
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
        </div>
        <div fxLayout="row"  class="w-100-p px-10 mt-12" fxLayoutGap="20px">
            <div fxLayout="row"  class="flex-grow" fxLayoutGap="4px" >
                <span class="font-weight-600">{{'ORDER.STATUS' | translate}} : </span>
                <span  [ngClass]="hasException ? 'red-color':'black-color'">{{'ORDER.TEXT.'+ order?.orderStatus | translate}} </span>
            </div>
            <div fxLayout="row"  fxLayoutGap="4px" class="cursor-pointer" *ngIf="hasException">
                <span class="link-underline" (click)="confirmUpdateStatus()">{{ 'COMPONENT.WAITING_SPARE_PARTS.EDIT.STATUS' | translate }}</span>
                <mat-icon matTooltip="{{ 'COMPONENT.WAITING_SPARE_PARTS.HELP.TEXT' | translate }}">error_outline</mat-icon>
            </div>
        </div>
        <app-ordered-content *ngIf="!!orderedSpareParts" class="mb-24" [withStatus]="true"
                             [isUnderWarranty]="isSG"
                             [isSparePartReceived]="sparePartReceived"
                             [orderedSpareParts]="orderedSpareParts"></app-ordered-content>

        <mat-checkbox
            [(ngModel)]="sparePartReceived"
        (change)="updateOrderStatus()">
            {{'BUTTON.SPARE_PART_RECEIVED' | translate}}
        </mat-checkbox>

        <div class="w-100-p  mt-24">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">

                <ng-container *ngIf="isSG ;else IS_HG">
                    <ng-container *ngIf="orderIsExpired">
                        <span class="info link"
                              (click)="openConfirmationModal()">  {{'BUTTON.SWITCH_TO_SWAP' | translate}}</span>
                    </ng-container>
                </ng-container>
                <ng-template #IS_HG>
<!--                    si la variable n'existe pas c parcequ'il utilise l'ancien WF-->
                    <ng-container *ngIf="!!maxDurationReceptionSparePart">
                        <button mat-button (click)="cancelOrder()" style="min-width: auto" class="aster-modal-btn_cancel"
                                type="reset">
                            {{'BUTTON.CANCEL_ORDER' | translate}}
                        </button>
                    </ng-container>
                </ng-template>
                <ng-container>
                    <div class="w-100-p" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
                        <button mat-stroked-button
                                type="submit"
                                (click)="onSubmit()"
                                [disabled]="!sparePartReceived"
                                [ngClass]="!sparePartReceived ? 'aster_btn_disabled' : 'aster_btn'">
                            {{'BUTTON.VALIDATE' | translate}}
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </mat-card-content>