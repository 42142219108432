import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Injectable({
    providedIn: 'root'
})
export class ConfigDisplayColumnsTableService {

    configDisplayColumns = 'configDisplayColumnsTable';

    constructor(@Inject(LOCAL_STORAGE) private localStorage: StorageService) {
    }

    public setConfigDisplayColumns(key, dataColumn): void {
        if (this.localStorage.get(this.configDisplayColumns)) {
            const dataConfig = this.localStorage.get(this.configDisplayColumns);
            dataConfig[key] = dataColumn;
            this.localStorage.set(this.configDisplayColumns, dataConfig);
        } else {
            this.localStorage.set(this.configDisplayColumns, {key: dataColumn});
        }
    }

    public getConfigDisplayColumns(key): string[] {
        if (this.localStorage.get(this.configDisplayColumns)) {
            const dataConfig = this.localStorage.get(this.configDisplayColumns);
            if (dataConfig[key]) {
                return dataConfig[key];
            }
        }
        return null;
    }

    public showColumnsTable(key, ruleDataSource, columnName: string): void {
        if (ruleDataSource.columnsToDisplay.includes(columnName)) {
            ruleDataSource.columnsToDisplay.splice(ruleDataSource.columnsToDisplay.indexOf(columnName), 1);
        } else {
            ruleDataSource.columnsToDisplay.splice(ruleDataSource.columnsToDisplay.length - 1, 0, columnName);
        }
        this.setConfigDisplayColumns(key, ruleDataSource.columnsToDisplay);
    }

    public moveOrderColumnsDisplay(key, ruleDataSource, positionDragDrop: CdkDragDrop<string[]>): void {
        moveItemInArray(ruleDataSource.allColumnsName, positionDragDrop.previousIndex, positionDragDrop.currentIndex);
        let columnsToDisplay = ruleDataSource.columnsToDisplay;
        columnsToDisplay = ruleDataSource.allColumnsName.filter(column => columnsToDisplay.includes(column));
        columnsToDisplay.splice(ruleDataSource.columnsToDisplay.indexOf('ACTIONS'), 1);
        columnsToDisplay.push('ACTIONS');
        ruleDataSource.columnsToDisplay = columnsToDisplay;
        this.setConfigDisplayColumns(key, ruleDataSource.columnsToDisplay);
    }

    public showConfigDisplayedColumns(ruleDataSource, key: string): void {
        const dataDisplayColumns = this.getConfigDisplayColumns(key);
        if (dataDisplayColumns) {
            ruleDataSource.columnsToDisplay = dataDisplayColumns;
            const columnsHide = ruleDataSource.allColumnsName.filter(column => !ruleDataSource.columnsToDisplay.includes(column));
            ruleDataSource.allColumnsName = [...dataDisplayColumns, ...columnsHide];
        }
    }
}
