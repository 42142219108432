import {CountryConfiguration} from '../../models/country.model';
import {ClaimSensibleInfo} from '../../models/claim-sensible/claim-sensible-api-info.model';

export class OrganizationState {
    operationMode: string;
    templateId: string;
    margin: number;
    enableStockClaim: boolean;
    claimWithoutCustomer: boolean;
    languageKey: string;
    countryConfiguration: CountryConfiguration;
    claimSensibleAPIInfo: ClaimSensibleInfo;
    dashboardInfo: DashboardInfo;
}

export class DashboardInfo {
    dashboardDataSourceId: string;
    dashboardSiteId: string;
}

export const initialOrganizationState = {
    enableStockClaim: false,
    claimWithoutCustomer: false,
    countryConfiguration: {
        currency: 'EUR',
        countryCode: 'FR',
        vats: [],
        legalForms: []
    }
} as OrganizationState;
