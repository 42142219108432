import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../../../models/product.model';
import {Observable} from 'rxjs';
import {Item} from '../../../models/invoice.model';
import {FolderCreationService} from '../../../main/folder/create-form/folder-creation.service';
import {WarrantyRule} from '../../../models/warrantyRule.model';
import {isLeroyMerlin, isNeitherProductRecallNorReparationContext} from '../../../store/user/user.selectors';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {Constants} from '../../../Constants';

@Component({
    selector: 'app-product-preview-card-all-details',
    templateUrl: './product-preview-card-all-details.component.html',
    styleUrls: ['./product-preview-card-all-details.component.scss']
})
export class ProductPreviewCardAllDetailsComponent implements OnInit {

    @Input() product: Product | Item;
    @Input() purchaseDate: string;
    @Input() isEditableQuantity: boolean;
    @Input() isFromInvoice: boolean;
    maxQuantity: number;
    quantity: number;
    warranty$: Observable<WarrantyRule>;
    isLeroyMerlin$: Observable<boolean>;
    isSupplierUnknown: boolean;

    isNeitherProductRecallNorReparationContext$: Observable<boolean>;

    constructor(private folderCreationService: FolderCreationService,
                private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.isLeroyMerlin$ = this.store$.select(isLeroyMerlin);
        this.isSupplierUnknown = this.product?.supplier?.code === Constants.UNKNOWN_SUPPLIER;
        this.isNeitherProductRecallNorReparationContext$ = this.store$.pipe(select(isNeitherProductRecallNorReparationContext));
        this.initProductQuantity();
        this.warranty$ = this.folderCreationService.productWarranty$;
    }

    maxQuantityMessage = () => this.maxQuantity ? '(max : ' + this.maxQuantity + ')' : '';

    updateQuantity(quantity): void {
        this.folderCreationService.productQuantityChanged(quantity);
    }

    private initProductQuantity(): void {
        if (this.isFromInvoice) {
            this.quantity = this.product['quantity'];
            this.maxQuantity = this.quantity;
            if (this.quantity !== 1) {
                return;
            }
        }
        this.quantity = 1;
        this.updateQuantity(this.quantity);
    }

}
