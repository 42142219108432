import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Folder, IFolderUpdateRequest} from '../../../../../../models/folder.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {TaskVariables} from '../../../task.variables';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SparePartStock, StockDetails} from '../../../../../../models/spare-parts/stock.model';
import {SelectionModel} from '@angular/cdk/collections';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {FuseConfirmDialogComponent} from '../../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {finalize, skipWhile, switchMap, take, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {from, Subject, Subscription} from 'rxjs';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {SiteType} from '../../../../../../models/enums/siteType.enum';
import {FileService} from '../../../../../../shared/services/file.service';
import {MultiImageModalComponent} from 'app/main/multi-image-modal/multi-image-modal.component';
import {Image, Price, RelatedSparePart, RelatedSparePartRequest, Supplier} from 'app/models/spare-parts/relatedSparePart.model';
import {SparePartService} from '../../../../../../shared/services/spare-part.service';
import {currentUser, isLeroyMerlin} from '../../../../../../store/user/user.selectors';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {GLOBAL} from '../../../../../../app-config';
import * as _ from 'lodash';
import {SwitchOperatingModeModalComponent} from '../../../../../../shared/generic/switch-operating-mode/switch-operating-mode-modal.component';
import {RegimeWarrantyEnum} from '../../../../../../models/warrantyRule.model';
import {RuleEvaluationContext} from '../../../../../../models/rules/RuleEvaluationContext';
import {BackOfficeService} from '../../../../../../shared/services/back-office.service';
import {ModOpDetails} from '../../../../../../models/mod.op.list.details';
import {ConfigurationReferential} from '../../../../../../models/configurationReferential.model';
import {allConfiguration} from '../../../../../../store/configuration/configuration.selectors';
import {ConfigCodeMapsEnum} from '../../../../../../shared/services/configuration-item-enum';
import * as moment from 'moment';
import {UnreferencedSparePartModalComponent} from '../unreferenced-spare-part-modal/unreferenced-spare-part-modal.component';
import {SparePartView} from '../../../../../../models/spare-parts/spare-part.model';
import {Site} from '../../../../../../models/site.model';
import {countryCode, currency, margin, vats} from 'app/store/organization/organization.selectors';
import {StocksAvailabilityResponse} from '../../../../../../models/spare-parts/stocksAvailabilityResponse.model';
import {AmountUtils} from '../../../../../../shared/utils/amount-utils';
import {DatePipe} from '@angular/common';
import {Order, OrderItem} from '../../../../../../models/order.model';
import {OmsService} from '../../../../../../shared/services/oms.service';
import {OrderSubjectService} from '../../../../../../shared/services/order-subject.service';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {UserState} from '../../../../../../store/user/user.state';
import {GrowthbookAttributes} from '../../../../../../shared/features/growthbook-attributes';
import {AppFeatures} from '../../../../../../shared/features/app-features';
import {GrowthbookService} from '../../../../../../shared/services/growthbook.service';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';
import {ProductFamilyType} from '../../../../../../models/enums/productFamilyType.enum';


@Component({
    selector: 'app-choose-spare-parts-to-order',
    templateUrl: './choose-spare-parts-to-order.component.html',
    styleUrls: ['./choose-spare-parts-to-order.component.scss']
})
export class ChooseSparePartsToOrderComponent extends Unsubscriber implements OnInit, OnDestroy, AfterViewInit {

    destroy$ = new Subject<void>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();

    placeholder = GLOBAL.sparePartsPlaceHolder;
    dataSource = new MatTableDataSource<any>();
    displayedColumns: string[] = ['reference', 'label', 'supplier', 'stock', 'image', 'pricettc', 'action', 'quantity', 'edit'];
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    isStockLoading = true;
    isSparePartsAvailable;
    isLeroyMerlin = false;
    selection = new SelectionModel<RelatedSparePart>(true, []);
    stockSubscription: Subscription;
    isUnderWarranty: boolean;
    operatingModeList: any[];
    modeOpConfigList: ConfigurationReferential[] = [];
    suppliers: Supplier[] = [];
    enableToAddUnreferencedSparePart: boolean;
    private fullNameOfCurrentUser: string;
    private siteCodeOfCurrentUser: string;
    private siteOfCurrentUser: Site;
    countryCode: string;
    stockAvailable = true;
    tva: string;
    order = new Order();
    currency: string;
    orderItemsMap: Map<string, OrderItem> = new Map<string, OrderItem>();
    orderItemsSelected: string[] = [];
    isLoading = false;
    sellingPriceSubscription: Subscription;
    defaultMargin: number;

    constructor(private matDialog: MatDialog,
                private store$: Store<AppState>,
                private translateService: TranslateService,
                private sparePartService: SparePartService,
                private backOfficeService: BackOfficeService,
                private omsService: OmsService,
                private orderSubjectService: OrderSubjectService,
                private folderSubjectService: FolderSubjectService,
                private folderService: FolderService,
                private datePipe: DatePipe,
                private fileService: FileService,
                private growthBookService: GrowthbookService) {
        super();
    }

    ngOnInit(): void {
        this.initOrder();
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => {
            this.currency = currency;
        });
        this.anotherSubscription = this.store$.pipe(select(margin)).subscribe(value => { this.defaultMargin = value; });

        this.anotherSubscription = this.store$.pipe(select(isLeroyMerlin)).subscribe(value => this.isLeroyMerlin = value);
        this.anotherSubscription = this.store$.pipe(select(countryCode))
            .subscribe(code => this.countryCode = code);
        this.initDataTable();
        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.MOD_OP}))
            .subscribe(it => this.modeOpConfigList = it);
        this.isEnableToAddUnreferencedSparePart();
        this.isUnderWarranty = this.folder.newWarranty.warranty === 'SG';
        this.anotherSubscription = this.store$
            .pipe(
                select(vats),
                skipWhile(vats => vats.length === 0)
            )
            .subscribe(vats => {
                this.tva = vats.find(value => value.standard === true).rate;
            });


    }

    private initOrder() {
        this.anotherSubscription = this.orderSubjectService.order$.subscribe(order => {
            this.order = order;
             this.orderItemsMap = this.order.orderItems.reduce((map, item) => {
                !!item.supplier ? map.set(item.code + '-' + item.supplier?.code, item) : map.set(item.code, item);
                return map;
            }, new Map<string, OrderItem>());
        });
    }

    private isEnableToAddUnreferencedSparePart() {
        this.anotherSubscription = this.store$.pipe(
            select(currentUser),
            take(1),
            switchMap((user: UserState) => {
                if (user) {
                    const attributes: GrowthbookAttributes = {
                        organizationCode: user.organizationCode,
                        context: user.context
                    };
                    return from(this.growthBookService.isOn(AppFeatures.ADD_UNREFERENCED_SPARE_PART_BUTTON, attributes));
                }
            })
        ).subscribe((isFeatureActive: boolean) => {
            this.enableToAddUnreferencedSparePart = isFeatureActive;
        });
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.paginator.page.subscribe(page => {
            this.fetchSellingPriceOfDisplayedSpareParts();
            this.fetchStocksOfDisplayedSpareParts();
            this.fetchImagesOfDisplayedSpareParts();
        });
    }

    initDataTable(): void {
        this.store$.dispatch(new StartLoading());
        const request: RelatedSparePartRequest = {
            productCode: this.folder.product?.code,
            supplierCode: this.folder.product?.supplier?.code,
            eanCode: this.folder.product?.codeEAN,
            brand: this.folder.product?.brand,
            family1: RuleEvaluationContext.getFamilyByType(this.folder.product?.families, ProductFamilyType.FAMILY),
            withMedia: this.isLeroyMerlin,
            countryCode: this.countryCode,
            managementSiteCode: this.getManagementSiteCode()
        };

        this.sparePartService.getRelatedSpareParts(request).subscribe(relatedSpareParts => {
            if (relatedSpareParts.length > 0) {
                const relatedSparePartsFormatted = _.flatMap(relatedSpareParts, sparePart => {
                        if (sparePart.suppliers?.length > 0) {
                            const sparePartsWithSupplier = sparePart.suppliers.map(supplier => ({...sparePart, supplier}));
                            sparePartsWithSupplier.filter(sparePart =>
                                this.completeLineByOrderItem(sparePart.code + '-' + sparePart.supplier.code, sparePart));
                            return sparePartsWithSupplier;
                        }
                        this.completeLineByOrderItem(sparePart.code, sparePart);
                        return sparePart;
                    }
                );
                this.isSparePartsAvailable = true;
                this.dataSource = this.variablesTask[TaskVariables.nomenclatures] ?
                    new MatTableDataSource<RelatedSparePart>(this.filterSparePartsByNomenclature(relatedSparePartsFormatted, this.variablesTask[TaskVariables.nomenclatures])) :
                    new MatTableDataSource<RelatedSparePart>(relatedSparePartsFormatted);
                this.dataSource.paginator = this.paginator;
                this.store$.dispatch(new StopLoading());
                this.fetchStocksOfDisplayedSpareParts();
                this.fetchImagesOfDisplayedSpareParts();
                this.fetchSellingPriceOfDisplayedSpareParts();
            } else {
                this.isSparePartsAvailable = false;
                this.store$.dispatch(new StopLoading());
            }
        }, () => {
            this.store$.dispatch(new StopLoading());
        }, () => {
                this.orderItemsMap?.forEach((orderItem, key) => {
                    if (!this.orderItemsSelected.includes(key)) {
                        const relatedUnreferencedSparePart = this.buildRelatedUnreferencedSparePartFromOrderItem(orderItem);
                        this.addUnreferencedSparePartToDatasource(relatedUnreferencedSparePart);
                        this.addImageToUnreferencedSparePart(relatedUnreferencedSparePart);
                        this.selectSparePart(relatedUnreferencedSparePart);
                    }
                });

        });
    }

    private completeLineByOrderItem(key: string, sparePart: RelatedSparePart) {
        const orderItem =  this.orderItemsMap.get(key);
        if (!!orderItem  && !this.orderItemsSelected.includes(key)) {
            sparePart.quantity = orderItem.quantityOrdered;
            this.selectSparePart(sparePart);
            this.updateSparePartPrice(orderItem, sparePart);
            this.orderItemsSelected.push(key);
        }
    }

    private updateSparePartPrice(orderItem: OrderItem, sparePart: RelatedSparePart) {
        sparePart.sellingPrice = {
            value: orderItem.price,
            currency: orderItem.currency
        };
        sparePart.isUpdated = this.isFreeSparePart(sparePart) && orderItem.price > 0;

    }

    onImageError(event: ErrorEvent) {
        (event.target as HTMLImageElement).src = this.placeholder;
    }

    changeValue(element: any, value): void {
        const quantity = Number(value.target.value);
        element.quantity = quantity;
        if (quantity === null) {
            this.selection.deselect(this.selection.selected.filter(sparePart => sparePart.code === element.code)[0]);
        }
    }

    filterSparePartsByNomenclature(response, nomenclatures): any {
        for (let i = 0; i < nomenclatures.length; i++) {
            if (!!nomenclatures[i].ray) {
                response = response.filter(it => nomenclatures[i].ray === it.nomenclature.ray);
            }
            if (!!nomenclatures[i].subRay) {
                response = response.filter(it => nomenclatures[i].subRay === it.nomenclature.subRay);
            }
            if (!!nomenclatures[i].nomenclatureType) {
                response = response.filter(it => nomenclatures[i].nomenclatureType === it.nomenclature.nomenclatureType);
            }
            if (!!nomenclatures[i].nomenclatureSubType) {
                response = response.filter(it => nomenclatures[i].nomenclatureSubType === it.nomenclature.nomenclatureSubType);
            }
            if (!!nomenclatures[i].nomenclatureSegment) {
                response = response.filter(it => nomenclatures[i].nomenclatureSegment === it.nomenclature.nomenclatureSegment);
            }
        }
        return response;
    }

    private fetchStocksOfDisplayedSpareParts(): void {
        const displayedData = this.dataSource._pageData(this.dataSource.data);
        const sparePartCodes = displayedData.map(data => data.code.trim());
        const managementSiteCode = this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE).code;

        if (!!this.stockSubscription) {
            this.stockSubscription.unsubscribe();
        }

        this.isStockLoading = true;
        this.stockSubscription = this.sparePartService.getStocks(sparePartCodes, managementSiteCode)
            .subscribe(stocks => {
                    this.merge(displayedData, stocks);
                    this.isStockLoading = false;
                },
                () => this.isStockLoading = false,
                () => {
                    this.store$.dispatch(new StopLoading());
                    this.isStockLoading = false;
                });
    }

    private fetchSellingPriceOfDisplayedSpareParts(): void {
        const displayedData = this.dataSource._pageData(this.dataSource.data);

        if (!!this.sellingPriceSubscription) {
            this.sellingPriceSubscription.unsubscribe();
        }

        displayedData
            .forEach(element => {
                const orderItem = this.orderItemsMap?.get(this.generateKey(element));
                if (!orderItem) {
                    const purchasePrice = element.supplier?.unitPrice?.value;
                    const currency = element.supplier?.unitPrice?.currency;
                    if (typeof purchasePrice === 'number' && purchasePrice > 0) {
                        this.getComputedSellingPrice(purchasePrice, element, currency);
                    } else {
                        element.sellingPrice = !!element.approximatePrice ? element.approximatePrice : {value: 0};
                    }
                }

            });
    }

    private generateKey(element: RelatedSparePart): string {
        return element.code + (element.supplier ? `-${element.supplier.code}` : '');
    }

    private getComputedSellingPrice(purchasePrice: number, element, currency) {
        element.isLoading = true;
        const approximatePrice = !!element.approximatePrice.value ? element.approximatePrice.value : 0;
        this.sellingPriceSubscription = this.backOfficeService.computeSellingPrice(purchasePrice, this.defaultMargin, approximatePrice)
            .subscribe(data => {
                    element.sellingPrice = {
                        value: data.sellingPrice || 0,
                        currency: currency
                    };
                    element.marginPercentage = data.marginPercentage || 0;
                    element.purchasePrice = purchasePrice || 0;
                    element.isLoading = false;
                },
                () =>   element.isLoading = false,
                () => {
                    this.store$.dispatch(new StopLoading());
                    element.isLoading = false;
                });
    }

    private fetchImagesOfDisplayedSpareParts() {
        const displayedData = this.dataSource._pageData(this.dataSource.data);

        displayedData
            .filter(sparePart => !!sparePart.images && sparePart.images.length > 0)
            .forEach(sparePart => {
                this.getImage(sparePart);
            });
    }

    private getImage(sparePart) {
        const localImages = sparePart.images.filter(image => !!image.id);
        const remoteImages = sparePart.images.filter(image => !!image.fullUrl);

        if (localImages.length > 0) {

            this.fileService.getAttachmentFile(localImages[0].id).subscribe(imageAsBlob => {
                sparePart.images[0].isLoading = true;
                const reader = new FileReader();
                reader.readAsDataURL(imageAsBlob);
                reader.onloadend = () => {
                    sparePart.images[0].src = reader.result;
                };
                sparePart.images[0].isLoading = false;
            });
        } else if (remoteImages.length > 0) {
            sparePart.images[0].isLoading = true;
            sparePart.images[0].src = remoteImages[0].fullUrl;
        }
    }

    onLoad(image: any) {
        image.isLoading = false;
    }


    private merge(spareParts: RelatedSparePart[], stocks: SparePartStock[]): void {
        const stocksByCode = new Map(stocks.map(stock => [stock.sparePartCode, stock]));
        spareParts.forEach(sparePart => sparePart.stock = stocksByCode.get(sparePart.code));
    }

    openMultiImageModal(images: Image[]): void {
        this.matDialog.open(MultiImageModalComponent, {
            height: 'auto',
            width: '100vh',
            minWidth: '800px',
            data: images
        });
    }

    onSubmit(): void {
        if (this.selection.selected.length === 0) {
            return;
        }

        this.hasAtLeastOneInfo() ? this.showConfirmationModal() : this.fireWorkflow();
    }

    fireWorkflow(): void {
        this.isLoading = true;
        const stockRequest = this.prepareStockRequest();
        this.sparePartService.getAvailabilityOfStocks(this.getManagementSiteCode(), stockRequest)
            .subscribe(stockResponse => {
                if (!!stockResponse) {
                    if (stockResponse.availabilityStatus === 'AVAILABLE') {
                        this.stockAvailable = true;
                        this.completeTask();
                    } else {
                        this.stockAvailable = false;
                        this.updateStockStatus(stockResponse);
                    }
                } else {
                    this.completeTask();
                }
            }, () => {
                this.isLoading = false;
            });
    }

    private updateStockStatus(stockResponse: StocksAvailabilityResponse) {
        const displayedData = this.dataSource._pageData(this.dataSource.data);
        this.merge(displayedData, stockResponse.stocks);
    }

    private prepareStockRequest() {
        return this.selection.selected.map(selectedSparePart => ({
            sparePartCode: selectedSparePart.code.trim(),
            quantity: selectedSparePart.quantity
        }));
    }

    private completeTask() {
        if (!!this.folder.orderIds && this.folder.orderIds.length > 0) {
            this.updateOrder();
        } else {
            this.addOrder();
        }
    }


    isDisabled(): boolean {
        return this.selection.selected.length === 0
            || this.selection.selected.filter(sparePart => sparePart.quantity !== null &&
                sparePart.quantity !== 0).length !== this.selection.selected.length;
    }

    changeSelected(event, row): void {
        if (event.checked) {
            row.quantity = 1;
        } else {
            row.quantity = 0;
        }
        this.selection.toggle(row);

    }

    isSelectedForUpdate(element: any): boolean {
        return this.selection.selected.filter(sparePart => sparePart.code === element.code && sparePart.supplier?.code === element.supplier?.code).length === 0;
    }

    private showConfirmationModal(): void {
        const dialogRef: MatDialogRef<FuseConfirmDialogComponent> = this.matDialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });

        dialogRef.componentInstance.title = this.translateService.instant('COMPONENT.CHOOSE_SPARE_PARTS_TO_ORDER.TITLE');
        dialogRef.componentInstance.message = this.translateService.instant('COMPONENT.CHOOSE_SPARE_PARTS_TO_ORDER.MESSAGE');
        dialogRef.componentInstance.confirmButtonLabel = this.translateService.instant('COMPONENT.CHOOSE_SPARE_PARTS_TO_ORDER.CONFIRM_BUTTON');
        dialogRef.componentInstance.cancelButtonLabel = this.translateService.instant('COMPONENT.CHOOSE_SPARE_PARTS_TO_ORDER.CANCEL_BUTTON');

        dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(isActionConfirmed => {
            if (isActionConfirmed) {
                this.fireWorkflow();
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private hasAtLeastOneInfo(): boolean {
        return this.selection.selected.some(sparePart => !!sparePart.information);
    }

    hasStock(status: string): boolean {
        return status && status === 'AVAILABLE';
    }

    getStockIndicatorClass(status: string): string {
        switch (status) {
            case 'AVAILABLE':
                return 'green-color';
            case 'NOT_AVAILABLE':
                return 'red-color';
            default:
                return 'black-color';
        }
    }

    displayStockStatus(status: string): string {
        if (!status) {
            return 'TEXT.UNDEFINED';
        }
        if ('UNKNOWN' === status) {
            return 'TEXT.UNKNOWN';
        }

        if (status === 'AVAILABLE') {
            return 'TEXT.AVAILABLE';
        }

        if (status === 'NOT_AVAILABLE') {
            return 'TEXT.UNAVAILABLE';
        }
    }

    stockDetailsTooltip(stocks: StockDetails[], totalStock: string): string {
        let tooltipMessage = '';
        if (totalStock) {
            tooltipMessage = this.translateService.instant('TOOLTIP.STOCK.DETAILS.STOCK', {stock: totalStock});

        } else {
            const stockDetails = stocks
                .map(stock => this.translateService.instant('TOOLTIP.STOCK.DETAILS',
                    {
                        siteCode: stock?.siteCode,
                        siteLabel: stock?.siteLabel,
                        deliveryDate: this.datePipe.transform(stock?.deliveryDate, 'dd-MM-yyyy')
                    }));

            stockDetails.map(stockDetails => tooltipMessage += stockDetails + '\n');
        }
        return tooltipMessage;
    }

    closeFolder() {
        this.inputMap.emit({
            'userChoise': 'closeFolder'
        });
    }

    openSwitchOperatingModeModal(): void {
        this.calculateEligibleOperatingMode(this.folder.newWarranty.warranty, this.folder.newWarranty.warrantyReason);
    }

    private calculateEligibleOperatingMode(warranty: RegimeWarrantyEnum, selectedReason: string): void {
        this.store$.dispatch(new StartLoading());
        const ruleEvaluationContext = RuleEvaluationContext.fromFolder(this.folder);
        ruleEvaluationContext.warrantyCode = warranty;
        ruleEvaluationContext.warrantyReasonEnum = selectedReason;
        this.backOfficeService.getEligibleOperatingMode(ruleEvaluationContext).pipe(
            finalize(
                () => {
                    this.store$.dispatch(new StopLoading());
                    const initData = {
                        warranty: this.folder.newWarranty,
                        operatingModeList: this.operatingModeList
                    };
                    const dialogRef = this.matDialog.open(SwitchOperatingModeModalComponent, {
                        height: '50vh', width: '300px', minWidth: '50%', data: initData
                    });
                    dialogRef.afterClosed().subscribe(data => {
                            if (!!data) {
                                this.inputMap.emit({
                                    'userChoise': 'swichModOp',
                                    'modOp': data.operatingMode.code,
                                    'commentSwitchModOp': data.comment,
                                    'reasonToSwitchModOp': data.reason
                                });

                            }
                        }
                    );
                }
            )
        ).subscribe(data => {
            data = data.filter(value => value.modOp !== this.folder.operationMode);
            data = data.filter(e => !e.forSelfCareOnly);
            this.operatingModeList = data.map(el => {
                return {
                    code: el.modOp,
                    label: this.getModOpLabel(el)
                };
            });
        }, () => {
            this.operatingModeList = [];
        });
    }

    private getModOpLabel(el: ModOpDetails): string {
        return this.modeOpConfigList.filter(e => e.code === el.modOp).length > 0 ?
            this.modeOpConfigList.filter(e => e.code === el.modOp)[0].label : null;
    }

    public canSwitchOperatingMode(): boolean {
        const claimDate = moment(this.folder.createdBy.actionDate).format('YYYY-MM-DD');
        const mepDate = '2023-06-21';
        return claimDate >= mepDate;
    }

    openAddSparePartUnreferencedModal(sparePart: RelatedSparePart, isEditMode?: boolean, index?: number): void {
        const dialogRef = this.matDialog.open(UnreferencedSparePartModalComponent, {
            height: 'auto',
            width: '100vh',
            minWidth: '50%',
            data: {
                isUnderWarranty: this.isUnderWarranty,
                unreferencedSparePart: isEditMode ? this.toUnreferencedSparePartView(sparePart) : null
            }
        });
        dialogRef.afterClosed().subscribe(data => {
                if (!!data && data.confirmed) {
                    const relatedUnreferencedSparePart = this.buildRelatedUnreferencedSparePart(data);
                    this.addUnreferencedSparePartToDatasource(relatedUnreferencedSparePart, index);
                    this.addImageToUnreferencedSparePart(relatedUnreferencedSparePart);
                    const indexSelectedSparePart = this.selection.selected.findIndex(selectedLine => selectedLine.id === sparePart?.id);
                    this.selectSparePart(relatedUnreferencedSparePart, indexSelectedSparePart);
                }
            }
        );
    }

    private addImageToUnreferencedSparePart(sparePart: any) {
        if (sparePart.images?.length > 0) {
            this.getImage(sparePart);
        }
    }

    private selectSparePart(relatedSparePart, indexLine?: number) {
        const selectedSparePart: RelatedSparePart[] = this.selection.selected;
        let index = selectedSparePart.findIndex((sparePart) => sparePart.code === relatedSparePart.code && sparePart.supplier?.code === relatedSparePart.supplier?.code);
        if (index === -1 && indexLine >= 0) {
            index = indexLine;
        }
        index >= 0 ? selectedSparePart[index] = relatedSparePart : selectedSparePart.push(relatedSparePart);
        this.selection.clear();
        this.selection.select(...selectedSparePart);
        relatedSparePart.quantity = relatedSparePart.quantity || 1;
    }

    private buildRelatedUnreferencedSparePart(data): RelatedSparePart {
        const sparePart: SparePartView = data.unreferencedSparePart;
        return {
            id: data.foundFromCatalog ? data.foundedSparePartId : sparePart.code + '_' + sparePart?.suppliers[0]?.code + '_' + this.order?.organizationCode,
            code: sparePart.code,
            label: sparePart.label,
            type: sparePart.type,
            ean: sparePart.ean,
            approximatePrice: sparePart.approximatePrice,
            sellingPrice: sparePart.sellingPrice,
            purchasePrice: {
                value: sparePart.purchasePrice,
                currency: this.currency
            },
            marginPercentage: sparePart.marginPercentage,
            supplier: {
                supplierReference: sparePart?.suppliers[0]?.supplierReference,
                name: sparePart?.suppliers[0]?.name,
                code: sparePart?.suppliers[0]?.code,
                freeUnderWarranty: false,
                unitPrice: sparePart?.suppliers[0]?.unitPrice
            },
            images: sparePart?.images,
            quantity: sparePart.quantity,
            isUnreferenced: !data.foundFromCatalog
        };
    }

    private toUnreferencedSparePartView(sparePart: RelatedSparePart): SparePartView {
        return {
            code: sparePart.code,
            label: sparePart.label,
            type: sparePart.type,
            ean: sparePart.ean,
            approximatePrice: sparePart.approximatePrice,
            sellingPrice: sparePart.sellingPrice,
            purchasePrice: sparePart.purchasePrice?.value,
            marginPercentage: sparePart.marginPercentage,
            suppliers: [{
                id: sparePart.supplier?.id,
                code: sparePart.supplier?.code,
                name: sparePart.supplier?.name,
                unitPrice: sparePart.supplier?.unitPrice,
                supplierReference: sparePart.supplier?.supplierReference
            }],
            images: sparePart.images,
            quantity: sparePart.quantity
        };
    }

    private buildRelatedUnreferencedSparePartFromOrderItem(orderItem: OrderItem): RelatedSparePart {
        return {
            id: orderItem.code + '_' + orderItem.supplier?.code + '_' + this.order?.organizationCode ,
            code: orderItem.code,
            label: orderItem.label,
            type: orderItem.type,
            ean: orderItem.ean,
            approximatePrice: {
                value: orderItem.price,
                currency: this.currency
            },
            sellingPrice: {
                value: orderItem.price,
                currency: this.currency
            },
            purchasePrice: {
                value: orderItem.purchasePrice,
                currency: this.currency
            },
            marginPercentage: orderItem.marginPercentage,
            supplier: {
                supplierReference: orderItem.supplier?.supplierReference,
                name: orderItem.supplier?.name,
                code: orderItem.supplier?.code,
                freeUnderWarranty: false,
                unitPrice: orderItem.supplier?.unitPrice
            },
            images: orderItem.images,
            quantity: orderItem.quantityOrdered,
            isUnreferenced: true
        };
    }

    private addUnreferencedSparePartToDatasource(data, index?: number) {
        if (!!this.dataSource.data && this.dataSource.data.length > 0) {
            const indexSparePart = this.dataSource.data.findIndex(item => item.code === data.code
                && item.supplier?.code === data.supplier.code);
            if (indexSparePart !== -1) {
                index = indexSparePart;
                data.isUpdated = true;
            }
            const relatedSparePart = this.dataSource.data;
            index === undefined ? relatedSparePart.unshift(data) : relatedSparePart[index] = data;
            this.dataSource.data = relatedSparePart;
        } else {
            this.isSparePartsAvailable = true;
            this.dataSource = new MatTableDataSource<RelatedSparePart>([data]);
            this.dataSource.paginator = this.paginator;
            this.fetchStocksOfDisplayedSpareParts();
            this.fetchImagesOfDisplayedSpareParts();
        }
    }

    private getManagementSiteCode(): string {
        return this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE)?.code;
    }

    public convertHTtoTTC(price) {
        return AmountUtils.convertHTtoTTC(price, parseFloat(this.tva));
    }

    prepareItems(): OrderItem[] {
        return this.selection.selected.map(selectedSparePart => ({
            code: selectedSparePart.code,
            label: selectedSparePart.label,
            type: 'SPARE_PART',
            ean: selectedSparePart.ean,
            currency: this.getCurrency(selectedSparePart.approximatePrice?.currency),
            price: this.computePrice(selectedSparePart),
            purchasePrice: selectedSparePart.purchasePrice?.value,
            marginPercentage: selectedSparePart.marginPercentage,
            taxPercent: Number(this.tva),
            weight: parseFloat(selectedSparePart.caracteristics?.weight),
            images: selectedSparePart.images,
            supplier: selectedSparePart.supplier || undefined,
            quantityOrdered: selectedSparePart.quantity,
            quantityInvoiced: this.computeInvoicedQuantity(selectedSparePart),
            isAvailable: selectedSparePart.stock ? this.hasStock(selectedSparePart.stock.status) : false,
        }));
    }

    private computePrice(sparePart: RelatedSparePart) {
        const price = sparePart.sellingPrice.value ?? 0;
        return this.isFreeSparePart(sparePart) ? 0 : price;
    }

    isFreeSparePart(sparePart: RelatedSparePart) {
        return sparePart.supplier?.freeUnderWarranty && this.isUnderWarranty && !sparePart.isUpdated;
    }

    private computeInvoicedQuantity(sparePart: RelatedSparePart) {
        return this.isFreeSparePart(sparePart) ? 0 : sparePart.quantity;
    }

    private getCurrency(currency: string) {
        return currency ? currency : this.currency;
    }

    private prepareOrder() {
        this.order.orderItems = this.prepareItems();
        this.order.externalId = this.folder.id;
        this.order.organizationCode = this.folder.organization.code;
        this.order.siteCode = this.getManagementSiteCode();
        this.order.currency = this.currency;
    }

    private addOrder() {
        this.store$.dispatch(new StartLoading());
        this.prepareOrder();
        this.omsService.createOrder(this.order).subscribe((data) => {
            if (!!data) {
                this.order = data;
                this.orderSubjectService.orderLoaded(this.order);
                this.updateFolder();

            }
        }, () => {
            this.isLoading = false;
            this.store$.dispatch(new StopLoading());
        });
    }

    private updateOrder() {
        this.store$.dispatch(new StartLoading());
        this.prepareOrder();
        this.omsService.updateOrder(this.order.id, this.order).subscribe((data) => {
            if (!!data) {
                this.order = data;
                this.orderSubjectService.orderLoaded(this.order);
                this.inputMap.emit({
                    'orderId': this.order.id,
                    'userChoise': 'selectSpareParts'
                });
            }
            this.store$.dispatch(new StopLoading());
        }, () => {
            this.isLoading = false;
            this.store$.dispatch(new StopLoading());
        });
    }

    private prepareFolderUpdateRequest(): IFolderUpdateRequest {
        return {
            orderId: this.order.id
        };
    }

    private updateFolder() {
        const folderUpdateRequest = this.prepareFolderUpdateRequest();
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
            this.folderSubjectService.folderLoaded(folder);
            this.inputMap.emit({
                'orderId': this.order.id,
                'userChoise': 'selectSpareParts'
            });
            this.store$.dispatch(new StopLoading());
        }, () => this.store$.dispatch(new StopLoading()));
    }
}
