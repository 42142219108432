<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2>{{'NAV.FOLDER.LIST' | translate}}</h2>
    <button mat-icon-button class="ml-40" (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <ng-container *ngIf="!!folderList">
        <table mat-table [dataSource]="folderList">

            <ng-container matColumnDef="INCREMENTAL_REFERENCE">
                <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.INCREMENTAL_REFERENCE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                    class="cursor-pointer">
                    {{element.incrementalNumber}}
                </td>
            </ng-container>

            <ng-container matColumnDef="REFERENCE">
                <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.REFERENCE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                    class="cursor-pointer">
                    {{element.reference}}
                </td>
            </ng-container>

            <ng-container matColumnDef="LAST_NAME">
                <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.LAST_NAME' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                    class="cursor-pointer">
                    {{element.customerLastName}}
                </td>
            </ng-container>


            <ng-container matColumnDef="FIRST_NAME">
                <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.FIRST_NAME' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                    class="cursor-pointer">
                    {{element.customerFirstName}}
                </td>
            </ng-container>

            <ng-container matColumnDef="CUSTOMER_MOBILE_PHONE">
                <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.CUSTOMER_MOBILE_PHONE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                    class="cursor-pointer height-reference_folder">
                    {{element.customerMobilePhone }}
                </td>
            </ng-container>

            <ng-container matColumnDef="CREATE_DATE">
                <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.CREATE_DATE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                    class="cursor-pointer height-reference_folder">
                    {{element.creationDate|date:"dd/MM/yyyy" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="STATUS">
                <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.STATUS' | translate}}</th>
                <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                    class="cursor-pointer">
                                <span class="pl-2"
                                      *ngIf="element.currentWorkFlowStatus">
                                    {{'COMPONENT.' + element.currentWorkFlowStatus | translate}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="DATE_WORKFLOW_STATUS">
                <th mat-header-cell
                    *matHeaderCellDef>{{'FOLDER.TABLE.DATE_WORKFLOW_STATUS' | translate}}</th>
                <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                    class="cursor-pointer">
                                <span class="pl-2" *ngIf="element.workflowStatusDate">
                                    {{element.workflowStatusDate |date:"dd/MM/yyyy" }}
                                </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="PRODUCT_LABELS">
                <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.PRODUCT_LABELS' | translate}}</th>
                <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)" class="cursor-pointer ">
                    {{element.productLabel}}
                </td>
            </ng-container>

            <ng-container matColumnDef="PRODUCT_CODE">
                <th mat-header-cell *matHeaderCellDef>{{'FOLDER.TABLE.PRODUCT_CODE' | translate}}</th>
                <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)" class="cursor-pointer ">
                    {{element.productCode}}
                </td>
            </ng-container>

            <ng-container matColumnDef="INDICATOR">
                <th mat-header-cell *matHeaderCellDef>{{'FOLDER.TABLE.INDICATOR' | translate}}</th>
                <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)">

                                <span class="cursor-pointer" *ngIf="!!element.newWarranty"
                                      [mdePopoverTriggerFor]="warrantyPopover"
                                      mdePopoverTriggerOn="hover">
                                    <mat-icon class="w-35 h-35" [ngClass]="element.newWarranty?.icon.class">
                                      {{element.newWarranty.icon.code}}
                                    </mat-icon>
                                </span>
                    <span *ngIf="!!element.operationMode" class="cursor-pointer"
                          [mdePopoverTriggerFor]="operationModePopover" mdePopoverTriggerOn="hover">
                                    <mat-icon class="w-35 h-35" svgIcon="{{element.operationMode?.icon}}">
                                    </mat-icon>
                                </span>
                    <span *ngIf="element.closed" [mdePopoverTriggerFor]="closedFolderPopover"
                          mdePopoverTriggerOn="hover" class="cursor-pointer">
                                    <mat-icon class="w-35 h-35">lock</mat-icon></span>

                    <mde-popover #warrantyPopover="mdePopover" [mdePopoverOffsetY]="20"
                                 [mdePopoverOffsetX]="15"
                                 [mdePopoverOverlapTrigger]="true">

                        <mat-card class="popover w-100-p mat-elevation-z2 ">
                            <div fxLayout="column" fxLayoutAlign="center center"
                                 class="popover_content">
                                <div fxLayout="row" fxLayoutAlign="center center">
                                    <mat-icon
                                        [ngClass]="'s-16 '+ element.newWarranty?.icon.class">{{element.newWarranty.icon.code}}
                                    </mat-icon>
                                    <span class="ml-4">
                                                {{(element.newWarranty.label.i18nKey) | translate }}</span></div>
                                <div>{{element.warrantyReason ? ('FOLDER.WARRANTY.REASON.LABEL'| translate) + " : " +
                                    getWarrantyRaisonLabel(element.warrantyReason) : ''}}</div>
                            </div>
                        </mat-card>
                    </mde-popover>
                    <mde-popover #operationModePopover="mdePopover" [mdePopoverOffsetY]="20"
                                 [mdePopoverOffsetX]="15"
                                 [mdePopoverOverlapTrigger]="true">

                        <mat-card class="popover w-100-p mat-elevation-z2 ">

                            <div fxLayout="row" fxLayoutAlign="space-evenly center" class="popover_content">
                                <mat-icon svgIcon="{{element.operationMode?.icon}}"
                                          [ngClass]="'s-16'">
                                </mat-icon>
                                <span class="ml-4">
                                    {{element.operationMode?.key | codeToLabel  :'SAV.CONFIG.MODE_OP' | async}}
                                </span>
                            </div>
                        </mat-card>
                    </mde-popover>
                    <mde-popover #closedFolderPopover="mdePopover" [mdePopoverOffsetY]="20"
                                 [mdePopoverOffsetX]="15"
                                 [mdePopoverOverlapTrigger]="true">

                        <mat-card class="popover w-100-p mat-elevation-z2 ">
                            <div fxLayout="row" fxLayoutAlign="center center" class="popover_content">
                                <mat-icon
                                    class="s-16">lock
                                </mat-icon>
                                <span class="ml-4">
                                                {{'COMPONENT.FOLDER_CLOSED' | translate }}</span></div>

                        </mat-card>
                    </mde-popover>
                </td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="dataSourceFolder.columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: dataSourceFolder.columnsToDisplay;"
                id="rowTable"></tr>
        </table>
        <mat-paginator *ngIf="pageConfig" [length]="pageConfig.totalElements"
                       (page)="changePage($event)" showFirstLastButtons [pageSize]="pageConfig.size"
                       [pageSizeOptions]="pageSizeOptions" class="w-100-p"></mat-paginator>
    </ng-container>
</mat-dialog-content>

