import {Dashboard} from 'app/models/dashboard/dashboard.model';

export class DashboardState {
    mode: string;
    dashboards: Dashboard[];
}

export const initialDashboardState = {
    dashboards: []
} as DashboardState;
