<h1 matDialogTitle>{{'OLD.REPARATION.FOLDERS.MODAL' | translate}}</h1>
<mat-dialog-content >
    <table mat-table [dataSource]="dataSourceOldFolders" class="w-100-p mat-elevation-z4">
        <ng-container matColumnDef="INCREMENTAL_REFERENCE">
            <th mat-header-cell *matHeaderCellDef class="table-header"> {{'FOLDER.TABLE.INCREMENTAL_REFERENCE' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                <a target="_blank" [routerLink]=getUrl(element)> {{element.incrementalReference}}</a>

        </ng-container>
        <ng-container matColumnDef="REFERENCE">
            <th mat-header-cell *matHeaderCellDef class="table-header"> {{'FOLDER.TABLE.REFERENCE' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.reference}}
            </td>
        </ng-container>
        <ng-container matColumnDef="CREATE_DATE">
            <th mat-header-cell *matHeaderCellDef class="table-header">  {{'FOLDER.TABLE.CREATE_DATE' | translate}} </th>
            <td mat-cell *matCellDef="let element">
                <span class="cursor-pointer"
                      matTooltip="{{element.createdBy.actionDate | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate) : (currentLanguage$ | async)}}">
                    {{element.createdBy.actionDate | moment: ('GENERAL.FOLDER_EVENT_DATE' | translate) : (currentLanguage$ | async)}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="SYMPTOM">
            <th mat-header-cell *matHeaderCellDef class="table-header">  {{'FOLDER.TABLE.SYMPTOM' | translate}} </th>
            <td mat-cell *matCellDef="let element">
                    <span
                        style="text-align: left">{{element.reparationReport && element.reparationReport.iris[0] && element.reparationReport.iris[0].symptom
                        ? element.reparationReport.iris[0].symptom.label : element.faultCode.label}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="ACTION">
            <th mat-header-cell *matHeaderCellDef class="table-header"></th>
            <td mat-cell *matCellDef="let element">
                <mat-radio-button class="full-width-radio" [value]=selectedFolder (change)="changeSelected(element)">
                </mat-radio-button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions class="mt-24 mb-0" fxLayoutAlign="end end" fxLayoutGap="10px">
    <div>
        <button mat-stroked-button (click)="onClose()" type="button" id="declareNewReparation"
                class="aster_btn_cancel">
            {{'OLD.FOLDER.REPARATION.DECLARE.NEW' | translate}}
        </button>
        <button (click)="validate()" mat-stroked-button color="accent"
                [disabled]="!selectedFolder"
                [ngClass]="( !selectedFolder ? 'aster_btn_disabled' : 'aster_btn')">
            {{'OLD.FOLDER.REPARATION.DECLARE.OLD' | translate}}
        </button>
    </div>
</mat-dialog-actions>