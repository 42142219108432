import {Injectable} from '@angular/core';
import {GLOBAL} from '../../../app-config';
import {HttpClient} from '@angular/common/http';
import {CustomerApiInfo} from '../../../models/customer/customer-api-info.model';
import {Customer} from '../../../models/customer/customer.model';
import {PageEvent} from '@angular/material/paginator';
import {PagedResult} from '../../../models/paged-result.model';
import {CustomerUtils} from '../../utils/customer-utils';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api/v1/customer`;

    constructor(private httpClient: HttpClient) {
    }

    loadCustomerApiInfo = () => this.httpClient.get<CustomerApiInfo>(`${this.path}/api-infos`);

    searchCustomer(customer: Customer, pageEvent?: PageEvent) {
        return this.httpClient.post<PagedResult<Customer>>(`${this.path}/search`, CustomerUtils.toRequest(customer, pageEvent));
    }

}
