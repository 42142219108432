import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar-notif',
    templateUrl: './snackbar-notif.component.html',
    styleUrls: ['./snackbar-notif.component.scss']
})
export class SnackbarNotifComponent implements OnInit {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    }

    ngOnInit() {
    }

    get getIcon() {
        switch (this.data.snackType) {
            case 'Success':
                return 'done';
            case 'Error':
                return 'error';
            case 'Warn':
                return 'warning';
            case 'Info':
                return 'info';
        }
    }
}
