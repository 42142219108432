import {Component, Input, OnInit} from '@angular/core';
import {Address, Customer, MobilePhoneInput} from '../../../../models/customer/customer.model';
import {Folder, IFolderUpdateRequest, RepairCenter} from '../../../../models/folder.model';
import {BackOfficeService} from '../../../../shared/services/back-office.service';
import {FolderService} from '../../../../shared/services/folder.service';
import {OperationModeEnum} from '../../../../models/enums/operationMode.enum';
import {FormControl, FormGroup} from '@angular/forms';
import {AddressType} from '../../../../models/enums/addressType.enum';
import {debounceTime, finalize, startWith, switchMap, take, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Observable, ReplaySubject} from 'rxjs';
import {CountriesUtils} from '../../../../shared/data/countries-utils';
import {Contact} from '../../../../models/contact.model';
import {SharedService} from '../../../../shared/services/shared.service';
import libphonenumber from 'google-libphonenumber';
import {PhoneNumberPipe} from '../../../../../@fuse/pipes/phone-number.pipe';
import {FolderSubjectService} from '../../folder-subject.service';
import {WorkflowService} from '../../../../shared/services/workflow.service';
import {CustomerType} from '../../../../models/enums/CustomerType.enum';
import {select, Store} from '@ngrx/store';
import {countryCode, legalForms} from '../../../../store/organization/organization.selectors';
import {isProductRecall} from '../../../../store/user/user.selectors';
import {AppState} from '../../../../store/app.state';
import {Unsubscriber} from '../../../../unsubscriber';
import {ProductUtils} from '../../../../shared/utils/product-utils';
import {CIVILITY_OPTIONS} from '../../../../models/customer/customer.constants';
import {RuleEvaluationContext} from 'app/models/rules/RuleEvaluationContext';
import PhoneNumberFormat = libphonenumber.PhoneNumberFormat;

@Component({
    selector: 'app-customer-info-card',
    templateUrl: './customer-info-card.component.html',
    styleUrls: ['./customer-info-card.component.scss'],
    providers: [SharedService]
})
export class CustomerInfoCardComponent extends Unsubscriber implements OnInit {

    @Input() isOriginator = false;
    @Input() showActionEdit: boolean;
    @Input() canUpdateReference: boolean;

    folder: Folder;
    customer: Customer;
    customerTypeEnum = CustomerType;
    mobilePhoneOutput: MobilePhoneInput;
    editCustomer = false;
    isHomeRepair = false;
    isProductRecall = false;
    isSwap = false;
    customerInterventionAddress: Address;
    customerDefaultAddress: Address;
    addresses: any;
    isLoading = false;
    errorMsg: string;
    customerDetailForm: any;
    editInterventionAddress = false;
    editDefaultAddress = false;
    validateFormAddress = true;
    private changedRepairCenter: RepairCenter;
    legalFormSelectBoxOptions$: Observable<string[]>;
    civilitySelectBoxOptions = CIVILITY_OPTIONS;
    prohibitedWorkflowStatus = ['WAITING_FOR_INTERVENTION', 'PLANNED_INTERVENTION'];

    constructor(private backOfficeService: BackOfficeService,
                private workflowService: WorkflowService,
                private folderService: FolderService,
                private translateService: TranslateService,
                private phoneNumberPipe: PhoneNumberPipe,
                private sharedService: SharedService,
                private folderSubjectService: FolderSubjectService,
                private _sharedService: SharedService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.legalFormSelectBoxOptions$ = this.store$.pipe(select(legalForms));
        this.anotherSubscription = this.store$.pipe(select(countryCode),
            take(1))
            .subscribe(organizationLocal => this.customerDetailForm = {
                form: FormGroup,
                countriesList: CountriesUtils.COUNTRIES_LIST,
                filteredCountriesList: new ReplaySubject<any[]>(),
                phoneConfigCountry: organizationLocal
            });

        this.anotherSubscription = this.store$.pipe(select(isProductRecall))
            .subscribe(isProductRecall => this.isProductRecall = isProductRecall);

        this.anotherSubscription = this._sharedService.getValidateForm().subscribe(validate => {
            this.validateFormAddress = validate;
        });
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
            this.isSwap = folder.operationMode === OperationModeEnum.SWAP;
            if (this.isOriginator) {
                this.customer = folder.originator;
            } else {
                this.customer = folder.customer;
            }
            this.isHomeRepair = this.folder.operationMode != null && this.folder.operationMode === OperationModeEnum.HOME_REPAIR;
            this.initCustomerDetailForm();
            this.initCustomerDetailDataWithCustomerData();
            this.initAutoComplete();
        });
    }

    initCustomerDetailForm(): void {
        this.customerDetailForm.form = new FormGroup({
            reference: new FormControl({value: null, disabled: !this.canUpdateReference}),
            gender: new FormControl({value: null}),
            firstName: new FormControl({value: null}),
            lastName: new FormControl({value: null}),
            mobileNumber: new FormControl(null), email: new FormControl(null),
            countryCode: new FormControl(null),
            countryCodeCtrl: new FormControl(null),
            city: new FormControl(null),
            postalCode: new FormControl(null),
            address1: new FormControl(null),
            address2: new FormControl(null),
        });

        this.updatePhoneInput();
        this.mobilePhoneOutput = new MobilePhoneInput();

        this.anotherSubscription = this.sharedService.getUpdateMobilePhoneEvent().subscribe(res => {
            if (!res) {
                this.customerDetailForm.form.get(['mobileNumber']).setErrors({'incorrect': true});
                return;
            }
            this.customerDetailForm.form.get(['mobileNumber']).setValue(res.mobileNumber);
            this.mobilePhoneOutput = res;
        });

        this.anotherSubscription = this.customerDetailForm.form.get(['countryCodeCtrl']).valueChanges
            .pipe(startWith(null))
            .subscribe(input => {
                const matchedResults = !!input ?
                    this.customerDetailForm.countriesList.filter(item =>
                        item.code.toLowerCase().includes(input.toLowerCase()) || item.name.toLowerCase().includes(input.toLowerCase())
                    ) : this.customerDetailForm.countriesList;

                this.customerDetailForm.filteredCountriesList.next(matchedResults);
            });
        if (!!this.customer && this.customer.type === CustomerType.PROFESSIONAL) {
            this.customerDetailForm.form.addControl('label', new FormControl({value: !!this.customer.company ? this.customer.company.label : null}));
            this.customerDetailForm.form.addControl('legalForm', new FormControl({value: !!this.customer.company ? this.customer.company.label : null}));
        }
    }

    initCustomerDetailDataWithCustomerData(): void {
        this.customerDetailForm.form.get(['reference']).setValue(this.customer.reference);
        this.customerDetailForm.form.get(['gender']).setValue(!!this.customer.gender ? this.customer.gender : '');
        this.customerDetailForm.form.get(['firstName']).setValue(!!this.customer.firstName ? this.customer.firstName : '-');
        this.customerDetailForm.form.get(['lastName']).setValue(!!this.customer.lastName ? this.customer.lastName : '-');
        if (this.customer.type === CustomerType.PROFESSIONAL) {
            this.customerDetailForm.form.get('label').setValue(this.customer.company.label);
            this.customerDetailForm.form.get('legalForm').setValue(this.customer.company.legalForm);
        }
        this.customerDetailForm.form.get(['email']).setValue(!!this.customer.contact ? this.customer.contact.email : '-');
        this.customerInterventionAddress = this.customer.addresses.length > 0 || !!this.customer
            ? this.getCustomerAddress(this.customer.addresses, AddressType.INTERVENTION) : new Address();
        this.customerDefaultAddress = this.getCustomerAddress(this.customer.addresses, AddressType.DEFAULT);
        this.updatePhoneInput();
        if (!!this.customerInterventionAddress) {
            if (!this.customerInterventionAddress.countryCode) {
                this.anotherSubscription = this.store$.pipe(select(countryCode))
                    .subscribe(organizationLocal => {
                        this.customerInterventionAddress.countryCode = organizationLocal;
                        this.customerDetailForm.form.get(['countryCode']).setValue(this.customerInterventionAddress.countryCode);
                    });
            }
            this.customerDetailForm.form.get(['city']).setValue(this.customerInterventionAddress.city);
            this.customerDetailForm.form.get(['postalCode']).setValue(this.customerInterventionAddress.zipCode);
            this.customerDetailForm.form.get(['address1']).setValue(this.customerInterventionAddress.address);
            this.customerDetailForm.form.get(['address2']).setValue(this.customerInterventionAddress.secondAddress);
        }
        this.mobilePhoneOutput = {
            countryCode: this.customerDetailForm.phoneConfigCountry, mobileNumber: !!this.customer.contact ? this.customer.contact.mobileNumber : ''
        };
        this.sharedService.updateMobilePhone(this.mobilePhoneOutput);
    }

    isValidCountryCode(countryCode: string): boolean {
        if (!countryCode) {
            return false;
        }
        return CountriesUtils.isValidCountryCode(countryCode);
    }


    getCountryCodeByPhoneNumber(countryName: string): string {
        return CountriesUtils.getCountryCode(countryName);
    }

    getInternationalNumber(number: string): string {
        if (number === '') {
            return '-';
        }
        return this.phoneNumberPipe.transform(number, this.customerDetailForm.phoneConfigCountry, PhoneNumberFormat.INTERNATIONAL);
    }


    onSubmit(): void {
        if (this.customerDetailForm.form.invalid || !this.validateFormAddress) {
            return;
        }
        const actualInterventionAddress: Address = this.folder.customer.addresses.filter(element => element.type === AddressType.INTERVENTION)[0];
        this.changeAddress();

        if (this.editInterventionAddress && (this.isNewRepairCenterRequired(actualInterventionAddress))) {
            this.getNewRepairCenter();
        } else {
            this.updateFormVariables();
            this.editFolderDetails();
        }
    }

    private canUpdateCenterRepair(repairCenter: RepairCenter): boolean {
        const actualFolderRepairCenter = this.folder.repairCenter;
        return !!actualFolderRepairCenter ? repairCenter.code !== actualFolderRepairCenter.code : false;
    }

    private isNewRepairCenterRequired(actualInterventionAddress: Address): boolean {
        return this.customerInterventionAddress.zipCode !== actualInterventionAddress.zipCode
            || this.customerInterventionAddress.countryCode !== actualInterventionAddress.countryCode;
    }

    private getNewRepairCenter(): void {
        this.backOfficeService.getRepairCenter(RuleEvaluationContext.fromFolder(this.folder), ProductUtils.latestLocation(this.folder.product),
            this.customerInterventionAddress.zipCode, this.customerInterventionAddress.countryCode).subscribe(repairCenter => {
            if (!!repairCenter) {
                if (this.canUpdateCenterRepair(repairCenter)) {
                    this.changedRepairCenter = repairCenter;
                    this.folder.repairCenter = repairCenter;
                    this.updateWorkflowVariable();
                }
            } else {
                // Todo actions if there's no repair Center found for the new intervention address
            }
            this.updateFormVariables();
            this.editFolderDetails();
        });
    }

    private updateWorkflowVariable(): void {
        this.workflowService.updateRepairCenterCode(this.folder.id, this.changedRepairCenter.code).subscribe(() => {
        }, () => {
            console.log('ERROR Update WF variable on folder with id :' + this.folder.id);
        });
    }

    private updateFormVariables(): void {
        this.editCustomer = false;
        this.editInterventionAddress = false;
        this.editDefaultAddress = false;
        this.customer.reference = this.customerDetailForm.form.value.reference ?? this.customer.reference;
        this.customer.firstName = this.customerDetailForm.form.value.firstName !== '-' ? this.customerDetailForm.form.value.firstName : this.customer.firstName;
        this.customer.lastName = this.customerDetailForm.form.value.lastName !== '-' ? this.customerDetailForm.form.value.lastName : this.customer.lastName;
        if (!!this.customerDetailForm.form.value.gender) {
            this.customer.gender = this.customerDetailForm.form.value.gender !== '-' ? this.customerDetailForm.form.value.gender : this.customer.gender;
        }
        if (!this.customer.contact) {
            this.customer.contact = new Contact();
        }
        this.customer.contact.email = this.customerDetailForm.form.value.email !== '-' ? this.customerDetailForm.form.value.email : this.customer.contact.email;
        this.customer.contact.mobileNumber = this.customerDetailForm.form.value.mobileNumber?.length > 0 ? this.mobilePhoneOutput.mobileNumber : this.customer.contact.mobileNumber;
        if (!!this.customer && this.customer.type === this.customerTypeEnum.PROFESSIONAL) {
            this.customer.company.label = this.customerDetailForm.form.value.label;
            this.customer.company.legalForm = this.customerDetailForm.form.value.legalForm;
        }
        this.folder.customer = this.customer;
        this.folder.customer.contact = {
            phoneNumber: this.customer.contact.phoneNumber, mobileNumber: this.mobilePhoneOutput.mobileNumber, email: this.customer.contact.email
        };
    }

    changeAddress(): void {
        this.anotherSubscription = this.sharedService.getUpdateAddressEvent().subscribe(address => {
            if (this.editDefaultAddress) {
                this.customer.addresses = this.customer.addresses.filter(element => element.type !== AddressType.DEFAULT);
                this.customerDefaultAddress = address;
                this.customerDefaultAddress.type = AddressType.DEFAULT;
                this.customer.addresses.push(this.customerDefaultAddress);
            } else {
                this.customer.addresses = this.customer.addresses.filter(element => element.type !== AddressType.INTERVENTION);
                this.customerInterventionAddress = address;
                this.customerInterventionAddress.type = AddressType.INTERVENTION;
                this.customer.addresses.push(this.customerInterventionAddress);
            }
        });
    }

    editFolderDetails(): void {
        const folderUpdateRequest: IFolderUpdateRequest = {
            customer: this.folder.customer
        };
        if (!!this.changedRepairCenter) {
            folderUpdateRequest.repairCenter = this.changedRepairCenter;
        }
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
            this.customer = folder.customer;
            this.customerInterventionAddress = this.getCustomerAddress(this.customer.addresses, AddressType.INTERVENTION);
        }).catch(() => {
            console.log('ERROR Update Folder with id :' + this.folder.id);
        });
    }

    getCustomerAddress(addresses: Address[], addressType: string): Address {
        const address = addresses.find(adr => adr.type === addressType);
        return address || addresses[0];
    }

    initAutoComplete(): void {
        this.customerDetailForm.form.get(['address1']).valueChanges
            .pipe(
                debounceTime(500),
                tap(() => {
                    this.errorMsg = '';
                    this.addresses = [];
                    this.isLoading = true;
                }),
                switchMap(value => this.backOfficeService.getAddressesByStringSearch(value)
                    .pipe(
                        finalize(() => {
                            this.isLoading = false;
                        }),
                    )
                )
            ).subscribe(data => {
            if (data.length > 0) {
                this.isLoading = false;
                this.errorMsg = '';
                this.addresses = data;
            } else {
                this.errorMsg = '';
                this.addresses = [];
            }
        });
    }


    editCustomerInfos(): void {
        this.editCustomer = !this.editCustomer;
        this.initCustomerDetailDataWithCustomerData();
    }

    switchEditDefaultAddressInfo(): void {
        this.editDefaultAddress = !this.editDefaultAddress;
    }

    switchEditInterventionAddressInfo(): void {
        this.editInterventionAddress = !this.editInterventionAddress;
    }

    isFormDisabled(): boolean {
        return this.customerDetailForm.form.invalid || !this.validateFormAddress;
    }

    getAddress(customerAddress: Address): string {
        return !!customerAddress ? (!!customerAddress.address ? customerAddress.address : '') + (!!customerAddress.secondAddress ? ', ' + customerAddress.secondAddress : '') : '-';
    }

    getAddressDetails(customerAddress: Address): string {
        return !!customerAddress ?
            (!!customerAddress.zipCode ? customerAddress.zipCode : '')
            + (!!customerAddress.city ? ', ' + customerAddress.city + ' - ' : '')
            + (!!customerAddress.countryName ? customerAddress.countryName : '') : '-';
    }

    private updatePhoneInput(): void {
        if (!!this.customer && !!this.customer.contact && !!this.customer.contact.mobileNumber && this.customer.contact.mobileNumber !== '--') {
            const formattedPhone = this.phoneNumberPipe.getFormattedPhone(this.folder.customer.contact.mobileNumber,
                this.customerDetailForm.phoneConfigCountry, PhoneNumberFormat.E164);
            if (formattedPhone.countryCode !== null) {
                this.customerDetailForm.phoneConfigCountry = formattedPhone.countryCode;
            }
        }
    }

    folderInProhibitedWFStatus(): boolean {
        return this.prohibitedWorkflowStatus.includes(this.folder.currentWorkflowStatus?.status);
    }
    enableEditInfoAction(): boolean {
        return this.showActionEdit && !this.folder.stockClaim;
    }
    enableEditAddressAction(): boolean {
        return this.showActionEdit && !this.folderInProhibitedWFStatus() && !this.folder.stockClaim;
    }
}
