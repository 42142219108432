import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {PublicService} from '../../../shared/services/public.service';
import {FolderPublic} from '../../../models/folder.public.model';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {of, Subject} from 'rxjs';
import {WARRANTY_CONFIGS} from '../../../shared/data/static.config';
import {Constants} from '../../../Constants';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {ConfigurationReferential} from '../../../models/configurationReferential.model';
import {ConfigCodeMapsEnum} from '../../../shared/services/configuration-item-enum';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {ConfigurationReferentialDto} from '../../../models/configurationReferentialDto.model';
import {Unsubscriber} from '../../../unsubscriber';
import {TranslateService} from '@ngx-translate/core';
import {ShowImageModalComponent} from '../../image-modal/show-image-modal.component';
import {FileInfo} from '../../../models/file-info.model';
import {MatDialog} from '@angular/material/dialog';
import {currentLanguage} from '../../../store/organization/organization.selectors';
import {AttachmentType, AttachmentTypeSharedWithEnum} from '../../../models/attachment-type.model';

@Component({
  selector: 'app-public-folder-workflow-read-only',
  templateUrl: './public-folder-workflow-read-only.component.html',
  styleUrls: ['./public-folder-workflow-read-only.component.scss']
})
export class PublicFolderWorkflowReadOnlyComponent  extends Unsubscriber implements OnInit, OnDestroy {
    publicFolder: FolderPublic;
    protected _onDestroy = new Subject<void>();
    UNKNOWN_SUPPLIER = Constants.UNKNOWN_SUPPLIER;
    listWarranty: any = WARRANTY_CONFIGS;
    warranty;
    currentLanguage: string;

    configRefDto: ConfigurationReferentialDto;
    symptom: string;
    condition = '';
    warrantyFolder: string;
    activeSection: string;

    attachments: FileInfo[] = [];
    attachmentsTypes: AttachmentType[] = [] ;
    displayedColumns: string[] = ['file', 'date', 'attachmentType'];
    isSupplier: boolean;


    constructor(private publicService: PublicService,
                private activatedRoute: ActivatedRoute,
                private store$: Store<AppState>,
                private translateService: TranslateService,
                @Inject(LOCAL_STORAGE) private localStorage: StorageService,
                private router: Router,
                private dialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        // @ts-ignore
        this.isSupplier = this.activatedRoute.url.value.map(val => val.path).includes('repairer') ? false : true;
        this.getFolderDetailsAndInitCache();
    }

    getAllowedListAttachmentType(sharedWith: string, uid: string): void {
        this.publicService.getAllowedListAttachmentTypes(sharedWith, uid, this.currentLanguage).subscribe(data => {
            this.attachmentsTypes = data;
            const attachmentTypeCodes = data.map(value => value.code);
            this.attachments = this.publicFolder.attachments.filter(fileInfo => attachmentTypeCodes.includes(fileInfo.attachmentType));
        });
    }
    private getFolderDetailsAndInitCache() {
        const externalUid = this.activatedRoute.snapshot.params.uid;

        this.anotherSubscription = this.store$.pipe(
            select(currentLanguage),
            switchMap(language => {
                this.currentLanguage = language;
                return this.publicService.getFolderDetails(externalUid, this.isSupplier, this.currentLanguage);
            })
        ).subscribe(folderPublic => {
            this.publicFolder = folderPublic;
            const sharedWith = this.isSupplier ? AttachmentTypeSharedWithEnum.SUPPLIER : AttachmentTypeSharedWithEnum.REPAIRER;
            this.getAllowedListAttachmentType(sharedWith, this.publicFolder.externalUid);
            this.getConfigurationReferentialDto();
        }, () => {
            console.error('CANNOT LOAD PUBLIC FOLDER');
            this.router.navigate(['public', 'notfound']);
        });
    }
    updateSection(section: string): void {
        this.activeSection = section;
    }
    hasPublicQuotations(): boolean {
        return this.publicFolder.publicQuotations.length > 0;
    }
    ngOnDestroy(): void {
        this._onDestroy.unsubscribe();
    }

    getConfigurationReferentialDto(): void {
        this.store$.dispatch(new StartLoading());
        this.publicService.getConfigurationReferential(this.publicFolder.externalUid, this.currentLanguage)
            .subscribe(value => {
                if (!!value) {
                    this.configRefDto = value;
                    this.initConfig();
                }
                this.localStorage.set('publicConfiguration', value);
                this.store$.dispatch(new StopLoading());
            }, () => this.store$.dispatch(new StopLoading()));
    }

    private initConfig() {
        this.initFaultCode();
        this.initCondition();
        this.initWarranty();
    }

    public getAllConfiguration(configurationItemCode: string): ConfigurationReferential[] {
        if (!!this.configRefDto) {
            return !!this.configRefDto.configurationMap[configurationItemCode] &&
            this.configRefDto.configurationMap[configurationItemCode].length > 0 ?
                this.configRefDto.configurationMap[configurationItemCode] : [];
        } else {
            return [];
        }
    }

    private initFaultCode() {
        if (!!this.publicFolder.faultCode && this.publicFolder.faultCode !== 'CUSTOMER_NOT_FOUND_PANNE_CODE') {
            const symtoms = this.getAllConfiguration(ConfigCodeMapsEnum.SYMPTOMS)
                .filter(value => value.code === this.publicFolder.faultCode);
            if (symtoms.length > 0) {
                this.symptom = symtoms[0].label;
            }else{
                this.symptom = '';
            }
        } else {
            this.getDefaultSymptomFromFolder();
        }
    }

    private getDefaultSymptomFromFolder() {
        this.symptom = this.publicFolder.faultCodeLabel;
    }

    initCondition(): void {
        if (!!this.publicFolder.faultCodeCondition && this.publicFolder.faultCodeCondition !== 'CONSTANT') {
            const conditions = this.getAllConfiguration(ConfigCodeMapsEnum.CONDITIONS)
                .filter(value => value.code === this.publicFolder.faultCodeCondition);
            if (conditions.length > 0) {
                this.condition = conditions[0].label;
            }else{
                this.condition = '';
            }
        } else {
            this.getDefaultCondition();
        }
    }

    private getDefaultCondition() {
        this.condition = this.translateService.instant('COMPONENT.CONSTANT.CONDITION');
    }

    initWarranty(): void {
        this.warranty = this.listWarranty.filter(el => el.key === this.publicFolder.warranty.warranty.toString())[0];
        const configCode = this.publicFolder.warranty.warranty === 'SG' ? ConfigCodeMapsEnum.WARRANTY_REASON_SG : ConfigCodeMapsEnum.WARRANTY_REASON_HG;
        const warranties = this.getAllConfiguration(configCode)
            .filter(value => value.code === this.publicFolder.warranty.warrantyReason);
        if (warranties.length > 0) {
            this.warrantyFolder = warranties[0].label;
        } else {
            this.warrantyFolder = '';
        }
    }

    showImageModal(srcImage): void {
        this.dialog.open(ShowImageModalComponent, {
            height: 'auto',
            width: '90vh',
            minWidth: '90%',
            data: of([srcImage])
        });
    }
}
