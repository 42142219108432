import {GLOBAL} from '../../app-config';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CountryConfiguration} from '../../models/country.model';

@Injectable({
    providedIn: 'root'
})
export class CountryService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api/country/v1`;

    constructor(public httpClient: HttpClient) {
    }

    getByCountryCode(countryCode: string): Observable<CountryConfiguration> {
        return this.httpClient.get<CountryConfiguration>(`${this.path}/${countryCode}`);
    }
}
