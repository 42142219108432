<div class="page-layout simple fullwidth pb-10" *ngIf="!!folder">
    <mat-card>
        <mat-card-content>
            <fieldset>
                <legend class="h3 title-fieldset">{{'FOLDER.PRODUCT.DIALOG.TITLE' | translate}}</legend>
                <div fxLayout="row" fxLayoutAlign="space-between" class="mt-12">
                <div class="h-140 w-140  mr-24 mb-16" fxLayout="row" fxLayoutAlign="center center">
                    <app-product-image [image]="folder.productImageUrl"
                                       [imageCss]="'h-160-max'"
                                       [loaderCss]="'w-60 h-60'"></app-product-image>
                </div>
                <div fxFlex class=" ml-80 mt-4">
                    <div class="w-100-p px-10 mt-12">
                        <div fxLayout="row" fxLayoutAlign="none">
                            <mat-icon>folder_open</mat-icon>
                            <span class="font-weight-600">{{'FOLDER.TABLE.INCREMENTAL_REFERENCE' | translate}} :
                                 <span> {{folder.incrementalReference}}
                           </span>
                            </span>
                        </div>
                    </div>
                    <span class="font-weight-900 text-bold">{{folder.productLabel}}</span>
                    <div>
                            <span class="font-weight-600">
                                {{'FOLDER.PRODUCT.PRODUCT_CODE' | translate}} :
                            </span>
                        <span> {{folder.productCode | slice:-10}}</span>
                    </div>
                    <div class="w-100-p px-10 mt-12">
                        <div fxLayout="row" fxLayoutAlign="none">
                            <mat-icon>today</mat-icon>
                            <span class="font-weight-600">{{'INVOICE.PURCHASE_DATE' | translate}} :
                                 <span> {{folder.purchaseDate ?
                                     (getDate(folder.purchaseDate) |date:"dd/MM/yyyy") : '' }}
                           </span>
                            </span>
                        </div>
                    </div>
                    <div class="w-100-p px-10 mt-12">
                        <div fxLayout="row" fxLayoutAlign="none">
                            <mat-icon>receipt</mat-icon>
                            <div>
                                <span class="font-weight-600">{{'INVOICE.TITLE' | translate}} :</span>
                                <span> {{folder.invoiceNumber}}
                           </span>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="folder.managementSite">
                        <div class="w-100-p px-10 mt-12">
                            <div fxLayout="row" fxLayoutAlign="none">
                                <mat-icon>store_mall_directory</mat-icon>
                                <span class="font-weight-600">{{'FOLDER.MANAGEMENT_SITE' | translate}} :
                                <span
                                    class=" font-weight-900 text-bold ">{{folder.managementSite.code + ' - ' + folder.managementSite.name}}</span>
                                </span>
                            </div>
                        </div>

                    </ng-container>
                    <ng-container *ngIf="!!folder.supplier">
                        <div class="w-100-p px-10 mt-12">
                            <div fxLayout="row" fxLayoutAlign="none">
                                <mat-icon>local_shipping</mat-icon>
                                <span class="font-weight-600"> {{'FOLDER.SUPPLIER.TITLE' | translate}} :
                                       <span
                                             (click)="showDetailSupplier()">
                                             <ng-container
                                                 *ngIf="folder.supplier?.code===UNKNOWN_SUPPLIER;else SHOW_SUPPLIER_NAME">
                                                {{UNKNOWN_SUPPLIER | translate}}
                                            </ng-container>
                                            <ng-template #SHOW_SUPPLIER_NAME>
                                              <span> {{folder.supplier?.code + " - " + folder.supplier?.label}}</span>
                                            </ng-template>
                           </span>
                            </span>
                            </div>
                        </div>
                    </ng-container>

                    <!-- serial numbers section-->
                    <div class="w-100-p px-10 mt-12">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                            <div fxLayout="row" fxLayoutAlign="center center" >
                                <mat-icon svgIcon="bar_code"></mat-icon>
                                <span class="font-weight-600"></span>
                                <span> {{folder.serialNumber1}}</span>
                            </div>
                            <div *ngIf="folder.serialNumber2" fxLayout="row" fxLayoutAlign="center center" >
                                <mat-icon svgIcon="bar_code"></mat-icon>
                                <span class="font-weight-600"></span>
                                <span> {{folder.serialNumber2}}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            </fieldset>
                <div fxLayout="row" fxLayoutAlign="space-between" class="mt-12">
                <div class="w-48" *ngIf="!!warranty">
                    <mat-icon
                        [ngClass]="'font-size-30 w-40  h-40 ' + warranty.icon.class">
                        {{warranty.icon.code}}
                    </mat-icon>
                </div>
                <div fxFlex>
                    <span class="font-weight-900 text-bold">{{'FOLDER.WARRANTY.TITLE' | translate}} :</span>
                    <div>
                        <span>{{ warrantyFolder }}
                        </span>
                    </div>
                </div>

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" class="pt-12">
                <div class="w-48">
                    <mat-icon class="icon-toolbar_quotation">build</mat-icon>
                </div>
                <div fxFlex>
                    <span class="font-weight-900 text-bold">{{'FOLDER.SYMPTOM.TITLE' | translate}} :</span>
                    <div>
                        <span>{{symptom}}</span>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" class="pt-12">
                <div class="w-48">
                    <mat-icon>dehaze</mat-icon>
                </div>
                <div fxFlex>
                    <span class="font-weight-900 text-bold">{{'FORM.TITLE.CONDITION' | translate}} :</span>
                    <div>
                        <span>{{condition}}</span>
                    </div>
                </div>
            </div>

            <!-- attachments -->
            <div class="pt-12">
                <app-files-list [displayedColumns]="displayedColumns"
                                [attachmentsTypes]="attachmentsTypes"
                                [(data)]="attachments">
                </app-files-list>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between" class="mt-16">
                <div fxFlex class="pt-4">
                    <span class="font-weight-900 text-bold">{{'FOLDER.COMMENT_FORM.LABEL' | translate}} :</span>
                    <ng-container *ngIf="folder.customerComment">
                        <div class="w-48">
                            <mat-icon class="icon-toolbar_quotation">comment</mat-icon>
                        </div>
                        <div>
                            <span>{{folder.customerComment.content}}</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="folder.agentComment">
                        <div class="w-48">
                            <mat-icon class="icon-toolbar_quotation">comment</mat-icon>
                        </div>
                        <div>
                            <span>{{folder.agentComment.content}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <form [formGroup]="repairCommentForm" (ngSubmit)="onSubmit()">
                <div fxLayout="row" fxLayoutAlign="space-between" class="mt-12">
                    <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                        <mat-label>{{'MODAL.REPAIRER.COMMENT.DESCRIPTION' | translate}}</mat-label>
                        <textarea matInput rows="4" cols="7"
                                  [placeholder]="'MODAL.REPAIRER.COMMENT.DESCRIPTION' | translate"
                                  formControlName="description"></textarea>
                    </mat-form-field>
                    <button mat-button class="mb-20" [disabled]="!readyToSent()"
                    >
                        <mat-icon>send</mat-icon>
                    </button>
                </div>
            </form>
            <app-public-survey-summary *ngIf="!!folder.surveyResponse && folder.surveyResponse.finished"
                                       [surveyResponseSummary]="folder.surveyResponse.surveyResponse"></app-public-survey-summary>
        </mat-card-content>
    </mat-card>
</div>

