import {Component} from '@angular/core';

@Component({
    selector: 'app-prepare-spare-parts-order',
    templateUrl: './prepare-spare-parts-order.component.html',
    styleUrls: ['./prepare-spare-parts-order.component.css']
})
export class PrepareSparePartsOrderComponent {
    // todo to be implement with the new logic of spare part odrer
}
