<div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
    <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="30" fxFlex.lt-md="100">
        <ngx-dropzone
            #dropzone
            label="{{fileUploadForm.hasFile ? fileUploadForm.file: 'FOLDER.FILE.UPLOAD_MSG' | translate}}"
            [showPreviews]="true" [multiple]="false" [accept]="'*'" (filesAdded)="onFilesAdded($event)">
        </ngx-dropzone>
    </mat-card>

    <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="70" fxFlex.lt-md="100">
        <table mat-table [dataSource]="attachmentQuotationDataTable" class="w-100-p mt-8">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{'FOLDER.FILE.TITLE' | translate}}</th>
                <td mat-cell *matCellDef="let element" class="text-left">
                    <ng-container *ngIf="element.info?.id;else FILE_WITHOUT_DOWNLOAD">
                        <a (click)="downloadFile(element.info.id)" class="cursor-pointer">
                            <p class="file_name" >
                                <mat-icon>file_copy</mat-icon>
                                {{!!element.info ? element.info.name : (!!getFileInfo(element.info.id) ? getFileInfo(element.info.id).name : '--')}}
                            </p>
                        </a>
                    </ng-container>
                    <ng-template #FILE_WITHOUT_DOWNLOAD>
                        <p class="file_name" >
                            <mat-icon>file_copy</mat-icon>
                            {{element.info?.name}}
                        </p>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> {{'FOLDER.FILE.DATE' | translate}}</th>
                <td mat-cell
                    *matCellDef="let element">
                    <p>
                        {{!!element.info ? (currentDate | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate)  : (currentLanguage$ | async)) : (getFileInfo(element).uploadDate | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate) : (currentLanguage$ | async)) }}
                    </p>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> {{'BUTTON.ACTION' | translate}}</th>
                <td mat-cell
                    *matCellDef="let element">
                    <mat-icon color="warn" class="cursor-pointer" (click)="removeFile(element)">
                        delete_forever
                    </mat-icon>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsAttachment"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsAttachment;"></tr>
        </table>
    </mat-card>
</div>