<div *ngIf="!!product">
    <div fxLayout="row" fxLayoutAlign="start start" class="product-title text-bold">{{product.label}}</div>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px" class="mt-8" *ngIf="product.code !== this.UNKNOWN_PRODUCT_CODE">
        <strong> {{'PRODUCT.PREVIEW_CARD.LABELS.CODE' | translate}}:</strong>
        <span class="item-info" (click)="showProductDetails()">{{product.code}} </span>
    </div>

    <app-product-supplier-field *ngIf="product.supplier && product?.supplier?.code !== this.UNKNOWN_PRODUCT_SUPPLIER"
                                [supplier]="product.supplier"
                                [isEditable]="(isElectroDepot$ | async)"
                                (save)="updateSupplier($event)">
    </app-product-supplier-field>
</div>