import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DashboardUtils} from '../../store/dashboard/dashboard.utils';
import {PageSearchRequest} from '../../models/page-search-request.model';
import {Page} from '../../models/page.model';
import {DashboardRule} from '../../models/dashboard/dashboard-rule.model';
import {Dashboard} from '../../models/dashboard/dashboard.model';

@Injectable({
    providedIn: 'root'
})
export class DashboardV2Service {

    constructor(private httpClient: HttpClient) {
    }

    getAllDashboards = () => this.httpClient.get<Dashboard[]>(`${DashboardUtils.baseUrl}/all`);
    getServerInfo = () => this.httpClient.get(`${DashboardUtils.baseUrl}/server`);

    save = request => this.httpClient.post(`${DashboardUtils.baseUrl}`, request);
    update = request => this.httpClient.put(`${DashboardUtils.baseUrl}`, request);

    findByRuleId = id => this.httpClient.get<DashboardRule>(`${DashboardUtils.baseUrl}/${id}`);
    search = (searchRequest: PageSearchRequest, inputs: any) =>
        this.httpClient.post<Page<DashboardRule>>(`${DashboardUtils.baseUrl}/search`, inputs, {params: <any>searchRequest})

    deleteRule = ruleId => this.httpClient.delete(`${DashboardUtils.baseUrl}/rule/${ruleId}`);
    deleteDashboard = dashboardId => this.httpClient.delete(`${DashboardUtils.baseUrl}/dashboard/${dashboardId}`);
}
