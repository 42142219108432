import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
// @ts-ignore
import {Observable} from 'rxjs';
import {Shared} from '../survey/shared';
import {GLOBAL} from '../../app-config';
import {SurveyElement} from '../../models/element.model';
import {DiagnosticResponse} from './diagnostic.response';

@Injectable({
    providedIn: 'root'
})
export class SurveyService {

    public isSubmittedForm: EventEmitter<boolean> = new EventEmitter<boolean>();
    public submitElement: EventEmitter<SurveyElement> = new EventEmitter<SurveyElement>();

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api/v1/survey`;

    constructor(private http: HttpClient) {
    }


    public getFile(fileId): Observable<Blob> {
        return this.http.get(`${this.path}/files/${fileId}`, {responseType: 'blob'});
    }

    /* ---------------- Summary survey --------------------- */
    public getSummarySurvey(surveyResponseId: string): Observable<SurveyElement[]> {
        return this.http.get<SurveyElement[]>(`${this.path}/${surveyResponseId}`);
    }
}
