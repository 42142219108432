<div class="text-center">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="text-bold">{{'FOLDER.FILE.UPLOAD' | translate}}</h2>
        <button mat-icon-button class="ml-40" (click)="onClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <mat-checkbox *ngIf="data.hasDeleteOption"
            labelPosition="after" [(ngModel)]="deleteExistingValues">{{'FOLDER.FILE.DELETE_EXISTING' | translate}}</mat-checkbox>
        <div class="dropzone-container">
            <ngx-dropzone
                #dropzone label="{{fileUploadForm.hasFile ? fileUploadForm.file: 'FOLDER.FILE.UPLOAD_MSG' | translate}}"
                [showPreviews]="data.showPreviews" [multiple]="false"
                [accept]="data.acceptedFile"
                (filesAdded)="onFilesAdded($event)"></ngx-dropzone>

            <button mat-mini-fab color="warn" *ngIf="fileUploadForm.hasFile" class="dropzone-cleaner"
                    (click)="onClearDropzone()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <div *ngIf="fileUploadForm.isUploading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>

        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mt-16">
        <div class="w-100-p">
            <div class="mb-8" *ngIf="fileUploadForm.hasSuccessfullyUploaded; else actionsContainer;">
                <div class="message-box mb-16">
                    {{'FOLDER.FILE.SAVED' |translate  }}
                </div>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="center center">
                    <button mat-button color="accent"
                            (click)="onCloseWithURL()">{{'BUTTON.SUBMIT' | translate}}</button>
                </div>
            </div>
            <ng-template #actionsContainer>
                <div class="w-100-p mb-8" fxLayout="row" fxLayoutAlign="center center">
                    <button mat-stroked-button   class=" aster_btn_cancel" (click)="onClose()">{{'BUTTON.CLOSE' | translate}}</button>
                    <button mat-stroked-button color="accent" class="aster_btn" [disabled]="!fileUploadForm.hasFile"
                            (click)="onUpload()">{{'BUTTON.VALIDATE' | translate}}</button>

                </div>
            </ng-template>
        </div>
    </mat-dialog-actions>
</div>
