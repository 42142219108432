import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Address, Company, LandLinePhoneInput, MobilePhoneInput} from '../../models/customer/customer.model';

@Injectable()
export class SharedService {
    private serialNumberSubject = new Subject<string>();
    private serialNumber2Subject = new Subject<string>();
    private mobilePhoneInputShared$ = new ReplaySubject<MobilePhoneInput>(1);
    private isMobilePhoneInputValid$ = new ReplaySubject<boolean>(1);
    private isLandLineInputValid$ = new ReplaySubject<boolean>(1);
    private companyShared$ = new ReplaySubject<Company>(1);
    private landLinePhoneInputShared$ = new ReplaySubject<LandLinePhoneInput>(1);
    private addressShared$ = new ReplaySubject<Address>(1);
    private validateForm$ = new Subject<boolean>();
    private resetAddress$ = new Subject<Address>();
    private countries$ = new ReplaySubject<string[]>(1);
    private currentCountry$ = new ReplaySubject<string>(1);

    public updateMobilePhone(inputTel: MobilePhoneInput): void {
        this.mobilePhoneInputShared$.next(inputTel);
    }

    public updateMobilePhoneInputValidity(isValid: boolean): void {
        this.isMobilePhoneInputValid$.next(isValid);
    }

    public updateLandLineInputValidity(isValid: boolean): void {
        this.isLandLineInputValid$.next(isValid);
    }

    public updateCompany(company: Company): void {
        this.companyShared$.next(company);
    }

    public getUpdateMobilePhoneEvent(): Observable<MobilePhoneInput> {
        return this.mobilePhoneInputShared$.asObservable();
    }

    public isMobilePhoneInputValid(): Observable<boolean> {
        return this.isMobilePhoneInputValid$.asObservable();
    }

    public updateLandLineInput(inputTel: LandLinePhoneInput): void {
        this.landLinePhoneInputShared$.next(inputTel);
    }

    public getUpdateLandLineInputEvent(): Observable<LandLinePhoneInput> {
        return this.landLinePhoneInputShared$.asObservable();
    }
    public updateAddress(address: Address): void {
        this.addressShared$.next(address);
    }

    public getUpdateAddressEvent(): Observable<Address> {
        return this.addressShared$.asObservable();
    }

    public validateForm(isValid: boolean): void {
        this.validateForm$.next(isValid);
    }

    public getValidateForm(): Observable<boolean> {
        return this.validateForm$.asObservable();
    }
    public updateResetAddress(address: Address): void {
        this.resetAddress$.next(address);
    }

    public getUpdateResetAddressEvent(): Observable<Address> {
        return this.resetAddress$.asObservable();
    }

    public setCountries(language: string[]): void {
        this.countries$.next(language);
    }

    public getCountries(): Observable<string[]> {
        return this.countries$.asObservable();
    }

    public setCurrentCountry(codeLanguage: string): void {
        this.currentCountry$.next(codeLanguage);
    }

    public getCurrentCountry(): Observable<string> {
        return this.currentCountry$.asObservable();
    }
    updateSerialNumber(serialNumber: string): void {
        this.serialNumberSubject.next(serialNumber);
    }

    getSerialNumberObservable(): Observable<string> {
        return this.serialNumberSubject.asObservable();
    }
    updateSerialNumber2(serialNumber: string): void {
        this.serialNumber2Subject.next(serialNumber);
    }

    getSerialNumber2Observable(): Observable<string> {
        return this.serialNumber2Subject.asObservable();
    }
}
