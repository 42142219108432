import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-refund-protocol-to-return-to-client',
    templateUrl: './refund-protocol-to-return-to-client.component.html',
    styleUrls: ['./refund-protocol-to-return-to-client.component.css']
})
export class RefundProtocolToReturnToClientComponent implements OnInit {
    signedProtocolReturnedToClient: boolean;
    @Output() inputMap = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
    }

    onSubmit() {
        this.inputMap.emit({})
    }
}
