import {Component, Input} from '@angular/core';
import {QuotationLine} from '../../../models/quotation.model';

@Component({
    selector: 'app-ordered-spare-parts',
    templateUrl: './ordered-spare-parts.component.html',
    styleUrls: ['./ordered-spare-parts.component.css']
})
export class OrderedSparePartsComponent {

    @Input() orderedSpareParts: QuotationLine[];
    @Input() isUnderWarranty: boolean;
    columns: string[] = ['code', 'label', 'quantity', 'price', 'tva'];

    constructor() {
    }


}
