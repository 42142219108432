import {Component, Input, OnInit} from '@angular/core';
import {QuotationLine} from '../../../../../../../../models/quotation.model';

@Component({
  selector: 'app-ordered-content-info',
  templateUrl: './ordered-content-info.component.html'
})
export class OrderedContentInfoComponent implements OnInit {


    @Input() selectedSparePart: QuotationLine;
  constructor() { }

  ngOnInit(): void {
  }

}
