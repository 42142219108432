import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-folder-external-references-dialog',
    templateUrl: './folder-external-references-dialog.component.html',
    styleUrls: ['./folder-external-references-dialog.component.scss']
})
export class FolderExternalReferencesDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public externalReferences: any) {
    }

}
