<div fxLayout="row">
    <div class="pl-24 w-100-p" fxLayoutAlign="end end">
        <ng-container *ngIf="enableEditInfoAction()">
            <button *ngIf="!editCustomer" mat-icon-button (click)="editCustomerInfos()" aria-label="more"
                    matTooltip="{{'FOLDER.ADDRESS.SUBMIT_BUTTON' | translate}}">
                <mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="editCustomer" mat-icon-button (click)="editCustomerInfos()" aria-label="more"
                    matTooltip="{{'FOLDER.ADDRESS.CLOSE_BUTTON' | translate}}">
                <mat-icon>close</mat-icon>
            </button>
        </ng-container>
    </div>
</div>

<ng-container *ngIf="!!customer && !editCustomer">

    <ng-container *ngIf="!!customer.company && customer.type !== customerTypeEnum.PRIVATE_INDIVIDUAL">

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class="mb-4">
            <mat-icon>local_offer</mat-icon>
            <div>
                {{ customer.company.label }}
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class="mb-4">
            <mat-icon>work_outline</mat-icon>
            <div>
                {{ customer.company.legalForm }}
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="!!customer.loyaltyCardNumber">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class="mb-4">
            <mat-icon>card_membership</mat-icon>
            <div>
                {{ customer.loyaltyCardNumber }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!!customer.reference">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class="mb-4">
            <mat-icon>local_offer</mat-icon>
            <div>
                {{ customer.reference }}
            </div>
        </div>
    </ng-container>
    <ng-container>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class="mb-4">
            <mat-icon>person_outline</mat-icon>
            <div>
                {{!!customer.gender ? ('CUSTOMER.CIVILITY.' + customer.gender|translate) : ''}}
                {{!!customer.firstName ? customer.firstName : '-' }}
                {{ !!customer.lastName ? customer.lastName : '-' }}
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
            <mat-icon>local_phone</mat-icon>
            <mat-icon class="mr-8 mb-4">
             <span
                 *ngIf="!!customer.contact && !!customer.contact.mobileNumber && isValidCountryCode(customerDetailForm.phoneConfigCountry); else globeIcon">
                            <span style="vertical-align: sub;"
                                  [attr.class]="'flag-icon h-16 w-20 flag-icon-' + getCountryCodeByPhoneNumber(customerDetailForm.phoneConfigCountry) | lowercase"></span>
                        </span>
                <ng-template #globeIcon>
                    public
                </ng-template>
            </mat-icon>
            <div *ngIf="!!customer.contact && !!customer.contact.mobileNumber">
                {{getInternationalNumber(customer.contact.mobileNumber)}}
            </div>


            <mat-icon class="mr-8 mb-4">
             <span
                 *ngIf="customer.contact?.phoneNumber && isValidCountryCode(customerDetailForm.phoneConfigCountry)">
                            <span style="vertical-align: sub;"
                                  [attr.class]="'flag-icon h-16 w-20 flag-icon-' + getCountryCodeByPhoneNumber(customerDetailForm.phoneConfigCountry) | lowercase"></span>
                        </span>
            </mat-icon>
            <div *ngIf="customer.contact?.phoneNumber">
                {{getInternationalNumber(customer.contact.phoneNumber)}}
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
            <mat-icon>email</mat-icon>
            <div>
                {{ !!customer.contact ? customer.contact.email : '-' }}
            </div>
        </div>
    </ng-container>

    <div fxLayout="column" fxLayoutGap="30px" class="mt-12">
        <div *ngIf="((isProductRecall && !isSwap) || isHomeRepair) && !isOriginator && customerInterventionAddress">
            <div fxLayoutAlign="space-between center">
                <label class="ml-4 mt-8"> {{"CUSTOMER.ADDRESS_INFO"| translate}}</label>
                <ng-container *ngIf="enableEditAddressAction()">
                    <button *ngIf="!editInterventionAddress" mat-icon-button
                            (click)="switchEditInterventionAddressInfo()"
                            aria-label="more"
                            matTooltip="{{'FOLDER.ADDRESS.SUBMIT_BUTTON' | translate}}">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button *ngIf="editInterventionAddress" mat-icon-button
                            (click)="switchEditInterventionAddressInfo()"
                            aria-label="more"
                            matTooltip="{{'FOLDER.ADDRESS.CLOSE_BUTTON' | translate}}">
                        <mat-icon>close</mat-icon>
                    </button>
                </ng-container>
            </div>
            <ng-container *ngIf="!editInterventionAddress && customerInterventionAddress">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
                    <mat-icon>person_pin_circle</mat-icon>
                    <div>
                        {{getAddress(customerInterventionAddress)}}
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
                    <mat-icon>location_city</mat-icon>
                    <div>
                        {{getAddressDetails(customerInterventionAddress)}}
                    </div>
                </div>
                <ng-container *ngIf="!!customerInterventionAddress?.accessibility">

                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
                        <mat-icon>home</mat-icon>
                        <div>{{(customerInterventionAddress.accessibility.apartment ? 'CODE_LABEL_CONFIG.FORM.PARAMETERS.ADDITIONAL_TIME.APARTMENT' :
                            'CODE_LABEL_CONFIG.FORM.PARAMETERS.ADDITIONAL_TIME.HOUSE') |translate}}
                        </div>
                    </div>
                    <ng-container *ngIf="customerInterventionAddress.accessibility.apartment">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
                            <mat-icon>ballot</mat-icon>
                            <div>{{customerInterventionAddress.accessibility.floorCount }}
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
                            <mat-icon>stairs</mat-icon>
                            <div
                                class="text-semibold">{{(customerInterventionAddress.accessibility.elevator ? 'CODE_LABEL_CONFIG.FORM.PARAMETERS.ADDITIONAL_TIME.WITH_ELEVATOR'
                                : 'CODE_LABEL_CONFIG.FORM.PARAMETERS.ADDITIONAL_TIME.WITHOUT_ELEVATOR')|translate}}

                            </div>
                        </div>
                    </ng-container>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
                        <mat-icon>local_parking</mat-icon>
                        <ng-container *ngIf="customerInterventionAddress.accessibility.easyParking">
                            <div
                                class="text-semibold">{{'CODE_LABEL_CONFIG.FORM.PARAMETERS.ADDITIONAL_TIME.PARKING_EASY' |translate}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="customerInterventionAddress.accessibility.mediumParking">
                            <div
                                class="text-semibold">{{'CODE_LABEL_CONFIG.FORM.PARAMETERS.ADDITIONAL_TIME.PARKING_MEDIUM' |translate}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="customerInterventionAddress.accessibility.difficultParking">
                            <div
                                class="text-semibold">{{'CODE_LABEL_CONFIG.FORM.PARAMETERS.ADDITIONAL_TIME.PARKING_DIFFICULT' |translate}}
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <div *ngIf="isProductRecall || !editInterventionAddress">
            <div fxLayoutAlign="space-between center">
                <label class="ml-4  mt-12"> {{"CUSTOMER.DEFAULT.ADDRESS_INFO"| translate}}</label>
                <ng-container *ngIf="enableEditAddressAction()">
                    <button *ngIf="!editDefaultAddress" mat-icon-button (click)="switchEditDefaultAddressInfo()"
                            aria-label="more"
                            matTooltip="{{'FOLDER.ADDRESS.SUBMIT_BUTTON' | translate}}">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button *ngIf="editDefaultAddress" mat-icon-button (click)="switchEditDefaultAddressInfo()"
                            aria-label="more"
                            matTooltip="{{'FOLDER.ADDRESS.CLOSE_BUTTON' | translate}}">
                        <mat-icon>close</mat-icon>
                    </button>
                </ng-container>
            </div>
            <ng-container *ngIf="!editDefaultAddress">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
                    <mat-icon>person_pin_circle</mat-icon>
                    <div>
                        {{getAddress(customerDefaultAddress)}}
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class=" mt-4">
                    <mat-icon>location_city</mat-icon>
                    <div>
                        {{getAddressDetails(customerDefaultAddress)}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</ng-container>


<form *ngIf="customerDetailForm.form" [formGroup]="customerDetailForm.form" (ngSubmit)="onSubmit()">
    <ng-container *ngIf="!!customer && editCustomer">
        <div fxLayoutAlign="start center" fxLayout="row" fxLayout.lt-sm="column" fxLayout.md="column"
             fxLayoutGap="10px">
            <ng-container *ngIf="!isProductRecall">
                <mat-form-field appearance="outline" fxFlex="40">
                    <mat-label>{{'CUSTOMER.REFERENCE'|translate}}</mat-label>
                    <input matInput placeholder="{{'CUSTOMER.REFERENCE'|translate}}" formControlName="reference">
                    <mat-icon matSuffix class="disabled-text">assignment_ind</mat-icon>
                </mat-form-field>
            </ng-container>
            <mat-form-field appearance="outline" fxFlex="40">
                <mat-label>{{'CUSTOMER.CIVILITY'|translate}}</mat-label>
                <mat-select formControlName="gender">
                    <mat-option *ngFor="let civility of civilitySelectBoxOptions"
                                [value]="civility.key">
                        <span>{{ civility.label |translate}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="customer.type !== customerTypeEnum.PRIVATE_INDIVIDUAL"
             fxLayoutAlign="start center" fxLayout="row" fxLayout.lt-sm="column" fxLayout.md="column"
             fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex="40">
                <mat-label>
                    {{'CUSTOMER.COMPANY_NAME'|translate}}</mat-label>
                <input matInput placeholder="{{'CUSTOMER.COMPANY_NAME'|translate}}"
                       formControlName="label"
                       id="create_folder_corporateName">
                <mat-icon matSuffix class="disabled-text">verified_user</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="40">
                <mat-label>
                    {{'CUSTOMER.COMPANY.LEGALFORM'|translate}}</mat-label>
                <mat-select id="create_folder_legalForm"
                            formControlName="legalForm">
                    <mat-option *ngFor="let legalForm of (legalFormSelectBoxOptions$ | async)"
                                id="{{'create_folder_legalForm_' + legalForm}}"
                                [value]="legalForm">
                        <span>{{legalForm |translate}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayoutAlign="start center" fxLayout="row" fxLayout.lt-sm="column" fxLayout.md="column"
             fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex="40">
                <mat-label>{{'CUSTOMER.FIRST_NAME'|translate}}</mat-label>
                <input matInput placeholder="{{'CUSTOMER.FIRST_NAME'|translate}}" formControlName="firstName"
                       [required]="!isProductRecall">
                <mat-icon matSuffix class="disabled-text">person</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="40">
                <mat-label>{{'CUSTOMER.LAST_NAME'|translate}}</mat-label>
                <input matInput placeholder="{{'CUSTOMER.LAST_NAME'|translate}}" formControlName="lastName"
                       [required]="!isProductRecall">
                <mat-icon matSuffix class="disabled-text">person</mat-icon>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxLayout.lt-sm="column"
             fxLayout.md="column">
            <div fxFlex="40">
                <mobile-phone-input-form *ngIf="!!mobilePhoneOutput"
                                         [mobilePhoneInput]="mobilePhoneOutput">
                </mobile-phone-input-form>
            </div>


            <mat-form-field appearance="outline" fxFlex="40">
                <mat-label>{{'CUSTOMER.EMAIL'|translate}}</mat-label>
                <input matInput placeholder="{{'CUSTOMER.EMAIL'|translate}}" formControlName="email"
                       type="email" isEmail>
                <mat-icon matSuffix class="disabled-text">email</mat-icon>
            </mat-form-field>


        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
            <mat-icon>person_pin_circle</mat-icon>
            <ng-container *ngIf="customerInterventionAddress?.address; else NO_INTERVENTION_ADDRESS">
                <div>{{customerInterventionAddress.address + "," + customerInterventionAddress.secondAddress}}</div>
            </ng-container>
            <ng-template #NO_INTERVENTION_ADDRESS>
                <div>-</div>
            </ng-template>

        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
            <mat-icon>location_city</mat-icon>
            <div>{{ (customerInterventionAddress?.zipCode || '') + "," + (customerInterventionAddress?.city || '') + ", " + (customerInterventionAddress?.countryName || '-')}}
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="editDefaultAddress">
        <app-generic-address-form [addressesInput]="customerDefaultAddress"
                                  [withAccessibility]="false"></app-generic-address-form>
    </ng-container>
    <ng-container *ngIf="editInterventionAddress">
        <app-generic-address-form [addressesInput]="customerInterventionAddress" [withAccessibility]="true"
        ></app-generic-address-form>
    </ng-container>
    <div fxLayoutAlign="end center" *ngIf="(editCustomer||editDefaultAddress||editInterventionAddress)">
        <button type="submit" mat-stroked-button aria-label="Submit"
                class="aster-btn_border"
                [disabled]="isFormDisabled()">
            {{'BUTTON.EDIT' | translate}}
        </button>
    </div>
</form>

