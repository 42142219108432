import {CustomerState, initialCustomerState} from './customer.state';
import {CustomerActions, CustomerActionType} from './customer.actions';
import {Customer} from '../../models/customer/customer.model';
import {PagedResult} from '../../models/paged-result.model';
import {CustomerType} from '../../models/enums/CustomerType.enum';

export function customerReducer(state: CustomerState = initialCustomerState, action: CustomerActions): CustomerState {

    switch (action.type) {
        case CustomerActionType.CUSTOMER_API_INFO_LOADED_SUCCESSFULLY:
            return {
                ...state,
                customerApiInfo: action.customerApiInfo
            };

        case CustomerActionType.UPDATE_CUSTOMER:
            return {
                ...state,
                customer: action.customer
            };

        case CustomerActionType.RESET_CUSTOMER:
            return {
                ...state,
                customer: action.keepFilters
                    ? {type: state.customer.type, lastName: state.customer.lastName} as Customer
                    : new Customer()
            };

        case CustomerActionType.CUSTOMER_TYPE_CHANGED:
            return {
                ...state,
                isProfessional: action.value === CustomerType.PROFESSIONAL
            };

        case CustomerActionType.SET_STOCK_CLAIM_CREATION:
            return {
                ...state,
                isStockClaimCreation: action.isStockClaimCreation
            };


        case CustomerActionType.UPDATE_FORM_VALIDITY:
            return {
                ...state,
                isCustomerFormValid: action.isValid
            };

        case CustomerActionType.UPDATE_CUSTOMER_SEARCH_RESULT:
            return {
                ...state,
                customerSearchResult: action.customers
            };

        case CustomerActionType.RESET_CUSTOMER_SEARCH_RESULT:
            return {
                ...state,
                customerSearchResult: new PagedResult<Customer>()
            };

        case CustomerActionType.SET_CREATION_WITHOUT_CUSTOMER:
            return {
                ...state,
                isFolderCreationWithoutCustomer: action.isWithoutCustomer
            };

        case CustomerActionType.RESET_CUSTOMER_STATE:
            return initialCustomerState;

        default:
            return state;
    }
}
