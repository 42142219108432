import {Component, Input} from '@angular/core';
import {Folder} from '../../../models/folder.model';
import {InstructionUserTask} from '../../../models/instruction-user-task.model';

@Component({
    selector: 'repairer-task-title',
    templateUrl: './repairer-task-title.component.html'
})
export class RepairerTaskTitleComponent {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() productLocation: string;

}
