<div class="tab-folder_details mat-elevation-z2 " *ngIf="!!folder">
    <!-- Info Section -->
    <section id="folderInfoSection" (click)="updateSection('folderInfoSection')">
        <input type="radio" name="sections" id="folderInfo" checked>
        <label for="folderInfo" class="text-center">
            <mat-icon class="mt-20">info</mat-icon>
            <p class="h4">  {{'FOLDER.INFOS_TITLE'| translate}} </p>
        </label>
        <article class="article-scroll">
            <app-folder-details-card
                [permission]="permission"></app-folder-details-card>
        </article>
    </section>
    <!-- Status Section :  a savoir avec Mohamed ==> il y a un traitement -->
    <section id="statusHistorySection"
             (click)="updateSection('statusHistorySection')">
        <input type="radio" name="sections" id="statusHistory" [disabled]="workflowStatusNumber === 0">
        <label for="statusHistory" class="text-center">
            <mat-icon [matBadge]="workflowStatusNumber > 0 ?  workflowStatusNumber : ''"
                      [ngClass]="workflowStatusNumber > 0 ? 'mt-20' : 'mt-20 disabled-tab_section'">history</mat-icon>
            <p [ngClass]="workflowStatusNumber > 0 ? 'h4' : 'disabled-tab_section h4'" > {{'FOLDER.STATUS.HISTORY'| translate}}</p>
        </label>
        <article class="article-scroll" disabled ="workflowStatusNumber > 0 && activeSection=='statusHistorySection'">
            <app-folder-history-status-card [events]="workflowStatus"></app-folder-history-status-card>
        </article>
    </section>
    <!-- Files Section : à discuter -->
    <section id="filesSection" (click)="updateSection('filesSection')" >
        <input type="radio" name="sections" id="files" >
        <label for="files" class="text-center">
            <mat-icon class="mt-20"
                      matBadgeColor="red" matBadgePosition="above after"
                      [matBadge]="folder.attachments.length > 0 ? folder.attachments.length : ''">attach_file
            </mat-icon>
            <p class="h4">
                {{'FOLDER.FILE.ATTACHMENTS_LIST' | translate}}
            </p>
        </label>
        <article class="article-scroll">
            <app-folder-files-card></app-folder-files-card>
        </article>
    </section>
    <!-- Diagnostic Section : à discuter -->
    <section id="surveySection" (click)="updateSection('surveySection')" >
        <input type="radio" name="sections" id="survey" [disabled]="!isEnabledSurvey()">
        <label for="survey" class="text-center">
            <mat-icon class="mt-20" [ngClass]="!isEnabledSurvey()?'disabled-tab_section' : ''">info</mat-icon>
            <p [ngClass]="!isEnabledSurvey()?'disabled-tab_section h4' : 'h4'">  {{'CODE_LABEL_CONFIG.CONFIG_CODES.DIAGNOSTIC' | translate}} </p>
        </label>
        <article *ngIf="isEnabledSurvey()" disabled="isEnabledSurvey() && activeSection==='surveySection'"  class="article-scroll">
            <app-survey-summary [surveyResponseId]="folder.survey.surveyResponseId"></app-survey-summary>
        </article>
    </section>
    <!-- Event Section -->
    <section id="eventSections" (click)="updateSection('eventSections')">
        <input type="radio" name="sections" id="events" [disabled]="eventsNumber === 0">
        <label for="events" class="text-center">
            <mat-icon
                [ngClass]="eventsNumber > 0 ? 'mt-20' : 'mt-20 disabled-tab_section'"
                [matBadge]="eventsNumber > 0 ? eventsNumber : ''" >event_note</mat-icon>
            <p [ngClass]="eventsNumber > 0 ? 'h4' : 'disabled-tab_section h4'">  {{'FOLDER.EVENT.TITLE' | translate}}  </p>
        </label>
        <article class="article-scroll" disabled="eventsNumber > 0 && activeSection=='eventSections'">
            <app-folder-events-card [events]="events"></app-folder-events-card>
        </article>
    </section>
    <!-- Quotation Section -->
    <section id="quotation"  *ngIf="canViewComponent()" (click)="updateSection('quotation')">
        <input type="radio" name="sections" id="quotationDetails" [disabled]="!hasQuotationOrReparationReport()">
        <label for="quotationDetails" class="text-center">
            <mat-icon  matBadgeColor="red" matBadgePosition="above after"
                      [ngClass]="hasQuotationOrReparationReport() ?  'mt-20 ' :  'mt-20 disabled-tab_section'"
                      [matBadge]="quotationBadgeValue()">assignment
            </mat-icon>
            <p [ngClass]="(folder.quotations.length === 0 && !folder.reparationReport)  ? 'h4 disabled-tab_section' : ' h4'"> {{'FOLDER.COMMENT.QUOTATION_CR.LABEL' | translate}} </p>
        </label>
        <article class="article-scroll" disabled="folder.quotations.length > 0 && activeSection=='quotation'">
            <app-folder-quotation-report-card></app-folder-quotation-report-card>
        </article>
    </section>
    <!-- Payment Section -->
    <section *ngIf="canViewComponent()" id="reportCR"  (click)="updateSection('payment')">
        <input type="radio" name="sections" id="report" [disabled]="folder.payments.length === 0">
        <label for="report" class="text-center">
            <mat-icon  [ngClass]="folder.payments.length > 0 ? 'mt-20' : 'mt-20 disabled-tab_section'"
                       matBadgeColor="red" matBadgePosition="above after"
                      [matBadge]="folder.payments.length > 0 ? folder.payments.length : ''">payment
            </mat-icon>
            <p [ngClass]=" folder.payments.length > 0 ? 'h4' : 'disabled-tab_section h4'"> {{'FOLDER.COMMENT.PAYMENT.LABEL' | translate}} </p>
        </label>
        <article class="article-scroll" disabled="folder.payments.length > 0 && activeSection==='payment'">
            <app-folder-payment-card></app-folder-payment-card>
        </article>
    </section>
    <!-- Colis Section -->
    <section id="shipment"  (click)="updateSection('shipment')">
        <input type="radio" name="sections" id="shipmentInput" [disabled]="folder.shipments.length === 0">
        <label for="shipmentInput" class="text-center end-tab">
            <mat-icon [ngClass]="folder.shipments.length > 0 ? 'mt-20' : 'mt-20 disabled-tab_section'"
                      matBadgeColor="red" matBadgePosition="above after"
                      [matBadge]="folder.shipments.length > 0 ? folder.shipments.length : ''">all_inbox
            </mat-icon>
            <p [ngClass]="folder.shipments.length > 0 ? 'h4' : 'disabled-tab_section h4'"> {{'FOLDER.SHIPMENT.TITLE' | translate}} </p>
        </label>
        <article class="article-scroll" disabled = "folder.shipments.length > 0">
            <app-folder-shipment-card [variablesTask]="variablesTask"></app-folder-shipment-card>
        </article>
    </section>
    <!-- Pièces Section -->
    <section id="cartItems" *ngIf="canViewComponent()" (click)="updateSection('cartItems')">
        <input type="radio" name="sections" id="cartItemsDetails" [disabled]="!hasFolderOrderParts">
        <label for="cartItemsDetails" class="text-center">
            <mat-icon [ngClass]="hasFolderOrderParts ? 'mt-20' : 'mt-20 disabled-tab_section'"
                      matBadgeColor="red" matBadgePosition="above after"
                      [matBadge]="!!orderCount? orderCount :''">
                assignment
            </mat-icon>
            <p [ngClass]="hasFolderOrderParts ? 'h4' : 'disabled-tab_section h4'"> {{'FOLDER.COMMENT.CART_ITEMS.LABEL' | translate}} </p>
        </label>
        <article class="article-scroll" disabled="activeSection==='cartItems' && hasFolderOrderParts">
            <app-folder-cart-items-card></app-folder-cart-items-card>
        </article>
    </section>
    <!-- Communications Section : a savoir avec Mohamed ==> il y a un traitement-->
    <section id="notifications" (click)="updateSection('notifications')">
        <input type="radio" name="sections" id="notificationsInput">
        <label for="notificationsInput" class="text-center end-tab">
            <mat-icon class="mt-20" matBadgeColor="red" matBadgePosition="above after"
                      [matBadge]="!!notificationCount? notificationCount :''">question_answer
            </mat-icon>
            <p class="h4"> {{'FOLDER.COMMUNICATIONS.TITLE' | translate}} </p>
        </label>
        <article class="article-scroll" *ngIf="activeSection==='notifications'">
            <app-folder-notifications-card [notificationCount]="notificationCount"></app-folder-notifications-card>
        </article>
    </section>
</div>