import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CampaginStripesValue} from '../../models/Campagin.model';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {GLOBAL} from '../../app-config';
import {map} from 'rxjs/operators';
import {CampaignLinkDTO} from '../../models/campaign.model';

@Injectable({
    providedIn: 'root'
})
export class CampaignService {
    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api`;
    defaultLanguage = 'en';

    constructor(public  httpClient: HttpClient) {
    }

    getConfigValueCampaign(folderId: string): Observable<CampaginStripesValue> {
        return this.httpClient.get<CampaginStripesValue>(`${this.path}/campaign/config/_search/${folderId}`);
    }

    public getWorkflowText(campaign: CampaginStripesValue, currentLanguage: string, workflowStatus: string): string {
        return !!campaign.text[currentLanguage] ? campaign.text[currentLanguage][workflowStatus]
            : campaign.text[this.defaultLanguage] ? campaign.text[this.defaultLanguage][workflowStatus] : '';
    }

    getOperatingsModeByBusinessLink(): Observable<any> {
        return this.httpClient.get(`${this.path}/v1/campaign/operating-mode`, {observe: 'response', responseType: 'text'});
    }

    sendProtocolNotification(formula: string, folderId: string, fileUploaded: any): Observable<any> {
        const headersHttp = new HttpHeaders();
        const formData = new FormData();

        formData.append('formula', formula);
        formData.append('template', fileUploaded);

        return this.httpClient.post<any>(`${this.path}/campaign/sendProtocolNotification/${folderId}`, formData, {
            observe: 'response',
            headers: headersHttp
        }).pipe(map((response) => {
            const data = response;
            return data;
        }));
    }

    getInfoCampaignLinks(): Observable<CampaignLinkDTO[]> {
        return this.httpClient.get<CampaignLinkDTO[]>(`${this.path}/campaign/infoCampaignLinks`);
    }
}
