import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {Observable} from 'rxjs';
import {legalForms} from '../../../store/organization/organization.selectors';

@Component({
    selector: 'app-professional-folder-form',
    templateUrl: './professional-folder-form.component.html',
    styleUrls: ['./professional-folder-form.component.scss']
})
export class ProfessionalFolderFormComponent implements OnInit {
    @Input() customerDetailForm: FormGroup;
    @Input() isReadOnly = false;

    legalFormSelectBoxOptions$: Observable<string[]>;

    constructor(private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.legalFormSelectBoxOptions$ = this.store$.pipe(select(legalForms));
    }


}
