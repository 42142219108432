<div class="page-layout blank p-24" fxLayout="column" fxLayoutAlign="start center" fusePerfectScrollbar>
    <div fxLayout="row" class="w-100-p mb-24" fxLayoutAlign="space-between center">

        <div>
            <h1 class="text-bold">{{'CODE_LABEL_CONFIG.CONFIG_CODES_TITLES.' + configCode | translate}}</h1>
            <h4 class="mt-0 secondary-text">{{'NAV.CONFIGURATIONS.HEADER' | translate}}</h4>
        </div>

        <button type="button" mat-stroked-button  (click)="onCreateNew()"
                class="aster-btn_border" *ngIf="!!codeLabelObjectsDataSource.filterForm">

            {{'CODE_LABEL_CONFIG.LIST.CREATE_BUTTON' | translate}}
        </button>
    </div>

    <div fxLayout="column" class="w-100-p overflow">
        <ng-container *ngIf="!!codeLabelObjectsDataSource.filterForm">
            <form [formGroup]="codeLabelObjectsDataSource.filterForm" (ngSubmit)="onSearch()">
                <table class="w-100-p" mat-table multiTemplateDataRows
                       [dataSource]="codeLabelObjectsDataSource.dataSource"
                       *ngIf="!!codeLabelObjectsDataSource.dataSource">
                    <ng-container matColumnDef="code">
                        <th class="header pt-16 px-16 max-w-150" mat-header-cell *matHeaderCellDef>
                            <div>
                                {{('CODE_LABEL_CONFIG.LIST.GRID_HEADER_CODE' | translate)}}
                            </div>
                            <mat-form-field class="filter w-100-p" floatLabel="never">
                                <input matInput (keyup.backspace)="resetSearch(codeLabelObjectsDataSource.filterForm.value.codeQuery)"
                                       [formControl]="codeLabelObjectsDataSource.filterForm.get(['codeQuery'])">
                                <mat-icon matSuffix class="disabled-text"
                                          *ngIf="codeLabelObjectsDataSource.filterForm.value.codeQuery.length === 0">
                                    search
                                </mat-icon>
                                <button type="button" mat-icon-button matSuffix class="disabled-text"
                                        *ngIf="codeLabelObjectsDataSource.filterForm.value.codeQuery.length > 0"
                                        color="primary" (click)="onClearSearchFormFiled('codeQuery')">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>
                        </th>
                        <td mat-cell class="px-16" *matCellDef="let el">{{el.code}}</td>
                    </ng-container>
                    <ng-container matColumnDef="label">
                        <th class="header pt-16 px-16 max-w-150" mat-header-cell *matHeaderCellDef>
                            <div>
                                {{('CODE_LABEL_CONFIG.LIST.GRID_HEADER_LABEL' | translate)}}
                            </div>
                            <mat-form-field class="filter w-100-p" floatLabel="never">
                                <input matInput (keyup.backspace)="resetSearch(codeLabelObjectsDataSource.filterForm.value.labelQuery)"
                                       [formControl]="codeLabelObjectsDataSource.filterForm.get(['labelQuery'])">
                                <mat-icon matSuffix class="disabled-text"
                                          *ngIf="codeLabelObjectsDataSource.filterForm.value.labelQuery.length === 0">
                                    search
                                </mat-icon>
                                <button type="button" mat-icon-button matSuffix class="disabled-text"
                                        *ngIf="codeLabelObjectsDataSource.filterForm.value.labelQuery.length > 0"
                                        color="primary" (click)="onClearSearchFormFiled('labelQuery')">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>
                        </th>
                        <td mat-cell class="px-16" *matCellDef="let el">{{el.label}}</td>
                    </ng-container>
                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="mt-16">
                                <button type="submit" mat-icon-button aria-label="Submit"
                                        [ngClass]="codeLabelObjectsDataSource.filterForm.value.codeQuery.length === 0 && codeLabelObjectsDataSource.filterForm.value.labelQuery.length === 0? 'aster_accent_disabled':'aster_accent'"
                                        [disabled]="codeLabelObjectsDataSource.filterForm.value.codeQuery.length === 0 && codeLabelObjectsDataSource.filterForm.value.labelQuery.length === 0">
                                    <mat-icon>arrow_forward</mat-icon>
                                </button>
                                <button type="button" mat-icon-button aria-label="reset "class="aster_accent"
                                        (click)="onResetSearchFilter()">
                                    <mat-icon>settings_backup_restore</mat-icon>
                                </button>

                            </div>
                        </th>
                        <td mat-cell class="py-4" *matCellDef="let el; let i = dataIndex;">
                            <div fxFlex="row" fxLayoutAlign="center" fxLayoutGap="10px">
                                <button type="button" mat-icon-button class="aster_accent" (click)="onEdit(el)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button type="button" mat-icon-button color="warn" (click)="onDelete(i,el)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>


                    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let el"
                            [attr.colspan]="codeLabelObjectsDataSource.columnsToDisplay.length">
                            <div class="example-element-detail"
                                 [@detailExpand]="!!codeLabelObjectsDataSource.expandedElement && codeLabelObjectsDataSource.expandedElement.id === el.id ? 'expanded' : 'collapsed'">

                                <app-item-code-label-extra-repairer-info class="w-100-p" [data]="el"
                                                                         *ngIf="configCode === configExtraCodes.REPAIRER else itemSite;"></app-item-code-label-extra-repairer-info>

                                <ng-template #itemSite>
                                    <app-item-site-info class="w-100-p" [data]="el"></app-item-site-info>
                                </ng-template>

                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="codeLabelObjectsDataSource.columnsToDisplay"></tr>
                    <tr mat-row
                        *matRowDef="let el; columns: codeLabelObjectsDataSource.columnsToDisplay"
                        class="example-element-row"
                        [class.example-expanded-row]="!!codeLabelObjectsDataSource.expandedElement && codeLabelObjectsDataSource.expandedElement.id === el.id"
                        (click)="codeLabelObjectsDataSource.expandedElement = !!codeLabelObjectsDataSource.expandedElement && codeLabelObjectsDataSource.expandedElement.id === el.id ? null : el"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                </table>
            </form>
        </ng-container>
    </div>
    <mat-paginator *ngIf="!!codeLabelObjectsDataSource.page" [length]="codeLabelObjectsDataSource.page.totalElements"
                   (page)="paginate($event)" showFirstLastButtons
                   [pageSize]="codeLabelObjectsDataSource.page.size"
                   [pageSizeOptions]="codeLabelObjectsDataSource.pageSizeOptions"
                   [pageIndex]="getPageIndex()" class="w-100-p"></mat-paginator>
</div>

