<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="text-bold">
        {{title | translate}}
    </h1>
    <button mat-icon-button class="ml-40" (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content style="min-width: 400px; max-width: 500px">
    <div [innerHTML]="message | translate"></div>
</mat-dialog-content>

<mat-dialog-content class="w-100-p">
    <form [formGroup]="inputForm" fxLayout="column" fxLayoutAlign="start">

        <div fxLayout="column" gdAlignColumns="20px" class="w-100-p">
            <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{inputTitle | translate}}</mat-label>
                <input matInput [placeholder]="inputPlaceholder | translate"
                       [required]="inputForm.get(['inputValue']) | hasRequiredField"
                       formControlName="inputValue">
            </mat-form-field>
        </div>


    </form>
</mat-dialog-content>


<mat-dialog-actions fxLayoutAlign="end end">
    <div fxLayout="row">
        <button mat-button (click)="onClose()" class="aster-modal-btn_cancel" type="reset">
            {{cancelButtonLabel | translate}}
        </button>
        <button mat-raised-button type="submit" [disabled]="inputForm.invalid" (click)="onSubmit()"
                [ngClass]="inputForm.invalid ? 'aster_btn_disabled' : 'aster_btn'">
            {{confirmButtonLabel | translate}}
        </button>
    </div>
</mat-dialog-actions>
