import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'reoptimize-confirm-dialog',
    templateUrl: './reoptimize-confirm-dialog.component.html',
    styleUrls: ['./reoptimize-confirm-dialog.component.scss']
})
export class ReOptimizeConfirmDialogComponent implements OnInit {
    minIteration = 256;
    maxIteration = 2024;

    reOptimizeForm = {
        form: null
    };

    constructor(public dialogRef: MatDialogRef<ReOptimizeConfirmDialogComponent>) {
    }


    ngOnInit(): void {
        this.initForm();

    }

    initForm(): void {
        this.reOptimizeForm.form = new FormGroup({
            iterationNumber: new FormControl(this.minIteration, Validators.compose([Validators.required,
                Validators.min(this.minIteration), Validators.max(this.maxIteration)])),
        });
    }

    onSubmit(): void {
        this.dialogRef.close(this.reOptimizeForm.form.value.iterationNumber);
    }
}
