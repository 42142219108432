import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-refund',
    templateUrl: './waiting-refund.component.html',
    styleUrls: ['./waiting-refund.component.css']
})
export class WaitingRefundComponent implements OnInit {
    refundDone: boolean;
    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    constructor() {
    }

    ngOnInit(): void {
    }

    onSubmit() {
        this.inputMap.emit({})
    }
}
