<mat-card class="mat-elevation-aster">
    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start center">
            <div>
                <span class="h2 ml-8  pt-4">
                    {{'COMPONENT.IMPACT_HEALTH_QUESTION.TITLE' | translate}}
                </span>
            </div>
        </div>
        <div class="mt-24">
            <form [formGroup]="healthImpactForm.form" fxLayout="column" fxLayoutAlign="start"
                  fxLayoutGap="30px"
                  (ngSubmit)="completeTask()">
                <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="30px" class="pt-24">
                    <mat-radio-group formControlName="healthImpact" class="ml-20" fxLayoutGap="10px"
                                     labelPosition="after" fxLayout="column"
                                     fxLayoutAlign="start stretch">
                        <mat-radio-button class="full-width-radio"
                                          *ngFor="let option of healthImpactForm.placeholders"
                                          [value]="option.healthImpact">
                            <div class="my-8" fxLayout="row" fxLayoutAlign="start center"
                                 fxLayoutGap="30px">
                                <div fxFlex>
                                    <div>{{ option.value | translate }}</div>
                                </div>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div fxLayout="row" fxLayoutAlign="end end">
                    <button mat-stroked-button
                            matTooltip="{{'BUTTON.VALIDATE' | translate}}" type="submit"
                            class="h-60 mt-4 pl-24 pr-24"
                            [disabled]="healthImpactForm.form.invalid"
                            [ngClass]="healthImpactForm.form.invalid ? 'aster_btn_disabled' : ' aster_btn'">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </form>
        </div>
    </mat-card-content>
</mat-card>
