<div *ngIf="surveyResponseId && mapSummarySurvey.size>0">
    <div>
        <h2>
            <mat-icon class="mt-20">info</mat-icon>
            {{'CODE_LABEL_CONFIG.CONFIG_CODES.DIAGNOSTIC' | translate}}
        </h2>
    </div>
    <div id="search-modern" class="page-layout simple fullwidth">
        <div class="content p-24">
            <div fxLayout="row" fxLayoutAlign="space-between end">

                <div>
                     <span *ngIf="!!validationDate" class="ml-4 ">
                                    {{validationDate |  moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate) : (currentLanguage$ | async)}}
                                </span>
                </div>
            </div>

            <div class="results">
                <!--mat-elevation-z4-->
                <div *ngFor="let survey of mapSummarySurvey | keyvalue:indexOrderAsc ;let i=index;"
                     class="result-item ">
                    <span class="title blue-800-fg">{{i + 1}}. {{survey.value.question.value}}</span>
                    <div *ngFor="let answer of survey.value.answers | keyvalue:indexOrderAsc">
                        <div class="description" *ngIf="survey.value.question.description"
                             [innerHTML]="survey.value.question.description"></div>
                        <ng-container *ngIf="answer.value">
                            <div class="description"
                                 [innerHTML]="answer.value.description !== ''? answer.value.description: answer.value.value"></div>
                            <div slot="start" *ngIf="isFilesFound(answer.value)">
                                <app-files-slider [filesId]="answer.value.displayedFiles"
                                                  [sliderWidth]="sliderWidth"></app-files-slider>
                            </div>
                            <mat-label
                                *ngIf="answer.value.value">
                                <div class="excerpt" [innerHTML]="answer.value.displayType.toLowerCase() ===
                                                'date' ? (answer.value.value |date:'dd/MM/yyyy') :
                               ''"></div>
                            </mat-label>
                            <!--<ng-container *ngIf="!!answer.value.additionInfos">
                                     <mat-list class="w-100-p ml-8" *ngFor="let addition of answer.value.additionInfos">
                                        <ng-container *ngIf="!!addition.value && addition.value.length>0">
                                         <div class="h4">{{('FORM.TITLE.'+addition.key) | translate}}</div>
                                         <div *ngFor="let element of addition.value;">
                                             <div fxLayout="row" fxLayoutAlign="space-between" class="w-100-p ml-8">
                                                 <div>
                                                     <ng-container *ngIf="!!element?.code">
                                                         <div mat-line class="h6 text-bold">
                                                             {{element.code}}:
                                                         </div>
                                                     </ng-container>
                                                     <div mat-line class="h6 text-italic url-zone cursor-pointer">
                                                         {{element.label}}
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                                                               </ng-container>
                                                                             </mat-list>
                            </ng-container>-->
                        </ng-container>
                    </div>

                </div>
            </div>
        </div>
    </div>


</div>
