
    <app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
    <mat-card-content class="mt-25">
        <form [formGroup]="diagExpertFrom" fxLayout="column" fxLayoutAlign="start">
            <mat-radio-group formControlName="expertDiagnosticDecision" class="ml-20" labelPosition="after"
                             fxLayout="column"
                             fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button [id]="status.choice" class="full-width-radio" *ngFor="let status of choices"
                                  (change)="diagnosticDecisionChanged($event)"
                                  [value]="status.choice">
                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                        <div fxFlex>
                            <div>{{ status.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>
            <ng-container
                *ngIf="diagExpertFrom.value.expertDiagnosticDecision === 'USAGE_PROBLEM' && isDiagExpertMethodSent">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch"
                     fxLayoutGap="12px">
                    <mat-form-field appearance="outline" fxFlex="50" [id]="'tracking_number'">
                        <mat-label>{{'FOLDER.TRACKING_NUMBER'|translate}}</mat-label>
                        <input matInput placeholder="{{'FOLDER.TRACKING_NUMBER'|translate}}"
                               formControlName="trackingNumber">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>{{'FOLDER.CARRIER_CODE'|translate}}</mat-label>
                        <mat-select formControlName="carrier" [id]="'carrier'">
                            <mat-option [id]="carrierItem.code" *ngFor="let carrierItem of carriers$ | async"
                                        [value]="carrierItem">
                                <span>{{  carrierItem.code + ' - ' + carrierItem.label }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
            <div fxLayout="column" fxLayoutAlign="space-between none">
                <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                    <mat-label>{{'FOLDER.COMMENT_FORM.LABEL' | translate}}</mat-label>
                    <textarea matInput rows="10" cols="7" [id]="'description'"
                              [placeholder]="'FOLDER.COMMENT_FORM.PLACEHOLDER' | translate"
                              formControlName="description"></textarea>
                </mat-form-field>
            </div>
            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        (click)="onSubmit()"
                        [ngClass]="diagExpertFrom.invalid?'aster_btn_disabled':'aster_btn'"
                        [disabled]="diagExpertFrom.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>