<mat-dialog-content class="w-100-p h-100-p">
    <div *ngIf="roomUrl" class="w-100-p h-100-p">
        <iframe [src]="roomUrl" class="w-100-p h-100-p"
                allow="camera; microphone; fullscreen; speaker; display-capture"></iframe>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="mt-24 mb-0">
    <div class="w-100-p">
        <div class="w-100-p" fxLayout="row" fxLayoutAlign="end end">
            <button mat-button (click)="onClose()" class="aster-modal-btn_cancel" type="reset">
                {{'BUTTON.CANCEL' | translate}}
            </button>
        </div>
    </div>
</mat-dialog-actions>
