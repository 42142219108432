    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon>library_books</mat-icon>
            <span class="h2 ml-8  pt-4">
                 <app-user-task-header [instructionUserTask]="instructionUserTask"
                                       [claim]="folder"></app-user-task-header>
            </span>
        </div>

        <div class="mt-24">
            <mat-card-subtitle>
                {{question$ | async}}
            </mat-card-subtitle>
            <mat-checkbox [(ngModel)]="isQuotationAccepted">{{'COMPONENT.ACCEPT_QUOTATION.TITLE' | translate}}</mat-checkbox>
        </div>

        <app-comment-area [commentForm]="commentControl"></app-comment-area>

        <div fxLayout="row" fxLayoutAlign="end">
            <button mat-stroked-button (click)="onSubmit()"
                    type="submit"
                    [disabled]="!isQuotationAccepted"
                    [ngClass]="!isQuotationAccepted?'h-60 mt-4  aster_btn_disabled':'h-60 mt-4 aster_btn'">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>
    </mat-card-content>