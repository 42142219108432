import {Component, Inject, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {WaitQuotationData} from './waitQuotationModal.model';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {Unsubscriber} from '../../../../../../../unsubscriber';
import {QuotationLine} from 'app/models/quotation.model';
import {Amount} from '../../../../../../../models/amount.model';
import {Vat} from 'app/models/vat.model';
import {TypeOfBenefitService} from '../../../../../../../shared/services/typeOfBenefit.service';
import {SparePartService} from '../../../../../../../shared/services/spare-part.service';
import {AppState} from '../../../../../../../store/app.state';
import {TypeOfServiceEnum} from '../../../../../../../models/typeOfService.model';
import {currency, vats} from '../../../../../../../store/organization/organization.selectors';
import {FuseConfirmDialogComponent} from '../../../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-waiting-for-quotation-modal',
    templateUrl: './waiting-for-quotation-modal.component.html',
    styleUrls: ['./waiting-for-quotation-modal.component.scss']
})
export class WaitingForQuotationModalComponent extends Unsubscriber implements OnInit {

    quotationForm: FormGroup = new FormGroup({});
    displayedColumns: string[] = ['type', 'code', 'label', 'price', 'quantity', 'discount', 'vat', 'totalPrice', 'action'];

    quotationLines = new MatTableDataSource<QuotationLine>();

    computeTotalPrice: Amount;
    discountQuotation = 0;
    typeOfBenefits = [];

    currency: string;
    vatsList$: Observable<Vat[]>;
    defaultVat;

    constructor(@Inject(MAT_DIALOG_DATA) public waitQuotationData: WaitQuotationData,
                private matDialog: MatDialog,
                private dialogRef: MatDialogRef<WaitingForQuotationModalComponent>,
                private translateService: TranslateService,
                private typeOfBenefitService: TypeOfBenefitService,
                private sparePartService: SparePartService,
                private store$: Store<AppState>
    ) {
        super();
    }

    ngOnInit(): void {
        this.initCurrency();
        this.initDefaultVat();
        this.initTypeOfBenefitService();
        this.initQuotationForm();
        this.quotationForm.get('type').setValue(TypeOfServiceEnum.SPARE_PART);

        if (!!this.waitQuotationData.initialQuotation) {
            this.loadInitialQuotation();
        }
    }

    private initCurrency() {
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => this.currency = currency);
    }

    private initDefaultVat() {
        this.vatsList$ = this.store$.pipe(select(vats));
        this.vatsList$.subscribe(vats => {
            this.defaultVat = vats.find(value => value.standard === true).rate;
        });
    }

    initQuotationForm(): void {
        this.quotationForm = new FormGroup({
            type: new FormControl(Validators.required),
            code: new FormControl(null, Validators.required),
            label: new FormControl(null, Validators.required),
            payer: new FormControl({value: 'DISTRIBUTOR', disabled: true}, null),
            quantity: new FormControl(1, [Validators.required, Validators.min(1)]),
            priceAmount: new FormGroup({
                value: new FormControl(0, [Validators.required, Validators.min(0)]),
            }),
            discount: new FormControl(0, [Validators.min(0), Validators.max(100)]),
            discountReason: new FormControl(null),
            totalLinePrice: new FormGroup({
                value: new FormControl(0),
            }),
        });
    }

    private initTypeOfBenefitService() {
        const sparePart = {
            code: TypeOfServiceEnum.SPARE_PART,
            label: this.translateService.instant('MODAL.QUOTATION.TYPE.SPARE_PART')
        };
        this.typeOfBenefits.push(sparePart);
        this.typeOfBenefitService.all().subscribe(types => {
            types.forEach(value => {
                this.typeOfBenefits.push(value);
            });
        });
    }

    loadInitialQuotation(): void {
        this.quotationLines.data = this.getInitialQuotationLines();
        this.discountQuotation = this.waitQuotationData.initialQuotation?.discount ? this.waitQuotationData.initialQuotation.discount : 0;
        this.computeTotalPrice = !!this.waitQuotationData.initialQuotation?.totalPrice ? this.waitQuotationData.initialQuotation.totalPrice : {
            value: 0,
            currency: this.currency
        };
    }

    private getInitialQuotationLines() {
        return this.waitQuotationData.initialQuotation.quotationLines?.length > 0 ? this.waitQuotationData.initialQuotation.quotationLines : [];
    }

    refreshDataForm(type: string): void {
        this.initQuotationForm();
        this.quotationForm.get('type').setValue(type);
    }

    checkDiscountAndCalculateTotalQuotationLine(event): void {
        if (event === '') {
            this.quotationForm.get(['discount']).setValue(0);
        } else {
            this.formatAndCalculateTotalQuotationLine();
        }
    }

    formatAndCalculateTotalQuotationLine(): void {
        this.quotationForm.get(['priceAmount', 'value']).setValue(this.sparePartService.formatPriceAccordingToCurrency(this.quotationForm.get(['priceAmount', 'value']).value, this.currency));
        this.quotationForm.get(['totalLinePrice', 'value']).setValue(
            this.sparePartService.formatPriceAccordingToCurrency(
                this.sparePartService.getTotalLinePrice(
                    this.sparePartService.normalizeFloat(this.quotationForm.get('priceAmount.value').value),
                    this.quotationForm.value.quantity, this.quotationForm.value.discount, this.defaultVat),
                this.currency));
    }

    normalize(value): number {
        return this.sparePartService.normalizeFloat(value);
    }

    addIntoQuotationLines(): void {
        const quotationLine = this.quotationForm.value;
        this.addVatToQuotationLine(quotationLine);
        this.addAndRefreshDataSourceLine(quotationLine);
    }

    private addVatToQuotationLine(quotationLine) {
        quotationLine.vat = {
            rate: this.defaultVat
        };
    }

    private addAndRefreshDataSourceLine(quotationLine) {
        this.quotationLines.data.unshift(quotationLine);
        this.refreshDataForm(TypeOfServiceEnum.SPARE_PART);
        this.refreshDataSourceTable();
    }

    refreshDataSourceTable(): void {
        this.quotationLines.data = [...this.quotationLines.data];
        const totalPrice = this.sparePartService.computeTotalPrice(this.quotationLines.data.map(quotationLine => quotationLine.totalLinePrice.value));
        this.computeTotalPrice = {
            value: totalPrice,
            currency: this.currency
        };
    }

    deleteLine(indexLine): void {
        const dialog = this.matDialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });
        dialog.componentInstance.title = this.translateService.instant('CONFIRMATION.MODAL.DELETE_DIALOG.TITLE');
        dialog.componentInstance.message = this.translateService.instant('FOLDER.FILE.DELETE_DIALOG.MESSAGE');
        dialog.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.DELETE');
        dialog.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialog.afterClosed().subscribe(isDelete => {
            if (isDelete) {
                this.quotationLines.data = this.quotationLines.data.filter((item, index) => index !== indexLine);
                this.refreshDataSourceTable();
            }
        });
    }

    getTotalPriceHT(): any {
        return !!this.quotationLines.data ? this.quotationLines.data.filter(quotationLine => quotationLine).reduce((total, line) =>
            total + this.sparePartService.normalizeFloat(line.priceAmount.value) * line.quantity, 0) : 0;
    }

    getTotalDiscount(): any {
        if (!!this.quotationLines.data) {
            const totalPriceHT = this.quotationLines.data.filter(quotationLine => quotationLine).reduce((total, line) =>
                total + this.sparePartService.normalizeFloat(line.priceAmount.value) * line.quantity, 0);
            return totalPriceHT - this.getTotalNetHT();
        }
        return 0;

    }

    getTotalNetHT(): any {
        if (!!this.quotationLines.data) {
            let totalNetHT;
            totalNetHT = this.quotationLines.data.filter(quotationLine => quotationLine).reduce((total, line) =>
                line.discount > 0 ? total + (this.sparePartService.normalizeFloat(line.priceAmount.value) * line.quantity -
                    ((this.sparePartService.normalizeFloat(line.priceAmount.value) * line.quantity) * (line.discount / 100))) : total
                    + (this.sparePartService.normalizeFloat(line.priceAmount.value) * line.quantity), 0);
            if (this.discountQuotation > 0) {
                return totalNetHT - totalNetHT * (this.discountQuotation / 100);
            }
            return totalNetHT;
        }
        return 0;
    }

    getTotalPriceTVA(): any {
        if (!!this.quotationLines.data) {
            return this.quotationLines.data.filter(quotationLine => quotationLine).reduce((total, line) => {
                const totalHT = this.sparePartService.normalizeFloat(line.priceAmount.value) * line.quantity;
                const discount = line.discount / 100;
                const vatRate = this.defaultVat;
                if (!!vatRate && vatRate > 0) {
                    const totalNetHT = totalHT - totalHT * discount;
                    return total + (totalNetHT + totalNetHT * (vatRate / 100)) - totalNetHT;
                }
                return total;
            }, 0);
        }
        return 0;
    }

    validateForm(): void {
        this.onCloseQuotationModal();
    }


    disabledForm(): boolean {
        return this.quotationLines.data.length <= 0 || this.computeTotalPrice?.value < 0;
    }

    private onCloseQuotationModal() {
        this.dialogRef.close({
            quotationLines: this.quotationLines.data,
            discount: this.discountQuotation,
            totalPrice: this.computeTotalPrice
        });

    }

    get getPriceAmountFromGroup(): FormGroup {
        return this.quotationForm.controls.priceAmount as FormGroup;
    }

    get getTotalLinePriceFromGroup(): FormGroup {
        return this.quotationForm.controls.totalLinePrice as FormGroup;
    }

    onCloseModal(): void {
        this.dialogRef.close();
    }
}
