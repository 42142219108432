import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-for-hs-product-destruction',
    templateUrl: './waiting-for-hs-product-destruction.component.html',
    styleUrls: ['./waiting-for-hs-product-destruction.component.scss']
})
export class WaitingForHsProductDestructionComponent {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() workflowStatus: any;
    @Input() processName: any;
    @Output() inputMap = new EventEmitter<any>();
    destructionDone = false;

    constructor() {
    }

    onSubmit(): void {
        this.inputMap.emit({});
    }

    stateChanged() {
        this.destructionDone = !this.destructionDone;
    }

}
