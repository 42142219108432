<div class="e-card">
    <div class="e-card-header">
        <div class="e-card-header-caption">
            <div class="e-card-title">{{'PRODUCT.DIALOG.INFOS.TITLE' | translate}}</div>
        </div>
    </div>
    <div class="e-card-content" fxLayout="column" fxLayoutGap="10px">
        <card-element title="PRODUCT.REFERENCE"
                      [value]="product.code">
        </card-element>

        <card-element title="PRODUCT.CODE_EAN"
                      [value]="product.codeEAN">
        </card-element>

        <card-element title="PRODUCT.MANUFACTURER_REFERENCE"
                      [value]="product.manufacturerReference">
        </card-element>

        <card-element title="PRODUCT.SELLING_PRICE"
                      [value]="product.sellingPrice ? (product.sellingPrice.value  | currency: product.sellingPrice.currency :'symbol-narrow') : '-'">
        </card-element>

        <card-element title="PRODUCT.BRAND"
                      [value]="product.brand | codeToLabel: 'SAV.CONFIG.BRANDS' | async">
        </card-element>

        <card-element title="PRODUCT.WEIGHT"
                      [value]="product.weight">
        </card-element>
    </div>
</div>