<form [formGroup]="contactForm" fxLayout="column"
      fxLayoutAlign="start" autocomplete="off" id="form1">
    <mat-form-field appearance="outline" fxFlex="100" floatLabel="always">
        <mat-label>{{'CODE_LABEL_CONFIG.FORM.FIELDS.LANDLINE_NUMBER.LABEL' | translate }}
        </mat-label>
        <input (input)="updatePhoneInput($event)"
               [required]="contactForm.get('phoneNumber') | hasRequiredField"
               matInput ng2TelInput
               numberOnly
               formControlName="phoneNumber" id="ng2TelInput_phoneNumber"
               [ng2TelInputOptions]="{ preferredCountries: [countryCode] , localizedCountries: { fr: 'France' }, utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'}"
               (countryChange)="onCountryChange($event)"
               placeholder="{{'CUSTOMER.PHONE_FIXE'|translate}}"/>
        <mat-icon *ngIf="isSearchable()" matSuffix class="disabled-text">search</mat-icon>
        <mat-error
            *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)">{{ "FROM.ERROR.MESSAGE.PHONE_NUMBER" |translate }}
        </mat-error>
    </mat-form-field>
</form>
