import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Quotation, QuotationLine} from '../../../../../models/quotation.model';
import {Folder, IFolderUpdateRequest} from '../../../../../models/folder.model';
import {QuotationType} from '../../../../../models/enums/quotationType.enum';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {FolderService} from '../../../../../shared/services/folder.service';
import {TaskVariables} from '../../task.variables';
import {RegimeWarrantyEnum} from '../../../../../models/warrantyRule.model';
import {allConfiguration} from '../../../../../store/configuration/configuration.selectors';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {MatDialog} from '@angular/material/dialog';
import {Unsubscriber} from '../../../../../unsubscriber';
import {ConfigurationReferential} from '../../../../../models/configurationReferential.model';
import {ConfirmationWithCommentComponent} from '../../../../../shared/generic/switch-to-swap/confirmation-with-comment.component';
import {OmsService} from '../../../../../shared/services/oms.service';
import {OrderSubjectService} from '../../../../../shared/services/order-subject.service';
import {Order, OrderItem} from '../../../../../models/order.model';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmDialogWithInputComponent} from '../../../../../../@fuse/components/confirm-dialog-with-input/confirm-dialog-with-input.component';
import {FolderSubjectService} from '../../../folder-subject.service';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {OrderStatus} from 'app/models/enums/orderStatus.enum';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';


@Component({
    selector: 'app-waiting-spare-parts-reception',
    templateUrl: './waiting-spare-parts-reception.component.html',
    styleUrls: ['./waiting-spare-parts-reception.component.scss']
})
export class WaitingSparePartsReceptionComponent extends Unsubscriber implements OnInit {
    orderedSpareParts: QuotationLine[] = [];
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();

    allSparePartReceived: boolean;
    isPartiallyReceived: boolean;
    private quotationId: string;
    private quotation: Quotation;
    isUnderWarranty: boolean;
    modeOpConfigList: ConfigurationReferential[] = [];
    order = new Order();
    maxDurationReceptionSparePart: string;

    constructor(private store$: Store<AppState>,
                private matDialog: MatDialog,
                private translateService: TranslateService,
                private omsService: OmsService,
                private orderSubjectService: OrderSubjectService,
                private folderService: FolderService,
                private folderSubjectService: FolderSubjectService,
                private snackBar: SnackBarService) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.MOD_OP}))
            .subscribe(it => this.modeOpConfigList = it);
        if (!!this.folder.orderIds && this.folder.orderIds?.length > 0) {
            this.initOrder();
        } else {
            // TODO will be deprecated
            this.quotationId = this.variablesTask[TaskVariables.quotationId];
            this.quotation = this.folder.quotations.find(quotation => quotation.id === this.quotationId);
            this.orderedSpareParts = this.quotation.quotationLines.filter(e => e.type === QuotationType.SPARE_PART);
            this.allSparePartReceived = this.orderedSpareParts.every(x => x.sparePartReceived === true);
            this.isPartiallyReceived = !this.allSparePartReceived && this.someReceived();
        }

        this.isUnderWarranty = this.folder.newWarranty.warranty === RegimeWarrantyEnum.SG;
        this.maxDurationReceptionSparePart = this.variablesTask['maxDurationReceptionSparePart'];
    }

    private someReceived() {
        return this.orderedSpareParts.some(x => x.sparePartReceived === true);
    }

    initOrder() {
        this.anotherSubscription = this.orderSubjectService.order$.subscribe(order => {
            this.order = order;
            this.buildDataLines();
            this.allSparePartReceived = this.orderedSpareParts.every(x => x.sparePartReceived === true);
        });


    }

    buildDataLines() {
        this.orderedSpareParts = [];
        this.order.orderItems.forEach(orderItem => {
            const dataSourceLine = new QuotationLine();
            dataSourceLine.orderItemId = orderItem.id;
            dataSourceLine.code = orderItem.code;
            dataSourceLine.label = orderItem.label;
            dataSourceLine.quantity = orderItem.quantityOrdered;
            dataSourceLine.priceAmount = {
                value: orderItem.price,
                currency: orderItem.currency
            };
            dataSourceLine.stock = orderItem.stock;
            dataSourceLine.type = QuotationType.SPARE_PART;
            dataSourceLine.supplierName = orderItem.supplier?.name;
            dataSourceLine.status = orderItem.itemStatus;
            dataSourceLine.sparePartReceived = this.isSparePartReceived(orderItem),
                dataSourceLine.totalLinePrice = {
                    value: orderItem.rowTotal,
                    currency: orderItem.currency
                };
            dataSourceLine.vat = {
                rate: orderItem.taxPercent?.toString()
            };
            dataSourceLine.discount = orderItem.discountPercent;
            this.orderedSpareParts.push(dataSourceLine);
        });
    }

    isSparePartReceived(orderItem: OrderItem) {
        return orderItem.itemStatus === OrderStatus.DELIVERED || orderItem.itemStatus === OrderStatus.PICKING;
    }

    onSubmit(): void {
        this.inputMap.emit({
            'userChoise': 'sparePartsReceived'
        });
    }

    updateSparePartReceived(event: MatCheckboxChange) {
        this.orderedSpareParts.map(e => e.sparePartReceived = event.checked);
        this.updateStatus(this.orderedSpareParts);
    }

    enableSubmitButton(): boolean {
        return this.allSparePartReceived || this.orderedSpareParts.every(x => x.sparePartReceived === true);
    }

    updateStatus(updatedQuotationLines: QuotationLine[]): void {
        this.store$.dispatch(new StartLoading());
        this.orderedSpareParts = updatedQuotationLines;
        this.allSparePartReceived = this.orderedSpareParts.every(x => x.sparePartReceived === true);
        if (!!this.folder.orderIds && this.folder.orderIds?.length > 0) {
            this.updateOrderStatus();
        } else {
            // TODO will be deprecated
            this.isPartiallyReceived = !this.allSparePartReceived && this.someReceived();
            this.quotation.quotationLines = updatedQuotationLines;
            this.folderService.updateQuotationToFolder(this.folder.id, new Array<any>(), this.quotation).subscribe(value => {
                this.store$.dispatch(new StopLoading());
            });
        }
    }

    openConfirmationModal(): void {
        const dialogRef = this.matDialog.open(ConfirmationWithCommentComponent, {
            height: 'auto',
            width: '100vh',
            minWidth: '800px'
        });
        dialogRef.componentInstance.id = 'CASE_SG_CP';
        dialogRef.componentInstance.reasonTitle = 'SWITCH.OPERATING.MODE.REASON.CHANGE';
        dialogRef.componentInstance.title = this.translateService.instant('FOLDER.SWITCH_SWAP.TITLE');
        dialogRef.componentInstance.description = this.translateService.instant('FOLDER.SWITCH_SWAP.DESCRIPTION');
        dialogRef.componentInstance.commentMessage = this.translateService.instant('FOLDER.COMMENT_FORM.SWITCH_SWAP.LABEL');
        dialogRef.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.VALIDATE');
        dialogRef.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialogRef.componentInstance.withCommentReason = true;
        dialogRef.afterClosed().subscribe(data => {
                if (!!data) {
                    if (!!data.reason || !!data.comment) {
                        this.commentGiven.emit({
                            value: this.handleCommentContent(data.comment, data.reason),
                            type: 'SWITCH_SWAP_REASON_WAITING_SPARE_PARTS_RECEPTION',
                        });
                    }
                    this.inputMap.emit({
                        'userChoise': 'switchSwap'
                    });
                }
            }
        );
    }

    handleCommentContent(comment: String, reason: String): String {
        const reasonContent = this.translateService.instant('SPARE_PART.SWAP_OR_CLOSE.' + reason);
        return !!comment ?  reasonContent + ' ' + comment : reasonContent ;
    }

    closeFolder() {
        const dialogRef = this.matDialog.open(ConfirmationWithCommentComponent, {
            height: 'auto', width: '300px', minWidth: '50%'
        });
        dialogRef.componentInstance.id = 'CASE_HG_CP';
        dialogRef.componentInstance.withCommentReason = true;
        dialogRef.componentInstance.reasonTitle = 'FOLDER.CLOSE_FORM.STATUS';
        dialogRef.componentInstance.title = this.translateService.instant('FOLDER.CLOSE_FORM.TITLE');
        dialogRef.componentInstance.description = this.translateService.instant('FOLDER.CLOSE_FORM.MESSAGE');
        dialogRef.componentInstance.commentMessage = this.translateService.instant('FOLDER.COMMENT_FORM.FOLDER_CLOSED.LABEL');
        dialogRef.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.VALIDATE');
        dialogRef.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialogRef.afterClosed().subscribe(data => {
                if (!!data) {
                    if (!!data.comment) {
                        this.commentGiven.emit({
                            value: this.handleCommentContent(data.comment, data.reason),
                            type: 'CLOSE_FOLDER_REASON_WAITING_SPARE_PARTS_RECEPTION',
                        });
                    }
                    this.inputMap.emit({
                        'userChoise': 'closeFolder'
                    });
                }
            }
        );
    }

    getTaskDuration(): string {
        return this.variablesTask['maxDurationReceptionSparePart'];
    }

    getStatusDate(): string {
        return this.folder.currentWorkflowStatus.workflowStatusDate;
    }

    updateOrderStatus() {
        if (!!this.order && !!this.order.id) {
            const orderUpdateRequest = this.prepareOrder(OrderStatus.DELIVERED);
            this.omsService.updateOrder(this.order.id, orderUpdateRequest).subscribe((order) => {
                this.order = order;
                this.orderSubjectService.orderLoaded(this.order);
                this.store$.dispatch(new StopLoading());
            }, () => this.store$.dispatch(new StopLoading()));
        }
    }

    private prepareOrder(targetStatus: OrderStatus) {
        return {
            orderItemsStatuses: this.toItems(targetStatus)
        };
    }

    private toItems(targetStatus: OrderStatus): OrderItem[] {
        return this.orderedSpareParts.map((sparePart) => {
            const status = this.computeStatus(sparePart, targetStatus);
            return {
                id: sparePart.orderItemId,
                itemStatus: status,
                quantityDelivered: this.getQuantityDelivered(status, sparePart)
            };
        });
    }

    private getQuantityDelivered(status: OrderStatus, sparePart: QuotationLine) {
        return status === OrderStatus.DELIVERED ? sparePart.quantity : null;
    }

    private computeStatus(sparePart: QuotationLine, targetStatus: OrderStatus): OrderStatus {
        return this.shouldForceToOrdered(targetStatus, sparePart) ? OrderStatus.ORDERED : this.markAsDelivered(sparePart);

    }

    private shouldForceToOrdered(targetStatus: OrderStatus, sparePart: QuotationLine) {
        return (targetStatus === OrderStatus.ORDERED && sparePart.status === OrderStatus.EXCEPTION) || !sparePart.sparePartReceived;
    }

    private markAsDelivered(sparePart: QuotationLine) {
        return (this.allSparePartReceived && sparePart.status !== OrderStatus.PICKING)
            ? OrderStatus.DELIVERED
            : sparePart.status;
    }

    isOrderExceptionPresent() {
        return this.order.orderStatus === OrderStatus.EXCEPTION || this.isOrderItemExceptionPresent();

    }

    disableSparePartReceived() {
        return this.order.orderStatus !== OrderStatus.ORDERED;
    }

    isOrderItemExceptionPresent() {
        return this.order.orderItems?.some(item => item.itemStatus === OrderStatus.EXCEPTION);
    }
    displayOrderStatus(status: number | string) {
        if (status === OrderStatus.ORDERED || status === OrderStatus.PARTIALLY_ORDERED || status === OrderStatus.CONFIRMED || status === OrderStatus.NOT_CONFIRMED ||
            (!this.allSparePartReceived && !this.isPartiallyReceived)) {
            return 'ORDER.TEXT.ORDERED';
        }
        if (status === OrderStatus.EXCEPTION || status === OrderStatus.PARTIALLY_EXCEPTION || this.isOrderItemExceptionPresent()) {
            return 'ORDER.TEXT.EXCEPTION';
        }
        if (status === OrderStatus.PARTIALLY_DELIVERED ||  (this.isPartiallyReceived && !this.allSparePartReceived ) ) {
            return 'ORDER.TEXT.PARTIALLY.DELIVERED';
        }
        if (status === OrderStatus.DELIVERED ||  (!this.isPartiallyReceived && this.allSparePartReceived ) ) {
            return 'ORDER.TEXT.DELIVERED';
        }
        if (status === OrderStatus.PICKING) {
            return 'ORDER.TEXT.PICKING';
        }
    }


    confirmUpdateStatus(): void {
        const dialog = this.matDialog.open(ConfirmDialogWithInputComponent, {
            hasBackdrop: true,
            disableClose: false,
            data: {inputValueRequired: true}
        });
        dialog.componentInstance.title = this.translateService.instant('COMPONENT.WAITING_SPARE_CONFIRMATION.MODAL.UPDATE.STATUS_DIALOG.TITLE');
        dialog.componentInstance.message = this.translateService.instant('COMPONENT.WAITING_SPARE_CONFIRMATION.MODAL.UPDATE.STATUS_DIALOG.MESSAGE');
        dialog.componentInstance.inputTitle = this.translateService.instant('COMPONENT.WAITING_SPARE_CONFIRMATION.MODAL.UPDATE.STATUS_DIALOG.INPUT.TITLE');
        dialog.componentInstance.inputPlaceholder = this.translateService.instant('COMPONENT.WAITING_SPARE_CONFIRMATION.MODAL.UPDATE.STATUS_DIALOG.INPUT.PLACEHOLDER');
        dialog.componentInstance.confirmButtonLabel = this.translateService.instant('COMPONENT.WAITING_SPARE_CONFIRMATION.MODAL.BUTTON.UPDATE');
        dialog.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialog.afterClosed().subscribe(data => {
            if (!!data) {
                this.addExternalOrderReference(data.inputValue);
            }
        });

    }

    prepareUpdateFolderRequest(reference: string): IFolderUpdateRequest {
        const references = new Map<string, string>();
        references['SPARE_PARTS_ORDER_REFERENCE'] = reference;
        return {
            externalReferences: references
        };

    }

    addExternalOrderReference(reference: string) {
        this.folderService.updateFolder(this.folder.id, this.prepareUpdateFolderRequest(reference))
            .then(data => {
                this.folderSubjectService.folderLoaded(data);
                this.manuallyUpdateOrderStatus();
            }).catch(() => {
            this.store$.dispatch(new StopLoading());
        });
    }

    manuallyUpdateOrderStatus() {
        if (!!this.order?.id) {
            const orderUpdateRequest = this.prepareOrder(OrderStatus.ORDERED);
            this.omsService.updateOrder(this.order.id, orderUpdateRequest).subscribe((order) => {
                this.order = order;
                this.orderSubjectService.orderLoaded(this.order);
                this.snackBar.openWithIcon('ORDER.SUCCESS_UPDATE', 'Success');
                this.store$.dispatch(new StopLoading());
            }, () => {
                this.snackBar.openWithIcon('ORDER.ERROR_UPDATE', 'Error');
                this.store$.dispatch(new StopLoading());
            });

        }
    }
}

