import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BackOfficeService} from '../../../../../../shared/services/back-office.service';
import * as moment from 'moment';
import {Folder} from '../../../../../../models/folder.model';
import {AddressType} from '../../../../../../models/enums/addressType.enum';
import {afternoon, CalendarRdvService, emptyCalendarDayElement, fullCalendarDayElement, morning} from '../../../../../../shared/generic/calendar-rdv/calendar-rdv.service';
import * as _ from 'lodash';
import {CalendarDayElement} from '../../../../../../shared/generic/calendar-rdv/calendarRdv.model';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {DayFilterEnum} from '../../../../../../shared/generic/calendar-rdv/dayFilterEnum';
import {InterventionReservationRequest, InterventionSuggestionsResponse, InterventionSuggestRequest} from '../../../../../../shared/generic/calendar-rdv/appointment.models';
import {Appointment} from '../../../../../../models/appointment.model';
import {Observable} from 'rxjs';
import {Context} from '../../../../../../models/enums/context.enum';
import {finalize, mergeMap, take} from 'rxjs/operators';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {organizationCode} from '../../../../../../store/user/user.selectors';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {FormControl} from '@angular/forms';
import {SnackBarService} from '../../../../../../shared/services/snack-bar.service';
import {currentLanguage} from '../../../../../../store/organization/organization.selectors';

@Component({
    selector: 'app-not-planed-intervention',
    templateUrl: './not-planed-intervention.component.html',
    styleUrls: ['./not-planed-intervention.component.css'],
    providers: [CalendarRdvService]
})
export class NotPlanedInterventionComponent extends Unsubscriber implements OnInit {
    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();

    private organizationCode: string;
    private MAX_MONTH_NUMBER_TO_SEARCH_SUGGESTION = 2;
    private MAX_SUGGESTION_COUNT = 8;
    selectedAppointment = [];
    folder: Folder;
    commentControl = new FormControl('');
    wishAppointmentDay = [];
    fullResponse: { date: moment.Moment; isMorning: boolean; selected?: boolean }[];
    loadFinished: boolean;
    currentLanguage$;
    numberOfMinSuggestionToNavigate = 7;
    historySuggestionDate: moment.Moment [] = [];

    constructor(private backOfficeService: BackOfficeService,
                private calendarRdvService: CalendarRdvService,
                private snackBar: SnackBarService,
                private store$: Store<AppState>,
                private folderSubjectService: FolderSubjectService) {
        super();
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.anotherSubscription = this.store$.pipe(
            select(organizationCode),
            mergeMap(organization => {
                this.organizationCode = organization;
                return this.folderSubjectService.folder$;
            })
            , take(1)).subscribe(folder => {
            this.folder = folder;
            this.computeSuggest();
        });

    }

    private computeSuggest(): void {
        this.loadCalendarSuggest(moment().add(1, 'days'));
    }

    private loadCalendarSuggest(dateFrom: moment.Moment): void {
        if (this.folder.context !== Context.REPARATION && !this.folder.repairCenter.publicAgenda) {
            this.calendarRdvService.calenderComputed(dateFrom, fullCalendarDayElement);
        } else {
            this.fullResponse = [];
            this.loadFinished = false;
            this.buildCalendar(dateFrom);
        }
    }

    private buildCalendar(dateFrom: moment.Moment) {
        this.store$.dispatch(new StartLoading());
        this.loadSuggest(dateFrom, DayFilterEnum.MORNING, 0).subscribe(responseMorning => {
            this.store$.dispatch(new StartLoading());
            this.computeSuggestionResponse(responseMorning, true);
            this.loadSuggest(dateFrom, DayFilterEnum.AFTERNOON, 0).subscribe(responseAfternoon => {
                this.computeSuggestionResponse(responseAfternoon, false);
                this.sortSuggestionResponse();
                if (this.canLoadSuggestionForNextMonth(dateFrom)) {
                    this.buildCalendar(moment(dateFrom).add('month', 1));
                } else {
                    this.store$.dispatch(new StopLoading());
                    this.loadFinished = true;
                    if (this.haveSuggest()) {
                        this.fullResponse = this.fullResponse.splice(0, this.MAX_SUGGESTION_COUNT);
                        this.historySuggestionDate.push(this.fullResponse[0].date);
                        this.selectAppointmentIfChosen();
                    }
                }
            }, () => this.store$.dispatch(new StopLoading()));

        }, () => this.store$.dispatch(new StopLoading()));
    }

    private canLoadSuggestionForNextMonth(dateFrom: moment.Moment) {
        return (!!this.fullResponse  && this.fullResponse.length < this.MAX_SUGGESTION_COUNT)
            && (moment(dateFrom).add('month', 1).isBefore(moment().add('month', this.MAX_MONTH_NUMBER_TO_SEARCH_SUGGESTION)));
    }

    private selectAppointmentIfChosen() {
        if (this.selectedAppointment.length > 0) {
            this.fullResponse.filter(e => e.date.isSame(this.selectedAppointment[0].date) && e.isMorning === (this.selectedAppointment[0].elementSelected === morning))
                .map(e => e.selected = true);
        }
    }

    private computeSuggestionResponse(suggestionResponse: InterventionSuggestionsResponse, isMorning: boolean) {
        this.fullResponse = this.fullResponse
            .concat(suggestionResponse.interventionSuggestions
                .filter(e => moment(e.interventionDate).isAfter(moment()))
                .map(e => {
                    return {'date': moment(e.interventionDate), 'isMorning': isMorning};
                }));
    }

    private sortSuggestionResponse() {
        this.fullResponse.sort((x, y) => {
            return (x.date.isBefore(y.date)) ? -1 : 1 ;
        });
    }

    private loadSuggest(dateFrom: moment.Moment, dayFilter: DayFilterEnum, maxDayNumberToSearchSuggestion: number): Observable<InterventionSuggestionsResponse> {
        const interventionSuggestRequest = this.prepareSuggestRequest(dateFrom, dayFilter, maxDayNumberToSearchSuggestion);
        if (this.folder.context !== Context.REPARATION) {
            return this.backOfficeService.appointmentSuggest(this.folder.repairCenter.asterOrganizationCode,
                interventionSuggestRequest);
        } else {
            return this.backOfficeService.appointmentSuggest(this.organizationCode, interventionSuggestRequest);
        }
    }

    private prepareSuggestRequest(dateFrom: moment.Moment, dayFilter: DayFilterEnum, maxDayNumberToSearchSuggestion: number): InterventionSuggestRequest {
        return {
            customer: {
                firstName: this.folder.customer.firstName,
                lastName: this.folder.customer.lastName,
                email: this.folder.customer.contact.email,
                phone: this.folder.customer.contact.mobileNumber,
                interventionAddress: this.folder.customer.addresses.filter(address => address.type === AddressType.INTERVENTION)[0]
            },
            maxDayNumberToSearchSuggestion: maxDayNumberToSearchSuggestion,
            interventionDate: moment(dateFrom).format('YYYY-MM-DD'),
            dayFilterEnum: dayFilter,
            serviceCode: 'DIAGNOSTIC'
        };
    }

    getResultElementSelected($event: any): void {
        this.selectedAppointment = $event;
    }

    validateAppointment(): void {
        this.store$.dispatch(new StartLoading());
        const interventionReservationRequest = this.prepareReservationRequest();
        const organizationCode = this.folder.context !== Context.REPARATION ? this.folder.repairCenter.asterOrganizationCode : this.organizationCode;
        const appointment = this.prepareAppointmentReq();
        this.store$.dispatch(new StartLoading());
        if (!!this.selectedAppointment[0]) {
            const dayFilterEnum = this.selectedAppointment[0].elementSelected === morning ? DayFilterEnum.MORNING : DayFilterEnum.AFTERNOON;
            this.loadSuggest(moment(this.selectedAppointment[0].date), dayFilterEnum, 1).subscribe(response => {
                if (this.selectedWishAppointmentIsTaken(response)) {
                    this.store$.dispatch(new StopLoading());
                    this.notifyUserAndRefreshPage();
                } else {
                    this.ConfirmAvailabilityAndSaveAppointment(organizationCode, interventionReservationRequest, appointment);
                }
            });

        }
    }

    private selectedWishAppointmentIsTaken(response: InterventionSuggestionsResponse): boolean {
        return response.interventionSuggestions.length === 0
            || !moment(response.interventionSuggestions[0].interventionDate).isSame(moment(this.selectedAppointment[0].date));
    }

    private ConfirmAvailabilityAndSaveAppointment(organizationCode: string, interventionReservationRequest: InterventionReservationRequest, appointment: Appointment): void {
        this.backOfficeService.saveAppointment(organizationCode, interventionReservationRequest)
            .pipe(finalize(() => this.store$.dispatch(new StopLoading())))
            .subscribe(interventionSuggestion => {
                if (this.commentControl.value.length > 0) {
                    this.commentGiven.emit({value: this.commentControl.value});
                }
                appointment.appointmentId = interventionSuggestion[0];
                this.inputMap.emit({
                    'confirmedAppointment': JSON.stringify(appointment),
                    'wishedDatesWrapper': JSON.stringify({'wishedDates': [appointment]}),
                });
            });
    }

    private notifyUserAndRefreshPage(): void {
        this.snackBar.openWithIcon('APPOINTMENT.DATE_ALREADY_TAKEN.AUTO_REFRESH', 'Error');
        this.loadCalendarSuggest(moment(this.selectedAppointment[0].date));
        this.wishAppointmentDay = [];
    }

    private prepareAppointmentReq(): Appointment {
        return {
            day: moment(this.selectedAppointment[0].date).format('YYYY-MM-DD'),
            beginHour: this.selectedAppointment[0].elementSelected.beginHour.replace('h', ':').concat(':00'),
            endHour: this.selectedAppointment[0].elementSelected.endHour.replace('h', ':').concat(':00')
        };
    }

    private prepareReservationRequest(): InterventionReservationRequest {
        return {
            customer: {
                firstName: this.folder.customer.firstName,
                lastName: this.folder.customer.lastName,
                email: this.folder.customer.contact.email,
                phone: this.folder.customer.contact.mobileNumber,
                interventionAddress: this.folder.customer.addresses.filter(address => address.type === AddressType.INTERVENTION)[0]
            },
            interventionDate: moment(this.selectedAppointment[0].date).format('YYYY-MM-DD'),
            dayFilterEnum: this.selectedAppointment[0].elementSelected === morning ? DayFilterEnum.MORNING : DayFilterEnum.AFTERNOON,
            serviceCode: 'DIAGNOSTIC',
            externalId: this.folder.context === Context.REPARATION ? this.folder.incrementalReference : this.folder.externalReferences['REPAIR_CENTER_REFERENCE']
        };
    }

    selectDate(item): void {
        if (this.selectedAppointment.length > 0) {
            this.fullResponse.filter(e => e.date.isSame(this.selectedAppointment[0].date) && e.isMorning
                === (this.selectedAppointment[0].elementSelected === morning))[0].selected = false;
            this.selectedAppointment = [];
        }
        this.selectedAppointment.push({
            date: item.date,
            elementSelected: item.isMorning ? morning : afternoon
        });
        item.selected = true;
    }

    loadNextSuggest(): void {
        const lastDate = this.fullResponse[this.fullResponse.length - 1].date;
        this.loadCalendarSuggest(moment(lastDate).add(1, 'days'));
    }

    loadPreviousSuggest(): void {
        let dateFrom;
        const previousSuggestIndex = this.haveSuggest() ? 2 : 1;
        dateFrom = this.historySuggestionDate[this.historySuggestionDate.length - previousSuggestIndex];
        this.historySuggestionDate.splice(-previousSuggestIndex);

        this.loadCalendarSuggest(dateFrom);
    }

    haveSuggest(): boolean {
        return !!this.fullResponse && this.fullResponse.length > 0;
    }

    isPreviousDisabled(): boolean {
        return this.historySuggestionDate.length <= 1;
    }

    isNextDisabled(): boolean {
        return !!this.fullResponse && this.fullResponse.length <= this.numberOfMinSuggestionToNavigate;
    }

    removeCheck(date): void {
        this.selectedAppointment = [];
        this.fullResponse.filter(e => e.date.isSame(date)).forEach(e => e.selected = false);
    }

    getInterventionPropositionDay(item): any {
        return moment(item.date, 'DD/MM/YYYY').toISOString();
    }

    getInterventionPropositionTiming(item): string {
        return item.isMorning ? morning.beginHour + ' - ' + morning.endHour : afternoon.beginHour + ' - ' + afternoon.endHour;
    }
}
