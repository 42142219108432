import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';

import {FuseNavigationModule, FuseShortcutsModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';

import {ToolbarComponent} from 'app/layout/components/toolbar/toolbar.component';
import {TranslateModule} from '@ngx-translate/core';
import {SearchBarModule} from '../search-bar/search-bar.module';
import {GLOBAL} from 'app/app-config';
import {OrganizationsMenuModule} from '@sav-components/organizations-menu';

@NgModule({
    declarations: [
        ToolbarComponent
    ], imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        FuseSharedModule,
        SearchBarModule,
        FuseShortcutsModule,
        TranslateModule,
        FuseNavigationModule,
        OrganizationsMenuModule.forRoot({apiUrl: `${GLOBAL.gatewayEndpoint}/back-office-service/api/organization/v1`}),
    ],
    exports: [
        ToolbarComponent
    ]
})
export class ToolbarModule {
}
