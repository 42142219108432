import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {countryCode} from '../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../unsubscriber';
import {AppState} from '../../../../store/app.state';

@Component({
    selector: 'app-search-phone-countries',
    templateUrl: './search-phone-countries.component.html',
    styleUrls: ['./search-phone-countries.component.scss']
})
export class SearchPhoneCountriesComponent extends Unsubscriber implements OnInit, OnChanges {
    // Two-way data binding with parent component
    @Input() value: string;
    @Output() valueChange: EventEmitter<string>;

    numberFormControl: FormControl;

    countryCode = 'fr';

    constructor(private store$: Store<AppState>) {
        super();
        this.valueChange = new EventEmitter<string>();
        this.numberFormControl = new FormControl();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.value.currentValue) {
            this.numberFormControl.reset();
        }
    }


    ngOnInit(): void {
        this.numberFormControl.setValue(this.value);
    }

    hasError($event: boolean) {
        const hasError = !$event;
        const empty = this.numberFormControl.value === undefined || this.numberFormControl.value === null || this.numberFormControl.value === '';
        const invalid = hasError && !empty;

        if (empty) {
            this.valueChange.emit(null);
        } else if (invalid) {
            this.numberFormControl.setErrors({'invalid': true});
            this.valueChange.emit(null);
        } else {
            this.numberFormControl.setErrors(null);
        }
    }

    getNumber(number: string) {
        this.numberFormControl.setValue(number);
        this.value = number;
        this.valueChange.emit(number);
    }

    onCountryChange($event: any) {
        this.numberFormControl.reset();
    }


    telInputObject(obj) {
        this.anotherSubscription = this.store$.pipe(select(countryCode))
            .subscribe((countryCode: string) => {
                this.countryCode = countryCode.toLowerCase();
                obj.setCountry(this.countryCode);
            });
    }
}
