import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {CustomerApiInfo} from '../../../models/customer/customer-api-info.model';

@Component({
    selector: 'searchable-field',
    templateUrl: './searchable-field.component.html'
})
export class SearchableFieldComponent implements OnInit {

    @Input() field: any;
    @Input() customerApiInfo: CustomerApiInfo;
    @Output() onBlur = new EventEmitter();
    @Output() onEnterKey = new EventEmitter();

    formGroup: FormGroup;

    constructor(private rootFormGroup: FormGroupDirective) {
    }

    ngOnInit(): void {
        this.formGroup = this.rootFormGroup.control;
    }

    isSearchable() {
        return this.customerApiInfo?.searchableFields?.find(field => field.includes(this.field.name));
    }

    getIcon() {
        return this.isSearchable() ? 'search' : this.field.icon;
    }

    doOnBlur() {
        if (this.customerApiInfo?.automaticFields?.includes(this.field.name) && this.hasValue()) {
            this.onBlur.emit();
        }
    }

    doOnEnterKey() {
        if (this.isSearchable() && this.hasValue()) {
            this.onEnterKey.emit();
        }
    }

    hasValue = () => this.formGroup.get(this.field.name).value;
}
