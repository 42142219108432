import {Component, Input, OnInit} from '@angular/core';
import {Folder} from '../../../models/folder.model';
import {WorkflowService} from '../../../shared/services/workflow.service';
import {FolderSubjectService} from '../folder-subject.service';
import {Context} from '../../../models/enums/context.enum';
import {NotificationService} from '../../../shared/services/notification.service';
import {FolderService} from '../../../shared/services/folder.service';
import {ServiceType} from '../../../models/enums/serviceType.enum';
import {IEvent} from '../../../models/events.model';
import {Unsubscriber} from '../../../unsubscriber';
import {OrderSubjectService} from '../../../shared/services/order-subject.service';

@Component({
    selector: 'app-folder-detail-tabs-card',
    templateUrl: './folder-detail-tabs-card.component.html',
    styleUrls: ['./folder-detail-tabs-card.component.scss']
})
export class FolderDetailTabsCardComponent extends Unsubscriber implements OnInit {
    @Input() permission;
    @Input() variablesTask: any;
    folder: Folder;
    eventsNumber = 0;
    workflowStatusNumber = 0;
    notificationCount;
    events: IEvent [] = [];
    activeSection: string;
    hasFolderOrderParts = false;
    workflowStatus: IEvent[] = [];
    orderCount;

    constructor(private workflowService: WorkflowService,
                private folderSubjectService: FolderSubjectService,
                private folderService: FolderService,
                private orderSubjectService: OrderSubjectService,
                private notificationService: NotificationService) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.workflowService.refreshSurveyFolder.subscribe(res => {
            this.folder.survey = res;
        });
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(res => {
            this.folder = res;
            this.folder.attachments = res.attachments.filter(att => !!att.id);
            this.getCountAllNotificationByFolder();
            this.getEvents();
            this.getHasFolderOrderParts();
        });
        this.anotherSubscription = this.notificationService.notification$.subscribe(notif => {
            this.notificationCount = notif;
        });
    }

    private getEvents() {
        this.folderService.getEventList(this.folder.id).subscribe(data => {
            this.events = data;
            this.eventsNumber = data.length;
            this.getWorkflowStatus();
        });
    }

    getWorkflowStatus() {
        this.workflowStatus = this.events.filter(event => event.eventType?.startsWith('WORKFLOW_STATUS'));
        this.workflowStatusNumber = this.workflowStatus.length;
    }

    private getHasFolderOrderParts(): boolean {
        if (!!this.folder.orderIds && this.folder.orderIds.length > 0) {
            this.anotherSubscription = this.orderSubjectService.order$.subscribe(order => {
                this.hasFolderOrderParts = order.orderItems?.length > 0;
                if (this.hasFolderOrderParts) {
                    this.orderCount = this.folder.orderIds.length;
                }else{
                    this.orderCount = undefined;
                }
            });
        } else {
            // TODO this code will be deprecated
            this.hasFolderOrderParts = this.folder.services.some(service => service.type === ServiceType.SPARE_PARTS);
        }

        return this.hasFolderOrderParts;
    }

    getCountAllNotificationByFolder(): void {
        if (!this.folder) {
            return;
        }
        this.notificationService.getNotificationsFolderCount(this.folder.id).subscribe(res => {
            this.notificationCount = res;
        });
    }

    isEnabledSurvey(): boolean {
        return !!this.folder.survey && this.folder.survey.finished && !this.folder.survey.canceled;
    }

    updateSection(section: string): void {
        this.activeSection = section;
    }

    canViewComponent(): boolean {
        return this.folder.context === Context.SAV || this.folder.context === Context.REPARATION;
    }

    hasQuotationOrReparationReport(): boolean {
        return this.folder.quotations.length > 0 || this.hasReparationReport();
    }

    hasReparationReport(): boolean {
        return !!this.folder.reparationReport && (this.folder.reparationReport.iris.length > 0 || this.folder.reparationReport.reportLine.length > 0);
    }

    quotationBadgeValue(): string {
        return String(this.folder.quotations.length === 0 ? (!this.hasReparationReport() ? '' : '1') : this.folder.quotations.length);
    }

}
