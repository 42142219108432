import {Roles} from '../../models/enums/roles.enum';
import {SiteType} from '../../models/enums/siteType.enum';

export class UserUtils {
    static refreshContext = (contexts: string[], currentContext: string) =>
        contexts.includes(currentContext) ? currentContext : contexts[0]

    static refreshOrganizationCode(organizations: string[], currentOrganizationCode: string) {
        if (organizations[0] === 'ALL') {
            if (currentOrganizationCode && currentOrganizationCode !== 'ALL') {
                return currentOrganizationCode;
            }
            return organizations[0];
        }
        if (organizations.includes(currentOrganizationCode)) {
            return currentOrganizationCode;
        }
        return organizations[0];
    }

    static userHasAccess = (userAccessSites, folderSites) =>
        (userAccessSites.includes('ALL') ||
            folderSites.filter(site => SiteType.MANAGEMENT_SITE === site.type)
                .map(site => site.code)
                .some(siteCode => userAccessSites.includes(siteCode)))

    static isAllowedByScopes = (scopes, item) => {
        if (item.oneOfScopes) {
            const matchAtLeastOneScope = item.oneOfScopes;
            return matchAtLeastOneScope.some(requiredScope => scopes.includes(requiredScope));
        }
        const requiredScope = item.scope;
        return !requiredScope || scopes.includes(requiredScope);
    }
}
