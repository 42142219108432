<div>
    <h2>
        <mat-icon class="mt-20">attach_file</mat-icon>
        {{'FOLDER.FILE.ATTACHMENTS_LIST'| translate}}
    </h2>
</div>
<div class="w-100-p" xmlns="http://www.w3.org/1999/html">
    <div *ngIf="!!folder">
        <form [formGroup]="folderAttachmentDataSource.filterForm">

            <div (click)="onUploadImage()" [ngClass]="'cursor-pointer pr-30'" fxLayout="row" fxLayoutGap="5px"
            fxLayoutAlign="end center">
                <mat-icon>add_circle_outline</mat-icon>
                <span [ngClass]="'link-underline'">{{'BUTTON.ADD' | translate}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <span fxFlex="50">{{'FORM.TYPE.ATTACHMENT.TITLE' | translate}}
              </span>
                <mat-form-field class="py-4 mt-32" fxFlex="50" appearance="outline">
                    <mat-label>{{'COMPONENT.ALL_TYPE_ATTACHMENT'|translate}}</mat-label>
                    <mat-select
                        (selectionChange)="changeTypeSelected($event.value)"
                        formControlName="type">
                        <mat-select-trigger>
                            {{
                            folderAttachmentDataSource.filterForm.get('type').value === 'ALL_TYPE_ATTACHMENT'
                                ? ('COMPONENT.ALL_TYPE_ATTACHMENT' | translate)
                                : (folderAttachmentDataSource.filterForm.get('type').value | codeToLabel  :'SAV.CONFIG.ATTACHMENT_TYPE' | async)
                            }}
                        </mat-select-trigger>
                        <mat-option
                            value="ALL_TYPE_ATTACHMENT">{{'COMPONENT.ALL_TYPE_ATTACHMENT' |translate}}</mat-option>
                        <mat-option *ngFor="let type of attachmentsTypes"
                                    [value]="type.code">
                            {{type.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div class="font-weight-900">
                    {{'FOLDER.FILE.ATTACHMENT' | translate}} :
                    ({{folderAttachmentDataSource.dataSource.data.length}} {{'FOLDER.FILE.ATTACHMENTS_LIST' | translate}}
                    )
                </div>
            </div>
            <div [ngClass]="'mat-elevation-z4 mt-8 w-100-p'" *ngIf="!!folderAttachmentDataSource.dataSource.data" >
                <table mat-table [dataSource]="folderAttachmentDataSource.dataSource" fxFill>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="table-header"> {{'FOLDER.FILE.TITLE' | translate}}</th>
                        <td mat-cell *matCellDef="let element" class="text-left">
                            <a (click)="openAttachmentFile(element.id)" class="cursor-pointer">
                                <p class="file_name">
                                    <mat-icon>file_copy</mat-icon>
                                    {{element.name}}
                                </p>
                            </a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef class="table-header"> {{'FOLDER.FILE.DATE' | translate}}</th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.uploadDate  | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate) : (currentLanguage$ | async)}} </td>
                    </ng-container>
                    <ng-container matColumnDef="attachmentType">
                        <th mat-header-cell *matHeaderCellDef class="table-header">
                            {{ 'FOLDER.FILE.ATTACHMENT_TYPE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element"
                            [matTooltip]="getSharedWithTooltip(element.attachmentType)"
                            matTooltipPosition="above"
                            matTooltipClass="tooltip-class">
                            {{ element.attachmentType | codeToLabel :'SAV.CONFIG.ATTACHMENT_TYPE' | async }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action" >
                        <th mat-header-cell *matHeaderCellDef class="table-header"> {{'BUTTON.ACTION' | translate}}</th>
                        <td mat-cell
                            *matCellDef="let element">
                            <div fxLayout="row">
                            <mat-icon color="warn" class="cursor-pointer" (click)="removeFile(element.id)">
                                delete_forever
                            </mat-icon>
                                <mat-icon [svgIcon]="'download-file'" class="cursor-pointer icon" (click)="downloadAttachmentFile(element.id, element.name)" ></mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="folderAttachmentDataSource.displayedColumns"></tr>
                    <tr mat-row
                        *matRowDef="let row; columns: folderAttachmentDataSource.displayedColumns;"></tr>

                </table>

            </div>
        </form>
    </div>
</div>
