import {Injectable} from '@angular/core';
import {GLOBAL} from '../../../app-config';
import {HttpClient} from '@angular/common/http';
import {ClaimSensibleInfo} from '../../../models/claim-sensible/claim-sensible-api-info.model';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ClaimSensibleInfoService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api/v1/claim-sensible-info`;

    constructor(private httpClient: HttpClient) {
    }

    public loadClaimSensibleInfo(): Observable<ClaimSensibleInfo> {
        return this.httpClient.get<ClaimSensibleInfo>(`${this.path}/api-infos`);
    }
}
