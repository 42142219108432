export enum PushNotificationMessage {
    ADDRESS_SITE_OR_ZIPCODE_NOT_FOUND = 'ERROR.ADDRESS_SITE_OR_ZIPCODE_NOT_FOUND',
    COMMON = 'ERROR.COMMON',
    ERROR_CALLING_BL_SERVICE = 'ERROR.ERROR_CALLING_BL_SERVICE',
    ERROR_CALLING_BL_SERVICE_SEND_FOLDER_PHEBUS = 'ERROR.ERROR_CALLING_BL_SERVICE_SEND_FOLDER_PHEBUS',
    ERROR_CREATING_FOLDER_REPAIR_CENTER = 'ERROR.ERROR_CREATING_FOLDER_REPAIR_CENTER',
    ERROR_WHEN_FIND_CONFIG_VALUE = 'ERROR.ERROR_WHEN_FIND_CONFIG_VALUE',
    ERROR_WHILE_CREATING_FOLDER_ON_REPAIRER_SERVICE = 'ERROR.ERROR_WHILE_CREATING_FOLDER_ON_REPAIRER_SERVICE',
    ERROR_WHILE_GETTING_THE_RESPONSE = 'ERROR.ERROR_WHILE_GETTING_THE_RESPONSE',
    EVENT_IS_REQUIRED = 'ERROR.EVENT_IS_REQUIRED',
    EXTERNAL_SYSTEM_RETURN_NOT_FOUND_ERROR = 'ERROR.EXTERNAL_SYSTEM_RETURN_NOT_FOUND_ERROR',
    FOLDER_ID_NULL = 'ERROR.FOLDER_ID_NULL',
    FOLDER_NOT_CREATED_IN_REPAIRER_SERVICE = 'ERROR.FOLDER_NOT_CREATED_IN_REPAIRER_SERVICE',
    FOLDER_NOT_FOUND = 'ERROR.FOLDER_NOT_FOUND',
    LIST_PRODUCTS_SUPPLIERS_EMPTY = 'ERROR.LIST_PRODUCTS_SUPPLIERS_EMPTY',
    MANAGEMENT_SITE_NOT_FOUND = 'ERROR.MANAGEMENT_SITE_NOT_FOUND',
    PRODUCT_NOT_FOUND = 'ERROR.PRODUCT_NOT_FOUND',
    REFERENCE_CUSTOMER_NOT_FOUND = 'ERROR.REFERENCE_CUSTOMER_NOT_FOUND',
    SITE_NOT_FOUND = 'ERROR.SITE_NOT_FOUND',
    WARRANTY_IS_NULL = 'ERROR.WARRANTY_IS_NULL',
    CLAIM_UNASSIGNED_INTERVENTION = 'ERROR.CLAIM_UNASSIGNED_INTERVENTION',
    EXCEPTION_WHILE_GENERATING_EXIT_VOUCHER = 'ERROR.EXCEPTION_WHILE_GENERATING_EXIT_VOUCHER',
    EXCEPTION_WHILE_GENERATING_MARKDOWN = 'ERROR.EXCEPTION_WHILE_GENERATING_MARKDOWN',
    PYXIS_ERROR_EMPTY_ORGANIZATION_CODE = 'ERROR.PYXIS_ERROR_EMPTY_ORGANIZATION_CODE',
    PYXIS_ERROR_NO_MATCHING_CAPITAL_LETTERS_CODE = 'ERROR.PYXIS_ERROR_NO_MATCHING_CAPITAL_LETTERS_CODE',
    PYXIS_ERROR_EMPTY_GENERIC_PRODUCT = 'ERROR.PYXIS_ERROR_EMPTY_GENERIC_PRODUCT',
    EMPTY_PYXIS_REFERENCE = 'ERROR.EMPTY_PYXIS_REFERENCE',
    ERROR_IN_PYXIS_SYSTEM = 'ERROR.ERROR_IN_PYXIS_SYSTEM',
    PYXIS_ERROR_EMPTY_GENERIC_PRODUCT_CODE = 'ERROR.PYXIS_ERROR_EMPTY_GENERIC_PRODUCT_CODE',
    PYXIS_ERROR_EMPTY_GENERIC_SUPPLIER_CODE = 'ERROR.PYXIS_ERROR_EMPTY_GENERIC_SUPPLIER_CODE',
    PYXIS_ERROR_EMPTY_BU_CODE = 'ERROR.PYXIS_ERROR_EMPTY_BU_CODE',
    PYXIS_ERROR_EMPTY_SPARE_PARTS = 'ERROR.PYXIS_ERROR_EMPTY_SPARE_PARTS',
    PYXIS_ERROR_EMPTY_CUSTOMER_REFERENCE = 'ERROR.PYXIS_ERROR_EMPTY_CUSTOMER_REFERENCE',
    PYXIS_ERROR_EMPTY_CUSTOMER_FIRSTNAME = 'ERROR.PYXIS_ERROR_EMPTY_CUSTOMER_FIRSTNAME',
    PYXIS_ERROR_EMPTY_CUSTOMER_LASTNAME = 'ERROR.PYXIS_ERROR_EMPTY_CUSTOMER_LASTNAME',
    PYXIS_ERROR_EMPTY_PURCHASE_PRICE_AMOUNT = 'ERROR.PYXIS_ERROR_EMPTY_PURCHASE_PRICE_AMOUNT',
    PYXIS_ERROR_EMPTY_PURCHASE_PRICE_CURRENCY = 'ERROR.PYXIS_ERROR_EMPTY_PURCHASE_PRICE_CURRENCY',
    EXCEPTION_WHILE_PUSHING_REPARATION_FOLDER = 'ERROR.EXCEPTION_WHILE_PUSHING_REPARATION_FOLDER',

    CART_FULLY_RECEIVED = 'INFO.CART_FULLY_RECEIVED',
    NEW_COMMENT_ADDED = 'INFO.NEW_COMMENT_ADDED'
}
