

    <app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
    <mat-card-content class="mt-25">

    <form [formGroup]="warrantyForm" fxLayout="column" fxLayoutAlign="start">

            <div class="px-16 pt-16" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
                <mat-form-field appearance="outline" fxFlex="30">
                    <mat-label>{{'FOLDER.WARRANTY.TITLE' | translate}}</mat-label>
                    <mat-select [id]="'warranty_regime'" (selectionChange)="changeWarranty($event.value)"
                                formControlName="warranty" required>
                        <mat-select-trigger>
                            <mat-icon class="s-20" [ngClass]="warrantyForm.controls['warranty'].value?.icon.class">
                                {{warrantyForm.controls['warranty'].value?.icon.code}}
                            </mat-icon>
                            {{('FOLDER.WARRANTY.ENUM_LABELS.' + warrantyForm.controls['warranty'].value?.key) |translate}}
                        </mat-select-trigger>

                        <mat-option [id]="warranty.key" *ngFor="let warranty of listWarranty" [value]="warranty">
                            <mat-icon class="s-20" [ngClass]="warranty.icon.class">
                                {{warranty.icon.code}}
                            </mat-icon>
                            {{('FOLDER.WARRANTY.ENUM_LABELS.' + warranty.key) |translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="30">
                    <mat-label>{{'FOLDER.WARRANTY.REASON.TITLE' | translate}}</mat-label>
                    <mat-select [id]="'warranty_reason'" (selectionChange)="changeReason($event.value)"
                                formControlName="reason" required>
                        <mat-option [id]="reason" *ngFor="let reason of reasonsWarranty" [value]="reason">
                            <div
                                *ngIf="warrantyForm.controls['warranty'].value?.key === 'SG'">{{reason| codeToLabel: 'SAV.CONFIG.WARRANTY_REASON_SG' | async}}</div>
                            <div
                                *ngIf="warrantyForm.controls['warranty'].value?.key === 'HG'">{{reason| codeToLabel: 'SAV.CONFIG.WARRANTY_REASON_HG' | async}}</div>
                        </mat-option>
                    </mat-select>
                </mat-form-field >
                <mat-form-field appearance="outline" fxFlex="30">
                    <mat-label>{{'FOLDER.OPERATION_MODE.TITLE' | translate}}</mat-label>
                    <mat-select [id]="'operating_mode'" (selectionChange)="changeOperatingMode($event.value)"
                                formControlName="operatingMode" required>

                        <mat-select-trigger>
                            <mat-icon class="s-20"
                                      [svgIcon]="getIconName(warrantyForm.controls['operatingMode'].value)">
                            </mat-icon>
                            {{warrantyForm.controls['operatingMode'].value?.code | codeToLabel  :'SAV.CONFIG.MODE_OP' | async}}
                        </mat-select-trigger>

                        <mat-option [id]="procedure.code" *ngFor="let procedure of operatingModeList" [value]="procedure">
                            <mat-icon class="s-20"
                                      [svgIcon]="getIconName(procedure)">
                            </mat-icon>
                            {{procedure.code | codeToLabel  :'SAV.CONFIG.MODE_OP' | async}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="px-16 pt-16" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px"
                 *ngIf="checkCustomerChoiceCaptured() && hasCustomerChoices()">
                <mat-form-field appearance="outline" fxFlex="30">
                    <mat-label>{{'FOLDER.OPERATION_MODE_CUSTOMER_CHOICE.TITLE' | translate}}</mat-label>
                    <mat-select  [id]="'customer_choice'" formControlName="customerChoice" required>
                        <mat-option [id]="choice" *ngFor="let choice of customerOperatingModeChoices.get(folder.organization.code)"
                                    [value]="choice">
                            {{ 'CUSTOMER_CHOICE.' + choice | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <ng-container *ngIf="hasDiagnosticExpert">
                <mat-label class="px-16 pt-16">{{'FOLDER.HAS_DIAG_EXPERT.TITLE' | translate}}</mat-label>
            </ng-container>
            <ng-container *ngIf="captureCustomerModOpChoice && hasCustomerChoices()">
                <h4 class="text-bold">{{'MODAL.COMMENT.TITLE_ADD_COMMENT' | translate}} </h4>
                <form [formGroup]="modOpCommentForm" fxLayout="column" fxLayoutAlign="start">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                            <mat-label>{{'MODOP.COMMENT.DESCRIPTION' | translate}}</mat-label>
                            <textarea matInput inputControl rows="4" cols="7"
                                      [placeholder]="'MODOP.COMMENT.DESCRIPTION' | translate"
                                      formControlName="comment"></textarea>
                        </mat-form-field>
                    </div>
                </form>
            </ng-container>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        (click)="onSubmit()"
                        [ngClass]="warrantyForm.invalid?'aster_btn_disabled':'aster_btn'"
                        [disabled]="warrantyForm.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
</mat-card-content>
