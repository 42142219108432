import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Customer} from '../../../../../models/customer/customer.model';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {CUSTOMER_COLUMNS} from '../../../../../models/customer/customer.constants';
import {PagedResult} from '../../../../../models/paged-result.model';
import {select, Store} from '@ngrx/store';
import {customerSearchResult} from '../../../../../store/customer/customer.selectors';
import {AppState} from '../../../../../store/app.state';
import {Unsubscriber} from '../../../../../unsubscriber';
import {CustomerPageEvent, UpdateForm} from '../../../../../store/customer/customer.actions';
import {CustomerUtils} from '../../../../../store/customer/customer.utils';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {CustomerService} from '../../../../../shared/services/store/customer.service';
import {finalize, map, take} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'customer-search-list',
    templateUrl: './customer-search-list.component.html'
})
export class CustomerSearchListComponent extends Unsubscriber implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    pageSize = 10;
    pageSizeOptions = [10, 25, 50, 100];

    customers: PagedResult<Customer>;
    customersDataSource = new MatTableDataSource<Customer>([]);

    @Output() showCustomerForm = new EventEmitter<boolean>();

    columns = CUSTOMER_COLUMNS;
    columnsName: string[];

    constructor(private store$: Store<AppState>,
                private customerService: CustomerService,
                private snackBar: MatSnackBar,
                private translateService: TranslateService) {
        super();
    }

    ngOnInit() {
        this.columnsName = this.columns.map(column => column.name);
        this.anotherSubscription = this.store$.pipe(select(customerSearchResult))
            .subscribe(customers => {
                this.customers = customers;
                this.customersDataSource.data = this.customers.items.map(customer => this.toCustomerDataSource(customer));
            });
    }

    toCustomerDataSource(customer) {
        return {
            ...customer,
            mobileNumber: customer.contact?.mobileNumber,
            zipCode: CustomerUtils.getDefaultAddress(customer.addresses).zipCode
        };
    }

    selectCustomer(customer: Customer): void {
        this.store$.dispatch(new StartLoading());
        this.customerService.searchCustomer(customer, null)
            .pipe(
                take(1),
                map(pagedCustomers => this.matchCustomer(customer, pagedCustomers)),
                finalize(() => {
                    this.showCustomerForm.emit();
                    this.store$.dispatch(new StopLoading());
                })
            ).subscribe();
    }

    private matchCustomer(customer: Customer, pagedCustomers: PagedResult<Customer>) {
        if (pagedCustomers.total > 0) {
            const matchedCustomer = pagedCustomers.items.find(item => item.reference === customer.reference);
            this.store$.dispatch(new UpdateForm(matchedCustomer));
        } else {
            this.snackBar.open(this.translateService.instant('CUSTOMER.NOT_FOUND'), '', {
                duration: 3000, horizontalPosition: 'end', verticalPosition: 'bottom', panelClass: 'teal-500',
            });
        }
    }

    changePage($event: PageEvent): void {
        this.store$.dispatch(new CustomerPageEvent($event));
    }

    goBackToForm() {
        this.showCustomerForm.emit();
    }
}
