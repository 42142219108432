import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface AnonymizeDialogData {
    customer: {
        id: string;
        firstName: string;
        lastName: string;
    };
}

@Component({
    selector: 'app-anonymize-dialog',
    templateUrl: './anonymize-dialog.component.html',
    styleUrls: ['./anonymize-dialog.component.scss']
})
export class AnonymizeDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: AnonymizeDialogData) {
    }

    ngOnInit(): void {
    }
}
