import {Injectable} from '@angular/core';
import {GLOBAL} from '../../app-config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Trip} from '../../models/repair/Trip/trip.model';

@Injectable({
    providedIn: 'root'
})
export class TripService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/repair/appointment/api`;

    constructor(public httpClient: HttpClient) {
    }


    getTrip(technicianId: string, tripId: string): Observable<Trip> {
        let params = new HttpParams();
        params = params.append('technicianId', technicianId);
        return this.httpClient.get<Trip>(`${this.path}/trip/${tripId}`, {params: params});
    }


}
