import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfigurationReferential} from 'app/models/configurationReferential.model';
import {DashboardItem, DashboardRule} from '../../../model/dashboard.model';
import {DashboardService} from 'app/shared/services/dashboard.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {Observable} from 'rxjs';
import {allSites} from '../../../../../../store/configuration/configuration.selectors';
import {take} from 'rxjs/operators';
import {currentUser} from 'app/store/user/user.selectors';
import {UserState} from '../../../../../../store/user/user.state';
import {Roles} from '../../../../../../models/enums/roles.enum';


@Component({
    selector: 'app-add-charts-config-dialog',
    templateUrl: './add-charts-config-dialog.component.html'
})
export class AddChartsConfigDialogComponent implements OnInit {
    chartsConfigForm: FormGroup;
    dashboardItems: DashboardItem[] = [];
    sites$: Observable<ConfigurationReferential[]>;
    roles = Object.keys(Roles);
    user: UserState;

    constructor(public dialogRef: MatDialogRef<AddChartsConfigDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private store$: Store<AppState>,
                private dashboardService: DashboardService) {
    }


    ngOnInit(): void {
        if (this.data && this.data.chart && this.data.chartRequest.charts.length > 0) {
            this.dashboardItems = this.dashboardService.buildDashboardItems(this.data);
        }
        this.createFormGroup();
        if (this.data.rule) {
            this.initForm();
        }
        this.sites$ = this.store$.pipe(select(allSites));
        this.store$.pipe(select(currentUser), take(1))
            .subscribe(currentUser => this.user = currentUser);
    }

    createFormGroup() {
        this.chartsConfigForm = new FormGroup({
            site: new FormControl(),
            user: new FormControl(),
            role: new FormControl()
        });
    }

    initForm() {
        this.chartsConfigForm.patchValue(this.data.rule);
    }

    closeDialog() {
        this.dialogRef.close();
    }

    saveChartsConfigs() {
        const dashboardRuleRequest: DashboardRule = {
            context: this.user.context,
            organizationCode: this.user.organizationCode,
            user: this.getValueOrAny('user'),
            role: this.getValueOrAny('role'),
            site: this.getValueOrAny('site'),
            businessLink: 'any',
            dashboardItems: this.dashboardItems
        };
        this.data.save(dashboardRuleRequest)
            .pipe(take(1))
            .subscribe(() => {
                this.closeDialog();
                this.dashboardService.showSuccessSave();
            }, () => this.dashboardService.showError());
    }

    getValueOrAny = field => this.chartsConfigForm.get(field).value ?? 'any';
    clearRoleForm = () => this.chartsConfigForm.get('role').reset();
}
