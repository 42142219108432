    <mat-card-content>
        <form [formGroup]="form"
              fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px"
              (ngSubmit)="onSubmit()">

            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>

            <ng-container *ngIf="!!reason">
                <fieldset>
                    <legend class="h3 title-fieldset">{{'COMPONENT.REFUS_REASON' | translate}}</legend>
                    <div class="mt-12" fxFlex="100">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px" class="mt-12 mb-12">
                            <div>
                        <span class="font-weight-600">
                            <ng-container class="text-left">
                            {{reason}}
                            </ng-container>
                        </span>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </ng-container>

            <ng-container *ngIf="!!comments && comments.length>0">
                <fieldset>
                    <legend class="h3 title-fieldset">{{'COMPONENT.REFUS_COMMENT' | translate}}</legend>
                    <div class="mt-12" fxFlex="100">
                        <ng-container *ngFor="let detail of comments ;let i=index;">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px" class="mt-12 mb-12">
                            <mat-icon>comment</mat-icon>
                            <div>
                        <span class="font-weight-600">
                            <div>{{ detail?.createdBy?.user?.fullName | translate }} : {{detail.content}}</div>
                        </span>
                            </div>
                        </div>
                        </ng-container>
                    </div>
                </fieldset>
            </ng-container>

            <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="8px">
                <mat-radio-group formControlName="decision" class="ml-20" fxLayoutGap="10px"
                                 labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch">
                    <mat-radio-button class="full-width-radio"
                                      *ngFor="let choice of placeholdersDecision"
                                      [value]="choice.status">
                        <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                            <div fxFlex>
                                <div>{{ choice.value | translate }}</div>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
                <app-comment-area *ngIf="form.value.decision === 'RESEND'" [commentForm]="form.get('comment')"></app-comment-area>
            </div>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        [ngClass]="form.invalid ?'aster_btn_disabled':'aster_btn'"
                        [disabled]="form.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>

        </form>
    </mat-card-content>