import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Subject, Subscription} from 'rxjs';
import {AddressType} from '../../../../../models/enums/addressType.enum';
import {Folder} from '../../../../../models/folder.model';
import {Address} from '../../../../../models/customer/customer.model';
import {SharedService} from '../../../../../shared/services/shared.service';
import {CustomerUtils} from '../../../../../shared/utils/customer-utils';
import {Context} from '../../../../../models/enums/context.enum';
import {SiteType} from '../../../../../models/enums/siteType.enum';
import {FolderSubjectService} from '../../../folder-subject.service';
import {Unsubscriber} from '../../../../../unsubscriber';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';


@Component({
    selector: 'app-edit-intervention-address',
    templateUrl: './edit-intervention-address.component.html',
    styleUrls: ['./edit-intervention-address.component.scss']
})
export class EditInterventionAddressComponent extends Unsubscriber implements OnInit, OnDestroy {
    protected _onDestroy = new Subject<void>();
    @Input() variablesTask: any;
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();

    editAddress = false;
    customerAddress: Address;
    addresses: any;
    form: FormGroup;
    addressOutput: Address;
    validateAddressSubscription: Subscription;
    validateFormAddress: boolean;
    productInStore = false;

    constructor(private translateService: TranslateService,
                private sharedService: SharedService,
                private folderSubjectService: FolderSubjectService) {
        super();
    }

    ngOnInit(): void {
        this.validateAddressSubscription = this.sharedService.getValidateForm().subscribe(validate => {
            this.validateFormAddress = validate;
        });
        this.initCustomerDetailForm();
        this.initCustomerDetailDataWithCustomerData();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
        this.validateAddressSubscription.unsubscribe();
    }

    initCustomerDetailForm(): void {
        this.form = new FormGroup({
            countryCode: new FormControl(null, Validators.required),
            accessibility: new FormGroup({
                housing: new FormControl(null),
                floorCount: new FormControl(null),
                stage: new FormControl(null),
                parking: new FormControl(null)
            }),
            comment: new FormControl(''),
        });
        this.anotherSubscription = this.sharedService.getUpdateAddressEvent().subscribe(res => {
            if (!res) {
                this.form.get(['countryCode']).setErrors({'incorrect': true});
                return;
            }
            this.form.get(['countryCode']).setValue(res.countryCode);
            this.addressOutput = res;
        });
    }

    initCustomerDetailDataWithCustomerData(): void {
        if (!!this.variablesTask['productInStoreOptionSelected']) {
            this.productInStore = this.variablesTask['productInStoreOptionSelected'];
        }

        this.customerAddress = this.getInterventionAddress();
        if (!!this.customerAddress) {
            this.form.get(['countryCode']).setValue(this.customerAddress.countryCode);
        }
    }

    onSubmit(): void {
        if (this.form.value.comment.length > 0) {
            this.commentGiven.emit({value: this.form.value.comment});
        }

        this.editAddress = false;
        if (this.form.invalid || !this.validateFormAddress) {
            return;
        }

        this.customerAddress = {
            countryName: this.translateService.instant('COUNTRY.NAME.' + this.addressOutput.countryCode),
            countryCode: this.addressOutput.countryCode,
            city: this.addressOutput.city,
            zipCode: this.addressOutput.zipCode,
            address: this.addressOutput.address,
            secondAddress: this.addressOutput.secondAddress,
            type: AddressType.INTERVENTION,
            accessibility: this.addressOutput.accessibility
        };

        this.inputMap.emit({
            'interventionCustomerDetails': JSON.stringify({
                'interventionAddress': this.customerAddress, 'contact': this.folder.customer.contact,
            }),
            'productInStoreOptionSelected': this.productInStore
        });
    }

    getInterventionAddress(): Address {
        return CustomerUtils.getCustomerAddressByType(this.folder.customer.addresses, AddressType.INTERVENTION);
    }

    reset(): void {
        this.customerAddress = this.getInterventionAddress();
        this.sharedService.updateResetAddress(this.customerAddress);
    }

    isFormDisabled(): boolean {
        return this.form.invalid || !this.validateFormAddress;
    }

    isProductRecallOrReparationContext(): boolean {
        return !!this.folder && (this.folder.context === Context.PRODUCT_RECALL || this.folder.context === Context.REPARATION);
    }

    onToggle() {
        this.productInStore = !this.productInStore;
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            if (this.productInStore) {
                this.customerAddress = folder?.sites.find(site => site.type === SiteType.MANAGEMENT_SITE)?.address;
                this.setAddress(this.customerAddress);
                if (this.form.get('address').valid) {
                    this.form.get('address').disable();
                }
            } else {
                this.form.get('address').enable();
                this.customerAddress = this.getInterventionAddress();
                this.setAddress(this.customerAddress);
            }
        });
    }

    private setAddress(address: Address) {
        this.form.get('address').get('address').setValue(address?.address);
        this.form.get('address').get('city').setValue(address?.city);
        this.form.get('address').get('countryCode').setValue(address?.countryCode);
        this.form.get('address').get('fullAddress').setValue(address?.fullAddress);
        this.form.get('address').get('location.lon').setValue(address?.location?.lon);
        this.form.get('address').get('location.lat').setValue(address?.location?.lat);
        this.form.get('address').get('secondAddress').setValue(address?.secondAddress);
        this.form.get('address').get('zipCode').setValue(address?.zipCode);
    }

}
