import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FolderService} from '../../../shared/services/folder.service';
import {FolderSubjectService} from '../folder-subject.service';
import {Folder, IFolderUpdateRequest} from '../../../models/folder.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {SnackBarService} from '../../../shared/services/snack-bar.service';
import {GrowthbookService} from '../../../shared/services/growthbook.service';
import {AppFeatures} from '../../../shared/features/app-features';
import {GrowthbookAttributes} from '../../../shared/features/growthbook-attributes';

export const REPAIR_CENTER_REFERENCE = 'REPAIR_CENTER_REFERENCE';
export const PAYMENT_REFERENCE = 'PAYMENT_REFERENCE';


@Component({
    selector: 'app-add-external-references-dialog',
    templateUrl: './add-external-references-dialog.component.html',
    styleUrls: ['./add-external-references-dialog.component.scss']
})
export class AddExternalReferencesDialogComponent implements OnInit {

    public addExternalReferencesForm: FormGroup;
    public externalSystems: Array<string> = [REPAIR_CENTER_REFERENCE, PAYMENT_REFERENCE];
    private newReference = new Map<string, string>();

    constructor(@Inject(MAT_DIALOG_DATA) public folder: Folder,
                public dialogRef: MatDialogRef<AddExternalReferencesDialogComponent>,
                private folderService: FolderService,
                private folderSubjectService: FolderSubjectService,
                private store$: Store<AppState>,
                private growthbookService: GrowthbookService,
                private snackBar: SnackBarService
    ) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initFeatures();
    }

    private initFeatures(): void {
        const attributes: GrowthbookAttributes = {
            organizationCode: this.folder.organization.code,
            context: this.folder.context,
        };

        this.growthbookService.getFeatureValue(AppFeatures.CLAIM_EXTERNAL_REFERENCES, attributes, this.externalSystems)
            .then((featureValue: string[]) => {
                this.externalSystems = [...this.externalSystems, ...featureValue]
                    .filter(systemId => !Object.keys(this.folder.externalReferences).includes(systemId));
            })
            .catch(error => {
                console.error(error);
            });
    }

    initForm(): void {
        this.addExternalReferencesForm = new FormGroup({
            externalSystems: new FormControl(null, Validators.required),
            references: new FormControl(null, Validators.required)
        });
    }

    addExternalReferences(): void {
        this.store$.dispatch(new StartLoading());
        const externalSystem = this.addExternalReferencesForm.value.externalSystems;
        this.newReference[externalSystem] = this.addExternalReferencesForm.value.references;
        const folderUpdateRequest: IFolderUpdateRequest = {
            externalReferences: this.newReference
        };

        // update the folder
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest)
            .then(data => {
                this.folderSubjectService.folderLoaded(data);
                this.snackBar.openWithIcon('FOLDER.SUCCESS_UPDATE', 'Success');
                this.store$.dispatch(new StopLoading());
                this.closeDialog();
            }).catch(() => {
            this.store$.dispatch(new StopLoading());
            this.snackBar.openWithIcon('FOLDER.ERROR_UPDATE', 'Error');
        });

    }

    onSystemSelected(): void {
        this.newReference.clear();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

}
