import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Constants} from '../../../app/Constants';

@Component({
    selector: 'app-confirm-dialog-with-input',
    templateUrl: './confirm-dialog-with-input.component.html',
    styleUrls: ['./confirm-dialog-with-input.component.scss']
})
export class ConfirmDialogWithInputComponent implements OnInit {

    title: string;
    message: string;
    inputTitle: string;
    inputPlaceholder: string;
    inputValueRequired = false;
    confirmButtonLabel = Constants.BUTTON_CONFIRM;
    cancelButtonLabel = Constants.BUTTON_CANCEL;

    inputForm = new FormGroup({});

    constructor(public dialogRef: MatDialogRef<ConfirmDialogWithInputComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        if (!!this.data) {
            this.inputValueRequired = this.data.inputValueRequired;
        }
        this.initForm();
        this.closeWithEscape();
    }

    private closeWithEscape(): void {
        this.dialogRef.keydownEvents().subscribe(event => {
            if (event.key === Constants.ESCAPE || event.key === Constants.ESC) {
                this.onClose();
            }
        });
    }

    initForm(): void {
        this.inputForm = new FormGroup({
            inputValue: new FormControl('', this.inputValueRequired ? [Validators.required] : null)
        });
    }

    onClose(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        this.dialogRef.close(this.inputForm.value);
    }


}
