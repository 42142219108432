<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" >

<mat-card-title fxLayout="row" fxLayoutAlign="space-between center" cdkDragHandle>
    <h2 class="text-bold">
        {{'FOLDER.SEND_COMMUNICATION' | translate}}
    </h2>
    <button mat-icon-button class="ml-40" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</mat-card-title>
<div [ngClass]="sendingInProgress ? 'read-only' : ''">
    <form [formGroup]="sendingCommunicationForm.form" fxLayout="column"
          fxLayoutAlign="start">
        <mat-card-content fxLayout="column" fusePerfectScrollbar>

            <!-- select type -->
            <mat-form-field fxFlex="100" appearance="outline">
                <mat-label>{{'FOLDER.SEND_COMMUNICATION_TYPE'|translate}}</mat-label>
                <mat-select formControlName="communicationType" (selectionChange)="getContactValue()">
                    <mat-option *ngFor="let communicationType of sendingCommunicationForm.communicationTypeList"
                                [value]="communicationType"> {{ 'FOLDER.SEND_COMMUNICATION_TYPE.' + communicationType|translate}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- select receiver -->
            <mat-form-field fxFlex="100" appearance="outline">
                <mat-label>{{'FOLDER.SEND_COMMUNICATION_RECEIVER'|translate}}</mat-label>
                <mat-select formControlName="receiver" (selectionChange)="getContactValue()">
                    <mat-option *ngFor="let receiver of sendingCommunicationForm.receiverList"
                                [value]="receiver"> {{ 'FOLDER.SEND_COMMUNICATION_RECEIVER.' + receiver|translate}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- content -->
            <ng-container *ngIf="isSelectedValues()">
                <!-- customer credentials -->
                <ng-container *ngIf="isMail(); else mobileNumberInput">
                    <mat-form-field fxFlex="100" appearance="outline">
                        <mat-label>{{'CUSTOMER.EMAIL'|translate}}</mat-label>
                        <input matInput type="email" isEmail formControlName="mail" required>
                    </mat-form-field>
                    <mat-form-field fxFlex="100" appearance="outline">
                        <mat-label>{{'FOLDER.SEND_COMMUNICATION_TYPE.MAIL.OBJECT'|translate}}</mat-label>
                        <input matInput formControlName="mailObject" required>
                    </mat-form-field>
                </ng-container>

                <!-- text area if sms; rich text if mail or call -->
                <ng-container *ngIf="isSMS(); else mailContent">
                    <mat-form-field fxFlex="100" appearance="outline">
                        <mat-label>{{'FOLDER.SEND_COMMUNICATION.CONTENT' | translate}}</mat-label>
                        <textarea #sms formControlName="smsContent" matInput required  maxlength="{{MAX_CHAR_NUMBER}}"></textarea>
                        <mat-hint align="end"    [style.color]=" isLimitNear(sms) ? 'red' : 'initial'">{{remainingCharacters(sms)}} / {{MAX_CHAR_NUMBER}}</mat-hint>
                    </mat-form-field>
                </ng-container>

            </ng-container>
        </mat-card-content>
        <ng-container *ngIf="isMail()">
            <mat-checkbox formControlName="cc">
                {{'BUTTON.CARBON_COPY.STORE' | translate}} ( {{'CODE_LABEL.EMAIL' | translate}} : {{storeEmail}} )
            </mat-checkbox>

        </ng-container>

        <div *ngIf="sendingInProgress">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
            <mat-dialog-actions fxLayoutAlign="end end">
                <div fxLayout="row">
                    <button (click)="closeDialog()" class="aster_btn_cancel button-height ml-8" mat-stroked-button
                            type="reset">
                        {{'BUTTON.CANCEL' | translate}}
                    </button>
                    <button [disabled]="!isFormValid()"
                            [ngClass]="!isFormValid() || sendingInProgress ?'aster_btn_disabled':'aster_btn'"
                            mat-stroked-button
                            (click)="onSubmit()">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </mat-dialog-actions>
    </form>


    <ng-template #mailContent>
        <div class="mb-24">
            <angular-editor [(ngModel)]="mailContentValue" [config]="editorConfig"></angular-editor>
        </div>
        <span class="mt-4">[{{'ATTACHMENTS.MAX_SIZE' | translate}}]</span>
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field appearance="outline" fxFlex="90">
                <mat-label>{{ 'ATTACHMENT' | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedAttachment">
                    <mat-option
                        *ngFor="let attachment of filteredAttachments | async"
                        [value]="attachment">
                        <span>{{attachment.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button type="button" mat-icon-button color="accent" fxFlex="10" style="padding-bottom:60px"
                    (click)="onAddAttachment()" [disabled]="!selectedAttachment">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <mat-list class="w-100-p">
            <ng-container *ngFor="let chosenAttachment of chosenAttachments">
                <mat-list-item>
                    <h4 mat-line class="file_name text-bold">{{chosenAttachment.name}}</h4>
                    <p mat-line>{{(convertToMb(chosenAttachment.size) * 1024) | number : '1.3'}} {{'ATTACHMENT.SIZE' | translate}}</p>
                    <mat-icon color="warn" class="cursor-pointer" style="margin-right: 15px" (click)="onDeleteAttachment(chosenAttachment)">delete_forever</mat-icon>
                </mat-list-item>
            </ng-container>
        </mat-list>
        <div class="message-box warning mb-16 mt-10" *ngIf="sizeAttachmentExceeded">
            {{'WARNING_MAX_SIZE' | translate}}
        </div>
    </ng-template>

    <ng-template #mobileNumberInput>
        <mobile-phone-input-form
            [parentFormGroup]="this.sendingCommunicationForm.form"
            [mobilePhoneInput]="mobilePhoneOutput" fxFlex="100"
        ></mobile-phone-input-form>
    </ng-template>
</div>
</div>