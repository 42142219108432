<form *ngIf="addressForm" [formGroup]="addressForm" fxLayout="column"
      fxLayoutAlign="start" autocomplete="off" >

    <div fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-form-field class="py-4" appearance="outline" fxFlex="100">
            <mat-label>{{'ADDRESS.street1'|translate}}
            </mat-label>
            <input matInput inputControl placeholder="{{'ADDRESS.street1'|translate}}"
                   formControlName="address"  [matAutocomplete]="auto"
                   [required]="addressForm.get(['address']) | hasRequiredField"
                   id="address_address" (change)="onAddressInputChange()"
                   autocomplete="fake-name-disable-autofill">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!isLoading">
                    <mat-option *ngFor="let address of addresses" [value]="address.full_street"
                                id="'address_address_'+{{address.number}}" (onSelectionChange)="selectAddress(address)">
                                    <span><b>{{address.full_address}}</b></span>
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field class="py-4" appearance="outline" fxFlex="100">
            <mat-label>{{'ADDRESS.street2'|translate}}</mat-label>
            <input matInput placeholder="{{'ADDRESS.street2'|translate}}" inputControl
                   id="address_secondAddress" formControlName="secondAddress" (change)="notifyAddressFormChanged()">
        </mat-form-field>
    </div>
    <div class="py-4" fxLayout="row wrap" gdColumns="repeat(auto-fit, minmax(200px, 1fr))" gdGap="10px">
        <mat-form-field appearance="outline">
            <mat-label>{{'ADDRESS.ZIPCODE'|translate}}
            </mat-label>
            <input matInput placeholder="{{'ADDRESS.ZIPCODE'|translate}}"
                   id="address_zipCode" formControlName="zipCode"  (change)="notifyAddressFormChanged()"
                   [required]="addressForm.get(['zipCode']) | hasRequiredField"
                   (blur)="validateZipCode()">
            <mat-icon matSuffix class="disabled-text">{{getIcon('zipCode', 'markunread_mailbox')}}</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{'ADDRESS.CITY'|translate}}
            </mat-label>
            <input matInput inputControl placeholder="{{'ADDRESS.CITY'|translate}}" formControlName="city" id="address_city"
                   [required]="addressForm.get(['city']) | hasRequiredField"
                   (change)="notifyAddressFormChanged()">
            <mat-icon matSuffix class="disabled-text">{{getIcon('city', 'location_city')}}</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{'ADDRESS.COUNTRY'|translate}}
            </mat-label>
            <mat-icon matPrefix class="mr-8">
                        <span *ngIf="!!addressForm.get(['countryCode']).value; else globeIcon">
                            <span style="vertical-align: sub;"
                                  [attr.class]="'flag-icon h-16 w-20 flag-icon-' + addressForm.get([ 'countryCode']).value | lowercase"></span>
                        </span>
                <ng-template #globeIcon>
                    public
                </ng-template>
            </mat-icon>
            <button matSuffix mat-icon-button type="button" class="mx-8"
                    *ngIf="!!addressForm.get(['countryCode']).value"
                    (click)="onClearAddressDetailFormControlValueByPath(['countryCode'])">
                <mat-icon>close</mat-icon>
            </button>
            <mat-select id="address_countryCode" formControlName="countryCode"
                        [required]="addressForm.get(['countryCode']) | hasRequiredField"
                        (selectionChange)="notifyAddressFormChanged()"
                        [placeholder]="'CODE_LABEL_CONFIG.FORM.FIELDS.COUNTRY.PLACEHOLDER' | translate"
                        aria-label=""
                        (blur)="validateZipCode()">
                <mat-option>
                <ngx-mat-select-search id="address_countryCodeCtrl" formControlName="countryCodeCtrl"
                                       [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                       [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>
                    </mat-option>
                <mat-option *ngFor="let country of filteredCountriesList | async"
                            id="{{'address_countryCodeCtrl_' + country.code}}" [value]="country.code">

                    <span
                        [attr.class]="'flag-icon h-24 w-32 flag-icon-' + country.code | lowercase"></span>
                    <span class="ml-8"
                          style="vertical-align: sub;">{{ ('COUNTRY.NAME.' + country.code) | translate }}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <ng-container *ngIf="showLoanLat" [formGroup]="addressForm.get(['location'])">
        <div class="py-4" fxLayout="row wrap" gdColumns="repeat(auto-fit, minmax(200px, 1fr))" gdGap="10px">
            <mat-form-field appearance="outline">
                <mat-label>{{'ADDRESS.LONG'|translate}}</mat-label>
                <input matInput placeholder="{{'ADDRESS.LONG'|translate}}"
                       id="address_lon" formControlName="lon" required>
                <mat-icon matSuffix class="disabled-text">location_on</mat-icon>llo
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'ADDRESS.LAT'|translate}}</mat-label>
                <input matInput placeholder="{{'ADDRESS.LAT'|translate}}" formControlName="lat" id="address_lat"
                       required>
                <mat-icon matSuffix class="disabled-text">location_on</mat-icon>
            </mat-form-field>
        </div>
    </ng-container>
    <ng-container [formGroup]="addressForm.get(['accessibility'])" *ngIf="!!withAccessibility">
        <div class="py-4">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <div fxFlex="30" fxLayout="row ">
                    <mat-label
                        class="mr-4">{{'CODE_LABEL_CONFIG.FORM.PARAMETERS.ADDITIONAL_TIME.HOUSE'|translate}}</mat-label>
                    <mat-slide-toggle formControlName="housing" id="address_housing" (toggleChange)="selectHousing()">
                        {{'CODE_LABEL_CONFIG.FORM.PARAMETERS.ADDITIONAL_TIME.APARTMENT' | translate}}
                    </mat-slide-toggle>
                </div>
                <ng-container *ngIf="isApartment">
                    <mat-form-field appearance="outline" fxFlex="15">
                        <mat-label>{{'CODE_LABEL_CONFIG.FORM.PARAMETERS.FLOOR_COUNT'|translate}}</mat-label>
                        <input matInput placeholder="{{'CODE_LABEL_CONFIG.FORM.PARAMETERS.FLOOR_COUNT'|translate}}"
                               formControlName="floorCount" id="address_floorCount"
                               (change)="notifyAddressFormChanged()"    type="number" min="0">
                    </mat-form-field>
                    <div fxFlex="45">
                        <div fxLayout="row ">
                            <mat-label
                                class="mr-4">{{'CODE_LABEL_CONFIG.FORM.PARAMETERS.ADDITIONAL_TIME.WITHOUT_ELEVATOR'|translate}}</mat-label>
                            <mat-slide-toggle formControlName="stage" id="address_stage" (toggleChange)="selectStage()">
                                {{'CODE_LABEL_CONFIG.FORM.PARAMETERS.ADDITIONAL_TIME.WITH_ELEVATOR' | translate}}
                            </mat-slide-toggle>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class=" mt-8 mb-8" fxLayout="row" fxLayoutAlign="start center">
                <mat-label>{{'CODE_LABEL_CONFIG.FORM.PARAMETERS.PARKING_PLACEHOLDER' |translate}} :</mat-label>
                <mat-radio-group aria-label="Select an option" (change)="notifyAddressFormChanged()"
                                 formControlName="parking" class="ml-20" labelPosition="after" fxLayout="row"
                                 fxLayoutAlign="start stretch"
                                 fxLayoutGap="10px">
                    <mat-radio-button
                        value="easyParking">{{'CODE_LABEL_CONFIG.FORM.PARAMETERS.ADDITIONAL_TIME.PARKING_EASY' | translate}}</mat-radio-button>
                    <mat-radio-button
                        value="mediumParking">{{'CODE_LABEL_CONFIG.FORM.PARAMETERS.ADDITIONAL_TIME.PARKING_MEDIUM' | translate}}</mat-radio-button>
                    <mat-radio-button
                        value="difficultParking">{{'CODE_LABEL_CONFIG.FORM.PARAMETERS.ADDITIONAL_TIME.PARKING_DIFFICULT' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </ng-container>
</form>