import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {Order} from '../../models/order.model';
import {filter} from 'rxjs/operators';
import {OrderStatus} from '../../models/enums/orderStatus.enum';
import {map} from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class OrderSubjectService {
    private orderSubject = new ReplaySubject<Order>(1);
    public order$ = this.orderSubject.asObservable().pipe(filter(order => order != null));
    isNotConfirmed$: Observable<boolean> = this.order$.pipe(
        filter(order => order != null),
        map(order => order.orderStatus === OrderStatus.NOT_CONFIRMED)
    );
    orderLoaded(order: Order): void {
        this.orderSubject.next(order);
    }

    clearOrder(): void {
        this.orderSubject.next(null);
    }

}
