import {Component, Input, OnInit} from '@angular/core';
import {CommentType} from '../../../../models/enums/CommentType.enum';
import {Comment} from '../../../../models/comment.model';
import {KeyValue} from '@angular/common';
import {SurveyElement} from '../../../../models/element.model';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {currentLanguage} from '../../../../store/organization/organization.selectors';


@Component({
  selector: 'app-folder-comments-card',
  templateUrl: './folder-comments-card.component.html',
  styleUrls: ['./folder-comments-card.component.scss']
})
export class FolderCommentsCardComponent implements OnInit {

    @Input() comments: Map<CommentType, Comment[]> = new Map();

    currentLanguage$: Observable<string>;

    constructor(private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
    }

    indexOrderAsc = (firstElement: KeyValue<string, SurveyElement>, secendElement: KeyValue<string, SurveyElement>): number => {
        let a = 0;
        let b = 0;
        if (!!firstElement.value && !!secendElement.value) {
            a = firstElement.value.order;
            b = secendElement.value.order;
        }
        return a > b ? 1 : (b > a ? -1 : 0);
    }

}
