    <mat-card-content *ngIf="manuelFolderProcess">
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
        <form [formGroup]="manuelFolderProcess.form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <mat-radio-group formControlName="choice" class="ml-20" fxLayout="row" fxLayoutAlign="space-between center"
                             labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button class="full-width-radio" *ngFor="let status of manuelFolderProcess.placeholders"
                                   [value]="status.choice" id="{{ status.choice}}">
                    <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div fxFlex>
                            <div>{{ status.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        [ngClass]="manuelFolderProcess.form.invalid?'aster_btn_disabled':'aster_btn'"
                        [disabled]="manuelFolderProcess.form.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>