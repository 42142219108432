import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {Site} from '../../../../../models/site.model';
import {AddressType} from '../../../../../models/enums/addressType.enum';
import {FolderSubjectService} from '../../../folder-subject.service';
import {Subscription} from 'rxjs';
import {CustomerUtils} from '../../../../../shared/utils/customer-utils';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-deposit-product-site',
    templateUrl: './deposit-product-site.component.html',
    styleUrls: ['./deposit-product-site.component.scss']
})
export class DepositProductSiteComponent implements OnInit, OnDestroy {

    folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    @Output() inputMap = new EventEmitter<any>();
    folderId: string;
    zipCode: string;
    siteSelected: Site;
    private folderSubscription: Subscription;
    depositInProgress = false;

    constructor(private folderSubjectService: FolderSubjectService) {
    }

    ngOnInit(): void {
        this.folderSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
        });
        this.getZipCOde();
    }

    getZipCOde() {
        if (!!this.folder && !!this.folder.customer && !!this.folder.customer.addresses) {
            let address = CustomerUtils.getCustomerAddressByType(this.folder.customer.addresses, AddressType.DEFAULT);
            this.zipCode = !!address ? address.zipCode : '';
        }
    }

    selectSiteEvent(site: Site): void {
        this.siteSelected = site;
    }

    completeTask(): void {
        this.depositInProgress = true;
        if (!this.siteSelected) {
            return;
        }
        let site;
        site = {
            name : this.siteSelected.name,
            code: this.siteSelected.code,
            address: this.siteSelected.address,
            contact: this.siteSelected.contact
        }
        let task = {
            'site': JSON.stringify(site)
        }
        this.inputMap.emit(task);
    }

    ngOnDestroy(): void {
        this.folderSubscription.unsubscribe();
    }

    stopPropagation(): boolean {
        return this.depositInProgress || !this.siteSelected;
    }
}
