import {WarrantyReasonDetails} from './warrantyReasonDetails';
import {WarrantyReasonEnum} from './enums/warrantyReason.enum';
import {ProductWarranty} from './product.model';

export interface WarrantyRule {
    warrantyReasonDetails: WarrantyReasonDetails;
    warrantyReason: WarrantyReasonEnum;
    regimeWarrantyCode: RegimeWarrantyEnum;
}

export enum RegimeWarrantyEnum {
    HG = 'HG', SG = 'SG'
}

export class ProductWarrantyRequest {
    invoiceLine?: string;
    purchaseDate?: string;
    organizationCode: string;
    invoiceNumber?: string;
    productCode?: string;
    invoiceEndOfWarrantyDate?: Date;
    context: string;
    productSubElementCode?: string;
    family?: string;
    subFamily?: string;
    type?: string;
    subType?: string;
    segment?: string;
    brand?: string;
    supplierCode?: string;
    productWarranty?: ProductWarranty;
}

