import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {Router} from '@angular/router';

@Component({
    selector: 'widget-price-count',
    templateUrl: './price-count.component.html',
    styleUrls: ['./price-count.component.scss'],
    animations: fuseAnimations
})
export class PriceCountComponent implements OnInit, OnDestroy {

    @Input() widgetData;
    @Input() columnCount: number = null;
    @Input() id: string = null;
    @Output() updatedColumnCount = new EventEmitter<number>();
    @Output() dropWidget = new EventEmitter<number>();

    totalPrice = 0;
    @Output() elementSelected = new EventEmitter();

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        if (this.widgetData.data[0]) {
            this.totalPrice = this.widgetData.data[0]?.value;
        }
    }

    ngOnDestroy(): void {
    }

    showDetails(): void {
        this.elementSelected.emit({});
    }

    update(): void {
        if (!!this.columnCount) {
            this.updatedColumnCount.emit(this.columnCount);
        }
    }

    deleteWidget(): void {
        this.dropWidget.emit();
    }

    editWidget(): void {
        this.router.navigate(['dashboard/widget/update/' + this.id]);
    }
}



