    <mat-card-content>
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
        <div class="mt-24">
            <mat-checkbox [(ngModel)]="confirmSwap">{{'BUTTON.SWAP_CONFIRMED' | translate}}</mat-checkbox>
        </div>
        <div fxLayout="row" fxLayoutAlign="end">
            <button mat-stroked-button (click)="onSubmit()"
                    type="submit"
                    [disabled]="!confirmSwap"
                    [ngClass]="!confirmSwap?'h-60 mt-4  aster_btn_disabled':'h-60 mt-4 aster_btn'">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>
    </mat-card-content>