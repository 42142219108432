import {Component, OnInit} from '@angular/core';
import {DashboardService} from '../../../shared/services/dashboard.service';
import {fuseAnimations} from '../../../../@fuse/animations';
import {Observable} from 'rxjs';
import {ChartsResponse} from './model/charts-response.model';
import {FolderListComponentModal} from '../folder-list-modal/folder-list.component-modal';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {DashboardFolderFilters, Filter, FilterOperator} from '../../../models/folder-search-response.model';
import {ChartRequest} from './model/dashboard.model';
import {ConfigurationReferential} from '../../../models/configurationReferential.model';
import {FormControl, FormGroup} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import * as _ from 'lodash';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {email} from '../../../store/user/user.selectors';
import {filteredSite} from '../../../store/configuration/configuration.selectors';
import {Unsubscriber} from '../../../unsubscriber';

@Component({
    selector: 'app-dashboard-folder',
    templateUrl: './dashboard-folder.component.html',
    styleUrls: ['./dashboard-folder.component.scss'],
    animations: fuseAnimations
})
export class DashboardFolderComponent extends Unsubscriber implements OnInit {

    chartsResponse: ChartsResponse[];
    chartRequest: {
        charts: ChartRequest[]
    } = {charts: []};
    initialChartRequest: {
        charts: ChartRequest[]
    } = {charts: []};
    dialogRef: MatDialogRef<FolderListComponentModal>;
    siteForm: FormGroup;
    filteredSiteList$: Observable<ConfigurationReferential[]>;
    charts: Map<string, ChartRequest[]>;
    email: string;
    isCurrentUserAdmin$: Observable<boolean>;

    constructor(private dashboardService: DashboardService,
                private router: Router,
                public dialog: MatDialog,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(email)).subscribe(email => this.email = email);
        // D'Ici on commence la recupération des Dashboard
        this.loadDashboard();
        this.siteForm = new FormGroup({
            siteCode: new FormControl('*'),
            siteFilterCtrl: new FormControl()
        });
        this.anotherSubscription = this.siteForm.get('siteFilterCtrl').valueChanges
            .pipe(startWith(null))
            .subscribe(input => {
                this.filteredSiteList$ = this.store$.pipe(select(filteredSite, {currentSite: input}), map(value => value.sort((a, b) => {
                    return a.code.toLowerCase() > b.code.toLowerCase() ? 1 : -1;
                })));
            });
    }

    getFolderList(event, chartType): void {
        let filterElement = this.chartRequest.charts.filter(e => e.name === chartType)[0];
        let filters = new Map();
        let filterFieldName;
        if (filterElement.groupBy) {
            filterFieldName = filterElement.groupBy.field;
            if (!!filterElement.groupBy.ranges && filterElement.groupBy.ranges.length > 0) {
                this.addRangeFilter(filterElement, event, filters, filterFieldName);
            } else {
                const filter: Filter = {
                    operator: FilterOperator.EQUAL,
                    value: event.code
                };
                filters.set(filterFieldName, filter);
            }
        }
        this.addAdditionalFiltersIfExists(filterElement, filters, filterFieldName);
        const convMap = {};
        filters.forEach((val: Filter, key: string) => {
            convMap[key] = val;
        });
        const dashboardFilterRequest: DashboardFolderFilters = {
            filters: convMap
        };
        this.dialogRef = this.dialog.open(FolderListComponentModal, {
            minWidth: 1024,
            data: dashboardFilterRequest
        });
        this.dialogRef.afterClosed().subscribe(folder => {
            if (!!folder) {
                this.router.navigate(['folder', folder.id]);
            }
        });
    }

    private addAdditionalFiltersIfExists(filterElement, filters: Map<any, any>, filterFieldName: string): void {
        if (filterElement.filters) {
            Object.keys(filterElement.filters).forEach(key => {
                let dashboardFolderFiltersValue = filterElement.filters[key];
                if (key !== filterFieldName) {
                    filters.set(key, dashboardFolderFiltersValue);
                }
            });
        }
        if (this.isSiteDefined()) {
            const dashboardFolderFiltersValue: Filter = {
                operator: FilterOperator.EQUAL,
                value: this.siteForm.get('siteCode').value
            };
            filters.set('managementSiteCode', dashboardFolderFiltersValue);
            filters.delete('siteCode'); // this field  is only in dashboard index and not in folder index
        }
    }

    private isSiteDefined(): boolean {
        return this.siteForm.get('siteCode').value && this.siteForm.get('siteCode').value !== '*';
    }

    private addRangeFilter(filterElement, event, filters: Map<any, any>, filterFieldName: string): void {
        const dashboardFolderFiltersValue: Filter = {
            operator: FilterOperator.BETWEEN,
            value: {
                from: filterElement.groupBy.ranges.filter(e => e.key === event.code)[0].from,
                to: filterElement.groupBy.ranges.filter(e => e.key === event.code)[0].to
            }
        };
        filters.set(filterFieldName, dashboardFolderFiltersValue);
    }

    loadDashboard(): void {
        this.store$.dispatch(new StartLoading());
        this.dashboardService.getDashboardUsingRule(this.email)
            .subscribe(chartRequest => {
                if (chartRequest && chartRequest.length > 0) {
                    if (this.isSiteDefined()) {
                        chartRequest.forEach(chart => {
                            const filter: Filter = {
                                operator: FilterOperator.EQUAL,
                                value: this.siteForm.get('siteCode').value
                            };
                            chart.filters['siteCode'] = filter;
                        });
                    }
                    this.chartRequest = {
                        charts: chartRequest
                    };
                    this.initialChartRequest.charts = _.cloneDeep(chartRequest);
                    this.dashboardService.getWidgetsData(this.chartRequest)
                        .subscribe(chartsResponse => {
                            this.store$.dispatch(new StopLoading());
                            this.chartsResponse = chartsResponse;
                            this.updateCharts();
                        }, () => this.store$.dispatch(new StopLoading()));
                } else {
                    this.store$.dispatch(new StopLoading());
                    this.chartsResponse = [];
                }
            }, () => this.store$.dispatch(new StopLoading()));
    }

    private updateCharts(): void {
        this.charts = new Map<string, ChartRequest[]>();
        this.initializeCharts();
    }

    private initializeCharts(): void {
        this.chartRequest.charts.forEach(value => {
            const lineNumber = value.line + '';
            if (this.charts.has(lineNumber)) {
                this.charts.get(lineNumber).push(value);
            } else {
                this.charts.set(lineNumber, [value]);
            }
        });
    }

    getAreasChart(key: string): string {
        let areas: string = '';
        const chart: ChartRequest[] = this.charts.get(key);
        chart.forEach(value => {
            for (let i = 0; i < value.columnCount; i++) {
                areas += ' ' + this.getChartName(value.name);
            }
        });
        return areas;
    }

    getChartName(name: string): string {
        return name.split(' ').join('')
            .split('.').join('')
            .split('\'').join('')
            .split('é').join('e')
            .split('è').join('e')
            .split('-').join('')
            .split('_').join('');
    }

    getChartResponse(key: string): ChartsResponse[] {
        const chart: string[] = this.charts.get(key).map(value => value.name);
        return this.chartsResponse.filter(value => chart.includes(value.name));
    }
}



