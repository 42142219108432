import {Product, ProductLocationTypes} from '../../models/product.model';

export class ProductUtils {

    static latestLocation = (product: Product) =>
        product.locations
            .sort((x, y) => y.eventTimestamp - x.eventTimestamp)[0]
            ?.location
        ?? ProductLocationTypes.UNKNOWN;

    static latestPosition = (product: Product) =>
        product.locations
            .sort((x, y) => y.eventTimestamp - x.eventTimestamp)[0]?.position
        ?? ''


}
