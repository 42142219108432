<!--
<div class="h-500" id ="map" >
</div>
-->
<ng-container *ngIf="!!positions && positions.length>0">
    <div leaflet class="h-500"
         [leafletOptions]="options"
         [leafletBaseLayers]="baseLayers"
         [leafletLayersControlOptions]="layersControlOptions"
         (leafletMapReady)="onMapReady($event)"
         [leafletMarkerCluster]="markerClusterData">
    </div>
</ng-container>