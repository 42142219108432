    <mat-card-content>
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>

        <form [formGroup]="form" fxLayout="column">
            <fieldset>
                <legend class="h3 title-fieldset">{{ 'COMPONENT.WAITING_PICKUP.TRANSPORTATION_INFO' | translate }}
                </legend>
                <div fxLayout="column" fxLayoutAlign="start">
                    <!-- Tracking number section -->
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'COMPONENT.WAITING_PICKUP.TRACKING_NUMBER' | translate }}</mat-label>
                        <input matInput inputControl [formControl]="trackingNumberControl"
                               [required]="trackingNumberControl | hasRequiredField"
                               [placeholder]="'COMPONENT.WAITING_PICKUP.TRACKING_NUMBER' | translate">
                        <button mat-button *ngIf="trackingNumberControl.value"
                                matSuffix mat-icon-button aria-label="Clear"
                                (click)="trackingNumberControl.reset()">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="trackingNumberControl.hasError('required')">
                            {{ 'COMPONENT.WAITING_PICKUP.TRACKING_NUMBER'| translate }}
                            <strong>{{ 'REQUIRED' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>

                    <!-- Carrier section -->
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'COMPONENT.WAITING_PICKUP.CARRIER' | translate }}</mat-label>
                        <input matInput inputControl [formControl]="carrierControl"
                               [required]="carrierControl | hasRequiredField"
                               [placeholder]="'COMPONENT.WAITING_PICKUP.CARRIER' | translate">
                        <button mat-button *ngIf="carrierControl.value"
                                matSuffix mat-icon-button aria-label="Clear"
                                (click)="carrierControl.reset()">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="carrierControl.hasError('required')">
                            {{ 'COMPONENT.WAITING_PICKUP.CARRIER' | translate }}
                            <strong>{{ 'REQUIRED' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </fieldset>

            <app-product-accessories *ngIf="showProductAccessories" [claim]="folder"
                                     (productAccessoriesEmitter)="updateSelectedAccessories($event)"></app-product-accessories>

            <!-- Product picked up section -->
            <div class="mt-24">
                <div>
                    <mat-checkbox fxFlex [id]="'product_pickedUp'"
                                  [formControl]="pickedControl">{{ 'BUTTON.PRODUCT_PICKED_UP' | translate }}
                    </mat-checkbox>
                </div>
              <div fxLayout="row" fxLayoutAlign="end">
                    <button mat-stroked-button (click)="onSubmit()"
                            type="submit"
                            [disabled]="form.invalid"
                            [ngClass]="form.invalid ?'aster_btn_disabled':'aster_btn'">
                        {{ 'BUTTON.VALIDATE' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </mat-card-content>