export enum OrderStatus {
    NOT_CONFIRMED = 'NOT_CONFIRMED',
    CONFIRMED = 'CONFIRMED',
    PICKING = 'PICKING',
    EXCEPTION = 'EXCEPTION',
    PARTIALLY_EXCEPTION = 'PARTIALLY_EXCEPTION',
    ORDERED = 'ORDERED',
    PARTIALLY_ORDERED = 'PARTIALLY_ORDERED',
    SHIPPED = 'SHIPPED',
    PARTIALLY_SHIPPED = 'PARTIALLY_SHIPPED',
    DELIVERED = 'DELIVERED',
    PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED',
    DELIVERED_TO_CLIENT = 'DELIVERED_TO_CLIENT'
}
