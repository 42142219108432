export class WorkflowTask {
    scheduleDate: string;
    repairDecision?: string;
    inputValue: string;
      constructor(
        inputValue: string
      ) {
          this.inputValue = inputValue;
      }

}
