<div *ngIf="!!data && !!data.customer"  fxLayout="column" fxLayoutAlign="start">

    <div  gdAreas="contact address"
          gdAreas.lt-md="contact contact | address address"
          gdAreas.lt-sm="contact | address"
          gdColumns="50% 50%" gdColumns.lt-md="100% 100%"
          gdColumns.lt-sm="100%" gdGap="20px">

        <div gdArea="contact">
            <h4 class="text-bold">{{'CODE_LABEL_CONFIG.HEADERS.CONTACT_CUSTOMER_DETAILS' | translate}}</h4>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">

                <mat-icon>person_outline</mat-icon>
                <div>
                    {{!!data.customer.firstName ? data.customer.firstName : '-'}}
                    {{!!data.customer.lastName ? data.customer.lastName : '-'}}
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class=" mt-4">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class=" mt-4">
                    <mat-icon>local_phone</mat-icon>
                    <mat-icon *ngIf="!!formattedPhoneNumber">
                        <span
                            *ngIf="isValidCountryCode(formattedPhoneNumber.countryCode); else globeIcon">
                            <span style="vertical-align: sub;"
                                  [attr.class]="'flag-icon h-16 w-20 flag-icon-' + getCountryCodeByPhoneNumber(formattedPhoneNumber.countryCode) | lowercase"></span>
                        </span>
                        <ng-template #globeIcon>
                            public
                        </ng-template>
                    </mat-icon>
                    <div>
                        {{!!formattedPhoneNumber ? getNationalPhoneNumber(!!formattedPhoneNumber.phoneNumber ? formattedPhoneNumber.phoneNumber : '') : ''}}
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class=" mt-4">
                    <mat-icon>email</mat-icon>
                    <div>
                        {{!!data.customer.email ? data.customer.email : ''}}
                    </div>
                </div>
            </div>

        </div>
        <div gdArea="address">
            <h4 class="text-bold">{{'CODE_LABEL_CONFIG.HEADERS.INTERVENTION_ADDRESS' | translate}}</h4>
            <div *ngIf="!!data.customer.interventionAddress" class="mt-8" fxLayout="row" fxLayoutAlign="start center"
                 fxLayoutGap="15px">
                <mat-icon class="mr-8">
                    <span
                        *ngIf="!!data.customer.interventionAddress.countryCode && isValidCountryCode(data.customer.interventionAddress.countryCode); else globeIcon">
                        <span [attr.class]="'flag-icon h-16 w-20 flag-icon-' + data.customer.interventionAddress.countryCode | lowercase"
                              style="vertical-align: sub;"></span>
                    </span>
                    <ng-template #globeIcon>
                        public
                    </ng-template>
                </mat-icon>
                <div>
                    {{'COUNTRY.NAME.' + data.customer.interventionAddress.countryCode | translate}},
                    {{data.customer.interventionAddress.city}} -
                    {{data.customer.interventionAddress.zipCode}}
                </div>
            </div>
            <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <mat-icon>location_city</mat-icon>
                <div>
                    {{!!data.customer.interventionAddress ? data.customer.interventionAddress.address : '-'}} {{!!data.customer.interventionAddress  && data.customer.interventionAddress.secondAddress ?
                    ', ' + data.customer.interventionAddress.secondAddress : ''}}
                </div>
            </div>

        </div>

    </div>


</div>
