import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {PublicService} from '../../../shared/services/public.service';
import {FolderPublic} from '../../../models/folder.public.model';
import {select, Store} from '@ngrx/store';
import {Unsubscriber} from '../../../unsubscriber';
import {AppState} from '../../../store/app.state';
import {currentLanguage} from '../../../store/organization/organization.selectors';

@Component({
    selector: 'app-details-quotation-repairer',
    templateUrl: './details-quotaion-repairer.component.html',
    styleUrls: ['./details-quotaion-repairer.component.scss']
})
export class DetailsQuotaionRepairerComponent extends Unsubscriber implements OnInit {

    displayedColumns: string[] = ['code', 'label', 'type', 'price', 'quantity', 'discount', 'discountReason', 'tva'];
    quotationLines = new MatTableDataSource<any>();
    uidFolder: string;
    folder: FolderPublic;
    canUpdate = true;

    constructor(private publicService: PublicService,
                private activatedRoute: ActivatedRoute,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        if (this.activatedRoute.snapshot.params.uid) {
            this.uidFolder = this.activatedRoute.snapshot.params.uid;
            this.anotherSubscription = this.store$.pipe(select(currentLanguage))
                .subscribe(language => {
                    this.getFolder(language);
                });
        }
    }

    getFolder(locale: string): void {
        this.publicService.getFolderByUid(this.uidFolder, false, locale).subscribe(res => {
            this.folder = res;
        });
    }
}



