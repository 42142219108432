import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {STATUS_FOLDER} from '../../widget.data';
import {Router} from '@angular/router';

@Component({
    selector: 'widget-list-table',
    templateUrl: './list-table.component.html',
    styleUrls: ['./list-table.component.scss'],
    animations: fuseAnimations
})
export class ListTableComponent implements OnInit {

    @Input() widgetData;
    @Output() elementSelected = new EventEmitter();
    @Input() columnCount: number = null;
    @Input() id: string = null;
    @Output() updatedColumnCount = new EventEmitter<number>();
    @Output() dropWidget = new EventEmitter<number>();

    statusDefaultSelected = ['SURVEY', 'WAIT_QUOTATION', 'QUOTATION_ACCEPTATION', 'PROGRESS_REPAIR', 'WAITING_PAYMENT'];
    allStatusFolder = STATUS_FOLDER;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    refreshDataTable(status): void {
        if (this.statusDefaultSelected.includes(status)) {
            this.statusDefaultSelected.splice(this.statusDefaultSelected.indexOf(status), 1);
        } else {
            this.statusDefaultSelected.push(status);
        }
    }

    showDetails(event): void {
        this.elementSelected.emit({code: this.widgetData.data.filter(value => value.name === event.name)[0].name});
    }

    update(): void {
        if (!!this.columnCount) {
            this.updatedColumnCount.emit(this.columnCount);
        }
    }

    editWidget(): void {
        this.router.navigate(['dashboard/widget/update/' + this.id]);
    }
    deleteWidget(): void {
        this.dropWidget.emit();
    }
}



