import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {currency, hasImmobilization} from '../../../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {FileInfo} from '../../../../../../models/file-info.model';
import {ReportUtils} from '../../../../../../shared/utils/report-utils';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {FileService} from '../../../../../../shared/services/file.service';
import {Quotation} from '../../../../../../models/quotation.model';
import {getCurrencySymbol} from '@angular/common';
import {RegimeWarrantyEnum} from '../../../../../../models/warrantyRule.model';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-internal-reparation-waiting-product-retrieval2',
    templateUrl: './internal-reparation-waiting-product-retrieval2.component.html',
    styleUrls: ['./internal-reparation-waiting-product-retrieval2.component.scss']
})
export class InternalReparationWaitingProductRetrieval2Component extends Unsubscriber implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    currency: string;
    productRetrieved: boolean;
    currentLanguage$: Observable<string>;

    immobilizationMessage$: Observable<string>;
    file: FileInfo;
    description$: Observable<string>;
    remainingAmount: number;
    quotationId: string;
    quotation: Quotation;

    constructor(private translateService: TranslateService,
                private store$: Store<AppState>,
                private folderSubjectService: FolderSubjectService,
                private fileService: FileService) {
        super();
    }

    ngOnInit(): void {
        if (this.folder.immobilization) {
            this.anotherSubscription = this.store$.pipe(select(hasImmobilization))
                .subscribe(value => {
                    this.buildMessage();
                });
        }
        this.anotherSubscription = this.folderSubjectService.folder$
            .subscribe(folder => this.file = ReportUtils.findReportByCode(folder.attachments, 'INTERNAL_REPARATION_INTERVENTION_REPORT_' + folder.newWarranty.warranty));

        if (this.folder.newWarranty.warranty === RegimeWarrantyEnum.HG) {
            this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => this.currency = currency);
            this.quotation = this.getLastQuotation();
            this.getRemainingAmount();

        }
    }

    private buildRemainingAmountMessage(): void {
        if (!!this.remainingAmount) {
            this.description$ = this.translateService.stream('COMPONENT.REMAINING_AMOUNT.DESCRIPTION',
                {
                    price: this.remainingAmount,
                    currency: getCurrencySymbol(this.currency || this.currency, 'narrow')
                });
        }
    }

    private getRemainingAmount() {
        if (!!this.quotation?.totalPrice?.value) {
            this.remainingAmount = this.quotation.totalPrice.value;
            this.buildRemainingAmountMessage();
        }
    }

    private getLastQuotation(): Quotation {
        return this.folder.quotations.length > 0 ? this.folder.quotations.reduce((quotation1, quotation2) => {
            return quotation1.userAction.actionDate > quotation2.userAction.actionDate ? quotation1 : quotation2;
        }) : null;
    }



    private buildMessage(): void {
        this.immobilizationMessage$ = this.translateService.stream('FOLDER.IMMOBILIZATION.DURATION',
            {
                days: this.folder.immobilization?.immobilizationDays,
                immobilizationBeginDate: this.folder?.immobilization?.immobilizationBeginDate,
                immobilizationEndDate: this.folder?.immobilization?.immobilizationEndDate
            });
    }

    onSubmit(): void {
        this.inputMap.emit({});
    }

    getReportAttachment(): void {
        this.fileService.downloadFile(this.file);
    }
}
