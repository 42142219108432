<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="text-center">
<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" cdkDragHandle>
        <h2>
            <mat-icon>class</mat-icon>
            {{'MODAL.UNREFERENCED_SPARE_PART.TITLE' | translate}}
        </h2>
        <button mat-icon-button class="ml-40" (click)="onCloseModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field class="px-4" appearance="outline" fxFlex="33">
                    <mat-label>{{'MODAL.UNREFERENCED_SPARE_PART.REFERENCE' | translate}}</mat-label>
                    <input matInput placeholder="{{'MODAL.UNREFERENCED_SPARE_PART.REFERENCE'|translate}}"
                           required formControlName="reference" (change)="onReferenceChanges($event)">
                    <mat-error
                        *ngIf="form.get(['reference']).invalid">{{'REFERENCE.ALREADY_EXISTS' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field class="px-4" appearance="outline" fxFlex="33">
                    <mat-label>{{'MODAL.UNREFERENCED_SPARE_PART.LABEL' | translate}}</mat-label>
                    <input matInput
                           id="spare_part_label" placeholder="{{'MODAL.UNREFERENCED_SPARE_PART.LABEL'|translate}}"
                           required formControlName="label">
                </mat-form-field>
                <mat-form-field class="px-4" appearance="outline" fxFlex="33">
                    <mat-label>{{'MODAL.UNREFERENCED_SPARE_PART.EAN' | translate}}</mat-label>
                    <input matInput
                           numberOnly type="number"
                           id="spare_part_ean"
                           [placeholder]="'MODAL.UNREFERENCED_SPARE_PART.EAN' | translate"
                           formControlName="ean">
                </mat-form-field>
            </div>
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field class="px-4" appearance="outline" fxFlex="48">
                    <mat-label>{{'MODAL.UNREFERENCED_SPARE_PART.PURCHASE_PRICE' | translate}}</mat-label>
                    <input matInput
                           type="number"
                           [min]="0"
                           id="spare_part_pushace_price"
                           appPositiveNumberValidator
                           required
                           [placeholder]="'MODAL.UNREFERENCED_SPARE_PART.PURCHASE_PRICE' | translate"
                           formControlName="purchasePrice"
                           (change)="onPurchasePriceChanged($event.target)">
                </mat-form-field>
                <mat-form-field class="px-4" appearance="outline" fxFlex="48">
                    <mat-label>{{'MODAL.UNREFERENCED_SPARE_PART.MARGIN' | translate}}</mat-label>
                    <input matInput
                           type="number"
                           [min]="0"
                           id="spare_part_margin_percentage"
                           required
                           [placeholder]="'MODAL.UNREFERENCED_SPARE_PART.MARGIN' | translate"
                           formControlName="marginPercentage"
                           (change)="computePriceUsingGivenMargin()">
                    <mat-icon class="s-16" matSuffix>%</mat-icon>
                </mat-form-field>
                <mat-form-field class="px-4" appearance="outline" fxFlex="48">
                    <mat-label>{{'MODAL.UNREFERENCED_SPARE_PART.SELLING_PRICE' | translate}}</mat-label>
                    <input matInput
                           type="number"
                           appPositiveNumberValidator
                           [min]="0"
                           id="spare_part_selling_price"
                           required
                           [placeholder]="'MODAL.UNREFERENCED_SPARE_PART.SELLING_PRICE' | translate"
                           formControlName="sellingPrice" (change)="calculateMarge()">
                </mat-form-field>
            </div>
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field appearance="outline" fxFlex="80">
                    <mat-label>{{'PRODUCT.PREVIEW_CARD.LABELS.SUPPLIER'|translate}}</mat-label>

                    <mat-select formControlName="supplierCtrl" required (selectionChange)="onSelectionSupplier($event.value)"
                                [placeholder]="'PRODUCT.PREVIEW_CARD.LABELS.SUPPLIER' | translate">
                        <mat-option>
                            <ngx-mat-select-search formControlName="supplierSearchInputCtrl"
                                                   [placeholderLabel]="'ASSET_CATALOG.FORM.CONFIG_CODES_SUPPLIER_SEARCH' | translate"
                                                   [noEntriesFoundLabel]="'CODE_LABEL_CONFIG.CONFIG_CODES_SUPPLIER_EMPTY' | translate"
                                                   [clearSearchInput]="false"
                            ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of filteredSuppliers| async" [value]="item">
                            <span> {{item.code + ' - ' + item.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="px-4" appearance="outline" fxFlex="48">
                    <mat-label>{{'MODAL.UNREFERENCED_SPARE_PART.QTE' | translate}}</mat-label>
                    <input matInput required
                           id="spare_part_qte"
                           numberOnly
                           min="1"
                           [placeholder]="'MODAL.UNREFERENCED_SPARE_PART.QTE' | translate"
                           formControlName="qte"
                           type="number">
                </mat-form-field>
            </div>

            <fieldset>
                <legend
                    class="h3 title-fieldset">{{'MODAL.UNREFERENCED_SPARE_PART.PHOTO_SPARE_PART' | translate}}</legend>
                <div class="w-100-p mt-12" fxLayout="row wrap"
                     gdColumns="repeat(auto-fit, minmax(100%, 1fr))"
                     gdGap="10px">
                    <mat-chip-list class="w-50-p">
                        <mat-chip color="primary" class="upload-image_style" (click)="onUploadImage()">
                            <mat-icon class="mr-8">camera_alt</mat-icon>
                            <span
                                class="ml-4">{{'RULE_SERIAL_NUMBER.FORM.FIELDS.IMAGE_ID.PLACEHOLDER'|translate}}</span>
                        </mat-chip>
                    </mat-chip-list>
                    <div *ngIf="attachmentsFiles && attachmentsFiles.length>0">
                        <div fxLayout="row wrap" fxLayoutAlign="space-evenly center"
                             *ngFor="let file of attachmentsFiles;let i=index ">
                            <ng-container *ngIf="file.src">
                                <img (click)="showImageModal(file.src)"
                                     class="cursor-pointer zone-file_img img-product_folder"
                                     alt="Image of the spare part"
                                     [src]="file.src">
                            </ng-container>
                            <h4 class="text-bold ml-44">{{file.name}}</h4>
                            <p class="ml-44"> {{file.uploadDate | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate) : (currentLanguage$ | async)}} </p>
                            <mat-icon color="warn" class="cursor-pointer"
                                      (click)="removeFileFromAttachment(file.id)">
                                delete_forever
                            </mat-icon>
                        </div>
                    </div>
                </div>
            </fieldset>


        </form>
    </mat-dialog-content>

    <mat-dialog-actions class="mt-16">
        <div class="w-100-p" fxLayout="row" fxLayoutAlign="end end">
            <button mat-button (click)="onCloseModal()" class="aster-modal-btn_cancel" type="reset">
                {{'BUTTON.CLOSE' | translate}}
            </button>
            <button type="submit" mat-stroked-button (click)="validateForm()"
                    [disabled]="form.invalid"
                    [ngClass]="form.invalid? 'aster_btn_disabled' : 'aster_btn'">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>
    </mat-dialog-actions>
</div>
