import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {Site} from '../../../../../models/site.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkflowService} from '../../../../../shared/services/workflow.service';
import {FolderSubjectService} from '../../../folder-subject.service';
import {AddressType} from '../../../../../models/enums/addressType.enum';
import {CustomerUtils} from '../../../../../shared/utils/customer-utils';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
  selector: 'app-choosing-swap-location',
  templateUrl: './choosing-swap-location.component.html',
  styleUrls: ['./choosing-swap-location.component.css']
})
export class ChoosingSwapLocationComponent implements OnInit, OnDestroy {

    folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    folderId: string;
    zipCode: string;
    siteSelected: Site;
    private folderSubscription: Subscription;

    constructor(private router: Router,
                private  route: ActivatedRoute,
                private  workflowService: WorkflowService,
                private folderSubjectService: FolderSubjectService) {
    }

    ngOnInit(): void {
        this.folderSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
        });
        this.zipCode = this.getZipCode();
    }

    getZipCode(): string {
        if (!!this.folder && !!this.folder.customer && !!this.folder.customer.addresses) {
            let address = CustomerUtils.getCustomerAddressByType(this.folder.customer.addresses, AddressType.DEFAULT);
            this.zipCode = !!address ? address.zipCode : '';
        }
        return this.zipCode;
    }

    selectSiteEvent(site: Site): void {
        this.siteSelected = site;
    }

    completeTask(): void {
        if (!this.siteSelected) {
            return;
        }
        let site;
        site = {
            name : this.siteSelected.name,
            code: this.siteSelected.code,
            address: this.siteSelected.address
        };
        let task = {
            'site': JSON.stringify(site)
        };
        this.inputMap.emit(task);
    }


    ngOnDestroy(): void {
        this.folderSubscription.unsubscribe();
    }

}
