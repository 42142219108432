import {Action} from '@ngrx/store';
import {ConfigurationReferential} from '../../models/configurationReferential.model';

export enum ConfigurationActionType {
    LOAD_CONFIGURATION = '[CONFIGURATION] Load Configuration',
    SUCCESS_LOAD_CONFIGURATION = '[CONFIGURATION] Success Load Configuration'
}

export class LoadConfiguration implements Action {
    readonly type = ConfigurationActionType.LOAD_CONFIGURATION;

    constructor(public cached: boolean) {
    }
}

export class SuccessLoadConfiguration implements Action {
    readonly type = ConfigurationActionType.SUCCESS_LOAD_CONFIGURATION;

    constructor(public configurationMap: Map<string, ConfigurationReferential[]>) {
    }
}

export type ConfigurationActions = LoadConfiguration | SuccessLoadConfiguration;
