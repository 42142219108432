import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {CodeLabel} from '../../models/element.model';
import {GLOBAL} from 'app/app-config';
import {Configuration} from '../../models/configuration.value';

@Injectable({
    providedIn: 'root'
})
export class ConfigV2Service {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api/configs/v2`;

    protected constructor(private httpClient: HttpClient) {
    }

    findLocalizedValuesOf(configCodes: string[]): Observable<CodeLabel[]> {
        return this.httpClient.get<CodeLabel[]>(`${this.path}/localized`, {params: {configCodes: configCodes}})
            .pipe(take(1));
    }

    findAllValues(configCode: string): Observable<Configuration[]> {
        return this.httpClient.get<Configuration[]>(`${this.path}/${configCode}`)
            .pipe(take(1));
    }
    update(configCode: string, config: Configuration): Observable<Configuration> {
        return this.httpClient.put<Configuration>(`${this.path}/${configCode}`, config);
    }
}
