import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Folder, IFolderUpdateRequest} from '../../../../../../models/folder.model';
import {Quotation} from '../../../../../../models/quotation.model';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {MatDialog} from '@angular/material/dialog';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {currency, currentLanguage} from '../../../../../../store/organization/organization.selectors';
import {Observable} from 'rxjs';
import {EditQuotationModalComponent} from './edit-quotation-modal/edit-quotation-modal.component';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {TypeOfServiceEnum} from '../../../../../../models/typeOfService.model';
import {TypeOfBenefitService} from '../../../../../../shared/services/typeOfBenefit.service';
import {TaskVariables} from '../../../task.variables';
import {cloneDeep} from 'lodash';
import {SparePartService} from '../../../../../../shared/services/spare-part.service';
import {EditQuotationData} from './edit-quotation-modal/editQuotationModal.model';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-transformation-into-client-quotation',
    templateUrl: './transformation-into-client-quotation.component.html',
    styleUrls: ['./transformation-into-client-quotation.component.scss']
})
export class TransformationIntoClientQuotationComponent extends Unsubscriber implements OnInit {

    @ViewChild('dropzone') dropzone: ElementRef;
    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;
    folder: Folder;
    quotation: Quotation;
    currency: string;
    attachments = new Array<any>();
    quotationId: string;

    private typeOfBenefits = [];
    displayedColumns: string[] = ['type', 'number', 'totalPriceHT', 'totalPrice'];

    quotationSummary = new MatTableDataSource<any>();
    currentLanguage$: Observable<string>;
    inProgress = false;

    constructor(private translateService: TranslateService,
                private folderService: FolderService,
                private store$: Store<AppState>,
                public folderSubjectService: FolderSubjectService,
                private typeOfBenefitService: TypeOfBenefitService,
                private sparePartService: SparePartService,
                private matDialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        this.getCurrentLanguage();
        this.initCurrency();
        this.initQuotationData();
    }

    private initCurrency() {
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => {
            this.currency = currency;
        });
    }

    private getCurrentLanguage() {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
    }

    private initQuotationData() {
        this.getQuotation();
        if (this.quotation) {
            this.initDataTableAndTypeOfBenefit();
        }

    }

    private getQuotation() {
        this.quotationId = this.variablesTask[TaskVariables.quotationId];
        if (!!this.quotationId) {
            this.quotation = cloneDeep(this.folder.quotations
                .find(quotation => quotation.id === this.quotationId));
        }
    }

    private initDataTableAndTypeOfBenefit() {
        const sparePart = {
            code: TypeOfServiceEnum.SPARE_PART,
            label: this.translateService.instant('MODAL.QUOTATION.TYPE.SPARE_PART')
        };
        this.typeOfBenefits.push(sparePart);
        this.typeOfBenefitService.all().subscribe(types => {
            types.forEach(value => {
                this.typeOfBenefits.push(value);
            });
            this.initQuotationDataTable();
        });
    }

    private initQuotationDataTable(): void {
        const dataSource = [];
        let number = 0;
        let totalTTC = 0;
        let totalHT = 0;
        for (const typeOfBenefit of this.typeOfBenefits) {
            const type = typeOfBenefit.code;
            number = this.getNumberOfQuotationLineByType(type);
            totalTTC = this.getTotalPriceByType(type);
            totalHT = this.getTotalPriceHTByType(type);
            if (number !== 0) {
                dataSource.push({
                    type: type,
                    number: number,
                    totalHT: totalHT,
                    totalTTC: totalTTC
                });
            }
        }
        this.quotationSummary = new MatTableDataSource<any>(dataSource);
    }

    getTotalPriceHTByType(sparePartsCode: string): any {
        return this.quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === sparePartsCode ? total + line.totalPriceHTAfterDiscount?.value : total, 0);
    }

    getNumberOfQuotationLineByType(type): number {
        return this.quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === type ? total + 1 : total, 0);
    }

    getTotalPriceByType(type): number {
        return this.quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === type ? total + line?.totalLinePrice?.value : total, 0);
    }

    getAttachments(attachments: any[]) {
        this.attachments = attachments;
    }

    stopPropagation(): boolean {
        return this.inProgress || this.quotation.quotationLines.length <= 0 || this.quotation?.totalPrice?.value < 0;
    }

    completeTask() {
        this.inProgress = true;
        this.store$.dispatch(new StartLoading());
        this.folderService.updateQuotationToFolder(this.folder.id, this.attachments, this.quotation).subscribe(value => {
            this.inputMap.emit({});
        }, () => {
            this.store$.dispatch(new StopLoading());
        });
    }

    openTransformationModal() {
        const waitQuotationData: EditQuotationData = {
            initialQuotation: this.quotation,
            folderId: this.folder.id,
        };
        const dialogRef = this.matDialog.open(EditQuotationModalComponent, {
            height: '90vh', width: '100vh', minWidth: '80%', data: waitQuotationData
        });
        dialogRef.afterClosed().subscribe(data => {
                if (!!data) {
                    this.quotation.quotationLines = this.formatPrices(data);
                    this.updateQuotation();
                }
            }
        );
    }

    private formatPrices(data) {
        data.quotationLines.forEach(line => {
            line.priceAmount = {
                value: this.sparePartService.normalizeFloat(line.priceAmount.value),
                currency: this.currency
            };
            line.totalLinePrice = {
                value: this.sparePartService.normalizeFloat(line.totalLinePrice.value),
                currency: this.currency
            };
        });
        return data.quotationLines;
    }

    updateQuotation(): void {
        this.store$.dispatch(new StartLoading());
        const folderUpdateRequest: IFolderUpdateRequest = {
            quotation: this.quotation
        };
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
            this.folderSubjectService.folderLoaded(folder);
            this.folder = folder;
            this.getQuotation();
            this.initQuotationDataTable();

            this.store$.dispatch(new StopLoading());
        }).catch(reason => {
            console.log('ERROR Update Folder with id :' + this.folder.id);
            this.store$.dispatch(new StopLoading());
        });
    }


}
