export class AttachmentType {
    id: string;
    code?: string;
    label?: string;
    locale?: string;
    sharedWith?: string[];
}

export enum AttachmentTypeSharedWithEnum {
    CUSTOMER = 'CUSTOMER',
    SUPPLIER = 'SUPPLIER',
    REPAIRER = 'REPAIRER',
    INSURANCE = 'INSURANCE'
}
