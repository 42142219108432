import {SymptomSearchComponent} from './workflowProcess/symptoms/symptom-search/symptom-search.component';
import {SurveyComponent} from './workflowProcess/survey/survey.component';
import {PanneConfirmedComponent} from './workflowProcess/panne-confirmed/panne-confirmed.component';
import {EditInterventionAddressComponent} from './workflowProcess/edit-intervention-address/edit-intervention-address.component';
import {WishesAppointmentComponent} from './workflowProcess/wishes-appointment/wishes-appointment.component';
import {ConfirmAppointmentComponent} from './workflowProcess/confirm-appointment/confirm-appointment.component';
import {ScheduledWaitingInterventionComponent} from './workflowProcess/scheduled-waiting-intervention/scheduled-waiting-intervention.component';
import {ProgressRepairComponent} from './workflowProcess/progress-repair/progress-repair.component';
import {FolderFinishedComponent} from './workflowProcess/folder-closed/folder-finished/folder-finished.component';
import {DepositProductSiteComponent} from './workflowProcess/deposit-product-site/deposit-product-site.component';
import {WaitingDepositSiteComponent} from './workflowProcess/waiting-deposit-site/waiting-deposit-site.component';
import {ParcelToSendComponent} from './workflowProcess/shipment/parcel-to-send/parcel-to-send.component';
import {StoreDepositComponent} from './workflowProcess/store-deposit/store-deposit.component';
import {ShipmentInProgressComponent} from './workflowProcess/shipment/shipment-in-progress/shipment-in-progress.component';
import {ChooseProductShipmentModeComponent} from './service-center-repair-process/choose-product-shipment-mode/choose-product-shipment-mode.component';
import {AddCustomerDetailsComponent} from './service-center-repair-process/add-customer-details/add-customer-details.component';
import {StorePaymentComponent} from './workflowProcess/store-payment/store-payment.component';
import {WaitQuotationComponent} from './service-center-repair-process/wait-quotation/wait-quotation.component';

import {RepairerAndCarrierConfirmationComponent} from './workflowProcess/repairer-and-carrier-confirmation/repairer-and-carrier-confirmation.component';
import {WaitingProductRetrievalComponent} from './service-center-repair-process/waiting-product-retrieval/waiting-product-retrieval.component';
import {
    WaitingClientDecisionUnrepairableProductComponent
} from './service-center-repair-process/waiting-client-decision-unrepairable-product/waiting-client-decision-unrepairable-product.component';
import {QuotationAcceptationComponent} from './service-center-repair-process/quotation-acceptation/quotation-acceptation.component';
import {HandleReservationAnomalyComponent} from './workflowProcess/handle-reservation-anomaly/handle-reservation-anomaly.component';
import {NotPlanedInterventionComponent} from './workflowProcess/reparation/not-planed-intervention/not-planed-intervention.component';
import {PlannedInterventionComponent} from './workflowProcess/reparation/planned-intervention/planned-intervention.component';

import {WaitingClientSwapComponent} from './swap-process/waiting-client-swap/waiting-client-swap.component';
import {WaitingSupplierConfirmationComponent} from './traitement-produit-hs/waiting-supplier-confirmation/waiting-supplier-confirmation.component';
import {WaitingForHsProductDestructionComponent} from './traitement-produit-hs/waiting-for-hs-product-destruction/waiting-for-hs-product-destruction.component';
import {WaitingCreditSupplierComponent} from './traitement-produit-hs/waiting-credit-supplier/waiting-credit-supplier.component';
import {
    WaitingForHsProductDestructionByCustomerComponent
} from './swap-process/waiting-for-hs-product-destruction-by-customer/waiting-for-hs-product-destruction-by-customer.component';
import {WaitingPaymentComponent} from './workflowProcess/waiting-payment/waiting-payment.component';
import {ReparationQuotationAcceptationComponent} from './workflowProcess/reparation/reparation-quotation-acceptation/reparation-quotation-acceptation.component';
import {ReparationInProgressComponent} from './workflowProcess/reparation/reparation-in-progress/reparation-in-progress.component';
import {RepairInProgressComponent} from './service-center-repair-process/repair-in-progress/repair-in-progress.component';
import {RepairerToBeConfirmedComponent} from './workflowProcess/repairer-to-be-confirmed/repairer-to-be-confirmed.component';
import {AddReturnInfoComponent} from './service-center-repair-process/add-return-info/add-return-info.component';
import {WaitingProductReceptionComponent} from './workflowProcess/service-center-reparation-repairer/waiting-product-reception/waiting-product-reception.component';
import {CheckProductConformityComponent} from './workflowProcess/service-center-reparation-repairer/check-product-conformity/check-product-conformity.component';
import {ProductDiagnosticComponent} from './workflowProcess/service-center-reparation-repairer/product-diagnostic/product-diagnostic.component';
import {PrepareSparePartsOrderComponent} from './workflowProcess/service-center-reparation-repairer/prepare-spare-parts-order/prepare-spare-parts-order.component';
import {SparePartsOrderInProgressComponent} from './workflowProcess/service-center-reparation-repairer/spare-parts-order-in-progress/spare-parts-order-in-progress.component';
import {
    CheckSparePartsBeforeReparationComponent
} from './workflowProcess/service-center-reparation-repairer/check-spare-parts-before-reparation/check-spare-parts-before-reparation.component';
import {InterventionReportComponent} from './workflowProcess/reparation/intervention-report/intervention-report.component';
import {SuggestPieceAndRepairTutoComponent} from './auto-repair-process/suggest-piece-and-repair-tuto/suggest-piece-and-repair-tuto.component';
import {WaitingAssistanceToCallComponent} from './auto-repair-process/waiting-assistance-to-call/waiting-assistance-to-call.component';
import {CustomerRepairInProgressComponent} from './auto-repair-process/customer-repair-in-progress/customer-repair-in-progress.component';
import {ChoosingSwapLocationComponent} from './swap-process/choosing-swap-location/choosing-swap-location.component';
import {IntroduceCampaignComponent} from './product-recall-process/introduce-compaign/introduce-campaign.component';
import {WaitingToInterveneComponent} from './auto-repair-process/waiting-to-intervene/waiting-to-intervene.component';
import {AddDefaultAddressComponent} from './workflowProcess/add-default-address/add-default-address.component';
import {ValidateCustomerAddressComponent} from './auto-repair-process/validate-customer-address/validate-customer-address.component';
import {ProductCheckCustomerComponent} from './product-recall-process/product-check-customer/product-check-customer.component';
import {NewContactOfProductComponent} from './product-recall-process/new-contact-of-product/new-contact-of-product.component';
import {CarrierToBeConfirmedComponent} from './auto-repair-process/carrier-to-be-confirmed/carrier-to-be-confirmed.component';
import {ProductQuantityCheckComponent} from './swap-process/product-quantity-check/product-quantity-check.component';
import {ReparationConfirmationComponent} from './workflowProcess/reparation-confirmation/reparation-confirmation.component';
import {WaitingToRepairComponent} from './workflowProcess/service-center-reparation-repairer/waiting-to-repair/waiting-to-repair.component';
import {ChooseOperatingModeComponent} from './workflowProcess/choose-operating-mode/choose-operating-mode.component';
import {CustomerDecisionComponent} from './workflowProcess/customer-decision/customer-decision.component';
import {ChooseSparePartsToOrderComponent} from './workflowProcess/spare-parts-order/choose-spare-parts-to-order/choose-spare-parts-to-order.component';
import {ClientValidateShipmentCustomerAddressComponent} from './workflowProcess/client-validate-shipment-customer-address/client-validate-shipment-customer-address.component';
import {SparePartsOrderWaitingValidationComponent} from './spare-parts/spare-parts-order-to-be-confirmed-component/spare-parts-order-waiting-validation.component';
import {SparePartsOrderValidatedComponent} from './spare-parts/spare-parts-order-validated/spare-parts-order-validated.component';
import {SparePartsOrderWaitingPreparationComponent} from './spare-parts/spare-parts-order-waiting-preparation/spare-parts-order-waiting-preparation.component';
import {RepairInProgressByCustomerComponent} from './visio-process/repair-in-progress-by-customer/repair-in-progress-by-customer.component';
import {BookAppointmentComponent} from './visio-process/book-appointment/book-appointment.component';
import {WaitingVisioComponent} from './visio-process/waiting-visio/waiting-visio.component';
import {CustomerRepairSavInProgressComponent} from './auto-repair-process/customer-repair-sav-in-progress/customer-repair-sav-in-progress.component';
import {DisplaySparePartsAndTutorialsComponent} from './auto-repair-process/display-spare-parts-and-tutorials/display-spare-parts-and-tutorials.component';
import {RefundProtocolSentComponent} from './refund-product-recall/refund-protocol-sent/refund-protocol-sent.component';
import {RefundProtocolToReturnToClientComponent} from './refund-product-recall/refund-protocol-to-return-to-client/refund-protocol-to-return-to-client.component';
import {RefundProtocolReturnedToClientComponent} from './refund-product-recall/refund-protocol-returned-to-client/refund-protocol-returned-to-client.component';
import {WaitingRefundComponent} from './refund-product-recall/waiting-refund/waiting-refund.component';
import {ChooseRepairPackageComponent} from './repair-package/choose-repair-package/choose-repair-package.component';
import {RefundToCustomerComponent} from './workflowProcess/refund-to-customer/refund-to-customer.component';
import {RefundCustomerComponent} from './swap-process/refund-customer/refund-customer.component';
import {AcceptQuotationComponent} from './workflowProcess/accept-quotation/accept-quotation.component';
import {WaitingSwapInStoreComponent} from './swap-process/waiting-swap-in-store/waiting-swap-in-store.component';
import {OrderByOtherPartnerInProgressComponent} from './workflowProcess/order-by-other-partner-in-progress/order-by-other-partner-in-progress.component';
import {ProductAtStoreComponent} from './service-center-repair-process/product-at-store/product-at-store.component';
import {ManuelFolderProcessComponent} from './workflowProcess/manuel-folder-process/manuel-folder-process.component';
import {SwapConfirmationComponent} from './service-center-repair-process/swap-confirmation/swap-confirmation.component';
import {ValidateSwapInStoreComponent} from './swap-process/validate-swap-in-store/validate-swap-in-store.component';
import {ProductReceptionAndControlComponent} from './service-center-repair-process/repaired-product-control/product-reception-and-control.component';
import {ScheduleExpertiseAtHomeComponent} from './diag-expert/schedule-expertise-at-home/schedule-expertise-at-home.component';
import {DiagExpertDecisionComponent} from './diag-expert/diag-expert-decision/diag-expert-decision.component';
import {ExpertiseCustomerCallBackComponent} from './diag-expert/expertise-customer-call-back/expertise-customer-call-back.component';
import {DiagExpertDecisionConfirmationComponent} from './diag-expert/diag-expert-decision-confirmation/diag-expert-decision-confirmation.component';
import {ConfirmCarrierAndRepairerComponent} from './service-center-repair-process/confirm-carrier-and-repairer/confirm-carrier-and-repairer.component';
import {ValidateSupplierDelayComponent} from './validate-agreement-delay/validate-supplier-delay/validate-supplier-delay.component';
import {ManualClaimProcessingComponent} from './workflowProcess/manual-claim-processing/manual-claim-processing.component';
import {WaitingReparationAgreementComponent} from './service-center-repair-process/waiting-reparation-agreement/waiting-reparation-agreement.component';
import {ReparationAgreementDelayComponent} from './service-center-repair-process/reparation-agreement-delay/reparation-agreement-delay.component';
import {DiagnosisInProgressComponent} from './service-center-repair-process/diagnosis-in-progress/diagnosis-in-progress.component';
import {WaitingPickupComponent} from './workflowProcess/shipment/waiting-pickup/waiting-pickup.component';
import {WaitingOnAsyncProcessComponent} from './waiting-on-async-process/waiting-on-async-process.component';
import {RefundRefusalValidationComponent} from './traitement-produit-hs/refund-refusal-validation/refund-refusal-validation.component';
import {SwapConfirmationHomeRepairComponent} from './home-repair/swap-confirmation-home-repair/swap-confirmation-home-repair.component';
import {WaitingSparePartsReceptionComponent} from './spare-parts/waiting-spare-parts-reception/waiting-spare-parts-reception.component';
import {
    WaitingSparePartReceptionItinerantComponent
} from './workflowProcess/internal-repair/waiting-spare-part-reception/waiting-spare-part-reception-itinerant/waiting-spare-part-reception-itinerant.component';
import {WaitingClientDecisionProductWorksComponent} from './service-center-repair-process/waiting-client-decision-product-works/waiting-client-decision-product-works.component';
import {WaitingSparePartRetrievalComponent} from './spare-parts/waiting-spare-part-retrieval/waiting-spare-part-retrieval.component';
import {CheckAndDiagnostic2Component} from './workflowProcess/internal-repair-v3/check-and-diagnostic2/check-and-diagnostic2.component';
import {InputRequirement2Component} from './workflowProcess/internal-repair-v3/input-requirement2/input-requirement2.component';
import {InternalReparationInProgress2Component} from './workflowProcess/internal-repair-v3/internal-reparation-in-progress2/internal-reparation-in-progress2.component';
import {WaitingSparePartReception2Component} from './workflowProcess/internal-repair-v3/waiting-spare-part-reception2/waiting-spare-part-reception2.component';
import {
    WaitingProductRetrievalQuotationRefused2Component
} from './workflowProcess/internal-repair-v3/waiting-product-retrieval-quotation-refused2/waiting-product-retrieval-quotation-refused2.component';
import {WaitingForReparationPermission2Component} from './workflowProcess/internal-repair-v3/waiting-for-reparation-permission2/waiting-for-reparation-permission2.component';
import {
    WaitingCustomerDecisionNonRepairable2Component
} from './workflowProcess/internal-repair-v3/waiting-customer-decision-non-repairable2/waiting-customer-decision-non-repairable2.component';
import {
    WaitingNotRepairedProductRetrieval2Component
} from './workflowProcess/internal-repair-v3/waiting-not-repaired-product-retrieval2/waiting-not-repaired-product-retrieval2.component';
import {
    InternalReparationWaitingProductRetrieval2Component
} from './workflowProcess/internal-repair-v3/internal-reparation-waiting-product-retrieval2/internal-reparation-waiting-product-retrieval2.component';
import {DiagnosticInProgressComponent} from './home-repair/diagnostic-in-progress/diagnostic-in-progress.component';
import {DiagnosticDelayComponent} from './service-center-repair-process/diagnostic-delay/diagnostic-delay.component';
import {ReparationDelayComponent} from './service-center-repair-process/reparation-delay/reparation-delay.component';
import {CheckAndConfirmOrderComponent} from './workflowProcess/internal-repair-v3/check-and-confirm-order/check-and-confirm-order.component';
import {SwapConfirmationInternalRepairComponent} from './workflowProcess/internal-repair-v3/swap-confirmation-internal-repair/swap-confirmation-internal-repair.component';
import {SwapConfirmationSparePartComponent} from './workflowProcess/spare-parts-order/swap-confirmation-spare-part/swap-confirmation-spare-part.component';
import {CheckSparePartsComponent} from './workflowProcess/spare-parts-order/check-spare-parts/check-spare-parts.component';
import {QuotationAcceptationClientComponent} from './workflowProcess/quotation-v2/quotation-acceptation-client/quotation-acceptation-client.component';
import {QuotationAcceptationDistributorComponent} from './workflowProcess/quotation-v2/quotation-acceptation-distributor/quotation-acceptation-distributor.component';
import {TransformationIntoClientQuotationComponent} from './workflowProcess/quotation-v2/transformation-into-client-quotation/transformation-into-client-quotation.component';
import {WaitingForRepairerQuotationComponent} from './workflowProcess/quotation-v2/waiting-for-repairer-quotation/waiting-for-repairer-quotation.component';
import {ProlongedDiagnosisComponent} from './workflowProcess/prolonged-diagnosis/prolonged-diagnosis.component';
import {ManualCreditAgreementRequestComponent} from './traitement-produit-hs/manual-credit-agreement-request/manual-credit-agreement-request.component';
import {ConfirmRepairerHomeRepairComponent} from './home-repair/confirm-repairer-home-repair/confirm-repairer-home-repair.component';
import {OldWaitingReparationAgreementComponent} from './service-center-repair-process/old-waiting-reparation-agreement/old-waiting-reparation-agreement.component';
import {ValidateRefusReparationByStoreComponent} from './service-center-repair-process/validate_refus_reparation_by_store/validate-refus-reparation-by-store.component';
import {
    WaitingCustomerDecisionOutOfWarrantyComponent
} from './service-center-repair-process/waiting-customer-decision-out-of-warranty/waiting-customer-decision-out-of-warranty.component';
export const GENERIC_COMPONENTS_TASKS = {
    // workflow_folder process
    'SYMPTOM_SELECTED': SymptomSearchComponent,
    'SURVEY': SurveyComponent,
    'PANNE_CONFIRMED': PanneConfirmedComponent,
    'PRODUCT_QUANTITY_CHECK': ProductQuantityCheckComponent,

    // Prolonged Diagnosis process
    'PROLONGED_DIAGNOSIS': ProlongedDiagnosisComponent,

    'CUSTOMER_DID_NOT_RESPOND': IntroduceCampaignComponent,
    'PRODUCT_CHECK_AT_CUSTOMER': ProductCheckCustomerComponent,
    'NEW_CONTACT_OF_PRODUCT': NewContactOfProductComponent,
    'CUSTOMER_DECISION': CustomerDecisionComponent,
    'CHOOSE_OPERATING_MODE': ChooseOperatingModeComponent,

    // home_repair_workflow process
    'EDIT_INTERVENTION_ADDRESS': EditInterventionAddressComponent,
    'CONFIRM_REPAIRER_HOME_REPAIR': ConfirmRepairerHomeRepairComponent,
    'WISHES_APPOINTMENT': WishesAppointmentComponent, // validate and next Step
    'CONFIRM_APPOINTMENT': ConfirmAppointmentComponent,
    'WAITING_FOR_INTERVENTION': ScheduledWaitingInterventionComponent,
    'PROGRESS_REPAIR': ProgressRepairComponent,
    'WAITING_PAYMENT': WaitingPaymentComponent,
    'REPAIRER_TO_BE_CONFIRMED': RepairerToBeConfirmedComponent,
    'REPARATION_CONFIRMATION': ReparationConfirmationComponent,
    'ACCEPT_QUOTATION': AcceptQuotationComponent,
    'ACCEPT_QUOTATION_NO_CALENDAR': AcceptQuotationComponent,
    'SWAP_CONFIRMATION_HOME_REPAIR': SwapConfirmationHomeRepairComponent,
    'DIAGNOSIS_IN_PROGRESS_HOME_REPAIR': DiagnosticInProgressComponent,

    // site_deposit_workflow process
    'DEPOSIT_PRODUCT_SITE': DepositProductSiteComponent,
    'WAITING_DEPOSIT_SITE': WaitingDepositSiteComponent,

    // SERVICE_CENTER_REPAIR_WF process
    'CHOOSE_PRODUCT_SHIPMENT_MODE': ChooseProductShipmentModeComponent,
    'ADD_CUSTOMER_DETAILS': AddCustomerDetailsComponent,
    'WAIT_QUOTATION': WaitQuotationComponent,
    'QUOTATION_ACCEPTATION': QuotationAcceptationComponent,
    'ADD_DEFAULT_ADDRESS': AddDefaultAddressComponent,
    'ADD_RETURN_INFO': AddReturnInfoComponent,
    'PRODUCT_AT_STORE': ProductAtStoreComponent,
    'MANUEL_FOLDER_PROCESS': ManuelFolderProcessComponent,
    'PRODUCT_RECEPTION_AND_CONTROL_AT_STORE': ProductReceptionAndControlComponent,
    'CONFIRM_CARRIER_AND_REPAIRER': ConfirmCarrierAndRepairerComponent,
    'DIAGNOSTIC_DELAY': DiagnosticDelayComponent,
    'REPARATION_DELAY': ReparationDelayComponent,

    // folder Closed
    'FOLDER_CLOSED_FINISHED': FolderFinishedComponent,
    // product destruction
    'HS_PRODUCT_DESTRUCTION': WaitingForHsProductDestructionComponent,
    // carrier_workflow process
    'PARCEL_TO_SEND': ParcelToSendComponent,
    'SHIPMENT_IN_PROGRESS': ShipmentInProgressComponent,
    'WAITING_PICKUP': WaitingPickupComponent,

    'STORE_DEPOSIT': StoreDepositComponent,
    'STORE_PAYMENT': StorePaymentComponent,

    'REPAIRER_AND_CARRIER_TO_BE_CONFIRMED': RepairerAndCarrierConfirmationComponent,
    'REPAIR_IN_PROGRESS': RepairInProgressComponent,
    'REPARATION_AGREEMENT_DELAY': ReparationAgreementDelayComponent,
    'WAITING_REPARATION_AGREEMENT': WaitingReparationAgreementComponent,
    'OLD_WAITING_REPARATION_AGREEMENT': OldWaitingReparationAgreementComponent,
    'VALIDATE_REFUS_REPARATION_BY_STORE': ValidateRefusReparationByStoreComponent,
    'WAITING_CUSTOMER_DECISION_OUT_OF_WARRANTY': WaitingCustomerDecisionOutOfWarrantyComponent,
    'WAITING_HS_PRODUCT_RETRIEVAL_AGREEMENT_REFUSED': WaitingNotRepairedProductRetrieval2Component,
    'DIAGNOSIS_IN_PROGRESS': DiagnosisInProgressComponent,
    'WAITING_PRODUCT_RETRIEVAL': WaitingProductRetrievalComponent,
    'WAITING_CLIENT_DECISION_UNREPAIRABLE_PRODUCT': WaitingClientDecisionUnrepairableProductComponent,
    'WAITING_CLIENT_DECISION_PRODUCT_WORKS': WaitingClientDecisionProductWorksComponent,
    'WAITING_CLIENT_SWAP': WaitingClientSwapComponent,
    'MANUAL_CREDIT_AGREEMENT_REQUEST': ManualCreditAgreementRequestComponent,
    'MANUAL_REPAIR_AGREEMENT_REQUEST': ManualCreditAgreementRequestComponent,
    'WAITING_SUPPLIER_CONFIRMATION': WaitingSupplierConfirmationComponent,
    'REFUND_REFUSAL_VALIDATION': RefundRefusalValidationComponent,
    'VALIDATE_AGREEMENT_DELAY': ValidateSupplierDelayComponent,
    'WAITING_FOR_HS_PRODUCT_DESTRUCTION': WaitingForHsProductDestructionComponent,
    'WAITING_FOR_HS_PRODUCT_DESTRUCTION_CONFIRMATION': WaitingForHsProductDestructionComponent,
    'WAITING_CREDIT_SUPPLIER': WaitingCreditSupplierComponent,
    'WAITING_FOR_HS_PRODUCT_DESTRUCTION_BY_CUSTOMER': WaitingForHsProductDestructionByCustomerComponent,
    'HANDLE_RESERVATION_ANOMALY': HandleReservationAnomalyComponent,
    'REFUND_CUSTOMER': RefundCustomerComponent,

    // reparation
    'NOT_PLANNED_INTERVENTION': NotPlanedInterventionComponent,
    'PLANNED_INTERVENTION': PlannedInterventionComponent,
    'INTERVENTION_IN_PROGRESS': ReparationInProgressComponent,
    'REPARATION_QUOTATION_ACCEPTATION': ReparationQuotationAcceptationComponent,
    'INTERVENTION_REPORT': InterventionReportComponent,
    'WAIT_FOR_SPARE_PART_RECEPTION_ITINERANT': WaitingSparePartReceptionItinerantComponent,

    // service center reparation repairer
    'WAITING_PRODUCT_RECEPTION': WaitingProductReceptionComponent,
    'CHECK_PRODUCT_CONFORMITY': CheckProductConformityComponent,
    'PRODUCT_DIAGNOSTIC': ProductDiagnosticComponent,
    'PREPARE_SPARE_PARTS_ORDER': PrepareSparePartsOrderComponent,
    'SPARE_PARTS_ORDER_IN_PROGRESS': SparePartsOrderInProgressComponent,
    'CHECK_SPARE_PARTS_NEED': CheckSparePartsBeforeReparationComponent,
    'CHOOSING_SWAP_LOCATION': ChoosingSwapLocationComponent,
    'WAITING_TO_REPAIR': WaitingToRepairComponent,

    // Auto réparation
    'SUGGEST_PIECE_AND_REPAIR_TUTO': SuggestPieceAndRepairTutoComponent,
    'WAITING_ASSISTANCE_TO_CALL': WaitingAssistanceToCallComponent,
    'VALIDATE_CUSTOMER_ADDRESS': ValidateCustomerAddressComponent,
    'CUSTOMER_REPAIR_IN_PROGRESS': CustomerRepairInProgressComponent,
    'WAITING_TO_INTERVENE': WaitingToInterveneComponent,
    'CARRIER_TO_BE_CONFIRMED': CarrierToBeConfirmedComponent,

    // commande pièce client
    'CHOOSE_SPARE_PART_TO_ORDER': ChooseSparePartsToOrderComponent,
    'CHECK_ORDER_SPARE_PART_ORDER': CheckSparePartsComponent,
    'VALIDATE_SHIPMENT_ADDRESS': ClientValidateShipmentCustomerAddressComponent,

    'SPARE_PARTS_ORDER_WAITING_VALIDATION': SparePartsOrderWaitingValidationComponent,
    'SPARE_PARTS_ORDER_VALIDATED': SparePartsOrderValidatedComponent,
    'SPARE_PARTS_ORDER_WAITING_PREPARATION': SparePartsOrderWaitingPreparationComponent,
    'WAITING_SPARE_PART_RECEPTION': WaitingSparePartsReceptionComponent,
    'WAITING_SPARE_PART_RETRIEVAL': WaitingSparePartRetrievalComponent,
    'SWAP_CONFIRMATION_SPARE_PART': SwapConfirmationSparePartComponent,
    // Visio
    'REPAIR_IN_PROGRESS_BY_CUSTOMER': RepairInProgressByCustomerComponent,
    'BOOK_APPOINTMENT': BookAppointmentComponent,
    'WAITING_VISIO_QUALIFICATION': WaitingVisioComponent,
    'WAITING_VISIO_REPAIR': WaitingVisioComponent,
    'SERVICE_QUOTATION_ACCEPTATION': QuotationAcceptationComponent,

    // auto repair workflow sav
    'WAITING_AUTO_REPAIR': CustomerRepairSavInProgressComponent,
    'CHOOSE_NEXT_SOLUTION': ChooseOperatingModeComponent,
    'SURVEY_AUTO_REPAIR': SurveyComponent,
    'PROPOSE_SPARE_PARTS_AND_TUTO': DisplaySparePartsAndTutorialsComponent,

    // refund client product recall
    'REFUND_PROTOCOL_SENT': RefundProtocolSentComponent,
    'REFUND_PROTOCOL_TO_RETURN_TO_CLIENT': RefundProtocolToReturnToClientComponent,
    'REFUND_PROTOCOL_RETURNED_TO_CLIENT': RefundProtocolReturnedToClientComponent,
    'WAITING_REFUND': WaitingRefundComponent,

    // refund client SAV
    'REFUND_TO_CUSTOMER': RefundToCustomerComponent,
    'WAITING_REFUND_SAV': WaitingRefundComponent,
    // repair package WF
    'CHOOSE_REPAIR_PACKAGE': ChooseRepairPackageComponent,

    // swap wf
    'WAITING_EXCHANGE_IN_STORE': WaitingSwapInStoreComponent,
    'SWAP_CONFIRMATION': SwapConfirmationComponent,
    'VALIDATE_SWAP_IN_STORE': ValidateSwapInStoreComponent,

    // alimentary product recall wf
    // 'PRODUCT_POSSESSION_TASK_ID': AlimentaryProductPossessionCustomerComponent,
    // 'PRODUCT_PHOTO_TASK_ID': ProductPhotoTaskComponent,
    // 'GO_TO_STORE_TASK_ID': GoToStoreComponent,
    // 'IMPACT_QUESTION_TASK_ID': HealthImpactComponent,
    // 'CONTACT_CLIENT_TASK_ID' : ContactCustomerComponent,

    'ORDER_BY_OTHER_PARTNER_IN_PROGRESS': OrderByOtherPartnerInProgressComponent,

    // diag expert
    'EXPERTISE_CUSTOMER_CALLBACK': ExpertiseCustomerCallBackComponent,
    'SCHEDULE_HOME_EXPERTISE_MEETING': ScheduleExpertiseAtHomeComponent,
    'WAITING_DIAGNOSTIC_EXPERT_DECISION': DiagExpertDecisionComponent,
    'DIAG_EXPERT_DECISION_CONFIRMATION': DiagExpertDecisionConfirmationComponent,
    // manual process
    'MANUAL_CLAIM_PROCESS': ManualClaimProcessingComponent,

    'WAITING_ASYNC_PROCESS': WaitingOnAsyncProcessComponent,

    // Internal reparation v3
    'CHECK_AND_DIAGNOSTIC_2': CheckAndDiagnostic2Component,
    'INPUT_REQUIREMENT_2': InputRequirement2Component,
    'PRODUCT_UNDER_REPAIR_2': InternalReparationInProgress2Component,
    'WAITING_PRODUCT_RETRIEVAL_AFTER_REPAIR_2': InternalReparationWaitingProductRetrieval2Component,
    'WAITING_PRODUCT_RETRIEVAL_QUOTATION_REFUSED_2': WaitingProductRetrievalQuotationRefused2Component,
    'WAITING_FOR_REPARATION_PERMISSION_2': WaitingForReparationPermission2Component,
    'WAIT_FOR_SPARE_PART_RECEPTION_2': WaitingSparePartReception2Component,
    'WAITING_CUSTOMER_DECISION_NON_REPAIRABLE_2': WaitingCustomerDecisionNonRepairable2Component,
    'WAITING_HS_PRODUCT_RETRIEVAL_2': WaitingNotRepairedProductRetrieval2Component,
    'CHECK_ORDER_INTERNAL_REPAIR': CheckAndConfirmOrderComponent,
    'SWAP_CONFIRMATION_INTERNAL_REPAIR': SwapConfirmationInternalRepairComponent,
    'WAITING_PRODUCT_RETRIEVAL_CLOSED_BY_DIAG_EXPRESS': WaitingNotRepairedProductRetrieval2Component,

    // quotation v2
    'WAITING_FOR_REPAIRER_QUOTATION': WaitingForRepairerQuotationComponent,
    'QUOTATION_ACCEPTATION_DISTRIBUTOR': QuotationAcceptationDistributorComponent,
    'TRANSFORMATION_INTO_CLIENT_QUOTATION': TransformationIntoClientQuotationComponent,
    'QUOTATION_ACCEPTATION_CLIENT': QuotationAcceptationClientComponent,
};
