import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LandLinePhoneInput} from '../../../models/customer/customer.model';
import {PhoneNumberPipe} from '../../../../@fuse/pipes/phone-number.pipe';
import {SharedService} from '../../services/shared.service';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import libphonenumber from 'google-libphonenumber';
import {CountriesUtils} from '../../data/countries-utils';
import {FolderService} from '../../services/folder.service';
import {select, Store} from '@ngrx/store';
import {countryCode} from '../../../store/organization/organization.selectors';
import {AppState} from '../../../store/app.state';
import {Unsubscriber} from '../../../unsubscriber';
import {CustomerApiInfo} from '../../../models/customer/customer-api-info.model';
import PhoneNumberFormat = libphonenumber.PhoneNumberFormat;

@Component({
    selector: 'landline-phone-input-form',
    templateUrl: './land-line-phone-input-form.component.html',
    styleUrls: ['./land-line-phone-input-form.component.scss']
})
export class LandLinePhoneInputFormComponent extends Unsubscriber implements OnInit {

    @Input() landLinePhoneInput: LandLinePhoneInput;
    @Input() searchable = false;
    @Input() isRequiredFields = true;
    @Input() customerApiInfo = new CustomerApiInfo();
    @Output() search = new EventEmitter();

    phoneConfigCountry = '';
    contactForm: FormGroup;
    countryCode: string;

    constructor(private phoneNumberPipe: PhoneNumberPipe,
                private sharedService: SharedService,
                private folderService: FolderService,
                private rootFormGroup: FormGroupDirective,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.contactForm = this.rootFormGroup.control;
        this.init();
        this.anotherSubscription = this.store$.pipe(select(countryCode)).subscribe(code => this.countryCode = code);
        this.anotherSubscription = this.sharedService.getUpdateLandLineInputEvent().subscribe(data => {
            if (!data) {
                return;
            }
            if (data && !data.phoneNumber) {
                this.initTelDetailForm();
            } else {
                this.landLinePhoneInput = data;
                this.initInputTelDetailWithData();
            }
        });
    }

    private init(): void {
        this.initTelDetailForm();
        this.initInputTelDetailWithData();
        this.updatePhoneInput();
    }

    initInputTelDetailWithData(): void {
        if (!!this.landLinePhoneInput && this.landLinePhoneInput.phoneNumber && this.landLinePhoneInput.countryCode) {
            this.contactForm.get('phoneNumber')
                .setValue(this.phoneNumberPipe.getFormattedPhone(this.landLinePhoneInput.phoneNumber,
                    this.landLinePhoneInput.countryCode
                    , PhoneNumberFormat.INTERNATIONAL).phoneNumber);
        } else if (!this.landLinePhoneInput) {
            this.landLinePhoneInput = {
                phoneNumber: '',
                countryCode: ''
            };
        }
    }

    initTelDetailForm(): void {
        this.folderService.findInvalidControls(this.contactForm);
    }

    notifyInputTelFormChanged(): void {
        const isNumberValid = this.contactForm.get('phoneNumber').valid;
        this.sharedService.updateLandLineInput(isNumberValid ? this.landLinePhoneInput : null);
        this.sharedService.updateLandLineInputValidity(isNumberValid);
    }

    updatePhoneInput(event?: Event): void {
        if (!!this.landLinePhoneInput.countryCode) {
            this.phoneConfigCountry = CountriesUtils.getCountryCode(this.landLinePhoneInput.countryCode);
        } else {
            this.phoneConfigCountry = this.countryCode;
        }

        const inputPhoneNumber = this.phoneNumberPipe.getFormattedPhone(this.contactForm.value.phoneNumber,
            this.phoneConfigCountry
            , PhoneNumberFormat.E164);

        if (this.landLinePhoneInput.countryCode !== null && this.landLinePhoneInput.countryCode !== 'fr') {
            this.phoneConfigCountry = this.landLinePhoneInput.countryCode;
        } else {
            this.phoneConfigCountry = 'fr';
            this.landLinePhoneInput.countryCode = 'fr';
        }
        if (!event) {
            this.contactForm.get('phoneNumber').setValue(inputPhoneNumber.phoneNumber);
        }
        this.landLinePhoneInput.phoneNumber = inputPhoneNumber.phoneNumber;
        this.landLinePhoneInput = this.phoneNumberPipe.getFormattedPhone(this.landLinePhoneInput.phoneNumber, this.landLinePhoneInput.countryCode
            , PhoneNumberFormat.INTERNATIONAL);
        this.verifyPhoneNumberType();
        this.notifyInputTelFormChanged();
        this.searchIfAutomaticField();
    }

    isSearchable = () => this.customerApiInfo?.searchableFields?.includes('phoneNumber');

    searchIfAutomaticField(): void {
        if (this.customerApiInfo?.automaticFields?.includes('phoneNumber') &&
            this.contactForm.get('phoneNumber').valid &&
            this.landLinePhoneInput.phoneNumber) {
            this.search.emit();
        }
    }

    verifyPhoneNumberType(): void {
        if (!this.landLinePhoneInput.phoneNumber) {
            this.contactForm.get('phoneNumber').setErrors(this.isRequiredFields  ? {'incorrect': true} : null);
        } else {
            const isLandLineNumber = this.phoneNumberPipe.isLandLinePhone(this.landLinePhoneInput.phoneNumber,
                this.landLinePhoneInput.countryCode);
            if (!isLandLineNumber) {
                this.contactForm.get('phoneNumber').setErrors({'incorrect': true});
            } else {
                this.contactForm.get('phoneNumber').setErrors(null);
            }
        }
    }

    onCountryChange(country: any): void {
        this.phoneConfigCountry = country.iso2;
        this.contactForm.get('phoneNumber').setValue(null);
        this.landLinePhoneInput.countryCode = country.iso2;
        this.landLinePhoneInput.phoneNumber = '';
    }

    get phoneNumber(): any {
        return this.contactForm.get('phoneNumber');
    }

    isValidCountryCode(countryCode: string): boolean {
        if (!countryCode) {
            return false;
        }
        return CountriesUtils.isValidCountryCode(countryCode);
    }

}
