    <mat-card-content>
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>

        <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start">
            <mat-radio-group formControlName="status" class="ml-20" fxLayout="row" fxLayoutAlign="space-between center"
                             labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button class="full-width-radio" *ngFor="let button of buttons"
                                  [value]="button.status"
                                  id="{{button.status}}">
                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                        <div fxFlex>
                            <div>{{ button.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>

            <app-comment-area [commentForm]="form.get('comment')"></app-comment-area>

            <div fxLayout="row" fxLayoutAlign="end">
                <button mat-stroked-button (click)="onSubmit()"
                        type="submit"
                        [disabled]="form.invalid"
                        [ngClass]="form.invalid ? 'h-60 mt-4  aster_btn_disabled' : 'h-60 mt-4 aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>