import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';
import {PushNotificationService} from '../../../shared/services/pushNotification.service';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {PushNotification, PushNotificationUpdateRequest} from '../../../models/pushNotification/pushNotification.model';
import {PushNotificationSubjectService} from '../../../models/pushNotification/pushNotification-subject.service';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {take} from 'rxjs/operators';
import {Unsubscriber} from '../../../unsubscriber';
import {PushNotificationMessage} from '../../../models/enums/push-notification-message.enum';

@Component({
    selector: 'app-notifications-details',
    templateUrl: './notifications-details.component.html'
})
export class NotificationsDetailsComponent extends Unsubscriber implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    dataSourceNotification: any = {
        dataSource: new MatTableDataSource(),
        columnsToDisplay: ['NOTIFICATION_TYPE', 'NOTIFICATION_MESSAGE', 'NOTIFICATION_FOLDER_ID', 'ACTION', 'CHECKED'],
        page: null,
        pageSizeOptions: [10, 25, 50, 100],
    };
    notificationsSelected = [];
    notificationTypes = ['ERROR', 'INFO'];
    messages = Object.values(PushNotificationMessage);
    pageIndex = 0;
    pageSize = 10;

    filterForm: FormGroup;

    constructor(private translateService: TranslateService,
                private pushNotificationService: PushNotificationService,
                private store$: Store<AppState>,
                private router: Router,
                private pushNotificationSubjectService: PushNotificationSubjectService) {
        super();
    }

    ngOnInit(): void {
        this.initFilterForm();
        this.initNotificationsDataSource();
    }

    initFilterForm(): void {
        this.filterForm = new FormGroup({
            type: new FormControl(''),
            businessId: new FormControl(''),
            message: new FormControl(''),
        });
    }

    initNotificationsDataSource(): void {
        this.dataSourceNotification.dataSource.paginator = this.paginator;
        this.searchNotifications();
    }

    searchNotifications() {
        this.store$.dispatch(new StartLoading());
        this.pushNotificationService.getAllPushNotifications(this.pageIndex, this.pageSize, this.prepareFilters())
            .pipe(take(1))
            .subscribe(data => {
                this.dataSourceNotification.dataSource.data = data.content;
                this.dataSourceNotification.page = data;
                this.store$.dispatch(new StopLoading());
            }, err => {
                this.store$.dispatch(new StopLoading());
                console.log('ERROR' + err);
            });
    }

    onSelectionChange(filter) {
        if (!!filter) {
            this.searchNotifications();
        }
    }

    private prepareFilters(): any {
        const filters: any = {};
        Object.entries(this.filterForm.value).forEach(([key, value]) => {
            if (!!value) {
                if (key === 'message') {
                    const message = value.toString().split('.').pop();
                    filters['message'] = message === 'COMMON' ? '' : message;
                } else {
                    filters[key] = value;
                }
            }
        });
        return filters;
    }

    getNotificationKey(key: string, pushNotificationType: string): string {
        const notificationKey = 'PUSH.NOTIFICATION.' + pushNotificationType + '.' + key;
        if (this.translateService.instant(notificationKey) !== notificationKey) {
            return notificationKey;
        }
        return 'PUSH.NOTIFICATION.ERROR.COMMON';
    }

    paginate(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.searchNotifications();
    }

    goToFolder(notification: PushNotification): void {
        this.router.navigate(
            ['/folder', notification.businessId],
            {queryParams: {showComments: this.isNewCommentAdded(notification)}}
        );
    }

    isNewCommentAdded = (notification: PushNotification) => notification.type === 'INFO' && notification.extraInfo['INFO'] === 'NEW_COMMENT_ADDED';

    resetSearch(key): void {
        if (key === '') {
            this.searchNotifications();
        }
    }

    onClearSearchFormFiled(fieldName: string): void {
        this.filterForm.get(fieldName).setValue('');
        this.searchNotifications();
    }

    updateStatus(status: string, pushNotification): void {
        const pushNotificationUpdateRequest: PushNotificationUpdateRequest = {
            status: status,
        };
        if (!!pushNotificationUpdateRequest) {
            this.pushNotificationService.updatePushNotification(pushNotification.id, pushNotificationUpdateRequest).then(value => {
                this.dataSourceNotification.dataSource.data.splice(this.dataSourceNotification.dataSource.data.indexOf(pushNotification), 1);
                this.dataSourceNotification.dataSource.data.push(value);
                this.dataSourceNotification.dataSource.connect().next(this.dataSourceNotification.dataSource.data);
                this.pushNotificationSubjectService.refreshPushNotification(value);
            });
        }
    }

    updateStatusOfNotificationSelected(): void {
        const notificationsSelectedIds = this.notificationsSelected.map(p => p.id);
        this.pushNotificationService.ignoredNotificationsSelected(notificationsSelectedIds)
            .pipe(take(1))
            .subscribe(notifications => {
                notifications?.forEach(notif => {
                    this.dataSourceNotification.dataSource.data.splice(this.dataSourceNotification.dataSource.data.indexOf(notif), 1);
                    this.dataSourceNotification.dataSource.data.push(notif);
                    this.dataSourceNotification.dataSource.connect().next(this.dataSourceNotification.dataSource.data);
                    this.pushNotificationSubjectService.refreshPushNotification(notif);
                    this.notificationsSelected = [];
                });
            });
    }

    checkedRow(event, row): void {
        if (event.checked) {
            this.notificationsSelected.push(row);
        } else {
            this.notificationsSelected.splice(row, 1);
        }

    }

}
