import {FormGroupDirective} from '@angular/forms';
import {Directive, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Unsubscriber} from '../unsubscriber';
import {CustomerActionType, ResetCustomer, ResetCustomerSearchResult, UpdateCustomer, UpdateForm, UpdateFormValidity} from '../store/customer/customer.actions';
import {CustomerUtils} from '../store/customer/customer.utils';
import {withLatestFrom} from 'rxjs/operators';
import {customer, isCreationWithoutCustomer, isStockClaimCreation} from '../store/customer/customer.selectors';
import {AppState} from '../store/app.state';
import {Actions, ofType} from '@ngrx/effects';
import {CustomerType} from '../models/enums/CustomerType.enum';
import {Address, Customer} from '../models/customer/customer.model';

@Directive({
    selector: '[connectFormToStore]'
})
export class ConnectFormToStoreDirective extends Unsubscriber implements OnInit {

    constructor(private formGroupDirective: FormGroupDirective,
                private store$: Store<AppState>,
                private actions$: Actions) {
        super();
    }

    ngOnInit() {
        this.listenToFormChanges();
        this.listenToCreationWithoutCustomerChanges();
        this.listenToCreationStockClaimChanges();

        this.onUpdateFormAction();
        this.onResetCustomerAction();
    }

    private listenToFormChanges() {
        this.anotherSubscription = this.formGroupDirective.form.valueChanges
            .pipe(withLatestFrom(this.store$.select(customer)))
            .subscribe(([changes, customer]) => {
                if (CustomerUtils.hasCustomerChanged(customer, changes)) {
                    this.store$.dispatch(new UpdateCustomer(this.formGroupDirective.form.getRawValue()));
                    this.store$.dispatch(new UpdateFormValidity(this.formGroupDirective.form.valid));
                }
            });
    }

    private listenToCreationWithoutCustomerChanges() {
        this.anotherSubscription = this.store$.pipe(select(isCreationWithoutCustomer))
            .subscribe(isWithoutCustomer => {
                if (isWithoutCustomer) {
                    this.formGroupDirective.form.disable();
                    this.store$.dispatch(new ResetCustomer());
                    this.store$.dispatch(new ResetCustomerSearchResult());
                } else {
                    this.formGroupDirective.form.enable({emitEvent: false});
                    this.formGroupDirective.form.updateValueAndValidity();
                }
            });
    }

    private listenToCreationStockClaimChanges() {
        this.anotherSubscription = this.store$.pipe(select(isStockClaimCreation))
            .subscribe(isStockClaim => {
                if (isStockClaim) {
                    this.formGroupDirective.form.disable();
                    this.store$.dispatch(new ResetCustomer());
                    this.store$.dispatch(new ResetCustomerSearchResult());
                } else {
                    this.formGroupDirective.form.enable({emitEvent: false});
                }
            });
    }
    private onUpdateFormAction() {
        this.anotherSubscription = this.actions$.pipe(
            ofType(CustomerActionType.UPDATE_FORM)
        ).subscribe((action: UpdateForm) => {

            const obj = {
                ...action.customer,
                address: action.customer.addresses ? action.customer.addresses[0] : new Address()
            };

            // exclude object's null fields
            const value = Object.keys(obj)
                .filter((k) => obj[k] != null)
                .reduce((a, k) => ({...a, [k]: obj[k]}), {});

            this.formGroupDirective.form.patchValue(value);
        });
    }

    private onResetCustomerAction() {
        this.anotherSubscription = this.actions$.pipe(ofType(CustomerActionType.RESET_CUSTOMER))
            .pipe(withLatestFrom(this.store$.select(customer)))
            .subscribe(([action, customer]: [ResetCustomer, Customer]) =>
                this.formGroupDirective.form.reset(action.keepFilters ? customer : {type: CustomerType.PRIVATE_INDIVIDUAL}));
    }
}
