    <mat-card-content>
        <form [formGroup]="chooseRepairPackageForm.form" fxLayout="column" fxLayoutAlign="start"
              (ngSubmit)="onSubmit()">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <div *ngIf="!!availableRepairPackages && availableRepairPackages.length>0">
                <mat-radio-group formControlName="decision" class="ml-20" fxLayout="row" fxLayoutAlign="space-between center"
                                 labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                    <mat-radio-button class="full-width-radio" *ngFor="let repairPackage of availableRepairPackages"
                                      [value]="repairPackage.code">
                        <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div fxFlex>
                                <div>{{ repairPackage.label }}</div>
                            </div>
                        </div>
                    </mat-radio-button>
                    <mat-radio-button class="full-width-radio" [value]="'DEVIS'">
                        <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div fxFlex>
                                <div>{{ 'CHOOSE_REPAIR_PACKAGE.REPAIR_PACKAGE_REFUSED' | translate }}</div>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        [ngClass]="chooseRepairPackageForm.form.invalid?'aster_btn_disabled':'aster_btn'"
                        [disabled]="chooseRepairPackageForm.form.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>