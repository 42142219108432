import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PhoneNumberPipe} from '../../../../../../@fuse/pipes/phone-number.pipe';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GLOBAL} from '../../../../../app-config';
import libphonenumber from 'google-libphonenumber';
import {CountriesUtils} from '../../../../../shared/data/countries-utils';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {browserLanguageOriginKey} from '../../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../../unsubscriber';
import {CIVILITY_OPTIONS} from '../../../../../models/customer/customer.constants';
import {Folder} from '../../../../../models/folder.model';
import PhoneNumberFormat = libphonenumber.PhoneNumberFormat;
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'new-contact-of-product',
    templateUrl: './new-contact-of-product.component.html',
    styleUrls: ['./new-contact-of-product.component.scss']
})
export class NewContactOfProductComponent extends Unsubscriber implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Output() previewTask = new EventEmitter<any>();

    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;

    @Input() folder: Folder;

    positionStep: number;

    customerDetailForm: any = {
        form: null,
        phoneConfigCountry: '',
        placeholders: [
            {
                existCustomer: true,
                value: 'BUTTON.YES',
            },
            {
                existCustomer: false,
                value: 'BUTTON.NO',
            }
        ]
    };

    civilitySelectBoxOptions = CIVILITY_OPTIONS;
    browserLanguageOrigin: string;

    constructor(private store$: Store<AppState>,
                private phoneNumberPipe: PhoneNumberPipe) {
        super();
        this.positionStep = 1;
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(browserLanguageOriginKey))
            .subscribe(browserLanguage => this.browserLanguageOrigin = browserLanguage);
        this.initCustomerDetailForm();
        this.initCustomerDetailDataWithCustomerData();
    }

    initCustomerDetailForm(): void {
        this.customerDetailForm.form = new FormGroup({
            existNewCustomerDetails: new FormControl(null, Validators.required),
            gender: new FormControl({value: null}),
            firstName: new FormControl({value: null}),
            lastName: new FormControl({value: null}),
            phone: new FormControl({value: null}),
            email: new FormControl({value: null}, [Validators.pattern(GLOBAL.validationPatterns.email)]),
        });
    }

    get phoneNumber() {
        return this.customerDetailForm.form.get('phone');
    }

    findInvalidControls(formGroup: FormGroup): void {
        Object.values(formGroup.controls).forEach(control => {
            control.markAsTouched();
            if (control['controls']) {
                control['controls'].forEach(c => this.findInvalidControls(c));
            }
        });
    }

    initCustomerDetailDataWithCustomerData(): void {
        this.customerDetailForm.form.get(['gender']).setValue(null);
        this.customerDetailForm.form.get(['firstName']).setValue(null);
        this.customerDetailForm.form.get(['lastName']).setValue(null);
        this.customerDetailForm.form.get(['phone']).setValue(null);
        this.customerDetailForm.form.get(['email']).setValue(null);
        this.updatePhoneInput(null);
    }

    onSubmit(): void {
        if (this.customerDetailForm.form.value.existNewCustomerDetails) {
            const phoneNumber = this.phoneNumberPipe.transform(this.customerDetailForm.form.value.phone, this.customerDetailForm.phoneConfigCountry, PhoneNumberFormat.E164);
            let newCustomerOfProduct = {
                gender: this.customerDetailForm.form.value.gender ? this.customerDetailForm.form.value.gender : '--',
                firstName: this.customerDetailForm.form.value.firstName ? this.customerDetailForm.form.value.firstName : '--',
                lastName: this.customerDetailForm.form.value.lastName ? this.customerDetailForm.form.value.lastName : '--',
                email: this.customerDetailForm.form.value.email ? this.customerDetailForm.form.value.email : '--',
                phone: phoneNumber ? phoneNumber : '--'
            };
            this.inputMap.emit({
                'existNewCustomerDetails': true,
                'agentCommentsNewContractOfProduct': newCustomerOfProduct.gender + ' ' + newCustomerOfProduct.firstName + ' ' + newCustomerOfProduct.lastName + ' / ' + newCustomerOfProduct.phone + ' / ' + newCustomerOfProduct.email
            });
        } else {
            this.inputMap.emit({
                'existNewCustomerDetails': false
            });
        }
    }

    private updatePhoneInput(customerAddress): void {
        if (!!customerAddress && !!customerAddress.countryCode) {
            this.customerDetailForm.phoneConfigCountry = CountriesUtils.getCountryCode(customerAddress.countryCode);
        } else {
            this.customerDetailForm.phoneConfigCountry = this.browserLanguageOrigin;
        }
        if (!this.customerDetailForm.form.get(['phone']).value) {
            return;
        }
        const formattedPhone = this.phoneNumberPipe.getFormattedPhone(this.customerDetailForm.form.get(['phone']).value,
            this.customerDetailForm.phoneConfigCountry, PhoneNumberFormat.E164);

        if (formattedPhone.countryCode !== null) {
            this.customerDetailForm.phoneConfigCountry = formattedPhone.countryCode;
            this.customerDetailForm.form.get(['phone']).setValue(formattedPhone.number);
        } else {
            this.customerDetailForm.form.get(['phone']).setValue(null);
        }
    }

    onCountryChange(country: any): void {
        this.customerDetailForm.phoneConfigCountry = country.iso2;
        this.customerDetailForm.form.get(['phone']).setValue(null);
    }

    hasError($event: boolean): void {
        if (!$event) {
            this.customerDetailForm.form.get(['phone']).setErrors({'incorrect': true});
        }
        this.verifyPhoneNumberType();
    }

    verifyPhoneNumberType(): void {
        const isMobileNumber = this.phoneNumberPipe.isMobileNumber(this.customerDetailForm.form.get('phone').value, this.customerDetailForm.phoneConfigCountry);
        if (!isMobileNumber) {
            this.customerDetailForm.form.get(['phone']).setErrors({'incorrect': true});
        }
    }

    getPreviewTask(): void {
        this.previewTask.emit({
            'currentTask': 'NEW_CONTACT_OF_PRODUCT',
            'previewsTask': 'PRODUCT_CHECK_AT_CUSTOMER'
        });
    }

}
