<mat-card class="mat-elevation-aster">

    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutAlign="start">
            <mat-card-title class="product-title text-bold pr-24 pt-20 pl-20">
                {{'COMPONENT.ALIMENTARY_PRODUCT_CHECK_AT_CUSTOMER.TEXT' | translate}}
            </mat-card-title>
        </div>
    </div>

    <mat-card-content>
        <form [formGroup]="interestInProductForm.form" fxLayout="column" fxLayoutAlign="start"
              fxLayoutGap="8px"
              (ngSubmit)="completeTask()">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
                <mat-radio-group formControlName="interestInProduct" class="ml-20" fxLayoutGap="10px"
                                 labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch">
                    <mat-radio-button class="full-width-radio"
                                      *ngFor="let option of interestInProductForm.placeholders"
                                      [value]="option.interestInProduct">
                        <div class="my-8" fxLayout="row" fxLayoutAlign="start center"
                             fxLayoutGap="30px">
                            <div fxFlex>
                                <div>{{ option.value | translate }}</div>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div fxLayoutAlign="end end">
                <button mat-stroked-button
                        matTooltip="{{'BUTTON.VALIDATE' | translate}}" type="submit"
                        class="h-60 mt-4 pl-24 pr-24"
                        [disabled]="interestInProductForm.form.invalid"
                        [ngClass]="interestInProductForm.form.invalid?' aster_btn_disabled':' aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>