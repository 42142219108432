
    <mat-card-content>
        <form fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <ng-container>
                <div class="mt-12">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-checkbox class="mt-24" [checked]="productRetrieved" [(ngModel)]="productRetrieved">
                            {{'BUTTON.CLIENT_RETRIEVE_NOT_REPAIRED_PRODUCT' | translate}}
                        </mat-checkbox>
                    </div>
                </div>
            </ng-container>

            <div fxLayoutAlign="end center" class="mt-24">
                <button type="submit" mat-stroked-button [disabled]="!productRetrieved"
                        [ngClass]="productRetrieved? 'aster_btn': 'aster_btn_disabled' ">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>