import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {MatTableDataSource} from '@angular/material/table';
import {SparePartService} from '../../../../../../shared/services/spare-part.service';
import {OmsService} from '../../../../../../shared/services/oms.service';
import {Folder} from '../../../../../../models/folder.model';
import {Order, OrderItem, OrderUpdateRequest} from '../../../../../../models/order.model';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {SiteType} from '../../../../../../models/enums/siteType.enum';
import {OrderStatus} from '../../../../../../models/enums/orderStatus.enum';
import {SparePartStock, StockDetails, StockRequest} from '../../../../../../models/spare-parts/stock.model';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {OrderSubjectService} from '../../../../../../shared/services/order-subject.service';
import {isNorauto} from '../../../../../../store/user/user.selectors';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {vats} from '../../../../../../store/organization/organization.selectors';
import {StockAvailability} from '../../../../../../models/enums/stockAvailability.enum';


@Component({
    selector: 'app-check-and-confirm-order',
    templateUrl: './check-and-confirm-order.component.html',
    styleUrls: ['./check-and-confirm-order.component.scss']
})
export class CheckAndConfirmOrderComponent extends Unsubscriber implements OnInit {
    @Input() folder: Folder;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();
    isAllSelected = true;
    siteCode: string;
    displayedColumns: string[];
    order = new Order();
    dataSource: OrderItem[] = [];
    orderLines = new MatTableDataSource<OrderItem>();
    isNorauto$: Observable<boolean>;

    constructor(private sparePartService: SparePartService,
                private omsService: OmsService,
                private orderSubjectService: OrderSubjectService,
                private translateService: TranslateService,
                private datePipe: DatePipe,
                private store$: Store<AppState>) {
        super();

    }

    ngOnInit(): void {
        this.anotherSubscription = this.isNorauto$ = this.store$.pipe(select(isNorauto));
        this.displayedColumns = ['code', 'label', 'quantity', 'stock', 'action'];
        this.siteCode = this.getManagementSiteCode();
        this.loadOrderData();
    }

    loadOrderData() {
        this.anotherSubscription = this.orderSubjectService.order$.subscribe(order => {
            this.order = order;
            this.dataSource = this.order.orderItems || [];
            this.checkAvailabilityStock();
            this.refreshDataSourceTable();
        });
    }

    updateOrder() {
        this.store$.dispatch(new StartLoading());
        const orderUpdateRequest: OrderUpdateRequest = {
            orderItems: this.orderLines.data
        };
        this.anotherSubscription = this.omsService.updateOrder(this.order.id, orderUpdateRequest).subscribe((order) => {
                this.orderSubjectService.orderLoaded(this.order);
                this.store$.dispatch(new StopLoading());
            }, () => {
                this.store$.dispatch(new StopLoading());
            }
        );
    }

    hasStock(status: string): boolean {
        return status && status === StockAvailability.AVAILABLE;
    }

    displayStockStatus(status: string): string {
        if (!status || StockAvailability.UNKNOWN === status) {
            return 'TEXT.UNKNOWN';
        }

        if (status === StockAvailability.AVAILABLE) {
            return 'TEXT.AVAILABLE';
        }

        if (status === StockAvailability.NOT_AVAILABLE) {
            return 'TEXT.UNAVAILABLE';
        }
    }

    private prepareStockRequest() {
        const stockRequests: StockRequest[] = [];
        this.dataSource.forEach((orderItem) => {
            orderItem.stock = {isLoading: true};
            const stockRequest = {
                sparePartCode: orderItem.code.trim(),
                quantity: orderItem.quantityOrdered
            };
            stockRequests.push(stockRequest);
        });
        return stockRequests;
    }

    private checkAvailabilityStock() {
        const stockRequest = this.prepareStockRequest();
        this.sparePartService.getAvailabilityOfStocks(this.siteCode, stockRequest).subscribe(response => {
            this.store$.dispatch(new StopLoading());
            if (!!response) {
                if (response.availabilityStatus === StockAvailability.AVAILABLE || response.availabilityStatus === StockAvailability.UNKNOWN) {
                    this.merge(response.stocks);
                    this.refreshDataSourceTable();
                }
            } else {
                const sparePartCodes: string[] = stockRequest.map((request: StockRequest) => request.sparePartCode);
                this.checkApiStock(sparePartCodes);
            }

        });
    }
    private getDefaultStock(): SparePartStock {
        return {
            status: 'UNKNOWN',
            isLoading: false
        } as SparePartStock;
    }
    refreshDataSourceTable(): void {
        this.orderLines.data = [];
        this.orderLines = new MatTableDataSource<OrderItem>(this.dataSource);
        this.order.orderItems = this.orderLines.data;
        this.store$.dispatch(new StopLoading());
    }

    private checkApiStock(sparePartCodes) {
        this.sparePartService.getStocks(sparePartCodes, this.siteCode).subscribe(stocks => {
            this.merge(stocks);
            this.refreshDataSourceTable();
        });
    }

    private merge(stocks: SparePartStock[]): void {
        const stocksByCode = new Map(stocks.map(stock => [stock.sparePartCode, stock]));
        this.dataSource.forEach(item => {
            item.stock = stocksByCode.get(item.code) ?? this.getDefaultStock();
            item.stock.isLoading = false;
            this.computeAvailableQuantity(item, item.stock);
            (this.isStockAvailableInStore(item.stock) || this.isPicking(item.itemStatus)) ? this.setPickingSparePart(item) : this.setSparePartToOrder(item);
        });

    }

    private computeAvailableQuantity(orderItem: OrderItem, sparePartStock: SparePartStock) {
        orderItem.isAvailable = this.isSparePartAvailable(sparePartStock);
        orderItem.quantityAvailable = this.getAvailableStock(sparePartStock);
    }

    isStockAvailableInStore(sparePartStock: SparePartStock) {
        return this.isSparePartAvailable(sparePartStock) && this.getStockDetailsByCurrentSite(sparePartStock.stockDetails)?.isStore;
    }

    getStockDetailsByCurrentSite(stockDetails: StockDetails[]): StockDetails {
        return stockDetails.find(details => details.siteCode === this.getManagementSiteCode());
    }

    private isSparePartAvailable(stock: SparePartStock): boolean {
        return (stock?.status === 'AVAILABLE') || false;
    }

    private getAvailableStock(stock: SparePartStock): number {
        return stock?.totalStock || 0;
    }

    private getManagementSiteCode(): string {
        return this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE)?.code;
    }

    private setPickingSparePart(item: OrderItem) {
        item.isSelected = false;
        this.isAllSelected = false;
        if (!this.isPicking(item.itemStatus)) {
            this.updateItemStatus(item);
        }


    }

    private setSparePartToOrder(item: OrderItem) {
        item.isSelected = true;
    }

    confirmAllRows(): void {
        this.orderLines.data.forEach(orderLine => {
            orderLine.isSelected = this.isAllSelected;
            orderLine.itemStatus = this.isAllSelected ? OrderStatus.NOT_CONFIRMED : OrderStatus.PICKING;
            orderLine.quantityPacked = 0;
        });
        this.updateOrder();
    }

    updateItemStatus(orderLine: OrderItem): void {
        orderLine.itemStatus = this.getItemStatus(orderLine);
        orderLine.quantityPacked = this.computePackedQuantity(orderLine);
        this.isAllSelected = this.orderLines.data.every(item => item.isSelected);
        this.updateOrder();
    }

    private getItemStatus(orderLine: OrderItem) {
        return orderLine.isSelected ? OrderStatus.NOT_CONFIRMED : OrderStatus.PICKING;
    }

    private isPicking(status: OrderStatus) {
        return status === OrderStatus.PICKING;
    }

    private computePackedQuantity(orderLine: OrderItem) {
        return this.isPicking(orderLine.itemStatus) ? orderLine.quantityOrdered : 0;
    }

    displayOrderStatus(status: number | string) {

        if (status === OrderStatus.CONFIRMED) {
            return 'ORDER.TEXT.CONFIRMED';
        }
        if (status === OrderStatus.NOT_CONFIRMED) {
            return 'ORDER.TEXT.NOT.CONFIRMED';
        }
    }

    OnSubmit() {
        this.inputMap.emit({});
    }

    displayItemStatus(status: number | string) {
        if ((!status || 'UNKNOWN' === status)) {
            return 'TEXT.UNKNOWN';
        }
        if ( status === OrderStatus.CONFIRMED) {
            return 'ORDER.TEXT.CONFIRMED';
        }

        if ( status === OrderStatus.NOT_CONFIRMED) {
            return 'ORDER.TEXT.NOT_CONFIRMED';
        }
        if (status === OrderStatus.SHIPPED || status === OrderStatus.ORDERED) {
            return 'ORDER.TEXT.ORDERED';
        }
        if (status === OrderStatus.PICKING) {
            return 'ORDER.TEXT.PICKING';
        }

        if (status === OrderStatus.DELIVERED) {
            return 'ORDER.TEXT.DELIVERED';
        }

    }

    getStockIndicatorClass(status: string): string {
        switch (status) {
            case StockAvailability.AVAILABLE:
                return 'green-color';
            case StockAvailability.NOT_AVAILABLE:
                return 'red-color';
            default:
                return 'black-color';
        }
    }

    stockDetailsTooltip(stocks: StockDetails[], totalStock: string): string {
        let tooltipMessage = '';
        if (totalStock) {
            tooltipMessage = this.translateService.instant('TOOLTIP.STOCK.DETAILS.STOCK', {stock: totalStock});

        } else {
            const stockDetails = stocks
                .map(stock => this.translateService.instant('TOOLTIP.STOCK.DETAILS',
                    {
                        siteCode: stock?.siteCode,
                        siteLabel: stock?.siteLabel,
                        deliveryDate: this.datePipe.transform(stock?.deliveryDate, 'dd-MM-yyyy')
                    }));

            stockDetails.map(stockDetails => tooltipMessage += stockDetails + '\n');
        }
        return tooltipMessage;
    }
}
