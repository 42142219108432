<div class="page-layout blank p-24" fxLayout="column" fxLayoutAlign="start center" fusePerfectScrollbar>
    <div fxLayout="row" class="w-100-p mb-24" fxLayoutAlign="space-between center">
        <div>
            <h1 class="text-bold">{{'NAV.CONFIGURATIONS.DASHBOARD.V2' | translate}}</h1>
            <h4 class="mt-0 secondary-text">{{'NAV.CONFIGURATIONS.HEADER' | translate}}</h4>
        </div>

        <div fxLayoutAlign="end end" fxLayoutGap="10px">
            <button type="button" mat-stroked-button (click)="create()"
                    class="aster-btn_border">
                {{'CODE_LABEL_CONFIG.LIST.CREATE_BUTTON' | translate}}
            </button>
        </div>
    </div>

    <div fxLayout="column" class="w-100-p overflow mat-elevation-z2" *ngIf="filterForm">
        <form [formGroup]="filterForm" (ngSubmit)="loadData()">
            <table class="w-100-p" mat-table [dataSource]="dataSource" *ngIf="fields">
                <ng-container *ngFor="let field of fields">
                    <ng-container [matColumnDef]="field.name">
                        <th class="header pt-16 px-16 max-w-150" mat-header-cell *matHeaderCellDef>
                            <div>{{field.label | translate}}</div>
                            <ng-container [ngSwitch]="field.type" *ngIf="field.type">
                                <mat-form-field class="filter w-100-p" floatLabel="never">
                                    <input *ngSwitchCase="'text'"
                                           matInput
                                           (keyup.backspace)="resetSearch(field.name)"
                                           [formControlName]="field.name">
                                    <mat-select *ngSwitchCase="'select'"
                                                [formControlName]="field.name"
                                                [placeholder]="field.label | translate">
                                        <mat-option *ngFor="let option of field.options"
                                                    [value]="field.optionCode ? option[field.optionCode] : option">
                                            <span>{{field.display ? field.display(option) : option | translate}}</span>
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix class="disabled-text"
                                              *ngIf="!filterForm.value[field.name]">
                                        search
                                    </mat-icon>
                                    <button type="button" mat-icon-button matSuffix class="disabled-text"
                                            *ngIf="filterForm.value[field.name]"
                                            color="primary" (click)="clearSearchForm(field.name)">
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                </mat-form-field>
                            </ng-container>
                        </th>
                        <td mat-cell class="px-16" *matCellDef="let el">{{field.display ? field.display(el[field.name]) : el[field.name]}}</td>
                    </ng-container>
                </ng-container>
                <th class="header pt-16 px-16 max-w-150" mat-header-cell *matHeaderCellDef>
                    <div> {{'CODE_LABEL_CONFIG.FORM.FIELDS.PRICE.LABEL' | translate }}
                    </div>
                </th>
                <!-- Actions Column -->
                <ng-container matColumnDef="actions" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="mt-16">
                            <button type="submit" mat-icon-button aria-label="Submit"
                                    [ngClass]="isFilterFormEmpty() ? 'aster_accent_disabled':'aster_accent'"
                                    [disabled]="isFilterFormEmpty()">
                                <mat-icon>arrow_forward</mat-icon>
                            </button>
                            <button type="button" mat-icon-button aria-label="reset" class="aster_accent"
                                    (click)="resetSearchFilter()">
                                <mat-icon>settings_backup_restore</mat-icon>
                            </button>

                        </div>
                    </th>
                    <td mat-cell class="py-4" *matCellDef="let el; let i = index;">
                        <div fxFlex="row" fxLayoutAlign="center" fxLayoutGap="10px">
                            <button type="button" mat-icon-button class="aster_accent" (click)="edit(el)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button type="button" mat-icon-button color="warn" (click)="delete(i, el)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let el; columns: columns"></tr>
            </table>
        </form>
    </div>

    <mat-paginator [length]="page.totalElements"
                   (page)="paginate($event)" showFirstLastButtons
                   [pageSize]="page.size"
                   [pageSizeOptions]="page.sizeOptions"
                   [pageIndex]="page.index" class="w-100-p">
    </mat-paginator>
</div>

