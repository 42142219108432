import {saveAs} from 'file-saver';

export class FilesUtils {

    static saveFile(dataurl, filename, format): void {
        const arr = dataurl.split(',');
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const arrayType = new Uint8Array(n);
        while (n--) {
            arrayType[n] = bstr.charCodeAt(n);
        }
        saveAs(new File([arrayType], filename, {type: format}));
    }

}
