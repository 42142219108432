import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {Appointment} from '../../../../../models/appointment.model';
import {Site} from '../../../../../models/site.model';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {currentLanguage} from '../../../../../store/organization/organization.selectors';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-deposit-site',
    templateUrl: './waiting-deposit-site.component.html',
    styleUrls: ['./waiting-deposit-site.component.scss']
})
export class WaitingDepositSiteComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;

    @Output() inputMap = new EventEmitter<any>();

    folderId: string;
    managementSiteAppointment: Appointment;
    site: Site;
    productDeposit: boolean;
    currentLanguage$: Observable<string>;

    constructor(private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.initializeVariablesTask();
    }


    initializeVariablesTask(): void {
        this.site = JSON.parse(this.variablesTask['site']);
        if (!!this.variablesTask['managementSiteAppointment']) {
            this.managementSiteAppointment = JSON.parse(this.variablesTask['managementSiteAppointment']);
        }
    }

    getHours(hour): string {
        const OpenTime = hour.split(':');
        return (OpenTime[0] + 'h' + OpenTime[1]);
    }

    onSubmit() {
        this.inputMap.emit({});
    }
}
