<div class="text-center">
    <mat-dialog-content>
        <ng-container *ngFor="let img of images$ | async">
            <img src="{{img}}" alt="image">
        </ng-container>
    </mat-dialog-content>
    <div mat-dialog-actions fxLayoutAlign="end">
        <button mat-stroked-button mat-dialog-close="null" class="aster_btn">{{'BUTTON.CLOSE' | translate}}</button>
    </div>
</div>
