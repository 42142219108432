<div fxLayout="column" fxLayoutGap="20px">
    <ejs-spinner width="50" [isLoading]="loading"></ejs-spinner>

    <ng-container *ngIf="(showYearsFeature| async)">
        {{ 'COMPONENT.CUSTOMER_INVOICES_ITEMS.SELECT_YEAR' | translate }}
        <ejs-dropdownlist id='PURCHASE_YEAR'
                          popupWidth='250px'
                          width='250px'
                          #samples [dataSource]='years'
                          [(value)]="currentYear"
                          (select)="onSelectYear($event)"
                          [placeholder]="'COMPONENT.CUSTOMER_INVOICES_ITEMS.SELECT_YEAR' | translate"></ejs-dropdownlist>
    </ng-container>

    <div class="control-section">
        <ejs-grid #grid
                  [dataSource]='invoicesItems'
                  [allowPaging]="true"
                  [allowGrouping]="true"
                  [allowSorting]="true"
                  [groupSettings]="groupOptions"
                  [toolbar]='toolbar'
                  [pageSettings]='pageSettings'
                  [allowTextWrap]="true"
                  [allowResizing]="true"
                  (rowSelected)="onSelect($event)"
                  autoFit='true'
                  (dataBound)='dataBound()' (created)='created()' (load)='load()'>
            <e-columns>

                <e-column [headerText]="'COMPONENT.CUSTOMER_INVOICES_ITEMS.PRODUCT_IMAGE' | translate" width='100'
                          textAlign='Center'>
                    <ng-template #template let-data>
                        <app-product-image [image]="data.product.imageUrl"
                                           [loaderCss]="'w-60 h-60'"
                                           [imageCss]="'w-60 h-60'"
                        ></app-product-image>
                    </ng-template>
                </e-column>

                <e-column field='invoiceNumber'
                          [headerText]="'COMPONENT.CUSTOMER_INVOICES_ITEMS.INVOICE_NUMBER' | translate" width='170'
                          textAlign='Center'></e-column>

                <e-column field='purchaseDate'
                          [headerText]="'COMPONENT.CUSTOMER_INVOICES_ITEMS.PURCHASE_DATE' | translate"
                          width='120' textAlign='Center'
                          [allowGrouping]="false" [allowSorting]="true"></e-column>

                <e-column field='site.siteLabel' [headerText]="'COMPONENT.CUSTOMER_INVOICES_ITEMS.SITE' | translate"
                          width='120' textAlign="center"
                          [allowGrouping]="false" [allowSorting]="false">
                </e-column>

                <e-column field='product.productCode'
                          [headerText]="'COMPONENT.CUSTOMER_INVOICES_ITEMS.PRODUCT_CODE' | translate" width='140'
                          textAlign="center"
                          [allowGrouping]="false" [allowSorting]="false">
                </e-column>

                <e-column field='product.productLabel'
                          [headerText]="'COMPONENT.CUSTOMER_INVOICES_ITEMS.PRODUCT_LABEL' | translate" width='140'
                          textAlign="center"
                          [allowGrouping]="false" [allowSorting]="false">
                </e-column>

                <e-column field='product.quantity'
                          [headerText]="'COMPONENT.CUSTOMER_INVOICES_ITEMS.QUANTITY' | translate"
                          width='100' textAlign="center"
                          [allowGrouping]="false" [allowSorting]="false"></e-column>

                <e-column field='product.sellingPrice.value'
                          [headerText]="'COMPONENT.CUSTOMER_INVOICES_ITEMS.SELLING_PRICE' | translate" width='100'
                          textAlign="center"
                          [allowGrouping]="false" [allowSorting]="false"></e-column>

                <e-column field='product.family.familyLabel'
                          [headerText]="'COMPONENT.CUSTOMER_INVOICES_ITEMS.FAMILY' | translate" width='100'
                          textAlign="center"
                          [allowGrouping]="false" [allowSorting]="false"></e-column>
            </e-columns>
        </ejs-grid>
        <ejs-dialog #alertDialog
                    [buttons]='alertDlgButtons'
                    [visible]='hidden'
                    [header]='alertHeader | translate'
                    [animationSettings]='animationSettings'
                    [content]='alertContent | translate'
                    [width]='alertWidth'>
        </ejs-dialog>

    </div>
</div>