<h1 matDialogTitle>{{title | translate}}</h1>

<mat-dialog-content style="min-width: 400px; max-width: 500px">
    <div [innerHTML]="message | translate"></div>
</mat-dialog-content>
<mat-dialog-actions class="mt-24 mb-0">
    <div class="w-100-p delete-popup">
        <button mat-button class="aster_btn_cancel" (click)="dialogRef.close(false)">{{cancelButtonLabel | translate}}</button>
        <button mat-raised-button class="aster_btn" id="confirm_delete"
                (click)="dialogRef.close(true)">{{confirmButtonLabel | translate}}</button>

    </div>
</mat-dialog-actions>

