    <mat-card-content fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center">
            <div>
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>
        </div>
        <app-ordered-content *ngIf="!!orderedSpareParts" class="mb-24"
                             [orderedSpareParts]="orderedSpareParts"
                            ></app-ordered-content>
        <mat-checkbox (change)="updateOrderStatus()"
            [(ngModel)]="sparePartRetrieve">
            {{'BUTTON.SPARE_PART_RETRIEVE' | translate}}
        </mat-checkbox>

        <ng-container *ngIf="!!description$">
                <h3 class="text-bold">
                    <mat-icon class="icon-color">warning</mat-icon>
                    {{description$ | async}}</h3>
        </ng-container>

        <div class="mt-24">
            <div fxLayout="row" fxLayoutAlign="end end">
                <div>
                    <button mat-stroked-button
                            type="submit"
                            (click)="onSubmit()"
                            [disabled]="!sparePartRetrieve"
                            [ngClass]="sparePartRetrieve ? 'aster_btn' : 'aster_btn_disabled'">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </mat-card-content>