import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {FormControl, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {FilterFolderListService} from "../../../main/folder/folder-list/config/filter-folder-list.service";
import {MdePopoverTrigger} from "@material-extended/mde";

@Component({
    selector: 'search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {
    collapsed: boolean;
    fuseConfig: any;
    searchForm: FormGroup;

    @ViewChild('searchBarContentPopover', {static: false}) searchBarContentPopover: MdePopoverTrigger;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param filterFolderListService
     * @param router
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private filterFolderListService: FilterFolderListService,
        private router: Router,
        private _fuseConfigService: FuseConfigService
    ) {
        // Set the defaults

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (config) => {
                    this.fuseConfig = config;
                }
            );
        this.initialFormGroup();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Collapse
     */
    onClose(): void {
        this.searchForm.reset();
        this.searchBarContentPopover.closePopover();
    }



    /**
     * Search
     *
     */
    search(): void {
        if (!this.searchForm.value.label) {
            return;
        }
        this.router.navigate(['folder/list'], {
            queryParams: {
                searchValue: this.searchForm.value.label,
                searchBar: true
            },
            queryParamsHandling: 'merge'
        }).then(value => {
            const inputHeaders = ['searchValue'];
            this.filterFolderListService.nodeTreeSelected.next(inputHeaders);
            this.onClose();
        });

    }

    initialFormGroup(): void {
        this.searchForm = new FormGroup({
            label: new FormControl('')
        });
    }
}
