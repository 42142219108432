import {Folder} from '../folder.model';
import {SiteType} from '../enums/siteType.enum';
import {ProductFamily} from '../productFamily.model';
import {ProductFamilyType} from '../enums/productFamilyType.enum';
import {OperationModeEnum} from '../enums/operationMode.enum';
import {Product} from '../product.model';
import {UserState} from '../../store/user/user.state';
import {OriginEnum} from '../enums/origin.enum';

export class RuleEvaluationContext {
    organizationCode: string;
    context: string;
    productCode?: string;
    warrantyCode?: string;
    warrantyReasonEnum?: string;
    purchaseDate?: string;
    supplierCode?: string;
    siteCode?: string;
    claimOrigin?: string;
    productPurchasePrice?: number;
    productSellingPrice?: number;
    productSubElementCode?: string;
    family?: string;
    subFamily?: string;
    type?: string;
    subType?: string;
    segment?: string;
    brand?: string;
    claimCreationDate?: string;
    operatingMode?: string;
    productEanCode?: string;
    status?: string;
    parcours?: string;
    carrierType?: string;
    static getSellingPrice(folder: Folder): number {
        const sellingPrice = folder.purchaseInvoice.sellingPrice;
        return sellingPrice && sellingPrice.value ? sellingPrice.value : null;
    }

    static getPurchasePrice(folder: Folder): number {
        const purchasePrice = folder.product.purchasePrice;
        return purchasePrice && purchasePrice.value ? purchasePrice.value : null;
    }

    static getSubElementCode(folder: Folder): string {
        const productSubElement = folder.productSubElement;
        return productSubElement && productSubElement.code ? productSubElement.code : null;
    }

    static getSiteCode(folder: Folder): string {
        const site = folder.sites.find(s => s.type === SiteType.MANAGEMENT_SITE);
        return site && site.code ? site.code : null;
    }

    static getSupplierCode(folder: Folder): string {
        const product = folder.product;
        return (product && product.supplier && product.supplier.code) ? product.supplier.code : null;
    }

    static getFamilyByType(families: ProductFamily[], productFamilyType: ProductFamilyType): string {
        const family = families.find(x => x.type === productFamilyType);
        return (family && family.code) ? family.code : null;
    }

    static getBrand(folder: Folder): string {
        const brand = folder.product.brand;
        return brand ? brand : null;
    }
    static extractIsoLocalDate(dateTime: string): string {
        return dateTime.substring(0, 10);
    }
    static toIsoLocalDate(basicIsoDate: string): string {
        return basicIsoDate.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
    }
    static getOperatingMode(folder: Folder) {
        return folder.operationMode === OperationModeEnum.NO_MODOP ? null : folder.operationMode;
    }
    static fromFolder(folder: Folder): RuleEvaluationContext {
        return  {
            organizationCode: folder.organization.code,
            context: folder.context,
            productCode: folder.product.code,
            productEanCode: folder.product.codeEAN,
            claimOrigin: folder.origin,
            operatingMode: this.getOperatingMode(folder),
            warrantyCode: folder.newWarranty.warranty,
            warrantyReasonEnum: folder.newWarranty.warrantyReason,
            claimCreationDate: RuleEvaluationContext.extractIsoLocalDate(folder.createdBy.actionDate),
            purchaseDate: RuleEvaluationContext.toIsoLocalDate(folder.purchaseInvoice.purchaseDate),
            supplierCode: RuleEvaluationContext.getSupplierCode(folder),
            productSubElementCode: RuleEvaluationContext.getSubElementCode(folder),
            siteCode: RuleEvaluationContext.getSiteCode(folder),
            productSellingPrice: RuleEvaluationContext.getSellingPrice(folder),
            productPurchasePrice: RuleEvaluationContext.getPurchasePrice(folder),
            type: RuleEvaluationContext.getFamilyByType(folder.product.families, ProductFamilyType.TYPE),
            subType: RuleEvaluationContext.getFamilyByType(folder.product.families, ProductFamilyType.SUB_TYPE),
            family: RuleEvaluationContext.getFamilyByType(folder.product.families, ProductFamilyType.FAMILY),
            subFamily: RuleEvaluationContext.getFamilyByType(folder.product.families, ProductFamilyType.SUB_FAMILY),
            segment: RuleEvaluationContext.getFamilyByType(folder.product.families, ProductFamilyType.SEGMENT),
            brand: RuleEvaluationContext.getBrand(folder)
        };
    }
    static fromProduct(product: Product, user: UserState): RuleEvaluationContext {
        return  {
            organizationCode: user.organizationCode,
            context: user.context,
            productCode: product.code,
            family: RuleEvaluationContext.getFamilyByType(product.families, ProductFamilyType.FAMILY),
            subFamily: RuleEvaluationContext.getFamilyByType(product.families, ProductFamilyType.SUB_FAMILY),
            type: RuleEvaluationContext.getFamilyByType(product.families, ProductFamilyType.TYPE),
            subType: RuleEvaluationContext.getFamilyByType(product.families, ProductFamilyType.SUB_TYPE),
            segment: RuleEvaluationContext.getFamilyByType(product.families, ProductFamilyType.SEGMENT),
            brand: product.brand,
            claimOrigin: OriginEnum.BO,
            supplierCode: product.supplier?.code
        };
    }
}
