import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
  selector: 'app-refund-refusal-validation',
  templateUrl: './refund-refusal-validation.component.html',
  styleUrls: ['./refund-refusal-validation.component.scss']
})
export class RefundRefusalValidationComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    refundStatusForm: any = {
        form: null,
        placeholders: []
    };

    constructor() {
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.refundStatusForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            sendProduct: new FormControl(null),
            refundAgreementNumber: new FormControl(null),
        });
        this.refundStatusForm.placeholders = [
            {
                status: 'true',
                value: 'FOLDER.SUPPLIER_REFUSAL_VALIDATION.ACCEPTED'
            },
            {
                status: 'false',
                value: 'FOLDER.SUPPLIER_REFUSAL_VALIDATION.REFUSED'
            }
        ];
    }


    onSubmit(): void {
        if (!!this.refundStatusForm.form.value.status) {
            this.inputMap.emit({
                acceptRefundSupplier : this.refundStatusForm.form.value.status,
                sendProduct : this.refundStatusForm.form.value.sendProduct,
                destroyedByCustomer: this.refundStatusForm.form.value.sendProduct === 'true' ? 'false' : 'true',
                sentToSupplier: this.refundStatusForm.form.value.sendProduct,
                refundAgreementNumber : this.refundStatusForm.form.value.refundAgreementNumber,
                refundRefusedBySupplier : false,
            });
        } else {
            this.inputMap.emit({
                acceptRefundSupplier : this.refundStatusForm.form.value.status,
                refundRefusedBySupplier : false,
            });
        }

    }

    isFormValid(): boolean {
        return this.refundStatusForm.form.value.status === 'false' || (this.refundStatusForm.form.value.status === 'true'
            && !!this.refundStatusForm.form.value.refundAgreementNumber) ;
    }

}
