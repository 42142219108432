import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {WorkflowTask} from '../../../../../models/workflowTask.model';
import {Folder, IFolderUpdateRequest} from '../../../../../models/folder.model';
import {DialogDataCancelInterventionComponent} from '../confirm-appointment/dialog-data-cancel-intervention/dialog-data-cancel-intervention.component';
import {MatDialog} from '@angular/material/dialog';
import {WorkflowAction} from '../../../../../models/enums/workflowAction.enum';
import {RepairerCalendarService} from '../../../../../shared/services/appointment/repairer-calendar.service';
import {AddAllDayAppointmentRequest} from '../../../../../models/AddAllDayAppointmentRequest.model';
import {AddressType} from '../../../../../models/enums/addressType.enum';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {FolderService} from '../../../../../shared/services/folder.service';
import {FolderSubjectService} from '../../../folder-subject.service';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {AppState} from '../../../../../store/app.state';
import {Store} from '@ngrx/store';
import {FuseConfirmDialogComponent} from '../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {CommentType} from '../../../../../models/enums/CommentType.enum';
import {IEvent} from '../../../../../models/events.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-scheduled-waiting-intervention',
    templateUrl: './scheduled-waiting-intervention.component.html',
    styleUrls: ['./scheduled-waiting-intervention.component.scss']
})
export class ScheduledWaitingInterventionComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();

    folderId: string;
    form: FormGroup;
    choices: any[];
    model = new WorkflowTask('');
    confirmedDate;
    dateFormatter = 'YYYY-MM-DD';
    confirmedAppointment: {};
    private hasAgenda: any;
    showCalender = false;
    minDate = new Date();
    nbreDays = null;
    events: IEvent [] = [];
    workflowStatus: IEvent[] = [];

    constructor(private route: ActivatedRoute,
                public dialog: MatDialog,
                private repairerCalendarService: RepairerCalendarService,
                private store$: Store<AppState>,
                private folderService: FolderService,
                private folderSubjectService: FolderSubjectService) {
        this.getEvents();
    }

    static getLastEventWaitingForIntervention(data): any {
        return data
            .filter(event => event.eventType?.startsWith('WORKFLOW_STATUS'))
            .filter(value => value.eventName === 'WAITING_FOR_INTERVENTION')
            .slice(-1)[0]?.userAction?.actionDate;
    }

    ngOnInit(): void {
        this.folderId = this.route.snapshot.params.folderId;
        this.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            comment: new FormControl(''),
            confirmedAppointment: new FormControl(null),
        });
        this.hasAgenda = this.variablesTask['hasAgenda'];
        if (!this.hasAgenda) {
            this.showCalender = true;
        }
        this.confirmedDate = JSON.parse(this.variablesTask['confirmedDate']);
        this.initChoices();
    }

    private initChoices(): void {
        this.choices = [
            {
                status: WorkflowAction.REPAIR_DECISION_CANCEL,
                value: 'BUTTON.CANCEL_INTERVENTION',
            },
            {
                status: WorkflowAction.REPAIR_DECISION_SPARE,
                value: 'BUTTON.MAINTENANCE_IN_ORDER',
            },
            {
                status: WorkflowAction.APPOINTMENT_RESCHEDULE,
                value: 'BUTTON.APPOINTMENT_RESCHEDULE',
            },
        ];
    }

    onSubmit(): void {
        if (this.form.value.status === WorkflowAction.REPAIR_DECISION_CANCEL) {
            this.delegateToCancelInterventionModal();
        } else if (this.form.value.status === WorkflowAction.APPOINTMENT_RESCHEDULE && !this.hasAgenda) {
            if (!!this.form.value.comment && this.form.value.comment.length > 0) {
                this.commentGiven.emit({
                    value: this.form.value.comment
                });
            }
            this.computeConfirmedAppointment();
            this.inputMap.emit({
                'repairDecision': this.form.value.status,
                'confirmedDate': JSON.stringify(this.confirmedAppointment)
            });
            this.resetForm();
        } else if (this.form.value.status === WorkflowAction.APPOINTMENT_RESCHEDULE && this.folder.repairCenter.publicAgenda) {
            this.delegateToConfirmationModal();
        } else {
            if (!!this.form.value.comment && this.form.value.comment.length > 0) {
                this.commentGiven.emit({
                    value: this.form.value.comment
                });
            }
            this.inputMap.emit({'repairDecision': this.form.value.status});
        }
    }

    private computeConfirmedAppointment() {
        if (!!this.form.value.confirmedAppointment) {
            const date = moment(this.form.value.confirmedAppointment).format('YYYY-MM-DD');
            this.confirmedAppointment = {
                day: date,
                beginHour: '00:00:00',
                endHour : '23:59:59'
            };
        }
    }

    private delegateToConfirmationModal(): void {
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });

        dialogRef.componentInstance.title = 'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.RESCHEDULE.DIALOG.TITLE';
        dialogRef.componentInstance.message = 'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.RESCHEDULE.DIALOG.MESSAGE';

        dialogRef.afterClosed().subscribe(confirmed => {
            if (!!confirmed) {
                this.store$.dispatch(new StartLoading());

                this.callDeleteAppointment().subscribe(() => {
                    const folderUpdateRequest: IFolderUpdateRequest = {
                        appointmentIdToRemove: this.folder.appointment.confirmedAppointment.appointmentId
                    };

                    this.folderService.updateFolder(this.folderId, folderUpdateRequest).then(folder => {
                        this.folderSubjectService.folderLoaded(folder);

                        if (!!this.form.value.comment && this.form.value.comment.length > 0) {
                            this.commentGiven.emit({
                                value: this.form.value.comment
                            });
                        }

                        this.inputMap.emit({'repairDecision': this.form.value.status});
                    }).catch(error => {
                        this.store$.dispatch(new StopLoading());
                    });

                    this.store$.dispatch(new StopLoading());
                }, error => {
                    this.store$.dispatch(new StopLoading());
                });
            }
        });
    }

    private delegateToCancelInterventionModal(): void {
        this.dialog.open(DialogDataCancelInterventionComponent).afterClosed()
            .subscribe(cancelComment => {
                if (cancelComment) {
                    this.store$.dispatch(new StartLoading());
                    this.callDeleteAppointment().subscribe(() => {
                        this.commentGiven.emit({
                            value: cancelComment,
                            type: CommentType.COMMENT_CANCEL_INTERVENTION
                        });

                        this.inputMap.emit({
                            'repairDecision': this.form.value.status,
                        });
                        this.store$.dispatch(new StopLoading());
                    }, error => {
                        this.store$.dispatch(new StopLoading());
                    });
                }
            });
    }

    private extractDepartmentCodeFromCustomerAddress(): string {
        return this.folder.customer.addresses.filter(adr => adr.type === AddressType.INTERVENTION)[0].zipCode.slice(0, 2);
    }

    private callDeleteAppointment(): Observable<void> {
        const date = moment(this.folder.appointment.confirmedAppointment.day, this.dateFormatter);
        const addAllDayAppointmentRequest: AddAllDayAppointmentRequest = {
            dayOfMonth: date.date().toString(),
            month: (date.month() + 1).toString(),
            year: date.year().toString(),
            department: this.extractDepartmentCodeFromCustomerAddress()
        };
        return this.repairerCalendarService.deleteAppointment(this.folder.repairCenter.code, this.folder.organization.code, addAllDayAppointmentRequest);
    }

    private resetForm() {
        this.form.get(['status']).setValue(null);
        this.form.get(['comment']).setValue(null);
        this.form.get(['confirmedAppointment']).setValue(null);
    }

    private getEvents() {
        this.folderId = this.route.snapshot.params.folderId;
        return this.folderService.getEventList(this.folderId).subscribe(data => {
            return this.getNumberOfDays(this.variablesTask['confirmedDateTime'], ScheduledWaitingInterventionComponent.getLastEventWaitingForIntervention(data));
        });
    }

    getNumberOfDays(start, end): string {
        const date1 = new Date(start);
        const date2 = new Date(end);
        const oneDay = 1000 * 60 * 60 * 24;
        const diffInTime = date1.getTime() - date2.getTime();
        const diffInDays = Math.round(diffInTime / oneDay);
        this.nbreDays = 'P' + diffInDays + 'D';
        return this.nbreDays;
    }

    getStatusDate(): string {
        return this.folder.currentWorkflowStatus.workflowStatusDate;
    }
}
