import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder, IFolderUpdateRequest} from '../../../../../models/folder.model';
import {FolderService} from '../../../../../shared/services/folder.service';
import {CommentType} from '../../../../../models/enums/CommentType.enum';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {AppState} from '../../../../../store/app.state';
import {Store} from '@ngrx/store';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-manual-claim-processing',
    templateUrl: './manual-claim-processing.component.html',
    styleUrls: ['./manual-claim-processing.component.scss']
})
export class ManualClaimProcessingComponent implements OnInit {
    agentComment: string;
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    constructor(private folderService: FolderService,
                private store$: Store<AppState>) {
    }

    ngOnInit(): void {
    }

    completeTask(): void {
        this.store$.dispatch(new StartLoading());
        if (!!this.agentComment && this.agentComment.length > 0) {
            const folderUpdateRequest: IFolderUpdateRequest = {
                agentComment: {
                    content: this.agentComment,
                    type: CommentType.COMMENT_GENERAL_AGENT
                }
            };
            this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(() => {
                this.completeWorkflowTask();
                this.store$.dispatch(new StopLoading());
            });
        } else {
            this.completeWorkflowTask();
            this.store$.dispatch(new StopLoading());
        }
    }

    private completeWorkflowTask(): void {
        this.inputMap.emit({});
    }
}
