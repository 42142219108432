export enum WorkflowAction {
    PAYMENT_ACCEPTED = 'PAYMENT_ACCEPTED',
    PAYMENT_REFUSED = 'PAYMENT_REFUSED',
    PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',

    APPOINTMENT_CONFIRMATION = 'APPOINTMENT_CONFIRMATION',
    APPOINTMENT_CANCELLATION = 'APPOINTMENT_CANCELLATION',
    APPOINTMENT_RESCHEDULE = 'APPOINTMENT_RESCHEDULE',
    PRODUCT_NOT_REPAIRABLE = 'PRODUCT_NOT_REPAIRABLE',
    WARRANTY_DISCLAIMER = 'WARRANTY_DISCLAIMER',
    NEW_INTERVENTION_TO_PLAN = 'NEW_INTERVENTION_TO_PLAN',

    REPAIR_DECISION_CANCEL = 'REPAIR_DECISION_CANCEL',
    REPAIR_DECISION_SPARE = 'REPAIR_DECISION_SPARE',
    REPAIR_DECISION_FINISHED = 'REPAIR_DECISION_FINISHED',
    REPAIR_COMPLETED = 'REPAIR_COMPLETED',
    DECLARE_BREAKDOWN = 'DECLARE_BREAKDOWN',
    SPARE_PARTS_ORDER = 'SPARE_PARTS_ORDER',
    PRODUCT_REPAIRED_BY_CUSTOMER = 'PRODUCT_REPAIRED_BY_CUSTOMER',
    CUSTOMER_REQUEST_NEW_SERVICE = 'CUSTOMER_REQUEST_NEW_SERVICE',
}
