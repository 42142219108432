import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Unsubscriber} from '../../../unsubscriber';
import {ProductBrand} from '../../../models/ProductBrand';
import {select, Store} from '@ngrx/store';
import {allConfiguration} from '../../../store/configuration/configuration.selectors';
import {ConfigCodeMapsEnum} from '../../../shared/services/configuration-item-enum';
import {AppState} from '../../../store/app.state';
import {FormControl} from '@angular/forms';
import {MatOptionSelectionChange} from '@angular/material/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';


@Component({
    selector: 'app-product-brand',
    templateUrl: './brands.component.html',
    styleUrls: ['./brands.component.scss']
})
export class BrandsComponent extends Unsubscriber implements OnInit {
    @Output() selectedBrand = new EventEmitter();
    @Input() disable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    brands: ProductBrand[];
    brand: ProductBrand;
    brandCtrl: FormControl;
    filteredBrands: Observable<ProductBrand[]>;

    constructor(private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.BRANDS}))
            .subscribe(it => {
                this.brands = it;
                this.init();
            });
        this.disable$.subscribe(value => {
            this.brandCtrl.patchValue(null);
            if (value) {
                this.brandCtrl.disable();
            } else {
                this.brandCtrl.enable();
            }
        });
        this.initBrandsAutocomplete();
    }

    private init(): void {
        this.filteredBrands = new Observable<ProductBrand[]>();
        this.brandCtrl = new FormControl(null);
    }

    private initBrandsAutocomplete(): void {
        this.filteredBrands = this.brandCtrl.valueChanges
            .pipe(
                startWith(''),
                map(value => this.filterBrands(value))
            );
    }
    private filterBrands(searchValue: string): ProductBrand[] {
        const filterValue = (searchValue ?? '').toLowerCase();
        return this.brands.filter(brand =>
            brand.label?.toLowerCase().includes(filterValue) ||
            brand.code?.toLowerCase().includes(filterValue)
        );
    }


    displayBrand = (brandCode: string) => {
        const brand = this.brands.find(x => x.code === brandCode);
        return brand ? brand.label : '';
    }

    onSelectBrand($event: any): void {
        this.brand = this.brands.find(x => x.code === $event.option.value);
        this.selectedBrand.emit(this.brand);
    }

    brandSelected($event: any) {
        if (!$event.target.value) {
            this.selectedBrand.emit(null);
        }
    }
}
