<div *ngIf="!!externalReferences">
    <h2 mat-dialog-title>
        {{'FOLDER.EXTERNAL_REFERENCES.OTHER' | translate}}
    </h2>
    <mat-dialog-content>
        <div *ngFor="let reference of externalReferences  | keyvalue ;let i=index">
            <ng-container *ngIf="!!reference.value">
                <div>
                    <strong>{{('FOLDER.EXTERNAL_REFERENCES.' + reference.key)| translate}}
                        :</strong> {{reference.value}}
                </div>
            </ng-container>
        </div>
    </mat-dialog-content>
</div>

