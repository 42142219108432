import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileInfo} from '../../../models/file-info.model';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {currentLanguage} from '../../../store/organization/organization.selectors';
import {AppState} from '../../../store/app.state';
import {FilesUtils} from '../../../shared/utils/files-utils';
import {FolderService} from '../../../shared/services/folder.service';
import {AttachmentType} from '../../../models/attachment-type.model';

@Component({
    selector: 'app-files-list',
    templateUrl: './files-list.component.html',
    styleUrls: ['./files-list.component.scss']
})
export class FilesListComponent implements OnInit {
    @Input() data: FileInfo[] = [];
    @Output() dataChange = new EventEmitter<FileInfo[]>();


    @Input() displayedColumns: string[] = [];
    @Input() attachmentsTypes: AttachmentType[] = [];

    currentLanguage$: Observable<string>;

    constructor(private store$: Store<AppState>,
                private folderService: FolderService) {
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
    }

    downloadFile(fileId: string, fileName: string) {
        this.folderService.getAttachmentFileFolder(fileId)
            .subscribe(resFile => {
                const reader = new FileReader();
                reader.readAsDataURL(resFile);
                reader.onloadend = () => {
                    FilesUtils.saveFile(reader.result, fileName, resFile.type);
                };
            });
    }

    getLabelAttachmentType(codeAttachmentType: any) {
        return this.attachmentsTypes.find(value => value.code === codeAttachmentType)?.label;
    }

    removeFile(id) {
        const index = this.data.findIndex(file => file.id === id);
        if (index > -1) {
            this.data = this.data.filter(file => file.id !== id);
            this.dataChange.emit(this.data);
        }
    }

    canRemoveFile(file: FileInfo): boolean {
        const codes = this.attachmentsTypes?.map(({ code }) => code);
        return codes?.includes(file.attachmentType) ?? false;
    }
}
