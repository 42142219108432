export enum DeliveryModeEnum {
    CUSTOMER_TO_REPAIRER = 'CUSTOMER_TO_REPAIRER',
    CUSTOMER_TO_STORE = 'CUSTOMER_TO_STORE',
    ALREADY_IN_STORE = 'ALREADY_IN_STORE',
    CUSTOMER_TO_DISTRIBUTOR = 'CUSTOMER_TO_DISTRIBUTOR',
    DISTRIBUTOR_TO_SUPPLIER = 'DISTRIBUTOR_TO_SUPPLIER',
    REPAIRER_TO_SUPPLIER = 'REPAIRER_TO_SUPPLIER',
    CUSTOMER_TO_SUPPLIER = 'CUSTOMER_TO_SUPPLIER',
    STORE_TO_SUPPLIER = 'STORE_TO_SUPPLIER',
    PRODUCT_REPAIRED_IN_STORE = 'PRODUCT_REPAIRED_IN_STORE', // product repaired in store
    STORE_TO_REPAIRER= 'STORE_TO_REPAIRER',
    PICKING_FROM_STORE= 'PICKING_FROM_STORE',
    CUSTOMER_SHIPPING_ADDRESS = 'TO_CUSTOMER',
    STORE_SHIPPING_ADDRESS = 'TO_STORE',


}
