
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div>
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
        </div>
        <div>
            <button type="submit" mat-stroked-button class="aster-btn_border" (click)="openQuotationModal()">
                <mat-icon class="mr-8">edit</mat-icon>
                {{'BUTTON.EDIT' | translate}}
            </button>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px">
        <table mat-table [dataSource]="quotationSummary" class="mat-elevation-z2 w-100-p mt-16 mb-16" fxFlex="70"
               fxFlex.lt-md="100">
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center"> {{'MODAL.QUOTATION.TYPE' | translate}}</th>
                <td mat-cell *matCellDef="let quotationLine"
                    class="text-center"> {{'MODAL.QUOTATION.TYPE.' + quotationLine.type | translate}}</td>
            </ng-container>
            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center"> {{'MODAL.QUOTATION.NUMBER' | translate}}</th>
                <td mat-cell *matCellDef="let quotationLine" class="text-center"> {{quotationLine.number}} </td>
            </ng-container>
            <ng-container matColumnDef="totalPrice">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center"> {{'MODAL.QUOTATION.TOTAL_TTC' | translate}}</th>
                <td mat-cell *matCellDef="let quotationLine"
                    class="text-center"> {{quotationLine.totalTTC |currency:currency :'symbol-narrow' | space}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div fxFlex="30" fxFlex.lt-md="100">
            <mat-card class="mat-elevation-z2 mt-16 mb-16">
                <h4 class="text-bold">{{'MODAL.QUOTATION.TOTAL_TTC' | translate}}</h4>
                <h2>
                    {{quotation.totalPrice.value  |currency:quotation.totalPrice.currency :'symbol-narrow' | space}}
                </h2>
            </mat-card>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
        <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="30" fxFlex.lt-md="100">
            <ngx-dropzone
                #dropzone
                label="{{fileUploadForm.hasFile ? fileUploadForm.file: 'FOLDER.FILE.UPLOAD_MSG' | translate}}"
                [showPreviews]="true" [multiple]="false" [accept]="'*'" (filesAdded)="onFilesAdded($event)">
            </ngx-dropzone>
        </mat-card>

        <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="70" fxFlex.lt-md="100">
            <table mat-table [dataSource]="attachmentQuotationDataTable" class="w-100-p mt-8">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> {{'FOLDER.FILE.TITLE' | translate}}</th>
                    <td mat-cell *matCellDef="let element" class="text-left">
                        <a (click)="downloadFile(element)" class="cursor-pointer">
                            <p class="file_name" *ngIf="!!getFileInfo(element)">
                                <mat-icon>file_copy</mat-icon>
                                {{getFileInfo(element).name}}
                            </p>
                        </a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> {{'FOLDER.FILE.DATE' | translate}}</th>
                    <td mat-cell
                        *matCellDef="let element"> {{getFileInfo(element).uploadDate  | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate) : (currentLanguage$ | async)}} </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> {{'BUTTON.ACTION' | translate}}</th>
                    <td mat-cell
                        *matCellDef="let element">
                        <mat-icon color="warn" class="cursor-pointer" (click)="removeFile(element)">
                            delete_forever
                        </mat-icon>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsAttachment"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAttachment;"></tr>
            </table>
        </mat-card>
    </div>


    <div>
        <form [formGroup]="quotationChoiceForm.form" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="8px"
              (ngSubmit)="completeTask()">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
                <mat-radio-group formControlName="quotationAccepted" class="ml-20" fxLayoutGap="10px"
                                 labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch">
                    <mat-radio-button class="full-width-radio"
                                      *ngFor="let quotationChoice of quotationChoiceForm.placeholders"
                                      [value]="quotationChoice.quotationAccepted">
                        <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                            <div fxFlex>
                                <div>{{ quotationChoice.value | translate }}</div>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxLayout="row" fxLayoutAlign="end end">
                <button type="submit" mat-stroked-button
                        [ngClass]="quotationChoiceForm.form.invalid || quotation.quotationLines.length<=0?'aster_btn_disabled':'aster_btn'"
                        [disabled]="quotationChoiceForm.form.invalid || quotation.quotationLines.length<=0">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </div>