import {NgModule} from '@angular/core';

import {KeysPipe} from './keys.pipe';
import {HtmlToPlaintextPipe} from './htmlToPlaintext.pipe';
import {FilterPipe} from './filter.pipe';
import {CamelCaseToDashPipe} from './camelCaseToDash.pipe';
import {MomentPipe} from './moment.pipe';
import {HasRequiredFieldPipe} from './hasRequiredField.pipe';
import {CodeToLabelPipe} from './codeToLabel.pipe';
import {CodeToType} from './codeToType.pipe';
import {PhoneNumberPipe} from './phone-number.pipe';

import {ObjectLengthPipe} from './object-length.pipe';
import {SpacePipe} from './space.pipe';
import {FormatAmountPipe} from "./formatAmount.pipe";

@NgModule({
    declarations: [
        KeysPipe,
        ObjectLengthPipe,
        SpacePipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        MomentPipe,
        HasRequiredFieldPipe,
        CodeToLabelPipe,
        PhoneNumberPipe,
        FormatAmountPipe,
        CodeToType
    ],
    imports: [],
    exports: [
        KeysPipe,
        ObjectLengthPipe,
        SpacePipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        MomentPipe,
        HasRequiredFieldPipe,
        CodeToLabelPipe,
        PhoneNumberPipe,
        FormatAmountPipe,
        CodeToType
]})
export class FusePipesModule {
}
