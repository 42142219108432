    <app-workflow-status-progress-bar taskTimeoutDuration="{{getTaskDuration()}}"
                                      currentStatusDate="{{getStatusDate()}}">
    </app-workflow-status-progress-bar>
    <div fxLayout="column" fxLayoutAlign="start">
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
    </div>
    <mat-card-content>
        <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="completeTask()">
            <mat-radio-group formControlName="status" class="ml-20" fxLayout="row" fxLayoutAlign="space-between center"
                             (change)="changeConfirmedAppointmentValidator($event)" labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button class="full-width-radio" value="REPAIR_COMPLETED"
                                  id="REPAIR_COMPLETED">
                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                        <div fxFlex>
                            <div>{{ 'COMPONENT.REPARATION.FINISHED' | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
                <ng-container *ngIf="folder.context ==='SAV'">
                    <mat-radio-button class="full-width-radio" value="PRODUCT_NOT_REPAIRABLE"
                                      id="PRODUCT_NOT_REPAIRABLE">
                        <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                            <div fxFlex>
                                <div>{{ 'BUTTON.NOT_REPAIRABLE' | translate }}</div>
                            </div>
                        </div>
                    </mat-radio-button>
                </ng-container>

                <mat-radio-button class="full-width-radio" value="APPOINTMENT_RESCHEDULE"
                                  id="APPOINTMENT_RESCHEDULE">
                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                        <div fxFlex>
                            <div class="mr-16">{{ 'BUTTON.NEW_APPOINTMENT' | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
                <ng-container *ngIf="form.value.status==='APPOINTMENT_RESCHEDULE' && showCalender">
                    <mat-form-field appearance="outline" class="pr-sm-8" [style.width.px]=327>
                        <mat-label>{{'FOLDER.SCHEDULE.DAY_OF_INTERVENTION' | translate}}</mat-label>
                        <input matInput [matDatepicker]="confirmedDay" [min]="minDate"
                               formControlName="confirmedAppointment">
                        <mat-datepicker-toggle matSuffix
                                               [for]="confirmedDay"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #confirmedDay></mat-datepicker>
                    </mat-form-field>
                </ng-container>

                <mat-radio-button class="full-width-radio" value="REPAIR_DECISION_CANCEL"
                                  id="REPAIR_DECISION_CANCEL">
                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                        <div fxFlex>
                            <div>{{ 'BUTTON.CANCEL_INTERVENTION' | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>

            <app-comment-area *ngIf="form.value.status !== 'REPAIR_DECISION_CANCEL'"
                              [commentForm]="form.get('comment')"
                              [title]="toCommentTitle(form.value.status)">
            </app-comment-area>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button [disabled]="form.invalid"
                        [ngClass]="form.invalid ? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>
