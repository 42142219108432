import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-customer-repair-in-progress',
    templateUrl: './customer-repair-in-progress.component.html',
    styleUrls: ['./customer-repair-in-progress.component.css']
})
export class CustomerRepairInProgressComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    repairRequiresForm: any = {
        form: null,
        placeholders: []
    };

    constructor() {
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.repairRequiresForm.form = new FormGroup({
            choice: new FormControl(null, Validators.required),
        });
        this.repairRequiresForm.placeholders = [
            {
                choice: 'true',
                value: 'BUTTON.CUSTOMER_NEED_TECHNICIAN',
            },

            {
                choice: 'false',
                value: 'BUTTON.PRODUCT_REPAIRED_BY_CUSTOMER',
            }
        ];
    }

    onSubmit(): void {
        this.inputMap.emit({
            'repairRequires': this.repairRequiresForm.form.value.choice
        });
    }

}

