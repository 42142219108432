import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    selector: 'dialog-data-cancel-intervention.component',
    templateUrl: 'dialog-scheduled-waiting-cancel-intervention.component.html',
})
export class   DialogWaitingCancelInterventionComponent {

    registerForm: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private formBuilder: FormBuilder) {
        this.initFormCancelIntervention();
    }


    initFormCancelIntervention(): void {
        this.registerForm = this.formBuilder.group({
            cancelIntervention: [null, Validators.required]
        });
    }

}
