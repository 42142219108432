import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder, IFolderUpdateRequest} from '../../../../../../models/folder.model';
import {ProductAccessory} from '../../../../../../models/ProductAccessory';
import {ShipmentType} from '../../../../../../models/enums/shipmentType.enum';
import {ShipmentItem} from '../../../../../../models/shipmentItem.model';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {ShipmentItemType} from '../../../../../../models/enums/shipmentItemType.enum';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GrowthbookService} from '../../../../../../shared/services/growthbook.service';
import {AppFeatures} from '../../../../../../shared/features/app-features';
import {GrowthbookAttributes} from '../../../../../../shared/features/growthbook-attributes';
import {UserTasks} from '../../../../../../shared/features/user-tasks';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

export const TRACKING_NUMBER = 'trackingNumber';
export const CARRIER = 'carrier';
export const PICKED = 'picked';
export const REQUIRED = 'required';

@Component({
    selector: 'app-waiting-pickup',
    templateUrl: './waiting-pickup.component.html',
    styleUrls: ['./waiting-pickup.component.scss']
})
export class WaitingPickupComponent implements OnInit {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    selectedProductAccessories: ProductAccessory[] = [];
    showProductAccessories: boolean;
    private shipmentItems: ShipmentItem[];

    form: FormGroup;

    constructor(private folderService: FolderService,
                private store$: Store<AppState>,
                private growthbookService: GrowthbookService,
                private folderSubjectService: FolderSubjectService) {
    }

    ngOnInit() {
        this.createForm();
        this.processForm();

        const shipmentType = this.folder.shipments.filter(shipment => shipment.type === ShipmentType.PICKING);
        if (shipmentType?.length > 0) {
            this.showProductAccessories = true;
        }
    }

    private processForm() {
        const pickingShipment = this.folder.shipments.find(shipment => shipment.type === ShipmentType.PICKING);

        if (pickingShipment) {
            this.form.get(TRACKING_NUMBER).patchValue(pickingShipment.trackingNumber);
            this.form.get(CARRIER).patchValue(pickingShipment.carrier);
        }

        const attributes: GrowthbookAttributes = {
            organizationCode: this.folder.organization.code,
            context: this.folder.context,
            userTask: UserTasks.WAITING_PICKUP
        };

        this.growthbookService.getFeatureValue(AppFeatures.USER_TASK_WAITING_PICKUP, attributes, {})
            .then(featureValue => {
                const requiredControls = featureValue[REQUIRED] || [];
                requiredControls.forEach((controlName: string) => {
                    this.form.get(controlName).setValidators([Validators.required]);
                });
            });
    }

    private createForm() {
        this.form = new FormGroup({
            picked: new FormControl(false, [Validators.requiredTrue]),
            trackingNumber: new FormControl(''),
            carrier: new FormControl('')
        });
    }

    onSubmit(): void {
        this.store$.dispatch(new StartLoading());

        const folderUpdateRequest = this.buildUpdateRequest();

        if (Object.keys(folderUpdateRequest).length > 0) {
            this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
                this.inputMap.emit({});
                this.folderSubjectService.folderLoaded(folder);
            }).catch(() => {
                console.log('ERROR Update Folder with id :' + this.folder.id);
                this.store$.dispatch(new StopLoading());
            });
        } else {
            this.inputMap.emit({});
        }
    }

    private buildUpdateRequest() {
        const folderUpdateRequest: IFolderUpdateRequest = {};

        if (this.selectedProductAccessories.length > 0) {
            this.shipmentItems = this.selectedProductAccessories.map(value => this.toShipmentItem(value));
            folderUpdateRequest.shipmentItems = this.shipmentItems;
        }

        const trackingNumber = this.form.get(TRACKING_NUMBER).value?.trim();
        const carrier = this.form.get(CARRIER).value?.trim();

        if (trackingNumber || carrier) {
            const pickingShipment = this.folder.shipments.find(shipment => shipment.type === ShipmentType.PICKING);

            if (pickingShipment) {
                folderUpdateRequest.shipmentToUpdate = {
                    id: pickingShipment.id,
                    trackingNumber: trackingNumber,
                    carrier: carrier
                };

            } else {
                folderUpdateRequest.shipment = {
                    type: ShipmentType.PICKING,
                    carrier: carrier,
                    trackingNumber: trackingNumber
                };
            }
        }
        return folderUpdateRequest;
    }

    toShipmentItem(productAccessory: ProductAccessory): ShipmentItem {
        return {
            code: productAccessory.code,
            label: productAccessory.label,
            type: ShipmentItemType.SECONDARY
        };
    }

    updateSelectedAccessories($event: ProductAccessory[]) {
        this.selectedProductAccessories = $event;
    }

    get pickedControl(): FormControl {
        return this.form?.get(PICKED) as FormControl;
    }

    get trackingNumberControl(): FormControl {
        return this.form?.get(TRACKING_NUMBER) as FormControl;
    }

    get carrierControl(): FormControl {
        return this.form?.get(CARRIER) as FormControl;
    }
}
