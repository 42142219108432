
    <div fxLayout="column" fxLayoutAlign="start">
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
    </div>

    <div fxLayout="column" fxLayoutGap="50px" ngClass.gt-md="w-100-p" ngClass.lt-md="w-100-p"
         ngClass.lt-sm="w-100-p">


            <form *ngIf="!!symptomsGrid.filterForm" [formGroup]="symptomsGrid.filterForm">

                <div fxLayout="column" fxLayoutAlign="start center" class="mt-25">

                    <mat-form-field class="w-100-p " fxFlex appearance="outline" id="symptom_search_label">
                        <mat-label>{{'FOLDER.SYMPTOM.SEARCH_FORM.PLACEHOLDER' | translate}}</mat-label>
                        <mat-select
                            (selectionChange)="symptomSelected($event.value)"
                            formControlName="label">
                            <mat-select-trigger *ngIf="symptomsGrid.filterForm.controls['label'].value">
                                {{symptomsGrid.filterForm.controls['label'].value.label}}
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search formControlName="labelCtrl"
                                                       [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                                       [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"
                                >
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option [id]="symptom.code"
                                *ngFor="let symptom of filteredSymptomsList | async"
                                [value]="symptom">
                                {{symptom.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>

                <div fxLayout="column" fxLayoutAlign="start">
                    <mat-hint>
                        <label (click)="toggleSymptomFound()"
                               id="symptom_not_found" matTooltip="{{'SYMPTOM.NOT_FOUND' | translate}}"
                               class="h-44 pr-24  cursor-pointer link-underline">
                            {{'SYMPTOM.NOT_FOUND' | translate}}
                        </label>
                    </mat-hint>


                    <mat-form-field appearance="outline" fxFlex="40" *ngIf="!symptomFound">
                        <mat-label>{{'CUSTOMER.WHEN.FAULT.NOT.FOUND.DESCRIPTION'|translate}}</mat-label>
                        <input matInput  placeholder="{{'CUSTOMER.WHEN.FAULT.NOT.FOUND.DESCRIPTION'|translate}}"
                               id="customerPanneNotFound" formControlName="customerPanneNotFound" inputControl>
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutAlign="start center" class="mt-8">
                            <span fxFlex="20">
                                {{'FORM.TITLE.CONDITION' | translate}}
                            </span>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field fxFlex appearance="outline" id="condition">
                        <mat-select formControlName="condition"
                                    [placeholder]="'FORM.TITLE.CONDITION' | translate"
                                    (selectionChange)="conditionChanged($event.value)">
                            <mat-option *ngFor="let item of conditionList$ | async"
                                        [value]="!!item.code ? item.code : ''">
                                <span> {{item.label | translate}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <div fxLayout="column">
                    <mat-form-field appearance="outline" fxFlex="40">
                        <mat-label>{{'FOLDER.SERIAL_NUMBER1'|translate}}</mat-label>
                        <input matInput placeholder="{{'FOLDER.SERIAL_NUMBER1'|translate}}" type="text"  [pattern]="serialNumberInfo?.serialNumber1RegEx"
                                   id="panne_confirmed_serialNumber"  formControlName="serialNumber1" [required]="serialNumberInfo?.requiredSerialNumber" inputControl>
                        <mat-hint *ngIf="serialNumberInfo?.imageId?.length>0">
                            <label class="h4 cursor-pointer bt-12 rule-number_product  link-underline"
                                   (click)="getAttachmentFile()">
                                {{'FOLDER.COMMENT_FORM.RULE_PRODUCT' | translate}}
                            </label>
                        </mat-hint>
                    </mat-form-field>
                    <ng-container *ngIf="!!serialNumberInfo && serialNumberInfo.requiredSecondSerialNumber">
                        <mat-form-field appearance="outline" fxFlex="40">
                            <mat-label>{{'FOLDER.SERIAL_NUMBER2'|translate}}</mat-label>
                            <input matInput placeholder="{{'FOLDER.SERIAL_NUMBER2'|translate}}"
                                   type="text"
                                   formControlName="serialNumber2"
                                   [pattern]="serialNumberInfo?.serialNumber2RegEx"
                                   inputControl
                                   required>
                        </mat-form-field>

                    </ng-container>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                            <mat-label>{{'FOLDER.COMMENT_FORM.LABEL' | translate}}</mat-label>
                            <textarea matInput rows="10" cols="7"
                                      #descriptionId
                                      id="panne_confirmed_description"
                                      [placeholder]="'FOLDER.COMMENT_FORM.PLACEHOLDER' | translate"
                                      [required]="isDescriptionRequired"
                                      formControlName="description"
                                      inputControl
                                      [maxlength]="maxlength"></textarea>
                            <mat-hint align="end">{{descriptionId.value.length}}/{{ maxlength }}</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <ng-container *ngIf="serialNumberInfo?.numberOfPhotos > 0">
                    <div class="mt-4 mr-24 mb-8 w-100-p">
                        <div class="upload-initializer w-100-p">
                            <div fxLayout="row" (click)="onUploadImage()">
                                <mat-icon svgIcon="icon_image_black"></mat-icon>
                                <span
                                    class="ml-4 cursor-pointer link-underline">{{'FOLDER.COMMENT_FORM.UPLOAD_IMG'|translate}}</span>
                                <span class="ml-4"><strong>{{numberOfPhotosToAttachMessage() | async}}</strong></span>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf=" !!folder.attachments && folder.attachments.length>0">
                    <mat-list class="w-100-p">
                        <ng-container *ngFor="let file of  panneConfirmedAttachedFile;let i=index ">
                            <mat-list-item  style="margin-bottom: 20px">
                                <div fxLayout="row" fxLayoutAlign="start">
                                    <ng-container *ngIf="file.url">
                                        <div class="zone-file_img cursor-pointer" (click)="showImageModal([file.url])">
                                            <mat-icon mat-list-icon><img class="image-serial_number" [src]="file.url">
                                            </mat-icon>
                                        </div>
                                    </ng-container>
                                    <div fxLayout="column" fxLayoutAlign="start">
                                        <h4 mat-line class="file_name text-bold ml-44">{{file.name}}</h4>
                                        <p mat-line class="file_date ml-44"> {{file.uploadDate | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate) : (currentLanguage$ | async)}} </p>
                                    </div>
                                    <div fxLayoutAlign="end">
                                        <mat-icon color="warn" class="cursor-pointer" (click)="removeFile(file.id)">
                                            delete_forever
                                        </mat-icon>
                                    </div>
                                </div>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>
                </div>
                <div matSuffix fxLayout="column" fxLayoutGap="20px">
                    <mat-hint>* {{'CUSTOMER.REQUIRED_FIELD' | translate}}</mat-hint>
                </div>
                <div fxLayoutAlign="start center" fxLayoutGap="10px" class="mt-24">
                    <button *ngIf="showProlongedDiagButton()" mat-stroked-button type="button"
                             [disabled]="isDisabled()"
                             [ngClass]="isDisabled()?'aster_btn_cancel_disabled':'aster_btn_cancel'"
                             (click)="prolongedDiagnosis()">
                        {{'BUTTON.DIAG_PROLONGED' | translate}}
                    </button>
                    <span fxFlex></span>
                    <button  mat-stroked-button type="button" class="aster_btn_cancel"
                            (click)="closeByDiagExpress()">
                        {{'BUTTON.CLOSE_BY_DIAG_EXPRESS' | translate}}
                    </button>
                    <button type="submit" mat-stroked-button aria-label="Submit" color="accent"
                            [disabled]="isDisabled()"
                            [ngClass]="isDisabled()?'aster_btn_disabled':'aster_btn'"
                            (click)="completeTask()">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </form>
        </div>