<div class="text-center">

    <mat-dialog-content>

        <form [formGroup]="reportLineForm.form" (ngSubmit)="addIntoSparePartList()" autocomplete="off">
            <div fxLayout="row" fxLayoutAlign="start start">
                <h2>{{'FOLDER.REPAIR_IN_PROGRESS.LIST_SPARE_PARTS' | translate}}</h2>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                <div fxFlex="25" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.TYPE'|translate}}</mat-label>
                        <mat-select formControlName="type" [placeholder]="'MODAL.QUOTATION.TYPE'|translate" required (selectionChange)="computePayers($event)">
                            <mat-option *ngFor="let type of sparePartsTypes" [value]="type">
                                <span> {{'MODAL.QUOTATION.TYPE.' + type | translate}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="25" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.CODE' | translate }}</mat-label>
                        <input matInput placeholder="{{'MODAL.QUOTATION.CODE' | translate }}"
                               formControlName="code" required >
                    </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.LABEL'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.LABEL'|translate"
                               formControlName="label" type="text" required >
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                <div fxFlex="20" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.QUANTITY'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.QUANTITY'|translate" formControlName="quantity"
                               min="1" numberOnly  required (blur)="formatAndCalculateTotalQuotationLine()">
                    </mat-form-field>
                </div>
                <div fxFlex="20" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.PRICE'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.PRICE'|translate"
                               formControlName="price" (blur)="formatAndCalculateTotalQuotationLine()"
                               min="0" required>

                    </mat-form-field>
                </div>
                <div fxFlex="20" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label> {{'MODAL.QUOTATION.TVA'|translate}}</mat-label>
                        <mat-select formControlName="tva" (selectionChange)="formatAndCalculateTotalQuotationLine()">
                            <mat-option *ngFor="let vat of (vatsList$ | async)" [value]="vat.rate">
                                {{vat.rate}} %
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="20" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.DISCOUNT'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.DISCOUNT'|translate"
                               formControlName="discount"
                               percentage (blur)="formatAndCalculateTotalQuotationLine()">
                        <mat-icon class="s-16" matSuffix>%</mat-icon>
                    </mat-form-field>
                </div>
                <div fxFlex="20" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.TOTAL_PRICE'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.TOTAL_PRICE'|translate"
                               required
                               formControlName="totalLinePrice" disabled readonly="true">
                    </mat-form-field>
                </div>
            </div>
            <ng-container *ngIf="this.reportLineForm.form.value.discount>0">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                    <div fxFlex="100" fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'MODAL.QUOTATION.DISCOUNT_REASON'|translate}}</mat-label>
                            <input matInput [placeholder]="'MODAL.QUOTATION.DISCOUNT_REASON' | translate"
                                   formControlName="discountReason" type="text">
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>

            <div fxLayoutAlign="end center">
                <div>
                    <button mat-stroked-button [disabled]="reportLineForm.form.invalid"
                            [ngClass]="reportLineForm.form.invalid ? 'aster-btn_border-disabled' : 'aster-btn_border'">
                        {{'BUTTON.ADD' | translate}}
                    </button>
                </div>
            </div>
        </form>

        <table mat-table [dataSource]="reportLineDataTable" class="mat-elevation-z4 w-100-p mt-16 mb-16">
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.CODE' | translate}} </th>
                <td mat-cell *matCellDef="let sparePart"> {{sparePart.code}} </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.TYPE' | translate}} </th>
                <td mat-cell
                    *matCellDef="let sparePart"> {{'MODAL.QUOTATION.TYPE.' + sparePart.type | translate}} </td>
            </ng-container>
            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.LABEL' | translate}} </th>
                <td mat-cell *matCellDef="let sparePart"> {{sparePart.label}} </td>
            </ng-container>
            <ng-container matColumnDef="payer">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center">  {{'MODAL.QUOTATION.PAYER' | translate}} </th>
                <td mat-cell
                    *matCellDef="let sparePart"> {{!!sparePart.payer ? ('RULE_WARRANTIES.CONFIGS.PAYERS_CONFIGS.' + sparePart.payer | translate) : "-"}} </td>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center">  {{'MODAL.QUOTATION.QUANTITY' | translate}} </th>
                <td mat-cell
                    *matCellDef="let sparePart">  {{sparePart.quantity}}  </td>
            </ng-container>

            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center">  {{'MODAL.QUOTATION.PRICE' | translate}} </th>
                <td mat-cell
                    *matCellDef="let sparePart">{{sparePart.price | currency:currency :'symbol-narrow' | space}}</td>
            </ng-container>

            <ng-container matColumnDef="tva">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center">  {{'MODAL.QUOTATION.TVA' | translate}} </th>
                <td mat-cell
                    *matCellDef="let sparePart">{{sparePart.tva}}</td>
            </ng-container>

            <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center">  {{'MODAL.QUOTATION.DISCOUNT' | translate}} </th>
                <td mat-cell
                    *matCellDef="let sparePart">{{sparePart.discount}}</td>
            </ng-container>

            <ng-container matColumnDef="totalLinePrice">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center">{{'MODAL.QUOTATION.TOTAL_PRICE' | translate}} </th>
                <td mat-cell
                    *matCellDef="let sparePart">  {{sparePart.totalLinePrice  |currency:currency :'symbol-narrow' | space}}  </td>
            </ng-container>

            <ng-container matColumnDef="discountReason">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center">  {{'MODAL.QUOTATION.DISCOUNT_REASON' | translate}} </th>
                <td mat-cell
                    *matCellDef="let sparePart">  {{sparePart.discountReason}}  </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center">  {{'BUTTON.ACTION' | translate}}</th>
                <td mat-cell *matCellDef="let indexLine = index;">
                    <button matSuffix mat-icon-button color="warn"
                            type="button" class="mx-8"
                            (click)="deleteLineSparePart(indexLine)">
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="reportLineColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: reportLineColumns;"></tr>
        </table>

        <mat-divider class="mb-16"></mat-divider>

<!--        <form [formGroup]="irisForm.form" (ngSubmit)="addIntoIrisList()" autocomplete="off">-->
<!--            <div fxLayout="row" fxLayoutAlign="start start">-->
<!--                <h2> {{'FOLDER.REPAIR_IN_PROGRESS.IRIS' | translate}} </h2>-->
<!--            </div>-->
<!--            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">-->
<!--                <div fxFlex fxFlex.lt-md="100">-->
<!--                    <mat-form-field appearance="outline" class="w-100-p">-->
<!--                        <mat-label>{{'FOLDER.REPAIR_IN_PROGRESS.SYMPTOM'| translate}}</mat-label>-->
<!--                        <mat-select placeholder="symptom" formControlName="symptom">-->
<!--                            <mat-option>-->
<!--                            <ngx-mat-select-search formControlName="symptomCodeCtrl"-->
<!--                                                   [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"-->
<!--                                                   [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>-->
<!--                                </mat-option>-->
<!--                            <mat-option *ngFor="let symptom of filteredSymptomList | async" [value]="symptom">-->
<!--                                <span [attr.class]="'flag-icon h-24 w-32 flag-icon-' + symptom.code | lowercase"></span>-->
<!--                                <span class="ml-8 ngx-select_label">{{ symptom.code + '-' + symptom.label }}</span>-->
<!--                            </mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->
<!--                </div>-->
<!--                <div fxFlex fxFlex.lt-md="100">-->
<!--                    <mat-form-field appearance="outline" class="w-100-p">-->
<!--                        <mat-label>{{'FOLDER.REPAIR_IN_PROGRESS.CONDITION' | translate}}</mat-label>-->
<!--                        <mat-select placeholder="symptom" formControlName="condition">-->
<!--                            <mat-option>-->
<!--                            <ngx-mat-select-search formControlName="conditionCodeCtrl"-->
<!--                                                   [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"-->
<!--                                                   [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>-->
<!--                                </mat-option>-->
<!--                            <mat-option *ngFor="let condition of filteredConditionList | async" [value]="condition">-->
<!--                                <span-->
<!--                                    [attr.class]="'flag-icon h-24 w-32 flag-icon-' + condition.code | lowercase"></span>-->
<!--                                <span class="ml-8 ngx-select_label">{{condition.code + '-' + condition.label }}</span>-->
<!--                            </mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->
<!--                </div>-->
<!--                <div fxFlex fxFlex.lt-md="100">-->
<!--                    <mat-form-field appearance="outline" class="w-100-p">-->
<!--                        <mat-label>{{'FOLDER.REPAIR_IN_PROGRESS.REPARATION' | translate}}</mat-label>-->
<!--                        <mat-select placeholder="symptom" formControlName="reparation">-->
<!--                            <mat-option>-->
<!--                            <ngx-mat-select-search formControlName="reparationCodeCtrl"-->
<!--                                                   [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"-->
<!--                                                   [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>-->
<!--                                </mat-option>-->
<!--                            <mat-option *ngFor="let repair of filteredRepairList | async" [value]="repair">-->
<!--                                <span [attr.class]="'flag-icon h-24 w-32 flag-icon-' + repair | lowercase"></span>-->
<!--                                <span class="ml-8 ngx-select_label">{{ repair.code + '-' + repair.label }}</span>-->
<!--                            </mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">-->
<!--                <div fxFlex fxFlex.lt-md="100">-->
<!--                    <mat-form-field appearance="outline" class="w-100-p">-->
<!--                        <mat-label>{{'FOLDER.REPAIR_IN_PROGRESS.DEFECT' | translate}}</mat-label>-->

<!--                        <mat-select placeholder="Défaut" formControlName="defect">-->
<!--                            <mat-option>-->
<!--                            <ngx-mat-select-search formControlName="defectCodeCtrl"-->
<!--                                                   [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"-->
<!--                                                   [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>-->
<!--                                </mat-option>-->

<!--                            <mat-option *ngFor="let defect of filteredDefectList | async" [value]="defect">-->
<!--                                <span-->
<!--                                    [attr.class]="'flag-icon h-24 w-32 flag-icon-' + defect?.code | lowercase"></span>-->
<!--                                <span class="ml-8 ngx-select_label">{{defect?.code + '-' + defect?.label }}</span>-->
<!--                            </mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->
<!--                </div>-->
<!--                <div fxFlex fxFlex.lt-md="100">-->
<!--                    <mat-form-field appearance="outline" class="w-100-p">-->
<!--                        <mat-label>{{'FOLDER.REPAIR_IN_PROGRESS.SECTION' | translate}}</mat-label>-->
<!--                        <mat-select placeholder="Section" formControlName="section">-->
<!--                            <mat-option>-->
<!--                            <ngx-mat-select-search formControlName="sectionCodeCtrl"-->
<!--                                                   [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"-->
<!--                                                   [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>-->
<!--                                </mat-option>-->
<!--                            <mat-option *ngFor="let section of filteredSectionList | async" [value]="section">-->
<!--                                <span-->
<!--                                    [attr.class]="'flag-icon h-24 w-32 flag-icon-' + section.code | lowercase"></span>-->
<!--                                <span class="ml-8 ngx-select_label">{{section.code + '-' + section.label }}</span>-->
<!--                            </mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div fxLayoutAlign="end center">-->
<!--                <div>-->
<!--                    <button mat-stroked-button [disabled]="irisForm.form.invalid"-->
<!--                            [ngClass]="irisForm.form.invalid ? 'aster-btn_border-disabled' : 'aster-btn_border'">-->
<!--                        {{'BUTTON.ADD' | translate}}-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </form>-->

<!--        <table mat-table [dataSource]="irisDataTable" class="mat-elevation-z2 w-100-p mt-16 mb-16">-->
<!--            <ng-container matColumnDef="symptom">-->
<!--                <th mat-header-cell *matHeaderCellDef-->
<!--                    class="text-center"> {{'FOLDER.REPAIR_IN_PROGRESS.SYMPTOM' | translate}} </th>-->
<!--                <td mat-cell *matCellDef="let iris"> {{iris.symptom?.label}} </td>-->
<!--            </ng-container>-->
<!--            <ng-container matColumnDef="condition">-->
<!--                <th mat-header-cell *matHeaderCellDef-->
<!--                    class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.CONDITION' | translate}} </th>-->
<!--                <td mat-cell-->
<!--                    *matCellDef="let iris">{{ iris.condition?.label}} </td>-->
<!--            </ng-container>-->
<!--            <ng-container matColumnDef="repair">-->
<!--                <th mat-header-cell *matHeaderCellDef-->
<!--                    class="text-center"> {{'FOLDER.REPAIR_IN_PROGRESS.REPARATION' | translate}} </th>-->
<!--                <td mat-cell *matCellDef="let sparePart"> {{sparePart.reparation?.label}} </td>-->
<!--            </ng-container>-->
<!--            <ng-container matColumnDef="defect">-->
<!--                <th mat-header-cell *matHeaderCellDef-->
<!--                    class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.DEFECT' | translate}} </th>-->
<!--                <td mat-cell-->
<!--                    *matCellDef="let iris">{{ iris.defect?.label}} </td>-->
<!--            </ng-container>-->
<!--            <ng-container matColumnDef="section">-->
<!--                <th mat-header-cell *matHeaderCellDef-->
<!--                    class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.SECTION' | translate}} </th>-->
<!--                <td mat-cell-->
<!--                    *matCellDef="let iris">{{ iris.section?.label}} </td>-->
<!--            </ng-container>-->
<!--            <ng-container matColumnDef="action">-->
<!--                <th mat-header-cell *matHeaderCellDef-->
<!--                    class="text-center">  {{'BUTTON.ACTION' | translate}}</th>-->
<!--                <td mat-cell *matCellDef="let indexLine = index;">-->
<!--                    <button matSuffix mat-icon-button color="warn"-->
<!--                            type="button" class="mx-8"-->
<!--                            (click)="deleteLineDataIris(indexLine)">-->
<!--                        <mat-icon>delete_forever</mat-icon>-->
<!--                    </button>-->
<!--                </td>-->
<!--            </ng-container>-->
<!--            <tr mat-header-row *matHeaderRowDef="irisColumns"></tr>-->
<!--            <tr mat-row *matRowDef="let row; columns: irisColumns;"></tr>-->
<!--        </table>-->

    </mat-dialog-content>

    <mat-dialog-actions class="mt-16">
        <div class="w-100-p">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="end end">
                <button type="submit" mat-stroked-button (click)="validateForm()"
                        [disabled]="reportLineDataTable.data.length<=0 "
                        [ngClass]="reportLineDataTable.data.length<=0 ? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </div>
    </mat-dialog-actions>
</div>
