import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CurrencyPipe} from '@angular/common';
import {RelatedSparePart, RelatedSparePartRequest} from '../../models/spare-parts/relatedSparePart.model';
import {SparePart, SparePartCriteria, SparePartView} from '../../models/spare-parts/spare-part.model';
import {Page} from '../../models/page.model';
import {take} from 'rxjs/operators';
import {SparePartStock, StockRequest} from '../../models/spare-parts/stock.model';
import {StocksAvailabilityResponse} from '../../models/spare-parts/stocksAvailabilityResponse.model';
import {SupplierDTO} from '../../models/spare-parts/supplier.model';
import {SparePartSearchRequest} from '../../models/spare-parts/SparePartSearchRequest';
import {GLOBAL} from '../../app-config';


@Injectable({
    providedIn: 'root'
})
export class SparePartService {
    private path = `${GLOBAL.gatewayEndpoint}/spare-part-apis/v1`;

    constructor(public httpClient: HttpClient,
                private currencyPipe: CurrencyPipe) {
    }

    create(sparePart: SparePart): Observable<SparePart> {
        return this.httpClient.post<SparePart>(`${this.path}/spare-parts`, sparePart);
    }

    update(id: string, sparePart: SparePart): Observable<SparePart> {
        return this.httpClient.put<SparePart>(`${this.path}/spare-parts?id=${id}`, sparePart);
    }

    search(page: number, size: number, criteria: SparePartCriteria, matchingMode: string): Observable<Page<SparePartView>> {
        return this.httpClient.post<Page<SparePartView>>(`${this.path}/v1/spare-parts-views/search`, criteria, {
            params: new HttpParams()
                .append('page', '' + page)
                .append('size', '' + size)
                .append('matchingMode', matchingMode),
        }).pipe(take(1));
    }

    getStocks(sparePartCodes: string[], siteCode: string): Observable<SparePartStock[]> {
        let requestParams = new HttpParams();
        requestParams = requestParams.append('siteCode', siteCode);

        return this.httpClient.post<SparePartStock[]>(`${this.path}/stocks/search`, sparePartCodes, {
            params: requestParams,
        });
    }

    getAvailabilityOfStocks(siteCode: string, stockRequest: StockRequest[]): Observable<StocksAvailabilityResponse> {
        let requestParams = new HttpParams();
        requestParams = requestParams.append('siteCode', siteCode);

        return this.httpClient.post<StocksAvailabilityResponse>(`${this.path}/stocks/availability`, stockRequest, {
            params: requestParams
        });
    }

    getRelatedSpareParts(relatedSparePartRequest: RelatedSparePartRequest): Observable<RelatedSparePart[]> {
        let params = new HttpParams();
        if (relatedSparePartRequest.productCode) {
            params = params.append('productCode', relatedSparePartRequest.productCode);
        }
        if (relatedSparePartRequest.supplierCode) {
            params = params.append('supplierCode', relatedSparePartRequest.supplierCode);
        }

        if (relatedSparePartRequest.eanCode) {
            params = params.append('eanCode', relatedSparePartRequest.eanCode);
        }

        if (relatedSparePartRequest.brand) {
            params = params.append('brand', relatedSparePartRequest.brand);
        }
        if (relatedSparePartRequest.family1) {
            params = params.append('family1', relatedSparePartRequest.family1);
        }
        if (relatedSparePartRequest.managementSiteCode && relatedSparePartRequest.countryCode) {
            params = params
                .append('managementSiteCode', relatedSparePartRequest.managementSiteCode)
                .append('countryCode', relatedSparePartRequest.countryCode);
        }

        if (relatedSparePartRequest.withMedia) {
            params = params.append('withMedia', String(relatedSparePartRequest.withMedia));
        }

        return this.httpClient.get<RelatedSparePart[]>(`${this.path}/spare-parts/related`, {params});
    }

    findWithCriteria(code: string, codes: string[]): Observable<SparePart[]> {
        const searchRequest: SparePartSearchRequest = {};

        if (code !== null) {
            searchRequest.code = code;
        }
        if (codes !== null) {
            searchRequest.codes = codes;
        }

        return this.httpClient.post<SparePart[]>(`${this.path}/spare-parts/search`, searchRequest);
    }

    findSuppliers(page, size, criteria: { code: string; name: string, matchingMode?: string }): Observable<Page<SupplierDTO>> {
        return this.httpClient.post<Page<SupplierDTO>>(`${this.path}/suppliers/search?page=${page}&size=${size}`, criteria);
    }

    formatPriceAccordingToCurrency(value, currency) {
        return this.currencyPipe.transform(this.normalizeFloat(value), currency, '');
    }

    computeTotalPrice(totalLine) {
        return totalLine.reduce((total, totalLinePrice) => total + this.normalizeFloat(totalLinePrice), 0);
    }

    normalizeNumber(number: string): number {
        if (!number) {
            return 0.0;
        }
        number = number.replace(/,/g, '').replace(/\.(?=.*\.)/g, '');

        return parseFloat(number);
    }

    normalizeFloat(number) {
        if (!number) {
            return 0.0;
        }
        number += '';
        const chars = [',', '.'];
        let lastIndex = -1;
        let i;
        for (i = 0; i < chars.length; i++) {
            const t = number.lastIndexOf(chars[i]);

            if (t > lastIndex) {
                lastIndex = t;
            }
        }

        if (lastIndex === -1) {
            lastIndex = number.length;
        }

        let whole = number.substring(0, lastIndex);
        let precision = number.substring(lastIndex);
        for (i = 0; i < chars.length; i++) {
            whole = whole.replace(chars[i], '');
            precision = precision.replace(chars[i], '.');
        }
        number = whole + precision;
        return parseFloat(number);
    }

    getTotalLinePrice(productPrice, quantity, discount, vatRate): number {
        let totalQuantityPrice;
        let totalPrice = 0;
        if (!discount) {
            discount = 0;
        }
        if (productPrice && quantity) {
            totalQuantityPrice = quantity * productPrice;
            if (discount > 0) {
                totalPrice = totalQuantityPrice - totalQuantityPrice * (discount / 100);
            } else {
                totalPrice = totalQuantityPrice;
            }
        }
        if (!!vatRate && vatRate > 0) {
            totalPrice = totalPrice + totalPrice * (vatRate / 100);
        }
        return totalPrice;
    }

    getProductPrice(totalPrice: number, discount: number, vatRate: number): number {
        const totalUnitPriceWithoutVAT = totalPrice / (1 + (vatRate / 100));
        return totalUnitPriceWithoutVAT / (1 - (discount / 100));
    }

    /**
     * Cette méthode permet de spliter les spareParts autant de fois que de supplier
     * @param spareParts
     * @private
     */
    public splitSpareParts(spareParts: RelatedSparePart[]): RelatedSparePart[] {
        const newSpareParts = [];
        spareParts.forEach(sparePart => {
            const suppliers = sparePart.suppliers;
            if (suppliers?.length > 0) {
                suppliers?.forEach(supplier => {
                    const newSparePart = Object.assign({}, sparePart);
                    newSparePart.supplier = supplier;
                    newSpareParts.push(newSparePart);
                });
            } else {
                newSpareParts.push(sparePart);
            }
        });
        return newSpareParts;
    }

}
