import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../../../models/folder.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../../store/app.state';
import {QuotationType} from '../../../../../../../models/enums/quotationType.enum';
import {QuotationLine} from '../../../../../../../models/quotation.model';
import {InstructionUserTask} from '../../../../../../../models/instruction-user-task.model';

@Component({
  selector: 'app-waiting-spare-part-reception-itinerant',
  templateUrl: './waiting-spare-part-reception-itinerant.component.html',
  styleUrls: ['./waiting-spare-part-reception-itinerant.component.scss']
})
export class WaitingSparePartReceptionItinerantComponent implements OnInit {
    @Input() readonly folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    sparePartReceived: boolean;
    orderedSpareParts: QuotationLine[] = [];
    @Output() inputMap = new EventEmitter<any>();

    constructor(private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        if (!!this.folder){
            this.folderSparePartQuotationLines(this.folder);

        }

    }

    onSubmit(): void {
        this.inputMap.emit({});
    }

    private folderSparePartQuotationLines(folder: Folder): void {
        if (!!folder.quotations && folder.quotations?.length > 0){
            this.folder.quotations.forEach(q => {
                q.quotationLines.forEach(ql => {
                    if (ql.type === QuotationType.SPARE_PART){
                        this.orderedSpareParts.push(ql);
                    }
                });
            });
        }
    }

}
