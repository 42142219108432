import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {FolderPublic} from '../../../../models/folder.public.model';
import {PublicService} from '../../../../shared/services/public.service';
import {CommentType} from '../../../../models/enums/CommentType.enum';
import {ActivatedRoute} from '@angular/router';

export const AGREEMENT_NUMBER = 'agreementNumber';
export const CHOICE = 'choice';

@Component({
    selector: 'app-public-waiting-reparation-agreement',
    templateUrl: './public-waiting-reparation-agreement.component.html',
    styleUrls: ['./public-waiting-reparation-agreement.component.scss']
})
export class PublicWaitingReparationAgreementComponent implements OnInit {

    @Input() folder: FolderPublic;
    @Output() inputMap = new EventEmitter<any>();
    public reparationAgreementForm: FormGroup;
    private isSupplier: boolean;

    constructor(private folderService: PublicService,
                private activatedRoute: ActivatedRoute) {
        // empty
    }

    ngOnInit(): void {
        this.createForm();
        this.onChoiceChange();
        this.isSupplier = this.isSupplierWF();
    }

    onSubmit(): void {
        let dataToComplete;
        dataToComplete = {
            reparationAgreementAcceptation: this.reparationAgreementForm.value.choice,
            reparationAgreementNumber: this.reparationAgreementForm.value.agreementNumber
        };
        if (this.reparationAgreementForm.value.choice === true) {
            dataToComplete.productState = this.reparationAgreementForm.value.productState;
        } else {
            dataToComplete.outOfWarranty = this.reparationAgreementForm.value.outOfWarranty;
        }
        this.inputMap.emit(dataToComplete);
        if (this.readyToSent()) {
            const reasonType = this.isSupplier ? CommentType.REASON_OF_REFUS_REPARATION_SUPPLIER : CommentType.REASON_OF_REFUS_REPARATION_REPAIRER;
            if (this.isSupplier) {
                this.addSupplierComment(reasonType);
            } else {
                this.addRepairerComment(reasonType);
            }
        }
    }

    private addSupplierComment(reasonType: CommentType) {
        this.folderService.updateFolderWithSupplierComment(this.folder.externalUid, this.reparationAgreementForm.value.comment, reasonType)
            .subscribe(folder => {
                this.resetComment();
            });
    }

    private addRepairerComment(reasonType: CommentType) {
        this.folderService.addRepairerComment(this.folder.externalUid, this.reparationAgreementForm.value.comment, reasonType)
            .subscribe(folder => {
                this.resetComment();
            });
    }

    private resetComment() {
        this.reparationAgreementForm.controls['comment'].setValue(null);
        this.reparationAgreementForm.controls['comment'].setErrors(null);
    }

    private createForm(): void {
        this.reparationAgreementForm = new FormGroup({
            choice: new FormControl(null, [Validators.required]),
            agreementNumber: new FormControl(null),
            productState: new FormControl(null),
            outOfWarranty: new FormControl(null),
            comment: new FormControl('')
        });
    }

    onChoiceChange(): void {
        this.reparationAgreementForm.get('choice')
            .valueChanges
            .subscribe(choice => {
                if (choice !== null) {
                    if (choice) {
                        this.reparationAgreementForm.get('agreementNumber').setValidators(Validators.required);
                        this.reparationAgreementForm.get('productState').setValidators(Validators.required);

                        this.resetValidator(this.reparationAgreementForm.get('outOfWarranty'));
                        this.resetValidator(this.reparationAgreementForm.get('comment'));

                    } else {
                        this.reparationAgreementForm.get('outOfWarranty').setValidators(Validators.required);
                        this.reparationAgreementForm.get('comment').setValidators(Validators.required);

                        this.resetValidator(this.reparationAgreementForm.get('agreementNumber'));
                        this.resetValidator(this.reparationAgreementForm.get('productState'));
                    }

                    this.reparationAgreementForm.get('agreementNumber').updateValueAndValidity();
                    this.reparationAgreementForm.get('productState').updateValueAndValidity();
                    this.reparationAgreementForm.get('outOfWarranty').updateValueAndValidity();
                    this.reparationAgreementForm.get('comment').updateValueAndValidity();
                }
            });
    }

    isSupplierWF(): boolean {
        // @ts-ignore
        if (this.activatedRoute.url.value.map(val => val.path).includes('supplier')) {
            return true;
        } else {
            return false;
        }
    }

    private resetValidator(ValidatorValue: AbstractControl) {
        ValidatorValue.clearValidators();
        ValidatorValue.setValue(null);
    }

    readyToSent(): boolean {
        return !!this.reparationAgreementForm.value.comment;
    }

    formIsDisabled(): boolean {
        return this.reparationAgreementForm.invalid ;
    }
}
