import {Injectable} from '@angular/core';
import {GLOBAL} from '../../app-config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Page} from '../../models/page.model';
import {Supplier} from '../../models/supplier.model';
import {SupplierDocument} from '../../models/SupplierDocument';

@Injectable({
    providedIn: 'root'
})
export class SupplierService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api/supplier`;

    constructor(public httpClient: HttpClient) {
    }

    search(page: number, size: number, organizationCode: string, context: string, filters: Map<string, any>): Observable<Page<Supplier>> {
        let params = new HttpParams();
        params = params.append('page', String(page));
        params = params.append('size', String(size));
        params = params.append('organizationCode', String(organizationCode));
        params = params.append('context', String(context));
        return this.httpClient.post<Page<Supplier>>(`${this.path}/product-suppliers`, filters, {params: params});
    }

    getByCode(supplierCode: string, organizationCode: string, context: string): Observable<SupplierDocument> {
        let params = new HttpParams();
        params = params.append('organizationCode', String(organizationCode));
        params = params.append('context', String(context));

        return this.httpClient.get<SupplierDocument>(`${this.path}/${supplierCode}`, {params: params});
    }
}
