    <mat-card-content>
        <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start">
            <div fxLayout="row" fxLayoutAlign="start center">
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>
            <mat-checkbox formControlName="requestSubmitted" required>
                {{'RULE_WARRANTIES.FORM.FIELDS.PRODUCT_HS.SUPPLIER_CONFIRMATION' | translate}}
            </mat-checkbox>
            <app-comment-area [commentForm]="form.get('comment')"></app-comment-area>

        </form>
        <div fxLayoutAlign="end center" class="mt-24">
            <button type="submit" mat-stroked-button
                    (click)="onSubmit()"
                    [disabled]="form.invalid"
                    [ngClass]="form.invalid ? 'aster_btn_disabled' : 'aster_btn'">
                {{ 'BUTTON.VALIDATE' | translate }}
            </button>
        </div>
    </mat-card-content>