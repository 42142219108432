import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {select, Store} from '@ngrx/store';
import {allConfiguration} from '../../../../../store/configuration/configuration.selectors';
import {AppState} from '../../../../../store/app.state';
import {Observable} from 'rxjs';
import {ConfigurationReferential} from '../../../../../models/configurationReferential.model';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-add-return-info',
    templateUrl: './add-return-info.component.html',
    styleUrls: ['./add-return-info.component.css']
})
export class AddReturnInfoComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();

    @Input() variablesTask: any;
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    carriers$: Observable<ConfigurationReferential[]>;

    repairStatusForm: any = {
        form: null,
        placeholders: []
    };

    constructor(private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initCarrierList();
    }

    private initForm(): void {
        this.repairStatusForm.form = new FormGroup({
            carrier: new FormControl(null, Validators.required),
            trackingNumber: new FormControl(null, Validators.required),
        });
    }

    initCarrierList(): void {
        this.carriers$ = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.CARRIER}));
    }

    onSubmit(): void {
        this.inputMap.emit({
            'carrierCode': this.repairStatusForm.form.value.carrier.code,
            'trackingNumber': this.repairStatusForm.form.value.trackingNumber
        });
    }

}
