import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {COLOR_SCHEME_DOMAIN} from '../../widget.data';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {configurationItem} from '../../../../../store/configuration/configuration.selectors';
import {AppState} from '../../../../../store/app.state';
import {Unsubscriber} from '../../../../../unsubscriber';

@Component({
    selector: 'widget-pie-chart',
    templateUrl: './widget-pie-chart.component.html',
    styleUrls: ['./widget-pie-chart.component.scss'],
    animations: fuseAnimations
})
export class WidgetPieChartComponent extends Unsubscriber implements OnInit {

    @Input() widgetData;
    @Output() elementSelected = new EventEmitter();
    @Input() columnCount: number = null;
    @Input() id: string = null;
    @Output() updatedColumnCount = new EventEmitter<number>();
    @Output() dropWidget = new EventEmitter<number>();

    colorScheme = {
        domain: COLOR_SCHEME_DOMAIN
    };

    constructor(private store$: Store<AppState>,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.widgetData.data.forEach(widgetData => {
                this.anotherSubscription = this.store$.pipe(select(configurationItem, {configurationItemCode: ConfigCodeMapsEnum.DASHBOARD_LABEL, code: widgetData.name}))
                    .subscribe(name => {
                        widgetData['code'] = widgetData.name;
                        widgetData.name = name;
                    });
            }
        );
    }

    showDetails(event): void {
        this.elementSelected.emit({code: this.widgetData.data.filter(value => value.name === event.name)[0].code});
    }


    update(): void {
        if (!!this.columnCount) {
            this.updatedColumnCount.emit(this.columnCount);
        }

    }

    deleteWidget(): void {
        this.dropWidget.emit();
    }

    editWidget(): void {
        this.router.navigate(['dashboard/widget/update/' + this.id]);
    }
}

