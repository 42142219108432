export const GROUP_BY_FIELDS: any[] = [
    {name: 'currentWorkFlowStatus', type: 'workflowStatusList'},
    {name: 'workflowStatusDate', type: 'Date'},
    {name: 'newWarranty', type: 'string'},
    {name: 'warrantyReason', type: 'warrantyReasonList'},
    {name: 'operationMode', type: 'operationModeList'},
    {name: 'site', type: 'string'},
    {name: 'origin', type: 'originList'},
    {name: 'creationDate', type: 'Date'},
    {name: 'repairCenterCode', type: 'string'},
    {name: 'family1Code', type: 'family1List'},
    {name: 'family2Code', type: 'family2List'},
    {name: 'family3Code', type: 'family3List'},
    {name: 'family4Code', type: 'family4List'},
    {name: 'brand', type: 'brandList'},
    {name: '_id', type: 'string'},
    {name: 'appointmentDate', type: 'Date'}
];

export const FILTER_BY_FIELDS: any[] = [
    ...GROUP_BY_FIELDS,
    {name: 'closed', type: 'checkbox'}
];

export const AGGREGATION_TYPE: any[] = [
    'COUNT',
    'SUM',
    'AVERAGE',
    'MAX',
    'MIN'];
