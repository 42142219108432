import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, from, Observable} from 'rxjs';
import {KeycloakService} from 'keycloak-angular';
import {Location} from '@angular/common';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    public lemonUserLoaded$ = new BehaviorSubject(null);


    constructor(private keycloak: KeycloakService,
                private location: Location) {
    }

    public init() {
        const {keycloakConfig} = environment;
        return from(this.keycloak.init({
            config: keycloakConfig,
            initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                    window.location.origin + '/assets/silent-check-sso.html',
                checkLoginIframe: false
            },
            bearerPrefix: 'Bearer',
            enableBearerInterceptor: true,
            bearerExcludedUrls: ['/assets', '/public', '/error']
        }));
    }

    public loadUser(): Observable<any> {
        if (this.keycloak.excludedUrls &&
            !(this.keycloak.excludedUrls.some(url => url.urlPattern.test(this.location.path())))) {
            return from(this.keycloak.loadUserProfile());
        }
        return EMPTY;
    }

    public getRoles(): string[] {
        return this.keycloak.getUserRoles(true);
    }

    public logout(): void {
        this.keycloak.logout();
    }

    isLoggedIn(): Observable<boolean> {
        return from(this.keycloak.isLoggedIn());
    }

    login() {
        return from(this.keycloak.login());
    }

    events() {
        return this.keycloak.keycloakEvents$;
    }
}
