import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

export const COMMENT = 'comment';
export const CHOICE = 'choice';

@Component({
    selector: 'app-reparation-agreement-delay',
    templateUrl: './reparation-agreement-delay.component.html',
    styleUrls: ['./reparation-agreement-delay.component.scss']
})
export class ReparationAgreementDelayComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    public reparationAgreementDelayForm: FormGroup;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() folder: Folder;

    constructor() {
        // empty
    }

    ngOnInit(): void {
        this.createForm();
    }

    onSubmit(): void {
        this.inputMap.emit({
            "confirmSwap" : this.reparationAgreementDelayForm.value.choice, //accept delay => confirmSwap
            "reparationAgreementDelayComment" : this.reparationAgreementDelayForm.value.comment
        });
    }

    private createForm(): void {
        this.reparationAgreementDelayForm = new FormGroup({
            choice: new FormControl(null, [Validators.required])
        });
    }


    onChoiceChange(value: boolean): void {
        this.reparationAgreementDelayForm.get(CHOICE).setValue(value);
        if (value) {
            this.reparationAgreementDelayForm.addControl(COMMENT, new FormControl(null));
        } else {
            this.reparationAgreementDelayForm.removeControl(COMMENT);
        }
    }

    displayComment(): boolean {
        return this.reparationAgreementDelayForm.contains(COMMENT);
    }
}
