import {Injectable} from '@angular/core';
import {GLOBAL} from '../../../app-config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigurationReferentialDto} from '../../../models/configurationReferentialDto.model';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api/config-referential`;

    constructor(private httpClient: HttpClient) {
    }

    public load(cached: boolean): Observable<ConfigurationReferentialDto> {
        const params = new HttpParams().set('cached', String(cached));
        return this.httpClient.get<ConfigurationReferentialDto>(`${this.path}/front-claim`, {params: params});
    }
}
