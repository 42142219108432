    <mat-card-content fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <div>
                <strong>{{'TASK.VALIDATION_UNIT'|translate}}</strong>
            </div>
        </div>
        <form *ngIf="!!choiceModel.form" [formGroup]="choiceModel.form">
            <app-ordered-content *ngIf="!!orderedSpareParts" class="mb-24"
                                 [isUnderWarranty]="isSG"
                                 [orderedSpareParts]="orderedSpareParts"></app-ordered-content>
                <div class="mt-12">
                    <mat-radio-group (change)="onChoice($event)" formControlName="choice" class="ml-20"
                                     labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch"
                                     fxLayoutGap="10px">
                        <mat-radio-button [id]="status.choice" class="full-width-radio"
                                          *ngFor="let status of choiceModel.placeholders"
                                          [value]="status.choice">
                            <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div fxFlex>
                                    <div>{{ status.value | translate}}</div>
                                </div>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <app-comment-area [commentForm]="choiceModel.form.get('comment')"
                                  [isRequired]="isCommentRequired()">
                </app-comment-area>
                <div class="mt-24">
                    <div fxLayout="row" fxLayoutAlign="end end">
                        <div>
                            <button mat-stroked-button
                                    (click)="onSubmit()" type="submit"
                                    [disabled]="choiceModel.form.invalid"
                                    [ngClass]="choiceModel.form.invalid ? 'h-60 mt-4  aster_btn_disabled' : 'h-60 mt-4 aster_btn' ">
                                {{'BUTTON.VALIDATE' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
        </form>
    </mat-card-content>