import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-refund-customer',
    templateUrl: './refund-customer.component.html',
    styleUrls: ['./refund-customer.component.scss']
})
export class RefundCustomerComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    isRefunded: false;
    @Output() inputMap = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        this.inputMap.emit({});
    }
}
