<mat-card class="pt-12 pb-8" *ngIf="!!folder">

    <mat-card-content>
        <div gdGap="25px"
             gdAreas="folder incrementalReference status date managementSite productLocation action"
             gdAreas.lt-lg="folder status | incrementalReference date | managementSite productLocation action"
             gdAreas.lt-md="folder | incrementalReference | status | date | managementSite | productLocation | action"
             gdAreas.lt-sm="folder | incrementalReference | status | date | managementSite| productLocation | action">

            <div fxLayout="row" fxLayoutAlign="start center" gdArea="folder">
                <!--<div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-icon>
                        <svg viewBox="0 0 24 24">
                            <path
                                d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z"/>
                        </svg>
                    </mat-icon>
                    <span class="font-weight-900  card-references_title">
                            <span> {{ folder.reference }}</span>
                    </span>
                </div>-->
                <ng-container *ngIf="getReferencesSize(folder.externalReferences) > 0">
                    <div (click)="openExternalReferencesViewDialog()" class="mt-4 cursor-pointer link-hover ml-12"
                         id="showExternalReferences" [matTooltip]="'FOLDER.EXTERNAL_REFERENCES.OTHER' | translate">
                        <mat-icon matBadgePosition="above after" matBadge="red"
                                  [matBadge]="getReferencesSize(folder.externalReferences)">
                            bookmarks
                        </mat-icon>
                    </div>
                </ng-container>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" gdArea="incrementalReference">
                <mat-icon>folder</mat-icon>
                <div class="card-references_title">
                    <span class="font-weight-900">{{'FOLDER.INCREMENTAL_REFERENCE_NUMBER'| translate}}  :</span>
                    {{ folder.incrementalReference  }}
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" gdArea="status">
                <mat-icon>info</mat-icon>
                <div class="card-references_title">
                    <span class="font-weight-900">{{'FOLDER.STATUS.TITLE'| translate}}  :</span>
                    <ng-container
                        *ngIf="!!folder && !!folder.currentWorkflowStatus  && !!folder.currentWorkflowStatus.status && customStatusLabel == null">
                        {{'COMPONENT.' + folder.currentWorkflowStatus.status.toString() | translate}}
                    </ng-container>
                    <ng-container
                        *ngIf="!!folder && !!folder.currentWorkflowStatus  && !!folder.currentWorkflowStatus.status && !!customStatusLabel">
                        {{customStatusLabel }}
                    </ng-container>
                </div>
                <mat-icon class="timer-class mat-icon-ml3px">query_builder</mat-icon>
                <div class="timer-class card-references_title timer-span">
                    {{getTimeDays() >= '1' ? (getTimeDays() + ('GENERAL.STATUS_DATE.DAYS' | translate)) : getTimeHours() >= '1' ? (getTimeHours() + ('GENERAL.STATUS_DATE.HOURS' | translate)) : getTimeMinutes() + ('GENERAL.STATUS_DATE.MINUTES' | translate)}}
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" gdArea="date">
                <mat-icon>access_time</mat-icon>
                <div class="card-references_title">
                    <span class="font-weight-900">{{'FOLDER.CREATE_DATE'| translate}}  :</span>
                    {{ folder.createdBy.actionDate | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE.CREATION_DATE' | translate) : (currentLanguage$ | async)}}
                </div>
            </div>

            <div fxLayout="row"
                 fxLayoutAlign="start center"
                 gdArea="managementSite"
                 class="cursor-pointer link-hover"
                 (click)="showAllSites()">
                <div class="card-references_title">
                    <mat-icon class="icon-site">store_mall_directory</mat-icon>
                    <div class="card-references_title">
                        <div class="font-weight-900 site_name ml-4" [matTooltip]="'NAV.CONFIGURATIONS.SITE' |translate">
                            {{managementSite}}
                        </div>
                    </div>
                </div>
            </div>
            <!-- Action gread -->

            <div fxLayout="row" fxLayoutAlign="start center"
                 gdArea="productLocation"
                 class="cursor-pointer link-hover"
                 (click)="showProductLocation()">
                <div class="card-references_title ">
                    <mat-icon class="icon-site">location_on</mat-icon>
                    <div class="card-references_title">
                        <div class="font-weight-900 site_name ml-4 " [id]="productLocation"
                             [matTooltip]="'PRODUCT_LOCATION' | translate">
                            {{'PRODUCT_LOCATION_' + productLocation | translate}} <span *ngIf="!!productPosition"> ({{productPosition}})</span>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end end" gdArea="action">
                <div fxLayoutAlign="end end">
                    <!-- Start Icon -->
                    <button mat-icon-button (click)="updateFavorites()">
                        <mat-icon> {{isFolderFavorite() ? 'star' : 'star_border'}}
                        </mat-icon>
                    </button>
                    <!-- notification Icon -->
                    <button mat-icon-button (click)="showAllPushNotificationFolder()">
                        <mat-icon matBadgePosition="above after" matBadge="red"
                                  [matBadgeHidden]="totalElementNotification === 0"
                                  [matBadge]="totalElementNotification">notifications
                        </mat-icon>
                    </button>
                    <!-- Comment Icon -->
                    <button mat-icon-button (click)="showAllComments()"
                            [matTooltip]="'FOLDER.COMMENT.CONTENT' | translate">
                        <mat-icon matBadgePosition="above after" matBadge="red"
                                  [matBadge]="getSizeComments() !== 0 ? getSizeComments() : ''">comment
                        </mat-icon>
                    </button>
                    <div>
                        <button mat-icon-button [matMenuTriggerFor]="reportMenu" (click)="fetchAvailableReport()">
                            <mat-icon>local_printshop</mat-icon>
                        </button>
                    </div>
                    <div>
                        <button mat-icon-button [matMenuTriggerFor]="notificationMenu"
                                (click)="fetchAvailableCommunication()">
                            <mat-icon>email</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="totalAlerts!== 0">
                        <button mat-icon-button (click)="showAllAlerts()"
                                [matTooltip]="'FOLDER.ALERT.CONTENT' | translate">
                            <mat-icon matBadgePosition="above after" matBadge="red"
                                      [matBadgeHidden]="totalAlerts === 0"
                                      [matBadge]="totalAlerts">warning
                            </mat-icon>
                        </button>
                    </div>

                    <mat-menu #reportMenu="matMenu" xPosition="before">
                        <div *ngFor="let report of availableReportList">
                            <button mat-menu-item [matTooltip]="report.reportDisplayedName"
                                    (click)="regenerateReport(report)">
                                <span>{{report.reportDisplayedName}}</span>
                            </button>
                        </div>
                    </mat-menu>
                    <mat-menu #notificationMenu="matMenu" xPosition="before">
                        <div *ngFor="let notif of availableNotificationList">
                            <button mat-menu-item [matTooltip]="notif.displayedName"
                                    (click)="regenerateNotification(notif)">
                                <span>{{notif.displayedName}}</span>
                            </button>
                        </div>
                    </mat-menu>
                    <ng-container>
                        <button mat-icon-button [matMenuTriggerFor]="menuSettings" id="menuSettings">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menuSettings="matMenu">
                            <button mat-menu-item [matTooltip]="'BUTTON.DELETE_FOLDER' | translate"
                                    (click)="removeFolder()"
                                    *ngIf="isAdminPlatana$ | async"
                                    id="delete_folder">
                                <mat-icon> delete_forever</mat-icon>
                                <span>{{'BUTTON.DELETE_FOLDER' | translate}}</span>
                            </button>
                            <ng-container *ngIf="!checkIfFolderClosed()">
                                <button mat-menu-item [matTooltip]="'BUTTON.CHANGE_SITE' | translate"
                                        (click)="changeManagementSite()">
                                    <mat-icon>store_mall_directory</mat-icon>
                                    <span>{{'BUTTON.CHANGE_SITE' | translate}}</span>
                                </button>
                            </ng-container>
                            <button mat-menu-item
                                    *ngIf="(hasScope(Scopes.FOLDER_TO_REPAIRER) | async)"
                                    (click)="sendAsychroneToRepairCenter()"
                                    [matTooltip]="'BUTTON.SEND_TO_REPAIR_CENTER' | translate">
                                <mat-icon>send</mat-icon>
                                <span>{{'BUTTON.SEND_TO_REPAIR_CENTER' | translate}}</span>
                            </button>

                            <button mat-menu-item
                                    *ngIf="(hasScope(Scopes.FOLDER_TO_EXTERNAL_SYSTEM) | async) && folder.context === 'SAV'"
                                    (click)="sendAsynchroneToExternalSystem()"
                                    [matTooltip]="'BUTTON.SEND_TO_EXTERNAL_SYSTEM'| translate">
                                <mat-icon>send</mat-icon>
                                <span>
                                    {{'BUTTON.SEND_TO_EXTERNAL_SYSTEM' | translate}}
                                </span>
                            </button>
                            <ng-container *ngIf="!checkIfFolderClosed() && (hasScope(Scopes.CLOSE_FOLDER) | async)">
                                <button mat-menu-item
                                        (click)="closeFolder()"
                                        [matTooltip]="'BUTTON.CLOSE_FOLDER' | translate">
                                    <mat-icon>local_offer</mat-icon>
                                    <span>{{('BUTTON.CLOSE_FOLDER' | translate) }}</span>
                                </button>
                            </ng-container>
                            <button mat-menu-item
                                    (click)="openAddExternalReferencesDialog()"
                                    [matTooltip]="'FOLDER.EXTERNAL_REFERENCES.ADD' | translate"
                                    id="external_reference_folder">
                                <mat-icon>library_add</mat-icon>
                                <span>{{'FOLDER.EXTERNAL_REFERENCES.ADD' | translate}}</span>
                            </button>

                            <button mat-menu-item
                                    *ngIf="hasWorkOrderWithoutNumber()"
                                    (click)="displayFetchWorkOrderNumbersDialog()"
                                    [matTooltip]="'BUTTON.FETCH_WORK_ORDER_NUMBER' | translate">
                                <mat-icon>call_received</mat-icon>
                                <span>{{'BUTTON.FETCH_WORK_ORDER_NUMBER' | translate}}</span>
                            </button>

                            <button mat-menu-item
                                    *ngIf="folderCanBeRollback() && (hasScope(Scopes.ROLLBACK_FOLDER_STATUS) | async)"
                                    (click)="changeCurrentWorkflowStatusDialog()"
                                    [disabled]="!isRollBackAllowed"
                                    [matTooltip]="'FOLDER.CHANGE_CURRENT_STATUS'| translate">
                                <mat-icon>history</mat-icon>
                                <span>{{'FOLDER.CHANGE_CURRENT_STATUS'|translate}}</span>
                            </button>
                            <button mat-menu-item
                                    (click)="sendCommunication()"
                                    [matTooltip]="'FOLDER.SEND_COMMUNICATION'| translate">
                                <mat-icon>mail_outline</mat-icon>
                                <span>{{'FOLDER.SEND_COMMUNICATION'|translate}}</span>
                            </button>

                            <button mat-menu-item *ngIf="!checkIfFolderClosed() && checkIfLMAndStoreBanne()"
                                    (click)="sendProtocolNotification()"
                                    [matTooltip]="'FOLDER.SEND_PROTOCOL_NOTIFICATION'|translate">
                                <mat-icon>mail_outline</mat-icon>
                                <span>{{'FOLDER.SEND_PROTOCOL_NOTIFICATION'|translate}}</span>
                            </button>

                            <!--<button (click)="suspendFolder()"
                                    *ngIf="!folder?.pendingStatus && !checkIfFolderClosed()"
                                    [matTooltip]="'BUTTON.PENDING_FOLDER' | translate "
                                    mat-menu-item>
                                <mat-icon>pause_presentation</mat-icon>
                                <span>{{'BUTTON.PENDING_FOLDER' | translate}}</span>
                            </button> -->

                            <ng-container *ngIf="(hasScope(Scopes.FOLDER_REOPEN) | async) && checkIfFolderClosed()">
                                <button (click)="reOpenFolder()"
                                        [matTooltip]="'BUTTON.REOPEN_FOLDER' | translate "
                                        mat-menu-item>
                                    <mat-icon>refresh</mat-icon>
                                    <span>{{'BUTTON.REOPEN_FOLDER' | translate}}</span>
                                </button>
                            </ng-container>

                            <ng-container  *ngIf="(hasScope(Scopes.ANONYMIZE_CUSTOMER_DETAILS) | async) && checkIfFolderClosed()">
                                <button (click)="anonymizeCustomerDetails()"
                                        [matTooltip]="'BUTTON.REOPEN_FOLDER' | translate "
                                        mat-menu-item>
                                    <mat-icon>visibility_off</mat-icon>
                                    <span>{{'BUTTON.ANONYMIZE' | translate}}</span>
                                </button>
                            </ng-container>

                        </mat-menu>
                    </ng-container>
                </div>
            </div>
        </div>

    </mat-card-content>
</mat-card>

