    <mat-card-content>
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
        <app-product-accessories *ngIf="showProductAccessories" [claim]="folder" (productAccessoriesEmitter)="updateSelectedAccessories($event)"></app-product-accessories>

            <div class="mt-24">
                <div *ngIf="shouldDisplayDownloadButton()" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start"
                         fxLayoutGap="12px" class="mt-8">
                        <div class="text-left cursor-pointer link-underline" (click)="getShipmentAttachment()">
                            {{'FOLDER.PARCEL_TO_SEND.DOWNLOAD.DOCUMENT' | translate}}
                        </div>
                    </div>
                </div>
                <div style="margin: 10px 0 10px 0" *ngIf="file">
                    {{'FOLDER.REPORT.DOWNLOAD' | translate}}:
                    <a (click)="getReportAttachment()" class="cursor-pointer">
                                <span>
                                    <mat-icon>file_copy</mat-icon>
                                    {{file.name}}
                                </span>
                    </a>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column">
                    <mat-card-subtitle>{{('FOLDER.PARCEL_TO_SEND.RECIPIENT.' + shipmentMode?.toString()) |translate}}</mat-card-subtitle>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" *ngIf="!reservationHasAnomalie">
                    <mat-card-subtitle>{{('FOLDER.SHIPMENT.LABEL_GENERATION') |translate}}</mat-card-subtitle>
                </div>
                <div>
                    <mat-checkbox [id]="'parcel_sent'"
                                  [(ngModel)]="shipmentInProgress">{{'BUTTON.SHIPMENT_SENT' | translate}}</mat-checkbox>
                </div>

                <div fxLayout="row" fxLayoutAlign="end">
                    <button mat-stroked-button (click)="onSubmit()"
                            type="submit"
                            [disabled]="!shipmentInProgress"
                            [ngClass]="!shipmentInProgress?'aster_btn_disabled':'aster_btn'">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </div>
    </mat-card-content>