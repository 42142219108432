<div *ngIf="!!data && !!data.repairer" fxLayout="row" fxLayoutAlign="space-between center center" fxLayoutGap="20px"
     mat-dialog-title style="font-size: medium">
    <div class="text-bold">
        {{'FOLDER.REPAIR.DIALOG.TITLE' | translate}}: {{data.repairer.label}}
    </div>
    <button mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <div class="text-bold">{{'CODE_LABEL_CONFIG.HEADERS.CONTACT' | translate}}</div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8" >
                <mat-icon>person_outline</mat-icon>
                <div>
                    {{getUserDetails()}}
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8">
                <mat-icon>local_phone</mat-icon>
                <div fxLayout="row">
                    <mat-icon *ngIf="!!formattedPhoneNumber">
                        <span
                            *ngIf="isValidCountryCode(formattedPhoneNumber?.countryCode); else globeIcon">
                            <span style="vertical-align: sub;"
                                  [attr.class]="'flag-icon h-16 w-20 flag-icon-' + getCountryCodeByPhoneNumber(formattedPhoneNumber.countryCode) | lowercase"></span>
                        </span>
                        <ng-template #globeIcon>
                            public
                        </ng-template>
                    </mat-icon>
                    <div class="mt-4">
                        {{getFormattedPhone(formattedPhoneNumber?.phoneNumber, formattedPhoneNumber?.countryCode)}}
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8">
                <mat-icon>smartphone</mat-icon>
                <div fxLayout="row">
                    <mat-icon *ngIf="!!formattedMobileNumber">
                        <span
                            *ngIf="isValidCountryCode(formattedMobileNumber?.countryCode); else globeIcon">
                            <span style="vertical-align: sub;"
                                  [attr.class]="'flag-icon h-16 w-20 flag-icon-' + getCountryCodeByPhoneNumber(formattedMobileNumber?.countryCode) | lowercase"></span>
                        </span>
                        <ng-template #globeIcon>
                            public
                        </ng-template>
                    </mat-icon>
                    <div class="mt-4">
                        {{getFormattedPhone(formattedMobileNumber?.phoneNumber, formattedMobileNumber?.countryCode)}}
                    </div>
                </div>
            </div>

            <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <mat-icon>email</mat-icon>
                <div>
                    {{!!data.repairer.contact ? data.repairer.contact.email : '-'}}
                </div>
            </div>
            <div *ngIf="data.repairer.contact?.website" class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <mat-icon>language</mat-icon>
                <div>
                    {{!!data.repairer.contact ? data.repairer.contact.website : '-'}}
                </div>
            </div>
        </div>

        <div fxLayout="column">
            <div class="text-bold">{{'CODE_LABEL_CONFIG.HEADERS.ADDRESS' | translate}}</div>
            <div *ngIf="!!data.repairer.completeAddress" class="mt-8" fxLayout="row" fxLayoutAlign="start center"
                 fxLayoutGap="15px">
                <mat-icon class="mr-8">
                    <span
                        *ngIf="!!data.repairer.completeAddress.countryCode && isValidCountryCode(data.repairer.completeAddress.countryCode); else globeIcon">
                        <span
                            [attr.class]="'flag-icon h-16 w-20 flag-icon-' + data.repairer.completeAddress.countryCode | lowercase"
                            style="vertical-align: sub;"></span>
                    </span>
                    <ng-template #globeIcon>
                        public
                    </ng-template>
                </mat-icon>
                <div>
                    {{getAddressRepairer()}}
                </div>
            </div>
            <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <mat-icon>location_city</mat-icon>
                <div>
                    {{getCompleteAddress()}}
                </div>
            </div>
        </div>
    </div>

</mat-dialog-content>
