import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-show-image-modal',
    templateUrl: './show-image-modal.component.html',
    styleUrls: ['./show-image-modal.component.scss']
})
export class ShowImageModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public images$: Observable<string[]>) {
    }
}
