import {AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewEncapsulation} from '@angular/core';
import {Folder, IFolderUpdateRequest, Service} from '../../../../../models/folder.model';
import {BackOfficeService} from '../../../../../shared/services/back-office.service';
import {TaskVariables} from '../../task.variables';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Observable} from 'rxjs';
import {AppointmentProposal} from '../../../../../models/appointment.model';
import * as uuid from 'uuid';
import {AppointmentType} from '../../../../../models/enums/appointmentType.enum';
import {FolderService} from '../../../../../shared/services/folder.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-book-appointment',
    templateUrl: './book-appointment.component.html',
    styleUrls: ['./book-appointment.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class BookAppointmentComponent implements OnInit, AfterContentChecked {
    selectedDate: any;
    today = new Date();
    availableDates: Map<number, number[]> = new Map();
    selectedDateHtmlElement: any;
    hasError = false;

    @Output() inputMap = new EventEmitter<any>();
    @Output() previewTask = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() workflowStatus: string;
    timeslots: any[] = [];
    selectedTimeSlot: any;
    serviceType: string;
    bookAppointmentInProgress = false;

    constructor(private store$: Store<AppState>,
                private cdRef: ChangeDetectorRef,
                private renderer: Renderer2,
                private folderService: FolderService,
                private backOfficeService: BackOfficeService) {

    }


    ngOnInit(): void {
        this.store$.dispatch(new StartLoading());
        this.serviceType = this.variablesTask[TaskVariables.serviceType];
        const date = moment();
        this.getAvailableDates(date).subscribe(dataMonth1 => {
            date.set({
                'date': 1
            }).add(1, 'M');
            this.getAvailableDates(date).subscribe(dataMonth2 => {
                this.availableDates[(moment().month() % 12) + 1] = dataMonth1;
                this.availableDates[(moment().add(1, 'M').month() % 12) + 1] = dataMonth2;
                this.store$.dispatch(new StopLoading());
            }, () => {
                this.hasError = true;
                this.store$.dispatch(new StopLoading());
            });
        }, () => {
            this.hasError = true;
            this.store$.dispatch(new StopLoading());
        });
    }

    ngAfterContentChecked(): void {
        this.cdRef.detectChanges();
    }

    onDateChanged($event: any): void {
        this.store$.dispatch(new StartLoading());
        this.selectedTimeSlot = null;
        this.selectedDate = $event;
        this.backOfficeService.getAvailableAppointments(this.folder.organization.code, this.serviceType,
            this.folder.product.code, this.folder.newWarranty.warranty, this.folder.newWarranty.warrantyReason,
            moment(this.selectedDate).format('YYYY-MM-DD'), this.folder.context)
            .subscribe(timeslots => {
                this.timeslots = timeslots;
                this.store$.dispatch(new StopLoading());
            }, () => {
                this.store$.dispatch(new StopLoading());
            });
    }

    getAvailableDates(date: Moment): Observable<any> {
        return this.backOfficeService.getAvailableDates(this.folder.organization.code, this.serviceType,
            this.folder.product.code, this.folder.newWarranty.warranty, this.folder.newWarranty.warrantyReason,
            moment(date).format('YYYY-MM-DD'), this.folder.context);
    }


    changeDateCellColor($event): void {
        // handle selections starting from the second one
        if (!!this.selectedDateHtmlElement && ['DIV', 'TD'].indexOf(this.selectedDateHtmlElement.nodeName) >= 0) {
            // handle both cases if the user click on <td> or <div> to delete the css class from the DOM
            if (this.selectedDateHtmlElement.nodeName === 'TD') {
                this.renderer.removeClass(this.selectedDateHtmlElement, 'mat-calendar-body-selected');
            }
            if (this.selectedDateHtmlElement.nodeName === 'DIV') {
                this.renderer.removeClass(this.selectedDateHtmlElement.parentElement, 'mat-calendar-body-selected');
            }
        }
        // inject the css class if the user click on the <td> and the <td> is not the date label on top
        if ($event.target.nodeName === 'TD' && this.isDateValid($event.target.className) && !$event.target.className.includes('mat-calendar-body-label')) {
            this.renderer.addClass($event.target, 'mat-calendar-body-selected');
        }
        // inject the css class if the user click on the <div> AND if the date is valid (not a disabled cell)
        if ($event.target.nodeName === 'DIV' && this.isDateValid($event.target.parentElement.className) && $event.target?.className.includes('mat-calendar-body-cell-content')) {
            this.renderer.addClass($event.target.parentElement, 'mat-calendar-body-selected');
        }
        // overwrite the current selected HTML element
        this.selectedDateHtmlElement = $event.target;
    }


    private isDateValid(className): boolean {
        return !className.includes('mat-calendar-body-disabled');
    }

    isDateAvailable(date: Moment): boolean {
        return this.filterGateway(date);
    }


    // trick to handle the validate button (enabled/disabled)
    setClass(): any {
        return (date: Moment) => {
            if (date.date() === 1) {
                this.selectedDate = null;
                this.selectedTimeSlot = null;
            }
        };
    }


    filterDate = (date: Moment): boolean => {
        return this.filterGateway(date);
    }

    filterGateway = (date: Moment): boolean => {
        const diff = date.startOf('day').diff(moment(Date.now()).startOf('day'), 'days');
        return diff !== 0 && !!this.availableDates && !!this.availableDates[date.month() + 1]
            && this.availableDates[date.month() + 1].includes(moment(date).format('YYYY-MM-DD'));
    }

    completeTask(): void {
        this.store$.dispatch(new StartLoading());
        const appointmentProposal: AppointmentProposal = {
            appointmentId: uuid.v4(),
            date: moment(this.selectedDate).format('YYYY-MM-DD'),
            startTime: this.selectedTimeSlot.startTime,
            endTime: this.selectedTimeSlot.endTime,
            type: AppointmentType[this.serviceType]  // VISIO_QUALIFICATION / VISIO_REPAIR
        };
        this.backOfficeService.addAppointment(this.folder.organization.code, this.serviceType,
            this.folder.product.code, this.folder.newWarranty.warranty,
            this.folder.newWarranty.warrantyReason, appointmentProposal, this.folder.context).subscribe(appointment => {
            this.inputMap.emit({
                'appointment': JSON.stringify(appointmentProposal),
                'proposalTechnicianId':  appointment.proposalTechnicianId
            });
            this.store$.dispatch(new StopLoading());

        }, () => {
            this.store$.dispatch(new StopLoading());
        });
    }

    watchDates(): boolean {
        return Object.keys(this.availableDates).length > 0;
    }

    selectTimeSlot(timeSlot: any): void {
        this.selectedTimeSlot = timeSlot;
    }

    isFormInvalid(): boolean {
        return !this.selectedTimeSlot || !this.selectedDate;
    }

    getPreviewTask(): void {
        this.bookAppointmentInProgress = true;
        const serviceId = this.variablesTask[TaskVariables.requestedServiceId];
        this.store$.dispatch(new StartLoading());
        const service =  this.folder.services.filter(value => value.finished === false && value.id === serviceId)[0];
        service.finished = true;
        let servicesToUpdate: Service[] = [];
        servicesToUpdate.push(service);
        const folderUpdateRequest: IFolderUpdateRequest = {
            services: servicesToUpdate
        };
        this.store$.dispatch(new StartLoading());

        // update the folder
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest)
            .then(data => {
                this.previewTask.emit({
                    'currentTask': 'BOOK_APPOINTMENT',
                    'previewsTask': 'CHOOSE_OPERATING_MODE'
                });
                this.store$.dispatch(new StopLoading());
            }).catch(error => {
            this.store$.dispatch(new StopLoading());
        });
    }
}
