<ng-container *ngIf="!!customerForm">
    <form [formGroup]="customerForm" connectFormToStore>
        <div fxLayout="row"
             fxLayoutAlign="start center" class="py-12">
            <customer-type></customer-type>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" class="py-12">
            <app-preferred-languages></app-preferred-languages>
        </div>

        <ng-container *ngIf="(this.searchCustomerInvoicesFeature | async) && (customerReferenceExist$ | async) && !(isStockClaimCreation$ | async)">
            <div fxLayout="row" fxLayoutGap="10px" class="customer-invoices-btn">
                <button type="button" mat-stroked-button color="accent"
                        (click)="findInvoiceByClient()">
                    {{ 'FOLDER.CREATE.CLIENT.SEARCH_INVOICE' | translate }}
                </button>
            </div>
        </ng-container>

        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutAlign.lt-sm="center stretch"
             fxLayoutAlign="space-between center"
             fxLayoutGap="20px"
             fxLayoutGap.lt-sm="10px">
            <div *ngFor="let field of fields.registrationInfo" [fxFlex]="field.size">
                <searchable-field [field]="field"
                                  [customerApiInfo]="customerApiInfo"
                                  (onEnterKey)="searchCustomer(field.name)">
                </searchable-field>
            </div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="20px">
            <mat-form-field appearance="outline" fxFlex="20">
                <mat-label>
                    {{'CUSTOMER.CIVILITY'|translate}}
                </mat-label>
                <mat-select formControlName="gender"
                            [disabled]="false"
                            [required]="customerForm.get('gender') | hasRequiredField">
                    <mat-option *ngFor="let civility of civilityOptions"
                                [value]="civility.key">
                        <span>{{ civility.label | translate}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div *ngFor="let field of fields.nameInfo" [fxFlex]="field.size">
                <searchable-field [field]="field"
                                  [customerApiInfo]="customerApiInfo"
                                  (onEnterKey)="searchCustomer()">
                </searchable-field>
            </div>
        </div>

        <app-professional-folder-form *ngIf="isProfessional$ | async"
                                      [customerDetailForm]="customerForm">
        </app-professional-folder-form>

        <customer-contact [isRequiredField]="isNeitherSAVNorProductRecall$ | async"
                          [customerApiInfo]="customerApiInfo"
                          (search)="searchCustomer()">
        </customer-contact>

        <app-generic-address-form [isRequiredFields]="isNeitherSAVNorProductRecall$ | async"
                                  [addressesInput]="addressInput$ | async"
                                  [customerApiInfos]="customerApiInfo"
                                  [validateOnLoadAddress]="true">
        </app-generic-address-form>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <customer-search>
            </customer-search>
            <div fxFlexOffset="57" class="mt-12">
                <a (click)="resetCustomer()"
                   class="cursor-pointer px-24">
                    {{'FOLDER.CREATE.BUTTON_CANCEL' | translate}}
                </a>
            </div>
        </div>
    </form>
</ng-container>