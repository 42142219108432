import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {SnackbarNotifComponent} from './shared/snackbar-notif/snackbar-notif.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private snackBar: MatSnackBar,
                private translateService: TranslateService,
                private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    this.showSnackbarError(this.getErrorMessage(httpErrorResponse.error));
                    return throwError(httpErrorResponse);
                })
            );
    }

    private getErrorMessage(error: any) {
        if (!!error.errorType && error.errorType === 'BUSINESS') {
            return this.getBusinessErrorMessage(error);
        } else {
            return this.getTechnicalErrorMessage(error);
        }
    }

    private getBusinessErrorMessage(error: any) {
        if (this.hasTranslation(error.errorCode)) {
            return this.translateService.instant(error.errorCode);
        } else if (this.hasTranslation('ERRORS.BUSINESS.DETAILED.' + error.errorCode)) {
            return this.translateService.instant('ERRORS.BUSINESS.DETAILED.' + error.errorCode);
        } else if (this.hasTranslation('ERRORS.TECHNICAL.DETAILED.' + error.errorCode)) {
            return this.translateService.instant('ERRORS.TECHNICAL.DETAILED.' + error.errorCode);
        } else {
            return error.detail;
        }
    }

    private getTechnicalErrorMessage(error: any) {
        switch (error.status) {
            case 401:
            case 403:
                this.router.navigate(['/']).then();
                return this.translateService.instant('ERRORS.BUSINESS.DETAILED.USER_UNAUTHORIZED');
            default:
                return this.translateService.instant('ERRORS.TECHNICAL.COMMON');
        }
    }

    private showSnackbarError(message: any) {
        this.snackBar.openFromComponent(SnackbarNotifComponent, {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['red-700', 'multi-line-snackbar'],
            data: {message: message, snackType: 'Error'}
        });
    }

    private hasTranslation(key: string): boolean {
        return this.translateService.instant(key) !== key;
    }
}
