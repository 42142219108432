<h1 mat-dialog-title>  {{'FOLDER.WORKFLOW.CANCEL_INTERVENTION' | translate}}</h1>
<div mat-dialog-content>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

        <form [formGroup]="form" class="w-100-p">
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>{{'FOLDER.WORKFLOW.CANCELLATION_DESCRIPTION' | translate}}</mat-label>
                <textarea matInput formControlName="cancelIntervention" required></textarea>
                <mat-error>{{'CUSTOMER.REQUIRED_FIELD' | translate}}</mat-error>
            </mat-form-field>
        </form>
    </div>

</div>


<div mat-dialog-actions fxLayoutAlign="end center" fxLayoutGap="10">
    <button mat-button [mat-dialog-close]="null"
            class="aster_btn_cancel">{{'BUTTON.CLOSE' | translate}}</button>
    <button mat-raised-button [disabled]="form.invalid"
            [ngClass]="form.invalid?'aster_btn_disabled':'aster_btn'"
            (click)="close()">{{'BUTTON.VALIDATE' | translate}}</button>
</div>
