import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import * as fileSaver from 'file-saver';
import {ConfigExtraCodesEnum} from 'app/models/codeLabelObjectWithExtra.model';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {DashboardService} from '../../../../../../shared/services/dashboard.service';
import {TranslateService} from '@ngx-translate/core';
import {DashboardHelperService} from '../../../../../../shared/services/dashboard-helper.service';
import {UploadModalComponent} from '../../../../../../shared/upload-modal/upload-modal.component';
import {UploadFileDialogResponse} from '../../../../../../shared/upload-modal/upload.file.dialog.response';
import {CloseModal} from '../../../../../../shared/modal/CloseModal';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {PageSearchRequest} from '../../../../../../models/page-search-request.model';
import {SnackBarService} from '../../../../../../shared/services/snack-bar.service';


@Component({
    selector: 'app-dashboard-list',
    templateUrl: './dashboard-list.component.html',
    styleUrls: ['./dashboard-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class DashboardListComponent implements OnInit, OnDestroy {
    private closeModal = new CloseModal();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    configCode: ConfigExtraCodesEnum = ConfigExtraCodesEnum.DASHBOARD;
    codeLabelObjectsDataSource: any = {
        dataSource: new MatTableDataSource(),
        columnsToDisplay: ['organizationCode', 'context', 'businessLink', 'siteCode', 'user', 'role', 'actions'],
        expandedElement: null,
        filterForm: null,
        page: null,
        pageSizeOptions: [10, 25, 50, 100],
    };

    subscriptions: Array<Subscription> = [];

    constructor(private matDialog: MatDialog,
                private snackBar: SnackBarService,
                private activatedRoute: ActivatedRoute,
                private dashboardService: DashboardService,
                private router: Router,
                private translateService: TranslateService,
                private store$: Store<AppState>,
                public dashboardHelperService: DashboardHelperService
    ) {
    }

    /**
     * unsubscribe from all the observables after destroying the component
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    /**
     *  Init some stuff when the component is loaded and attached to the DOM
     */
    ngOnInit(): void {
        this.initCodeLabelObjectsDataSource();
        this.initFilterForm();
    }

    /**
     * Init the filtering form
     */
    initFilterForm(): void {
        this.codeLabelObjectsDataSource.filterForm = this.dashboardHelperService.createFilterForm();
    }

    /**
     * Init the datasource
     */
    initCodeLabelObjectsDataSource(): void {
        this.codeLabelObjectsDataSource.dataSource.paginator = this.paginator;
        this.loadCodeLabelObjectsData();
    }

    /**
     * Fetch the data based on pagination or filtering criteria
     */
    loadCodeLabelObjectsData(): void {
        this.store$.dispatch(new StartLoading());
        const inputs = this.dashboardHelperService.prepareSearchCriteria();
        const pageCriteria: PageSearchRequest = {
            page: this.getPageIndex(),
            size: this.getPageSize(),
        };

        const subscription = this.dashboardService.getAllDashboards(inputs, pageCriteria).subscribe(page => {
            this.codeLabelObjectsDataSource.dataSource.data = [];
            this.store$.dispatch(new StopLoading());
            if (!!!page) { // in case of no result
                // initialize the page object
                this.codeLabelObjectsDataSource.page = {};
                this.codeLabelObjectsDataSource.page.totalElements = 0;
            }
            if (!!page && !!page.content) {
                this.codeLabelObjectsDataSource.dataSource.data = this.dashboardHelperService.extractDataFromPage(page.content);
                this.codeLabelObjectsDataSource.page = page;
            }
        }, () => {
            this.store$.dispatch(new StopLoading());
        });

        this.subscriptions.push(subscription);
    }

    /**
     * Fire the search action
     */
    onSearch(): void {
        this.loadSearchResult();
    }

    /**
     * Load all the data after clearing a filter input
     * @param fieldName
     */
    onClearSearchFormFiled(fieldName: string): void {
        this.codeLabelObjectsDataSource.filterForm.get([fieldName]).setValue('');
        this.loadSearchResult();
    }

    /**
     * Reset search form by emptying all inputs
     */
    onResetSearchFilter(): void {
        this.dashboardHelperService.resetSearchFilter(this.codeLabelObjectsDataSource.filterForm);
        this.loadSearchResult();
    }

    /**
     * Fetch data by getting all the initial values from the url into an object as a queries
     */
    loadSearchResult(): void {
        const queries = this.dashboardHelperService.prepareQueryParam(this.codeLabelObjectsDataSource.filterForm);

        // in case of a new search, we should go back to the first page
        if (this.getPageIndex() !== 0) {
            this.paginator.pageIndex = 0;
            queries['page'] = 0;
        }

        this.router.navigate([], {
            queryParams: queries,
            queryParamsHandling: 'merge',
        }).finally(() => {
            this.loadCodeLabelObjectsData();
        });
    }

    /**
     *Get the page index from the url
     */

    getPageIndex(): number {
        const pageIndex = this.activatedRoute.snapshot.queryParams.page;
        return pageIndex > 0 ? pageIndex : 0;
    }

    /**
     *Get the page size from the url
     */
    getPageSize(): number {
        const pageSize = this.activatedRoute.snapshot.queryParams.size;
        return pageSize > 0 ? pageSize : 10;
    }


    /**
     * Reset search input
     * @param key
     */
    resetSearch(key): void {
        if (key === '') {
            this.loadSearchResult();
        }
    }

    /**
     * Redirect to add a new dashboard configuration
     */
    onCreateNew(): void {
        this.router.navigate(['dashboard/add']);
    }

    onEdit(dashboard: any): void {
        this.router.navigate(['dashboard/update/' + dashboard.id]);
    }

    /**
     * Delete a dashboard from the list
     * @param index :  index in the table
     * @param dashboard:  dashbaord object
     */
    onDelete(index: any, dashboard: any): void {
        this.dashboardService.deleteDashboard(dashboard.id).subscribe(() => {
            this.showSnackBar('SUCCESS_DELETE');
            this.codeLabelObjectsDataSource.dataSource.data.splice(index, 1);
            this.codeLabelObjectsDataSource.dataSource.connect().next(this.codeLabelObjectsDataSource.dataSource.data);
            this.codeLabelObjectsDataSource.page.totalElements -= 1;
        });
    }

    /**
     * Handle the pagination events (next page, previous page, size per page, ..)
     * @param event
     */
    paginate(event: PageEvent): void {
        this.router.navigate([], {
            queryParams: {
                page: event.pageIndex,
                size: event.pageSize
            },
            queryParamsHandling: 'merge',
        }).finally(() => {
            this.loadCodeLabelObjectsData();
        });
    }

    /**
     * true will disable the filter form
     */
    disableSearchDashboard(): boolean {
        return this.dashboardHelperService.isSearchDisabled(this.codeLabelObjectsDataSource.filterForm);
    }

    /**
     * Show a snackbar after deleting a dashboard configuration
     * @param key
     */
    showSnackBar(key: string): void {
        const snackBarConfigs: any[] = [
            {
                key: 'SUCCESS_CREATE',
                snackTpe: 'Info',
                message: '{{configCodeName}} was created successfully',
                messageI18nKey: 'CODE_LABEL_CONFIG.FORM.TOAST_MESSAGES.SUCCESS_CREATE'
            },
            {
                key: 'SUCCESS_UPDATE',
                snackTpe: 'Info',
                message: '{{configCodeName}} was updated successfully',
                messageI18nKey: 'CODE_LABEL_CONFIG.FORM.TOAST_MESSAGES.SUCCESS_UPDATE'
            },
            {
                key: 'SUCCESS_DELETE',
                snackTpe: 'Info',
                message: '{{configCodeName}} was deleted successfully',
                messageI18nKey: 'CODE_LABEL_CONFIG.FORM.TOAST_MESSAGES.SUCCESS_DELETE'
            },
        ];
        const currentConfig = snackBarConfigs.filter(item => item.key === key)[0];
        if (!!currentConfig) {
            this.snackBar.openAtStart(currentConfig.snackType, currentConfig.messageI18nKey, {
                configCodeName: this.translateService.instant(`CODE_LABEL_CONFIG.CONFIG_CODES_TITLES.${this.configCode}`)
            });
        }
    }

    onUnarchive(): void {
        const dialogRef = this.matDialog.open(UploadModalComponent, {
            width: '900px',
            minWidth: '30%',
            disableClose: true,
            data: {
                acceptedFile: '.json,application/json',
                hasDeleteOption: false,
                showPreviews: false
            }
        });
        this.closeModal.closeWithEscape(dialogRef);
        dialogRef.afterClosed().subscribe(
            (data: UploadFileDialogResponse) => {
                this.store$.dispatch(new StartLoading());
                if (!!data) {
                    this.onFileChange(data.files);
                }
                this.store$.dispatch(new StopLoading());
            }, () => this.store$.dispatch(new StopLoading())
        );
    }

    private onFileChange(files: any): void {
        this.dashboardService.importDashboard(files).subscribe(() => {
            },
            error => console.log(error));
    }


    onArchive(index: any, dashboard: any): void{
        this.dashboardService.exportDashboard(dashboard.id).subscribe(data => {
            const file = new File([data], 'dashboard_' + dashboard.id  + '.json');
            fileSaver.saveAs(file);
        }, error => {
            console.log('error: ' + error);
        });
    }
}
