import {AddressType} from '../../models/enums/addressType.enum';
import {Address} from '../../models/customer/customer.model';
import {CustomerSearchRequest} from '../../models/customer/customer-search-request.model';

export class CustomerUtils {

    /** This Method returns the intervention address.
     * returns (by priority):
     *      1. Last intervention address.
     *      2. Last address (any type) in case no intervention address was found.
     *      3. {@null} in case no address was found.
     *
     * @param addresses : a customer addresses list.
     * @return address: the intervention address.
     */
    static getCustomerAddressByType(addresses: Address[], type: AddressType): Address {
        const interventionAddresses = addresses.filter(item => item.type === type);
        if (interventionAddresses.length > 0) {
            return interventionAddresses.slice(-1)[0];
        }
        if (addresses.length > 0) {
            return addresses.slice(-1)[0];
        }
        return null;
    }

    static toRequest(customer, pageEvent) {
        const pageSize = !!pageEvent?.pageSize ? pageEvent.pageSize : 10;
        let page = 1;
        if (pageEvent) {
            page = pageEvent.pageIndex + 1;
        }
        const address = this.getDefaultAddress(customer.addresses);
        return {
            reference: customer.reference || null,
            loyaltyCardNumber: customer.loyaltyCardNumber || null,
            firstName: customer.firstName || null,
            lastName: customer.lastName || null,
            email: customer.contact?.email || null,
            phoneNumber: customer.contact?.mobileNumber || null,
            zipCode: customer?.address?.zipCode ?? address?.zipCode ?? null,
            city: address.city || null,
            page: page,
            pageSize: pageSize
        } as CustomerSearchRequest;
    }

    static getDefaultAddress = (addresses: Address[]) =>
        addresses && addresses.length > 0
            ? addresses.find(address => address.type === AddressType.DEFAULT) ?? addresses[0]
            : new Address()

}
