import {Injectable} from '@angular/core';
import {Product} from '../../models/product.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GLOBAL} from '../../app-config';
import {Observable} from 'rxjs';
import {Page} from '../../models/page.model';
import {StockResponse} from '../../main/folder/folder-workflow/swap-process/validate-swap-in-store/model/stock-response.model';
import {PageSearchRequest} from '../../models/page-search-request.model';

export const WITH_DOCUMENTS = 'withDocuments';
export const PRODUCT_CODE = 'productCode';
export const TRUE = 'true';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api/products`;

    constructor(public httpClient: HttpClient) {
    }

    search(paramsMap: Map<string, any>): Observable<Page<Product>> {
        let params = new HttpParams();

        paramsMap.forEach((value: string, key: string) => {
            params = params.append(key, value);
        });
        return this.httpClient.get<Page<Product>>(`${this.path}/search`, {params: params});

    }

    findProductsByLabel(productLabel: string): Observable<Page<Product>> {
        return this.httpClient.get<Page<Product>>(`${this.path}/product/labels/${productLabel}`);
    }

    getProductList(queryParams: PageSearchRequest, inputs: Map<string, string>): Observable<Page<Product>> {
        const requestParam = this.buildRequestParamFromInputs(inputs);
        return this.httpClient.get<Page<Product>>(`${this.path}?${requestParam}`, {params: <any>queryParams});
    }

    private buildRequestParamFromInputs(inputs: Map<string, string>): any {
        let requestParam = '';
        inputs.forEach((value: string, key: string) => {
            if (!value || value === '*') {
                requestParam += `${key}=any&`;
            } else {
                requestParam += `${key}=${value}&`;
            }
        });
        requestParam = requestParam.substring(0, requestParam.length - 1);
        return requestParam;
    }

    getAvailableStock(organizationCode: string, productId: string, storeId: string): Observable<StockResponse> {
        const queryParams = {organizationCode, storeId};
        return this.httpClient.get<StockResponse>(`${this.path}/product/stock/${productId}`, {params: queryParams});
    }
}
