
import {Injectable} from '@angular/core';
import {GLOBAL} from '../../app-config';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Notification, NotificationSearchResponse} from '../../models/notification.model';
import {SearchNotificationRequest} from '../../models/SearchNotificationsRequest.model';


@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api/notification`;
    private notificationCount = new Subject<number>();
    public notification$ = this.notificationCount.asObservable();

    constructor(private  httpClient: HttpClient) {

    }

    getNotificationsFolder(searchNotificationRequest: SearchNotificationRequest, folderId: string): Observable<NotificationSearchResponse> {
        return this.httpClient.post<NotificationSearchResponse>(`${this.path}/${folderId}/search`, searchNotificationRequest);
    }

    getNotificationsFolderCount(folderId: string): Observable<number> {
        return this.httpClient.get<number>(`${this.path}/count/${folderId}`);
    }

    saveNotificationCall(notifications: Notification[]): Observable<Notification[]> {
        return this.httpClient.post<Notification[]>(`${this.path}`, notifications);
    }

    deleteNotification(notificationId: string): Promise<void> {
        return this.httpClient.delete<void>(`${this.path}/${notificationId}`).toPromise();
    }

    updateNotificationCount(notificationCount: number): void {
        this.notificationCount.next(notificationCount);
    }


    getNotification(notificationId: string): Promise<Notification> {
        return this.httpClient.get<Notification>(`${this.path}/${notificationId}`).toPromise();
    }

    sendNotification(communication: Notification, folderId: string): Observable<any> {
        return this.httpClient.post<Notification[]>(`${this.path}/send/${folderId}`, communication
        );
    }
}
