import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TypeOfServiceEnum, TypeOfServicePublicRepairerEnum} from '../../../../../models/typeOfService.model';
import {ConfigurationReferential} from '../../../../../models/configurationReferential.model';
import {PublicService} from '../../../../../shared/services/public.service';
import {Vat} from '../../../../../models/vat.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {CountryConfigurationSetSuccessfully} from '../../../../../store/organization/organization.actions';
import {vats} from '../../../../../store/organization/organization.selectors';
import {PAYERS_CONFIGS} from 'app/shared/data/static.config';
import {MatSelectChange} from '@angular/material/select';
import {PayerModel} from '../../../../../models/payer.model';
import {Payer} from '../../../../../models/enums/payer.enum';
import {SparePartService} from '../../../../../shared/services/spare-part.service';


@Component({
    selector: 'app-repairer-report-modal',
    templateUrl: 'repairer-report-modal.component.html',
    styleUrls: ['repairer-report-modal.component.scss']
})
export class RepairerReportModalComponent implements OnInit, OnDestroy {

    protected _onDestroy = new Subject<void>();

    sparePartsTypes = [];
    reportLineParts = [];
    isLoading = false;
    payers = PAYERS_CONFIGS;
    vatsList$: Observable<Vat[]>;
    reportLineColumns: string[] = ['type', 'code', 'label', 'payer', 'quantity', 'price', 'tva', 'discount', 'totalLinePrice', 'discountReason', 'action'];
    // irisColumns: string[] = ['symptom', 'condition', 'repair', 'defect', 'section', 'action'];

    dataSourceReportLine = [];
    reportLineDataTable = new MatTableDataSource<any>();

    // dataSourceIris = [];
    // irisDataTable = new MatTableDataSource<any>();

    reportLineForm: any = {
        form: null
    };

    filteredSymptomList: ReplaySubject<ConfigurationReferential[]> = new ReplaySubject<ConfigurationReferential[]>(1);
    symptomList = [];

    filteredConditionList: ReplaySubject<ConfigurationReferential[]> = new ReplaySubject<ConfigurationReferential[]>(1);
    conditionList = [];

    filteredRepairList: ReplaySubject<ConfigurationReferential[]> = new ReplaySubject<ConfigurationReferential[]>(1);
    repairList = [];

    filteredDefectList: ReplaySubject<ConfigurationReferential[]> = new ReplaySubject<ConfigurationReferential[]>(1);
    defectList = [];

    filteredSectionList: ReplaySubject<ConfigurationReferential[]> = new ReplaySubject<ConfigurationReferential[]>(1);
    sectionList = [];
    // irisForm: any = {
    //     form: null,
    // };

    externalUid: string;
    currency: string;

    constructor(private dialogRef: MatDialogRef<RepairerReportModalComponent>,
                private publicService: PublicService,
                private sparePartService: SparePartService,
                private store$: Store<AppState>,
                @Inject(MAT_DIALOG_DATA) public data) {
        this.currency = data.folder.currency;
        this.externalUid = data.folder.externalUid;
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    ngOnInit(): void {
        this.vatsList$ = this.store$.pipe(select(vats));
        this.publicService.getByCountryCode(this.externalUid).subscribe(countryConfiguration => {
            this.store$.dispatch(new CountryConfigurationSetSuccessfully(countryConfiguration));
        });
        this.sparePartsTypes = Object.keys(TypeOfServicePublicRepairerEnum);
        this.initReportLineForm();
        if (this.data) {
            // this.initIrisForm();
            this.reportLineDataTable.data = this.data.reportLine;
            // this.irisDataTable.data = this.data.iris;

            this.dataSourceReportLine = this.data.reportLine;
            // this.dataSourceIris = this.data.iris;
        }
    }

    initReportLineForm(): void {
        this.reportLineForm.form = new FormGroup({
            code: new FormControl(null, Validators.required),
            label: new FormControl(null, Validators.required),
            type: new FormControl(null, Validators.required),
            payer: new FormControl(null, Validators.required),
            quantity: new FormControl(1, Validators.required),
            price: new FormControl(null, Validators.required),
            tva: new FormControl(0, Validators.required),
            discount: new FormControl(0, [Validators.min(0), Validators.max(100)]),
            totalLinePrice: new FormControl(0),
            discountReason: new FormControl(null)
        });
    }

    // initIrisForm(): void {
    //     this.irisForm.form = new FormGroup({
    //         symptom: new FormControl(null, Validators.required),
    //         symptomCodeCtrl: new FormControl(''),
    //         condition: new FormControl(null, Validators.required),
    //         conditionCodeCtrl: new FormControl(''),
    //         reparation: new FormControl(null, Validators.required),
    //         reparationCodeCtrl: new FormControl(''),
    //         defect: new FormControl(null, Validators.required),
    //         defectCodeCtrl: new FormControl(''),
    //         section: new FormControl(null, Validators.required),
    //         sectionCodeCtrl: new FormControl('')
    //     });
    //     this.filteredIrisForm();
    // }

    // filteredIrisForm(): void {
    //     this.searchByConfigValue(this.externalUid, ConfigCodesEnum.IRIS_SYMPTOMS, this.symptomList, this.filteredSymptomList, 'symptomCodeCtrl');
    //     this.searchByConfigValue(this.externalUid, ConfigCodesEnum.IRIS_DEFECTS, this.defectList, this.filteredDefectList, 'defectCodeCtrl');
    //     this.searchByConfigValue(this.externalUid, ConfigCodesEnum.CONDITIONS, this.conditionList, this.filteredConditionList, 'conditionCodeCtrl');
    //     this.searchByConfigValue(this.externalUid, ConfigCodesEnum.REPAIR, this.repairList, this.filteredRepairList, 'reparationCodeCtrl');
    //     this.searchByConfigValue(this.externalUid, ConfigCodesEnum.IRIS_SECTIONS, this.sectionList, this.filteredSectionList, 'sectionCodeCtrl');
    // }

    // private searchByConfigValue(folderUid: string, configValueCode: ConfigCodesEnum, configValues: any, filteredList: any, fieldControl: string) {
    //     const pageCriteria: PageSearchRequest = {
    //         page: 0, size: 100,
    //     };
    //     const inputs = new Map();
    //     this.publicService.bulkSearchCodeLabelObjects(folderUid, configValueCode, pageCriteria, inputs).then(page => {
    //         configValues = page.content;
    //         this.irisForm.form.get([fieldControl]).valueChanges
    //             .pipe(takeUntil(this._onDestroy),
    //                 startWith(null)).subscribe(input => {
    //             const matchedResults = !!input ?
    //                 configValues.filter(item =>
    //                     item.code.toLowerCase().includes(input.toLowerCase()) || item.label.toLowerCase().includes(input.toLowerCase())
    //                 ) : configValues;
    //
    //             filteredList.next(matchedResults);
    //         });
    //     }).catch(() => {
    //         configValues = [];
    //     });
    // }

    addIntoSparePartList(): void {
        this.checkDiscountValue();
        this.dataSourceReportLine.push(this.reportLineForm.form.value);
        this.reportLineParts = [];
        this.refreshDataTableSparePart();
        this.refreshDataForm(this.reportLineForm.form);
    }

    private checkDiscountValue() {
        if (!this.reportLineForm.form.value.discount) {
            this.reportLineForm.form.get(['discount']).setValue(0);
        }
    }

    deleteLineSparePart(indexLine: any): void {
        this.dataSourceReportLine = this.reportLineDataTable.data.filter((item, index) => index !== indexLine);
        this.refreshDataTableSparePart();
    }

    refreshDataTableSparePart(): void {
        this.reportLineDataTable.data = [];
        this.reportLineDataTable = new MatTableDataSource<any>(this.dataSourceReportLine);
    }

    // addIntoIrisList(): void {
    //     let iris: Iris;
    //     iris = {
    //         symptom: {
    //             code: this.irisForm.form.value.symptom.code,
    //             label: this.irisForm.form.value.symptom.label
    //         },
    //         condition: {
    //             code: this.irisForm.form.value.condition.code,
    //             label: this.irisForm.form.value.condition.label
    //         },
    //         reparation: {
    //             code: this.irisForm.form.value.reparation.code,
    //             label: this.irisForm.form.value.reparation.label
    //         },
    //         section: {
    //             code: this.irisForm.form.value.section.code,
    //             label: this.irisForm.form.value.section.label
    //         },
    //         defect: {
    //             code: this.irisForm.form.value.defect.code,
    //             label: this.irisForm.form.value.defect.label
    //         }
    //     };
    //     this.dataSourceIris.push(iris);
    //     this.refreshDataTableIris();
    //     this.refreshDataForm(this.irisForm.form);
    // }
    //
    // refreshDataTableIris(): void {
    //     this.irisDataTable.data = [];
    //     this.irisDataTable = new MatTableDataSource<any>(this.dataSourceIris);
    // }

    refreshDataForm(form: FormGroup): void {
        Object.keys(form.controls).forEach(key => {
            if (key !== 'quantity') {
                form.controls[key].setValue(null);
            }
            form.controls[key].setErrors(null);
        });
        form.setErrors({'invalid': true});
    }

    // deleteLineDataIris(indexLine: any): void {
    //     this.dataSourceIris = this.irisDataTable.data.filter((item, index) => index !== indexLine);
    //     this.refreshDataTableIris();
    // }
    formatAndCalculateTotalQuotationLine(): void {
        this.reportLineForm.form.get('price').setValue(this.sparePartService.formatPriceAccordingToCurrency(this.reportLineForm.form.get('price').value, this.currency));
        this.reportLineForm.form.get('totalLinePrice').setValue(
            this.sparePartService.formatPriceAccordingToCurrency(
                this.sparePartService.getTotalLinePrice(
                    this.sparePartService.normalizeFloat(this.reportLineForm.form.value.price),
                    this.reportLineForm.form.value.quantity, this.reportLineForm.form.value.discount, this.reportLineForm.form.value.tva),
                this.currency));
    }

    validateForm(): void {
        this.dialogRef.close({
            reportLine: this.reportLineDataTable.data
        });
    }

    computePayers($event: MatSelectChange) {
        const payers = this.data.folder.payers as PayerModel;
        let payer;
        switch ($event.value) {
            case TypeOfServiceEnum.WORKFORCE: {
                payer = payers.workforce;
                break;
            }
            case TypeOfServiceEnum.SPARE_PART: {
                payer = payers.spareParts;
                break;
            }
            case TypeOfServiceEnum.DISPLACEMENT: {
                payer = payers.displacement;
                break;
            }
            default: {
                payer = Payer.CLIENT;
                break;
            }
        }
        this.reportLineForm.form.get('payer').setValue(payer);
    }
}
