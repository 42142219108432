export enum NotificationType {
    ALL = 'ALL',
    SMS = 'SMS',
    MAIL = 'MAIL',
    CALL = 'CALL',
    CALL_IN = 'CALL_IN',
    CALL_OUT = 'CALL_OUT',
    POSTAL_MAIL = 'POSTAL_MAIL',
    REMOTE_ASSISTANCE = 'REMOTE_ASSISTANCE',

}
