import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-single-upload-modal',
    templateUrl: './single-upload-modal.component.html',
    styleUrls: ['./single-upload-modal.component.scss']
})
export class SingleUploadModalComponent implements OnInit {
    @ViewChild('dropzone') dropzone: ElementRef;
    attachmentTypes: any[] = [];

    fileUploadForm: any = {
        url: 'assets/images/demo-content/machine_upload.jpg',
        file: null,
        hasFile: false,
        isUploading: false,
        hasSuccessfullyUploaded: false,
    };
    elementForm = {
        attachmentForm: null
    };

    files: any;
    sizeFile: number;
    warningSizeFile = false;
    hideFileType = false;
    sharedWithList: string[] = [];

    constructor(
        private dialogRef: MatDialogRef<SingleUploadModalComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: any,
        private translateService: TranslateService
    ) {
    }

    ngOnInit(): void {
        this.getAttachmentTypes();
        this.initAttachmentForm();
        this.hideFileType = this.modalData?.hideFileType;
        this.sharedWithAttachementTypes();
    }
    getAttachmentTypes(){
        if (this.modalData?.attachmentTypes){
            this.attachmentTypes = this.modalData?.attachmentTypes;
        }
    }

    sharedWithAttachementTypes(): void {
        this.elementForm.attachmentForm.get('attachmentType').valueChanges.subscribe((selectedCode) => {
            const selectedAttachment = this.attachmentTypes.find(attachment => attachment.code === selectedCode);
            if (selectedAttachment && selectedAttachment.extra.sharedWith) {
                this.sharedWithList = selectedAttachment.extra.sharedWith
                    .map(sharedWithItem => this.translateService.instant('SHARED_WITH_' + sharedWithItem));
            } else {
                this.sharedWithList = [];
            }
        });
    }



    initAttachmentForm(): void {
        this.elementForm.attachmentForm = new FormGroup({
            attachmentType: new FormControl(Validators.required),
        });
        this.elementForm.attachmentForm.get(['attachmentType']).setValue();
    }

    onClose(): void {
        this.dialogRef.close();
    }

    // cette methode pour Fermer la modal avec un echap
    @HostListener('document:keydown', ['$event'])
    onKeyDownHandler(event: KeyboardEvent): void {
        if (event.key === "Escape") {
            this.dialogRef.close();
        }
    }

    onCloseWithURL(): void {
        if (!!this.elementForm.attachmentForm &&
            !!this.elementForm.attachmentForm.get(['attachmentType']).value){
            this.files[0].attachmentType = this.elementForm.attachmentForm.get(['attachmentType']).value;
        }
        this.dialogRef.close(this.files);
    }

    onFilesAdded($event: File[]): void {
        this.sizeFile = $event[0].size / 1024 / 1024;
        if ($event[0].type === '') {
            this.onClearDropzone();
        }
        else if (this.sizeFile < 10) {
            this.fileUploadForm.hasFile = true;
            this.warningSizeFile = false;
            this.files = $event;
        } else {
            this.onClearDropzone();
            this.warningSizeFile = true;
        }
    }


    onFilesRejected($event: File[]): void {
        console.log('File sRejected:', $event);
    }

    onClearDropzone(): void {
        // @ts-ignore
        this.dropzone.reset();
        this.fileUploadForm.hasFile = false;
    }

    onUpload(): void {
        this.fileUploadForm.isUploading = true;

        setTimeout(() => this.onSuccessfullyUploaded(), 200);

    }

    onSuccessfullyUploaded(): void {
        // TEMPORARY from here
        this.onUploadTerminated();


        this.fileUploadForm.hasSuccessfullyUploaded = true;

    }

    onUploadTerminated(): void {
        this.fileUploadForm.isUploading = false;
    }

    acceptType(): string {
        if (!!this.modalData) {
            if (this.modalData.type === 'PRODUCT') {
                return 'application/pdf,image/png';
            } else if (this.modalData.type === 'IMAGE') {
                return 'image/png,image/jpeg';
            } else {
                return 'application/pdf,image/png,image/jpeg';
            }
        } else {
            return 'application/pdf,image/png,image/jpeg';
        }
    }

    isEnabled(): boolean {
        return ('PRODUCT' === this.modalData?.type || this.hideFileType) ? this.fileUploadForm.hasFile :
            (this.fileUploadForm.hasFile && !!this.elementForm.attachmentForm.get(['attachmentType']).value);
    }
}
