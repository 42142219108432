import {createSelector} from '@ngrx/store';
import {LoaderState} from './loader.state';
import {AppState} from '../app.state';

const loaderState = (state: AppState) => state.loader;

export const isLoading = createSelector(
    loaderState,
    (loader: LoaderState) => loader.isLoading
);
