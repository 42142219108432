<ejs-grid  *ngIf = "orderedSpareParts?.length > 0" #grid [dataSource]="orderedSpareParts"
           [allowPaging]="true"
           [allowSorting]="true"
           [allowFiltering]="true"
           [showColumnMenu]="true"
           [allowSelection]='true'
           [allowTextWrap]="true"
           [pageSettings]="{pageSize: 5}"
           [filterSettings]="{type: 'CheckBox'}"
           [selectionSettings]="{type: 'Multiple', checkboxOnly: true }"
           (detailDataBound)='detailDataBound($event)'
           autoFit='true'

>

    <e-columns>
        <e-column field="label"
                  [headerText]="'COMPONENT.FOLDER_CART_ITEMS_CARD.LABEL' | translate"
        >
        </e-column>
        <e-column field="supplierName"
                  [headerText]="'ASSET_CATALOG.FORM.FIELDS.SUPPLIER' | translate"
        >
        </e-column>

        <e-column field="quantity"
                  [headerText]="'ASSET_CATALOG.FORM.FIELDS.QUANTITY' | translate"
        >
        </e-column>
        <e-column field="price"
                  [headerText]="'COMPONENT.FOLDER_CART_ITEMS_CARD.SALE.PRICE.TTC' | translate" [width]="130"
        ><ng-template #template let-data>
            <span>
                 {{data.priceAmount?.value === 0 && isUnderWarranty ?
                ('ASSET_CATALOG.FORM.FIELDS.FREE' | translate) :
                ( getTotalPrices(data) | formatAmount)}}
            </span>
        </ng-template>
        </e-column>
        <e-column field="status" *ngIf="withStatus"
                  [headerText]="'COMPONENT.FOLDER_CART_ITEMS_CARD.STATUS' | translate"
        >
            <ng-template #template let-data>
                <span>{{displayItemStatus(data.status , data.index) | translate }}</span>
            </ng-template>
        </e-column>
        <e-column field="sparePartReceived" *ngIf="withSparePartReceivedCheckbox"
                  [headerText]="'SPARE_PART_RECEIVED_LABEL' | translate"
        >
            <ng-template #template let-data>
                <mat-checkbox  *ngIf="displaySparePartReceivedCheckbox(data)" [(ngModel)]="data.sparePartReceived" [checked]="isItemChecked(data.index)"
                               (change)="onSelectSparePartReceived(data, data.index)"></mat-checkbox>
            </ng-template>
        </e-column>
    </e-columns>
    <ng-template #detailTemplate let-data>
        <app-ordered-content-info [selectedSparePart]="data">
        </app-ordered-content-info>
    </ng-template>
</ejs-grid>
