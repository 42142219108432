import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-product-retrieval-quotation-refused2',
    templateUrl: './waiting-product-retrieval-quotation-refused2.component.html',
    styleUrls: ['./waiting-product-retrieval-quotation-refused2.component.scss']
})
export class WaitingProductRetrievalQuotationRefused2Component implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    productRetrieved: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        this.inputMap.emit({});
    }

}
