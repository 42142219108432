<div class="text-center">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
        <h2>
            <mat-icon>class</mat-icon>
            {{'FOLDER.REPAIR_IN_PROGRESS.REPORT' | translate}}
        </h2>
        <button mat-icon-button class="ml-40" (click)="onCloseModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <ng-container *ngIf="reportLineForm.form">
            <form [formGroup]="reportLineForm.form" (ngSubmit)="addIntoSparePartList()" autocomplete="off" (keydown)="onEnterPressed($event)">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <h2>{{'FOLDER.REPAIR_IN_PROGRESS.LIST_SPARE_PARTS' | translate}}</h2>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                    <div fxFlex="25" fxFlex.lt-md="100">

                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'MODAL.QUOTATION.TYPE'|translate}}</mat-label>
                            <mat-select formControlName="type" [placeholder]="'MODAL.QUOTATION.TYPE'|translate" required
                                        (selectionChange)="onTypeChange($event.value)">
                                <mat-option *ngFor="let type of sparePartsTypes" [value]="type.code">
                                    <span> {{type.label}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="25" fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'MODAL.QUOTATION.CODE' | translate }}</mat-label>
                            <input matInput placeholder="{{'MODAL.QUOTATION.CODE' | translate }}"
                                   formControlName="code" required [matAutocomplete]="autoCode"
                                   (keyup)="onCodeChanges()">
                            <mat-autocomplete #autoCode="matAutocomplete" (optionSelected)='sparePartChanged($event)'>
                                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option *ngFor="let option of filteredTypeOfServices | async" [value]="option">
                                        {{option.code}} - {{option.label}}
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'MODAL.QUOTATION.LABEL'|translate}}</mat-label>
                            <input matInput [placeholder]="'MODAL.QUOTATION.LABEL'|translate" (keyup)="onLabelChanges()"
                                   formControlName="label" type="text" required [matAutocomplete]="autoLabel">
                            <mat-autocomplete #autoLabel="matAutocomplete" (optionSelected)='sparePartChanged($event)'>
                                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option *ngFor="let sparePart of filteredTypeOfServices | async"
                                                [value]="sparePart">
                                        {{sparePart.code}} - {{sparePart.label}}
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'MODAL.QUOTATION.PAYER'|translate}}</mat-label>
                            <mat-select formControlName="payer" [placeholder]="'MODAL.QUOTATION.PAYER'|translate">
                                <mat-option *ngFor="let payer of payers" [value]="payer.key">
                                    <span> {{payer.label.i18nKey | translate}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                    <div fxFlex="20" fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'MODAL.QUOTATION.QUANTITY'|translate}}</mat-label>
                            <input matInput [placeholder]="'MODAL.QUOTATION.QUANTITY'|translate"
                                   formControlName="quantity"
                                   min="1" type="number" required (blur)="calculationReportLine()">
                        </mat-form-field>
                    </div>
                    <div fxFlex="20" fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'MODAL.QUOTATION.PRICE'|translate}}</mat-label>
                            <input matInput [placeholder]="'MODAL.QUOTATION.PRICE'|translate"
                                   [value]="reportLineForm.form.get('price').value"
                                   formControlName="price" (blur)="calculationReportLine()"
                                   min="0" required>

                        </mat-form-field>
                    </div>
                    <div fxFlex="20" fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label> {{'MODAL.QUOTATION.TVA'|translate}}</mat-label>
                            <mat-select formControlName="tva" (selectionChange)="calculationReportLine()">
                                <mat-option *ngFor="let vat of vatsList$ | async" [value]="vat.rate">
                                    {{vat.rate}} %
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="20" fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'MODAL.QUOTATION.DISCOUNT'|translate}}</mat-label>
                            <input matInput [placeholder]="'MODAL.QUOTATION.DISCOUNT'|translate"
                                   formControlName="discount"
                                   min="0" numberOnly type="number" (blur)="calculationReportLine()">
                            <mat-icon class="s-16" matSuffix>%</mat-icon>
                        </mat-form-field>
                    </div>
                    <div fxFlex="20" fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'MODAL.QUOTATION.TOTAL_PRICE'|translate}}</mat-label>
                            <input matInput [placeholder]="'MODAL.QUOTATION.TOTAL_PRICE'|translate"
                                   required
                                   [value]="reportLineForm.form.get('totalLinePrice').value"
                                   formControlName="totalLinePrice" disabled readonly="true"
                                   (blur)="calculationReportLine()">

                        </mat-form-field>
                    </div>
                </div>
                <ng-container *ngIf="this.reportLineForm.form.value.discount>0">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                        <div fxFlex="100" fxFlex.lt-md="100">
                            <mat-form-field appearance="outline" class="w-100-p">
                                <mat-label>{{'MODAL.QUOTATION.DISCOUNT_REASON'|translate}}</mat-label>
                                <input matInput [placeholder]="'MODAL.QUOTATION.DISCOUNT_REASON' | translate"
                                       formControlName="discountReason" type="text">
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>

                <div fxLayoutAlign="end center">
                    <div>
                        <button mat-stroked-button [disabled]="reportLineForm.form.invalid"
                                [ngClass]="reportLineForm.form.invalid ? 'aster-btn_border-disabled' : 'aster-btn_border'">
                            <mat-icon>add</mat-icon>
                            {{'BUTTON.ADD' | translate}}
                        </button>
                    </div>
                </div>
            </form>
        </ng-container>

        <table mat-table [dataSource]="reportLineDataTable" class="mat-elevation-z4 w-100-p mt-16 mb-16">
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'FOLDER.REPAIR_IN_PROGRESS.CODE' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let sparePart">{{sparePart.code}}</td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'FOLDER.REPAIR_IN_PROGRESS.TYPE' | translate}}</th>
                <td class="element" mat-cell
                    *matCellDef="let sparePart">{{'MODAL.QUOTATION.TYPE.' + sparePart.type | translate}}</td>
            </ng-container>
            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'FOLDER.REPAIR_IN_PROGRESS.LABEL' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let sparePart"> {{sparePart.label}} </td>
            </ng-container>
            <ng-container matColumnDef="payer">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'MODAL.QUOTATION.PAYER' | translate}}</th>
                <td class="element" mat-cell
                    *matCellDef="let sparePart">{{!!sparePart.payer ? ('RULE_WARRANTIES.CONFIGS.PAYERS_CONFIGS.' + sparePart.payer | translate) : "-"}}</td>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'MODAL.QUOTATION.QUANTITY' | translate}}</th>
                <td class="element" mat-cell
                    *matCellDef="let sparePart">{{sparePart.quantity}}</td>
            </ng-container>

            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'MODAL.QUOTATION.PRICE' | translate}}</th>
                <td class="element" mat-cell
                    *matCellDef="let sparePart">{{sparePart.price |currency:currency :'symbol-narrow' | space}}</td>
            </ng-container>

            <ng-container matColumnDef="tva">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'MODAL.QUOTATION.TVA' | translate}}</th>
                <td class="element" mat-cell
                    *matCellDef="let sparePart">{{sparePart.tva}}</td>
            </ng-container>

            <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'MODAL.QUOTATION.DISCOUNT' | translate}}</th>
                <td class="element" mat-cell
                    *matCellDef="let sparePart">{{sparePart.discount}}</td>
            </ng-container>

            <ng-container matColumnDef="totalLinePrice">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'MODAL.QUOTATION.TOTAL_PRICE' | translate}}</th>
                <td class="element" mat-cell
                    *matCellDef="let sparePart">{{sparePart.totalLinePrice  |currency:currency :'symbol-narrow'}}</td>
            </ng-container>

            <ng-container matColumnDef="discountReason">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'MODAL.QUOTATION.DISCOUNT_REASON' | translate}}</th>
                <td class="element" mat-cell
                    *matCellDef="let sparePart">{{sparePart.discountReason}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'BUTTON.ACTION' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let indexLine = index;">
                    <button matSuffix mat-icon-button color="warn"
                            type="button"
                            (click)="deleteLineSparePart(indexLine)">
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="reportLineColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: reportLineColumns;"></tr>
        </table>

        <mat-divider class="mb-16"></mat-divider>
        <ng-container *ngIf="!reportModalData.hideIris">
            <form [formGroup]="irisForm.form" (ngSubmit)="addIntoIrisList()" autocomplete="off">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <h2> {{'FOLDER.REPAIR_IN_PROGRESS.IRIS' | translate}} </h2>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                    <div fxFlex fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'FOLDER.REPAIR_IN_PROGRESS.SYMPTOM'| translate}}</mat-label>
                            <mat-select placeholder="symptom" formControlName="symptom">
                                <mat-option>
                                    <ngx-mat-select-search formControlName="symptomCodeCtrl"
                                                           [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                                           [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngIf="symptomList.length===0">
                                    <span>{{'GENERAL.SEARCH_NO_ITEM'| translate}}</span></mat-option>
                                <mat-option *ngFor="let symptom of filteredSymptomList | async" [value]="symptom">
                                    <span
                                        [attr.class]="'flag-icon h-24 w-32 flag-icon-' + symptom.code | lowercase"></span>
                                    <span class="ml-8 ngx-select_label">{{ symptom.label }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'FOLDER.REPAIR_IN_PROGRESS.CONDITION' | translate}}</mat-label>
                            <mat-select placeholder="symptom" formControlName="condition">
                                <mat-option>
                                    <ngx-mat-select-search formControlName="conditionCodeCtrl"
                                                           [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                                           [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngIf="conditionList.length ===0">
                                    <span>{{'GENERAL.SEARCH_NO_ITEM'| translate}}</span></mat-option>
                                <mat-option *ngFor="let condition of filteredConditionList | async" [value]="condition">
                                            <span
                                                [attr.class]="'flag-icon h-24 w-32 flag-icon-' + condition.code | lowercase"></span>
                                    <span class="ml-8 ngx-select_label">{{ condition.label | translate }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'FOLDER.REPAIR_IN_PROGRESS.REPARATION' | translate}}</mat-label>
                            <mat-select placeholder="symptom" formControlName="reparation">
                                <mat-option>
                                    <ngx-mat-select-search formControlName="reparationCodeCtrl"
                                                           [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                                           [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngIf="repairList.length ===0">
                                    <span>{{'GENERAL.SEARCH_NO_ITEM'| translate}}</span></mat-option>
                                <mat-option *ngFor="let repair of filteredRepairList | async" [value]="repair">
                                    <span [attr.class]="'flag-icon h-24 w-32 flag-icon-' + repair | lowercase"></span>
                                    <span class="ml-8 ngx-select_label">{{ repair.label }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                    <div fxFlex fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'FOLDER.REPAIR_IN_PROGRESS.DEFECT' | translate}}</mat-label>

                            <mat-select placeholder="Défaut" formControlName="defect">
                                <mat-option>
                                    <ngx-mat-select-search formControlName="defectCodeCtrl"
                                                           [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                                           [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngIf="defectList.length===0">
                                    <span>{{'GENERAL.SEARCH_NO_ITEM'| translate}}</span></mat-option>

                                <mat-option *ngFor="let defect of filteredDefectList | async" [value]="defect">
                                            <span
                                                [attr.class]="'flag-icon h-24 w-32 flag-icon-' + defect.code | lowercase"></span>
                                    <span class="ml-8 ngx-select_label">{{defect?.label }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'FOLDER.REPAIR_IN_PROGRESS.SECTION' | translate}}</mat-label>
                            <mat-select placeholder="Section" formControlName="section">
                                <mat-option>
                                    <ngx-mat-select-search formControlName="sectionCodeCtrl"
                                                           [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                                           [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngIf="sectionList.length===0">
                                    <span>{{'GENERAL.SEARCH_NO_ITEM'| translate}}</span></mat-option>
                                <mat-option *ngFor="let section of filteredSectionList | async" [value]="section">
                                            <span
                                                [attr.class]="'flag-icon h-24 w-32 flag-icon-' + section.code | lowercase"></span>
                                    <span class="ml-8 ngx-select_label">{{ section.label }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayoutAlign="end center">
                    <div>
                        <button mat-stroked-button [disabled]="irisForm.form.invalid"
                                [ngClass]="irisForm.form.invalid ? 'aster-btn_border-disabled' : 'aster-btn_border'">
                            <mat-icon>add</mat-icon>
                            {{'BUTTON.ADD' | translate}}
                        </button>
                    </div>
                </div>
            </form>

                    <table mat-table [dataSource]="irisDataTable" class="mat-elevation-z4 w-100-p mt-16 mb-16">
                        <ng-container matColumnDef="symptom">
                            <th mat-header-cell *matHeaderCellDef
                                class="table-header">{{'FOLDER.REPAIR_IN_PROGRESS.SYMPTOM' | translate}}</th>
                            <td class="element" mat-cell
                                *matCellDef="let irisLine"> {{irisLine.symptom?.label ? irisLine.symptom?.label : irisLine.symptom?.code}} </td>
                        </ng-container>
                        <ng-container matColumnDef="condition">
                            <th mat-header-cell *matHeaderCellDef
                                class="table-header">{{'FOLDER.REPAIR_IN_PROGRESS.CONDITION' | translate}}</th>
                            <td class="element" mat-cell
                                *matCellDef="let irisLine">{{ irisLine.condition?.label ? irisLine.condition?.label : irisLine.condition?.code}}</td>
                        </ng-container>
                        <ng-container matColumnDef="repair">
                            <th mat-header-cell *matHeaderCellDef
                                class="table-header">{{'FOLDER.REPAIR_IN_PROGRESS.REPARATION' | translate}}</th>
                            <td class="element" mat-cell *matCellDef="let irisLine">
                                <div *ngIf="irisLine.reparation ; else editReparation">
                                    {{irisLine.reparation?.label ? irisLine.reparation?.label : irisLine.reparation?.code}}
                                </div>
                                <ng-template #editReparation>
                                    <form [formGroup]="irisForm.form">
                                        <mat-form-field appearance="outline" class="w-100-p">
                                            <mat-select (selectionChange)="saveReparation($event, irisLine)">
                                                <mat-option>
                                                    <ngx-mat-select-search formControlName="reparationCodeCtrl"
                                                                           [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                                                           [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate">
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let repair of filteredRepairList | async" [value]="repair">
                                                    <span
                                                        [attr.class]="'flag-icon h-24 w-32 flag-icon-' + repair | lowercase"></span>
                                                    <span
                                                        class="ml-8 ngx-select_label">{{ repair.code + '-' + repair.label }}</span>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </ng-template>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="defect">
                            <th mat-header-cell *matHeaderCellDef
                                class="table-header">  {{'FOLDER.REPAIR_IN_PROGRESS.DEFECT' | translate}} </th>
                            <td mat-cell
                                *matCellDef="let sparePart">{{ sparePart.defect?.label}} </td>
                        </ng-container>
                        <ng-container matColumnDef="section">
                            <th mat-header-cell *matHeaderCellDef
                                class="table-header">  {{'FOLDER.REPAIR_IN_PROGRESS.SECTION' | translate}} </th>
                            <td class="element" mat-cell
                                *matCellDef="let sparePart">{{ sparePart.section?.label}} </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef
                                class="table-header">  {{'BUTTON.ACTION' | translate}}</th>
                            <td class="element" class="element" mat-cell *matCellDef="let indexLine = index;">
                                <button matSuffix mat-icon-button color="warn"
                                        type="button" class="mx-8"
                                        (click)="deleteLineDataIris(indexLine)">
                                    <mat-icon>delete_forever</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="irisColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: irisColumns;"></tr>
                    </table>
        </ng-container>


    </mat-dialog-content>

    <mat-dialog-actions class="mt-16">
        <div class="w-100-p">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="end end">
                <button type="submit" mat-stroked-button (click)="validateForm()"
                        [disabled]="disabled()"
                        [ngClass]="disabled()? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </div>
    </mat-dialog-actions>
</div>
