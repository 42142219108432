<div fxFill fxLayout="row wrap" fxLayoutGap="8px grid" *ngIf="!!publicFolder">
    <div fxFlex="50%" fxLayoutAlign="start stretch"  fxFlex.xs="100%" fxFlex.sm="100%">
        <div class="tab-public_details mat-elevation-z2 ">
            <section id="productInfoSection" (click)="updateSection('productInfoSection')">
                <input type="radio" name="sections" id="productInfo" checked>
                <label for="productInfo" class="text-center">
                    <mat-icon class="mt-20">info</mat-icon>
                    <p class="h4">  {{'FOLDER.INFOS_TITLE'| translate}} </p>
                </label>
                <article class="article-scroll">
                    <mat-card class="w-100-p mb-4  mt-16 mat-elevation-aster">
            <mat-card-content>
                <div fxLayout="row" fxLayoutAlign="space-between" class="mt-12">
                    <div class="h-140 w-140  mr-24 mb-16 "
                         fxLayout="row" fxLayoutAlign="center center">
                        <app-product-image [image]="publicFolder.productImageUrl"
                                           [imageCss]="'h-160-max'"
                                           [loaderCss]="'w-60 h-60'"></app-product-image>
                    </div>
                    <div fxFlex class=" ml-80 mt-4">
                        <div class="w-100-p px-10 mt-12">
                            <div fxLayout="row" fxLayoutAlign="none">
                                <mat-icon>folder_open</mat-icon>
                                <span class="font-weight-600">{{'FOLDER.TABLE.INCREMENTAL_REFERENCE' | translate}} :
                                 <span>{{publicFolder.incrementalReference}}
                           </span>
                            </span>
                            </div>
                        </div>

                        <span class="font-weight-900 text-bold">{{publicFolder.productLabel}}</span>
                        <div>
                            <span class="font-weight-600">
                                {{'FOLDER.PRODUCT.PRODUCT_CODE' | translate}} :
                            </span>
                            <span>{{publicFolder.productCode | slice:-10}}</span>
                        </div>
                        <div class="w-100-p px-10 mt-12">
                            <div fxLayout="row" fxLayoutAlign="none">
                                <mat-icon>today</mat-icon>
                                <span class="font-weight-600">{{'INVOICE.PURCHASE_DATE' | translate}} :
                                    <span> {{publicFolder.purchaseDate | moment: 'DD/MM/yyyy'}}</span>
                                 </span>
                            </div>
                        </div>
                        <div class="w-100-p px-10 mt-12">
                            <div fxLayout="row" fxLayoutAlign="none">
                                <mat-icon>receipt</mat-icon>
                                <div>
                                    <span class="font-weight-600">{{'INVOICE.TITLE' | translate}} :</span>
                                    <span> {{publicFolder.invoiceNumber}}
                           </span>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="publicFolder.managementSite">
                            <div class="w-100-p px-10 mt-12">
                                <div fxLayout="row" fxLayoutAlign="none">
                                    <mat-icon>store_mall_directory</mat-icon>
                                    <span class="font-weight-600">{{'FOLDER.MANAGEMENT_SITE' | translate}} :
                                <span
                                    class=" font-weight-900 text-bold ">{{publicFolder.managementSite.code + ' - ' + publicFolder.managementSite.name}}</span>
                                </span>
                                </div>
                            </div>

                        </ng-container>
                        <ng-container *ngIf="!!publicFolder.supplier">
                            <div class="w-100-p px-10 mt-12">
                                <div fxLayout="row" fxLayoutAlign="none">
                                    <mat-icon>local_shipping</mat-icon>
                                    <span class="font-weight-600"> {{'FOLDER.SUPPLIER.TITLE' | translate}} :
                                       <span>
                                             <ng-container
                                                 *ngIf="publicFolder.supplier?.code===UNKNOWN_SUPPLIER;else SHOW_SUPPLIER_NAME">
                                                {{UNKNOWN_SUPPLIER | translate}}
                                            </ng-container>
                                            <ng-template #SHOW_SUPPLIER_NAME>
                                              <span> {{publicFolder.supplier?.code + " - " + publicFolder.supplier?.label}}</span>
                                            </ng-template>
                           </span>
                            </span>
                                </div>
                            </div>
                        </ng-container>

                        <!-- serial numbers section-->
                        <div class="w-100-p px-10 mt-12">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                <div fxLayout="row" fxLayoutAlign="center center" >
                                    <mat-icon svgIcon="bar_code"></mat-icon>
                                    <span class="font-weight-600"></span>
                                    <span> {{publicFolder.serialNumber1}}</span>
                                </div>
                                <div *ngIf="publicFolder.serialNumber2" fxLayout="row" fxLayoutAlign="center center" >
                                    <mat-icon svgIcon="bar_code"></mat-icon>
                                    <span class="font-weight-600"></span>
                                    <span> {{publicFolder.serialNumber2}}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between" class="mt-12">
                    <div class="w-48" *ngIf="!!warranty">
                        <mat-icon
                            [ngClass]="'font-size-30 w-40  h-40 ' + warranty.icon.class">
                            {{warranty.icon.code}}
                        </mat-icon>
                    </div>
                    <div fxFlex>
                        <span class="font-weight-900 text-bold">{{'FOLDER.WARRANTY.TITLE' | translate}} :</span>
                        <div>
                        <span>{{ warrantyFolder }}
                        </span>
                        </div>
                    </div>

                </div>
                <div fxLayout="row" fxLayoutAlign="space-between" class="pt-12">
                    <div class="w-48">
                        <mat-icon class="icon-toolbar_quotation">build</mat-icon>
                    </div>
                    <div fxFlex>
                        <span class="font-weight-900 text-bold">{{'FOLDER.SYMPTOM.TITLE' | translate}} :</span>
                        <div>
                            <span>{{symptom}}</span>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between" class="pt-12">
                    <div class="w-48">
                        <mat-icon>dehaze</mat-icon>
                    </div>
                    <div fxFlex>
                        <span class="font-weight-900 text-bold">{{'FORM.TITLE.CONDITION' | translate}} :</span>
                        <div>
                                <span>{{condition}}</span>
                        </div>
                    </div>
                </div>
                <!-- attachments -->
                <div class="pt-12">
                        <app-files-list
                            [displayedColumns]="displayedColumns"
                            [attachmentsTypes]="attachmentsTypes"
                            [(data)]="attachments"></app-files-list>
                </div>
                <app-public-survey-summary *ngIf="!!publicFolder.surveyResponse && publicFolder.surveyResponse.finished"
                                           [surveyResponseSummary]="publicFolder.surveyResponse.surveyResponse"></app-public-survey-summary>
            </mat-card-content>
        </mat-card>
                </article>
            </section>
            <section id="quotation" (click)="updateSection('quotation')">
                <input type="radio" name="sections" id="quotationDetails" [disabled]="!hasPublicQuotations()">
                <label for="quotationDetails" class="text-center">
                    <mat-icon  matBadgeColor="red" matBadgePosition="above after"
                               [ngClass]="hasPublicQuotations() ?  'mt-20 ' :  'mt-20 disabled-tab_section'"
                    >assignment
                    </mat-icon>
                    <p [ngClass]="(publicFolder.publicQuotations.length === 0)  ? 'h4 disabled-tab_section' : ' h4'"> {{'FOLDER.COMMENT.QUOTATION_CR.LABEL' | translate}} </p>
                </label>
                <article class="article-scroll" disabled="folder.quotations.length > 0 && activeSection=='quotation'">
                    <app-public-quotation-report-card [folder]="publicFolder"></app-public-quotation-report-card>
                </article>
            </section>
        </div>
    </div>

    <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
        <mat-card class="w-100-p mb-4   mt-16 mat-elevation-aster">
            <div fxLayout="column" fxLayoutGap="30px" ngClass.gt-md="w-100-p" ngClass.lt-md="w-100-p"
                 ngClass.lt-sm="w-100-p">
                <mat-card-content>
                    <div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
                        <div class="text-center pt-44 pb-44 w-100-p">
                            <h2 class=" text-bold">{{'FOLDER_PUBLIC.MESSAGE' | translate}}</h2>
                            <h3 class=" text-bold">{{'FOLDER_PUBLIC.THANKS_MESSAGE' | translate}}</h3>
                        </div>
                    </div>
                </mat-card-content>
            </div>
        </mat-card>
    </div>
</div>