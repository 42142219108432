import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingScreenService } from '../../../shared/services/loading-screen.service';

@Component({
  selector: 'app-loader2',
  templateUrl: './loader2.component.html',
  styleUrls: ['./loader2.component.scss']
})
export class Loader2Component implements OnInit, AfterViewChecked {
  isLoading = false;

  constructor(private loadingScreen: LoadingScreenService, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.loadingScreen.loadingStatus.subscribe((status: boolean) => {
      this.isLoading = status;
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }
}
