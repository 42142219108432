<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="text-bold">
        {{'FOLDER.CHANGE_CURRENT_STATUS'|translate}}
    </h2>

    <button mat-icon-button class="ml-40" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="w-100-p" fxLayout="column" fxLayoutAlign="center center">
    <form [formGroup]="changeCurrentStatusForm"
          (ngSubmit)="onSubmit()"
          class="w-100-p">
        <mat-dialog-content>
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field fxFlex="100" appearance="outline">
                    <mat-label>{{'FOLDER.CHANGE_CURRENT_STATUS'|translate}}</mat-label>
                    <mat-select formControlName="workflowStatus">
                        <mat-option *ngIf="previousWorkflowTaskList.length === 0">{{'FOLDER.PREVIEWS_STATUS'|translate}}</mat-option>
                        <mat-option *ngFor="let system of previousWorkflowTaskList"
                                    [value]="system"> {{'COMPONENT.' + system|translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions fxLayoutAlign="end end">
            <div fxLayout="row">
                <button type="reset" mat-stroked-button (click)="closeDialog()"
                        class="aster_btn_cancel">
                    {{'BUTTON.CANCEL' | translate}}
                </button>
                <button type="submit" mat-stroked-button
                        [disabled]="changeCurrentStatusForm.invalid"
                        [ngClass]="changeCurrentStatusForm.invalid?'aster_btn_disabled':'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </mat-dialog-actions>
    </form>
</div>