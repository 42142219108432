import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Symptom} from '../../../../../../models/symptom.model';
import {Folder, SerialNumberRule} from '../../../../../../models/folder.model';
import {BackOfficeService} from '../../../../../../shared/services/back-office.service';
import {TranslateService} from '@ngx-translate/core';
import {map, startWith} from 'rxjs/operators';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ConfigurationReferential} from '../../../../../../models/configurationReferential.model';
import Fuse from 'fuse.js';
import {FaultCode} from '../../../../../../models/fault-code.model';
import {Observable, of, ReplaySubject} from 'rxjs';
import {SingleUploadModalComponent} from '../../../../single-upload-modal/single-upload-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {NgxImageCompressService} from 'ngx-image-compress';
import {AttachmentTypeEnum} from '../../../../../../models/enums/attachmentType.enum';
import {FileService} from '../../../../../../shared/services/file.service';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {ShowImageModalComponent} from 'app/main/image-modal/show-image-modal.component';
import {FileInfo} from '../../../../../../models/file-info.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {currentLanguage} from '../../../../../../store/organization/organization.selectors';
import {conditions} from '../../../../../../store/configuration/configuration.selectors';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {RuleEvaluationContext} from 'app/models/rules/RuleEvaluationContext';
import {StartLoading} from '../../../../../../store/loader/loader.actions';
import {TaskVariables} from '../../../task.variables';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';
import {SharedService} from '../../../../../../shared/services/shared.service';
import {ConfigV2Service} from '../../../../../../shared/services/config-v2.service';
import {ConfigExtraCodesEnum} from '../../../../../../models/codeLabelObjectWithExtra.model';

@Component({
    selector: 'app-symptom-search',
    templateUrl: './symptom-search.component.html',
    styleUrls: ['./symptom-search.component.scss'],
    animations: [trigger('detailExpand', [state('collapsed', style({
        height: '0px', minHeight: '0'
    })), state('expanded', style({height: '*'})), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))])],
})
export class SymptomSearchComponent extends Unsubscriber implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    maxlength = 4000;
    symptoms: Symptom[];
    symptomsGrid: any = {
        filterForm: null,
        lastApiResultSet: [],
        elements: [],
        selectedElementIndex: -1,
    };
    other = 'OTHER';
    subscriberSymptoms;
    filteredSymptomsList = new ReplaySubject<Symptom[]>();
    fuse: any;
    faultCode: FaultCode = new FaultCode();
    survey: any;
    symptomFound = true;

    serialNumberInfo: SerialNumberRule;
    serialNumberRequired: boolean;

    form: FormGroup;
    totalUploadedPhotos = 0;
    panneConfirmedAttachedFile: FileInfo[];
    currentLanguage$: Observable<string>;
    conditionList$: Observable<ConfigurationReferential[]>;
    isDescriptionRequired = false;
    isLoading = false;
    attachmentsTypes: any = [];

    constructor(
        private backOfficeService: BackOfficeService,
        private store$: Store<AppState>,
        private translateService: TranslateService,
        private matDialog: MatDialog,
        private fileService: FileService,
        private imageCompress: NgxImageCompressService,
        private folderService: FolderService,
        private fb: FormBuilder,
        private configV2Service: ConfigV2Service,
        private sharedService: SharedService) {
        super();
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.conditionList$ = this.store$.pipe(select(conditions), map(value => [...value].sort((a, b) => {
            return this.translateService.instant(a.label) > this.translateService.instant(b.label) ? 1 : -1;
            })));
        this.getSymptoms();
        this.getSerialNumberRule();
        this.anotherSubscription = this.sharedService.getSerialNumberObservable().subscribe(serialNumber => {
            this.symptomsGrid.filterForm.get('serialNumber1').setValue(serialNumber);
        });
        this.anotherSubscription = this.sharedService.getSerialNumber2Observable().subscribe(serialNumber => {
            this.symptomsGrid.filterForm.get('serialNumber2').setValue(serialNumber);
        });
        this.getAttachmentTypes();
    }
    getAttachmentTypes(){
        this.configV2Service.findAllValues(ConfigExtraCodesEnum.ATTACHMENT_TYPE)
            .subscribe(data => {
                this.attachmentsTypes = data;
            });
    }
    private getSerialNumberRule(): void {
        const ruleEvaluationContext = RuleEvaluationContext.fromFolder(this.folder);
        this.anotherSubscription = this.backOfficeService.getSerialNumberRule(ruleEvaluationContext)
            .subscribe(serialNumberRule => {
                if (serialNumberRule) {
                    this.serialNumberInfo = serialNumberRule;
                }
                this.initForm();
            });
    }

    private isLmOrganization() {
        return this.folder.organization.code.startsWith('LM');
    }
    private initForm(): void {
        this.symptomsGrid.filterForm = this.fb.group({
            label: [],
            labelCtrl: [],
            condition: [],
            description: [],
            serialNumber1: [],
            customerPanneNotFound: ['', [Validators.maxLength(25)]]
        });

        this.symptomsGrid.filterForm.get(['condition']).setValidators(this.isLmOrganization() ? null : Validators.required);
        this.symptomsGrid.filterForm.get(['condition']).setValue(this.isLmOrganization() ? null : 'CONSTANT');

        if (this.isElectroDepot()) {
            this.isDescriptionRequired = true;
            this.symptomsGrid.filterForm.get(['description']).setValidators([Validators.required]);
        }

        if (!!this.serialNumberInfo && this.serialNumberInfo.requiredSerialNumber) {
            this.serialNumberRequired = true;
            this.symptomsGrid.filterForm.get('serialNumber1').setValidators([Validators.required]);
        }
        this.symptomsGrid.filterForm.get('serialNumber1').setValue(this.folder.serialNumber1);

        if (!!this.serialNumberInfo && this.serialNumberInfo.requiredSecondSerialNumber) {
            this.symptomsGrid.filterForm.addControl('serialNumber2', new FormControl(null, [Validators.required]));
            this.symptomsGrid.filterForm.get('serialNumber2').setValue(this.folder.serialNumber2);
        }

        this.anotherSubscription = this.symptomsGrid.filterForm.get(['labelCtrl']).valueChanges
            .pipe(startWith(null as string))
            .subscribe(input => {
                const matchedResults = !!input ? this.fuse.search(input).filter(value => value.score < 0.2).map(value => value.item) : this.symptoms;
                this.filteredSymptomsList.next(matchedResults);
            });

        this.anotherSubscription = this.symptomsGrid.filterForm.get(['customerPanneNotFound']).valueChanges
            .pipe(
                startWith(null as string))
            .subscribe(customerPanneNotFound => {
                if (customerPanneNotFound) {
                    this.prepareFaultCodeObject('CUSTOMER_NOT_FOUND_PANNE_CODE', customerPanneNotFound);
                }else if (this.isSymptomSelected()){
                    this.prepareFaultCodeObject(this.symptomsGrid.filterForm.controls.label.value.code, this.symptomsGrid.filterForm.controls.label.value.label);
                }else{
                    this.clearFaultCodeObject();
                }
            });

        this.panneConfirmedAttachedFile = this.folder.attachments.filter(e => e.attachmentType === AttachmentTypeEnum.PANNE_CONFIRMED);
        this.totalUploadedPhotos = this.panneConfirmedAttachedFile.length;
    }
    isSymptomSelected(): boolean {
        return !!this.symptomsGrid.filterForm.controls.label.value;
    }
    getSymptoms(): void {
        const evaluationContext = RuleEvaluationContext.fromFolder(this.folder);
        this.subscriberSymptoms = this.backOfficeService.getSymptoms(evaluationContext)
            .pipe(map(data => data.filter(value => !value.diagnosticCode || !value.diagnosticCode.endsWith('AUTO_REPAIR_DIAGNOSTIC'))));
        this.getAllSymptoms();
    }

    symptomSelected(symptom): void {
        this.symptomFound = true;
        this.prepareFaultCodeObject(symptom.code, symptom.label);
        this.survey = !!symptom.diagnosticCode ?
            {surveyCode: symptom.diagnosticCode} : null;
    }

    toggleSymptomFound(): void {
        this.symptomFound = !this.symptomFound;
    }

    prepareFaultCodeObject(code, label): any {
        this.faultCode.code = code;
        this.faultCode.label = label;
    }
    clearFaultCodeObject(){
        this.faultCode = new FaultCode();
    }

    completeTask(): void {
        this.isLoading = true;
        this.store$.dispatch(new StartLoading());
        this.faultCode.condition = this.symptomsGrid.filterForm.get(['condition']).value;
        const workflowVariables = this.prepareWorkflowVariables();
        workflowVariables['survey'] = this.symptomFound;
        workflowVariables[TaskVariables.PROLONGED_DIAGNOSIS] = false;
        this.inputMap.emit(workflowVariables);
    }

    getAllSymptoms(): void {
        this.subscriberSymptoms.subscribe(symptoms => {
            this.symptoms = symptoms.sort((a, b) => {
                return a.label > b.label ? 1 : -1;
            });
            const options = {
                includeScore: true,
                keys: ['label'],
                ignoreLocation: true
            };
            this.fuse = new Fuse(this.symptoms, options);
            this.filteredSymptomsList.next(this.symptoms);
        });
    }

    conditionChanged(value): void {
        this.symptomsGrid.filterForm.get(['condition']).setValue(value);
    }

    isDisabled(): boolean {
        return this.isLoading || this.faultCode.code === undefined || this.faultCode.label === undefined ||
            this.faultCode.code === '' || this.faultCode.label === '' || this.isValidSerialNumberInfo()
            || (this.isElectroDepot() && this.symptomsGrid.filterForm.get('description').invalid);
    }

    isValidSerialNumberInfo(): boolean {
        return this.symptomsGrid.filterForm.get('serialNumber1').invalid
            || (!!this.serialNumberInfo && (this.totalUploadedPhotos < this.serialNumberInfo.numberOfPhotos))
            || (this.symptomsGrid.filterForm.get('serialNumber2') && this.symptomsGrid.filterForm.get('serialNumber2').invalid);
    }

    onUploadImage(): void {
        const dialogRef = this.matDialog.open(SingleUploadModalComponent, {
            disableClose: true,
            data: {
                hideFileType: true,
                attachmentTypes: this.attachmentsTypes
            }
        });
        dialogRef.afterClosed().subscribe(
            data => {
                if (!!data) {
                    this.onFileChange(data);
                }
            }
        );
    }

    private onFileChange(event): void {
        const reader = new FileReader();
        let fileEvent;
        fileEvent = event[0];
        if (event && event.length) {
            const [file] = event;
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (file.type
                    .toLocaleString()
                    .toLowerCase()
                    .split('/')[0] === 'image') {
                    this.compressFile(reader.result, fileEvent);
                } else {
                    this.updateFile(reader.result.toString().split(',')[1], fileEvent);
                }
            };
        }
    }

    private compressFile(image, event): void {
        this.imageCompress.compressFile(image, 1, 100, 50).then(result => {
            this.updateFile(result.toString().split(',')[1], event);
        });
    }

    private updateFile(file, event): void {
        const imageBlob = this.fileService.dataURItoBlob(file);
        this.saveFile(imageBlob, event);
    }

    private saveFile(file, event): void {
        this.fileService.uploadAttachment(file, event, this.folder.organization.code, this.folder.id, AttachmentTypeEnum.PANNE_CONFIRMED).subscribe(async data => {
            if (data) {
                this.folder.attachments.push(data);
                this.totalUploadedPhotos++;
                this.panneConfirmedAttachedFile.push(data);
            }
            const folderUpdateRequest = {
                attachment: data
            };
            this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then();
        });
    }

    numberOfPhotosToAttachMessage(): Observable<string> {
        return this.translateService.stream('FOLDER.COMMENT_FORM.UPLOAD_IMG.MIN.NUMBER',
            {photosMinNumber: this.serialNumberInfo.numberOfPhotos});
    }

    showImageModal(photos): void {
       this.matDialog.open(ShowImageModalComponent, {
            height: 'auto',
            width: '90vh',
            minWidth: '90%',
            data: of(photos)
        });

    }

    removeFile(fileId): void {
        this.folder.attachments = this.folder.attachments.filter(attachment => attachment.id !== fileId);
        this.folderService.deleteAttachmentFileFolder(this.folder.id, fileId).then(() => {
            this.totalUploadedPhotos--;
            this.panneConfirmedAttachedFile = this.panneConfirmedAttachedFile.filter(e => e.id !== fileId);
        });
    }

    closeByDiagExpress() {
        this.isLoading = true;
        this.store$.dispatch(new StartLoading());
        this.faultCode.condition = this.symptomsGrid.filterForm.get(['condition']).value;
        const workflowVariables = this.prepareWorkflowVariables();
        workflowVariables['closeByDiagExpress'] = true;
        this.inputMap.emit(workflowVariables);
    }

    prepareWorkflowVariables(): any {
        return {
            'symptomSelected': JSON.stringify({
                'faultCode': this.faultCode,
                'surveyCode': this.survey ? this.survey.surveyCode : null,
            }),
            'agentCommentsPanneConfirmed': this.symptomsGrid.filterForm.value.description,
            'serialNumber1': this.symptomsGrid.filterForm.value.serialNumber1 ? this.symptomsGrid.filterForm.value.serialNumber1.trim() : null,
            'serialNumber2': this.symptomsGrid.filterForm.value.serialNumber2 ? this.symptomsGrid.filterForm.value.serialNumber2.trim() : null,
        };
    }

    prolongedDiagnosis() {
        this.isLoading = true;
        this.store$.dispatch(new StartLoading());
        const workflowVariables = this.prepareWorkflowVariables();
        workflowVariables[TaskVariables.PROLONGED_DIAGNOSIS] = true;
        this.inputMap.emit(workflowVariables);
    }

    getAttachmentFile(): void {
        const ruleImageSerialNumber = [];
        for (const fileId of this.serialNumberInfo.imageId) {
            this.fileService.getAttachmentFile(fileId)
                .subscribe(resFile => {
                    const reader = new FileReader();
                    reader.readAsDataURL(resFile);
                    reader.onloadend = () => {
                        ruleImageSerialNumber.push(reader.result);
                    };
                });
        }
        this.showImageModal(ruleImageSerialNumber);
    }

    private isElectroDepot() {
        return this.folder.organization.code.startsWith('ED_');
    }

    protected showProlongedDiagButton(){
        return this.folder.version > 1;
    }
}

