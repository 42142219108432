import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TaskVariables} from '../../../task.variables';
import {Folder} from '../../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-reparation-in-progress',
    templateUrl: './reparation-in-progress.component.html',
    styleUrls: ['./reparation-in-progress.component.css']
})
export class ReparationInProgressComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    repairStatusForm: any = {
        form: null,
        placeholders: []
    };


    constructor() {
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.repairStatusForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
        });
        this.repairStatusForm.placeholders = [
            {
                status: 'NEED_SPARE_PARTS',
                value: !this.variablesTask[TaskVariables.quotationId] ? 'COMPONENT.BUTTON.WAIT_QUOTATION' : 'COMPONENT.BUTTON.CHANGE_QUOTATION',
            },
            {
                // todo demo only not work
                status: 'WARRANTY_DISCLAIMER',
                value: 'BUTTON.WARRANTY_DISCLAIMER',
            },
            {
                status: 'FINISHED',
                value: 'COMPONENT.REPARATION.FINISHED',
            }
        ];
    }

    onSubmit(): void {
        if (this.repairStatusForm.form.value.status === 'NEED_SPARE_PARTS') {
            this.inputMap.emit({
                'needSpareParts': 'true'
            });
        } else if (this.repairStatusForm.form.value.status !== 'NEED_SPARE_PARTS') {
            this.inputMap.emit({});
        }

    }

}
