import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-swap-confirmation-internal-repair',
    templateUrl: './swap-confirmation-internal-repair.component.html',
    styleUrls: ['./swap-confirmation-internal-repair.component.scss']
})
export class SwapConfirmationInternalRepairComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Input() instructionUserTask: InstructionUserTask;
    @Input() folder: Folder;

    form: FormGroup;
    buttons: any[];

    constructor() {
    }

    ngOnInit(): void {
        this.initForm();
        this.initButtons();
    }

    private initForm(): void {
        this.form = new FormGroup({
            status: new FormControl(null, Validators.required)
        });
    }

    private initButtons(): void {
        this.buttons = [
            {
                status: true,
                value: 'BUTTON.NO_RESPONSE_FROM_SUPPLIER',
            },
            {
                status: false,
                value: 'BUTTON.RESPONSE_RECEIVED_FROM_SUPPLIER',
            }
        ];
    }

    onSubmit(): void {
        this.inputMap.emit({'confirmSwap': this.form.value.status});
    }

    disabledButtonForm(): boolean {
        return this.form.invalid;
    }
}
