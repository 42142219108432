<h2 mat-dialog-title>
    {{'FOLDER.NOTIFICATION.CALL_TYPE.TITLE'|translate}}
</h2>
<form *ngIf="remoteAssistanceObjectForm.form" [formGroup]="remoteAssistanceObjectForm.form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <div class="w-100-p mt-12" fxLayout="row wrap" gdColumns="repeat(auto-fit, minmax(100%, 1fr))" gdGap="10px">
            <mat-form-field appearance="outline">
                <mat-label>{{'FOLDER.NOTIFICATION.CALL_TYPE.TYPE'|translate}}</mat-label>
                <mat-select formControlName="type"
                            [placeholder]="'FOLDER.NOTIFICATION.CALL_TYPE.TYPE'|translate"
                            [required]="remoteAssistanceObjectForm.form.get(['type']) | hasRequiredField">
                    <mat-option *ngFor="let notification of notificationsType"
                                [value]="notification">
                        <span>{{'FOLDER.NOTIFICATION.TYPE.'+notification|translate}}</span>
                    </mat-option>
                </mat-select>

            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'FOLDER.NOTIFICATION.CALL_TYPE.COMMENT'|translate}}</mat-label>
                <input matInput [placeholder]="'FOLDER.NOTIFICATION.CALL_TYPE.COMMENT'|translate"
                       formControlName="comment"
                       inputControl
                       [required]="remoteAssistanceObjectForm.form.get(['comment']) | hasRequiredField">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'FOLDER.NOTIFICATION.CALL_TYPE.DURATION'|translate}}</mat-label>
                <input matInput [placeholder]="'FOLDER.NOTIFICATION.CALL_TYPE.DURATION'|translate"
                       formControlName="duration"  type="number"  numberOnly min="0"
                       [required]="remoteAssistanceObjectForm.form.get(['duration']) | hasRequiredField">
            </mat-form-field>
            <mat-progress-bar *ngIf="remoteAssistanceObjectForm.showSubmitLoader"
                              mode="indeterminate"></mat-progress-bar>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close class="aster_btn_cancel" (click)="onClose()">
            {{'CODE_LABEL_CONFIG.FORM.CANCEL_BUTTON' | translate}}
        </button>
        <button type="submit" mat-button aria-label="Submit"
                class="py-8 px-24 font-weight-900 font-size-30"
                [ngClass]="remoteAssistanceObjectForm.form.invalid?'aster_btn_disabled': 'aster_btn'"
                [disabled]="remoteAssistanceObjectForm.form.invalid">
            {{ 'CODE_LABEL_CONFIG.FORM.SUBMIT_BUTTON.NEW' | translate}}
        </button>
    </mat-dialog-actions>
</form>
