import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Invoice, InvoiceItem, InvoiceProducts} from '../../../models/invoice.model';
import {Product} from '../../../models/product.model';
import {WarrantyRule} from '../../../models/warrantyRule.model';

@Injectable({
    providedIn: 'root'
})
export class FolderCreationService {

    private invoice = new BehaviorSubject<Invoice>(null);
    public invoice$ = this.invoice.asObservable();

    private invoiceItem = new Subject<InvoiceItem>();
    public invoiceItem$ = this.invoiceItem.asObservable();

    private lineNumber = new Subject<number>();
    public lineNumber$ = this.lineNumber.asObservable();

    private customerPreferredLanguage = new Subject<string>();
    public customerPreferredLanguage$ = this.customerPreferredLanguage.asObservable();

    private invoiceList = new Subject<Invoice[]>();
    public invoiceList$ = this.invoiceList.asObservable();
    private product = new Subject<Product>();
    private productList = new BehaviorSubject<Product[]>([]);
    public productList$ = this.productList.asObservable();
    private productFromInvoice = new Subject<Product>();
    public productFromInvoice$ = this.productFromInvoice.asObservable();
    private productQuantity = new Subject<number>();
    public productQuantity$ = this.productQuantity.asObservable();

    private productWarranty = new Subject<WarrantyRule>();
    public productWarranty$ = this.productWarranty.asObservable();

    private invoiceProductPage = new BehaviorSubject<InvoiceProducts>(null);
    public pagedInvoiceProducts$ = this.invoiceProductPage.asObservable();

    private isFilteredProducts = new BehaviorSubject<boolean>(false);
    public isFilteredProducts$ = this.isFilteredProducts.asObservable();

    private productPage = new Subject<number>();
    public productPage$ = this.productPage.asObservable();

    private _filterValue = '';

    public productFilterPageChanged$ = new BehaviorSubject(null);

    public newProductSearchFilter$ = new BehaviorSubject(null);

    constructor() {
    }

    public invoiceItemChanged(invoiceItem: InvoiceItem): void{
        this.invoiceItem.next(invoiceItem);
    }

    public lineNumberSelected(lineNumber: number): void {
        this.lineNumber.next(lineNumber);
    }

    public customerPreferredLanguageChanged(language: string): void {
        this.customerPreferredLanguage.next(language);
    }

    public invoiceProductsPageChanged(invoiceProducts: InvoiceProducts): void {
        this.invoiceProductPage.next(invoiceProducts);
    }
    public productWarrantyChanged(warrantyRule: WarrantyRule): void {
        this.productWarranty.next(warrantyRule);
    }

    public productQuantityChanged(quantity: number): void {
        this.productQuantity.next(quantity);
    }


    public invoiceChanged(invoice: Invoice): void {
        this.invoice.next(invoice);
    }

    public invoiceListChanged(invoices: Invoice[]): void {
        this.invoiceList.next(invoices);
    }

    public productChanged(product: any): void {
        this.product.next(product);
    }

    public getNewProduct() {
        return this.product.asObservable();
    }

    public productFromInvoiceChanged(product: any): void {
        this.productFromInvoice.next(product);
    }

    public productListChanged(products: Product[]): void {
        this.productList.next(products);
    }
    public productListIsFilteredResult(isFilteredProducts: boolean): void {
        this.isFilteredProducts.next(isFilteredProducts);
    }

    public resetFilter(){
        this._filterValue = '';
    }

    set filterValue(filterValue: string){
        this._filterValue = filterValue;
    }

    get filterValue(){
        return this._filterValue;
    }
}
