export class TypeOfService {
    id: string;
    organizationCode?: string;
    code: string;
    label: string;
    price: string;
    type: TypeOfServiceEnum;

    constructor(obj: ITypeOfService) {
        Object.assign(this, obj);
    }
}

export enum TypeOfServiceEnum {
    SPARE_PART = 'SPARE_PART',
    WORKFORCE = 'WORKFORCE',
    DISPLACEMENT = 'DISPLACEMENT',
    QUOTATION_FEES = 'QUOTATION_FEES',
    VISIO_QUALIFICATION_FEES = 'VISIO_QUALIFICATION_FEES',
    VISIO_REPAIR_FEES = 'VISIO_REPAIR_FEES',
    SHIPMENT_FEES = 'SHIPMENT_FEES'
}

export enum TypeOfServicePublicRepairerEnum {
    SPARE_PART = 'SPARE_PART',
    WORKFORCE = 'WORKFORCE',
    SHIPMENT_FEES = 'SHIPMENT_FEES'
}

export interface ITypeOfService {
    id: string;
    organizationCode?: string;
    code: string;
    label: string;
    price: string;
    type: TypeOfServiceEnum;
}
