<div class="page-layout blank p-24">
    <div cdkDropListGroup>
        <div fxLayout="row" fxLayoutAlign="end start">
            <div class="example-container mat-elevation-z2 p-4" fxLayout="column">

                <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-8">
                    <div class="h2">{{'DASHBOARD.WIDGET.FORM.SHOP' |translate}}</div>
                    <div (click)="showWidgetList=!showWidgetList" class="cursor-pointer">
                        <mat-icon>{{showWidgetList ? 'expand_less' : 'expand_more'}}</mat-icon>
                    </div>
                </div>
                <ng-container *ngIf="showWidgetList">
                    <div
                        cdkDropList
                        [cdkDropListData]="chartsResponse"
                        class="example-list"
                        (cdkDropListDropped)="drop($event,'')">
                        <div *ngFor="let widgetData of chartsResponse;let j=index;">
                            <div cdkDrag class="example-box">
                                {{widgetData?.name}}
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="5px">
                        <button type="button" mat-button matTooltip="{{'DASHBOARD.WIDGET.FORM.ADD_LIST' | translate}}"
                                class="w-100-p aster-btn_add_widget_list"
                                (click)="addNewLine()">
                            <mat-icon>keyboard_return</mat-icon>
                            {{'DASHBOARD.WIDGET.FORM.ADD_LIST' | translate}}
                        </button>
                        <button type="button" mat-button
                                matTooltip="{{'DASHBOARD.WIDGET.FORM.SAVE_CHARTS' | translate}}"
                                class="w-100-p aster-btn_add_widget_list"
                                (click)="saveRule()">
                            <mat-icon>save</mat-icon>
                            {{'DASHBOARD.WIDGET.FORM.SAVE_CHARTS' | translate}}
                        </button>
                    </div>
                </ng-container>

            </div>


        </div>

        <div>
            <ng-container *ngFor="let chart of charts | keyvalue ;let i=index;">
                <div
                    cdkDropList
                    [cdkDropListData]="chart.value"
                    class="example-widget-list w-100-p mb-20"
                    cdkDropListSortingDisabled
                    (cdkDropListDropped)="drop($event,chart.key)">
                    <ng-container *ngIf="!!chart && chart.value.length>0 ;else EMPTY_CHART">
                        <div gdGap="20px"
                             [gdAreas]="getAreasChart(chart.key)" class="w-100-p">
                            <ng-container *ngFor="let widgetData of chart.value;">

                                <div [gdArea]="getChartName(widgetData.name)" class="w-100-p">

                                    <ng-container [ngSwitch]="widgetData.type ">
                                        <ng-container *ngSwitchCase="'METRIC'">
                                            <div class="cursor-pointer" fxFlex>
                                                <widget-price-count [widgetData]="widgetData"
                                                                    [columnCount]="getChart(widgetData.name).columnCount"
                                                                    [id]="getChart(widgetData.name).id"
                                                                    (updatedColumnCount)="updateChartColumn(widgetData.name,$event)"
                                                                    (dropWidget)="dropWidget(widgetData.name)"></widget-price-count>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'MAP'">
                                            <div class="cursor-pointer" fxFlex
                                                 *ngIf="widgetData && widgetData.data.length > 0">
                                                <app-leaflet-markercluster class="mt-24"
                                                                           [columnCount]="getChart(widgetData.name).columnCount"
                                                                           [id]="getChart(widgetData.name).id"
                                                                           (updatedColumnCount)="updateChartColumn(widgetData.name,$event)"
                                                                           [widgetData]="widgetData"
                                                                           (dropWidget)="dropWidget(widgetData.name)"></app-leaflet-markercluster>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'BAR_CHART'">
                                            <div class="cursor-pointer" fxFlex>
                                                <widget-bar-chart-horizontal
                                                    [columnCount]="getChart(widgetData.name).columnCount"
                                                    [id]="getChart(widgetData.name).id"
                                                    (updatedColumnCount)="updateChartColumn(widgetData.name,$event)"
                                                    [widgetData]="widgetData"
                                                    (dropWidget)="dropWidget(widgetData.name)"></widget-bar-chart-horizontal>

                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'PIE_CHART'">
                                            <div class="cursor-pointer" fxFlex>
                                                <widget-pie-chart [widgetData]="widgetData"
                                                                  [columnCount]="getChart(widgetData.name).columnCount"
                                                                  [id]="getChart(widgetData.name).id"
                                                                  (updatedColumnCount)="updateChartColumn(widgetData.name,$event)"
                                                                  (dropWidget)="dropWidget(widgetData.name)"></widget-pie-chart>

                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'ADVANCED_PIE_CHART'">
                                            <div class="cursor-pointer" fxFlex>
                                                <advanced-pie-chart [widgetData]="widgetData"
                                                                    [columnCount]="getChart(widgetData.name).columnCount"
                                                                    [id]="getChart(widgetData.name).id"
                                                                    (updatedColumnCount)="updateChartColumn(widgetData.name,$event)"
                                                                    (dropWidget)="dropWidget(widgetData.name)"></advanced-pie-chart>

                                            </div>
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'PIE_CHART_GRID'">
                                            <div class="cursor-pointer" fxFlex>
                                                <pie-chart-grid [widgetData]="widgetData"
                                                                [columnCount]="getChart(widgetData.name).columnCount"
                                                                [id]="getChart(widgetData.name).id"
                                                                (updatedColumnCount)="updateChartColumn(widgetData.name,$event)"
                                                                (dropWidget)="dropWidget(widgetData.name)"></pie-chart-grid>

                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'TABLE'">
                                            <div class="cursor-pointer" fxFlex>
                                                <widget-list-table [widgetData]="widgetData"
                                                                   [columnCount]="getChart(widgetData.name).columnCount"
                                                                   [id]="getChart(widgetData.name).id"
                                                                   (updatedColumnCount)="updateChartColumn(widgetData.name,$event)"
                                                                   (dropWidget)="dropWidget(widgetData.name)"></widget-list-table>

                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #EMPTY_CHART>
                        <div class="mt-20" fxLayout="column" fxLayoutAlign="center center">
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <mat-icon>playlist_add</mat-icon>
                                <div class="ml-8">{{'DASHBOARD.WIDGET.FORM.EMPTY_LIST' |translate}}</div>
                            </div>

                        </div>
                        <div class="cursor-pointer mr-4 mb-4" fxLayout="row" fxLayoutAlign="center center"
                             (click)="deleteChartLine(chart.key)">
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <mat-icon color="warn">delete_forever</mat-icon>
                                <div class="ml-8">{{'DASHBOARD.WIDGET.FORM.DELETE_LIST' |translate}}</div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
        </div>


    </div>
</div>
