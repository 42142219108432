import {CodeLabel} from '../../models/element.model';

export class UserState {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    attributes: KeyCloackAttributes;
    businessLink: string;
    affectedBusinessLinks: CodeLabel[];
    roles: string[];
    context: string;
    affectedSite: string;
    accessSites: string[];
    organizationCode: string;
    organizationLabel: string;
    isAuthenticated: boolean;
    scopes: string[];
    isFirstLoad: boolean;
}

export class KeyCloackAttributes {
    organizations: string[];
    contexts: string[];
    businessLinks: string[];
}

export const initialUserState = {
    firstName: '',
    lastName: '',
    businessLink: '',
    organizationCode: '',
    context: '',
    roles: [],
    affectedBusinessLinks: [],
    attributes: {
        organizations: [],
        contexts: [],
        businessLinks: [],
    },
    accessSites: [],
    isAuthenticated: false,
    scopes: []
} as UserState;
