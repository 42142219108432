import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {CalendarDayElement} from './calendarRdv.model';
import {CalendarRdvService} from './calendar-rdv.service';
import {DaysName} from './daynames';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {Observable} from 'rxjs';
import {currentLanguage} from '../../../store/organization/organization.selectors';

@Component({
    selector: 'app-calendar-rdv',
    templateUrl: './calendar-rdv.component.html',
    styleUrls: ['./calendar-rdv.component.scss']
})
export class CalendarRdvComponent implements OnChanges, OnInit {

    @Input() isStaticWeekData: boolean;
    @Input() nbrDays: number;
    @Input() beginDate: Date;
    @Input() descriptionCalender: string;

    @Input() listElementsDays: CalendarDayElement[];

    // maximum of selected RDV
    @Input() maxElementsSelected: number;

    /*
    * Structure of input listDaysIgnored
     [{name: "Jour de l'an", date: Moment}]
    */
    @Input() listDaysIgnored;

    // Result : list of RDV selected
    @Output() elementSelected = new EventEmitter<any>();

    // List of RDV selected
    @Input() daysChecked;

    daysOfWeek = [];

    today;
    disabledPreview = false;
    listDays = [];
    minDate = moment().add('1', 'days');
    language = 'en-EN';
    currentLanguage$: Observable<string>;

    constructor(private translate: TranslateService,
                @Inject(LOCAL_STORAGE) private localStorage: StorageService,
                private store$: Store<AppState>,
                private calendarRdvService: CalendarRdvService) {
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.today = moment(new Date());
        this.calendarRdvService.calendarElement$.subscribe(data => {
            this.listElementsDays = data.fullCalendarDayElement;
            this.getWeekDates(data.dateFrom.toDate());
        });
        if (this.isStaticWeekData) {
            this.getWeekDates(this.today);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        switch (true) {
            case window.innerWidth <= 375: {
                this.nbrDays = 2;
                break;
            }
            case window.innerWidth <= 768: {
                this.nbrDays = 4;
                break;
            }
        }
    }

    /*
    * Initialise into calender of current week
    */
    getWeekDates(referenceDate: Date): void {
        this.listDays = [];
        this.daysOfWeek = [];
        this.beginDate = referenceDate;
        let dayOfWeek = moment(this.beginDate).locale(this.language);
        this.daysOfWeek.push(dayOfWeek);
        for (let i = 1; i < this.nbrDays; i++) {
            dayOfWeek = moment(dayOfWeek).add(1, 'days');
            this.daysOfWeek.push(dayOfWeek.locale(this.language));
        }
        this.setElementsOfDay();
    }

    setElementsOfDay(): void {
        if (this.listElementsDays) {
            let compareMoment: boolean;
            let existHoliday: boolean;
            this.checkPreviousButton(moment(this.today).toDate(), this.daysOfWeek[0].toDate());
            for (let day = 0; day < this.nbrDays; day++) {
                compareMoment = this.compareTowMoment(this.today.toDate(), this.daysOfWeek[day].toDate());
                if (this.listDaysIgnored) {
                    existHoliday = this.listDaysIgnored.filter(holiday =>
                        holiday.date.toDate().getMonth() === this.daysOfWeek[day].toDate().getMonth()
                        && holiday.date.toDate().getDate() === this.daysOfWeek[day].toDate().getDate()).length > 0;
                }
                if (existHoliday || compareMoment || this.daysOfWeek[day].format('dddd').toLocaleUpperCase() === DaysName.SUNDAY) {
                    this.listDays.push({'date': this.daysOfWeek[day], 'dayElements': [null, null]});
                } else {
                    let elementofDay;
                    elementofDay = this.listElementsDays.filter(element => element.day === this.daysOfWeek[day].format('dddd').toLocaleUpperCase());
                    if (elementofDay[0]) {

                        this.listDays.push({'date': this.daysOfWeek[day], 'dayElements': elementofDay[0].dayElements});
                    } else {
                        this.listDays.push({'date': this.daysOfWeek[day], 'dayElements': []});
                    }
                }
            }
        }
    }

    nextWeek(): void {
        if (!this.isStaticWeekData) {
            this.calendarRdvService.loadNexWeekData(this.listDays[this.listDays.length - 1].date.add(1, 'days'));
            return;
        }
        this.loadNextWeek();
    }


    private loadNextWeek(): void {
        let last_element = this.listDays[this.listDays.length - 1].date;
        this.daysOfWeek = [];
        for (let i = 0; i < this.nbrDays; i++) {
            last_element = moment(last_element).add(1, 'days');
            this.daysOfWeek.push(last_element.locale(this.language));
        }
        this.listDays = [];
        this.disabledPreview = false;
        this.setElementsOfDay();
    }

    previewWeek(): void {
        if (!this.isStaticWeekData) {
            this.calendarRdvService.loadLastWeekData(moment(this.listDays[0].date).subtract(this.nbrDays, 'days').toDate());
            return;
        }
        this.loadPreviousWeek();
    }

    private loadPreviousWeek(): void {
        let last_element = this.listDays[0].date;
        this.daysOfWeek = [];
        for (let i = 0; i < this.nbrDays; i++) {
            last_element = moment(last_element).subtract(1, 'day');
            this.daysOfWeek = [last_element.locale(this.language)].concat(this.daysOfWeek);
        }
        this.listDays = [];
        this.setElementsOfDay();
    }

    changeBeginDay(type: string, event: MatDatepickerInputEvent<Date>): void {
        const beginDate = moment(event.value).locale(this.language).toDate();
        if (!this.isStaticWeekData) {
            this.calendarRdvService.changeBeginDate(moment(event.value).toDate());
            return;
        }

        this.loadFromBeginDate(beginDate);
    }

    private loadFromBeginDate(fromDate: Date): void {
        this.disabledPreview = false;
        this.daysOfWeek = [];
        let dayOfWeek = moment(fromDate);
        this.daysOfWeek.push(dayOfWeek);
        for (let i = 1; i < this.nbrDays; i++) {
            dayOfWeek = moment(dayOfWeek).add(1, 'days');
            this.daysOfWeek.push(dayOfWeek.locale(this.language));
        }
        this.listDays = [];
        this.setElementsOfDay();
    }

    checkDateIntoElementSelected(date, elementSelected): boolean {
        for (const daychecked of this.daysChecked) {
            if (date.toDate().getDate() === daychecked.date.toDate().getDate()
                && date.toDate().getFullYear() === daychecked.date.toDate().getFullYear()
                && date.toDate().getMonth() === daychecked.date.toDate().getMonth()
                && daychecked.elementSelected === elementSelected) {
                return true;
            }
        }
        return false;
    }

    rdvChecked(date, elementSelected): void {
        const existDate = this.checkDateIntoElementSelected(date, elementSelected);
        if (existDate) {
            this.daysChecked = this.daysChecked.filter(daychecked => daychecked.elementSelected !== elementSelected);
        } else {
            if (this.daysChecked.length < this.maxElementsSelected) {
                this.daysChecked.push({'date': date, 'elementSelected': elementSelected});
            }
        }
        this.elementSelected.emit(this.daysChecked);
    }

    checkDayIntoDaysChecked(day, elementOfDateSelected): boolean {
        for (const dayChecked of this.daysChecked) {
            if (day.date.toDate().getDate() === dayChecked.date.toDate().getDate()
                && day.date.toDate().getFullYear() === dayChecked.date.toDate().getFullYear()
                && day.date.toDate().getMonth() === dayChecked.date.toDate().getMonth()) {
                if (dayChecked.elementSelected.beginHour === elementOfDateSelected.beginHour) {
                    return true;
                }
            }
        }
        return false;
    }

    checkPreviousButton(currentDate: Date, otherDate: Date): void {
        this.disabledPreview = currentDate.getMonth() === otherDate.getMonth()
            && currentDate.getFullYear() === otherDate.getFullYear() && otherDate.getDate() <= currentDate.getDate();
    }

    compareTowMoment(currentDate: Date, otherDate: Date): boolean {
        return !((currentDate.getFullYear() < otherDate.getFullYear())
            || (currentDate.getMonth() < otherDate.getMonth() || currentDate.getDate() < otherDate.getDate()));

    }


    dayUpperCase(dayName: string): string {
        return this.translate.instant('GENERAL.DATE_FORMAT_CALENDAR_' + dayName.toUpperCase());
    }
}
