import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {Folder} from '../../../../../models/folder.model';
import {Address} from '../../../../../models/customer/customer.model';
import {TranslateService} from '@ngx-translate/core';
import {SharedService} from '../../../../../shared/services/shared.service';
import {AddressType} from '../../../../../models/enums/addressType.enum';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TaskVariables} from '../../task.variables';
import {OperationModeEnum} from '../../../../../models/enums/operationMode.enum';
import {takeUntil} from 'rxjs/operators';
import {CustomerUtils} from '../../../../../shared/utils/customer-utils';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-validate-customer-address',
    templateUrl: './validate-customer-address.component.html',
    styleUrls: ['./validate-customer-address.component.css']
})
export class ValidateCustomerAddressComponent implements OnInit, OnDestroy {


    protected _onDestroy = new Subject<void>();

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();
    @Output() previewTask = new EventEmitter<any>();

    customerAddress: Address;
    addresses: any;
    customerAddressForm: any = {
        form: null,
    };
    addressOutput: Address;
    validateFormAddress: boolean;

    constructor(private translateService: TranslateService,
                private sharedService: SharedService) {
    }

    ngOnInit(): void {
        this.initCustomerDetailForm();
        this.initCustomerDetailDataWithCustomerData();
    }

    initCustomerDetailDataWithCustomerData(): void {
        this.customerAddress = this.getDefaultAddress();

        if (!!this.customerAddress) {
            this.customerAddressForm.form.get(['countryCode']).setValue(this.customerAddress.countryCode);
        }
    }

    getDefaultAddress(): Address {
        return CustomerUtils.getCustomerAddressByType(this.folder.customer.addresses, AddressType.DEFAULT);
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();

    }

    initCustomerDetailForm(): void {
        this.customerAddressForm.form = new FormGroup({
            countryCode: new FormControl(null, Validators.required),
        });
        this.sharedService.getUpdateAddressEvent().pipe(takeUntil(this._onDestroy)).subscribe(res => {
            if (!res) {
                this.customerAddressForm.form.get(['countryCode']).setErrors({'incorrect': true});
                return;
            }
            this.customerAddressForm.form.get(['countryCode']).setValue(res.countryCode);
            this.addressOutput = res;
        });
        this.sharedService.getValidateForm().pipe(takeUntil(this._onDestroy)).subscribe(validate => {
            this.validateFormAddress = validate;
        });
    }

    isInvalidForm(): boolean {
        return this.customerAddressForm.form.invalid || !this.validateFormAddress;
    }
    onSubmit(): void {
        if (this.isInvalidForm()) {
            return;
        }

        this.customerAddress = {
            countryName: this.translateService.instant('COUNTRY.NAME.' + this.addressOutput.countryCode),
            countryCode: this.addressOutput.countryCode,
            city: this.addressOutput.city,
            zipCode: this.addressOutput.zipCode,
            address: this.addressOutput.address,
            secondAddress: this.addressOutput.secondAddress,
            type: AddressType.DEFAULT
        };

        this.inputMap.emit({
            'defaultCustomerDetails': JSON.stringify({
                'defaultAddress': this.customerAddress, 'contact': this.folder.customer.contact
            })
        });

    }

    reset(): void {
        this.customerAddress = null;
        this.sharedService.updateResetAddress(this.customerAddress);
    }

    getPreviewTask(): void {
        let activityMap = {};
        switch (this.folder.operationMode) {
            case OperationModeEnum.AUTO_REPAIR: {
                if (JSON.parse(this.variablesTask[TaskVariables.customerAbleToRepair])) {
                    activityMap = {
                        'currentTask': 'VALIDATE_CUSTOMER_ADDRESS',
                        'previewsTask': 'SUGGEST_PIECE_AND_REPAIR_TUTO'
                    };
                } else {
                    activityMap = {
                        'currentTask': 'VALIDATE_CUSTOMER_ADDRESS',
                        'previewsTask': 'WAITING_ASSISTANCE_TO_CALL'
                    };
                }
                break;
            }
            case OperationModeEnum.SWAP: {
                activityMap = {
                    'currentTask': 'VALIDATE_CUSTOMER_ADDRESS',
                    'previewsTask': 'WAITING_FOR_HS_PRODUCT_DESTRUCTION_BY_CUSTOMER'
                };
                break;
            }
        }
        this.previewTask.emit(activityMap);
    }

}
