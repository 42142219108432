import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FolderPublic} from '../../../../models/folder.public.model';
import {FormControl, FormGroup} from '@angular/forms';
import {ConfigCodesEnum} from '../../../../models/codeLabelObject.model';
import {PublicService} from '../../../../shared/services/public.service';
import {PageSearchRequest} from '../../../../models/page-search-request.model';

@Component({
    selector: 'app-spare-parts-order-waiting-preparation',
    templateUrl: './spare-parts-order-waiting-preparation.component.html',
    styleUrls: ['./spare-parts-order-waiting-preparation.component.css']
})
export class SparePartsOrderWaitingPreparationComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: FolderPublic;
    carriers = new Array<any>();
    prepareOrderForm: FormGroup;

    constructor(private publicService: PublicService) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initCarrierList();
    }

    private initForm(): void {
        this.prepareOrderForm = new FormGroup({
            carrier: new FormControl(null),
            trackingNumber: new FormControl(null),
        });

    }

    initCarrierList(): void {
        this.carriers = [];
        const pageCriteria: PageSearchRequest = {
            page: 0, size: 100,
        };
        const inputs = new Map();
        this.publicService.bulkSearchCodeLabelObjects(this.folder.externalUid, ConfigCodesEnum.CARRIER, pageCriteria, inputs).then(page => {
            this.carriers = page.content;
        }).catch(() => {
            this.carriers = [];
        });
    }

    onSubmit(): void {
        this.inputMap.emit({
            'carrierCode': this.prepareOrderForm.value.carrier ? this.prepareOrderForm.value.carrier.code : '',
            'trackingNumber': this.prepareOrderForm.value.trackingNumber
        });
    }
}
