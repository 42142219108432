import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Folder, SerialNumberRule} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BackOfficeService} from '../../../../../shared/services/back-office.service';
import {Permission} from '../../../../../models/permession.model';
import {GLOBAL} from '../../../../../app-config';
import {RuleEvaluationContext} from '../../../../../models/rules/RuleEvaluationContext';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';
import {SharedService} from '../../../../../shared/services/shared.service';
import {Unsubscriber} from '../../../../../unsubscriber';


@Component({
    selector: 'app-panne-confirmed.component',
    templateUrl: './panne-confirmed.component.html',
    styleUrls: ['./panne-confirmed.component.scss']
})
export class PanneConfirmedComponent extends Unsubscriber implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Output() previewTask = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Input() permission: Permission;

    submitInProgress = false;
    SymptomDescForm = {
        form: null
    };

    fileName: string;
    attachmentsFiles = [];
    serialNumberInfo: SerialNumberRule;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private backOfficeService: BackOfficeService,
        private sharedService: SharedService) {
        super();
    }

    ngOnInit(): void {
        this.getSerialNumberRule();
        this.attachmentsFiles = this.folder.attachments;
        this.anotherSubscription = this.sharedService.getSerialNumberObservable().subscribe(serialNumber => {
            this.SymptomDescForm.form.get('serialNumber1').setValue(serialNumber);
        });
    }

    private getSerialNumberRule(): void {
        const ruleEvaluationContext = RuleEvaluationContext.fromFolder(this.folder);
        this.backOfficeService.getSerialNumberRule(ruleEvaluationContext)
            .subscribe(serialNumberRule => {
                if (serialNumberRule) {
                    this.serialNumberInfo = serialNumberRule;
                }
                this.initSymptomDescriptionForm();
            });
    }

    initializeVariablesTask(): void {
        if (this.variablesTask['panneConfirmed']) {
            const panneConfirmed = JSON.parse(this.variablesTask['panneConfirmed']);
            this.SymptomDescForm.form.get(['description']).setValue(panneConfirmed.agentComment.content);
            this.SymptomDescForm.form.get(['serialNumber1']).setValue(panneConfirmed.serialNumber1 ? panneConfirmed.serialNumber1 : null);
            this.SymptomDescForm.form.get(['serialNumber2']).setValue(panneConfirmed.serialNumber2 ? panneConfirmed.serialNumber2 : null);

        }
    }

    initSymptomDescriptionForm(): void {
        this.SymptomDescForm.form = new FormGroup({
            description: new FormControl(),
            serialNumber1: new FormControl(null, Validators.pattern(GLOBAL.validationPatterns.serialNumber))
        });
        if (!!this.serialNumberInfo && this.serialNumberInfo.requiredSerialNumber) {
            this.SymptomDescForm.form.get('serialNumber1').setValidators([Validators.required]);
        }
        this.SymptomDescForm.form.get('serialNumber1').setValue(this.folder.serialNumber1);
        if (!!this.serialNumberInfo && this.serialNumberInfo.requiredSecondSerialNumber) {
            this.SymptomDescForm.form.addControl('serialNumber2', new FormControl(null, [Validators.required , Validators.pattern(GLOBAL.validationPatterns.serialNumber)]));
        }
        this.initializeVariablesTask();

    }

    disableForm(): void {
        this.submitInProgress = true;
        this.SymptomDescForm.form.get('serialNumber1').disable();
        if (!!this.SymptomDescForm.form.get('serialNumber2')) {
            this.SymptomDescForm.form.get('serialNumber2').disable();
        }
        this.SymptomDescForm.form.get('description').disable();
    }

    onSubmit(): void {
        if (this.SymptomDescForm.form.get('serialNumber1').valid) {
            this.disableForm();
            // Notify PanneConfirmedComponent of data customerComment and serialNumber1
            this.inputMap.emit(
                {
                    'agentCommentsPanneConfirmed': this.SymptomDescForm.form.value.description,
                    'serialNumber1': this.SymptomDescForm.form.value.serialNumber1 ? this.SymptomDescForm.form.value.serialNumber1 : null,
                    'serialNumber2': this.SymptomDescForm.form.value.serialNumber2 ? this.SymptomDescForm.form.value.serialNumber2 : null
                }
            );
        }
    }

    getPreviewTask(): void {
        this.previewTask.emit({
            'currentTask': 'PANNE_CONFIRMED',
            'previewsTask': 'SYMPTOM_SELECTED'
        });
    }

    disabledValidateButton(): boolean {
        return this.submitInProgress ||
            this.SymptomDescForm.form.get('serialNumber1').invalid
            || (this.SymptomDescForm.form.get('serialNumber2') &&
                this.SymptomDescForm.form.get('serialNumber2').invalid);
    }
}
