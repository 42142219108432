import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {QuotationLine} from '../../../../../../../models/quotation.model';
import {EditQuotationData} from './editQuotationModal.model';
import {select, Store} from '@ngrx/store';
import {currency, margin, vats} from '../../../../../../../store/organization/organization.selectors';
import {TypeOfServiceEnum} from '../../../../../../../models/typeOfService.model';
import {Unsubscriber} from '../../../../../../../unsubscriber';
import {Observable, Subject} from 'rxjs';
import {Vat} from '../../../../../../../models/vat.model';
import {AppState} from '../../../../../../../store/app.state';
import {TypeOfBenefitService} from '../../../../../../../shared/services/typeOfBenefit.service';
import {TranslateService} from '@ngx-translate/core';
import {Amount} from '../../../../../../../models/amount.model';
import {QuotationType} from '../../../../../../../models/enums/quotationType.enum';
import {Payer} from '../../../../../../../models/enums/payer.enum';
import {SparePartService} from '../../../../../../../shared/services/spare-part.service';
import {FuseConfirmDialogComponent} from '../../../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {cloneDeep} from 'lodash';
import {MatSelectChange} from '@angular/material/select';

@Component({
    selector: 'app-edit-quotation-modal',
    templateUrl: './edit-quotation-modal.component.html',
    styleUrls: ['./edit-quotation-modal.component.scss']
})
export class EditQuotationModalComponent extends Unsubscriber implements OnInit {

    displayedColumns: string[] = ['type', 'code', 'label', 'purchasePrice', 'marginPercentage', 'price', 'quantity', 'discount', 'vat', 'payer', 'totalPrice', 'action'];

    quotationLines = new MatTableDataSource<QuotationLine>();
    typeOfBenefits = [];
    totalPriceTTC: Amount;
    discountQuotation = 0;
    currency: string;
    vatsList$: Observable<Vat[]>;
    defaultMargin: number;
    _onDestroy = new Subject<void>();

    defaultVat;
    payers = [];

    totalPriceHTAfterDiscount: Amount;
    totalDiscount: Amount;
    totalPriceHT: Amount;
    totalVAT: Amount;

    constructor(@Inject(MAT_DIALOG_DATA) public editQuotationData: EditQuotationData,
                private dialogRef: MatDialogRef<EditQuotationModalComponent>,
                private matDialog: MatDialog,
                private store$: Store<AppState>,
                private typeOfBenefitService: TypeOfBenefitService,
                private translateService: TranslateService,
                private sparePartService: SparePartService
    ) {
        super();
        this.payers = Object.keys(Payer);
    }

    ngOnInit(): void {
        this.initCurrency();
        this.initDefaultVat();
        this.initTypeOfBenefitService();
        if (!!this.editQuotationData.initialQuotation) {
            this.loadInitialQuotation();
        }
    }

    private initCurrency() {
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => this.currency = currency);
    }

    private initDefaultVat() {
        this.vatsList$ = this.store$.pipe(select(vats));
        this.vatsList$.subscribe(vats => {
            this.defaultVat = vats.find(value => value.standard === true).rate;
        });
    }

    private initTypeOfBenefitService() {
        const sparePart = {
            code: TypeOfServiceEnum.SPARE_PART,
            label: this.translateService.instant('MODAL.QUOTATION.TYPE.SPARE_PART')
        };
        this.typeOfBenefits.push(sparePart);
        this.typeOfBenefitService.all().subscribe(types => {
            types.forEach(value => {
                this.typeOfBenefits.push(value);
            });
        });
    }

    loadInitialQuotation(): void {
        const quotation = cloneDeep(this.editQuotationData.initialQuotation);
        this.quotationLines.data = quotation.quotationLines?.length > 0 ? quotation.quotationLines : [];
        this.discountQuotation = quotation?.discount ? quotation.discount : 0;
        this.totalPriceTTC = !!quotation?.totalPrice ? quotation.totalPrice : this.getAmountZero();
        this.totalPriceHTAfterDiscount = !!quotation?.totalPriceHTAfterDiscount ? quotation.totalPriceHTAfterDiscount : this.getAmountZero();
        this.totalDiscount = !!quotation?.totalDiscount ? quotation.totalDiscount : this.getAmountZero();
        this.totalPriceHT = !!quotation?.totalPriceHT ? quotation.totalPriceHT : this.getAmountZero();
        this.totalVAT = !!quotation?.totalVAT ? quotation.totalVAT : this.getAmountZero();
    }

    private getAmountZero() {
        return {
            value: 0,
            currency: this.currency
        };
    }

    changePayer($event: MatSelectChange, quotationLine) {
        if ($event.value !== Payer.CLIENT) {
            quotationLine.priceAmount.value = 0;
            this.changeLinePrice(quotationLine);
        } else {
            this.getToTalPriceAfterMargin(quotationLine);
        }
    }

    getToTalPriceAfterMargin(quotationLine) {
        this.store$.pipe(select(margin)).subscribe(value => {
            this.defaultMargin = value;
            quotationLine.marginPercentage = !!this.defaultMargin && quotationLine.purchasePrice.value !== 0 ? this.defaultMargin : 0;
            quotationLine.priceAmount.value = this.computeSellingPrice(quotationLine.purchasePrice.value, quotationLine.marginPercentage);

            this.quotationLines.data
                .filter(value => value.code === quotationLine.code)
                .map(quotationLine => {
                    quotationLine.totalLinePrice.value = this.sparePartService
                        .getTotalLinePrice(quotationLine.priceAmount.value, quotationLine.quantity, quotationLine.discount, this.defaultVat);
                });

            this.refreshDataSourceTable();
        });
    }

    private computeSellingPrice(purchasePrice: number, margin: number): number {
        return this.sparePartService.normalizeFloat((purchasePrice * (1 + (margin / 100))).toFixed(2));
    }

    changeLinePrice(quotationLine): void {
        this.quotationLines.data
            .filter(value => value.code === quotationLine.code)
            .map(quotationLine => {
                quotationLine.marginPercentage = this.getMargin(quotationLine.priceAmount.value, quotationLine.purchasePrice.value);
                quotationLine.totalLinePrice.value = this.sparePartService
                    .getTotalLinePrice(quotationLine.priceAmount.value, quotationLine.quantity, quotationLine.discount, this.defaultVat);
            });
        this.refreshDataSourceTable();
    }

    private getMargin(sellingPrice: number, purchasePrice: number): number {
        return purchasePrice > 0 ? this.sparePartService.normalizeFloat((((sellingPrice - purchasePrice) / purchasePrice) * 100).toFixed(2)) : 0;
    }

    refreshDataSourceTable(): void {
        this.quotationLines.data = [...this.quotationLines.data];
        this.totalPriceTTC = {
            value: this.sparePartService.computeTotalPrice(this.quotationLines.data.map(quotationLine => quotationLine.totalLinePrice.value)),
            currency: this.currency
        };
        this.totalPriceHTAfterDiscount.value = this.getTotalPriceHT();
        this.totalDiscount.value = this.getTotalDiscount();
        this.totalPriceHT.value = this.getTotalNetHT();
        this.totalVAT.value = this.getTotalPriceTVA();
    }

    isClientQuotationFees(quotationLine: QuotationLine): boolean {
        return quotationLine.type.startsWith(QuotationType.QUOTATION_FEES) && quotationLine.priceAmount.value < 0;
    }

    isDisabled(quotationLine: QuotationLine): boolean {
        return quotationLine.type.startsWith(QuotationType.QUOTATION_FEES) && quotationLine.priceAmount.value < 0 || quotationLine.payer !== Payer.CLIENT;
    }

    normalize(value): number {
        return this.sparePartService.normalizeFloat(value);
    }

    deleteLine(indexLine): void {
        const dialog = this.matDialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });
        dialog.componentInstance.title = this.translateService.instant('CONFIRMATION.MODAL.DELETE_DIALOG.TITLE');
        dialog.componentInstance.message = this.translateService.instant('FOLDER.FILE.DELETE_DIALOG.MESSAGE');
        dialog.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.DELETE');
        dialog.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialog.afterClosed().subscribe(isDelete => {
            if (isDelete) {
                this.quotationLines.data = this.quotationLines.data.filter((item, index) => index !== indexLine);
                this.refreshDataSourceTable();
            }
        });
    }

    onCloseModal(): void {
        this.dialogRef.close(null);
    }

    validateForm() {
        this.dialogRef.close({
            quotationLines: this.quotationLines.data,
            discount: this.discountQuotation,
            totalPrice: this.totalPriceTTC
        });
    }

    getTotalPriceHT(): number {
        return !!this.quotationLines.data ? this.quotationLines.data.filter(quotationLine => quotationLine).reduce((total, line) =>
            total + this.sparePartService.normalizeFloat(line.priceAmount.value) * line.quantity, 0) : 0;
    }

    getTotalDiscount(): any {
        if (!!this.quotationLines.data) {
            const totalPriceHT = this.quotationLines.data.filter(quotationLine => quotationLine).reduce((total, line) =>
                total + this.sparePartService.normalizeFloat(line.priceAmount.value) * line.quantity, 0);
            return totalPriceHT - this.getTotalNetHT();
        }
        return 0;

    }

    getTotalNetHT(): any {
        if (!!this.quotationLines.data) {
            let totalNetHT;
            totalNetHT = this.quotationLines.data.filter(quotationLine => quotationLine).reduce((total, line) =>
                line.discount > 0 ? total + (this.sparePartService.normalizeFloat(line.priceAmount.value) * line.quantity -
                    ((this.sparePartService.normalizeFloat(line.priceAmount.value) * line.quantity) * (line.discount / 100))) : total
                    + (this.sparePartService.normalizeFloat(line.priceAmount.value) * line.quantity), 0);
            if (this.discountQuotation > 0) {
                return totalNetHT - totalNetHT * (this.discountQuotation / 100);
            }
            return totalNetHT;
        }
        return 0;
    }

    getTotalPriceTVA(): any {
        if (!!this.quotationLines.data) {
            return this.quotationLines.data.filter(quotationLine => quotationLine).reduce((total, line) => {
                const totalHT = this.sparePartService.normalizeFloat(line.priceAmount.value) * line.quantity;
                const discount = line.discount / 100;
                const vatRate = this.defaultVat;
                if (!!vatRate && vatRate > 0) {
                    const totalNetHT = totalHT - totalHT * discount;
                    return total + (totalNetHT + totalNetHT * (vatRate / 100)) - totalNetHT;
                }
                return total;
            }, 0);
        }
        return 0;
    }

    disabledForm(): boolean {
        return this.quotationLines.data.length <= 0 || !this.allPositivePrices || this.totalPriceTTC?.value < 0;
    }

    allPositivePrices(): boolean {
        return this.quotationLines.data.every(value => {
            return (value.priceAmount?.value ?? false) && (value.priceAmount.value > 0);
        });
    }

}
