import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Folder, IFolderUpdateRequest} from '../../../../../../models/folder.model';
import {MatTableDataSource} from '@angular/material/table';
import {Quotation} from '../../../../../../models/quotation.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {AppState} from '../../../../../../store/app.state';
import {currency} from '../../../../../../store/organization/organization.selectors';
import {FuseConfirmDialogComponent} from '../../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {FileService} from '../../../../../../shared/services/file.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {Payer} from '../../../../../../models/enums/payer.enum';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import * as uuid from 'uuid';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {TypeOfBenefitService} from '../../../../../../shared/services/typeOfBenefit.service';
import {TypeOfServiceEnum} from '../../../../../../models/typeOfService.model';
import {WaitQuotationData} from './waiting-for-quotation-modal/waitQuotationModal.model';
import {WaitingForQuotationModalComponent} from './waiting-for-quotation-modal/waiting-for-quotation-modal.component';
import {QuotationStatus} from '../../../../../../models/enums/quotationStatus.enum';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {SparePartService} from '../../../../../../shared/services/spare-part.service';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-for-repairer-quotation',
    templateUrl: './waiting-for-repairer-quotation.component.html',
    styleUrls: ['./waiting-for-repairer-quotation.component.scss']
})
export class WaitingForRepairerQuotationComponent extends Unsubscriber implements OnInit {

    @ViewChild('dropzone') dropzone: ElementRef;
    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;
    folder: Folder;
    quotation: Quotation;
    currency: string;
    attachments = new Array<any>();
    waitQuotationInprogress = false;

    waitQuotationForm: any = {
        form: null
    };
    private typeOfBenefits = [];

    displayedColumns: string[] = ['type', 'number', 'totalPriceHT', 'totalPrice'];
    quotationSummary = new MatTableDataSource<any>();

    constructor(private store$: Store<AppState>,
                private folderSubjectService: FolderSubjectService,
                private matDialog: MatDialog,
                private folderService: FolderService,
                private sparePartService: SparePartService,
                private typeOfBenefitService: TypeOfBenefitService,
                private translateService: TranslateService) {
        super();
    }

    ngOnInit(): void {
        this.initCurrency();
        this.initWaitQuotationForm();
        this.quotation = this.getQuotation();
        if (!!this.quotation) {
            this.initQuotationData();
        }
    }

    private getQuotation(): Quotation {
        return this.folder.quotations
            .find(quotation => quotation.status === QuotationStatus.DRAFT);
    }

    private initCurrency() {
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => {
            this.currency = currency;
        });
    }

    private initQuotationData() {
        this.waitQuotationForm.form.controls['quotationCode'].setValue(!!this.quotation.code ? this.quotation.code : '');
        this.waitQuotationForm.form.controls['feesRefusedQuotationWithoutRestitution'].setValue(!!this.quotation.feesRefusedQuotationWithoutRestitution?.value ? this.quotation.feesRefusedQuotationWithoutRestitution.value : 0);
        this.waitQuotationForm.form.controls['feesRefusedQuotationWithRestitution'].setValue(!!this.quotation.feesRefusedQuotationWithRestitution?.value ? this.quotation.feesRefusedQuotationWithRestitution.value : 0);
        this.initTypeOfBenefitAndDataTable();
    }

    private initTypeOfBenefitAndDataTable() {
        const sparePart = {
            code: TypeOfServiceEnum.SPARE_PART,
            label: this.translateService.instant('MODAL.QUOTATION.TYPE.SPARE_PART')
        };
        this.typeOfBenefits.push(sparePart);
        this.typeOfBenefitService.all().subscribe(types => {
            types.forEach(value => {
                this.typeOfBenefits.push(value);
            });
            this.initDataTable(this.quotation);
        });
    }

    initWaitQuotationForm(): void {
        this.waitQuotationForm.form = new FormGroup({
            quotationCode: new FormControl(null, Validators.required),
            feesRefusedQuotationWithoutRestitution: new FormControl(0),
            feesRefusedQuotationWithRestitution: new FormControl(0),
        });
    }

    openQuotationModal(): void {
        const waitQuotationData: WaitQuotationData = {
            initialQuotation: this.quotation,
            folderId: this.folder.id,
        };
        const dialogRef = this.matDialog.open(WaitingForQuotationModalComponent, {
            height: '90vh', width: '100vh', minWidth: '80%', data: waitQuotationData
        });
        dialogRef.afterClosed().subscribe(data => {
                if (!!data) {
                    if (!this.quotation) {
                        this.quotation = new Quotation();
                        this.quotation.id = uuid.v4();
                    }
                    this.quotation.quotationLines = this.formatPrices(data);
                    this.quotation.target = Payer.DISTRIBUTOR;
                    this.updateQuotation();
                }
            }
        );
    }

    private formatPrices(data) {
        data.quotationLines.forEach(line => {
            line.priceAmount = {
                value: this.sparePartService.normalizeFloat(line.priceAmount.value),
                currency: this.currency
            };
            line.totalLinePrice = {
                value: this.sparePartService.normalizeFloat(line.totalLinePrice.value),
                currency: this.currency
            };
        });

        return data.quotationLines;
    }

    updateQuotation(): void {
        this.store$.dispatch(new StartLoading());
        const folderUpdateRequest: IFolderUpdateRequest = {
            quotation: this.quotation
        };
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
            this.folderSubjectService.folderLoaded(folder);
            this.folder = folder;

            this.quotation = this.folder.quotations.filter(quotation => quotation.id === this.quotation.id)[0];
            this.refreshDataTable();

            this.store$.dispatch(new StopLoading());
        }).catch(reason => {
            console.log('ERROR Update Folder with id :' + this.folder.id);
            this.store$.dispatch(new StopLoading());
        });
    }

    private refreshDataTable() {
        if (!!this.typeOfBenefits && this.typeOfBenefits.length > 0) {
            this.initDataTable(this.quotation);
        } else {
            this.initTypeOfBenefitAndDataTable();
        }
    }

    private initDataTable(quotation): void {
        const dataSource = [];
        let number = 0;
        let totalTTC = 0;
        let totalHT = 0;
        for (const type of this.typeOfBenefits) {
            number = this.getNumberOfQuotationLineByType(quotation, type.code);
            totalTTC = this.getTotalPriceByType(quotation, type.code);
            totalHT = this.getTotalPriceHTByType(quotation, type.code);
            if (number !== 0) {
                dataSource.push({
                    type: type,
                    number: number,
                    totalHT: totalHT,
                    totalTTC: totalTTC
                });
            }
        }
        this.quotationSummary = new MatTableDataSource<any>(dataSource);
    }

    getNumberOfQuotationLineByType(quotation, type): number {
        return quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === type ? total + 1 : total, 0);
    }

    getTotalPriceByType(quotation, type): number {
        return quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === type ? total + line?.totalLinePrice?.value : total, 0);
    }

    getTotalPriceHTByType(quotation, type): any {
        return quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === type ? total + line.totalPriceHTAfterDiscount?.value : total, 0);
    }

    getAttachments(attachments: any[]) {
        this.attachments = attachments;
    }

    stopPropagation(): boolean {
        return this.waitQuotationInprogress || !this.isEnabledQuotation();
    }

    isEnabledQuotation(): boolean {
        return this.quotation?.totalPrice?.value >= 0 && this.quotation?.quotationLines?.length > 0 && this.waitQuotationForm.form?.valid;
    }

    completeTask() {
        this.waitQuotationInprogress = true;
        if (!this.isEnabledQuotation()) {
            return;
        }
        this.store$.dispatch(new StartLoading());
        this.quotation.code = this.waitQuotationForm.form.value.quotationCode;
        this.addFeesRefusedQuotation();
        this.folderService.updateQuotationToFolder(this.folder.id, this.attachments, this.quotation).subscribe(value => {
            this.inputMap.emit(
                {}
            );
        }, () => {
            this.store$.dispatch(new StopLoading());
        });
    }

    private addFeesRefusedQuotation() {
        const {feesRefusedQuotationWithoutRestitution} = this.waitQuotationForm.form.value;
        if (feesRefusedQuotationWithoutRestitution != null) {
            this.quotation.feesRefusedQuotationWithoutRestitution = {
                value: feesRefusedQuotationWithoutRestitution,
                currency: this.currency
            };
        }
        const {feesRefusedQuotationWithRestitution} = this.waitQuotationForm.form.value;
        if (feesRefusedQuotationWithRestitution != null) {
            this.quotation.feesRefusedQuotationWithRestitution = {
                value: feesRefusedQuotationWithRestitution,
                currency: this.currency
            };
        }
    }
}
