<ng-container *ngIf="preferredLanguages.length > 1">
    <mat-form-field appearance="outline">
        <mat-label>
            {{ 'COMPONENT.CUSTOMER_FORM.PREFERRED_LANGUAGES' | translate }}
        </mat-label>
        <mat-select (selectionChange)="onSelectLanguage($event)"
                    [formControl]="languageCtrl">
            <mat-option *ngFor="let language of preferredLanguages"
                        [value]="language">
                <span>{{ 'COMPONENT.CUSTOMER_FORM.PREFERRED_LANGUAGE.' + (language | uppercase)  | translate }}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-container>