import {initialOrganizationState, OrganizationState} from './organization.state';
import {OrganizationActions, OrganizationActionType} from './organization.actions';


export function organizationReducer(state: OrganizationState = initialOrganizationState, action: OrganizationActions): OrganizationState {
    switch (action.type) {
        case OrganizationActionType.OPERATION_MODE_SET_SUCCESSFULLY:
            return {
                ...state,
                operationMode: action.operationMode
            };

        case OrganizationActionType.RESET_OPERATION_MODE:
            return {
                ...state,
                operationMode: ''
            };

        case OrganizationActionType.COUNTRY_CONFIGURATION_SET_SUCCESSFULLY:
            return {
                ...state,
                countryConfiguration: action.countryConfiguration
            };

        case OrganizationActionType.SET_TEMPLATE_ID:
            return {
                ...state,
                templateId: action.templateId
            };
        case OrganizationActionType.SET_ENABLE_STOCK_CLAIM_STATUS:
            return {
                ...state,
                enableStockClaim: action.enableStockClaim
            };
        case OrganizationActionType.ENABLE_CLAIM_CREATION_WITHOUT_CUSTOMER:
            return {
                ...state,
                claimWithoutCustomer: action.claimWithoutCustomer
            };

        case OrganizationActionType.SET_MARGIN:
            return {
                ...state,
                margin: action.margin
            };

        case OrganizationActionType.SET_DASHBOARD_SERVER_INFO:
            return {
                ...state,
                dashboardInfo: {
                    dashboardDataSourceId: action.dashboardDataSourceId,
                    dashboardSiteId: action.dashboardSiteId
                }
            };
        case OrganizationActionType.UPDATE_LANGUAGE:
            return {
                ...state,
                languageKey: action.language
            };
        case OrganizationActionType.SUCCESS_LOAD_CLAIM_SENSIBLE_INFO:
            return {
                ...state,
                claimSensibleAPIInfo: action.claimSensibleAPIInfo
            };
        default:
            return state;
    }
}
