import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ConfigExtraCodesEnum} from 'app/models/codeLabelObjectWithExtra.model';
import {DashboardRule} from 'app/main/dashboard/dashboard-folder/model/dashboard.model';

@Injectable({
    providedIn: 'root'
})
export class DashboardHelperService {
    configCode: ConfigExtraCodesEnum = ConfigExtraCodesEnum.DASHBOARD;

    constructor(private activatedRoute: ActivatedRoute) {
    }

    createFilterForm(): FormGroup {
        return new FormGroup({
            user: new FormControl(''),
            siteCode: new FormControl(''),
            role: new FormControl(''),
            organizationCode: new FormControl(''),
            businessLink: new FormControl(''),
            context: new FormControl(''),
        });

    }


    isSearchDisabled(filterForm: FormGroup): boolean {
        return filterForm.value.user.length === 0 &&
            filterForm.value.siteCode.length === 0 &&
            filterForm.value.role.length === 0 &&
            filterForm.value.organizationCode.length === 0 &&
            filterForm.value.businessLink.length === 0 &&
            filterForm.value.context.length === 0;
    }

    getUserQuery(): string {
        const userQuery = this.activatedRoute.snapshot.queryParams.user;
        return !!userQuery ? userQuery : '';
    }


    getSiteQuery(): string {
        const siteQuery = this.activatedRoute.snapshot.queryParams.siteCode;
        return !!siteQuery ? siteQuery : '';
    }

    getContextQuery(): string {
        const contextQuery = this.activatedRoute.snapshot.queryParams.context;
        return !!contextQuery ? contextQuery : '';
    }

    getRoleQuery(): string {
        const roleQuery = this.activatedRoute.snapshot.queryParams.role;
        return !!roleQuery ? roleQuery : '';
    }

    getOrganizationCodeQuery(): string {
        const organizationCodeQuery = this.activatedRoute.snapshot.queryParams.organizationCode;
        return !!organizationCodeQuery ? organizationCodeQuery : '';
    }

    getBusinessLinkQuery(): string {
        const businessLinkQuery = this.activatedRoute.snapshot.queryParams.businessLink;
        return !!businessLinkQuery ? businessLinkQuery : '';
    }


    resetSearchFilter(filterForm: FormGroup): void {
        filterForm.get(['user']).setValue('');
        filterForm.get(['siteCode']).setValue('');
        filterForm.get(['role']).setValue('');
        filterForm.get(['organizationCode']).setValue('');
        filterForm.get(['businessLink']).setValue('');
        filterForm.get(['context']).setValue('');

    }

    prepareQueryParam(filterForm: FormGroup): any {
        const queryParam: any = {};
        queryParam.user = filterForm.value.user;
        queryParam.siteCode = filterForm.value.siteCode;
        queryParam.role = filterForm.value.role;
        queryParam.organizationCode = filterForm.value.organizationCode;
        queryParam.businessLink = filterForm.value.businessLink;
        queryParam.context = filterForm.value.context;
        return queryParam;
    }


    prepareSearchCriteria(): Map<string, any> {
        const inputs = new Map();

        if (this.getUserQuery().length > 0) {
            inputs.set('user', this.getUserQuery());
        }

        if (this.getSiteQuery().length > 0) {
            inputs.set('site', this.getSiteQuery());
        }

        if (this.getRoleQuery().length > 0) {
            inputs.set('role', this.getRoleQuery());
        }

        if (this.getOrganizationCodeQuery().length > 0) {
            inputs.set('organizationCode', this.getOrganizationCodeQuery());
        }

        if (this.getBusinessLinkQuery().length > 0) {
            inputs.set('businessLink', this.getBusinessLinkQuery());
        }

        if (this.getContextQuery().length > 0) {
            inputs.set('context', this.getContextQuery());
        }
        return inputs;
    }


    extractDataFromPage(pageContent): DashboardRule[] {
        const data = [];
        pageContent.forEach(item => {
            const dashBoardItem: DashboardRule =
                {
                    id: item.id,
                    user: item.stripes.user,
                    site: item.stripes.site,
                    role: item.stripes.role,
                    context: item.stripes.context,
                    organizationCode: item.stripes.organizationCode,
                    businessLink: item.stripes.businessLink,
                    dashboardItems: item.stripes.value
                };
            data.push(dashBoardItem);
        });
        return data;
    }

}
