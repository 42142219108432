import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {FuseThemeOptionsModule} from '../../../@fuse/components';
import {QuotationRepairerComponent} from './public-folder-workflow/quotaion-repairer/quotation-repairer.component';
import {PublicRoutes} from './public.routing';
import {FolderNotFoundComponent} from './folder-not-found/folder-not-found.component';
import {PublicFolderWorkflowComponent} from './public-folder-workflow/public-folder-workflow.component';
import {DynamicModule} from 'ng-dynamic-component';
import {ParcelToSendComponent} from './public-folder-workflow/parcel-to-send/parcel-to-send.component';
import {ShipmentInProgressComponent} from './public-folder-workflow/shipment-in-progress/shipment-in-progress.component';
import {RepairInProgressComponent} from './public-folder-workflow/repair-in-progress/repair-in-progress.component';
import {RepairerReportModalComponent} from './public-folder-workflow/repair-in-progress/repairer-report-modal/repairer-report-modal.component';
import {RepairerProductHeaderComponent} from './public-folder-workflow/repairer-product-header/repairer-product-header.component';
import {DetailsQuotaionRepairerComponent} from './details-quotaion-repairer/details-quotaion-repairer.component';
import {SparePartsOrderWaitingForValidationComponent} from './public-folder-workflow/spare-parts-order-waiting-for-validation/spare-parts-order-waiting-for-validation.component';
import {SparePartsOrderValidatedComponent} from './public-folder-workflow/spare-parts-order-validated/spare-parts-order-validated.component';
import {SparePartsOrderWaitingPreparationComponent} from './public-folder-workflow/spare-parts-order-waiting-preparation/spare-parts-order-waiting-preparation.component';
import {PublicSupplierHeaderComponent} from './public-folder-workflow/public-supplier-header/public-supplier-header.component';
import {PublicWaitingCreditSupplierComponent} from './public-folder-workflow/public-waiting-credit-supplier/public-waiting-credit-supplier.component';
import {PublicWaitingSupplierConfirmationComponent} from './public-folder-workflow/public-waiting-supplier-confirmation/public-waiting-supplier-confirmation.component';
import {PublicWaitingReparationAgreementComponent} from './public-folder-workflow/public-waiting-reparation-agreement/public-waiting-reparation-agreement.component';
import {DiagnosisInProgressComponent} from './public-folder-workflow/diagnosis-in-progress/diagnosis-in-progress.component';
import {PublicWaitingPickupComponent} from './public-folder-workflow/public-waiting-pickup/public-waiting-pickup.component';
import {PublicFolderWorkflowReadOnlyComponent} from './public-folder-workflow-read-only/public-folder-workflow-read-only.component';
import {SharedGenericModule} from '../../shared/generic/shared-generic.module';
import {FilesListComponent} from './files-list/files-list.component';
import { PublicSurveySummaryComponent } from './public-folder-workflow/public-survey/public-survey-summary.component';
import { DiagnosisInProgressHomeRepairComponent } from './public-folder-workflow/diagnosis-in-progress-home-repair/diagnosis-in-progress-home-repair.component';
import { ProgressRepairComponent } from './public-folder-workflow/progress-repair/progress-repair.component';
import { CancelInterventionModalComponent } from './public-folder-workflow/progress-repair/cancel-intervention-modal/cancel-intervention-modal.component';
import {PublicDetailTabsCardComponent} from './public-detail-tabs-card/public-detail-tabs-card.component';
import {PublicQuotationReportCardComponent} from './public-quotation-report-card/public-quotation-report-card.component';
import { PublicWaitingForRepairerQuotationComponent } from './public-folder-workflow/public-waiting-for-repairer-quotation/public-waiting-for-repairer-quotation.component';
import { PublicQuotationAcceptationDistributorComponent } from './public-folder-workflow/public-quotation-acceptation-distributor/public-quotation-acceptation-distributor.component';
import { PublicOldWaitingReparationAgreementComponent } from './public-folder-workflow/public-old-waiting-reparation-agreement/public-old-waiting-reparation-agreement.component';


@NgModule({
    declarations: [
        FolderNotFoundComponent,
        PublicFolderWorkflowComponent,
        QuotationRepairerComponent,
        ParcelToSendComponent,
        ShipmentInProgressComponent,
        RepairInProgressComponent,
        RepairerReportModalComponent,
        RepairerProductHeaderComponent,
        PublicDetailTabsCardComponent,
        PublicQuotationReportCardComponent,
        DetailsQuotaionRepairerComponent,
        SparePartsOrderWaitingForValidationComponent,
        SparePartsOrderValidatedComponent,
        SparePartsOrderWaitingPreparationComponent,
        PublicSupplierHeaderComponent,
        PublicWaitingCreditSupplierComponent,
        PublicWaitingSupplierConfirmationComponent,
        PublicWaitingReparationAgreementComponent,
        PublicFolderWorkflowReadOnlyComponent,
        PublicWaitingReparationAgreementComponent,
        DiagnosisInProgressComponent,
        PublicWaitingPickupComponent,
        FilesListComponent,
        PublicSurveySummaryComponent,
        DiagnosisInProgressHomeRepairComponent,
        ProgressRepairComponent,
        CancelInterventionModalComponent,
        PublicWaitingForRepairerQuotationComponent,
        PublicQuotationAcceptationDistributorComponent,
        PublicOldWaitingReparationAgreementComponent
    ],
    imports: [
        RouterModule.forChild(PublicRoutes),
        TranslateModule,
        FuseSharedModule,
        FuseThemeOptionsModule,
        // Dynamic Module WorkFlow
        DynamicModule.withComponents([]),
        SharedGenericModule
    ], exports: [
        TranslateModule
    ], entryComponents: [
        RepairerReportModalComponent,
        RepairerProductHeaderComponent,
        PublicSupplierHeaderComponent
    ]
})
export class PublicModule {
}
