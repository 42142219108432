import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {finalize, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {
    CustomerActionType,
    CustomerApiInfoLoadedSuccessfully,
    CustomerPageEvent,
    CustomerTypeChanged,
    ResetCustomer,
    ResetCustomerSearchResult,
    SearchCustomer,
    SetStockClaimCreation,
    UpdateCustomer,
    UpdateCustomerSearchResult,
    UpdateForm
} from './customer.actions';
import {CustomerService} from '../../shared/services/store/customer.service';
import {customer} from './customer.selectors';
import {Customer} from '../../models/customer/customer.model';
import {StartLoading, StopLoading} from '../loader/loader.actions';
import {SnackBarService} from '../../shared/services/snack-bar.service';
import {SiteService} from '../../shared/services/site.service';
import {affectedSite} from '../user/user.selectors';
import {Site, SiteWrapperRequest} from '../../models/site.model';

@Injectable()
export class CustomerEffects {

    constructor(private actions$: Actions,
                private store$: Store<AppState>,
                private customerService: CustomerService,
                private siteService: SiteService,
                private snackBar: SnackBarService) {
    }

    @Effect()
    loadCustomerApiInfo$ = this.actions$.pipe(
        ofType(CustomerActionType.LOAD_CUSTOMER_API_INFO),
        switchMap(() => this.customerService.loadCustomerApiInfo()
            .pipe(
                map(customerApiInfo => new CustomerApiInfoLoadedSuccessfully(customerApiInfo))
            )
        )
    );

    @Effect()
    customerPageEvent$ = this.actions$.pipe(
        ofType(CustomerActionType.CUSTOMER_PAGE_EVENT),
        map((action: CustomerPageEvent) => new SearchCustomer(undefined, action.pageEvent)));


    @Effect()
    searchCustomer$ = this.actions$.pipe(
        ofType(CustomerActionType.SEARCH_CUSTOMER),
        withLatestFrom(this.store$.select(customer)),
        tap(() => this.store$.dispatch(new StartLoading())),
        switchMap(([action, customer]: [SearchCustomer, Customer]) =>
            this.customerService.searchCustomer(action.customer ?? customer, action.pageEvent)
                .pipe(
                    switchMap(response => {
                        if (response.items?.length > 1) {
                            return [new UpdateCustomerSearchResult(response)];
                        }
                        if (response.items?.length === 1) {
                            return [
                                new CustomerTypeChanged(response.items[0].type),
                                new ResetCustomerSearchResult(),
                                new UpdateForm(response.items[0]),
                            ];
                        }
                        this.snackBar.openAtEnd('Info', 'CUSTOMER.NOT_FOUND');
                        return [new ResetCustomerSearchResult()];
                    }),
                    finalize(() => this.store$.dispatch(new StopLoading()))
                ))
    );

    @Effect()
    stockClaim$ = this.actions$.pipe(
        ofType(CustomerActionType.SET_STOCK_CLAIM_CREATION),
        withLatestFrom(this.store$.select(affectedSite)),
        tap(() => this.store$.dispatch(new StartLoading())),
        switchMap(([action, siteCode]: [SetStockClaimCreation, string]) => {
                if (action.isStockClaimCreation) {
                    this.store$.dispatch(new ResetCustomer());
                    this.store$.dispatch(new ResetCustomerSearchResult());
                    const siteWrapperRequest: SiteWrapperRequest = {};
                    if (siteCode) {
                        siteWrapperRequest.code = siteCode;
                    }
                    return this.siteService.findNearestSites(siteWrapperRequest).pipe(
                        switchMap(sites =>  this.updateCustomerInfo(sites?.[0] ?? null)));
                } else {
                    return [new ResetCustomer(), new  StopLoading()];
                }
            }
        ),
    );

    private static buildCustomerInfo(site: Site): Customer {
        const customer: Customer = new Customer();
        customer.reference = site.code;
        customer.lastName = site.name;
        customer.firstName = site.name;
        customer.addresses = [site.address];
        return customer;
    }

    private updateCustomerInfo(site: Site) {
        if (site) {
            const customer = CustomerEffects.buildCustomerInfo(site);
            return [new UpdateCustomer(customer), new UpdateForm(customer), new StopLoading()];
        } else {
            return [new StopLoading()];
        }
    }
}
