import {Injectable} from '@angular/core';
import {CalendarDayElement} from './calendarRdv.model';
import {ReplaySubject, Subject} from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DayFilterEnum} from './dayFilterEnum';
import {InterventionSuggestionsResponse} from './appointment.models';

export const afternoon = {beginHour: '13h00', endHour: '20h00'};
export const morning = {beginHour: '08h00', endHour: '13h00'};
export const fullDay =  [morning, afternoon];
export const emptyCalendarDayElement: CalendarDayElement[] =
    [{day: 'MONDAY', dayElements: []},
        {day: 'TUESDAY', dayElements: []},
        {day: 'WEDNESDAY', dayElements: []},
        {day: 'THURSDAY', dayElements: []},
        {day: 'FRIDAY', dayElements: []},
        {day: 'SATURDAY', dayElements: []},
        {day: 'SUNDAY', dayElements: []}];
export const fullCalendarDayElement: CalendarDayElement[] =
    [{day: 'MONDAY', dayElements: fullDay},
        {day: 'TUESDAY', dayElements: fullDay},
        {day: 'WEDNESDAY', dayElements: fullDay},
        {day: 'THURSDAY', dayElements: fullDay},
        {day: 'FRIDAY', dayElements: fullDay},
        {day: 'SATURDAY', dayElements: fullDay},
        {day: 'SUNDAY', dayElements: fullDay}];

@Injectable()
export class CalendarRdvService {

    /*
* Structure of input listElementsDays
* ENUM DaysName to set name of day
    [{day: "MONDAY", dayElements: [{beginHour: "08h00", endHour: "13h00"}, {beginHour: "14h00", endHour: "18h00"}]},
    {day: "TUESDAY", dayElements: [{beginHour: "08h00"}, {beginHour: "14h00"}]},
    {day: "WEDNESDAY", dayElements: []]},
    {day: "THURSDAY", dayElements: []},
    {day: "FRIDAY", dayElements: []},
    {day: "SATURDAY", dayElements: []},
    {day: "SUNDAY", dayElements: []}]
 */
    private calendarElement = new ReplaySubject<{ dateFrom: Moment, fullCalendarDayElement: CalendarDayElement[] }>(1);
    public calendarElement$ = this.calendarElement.asObservable();
    private changeCalendarElement = new Subject<{ dateFrom: Date }>();
    public changeDateEvent$ = this.changeCalendarElement.asObservable();

    constructor() {
    }

    public computeCalendarWeekElement(dayFiler: DayFilterEnum, data: InterventionSuggestionsResponse): CalendarDayElement[] {
        const calendarDayElement = _.cloneDeep(emptyCalendarDayElement);
        data.interventionSuggestions.forEach(interventionSuggestion => {
            const date = moment(interventionSuggestion.interventionDate, 'YYYY-MM-DD');
            const dayNumber = date.isoWeekday();
            if (dayFiler === DayFilterEnum.MORNING) {
                calendarDayElement[dayNumber - 1].dayElements.push(morning);
            } else {
                calendarDayElement[dayNumber - 1].dayElements.push(afternoon);
            }
        });
        return calendarDayElement;
    }

    loadNexWeekData(date: Date) {
        this.changeCalendarElement.next({dateFrom: date});
    }

    loadLastWeekData(date: Date) {
        this.changeCalendarElement.next({dateFrom: date});
    }

    calenderComputed(dateFrom: moment.Moment, fullCalendarDayElement: CalendarDayElement[]) {
        this.calendarElement.next({
            dateFrom: dateFrom,
            fullCalendarDayElement: fullCalendarDayElement
        });
    }

    changeBeginDate(beginDate: Date): void {
        this.changeCalendarElement.next({dateFrom: beginDate});
    }
}
