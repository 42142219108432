<div class="h3 mt-12 mb-12" fxLayout="row" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutGap="20px"  *ngIf="isExpedition && isPickup; else PRE_SELECTED_TRANSPORT_MODE">
        <div >
            <strong>
                {{'COMPONENT.CONFIRM_CARRIER_AND_REPAIRER.MODE_OF_TRANSPORT.TITLE' | translate}}
            </strong>
        </div>
        <div fxLayout="column">
            <mat-radio-group (change)="onSelectTransportMode($event)" fxLayout="column">
                <mat-radio-button *ngFor="let mode of transportModes"
                                  [id]="mode"
                                  [value]="mode">
                    {{'COMPONENT.CONFIRM_CARRIER_AND_REPAIRER.MODE_OF_TRANSPORT.' + mode | translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>


<ng-template #PRE_SELECTED_TRANSPORT_MODE>
    <strong>{{'COMPONENT.CONFIRM_CARRIER_AND_REPAIRER.MODE_OF_TRANSPORT.TITLE'|translate}}:
        <span *ngIf="!!selectedTransportMode">{{'COMPONENT.CONFIRM_CARRIER_AND_REPAIRER.MODE_OF_TRANSPORT.'+selectedTransportMode | translate}}</span>
        <span *ngIf="!selectedTransportMode">{{'COMPONENT.CONFIRM_CARRIER_AND_REPAIRER.MODE_OF_TRANSPORT.NOT_DEFINED' | translate}}</span>
    </strong>
</ng-template>
<ng-container *ngIf="isExpeditionSelected">
    <div class="h3 mt-12 mb-12"><strong>{{'LIST.SHIPPERS' | translate}}</strong></div>
    <div class="mat-elevation-z4 w-100-p mt-12 mb-16">
        <table mat-table [dataSource]="carriersDataSource" multiTemplateDataRows class="mat-elevation-z4 w-100-p mt-16 mb-16">
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef class="table-header"> {{'TABLE.LOGO' | translate}}
                </th>

                <td mat-cell *matCellDef="let element" class="cursor-pointer">
                    <div *ngIf="element.type">
                        <img style="width: 35px; height: 35px;" class="my-8" [src]="'assets/icons/carrier-logo/'+element.type.toUpperCase() +'.png'" />
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef class="table-header"> {{'TABLE.CODE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="cursor-pointer">
                    {{element.code}}
                </td>
            </ng-container>

            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef class="table-header"> {{'TABLE.LABEL' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="cursor-pointer">
                    {{element.label}}
                </td>
            </ng-container>
            <ng-container matColumnDef="selection">
                <th mat-header-cell *matHeaderCellDef  class="table-header"> {{'BUTTON.SELECTION' | translate}}</th>
                <mat-radio-group [(ngModel)]="selectedCarrier"
                                 fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="space-between center">
                    <td mat-cell *matCellDef="let row; let i = index">
                        <mat-radio-button [id]="CARRIER + row.code" [value]="row"
                                          (click)="expandedElement = carrierExtraData(row)"></mat-radio-button>
                    </td>
                </mat-radio-group>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="carriersColumns.length">
                    <div class="eligibility-carrier-element-detail"
                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="w-100-p p-20">
                            <div fxLayout="row" fxLayoutAlign="space-between"  class="mt-12">
                                <div *ngIf="element?.extra?.isInsuranceEnabled" fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="space-between center" >
                                    <mat-label>{{ 'ENABLE.INSURANCE' | translate }}</mat-label>
                                    <mat-slide-toggle [(ngModel)]="element.extra.insurance" [ngModel]="false" (change)="toggle($event, element)"  [ngClass]="{ 'disabled-style': !isInsuranceEnabled, 'aster_accent': isInsuranceEnabled }">
                                    </mat-slide-toggle>
                                </div>
                                <div  *ngIf="element.extra?.insurance === true"  fxFlex="40" fxLayout="row" >
                                    <mat-form-field    appearance="outline" class="w-100-p; top-align">
                                        <mat-label>{{ 'COMPONENT.CONFIRM_CARRIER_AND_REPAIRER.PRICE' | translate }}<span class="asterisk">*</span></mat-label>
                                        <input matInput [placeholder]="'COMPONENT.CONFIRM_CARRIER_AND_REPAIRER.PRICE' | translate" [(ngModel)]="element.extra.insuranceValue"
                                               (blur)="emitInsurrancePrice(element.extra.insuranceValue)"
                                               type="number" step="any" min="0" inputmode="decimal">
                                    </mat-form-field>
                                </div>
                                <div *ngIf="element?.extra?.isProductDangerEnabled" fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="space-between center" >
                                    <mat-label>{{ 'ENABLE.PRODUCT_DANGER' | translate }}</mat-label>
                                    <mat-slide-toggle [(ngModel)]="element.extra.isProductDanger" [ngModel]="false" (change)="toggleProductState($event, element)" [ngClass]="{ 'disabled-style': !isProductDanger, 'aster_accent': isProductDanger }">
                                    </mat-slide-toggle>
                                </div>
                            </div>

                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="carriersColumns"></tr>
            <tr mat-row *matRowDef="let carrier; columns: carriersColumns;"
                class="eligibility-carrier-element-row cursor-pointer"
                [class.example-expanded-row]="expandedElement === carrier"
                (click)="this.singleCarrier == true ? this.carrierExtraData(carrier) : null">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="extra-detail-row"></tr>

        </table>
    </div>
</ng-container>