import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PublicSurveyElement} from '../../../../models/folder.public.model';
import {KeyValue} from '@angular/common';

@Component({
  selector: 'app-public-survey-summary',
  templateUrl: './public-survey-summary.component.html',
  styleUrls: ['./public-survey-summary.component.scss']
})
export class PublicSurveySummaryComponent implements OnInit, OnChanges {

    @Input() surveyResponseSummary: PublicSurveyElement[];
    mapSummarySurvey: Map<string, { question: PublicSurveyElement, answers: Map<string, PublicSurveyElement> }> = new Map();
    sliderWidth: number;


    constructor() {
    }

    ngOnInit(): void {
        switch (true) {
            case window.innerWidth <= 375: {
                this.sliderWidth = 200;
                break;
            }
            case window.innerWidth > 375: {
                this.sliderWidth = 400;
                break;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initializeSurvey();
    }

    initializeSurvey(): void {
        this.mapSummarySurvey = new Map();
        this.updateSummarySurveyQuestion();
    }

    indexOrderAsc = (firstElement: KeyValue<string, PublicSurveyElement>, secendElement: KeyValue<string, PublicSurveyElement>): number => {
        let a = 0;
        let b = 0;
        if (!!firstElement.value && !!secendElement.value) {
            a = firstElement.value.order;
            b = secendElement.value.order;
        }
        return a > b ? -1 : (b > a ? 1 : 0);
    };


    updateSummarySurveyQuestion(): void {
        this.mapSummarySurvey = new Map();
        const surveyQuestions = this.surveyResponseSummary.filter(value => value.elementType &&
            value.elementType === 'QUESTION');
        const surveyAnswers = this.surveyResponseSummary.filter(value => value.elementType &&
            value.elementType === 'ANSWER' && value.displayType !== 'LABEL');
        surveyQuestions.forEach((elementSurvey, index) => {
            elementSurvey.order = index;
            this.mapSummarySurvey.set(elementSurvey.code, {question: elementSurvey, answers: new Map()});
            elementSurvey.nextElements.forEach(codeElement => {
                const answerValue = surveyAnswers.filter(data => data.code === codeElement)[0];
                if (!!answerValue) {
                    this.mapSummarySurvey.get(elementSurvey.code).answers
                        .set(codeElement, answerValue);
                }
            });
        });
    }
}
