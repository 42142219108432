<mat-table [dataSource]="customersDataSource">

    <mat-header-row *matHeaderRowDef="columnsName"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsName;"></mat-row>

    <ng-container *ngFor="let column of columns">
        <ng-container matColumnDef="{{column.name}}">
            <mat-header-cell *matHeaderCellDef>
                {{column.label | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"
                      (click)="selectCustomer(element)" class="cursor-pointer">
                {{element[column.name]}}
            </mat-cell>
        </ng-container>
    </ng-container>

</mat-table>

<div fxLayout="row" fxLayoutAlign="space-between center">
    <button type="button" mat-stroked-button color="accent" (click)="goBackToForm()">
        {{'FOLDER.CREATE.BACK_CLIENT_FORM' | translate}}
    </button>

    <mat-paginator showFirstLastButtons
                   [length]="customers.total"
                   (page)="changePage($event)"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   class="w-100-p">
    </mat-paginator>
</div>