    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon> local_shipping</mat-icon>
            <div class="h2 ml-8  pt-4">
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start center" class="mt-12">
            <div [innerHTML]="descriptionText">
            </div>
            <div *ngIf="!!image">
                <img class="w-120 h-120" [src]="image">
            </div>
        </div>
    </mat-card-content>
    <ng-container>
        <div class="mt-24">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-checkbox class="mt-24" [(ngModel)]="validateShipmentSent">
                    {{'BUTTON.DESTRUCTION_BY_CUSTOMER_FINISHED' | translate}}
                </mat-checkbox>

                <button mat-stroked-button (click)="onSubmit()"
                        type="submit" [ngClass]="validateShipmentSent? 'aster_btn': 'aster_btn_disabled' "
                        class="h-60 mt-4 aster_btn">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </div>
    </ng-container>