import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appPositiveNumberValidator]'
})
export class NumberValidatorDirective {

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {
        const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter'];
        const isDecimal = e.key === '.' && !this.el.nativeElement.value.includes('.');
        const isNumber = !isNaN(Number(e.key)) && e.key !== ' ';

        if (allowedKeys.includes(e.key) || isDecimal || isNumber) {
            return;
        }
        e.preventDefault();
    }

    @HostListener('input', ['$event'])
    onInputChange(event) {
        const initialValue = this.el.nativeElement.value;
        const sanitizedValue = initialValue
            .replace(/[^0-9.\s]/g, '')  // Remove invalid characters
            .replace(/\s{2,}/g, ' ')    // Replace multiple spaces with a single space
            .replace(/(\.\d*)\./g, '$1'); // Prevent more than one decimal point

        if (sanitizedValue !== initialValue) {
            this.el.nativeElement.value = sanitizedValue;
            event.stopPropagation();
            this.el.nativeElement.dispatchEvent(new Event('input'));
        }
    }
}
