    <mat-card-content>
          <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"
                              [info]="fetchProductLocationInfo()"></app-user-task-header>
        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px" class="mt-25 mb-16" *ngIf="shipmentErrorMessage">
            <div class="custom-badge-container">
                <span class="custom-badge blinking"
                      matBadge="&#8288;"
                      matBadgePosition="before">
                </span>
                <strong>{{shipmentErrorMessage |translate}}</strong>
            </div>
        </div>
        <form [formGroup]="reservationTicketChoiceForm" fxLayout="column" fxLayoutAlign="start" class="mt-25">
            <div [ngClass]="'pt-25 pb-40'">
                <ng-container *ngIf="!!existingShipment">
                    <mat-radio-group class="ml-20" formControlName="status" fxLayout="row"
                                     fxLayout="column"
                                     fxLayoutAlign="space-between center" fxLayoutAlign="start stretch"
                                     fxLayoutGap="10px"
                                     labelPosition="after">
                        <mat-radio-button *ngFor="let status of reservationTicketChoiceList"
                                          [value]="status.status"
                                          class="full-width-radio">
                            <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div fxFlex>
                                    <div> {{status.value | translate}}</div>
                                </div>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-container>
            </div>
        </form>

        <form [formGroup]="handleReservationAnomalyForm" fxLayout="column"
              fxLayoutAlign="start">

            <ng-container *ngIf="displayHandleReservationAnomalyForm()">
                <mat-form-field appearance="outline" class="w-50-p">
                    <mat-label> {{'FOLDER.HANDLE_RESERVATION_ANOMALY.TRACKING_NUMBER' | translate}}</mat-label>
                    <input inputControl formControlName="trackingNumber" matInput [id]="'tracking_number'"
                           placeholder="{{'FOLDER.HANDLE_RESERVATION_ANOMALY.TRACKING_NUMBER' | translate}}">
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-50-p">
                    <mat-label>{{'FOLDER.CARRIER_CODE'|translate}}</mat-label>
                    <mat-select formControlName="carrier">
                        <mat-option [id]="carrierItem.code" *ngFor="let carrierItem of carriers$ | async"
                                    [value]="carrierItem.code">
                            <span>{{ carrierItem.label }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <div *ngIf="handleReservationAnomalyForm" fxLayoutAlign="end center" fxLayoutGap="10px" class="mt-24">
                <button (click)="tryAgain()"
                        [disabled]="isDisabledTryAgainButton()"
                        [ngClass]="isDisabledTryAgainButton() ? 'aster_btn_cancel_disabled' : 'aster_btn_cancel'"
                        mat-stroked-button type="button">
                    {{'BUTTON.TRY_AGAIN_GENERATE_TICKET' | translate}}
                </button>

                <button (click)="onSubmit()"
                        [disabled]="isDisabledSubmit()"
                        [ngClass]=" isDisabledSubmit()? 'aster_btn_disabled' : 'aster_btn'"
                        mat-stroked-button>
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>