import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Folder} from '../../../../models/folder.model';
import {Shipment} from '../../../../models/shipment.model';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FolderSubjectService} from '../../folder-subject.service';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FileService} from '../../../../shared/services/file.service';
import {TranslateService} from '@ngx-translate/core';
import {FilesUtils} from '../../../../shared/utils/files-utils';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {ShipmentType} from '../../../../models/enums/shipmentType.enum';
import {AttachmentTypeEnum} from '../../../../models/enums/attachmentType.enum';
import {FileInfo} from '../../../../models/file-info.model';

@Component({
    selector: 'app-folder-shipment-card',
    templateUrl: './folder-shipment-card.component.html',
    styleUrls: ['./folder-shipment-card.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class FolderShipmentCardComponent implements OnInit, OnDestroy {

    private destroy$ = new Subject<void>();

    folder: Folder;
    displayedColumns: string[] = ['type', 'carrier', 'trackingNumber'];
    dataSourceShipments = new MatTableDataSource<Shipment>([]);
    expandedElement: Shipment | null;

    currentLanguage$: Observable<string>;
    @Input() variablesTask: any;

    constructor(private folderSubjectService: FolderSubjectService,
                private store$: Store<AppState>,
                private fileService: FileService,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.getFolder();
    }

    private getFolder(): void {
        this.folderSubjectService.folder$
            .pipe(takeUntil(this.destroy$))
            .subscribe(folder => {
                    this.folder = folder;
                    this.dataSourceShipments.data = this.folder.shipments;
                }
            );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getShipmentAttachment(): void {
        this.store$.dispatch(new StartLoading());
        const attachment = this.getShipmentDocument();
        if (!!attachment) {
            const shipmentDocumentName = this.translateService.instant('COMPONENT.PARCEL_TO_SEND.SHIPPING_LABEL');
            this.fileService.getAttachmentFile(attachment.id).subscribe(downloadedFile => {
                    this.downloadFile(downloadedFile, shipmentDocumentName);
                    this.store$.dispatch(new StopLoading());
                },
                () => {
                    console.error('ERROR DURING DOWNLOADING FILE');
                    this.store$.dispatch(new StopLoading());
                });
        }
    }

    getShipmentDocument() {
        return this.folder?.attachments?.find(value =>
            value.attachmentType === AttachmentTypeEnum.SHIPMENT_DOCUMENT ||
            this.deprecatedShipmentAttachmentTypes(value)
        );
    }

    // TODO After 3 months, delete 2 attachment types shipment: ATTACHMENTS_STORE_TO_REPAIRER and ATTACHMENTS_CUSTOMER_TO_REPAIRER
    private deprecatedShipmentAttachmentTypes(value: FileInfo) {
        return value.attachmentType === AttachmentTypeEnum.ATTACHMENTS_STORE_TO_REPAIRER ||
            value.attachmentType === AttachmentTypeEnum.ATTACHMENTS_CUSTOMER_TO_REPAIRER;
    }

    hasShipmentDocument() {
        return !!this.getShipmentDocument();
    }

    downloadFile(resFile: any, fileName: string): void {
        const reader = new FileReader();
        reader.readAsDataURL(resFile);
        reader.onloadend = () => {
            console.log(reader);
            FilesUtils.saveFile(reader.result, fileName, resFile.type);
        };
    }

    shouldDisplayDownloadButton(): boolean {
        return !this.isPicking() && this.hasShipmentDocument();
    }

    private isPicking() {
        return this.expandedElement?.type === ShipmentType.PICKING;
    }

    displayShipmentType(shipmentLine): string {
        if (shipmentLine.type === ShipmentType.PICKING) {
            return 'COMPONENT.SHIPMENT.TYPE.' + this.getDeliveryOrShipmentMode() + shipmentLine.type;
        }
        return 'COMPONENT.SHIPMENT.TYPE.' + shipmentLine.type;
    }

    getDeliveryOrShipmentMode(): string {
        return this.variablesTask ? (this.variablesTask['deliveryMode'] || this.variablesTask['shipmentMode']) + '.' : '';
    }
}
