
    <mat-card-content>
        <form [formGroup]="repairStatusForm.form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <mat-radio-group formControlName="status" class="ml-20" fxLayout="column" fxLayoutAlign="start stretch"
                             labelPosition="after" fxLayoutGap="10px">
                <mat-radio-button class="full-width-radio" *ngFor="let status of repairStatusForm.placeholders"
                                  [value]="status.status">
                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                        <div fxFlex>
                            <div>{{ status.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button [disabled]="repairStatusForm.form.invalid"
                        [ngClass]="repairStatusForm.form.invalid ? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>