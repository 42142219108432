<ejs-grid [dataSource]="userTaskReportsDataSource.dataSource.filteredData">
    <e-columns>
        <e-column [width]="'10%'">
            <ng-template #template let-data>
                <mat-icon [svgIcon]="'file-type-pdf'" class="icon" (click)="downloadAttachment(data)" >
                </mat-icon>
            </ng-template>
        </e-column>
        <e-column field='name' [headerTemplate]="headerTemplate" [width]="'65%'">
            <ng-template #template let-data>
                <a (click)="downloadAttachment(data)" class="cursor-pointer">
                        {{data.name}}
                </a>
            </ng-template>
        </e-column>

        <e-column field='attachmentType' [headerText]="'FOLDER.FILE.ATTACHMENT_TYPE' | translate" [width]="'25%'">
            <ng-template #template let-data>
                {{ data.attachmentType | codeToLabel  :'SAV.CONFIG.ATTACHMENT_TYPE' | async }}
            </ng-template>
        </e-column>
    </e-columns>
    <ng-template #headerTemplate>
        <div class="header-text-align-custom">{{'FOLDER.FILE.TITLE' | translate}}</div>
    </ng-template>
</ejs-grid>
