<h1 mat-dialog-title>  {{'FOLDER.WORKFLOW.EDIT_INTERVENTION' | translate}}</h1>
<div mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="null"
            class="h-44 mt-4 pl-20 button_color-gris-white">{{'BUTTON.CLOSE' | translate}}</button>
    <button mat-button [mat-dialog-close]="'VALIDATE'"
            class="h-44 mt-4 ml-8  "
            class="h-44 mt-4 ml-8  aster_btn"
            cdkFocusInitial>{{'BUTTON.VALIDATE' | translate}}</button>
</div>
