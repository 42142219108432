<div fxLayout="column" fxLayoutAlign="start start" class="ml-24">
    <ng-container *ngIf="!isNotCall">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="detail">
            <mat-icon>access_time</mat-icon>
            <h4 class="text-bold">{{'FOLDER.NOTIFICATION.CALL_TYPE.DURATION'|translate}}:</h4>
            <div class="ml-8">
                {{!!notification?.content['duration'] ? notification.content['duration'] : '-'}}
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="detail">
            <mat-icon>comment</mat-icon>
            <h4 class="text-bold">{{'FOLDER.NOTIFICATION.CALL_TYPE.COMMENT'|translate}}:</h4>

            <div class="ml-8">
                {{!!notification?.content['comment'] ? notification.content['comment'] : '-'}}
            </div>
        </div>
    </ng-container>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="detail">
        <mat-icon>details</mat-icon>
        <h4 class="text-bold">{{"FOLDER.NOTIFICATION.COMMUNICATION_CODE"| translate}}:</h4>
        <div class="ml-8">
            {{!!notification?.description? notification?.description : (notification?.communicationCode ? ("FOLDER.NOTIFICATION."+notification.communicationCode| translate) : '-')}}
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="detail">
        <mat-icon>mail_outline</mat-icon>
        <h4 class="text-bold">{{"FOLDER.NOTIFICATION.COMMUNICATION_RECEIVER"| translate}}:</h4>
        <div class="ml-8">
            {{getCustomerContact()}}
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="detail">
        <mat-icon>access_time</mat-icon>
        <h4 class="text-bold">{{'FOLDER.NOTIFICATION.DATE' | translate}}:</h4>
        <div class="ml-8">
            {{ !!notificationIndex.changeStatusDate ? (notificationIndex.changeStatusDate|date: 'dd-MM-yyyy HH:mm') : ''}}
        </div>
    </div>
    <ng-container *ngIf="isMailType()">
        <ng-container *ngFor="let attachment of attachments">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="detail">
                <mat-icon>file_copy</mat-icon>
                <h4 class="text-bold">{{"ATTACHMENT"|translate}}:</h4>
                <div class="ml-8 cursor-pointer link-underline"
                     (click)="downloadAttachment(attachment.id, attachment.name)">
                    {{attachment.name}}
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
