import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FolderPublic} from '../../../../models/folder.public.model';
import {PublicService} from '../../../../shared/services/public.service';
import {CommentType} from '../../../../models/enums/CommentType.enum';

@Component({
    selector: 'app-public-waiting-supplier-confirmation',
    templateUrl: './public-waiting-supplier-confirmation.component.html',
    styleUrls: ['./public-waiting-supplier-confirmation.component.css']
})
export class PublicWaitingSupplierConfirmationComponent implements OnInit {

    @Input() folder: FolderPublic;

    @Output() inputMap = new EventEmitter<any>();

    refundStatusForm: any = {
        form: null,
        placeholders: []
    };

    constructor(private router: Router,
                private route: ActivatedRoute,
                private folderService: PublicService) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.refundStatusForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            refundAgreementNumber: new FormControl(null),
            sendProduct: new FormControl(null),
            supplierComment: new FormControl(''),
        });
        this.refundStatusForm.placeholders = [
            {
                status: 'true',
                value: 'FOLDER.WAITING_SUPPLIER_CONFIRMATION.ACCEPTED',
            },
            {
                status: 'false',
                value: 'FOLDER.WAITING_SUPPLIER_CONFIRMATION.REFUSED',
            }
        ];
    }

    onSubmit(): void {
        if (this.readyToSent()) {
            this.folderService.updateFolderWithSupplierComment(this.folder.externalUid, this.refundStatusForm.form.value.supplierComment, CommentType.COMMENT_GENERAL_SUPPLIER)
                .subscribe(folder => {
                    this.completeTask();
                });
        }else {
            this.completeTask();
        }
    }

    private completeTask(): void {
        if (this.refundStatusForm.form.value.status === 'false') {
            this.inputMap.emit({
                acceptRefundSupplier : this.refundStatusForm.form.value.status,
                refundRefusedBySupplier: true,
            });
        } else {
            this.inputMap.emit({
                acceptRefundSupplier: 'true',
                refundAgreementNumber: this.refundStatusForm.form.value.refundAgreementNumber,
                sendProduct : this.refundStatusForm.form.value.sendProduct,
                destroyedByCustomer: this.refundStatusForm.form.value.sendProduct === 'true' ? 'false' : 'true',
                refundRefusedBySupplier : false,
            });
        }
    }

    readyToSent(): boolean {
        return !!this.refundStatusForm.form.value.supplierComment;
    }

    isFormValid(): boolean {
        return this.refundStatusForm.form.value.status === 'false' || (this.refundStatusForm.form.value.status === 'true'
            && !!this.refundStatusForm.form.value.refundAgreementNumber && !!this.refundStatusForm.form.value.sendProduct) ;
    }

    choiceChanged(): void {
        this.refundStatusForm.form.get(['refundAgreementNumber']).setValue(null);
        this.refundStatusForm.form.get(['sendProduct']).setValue(null);
    }
}
