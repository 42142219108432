<fieldset>
    <legend class="h3 title-fieldset">{{'COMPONENT.PRODUCT_ACCESSORIES.ITEMS_TO_SEND' | translate}}</legend>
    <mat-card-subtitle style="margin-top: 10px">{{'COMPONENT.PRODUCT_ACCESSORIES.PRODUCT' | translate}}</mat-card-subtitle>
    <mat-checkbox [checked]="true" [disabled]="true">
        <span>{{!!claim.productSubElement ? claim.productSubElement.label : claim.product.label}}</span>
    </mat-checkbox>
    <div *ngIf="claim.productAccessories.length > 0">
        <mat-card-subtitle style="margin-top: 12px">{{'COMPONENT.PRODUCT_ACCESSORIES.ACCESSORIES' | translate}}</mat-card-subtitle>
        <div *ngFor="let accessory of claim.productAccessories">
            <mat-checkbox (change)="onSelectAccessory(accessory, $event)">
                <span>{{accessory.label}}</span>
            </mat-checkbox>
        </div>
    </div>
</fieldset>



















