<ng-container *ngIf="!!folder; else notFoundContent">
    <div class="page-layout blank p-24">
        <div>
            <div fxLayout="row wrap">
                <div fxFlex>
                    <app-folder-snapshot-card
                            [variablesTask]="inputDynamicData.variablesTask"></app-folder-snapshot-card>
                </div>
            </div>
        </div>
        <div class="mt-8">
            <div fxLayout="row" fxLayout.lt-lg="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                <div fxFlex="0 1   calc(50% - 8px)" fxFlex.lt-md="0 1 calc(100% - 8px)" fxFlex.lt-sm="100%">
                    <app-folder-detail-tabs-card [permission]="permission" [variablesTask]="inputDynamicData.variablesTask">
                    </app-folder-detail-tabs-card>
                </div>
                <div fxFlex="0 1  calc(50%)" fxFlex.lt-md="0 1  calc(100% - 8px)"
                     fxFlex.lt-sm="100%">
                    <div fxLayout="row wrap" fxLayoutGap="8px">
                        <div fxFlex>
                            <app-warranty-card
                                    *ngIf="folder &&  folder.newWarranty &&  folder.warrantyOrigin "></app-warranty-card>
                        </div>
                    </div>
                    <div class="mt-12" fxFill>
                        <div [ngClass]="(hasWriteAccessToUserTask() | async) ? '' : 'readonly-wrapper'">

                            <mat-card class="mat-elevation-aster">
                                <ndc-dynamic [ndcDynamicComponent]="genericComponent"
                                                     [ndcDynamicInputs]="inputDynamicData"
                                                     [ndcDynamicOutputs]="outputDynamicData"></ndc-dynamic>

                                <app-user-task-footer *ngIf="inputDynamicData.instructionUserTask"
                                                      [instructionUserTask]="inputDynamicData.instructionUserTask"
                                                      [claim]="folder"></app-user-task-footer>
                            </mat-card>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</ng-container>
<ng-template #notFoundContent>
    <ng-container *ngIf="isFolderReason">
        <ng-container *ngIf="isFolderReason===FOLDER_NOT_FOUND;else folderError">
            <app-folder-not-found fxLayoutAlign="center center" class="w-100-p"></app-folder-not-found>
        </ng-container>
        <ng-template #folderError>
            <app-folder-error fxLayoutAlign="center center" class="w-100-p"></app-folder-error>
        </ng-template>
    </ng-container>
</ng-template>