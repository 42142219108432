
    <app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
    <mat-card-content>

        <form [formGroup]="invoiceForm.form" fxLayout="column"
              fxLayoutAlign="start" autocomplete="off" id="form1" class="mt-25">
            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>{{'INVOICE.INVOICE_NUMBER'|translate}}</mat-label>
                    <input matInput placeholder="{{'INVOICE.INVOICE_NUMBER'|translate}}"
                           inputControl
                           formControlName="invoiceNumber" required>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>{{'FOLDER.STORE_PAYMENT.PAYMENT_AMOUNT'|translate}}</mat-label>
                    <input matInput
                           placeholder="{{'FOLDER.STORE_PAYMENT.PAYMENT_AMOUNT'|translate}}"
                           min="0" type="number"
                           [readonly]="!isAuthorizedEditMode()"
                           formControlName="paymentAmount">
                </mat-form-field>
            </div>

        </form>

        <div fxLayout="row" fxLayoutAlign="space-between end">
            <div>
                <button type="button" mat-button
                        (click)="cancelPayment()"
                        class="aster_btn_cancel">
                    {{  'BUTTON.CANCEL_PAYMENT' | translate}}
                </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                <button mat-button
                        [ngClass]="'aster-btn_border'"
                        (click)="switchPaymentMode()">
                    {{'FOLDER.WORKFLOW.WAITING_PAYMENT.SWITCH_PAYMENT_MODE_TO_INTERNET' | translate}}
                </button>

                <button mat-stroked-button (click)="onSubmit()"
                        type="submit" [disabled]="invoiceForm.form.invalid"
                        [ngClass]="invoiceForm.form.invalid?'aster_btn_disabled':'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>

            </div>
        </div>


    </mat-card-content>