import {Pipe, PipeTransform} from '@angular/core';


/**
 * this pipe add a space between the Amount and the Currency
 * Examples:
 * zł100.00 ==> zł 100.00
 * €100.00 ==> € 100.00
 */

export const SPACE = ' ';

@Pipe({name: 'space'})
export class SpacePipe implements PipeTransform {

    transform(priceAmount: string): string {
        if (priceAmount != null) {
            const firstDigitIndex = priceAmount.search(/\d/);
            return this.insert(priceAmount, firstDigitIndex);
        }

    }

    insert(str, index): string {
        return str.substr(0, index) + SPACE + str.substr(index);
    }

}
