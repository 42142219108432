import {createSelector} from '@ngrx/store';
import {OrganizationState} from './organization.state';
import {AppState} from '../app.state';
import {CountryConfiguration} from '../../models/country.model';
import {I18nUtils} from '../../shared/utils/i18n-utils';

const organizationState = (state: AppState) => state.organization;

const countryConfiguration = createSelector(
    organizationState,
    (organizationState: OrganizationState) => organizationState.countryConfiguration
);

export const currency = createSelector(
    countryConfiguration,
    (countryConfiguration: CountryConfiguration) => countryConfiguration.currency
);

export const countryCode = createSelector(
    countryConfiguration,
    (countryConfiguration: CountryConfiguration) => countryConfiguration.countryCode
);

export const hasImmobilization = createSelector(
    countryConfiguration,
    (countryConfiguration: CountryConfiguration) => countryConfiguration.hasImmobilization
);

export const vats = createSelector(
    countryConfiguration,
    (countryConfiguration: CountryConfiguration) => countryConfiguration.vats
);

export const legalForms = createSelector(
    countryConfiguration,
    (countryConfiguration: CountryConfiguration) => countryConfiguration.legalForms
);

export const templateId = createSelector(
    organizationState,
    (organizationState: OrganizationState) => organizationState.templateId
);

export const enableStockClaim = createSelector(
    organizationState,
    (organizationState: OrganizationState) => organizationState.enableStockClaim
);

export const enableClaimWithoutCustomer = createSelector(
    organizationState,
    (organizationState: OrganizationState) => organizationState.claimWithoutCustomer
);

export const margin = createSelector(
    organizationState,
    (organizationState: OrganizationState) => organizationState.margin
);

export const currentLanguage = createSelector(
    organizationState,
    (organizationState: OrganizationState) => organizationState.languageKey
);
export const dashboardInfo = createSelector(
    organizationState,
    (organizationState: OrganizationState) => organizationState.dashboardInfo
);
export const browserLanguageOriginKey = createSelector(
    currentLanguage,
    (languageKey: string) => languageKey ?? I18nUtils.getBrowserLanguageOriginKey()
);

export const operationMode = createSelector(
    organizationState,
    (organizationState: OrganizationState) => organizationState.operationMode
);

const claimSensibleHiddenFields = createSelector(
    organizationState,
    (organizationState: OrganizationState) => organizationState.claimSensibleAPIInfo?.hiddenFields || []
);

export const isClaimSensibleHiddenField = createSelector(
    claimSensibleHiddenFields,
    (claimSensibleHiddenFields, props) => claimSensibleHiddenFields.includes(props.field)
);
