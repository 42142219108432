import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-to-repair',
    templateUrl: './waiting-to-repair.component.html',
    styleUrls: ['./waiting-to-repair.component.css']
})
export class WaitingToRepairComponent implements OnInit {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    repairInProgress = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        if (!this.repairInProgress) {
            return;
        }
        this.inputMap.emit({});
    }

}
