import {Component, Input, OnInit} from '@angular/core';
import {SharedService} from '../../services/shared.service';
import {CountriesUtils} from '../../data/countries-utils';
import {BackOfficeService} from '../../services/back-office.service';
import {Subscription} from 'rxjs';
import {PageSearchRequest} from '../../../models/page-search-request.model';

@Component({
    selector: 'app-countries-flags-component',
    templateUrl: './countries-flags.component.html',
    styleUrls: ['./countries-flags.component.css']
})

export class CountriesFlagsComponent implements OnInit {
    currentCountry;
    countries = [];
    @Input() currentSelectedCountryCode;

    private currentCountrySubscription: Subscription;
    private allCountriesSubscription: Subscription;

    constructor(private sharedService: SharedService, private backOfficeService: BackOfficeService) {
    }

    ngOnInit(): void {
        if(this.currentSelectedCountryCode === undefined){
            this.getAllConfiguredCountriesList();
        }

        this.allCountriesSubscription = this.sharedService.getCountries().subscribe(countries => {
            this.countries = countries;
            this.getSelectedCountry();
        });
    }

    private getAllConfiguredCountriesList() {
        const pageCriteria: PageSearchRequest = {
            page: 0, size: 10,
        };
        let allConfiguredCountriesList = [];
        this.backOfficeService.bulkSearchRepairerDepartments(pageCriteria, new Map()).subscribe(res => {
            res.content.forEach(configuredCountry => {
                const index = CountriesUtils.COUNTRIES_LIST.findIndex(country => country.code === configuredCountry.countryCode);
                if (index !== -1) {
                    allConfiguredCountriesList.push(CountriesUtils.COUNTRIES_LIST[index]);
                }
            });
            this.initializeFlagsList(allConfiguredCountriesList);
        });
    }

    private initializeFlagsList(allConfiguredCountriesList: any[]) {
        this.sharedService.setCountries(allConfiguredCountriesList);
        if (allConfiguredCountriesList[0]) {
            this.sharedService.setCurrentCountry(allConfiguredCountriesList[0].code);
        }
    }

    countrySelectedItem(code: string): void {
        this.sharedService.setCurrentCountry(code);
    }

    getSelectedCountry(): void {
        this.currentCountrySubscription = this.sharedService.getCurrentCountry().subscribe(currentCountry => {
            this.currentCountry = currentCountry;
        });
    }

    ngOnDestroy() {
        if (!!this.currentCountrySubscription) {
            this.currentCountrySubscription.unsubscribe();
        }if (!!this.allCountriesSubscription) {
            this.allCountriesSubscription.unsubscribe();
        }
    }
}
