<div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title class="h3 mb-12">{{!!userTaskTitle ? userTaskTitle : ('COMPONENT.' + instructionUserTask?.code | translate)}} {{additionalTitle}}</mat-card-title>
        <div>
            <strong>{{info}}</strong>
        </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" *ngIf="!!subTitle">
        <mat-card-subtitle  class="mb-12">{{subTitle}}</mat-card-subtitle>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start" class="mb-12" *ngIf="!!instructionText">
        <fieldset class="align-center" fxFlex="100">
            <legend>{{'FOLDER.INSTRUCTION'|translate}}</legend>
            <div [innerHTML]="instructionText"></div>
            <div fxLayout="column" fxLayoutAlign="start start end ">
                <mat-list-item id="attachmentFileList"
                               *ngFor="let attachment of attachments; let i = index; ">
                    <a (click)="downloadAttachment(attachment)" class="cursor-pointer">
                        <mat-icon>file_copy</mat-icon>
                        <span class="file_name">{{attachment?.name}}</span>
                    </a>
                </mat-list-item>
            </div>
        </fieldset>
    </div>
</div>