import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ShippingEventType} from '../../../../../../models/enums/shipmentEventType.enum';
import {Folder} from '../../../../../../models/folder.model';
import {Shipment} from '../../../../../../models/shipment.model';
import {ConfigCodeMapsEnum} from '../../../../../../shared/services/configuration-item-enum';
import {TaskVariables} from '../../../task.variables';
import {MatTableDataSource} from '@angular/material/table';
import {select, Store} from '@ngrx/store';
import {allConfiguration} from '../../../../../../store/configuration/configuration.selectors';
import {filter} from 'rxjs/operators';
import {AppState} from '../../../../../../store/app.state';
import {Observable} from 'rxjs';
import {currentLanguage} from '../../../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {StartLoading} from '../../../../../../store/loader/loader.actions';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-shipment-in-progress',
    templateUrl: './shipment-in-progress.component.html',
    styleUrls: ['./shipment-in-progress.component.scss']
})
export class ShipmentInProgressComponent extends Unsubscriber implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();

    carrier;
    trackingNumber;
    reservationHasAnomalie: boolean;
    shipmentMode: string;
    shipmentDelivered = false;
    currentShipment: Shipment;
    hasEvents: boolean;
    eventsShipmentColumns: string[] = ['type', 'details', 'date'];
    eventsShipmentDataTable = new MatTableDataSource<any>();
    currentLanguage$: Observable<string>;

    constructor(private store$: Store<AppState>){
        super();
    }

    ngOnInit(): void{
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        if (!!this.variablesTask[TaskVariables.carrierCode]) {
            this.anotherSubscription = this.store$.pipe(
                select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.CARRIER}),
                filter(carrier => carrier.code === this.variablesTask[TaskVariables.carrierCode])
            )
                .subscribe(it => {
                    if (it.length > 0) {
                        this.carrier = it[0];
                    }
                });
        }
        if (!!this.variablesTask[TaskVariables.trackingNumber]) {
            this.trackingNumber = this.variablesTask[TaskVariables.trackingNumber];
        }
        if (!!this.variablesTask[TaskVariables.shipmentMode]) {
            this.shipmentMode = this.variablesTask[TaskVariables.shipmentMode];
        }
        this.reservationHasAnomalie = !!this.variablesTask['reservationHasAnomalie'];
        this.currentShipment = this.getCurrentShipment();

        if (this.currentShipment.events.length > 0) {
            this.hasEvents = true;
            this.eventsShipmentDataTable.data = this.currentShipment.events;
        }
    }

    private getCurrentShipment(): Shipment{
        return this.folder.shipments.find(shipment => shipment.trackingNumber === this.trackingNumber);
    }


    onSubmit(): void{
        if (!this.shipmentDelivered) {
            return;
        }
        this.inputMap.emit({
            'forcedShippingStatus': ShippingEventType.SHIPMENT_DELIVERED.toString()
        });
    }



}
