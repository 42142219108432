<div *ngIf="!isSearch; else SEARCH_SUPPLIER" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" class="mt-8">

    <ng-container *ngIf="!editMode; else UPDATE_SUPPLIER">
        <div fxLayoutGap="15px">
            <strong [ngStyle]="{'color': isUnknownSupplier() ? 'red' : ''}"> {{'PRODUCT.PREVIEW_CARD.LABELS.SUPPLIER' | translate}}:</strong>
                <ng-container
                    *ngIf="isUnknownSupplier();else SHOW_SUPPLIER_NAME">
                    <span [ngStyle]="{'color': isUnknownSupplier() ? 'red' : ''}">{{UNKNOWN_SUPPLIER | translate}}</span>
                </ng-container>
                <ng-template #SHOW_SUPPLIER_NAME>
                    <span (click)="showSupplierDetail()" class="item-info"
                        [hideSensibleInfo]="claimSensibleHiddenField.SUPPLIER">{{supplier.label}}</span>
                </ng-template>
        </div>
        <div *ngIf="isEditable" (click)="switchEditMode()" class="cursor-pointer">
            <mat-icon>edit</mat-icon>
        </div>
    </ng-container>
    <ng-template #UPDATE_SUPPLIER>
        <mat-form-field appearance="outline" fxFlex="80">
            <mat-label>{{'PRODUCT.PREVIEW_CARD.LABELS.SUPPLIER'|translate}}</mat-label>
            <input type="text" matInput [id]="'suppliers'"
                   [placeholder]="'PRODUCT.PREVIEW_CARD.LABELS.SUPPLIER'|translate"
                   [formControl]="supplierCtrl"
                   [matAutocomplete]="auto"
                   [required]="true">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let supplier of filteredSuppliers | async"
                            (onSelectionChange)="onSelectSupplier($event);"
                            [id]="supplier.code"
                            [value]="supplier.code">
                    {{supplier.code}} - {{supplier.label}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <div fxFlex="20">
            <div fxLayout="row" fxLayoutAlign="end center">
                <div (click)="cancel()" class="cursor-pointer">
                    <mat-icon>clear</mat-icon>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #SEARCH_SUPPLIER>
    <mat-form-field class="filter w-100-p" floatLabel="never" appearance="outline">
        <mat-label>{{'PRODUCT.PREVIEW_CARD.LABELS.SUPPLIER'| translate}}</mat-label>
        <input type="text" matInput [id]="'suppliers'"
               [placeholder]="'PRODUCT.PREVIEW_CARD.LABELS.SUPPLIER'|translate"
               [formControl]="supplierCtrl"
               [matAutocomplete]="auto"
               (change)="supplierSelected($event)">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let supplier of filteredSuppliers | async"
                        (onSelectionChange)="onSelectSupplier($event);"
                        [id]="supplier.code"
                        [value]="supplier.code">
                {{supplier.code}} - {{supplier.label}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</ng-template>
