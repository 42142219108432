import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {FileService} from '../../../../../../shared/services/file.service';
import {QuotationService} from '../../../../../../shared/services/quotation.service';
import {TaskVariables} from '../../../task.variables';
import {MatTableDataSource} from '@angular/material/table';
import {Folder} from '../../../../../../models/folder.model';
import {Observable, Subscription} from 'rxjs';
import {TypeOfServiceEnum} from '../../../../../../models/typeOfService.model';
import {Quotation} from '../../../../../../models/quotation.model';
import {FileInfo} from '../../../../../../models/file-info.model';
import {saveAs} from 'file-saver';
import * as uuid from 'uuid';
import {CurrentWorkFlowStatusEnum} from '../../../../../../models/enums/currentWorkFlowStatus.enum';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {AppState} from '../../../../../../store/app.state';
import {select, Store} from '@ngrx/store';
import {currentLanguage} from '../../../../../../store/organization/organization.selectors';
import {SparePartService} from '../../../../../../shared/services/spare-part.service';
import {GrowthbookService} from '../../../../../../shared/services/growthbook.service';
import {AppFeatures} from '../../../../../../shared/features/app-features';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';
import {IEvent} from '../../../../../../models/events.model';
import * as moment from 'moment';

@Component({
    selector: 'app-quotation-process-customer',
    templateUrl: './quotation-process-customer.component.html',
    styleUrls: ['./quotation-process-customer.component.scss']
})
export class QuotationProcessCustomerComponent implements OnInit, OnDestroy {

    @ViewChild('dropzone') dropzone: ElementRef;
    @Output() inputMapSubComponent = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;
    currency: string;
    currentDate: string = moment().format();

    folder: Folder;
    private folderSubscription: Subscription;
    currentLanguage$: Observable<string>;

    displayedColumns: string[] = ['type', 'number', 'totalPrice'];
    displayedColumnsAttachment: string[] = ['name', 'date', 'action'];

    private sparePartsTypes = Object.keys(TypeOfServiceEnum);
    attachmentQuotationDataTable = new MatTableDataSource<any>();
    quotationSummary = new MatTableDataSource<any>();

    quotation: Quotation;

    fileUploadForm: any = {
        file: null,
        hasFile: false
    };

    quotationAcceptationForm: any = {
        form: null,
        placeholders: []
    };

    quotationId: string;
    quotationDraftId: string;
    attachments = new Array<any>();
    attachmentsFolder = new Array<any>();

    isVisible = -1;

    processWithDownPayment = false;
    invalidPercent = false;
    invalidValue: boolean;
    events: IEvent [] = [];

    constructor(private folderSubjectService: FolderSubjectService,
                private folderService: FolderService,
                private growthbookService: GrowthbookService,
                private fileService: FileService,
                private quotationService: QuotationService,
                private sparePartService: SparePartService,
                private store$: Store<AppState>) {
    }


    ngOnInit(): void {
        this.currency = this.variablesTask[TaskVariables.currency];
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.growthbookService.getFeatureValue(AppFeatures.DOWN_PAYMENT_INPUTS, {}, null).then((value: string) => {
            const claimDate = moment(this.folder.createdBy.actionDate).format('YYYY-MM-DD');
            if (value <= claimDate) {
                this.processWithDownPayment = true;
            }
        });

        this.folderSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
            this.initFormValidateQuotation();
            this.quotation = Quotation.getEmptyQuotation(this.currency);
            this.initQuotationVariable();
        });
    }

    initQuotationVariable(): void {
        if (this.folder.quotations) {
            this.getDataQuotation();
        }
    }

    private getDataQuotation(): void {
        this.quotationId = this.variablesTask[TaskVariables.quotationId];
        this.quotation = this.folder.quotations.filter(quotation => quotation.id === this.quotationId)[0];
        this.quotation.totalPrice = {
            value: this.variablesTask[TaskVariables.totalQuotationPrice],
            currency: this.variablesTask[TaskVariables.currency]
        };
        this.quotation.quotationAttachments.forEach(fileId => {
            this.attachmentsFolder.push(this.folder.attachments.find(file => file.id.startsWith(fileId)));
        });
        this.attachmentQuotationDataTable.data = this.attachmentsFolder;
        this.initDataTable();
    }

    ngOnDestroy(): void {
        if (!!this.folderSubscription) {
            this.folderSubscription.unsubscribe();
        }
    }

    private initDataTable(): void {
        const dataSource = [];
        let numberSpareParts = 0;
        let totalTTC = 0;
        this.quotation.totalPrice.value = 0;
        for (const sparePartsCode of this.sparePartsTypes) {
            numberSpareParts = this.getNumberSparePartsOfQuotation(sparePartsCode);
            totalTTC = this.getTotalPriceSparePartsOfQuotation(sparePartsCode);
            if (numberSpareParts !== 0) {
                dataSource.push({
                    type: sparePartsCode,
                    number: numberSpareParts,
                    totalTTC: totalTTC
                });
                this.quotation.totalPrice.value += totalTTC;
            }
        }
        if (this.folder.operationMode === 'SPARE_PARTS_ORDER') {
            this.quotationAcceptationForm.form.get(['downPaymentValue']).setValue(this.sparePartService.formatPriceAccordingToCurrency(this.quotation.totalPrice.value, this.currency));
        }

        this.quotationSummary = new MatTableDataSource<any>(dataSource);
    }

    getNumberSparePartsOfQuotation(sparePartsCode): number {
        return this.quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === sparePartsCode ? total + 1 : total, 0);
    }

    getTotalPriceSparePartsOfQuotation(sparePartsCode): number {
        return this.quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === sparePartsCode ? total + line.totalLinePrice?.value : total, 0);
    }

    onFilesAdded($event: any): void {
        this.fileUploadForm.hasFile = true;
        this.fileUploadForm.file = $event[0].name;
        const fileChange = this.fileService.onFileChange($event);
        fileChange.reader.onload = () => {
            const blobFile = this.fileService.dataURItoBlob(fileChange.reader.result.toString().split(',')[1]);
            this.attachments.push({file: blobFile, info: $event[0]});
            this.attachmentsFolder.push({file: blobFile, info: $event[0]});
            this.attachmentQuotationDataTable.data = this.attachmentsFolder;
            // @ts-ignore
            this.dropzone.reset();
            this.fileUploadForm.hasFile = false;
        };
    }

    removeFile(fileId): void {
        this.folderService.deleteAttachmentFileFolder(this.folder.id, fileId).then(resFile => {
            this.folder.attachments = this.folder.attachments.filter(file => file.id !== fileId);
            this.attachmentsFolder = this.attachmentsFolder.filter(file => file !== fileId);
            this.attachmentQuotationDataTable.data = this.attachmentsFolder;
        });
    }

    removeFileFromDataTable(fileId): void {
        const index = this.attachmentsFolder.indexOf(fileId);
        if (index > -1) {
            this.attachmentsFolder.splice(index, 1);
            this.attachments = this.attachments.filter(value => value.info.name !== fileId.name);
        }
        this.attachmentQuotationDataTable.data = this.attachmentsFolder;
    }

    onItemChange(item, i) {
        this.isVisible = i;
    }

    private initFormValidateQuotation(): void {
        this.quotationAcceptationForm.form = new FormGroup({
            quotationAccepted: new FormControl(null, Validators.required),
        });

        if (this.folder.operationMode === 'SPARE_PARTS_ORDER') {
            this.quotationAcceptationForm.form.addControl('downPaymentPercent', new FormControl(100, [Validators.min(0), Validators.max(100)]));
            this.quotationAcceptationForm.form.addControl('downPaymentValue', new FormControl([Validators.required, Validators.min(0)]));
        }

        if (this.folder.currentWorkflowStatus.status === CurrentWorkFlowStatusEnum.QUOTATION_ACCEPTATION) {
            this.quotationAcceptationForm.placeholders = [
                {
                    quotationAccepted: 'true',
                    value: 'MODAL.QUOTATION.ACCEPT_QUOTATION',
                },
                {
                    quotationAccepted: 'false',
                    value: 'MODAL.QUOTATION.REFUSE_QUOTATION_WITHOUT_RESTITUTION',
                },
                {
                    quotationAccepted: 'REFUSE_QUOTATION_WITH_RESTITUTION',
                    value: 'MODAL.QUOTATION.REFUSE_QUOTATION_WITH_RESTITUTION',
                },
            ];
        } else {
            // TODO get events will be deprecated , should replaced by this.sparePartsPlaceholders();, temporarily logic to handle old folders
            this.folder.operationMode === 'SPARE_PARTS_ORDER' ? this.getEvents() : this.defaultPlaceholders();
        }
    }

    private defaultPlaceholders() {
        this.quotationAcceptationForm.placeholders = [
            {
                quotationAccepted: 'true',
                value: 'MODAL.QUOTATION.ACCEPT_QUOTATION',
            },
            {
                quotationAccepted: 'false',
                value: 'MODAL.QUOTATION.REFUSE_QUOTATION',
            }
        ];
    }

    private sparePartsPlaceholders() {
        this.quotationAcceptationForm.placeholders = [
            {
                quotationAccepted: 'true',
                value: 'MODAL.QUOTATION.ACCEPT_QUOTATION',
            },
            {
                quotationAccepted: 'false',
                value: 'MODAL.QUOTATION.REFUSE_QUOTATION',
            },
            {
                quotationAccepted: 'QUOTATION_RETURNED',
                value: 'MODAL.QUOTATION.RETURNED_QUOTATION',
            }
        ];
    }

    validateQuotationChoiceForm(): void {
        if (!this.isEnabledQuotation()) {
            return;
        }

        if (this.quotationAcceptationForm.form.value.quotationAccepted === 'true') {
            this.updateQuotationAndFireWorkflow();
        } else {
            this.inputMapSubComponent.emit({
                quotationAccepted: this.quotationAcceptationForm.form.value.quotationAccepted
            });
        }
    }

    private updateQuotationAndFireWorkflow() {
        if (this.variablesTask['quotationDistributorId']
            || this.variablesTask['servicesQuotationId']
            || this.variablesTask['quotationProcess'] === this.quotation.target) {
            this.quotationDraftId = this.quotation.id;
        } else {
            this.quotationDraftId = uuid.v4();
        }
        this.quotation.id = this.quotationDraftId;
        const {downPaymentValue} = this.quotationAcceptationForm.form.value;

        if (downPaymentValue != null) {
            this.quotation.downPayment = {
                value: this.sparePartService.normalizeNumber(downPaymentValue),
                currency: this.currency
            };
        }

        this.store$.dispatch(new StartLoading());
        this.folderService.updateQuotationToFolder(this.folder.id, this.attachments, this.quotation).subscribe(value => {
            this.inputMapSubComponent.emit({
                quotationId: this.quotation.id,
                quotationAccepted: this.quotationAcceptationForm.form.value.quotationAccepted
            });

            this.store$.dispatch(new StopLoading());
        });
    }

    getFileInfo(fileId: string): FileInfo {
        return this.folder.attachments.find(file => file.id.startsWith(fileId));
    }

    downloadFile(fileId: string): void {
        this.fileService.getAttachmentFile(fileId).subscribe(value => {
            saveAs(value, this.getFileInfo(fileId).name);
        });
    }

    isEnabledQuotation(): boolean {
        return this.quotation.totalPrice.value >= 0 && this.quotation.quotationLines.length > 0 && !this.quotationAcceptationForm.form.invalid;
    }

    calculateDownPaymentValue(percent: number) {
        this.invalidPercent = false;
        if (percent > 100 || percent < 0) {
            this.invalidPercent = true;
        }
        this.quotationAcceptationForm.form.get(['downPaymentValue'])
            .setValue(this.sparePartService
                .formatPriceAccordingToCurrency(this.sparePartService.normalizeFloat((percent / 100) * this.quotation.totalPrice.value), this.currency));
    }

    computeDownPaymentPercent(value: number) {
        this.invalidValue = false;
        if (value > this.getTotalPrice() || value < 0) {
            this.invalidValue = true;
        }
        this.quotationAcceptationForm.form.get(['downPaymentPercent']).setValue(Math.round(this.getPercent(value) * 100) / 100);
    }

    private getPercent(value: number) {
        return (value / this.getTotalPrice()) * 100;
    }

    private getTotalPrice(): number {
        return Math.round(this.quotation.totalPrice.value * 100) / 100;
    }

    private getEvents() {
        this.folderService.getEventList(this.folder.id).subscribe(data => {
            const chooseModeOpSparePartDate =  moment (this.getEventDateOfOperatingChanged(data));
            this.growthbookService.getFeatureValue(AppFeatures.SPARE_PARTS_DEPLOYMENT_DATE, {}, null)
                .then((featureValue: string) => {
                    const deploymentDate =  moment (featureValue);
                    const comparisonResult = chooseModeOpSparePartDate < deploymentDate;
                    if (comparisonResult) {
                        // TODO get events will be deprecated , temporarily logic to handle old folders
                        this.defaultPlaceholders();
                    } else {
                        this.sparePartsPlaceholders();
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        });
    }

    private getEventDateOfOperatingChanged(events: IEvent[]) {
        const filteredEvents = events.filter((e) => e.eventType === 'FOLDER_DATA' && (e.eventName === 'OPERATING_MODE_CHANGED'));
        const filteredChooseOperatingModeEvents = events.filter((e) => e.eventType === 'WORKFLOW_STATUS' && (e.eventName === 'CHOOSE_OPERATING_MODE'));
        if (!filteredEvents && !filteredChooseOperatingModeEvents) {
            return;
        }
        const lastEvent = filteredEvents.reduce((prev, current) =>
            prev?.userAction.actionDate > current.userAction.actionDate ? prev : current
        );
        const lastChooseOperatingModeEvent = filteredChooseOperatingModeEvents.reduce((prev, current) =>
            prev?.userAction.actionDate > current.userAction.actionDate ? prev : current
        );
        if (lastEvent.userAction.actionDate > lastChooseOperatingModeEvent.userAction.actionDate) {
            return lastEvent.userAction.actionDate;
        } else {
            return lastChooseOperatingModeEvent.userAction.actionDate;
        }

    }
}
