    <app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
    <form [formGroup]="waitQuotationForm.form" fxLayout="column" *ngIf="waitQuotationForm.placeholders"
          fxLayoutAlign="start">
        <div fxLayout="row" fxLayoutAlign="space-between stretch" >
            <mat-form-field appearance="outline" fxFlex="50">
                <mat-label>{{'FOLDER.QUOTATION.CODE'|translate}}</mat-label>
                <input matInput placeholder="{{'FOLDER.QUOTATION.CODE'|translate}}"
                       formControlName="quotationCode" required>
            </mat-form-field>
            <button mat-stroked-button class="aster-btn_border" (click)="openQuotationModal()">
                <mat-icon class="mr-8">add</mat-icon>
                {{'BUTTON.ADD' | translate}}
            </button>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px">
            <div fxFlex="70" fxFlex.lt-md="100">
                <table mat-table [dataSource]="quotationSummary" class="mat-elevation-z2 w-100-p mt-16 mb-16">
                    <ng-container matColumnDef="type">
                        <th class="table-header" mat-header-cell *matHeaderCellDef> {{'MODAL.QUOTATION.TYPE' | translate}}</th>
                        <td mat-cell *matCellDef="let quotationLine"> {{'MODAL.QUOTATION.TYPE.' + quotationLine.type | translate}}</td>
                    </ng-container>
                    <ng-container matColumnDef="number">
                        <th mat-header-cell *matHeaderCellDef
                            class="table-header"> {{'MODAL.QUOTATION.NUMBER' | translate}}</th>
                        <td mat-cell *matCellDef="let quotationLine">{{quotationLine.number}} </td>
                    </ng-container>
                    <ng-container matColumnDef="totalPrice">
                        <th mat-header-cell *matHeaderCellDef
                            class="table-header"> {{'MODAL.QUOTATION.TOTAL_TTC' | translate}}</th>
                        <td mat-cell *matCellDef="let quotationLine">{{quotationLine.totalTTC |currency:currency :'symbol-narrow' | space}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div fxFlex="30" fxFlex.lt-md="100">
                <mat-card class="mat-elevation-z2 mt-16 mb-16">
                    <h4 class="text-bold">{{'MODAL.QUOTATION.TOTAL_TTC' | translate}}</h4>
                    <h2>
                        {{quotation.totalPrice.value  |currency:quotation.totalPrice.currency :'symbol-narrow' | space}}
                    </h2>
                </mat-card>
                <ng-container *ngIf="quotation.totalPrice.value < 0">
                 <p class="error-text-color">{{'FOLDER.REPAIR_IN_PROGRESS.NEGATIVE_QUOTATION' | translate}}</p>
                </ng-container>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
            <mat-card class="mat-elevation-z4 mt-16 mb-16" fxFlex="30" fxFlex.lt-md="100">
                <ngx-dropzone
                    #dropzone
                    label="{{fileUploadForm.hasFile ? fileUploadForm.file: 'FOLDER.FILE.UPLOAD_MSG' | translate}}"
                    [showPreviews]="true" [multiple]="false" [accept]="'*'" (filesAdded)="onFilesAdded($event)">
                </ngx-dropzone>
            </mat-card>

            <mat-card class="mat-elevation-z4 mt-16 mb-16" fxFlex="70" fxFlex.lt-md="100">
                <table mat-table [dataSource]="attachmentQuotationDataTable" class="w-100-p mt-8">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="table-header">{{'FOLDER.FILE.TITLE' | translate}}</th>
                        <td mat-cell *matCellDef="let element" class="text-left">
                            <p class="file_name">
                                <mat-icon>file_copy</mat-icon>
                                {{!!element.info ? element.info.name : '--'}}
                            </p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="table-header"> {{'BUTTON.ACTION' | translate}}</th>
                        <td mat-cell
                            *matCellDef="let element">
                            <mat-icon color="warn" class="cursor-pointer"
                                      (click)="removeFile(element)">
                                delete_forever
                            </mat-icon>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAttachment"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAttachment;"></tr>
                </table>
            </mat-card>
        </div>

        <div fxLayout="row" fxLayoutAlign="end end">
            <button type="submit" mat-stroked-button
                    [disabled]="stopPropagation()"
                    (click)="completeTask()"
                    [ngClass]="stopPropagation() ? 'aster_btn_disabled' : 'aster_btn'">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>
    </form>
