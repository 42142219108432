<h1 mat-dialog-title>  {{'FOLDER.WORKFLOW.EDIT_INTERVENTION' | translate}}</h1>
<div mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        {{'VALIDATE.EDIT_INTERVENTION' | translate}}
    </div>
</div>

<mat-dialog-actions fxLayoutAlign="end end">
    <div fxLayout="row">
        <button mat-button (click)="onClose()" class="aster-modal-btn_cancel" type="reset">
            {{'BUTTON.CLOSE' | translate}}
        </button>
        <button mat-button [mat-dialog-close]="'VALIDATE'" (click)="onConfirm()"
                class="h-44 mt-4 ml-8  "
                class="h-44 mt-4 ml-8  aster_btn"
                cdkFocusInitial>{{'BUTTON.VALIDATE' | translate}}</button>
    </div>
</mat-dialog-actions>

