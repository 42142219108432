<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="text-bold">
        {{title}}
    </h1>
    <button mat-icon-button class="ml-40" (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content style="min-width: 400px; max-width: 500px">
    <div [innerHTML]="message"></div>
</mat-dialog-content>

<mat-dialog-content class="w-100-p">
    <form [formGroup]="commentForm.form" fxLayout="column" fxLayoutAlign="start">

        <div fxLayout="column" gdAlignColumns="20px" class="w-100-p">
            <mat-form-field appearance="outline" *ngIf="isProductRecallContext()">
                <mat-label>{{'FOLDER.CLOSE_FORM.STATUS' | translate }}</mat-label>
                <mat-select
                    [placeholder]="'FOLDER.CLOSE_FORM.STATUS' | translate"
                    [required]="commentForm.form.get(['currentStatus']) | hasRequiredField"
                    aria-label=""
                    formControlName="currentStatus">
                    <mat-option *ngFor="let status of statusList"
                                [value]="status">
                        <span>{{ ('COMPONENT.' + status) | translate }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{commentMessage}}</mat-label>
                <textarea matInput rows="10" cols="7" [placeholder]="commentMessage"
                          formControlName="comment"></textarea>
            </mat-form-field>
        </div>


    </form>
</mat-dialog-content>


<mat-dialog-actions fxLayoutAlign="end end">
    <div fxLayout="row">
        <button mat-button (click)="onClose()" class="aster-modal-btn_cancel" type="reset">
            {{cancelButtonLabel}}
        </button>
        <button mat-raised-button type="submit" [disabled]="!commentForm.form.valid" (click)="onSubmit()"
                [ngClass]="commentForm.form.valid? 'aster_btn' : 'aster_btn_disabled'">
            {{confirmButtonLabel}}
        </button>
    </div>
</mat-dialog-actions>
