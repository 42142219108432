import {Component, Input} from '@angular/core';
import {FileInfo} from '../../../../models/file-info.model';

@Component({
    selector: 'app-product-documents',
    templateUrl: './product-documents.component.html',
    styleUrls: ['./product-documents.component.scss']
})
export class ProductDocumentsComponent {

    @Input() documents: FileInfo[];

}
