<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <fieldset fxFlex>
        <legend class="h3 title-fieldset">{{'PRODUCT_STATE.LABELS.STATE'|translate}}</legend>
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'PRODUCT_STATE.SELECT.STATE'|translate}}</mat-label>
                <mat-select id="productState"
                    (selectionChange)="changeProductStatus($event.value)"
                    [(value)]="selectedValue"
                    required>
                    <mat-option id="{{status.code}}"
                                *ngFor="let status of  listProductState" [value]="status.code">
                        {{status.label}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class=" mt-8" fxFlex>
                <ng-container>
                    <mat-chip-list class="w-50-p">
                        <mat-chip color="primary" (click)="onUploadImage()">
                            <mat-icon>add_a_photo</mat-icon>
                            <span
                                class="ml-4">{{'PRODUCT.FORM.FIELDS.IMAGE_ID.PLACEHOLDER'|translate}}</span>
                        </mat-chip>
                    </mat-chip-list>
                </ng-container>
                <div class="mt-8" *ngIf="attachmentsFiles && attachmentsFiles.length>0">
                    <strong>{{'PRODUCT_STATE.PICTURE'|translate}}</strong> :
                    <mat-list class="w-100-p">
                        <ng-container
                            *ngFor="let file of attachmentsFiles; let i=index">
                            <mat-list-item>
                                <ng-container *ngIf="file.url">
                                    <div class="zone-file_img cursor-pointer" (click)="showImageModal(file.url)">
                                        <mat-icon mat-list-icon><img class="image-serial_number" [src]="file.url">
                                        </mat-icon>
                                    </div>
                                </ng-container>
                                <h4 class="text-bold ml-44">{{file.name}}</h4>
                                <h4 class="text-bold ml-44"> {{file.uploadDate ? (getDate(file.uploadDate) |date:"dd/MM/yyyy") : '' }}</h4>
                                <mat-icon color="warn" class="cursor-pointer" (click)="removeFileFromAttachment(i)">
                                    delete_forever
                                </mat-icon>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>
                </div>
            </div>

        </div>

    </fieldset>
</div>
