import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Folder} from '../../../../models/folder.model';
import {FileInfo} from '../../../../models/file-info.model';
import {Unsubscriber} from '../../../../unsubscriber';
import {InstructionUserTask} from '../../../../models/instruction-user-task.model';

@Component({
  selector: 'app-user-task-footer',
  templateUrl: './user-task-footer.component.html',
  styleUrls: ['./user-task-footer.component.scss']
})
export class UserTaskFooterComponent extends Unsubscriber implements  OnChanges {
    @Input() userTaskCode: string;
    @Input() claim: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    reportsCodeList: FileInfo[];

    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.instructionUserTask) {
            this.updateReportsCodeList();
        }
    }

    private updateReportsCodeList(): void {
        if (this.instructionUserTask && this.instructionUserTask?.reportCodes?.length > 0) {
            this.reportsCodeList = this.claim.attachments.filter(value =>
                this.instructionUserTask.reportCodes.includes(value.extraInfo['REPORT_CODE'])
            );
        } else {
            this.reportsCodeList = [];
        }
    }

}
