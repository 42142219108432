<div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxLayoutAlign="start">
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>
        </div>


        <form *ngIf="customerDetailForm" [formGroup]="customerDetailForm"
              (ngSubmit)="completeTask()" autocomplete="off" id="form-edit">
            <mat-radio-group
                formControlName="type"
                fxLayoutAlign="start start"
                fxLayoutGap="10px"
                (change)="customerTypeChange($event)"
                fxLayout="column">
                <mat-radio-button class="example-radio-button"  *ngFor="let folderType of folderTypeList" [value]="folderType">
                    {{folderType | translate}}
                </mat-radio-button>
            </mat-radio-group>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" class="mt-25">

                <mat-form-field class="py-4" appearance="outline" fxFlex="20" fxFlex.lt-md="100">
                    <mat-label>{{'CUSTOMER.CIVILITY'|translate}}</mat-label>
                    <mat-select formControlName="gender">
                        <mat-option *ngFor="let civility of civilitySelectBoxOptions"
                                    [value]="civility.key">
                            <span>{{ civility.label | translate}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="py-4" appearance="outline" fxFlex="40" fxFlex.lt-md="100">
                    <mat-label>{{'CUSTOMER.FIRST_NAME'|translate}}</mat-label>
                    <input matInput placeholder="{{'CUSTOMER.FIRST_NAME'|translate}}"
                           formControlName="firstName" required>
                </mat-form-field>

                <mat-form-field class="py-4" appearance="outline" fxFlex="40" fxFlex.lt-md="100">
                    <mat-label>{{'CUSTOMER.LAST_NAME'|translate}}</mat-label>
                    <input matInput placeholder="{{'CUSTOMER.LAST_NAME'|translate}}"
                           formControlName="lastName"
                           required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8">
                <mobile-phone-input-form *ngIf="!!mobilePhoneOutput" fxFlex="40"
                                         [isRequiredFields] = "false"
                                         [mobilePhoneInput]="mobilePhoneOutput"></mobile-phone-input-form>
                <mat-form-field class="py-4" appearance="outline" fxFlex="60">
                    <mat-label>{{'CUSTOMER.EMAIL'|translate}}</mat-label>
                    <input matInput placeholder="{{'CUSTOMER.EMAIL'|translate}}" formControlName="email"
                           type="email" isEmail>
                </mat-form-field>
            </div>

            <div *ngIf="isProfessionalFolder">
                <app-professional-folder-form
                    [customerDetailForm] ="customerDetailForm"
                ></app-professional-folder-form>
            </div>

            <div fxLayoutAlign="space-between center">
                <div>
                    <button type="button" mat-button matTooltip="{{'BUTTON.PREVIEW.STEP' | translate}}"
                            *ngIf="folder.operationMode==='AUTO_REPAIR'"
                            (click)="getPreviewTask()"
                            class="h-60 mt-16 mr-16">
                        <mat-icon>keyboard_return</mat-icon>
                        {{'BUTTON.PREVIEW.STEP' | translate}}
                    </button>
                </div>

                <button mat-stroked-button type="submit" [disabled]="stopPropagation()"
                        [ngClass]="stopPropagation() ? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>

        </form>

</div>
