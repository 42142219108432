import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import {FuseConfirmDialogComponent} from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {FolderPublic} from '../../../../models/folder.public.model';
import {CancelInterventionModalComponent} from './cancel-intervention-modal/cancel-intervention-modal.component';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {PublicService} from '../../../../shared/services/public.service';

@Component({
    selector: 'app-progress-repair',
    templateUrl: './progress-repair.component.html',
    styleUrls: ['./progress-repair.component.scss']
})
export class ProgressRepairComponent implements OnInit {

    @Input() folder: FolderPublic;

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();

    form: FormGroup;
    folderId: string;
    showCalender = false;
    minDate = new Date();
    confirmedAppointment: {};

    constructor(private route: ActivatedRoute,
                private router: Router,
                private store$: Store<AppState>,
                private publicService: PublicService,
                public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.folderId = this.route.snapshot.parent.params.folderId;
        if (!this.folder.repairerHasAgenda) {
            this.showCalender = true;
        }
        this.initForm();
    }


    private initForm(): void {
        this.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            comment: new FormControl(''),
            confirmedAppointment: new FormControl(null),
        });
    }

    completeTask(): any {
        if (this.form.value.status === 'REPAIR_DECISION_CANCEL') {
            this.delegateToCancelInterventionModal();
        } else if (this.form.value.status === 'APPOINTMENT_RESCHEDULE' && this.folder.repairerHasPublicAgenda) {
            this.delegateToConfirmationModal();
        } else {
            if (this.hasCommentValue()) {
                this.commentGiven.emit({
                        value: this.form.value.comment,
                        type: this.toCommentType(this.form.value.status),
                    }
                );
            }
            if (!!this.form.value.confirmedAppointment) {
                const date = moment(this.form.value.confirmedAppointment).format('YYYY-MM-DD');
                this.confirmedAppointment = {
                    day: date,
                    beginHour: '00:00:00',
                    endHour: '23:59:59'
                };
            }

            this.inputMap.emit({
                'decision': this.form.value.status,
                'confirmedDate': JSON.stringify(this.confirmedAppointment)
            });
        }
    }

    private delegateToConfirmationModal(): void {
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });

        dialogRef.componentInstance.title = 'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.RESCHEDULE.DIALOG.TITLE';
        dialogRef.componentInstance.message = 'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.RESCHEDULE.DIALOG.MESSAGE';

        dialogRef.afterClosed().subscribe(confirmed => {
            if (!!confirmed) {
                if (this.hasCommentValue()) {
                    this.commentGiven.emit({
                            value: this.form.value.comment,
                        }
                    );
                }

                this.inputMap.emit({
                    'decision': this.form.value.status
                });
            }
        });
    }

    private delegateToCancelInterventionModal(): void {
        this.dialog.open(CancelInterventionModalComponent).afterClosed()
            .subscribe(cancelComment => {
                if (cancelComment) {
                    this.store$.dispatch(new StartLoading());
                    this.saveComment(cancelComment);
                    this.publicService.completeTaskCancelIntervention(this.folder.externalUid).subscribe(value => {
                        this.store$.dispatch(new StopLoading());
                        this.router.navigate([`public/read-only/${this.folder.externalUid}`]);
                    }, () => this.store$.dispatch(new StopLoading()));
                }
            });
    }

    private saveComment(cancelComment) {
        this.commentGiven.emit({
            value: cancelComment,
            type: 'COMMENT_CANCEL_INTERVENTION',
        });
    }

    private hasCommentValue(): boolean {
        return this.form.value.comment.length > 0;
    }

    private toCommentType(status: string): string {
        switch (status) {
            case 'REPAIR_COMPLETED':
                return 'COMMENT_PRODUCT_REPAIRED';
            case 'PRODUCT_NOT_REPAIRABLE':
                return 'COMMENT_NOT_REPAIRABLE';
            case 'APPOINTMENT_RESCHEDULE':
                return 'COMMENT_APPOINTMENT_RESCHEDULE';
            case 'REPAIR_DECISION_CANCEL':
                return 'COMMENT_CANCEL_INTERVENTION';
            default:
                return undefined;
        }
    }

    private toCommentTitle(status: string): string {
        switch (status) {
            case 'WARRANTY_DISCLAIMER':
            case 'PRODUCT_NOT_REPAIRABLE':
                return 'FOLDER.COMMENT_FORM.LABEL';
            default:
                return 'FOLDER.COMMENT';
        }
    }

    changeConfirmedAppointmentValidator($event) {
        if ($event.value === 'APPOINTMENT_RESCHEDULE' && !this.folder.repairerHasAgenda) {
            this.form.get('confirmedAppointment').setValidators(Validators.required);
        } else {
            this.form.get('confirmedAppointment').clearValidators();
            this.form.get('confirmedAppointment').setValue(null);
        }
        this.form.get('confirmedAppointment').updateValueAndValidity();

    }

}
