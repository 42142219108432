<div >
    <button mat-icon-button
            matTooltip="{{'TOOLBAR.MENU.CRITERIA_SEARCH' | translate}}"
            [mdePopoverTriggerFor]="searchBarPopover" mdePopoverTriggerOn="click"
            #searchBarContentPopover="mdePopoverTrigger"
            class="fuse-search-bar-expander pr-0 ml-16">
        <mat-icon class="s-24 secondary-text white">search</mat-icon>
    </button>
    <mde-popover #searchBarPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
        <ng-container *ngIf="searchForm">
            <mat-card class="searchBarPopover mat-elevation-aster ">
                <mat-card-content>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <form [formGroup]="searchForm" class="w-100-p" (ngSubmit)="search()">
                            <div fxLayout="row" class="mt-8"
                                 fxLayoutAlign="space-between center" fxLayoutGap="15px">
                                <div fxFlex="100" class="ml-24">

                                    <mat-form-field id="fuse-search-bar-input" class="w-100-p">
                                        <input formControlName="label" type="text"
                                               placeholder="{{'BUTTON.SEARCH' | translate}}" matInput>
                                    </mat-form-field>
                                </div>

                            </div>
                        </form>
                        <div fxLayout="row" fxLayoutAlign="end center" class="mt-4">
                            <button mat-icon-button class="fuse-search-bar-collapser" (click)="onClose()"
                                    aria-label="Collapse Search Bar">
                                <mat-icon class="s-24 secondary-text">close</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </ng-container>
    </mde-popover>
</div>

