
export class SparePartModel {
    reference: string;
    label: string;
    type: string;
    quantity: number;
    price: number;
    currency: string;
    tva: number;
    totalLinePrice: number;
}

export interface OrderedSparePartsRequest {
    requestedServiceId: string;
    quotationLineTypes: string[];
}

export enum SparePartType {
    SPARE_PART = 'SPARE_PART',
    WORKFORCE = 'WORKFORCE',
    DISPLACEMENT = 'DISPLACEMENT',
}

export interface OrderedSparePartsDTO{
    code: string;
    label: string;
    price: string;
    tva: string;
    quantity: string;
    discount: string;
    type: string;
    payer: string;
}
