    <app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
    <mat-card-content *ngIf="!!folder">
        <form *ngIf="customerAddressForm.form" [formGroup]="customerAddressForm.form" fxLayout="column"
              fxLayoutAlign="start" (ngSubmit)="onSubmit()" autocomplete="off" id="form1">

            <div fxLayout="row wrap" gdColumns="repeat(auto-fit, minmax(100%, 1fr))" gdGap="10px"
                 formGroupName="countryCode">
                <app-generic-address-form [addressesInput]="customerAddress" ></app-generic-address-form>
            </div>

            <div fxLayoutAlign="space-between center">
                <button type="button" mat-button matTooltip="{{'BUTTON.PREVIEW.STEP' | translate}}"
                        (click)="getPreviewTask()"
                        class="h-60 mt-16 mr-16">
                    <mat-icon>keyboard_return</mat-icon>
                    {{'BUTTON.PREVIEW.STEP' | translate}}
                </button>

                <ng-container *ngIf="!!folder.customer">
                    <div>
                        <button mat-stroked-button (click)="reset()" type="button"
                                class="h-60 mt-4 mr-8 aster_btn_cancel">
                            {{'BUTTON.CANCEL' | translate}}
                        </button>
                        <button mat-stroked-button type="submit"
                                [disabled]="isInvalidForm()"
                                [ngClass]="'h-60 mt-4 '+( isInvalidForm() ?' aster_btn_disabled':' aster_btn')">
                            {{'BUTTON.VALIDATE' | translate}}
                        </button>
                    </div>
                </ng-container>
            </div>
        </form>

    </mat-card-content>