
    <mat-card-title class="h3 mt-12 mb-24">{{'COMPONENT.SUPPLIER_AGREEMENT_EXCEED' | translate}}</mat-card-title>

    <mat-card-content>
        <form [formGroup]="supplierExceededAgreement.form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <mat-radio-group formControlName="options"
                             class="ml-20" fxLayout="row"
                             fxLayoutAlign="space-between center"
                             labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button class="full-width-radio" *ngFor="let option of supplierExceededAgreement.placeholders"
                                  [id]="option.status"
                                  [value]="option.status">
                    <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div fxFlex>
                            <div>{{ option.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>

            <ng-container [ngSwitch]="supplierExceededAgreement.form.value.options">
                <div *ngSwitchCase="'false'" class="product-bloc">
                    <mat-radio-group formControlName="sendProduct" fxLayout="row" fxLayoutAlign="space-between center"
                                     labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch"
                                     fxLayoutGap="20px" (change)="radioChange($event)">
                        <mat-radio-button
                            [id]="'product_destruction'" class="full-width-radio" value="false">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div fxFlex>
                                    {{'FOLDER.WAITING_SUPPLIER_CONFIRMATION.DESTRUCTION_PRODUCT' | translate}}
                                </div>
                            </div>
                        </mat-radio-button>
                        <mat-radio-button *ngIf="variablesTask.sendProduct =='true'"
                            [id]="'send_product_to_supplier'" class="full-width-radio" value="true">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div fxFlex>
                                    {{'FOLDER.WAITING_SUPPLIER_CONFIRMATION.SHIPMENT_TO_SUPPLIER' | translate}}
                                </div>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>
                    <form [formGroup]="commentForm">
                        <mat-form-field appearance="outline" fxFill class="comment-field">
                            <mat-label>{{'MODAL.COMMENT.TITLE_ADD_COMMENT'|translate}}</mat-label>
                            <textarea matInput formControlName="comment"></textarea>
                        </mat-form-field>
                    </form>


                </div>

            </ng-container>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        [ngClass]="!activateButton() ?'aster_btn_disabled':'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>