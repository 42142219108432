import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingScreenService {


    private loadingCounter = 0;
    private _loading = false;
    loadingStatus: Subject<boolean> = new Subject<boolean>();

    get loading(): boolean {
        return this._loading;
    }

    set loading(value) {
        this._loading = value;
        this.loadingStatus.next(value);
    }

    startLoading(): void {
        this.loadingCounter++;
        if (this.loadingCounter === 1) {
            this.loading = true;
        }

    }

    stopLoading(): void {
        if (this.loadingCounter > 0) {
            this.loadingCounter--;
        }
        if (this.loadingCounter === 0) {
            this.loading = false;
        }
    }
}
