    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start center">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
        </div>

        <div class="mt-24">
            <mat-card-subtitle>{{'COMPONENT.SWAP.IN_STORE.DESCRIPTION'|translate}}</mat-card-subtitle>
            <mat-checkbox [(ngModel)]="isProductExchanged">{{'COMPONENT.SWAP.IN_STORE.DONE' | translate}}</mat-checkbox>
        </div>
        <div fxLayout="row" fxLayoutAlign="end">
            <button mat-stroked-button (click)="onSubmit()"
                    type="submit"
                    [disabled]="!isProductExchanged"
                    [ngClass]="!isProductExchanged?'h-60 mt-4  aster_btn_disabled':'h-60 mt-4 aster_btn'">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>
    </mat-card-content>