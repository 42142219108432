import {Component, Input} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {TaskVariables} from '../../task.variables';
import {TranslateService} from '@ngx-translate/core';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-order-by-other-partner-in-progress',
    templateUrl: './order-by-other-partner-in-progress.component.html',
    styleUrls: ['./order-by-other-partner-in-progress.component.scss']
})
export class OrderByOtherPartnerInProgressComponent {

    @Input() workflowStatus: string;
    @Input() variablesTask: any;
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    constructor(private translateService: TranslateService) {
    }

    redirectToPartnerSite(): void {
        let partnerLink = this.variablesTask[TaskVariables.partnerLink];
        let fullLink = this.addFolderIdToRequestParam(partnerLink);
        window.open(fullLink, '_blank');
    }

    private addFolderIdToRequestParam(partnerLink) {
        return partnerLink.concat('?folderId=', this.folder.id);
    }

    getDescription = () => {
        return this.translateService.stream('COMPONENT.OTHER_BY_OTHER_PARTNER.DESCRIPTION', {
            partnerName: this.variablesTask[TaskVariables.partnerName]
        });
    };
}
