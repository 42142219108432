import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {Quotation} from '../../../../../../models/quotation.model';
import {TaskVariables} from '../../../task.variables';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {currency} from '../../../../../../store/organization/organization.selectors';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-quotation-acceptation-client',
    templateUrl: './quotation-acceptation-client.component.html',
    styleUrls: ['./quotation-acceptation-client.component.scss']
})
export class QuotationAcceptationClientComponent extends Unsubscriber implements OnInit {

    @ViewChild('dropzone') dropzone: ElementRef;
    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;
    folder: Folder;
    quotation: Quotation;
    quotationId: string;
    attachments = new Array<any>();
    quotationChoices = [];
    currency: string;
    quotationChoiceForm: FormGroup = new FormGroup({});

    constructor(
        private folderService: FolderService,
        private store$: Store<AppState>
    ) {
        super();
    }

    ngOnInit(): void {
        this.initCurrency();
        this.initQuotation();
        this.initChoices();
        this.initQuotationChoiceForm();
    }

    private initCurrency() {
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => {
            this.currency = currency;
        });
    }

    private initQuotationChoiceForm(): void {
        this.quotationChoiceForm = new FormGroup({
            status: new FormControl(null, Validators.required),
        });
    }

    private initChoices() {
        this.quotationChoices = [
            {
                status: 'ACCEPTED',
                value: 'FOLDER.WORKFLOW.QUOTATION_CLIENT.ACCEPT_QUOTATION',
            },
            {
                status: 'REFUSE_QUOTATION_WITHOUT_RESTITUTION',
                value: 'FOLDER.WORKFLOW.QUOTATION_CLIENT.REFUSED_QUOTATION_WITHOUT_RESTITUTION',
            },
            {
                status: 'REFUSE_QUOTATION_WITH_RESTITUTION',
                value: 'FOLDER.WORKFLOW.QUOTATION_CLIENT.REFUSED_QUOTATION_WITH_RESTITUTION',
            },
            {
                status: 'RETURNED',
                value: 'FOLDER.WORKFLOW.QUOTATION_CLIENT.RETURNED',
            }
        ];
    }

    private initQuotation() {
        this.quotationId = this.variablesTask[TaskVariables.quotationId];
        this.quotation = this.folder.quotations
            .filter(quotation => quotation.id === this.quotationId)[0];
    }

    getAttachments(attachments: any[]) {
        this.attachments = attachments;
    }

    complete() {
        this.store$.dispatch(new StartLoading());
        this.folderService.updateQuotationToFolder(this.folder.id, this.attachments, this.quotation).subscribe(value => {
            this.inputMap.emit({'quotationClientStatus': this.quotationChoiceForm.value.status});
        }, () => {
            this.store$.dispatch(new StopLoading());
        });

    }

    disableForm() {
        return this.quotationChoiceForm.invalid;
    }
}
