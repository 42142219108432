import {Injectable} from '@angular/core';
import {GLOBAL} from '../../app-config';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ChartsResponse} from '../../main/dashboard/dashboard-folder/model/charts-response.model';
import {DashboardFolderFilters, FolderSearchResponse} from '../../models/folder-search-response.model';
import {ChartRequest, DashboardItem, DashboardRule} from 'app/main/dashboard/dashboard-folder/model/dashboard.model';
import {Page} from '../../models/page.model';
import {PageSearchRequest} from '../../models/page-search-request.model';
import {SnackBarService} from './snack-bar.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api`;

    constructor(public  httpClient: HttpClient,
                private snackBar: SnackBarService) {
    }

    createWidget(chartRequest): Observable<ChartsResponse> {
        return this.httpClient.post<ChartsResponse>(`${this.path}/dashboard/widget-config`, chartRequest);
    }

    updateWidget(widgetId, chartRequest): Observable<ChartsResponse> {
        return this.httpClient.put<ChartsResponse>(`${this.path}/dashboard/widget-config/${widgetId}`, chartRequest);
    }

    getWidgetsData(chartRequest): Observable<ChartsResponse[]> {
        return this.httpClient.post<ChartsResponse[]>(`${this.path}/dashboard/folders/widget`, chartRequest);
    }

    getWidget(widgetId): Observable<ChartRequest> {
        return this.httpClient.get<ChartRequest>(`${this.path}/dashboard/rule/${widgetId}`);
    }

    findFolders(dashboardFilterRequest: DashboardFolderFilters, queryParams: any): Observable<FolderSearchResponse> {
        return this.httpClient.post<FolderSearchResponse>(`${this.path}/dashboard/folders`, dashboardFilterRequest, {params: queryParams});
    }

    getAllWidgets(): Observable<ChartRequest[]> {
        return this.httpClient.get<ChartRequest[]>(`${this.path}/dashboard/widgets`);
    }

    getDashboardUsingRule(email: string): Observable<ChartRequest[]> {
        const request: DashboardRule = {
            user: email
        };
        return this.httpClient.post<ChartRequest[]>(`${this.path}/dashboard/rule/get-all`, request);
    }
    getDashboard(id: string): Observable<DashboardRule> {
        return this.httpClient.get<DashboardRule>(`${this.path}/dashboard/${id}`);
    }
    simulateWidget(chartRequest): Observable<ChartsResponse> {
        return this.httpClient.post<ChartsResponse>(`${this.path}/dashboard/widget-config/simulate`, chartRequest);
    }

    saveDashboard(dashboardRuleRequest: DashboardRule): Observable<any> {
        return this.httpClient.post<any>(`${this.path}/dashboard`, dashboardRuleRequest);
    }
    updateDashboard(dashboardRuleRequest: DashboardRule): Observable<any> {
        return this.httpClient.put<any>(`${this.path}/dashboard/${dashboardRuleRequest.id}`, dashboardRuleRequest);
    }

    deleteDashboard(dashboardId): Observable<any> {
        return this.httpClient.delete<any>(`${this.path}/dashboard/${dashboardId}`);
    }

    getAllDashboards(inputs: Map<string, string>, queryParams?: PageSearchRequest): Observable<Page<any>> {
        const configurationItemCode = 'SAV.RULE.DASHBOARD';
        const requestParam = this.buildRequestParamFromInputs(inputs);
        return this.httpClient.get<Page<any>>(this.path + '/dashboard/search/filter/' + configurationItemCode + `?${requestParam}`, {params: <any>queryParams});
    }


    private buildRequestParamFromInputs(inputs: Map<string, string>): any {
        let requestParam = '';
        inputs.forEach((value: string, key: string) => {
            if (!value || value === '*') {
                requestParam += `${key}=any&`;
            } else {
                requestParam += `${key}=${value}&`;
            }
        });
        requestParam = requestParam.substring(0, requestParam.length - 1);
        return requestParam;
    }
    /**
     * Build dashboard Items based on chart and chartRequest from the parent component
     * @param data
     */
    buildDashboardItems(data): DashboardItem[] {
        const dashboardItems: DashboardItem[] = [];
        const chartRequestArray = data.chartRequest.charts;
        for (const [key, value] of data.chart) {
            for (const [k, v] of Object.entries(value)) {
                // find the widget and start building the dashboard item
                // @ts-ignore
                const widget = chartRequestArray.find(x => x.name === v.name);
                const dashboardItem: DashboardItem = {line: key, position: Number(k) + 1, code: widget.code, columnCount: widget.columnCount};
                dashboardItems.push(dashboardItem);
            }
        }
        return dashboardItems
    }
    showSuccessSave() {
        this.snackBar.openAtCenter('Success', 'DASHBOARD.ITEMS.SUCCESS_CONFIRMATION');
    }
    showError() {
        this.snackBar.openAtCenter('Error', 'DASHBOARD.ITEMS.ERROR_CONFIRMATION');
    }
    importDashboard(file: any): Observable<any>  {
        const fileUploaded = new File(file, file[0].name, {type: file.type, lastModified: Date.now()});
        const formData = new FormData();
        formData.append('file', fileUploaded);
        return this.httpClient.post<any>(`${this.path}/dashboard/upload`, formData);

    }

    exportDashboard(id: string): Observable<any>  {
        return this.httpClient.get(this.path + '/dashboard/export/' + id, {
            responseType: 'blob'
        });
    }
}
