import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Folder} from '../../models/folder.model';

@Injectable({
    providedIn: 'root'
})
export class FolderSubjectService {
    private folder = new ReplaySubject<Folder>(1);
    public folder$ = this.folder.asObservable();
    private refreshWorkflowStatus = new Subject();
    public refreshWorkflowStatus$ = this.refreshWorkflowStatus.asObservable();

    folderLoaded(folder: Folder): void {
        this.folder.next(folder);
    }

    folderAsObservable(): Observable<Folder> {
        return this.folder$;
    }

    refreshWorkflowStatusLoaded(folder: Folder): void {
        this.refreshWorkflowStatus.next(folder);
    }

    clearFolder(): void {
        this.folder.next(null);
    }

}
