<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="text-bold">
        {{'NAV.CONFIGURATIONS.SITE' | translate}}</h2>
    <button mat-icon-button class="ml-40" (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <table mat-table [dataSource]="dataSourceSite" class="mat-elevation-z4 w-100-p mt-16 mb-16">
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef class="table-header">{{'MODAL.SITE.CODE' | translate}}</th>
            <td mat-cell *matCellDef="let site" > {{site.code}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="table-header">
                {{'MODAL.SITE.NAME' | translate}}</th>
            <td mat-cell
                *matCellDef="let site">{{site.name}}
            </td>
        </ng-container>
        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef class="table-header">
                {{'MODAL.SITE.ADDRESS' | translate}}
            </th>
            <td mat-cell
                *matCellDef="let site">
                <ng-container *ngIf="!!site.address">
                    {{site.address.address ? site.address.address + ' - '  : ''}}
                    {{site.address.city ? site.address.city + ' - ' : ''}}
                    {{site.address.zipCode ? site.address.zipCode : ''}}
                </ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef
                class="table-header">  {{'MODAL.SITE.TYPE' | translate}}</th>
            <td mat-cell *matCellDef="let site"> {{'NAV.CONFIGURATIONS.SITE.' + site.type | translate}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</mat-dialog-content>


