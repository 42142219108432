<ng-container *ngIf="showCustomerForm">
    <div *ngIf="customers?.items?.length > 0"
         fxLayout="column"
         fxLayoutAlign="start stretch">
        <button type="button" mat-stroked-button color="accent" (click)="backToCustomerList()"
                class="py-12 px-24 mb-8 mr-8 font-weight-900 font-size-30">
            {{'FOLDER.CREATE.BACK_CLIENT_LIST_BUTTON' | translate}}
        </button>
    </div>

    <ng-container>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <ng-container *ngIf="enableClaimWithoutCustomer$ | async">
                <mat-checkbox
                    [disabled]="(isStockClaimCreation$ | async)"
                    (change)="setCreationWithoutCustomer($event)">
                    {{'FOLDER.CREATE.NO_CUSTOMER' | translate}}
                </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="enableStockClaim$ | async">
                <mat-checkbox
                    [disabled]="(isClaimWithoutCustomer$ | async)"
                    (change)="seStockClaimCreation($event)">
                    {{'FOLDER.CREATE.STORE' | translate}}
                </mat-checkbox>
            </ng-container>
        </div>
    </ng-container>
</ng-container>

<app-customer-form [hidden]="!showCustomerForm"></app-customer-form>

<customer-search-list *ngIf="!showCustomerForm && customers?.items?.length > 0"
                      (showCustomerForm)="showCustomerForm = true">
</customer-search-list>

