import {Component, OnInit} from '@angular/core';
import {SingleUploadModalComponent} from '../../single-upload-modal/single-upload-modal.component';
import {Folder} from '../../../../models/folder.model';
import {NgxImageCompressService} from 'ngx-image-compress';
import {FolderService} from '../../../../shared/services/folder.service';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {FileInfo} from '../../../../models/file-info.model';
import {FolderSubjectService} from '../../folder-subject.service';
import {Observable} from 'rxjs';
import {FileService} from '../../../../shared/services/file.service';
import {FormControl, FormGroup} from '@angular/forms';
import {FuseConfirmDialogComponent} from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {organizationCode} from '../../../../store/user/user.selectors';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../unsubscriber';
import {BackOfficeService} from '../../../../shared/services/back-office.service';
import {ConfigExtraCodesEnum} from '../../../../models/codeLabelObjectWithExtra.model';
import {AttachmentTypeSharedWithEnum} from '../../../../models/attachment-type.model';
import {FilesUtils} from '../../../../shared/utils/files-utils';


@Component({
    selector: 'app-folder-files-card',
    templateUrl: './folder-files-card.component.html',
    styleUrls: ['./folder-files-card.component.scss']
})
export class FolderFilesCardComponent extends Unsubscriber implements OnInit {

    folder: Folder;
    folderAttachmentDataSource: any = {
        dataSource: new MatTableDataSource<FileInfo>([]),
        displayedColumns: ['status', 'date', 'attachmentType', 'action'],
        filterForm: null,
    };
    fileName: string;
    organizationCode: string;
    attachmentsTypes: any = [];
    currentLanguage$: Observable<string>;

    constructor(private matDialog: MatDialog,
                private translateService: TranslateService,
                private imageCompress: NgxImageCompressService,
                private folderService: FolderService,
                private fileService: FileService,
                private backOfficeService: BackOfficeService,
                private folderSubjectService: FolderSubjectService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(res => {
            this.folder = res;
            this.folderAttachmentDataSource.dataSource.data = this.folder.attachments.filter(it => it.id != null);
            this.folderAttachmentDataSource.dataSource.connect().next(this.folderAttachmentDataSource.dataSource.data);
        });
        this.anotherSubscription = this.store$.pipe(select(organizationCode)).subscribe(organizationCode => {
            this.organizationCode = organizationCode;
        });
        this.currentLanguage$.subscribe(language => {
            this.getAttachmentTypes(language);
        });
        this.initAttachmentForm();
    }

    getAttachmentTypes(language: string): void {
        const inputs = new Map();
        inputs.set('locale', language);
        this.backOfficeService.bulkSearchExtraCodeLabelObjects(ConfigExtraCodesEnum.ATTACHMENT_TYPE, inputs, {page: 0, size: 1000})
            .then(page => {
                this.attachmentsTypes = page.content;
            });
    }

    getSharedWithTooltip(attachmentTypeCode: string): string {
        const attachmentType = this.attachmentsTypes.find(item => item.code === attachmentTypeCode);
        if (attachmentType && attachmentType.extra && attachmentType.extra.sharedWith) {
            const sharedWithList = attachmentType.extra.sharedWith;

            return this.buildSharedWithList(sharedWithList);
        }
        return '';
    }

    private buildSharedWithList(sharedWithList: string[]): string {
        if (sharedWithList.length === 0) {
            return '';
        } else if (sharedWithList.length === Object.keys(AttachmentTypeSharedWithEnum).length) {
            return this.translateService.instant('CONFIGURATION.GRID.HEADER.SHARED_WITH_ALL');
        } else {
            return this.translateService.instant('CONFIGURATION.GRID.HEADER.SHARED_WITH') + ' ' +
                this.getTranslatedSharedWithList(sharedWithList).join(', ');
        }
    }

    private getTranslatedSharedWithList(sharedWithList: string[]) {
        return sharedWithList.map(sharedWithItem =>
            this.translateService.instant('SHARED_WITH_' + sharedWithItem));
    }

    filterAttachmentByTypeSelected(type): any {
        return this.folder.attachments.filter(attachment => attachment?.attachmentType === type);
    }

    initAttachmentForm(): void {
        this.folderAttachmentDataSource.filterForm = new FormGroup({
            type: new FormControl(null),
        });
    }

    onUploadImage(): void {
        const dialogRef = this.matDialog.open(SingleUploadModalComponent, {
            disableClose: true,
            data: {
                attachmentTypes: this.attachmentsTypes
            }
        });

        dialogRef.afterClosed().subscribe(
            data => {
                if (!!data) {
                    this.onFileChange(data);

                }
            }
        );
    }

    private onFileChange(event): void {
        const reader = new FileReader();
        const fileEvent = event[0];
        if (event && event.length) {
            const [file] = event;
            this.fileName = file.name;
            reader.readAsDataURL(file);

            reader.onload = () => {
                if (
                    file.type
                        .toLocaleString()
                        .toLowerCase()
                        .split('/')[0] === 'image'
                ) {
                    this.compressFile(reader.result, fileEvent);
                } else {
                    this.uploadFile(reader.result.toString().split(',')[1], fileEvent);
                }
            };
            event = null;
        }
    }

    compressFile(image, event): void {
        this.imageCompress.compressFile(image, 1, 100, 50).then(result => {
            this.uploadFile(result.toString().split(',')[1], event);
        }).catch(err => {
        });
    }

    uploadFile(file, event): void {
        const imageBlob = this.dataURItoBlob(file);
        this.fileService.uploadAttachment(imageBlob, event, this.organizationCode, this.folder.id,
            event.attachmentType).subscribe(data => {
            if (data) {
                const folderUpdateRequest = {
                    attachment: data
                };
                this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(() => {
                    this.folder.attachments.push(data);
                    this.folderAttachmentDataSource.dataSource.data = this.folder.attachments;
                    this.folderSubjectService.folderLoaded(this.folder);
                });
            }
        }, (res) => {
        });
    }

    private dataURItoBlob(dataURI): any {
        const byteString = atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], {type: ''});
        return blob;
    }

    removeFile(fileId): void {
        const dialogRef = this.matDialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });

        dialogRef.componentInstance.title = this.translateService.instant('CONFIRMATION.MODAL.DELETE_DIALOG.TITLE');
        dialogRef.componentInstance.message = this.translateService.instant('FOLDER.FILE.DELETE_DIALOG.MESSAGE');
        dialogRef.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.DELETE');
        dialogRef.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialogRef.afterClosed().subscribe(deleteFile => {
            if (deleteFile) {
                this.folder.attachments = this.folder.attachments.filter(attachment => attachment.id !== fileId);
                this.folderService.deleteAttachmentFileFolder(this.folder.id, fileId).then(resFile => {
                    this.folderAttachmentDataSource.dataSource.data = this.folder.attachments;
                    this.folderSubjectService.folderLoaded(this.folder);
                }).catch(reason => {
                });
            }
        });

    }

    openAttachmentFile(fileId): void {
        this.folderService.getAttachmentFileFolder(fileId)
            .subscribe(resFile => {
                const fileURL = window.URL.createObjectURL(resFile);
                window.open(fileURL, '_blank', 'noopener');
            });
    }
    downloadAttachmentFile(fileId, fileName): void {
        this.folderService.getAttachmentFileFolder(fileId)
            .subscribe(resFile => {
                const reader = new FileReader();
                reader.readAsDataURL(resFile);
                reader.onloadend = () => {
                    FilesUtils.saveFile(reader.result, fileName, resFile.type);
                };
            });
    }

    changeTypeSelected(value): void {
        this.folderAttachmentDataSource.filterForm.get(['type']).setValue(value);
        if (!!this.folderAttachmentDataSource.filterForm && !!this.folderAttachmentDataSource.filterForm.get(['type'])
            && !!this.folderAttachmentDataSource.filterForm.get(['type']).value
            && this.folderAttachmentDataSource.filterForm.get(['type']).value !== 'ALL_TYPE_ATTACHMENT') {
            this.folderAttachmentDataSource.dataSource.data =
                this.filterAttachmentByTypeSelected(this.folderAttachmentDataSource.filterForm.get(['type']).value);
        } else {
            this.folderAttachmentDataSource.dataSource.data = this.folder.attachments;
        }
        this.folderAttachmentDataSource.dataSource.connect().next(this.folderAttachmentDataSource.dataSource.data);
    }


}
