import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DeliveryModeEnum} from '../../../../../models/enums/deliveryMode.enum';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-product-at-store',
    templateUrl: './product-at-store.component.html',
    styleUrls: ['./product-at-store.component.scss']
})
export class ProductAtStoreComponent implements OnInit {
    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    productAtStore: {
        placeholders: any;
        form: FormGroup;

    };

    constructor() {
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.productAtStore = {
            form: new FormGroup({
                choice: new FormControl(null, Validators.required),
            }),
            placeholders: [
                {
                    choice: 'PRODUCT_REPAIRED_IN_STORE',
                    value: 'BUTTON.PRODUCT_REPAIRED_IN_STORE',
                },
                {
                    choice: DeliveryModeEnum.STORE_TO_REPAIRER,
                    value: 'BUTTON.SEND_PRODUCT_TO_REPAIRER',
                }
            ]
        };
    }

    onSubmit(): void {
        this.inputMap.emit({
            deliveryMode: this.productAtStore.form.value.choice,
        });
    }
}
