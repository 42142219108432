export enum OperationModeEnum {
    SWAP = 'SWAP',
    HOME_REPAIR = 'HOME_REPAIR',
    SERVICE_CENTER_REPAIR = 'SERVICE_CENTER_REPAIR',
    AUTO_REPAIR = 'AUTO_REPAIR',
    NO_MODOP = 'NO_MODOP',
    SPARE_PARTS_ORDER = 'SPARE_PARTS_ORDER',
    VISIO = 'VISIO',
    REFUND = 'REFUND',
    PRE_DIAGNOSTIC = 'PRE_DIAGNOSTIC',
    MANUAL_PROCESSING = 'MANUAL_PROCESSING',
    INTERNAL_REPARATION = 'INTERNAL_REPARATION'
}

export enum CustomerChoiceEnum {
    SWAP = 'SWAP',
    REPAIR = 'REPAIR',
    REFUND = 'REFUND',
    DECREASED_PRICE = 'DECREASED_PRICE'
}

