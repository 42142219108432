import {Injectable} from '@angular/core';
import {Context, GrowthBook} from '@growthbook/growthbook';
import {environment} from '../../../environments/environment';
import {GrowthbookAttributes} from '../features/growthbook-attributes';

@Injectable({
    providedIn: 'root'
})
export class GrowthbookService {

    constructor() {
    }

    async getFeatureValue(featureKey: string, attributes: GrowthbookAttributes, defaultValue: any | any[]): Promise<any> {
        const context: Context = {
            apiHost: environment.growthBook.apiHost,
            clientKey: environment.growthBook.clientKey,
            enableDevMode: environment.growthBook.devMode,
            subscribeToChanges: true,
            attributes: attributes
        };

        const growthbook = new GrowthBook(context);
        await growthbook.loadFeatures();
        return growthbook.getFeatureValue(featureKey, defaultValue);
    }

    async isOn(featureKey: string, attributes: GrowthbookAttributes): Promise<boolean> {
        const context: Context = {
            apiHost: environment.growthBook.apiHost,
            clientKey: environment.growthBook.clientKey,
            enableDevMode: environment.growthBook.devMode,
            subscribeToChanges: true,
            attributes: attributes
        };

        const growthbook = new GrowthBook(context);
        await growthbook.loadFeatures();
        return growthbook.isOn(featureKey);
    }

}
