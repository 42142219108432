export enum StatusEnum {
    FOLDER_IN_PROGRESS = 'FOLDER_IN_PROGRESS', // Dossier en cours : lors de l’entré en workflow. On va mettre avec le statut du workflow.
    FOLDER_CLOSED_FINISHED = 'FOLDER_CLOSED_FINISHED', // Dossier clôturé
    FOLDER_CLOSED_SURVEY_RESOLVED = 'FOLDER_CLOSED_SURVEY_RESOLVED', // Survey résolu: le diagnostique est résolue
    FOLDER_CLOSED_PAYMENT_REFUSED = 'FOLDER_CLOSED_PAYMENT_REFUSED', // paiement refusé
    FOLDER_CLOSED_CANCEL_INTERVENTION = 'FOLDER_CLOSED_CANCEL_INTERVENTION', // Annulation de l'intervention
    FOLDER_CLOSED_CANCEL_DEPOSIT_PRODUCT = 'FOLDER_CLOSED_CANCEL_DEPOSIT_PRODUCT',
    FOLDER_CLOSED_TIME_EXPIRED_CHOOSE_WISHES_APPOINTMENT = 'FOLDER_CLOSED_TIME_EXPIRED_CHOOSE_WISHES_APPOINTMENT',
    FOLDER_CLOSED_TIME_EXPIRED_TO_GET_SYMPTOMS = 'FOLDER_CLOSED_TIME_EXPIRED_TO_GET_SYMPTOMS',
    FOLDER_CLOSED_TIME_EXPIRED_DOES_NOT_PAY = 'FOLDER_CLOSED_TIME_EXPIRED_DOES_NOT_PAY',
    FOLDER_CLOSED_TIME_EXPIRED_DOES_NOT_COMPLETE_DIAGNOSIS = 'FOLDER_CLOSED_TIME_EXPIRED_DOES_NOT_COMPLETE_DIAGNOSIS',
    FOLDER_CLOSED_IN_STORE_PRODUCT_HAS_NO_DAMAGE = 'FOLDER_CLOSED_IN_STORE_PRODUCT_HAS_NO_DAMAGE',
    FOLDER_CLOSED_ABORTED = 'FOLDER_CLOSED_ABORTED',
    FOLDER_CREATED = 'FOLDER_CREATED',
    FOLDER_CLOSED_BY_DIAGNOSTIC = 'FOLDER_CLOSED_BY_DIAGNOSTIC'
}
