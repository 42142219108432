import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {TaskVariables} from '../../task.variables';
import {DiagExpertDecision} from '../model/diag-expert.model';
import {CommentType} from '../../../../../models/enums/CommentType.enum';
import {BackOfficeService} from '../../../../../shared/services/back-office.service';
import {WarrantyReasonEnum} from '../../../../../models/enums/warrantyReason.enum';
import {getModOpIconNameByCode, noModeOp} from '../../../../../shared/data/static.config';
import {RuleEvaluationContext} from '../../../../../models/rules/RuleEvaluationContext';
import {RegimeWarrantyEnum} from '../../../../../models/warrantyRule.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-diag-expert-decision-confirmation',
    templateUrl: './diag-expert-decision-confirmation.component.html',
    styleUrls: ['./diag-expert-decision-confirmation.component.scss']
})
export class DiagExpertDecisionConfirmationComponent implements OnInit {
    @Input() variablesTask: any;
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    diagExpertDecision: DiagExpertDecision;
    diagExpertComment: string
    warrantyDisclaimerModOp: string[];
    selectedWarrantyDisclaimerModOp: string;

    constructor(private backOfficeService: BackOfficeService) {
    }

    ngOnInit(): void {
        this.diagExpertDecision = this.variablesTask[TaskVariables.expertDiagnosticDecision];
        this.diagExpertComment = this.getDiagExpertComment();
        if (this.diagExpertDecision === DiagExpertDecision.WARRANTY_DISCLAIMER) {
            this.computeModOpAfterWarrantyDisclaimer();
        }
    }

    private getDiagExpertComment() {
        return !!this.folder.repairerComments &&
        !!this.folder.repairerComments[CommentType.COMMENT_DIAG_EXPERT] &&
        this.folder.repairerComments[CommentType.COMMENT_DIAG_EXPERT].length > 0 ?
            this.folder.repairerComments[CommentType.COMMENT_DIAG_EXPERT][0].content : '';
    }

    onSubmit() {
        this.inputMap.emit({
            resendToExpert: false,
            selectedWarrantyDisclaimerModOp: this.selectedWarrantyDisclaimerModOp
        });
    }

    resendToExpert(): void {
        this.inputMap.emit({
            resendToExpert: true
        });
    }

    private computeModOpAfterWarrantyDisclaimer(): void {
        const ruleEvaluationContext = RuleEvaluationContext.fromFolder(this.folder);
        ruleEvaluationContext.warrantyCode = RegimeWarrantyEnum.HG;
        ruleEvaluationContext.warrantyReasonEnum = WarrantyReasonEnum.HG_CUSTOMER_DEFAULT;
        this.backOfficeService.getEligibleOperatingMode(ruleEvaluationContext).subscribe(data => {
            if (data.length > 0) {
                this.selectedWarrantyDisclaimerModOp = data[0].modOp;
                this.warrantyDisclaimerModOp = data.map(e => e.modOp);
            } else {
                this.warrantyDisclaimerModOp = [noModeOp.code];
                this.selectedWarrantyDisclaimerModOp = noModeOp.code;
            }
        });
    }

    isWarrantyDisclaimer(): boolean {
        return this.diagExpertDecision === DiagExpertDecision.WARRANTY_DISCLAIMER
    }

    getIconName(value: any) {
        return getModOpIconNameByCode(value);
    }

}
