import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {Product} from '../../../../../models/product.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'product-check-customer',
    templateUrl: './product-check-customer.component.html',
    styleUrls: ['./product-check-customer.component.scss']
})
export class ProductCheckCustomerComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Output() previewTask = new EventEmitter<any>();

    @Input() folder: Folder;
    @Input() product: Product;
    @Input() workflowStatus: string;
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;

    positionStep: number;
    productAtCustomerForm: any = {
        form: null,
        placeholders: []
    };

    constructor() {
        this.positionStep = 1;
    }

    ngOnInit(): void {
        this.initQuotationChoseForm();
    }

    private initQuotationChoseForm(): void {
        this.productAtCustomerForm.form = new FormGroup({
            productAtCustomer: new FormControl(null, Validators.required)
        });
        this.productAtCustomerForm.placeholders = [
            {
                productAtCustomer: true,
                value: 'COMPONENT.PRODUCT_CHECK_AT_CUSTOMER.TRUE',
            },
            {
                productAtCustomer: false,
                value: 'COMPONENT.PRODUCT_CHECK_AT_CUSTOMER.FALSE',
            }
        ];
    }

    completeTask(): void {
        this.inputMap.emit({
            'productAtCustomer': this.productAtCustomerForm.form.value.productAtCustomer
        });
    }


    getPreviewTask(): void {
        this.previewTask.emit({
            'currentTask': 'PRODUCT_CHECK_AT_CUSTOMER',
            'previewsTask': 'CUSTOMER_DID_NOT_RESPOND'
        });
    }

}
