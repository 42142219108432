import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import {addDays, endOfWeek, startOfDay, startOfWeek} from 'date-fns';
import {MatTableDataSource} from '@angular/material/table';
import {startWith} from 'rxjs/operators';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {AppointmentService} from '../../../../shared/services/appointment/appointment.service';
import {ConfigurationReferential} from '../../../../models/configurationReferential.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {organizationCode} from '../../../../store/user/user.selectors';
import {filteredSite} from '../../../../store/configuration/configuration.selectors';
import {Unsubscriber} from '../../../../unsubscriber';


registerLocaleData(localeFr);

export const DD_MM_YYYY_Format = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-planning',
    templateUrl: './planning.component.html',
    styleUrls: ['./planning.component.scss'],
    providers: [
        {provide: MAT_DATE_FORMATS, useValue: DD_MM_YYYY_Format},
    ]
})
export class PlanningComponent extends Unsubscriber implements OnInit {

    viewDate: Date;
    view: string;
    selectedDay: any;
    startWeek: number;

    mindate: Date;
    showTable: boolean;
    dateFormat = 'yyyy-MM-dd';
    mode = 'determinate';
    private organizationCode: string;
    private all = 'ALL';
    filteredSiteList$: Observable<ConfigurationReferential[]>;

    dashboardDataSource: any = {
        dataSource: new MatTableDataSource(),
        columnsToDisplay: ['technician', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
        filterForm: null,
    };


    constructor(private appointmentService: AppointmentService,
                private router: Router,
                private datePipe: DatePipe,
                private store$: Store<AppState>) {
        super();
        this.showTable = false;
        this.view = 'week';
        this.startWeek = 1; // start on Monday
        this.viewDate = new Date();
        this.selectedDay = {date: startOfDay(new Date())};
    }


    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(organizationCode)).subscribe(currentOrganizationCode => {
            this.organizationCode = currentOrganizationCode;
            this.initDashBoardDataSource();
            this.initFilterForm();
        });

        this.anotherSubscription = this.dashboardDataSource.filterForm.get('siteQuery').valueChanges
            .pipe(startWith(null))
            .subscribe(input => {
                this.initDashBoardDataSource();
            });
    }

    initFilterForm(): void {
        this.dashboardDataSource.filterForm = new FormGroup({
            selectedDate: new FormControl(new Date(), [Validators.required]),
            siteQuery: new FormControl(''),
            siteCodeCtrl: new FormControl(''),

        });
        this.filterSite();
        this.dashboardDataSource.filterForm.get(['selectedDate']).setValue(this.viewDate);
        this.dashboardDataSource.filterForm.get(['siteQuery']).setValue(this.getSiteQuery());
    }

    filterSite(): void {
        this.anotherSubscription = this.dashboardDataSource.filterForm.get(['siteCodeCtrl']).valueChanges
            .pipe(startWith(null))
            .subscribe(input => {
                this.filteredSiteList$ = this.store$.pipe(select(filteredSite, {currentSite: input}));
            });
    }


    toggleWeek(): void {
        this.router.navigate([], {
            queryParamsHandling: 'merge',
        }).finally(() => {
            this.initDashBoardDataSource();
        });
    }

    onChangeDatePicker(): void {
        this.viewDate = this.dashboardDataSource.filterForm.get(['selectedDate']).value._d;
        this.router.navigate([], {

            queryParamsHandling: 'merge',
        }).finally(() => {
            this.initDashBoardDataSource();
        });
    }


    // Tobe use later to open a day details
    openDashboardDay(element, details): void {
        this.router.navigate(['itinerant/day/' + element.technicianId], {
            queryParams: {
                date: details.date,
                site: this.getSiteQuery() ? this.getSiteQuery() : this.all
            }
        });
    }


    initDashBoardDataSource(): void {
        const beginWeek = this.datePipe.transform(startOfWeek(this.viewDate, {weekStartsOn: 1}), this.dateFormat);
        const endWeek = this.datePipe.transform(endOfWeek(this.viewDate, {weekStartsOn: 1}), this.dateFormat);
        const inputs = new Map();
        inputs.set('organizationCode', this.organizationCode);

        inputs.set('beginDate', beginWeek);
        inputs.set('endDate', endWeek);
        if (this.getSiteQuery()) {
            inputs.set('site', this.getSiteQuery());
        } else {
            inputs.set('site', this.all);
        }

        this.store$.dispatch(new StartLoading());
        this.appointmentService.getDashBoardWeek(inputs)
            .subscribe(
                (response) => {
                    this.showTable = true;
                    if (response) {
                        this.dashboardDataSource.data = [];
                        this.dashboardDataSource.data = response.filter(tech => tech.technicianId != null);
                        this.store$.dispatch(new StopLoading());
                    }
                }, () => this.store$.dispatch(new StopLoading()));
    }

    onSearch(): void {
        this.initDashBoardDataSource();
    }

    dayOfWeek(number) {
        return this.datePipe.transform(addDays(startOfWeek(this.viewDate, {weekStartsOn: 1}), number), 'dd');
    }


    codeColor(percent): any {
        if (percent === 0) {
            return 'custom-spinner-white';
        } else {
            return 'custom-spinner-blue';
        }

    }

    getSiteQuery(): string {
        if (!!this.dashboardDataSource.filterForm) {
            const codeQuery = this.dashboardDataSource.filterForm.get(['siteQuery']).value;
            return !!codeQuery ? codeQuery : '';
        }
        return '';
    }


}
