import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {CampaignService} from '../../../../../shared/services/campaign.service';
import {Context} from '../../../../../models/enums/context.enum';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {currentLanguage} from '../../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../../unsubscriber';
import {TaskVariables} from '../../task.variables';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-for-hs-product-destruction-by-customer',
    templateUrl: './waiting-for-hs-product-destruction-by-customer.component.html',
    styleUrls: ['./waiting-for-hs-product-destruction-by-customer.component.css']
})
export class WaitingForHsProductDestructionByCustomerComponent extends Unsubscriber implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() workflowStatus: string;

    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();

    validateShipmentSent: false;
    descriptionText: string;
    image: string;
    languageCode: string;

    constructor(private campaignService: CampaignService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(currentLanguage))
            .subscribe(language => this.languageCode = language);
        if (this.isProductRecallContext()) {
            this.getConfigCampaign();
        }
    }

    getConfigCampaign(): void {
        this.campaignService.getConfigValueCampaign(this.folder.id).subscribe(value => {
            this.descriptionText = this.campaignService.getWorkflowText(value, this.languageCode, this.workflowStatus);
            this.image = !!value.media['images'] ? value.media['images']['WAITING_FOR_HS_PRODUCT_DESTRUCTION_BY_CUSTOMER'] : '';
        });
    }

    onSubmit(): void {
        if (!this.validateShipmentSent) {
            return;
        }
        this.inputMap.emit({'swapOption': this.getSwapOption()});
    }

    private isProductRecallContext(): boolean {
        return this.folder.context === Context.PRODUCT_RECALL.toString();
    }

    private getSwapOption() {
        return  this.variablesTask[TaskVariables.swapOption] || 'EXCHANGE_IN_STORE';
    }
}
