import {Component, Inject, Input, OnInit} from '@angular/core';
import {FolderPublic} from '../../../../models/folder.public.model';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import {Moment} from 'moment';
import {ShowImageModalComponent} from '../../../image-modal/show-image-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {of, Subject} from 'rxjs';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {WARRANTY_CONFIGS} from '../../../../shared/data/static.config';
import {ConfigurationReferentialDto} from '../../../../models/configurationReferentialDto.model';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {PublicService} from '../../../../shared/services/public.service';
import {ConfigurationReferential} from '../../../../models/configurationReferential.model';
import {ConfigCodeMapsEnum} from '../../../../shared/services/configuration-item-enum';
import {Unsubscriber} from '../../../../unsubscriber';
import {currentLanguage} from 'app/store/organization/organization.selectors';
import {FileInfo} from '../../../../models/file-info.model';
import {AttachmentType, AttachmentTypeSharedWithEnum} from '../../../../models/attachment-type.model';

@Component({
    selector: 'app-public-supplier-header',
    templateUrl: './public-supplier-header.component.html',
    styleUrls: ['./public-supplier-header.component.scss']
})
export class PublicSupplierHeaderComponent extends Unsubscriber implements OnInit {
    protected _onDestroy = new Subject<void>();

    @Input() folder: FolderPublic;

    warranty;
    currentLanguage: string;
    listWarranty: any = WARRANTY_CONFIGS;

    configRefDto: ConfigurationReferentialDto;
    symptom: string;
    condition = '';
    warrantyFolder: string;

    attachments: FileInfo[] = [];
    displayedColumns: string[] = ['file', 'date', 'attachmentType'];
    attachmentsTypes: AttachmentType[] = [];


    constructor(private dialog: MatDialog,
                private publicService: PublicService,
                private translateService: TranslateService,
                @Inject(LOCAL_STORAGE) private localStorage: StorageService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(currentLanguage))
            .subscribe(language => {
                this.currentLanguage = language;
                this.getConfigurationReferentialDto();
                this.getAllowedListAttachmentType(AttachmentTypeSharedWithEnum.SUPPLIER, this.folder.externalUid);
            });
    }
    getAllowedListAttachmentType(sharedWith: string, uid: string): void {
        this.publicService.getAllowedListAttachmentTypes(sharedWith, uid, this.currentLanguage).subscribe(data => {
            this.attachmentsTypes = data;
            const attachmentTypeCodes = data.map(value => value.code);
            this.attachments = this.folder.attachments.filter(fileInfo => attachmentTypeCodes.includes(fileInfo.attachmentType));
        });
    }

    getConfigurationReferentialDto(): void {
        this.store$.dispatch(new StartLoading());
        this.publicService.getConfigurationReferential(this.folder.externalUid, this.currentLanguage)
            .subscribe(value => {
                if (!!value) {
                    this.configRefDto = value;
                    this.initConfig();
                }
                this.localStorage.set('publicConfiguration', value);
                this.store$.dispatch(new StopLoading());
            }, (error) => {
                this.store$.dispatch(new StopLoading());
            });
    }

    private initConfig() {
        this.initFaultCode();
        this.initCondition();
        this.initWarranty();
    }

    public getAllConfiguration(configurationItemCode: string): ConfigurationReferential[] {
        if (!!this.configRefDto) {
            return !!this.configRefDto.configurationMap[configurationItemCode] &&
            this.configRefDto.configurationMap[configurationItemCode].length > 0 ?
                this.configRefDto.configurationMap[configurationItemCode] : [];
        } else {
            return [];
        }
    }

    private initFaultCode() {
        if (!!this.folder.faultCode && this.folder.faultCode !== 'CUSTOMER_NOT_FOUND_PANNE_CODE') {
            const symtoms = this.getAllConfiguration(ConfigCodeMapsEnum.SYMPTOMS)
                .filter(value => value.code === this.folder.faultCode);
            this.symptom = symtoms.length > 0 ? symtoms[0].label : '';
        } else {
            this.getDefaultSymptomFromFolder();
        }
    }

    private getDefaultSymptomFromFolder() {
        this.symptom = this.folder.faultCodeLabel;
    }

    initCondition(): void {
        if (!!this.folder.faultCodeCondition && this.folder.faultCodeCondition !== 'CONSTANT') {
            const conditions = this.getAllConfiguration(ConfigCodeMapsEnum.CONDITIONS)
                .filter(value => value.code === this.folder.faultCodeCondition);
            this.condition = conditions.length > 0 ? conditions[0].label : '';
        } else {
            this.getDefaultCondition();
        }
    }

    private getDefaultCondition() {
        this.condition = this.translateService.instant('COMPONENT.CONSTANT.CONDITION');
    }

    initWarranty(): void {
        this.warranty = this.listWarranty.filter(el => el.key === this.folder.warranty.warranty.toString())[0];
        const configCode = this.folder.warranty.warranty === 'SG' ? ConfigCodeMapsEnum.WARRANTY_REASON_SG : ConfigCodeMapsEnum.WARRANTY_REASON_HG;
        const warranties = this.getAllConfiguration(configCode)
            .filter(value => value.code === this.folder.warranty.warrantyReason);
        this.warrantyFolder = warranties.length > 0 ? warranties[0].label : '';
    }

    getDate(purchaseDate: string): Moment {
        return moment(purchaseDate);
    }

    showImageModal(srcImage): void {
        this.dialog.open(ShowImageModalComponent, {
            height: 'auto',
            width: '90vh',
            minWidth: '90%',
            data: of([srcImage])
        });
    }


}
