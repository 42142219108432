<img *ngIf="loading"
     [src]="loader"
     [ngClass]="loaderCss"
     alt="loading"
     class="rotate"/>
<img
    [hidden]="loading"
    [src]="!!image ? image : placeholder"
    [ngClass]="imageCss"
    (load)="onLoad()"
    (error)="onImageError($event)"
    alt="product image">
