    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start center">
            <div>
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>
        </div>

        <div class="mt-12">
            <mat-checkbox [(ngModel)]="repairInProgress">{{'BUTTON.WAITING_TO_REPAIR' | translate}}</mat-checkbox>
        </div>

        <div class="mt-24">
            <div fxLayout="row" fxLayoutAlign="end end">
                <div>
                    <button mat-stroked-button (click)="onSubmit()" type="submit"
                            [disabled]="!repairInProgress"
                            [ngClass]="!repairInProgress ? 'h-60 mt-4  aster_btn_disabled' : 'h-60 mt-4 aster_btn' ">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </mat-card-content>