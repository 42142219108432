
    <mat-card-content>
        <form fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <app-customer-product-retrieval-decision (retrieveProduct)="customerChoice($event)"
                                                     (choiceDone)="isChoiceDone($event)">
            </app-customer-product-retrieval-decision>
            <div fxLayoutAlign="end center" class="mt-24">
                <button type="submit" mat-stroked-button [disabled]="!choiceDone"
                        [ngClass]="choiceDone? 'aster_btn': 'aster_btn_disabled' ">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>