import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {InvoiceProducts, Item} from '../../../../../models/invoice.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {FolderCreationService} from '../../folder-creation.service';
import {GLOBAL} from '../../../../../app-config';
import {Product} from '../../../../../models/product.model';
import {BackOfficeService} from '../../../../../shared/services/back-office.service';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';

@Component({
    selector: 'app-product-form-child-table',
    templateUrl: './product-form-child-table.component.html',
    styleUrls: ['./product-form-child-table.component.scss']
})
export class ProductFormChildTableComponent implements OnInit {

    @Input() productsFirstPage: InvoiceProducts;
    @Input() invoiceId: string;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] = ['image', 'code', 'label', 'sellingPrice', 'quantity', 'action'];
    @Output() product = new EventEmitter<any>();
    pageConfig: InvoiceProducts;
    dataSource: MatTableDataSource<Item>;
    constructor(public folderCreationServices: FolderCreationService,
                private store$: Store<AppState>,
                private backOfficeService: BackOfficeService) {

    }
    pageIndex = 0;

    ngOnInit(): void {
        this.initializeDataSource(this.productsFirstPage);
    }

        private initializeDataSource(invoiceProductPage): void {
            this.pageConfig = invoiceProductPage;
            this.dataSource = new MatTableDataSource<Item>();
            this.dataSource.data = invoiceProductPage.content;
        }

    getImagePlaceholder(): string  {
        return GLOBAL.imagePlaceholder;
    }

    onProductSelect(product: Product): void {
        this.folderCreationServices.productFromInvoiceChanged(product);
        this.product.emit(product);
    }

    changePage($event: PageEvent): void {
        this.pageIndex = $event.pageIndex;
        this.store$.dispatch(new StartLoading());
        this.backOfficeService.searchInvoiceProducts(this.invoiceId,
            '' + $event.pageIndex, '' + $event.pageSize).subscribe(data => {
            this.pageConfig = data;
            this.initializeDataSource(data);
            this.store$.dispatch(new StopLoading());
        }, () => this.store$.dispatch(new StopLoading()));
    }
}

