<div class="e-card">
    <div class="e-card-header">
        <div class="e-card-header-caption">
            <div class="e-card-title">{{'PRODUCT.REPAIRABILITY' | translate}}</div>
        </div>
    </div>
    <div class="e-card-content" fxLayoutAlign="center center">
        <div fxFlex="50" [ngStyle]="{'color':'black'}">
            {{'PRODUCT.TITLE.REPAIRABLE' | translate}}
        </div>

        <div fxFlex>
            {{computeReparability() | translate }}
        </div>

        <ng-container *ngIf="hasRepairabilityInfo()">
            <div fxFlex="33">
                <img *ngIf="product.repairable; else UNREPAIRABLE"
                     class="repairability-icon"
                     [src]="REPAIRABLE_PRODUCT_ICON"
                     alt="product-repairable-icon">
                <ng-template #UNREPAIRABLE>
                    <img [src]="UNREPAIRABLE_PRODUCT_ICON"
                         class="repairability-icon"
                         alt="product-unrepairable-icon">
                </ng-template>
            </div>
        </ng-container>

    </div>
</div>