import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Folder, Payment, RefundRequest} from '../../../../models/folder.model';
import {Observable} from 'rxjs';
import {FolderSubjectService} from '../../folder-subject.service';
import {FuseConfirmDialogComponent} from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {BackOfficeService} from '../../../../shared/services/back-office.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {PaymentMode} from '../../../../models/enums/PaymentMode';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {currency, currentLanguage} from '../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../unsubscriber';
import {hasScope} from '../../../../store/user/user.selectors';
import {Scopes} from '../../../../models/scopes/scopes.model';

@Component({
    selector: 'app-folder-payment-card',
    templateUrl: './folder-payment-card.component.html',
    styleUrls: ['./folder-payment-card.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])
    ]
})
export class FolderPaymentCardComponent extends Unsubscriber implements OnInit {

    folder: Folder;
    displayedColumns: string[] = ['paymentReason', 'amount', 'refund'];
    dataSourcePayments = new MatTableDataSource<Payment>([]);
    expandedElement: Payment | null;

    currency$: Observable<string>;
    currentLanguage$: Observable<string>;

    constructor(public folderSubjectService: FolderSubjectService,
                private dialog: MatDialog,
                private backOfficeService: BackOfficeService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.currency$ = this.store$.pipe(select(currency));
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
                this.folder = folder;
                this.dataSourcePayments.data = this.folder.payments;
            }
        );
    }

    refund(payment: Payment) {
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });
        dialogRef.componentInstance.title = 'CONFIRMATION.MODAL.REFUND.TITLE';
        dialogRef.componentInstance.message = 'CONFIRMATION.MODAL.REFUND.FOLDER';
        dialogRef.componentInstance.confirmButtonLabel = 'CONFIRMATION.MODAL.REFUND.CONFIRM_BUTTON';
        dialogRef.componentInstance.cancelButtonLabel = 'CONFIRMATION.MODAL.REFUND.CANCEL_BUTTON';

        dialogRef.afterClosed().subscribe(validate => {
            if (validate) {
                const refundRequest: RefundRequest = {
                    folderId: this.folder.id,
                    paymentReference: payment.paymentReference,
                    refundAmount: {
                        value: payment.amount,
                        currency: payment.currency
                    },
                    refundReason: 'Refund :' + payment.paymentReason
                };
                this.store$.dispatch(new StartLoading());
                this.backOfficeService.refund(refundRequest).subscribe(folder => {
                    this.store$.dispatch(new StopLoading());
                    this.folderSubjectService.folderLoaded(folder);
                }, () => this.store$.dispatch(new StopLoading()));
            }
        });
    }

    isRefundEnabled(payment: Payment): boolean {
        return (!payment.refunds || payment.refunds.length === 0)
            && payment.paymentMode === PaymentMode.INTERNET;
    }

    isRefundCarriedOut(payment: Payment): boolean {
        return !!payment.refunds && payment.refunds.length > 0;
    }

    public hasScope = (scope) => this.store$.pipe(select(hasScope, {scope: scope}));
    protected readonly Scopes = Scopes;
}
