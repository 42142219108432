<div fxLayout="row" fxLayout.lt-lg="row" fxLayout.lt-md="column" fxLayoutGap="8px" fusePerfectScrollbar>
    <div fxFlex="50">
        <app-public-detail-tabs-card [folder]="folder"></app-public-detail-tabs-card>
    </div>
    <div fxFlex="50">
        <mat-card class="mat-elevation-aster">
            <mat-card-title class="product-title text-bold pr-24 pt-20 pl-10">
                {{'COMPONENT.WAITING_PICKUP' | translate}}
            </mat-card-title>

            <mat-card-content>
                <div class="mt-24">
                    <div>
                        <mat-checkbox [id]="'product_pickedUp'"
                                      [(ngModel)]="productPickedUp">{{'BUTTON.PRODUCT_PICKED_UP' | translate}}</mat-checkbox>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end">
                        <button mat-stroked-button (click)="onSubmit()"
                                type="submit"
                                [disabled]="!productPickedUp"
                                [ngClass]="!productPickedUp?'aster_btn_disabled':'aster_btn'">
                            {{'BUTTON.VALIDATE' | translate}}
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
