import {Action} from '@ngrx/store';
import {DashboardOptions} from '../../models/dashboard/dashboard-options.model';
import {Dashboard} from 'app/models/dashboard/dashboard.model';

export enum DashboardActionType {
    LOAD_DASHBOARD = '[DASHBOARD] Load Dashboard',
    UPDATE_DASHBOARD_LANGUAGE = '[DASHBOARD] Update Dashboard Language',
    UPDATE_DASHBOARD_NAVIGATION = '[DASHBOARD] Update Navigation',
    SET_DASHBOARDS = '[DASHBOARD] Set Dashboards',
    SAVE_DASHBOARD = '[DASHBOARD] Save Dashboard'
}

export class LoadDashboard implements Action {
    readonly type = DashboardActionType.LOAD_DASHBOARD;

    constructor(public options: DashboardOptions) {
    }
}

export class UpdateDashboardLanguage implements Action {
    readonly type = DashboardActionType.UPDATE_DASHBOARD_LANGUAGE;

    constructor(public language: string) {
    }
}

export class UpdateDashboardNavigation implements Action {
    readonly type = DashboardActionType.UPDATE_DASHBOARD_NAVIGATION;
}

export class SetDashboards implements Action {
    readonly type = DashboardActionType.SET_DASHBOARDS;

    constructor(public dashboards: Dashboard[]) {
    }
}

export class SaveDashboard implements Action {
    readonly type = DashboardActionType.SAVE_DASHBOARD;

    constructor(public dashboardId?: string) {
    }
}

export type DashboardActions =
    LoadDashboard | SetDashboards;
