<div fxLayout="row" fxLayout.lt-lg="row" fxLayout.lt-md="column" fxLayoutGap="8px" fusePerfectScrollbar>
    <div  fxFlex="50">
        <app-public-detail-tabs-card [folder]="folder"></app-public-detail-tabs-card>
    </div>
    <div fxFlex="50">
        <mat-card class="mat-elevation-aster">

                <mat-card-content *ngIf="form">
                    <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
                        <fieldset>
                            <legend class="h3 title-fieldset">{{'DIAGNOSTIC' | translate}}</legend>
                            <ng-container>
                                <mat-radio-group formControlName="status" class="ml-20" labelPosition="after" fxLayout="column"
                                                 fxLayoutAlign="start stretch" fxLayoutGap="10px">
                                    <mat-radio-button id="PERFORMED" class="full-width-radio"
                                                      value="PERFORMED">
                                        <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                            <div fxFlex>
                                                <div>{{ 'PERFORMED' | translate }}</div>
                                            </div>
                                        </div>
                                    </mat-radio-button>
                                    <mat-radio-button id="FIX_APPOINTMENT" class="full-width-radio"
                                                      value="FIX_APPOINTMENT">
                                        <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                            <div fxFlex>
                                                <div>{{ 'FIX_APPOINTMENT' | translate }}</div>
                                            </div>
                                        </div>
                                    </mat-radio-button>
                                    <ng-container *ngIf="showCalender">
                                        <mat-form-field appearance="outline" class="pr-sm-8"  [style.width.px]=327>
                                            <mat-label>{{'FOLDER.SCHEDULE.DAY_OF_INTERVENTION' | translate}}</mat-label>
                                            <input matInput [matDatepicker]="confirmedDay" [min]="minDate"
                                                   formControlName="confirmedAppointment">
                                            <mat-datepicker-toggle matSuffix
                                                                   [for]="confirmedDay"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi #confirmedDay></mat-datepicker>
                                        </mat-form-field>
                                    </ng-container>
                                    <mat-radio-button id="REPAIR_DECISION_CANCEL" class="full-width-radio"
                                                      value="REPAIR_DECISION_CANCEL">
                                        <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                            <div fxFlex>
                                                <div>{{ 'BUTTON.CANCEL_INTERVENTION' | translate }}</div>
                                            </div>
                                        </div>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </ng-container>
                        </fieldset>
                        <ng-container *ngIf="form.value.status === 'PERFORMED'">
                            <fieldset>
                                <legend
                                    class="h3 title-fieldset">{{'COMPONENT.DIAGNOSIS_IN_PROGRESS.BREAKDOWN' | translate}}</legend>
                                <ng-container>
                                    <mat-radio-group formControlName="breakDownStatus" class="ml-20" labelPosition="after"
                                                     fxLayout="column"
                                                     fxLayoutAlign="start stretch" fxLayoutGap="10px">
                                        <mat-radio-button [id]="button.status" class="full-width-radio"
                                                          *ngFor="let button of breakDownButtons"
                                                          [value]="button.status">
                                            <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                                <div fxFlex>
                                                    <div>{{ button.value | translate }}</div>
                                                </div>
                                            </div>
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </ng-container>
                            </fieldset>
                            <ng-container *ngIf="form.value.breakDownStatus === 'BREAKDOWN_CONFIRMED'">
                                <ng-container *ngIf="folder.warranty.warranty === 'SG'">
                                    <fieldset>
                                        <legend
                                            class="h3 title-fieldset">{{'COMPONENT.DIAGNOSIS_IN_PROGRESS.WARRANTY' | translate}}</legend>
                                        <ng-container>
                                            <mat-radio-group formControlName="warrantyStatus" class="ml-20"
                                                             labelPosition="after"
                                                             fxLayout="column"
                                                             fxLayoutAlign="start stretch" fxLayoutGap="10px">
                                                <mat-radio-button [id]="button.status" class="full-width-radio"
                                                                  *ngFor="let button of warrantyButtons"
                                                                  [value]="button.status">
                                                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center"
                                                         fxLayoutGap="30px">
                                                        <div fxFlex>
                                                            <div>{{ button.value | translate }}</div>
                                                        </div>
                                                    </div>
                                                </mat-radio-button>
                                            </mat-radio-group>

                                            <div *ngIf="form.value.warrantyStatus === 'WARRANTY_DISCLAIMER'" class="ml-20">
                                                <mat-form-field class="py-4 mt-16" fxFlex="50" appearance="outline">
                                                    <mat-label>{{'COMPONENT.DIAGNOSIS_IN_PROGRESS.WARRANTY_DISCLAIMER_REASON' | translate}}</mat-label>
                                                    <mat-select formControlName="warrantyDisclaimerOption">
                                                        <mat-option *ngFor="let option of warrantyDisclaimerOptions"
                                                                    [value]="option">
                                                            {{('COMPONENT.DIAGNOSIS_IN_PROGRESS.' + option) |translate}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </fieldset>
                                </ng-container>

                                <fieldset>
                                    <legend
                                        class="h3 title-fieldset">{{'COMPONENT.DIAGNOSIS_IN_PROGRESS.PRODUCT' | translate}}</legend>
                                    <ng-container>
                                        <mat-radio-group formControlName="productStatus" class="ml-20" labelPosition="after"
                                                         fxLayout="column"
                                                         fxLayoutAlign="start stretch" fxLayoutGap="10px">
                                            <mat-radio-button [id]="button.status" class="full-width-radio"
                                                              *ngFor="let button of productButtons"
                                                              [value]="button.status"
                                                              (change)="updateDiagnoticStatus(button.status)">
                                                <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                                    <div fxFlex>
                                                        <div>{{ button.value | translate }}</div>
                                                    </div>
                                                </div>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </ng-container>
                                </fieldset>
                            </ng-container>
                        </ng-container>
                        <app-comment-area [commentForm]="form.get('comment')"></app-comment-area>

                        <div fxLayoutAlign="end center" class="mt-24">
                            <button type="submit" mat-stroked-button [disabled]="form.invalid"
                                    [ngClass]="form.invalid ? 'aster_btn_disabled' : 'aster_btn'">
                                {{'BUTTON.VALIDATE' | translate}}
                            </button>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
    </div>
</div>














