<div class="page-layout blank p-24 w-100-p" fusePerfectScrollbar>
    <div gdAreas="schedules | infos" gdGap="10px" class='w-100-p'>
        <div gdArea="schedules">
            <div [ngClass]="viewDate | date:'MMM'" fxLayout="row" fxLayoutAlign="center"
                 class="mb-12 mt-4">

                <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">


                    <!-- HEADER BOTTOM -->
                    <div class="header-bottom" fxLayout="row" fxLayoutAlign="center center">

                        <button mat-icon-button class="arrow"
                                mwlCalendarPreviousView
                                [view]="view"
                                [(viewDate)]="viewDate"
                                (viewDateChange)="selectedDay = {date:$event}"
                                (click)="onChangeDate()"
                                aria-label="Previous">
                            <mat-icon>chevron_left</mat-icon>
                        </button>

                        <div>
                            <span
                                class="mat-title">  {{ viewDate | date:('GENERAL.DATE_MOMENT_FORMA_DASH_BOARD' |translate):'':(currentLanguage$ | async)  }}</span>
                        </div>

                        <button mat-icon-button class="arrow"
                                mwlCalendarNextView
                                [view]="view"
                                [(viewDate)]="viewDate"
                                (viewDateChange)="selectedDay = {date:$event}"
                                (click)="onChangeDate()"
                                aria-label="Next">
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                    </div>
                    <!-- / HEADER BOTTOM -->
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start" *ngIf="haveDashBoardDayInterventions()">
                <span class="material-icons" style="font-size: 32px; cursor: pointer" (click)="sendToMobile()"
                      matTooltip="{{'BUTTON.SEND_TO_MOBILE' | translate}}">mobile_screen_share</span>
            </div>
        </div>

        <div gdArea="infos" fxLayout="row" fxLayoutAlign="space-between start" gdGap="10px" id="targetscroll">
            <div fxFlex="30" *ngIf="haveDashBoardDayInterventions()">
                <mat-card gdArea="list">
                    <mat-list>
                        <mat-list-item *ngFor="let technicianItem of dashBoardDay"
                                       (click)="changeTechnician(technicianItem)"
                                       [ngClass]="technicianItem.technician.id==currentTechnicianId? 'is-active':''">
                            <div mat-line fxLayout="row" fxLayoutAlign="space-between center">
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <ng-container *ngIf="!!technicianItem.technician.id;else unaffected">
                                        <mat-icon class="s-16">person</mat-icon>
                                    </ng-container>
                                    <ng-template #unaffected>
                                        <span class="no-trip-text">{{'TECHNICIAN.UNAFFECTED'|translate}}</span>
                                    </ng-template>
                                    {{ technicianItem.technician.firstName | titlecase }}  {{technicianItem.technician.lastName | titlecase}}
                                </div>
                                <div class="h6">{{getClientTime(technicianItem)}}</div>
                            </div>
                            <span mat-line>
                               {{technicianItem.dayDashboardDto.interventionCount}} {{"DASHBOARD.DAY.TIME.APPOINTMENT" |translate}}</span>
                            <mat-progress-bar mat-line class="mt-8"
                                              matTooltip="{{technicianItem.dayDashboardDto.availabilityPercent}} %"
                                              mode="determinate"
                                              [value]="technicianItem.dayDashboardDto.availabilityPercent">
                            </mat-progress-bar>

                            <div fxLayout="column" fxLayoutAlign="center center" class="fixed">
                                <button mat-icon-button (mouseenter)="showDetails(technicianItem)"
                                        [disabled]="!technicianItem.technician.id" [mdePopoverTriggerFor]="appPopover"
                                        mdePopoverTriggerOn="hover">
                                    <mat-icon class="mat-24">info</mat-icon>
                                </button>
                                <span
                                    class="availabilityPercent">{{technicianItem.dayDashboardDto.availabilityPercent + '%'}}</span>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </mat-card>
            </div>
            <div fxFlex="70" *ngIf="haveDashBoardDayInterventions() else noInterventions">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-button-toggle-group fxLayout="row" fxLayoutAlign="start start" aria-label="Font Style"
                                             class="mt-12 mb-8 ml-12" [(ngModel)]="buttonToggleSelected">
                        <mat-button-toggle (click)="showMap()"
                                           matTooltip="{{'DASHBOARD.DAY.BUTTON.MAPS' | translate}}"
                                           value="map">
                            <mat-icon>location_on</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle (click)="showDetailTrips()"
                                           matTooltip="{{'DASHBOARD.DAY.BUTTON.TRIPS' | translate}}"
                                           value="trips">
                            <mat-icon>list</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle *ngIf="isAuthorizedToReOptimizeTrip()"
                                           (click)="reOptimizeTripsDialogConfirmation()"
                                           matTooltip="{{'DASHBOARD.DAY.BUTTON.RE_OPTIMIZE' | translate}}"
                                           value="reOptimize">
                            <mat-icon>sync</mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <mat-button-toggle type="button" class="aster_accent w-100"
                                       [matTooltip]="'NAV.FOLDER.EXPORT_FOLDER' | translate"
                                       (click)="exportCSVFolders()"
                                       value="export">
                        <mat-icon> archive</mat-icon>
                        {{'NAV.FOLDER.EXPORT' | translate}}
                    </mat-button-toggle>
                </div>
                <mat-card gdArea="tableau" class="ml-12 overflow">
                    <ng-container *ngIf="!displayMap">
                        <table mat-table multiTemplateDataRows [dataSource]="dayDataSource.dataSource"
                               *ngIf="!!dayDataSource.dataSource">
                            <ng-container matColumnDef="planned">
                                <th class="header pt-16 px-16 w-20" mat-header-cell *matHeaderCellDef>
                                </th>
                                <td mat-cell class="px-16"
                                    [ngClass]="rowPlannedBorderLeftColorAndBreakTimeStyle(el)"
                                    *matCellDef="let el">

                                    <mat-icon class="mt-4 availabilityPercent"
                                              svgIcon="{{getIconPlanned(el.dayFilterEnum) }}"></mat-icon>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="client">
                                <th mat-header-cell *matHeaderCellDef >
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        {{'DASHBOARD.DAY.CUSTOMERS' | translate}}
                                    </div>

                                </th>
                                <td mat-cell [ngClass]="isBreakTime(el)? 'hour-schedule':''"
                                    *matCellDef="let el"> {{ getClient(el) | translate }}</td>
                            </ng-container>


                            <ng-container matColumnDef="folderReference">
                                <th mat-header-cell *matHeaderCellDef>
                                    <div fxLayout="row" fxLayoutAlign="center center">
                                        {{'DASHBOARD.DAY.FOLDER_REF' | translate}}
                                    </div>

                                </th>
                                <td mat-cell [ngClass]="isBreakTime(el)? 'hour-schedule':''"
                                    *matCellDef="let el" class="text-center">
                                    {{getFolderRef(el)}}
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="service">
                                <th mat-header-cell  *matHeaderCellDef>
                                    <div fxLayout="row" fxLayoutAlign="center center">
                                        {{ 'DASHBOARD.DAY.TIME.SERVICE'  | translate}}
                                    </div>

                                </th>
                                <td mat-cell [ngClass]="isBreakTime(el)? 'hour-schedule':''"
                                    *matCellDef="let el"
                                    class="text-center">{{el.service ? (el.service.code | codeToLabel  :'SAV.CONFIG.SERVICES' | async) : '' }}</td>
                            </ng-container>

                            <ng-container matColumnDef="schedules">
                                <th mat-header-cell  *matHeaderCellDef>
                                    <div fxLayout="row" fxLayoutAlign="center center">
                                        {{'ITINERANT.TECHNICIAN.CALENDAR.SCHEDULES' | translate}}
                                    </div>

                                </th>
                                <td mat-cell [ngClass]="isBreakTime(el)? 'hour-schedule':''"
                                    *matCellDef="let el">
                                    <div fxLayout="row"
                                         fxLayoutAlign="center center">{{el.type != 'END' ? el.beginTime : ''}}
                                        {{(!!el.beginTime && !!el.endTime && el.endTime != el.beginTime) ? '-' : '' }} {{el.endTime}} </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="realSchedules">
                                <th mat-header-cell  *matHeaderCellDef>
                                    <div fxLayout="row" fxLayoutAlign="center center">
                                        {{'ITINERANT.TECHNICIAN.CALENDAR.REAL.SCHEDULES' | translate}}
                                    </div>

                                </th>
                                <td mat-cell [ngClass]="isBreakTime(el)? 'hour-schedule':''"
                                    *matCellDef="let el">
                                    <div fxLayout="row"
                                         fxLayoutAlign="center center">{{el.realBeginTime }}
                                        {{(el.realEndTime) ? '-' : '' }} {{el.realEndTime}} </div>
                                </td>
                            </ng-container>
                            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                            <ng-container matColumnDef="expandedDetail">
                                <td mat-cell *matCellDef="let el" [ngClass]="isBreakTime(el)? 'hour-schedule':''"
                                    [attr.colspan]="dayDataSource.columnsToDisplay.length">
                                    <div class="example-element-detail"
                                         [@detailExpand]="!!dayDataSource.expandedElement && dayDataSource.expandedElement.id === el.id ? 'expanded' : 'collapsed'">

                                        <app-item-client-infos class="w-100-p mb-4" [data]="el"></app-item-client-infos>

                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="dayDataSource.columnsToDisplay"></tr>
                            <tr mat-row
                                *matRowDef="let el; columns: dayDataSource.columnsToDisplay"
                                class="example-element-row"
                                [class.example-expanded-row]="!!dayDataSource.expandedElement && dayDataSource.expandedElement.id === el.id"
                                (click)="dayDataSource.expandedElement = !!dayDataSource.expandedElement && dayDataSource.expandedElement.id === el.id ? null : el"></tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                                class="example-detail-row"></tr>

                        </table>
                    </ng-container>
                    <ng-container *ngIf="displayMap &&  !!this.dashBoardDay &&!!selectedInterventions">

                        <app-technician-intervention-map [selectedInterventions]="selectedInterventions"
                                                         [technicianId]="currentTechnicianId"
                                                         [tripId]="currentTechnicianTripId"
                                                         [allDashBoardDayDetails]=allDashBoardDayActivitiesDetails
                        ></app-technician-intervention-map>
                    </ng-container>
                </mat-card>
            </div>
            <ng-template #noInterventions>
                <div class="no-trip">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon svgIcon="no_intervention" class="no_intervention"></mat-icon>
                        <span class="no-trip-text">{{'TECHNICIAN.NO_INTERVINTIONS'|translate}}</span>
                    </div>
                </div>


            </ng-template>
        </div>

    <mde-popover #appPopover="mdePopover" [mdePopoverOffsetY]="40" [mdePopoverOffsetX]="30"
                 [mdePopoverOverlapTrigger]="true">

        <mat-card class="popover w-100-p mat-elevation-z2 ">
            <div *ngIf="!!technicianSelected" gdAreas="contact address" gdGap="10px">
                <div gdArea="contact">
                    <h4 class="text-bold">{{'CODE_LABEL_CONFIG.HEADERS.ADDRESS' | translate}}</h4>
                    <div *ngIf="!!technicianSelected.technician.address" class="mt-8" fxLayout="row"
                         fxLayoutAlign="start center"
                         fxLayoutGap="15px">
                        <mat-icon class="mr-8">
                                                 <span
                                                     *ngIf="!!technicianSelected.technician.address.countryCode && isValidCountryCode(technicianSelected.technician.address.countryCode); else globeIcon">
                                                     <span
                                                         [attr.class]="'flag-icon h-16 w-20 flag-icon-' + technicianSelected.technician.address.countryCode | lowercase"
                                                         style="vertical-align: sub;"></span>
                                                 </span>
                            <ng-template #globeIcon>
                                public
                            </ng-template>
                        </mat-icon>
                        <div>
                            {{'COUNTRY.NAME.' + technicianSelected.technician.address.countryCode | translate}},
                            {{technicianSelected.technician.address.city}} -
                            {{technicianSelected.technician.address.zipCode}}
                        </div>
                    </div>
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                        <mat-icon>location_city</mat-icon>
                        <div>
                            {{getTechnicianFullAddress(technicianSelected)}}
                        </div>
                    </div>
                </div>


                <div gdArea="address">
                    <h4 class="text-bold">{{'DASHBOARD.DAY.TIME.HOUR' | translate}}</h4>
                    <div fxLayout="column" fxLayoutAlign="start start">
                        <div class="mt-8" fxLayout="row"
                             fxLayoutAlign="start center">{{'DASHBOARD.DAY.TIME.START' | translate }}
                            : {{technicianSelected.dayDashboardDto.beginTime}}</div>
                        <div class="mt-8" fxLayout="row"
                             fxLayoutAlign="start center">{{'DASHBOARD.DAY.TIME.END' | translate}}
                            : {{technicianSelected.dayDashboardDto.endTime}}</div>
                    </div>
                </div>
            </div>
        </mat-card>
    </mde-popover>
</div>
</div>
