import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import {FuseConfigService} from '@fuse/services/config.service';
import {Unsubscriber} from '../../../unsubscriber';

@Component({
    selector     : 'horizontal-layout-1',
    templateUrl  : './layout-1.component.html',
    styleUrls    : ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HorizontalLayout1Component extends Unsubscriber implements OnInit {

    fuseConfig: any;

    constructor(private _fuseConfigService: FuseConfigService) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this._fuseConfigService.config
            .subscribe((config) => {
                this.fuseConfig = config;
            });
    }
}
