import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {TypeOfServicePublicRepairerEnum} from '../../../../models/typeOfService.model';
import {MatTableDataSource} from '@angular/material/table';
import {DataSourceLine, PublicQuotation, PublicQuotationLine} from '../../../../models/quotation.model';
import {FileService} from '../../../../shared/services/file.service';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {QuotationType} from '../../../../models/enums/quotationType.enum';
import {FolderPublic} from '../../../../models/folder.public.model';
import {PublicService} from '../../../../shared/services/public.service';
import * as uuid from 'uuid';
import {FuseConfirmDialogComponent} from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {FileInfo} from '../../../../models/file-info.model';
import {saveAs} from 'file-saver';
import {Amount} from '../../../../models/amount.model';
import {Vat} from '../../../../models/vat.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {Observable} from 'rxjs';
import {currentLanguage, vats} from '../../../../store/organization/organization.selectors';
import {CountryConfigurationSetSuccessfully} from '../../../../store/organization/organization.actions';
import {SparePartService} from '../../../../shared/services/spare-part.service';
import {QuotationStatus} from '../../../../models/enums/quotationStatus.enum';
import {map} from 'rxjs/operators';
import {CurrentWorkFlowStatusEnum} from '../../../../models/enums/currentWorkFlowStatus.enum';


@Component({
    selector: 'app-quotation-repairer',
    templateUrl: './quotation-repairer.component.html',
    styleUrls: ['./quotation-repairer.component.scss'],
    animations: fuseAnimations
})
export class QuotationRepairerComponent implements OnInit {

    @Input() folder: FolderPublic;
    @Input() canNotUpdate: boolean;
    @Output() inputMapQuotation = new EventEmitter<any>();
    @ViewChild('dropzone') dropzone: ElementRef;
    @ViewChild('ngFormQuotation') ngFormQuotation: NgForm;

    attachments = new Array<any>();

    displayedColumnsAttachment: string[] = ['name'];
    displayedColumns: string[] = ['code', 'label', 'type', 'price', 'quantity', 'discount', 'discountReason', 'vat', 'totalPrice'];
    dataSource = [];

    quotation: PublicQuotation;
    discountQuotations = 0;
    computeTotalPrice: Amount;
    quotationLines = new MatTableDataSource<DataSourceLine>();

    sparePartsTypes = Object.keys(TypeOfServicePublicRepairerEnum);
    attachmentQuotationDataTable = new MatTableDataSource<any>();
    isLoading = false;

    quotationForm: any = {
        form: null
    };

    fileUploadForm: any = {
        file: null, hasFile: false
    };

    quotationDescriptionForm: any = {
        form: null, placeholders: []
    };

    quotationCodeForm: any = {
        form: null, placeholders: []
    };
    currency: string;
    vatsList$: Observable<Vat[]>;
    defaultVatValue: string;
    title: string;

    constructor(private publicService: PublicService,
                private fileService: FileService,
                private store$: Store<AppState>,
                private _translateService: TranslateService,
                private dialog: MatDialog,
                private sparePartService: SparePartService) {
        this.sparePartsTypes = Object.keys(TypeOfServicePublicRepairerEnum);

    }

    ngOnInit(): void {
        this.initTitle();
        this.initLanguage();
        this.vatsList$ = this.store$.pipe(select(vats));
        this.currency = this.folder.currency;
        this.getVats();
        if (!this.canNotUpdate) {
            this.displayedColumns.push('action');
            this.displayedColumnsAttachment.push('action');
            this.initQuotationRepairerForm(TypeOfServicePublicRepairerEnum.SPARE_PART);
        }
        this.initFormValidateQuotation();
        this.initQuotation();
    }

    private initTitle() {
        this.title = (this.folder.status === CurrentWorkFlowStatusEnum.WAIT_QUOTATION) ?
            this._translateService.instant('MODAL.QUOTATION.ADD_QUOTATION') :
            this._translateService.instant('MODAL.QUOTATION.WAIT_ACCEPT_QUOTATION');
    }

    private initLanguage() {
        this.store$.pipe(select(currentLanguage)).subscribe(
            language => {
                this._translateService.use(language);
            }
        );
    }

    private initQuotation(): void {
        this.quotation = this.getLastQuotation();
        if (this.quotation) {
            let totalTTc = 0;
            this.quotation.quotationLines = this.quotation.quotationLines.filter(value => value.type !== QuotationType.QUOTATION_FEES);
            this.attachmentQuotationDataTable.data = this.quotation.quotationAttachments;
            this.dataSource = this.toDataSourceLines(this.quotation.quotationLines);
            this.quotationLines = new MatTableDataSource<DataSourceLine>(this.dataSource);
            this.quotationLines.data.map(quotationLine => {
                totalTTc = totalTTc + this.sparePartService.getTotalLinePrice(quotationLine.price, quotationLine.quantity, quotationLine.discount, quotationLine.vat);
            });
            this.computeTotalPrice = {
                value: totalTTc,
                currency: this.currency
            } || {
                value: 0,
                currency: this.currency
            };
            this.quotationDescriptionForm.form.setValue({'description': !!this.quotation.agentComment ? this.quotation.agentComment : ''});
        } else {
            this.quotation = {
                quotationLines: [], discount: 0, totalPrice: {value: 0}, quotationAttachments: [], target: 'DISTRIBUTOR', userAction: null, code: ''
            };
        }
    }

    private getLastQuotation(): PublicQuotation {
        return this.folder.publicQuotations.length > 0 ? this.folder.publicQuotations.reduce((quotation1, quotation2) => {
            return quotation1.userAction.actionDate > quotation2.userAction.actionDate ? quotation1 : quotation2;
        }) : null;
    }

    private getVats() {
        this.publicService.getByCountryCode(this.folder.externalUid).subscribe(countryConfiguration => {
            this.store$.dispatch(new CountryConfigurationSetSuccessfully(countryConfiguration));
        });
    }

    initFormValidateQuotation(): void {
        this.quotationCodeForm.form = new FormGroup({
            quotationCode: new FormControl(null, Validators.required),
        });
        this.quotationDescriptionForm.form = new FormGroup({
            description: new FormControl(null)
        });

    }

    initQuotationRepairerForm(typeValue: string): void {
        this.vatsList$.pipe(
            map(vats => vats.find(item => item.standard === true))
        ).subscribe(vat => {
            if (vat) {
                this.defaultVatValue = vat.rate;
                this.initValuesQuotationRepairerForm(typeValue);
            }
        });
    }

    initValuesQuotationRepairerForm(typeValue: string): void {
        this.quotationForm.form = new FormGroup({
            code: new FormControl(null, Validators.required),
            label: new FormControl(null, Validators.required),
            quantity: new FormControl(1, Validators.required),
            price: new FormControl(null, Validators.required),
            vat: new FormControl(this.defaultVatValue, Validators.required),
            discount: new FormControl(0, [Validators.min(0), Validators.max(100)]),
            type: new FormControl(typeValue, Validators.required),
            discountReason: new FormControl(null),
            totalLinePrice: new FormControl(0),
        });
    }

    onFilesAdded($event: any): void {
        this.fileUploadForm.hasFile = true;
        this.fileUploadForm.file = $event[0].name;
        const fileChange = this.fileService.onFileChange($event);
        fileChange.reader.onload = () => {
            const blobFile = this.fileService.dataURItoBlob(fileChange.reader.result.toString().split(',')[1]);
            const newAttachement = {file: blobFile, info: $event[0]};
            this.attachments.push(newAttachement);
            this.attachmentQuotationDataTable.data = this.attachmentQuotationDataTable.data.concat(newAttachement);
            // @ts-ignore
            this.dropzone.reset();
            this.fileUploadForm.hasFile = false;
        };
    }

    removeFile(fileId): void {
        const index = this.attachments.indexOf(fileId);
        if (index > -1) {
            this.attachments.splice(index, 1);
        }
        if (this.quotation.quotationAttachments.length !== 0 && fileId.id) {
            this.quotation.quotationAttachments = this.quotation.quotationAttachments.filter(file => file.id !== fileId.id);
        }
        this.attachmentQuotationDataTable.data = this.attachmentQuotationDataTable.data.filter(attachment => attachment !== fileId);
    }

    validateQuotationChoiceForm(): void {
        this.quotationConfirmation(this.prepareQuotation());
    }

    private prepareQuotation() {
        return {
            id: !this.quotation.id ? uuid.v4() : this.quotation.id,
            code: this.quotationCodeForm.form.value.quotationCode,
            quotationLines: this.toQuotationLines(this.quotationLines.data),
            discount: this.quotation.discount,
            target: this.quotation.target,
            quotationAttachments:  this.getQuotationAttachments(),
            agentComment: this.quotation.agentComment,
            status: this.quotation.status
        };
    }

    private getQuotationAttachments() {
        return this.quotation.quotationAttachments.map(value => value.id);
    }

    quotationConfirmation(quotation): void {
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true, disableClose: false,
        });
        dialogRef.componentInstance.title = 'CONFIRMATION.MODAL.DELETE_DIALOG.TITLE';
        dialogRef.componentInstance.message = 'CONFIRMATION.MODAL.CONFIRM.QUOTATION';
        dialogRef.componentInstance.confirmButtonLabel = 'CONFIRMATION.MODAL.DELETE_DIALOG.VALIDATE_BUTTON';
        dialogRef.componentInstance.cancelButtonLabel = 'CONFIRMATION.MODAL.DELETE_DIALOG.CANCEL_BUTTON';
        dialogRef.afterClosed().subscribe(validate => {
            if (validate) {
                this.inputMapQuotation.emit({
                    'description': this.quotationDescriptionForm.form.value.description,
                    'quotation': quotation,
                    'attachments': this.attachments
                });
            }
        });
    }

    calculationQuotationLine(): void {
        this.quotationForm.form.get('price').setValue(this.sparePartService.formatPriceAccordingToCurrency(this.quotationForm.form.get('price').value, this.currency));
        this.quotationForm.form.get('totalLinePrice').setValue(
            this.sparePartService.formatPriceAccordingToCurrency(
                this.sparePartService.getTotalLinePrice(
                    this.sparePartService.normalizeFloat(this.quotationForm.form.value.price),
                    this.quotationForm.form.value.quantity, this.quotationForm.form.value.discount, this.quotationForm.form.value.vat),
                this.currency));
    }

    getTotalLinePrice(quotationLine: any): number {
        return this.sparePartService.getTotalLinePrice(quotationLine.price, quotationLine.quantity, quotationLine.discount, quotationLine.vat);
    }

    getTotalPriceHT(): number {
        return !!this.dataSource ? this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) =>
            total + this.sparePartService.normalizeFloat(line.price) * line.quantity, 0) : 0;
    }

    getTotalDiscount(): number {
        if (!!this.dataSource) {
            const totalPriceHT = this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) =>
                total + this.sparePartService.normalizeFloat(line.price) * line.quantity, 0);
            return totalPriceHT - this.getTotalNetHT();
        }
        return 0;
    }

    getTotalNetHT(): number {
        if (!!this.dataSource) {
            let totalNetHT;
            totalNetHT = this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) =>
                line.discount > 0 ? total + (this.sparePartService.normalizeFloat(line.price) * line.quantity -
                    ((this.sparePartService.normalizeFloat(line.price) * line.quantity) * (line.discount / 100))) : total
                    + (this.sparePartService.normalizeFloat(line.price) * line.quantity), 0);
            if (this.discountQuotations > 0) {
                return totalNetHT - totalNetHT * (this.discountQuotations / 100);
            }
            return totalNetHT;
        }
        return 0;
    }

    getTotalPriceTVA(): number {
        if (!!this.dataSource) {
            return this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) => {
                const totalHT = this.sparePartService.normalizeFloat(line.price) * line.quantity;
                const discount = line.discount / 100;
                const vatRate = line.vat;
                if (!!vatRate && vatRate > 0) {
                    const totalNetHT = totalHT - totalHT * discount;
                    return total + (totalNetHT + totalNetHT * (vatRate / 100)) - totalNetHT;
                }
                return total;
            }, 0);
        }
        return 0;
    }

    addIntoQuotationLines(): void {
        this.checkDiscountValue();
        this.dataSource.unshift(this.quotationForm.form.value);
        this.refreshDataForm();
        this.refreshDataSourceTable();
    }

    private checkDiscountValue() {
        if (!this.quotationForm.form.value.discount) {
            this.quotationForm.form.get(['discount']).setValue(0);
        }
    }

    refreshDataForm(): void {
        this.ngFormQuotation.resetForm();
        this.initQuotationRepairerForm(TypeOfServicePublicRepairerEnum.SPARE_PART);
    }

    deleteLine(indexLine: number): void {
        this.dataSource = this.dataSource.filter((item, index) => index !== indexLine || item.type.startsWith(QuotationType.QUOTATION_FEES));
        this.quotationLines = new MatTableDataSource<any>(this.dataSource);
        this.refreshDataSourceTable();
    }

    refreshDataSourceTable(): void {
        this.quotationLines.data = [];
        this.quotationLines = new MatTableDataSource<DataSourceLine>(this.dataSource);
        const totalPrice = this.sparePartService.computeTotalPrice(this.dataSource.map(quotationLine => quotationLine.totalLinePrice));
        this.computeTotalPrice = {
            value: totalPrice,
            currency: this.currency
        };
    }


    downloadFile(file: FileInfo): void {
        this.fileService.getPublicAttachmentFile(file.id, this.folder.externalUid).subscribe(value => {
            saveAs(value, file.name);
        });
    }

    toDataSourceLines(quotationLines: PublicQuotationLine[]): DataSourceLine[] {
        const dataSourceLines: DataSourceLine[] = [];
        quotationLines.forEach(quotationLine => {
            // map some specific values
            const line = {
                ...quotationLine,
                vat: Number(quotationLine.vat.rate),
                price: quotationLine.priceAmount.value,
                totalLinePrice: quotationLine.totalLinePrice?.value
            };
            const {priceAmount, ...dataSourceLine} = line;
            dataSourceLines.push(dataSourceLine);
        });

        return dataSourceLines;
    }

    toQuotationLines(dataSourceLines: any[]): PublicQuotationLine[] {
        const quotationLines: PublicQuotationLine[] = [];
        dataSourceLines.forEach(line => {
            const quotationLine: PublicQuotationLine = {
                ...line,
                vat: {rate: String(line.vat)},
                priceAmount: {
                    value: this.sparePartService.normalizeFloat(line.price),
                    currency: this.currency
                },
                totalLinePrice: {
                    value: this.sparePartService.normalizeFloat(line.totalLinePrice),
                    currency: this.currency
                }
            };
            delete quotationLine['price'];
            quotationLines.push(quotationLine);
        });

        return quotationLines;
    }


}



