    <mat-card-content>
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>

        <ng-container *ngIf="folder?.warrantyEndDate">
            <label class="h3"><span
                class="text-bold"> {{ 'FOLDER.WARRANTY_END_DATE'|translate }}
                :</span> {{ folder.warrantyEndDate  | moment: 'L' : languageKey }}
            </label>
        </ng-container>

        <div *ngIf="hasImmobilization && !isProlongedDiagnosis " class="mt-16 mb-16">
            <div fxLayout="row wrap" fxLayoutAlign="start start">
                <label class="h3"><span
                    class="text-bold"> {{'FOLDER.WAITING_PRODUCT_RETRIEVAL.PRODUCT_IMMOBILIZATION'|translate}}
                    :</span>                                   {{immobilizationMessage$ | async}}
                </label>

            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="10px">
            <div *ngIf="file">
                {{'FOLDER.REPORT.DOWNLOAD' | translate}}:
                <a (click)="getReportAttachment()" class="cursor-pointer">
                    <span>
                        <mat-icon>file_copy</mat-icon>
                        {{file.name}}
                    </span>
                </a>
            </div>
            <app-accessories-label-list *ngIf="accessoriesLabel.length>0" [accessoriesLabel]="accessoriesLabel"></app-accessories-label-list>
            <ng-container *ngIf="!!description$">
                <h3 class="text-bold">
                    <mat-icon class="icon-color">warning</mat-icon>
                    {{description$ | async}}</h3>
            </ng-container>
            <div class="mt-16">
                <mat-checkbox [(ngModel)]="retrievalDone">
                    {{'BUTTON.PRODUCT_RECOVERED' | translate}}
                </mat-checkbox>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end end">
            <button mat-stroked-button (click)="onSubmit()"
                    type="submit"
                    [disabled]="!retrievalDone"
                    [ngClass]="!retrievalDone ? 'aster_btn_disabled' : 'aster_btn'">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>

    </mat-card-content>
