import {Directive, ElementRef, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
    selector: '[inputControl]'
})
export class InputControlDirective {

    constructor(private el: ElementRef, private ngControl: NgControl) {
    }

    @HostListener('input', ['$event'])
    preventEnterSpaceInput(event: KeyboardEvent) {
        const value = this.el.nativeElement.value?.trim();
        if (value?.length === 0) {
            this.el.nativeElement.value = '';
            this.ngControl.control.setValue('', {emitEvent: false});
        }
    }

    @HostListener('keydown', ['$event'])
    preventEnterSpace(event: KeyboardEvent) {
        if ((event.key === 'Enter' || event.key === ' ') && this.el.nativeElement.value.trim() === '') {
            event.preventDefault();
        }
    }
}
