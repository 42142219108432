<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="text-center">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" cdkDragHandle>
        <h2>
            <mat-icon>note_add</mat-icon>
            {{'MODAL.QUOTATION.ADD_QUOTATION'|translate}}
        </h2>
        <button mat-icon-button class="ml-40" (click)="onCloseModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <form *ngIf="quotationForm" [formGroup]="quotationForm"
              autocomplete="off">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                <div fxFlex="19.5" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.TYPE'|translate}}</mat-label>
                        <mat-select formControlName="type" [placeholder]="'MODAL.QUOTATION.TYPE'|translate" required
                                    (selectionChange)="refreshDataForm($event.value)">
                            <mat-option *ngFor="let type of typeOfBenefits" [value]="type.code">
                                <span> {{type.label}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="37" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.CODE' | translate }}</mat-label>
                        <input matInput placeholder="{{'MODAL.QUOTATION.CODE' | translate }}"
                               formControlName="code" required>
                    </mat-form-field>
                </div>
                <div
                    fxFlex="37"
                    fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.LABEL'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.LABEL'|translate"
                               formControlName="label" type="text" required>
                    </mat-form-field>
                </div>

                <div fxFlex="15" fxFlex.lt-md="100" >
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{ 'MODAL.QUOTATION.PAYER'|translate }}</mat-label>
                        <mat-select formControlName="payer" [placeholder]="'MODAL.QUOTATION.PAYER'|translate">
                            <mat-option value="DISTRIBUTOR" disabled>
                                <span> {{ 'MODAL.QUOTATION.PAYER_CODE.DISTRIBUTOR'|translate}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                <div fxFlex="20" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.QUANTITY'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.QUANTITY'|translate" formControlName="quantity"
                               (change)="formatAndCalculateTotalQuotationLine()" NumberOnlyNotIncludeZeros min="1"
                               required
                               type="number">
                    </mat-form-field>
                </div>
                <div fxFlex="20" fxFlex.lt-md="100">
                    <ng-container [formGroup]="getPriceAmountFromGroup">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'MODAL.QUOTATION.PRICE'|translate}}</mat-label>
                            <input matInput [placeholder]="'MODAL.QUOTATION.PRICE'|translate"
                                   (change)="formatAndCalculateTotalQuotationLine()" formControlName="value" min="0" required>
                        </mat-form-field>
                    </ng-container>

                </div>
                <div fxFlex="20" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.DISCOUNT'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.DISCOUNT'|translate" formControlName="discount"
                               (change)="checkDiscountAndCalculateTotalQuotationLine($event)"
                               percentage>
                        <mat-icon class="s-16" matSuffix>%</mat-icon>
                    </mat-form-field>
                </div>
                <div fxFlex="20" fxFlex.lt-md="100">
                    <ng-container [formGroup]="getTotalLinePriceFromGroup">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'MODAL.QUOTATION.TOTAL_PRICE'|translate}}</mat-label>
                            <input matInput [placeholder]="'MODAL.QUOTATION.TOTAL_PRICE'|translate"
                                   required
                                   formControlName="value" disabled readonly="true"
                                   [ngClass]="'input-disabled'"
                                   (change)="formatAndCalculateTotalQuotationLine()">
                        </mat-form-field>
                    </ng-container>

                </div>
            </div>
            <ng-container *ngIf="this.quotationForm.value.discount>0">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                    <div fxFlex="100" fxFlex.lt-md="100">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{'MODAL.QUOTATION.DISCOUNT_REASON'|translate}}</mat-label>
                            <input matInput [placeholder]="'MODAL.QUOTATION.DISCOUNT_REASON' | translate"
                                   formControlName="discountReason" type="text">
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
            <div fxLayoutAlign="end center">
                <div>
                    <button mat-stroked-button class="aster-btn_border" type="button"
                            (click)="addIntoQuotationLines()"
                            [disabled]="quotationForm.invalid "
                            [ngClass]="quotationForm.invalid ? 'aster-btn_border-disabled' : 'aster-btn_border'">
                        <mat-icon>add</mat-icon>
                        {{'BUTTON.ADD' | translate}}
                    </button>
                </div>
            </div>
        </form>

        <table mat-table [dataSource]="quotationLines" class="mat-elevation-z4 w-100-p mt-16 mb-16">

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef class="table-header">  {{'MODAL.QUOTATION.CODE' | translate}}</th>
                <td mat-cell *matCellDef="let quotationLine" class="element">{{quotationLine.code}}
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef class="table-header">{{'MODAL.QUOTATION.TYPE' | translate}}</th>
                <td mat-cell
                    class="element"
                    *matCellDef="let quotationLine">{{'MODAL.QUOTATION.TYPE.' + quotationLine.type | translate}}</td>
            </ng-container>

            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef class="table-header">{{'MODAL.QUOTATION.LABEL' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let quotationLine">{{quotationLine.label}}</td>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">  {{'MODAL.QUOTATION.QUANTITY' | translate}}</th>
                <td mat-cell *matCellDef="let quotationLine" class="element">{{quotationLine.quantity}} </td>
            </ng-container>

            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef class="table-header">{{'MODAL.QUOTATION.PRICE' | translate}}</th>
                <td mat-cell *matCellDef="let quotationLine" class="element">
                    {{normalize(quotationLine?.priceAmount?.value)   |currency:currency :'symbol-narrow' | space}}
                </td>
            </ng-container>

            <ng-container matColumnDef="vat">
                <th mat-header-cell *matHeaderCellDef class="table-header">  {{'MODAL.QUOTATION.TVA' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let quotationLine"> {{quotationLine.vat?.rate}} </td>
            </ng-container>

            <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">  {{'MODAL.QUOTATION.DISCOUNT' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let quotationLine"> {{quotationLine.discount}} </td>
            </ng-container>

            <ng-container matColumnDef="totalPrice">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header"> {{'MODAL.QUOTATION.TOTAL_PRICE' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let quotationLine">
                    {{normalize(quotationLine?.totalLinePrice?.value)   |currency:currency :'symbol-narrow' | space}}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">  {{'MODAL.QUOTATION.ACTION' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let quotationLine;let indexLine = index;">
                    <button matSuffix mat-icon-button color="warn"
                            type="button" class="mx-8" (click)="deleteLine(indexLine)">
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div fxLayout="row" fxLayoutAlign="end end">
            <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="30" fxFlex.lt-md="100">
                <div fxLayout="column" class="zone-total">
                    <div fxLayoutAlign="space-between center">
                        <strong>{{'MODAL.QUOTATION.TOTAL_HT' | translate}} : </strong>
                        <div>{{getTotalPriceHT()  |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <strong>{{'MODAL.QUOTATION.TOTAL_DISCOUNT' | translate}} : </strong>
                        <div>{{getTotalDiscount()  |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <strong>{{'MODAL.QUOTATION.TOTAL_NET_HT' | translate}}  : </strong>
                        <div>{{getTotalNetHT()  |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <strong>{{'MODAL.QUOTATION.TOTAL_TVA' | translate}}  : </strong>
                        <div>
                            {{getTotalPriceTVA()  |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <strong> {{'MODAL.QUOTATION.TOTAL_TTC' | translate}} : </strong>
                        <div>{{computeTotalPrice?.value |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                </div>

            </mat-card>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mt-16">
        <div class="w-100-p">
            <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
                <ng-container *ngIf="computeTotalPrice?.value < 0">
                    <p class="red-color">{{'FOLDER.REPAIR_IN_PROGRESS.NEGATIVE_QUOTATION' | translate}}</p>
                </ng-container>
                <button type="submit" mat-stroked-button (click)="validateForm()"
                        [disabled]=" disabledForm()"
                        [ngClass]=" disabledForm()? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </div>
    </mat-dialog-actions>
</div>
