import {Component, Input, OnInit} from '@angular/core';
import {PhoneNumberPipe} from '../../../../../../@fuse/pipes/phone-number.pipe';
import {CountriesUtils} from '../../../../../shared/data/countries-utils';
import libphonenumber from 'google-libphonenumber';
import {Detail} from '../../../../../models/repair/detail.model';
import PhoneNumberFormat = libphonenumber.PhoneNumberFormat;

@Component({
    selector: 'app-item-client-infos',
    templateUrl: './item-client-infos.component.html',
    styleUrls: ['./client-infos.component.scss']
})
export class ItemClientInfosComponent implements OnInit {
    @Input() data: Detail;
    formattedPhoneNumber: {
        phoneNumber: '',
        countryCode: ''
    };

    constructor(private phoneNumberPipe: PhoneNumberPipe) {
    }

    ngOnInit(): void {
        if (!!this.data && !!this.data.customer) {
            this.initializePhoneNumber();
        }
    }


    private initializePhoneNumber(): void {
        if (!!this.data.customer.phone && !!this.data.customer.interventionAddress && !!this.data.customer.interventionAddress.countryCode) {
            this.formattedPhoneNumber = this.phoneNumberPipe.getFormattedPhone(this.data.customer.phone, this.data.customer.interventionAddress.countryCode
                , PhoneNumberFormat.E164);
        }
    }

    isValidCountryCode(countryCode: string): boolean {
        return CountriesUtils.isValidCountryCode(countryCode);
    }

    getCountryNameByCode(countryCode: string): string {
        return CountriesUtils.getCountryName(countryCode);
    }

    getCountryNameByCountryCode(countryCode: string): string {
        return CountriesUtils.getCountryName(countryCode);
    }

    getCountryCodeByPhoneNumber(countryName: string): string {
        return CountriesUtils.getCountryCode(countryName);
    }


    getNationalPhoneNumber(number: string): string {
        if (number === '') {
            return '-';
        }
        return this.phoneNumberPipe.transform(number, this.formattedPhoneNumber.countryCode, PhoneNumberFormat.INTERNATIONAL);
    }

}
