import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FolderService} from '../../../shared/services/folder.service';
import {FolderSubjectService} from '../folder-subject.service';
import {Folder} from '../../../models/folder.model';
import {WorkflowService} from '../../../shared/services/workflow.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {Unsubscriber} from '../../../unsubscriber';

@Component({
    selector: 'app-change-current-status-dialog',
    templateUrl: './change-current-status-dialog.component.html',
    styleUrls: ['./change-current-status-dialog.component.scss']
})
export class ChangeCurrentStatusDialogComponent extends Unsubscriber implements OnInit {

    changeCurrentStatusForm: FormGroup;
    folder: Folder;
    previousWorkflowTaskList = [];
    currentStatus: string;


    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<ChangeCurrentStatusDialogComponent>,
                private folderService: FolderService,
                private folderSubjectService: FolderSubjectService,
                private store$: Store<AppState>,
                private workflowService: WorkflowService) {
        super();
    }

    ngOnInit(): void {
        if (!!this.data) {
            this.currentStatus = this.data.currentStatus;
            this.folder = this.data.folder;
            this.getPreviousWorkflowTaskList();
            this.initChangeCurrentStatusForm();
        }
    }


    initChangeCurrentStatusForm(): void {
        this.changeCurrentStatusForm = new FormGroup({
            workflowStatus: new FormControl(null, Validators.required),
        });
    }

    getPreviousWorkflowTaskList(): void {
        this.workflowService.getPreviousWorkflowTaskList(this.folder.id, this.currentStatus).subscribe(res => {
            this.previousWorkflowTaskList = res;
        });
    }

    onSubmit(): void {
        if (!this.changeCurrentStatusForm.value.workflowStatus) {
            return;
        }
        this.store$.dispatch(new StartLoading());
        let activityMap = {};
        activityMap = {
            'currentTask': this.currentStatus, 'previewsTask': this.changeCurrentStatusForm.value.workflowStatus
        };
        this.getPreviousTask(activityMap);
        this.store$.dispatch(new StopLoading());

    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    getPreviousTask(activityMap): void {
        this.workflowService.getPreviewsTaskWorkflow(this.folder.id, activityMap).then(value => {
            this.anotherSubscription = this.folderService.getFolder(this.folder.id)
                .subscribe(res => {
                    this.folderSubjectService.folderLoaded(res);
                    this.folderSubjectService.refreshWorkflowStatusLoaded(res);
                    this.closeDialog();
                });
        }).catch(reason => {
            this.closeDialog();
        });
    }

}
