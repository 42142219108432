import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject, EMPTY, from, Observable, of} from 'rxjs';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {BackOfficeService} from '../../../../shared/services/back-office.service';
import {ProductService} from '../../../../shared/services/product.service';
import {FolderCreationService} from '../folder-creation.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {Product} from '../../../../models/product.model';
import {UserState} from '../../../../store/user/user.state';
import {ProductBrand} from '../../../../models/ProductBrand';
import {Supplier} from '../../../../models/supplier.model';
import {switchMap, take} from 'rxjs/operators';
import {RuleEvaluationContext} from '../../../../models/rules/RuleEvaluationContext';
import {ProductFamilyType} from '../../../../models/enums/productFamilyType.enum';
import {Page} from '../../../../models/page.model';
import {Unsubscriber} from '../../../../unsubscriber';
import {MatDialog} from '@angular/material/dialog';
import {UnknownProductComponent} from '../unknown-product/unknown-product.component';
import {UnknownProduct} from '../unknown-product/unknown.product';
import {GrowthbookService} from '../../../../shared/services/growthbook.service';
import {AppFeatures, NomenclatureFeatureData} from '../../../../shared/features/app-features';
import {currentUser} from '../../../../store/user/user.selectors';
import {GrowthbookAttributes} from '../../../../shared/features/growthbook-attributes';
import {InvoiceItem} from '../../../../models/invoice.model';
import {Constants} from '../../../../Constants';

export const PRODUCT_FIELDS = {
    LM: {
        productCode: {
            name: 'productCode',
            label: 'FOLDER.TABLE.PRODUCT_CODE'
        },
        productLabel: {
            name: 'productLabel',
            label: 'FOLDER.TABLE.FILTER.TREE.PRODUCTLABEL'

        },
        eanCode: {
            name: 'eanCode',
            label: 'FOLDER.CREATE.PRODUCT_EAN'
        },
        supplierCode: {
            // separated component => app-product-supplier-field
            name: 'supplierCode',
        },
        brand: {
            // separated component => app-product-brand
            name: 'brandCode',
        },
        manufacturerReference: {
            name: 'manufacturerReference',
            label: 'FOLDER.CREATE.PRODUCT_MANUFACTURER_REFERENCE'
        }
    },
    NR: {
        productCode: {
            name: 'productCode',
            label: 'FOLDER.CREATE.PRODUCT_CODE.TITLE'
        },
        productLabel: {
            name: 'productLabel',
            label: 'FOLDER.CREATE.PRODUCT_LABEL.TITLE'
        }
    },
    OTHERS: {
        productCode: {
            name: 'productCode',
            label: 'FOLDER.CREATE.PRODUCT_CODE.TITLE',
        }
    }
};

@Component({
    selector: 'app-product-search',
    templateUrl: './product-search.component.html',
    styleUrls: ['./product-search.component.scss'],
})
export class ProductSearchComponent extends Unsubscriber implements OnInit {
    @Input() user: UserState;
    @Input() invoiceNotFound = false;
    @Input() isSearchProductPerLabelActive: boolean;
    @Input() clearSearch$: Observable<boolean>;
    // si true le bloc s'affiche ; si non le bloc ne s'affiche pas
    @Output() productNotFound: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() showProductState: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() pageSize: number;
    @Output() resetProductState: EventEmitter<void> = new EventEmitter<void>();
    loadedPages = new Set<number>();
    disableBrand$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    disableSupplier$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showAdditionalSearchInputs = false;

    formGroup: FormGroup;
    prefixOrganizationCode: string;
    fields: any;
    disableSearch = true;
    identificationProductCtrl = new FormControl();
    private _brandLabel: string;

    unknownProductFeatureActive: boolean;

    nomenclatureFields: NomenclatureFeatureData[] = [];
    constructor(private backOfficeService: BackOfficeService,
                private productService: ProductService,
                private folderCreationService: FolderCreationService,
                private store$: Store<AppState>,
                private dialog: MatDialog,
                private growthBookService: GrowthbookService) {
        super();

    }

    ngOnInit(): void {

        this.formGroup = new FormGroup({});
        this.initConfiguration();
        this.prefixOrganizationCode = this.getPrefixForOrganizationCode(this.user.organizationCode);
        this.fields = PRODUCT_FIELDS[this.prefixOrganizationCode];
        this.initForm();
        this.anotherSubscription = this.folderCreationService.productFilterPageChanged$.subscribe(pageNumber => {
            if (pageNumber) {
                this.searchProduct(pageNumber - 1);
            }
        });
        this.formGroup.valueChanges.subscribe(value => {
            this.formGroupToMap(false);
        });
        this.clearSearch$.subscribe(data => {
            if (data) {
                this.cleanAllForms();
            }
        });
        this.listenToInvoiceItem();
    }

    private initForm() {
        Object.keys(this.fields).forEach((key) => {
            this.formGroup.addControl(this.fields[key].name, new FormControl(''));
        });
    }

    private formGroupToMap(onSubmit: boolean) {
        // le trim est désactivé pour le productLabel et s'il n'est pas du submit
        const fieldsNotToTrim = ['productLabel'];
        const formMap = new Map<string, any>();
        Object.keys(this.formGroup.controls).forEach((key) => {
            const control: AbstractControl | null = this.formGroup.controls[key];
            if (control && control.value && control.value !== '') {
                let value = control.value;
                if (onSubmit || !fieldsNotToTrim.includes(key)) {
                    value = value.trim();
                }
                control.patchValue(value, { emitEvent: false });
                formMap.set(key, value);
            }
        });
        this.disableSearch = formMap.size === 0;
        return formMap;
    }

    searchProduct(page: number): void {
        this.folderCreationService.productListIsFilteredResult(true);
        if (page === 0) {
            this.resetSearch();
        } else if (this.loadedPages.has(page)) {
            return;
        }

        const productCode = this.formGroup.get('productCode').value;
        if (productCode) {
            this.searchWithProductCode();
        } else {
            this.performSearchWithoutProductCode(page);
        }

        this.loadedPages.add(page);
    }

    private resetSearch(): void {
        this.loadedPages.clear();
        this.folderCreationService.newProductSearchFilter$.next(true);
    }

    private performSearchWithoutProductCode(page: number): void {
        this.store$.dispatch(new StartLoading());
        this.productNotFound.emit(false);
        this.showProductState.emit(false);
        this.folderCreationService.productChanged(null);

        const formMap = this.formGroupToMap(true);
        // add specific field to the search endpoint
        if (this._brandLabel) {
            formMap.set('brandLabel', this._brandLabel);
        }
        formMap.set(Constants.ORGANIZATION_CODE, this.user.organizationCode);
        formMap.set(Constants.PAGE, page);
        formMap.set(Constants.SIZE, this.pageSize);

        this.productService.search(formMap)
            .subscribe((pageOfProducts: Page<Product>) => {
                this.processSearchResults(pageOfProducts, page);
            }, () => this.handleSearchError());
    }

    private processSearchResults(pageOfProducts: Page<Product>, page: number): void {
        this.sendDataToResultTable(pageOfProducts, page);
        this.store$.dispatch(new StopLoading());
    }

    private handleSearchError(): void {
        this.productNotFound.emit(false);
        this.store$.dispatch(new StopLoading());
    }


    listenToInvoiceItem(): void{
        this.anotherSubscription = this.folderCreationService.invoiceItem$.subscribe(invoiceItem => {
            this.formGroup.get('productCode').setValue(invoiceItem.product.productCode);
            this.searchWithProductCode(invoiceItem);
        });
    }

    searchWithProductCode(invoiceItem?: InvoiceItem): void {
        this.productNotFound.emit(false);
        const productCode = this.formGroup.controls['productCode'].value;
        this.identificationProductCtrl.setValue(null);
        if (productCode) {
            this.formGroup.controls['productCode'].patchValue(productCode.trim());
            this.store$.dispatch(new StartLoading());
            this.showProductState.emit(false);
            this.folderCreationService.productChanged(null);
            let product;

            const formMap = this.formGroupToMap(true);
            formMap.set(Constants.ORGANIZATION_CODE, this.user.organizationCode);
            formMap.set(Constants.PAGE, 0);
            formMap.set(Constants.SIZE, 1);
            formMap.set(Constants.WITH_DOCUMENTS, true);
            formMap.set(Constants.WITH_SELLING_PRICE, true);
            formMap.set(Constants.WITH_PURCHASE_PRICE, true);

            this.productService.search(formMap)
                .pipe(
                    switchMap((pageResponse: Page<Product>) => {
                        product = pageResponse.content[0];
                        if (!product) {
                            this.store$.dispatch(new StopLoading());
                            this.productNotFound.emit(true);
                            return EMPTY;
                        }
                        if (!product.afterSalesEligible){
                            return of(false);
                        }
                        const evaluationContext = RuleEvaluationContext.fromProduct(product, this.user);
                        return this.backOfficeService.getEligibilities(evaluationContext);
                    })
                )
                .subscribe(eligibility => {
                    product.afterSalesEligible = eligibility;
                    product.quantity = invoiceItem?.product?.quantity ||  1;
                    product.sellingPrice = invoiceItem?.product?.sellingPrice || product.sellingPrice;
                    this.folderCreationService.productChanged(product);
                    this.showProductState.emit(true);
                    this.store$.dispatch(new StopLoading());
                }, () => {
                    this.productNotFound.emit(true);
                    this.store$.dispatch(new StopLoading());
                });
        }
    }

    private sendDataToResultTable(pageOfProducts: Page<Product>, page: number) {
        const products = pageOfProducts.numberOfElements === 1 ? pageOfProducts.content : [];
        if (products.length === 1 && page === 0) {
            const oneProduct = products[0] as any;
            this.showProductState.emit(true);
            const evaluationContext = RuleEvaluationContext.fromProduct(oneProduct, this.user);
            this.backOfficeService.getEligibilities(evaluationContext).subscribe(eligibility => {
                oneProduct.afterSalesEligible = !!oneProduct.afterSalesEligible ? (eligibility && oneProduct.afterSalesEligible) : eligibility;
                oneProduct.quantity = 1;
                this.folderCreationService.productChanged(oneProduct);
            });
        } else {
            const productsContent = pageOfProducts.content || [] as any;
            this.productNotFound.emit(productsContent.length === 0 && page === 0);
            const orders = Object.keys(ProductFamilyType);
            productsContent.forEach(it => it.families = it.families.sort((a, b) => (orders.indexOf(a.type)) - (orders.indexOf(b.type))));
            if (productsContent.length === this.pageSize){
                productsContent.push({code: 'load', id: ''});
            }
            this.folderCreationService.productListChanged(productsContent);
            this.folderCreationService.productListIsFilteredResult(true);
        }
    }

    toggleAdditionalSearchInputs() {
        this.showAdditionalSearchInputs = !this.showAdditionalSearchInputs;
    }

    updateSupplier(supplier: Supplier): void {
        this.formGroup.controls['supplierCode'].setValue(supplier?.code);
        this.fieldSelected('supplierCode', supplier?.code);
    }

    updateBrand(brand: ProductBrand): void {
        this.formGroup.controls['brandCode'].setValue(brand?.code);
        this._brandLabel = brand?.label;
        this.fieldSelected('brandCode', brand?.code);
    }

    cleanAllForms() {
        Object.keys(this.formGroup.controls).forEach(key => {
            this.formGroup.controls[key].patchValue(null);
        });
        this.disableBrand$.next(false);
        this.disableSupplier$.next(false);
        this.disableField('productCode', false);
        this.disableField('manufacturerReference', false);
        this.disableField('eanCode', false);
        this.disableField('productLabel', false);
        this.folderCreationService.productChanged(null);
        this.folderCreationService.productListChanged(null);
        this.resetProductState.emit();
        this.loadedPages.clear();
        this.productNotFound.emit(false);
        this.folderCreationService.resetFilter();
        this._brandLabel = null;
    }

    disableField(fieldName: string, disable: boolean) {
        if (disable) {
            this.formGroup.controls[fieldName]?.disable();
        } else {
            this.formGroup.controls[fieldName]?.enable();
        }

    }


    fieldSelected(fieldName: string, data: any) {
        this.loadedPages.clear();
        if ('productCode' === fieldName) {
            this.productCodeSelected(!!data.target.value);
        } else if ('eanCode' === fieldName) {
            this.eanCodeSelected(!!data.target.value);
        } else if ('manufacturerReference' === fieldName) {
            this.manufacturerReferenceSelected(!!data.target.value);
        } else if ('productLabel' === fieldName) {
            this.other(!!data.target.value);
        } else {
            this.other(!!data);
        }
    }

    private productCodeSelected(isSelected: boolean) {
        this.disableField('productLabel', isSelected);
        this.disableField('manufacturerReference', isSelected);
        this.disableField('eanCode', isSelected);
        this.disableBrand$.next(isSelected);
        this.disableSupplier$.next(isSelected);
    }

    private eanCodeSelected(isSelected: boolean) {
        this.disableField('manufacturerReference', isSelected);
        this.disableField('productCode', isSelected);
        this.disableField('productLabel', isSelected);
        this.disableBrand$.next(isSelected);
        this.disableSupplier$.next(isSelected);
    }

    private manufacturerReferenceSelected(isSelected: boolean) {
        this.disableField('productLabel', isSelected);
        this.disableField('productCode', isSelected);
        this.disableField('eanCode', isSelected);
        this.disableBrand$.next(isSelected);
        this.disableSupplier$.next(isSelected);
    }

    private other(isSelected: boolean) {
        this.disableField('manufacturerReference', isSelected);
        this.disableField('productCode', isSelected);

    }

    identificationProductChanged($productCtrl: FormControl): void {
        this.identificationProductCtrl = $productCtrl;
        const product = $productCtrl.value as any;
        product.quantity = 1;
        this.formGroup.controls['productCode'].setValue(product.code);
        this.showProductState.emit(true);
        this.productNotFound.emit(false);
        this.folderCreationService.productChanged(product);
    }

    unknownProduct() {
        const dialogRef = this.dialog.open(UnknownProductComponent, {
            hasBackdrop: true,
            disableClose: false,
            data: { nomenclatureFields: this.nomenclatureFields }
        });
        this.folderCreationService.productChanged(null);
        this.showProductState.emit(false);
        this.anotherSubscription = dialogRef.afterClosed().subscribe((unknownProduct: UnknownProduct) => {
            if (unknownProduct) {
                this.folderCreationService.productChanged(unknownProduct?.toProduct());
                this.showProductState.emit(true);
            }
        });
    }


    private initConfiguration() {
        this.store$.pipe(
            select(currentUser),
            take(1)
        ).subscribe((user: UserState) => {
            if (user) {
                const attributes: GrowthbookAttributes = {
                    organizationCode: user.organizationCode,
                    context: user.context
                };
                from(this.growthBookService.isOn(AppFeatures.UNKNOWN_PRODUCT_SEARCH, attributes))
                    .subscribe((isFeatureActive: boolean) => {
                        this.unknownProductFeatureActive = isFeatureActive;
                        if (isFeatureActive) {
                            from(this.growthBookService.getFeatureValue(AppFeatures.UNKNOWN_PRODUCT_NOMENCLATURE, attributes, this.nomenclatureFields))
                                .subscribe((data) => {
                                    this.nomenclatureFields = data;
                                });
                        }
                    });
            }
        });
    }

    getPrefixForOrganizationCode(orgCode: string): string {
        if (orgCode.startsWith('LM')) {
            return 'LM';
        } else if (orgCode.startsWith('NR') || orgCode.startsWith('AU')) {
            return 'NR';
        } else {
            return 'OTHERS';
        }
    }


}
