<h1 mat-dialog-title>  {{'FOLDER.WORKFLOW.CANCEL_INTERVENTION' | translate}}</h1>
<div mat-dialog-content>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

        <form [formGroup]="registerForm" class="w-100-p">
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>{{'FOLDER.WORKFLOW.CANCELLATION_DESCRIPTION' | translate}}</mat-label>
                <textarea matInput formControlName="cancelIntervention" required></textarea>
                <mat-error>{{'CUSTOMER.REQUIRED_FIELD' | translate}}</mat-error>
            </mat-form-field>
        </form>
    </div>

</div>
<div>
<ng-container *ngIf="isRefundSuggestionPossible()">
    <h1 mat-dialog-title>  {{'FOLDER.WORKFLOW.PAYMENT.REFUND.TITLE' | translate}}</h1>
    <table class="w-100-p mat-table mat-elevation-z2 text-center mt-8" border="0">
        <thead>
        <tr>
            <th class="text-center">{{'COMPONENT.PAYMENT.PAYMENT_REASON' | translate}}</th>
            <th class="text-center">{{'COMPONENT.PAYMENT.PAYMENT_AMOUNT' | translate}}</th>
            <th class="text-center">{{'FOLDER.WORKFLOW.PAYMENT.REFUND' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of paymentsWrapper">
            <td>{{'COMPONENT.PAYMENT.PAYMENT_REASON.' + item.payment.paymentReason | translate}}</td>
            <td>{{item.payment.amount |currency:item.payment.currency :'symbol-narrow' | space}}</td>
            <td>
                <ng-container *ngIf="isRefundEnabled(item.payment)">
                    <mat-checkbox [(ngModel)]="item.selected" class="example-margin">
                    </mat-checkbox>
                </ng-container>
                <ng-container *ngIf="!isRefundEnabled(item.payment)">
                    <mat-icon>check</mat-icon>
                </ng-container>
            </td>
        </tr>
        </tbody>
    </table>
</ng-container>
</div>
<div mat-dialog-actions fxLayoutAlign="end center" fxLayoutGap="10">
    <button mat-button [mat-dialog-close]="null"
            class="aster_btn_cancel">{{'BUTTON.CLOSE' | translate}}</button>
    <button mat-button [disabled]="registerForm.invalid"
            (click)="refund()"
            [ngClass]="registerForm.invalid?'aster_btn_disabled':'aster_btn'"
            cdkFocusInitial>{{'BUTTON.VALIDATE' | translate}}</button>
</div>
