import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {currency, currentLanguage, hasImmobilization} from '../../../../../store/organization/organization.selectors';
import {TranslateService} from '@ngx-translate/core';
import {Unsubscriber} from '../../../../../unsubscriber';
import * as moment from 'moment';
import {combineLatest, Observable} from 'rxjs';
import {FileInfo} from '../../../../../models/file-info.model';
import {ReportUtils} from '../../../../../shared/utils/report-utils';
import {FolderSubjectService} from '../../../folder-subject.service';
import {FileService} from '../../../../../shared/services/file.service';
import {RegimeWarrantyEnum} from '../../../../../models/warrantyRule.model';
import {Quotation} from '../../../../../models/quotation.model';
import {getCurrencySymbol} from '@angular/common';
import {SparePartService} from '../../../../../shared/services/spare-part.service';
import {Payer} from '../../../../../models/enums/payer.enum';
import {TaskVariables} from '../../task.variables';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-product-retrieval',
    templateUrl: './waiting-product-retrieval.component.html',
    styleUrls: ['./waiting-product-retrieval.component.scss']
})
export class WaitingProductRetrievalComponent extends Unsubscriber implements OnInit {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();
    retrievalDone: boolean;

    warrantyCode: string;
    immobilizationMessage$: Observable<string>;
    hasImmobilization: boolean;
    isProlongedDiagnosis: boolean;
    file: FileInfo;
    accessoriesLabel: string[] = [];
    remainingAmount: String;
    description$: Observable<string>;
    currency: string;
    quotationId: string;
    quotation: Quotation;
    languageKey: string;

    constructor(private store$: Store<AppState>,
                private translateService: TranslateService,
                private folderSubjectService: FolderSubjectService,
                private sparePartService: SparePartService,
                private fileService: FileService) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = combineLatest([
            this.store$.pipe(select(currentLanguage)),
            this.store$.pipe(select(hasImmobilization)),
            this.folderSubjectService.folder$
        ]).subscribe(([languageKey, hasImmobilization, folder]) => {
            this.warrantyCode = this.folder.newWarranty.warranty;
            this.languageKey = languageKey;
            this.buildMessage(languageKey);
            this.hasImmobilization = this.warrantyCode === 'SG' && hasImmobilization;
            this.file = ReportUtils.findReportByCode(folder.attachments, 'RESTITUATION_REPORT_' + this.warrantyCode);
            if (folder.productAccessories) {
                this.accessoriesLabel = folder.productAccessories.map(productAccessory => productAccessory.label);
            }
            if (this.folder.newWarranty.warranty === RegimeWarrantyEnum.HG) {
                this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => this.currency = currency);
                this.quotation = this.getLastQuotation();
                if (this.QuotationAcceptedAndProductRepairable()) {
                    this.getRemainingAmount();
                }
            }
        });
        this.isProlongedDiagnosis = Boolean(this.variablesTask[TaskVariables.PROLONGED_DIAGNOSIS]);
    }

    private QuotationAcceptedAndProductRepairable() {
        return !!this.variablesTask['quotationAccepted'] &&
            (this.variablesTask['quotationAccepted'] === 'true' || this.variablesTask['quotationAccepted'] === 'ACCEPTED') &&
            !!this.variablesTask['reparationStatus'] && this.variablesTask['reparationStatus'] !== 'NOT_REPAIRABLE';
    }

    onSubmit(): void {
        this.inputMap.emit({});
    }

    private buildMessage(language): void {
        this.immobilizationMessage$ = this.translateService.stream('FOLDER.IMMOBILIZATION.DURATION',
            {
                days: this.folder.immobilization?.immobilizationDays,
                immobilizationBeginDate: this.formatImmobilizationDate(this.folder?.immobilization?.immobilizationBeginDate, language),
                immobilizationEndDate: this.formatImmobilizationDate(this.folder?.immobilization?.immobilizationEndDate, language),
            });
    }

    private formatImmobilizationDate(date, language) {
        return moment(date, moment.ISO_8601, language).locale(language).format('L');
    }

    getReportAttachment(): void {
        this.fileService.downloadFile(this.file);
    }

    private getRemainingAmount() {
        if (!!this.quotation?.totalPrice?.value ) {
            const totalPrice = this.getTotalPriceCustomerQuotation();
            if (totalPrice > 0) {
                this.remainingAmount = this.sparePartService.formatPriceAccordingToCurrency(this.sparePartService.normalizeFloat(totalPrice), this.currency);
            }
            this.buildMessageRemainingAmount();
        }
    }

    private getTotalPriceCustomerQuotation(): number {
        return this.sparePartService.computeTotalPrice(this.quotation.quotationLines
            .filter(value => value.payer === Payer.CLIENT)
            .map(value => value.totalLinePrice.value));
    }

    private getLastQuotation(): Quotation {
        return this.folder.quotations.length > 0 ? this.folder.quotations.reduce((quotation1, quotation2) => {
            return quotation1.userAction.actionDate > quotation2.userAction.actionDate ? quotation1 : quotation2;
        }) : null;
    }

    private buildMessageRemainingAmount(): void {
        if (!!this.remainingAmount) {
            this.description$ = this.translateService.stream('COMPONENT.REMAINING_AMOUNT.DESCRIPTION',
                {
                    price: this.remainingAmount,
                    currency: getCurrencySymbol(this.currency || this.currency, 'narrow')
                });
        }
    }
}
