import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Carrier, CarrierExtraInfo, ProductDeliveryRule} from '../../../models/product-delivery-rule';
import {MatTableDataSource} from '@angular/material/table';
import {BackOfficeService} from '../../services/back-office.service';
import {SnackBarService} from '../../services/snack-bar.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {RuleEvaluationContext} from '../../../models/rules/RuleEvaluationContext';
import {DeliveryModeEnum} from '../../../models/enums/deliveryMode.enum';
import {EXPEDITION, PICKUP} from '../../../main/folder/folder-workflow/service-center-repair-process/confirm-carrier-and-repairer/confirm-carrier-and-repairer.component';
import {MatRadioChange} from '@angular/material/radio';
import {Folder} from '../../../models/folder.model';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {switchMap} from 'rxjs/operators';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {of} from 'rxjs';

@Component({
    selector: 'app-choose-carrier',
    templateUrl: './choose-carrier.component.html',
    styleUrls: ['./choose-carrier.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ChooseCarrierComponent implements OnInit {
    @Input() folder: Folder;
    @Output() carrierExtraDataEvent = new EventEmitter<any>();
    @Output() carrierCode = new EventEmitter<any>();
    @Output() selectedTransportModeEvent = new EventEmitter<any>();

    singleCarrier = false;
    productDeliveryRule: ProductDeliveryRule;
    carriersDataSource = new MatTableDataSource<Carrier>();
    carriersColumns: string[] = [];
    transportModes: string[] = [];
    selectedCarrier: Carrier;
    selectedTransportMode: string;
    isPickup: boolean;
    isPickupSelected = false;
    isExpedition: boolean;
    isExpeditionSelected: boolean;
    expandedElement: Carrier | null = null;
    insuranceValue: number;
    isInsuranceEnabled: boolean;
    isProductDanger: boolean;
    carrierExtraInfo: CarrierExtraInfo = {};
    CARRIER = 'CARRIER';

    constructor(private backOfficeService: BackOfficeService,
                private snackBar: SnackBarService,
                private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.initProductDelivery();
    }

    private initProductDelivery(): void {
        this.store$.dispatch(new StartLoading());
        this.backOfficeService.getProductDeliveryRule(RuleEvaluationContext.fromFolder(this.folder)).pipe(
            switchMap(productDeliveryRule => {
                this.productDeliveryRule = productDeliveryRule;
                this.carriersColumns = ['type', 'code', 'label'];
                const carriers = (!!this.productDeliveryRule.productDeliveryModesFromStore &&
                    this.productDeliveryRule.productDeliveryModesFromStore[DeliveryModeEnum.STORE_TO_REPAIRER]) || this.productDeliveryRule.carriers;
                if (!carriers) {
                    return of([]);
                }
                if (carriers.length === 1) {
                    this.selectedCarrier = carriers[0];
                    this.singleCarrier = true;
                    this.carrierCode.emit(this.selectedCarrier?.code);
                } else {
                    this.carriersColumns.push('selection');
                }
                return this.backOfficeService.getCarriersList(carriers);
            })
        ).subscribe(value => {
            this.carriersDataSource.data = value;
            if (this.carriersDataSource.data?.length !== 0) {
                this.initCarrierEligibility(this.carriersDataSource.data);
            } else {
                this.selectedCarrier = null;
            }
            this.computeTransportModes();
            this.store$.dispatch(new StopLoading());
        }, () => this.store$.dispatch(new StopLoading()));
    }

    private computeCarriers(): void {
        this.carriersColumns = ['type', 'code', 'label'];
        const carriers = (!!this.productDeliveryRule.productDeliveryModesFromStore &&
            this.productDeliveryRule.productDeliveryModesFromStore[DeliveryModeEnum.STORE_TO_REPAIRER]) || this.productDeliveryRule.carriers;
        if (!carriers) {
            return;
        }
        if (carriers.length === 1) {
            this.selectedCarrier = carriers[0];
            this.singleCarrier = true;
            this.carrierCode.emit(this.selectedCarrier?.code);
        } else {
            this.carriersColumns.push('selection');
        }
        this.backOfficeService.getCarriersList(carriers).subscribe(value => {
                this.carriersDataSource.data = value;
                if (this.carriersDataSource.data?.length !== 0) {
                    this.initCarrierEligibility(this.carriersDataSource.data);
                } else {
                    this.selectedCarrier = null;
                    this.carrierCode.emit(null);
                    this.checkCarrierAvailability();
                }
            }
        );
    }

    checkCarrierAvailability() {
        if (this.selectedTransportMode === EXPEDITION && this.carriersDataSource.data?.length === 0) {
            this.snackBar.openAtEnd('Error', 'CARRIER.OR_REPAIRER.NOT_FOUND.DEFAULT_TEXT');
        }
    }

    private computeTransportModes(): void {
        if (!!this.productDeliveryRule.productDeliveryModesFromStore &&
            !!this.productDeliveryRule.productDeliveryModesFromStore[DeliveryModeEnum.PICKING_FROM_STORE]) {
            this.transportModes.push(PICKUP);
            this.isPickup = true;
        }
        if (!!this.productDeliveryRule.productDeliveryModesFromStore
            && !!this.productDeliveryRule.productDeliveryModesFromStore[DeliveryModeEnum.STORE_TO_REPAIRER]
            && this.productDeliveryRule.productDeliveryModesFromStore[DeliveryModeEnum.STORE_TO_REPAIRER].length > 0) {
            this.transportModes.push(EXPEDITION);
            this.isExpedition = true;
        }
        if (this.isPickup && !this.isExpedition) {
            this.isPickupSelected = true;
            this.selectedTransportMode = PICKUP;
        }
        if (this.isExpedition && !this.isPickup) {
            this.isExpeditionSelected = true;
            this.selectedTransportMode = EXPEDITION;
            this.checkCarrierAvailability();
        }
        this.selectedTransportModeEvent.emit(this.selectedTransportMode);
    }

    carrierExtraData(carrier: Carrier) {
        this.selectedCarrier = carrier;
        this.carrierCode.emit(carrier?.code);
        if (!!carrier.extra && (carrier.extra?.isProductDangerEnabled === true || carrier.extra?.isInsuranceEnabled === true)) {
            carrier.extra.isProductDanger = false;
            carrier.extra.insurance = false;
            carrier.extra.insuranceValue = null;
            return this.expandedElement = this.expandedElement === carrier ? null : carrier;
        }
    }

    onSelectTransportMode($event: MatRadioChange): void {
        const transportMode = $event.value;
        if (transportMode === PICKUP) {
            this.isPickupSelected = true;
            this.isExpeditionSelected = false;
            this.selectedCarrier = null;
            this.carrierCode.emit(null);
            this.carrierExtraDataEvent.emit(null);
        }
        if (transportMode === EXPEDITION) {
            this.isExpeditionSelected = true;
            this.isPickupSelected = false;
            this.computeCarriers();
        }
        this.selectedTransportMode = transportMode;
        this.insuranceValue = null;
        this.isInsuranceEnabled = false;
        this.selectedTransportModeEvent.emit(this.selectedTransportMode);
    }


    toggle($event: MatSlideToggleChange, carrier: Carrier) {
        carrier.extra.insurance = $event.checked;
        this.carrierExtraInfo.insurance = $event.checked;
        this.carrierExtraInfo.insuranceValue =  carrier.extra.insuranceValue;
        this.carrierExtraDataEvent.emit(this.carrierExtraInfo);
    }

    toggleProductState($event: MatSlideToggleChange, carrier: Carrier) {
        carrier.extra.isProductDanger = $event.checked;
        this.carrierExtraInfo.isProductDanger = $event.checked;
        this.carrierExtraDataEvent.emit(this.carrierExtraInfo);
    }

    initCarrierEligibility(carriers: Carrier[]) {
        carriers.map(carrier => {
            if (carrier.extra) {
                carrier.extra.isProductDangerEnabled = carrier.extra?.isProductDanger;
                carrier.extra.isInsuranceEnabled = carrier.extra?.insurance;
            }
        });
    }

    emitInsurrancePrice(price: number) {
        this.carrierExtraInfo.insuranceValue = price;
        this.carrierExtraDataEvent.emit(this.carrierExtraInfo);
    }
}
