export class Contact {
    phoneNumber: string;
    mobileNumber?: string;
    email?: string;
    website?: string;
    fax?: string;

    constructor() {
    }
}
