<div class="page-layout blank p-24" fxLayout="column" fxLayoutAlign="start center" fusePerfectScrollbar>
    <div fxLayout="row" class="w-100-p mb-24" fxLayoutAlign="space-between center">

        <div>
            <h1 class="text-bold">{{'CODE_LABEL_CONFIG.CONFIG_CODES_TITLES.' + configCode | translate}}</h1>
            <h4 class="mt-0 secondary-text">{{'NAV.CONFIGURATIONS.HEADER' | translate}}</h4>
        </div>

        <div fxLayoutAlign="end end" fxLayoutGap="10px">
            <button type="button" mat-stroked-button (click)="onUnarchive()"
                    class=" aster-btn_border"
                    *ngIf="!!codeLabelObjectsDataSource.filterForm">
                {{'CODE_LABEL_CONFIG.LIST.IMPORT_BUTTON' | translate}}
            </button>
            <button type="button" mat-stroked-button (click)="onCreateNew()"
                    class="aster-btn_border"
                    *ngIf="!!codeLabelObjectsDataSource.filterForm">

                {{'CODE_LABEL_CONFIG.LIST.CREATE_BUTTON' | translate}}
            </button>
        </div>

    </div>

    <div fxLayout="column" class="w-100-p overflow ">
        <ng-container *ngIf="!!codeLabelObjectsDataSource.filterForm">
            <form [formGroup]="codeLabelObjectsDataSource.filterForm" (ngSubmit)="onSearch()">
                <table class="w-100-p" mat-table multiTemplateDataRows
                       [dataSource]="codeLabelObjectsDataSource.dataSource"
                       *ngIf="!!codeLabelObjectsDataSource.dataSource">
                    <ng-container matColumnDef="organizationCode">
                        <th class="header pt-16 px-16 max-w-150" mat-header-cell *matHeaderCellDef>
                            <div class="title-header_description">
                                {{('CODE_LABEL_CONFIG.LIST.GRID_HEADER_ORGANIZATION' | translate)}}
                            </div>
                        </th>
                        <td mat-cell class="px-16" *matCellDef="let el">{{el.organizationCode}}</td>
                    </ng-container>

                    <ng-container matColumnDef="context">
                        <th class="header pt-16 px-16 max-w-150" mat-header-cell *matHeaderCellDef>
                            <div class="title-header_description">
                                {{('CODE_LABEL_CONFIG.LIST.GRID_HEADER_CONTEXT' | translate)}}
                            </div>

                        </th>
                        <td mat-cell class="px-16" *matCellDef="let el">{{el.context}}</td>
                    </ng-container>

                    <ng-container matColumnDef="businessLink">
                        <th class="header pt-16 px-16 max-w-150" mat-header-cell *matHeaderCellDef>
                            <div>
                                {{('CODE_LABEL_CONFIG.LIST.GRID_HEADER_BUSINESS_LINK' | translate)}}
                            </div>
                            <mat-form-field class="filter w-100-p" floatLabel="never">
                                <input matInput
                                       (keyup.backspace)="resetSearch(codeLabelObjectsDataSource.filterForm.value.businessLink)"
                                       [formControl]="codeLabelObjectsDataSource.filterForm.get(['businessLink'])">
                                <mat-icon matSuffix class="disabled-text"
                                          *ngIf="codeLabelObjectsDataSource.filterForm.value.businessLink.length === 0">
                                    search
                                </mat-icon>
                                <button type="button" mat-icon-button matSuffix class="disabled-text"
                                        *ngIf="codeLabelObjectsDataSource.filterForm.value.businessLink.length > 0"
                                        color="primary" (click)="onClearSearchFormFiled('businessLink')">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>
                        </th>
                        <td mat-cell class="px-16" *matCellDef="let el">{{el.businessLink}}</td>
                    </ng-container>

                    <ng-container matColumnDef="siteCode">
                        <th class="header pt-16 px-16 max-w-150" mat-header-cell *matHeaderCellDef>
                            <div>
                                {{('CODE_LABEL_CONFIG.LIST.GRID_HEADER_SITE' | translate)}}
                            </div>
                            <mat-form-field class="filter w-100-p" floatLabel="never">
                                <input matInput
                                       (keyup.backspace)="resetSearch(codeLabelObjectsDataSource.filterForm.value.siteCode)"
                                       [formControl]="codeLabelObjectsDataSource.filterForm.get(['siteCode'])">
                                <mat-icon matSuffix class="disabled-text"
                                          *ngIf="codeLabelObjectsDataSource.filterForm.value.siteCode.length === 0">
                                    search
                                </mat-icon>
                                <button type="button" mat-icon-button matSuffix class="disabled-text"
                                        *ngIf="codeLabelObjectsDataSource.filterForm.value.siteCode.length > 0"
                                        color="primary" (click)="onClearSearchFormFiled('siteCode')">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>
                        </th>
                        <td mat-cell class="px-16" *matCellDef="let el">{{el.siteCode}}</td>
                    </ng-container>

                    <ng-container matColumnDef="user">
                        <th class="header pt-16 px-16 max-w-150" mat-header-cell *matHeaderCellDef>
                            <div>
                                {{('CODE_LABEL_CONFIG.LIST.GRID_HEADER_USER' | translate)}}
                            </div>
                            <mat-form-field class="filter w-100-p" floatLabel="never">
                                <input matInput
                                       (keyup.backspace)="resetSearch(codeLabelObjectsDataSource.filterForm.value.user)"
                                       [formControl]="codeLabelObjectsDataSource.filterForm.get(['user'])">
                                <mat-icon matSuffix class="disabled-text"
                                          *ngIf="codeLabelObjectsDataSource.filterForm.value.user.length === 0">
                                    search
                                </mat-icon>
                                <button type="button" mat-icon-button matSuffix class="disabled-text"
                                        *ngIf="codeLabelObjectsDataSource.filterForm.value.user.length > 0"
                                        color="primary" (click)="onClearSearchFormFiled('user')">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>
                        </th>
                        <td mat-cell class="px-16" *matCellDef="let el">{{el.user}}</td>
                    </ng-container>

                    <ng-container matColumnDef="role">
                        <th class="header pt-16 px-16 max-w-150" mat-header-cell *matHeaderCellDef>
                            <div>
                                {{('CODE_LABEL_CONFIG.LIST.GRID_HEADER_ROLE' | translate)}}
                            </div>
                            <mat-form-field class="filter w-100-p" floatLabel="never">
                                <input matInput
                                       (keyup.backspace)="resetSearch(codeLabelObjectsDataSource.filterForm.value.role)"
                                       [formControl]="codeLabelObjectsDataSource.filterForm.get(['role'])">
                                <mat-icon matSuffix class="disabled-text"
                                          *ngIf="codeLabelObjectsDataSource.filterForm.value.role.length === 0">
                                    search
                                </mat-icon>
                                <button type="button" mat-icon-button matSuffix class="disabled-text"
                                        *ngIf="codeLabelObjectsDataSource.filterForm.value.role.length > 0"
                                        color="primary" (click)="onClearSearchFormFiled('role')">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>
                        </th>
                        <td mat-cell class="px-16" *matCellDef="let el">{{el.role}}</td>
                    </ng-container>

                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="mt-16">

                                <button type="submit" mat-icon-button aria-label="Submit"
                                        [disabled]="disableSearchDashboard()"
                                        [ngClass]="disableSearchDashboard()?'aster_accent_disabled':'aster_accent'">
                                    <mat-icon>arrow_forward</mat-icon>
                                </button>
                                <button type="button" mat-icon-button aria-label="reset " class="aster_accent"
                                        (click)="onResetSearchFilter()">
                                    <mat-icon>settings_backup_restore</mat-icon>
                                </button>
                            </div>
                        </th>
                        <td mat-cell class="py-4" *matCellDef="let el; let i = dataIndex;">
                            <div fxFlex="row" fxLayoutAlign="center" fxLayoutGap="10px">
                                <button type="button" mat-icon-button class="aster_accent" (click)="onEdit(el)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button type="button" mat-icon-button color="warn" (click)="onDelete(i,el)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                                <button type="button" mat-icon-button class="aster_accent" (click)="onArchive(i,el)">
                                    <mat-icon>archive</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let el"
                            [attr.colspan]="codeLabelObjectsDataSource.columnsToDisplay.length">
                            <div class="example-element-detail"
                                 [@detailExpand]="!!codeLabelObjectsDataSource.expandedElement && codeLabelObjectsDataSource.expandedElement.id === el.id ? 'expanded' : 'collapsed'">
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="codeLabelObjectsDataSource.columnsToDisplay"></tr>
                    <tr mat-row
                        *matRowDef="let el; columns: codeLabelObjectsDataSource.columnsToDisplay"
                        class="example-element-row"
                        [class.example-expanded-row]="!!codeLabelObjectsDataSource.expandedElement && codeLabelObjectsDataSource.expandedElement.id === el.id"
                        (click)="codeLabelObjectsDataSource.expandedElement = !!codeLabelObjectsDataSource.expandedElement && codeLabelObjectsDataSource.expandedElement.id === el.id ? null : el"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                </table>
            </form>
        </ng-container>
    </div>
    <mat-paginator *ngIf="!!codeLabelObjectsDataSource.page" [length]="codeLabelObjectsDataSource.page.totalElements"
                   (page)="paginate($event)" showFirstLastButtons
                   [pageSize]="codeLabelObjectsDataSource.page.size"
                   [pageSizeOptions]="codeLabelObjectsDataSource.pageSizeOptions"
                   [pageIndex]="getPageIndex()" class="w-100-p"></mat-paginator>
</div>

