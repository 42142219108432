import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilesSliderComponent} from './files-slider.component';
import {Ng2CarouselamosModule} from 'ng2-carouselamos';
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [FilesSliderComponent],
    exports: [
        FilesSliderComponent
    ],
    imports: [
        CommonModule,
        Ng2CarouselamosModule,
        MatIconModule,
        TranslateModule,
    ]
})
export class FilesSliderModule { }
