import {Component, OnInit} from '@angular/core';
import {CIVILITY_OPTIONS, CUSTOMER_FIELDS} from '../../../../../models/customer/customer.constants';
import {FolderCreationService} from '../../folder-creation.service';
import {CustomerApiInfo} from '../../../../../models/customer/customer-api-info.model';
import {Address, Customer} from '../../../../../models/customer/customer.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {ResetCustomer, ResetCustomerSearchResult, SearchCustomer} from '../../../../../store/customer/customer.actions';
import {Unsubscriber} from '../../../../../unsubscriber';
import {addressInput, customer, customerApiInfo, customerReferenceExist, isProfessional, isStockClaimCreation} from '../../../../../store/customer/customer.selectors';
import {Observable} from 'rxjs';
import {skipWhile, switchMap, take} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';
import {currentUser, isNeitherSAVNorProductRecall} from '../../../../../store/user/user.selectors';
import {CustomerUtils} from '../../../../../store/customer/customer.utils';
import {InvoicesItemsListComponent} from '../../invoices-items-list/invoices-items-list.component';
import {MatDialog} from '@angular/material/dialog';
import {GrowthbookService} from '../../../../../shared/services/growthbook.service';
import {UserState} from '../../../../../store/user/user.state';
import {AppFeatures} from '../../../../../shared/features/app-features';
import {GrowthbookAttributes} from '../../../../../shared/features/growthbook-attributes';

@Component({
    selector: 'app-customer-form',
    templateUrl: './customer-form.component.html',
    styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent extends Unsubscriber implements OnInit {

    customer: Customer;
    customerApiInfo: CustomerApiInfo;
    customerForm: FormGroup;
    isProfessional$: Observable<boolean>;
    isStockClaimCreation$: Observable<boolean>;
    customerReferenceExist$: Observable<boolean>;
    addressInput$: Observable<Address>;
    isNeitherSAVNorProductRecall$: Observable<boolean>;

    fields = CUSTOMER_FIELDS;
    civilityOptions = CIVILITY_OPTIONS;
    searchCustomerInvoicesFeature: Observable<boolean>;

    constructor(private folderCreationService: FolderCreationService,
                private store$: Store<AppState>,
                public dialog: MatDialog,
                private growthBookService: GrowthbookService) {
        super();
    }

    ngOnInit(): void {
        this.store$.pipe(
            select(customerApiInfo),
            skipWhile(apiInfo => !apiInfo),
            take(1)
        ).subscribe(apiInfo => {
            this.customerApiInfo = apiInfo;
            this.customerForm = CustomerUtils.initializeForm(apiInfo.requiredFields);
        });

        this.anotherSubscription = this.store$.pipe(select(customer))
            .subscribe(customer => this.customer = customer);

        this.isProfessional$ = this.store$.pipe(select(isProfessional));
        this.isStockClaimCreation$ = this.store$.pipe(select(isStockClaimCreation));
        this.customerReferenceExist$ = this.store$.pipe(select(customerReferenceExist));
        this.addressInput$ = this.store$.pipe(select(addressInput));
        this.isNeitherSAVNorProductRecall$ = this.store$.pipe(select(isNeitherSAVNorProductRecall));

        this.searchCustomerFromInvoice();

        this.anotherSubscription = this.searchCustomerInvoicesFeature = this.store$.pipe(select(currentUser))
            .pipe(
                switchMap((user: UserState) => {
                    const attributes: GrowthbookAttributes = {
                        organizationCode: user.organizationCode,
                        context: user.context,
                    };
                    return this.growthBookService.isOn(AppFeatures.SEARCH_CUSTOMER_INVOICES, attributes);
                }));
    }

    private searchCustomerFromInvoice(): void {
        this.anotherSubscription = this.folderCreationService.invoice$
            .subscribe(invoice => {
                if (!invoice || !invoice.customerCode) {
                    this.store$.dispatch(new ResetCustomerSearchResult());
                } else if (this.customerApiInfo?.searchFromInvoice && this.customer.reference !== invoice.customerCode) {
                    this.store$.dispatch(new SearchCustomer({reference: invoice.customerCode} as Customer));
                }
            });
    }

    findInvoiceByClient(): void {
        this.dialog.open(InvoicesItemsListComponent, {
            minWidth: '70%',
            minHeight: '70%',
            width: '90%',
            height: '80%',
            data: {
                customerReference: this.customer.reference
            }
        });
    }

    searchCustomer(fieldName?: string): void {
        if (fieldName && fieldName === 'reference' || !this.customer.reference) {
            this.store$.dispatch(new SearchCustomer(this.customer));
        }
    }

    resetCustomer() {
        this.store$.dispatch(new ResetCustomer());
        this.store$.dispatch(new ResetCustomerSearchResult());
    }

}
