<app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
    <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px" class="mt-25">
            <div class="mt-12" *ngIf="!reservationHasAnomalie">
                <div class="custom-badge-container">
                    <span class="custom-badge blinking"
                          matBadge="&#8288;"
                          matBadgePosition="before">

                    </span>
                    <strong>{{('FOLDER.SHIPMENT.LABEL_GENERATION') |translate}}</strong>
                </div>
            </div>
            <div class="mt-12" *ngIf="!!shipmentMode">
                <strong>{{'COMPONENT.SHIPMENT.EVENT_TYPE' | translate}}</strong>: {{'FOLDER.PARCEL_TO_SEND.RECIPIENT.' + shipmentMode | translate}}
            </div>
            <div class="mt-12" *ngIf="!!carrier">
                <strong>{{'CODE_LABEL_CONFIG.CONFIG_CODES.CARRIER' | translate}}</strong>: {{carrier.label}}
            </div>
            <div class="mt-12" *ngIf="!!trackingNumber">
                <strong>{{'FOLDER.HANDLE_RESERVATION_ANOMALY.TRACKING_NUMBER' | translate}}</strong>:

                <ng-container *ngIf="reservationHasAnomalie; else showLink">
                    {{trackingNumber}}
                </ng-container>
                <ng-template #showLink>
                    <a  [href]="currentShipment?.trackingURL" target="_blank" class="cursor-pointer" >
                        {{trackingNumber}}
                    </a>
                </ng-template>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
            <table mat-table [dataSource]="eventsShipmentDataTable"
                   class="mat-elevation-z4 w-100-p mat-table" *ngIf="!!hasEvents">
                <ng-container matColumnDef="type">
                    <th class="table-header" mat-header-cell *matHeaderCellDef>  {{'COMPONENT.SHIPMENT.EVENT_TYPE' | translate}}  </th>
                    <td mat-cell *matCellDef="let element" > {{'COMPONENT.SHIPMENT.EVENT_TYPE.' + element.eventType | translate}} </td>
                </ng-container>
                <ng-container matColumnDef="details">
                    <th class="table-header" mat-header-cell *matHeaderCellDef>  {{'COMPONENT.SHIPMENT.LABEL' | translate}}  </th>
                    <td mat-cell  *matCellDef="let element" > {{element.label}} </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th class="table-header" mat-header-cell *matHeaderCellDef >  {{'COMPONENT.SHIPMENT.DATE' | translate}}  </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.date| moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate) : (currentLanguage$ | async)}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="eventsShipmentColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: eventsShipmentColumns;"></tr>
            </table>
        </div>

        <div class="mt-12">
            <mat-checkbox [id]="'shipment_delivered'" [(ngModel)]="shipmentDelivered">{{'BUTTON.SHIPMENT_DELIVERED' | translate}}</mat-checkbox>
        </div>

        <div class="mt-24">
            <div fxLayout="row" fxLayoutAlign="end end">
                <div>
                    <button mat-stroked-button (click)="onSubmit()" type="submit"
                            [disabled]="!shipmentDelivered"
                            [ngClass]="!shipmentDelivered ? 'aster_btn_disabled' : 'aster_btn' ">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </mat-card-content>