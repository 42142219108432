import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Folder} from '../../../models/folder.model';
import {FileService} from '../../../shared/services/file.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CampaignService} from '../../../shared/services/campaign.service';
import {SnackBarService} from '../../../shared/services/snack-bar.service';


@Component({
    selector: 'app-sending-protocol-notification',
    templateUrl: './sending-protocol-notification.component.html',
    styleUrls: ['./sending-protocol-notification.component.scss']
})
export class SendingProtocolNotificationComponent implements OnInit {
    protected _onDestroy = new Subject<void>();
    @ViewChild('dropzone') dropzone: ElementRef;

    notificationForm: any = {
        form: null,
        template: null
    };
    sendingInProgress = false;
    fileDetail;

    constructor(public dialogRef: MatDialogRef<SendingProtocolNotificationComponent>,
                @Inject(MAT_DIALOG_DATA) public folder: Folder,
                private snackBar: SnackBarService,
                public dialog: MatDialog,
                private fileService: FileService,
                private campaignService: CampaignService,
    ) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.notificationForm.form = new FormGroup({
            formula: new FormControl(null, Validators.required),
        });
    }

    onUploadFile($event: any): void {
        const fileChange = this.fileService.onFileChange($event);
        fileChange.reader.onload = () => {
            const fileBlob = this.fileService.dataURItoBlob(fileChange.reader.result.toString().split(',')[1]);
            console.log('event : ', $event[0]);
            this.fileDetail = {
                info: $event[0],
                file: fileBlob
            };
            this.notificationForm.template = this.fileDetail;
            // @ts-ignore
            this.dropzone.reset();
        };
    }

    deleteImage(): void {
        this.notificationForm.template = null;
        this.fileDetail.info = null;
        this.fileDetail.file = null;
    }

    onSubmit(): void {
        this.sendingInProgress = true;
        const fileUploaded = new File([this.fileDetail.file], this.fileDetail.info.name, {type: this.fileDetail.info.type, lastModified: Date.now()});

        this.campaignService.sendProtocolNotification(this.notificationForm.form.value.formula, this.folder.id, fileUploaded).subscribe(res => {
            this.sendingInProgress = false;
            if (!!res) {
                this.snackBar.openWithIcon('FOLDER.SEND_COMMUNICATION_SUCCESS', 'Success');
            }
        }, error => {
            this.snackBar.openWithIcon('ERRORS.TECHNICAL.COMMON', 'Error');
            this.sendingInProgress = false;
        });
    }

    isFormValid() {
        if (this.notificationForm.template == null) {
            return false;
        }
        return this.notificationForm.form.valid;
    }

    closeDialog(): void {
        this.dialogRef.close(null);
    }
    @HostListener('document:keydown', ['$event'])
    onKeyDownHandler(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.dialogRef.close(null);
        }
    }
}
