
    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start center">
            <div>
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>
        </div>

        <ng-container>
            <div class="mt-24">
                <div>
                    <mat-checkbox
                        [(ngModel)]="refundDone">{{'BUTTON.WAITING_REFUND' | translate}}</mat-checkbox>
                </div>
                <div fxLayout="row" fxLayoutAlign="end">
                    <button mat-stroked-button (click)="onSubmit()"
                            type="submit"
                            [disabled]="!refundDone"
                            [ngClass]="!refundDone?'h-60 mt-4  aster_btn_disabled':'h-60 mt-4 aster_btn'">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </div>
        </ng-container>
    </mat-card-content>