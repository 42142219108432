import {AppState} from '../app.state';
import {createSelector} from '@ngrx/store';
import {ConfigurationState} from './configuration.state';
import {ConfigurationReferential} from '../../models/configurationReferential.model';
import {ConfigCodeMapsEnum} from '../../shared/services/configuration-item-enum';
import {isLeroyMerlin} from '../user/user.selectors';
import {ConfigurationUtils} from './configuration.utils';

const configurationState = (state: AppState) => state.configuration;

const configurationMap = createSelector(
    configurationState,
    (configuration: ConfigurationState) => configuration.configurationMap
);

export const multipleConfiguration = createSelector(
    configurationMap,
    (configurationMap: Map<string, ConfigurationReferential[]>, props) =>
        props.configurationItemCodes
            .map(item => configurationMap[item])
            .filter(item => item)
            .reduce((item1, item2) => item1.concat(item2), [])
);

export const conditions = createSelector(
    configurationMap,
    isLeroyMerlin,
    (configurationMap, isLM) => {
        const conditions = configurationMap[ConfigCodeMapsEnum.CONDITIONS] ?? [];
        if (!isLM) {
            return [
                ...conditions,
                {
                    id: 'CONSTANT',
                    code: 'CONSTANT',
                    label: 'COMPONENT.CONSTANT.CONDITION'
                }];
        }
        return conditions;
    }
);

export const allConfiguration = createSelector(
    configurationMap,
    conditions,
    (configurationMap, conditions, props) =>
        props.configurationItemCode === ConfigCodeMapsEnum.CONDITIONS ? conditions : configurationMap[props.configurationItemCode] ?? []
);

export const configurationItem = createSelector(
    allConfiguration,
    (configurations, props) => configurations.find(item => item.code.toLowerCase() === props.code?.toLowerCase())?.label ?? props.code
);
export const configurationType = createSelector(
    allConfiguration,
    (configurations, props) => configurations.find(item => item.code.toLowerCase() === props.code?.toLowerCase())?.type ?? ''
);
export const cacheConfigObject = createSelector(
    allConfiguration,
    (configurations, props) => configurations.find(item => item.code.toLowerCase() === props.code?.toLowerCase()) ?? {'code': props.code}
);
export const condition = createSelector(
    conditions,
    (conditionList, props) =>
        ConfigurationUtils.findConfigurationItemLabelOrDefault(props.faultCodeCondition, 'CONSTANT', conditionList, 'COMPONENT.CONSTANT.CONDITION')
);

export const symptom = createSelector(
    configurationMap,
    (configurationMap, props) =>
        ConfigurationUtils.findConfigurationItemLabelOrDefault(props.faultCode, 'CUSTOMER_NOT_FOUND_PANNE_CODE', configurationMap[ConfigCodeMapsEnum.SYMPTOMS])
);

export const allSites = createSelector(
    configurationMap,
    (configurationMap: Map<string, ConfigurationReferential[]>) => {
        const allSites = [{id: '', code: '*', label: 'ALL', parent: ''}];
        return configurationMap[ConfigCodeMapsEnum.SITE] ? allSites.concat(configurationMap[ConfigCodeMapsEnum.SITE]) : allSites;
    }
);

export const filteredSite = createSelector(
    allSites,
    (allSites: ConfigurationReferential[], props) =>
        props.currentSite ? ConfigurationUtils.findByCodeOrLabel(props.currentSite, allSites) : allSites
);

