<div fxLayout="row">
    <div fxFlex="50" class="header">
        {{title | translate}}
    </div>
    <div fxFlex>
        <ng-container *ngIf="!isLoading else LOADER">
            {{value ? value : '-'}}
        </ng-container>
        <ng-template #LOADER>
            <div class="spinner-wrapper">
                <mat-spinner diameter="20"></mat-spinner>
            </div>
        </ng-template>
    </div>
</div>