import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarNotifComponent} from '../snackbar-notif/snackbar-notif.component';
import {MatSnackBarHorizontalPosition} from '@angular/material/snack-bar/snack-bar-config';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {

    constructor(private snackBar: MatSnackBar,
                private translateService: TranslateService) {
    }

    openAtStart(snackType: string, message: string, params?: any) {
        this.open(snackType, 'start', message, params);
    }

    openAtCenter(snackType: string, message: string) {
        this.open(snackType, 'center', message);
    }

    openAtEnd(snackType: string, message: string) {
        this.open(snackType, 'end', message);
    }

    private open(snackType: string, position: MatSnackBarHorizontalPosition, message: string, params?: any) {
        this.snackBar.open(this.translateService.instant(message, params), '', {
            duration: 3000,
            horizontalPosition: position,
            verticalPosition: 'bottom',
            panelClass: this.getPanelClass(snackType)
        });
    }

    openWithIcon(message: string, snackType: string) {
        this.snackBar.openFromComponent(SnackbarNotifComponent, {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: this.getPanelClass(snackType),
            data: {message: message, snackType: snackType}
        });
    }

    private getPanelClass = (snackType: string) => {
        switch (snackType) {
            case 'Success':
                return 'green-700';
            case 'Error':
                return 'red-700';
            case 'Info':
                return 'teal-500';
        }
    }

}
