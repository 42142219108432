import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from "../../../../../../models/folder.model";
import {Product} from "../../../../../../models/product.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-alimentary-product-possession-customer',
  templateUrl: './alimentary-product-possession-customer.component.html',
  styleUrls: ['./alimentary-product-possession-customer.component.css']
})
export class AlimentaryProductPossessionCustomerComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Output() previewTask = new EventEmitter<any>();

    @Input() folder: Folder;
    @Input() product: Product;
    @Input() workflowStatus: string;
    @Input() variablesTask: any;
    positionStep: number;
    interestInProductForm: any = {
        form: null,
        placeholders: []
    };

    constructor() {
        this.positionStep = 1;
    }

    ngOnInit(): void {
        this.initInterestInProductForm();
    }

    private initInterestInProductForm(): void {
        this.interestInProductForm.form = new FormGroup({
            interestInProduct: new FormControl(null, Validators.required)
        });
        this.interestInProductForm.placeholders = [
            {
                interestInProduct: 'concerned',
                value: 'COMPONENT.PRODUCT_CHECK_AT_CUSTOMER.CONCERNED',
            },
            {
                interestInProduct: 'consumed',
                value: 'COMPONENT.PRODUCT_CHECK_AT_CUSTOMER.CONSUMED',
            },
            {
                interestInProduct: 'not_bought',
                value: 'COMPONENT.PRODUCT_CHECK_AT_CUSTOMER.NOT_BOUGHT',
            }
        ];
    }

    completeTask(): void {
        this.inputMap.emit({
            'interestInProduct': this.interestInProductForm.form.value.interestInProduct
        });
    }

}
