import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
  selector: 'app-display-spare-parts-and-tutorials',
  templateUrl: './display-spare-parts-and-tutorials.component.html',
  styleUrls: ['./display-spare-parts-and-tutorials.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DisplaySparePartsAndTutorialsComponent),
            multi: false
        }
    ]
})
export class DisplaySparePartsAndTutorialsComponent implements OnInit {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    repairRequiresForm: any = {
        form: null,
        placeholders: []
    };
    constructor() { }
    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.repairRequiresForm.form = new FormGroup({
            choice: new FormControl(null, Validators.required),
        });
        this.repairRequiresForm.placeholders = [
            {
                choice: 'false',
                value: 'BUTTON.CHOOSE_OTHER_OPERATION_MODE',
                repairStatus: 'CHOOSE_OTHER_OPERATING_MODE'
            },
            {
                choice: 'true',
                value: 'BUTTON.AUTO_REPAIR',
                repairStatus: 'REPAIRED_BY_CUSTOMER'
            }
        ];
    }
    private onSubmit(): void {
        if (this.repairRequiresForm.form.value.choice) {
            this.inputMap.emit({'customerAbleToRepair': this.repairRequiresForm.form.value.choice,
                'repairStatus': this.getRepairStatusByValueSelected(
                    this.repairRequiresForm.form.value.choice)});
        } else {
            this.inputMap.emit({'customerAbleToRepair': this.repairRequiresForm.form.value.choice});
        }
    }
    getRepairStatusByValueSelected(selected: any): any {
        return this.repairRequiresForm.placeholders.filter(repairForm => repairForm.choice ===  this.repairRequiresForm.form.value.choice )
            .map( repairForm => repairForm.repairStatus)[0];
    }
}
