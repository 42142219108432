import {Routes} from '@angular/router';
import {AppAuthGuard} from 'app/shared/utils/app.authguard';
import {WidgetListComponent} from './dashboard-folder/config/dashboard/widget/widget-list/widget-list.component';
import {Roles} from 'app/models/enums/roles.enum';
import {Context} from '../../models/enums/context.enum';
import {UpdateWidgetComponent} from './dashboard-folder/config/dashboard/widget/update-widget/update-widget.component';
import {DashboardConfigComponent} from './dashboard-folder/config/dashboard/dashboard-config/dashboard-config.component';
import {DashboardListComponent} from './dashboard-folder/config/dashboard/dashboard-list/dashboard-list.component';
import {DashboardFolderComponent} from './dashboard-folder/dashboard-folder.component';
import {PlanningComponent} from './itinerant/planning/planning.component';
import {DashBoardDayComponent} from './itinerant/dash-board-day/dash-board-day.component';
import {DashboardHomePageComponent} from './dashboard-v2/dashboard-home-page.component';
import {CreateDashboardComponent} from './dashboard-v2/crud/create-dashboard.component';
import {EditDashboardComponent} from './dashboard-v2/crud/edit-dashboard.component';
import {ConfigDashboardComponent} from './dashboard-v2/config/config-dashboard.component';
import {ViewDashboardComponent} from './dashboard-v2/crud/view-dashboard.component';

export const DashboardRouting: Routes = [
    {
        path: 'dashboard',
        data: {
            context: [Context.SAV, Context.PRODUCT_RECALL, Context.REPARATION],
        },
        children: [
            {
                path: '',
                component: DashboardFolderComponent,
                canActivate: [AppAuthGuard],
                data: {
                    scope: 'dashboard'
                },
            },
            {
                path: 'add',
                pathMatch: 'full',
                component: DashboardConfigComponent,
                canActivate: [AppAuthGuard],
                data: {
                    scope: 'configuration.dashboard'
                }
            },
            {
                path: 'update/:id',
                pathMatch: 'full',
                component: DashboardConfigComponent,
                canActivate: [AppAuthGuard],
                data: {
                    scope: 'configuration.dashboard'
                }
            },
            {
                path: 'list',
                pathMatch: 'full',
                component: DashboardListComponent,
                canActivate: [AppAuthGuard],
                data: {
                    scope: 'configuration.dashboard'
                }
            },
            {
                path: 'widget',
                pathMatch: 'full',
                component: WidgetListComponent,
                canActivate: [AppAuthGuard],
                data: {
                    scope: 'configuration.widget'
                }
            },
            {
                path: 'widget/add',
                pathMatch: 'full',
                component: UpdateWidgetComponent,
                canActivate: [AppAuthGuard],
                data: {
                    context: [Context.SAV],
                    scope: 'configuration.widget'
                }
            },
            {
                path: 'widget/update/:id',
                pathMatch: 'full',
                component: UpdateWidgetComponent,
                canActivate: [AppAuthGuard],
                data: {
                    scope: 'configuration.widget'
                }
            }
        ]
    }, {
        path: 'itinerant/dashboard',
        pathMatch: 'full',
        canActivate: [AppAuthGuard],
        data: {
            context: [Context.REPARATION]
        },
        component: PlanningComponent,
    },
    {
        path: 'itinerant/day/:id',
        pathMatch: 'full',
        data: {
            context: [Context.REPARATION]
        },
        component: DashBoardDayComponent

    },
    {
        path: 'dashboard-v2',
        data: {
            context: [Context.SAV, Context.PRODUCT_RECALL, Context.REPARATION],
            scope: 'view.dashboard-v2'
        },
        children: [
            {
                path: '',
                component: DashboardHomePageComponent,
                canActivate: [AppAuthGuard],
                data: {
                    scope: 'view.dashboard-v2'
                }
            },
            {
                path: 'create',
                pathMatch: 'full',
                component: CreateDashboardComponent,
                canActivate: [AppAuthGuard],
                data: {
                    scope: 'configuration.dashboard-v2'
                }
            },
            {
                path: 'config',
                pathMatch: 'full',
                component: ConfigDashboardComponent,
                canActivate: [AppAuthGuard],
                data: {
                    scope: 'configuration.dashboard-v2'
                }
            },
            {
                path: 'edit/:id',
                pathMatch: 'full',
                component: EditDashboardComponent,
                canActivate: [AppAuthGuard],
                data: {
                    scope: 'configuration.dashboard-v2'
                }
            },
            {
                path: ':id',
                pathMatch: 'full',
                component: ViewDashboardComponent,
                canActivate: [AppAuthGuard],
                data: {
                    scope: 'view.dashboard-v2'
                }
            }
        ]
    }
];
