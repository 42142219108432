export enum ShipmentType {
    CUSTOMER_TO_REPAIRER = 'CUSTOMER_TO_REPAIRER',
    REPAIRER_TO_CUSTOMER = 'REPAIRER_TO_CUSTOMER',
    TO_SUPPLIER = 'TO_SUPPLIER',
    STORE_TO_REPAIRER = 'STORE_TO_REPAIRER',
    SUPPLIER_TO_CUSTOMER = 'SUPPLIER_TO_CUSTOMER',
    SUPPLIER_TO_REPAIRER = 'SUPPLIER_TO_REPAIRER',
    CUSTOMER_TO_DISTRIBUTOR = 'CUSTOMER_TO_DISTRIBUTOR',
    CUSTOMER_TO_STORE = 'CUSTOMER_TO_STORE',
    CUSTOMER_TO_SUPPLIER = 'CUSTOMER_TO_SUPPLIER',
    STORE_TO_SUPPLIER = 'STORE_TO_SUPPLIER',
    REPAIRER_TO_SUPPLIER = 'REPAIRER_TO_SUPPLIER',
    DISTRIBUTOR_TO_SUPPLIER = 'DISTRIBUTOR_TO_SUPPLIER',
    REPAIRER_TO_STORE = 'REPAIRER_TO_STORE',
    PICKING = 'PICKING'

}
