export enum AttachmentTypeEnum {
    // Liste des types de pièces jointes fixes non supprimables
    DEFAULT = 'DEFAULT',
    PANNE_CONFIRMED = 'PANNE_CONFIRMED',
    ATTACHEMENT_REPARATION_INTERVENTION_REPORT = 'ATTACHEMENT_REPARATION_INTERVENTION_REPORT',
    ATTACHEMENT_BACK_OFFICE_QUOTATION_REPAIRER = 'ATTACHEMENT_BACK_OFFICE_QUOTATION_REPAIRER',
    ATTACHEMENT_PUBLIC_QUOTATION_REPAIRER = 'ATTACHEMENT_PUBLIC_QUOTATION_REPAIRER',
    PRODUCT_STATUS_IMAGE = 'PRODUCT_STATUS_IMAGE',
    SHIPMENT_DOCUMENT = 'SHIPMENT_DOCUMENT',


    // non fixes
    PRODUCT_IMAGE = 'PRODUCT_IMAGE',
    ATTACHEMENT_ASSET_CATALOG_IMAGE = 'ATTACHEMENT_ASSET_CATALOG_IMAGE',
    ATTACHMENTS_STORE_TO_REPAIRER = 'ATTACHMENTS_STORE_TO_REPAIRER',
    ATTACHMENTS_CUSTOMER_TO_REPAIRER = 'ATTACHMENTS_CUSTOMER_TO_REPAIRER'


}
