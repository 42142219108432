import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from '../../../../models/product.model';
import {FolderCreationService} from '../folder-creation.service';
import {InvoiceProducts, Item} from '../../../../models/invoice.model';
import {Unsubscriber} from '../../../../unsubscriber';
import {UserState} from '../../../../store/user/user.state';
import {Context} from '../../../../models/enums/context.enum';

@Component({
    selector: 'app-product-form',
    templateUrl: './product-form.component.html',
    styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent extends Unsubscriber implements OnInit {

    @Output() showProductStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() productListEmitter = new EventEmitter();
    @Input() productNotFound: boolean;
    @Input() purchaseDate: string;
    @Input() user: UserState;
    @Input() pageSize: number;
    product = null;
    productList: InvoiceProducts;
    products: Product[] = [];
    isFilteredResult = false;
    @Input() invoiceId: string;
    page: number;


    constructor(private folderCreationService: FolderCreationService) {
        super();
    }

    ngOnInit(): void {
        this.folderCreationService.resetFilter();
        this.anotherSubscription = this.folderCreationService.pagedInvoiceProducts$
            .subscribe(pagedProducts => {
                if (!pagedProducts || pagedProducts.totalElements === 0) {
                    this.productList = {
                        content: [],
                        totalElements: 0
                    };
                    return;

                }
                this.productList = pagedProducts;
            });
        this.anotherSubscription = this.folderCreationService.getNewProduct()
            .subscribe(product => {
                if (!product || !product.code) {
                    this.product = null;
                } else {
                    this.product = product;
                }
            });
        this.anotherSubscription = this.folderCreationService.productList$
            .subscribe(products => {
                if (!products || (this.products.length === 0 && products.length === 0)) {
                    this.resetProductList();
                    this.page = 0;
                    this.products = [];
                } else if (this.isProductRecallContext()) {
                    this.product = products[0];
                    this.onProductSelect(this.product);
                } else {
                    const lastIndex = this.products.length - 1;
                    if (lastIndex >= 0 && !this.products[lastIndex].id) {
                        this.products.splice(lastIndex, 1);
                    }
                    this.products = [...this.products, ...products];
                    this.page = Math.round(this.products.length / this.pageSize);
                    this.productList = {
                        content: this.products.map(p => new Item(p)),
                        totalElements: this.products.length
                    };
                }
            });
        this.anotherSubscription = this.folderCreationService.isFilteredProducts$
            .subscribe(isFiltered => {
                this.isFilteredResult = isFiltered;
            });

        this.anotherSubscription = this.folderCreationService.newProductSearchFilter$.subscribe(data => {
            if (data){
                this.products = [];
            }
        });
    }

    private resetProductList() {
        this.productList = {
            content: [],
            totalElements: 0
        };
    }

    changeProduct(event): void {
        this.product = event;
        this.changeShowProductState(true);
    }

    onProductSelect(product: Product): void {
        this.folderCreationService.productFromInvoiceChanged(product);
        this.showProductStateChange.emit(true);
    }

    backToProductList(): void {
        this.product = null;
        this.folderCreationService.productChanged(this.product);
        this.folderCreationService.productQuantityChanged(null);
        this.productListEmitter.emit();
        this.changeShowProductState(false);
    }

    changeShowProductState(state: boolean): void {
        this.showProductStateChange.emit(state);
    }

    isProductRecallContext(): boolean {
        return this.user.context === Context.PRODUCT_RECALL;
    }

}

