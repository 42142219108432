import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FolderSubjectService} from '../../folder-subject.service';
import {Folder} from '../../../../models/folder.model';
import {QuotationType} from '../../../../models/enums/quotationType.enum';
import {Quotation, QuotationLine} from '../../../../models/quotation.model';
import {ServiceType} from '../../../../models/enums/serviceType.enum';
import {currentUser} from '../../../../store/user/user.selectors';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {Observable} from 'rxjs';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../unsubscriber';
import {Order} from '../../../../models/order.model';
import {OrderSubjectService} from '../../../../shared/services/order-subject.service';

@Component({
    selector: 'app-folder-cart-items-card',
    templateUrl: './folder-cart-items-card.component.html',
    styleUrls: ['./folder-cart-items-card.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])
    ]
})
export class FolderCartItemsCardComponent extends Unsubscriber implements OnInit {
    folder: Folder;
    displayedColumns: string[] = ['code', 'label', 'quantity', 'status'];
    organizationCode: string;
    context: string;
    orderedSpareParts = [];
    orderedSparePartsGrouped: {};
    columns: string[] = ['code', 'label', 'price', 'tva'];
    currentLanguage$: Observable<string>;
    isSG: boolean;
    constructor(private folderSubjectService: FolderSubjectService,
                private orderSubjectService: OrderSubjectService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.anotherSubscription = this.store$.pipe(select(currentUser)).subscribe(currentUser => {
            this.organizationCode = currentUser.organizationCode;
            this.context = currentUser.context;
        });
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
            this.isSG = this.folder.newWarranty.warranty.valueOf() === 'SG';
            this.groupQuotationLines();
        });
    }

    groupQuotationLines(): void {
            if (!!this.folder.orderIds && this.folder.orderIds?.length > 0) {
                this.anotherSubscription = this.orderSubjectService.order$.subscribe(order => {
                    this.buildDataLines(order);
                });
            } else {

                // TODO this code will be deprecated
                // get all the quotations lines with type SPARE PARTS
                const quotationsIds = this.folder.services.filter(service => service.type === ServiceType.SPARE_PARTS).map(it => it.quotationId);
                const quotations = this.folder.quotations.filter((quotation: Quotation) => quotationsIds.includes(quotation.id));

                const map = {}; // map of <quotation_id,quotationLines[]>
                for (let i = 0; i < quotations.length; i++) {
                    for (let j = 0; j < quotations[i].quotationLines.length; j++) {
                        const quotationLine = quotations[i].quotationLines[j];
                        if (quotationLine.type === QuotationType.SPARE_PART) {
                            map[quotations[i].id] = map[quotations[i].id] || [];
                            map[quotations[i].id].push(quotationLine);
                        }
                    }
                }
                this.orderedSparePartsGrouped = map;


        }
    }

    buildDataLines(order: Order) {
        this.orderedSpareParts = [];
        order.orderItems?.forEach(orderItem => {
            const dataSourceLine = new QuotationLine();
            dataSourceLine.code = orderItem.code;
            dataSourceLine.label = orderItem.label;
            dataSourceLine.quantity = orderItem.quantityOrdered;
            dataSourceLine.priceAmount = {
                value: orderItem.price,
                currency: orderItem.currency
            };
            dataSourceLine.stock = orderItem.stock;
            dataSourceLine.type = QuotationType.SPARE_PART;
            dataSourceLine.supplierName = orderItem.supplier?.name;
            dataSourceLine.status = orderItem.itemStatus;
            dataSourceLine.totalLinePrice = {
                value: orderItem.rowTotal,
                currency: orderItem.currency
            };
            dataSourceLine.vat = {
                rate: orderItem.taxPercent.toString()
            };
            dataSourceLine.discount = orderItem.discountPercent;
            this.orderedSpareParts.push(dataSourceLine);
        });

    }

}
