import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-product-retrieval-closed-by-diag-express',
    templateUrl: './waiting-product-retrieval-closed-by-diag-express.component.html',
    styleUrls: ['./waiting-product-retrieval-closed-by-diag-express.component.scss']
})
export class WaitingProductRetrievalClosedByDiagExpressComponent {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    productRetrieved: boolean;

    constructor() {
    }

    onSubmit(): void {
        if (!this.productRetrieved) {
            return;
        }
        this.inputMap.emit({});
    }


}
