<ejs-grid #grid [dataSource]="subElements"
          [allowPaging]="true"
          [allowSorting]="true"
          [allowFiltering]="true"
          [showColumnMenu]="true"
          [pageSettings]="{pageSize: 5}"
          [filterSettings]="{type: 'CheckBox'}"
>
    <e-columns>
        <e-column field="label"
                  [headerText]="'FOLDER.SUB_ELEMENT' | translate"
        >
        </e-column>
<!--        <e-column field="warrantyDuration"-->
<!--                  [headerText]="'PRODUCT.DURATION_WARRANTY' | translate"-->
<!--        >-->
<!--            <ng-template #template let-data>-->
<!--                <ng-container *ngIf="data.warrantyDuration as warranty else manufacturerReference">-->
<!--                    {{warranty.duration }}  {{'PRODUCT_CONFIG.DURATION_UNIT_WARRANTY.' + warranty.unit+'S' | translate}}-->
<!--                </ng-container>-->
<!--                <ng-template #manufacturerReference>-->
<!--                    <ng-container *ngIf="product.warranty as warranty else no_warranty">-->
<!--                        {{warranty.duration }}  {{'PRODUCT_CONFIG.DURATION_UNIT_WARRANTY.' + warranty.durationUnitEnum | translate}}-->
<!--                    </ng-container>-->
<!--                </ng-template>-->
<!--                <ng-template #no_warranty>-</ng-template>-->
<!--            </ng-template>-->
<!--        </e-column>-->
    </e-columns>
</ejs-grid>
