import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppState} from '../../store/app.state';
import {Store} from '@ngrx/store';
import {switchMap, take, withLatestFrom} from 'rxjs/operators';
import {currentUser} from '../../store/user/user.selectors';
import {currentLanguage} from '../../store/organization/organization.selectors';
import {Location} from '@angular/common';
import {GLOBAL} from '../../app-config';


export const ORGANIZATION_HEADER = 'x-current-organization';
export const CONTEXT_HEADER = 'x-current-context';
export const LANGUAGE_HEADER = 'x-current-language';
export const BUSINESS_LINK_HEADER = 'x-current-businessLink';
export const GRAVITEE_API_KEY_HEADER = 'x-gravitee-api-key';



@Injectable({
    providedIn: 'root'
})
export class CurrentOrganizationInterceptorService implements HttpInterceptor {

    constructor(private store$: Store<AppState>,
                private location: Location) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.store$.pipe(
            take(1),
            withLatestFrom(this.store$.select(currentUser)),
            withLatestFrom(this.store$.select(currentLanguage)),
            switchMap(([[, user], language]) => {
                if (request.url.includes('back-office-service') || request.url.includes('file-service')) {
                    if (this.location.path().indexOf('/public') < 0) {
                        request = request.clone(
                            {
                                headers: this.buildCommonHeaders(request, user)
                            });
                    }
                    request = request.clone(
                        {
                            headers: request.headers
                                .set(LANGUAGE_HEADER, language)
                        });
                }

                if (request.url.includes('spare-part-apis')) {
                    request = request.clone(
                        {
                            headers: this.buildCommonHeaders(request, user)
                                .set(LANGUAGE_HEADER, language)
                                .set(GRAVITEE_API_KEY_HEADER, GLOBAL.sparePartApiKey)
                        });
                }

                return next.handle(request);
            })
        );
    }

    private buildCommonHeaders(request: HttpRequest<any>, user): HttpHeaders {
        return request.headers.set(ORGANIZATION_HEADER, user.organizationCode)
            .set(CONTEXT_HEADER, user.context)
            .set(BUSINESS_LINK_HEADER, user.businessLink);
    }
}
