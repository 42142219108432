import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import * as uuid from 'uuid';
import {MatDialog} from '@angular/material/dialog';
import {Folder, IFolderUpdateRequest} from '../../../../../../models/folder.model';
import {QuotationModalComponent} from '../../../../../../shared/generic/quotation-modal/quotation-modal.component';
import {QuotationModalConfig} from '../../../../../../shared/generic/quotation-modal/quotationModal.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {currentUser, isLeroyMerlin, isNorauto} from '../../../../../../store/user/user.selectors';
import {UserState} from '../../../../../../store/user/user.state';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {Observable} from 'rxjs';
import {SiteType} from '../../../../../../models/enums/siteType.enum';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {DataSourceLine, Quotation, QuotationLine} from '../../../../../../models/quotation.model';
import {TaskVariables} from '../../../task.variables';
import {PaymentReason} from '../../../../../../models/enums/paymentReason.enum';
import {QuotationType} from '../../../../../../models/enums/quotationType.enum';
import {Payer} from '../../../../../../models/enums/payer.enum';
import {TranslateService} from '@ngx-translate/core';
import {Vat} from '../../../../../../models/vat.model';
import {currency} from 'app/store/organization/organization.selectors';
import {take, map} from 'rxjs/operators';
import {AmountUtils} from '../../../../../../shared/utils/amount-utils';
import {RuleEvaluationContext} from '../../../../../../models/rules/RuleEvaluationContext';
import {BackOfficeService} from '../../../../../../shared/services/back-office.service';
import {Workforce} from '../../../../../../models/Workforce.model';
import {SparePartService} from '../../../../../../shared/services/spare-part.service';
import {SnackBarService} from '../../../../../../shared/services/snack-bar.service';
import {StocksAvailabilityResponse} from '../../../../../../models/spare-parts/stocksAvailabilityResponse.model';
import {Order, OrderItem} from '../../../../../../models/order.model';
import {OmsService} from '../../../../../../shared/services/oms.service';
import {OrderSubjectService} from '../../../../../../shared/services/order-subject.service';
import {IEvent} from '../../../../../../models/events.model';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-input-requirement2',
    templateUrl: './input-requirement2.component.html',
    styleUrls: ['./input-requirement2.component.scss']
})
export class InputRequirement2Component extends Unsubscriber implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();

    reportLineColumns: string[] = ['code', 'label', 'type', 'payer'];
    dataSource = new MatTableDataSource<DataSourceLine>();
    comment: string;
    currentUser: UserState;
    private isNorauto$: Observable<boolean>;
    private isLeroyMerlin$: Observable<boolean>;
    quotation: Quotation = new Quotation();
    private quotationId: any;
    vat: Vat;
    currency: string;
    workforces: Workforce[] = [];
    order = new Order();
    warrantyCode: string;
    quotationAccepted: boolean;
    operatingMode: string;
    totalPrice = 0;
    feesQuotationLine: DataSourceLine;
    events: IEvent [] = [];
    isOrderConfirmed: boolean;

    constructor(private store$: Store<AppState>,
                private matDialog: MatDialog,
                private snackBar: SnackBarService,
                private translateService: TranslateService,
                private backOfficeService: BackOfficeService,
                private sparePartService: SparePartService,
                private orderSubjectService: OrderSubjectService,
                private folderSubjectService: FolderSubjectService,
                private folderService: FolderService,
                private omsService: OmsService) {
        super();
    }

    ngOnInit(): void {
        this.operatingMode = this.folder.operationMode;
        this.warrantyCode = this.folder.newWarranty.warranty.valueOf();
        this.quotationAccepted = this.variablesTask[TaskVariables.quotationAccepted];
        this.vat = JSON.parse(this.variablesTask[TaskVariables.vat]);
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => {
            this.currency = currency;
            if (this.warrantyCode === 'HG') {
                if (!!this.folder.quotations && this.folder.quotations.length > 0) {
                    this.quotation = this.getLastQuotation();
                    this.completeQuotationWithPayers(this.quotation);
                } else {
                    this.quotation = Quotation.getEmptyQuotation(this.currency);
                }
                this.updateFeesQuotation();
            } else if (this.warrantyCode === 'SG' && !!this.folder.quotations && this.folder.quotations.length > 0) {
                this.initOldFolderUnderWarranty();
            }
        });

        this.store$.pipe(select(currentUser), take(1))
            .subscribe(userDetails => this.currentUser = userDetails);
        this.isNorauto$ = this.store$.pipe(select(isNorauto));
        this.isLeroyMerlin$ = this.store$.select(isLeroyMerlin);
        if (!!this.folder.orderIds && this.folder.orderIds.length > 0) {
            this.initOrder();
        } else if (!!this.folder.quotations && this.folder.quotations.length > 0) {
            this.initQuotationDataSource();
        }
    }

    private initOldFolderUnderWarranty() {
        this.quotation = this.getLastQuotation();
        this.completeQuotationWithPayers(this.quotation);
        this.totalPrice = this.quotation.totalPrice?.value;
    }

    private initQuotationDataSource() {
        this.dataSource.data = this.quotationLinesToDataSourceLines(this.quotation.quotationLines);
    }

    private getLastQuotation(): Quotation {
        return this.folder.quotations.reduce((quotation1, quotation2) => {
            return quotation1.userAction.actionDate > quotation2.userAction.actionDate ? quotation1 : quotation2;
        });
    }

    private initOrder() {
        this.anotherSubscription = this.orderSubjectService.order$.subscribe(data => {
            this.order = data;
            const items = [...this.order.orderItems, ...this.order.orderExtraItems];
            this.refreshDataSource(items);
            if (!!this.order.grandTotal) {
                this.totalPrice += this.order.grandTotal;
            }
            if (this.order.orderStatus !== 'NOT_CONFIRMED' && this.order.orderStatus !== 'PICKING') {
                this.showOrderErrorMessage();
                this.isOrderConfirmed = true;
            }
        });
    }

    private showOrderErrorMessage() {
        this.snackBar.openAtEnd('Error', 'UPDATING.A.CONFIRMED.ORDER.IS.NOT.ALLOWED');
    }

    private refreshDataSource(items: OrderItem[]) {
        this.dataSource.data = this.toDataSourceLines(items);
        if (this.shouldDisplayQuotationFees()) {
            this.dataSource.data.push(this.feesQuotationLine);
        }
    }


    private shouldDisplayQuotationFees() {
        return this.dataSource.data.every(line => line.type !== QuotationType.QUOTATION_FEES) && !!this.feesQuotationLine;
    }

    private updateFeesQuotation(): void {
        const feesPaymentQuotation = this.folder.payments.find(value => value.paymentReason.startsWith(PaymentReason.QUOTATION_FEES));

        const feesQuotation = this.quotation?.quotationLines?.length > 0 ?
            this.quotation.quotationLines.find(value => value.type.startsWith(QuotationType.QUOTATION_FEES)) : null;
        if (!!feesPaymentQuotation && !feesQuotation) {
            this.feesQuotationLine = this.toDataSourceLine(feesPaymentQuotation);
            this.dataSource.data.push(this.feesQuotationLine);
            const quotationLine = this.toQuotationLine(feesPaymentQuotation);
            this.quotation.quotationLines.push(quotationLine);
            this.quotation.totalPrice.value += feesPaymentQuotation.amount * -1;
            this.totalPrice = feesPaymentQuotation.amount * -1;
        } else if (!!feesQuotation) {
            this.feesQuotationLine = this.toDataSourceLine(feesQuotation);
            this.dataSource.data.push(this.feesQuotationLine);
            this.totalPrice = this.quotation.totalPrice?.value;
        }
    }

    private toDataSourceLine(feesPaymentQuotation): DataSourceLine {
        return {
            code: '-',
            label: this.translateService.instant('MODAL.QUOTATION.FEES_QUOTATION'),
            payer: Payer.CLIENT,
            quantity: 1,
            price: AmountUtils.convertTTCtoHT(feesPaymentQuotation.amount, Number(this.vat.rate)) * -1,
            currency: this.currency,
            vat: Number(this.vat.rate),
            type: QuotationType.QUOTATION_FEES,
            discount: 0,
            totalLinePrice: feesPaymentQuotation.amount * -1
        };
    }

    private toQuotationLine(feesPaymentQuotation): QuotationLine {
        return {
            code: '-',
            label: this.translateService.instant('MODAL.QUOTATION.FEES_QUOTATION'),
            payer: Payer.CLIENT,
            quantity: 1,
            priceAmount: {
                value: AmountUtils.convertTTCtoHT(feesPaymentQuotation.amount, Number(this.vat.rate)) * -1,
                currency: this.currency
            },
            vat: this.vat,
            type: QuotationType.QUOTATION_FEES,
            discount: 0,
            totalLinePrice: {
                value: feesPaymentQuotation.amount * -1,
                currency: this.currency
            }
        };
    }

    onSubmit(): void {
        this.store$.dispatch(new StopLoading());
        if (this.hasSparePart()) {
            this.sparePartService.getAvailabilityOfStocks(this.getManagementSiteCode(), this.prepareStockRequest())
                .subscribe(stockResponse => {
                    if (!!stockResponse) {
                        if (stockResponse.availabilityStatus !== 'AVAILABLE' && stockResponse.availabilityStatus !== 'UNKNOWN') {
                            this.showErrorMessage();
                            this.updateQuotationWithStockAvailability(stockResponse);
                            this.openQuotationModal();
                        } else {
                            this.validateTask();
                        }
                    } else {
                        this.validateTask();
                    }

                });
        } else {
            this.validateTask();
        }

    }

    private validateTask() {
        if (this.warrantyCode === 'HG') {
            const quotationLines = this.quotationLinesExcludingFees();
            if (quotationLines?.length === 0) {
                // le cas de dénonciation de garantie et on n'a pas changé les besoins de réparation (sans ouvrir le modal de quotation)
                const folderUpdateRequest: IFolderUpdateRequest = {
                    quotation: this.loadQuotationFromOrder()
                };
                this.updateQuotationAndFireWorkflow(folderUpdateRequest);
            } else {
                this.emitHGVariables();
                if (!!this.comment && this.comment.length > 0) {
                    this.updateComment();
                }
            }
        } else {
            this.emitSGVariables();
            if (!!this.comment && this.comment.length > 0) {
                this.updateComment();
            }
        }
    }

    private updateQuotationAndFireWorkflow(folderUpdateRequest: IFolderUpdateRequest) {
        this.store$.dispatch(new StartLoading());
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
            this.folderSubjectService.folderLoaded(folder);
            this.emitHGVariables();
            if (!!this.comment && this.comment.length > 0) {
                this.updateComment();
            }
            this.store$.dispatch(new StopLoading());
        }, () => this.store$.dispatch(new StopLoading()));
    }

    private emitHGVariables() {
        this.inputMap.emit({
            quotationId: this.quotation.id,
            orderId: this.order.id
        });
    }

    private emitSGVariables() {
        this.inputMap.emit({
            orderId: this.order.id
        });
    }

    private updateComment() {
        this.updateOrderWithComment();
        this.commentGiven.emit({
                value: this.comment
            }
        );
    }

    private updateOrderWithComment() {
        if (!this.isOrderConfirmed) {
            this.order.remark = this.comment;
            this.store$.dispatch(new StartLoading());
            this.omsService.updateOrder(this.order.id, this.order).subscribe((data) => {
                if (!!data) {
                    this.order = data;
                    this.orderSubjectService.orderLoaded(this.order);
                }
                this.store$.dispatch(new StopLoading());
            }, () => this.store$.dispatch(new StopLoading()));
        }
    }

    private showErrorMessage() {
        this.snackBar.openAtEnd('Error', 'STOCKS.SPARE_PARTS.NOT_AVAILABLE');
    }

    private prepareStockRequest() {
        return this.dataSource.data
            .filter(value => value.type === QuotationType.SPARE_PART)
            .map(quotationLine => ({
                sparePartCode: quotationLine.code.trim(),
                quantity: quotationLine.quantity
            }));
    }

    private hasSparePart() {
        return this.dataSource.data
            .some(value => value.type === QuotationType.SPARE_PART);
    }

    private updateQuotationWithStockAvailability(stockResponse: StocksAvailabilityResponse) {
        const quotationLines = this.quotationLinesExcludingFees();
        if (quotationLines?.length > 0) {
            this.quotation.quotationLines.map(quotationLine => stockResponse.stocks
                .filter(value => value.sparePartCode === quotationLine.code)
                .map(value => quotationLine.stock = value));
        } else {
            this.order.orderItems?.map(item => stockResponse.stocks
                .filter(value => value.sparePartCode === item.code)
                .map(value => item.stock = value));

        }

    }

    private getManagementSiteCode(): string {
        return this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE)?.code;
    }

    openQuotationModal(): void {
        const ruleEvaluationContext = RuleEvaluationContext.fromFolder(this.folder);

        this.backOfficeService.getWorkforces(ruleEvaluationContext)
            .subscribe(value => {
                    if (!!value) {
                        this.workforces = value;
                        const quotationModalConfig: QuotationModalConfig = {
                            showFormAddQuotation: true,
                            initialQuotation: !!this.quotation ? this.quotation : {quotationLines: []},
                            initOrder: this.order,
                            warrantyCode: this.warrantyCode,
                            operatingMode: this.operatingMode,
                            titleModal: 'MODAL.QUOTATION.ADD_REQUIREMENT',
                            folderId: this.folder.id,
                            hideTva$: this.hideTva(),
                            hideDiscount$: this.hideDiscount(),
                            hidePayers$: this.hidePayers(),
                            hidePrice$: this.hidePrice(),
                            showSupplier$: this.showSupplier(),
                            showPurchasePrice$: this.showPurchasePrice(),
                            showTotalUnitPrice$: this.showTotalUnitPrice(),
                            showMargin$: this.showMargin(),
                            payers: this.folder.payers,
                            organizationCode: this.folder.organization.code,
                            context: this.folder.context,
                            product: this.folder.product,
                            managementSite: this.getManagementSite(),
                            loadSparePartCatalog: true,
                            workforcesRule: this.workforces,
                            loadTypeOfBenefit: true
                        };
                        const dialogRef = this.matDialog.open(QuotationModalComponent, {
                            height: '90vh', width: '100vh', minWidth: '80%', data: quotationModalConfig
                        });
                        dialogRef.afterClosed().subscribe(dialogResult => {
                                if (!!dialogResult) {
                                    this.totalPrice = dialogResult.totalPrice?.value;
                                    this.order = dialogResult.order;
                                    this.quotation.id = dialogResult.quotationId;
                                    this.quotation.quotationLines = dialogResult.quotationLines;
                                    if (!!this.order.orderItems || !!this.order.orderExtraItems) {
                                        const items = [...this.order.orderItems, ...this.order.orderExtraItems];
                                        this.refreshDataSource(items);
                                    } else if (!!dialogResult.quotationLines) {
                                        // TODO to be deleted after 60 days
                                        this.dataSource.data = this.quotationLinesToDataSourceLines(this.quotation.quotationLines);
                                    }
                                }
                            }
                        );
                    }
                }
            );

    }

    quotationLinesToDataSourceLines(quotationLines: QuotationLine[]): DataSourceLine[] {
        const dataSourceLines: DataSourceLine[] = [];
        if (quotationLines?.length > 0) {
            quotationLines.forEach(quotationLine => {
                const line = {
                    ...quotationLine,
                    vat: Number(quotationLine.vat),
                    price: quotationLine.priceAmount.value,
                    purchasePrice: quotationLine.purchasePrice.value,
                    totalLinePrice: quotationLine.totalLinePrice?.value,
                    stock: quotationLine.stock
                };
                dataSourceLines.push(line);
            });

        }
        return dataSourceLines;
    }

    completeQuotationWithPayers(quotation: Quotation) {
        quotation?.quotationLines?.filter(line => line.payer == null).forEach(line => {
            line.payer = this.getPayerByType(line.type);
        });
    }

    getPayerByType(type: string): Payer {
        switch (type) {
            case QuotationType.DISPLACEMENT:
                return Payer[this.folder.payers.displacement];
            case QuotationType.SPARE_PART:
                return Payer[this.folder.payers.spareParts];
            case QuotationType.WORKFORCE:
                return Payer[this.folder.payers.workforce];
            case QuotationType.QUOTATION_FEES:
                return Payer.CLIENT;
            default:
                return null;
        }
    }

    disabledButtonForm(): boolean {
        return this.totalPrice < 0 || this.dataSource.data?.length === 0;
    }

    private hideTva(): Observable<boolean> {
        return this.isNorauto$;
    }

    private hideDiscount(): Observable<boolean> {
        return this.isNorauto$;
    }

    private hidePayers(): Observable<boolean> {
        return this.isNorauto$;
    }

    private hidePrice(): Observable<boolean> {
        return this.isNorauto$;
    }
    private showSupplier(): Observable<boolean> {
        return this.isNorauto$.pipe(
            map(isNorauto => !isNorauto)
        );
    }
    private showMargin(): Observable<boolean> {
        return this.isNorauto$.pipe(
            map(isNorauto => !isNorauto)
        );
    }
    private showPurchasePrice(): Observable<boolean> {
        return this.isNorauto$.pipe(
            map(isNorauto => !isNorauto)
        );
    }
    private showTotalUnitPrice(): Observable<boolean> {
        return this.isNorauto$.pipe(
            map(isNorauto => !isNorauto)
        );
    }
    toDataSourceLines(items: OrderItem[]): DataSourceLine[] {
        const itemLines: DataSourceLine[] = [];
        items.forEach(item => {
            const itemLine: DataSourceLine = {
                code: item.code,
                label: item.label,
                payer: item.payer ? item.payer : this.getPayerByType(item.type),
                quantity: item.quantityOrdered,
                price: item.price,
                vat: item.taxPercent,
                stock: item.stock,
                type: item.type,
                discountReason: item.discountReason,
                discount: item.discountPercent,
                totalLinePrice: item.rowTotal,

            };

            itemLines.push(itemLine);
        });
        return itemLines;
    }

    loadQuotationFromOrder(): Quotation {
        const orderItems = this.order?.orderItems ? this.order?.orderItems : [];
        const orderExtraItems = this.order?.orderExtraItems ? this.order?.orderExtraItems : [];
        const items = [...orderItems, ...orderExtraItems];
        if (!this.quotation) {
            this.quotation = Quotation.getEmptyQuotation(this.currency);
        }
        this.toQuotationLines(items);
        this.quotation.id = uuid.v4();
        this.quotation.target = 'CLIENT';
        this.quotation.totalPrice = {
            value: this.order.grandTotal,
            currency: this.currency
        };
        this.completeQuotationWithPayers(this.quotation);
        return this.quotation;

    }

    private toQuotationLines(orderItems: OrderItem[]) {
        orderItems.forEach(orderItem => {
            const quotationLine: QuotationLine = {
                code: orderItem.code,
                label: orderItem.label,
                quantity: orderItem.quantityOrdered,
                type: orderItem.type,
                payer: this.completeWithPayers(orderItem.type),
                discount: orderItem.discountPercent,
                discountReason: orderItem.discountReason,
                vat: {rate: String(orderItem.taxPercent)},
                priceAmount: {
                    value: orderItem.price,
                    currency: this.currency
                },
                totalLinePrice: {
                    value: orderItem.rowTotal,
                    currency: this.currency
                }
            };
            this.quotation.quotationLines.push(quotationLine);
        });
    }

    completeWithPayers(type) {
        if (type === 'SHIPMENT_FEES' && this.warrantyCode === 'HG') {
            return Payer.CLIENT;
        } else {
            return this.getPayerByType(type);
        }

    }

    quotationLinesExcludingFees() {
        return this.quotation?.quotationLines?.filter(quotation => quotation.type !== QuotationType.QUOTATION_FEES);
    }

    private getManagementSite() {
        return this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE);
    }
}
