import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder, RepairCenter} from '../../../../../models/folder.model';
import {FolderSubjectService} from '../../../folder-subject.service';
import {Unsubscriber} from '../../../../../unsubscriber';
import {RuleEvaluationContext} from '../../../../../models/rules/RuleEvaluationContext';
import {BackOfficeService} from '../../../../../shared/services/back-office.service';
import {mergeMap} from 'rxjs/operators';
import {FolderService} from '../../../../../shared/services/folder.service';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';
import {LoadingScreenService} from '../../../../../shared/services/loading-screen.service';

@Component({
    selector: 'app-waiting-reparation-agreement',
    templateUrl: './waiting-reparation-agreement.component.html',
    styleUrls: ['./waiting-reparation-agreement.component.scss']
})
export class WaitingReparationAgreementComponent extends Unsubscriber implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;
    folder: Folder;
    public reparationAgreementForm: FormGroup;
    currentRepairer: RepairCenter;
    localRepairers: RepairCenter[];
    repairers: RepairCenter [] = [];
    hideRepairers = true;

    constructor(private folderSubjectService: FolderSubjectService,
                private backOfficeService: BackOfficeService,
                private loadingScreenService: LoadingScreenService,
                private folderService: FolderService) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
            this.currentRepairer = this.folder.repairCenter;
        });
        this.createForm();
        this.initRepairerList();
        this.onChoiceChange();
    }

    private createForm(): void {
        this.reparationAgreementForm = new FormGroup({
            choice: new FormControl(null, [Validators.required]),
            agreementNumber: new FormControl(null, [Validators.required]),
            otherRepairer: new FormControl(null),
            productState: new FormControl(null, [Validators.required]),
            outOfWarranty: new FormControl(null, [Validators.required]),
            comment: new FormControl('', [Validators.required]),
        });
    }

    initRepairerList(): void {
        this.backOfficeService.getLocalRepairers(RuleEvaluationContext.fromFolder(this.folder))
            .pipe(
                mergeMap((localRepairers: RepairCenter[]) => {
                    this.localRepairers = localRepairers;
                    return this.folderService.getRepairCenter(this.folder.id);
                }),
            )
            .subscribe((repairers: RepairCenter[]) => {
                    if (repairers.length > 0) {
                        repairers = repairers.filter(value => value.code !== this.currentRepairer.code);
                    }
                    if (this.localRepairers.length > 0) {
                        this.repairers = [...new Map([...this.localRepairers, ...repairers].map(it => [it.code, it])).values()];
                    } else {
                        this.repairers = [...repairers];
                    }
                }
            );
    }

    onChoiceChange(): void {
        this.reparationAgreementForm.get('choice')
            .valueChanges
            .subscribe(choice => {
                if (choice !== null) {
                    if (choice) {
                        this.reparationAgreementForm.get('agreementNumber').setValidators(Validators.required);
                        this.reparationAgreementForm.get('productState').setValidators(Validators.required);

                        this.resetValidator(this.reparationAgreementForm.get('outOfWarranty'));
                        this.resetValidator(this.reparationAgreementForm.get('comment'));

                    } else {

                        this.reparationAgreementForm.get('outOfWarranty').setValidators(Validators.required);
                        this.reparationAgreementForm.get('comment').setValidators(Validators.required);

                        this.resetValidator(this.reparationAgreementForm.get('agreementNumber'));
                        this.reparationAgreementForm.get('otherRepairer').setValue(null);
                        this.resetValidator(this.reparationAgreementForm.get('productState'));
                    }

                    this.reparationAgreementForm.get('agreementNumber').updateValueAndValidity();
                    this.reparationAgreementForm.get('productState').updateValueAndValidity();
                    this.reparationAgreementForm.get('outOfWarranty').updateValueAndValidity();
                    this.reparationAgreementForm.get('comment').updateValueAndValidity();
                }
            });

    }

    private resetValidator(ValidatorValue: AbstractControl) {
        ValidatorValue.setValue(null);
        ValidatorValue.clearValidators();
    }

    getTaskDuration(): string {
        return this.variablesTask['reparationAgreementDate'];
    }

    getStatusDate(): string {
        return this.folder.currentWorkflowStatus.workflowStatusDate;
    }


    onSubmit(): void {
        this.loadingScreenService.startLoading();
        if (this.reparationAgreementForm.invalid) {
            return;
        }
        if (!!this.reparationAgreementForm.value.comment && this.reparationAgreementForm.value.comment.length > 0) {
            this.commentGiven.emit({
                value: this.reparationAgreementForm.value.comment,
                type: 'REASON_OF_REFUS_REPARATION'
            });
        }
        let dataToComplete;
        dataToComplete = {
            reparationAgreementAcceptation: this.reparationAgreementForm.value.choice,
            reparationAgreementNumber: this.reparationAgreementForm.value.agreementNumber
        };
        if (this.reparationAgreementForm.value.choice === true) {
            dataToComplete.productState = this.reparationAgreementForm.value.productState;
            if (!!this.reparationAgreementForm.value.otherRepairer) {
                dataToComplete.otherRepairer = this.reparationAgreementForm.value.otherRepairer.code;
            }
        } else {
            dataToComplete.outOfWarranty = this.reparationAgreementForm.value.outOfWarranty;
        }
        this.inputMap.emit(dataToComplete);
    }

    showRepairers() {
        this.hideRepairers = false;
    }
}
