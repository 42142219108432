<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="text-bold">
        {{'FOLDER.EXTERNAL_REFERENCES.ADD'|translate}}
    </h2>
    <button mat-icon-button class="ml-40" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div fxLayout="column" class="w-100-p">
    <form [formGroup]="addExternalReferencesForm" fxLayout="column"
          (ngSubmit)="addExternalReferences()">
        <mat-form-field appearance="outline">
            <mat-label>{{'FOLDER.EXTERNAL_SYSTEMS'|translate}}</mat-label>
            <mat-select formControlName="externalSystems" (ngModelChange)="onSystemSelected()">
                <mat-option *ngFor="let system of externalSystems"
                            [value]="system"> {{'FOLDER.EXTERNAL_REFERENCES.' + system|translate}}</mat-option>
            </mat-select>
        </mat-form-field>
        <br>

        <mat-form-field appearance="outline">
            <mat-label>{{'FOLDER.EXTERNAL_REFERENCES.TITLE'|translate}}</mat-label>
            <input matInput [placeholder]="'FOLDER.EXTERNAL_REFERENCES.TYPING'| translate"
                   formControlName="references">
        </mat-form-field>
        <mat-dialog-actions fxLayoutAlign="end end">
            <div fxLayout="row">
                <button type="reset" mat-stroked-button class="aster_btn_cancel" (click)="dialogRef.close(false)">
                    {{'BUTTON.CANCEL' | translate}}
                </button>
                <button type="submit" mat-stroked-button
                        [disabled]="addExternalReferencesForm.invalid"
                        [ngClass]="addExternalReferencesForm.invalid?'aster_btn_disabled':'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </mat-dialog-actions>
    </form>
</div>