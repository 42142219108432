import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Invoice} from '../../../../models/invoice.model';
import {Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {FolderCreationService} from '../folder-creation.service';
import {FolderService} from '../../../../shared/services/folder.service';

@Component({
    selector: 'app-invoice-folder-repairer',
    templateUrl: './invoice-folder-repairer.component.html',
    styleUrls: ['./invoice-folder-repairer.component.css']
})
export class InvoiceFolderRepairerComponent implements OnInit, OnDestroy {

    invoice = new Invoice();
    private invoiceSubscription: Subscription;
    private invoiceListSubscription: Subscription;
    editInvoice = true;
    invoiceList = new MatTableDataSource<Invoice>();
    displayedColumns = ['id', 'creationDate'];
    maxDate: Date;
    @Input() invoiceNotFound: boolean;
    invoiceNotFoundForm: FormGroup;
    invoiceForm = {
        form: null
    };
    isRequired = false;
    constructor(private folderCreationService: FolderCreationService,
                private folderService: FolderService) {
    }

    ngOnInit(): void {
        this.initInvoiceNotFoundForm();
        this.initInvoice();
        this.maxDate = new Date();
        this.invoiceSubscription = this.folderCreationService.invoice$.subscribe(invoice => {
            if (invoice) {
                this.editInvoice = false;
                this.invoice = invoice;
                this.invoiceForm.form.get(['id']).setValue(invoice.id);
                this.invoiceForm.form.get(['creationDate']).setValue(invoice.creationDate);
                if (!invoice.id) {
                    this.invoiceForm.form.get(['id']).setValue(invoice.id);
                    this.invoiceForm.form.get(['creationDate']).setValue(invoice.creationDate);
                    this.initInvoiceNotFoundForm();
                    this.folderService.findInvalidControls(this.invoiceNotFoundForm);
                }
            }
        });
        this.invoiceListSubscription = this.folderCreationService.invoiceList$.subscribe(invoices => {
            this.invoice = null;
            this.invoiceList.data = invoices;
        });

    }

    initInvoice(): void {
        this.invoiceForm.form = new FormGroup({
            creationDate: new FormControl(null),
            id: new FormControl(null)
        });
    }

    initInvoiceNotFoundForm(): void {
        this.invoiceNotFoundForm = new FormGroup({
            id: new FormControl(null, Validators.required),
            creationDate: new FormControl(null, Validators.required),
        });
    }

    ngOnDestroy(): void {
        this.invoiceSubscription.unsubscribe();
    }

    switchEditInvoiceNumber(): void {
        this.editInvoice = !this.editInvoice;
    }

    invoiceNumberChanged(): void {
        this.editInvoice = !this.editInvoice;
        this.invoiceForm.form.get(['id']).setValue(this.invoiceForm.form.get(['id']).value);
        this.invoice.id = this.invoiceForm.form.get(['id']).value;
        this.changeValidators();
        this.folderCreationService.invoiceChanged(this.invoice);
    }

    changeValidators(): void {
        this.isRequired = !!this.invoiceForm.form.get(['id']).value;
        this.invoiceForm.form.get(['creationDate']).clearValidators();
        this.invoiceForm.form.get(['creationDate']).setValidators(Validators.required);
        this.invoiceForm.form.get(['creationDate']).updateValueAndValidity();
    }

    invoiceCreationDateChanged(): void {
        this.invoiceForm.form.get(['creationDate']).setValue(this.invoiceForm.form.get(['creationDate']).value);
        this.invoice.creationDate = this.invoiceForm.form.get(['creationDate']).value;
        this.folderCreationService.invoiceChanged(this.invoice);
    }

    backToInvoiceList(): void {
        this.invoice = null;
    }

    selectInvoice(invoice: Invoice): void {
        this.invoice = invoice;
        this.folderCreationService.invoiceChanged(this.invoice);
    }

    showInvoiceList(): boolean {
        return !this.invoice && this.invoiceList.data && this.invoiceList.data.length > 0;
    }
}
