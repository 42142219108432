import {Injectable} from '@angular/core';
import {QuotationLine} from '../../models/quotation.model';

@Injectable({
    providedIn: 'root'
})
export class QuotationService {


    constructor() {
    }

    public computeTotalPriceQuotationLine(quotationLine: QuotationLine): number {
        const quantity = quotationLine.quantity;
        const productPrice = quotationLine.priceAmount.value;
        let totalTTC = 0;
        if (productPrice && quantity) {
            totalTTC = quantity * productPrice;
            if (quotationLine.discount > 0) {
                totalTTC = totalTTC - totalTTC * (quotationLine.discount / 100);
            }
        }
        const vatRate = Number(quotationLine.vat.rate);
        if (vatRate > 0) {
            totalTTC = totalTTC + totalTTC * (vatRate / 100);
        }
        return totalTTC;
    }
}
