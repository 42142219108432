    <app-user-task-header [instructionUserTask]="instructionUserTask"
                          additionalTitle="{{'COMPONENT.ACCEPTATION_CLIENT' | translate}}"
                          [claim]="folder"></app-user-task-header>

    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px">
            <table mat-table [dataSource]="quotationSummary" class="mat-elevation-z2 w-100-p mt-16 mb-16" fxFlex="70"
                   fxFlex.lt-md="100">
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center"> {{'MODAL.QUOTATION.TYPE' | translate}}</th>
                    <td mat-cell
                        *matCellDef="let quotationLine"> {{'MODAL.QUOTATION.TYPE.' + quotationLine.type | translate}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="number">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center"> {{'MODAL.QUOTATION.NUMBER' | translate}}</th>
                    <td mat-cell *matCellDef="let quotationLine"> {{quotationLine.number}} </td>
                </ng-container>
                <ng-container matColumnDef="totalPrice">
                    <th mat-header-cell *matHeaderCellDef
                        class="text-center"> {{'MODAL.QUOTATION.TOTAL_TTC' | translate}}</th>
                    <td mat-cell
                        *matCellDef="let quotationLine"> {{quotationLine.totalTTC |currency: currency :'symbol-narrow' | space}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxFlex="30" fxFlex.lt-md="100">
                <mat-card class="mat-elevation-z2 mt-16 mb-16">
                    <h4 class="text-bold">{{'MODAL.QUOTATION.TOTAL_TTC' | translate}}</h4>
                    <h2>
                        {{quotation?.totalPrice.value  |currency:quotation?.totalPrice.currency :'symbol-narrow' | space}}
                    </h2>
                </mat-card>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
            <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="30" fxFlex.lt-md="100">
                <ngx-dropzone
                    #dropzone
                    label="{{fileUploadForm.hasFile ? fileUploadForm.file: 'FOLDER.FILE.UPLOAD_MSG' | translate}}"
                    [showPreviews]="true" [multiple]="false" [accept]="'*'" (filesAdded)="onFilesAdded($event)">
                </ngx-dropzone>
            </mat-card>

            <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="70" fxFlex.lt-md="100">
                <table mat-table [dataSource]="attachmentQuotationDataTable" class="w-100-p mt-8">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> {{'FOLDER.FILE.TITLE' | translate}}</th>
                        <td mat-cell *matCellDef="let element" class="text-left">
                            <ng-container *ngIf="element.id;else FILE_WITHOUT_DOWNLOAD">
                                <a (click)="downloadFile(element.id)" class="cursor-pointer">
                                    <p class="file_name" >
                                        <mat-icon>file_copy</mat-icon>
                                        {{element.name}}
                                    </p>
                                </a>
                            </ng-container>
                            <ng-template #FILE_WITHOUT_DOWNLOAD>
                                <p class="file_name" >
                                    <mat-icon>file_copy</mat-icon>
                                    {{!!element.info ? element.info.name : element.name}}
                                </p>
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef> {{'FOLDER.FILE.DATE' | translate}}</th>
                        <td mat-cell
                            *matCellDef="let element"><p
                            > {{!!element.info ? (currentDate | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate)  : (currentLanguage$ | async))  : (element.uploadDate | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate) : (currentLanguage$ | async)) }}
                        </p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> {{'BUTTON.ACTION' | translate}}</th>
                        <td mat-cell
                            *matCellDef="let element">

                            <ng-container *ngIf="element.id;else FILE_WITHOUT_DOWNLOAD">
                                <mat-icon color="warn" class="cursor-pointer" (click)="removeFile(element)">
                                    delete_forever
                                </mat-icon>
                            </ng-container>
                            <ng-template #FILE_WITHOUT_DOWNLOAD>
                                <mat-icon color="warn" class="cursor-pointer"
                                          (click)="removeFileFromDataTable(element)">
                                    delete_forever
                                </mat-icon>
                            </ng-template>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAttachment"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAttachment;"></tr>
                </table>
            </mat-card>
        </div>


        <div fxLayout="row" fxLayoutAlign="start center" class="mt-36">
            <form [formGroup]="quotationAcceptationForm.form" class="w-100-p">
                <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
                    <mat-radio-group formControlName="quotationAccepted" class="ml-20" fxLayoutGap="30px"
                                     labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch">

                        <mat-radio-button class="full-width-radio"
                                          *ngFor="let quotationChoice of quotationAcceptationForm.placeholders;let i = index;"
                                          [value]="quotationChoice.quotationAccepted"
                                          (change)="onItemChange(quotationChoice, i)">
                            <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                <div fxFlex>
                                    <div>{{ quotationChoice.value | translate }}</div>
                                </div>
                                <ng-container *ngIf="processWithDownPayment">
                                    <div
                                        *ngIf="isVisible == i && folder.operationMode === 'SPARE_PARTS_ORDER' && quotationAcceptationForm.form.value.quotationAccepted === 'true'">
                                        <div fxLayout="row" fxLayoutAlign="start start">
                                            <div fxLayout="column " fxLayoutAlign="space-between center">
                                                <mat-form-field appearance="outline" class="mr-12">
                                                    <mat-label>{{'MODAL.QUOTATION.DOWN_PAYMENT_VALUE'|translate}}</mat-label>
                                                    <input matInput
                                                           [placeholder]="'MODAL.QUOTATION.DOWN_PAYMENT_VALUE'|translate"
                                                           [value]="quotationAcceptationForm.form.get('downPaymentValue').value"
                                                           formControlName="downPaymentValue"
                                                           min="0" appPositiveNumberValidator
                                                           (blur)="computeDownPaymentPercent(quotationAcceptationForm.form.value.downPaymentValue)">
                                                </mat-form-field>
                                                <ng-container *ngIf="invalidValue">
                                                    <div style="color: red"> {{'INVALID_DOWN_PAYMENT_VALUE' | translate }}</div>
                                                </ng-container>
                                            </div>

                                            <div fxLayout="column" fxLayoutAlign="space-between center">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>{{'MODAL.QUOTATION.DOWN_PAYMENT_PERCENT'|translate}}</mat-label>
                                                    <input matInput
                                                           [placeholder]="'MODAL.QUOTATION.DOWN_PAYMENT_PERCENT'|translate"
                                                           formControlName="downPaymentPercent"
                                                           min="0" type="number" percentage
                                                           (blur)="calculateDownPaymentValue(quotationAcceptationForm.form.value.downPaymentPercent)">
                                                    <mat-icon class="s-16" matSuffix>%</mat-icon>
                                                </mat-form-field>
                                                <ng-container *ngIf="invalidPercent">
                                                    <div
                                                        style="color: red"> {{'INVALID_PERCENT' | translate }}</div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div fxLayout="row" fxLayoutAlign="end end">
                    <button type="button" mat-stroked-button
                            [ngClass]="!isEnabledQuotation() ?'aster_btn_disabled':'aster_btn'"
                            [disabled]="!isEnabledQuotation()"
                            (click)="validateQuotationChoiceForm()">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </form>
        </div>
    </mat-card-content>