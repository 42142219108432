/* tslint:disable:max-line-length */
import {Injectable} from '@angular/core';
import {GLOBAL} from '../../app-config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FileOriginEnum} from '../../models/enums/fileOrigin.enum';
import {Observable} from 'rxjs';
import {FileInfo, Metadata} from '../../models/file-info.model';
import {FilesUtils} from '../utils/files-utils';
import {FolderService} from './folder.service';


@Injectable({
    providedIn: 'root'
})
export class FileService {

    private backOfficePath = `${GLOBAL.gatewayEndpoint}/back-office-service`;

    constructor(private  httpClient: HttpClient,
                private folderService: FolderService,
    ) {

    }


    public uploadAttachment(file: Blob, event: any, organizationCode: string, businessKey?: string, attachmentType?: string): Observable<FileInfo> {
        const fileUploaded = new File([file], event.name, {type: event.type, lastModified: Date.now()});
        const httpHeaders = new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'x-application-source': 'SAV'
        });

        const formData = new FormData();
        formData.append('file', fileUploaded);
        formData.append('fileName', event.name);
        formData.append('fileType', event.type);
        formData.append('businessKey', businessKey);
        formData.append('organization', organizationCode);
        formData.append('origin', FileOriginEnum.BACK_OFFICE);
        if (!!attachmentType) {
            formData.append('attachmentType', attachmentType);
        }
        return this.httpClient.post<FileInfo>(`${this.backOfficePath}/api/folders/file/upload`,
            formData, {
            headers: httpHeaders
        });
    }

    public getAttachmentFile(fileId): Observable<Blob> {
        return this.httpClient.get<Blob>(`${this.backOfficePath}/api/folders/files/${fileId}`, {responseType: 'blob' as 'json'});
    }

    public getPublicAttachmentFile(fileId, folderUid): Observable<Blob> {
        return this.httpClient.get<Blob>(`${this.backOfficePath}/api/public/files/${fileId}/${folderUid}`, {responseType: 'blob' as 'json'});
    }
    public deleteAttachmentFile(fileId): Promise<any> {
        const headers = new HttpHeaders();
        return this.httpClient.delete(`${this.backOfficePath}/api/folders/file/remove/${fileId}`, {
            headers, responseType: 'text'
        }).toPromise();

    }

    public getAttachmentFileAllInfo(fileId): Observable<Metadata> {
        return this.httpClient.get<Metadata>(`${this.backOfficePath}/api/files/info/${fileId}`);
    }

    dataURItoBlob(dataURI): any {
        const byteString = atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], {type: ''});
        return blob;
    }

    onFileChange(event): any {
        const reader = new FileReader();
        let fileEvent;
        fileEvent = event[0];
        if (event && event.length) {
            const [file] = event;
            reader.readAsDataURL(file);
            return {'reader': reader, 'event': fileEvent};
        }
    }

    public downloadFile(file: FileInfo): void {
        this.folderService.getAttachmentFileFolder(file.id)
            .subscribe(resFile => {
                const reader = new FileReader();
                reader.readAsDataURL(resFile);
                reader.onloadend = () => {
                    FilesUtils.saveFile(reader.result, file.name, resFile.type);
                };
            });
    }

}
