import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UploadFileModel} from './uploadFileModel';
import {UploadFileDialogResponse} from './upload.file.dialog.response';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-serial-number-upload-modal',
    templateUrl: './upload-modal.component.html',
    styleUrls: ['./upload-modal.component.scss']
})
export class UploadModalComponent implements OnInit {
    @ViewChild('dropzone') dropzone: ElementRef;

    fileUploadForm: any = {
        url: 'assets/images/demo-content/machine_upload.jpg',
        file: null,
        hasFile: false,
        isUploading: false,
        hasSuccessfullyUploaded: false
    };
    files: any;
    deleteExistingValues: boolean;

    constructor(private dialogRef: MatDialogRef<UploadModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: UploadFileModel) {
    }

    ngOnInit(): void {
        this.closeWithEscape();
    }
    private closeWithEscape(): void {
        this.dialogRef.keydownEvents().subscribe(event => {
            if (event.key === 'Escape' || event.key === 'Esc') {
                this.onClose();
            }
        });
    }
    onClose(): void {
        this.dialogRef.close();
    }

    onCloseWithURL(): void {
        let uploadFileDialogResponse: UploadFileDialogResponse = {
            files: this.files,
            additionalInfo: {
                deleteExistingValues: !!this.deleteExistingValues}
        };
        this.dialogRef.close(uploadFileDialogResponse);
    }

    onFilesAdded($event: any): void {
        this.fileUploadForm.hasFile = true;
        this.fileUploadForm.file = $event[0].name;
        this.files = $event;
    }

    onClearDropzone(): void {
        // @ts-ignore
        this.dropzone.reset();
        this.fileUploadForm.hasFile = false;
    }

    onUpload(): void {
        this.fileUploadForm.isUploading = true;
        setTimeout(() => this.onSuccessfullyUploaded(), 200);
    }

    onSuccessfullyUploaded(): void {
        this.onUploadTerminated();
        this.fileUploadForm.hasSuccessfullyUploaded = true;
    }

    onUploadTerminated(): void {
        this.fileUploadForm.isUploading = false;
    }

}
