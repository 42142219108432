
    <mat-card-content>
        <form [formGroup]="refundStatusForm.form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <app-workflow-status-progress-bar taskTimeoutDuration="{{getTaskDuration()}}"
                                              currentStatusDate="{{getStatusDate()}}">
            </app-workflow-status-progress-bar>
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <mat-radio-group formControlName="status" class="ml-20" fxLayout="row" fxLayoutAlign="space-between center"
                             labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button [id]="'accept_confirmation'" class="full-width-radio"
                                  [value]="'true'">
                    <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div fxFlex>
                            <div>{{ 'FOLDER.WAITING_SUPPLIER_CONFIRMATION.ACCEPTED' | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
                <ng-container *ngIf="refundStatusForm.form.value.status === 'true'">
                    <div class="ml-44">
                        <fieldset>
                            <legend class="h3 title-fieldset">{{'FOLDER.ACCEPT_SUPPLIER_CONFIRMATION.DETAILS' | translate}}</legend>
                        <mat-form-field class="py-4 w-70-p" appearance="outline">
                            <mat-label>{{'FOLDER.WAITING_SUPPLIER_CONFIRMATION.AGREEMENT_NUMBER'|translate}}</mat-label>
                            <input [id]="'refund_agreement_number'" matInput placeholder="{{'FOLDER.WAITING_SUPPLIER_CONFIRMATION.AGREEMENT_NUMBER'|translate}}"
                                   inputControl
                                   required
                                   formControlName="refundAgreementNumber">
                        </mat-form-field>

                        <mat-radio-group [formControlName]="'sendProduct'" fxLayout="row" fxLayoutAlign="space-between center"
                                         labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                            <mat-radio-button  [id]="'product_destruction'" [checked]="isDestructionProduct" (click)="clearCarrierInfo()" class="full-width-radio" value="false">
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <div fxFlex>
                                        {{'FOLDER.WAITING_SUPPLIER_CONFIRMATION.DESTRUCTION_PRODUCT' | translate}}
                                    </div>
                                </div>
                            </mat-radio-button>
                            <mat-radio-button  [id]="'send_product_to_supplier'" [checked]="isShipmentToSupplier" class="full-width-radio" value="true">
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <div fxFlex>
                                        {{'FOLDER.WAITING_SUPPLIER_CONFIRMATION.SHIPMENT_TO_SUPPLIER' | translate}}
                                    </div>
                                </div>
                            </mat-radio-button>
                        </mat-radio-group>

                        <ng-container *ngIf="sendToSupplier()">
                            <app-choose-carrier [folder]="folder"
                                                (carrierCode)="changeCarrierCode($event)"
                                                (carrierExtraDataEvent)="changeCarrierExtraEvent($event)"
                                                (selectedTransportModeEvent)="changeTransportModeEvent($event)"
                            ></app-choose-carrier>
                        </ng-container>
                        </fieldset>
                    </div>
                </ng-container>
                <mat-radio-button [id]="'refuse_confirmation'" class="full-width-radio" (click)="clearCarrierInfo()"
                                  [value]="'false'">
                    <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div fxFlex>
                            <div>{{ 'FOLDER.WAITING_SUPPLIER_CONFIRMATION.REFUSED' | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
                <ng-container *ngIf="refundStatusForm.form.value.status === 'false'">
                    <div class="ml-44">
                        <app-comment-area [commentForm]="refundStatusForm.form.get('comment')"
                                          [isRequired]="true"
                                          [title]="this.commentTitle"></app-comment-area>
                    </div>
                </ng-container>
            </mat-radio-group>



            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        [ngClass]="isFormInvalid() ?'aster_btn_disabled':'aster_btn'"
                        [disabled]="isFormInvalid()">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>