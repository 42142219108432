import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'confirmation-with-comment',
    templateUrl: './confirmation-with-comment.component.html',
    styleUrls: ['./confirmation-with-comment.component.scss']
})
export class ConfirmationWithCommentComponent implements OnInit {

    id: string;
    reasonsChange = ['REASON_1', 'REASON_2' , 'REASON_3', 'REASON_4', 'OTHER'];

    withCommentReason = false;
    public title = 'Confirm';
    public description;
    public commentMessage = 'Are you sure?';
    public confirmButtonLabel = 'Confirm';
    public cancelButtonLabel = 'Cancel';

    form: FormGroup;
    reasonTitle: string;

    constructor(private dialogRef: MatDialogRef<ConfirmationWithCommentComponent>,
                @Inject(MAT_DIALOG_DATA) public data) {
    }
    ngOnInit(): void {
        this.form = new FormGroup({
            comment: new FormControl(null, [Validators.required]),
        });
        if (this.withCommentReason) {
            this.form.addControl('reason', new FormControl(null, [Validators.required]));
        }
    }
    onCloseModal(): void {
        this.dialogRef.close();
    }

    validateForm(): void {
        if (this.form.invalid) {
            return;
        }
        this.dialogRef.close({
            comment: this.form.value.comment,
            reason: !!this.withCommentReason ? this.form.value.reason : null,
        });
    }

    changeCommentValidator(value: any) {
        this.form.get('comment').setValue(null);
        this.form.get('comment').updateValueAndValidity();
    }
}
