
    <app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
    <mat-card-content class="mt-25">
        <div class="mt-12" fxLayout="column" fxLayoutGap="15px">
            <div fxFlex="33">
                <span class="font-weight-600">{{'EXPERT_DECISION' | translate}} :</span>
                <span> {{'DIAG_EXPERT_' + diagExpertDecision | translate}} </span>
            </div>
            <div class="mt-12"  fxFlex="100">
                <span class="font-weight-600">{{'EXPERTISE_COMMENT' | translate}} :</span>
                <span> {{ diagExpertComment | translate}} </span>
            </div>
        </div>
        <div class="mt-12 pt-12" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" *ngIf="isWarrantyDisclaimer()">
            <mat-form-field appearance="outline" fxFlex="30">
                <mat-label>{{'FOLDER.WARRANTY_DISCLAIMER_MOD_OPS.TITLE' | translate}}</mat-label>
                <mat-select  [(value)]="selectedWarrantyDisclaimerModOp">
                    <mat-select-trigger>
                        <mat-icon class="s-20"
                                  [svgIcon]="getIconName(selectedWarrantyDisclaimerModOp)">
                        </mat-icon>
                        {{selectedWarrantyDisclaimerModOp | codeToLabel  :'SAV.CONFIG.MODE_OP' | async}}
                      </mat-select-trigger>

                    <mat-option *ngFor="let procedure of warrantyDisclaimerModOp" [value]="procedure">
                        <mat-icon class="s-20"
                                  [svgIcon]="getIconName(procedure)">
                        </mat-icon>
                        {{procedure | codeToLabel  :'SAV.CONFIG.MODE_OP' | async}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayoutAlign="end center" fxLayoutGap="10px">
            <button type="button" mat-button
                    [id]="'resend_to_expert'"
                    (click)="resendToExpert()"
                    class="aster_btn_cancel">
                {{  'BUTTON.RESEND_TO_EXPERT' | translate}}
            </button>
            <button type="submit" mat-stroked-button
                    class="aster_btn"
                    (click)="onSubmit()">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>
    </mat-card-content>