import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FolderPublic} from '../../../../models/folder.public.model';
import {PublicService} from '../../../../shared/services/public.service';
import {CommentType} from '../../../../models/enums/CommentType.enum';

@Component({
    selector: 'app-public-waiting-credit-supplier',
    templateUrl: './public-waiting-credit-supplier.component.html',
    styleUrls: ['./public-waiting-credit-supplier.component.css']
})
export class PublicWaitingCreditSupplierComponent implements OnInit {

    @Input() folder: FolderPublic;

    @Output() inputMap = new EventEmitter<any>();
    uidFolder: string;

    creditSupplierStatusForm: any = {
        form: null,
        placeholders: []
    };

    constructor(private router: Router,
                private route: ActivatedRoute,
                private folderService: PublicService) {
    }

    ngOnInit(): void {
        this.initForm();
        this.uidFolder = this.route.snapshot.params.uid;
    }

    private initForm(): void {
        this.creditSupplierStatusForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            supplierComment: new FormControl(''),
        });
        this.creditSupplierStatusForm.placeholders = [
            {
                status: 'FOLDER_CLOSED_CREDIT_SUPPLIER_ACCEPTED',
                value: 'FOLDER.WAITING_CREDIT_SUPPLIER.ACCEPTED',
            },
            {
                status: 'FOLDER_CLOSED_CREDIT_SUPPLIER_REFUSED',
                value: 'FOLDER.WAITING_CREDIT_SUPPLIER.REFUSED',
            },
            {
                status: 'FOLDER_CLOSED_CREDIT_SUPPLIER_CANCELED',
                value: 'FOLDER.WAITING_CREDIT_SUPPLIER.CANCELED',
            },
        ];
    }

    onSubmit(): void {
        const closingFolderReason = this.route.snapshot.params.closingFolderReason;
        this.inputMap.emit({
            closingFolderReason: this.creditSupplierStatusForm.form.value.status
        });
        if (this.readyToSent()) {
            this.folderService.updateFolderWithSupplierComment(this.folder.externalUid, this.creditSupplierStatusForm.form.value.supplierComment, CommentType.COMMENT_GENERAL_SUPPLIER)
                .subscribe(folder => {
                this.creditSupplierStatusForm.form.controls['supplierComment'].setValue(null);
                this.creditSupplierStatusForm.form.controls['supplierComment'].setErrors(null);
            });
        }
    }

    readyToSent(): boolean {
        return !!this.creditSupplierStatusForm.form.value.supplierComment;
    }
}
