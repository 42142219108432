import {Action} from '@ngrx/store';
import {OrganizationConfiguration} from '../../models/organization.model';
import {CodeLabel} from '../../models/element.model';

export enum UserActionType {
    LOAD_USER = '[USER] Load',
    USER_LOADED_SUCCESSFULLY = '[USER] Successful Load',
    LOGIN = '[USER] Login',
    FIRST_LOAD = '[USER] First Load',
    LOGOUT = '[USER] Logout',
    LOAD_ROLES = '[USER] Load Roles',
    ROLES_LOADED_SUCCESSFULLY = '[USER] Roles Loaded Successfully',
    LOAD_SCOPES = '[USER] Load Scopes',
    SCOPES_LOADED_SUCCESSFULLY = '[USER] Scopes Loaded Successfully',
    SWITCH_ORGANIZATION = '[USER] Switch Organization',
    SWITCH_CONTEXT = '[USER] Switch Context',
    SWITCH_BUSINESS_LINK = '[USER] Switch Business Link',
    RESET_BUSINESS_LINK = '[USER] Reset Business Link',
    UPDATE_BUSINESS_LINKS = '[USER] Update Business Links',
    LOAD_INFO_CAMPAIGN_LINKS = '[USER] Load Info Campaign Links',
    ROUTE_TO_FOLDER_LIST = '[USER] Route To Folder List',
}

export class LoadUser implements Action {
    readonly type = UserActionType.LOAD_USER;
}

export class UserLoadedSuccessfully implements Action {
    readonly type = UserActionType.USER_LOADED_SUCCESSFULLY;

    constructor(public userProfile) {
    }
}

export class Login implements Action {
    readonly type = UserActionType.LOGIN;
}

export class FirstLoad implements Action {
    readonly type = UserActionType.FIRST_LOAD;

    constructor(public isFirst: boolean) {
    }
}

export class Logout implements Action {
    readonly type = UserActionType.LOGOUT;
}

export class LoadRoles implements Action {
    readonly type = UserActionType.LOAD_ROLES;
}

export class RolesLoadedSuccessfully implements Action {
    readonly type = UserActionType.ROLES_LOADED_SUCCESSFULLY;

    constructor(public roles: string[]) {
    }
}

export class LoadScopes implements Action {
    readonly type = UserActionType.LOAD_SCOPES;
}

export class ScopesLoadedSuccessfully implements Action {
    readonly type = UserActionType.SCOPES_LOADED_SUCCESSFULLY;

    constructor(public scopes: string[]) {
    }
}

export class SwitchOrganization implements Action {
    readonly type = UserActionType.SWITCH_ORGANIZATION;

    constructor(public organization: OrganizationConfiguration) {
    }
}

export class SwitchContext implements Action {
    readonly type = UserActionType.SWITCH_CONTEXT;

    constructor(public context: string) {
    }
}

export class SwitchBusinessLink implements Action {
    readonly type = UserActionType.SWITCH_BUSINESS_LINK;

    constructor(public businessLink: string) {
    }
}

export class ResetBusinessLink implements Action {
    readonly type = UserActionType.RESET_BUSINESS_LINK;
}

export class UpdateBusinessLinks implements Action {
    readonly type = UserActionType.UPDATE_BUSINESS_LINKS;

    constructor(public businessLinks: CodeLabel[]) {
    }
}

export class LoadInfoCampaignLinks implements Action {
    readonly type = UserActionType.LOAD_INFO_CAMPAIGN_LINKS;
}

export class RouteToFolderList implements Action {
    readonly type = UserActionType.ROUTE_TO_FOLDER_LIST;
}

export type UserActions =
    LoadUser | UserLoadedSuccessfully |
    Login | Logout |
    LoadRoles | RolesLoadedSuccessfully |
    LoadScopes | ScopesLoadedSuccessfully |
    SwitchOrganization | SwitchContext |
    SwitchBusinessLink | ResetBusinessLink | UpdateBusinessLinks |
    LoadInfoCampaignLinks | RouteToFolderList | FirstLoad;
