import {Component, HostListener, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PendingStatusReasonEnum} from '../../../models/enums/pendingStatusReasonEnum';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-suspending-folder-reason-dialog', templateUrl: './suspending-folder-reason-dialog.component.html', styleUrls: ['./suspending-folder-reason-dialog.component.css']
})
export class SuspendingFolderReasonDialogComponent implements OnInit {

    suspendingFolderReasonForm: any = {
        form: null, placeholders: []
    };

    constructor(public dialogRef: MatDialogRef<SuspendingFolderReasonDialogComponent>) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    onSubmit(): void {
        this.dialogRef.close(this.suspendingFolderReasonForm.form.value);
    }

    closeDialog(): void {
        this.dialogRef.close();

    }

    private initForm(): void {
        this.suspendingFolderReasonForm.form = new FormGroup({
            reason: new FormControl(null, Validators.required), comment: new FormControl(null)
        });
        this.suspendingFolderReasonForm.placeholders = [{
            code: PendingStatusReasonEnum.LITIGE, value: 'BUTTON.PENDING_FOLDER_REASON_LITIGE'
        }, {
            code: PendingStatusReasonEnum.WAITING_FOR_SUPPLIER, value: 'BUTTON.PENDING_FOLDER_REASON_WAITING_FOR_SUPPLIER',
        }, {
            code: PendingStatusReasonEnum.WAITING_FOR_CUSTOMER_INFO, value: 'BUTTON.PENDING_FOLDER_REASON_WAITING_FOR_CUSTOMER_INFO',
        }, {
            code: PendingStatusReasonEnum.OTHER_REASON, value: 'BUTTON.PENDING_FOLDER_REASON_OTHER_REASON',
        }];
    }

}
