
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutAlign="start">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
        </div>
        <mat-slide-toggle [(ngModel)]="productInStore" (toggleChange)="onToggle()">
            {{'COMPONENT.PRODUCT_AT_STORE' | translate}}
        </mat-slide-toggle>
    </div>

    <mat-card-content *ngIf="!!folder">
        <form *ngIf="form" [formGroup]="form" fxLayout="column"
              fxLayoutAlign="start" (ngSubmit)="onSubmit()" autocomplete="off" id="form1">


            <div fxLayout="row wrap" gdColumns="repeat(auto-fit, minmax(100%, 1fr))" gdGap="10px"
                 formGroupName="countryCode" class="mt-25">
                <app-generic-address-form [addressesInput]="customerAddress"
                                          [withAccessibility]="isProductRecallOrReparationContext()"></app-generic-address-form>
            </div>

            <app-comment-area [commentForm]="form.get('comment')"></app-comment-area>

            <div fxLayoutAlign="end center" fxLayoutGap="10px">
                <ng-container *ngIf="!!folder.customer">
                    <button mat-stroked-button type="submit"
                            [disabled]="isFormDisabled()"
                            [ngClass]="(isFormDisabled()?' aster_btn_disabled':' aster_btn')">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </ng-container>
            </div>
        </form>
    </mat-card-content>