export class Alert {
    id: string;
    businessId: string;
    content: string;
    attachmentIds?: string[];

}

export interface AlertInfo {
    content: string;
    attachmentIds?: string[];
}

export interface AlertDto {
    businessId: string;
    content: string;
    attachmentIds?: string[];

}

