import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-check-product-conformity',
    templateUrl: './check-product-conformity.component.html',
    styleUrls: ['./check-product-conformity.component.css']
})
export class CheckProductConformityComponent implements OnInit {
    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    isChooseOtherCarrier = false;
    checkConformityForm: any = {
        form: null,
        placeholders: []
    };

    constructor() {
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.checkConformityForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            carrier: new FormControl(null),
            trackingNumber: new FormControl(null),
            description: new FormControl(''),
        });


        this.checkConformityForm.placeholders = [
            {
                status: 'PRODUCT_COMPLIANT',
                value: 'BUTTON.PRODUCT_COMPLIANT',
            },
            {
                status: 'PRODUCT_NOT_COMPLIANT',
                value: 'BUTTON.PRODUCT_NOT_COMPLIANT',
            }
        ];
    }

    onSubmit() {
        this.inputMap.emit({
            'productCompliance': this.checkConformityForm.form.value.status === 'PRODUCT_COMPLIANT' ? 'OK' : 'KO',
            'carrierCode': this.checkConformityForm.form.value.carrier ? this.checkConformityForm.form.value.carrier : 'CHRONOPOST',
            'trackingNumber': this.checkConformityForm.form.value.trackingNumber,
            'closeFolderComment': this.checkConformityForm.form.value.description,
            'closeFolderReason': 'FOLDER_CLOSED_PRODUCT_NOT_COMPLIANT'
        });
    }

    chooseOtherCarrier() {
        this.isChooseOtherCarrier = !this.isChooseOtherCarrier;
    }
}
