import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {PushNotification} from './pushNotification.model';
import {Alert} from '../Alert.model';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationSubjectService {
    private pushNotification = new ReplaySubject<PushNotification>(1);
    public pushNotification$ = this.pushNotification.asObservable();
    private currentPushNotification: PushNotification;

    private alert = new ReplaySubject<Alert>(1);
    public alert$ = this.alert.asObservable();
    private currentAlert: Alert;

    constructor() {
    }

    refreshPushNotification(pushNotification: PushNotification): void {
        this.pushNotification.next(pushNotification);
        this.currentPushNotification = pushNotification;
    }

    refreshAlert(alert: Alert): void {
        this.alert.next(alert);
        this.currentAlert = alert;
    }

}
