import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {FuseModule} from '../../../../../@fuse/fuse.module';
import {fuseConfig} from '../../../../fuse-config';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '../../../../../@fuse/components';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../../../../app.module';
import {HttpClient} from '@angular/common/http';
import {SharedGenericModule} from '../../../../shared/generic/shared-generic.module';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {WaitingClientSwapComponent} from './waiting-client-swap/waiting-client-swap.component';
import {WaitingForHsProductDestructionByCustomerComponent} from './waiting-for-hs-product-destruction-by-customer/waiting-for-hs-product-destruction-by-customer.component';
import {ChoosingSwapLocationComponent} from './choosing-swap-location/choosing-swap-location.component';
import {ProductQuantityCheckComponent} from './product-quantity-check/product-quantity-check.component';
import { RefundCustomerComponent } from './refund-customer/refund-customer.component';
import { WaitingSwapInStoreComponent } from './waiting-swap-in-store/waiting-swap-in-store.component';
import { ValidateSwapInStoreComponent } from './validate-swap-in-store/validate-swap-in-store.component';


@NgModule({
    entryComponents: [
        WaitingClientSwapComponent,
        RefundCustomerComponent
    ],
    declarations: [
        WaitingClientSwapComponent,
        WaitingForHsProductDestructionByCustomerComponent,
        ChoosingSwapLocationComponent,
        ProductQuantityCheckComponent,
        RefundCustomerComponent,
        WaitingSwapInStoreComponent,
        ValidateSwapInStoreComponent
    ],
    imports: [FormsModule,
        // Material moment date module
        MatMomentDateModule,
        // Material
        Ng2TelInputModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedGenericModule,
        MatCardModule, MatIconModule, MatTableModule, NgxDropzoneModule,
    ],
    exports: [
        WaitingClientSwapComponent,
        RefundCustomerComponent
    ]
})
export class SwapProcessModule {
}
