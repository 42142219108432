import {CustomerApiInfo} from '../../models/customer/customer-api-info.model';
import {Customer} from '../../models/customer/customer.model';
import {PagedResult} from '../../models/paged-result.model';

export class CustomerState {
    customerApiInfo: CustomerApiInfo;
    customer: Customer;
    customerSearchResult: PagedResult<Customer>;
    isCustomerFormValid: boolean;
    isFolderCreationWithoutCustomer: boolean;
    isProfessional: boolean;
    isStockClaimCreation: boolean;
}

export const initialCustomerState = {
    customer: new Customer()
} as CustomerState;
