<div>
    <h2>
        <mat-icon class="mt-20">payment</mat-icon>
        {{'FOLDER.COMMENT.PAYMENT.LABEL' | translate}}
    </h2>
</div>
<div class="page-layout simple fullwidth mat-elevation-z1">
    <table mat-table [dataSource]="dataSourcePayments" multiTemplateDataRows>
        <ng-container matColumnDef="paymentReason">
            <th mat-header-cell *matHeaderCellDef class="table-header">
                {{'COMPONENT.PAYMENT.PAYMENT_REASON'|translate}}
            </th>
            <td mat-cell
                *matCellDef="let payment">{{'COMPONENT.PAYMENT.PAYMENT_REASON.' + payment.paymentReason | translate}}
            </td>
        </ng-container>
        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef class="table-header">
                {{'COMPONENT.PAYMENT.PAYMENT_AMOUNT'|translate}}
            </th>
            <td mat-cell
                *matCellDef="let payment"> {{payment.amount |currency: (currency$ | async) :'symbol-narrow' | space}}</td>
        </ng-container>
        <ng-container matColumnDef="refund">
            <th mat-header-cell *matHeaderCellDef class="table-header">
                {{'COMPONENT.PAYMENT.REFUND'|translate}}
            </th>
            <td mat-cell *matCellDef="let payment">
                <ng-container *ngIf="isRefundEnabled(payment)">
                    <ng-container *ngIf="(hasScope(Scopes.PAYMENT_REFUND) | async); else HAS_NOT_ACCESS_TO_REFUND">
                        <button type="submit" mat-stroked-button class="aster_btn cursor-pointer"
                                (click)="refund(payment)">
                            <mat-icon  svgIcon="refund-visio"></mat-icon>
                            {{'COMPONENT.PAYMENT.REFUND_TITLE'|translate}}
                        </button>
                    </ng-container>
                    <ng-template #HAS_NOT_ACCESS_TO_REFUND>
                        <div>{{'--'}}</div>
                    </ng-template>
                </ng-container>
                <ng-container *ngIf="!isRefundEnabled(payment)">
                    <mat-icon>{{isRefundCarriedOut(payment) ? 'check' : 'remove'}}</mat-icon>
                </ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="payment-line-element-detail"
                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="w-100-p p-20">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="h4 text-bold">
                                {{'COMPONENT.PAYMENT.PAYMENT_REFERENCE'|translate}}
                            </div>
                            <div class="h4 ml-4">
                                {{element.paymentReference}}
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="h4 text-bold"> {{'COMPONENT.PAYMENT.PAYMENT_DATE'|translate}}</div>
                            <div class="h4 ml-4">
                                {{element.createdDate | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate) : (currentLanguage$ | async)}}
                            </div>
                        </div>
                        <ng-container *ngIf="isRefundCarriedOut(element)">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div class="h4 text-bold"> {{'COMPONENT.PAYMENT.REFUND_REFERENCE'|translate}}</div>
                                <div class="h4 ml-4">
                                    {{element.refunds[0].refundReference}}
                                </div>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div class="h4 text-bold"> {{'COMPONENT.PAYMENT.REFUND_DATE'|translate}}</div>
                                <div class="h4 ml-4">
                                    {{element.refunds[0].createdDate | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate) : (currentLanguage$ | async)}}
                                </div>
                            </div>
                        </ng-container>
                        <div fxLayout="row">
                            <div class="h4 text-bold"> {{'COMPONENT.PAYMENT.PAYMENT_MODE'|translate}}</div>
                            <div
                                class="h4 ml-4">{{'COMPONENT.PAYMENT.PAYMENT_MODE.' + element.paymentMode | translate}}</div>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let payment; columns: displayedColumns;"
            class="payment-line-element-row cursor-pointer"
            [class.example-expanded-row]="expandedElement === payment"
            (click)="expandedElement = expandedElement === payment ? null : payment">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
</div>



