import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-customer-decision-non-repairable2',
    templateUrl: './waiting-customer-decision-non-repairable2.component.html',
    styleUrls: ['./waiting-customer-decision-non-repairable2.component.scss']
})
export class WaitingCustomerDecisionNonRepairable2Component {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    choiceDone: boolean;
    retrieveNotRepairedProduct;

    constructor(private store$: Store<AppState>) {
    }

    customerChoice(customerChoice: boolean): void {
        this.retrieveNotRepairedProduct = customerChoice;
    }

    onSubmit(): void {
        if (!this.choiceDone) {
            return;
        }
        this.inputMap.emit({
            retrieveNotRepairedProduct: this.retrieveNotRepairedProduct
        });
    }

    isChoiceDone(choiceDone: boolean): void {
        this.choiceDone = choiceDone;
    }

}
