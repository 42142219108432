<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="column" fxLayoutAlign="start center">

    <div fxLayout="row" class="w-100-p mb-16" fxLayoutAlign="space-between center">
        <div>
            <h1 class="text-bold">{{'NAV.FOLDER.LIST' | translate}}</h1>
        </div>
        <div>
            <button type="button" mat-icon-button class="aster_accent" [matTooltip]="'NAV.FOLDER.HELP' | translate"
                    (click)="showGuideFolderList()">
                <mat-icon svgIcon="idea"></mat-icon>
            </button>
            <button type="button" mat-icon-button class="aster_accent" [matTooltip]="'NAV.FOLDER.REFRESH' | translate"
                    (click)="refreshFolderList()">
                <mat-icon> refresh</mat-icon>
            </button>
            <button type="button" mat-stroked-button class="aster_accent w-100 cursor-pointer"
                    [disabled]="isCsvExportDisabled()"
                    (click)="exportClaims()">
                <mat-icon>archive</mat-icon>
                <span
                    [matTooltip]="(isCsvExportDisabled() ?  'NAV.FOLDER.EXPORT_REFINE' : 'NAV.FOLDER.EXPORT') | translate">
                    {{'NAV.FOLDER.EXPORT' | translate}}</span>
            </button>
        </div>
    </div>
    <div class="center w-100-p">
        <mat-card class="mat-elevation-z2 w-100-p  mt-8" id="zoneSearch">
            <mat-card-content *ngIf="!!filterFoldersForms">
                <span class="bar-bleu mt-12"></span>

                <form [formGroup]="filterFoldersForms" (ngSubmit)="onSubmit()">

                    <div fxLayout="row" class="form-search mt-8"
                         fxLayoutAlign="space-between center" fxLayoutGap="15px">
                        <mat-form-field appearance="outline" fxFlex="27" id="searchValue">
                            <input matInput (keyup.enter)="searchValueChanged()" formControlName="searchValue"
                                   inputControl
                                   placeholder="{{'BUTTON.SEARCH' | translate}}">
                            <mat-hint>* {{'FOLDER.TABLE.FILTER.TREE.HINT.CRITERIA_SEARCH' | translate}}</mat-hint>
                        </mat-form-field>

                        <app-search-phone-countries [(value)]="customerContactNumber"></app-search-phone-countries>

                        <mat-form-field appearance="outline" fxFlex="15" id="warranty">
                            <mat-label>{{'FOLDER.WARRANTY.TITLE' | translate}}</mat-label>
                            <mat-select (selectionChange)="changeWarranty($event.value)"
                                        formControlName="newWarranty">
                                <mat-select-trigger>
                                    <ng-container
                                        *ngIf="filterFoldersForms.controls['newWarranty'].value && filterFoldersForms.controls['newWarranty'].value.icon;else SHOW_ALL_WARRANTY_LABEL">
                                        <mat-icon class="s-20"
                                                  [ngClass]="filterFoldersForms.controls['newWarranty'].value.icon.class">
                                            {{filterFoldersForms.controls['newWarranty'].value?.icon.code}}
                                        </mat-icon>
                                        {{('FOLDER.WARRANTY.ENUM_LABELS.' + filterFoldersForms.controls['newWarranty'].value?.key) |translate}}
                                    </ng-container>
                                    <ng-template #SHOW_ALL_WARRANTY_LABEL>
                                        {{'MOCK.ANY_LABEL' |translate}}
                                    </ng-template>
                                </mat-select-trigger>
                                <mat-option
                                    value="''">{{'MOCK.ANY_LABEL' |translate}}</mat-option>
                                <mat-option *ngFor="let warranty of listWarranty"
                                            [value]="warranty">
                                    <mat-icon class="s-20"
                                              [ngClass]="warranty.icon.class">
                                        {{warranty.icon.code}}
                                    </mat-icon>
                                    {{('FOLDER.WARRANTY.ENUM_LABELS.' + warranty.key) |translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <warranty-reason-selector fxFlex="20" [(selectedWarrantyReasons)]="warrantyReasonsToFilter">
                        </warranty-reason-selector>

                        <operating-mode-selector fxFlex="16" [(selectedOperatingMode)]="operatingModeToFilter">
                        </operating-mode-selector>
                        <div fxFlex="4">
                            <div fxLayoutAlign="end end" fxLayout="row" id="advancedSearch">
                                <button mat-button [matMenuTriggerFor]="beforeMenu" class="aster_accent" type="button">
                                    <mat-icon>add</mat-icon>
                                    {{'FOLDER.TABLE.ADD_COLUMN' | translate}}
                                </button>
                                <mat-menu #beforeMenu="matMenu" xPosition="before" [overlapTrigger]="true"
                                          class="menu-filter-search">
                                    <form [formGroup]="optionFilterFormGroup" class="select-node-filters"
                                          *ngIf="optionsFilters">
                                        <mat-form-field class="w-100-p search-form-input" appearance="fill">
                                            <mat-label>{{'BUTTON.SEARCH' | translate }}</mat-label>
                                            <input matInput [placeholder]="'BUTTON.SEARCH' | translate"
                                                   formControlName="searchOption" (click)="$event.stopPropagation()">
                                            <div matSuffix>
                                                <button mat-icon-button
                                                        (click)="optionFilterFormGroup.controls['searchOption'].value?onClearFormControlValueByPath('searchOption'):null">
                                                    <mat-icon>{{optionFilterFormGroup.controls['searchOption'].value ? 'close' : 'search'}}</mat-icon>
                                                </button>
                                            </div>
                                        </mat-form-field>
                                        <div class="p-12">
                                            <ng-container *ngFor="let option of optionsFilters  | keyvalue">
                                                <ng-container *ngIf="!!option.value ;else ONE_FIELD">
                                                    <div class="h3 title-checkbox-field mb-4"
                                                         *ngIf="checkIfEmptyListChildOption(option.key)">
                                                        {{'FOLDER.TABLE.FILTER.TREE.' + option.key.toString().toUpperCase() | translate}}
                                                    </div>
                                                    <ng-container *ngFor="let childOption of option.value  | keyvalue">
                                                        <div class="w-100-p ml-8"
                                                             *ngIf="fieldsFilterSearch.indexOf(childOption.key.toString())>-1">
                                                            <mat-checkbox
                                                                (change)="fieldFilterSelected(childOption.key.toString())"
                                                                [value]="childOption.key"
                                                                [formControlName]="childOption.key">
                                                                {{'FOLDER.TABLE.FILTER.TREE.' + childOption.key.toString().toUpperCase() | translate}}
                                                            </mat-checkbox>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #ONE_FIELD>
                                                    <div class="w-100-p">
                                                        <div class="h3 title-checkbox-field"
                                                             *ngIf="fieldsFilterSearch.indexOf(option.key.toString())>-1">
                                                            <mat-checkbox (change)="fieldFilterSelected(option.key)"
                                                                          [value]="option.key"
                                                                          class="w-100-p"
                                                                          [formControlName]="option.key">
                                                                {{'FOLDER.TABLE.FILTER.TREE.' + option.key.toString().toUpperCase() | translate}}
                                                            </mat-checkbox>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                    </form>

                                </mat-menu>

                            </div>
                        </div>
                        <div fxFlex="4">
                            <button type="button" mat-icon-button aria-label="reset" class="aster_accent"
                                    (click)="onResetSearchFilter()" id="resetButton">
                                <mat-icon>settings_backup_restore</mat-icon>
                            </button>
                        </div>
                    </div>


                    <div class="form-search scroll-tree mt-8 w-100-p" *ngIf="filterTreeData && filterTreeData.length>0">
                        <ng-container *ngFor="let line of convertToArray(filterTreeData.length)">
                            <div fxLayout="row" fxLayout.lt-md="column"
                                 fxLayoutGap="12px" class="pt-8" fxLayoutAlign="flex-start">
                                <ng-container *ngFor="let itemNode of filterTreeData;let i=index">

                                    <ng-container *ngIf="i>=line && i<line+numberInputOfLine">
                                        <ng-container [ngSwitch]="itemNode">
                                            <ng-container *ngSwitchCase="'currentWorkFlowStatus'">
                                                <div fxFlex="0 1 calc(25% - 12px)"
                                                     fxFlex.lt-md="0 1 calc(50% - 12px)"
                                                     fxFlex.lt-sm="100%">
                                                    <button mat-icon-button class="btn-close-form-field"
                                                            (click)="removeFieldSearch(itemNode.toString())">
                                                        <mat-icon class="close-icon">close</mat-icon>
                                                    </button>
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>{{'FOLDER.TABLE.FILTER.STATUS' | translate}}</mat-label>
                                                        <mat-select
                                                            (selectionChange)="selectStatusWorkflow($event.value)"
                                                            multiple
                                                            #statusSelect formControlName="currentWorkFlowStatus">
                                                            <mat-select-trigger>
                                                                <ng-container
                                                                    *ngFor="let label of this.filterFoldersForms.controls['currentWorkFlowStatus'].value ">
                                                                    {{label}},
                                                                </ng-container>
                                                            </mat-select-trigger>
                                                            <mat-option>
                                                                <ngx-mat-select-search
                                                                    formControlName="currentWorkFlowStatusCtrl"
                                                                    [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                                                    [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate">
                                                                </ngx-mat-select-search>
                                                            </mat-option>
                                                            <div fxLayout="row" fxLayoutAlign="center center"
                                                                 class="mt-12 mb-12"
                                                                 fxLayoutGap="12px">
                                                                <button mat-raised-button
                                                                        class="mat-primary fill text-sm"
                                                                        (click)="selectAll(statusSelect)">
                                                                    {{'FOLDER.TABLE.FILTER.SELECT_ALL_STATUS' | translate}}
                                                                </button>
                                                                <button mat-raised-button
                                                                        class="mat-accent fill text-sm"
                                                                        (click)="deselectAll(statusSelect)">
                                                                    {{'FOLDER.TABLE.FILTER.DESELECT_SELECT_ALL' | translate}}
                                                                </button>
                                                            </div>

                                                            <mat-option
                                                                *ngFor="let label of this.filteredStatusListFolder | async"
                                                                [value]="label">
                                                                {{label}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngSwitchCase=" LIST_INPUT_RANG_DATE.includes(itemNode.toString()) ? itemNode : '' ">
                                                <div fxFlex="0 1 calc(25% - 12px)"
                                                     fxFlex.lt-md="0 1 calc(50% - 12px)"
                                                     fxFlex.lt-sm="100%">
                                                    <button mat-icon-button class="btn-close-form-field"
                                                            (click)="removeFieldSearch(itemNode.toString())">
                                                        <mat-icon class="close-icon">close</mat-icon>
                                                    </button>
                                                    <mat-form-field appearance="outline" class="w-100-p">
                                                        <mat-label>{{'FOLDER.TABLE.FILTER.TREE.' + itemNode.toString().toUpperCase() | translate}}</mat-label>
                                                        <input matInput [formControlName]="itemNode"
                                                               [placeholder]="'FOLDER.TABLE.FILTER.TREE.'+itemNode.toString().toUpperCase() | translate"
                                                               [satDatepicker]="picker5">
                                                        <sat-datepicker #picker5 [rangeMode]="true"
                                                                        [selectFirstDateOnClose]="true">
                                                        </sat-datepicker>
                                                        <sat-datepicker-toggle matSuffix [for]="picker5">
                                                        </sat-datepicker-toggle>
                                                    </mat-form-field>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'tags'">
                                                <div
                                                    fxFlex="0 1 calc(25% - 12px)"
                                                    fxFlex.lt-md="0 1 calc(50% - 12px)"
                                                    fxFlex.lt-sm="100%">
                                                    <section class="mt-44">
                                                        <mat-slide-toggle formControlName="tags" class="ml-4">
                                                            {{'FOLDER.TABLE.FILTER.FAVORITES' | translate}}
                                                        </mat-slide-toggle>
                                                        <button mat-icon-button
                                                                class="btn-close-form-field mat-slide-close"
                                                                (click)="removeFieldSearch(itemNode.toString())">
                                                            <mat-icon class="close-icon">close</mat-icon>
                                                        </button>
                                                    </section>
                                                </div>

                                            </ng-container>
                                            <ng-container *ngSwitchCase="'stockClaim'">
                                                <div
                                                    fxFlex="0 1 calc(25% - 12px)"
                                                    fxFlex.lt-md="0 1 calc(50% - 12px)"
                                                    fxFlex.lt-sm="100%">
                                                    <section class="mt-44">
                                                        <mat-slide-toggle formControlName="stockClaim" class="ml-4">
                                                            {{'FOLDER.TABLE.FILTER.TREE.STOCKCLAIM' | translate}}
                                                        </mat-slide-toggle>
                                                        <button mat-icon-button
                                                                class="btn-close-form-field mat-slide-close"
                                                                (click)="removeFieldSearch(itemNode.toString())">
                                                            <mat-icon class="close-icon">close</mat-icon>
                                                        </button>
                                                    </section>
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngSwitchCase="LIST_INPUT_STRING.includes(itemNode.toString()) ? itemNode : ''  ">
                                                <div
                                                    fxFlex="0 1 calc(25% - 12px)"
                                                    fxFlex.lt-md="0 1 calc(50% - 12px)"
                                                    fxFlex.lt-sm="100%">
                                                    <button mat-icon-button class="btn-close-form-field"
                                                            (click)="removeFieldSearch(itemNode.toString())">
                                                        <mat-icon class="close-icon">close</mat-icon>
                                                    </button>
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>{{'FOLDER.TABLE.FILTER.TREE.' + itemNode.toString().toUpperCase() | translate}}</mat-label>
                                                        <input matInput [formControlName]="itemNode"
                                                               [placeholder]="'FOLDER.TABLE.FILTER.TREE.'+itemNode.toString().toUpperCase() | translate">
                                                    </mat-form-field>
                                                </div>

                                            </ng-container>
                                            <!--origin list-->
                                            <ng-container [ngSwitch]="itemNode">
                                                <ng-container *ngSwitchCase="'origin'">
                                                    <div fxFlex="0 1 calc(25% - 12px)"
                                                         fxFlex.lt-md="0 1 calc(50% - 12px)"
                                                         fxFlex.lt-sm="100%">
                                                        <button mat-icon-button class="btn-close-form-field"
                                                                (click)="removeFieldSearch(itemNode.toString())">
                                                            <mat-icon class="close-icon">close</mat-icon>
                                                        </button>
                                                        <mat-form-field appearance="outline" id="origin">
                                                            <mat-label>{{'FOLDER.ORIGIN_TYPE.TITLE' | translate}}</mat-label>
                                                            <mat-select
                                                                (selectionChange)="changeOriginType($event.value)"
                                                                formControlName="origin">
                                                                <mat-select-trigger>
                                                                    <ng-container
                                                                        *ngIf="filterFoldersForms.controls['origin'].value && filterFoldersForms.controls['origin'].value.icon;else SHOW_ALL_ORIGIN">
                                                                        <mat-icon class="s-20"
                                                                                  [ngClass]="filterFoldersForms.controls['origin'].value?.icon.class"
                                                                                  svgIcon="{{filterFoldersForms.controls['origin'].value?.icon.code}}">
                                                                            {{filterFoldersForms.controls['origin'].value?.icon.code}}
                                                                        </mat-icon>
                                                                        {{('FOLDER.ORIGIN.ENUM_LABELS.' + filterFoldersForms.controls['origin'].value?.key) |translate}}
                                                                    </ng-container>
                                                                    <ng-template #SHOW_ALL_ORIGIN>
                                                                        {{'MOCK.ANY_LABEL' |translate}}
                                                                    </ng-template>
                                                                </mat-select-trigger>
                                                                <mat-option
                                                                    value="''">{{'MOCK.ANY_LABEL' |translate}}</mat-option>
                                                                <mat-option *ngFor="let originType of originTypesList"
                                                                            [value]="originType">
                                                                    <mat-icon class="s-20"
                                                                              [ngClass]="originType.icon.class"
                                                                              svgIcon="{{originType.icon.code}}">
                                                                    </mat-icon>
                                                                    {{('FOLDER.ORIGIN.ENUM_LABELS.' + originType.key) |translate}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>

                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container
                                                *ngSwitchCase="'customerZipCode'">
                                                <div fxFlex="0 1 calc(25% - 12px)"
                                                     fxFlex.lt-md="0 1 calc(50% - 12px)"
                                                     fxFlex.lt-sm="100%">
                                                    <button mat-icon-button class="btn-close-form-field"
                                                            (click)="removeFieldSearch(itemNode.toString())">
                                                        <mat-icon class="close-icon">close</mat-icon>
                                                    </button>
                                                    <mat-form-field appearance="outline" class="w-100-p">
                                                        <mat-label>{{'FOLDER.TABLE.FILTER.TREE.' + itemNode.toString().toUpperCase() | translate}}</mat-label>
                                                        <input matInput [formControlName]="itemNode"
                                                               [placeholder]="'FOLDER.TABLE.FILTER.TREE.'+itemNode.toString().toUpperCase() | translate"
                                                               numberOnly>
                                                    </mat-form-field>
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngSwitchCase="'customerDepartmentCode'">
                                                <div fxFlex="0 1 calc(25% - 12px)"
                                                     fxFlex.lt-md="0 1 calc(50% - 12px)"
                                                     fxFlex.lt-sm="100%">
                                                    <button mat-icon-button class="btn-close-form-field"
                                                            (click)="removeFieldSearch(itemNode.toString())">
                                                        <mat-icon class="close-icon">close</mat-icon>
                                                    </button>
                                                    <mat-form-field appearance="outline" class="w-100-p">
                                                        <mat-label>{{'FOLDER.TABLE.FILTER.TREE.' + itemNode.toString().toUpperCase() | translate}}</mat-label>
                                                        <input matInput [formControlName]="itemNode"
                                                               [placeholder]="'FOLDER.TABLE.FILTER.TREE.'+itemNode.toString().toUpperCase() | translate"
                                                               numberOnly>
                                                    </mat-form-field>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>

                    </div>

                    <div fxLayout="row" class="form-search mt-8" fxLayoutAlign="end end" >
                            <div class="status-section" id="choiceStatusFolder">
                                <div class="switch-toggle  mat-elevation-z2">
                                    <input id="progress" formControlName="closed" name="closed" type="radio" value="false">
                                    <label for="progress"
                                           [ngClass]="filterFoldersForms.value.closed ===false?'switch-toggle-checked':''">
                                        {{'FOLDER.TABLE.FILTER.FOLDER_IN_PROGRESS' | translate}}
                                    </label>
                                    <input id="all" formControlName="closed" name="closed" type="radio" value="null">
                                    <label for="all"
                                           [ngClass]="filterFoldersForms.value.closed ===null?'switch-toggle-checked':''">
                                        {{'FOLDER.TABLE.FILTER.ALL' | translate}}
                                    </label>
                                    <input id="closed" formControlName="closed" name="closed" type="radio" value="true">
                                    <label for="closed"
                                           [ngClass]="filterFoldersForms.value.closed ===true?'switch-toggle-checked':''">
                                        {{'FOLDER.TABLE.FILTER.FOLDER_CLOSED' | translate}}
                                    </label>
                                </div>
                            </div>
                        <div>
                            <button mat-raised-button type="submit" id="searchButton"
                                    [ngClass]="disableSearch()?'aster-modal-btn_validate-disabled':'aster-modal-btn_validate'"
                                    [disabled]="disableSearch()">{{'BUTTON.SEARCH' | translate}}</button>
                        </div>
                    </div>

                </form>
            </mat-card-content>
        </mat-card>


        <div fxLayout="column" class="mat-elevation-z2 w-100-p overflow mt-12 disabled-scroll" *ngIf="folderList">
            <table mat-table [dataSource]="folderList">

                <ng-container matColumnDef="INCREMENTAL_REFERENCE">
                    <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.INCREMENTAL_REFERENCE' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                        (contextmenu)="openNewTab(element, $event)" class="cursor-pointer">
                        {{element.incrementalNumber}}
                    </td>
                </ng-container>


                <ng-container matColumnDef="LAST_NAME">
                    <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.LAST_NAME' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                        (contextmenu)="openNewTab(element, $event)"
                        class="cursor-pointer">
                        {{element.customerLastName}}
                    </td>
                </ng-container>


                <ng-container matColumnDef="FIRST_NAME">
                    <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.FIRST_NAME' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                        (contextmenu)="openNewTab(element, $event)" class="cursor-pointer">
                        {{element.customerFirstName}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="CUSTOMER_MOBILE_PHONE">
                    <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.CUSTOMER_MOBILE_PHONE' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                        (contextmenu)="openNewTab(element, $event)" class="cursor-pointer height-reference_folder">
                        {{element.customerMobilePhone }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="CREATE_DATE">
                    <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.CREATE_DATE' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                        (contextmenu)="openNewTab(element, $event)" class="cursor-pointer height-reference_folder">
                        {{element.creationDate|date:"dd/MM/yyyy" }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="STATUS">
                    <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.STATUS' | translate}}</th>
                    <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                        (contextmenu)="openNewTab(element, $event)" class="cursor-pointer">
                                <span class="pl-2"
                                      *ngIf="element.currentWorkFlowStatus">
                                    {{!!((findUserTask(element?.currentWorkFlowStatus) | async).label) ? (findUserTask(element?.currentWorkFlowStatus) | async).label : 'COMPONENT.' + element?.currentWorkFlowStatus | translate}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="DATE_WORKFLOW_STATUS">
                    <th mat-header-cell
                        *matHeaderCellDef>{{'FOLDER.TABLE.DATE_WORKFLOW_STATUS' | translate}}</th>
                    <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                        (contextmenu)="openNewTab(element, $event)" class="cursor-pointer">
                                <span class="pl-2" *ngIf="!!element.workflowStatusDate">
                                    {{element?.workflowStatusDate |date:"dd/MM/yyyy" }}
                                </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="PRODUCT_LABELS">
                    <th mat-header-cell *matHeaderCellDef> {{'FOLDER.TABLE.PRODUCT_LABELS' | translate}}</th>
                    <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                        (contextmenu)="openNewTab(element, $event)" class="cursor-pointer ">
                        {{element?.productLabel}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="PRODUCT_CODE">
                    <th mat-header-cell *matHeaderCellDef>{{'FOLDER.TABLE.PRODUCT_CODE' | translate}}</th>
                    <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                        (contextmenu)="openNewTab(element, $event)" class="cursor-pointer ">
                        {{element.productCode}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="INDICATOR">
                    <th mat-header-cell *matHeaderCellDef>
                        <div fxLayout="row" class="form-search mt-8" fxLayoutAlign="start center"
                             fxLayoutGap="15px">
                            <div class="indicator-header-cell">
                                {{'FOLDER.TABLE.INDICATOR' | translate}}
                            </div>
                            <div class="indicator-header-cell">
                                <button mat-icon-button [matMenuTriggerFor]="showColumn" id="visibilityColumns">
                                    <mat-icon>visibility</mat-icon>
                                </button>
                                <mat-menu #showColumn="matMenu" style="background: white !important;">
                                    <div class="h-240" cdkDropList
                                         (cdkDropListDropped)="changePositionColumn($event)">
                                        <ng-container
                                            *ngFor="let column of dataSourceFolder.allColumnsName">
                                            <button fxLayout="row" fxLayoutAlign="start center" cdkDrag
                                                    mat-menu-item>
                                                <mat-checkbox (change)="showColumnsTable(column)"
                                                              [checked]="dataSourceFolder.columnsToDisplay.includes(column)">
                                                    {{'FOLDER.TABLE.' + column | translate}}
                                                </mat-checkbox>
                                            </button>
                                        </ng-container>
                                    </div>
                                </mat-menu>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="goToFolderDetails(element)"
                        (contextmenu)="openNewTab(element, $event)">

                                <span class="cursor-pointer" *ngIf="!!element.newWarranty"
                                      [mdePopoverTriggerFor]="warrantyPopover"
                                      mdePopoverTriggerOn="hover">
                                    <mat-icon class="w-35 h-35" [ngClass]="element.newWarranty?.icon?.class">
                                      {{element.newWarranty?.icon.code}}
                                    </mat-icon>
                                </span>
                        <span *ngIf="!!element.operationMode" class="cursor-pointer"
                              [mdePopoverTriggerFor]="operationModePopover" mdePopoverTriggerOn="hover">
                                    <mat-icon class="w-35 h-35" ngClass="{{element.operatinMode?.icon}}"
                                              svgIcon="{{element.operationMode?.icon}}">
                                    </mat-icon>
                                </span>
                        <span *ngIf="element.closed" [mdePopoverTriggerFor]="closedFolderPopover"
                              mdePopoverTriggerOn="hover" class="cursor-pointer">
                                    <mat-icon class="w-35 h-35">lock</mat-icon></span>

                        <span *ngIf="!!element.pendingReason" [mdePopoverTriggerFor]="pendingFolderPopover"
                              class="cursor-pointer"
                              mdePopoverTriggerOn="hover">
                                    <mat-icon class="w-35 h-35">
                                      pause_presentation
                                    </mat-icon>
                            </span>

                        <mde-popover #pendingFolderPopover="mdePopover" [mdePopoverOffsetX]="15"
                                     [mdePopoverOffsetY]="20"
                                     [mdePopoverOverlapTrigger]="true">

                            <mat-card class="popover w-100-p mat-elevation-z2 ">
                                <div class="popover_content" fxLayout="column"
                                     fxLayoutAlign="center center">
                                    <div fxLayout="row" fxLayoutAlign="center center">
                                        <mat-icon
                                            [ngClass]="'s-16'">pause_presentation
                                        </mat-icon>
                                        <span class="ml-4">
                                                {{'FOLDER.INDICATOR.PENDING' | translate }}</span></div>
                                    <div>
                                        {{ ('FOLDER.PENDING.REASON'| translate) + " : " + ('BUTTON.PENDING_FOLDER_REASON_' + element.pendingReason | translate)}}
                                    </div>
                                </div>
                            </mat-card>
                        </mde-popover>
                        <mde-popover #warrantyPopover="mdePopover" [mdePopoverOffsetY]="20"
                                     [mdePopoverOffsetX]="15"
                                     [mdePopoverOverlapTrigger]="true">

                            <mat-card class="popover w-100-p mat-elevation-z2 ">
                                <div fxLayout="column" fxLayoutAlign="center center"
                                     class="popover_content">
                                    <div fxLayout="row" fxLayoutAlign="center center">
                                        <mat-icon
                                            [ngClass]="'s-16 '+ element.newWarranty?.icon?.class">{{element.newWarranty?.icon?.code}}
                                        </mat-icon>
                                        <span class="ml-4">
                                                {{(element.newWarranty.label.i18nKey) | translate }}</span></div>
                                    <div>{{element.warrantyReason ? ('FOLDER.WARRANTY.REASON.LABEL'| translate) + " : " + getWarrantyReasonLabel(element.warrantyReason) : ''}}</div>
                                </div>
                            </mat-card>
                        </mde-popover>
                        <mde-popover #operationModePopover="mdePopover" [mdePopoverOffsetY]="20"
                                     [mdePopoverOffsetX]="15"
                                     [mdePopoverOverlapTrigger]="true">

                            <mat-card class="popover w-100-p mat-elevation-z2 ">

                                <div fxLayout="row" fxLayoutAlign="space-evenly center" class="popover_content">
                                    <mat-icon svgIcon="{{element.operationMode?.icon}}" [ngClass]="'s-16'">
                                    </mat-icon>
                                    <span class="ml-4">{{element.operationMode?.key| codeToLabel  :'SAV.CONFIG.MODE_OP' | async}}</span></div>
                            </mat-card>
                        </mde-popover>
                        <mde-popover #closedFolderPopover="mdePopover" [mdePopoverOffsetY]="20"
                                     [mdePopoverOffsetX]="15"
                                     [mdePopoverOverlapTrigger]="true">

                            <mat-card class="popover w-100-p mat-elevation-z2 ">
                                <div fxLayout="row" fxLayoutAlign="center center" class="popover_content">
                                    <mat-icon
                                        class="s-16">lock
                                    </mat-icon>
                                    <span class="ml-4">
                                                {{'COMPONENT.FOLDER_CLOSED' | translate }}</span></div>

                            </mat-card>
                        </mde-popover>
                        <!--origin icon-->
                        <span *ngIf="!!element.origin" class="cursor-pointer"
                              [mdePopoverTriggerFor]="originPopover" mdePopoverTriggerOn="hover">
                                    <mat-icon class="w-35 h-35" ngClass="{{element.origin?.icon.class}"
                                              svgIcon="{{element.origin?.icon.code}}">
                                    </mat-icon>
                                     <mde-popover #originPopover="mdePopover" [mdePopoverOffsetY]="20"
                                                  [mdePopoverOffsetX]="15"
                                                  [mdePopoverOverlapTrigger]="true">

                                <mat-card class="popover w-100-p mat-elevation-z2 ">

                                    <div fxLayout="row" fxLayoutAlign="space-evenly center" class="popover_content">
                                        <mat-icon svgIcon="{{element.origin?.icon.code}}"
                                                  [ngClass]="'s-16 '+ element.origin?.icon.class">
                                        </mat-icon>
                                        <span class="ml-4">
                                                {{(element.origin?.label.i18nKey) | translate }}</span></div>
                                </mat-card>
                               </mde-popover>
                             </span>
                    </td>

                </ng-container>

                <tr mat-header-row *matHeaderRowDef="dataSourceFolder.columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: dataSourceFolder.columnsToDisplay;"
                    id="rowTable"></tr>
            </table>
        </div>
        <div id="paginatorList">
            <mat-paginator *ngIf="pageConfig"
                           [length]="pageConfig.totalElements"
                           (page)="changePage($event)" showFirstLastButtons
                           [pageSize]="pageConfig.size"
                           [pageIndex]="getPageIndex()"
                           [pageSizeOptions]="pageSizeOptions" class="w-100-p"></mat-paginator>
        </div>
    </div>
</div>
