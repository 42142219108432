import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Product} from '../../../models/product.model';

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

    product: Product;
    purchaseDate: string;
    managementSiteCode: string;

    constructor(private dialogRef: MatDialogRef<ProductDetailComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.product = this.data.product;
        this.purchaseDate = this.data.purchaseDate;
        this.managementSiteCode = this.data.managementSiteCode;
    }

    onClose(): void {
        this.dialogRef.close();
    }
}
