<div class="text-center">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
        <h2>
            <mat-icon>class</mat-icon>
            {{'FOLDER.VISIO.REPORT' | translate}}
        </h2>
        <button mat-icon-button class="ml-40" (click)="onCloseModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>

        <form [formGroup]="sparePartLineForm.form" (ngSubmit)="addIntoSparePartList()" autocomplete="off">
            <div fxLayout="row" fxLayoutAlign="start start">
                <h2>{{'FOLDER.VISIO.LIST_SPARE_PARTS' | translate}}</h2>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                <div fxFlex="25" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.CODE' | translate }}</mat-label>
                        <input matInput placeholder="{{'MODAL.QUOTATION.CODE' | translate }}"
                               formControlName="reference" required [matAutocomplete]="autoCode" (keyup)="changeCode()">
                        <mat-autocomplete #autoCode="matAutocomplete" (optionSelected)='sparePartChanged($event)'>
                            <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option *ngFor="let sparePart of reportLineParts " [value]="sparePart">
                                    {{sparePart.code}} - {{sparePart.label}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div fxFlex="75" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.LABEL'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.LABEL'|translate" (keyup)="changeLabel()"
                               formControlName="label" type="text" required [matAutocomplete]="autoLabel">
                        <mat-autocomplete #autoLabel="matAutocomplete" (optionSelected)='sparePartChanged($event)'>
                            <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option *ngFor="let sparePart of reportLineParts " [value]="sparePart">{{sparePart.label}}</mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                <div fxFlex="25" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.QUANTITY'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.QUANTITY'|translate" formControlName="quantity"
                               min="1" numberOnly type="number" required (change)="calculationReportLine()">
                    </mat-form-field>
                </div>
                <div fxFlex="25" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.PRICE'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.PRICE'|translate"
                               formControlName="price" numberOnly type="number" (change)="calculationReportLine()"
                               min="0" required>

                    </mat-form-field>
                </div>
                <div fxFlex="25" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label> {{'MODAL.QUOTATION.TVA'|translate}}</mat-label>
                        <mat-select formControlName="tva" (selectionChange)="calculationReportLine()">
                            <mat-option *ngFor="let vat of vatsList$ | async" [value]="vat.rate">
                                {{vat.rate}} %
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="25" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'MODAL.QUOTATION.TOTAL_PRICE'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.TOTAL_PRICE'|translate" numberOnly type="number"
                               required
                               formControlName="totalLinePrice" disabled readonly="true"
                               (change)="calculationReportLine()">

                    </mat-form-field>
                </div>
            </div>
            <div fxLayoutAlign="end center">
                <div>

                    <button mat-stroked-button [disabled]="sparePartLineForm.form.invalid"
                            [ngClass]="sparePartLineForm.form.invalid ? 'aster-btn_border-disabled' : 'aster-btn_border'">
                        <mat-icon>add</mat-icon>
                        {{'BUTTON.ADD' | translate}}
                    </button>
                </div>
            </div>
        </form>

        <table mat-table [dataSource]="sparePartDataTable" class="mat-elevation-z4 w-100-p mt-16 mb-16">
            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'FOLDER.REPAIR_IN_PROGRESS.CODE' | translate}}</th>
                <td mat-cell *matCellDef="let sparePart" class="text-left"> {{sparePart.reference}} </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'FOLDER.REPAIR_IN_PROGRESS.TYPE' | translate}}</th>
                <td mat-cell
                    *matCellDef="let sparePart" class="text-left">{{'MODAL.QUOTATION.TYPE.' + sparePart.type | translate}} </td>
            </ng-container>
            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'FOLDER.REPAIR_IN_PROGRESS.LABEL' | translate}}</th>
                <td mat-cell *matCellDef="let sparePart" class="text-left">{{sparePart.label}}</td>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'MODAL.QUOTATION.QUANTITY' | translate}}</th>
                <td mat-cell
                    *matCellDef="let sparePart" class="text-left">{{sparePart.quantity}}</td>
            </ng-container>

            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'MODAL.QUOTATION.PRICE' | translate}}</th>
                <td mat-cell
                    *matCellDef="let sparePart" class="text-left">{{sparePart.price | currency: currency :'symbol-narrow' | space}}</td>
            </ng-container>

            <ng-container matColumnDef="tva">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'MODAL.QUOTATION.TVA' | translate}} </th>
                <td mat-cell
                    *matCellDef="let sparePart" class="text-left">{{sparePart.tva}}</td>
            </ng-container>

            <ng-container matColumnDef="totalLinePrice">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'MODAL.QUOTATION.TOTAL_PRICE' | translate}}</th>
                <td mat-cell
                    *matCellDef="let sparePart" class="text-left">{{sparePart.totalLinePrice  |currency: currency :'symbol-narrow' | space}}  </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">{{'BUTTON.ACTION' | translate}}</th>
                <td mat-cell *matCellDef="let indexLine = index;" class="text-left">
                    <button matSuffix mat-icon-button color="warn"
                            type="button" class="mx-8"
                            (click)="deleteLineSparePart(indexLine)">
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="sparePartLineColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: sparePartLineColumns;"></tr>
        </table>

        <mat-divider class="mb-16"></mat-divider>


    </mat-dialog-content>

    <mat-dialog-actions class="mt-16">
        <div class="w-100-p">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="end end">
                <button type="submit" mat-stroked-button (click)="validateForm()"
                        [disabled]="sparePartDataTable.data.length<=0 "
                        [ngClass]="sparePartDataTable.data.length<=0 ? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </div>
    </mat-dialog-actions>
</div>
