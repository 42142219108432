<div class="text-center">

    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
        <div class="text-bold">
            {{'FOLDER.FILE.UPLOAD' | translate}}
        </div>

        <button mat-icon-button class="ml-40" (click)="onClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <div class="dropzone-container">
            <ngx-dropzone
                #dropzone label="{{'FOLDER.FILE.UPLOAD' | translate}}" [showPreviews]="true" [multiple]="false"
                [accept]="acceptType()"
                (filesAdded)="onFilesAdded($event)" (filesRejected)="onFilesRejected($event)">
            </ngx-dropzone>
            <button mat-mini-fab color="warn" *ngIf="fileUploadForm.hasFile" class="dropzone-cleaner"
                    (click)="onClearDropzone()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <ng-container *ngIf="'PRODUCT' !== modalData?.type && !hideFileType">
            <form [formGroup]="elementForm.attachmentForm">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span class="mr-12">{{'FORM.TYPE.ATTACHMENT.TITLE' | translate}}</span>
                    <mat-form-field class="py-4 mt-20" appearance="outline">
                        <mat-label class="pt-2-p"></mat-label>
                        <mat-select formControlName="attachmentType" required>
                            <mat-option *ngFor="let attachmentType of attachmentTypes"
                                        [value]="attachmentType.code">
                                {{attachmentType.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </ng-container>
        <div *ngIf="fileUploadForm.isUploading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <div *ngIf="sharedWithList.length > 0" fxLayout="row" fxLayoutAlign="start center" class="mt-20">
            <span class="mr-12">{{ 'CONFIGURATION.GRID.HEADER.SHARED_WITH' | translate }}</span>
            <ejs-chiplist>
                <e-chips>
                    <e-chip *ngFor="let chip of sharedWithList" [text]="chip" ></e-chip>
                </e-chips>
            </ejs-chiplist>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mt-16">

        <span class="mt-4">[{{'FOLDER.FILE.MAX_SIZE' | translate}}]</span>
        <div class="w-100-p">
            <div class="mb-8" *ngIf="fileUploadForm.hasSuccessfullyUploaded; else actionsContainer;">
                <div class="message-box success mb-16">
                    {{'FOLDER.FILE.SAVED' |translate  }}
                </div>
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="center center">
                    <button mat-button color="accent"
                            (click)="onCloseWithURL()">{{'BUTTON.SUBMIT' | translate}}</button>
                </div>
            </div>
            <div class="message-box warning mb-16" *ngIf="warningSizeFile">
                {{'FOLDER.FILE.WARNING_MAX_SIZE' |translate  }}
            </div>
            <ng-template #actionsContainer>
                <div class="mb-20" fxLayout="row" fxLayoutAlign="center center">
                    <button (click)="onClose()" class="aster_btn_cancel" mat-stroked-button
                            type="reset">
                        {{'BUTTON.CANCEL' | translate}}
                    </button>
                    <button [disabled]="!isEnabled()"
                            [ngClass]="!isEnabled() || fileUploadForm.isUploading ?'aster_btn_disabled':'aster_btn'"
                            mat-stroked-button
                            (click)="onUpload()">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>

                </div>
            </ng-template>
        </div>

    </mat-dialog-actions>
</div>
