
    <app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
    <form [formGroup]="waitQuotationForm.form" fxLayout="column" *ngIf="!!waitQuotationForm.form"
          fxLayoutAlign="start">
        <div fxLayout="row" fxLayoutAlign="space-between stretch" >
            <mat-form-field appearance="outline" fxFlex="50">
                <mat-label>{{'FOLDER.QUOTATION.CODE'|translate}}</mat-label>
                <input matInput inputControl placeholder="{{'FOLDER.QUOTATION.CODE'|translate}}"
                       formControlName="quotationCode" required>
            </mat-form-field>
            <button mat-stroked-button class="aster-btn_border" (click)="openQuotationModal()">
                <mat-icon class="mr-8">add</mat-icon>
                {{'BUTTON.ADD' | translate}}
            </button>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px">
            <div fxFlex="70" fxFlex.lt-md="100">
                <table mat-table [dataSource]="quotationSummary" class="mat-elevation-z2 w-100-p mt-16 mb-16">
                    <ng-container matColumnDef="type">
                        <th class="table-header" mat-header-cell *matHeaderCellDef> {{'MODAL.QUOTATION.TYPE' | translate}}</th>
                        <td mat-cell *matCellDef="let quotationLine"> {{'MODAL.QUOTATION.TYPE.' + quotationLine.type.code | translate}}</td>
                    </ng-container>
                    <ng-container matColumnDef="number">
                        <th mat-header-cell *matHeaderCellDef
                            class="table-header"> {{'MODAL.QUOTATION.NUMBER' | translate}}</th>
                        <td mat-cell *matCellDef="let quotationLine">{{quotationLine.number}} </td>
                    </ng-container>
                    <ng-container matColumnDef="totalPriceHT">
                        <th mat-header-cell *matHeaderCellDef
                            class="table-header"> {{'QUOTATION.CONTENT.TOTAL_HT' | translate}}</th>
                        <td mat-cell *matCellDef="let quotationLine"
                            class="text-left"> {{quotationLine.totalHT |currency:currency :'symbol-narrow' | space}} </td>
                    </ng-container>
                    <ng-container matColumnDef="totalPrice">
                        <th mat-header-cell *matHeaderCellDef
                            class="table-header"> {{'MODAL.QUOTATION.TOTAL_TTC' | translate}}</th>
                        <td mat-cell *matCellDef="let quotationLine">{{quotationLine.totalTTC |currency:currency :'symbol-narrow' | space}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div fxFlex="30" fxFlex.lt-md="100">
                <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex.lt-md="100">
                    <div fxLayout="column" class="zone-total">
                        <div fxLayoutAlign="space-between center" fxLayoutGap="8px">
                            <strong>{{'QUOTATION.CONTENT.TOTAL_HT' | translate}} : </strong>
                            <div>{{ quotation?.totalPriceHTAfterDiscount?.value  |currency:currency :'symbol-narrow' | space}}
                            </div>
                        </div>
                        <div fxLayoutAlign="space-between center" fxLayoutGap="8px">
                            <strong> {{'QUOTATION.CONTENT.TOTAL_TTC' | translate}} : </strong>
                            <div>{{ quotation?.totalPrice?.value |currency:currency :'symbol-narrow' | space}}
                            </div>
                        </div>
                    </div>
                </mat-card>
                <ng-container *ngIf="quotation?.totalPrice?.value < 0">
                    <p class="error-text-color">{{'FOLDER.REPAIR_IN_PROGRESS.NEGATIVE_QUOTATION' | translate}}</p>
                </ng-container>
            </div>
        </div>

        <app-attachment-uploader  class="mb-24"
                                  [folder]="folder"
                                  [quotation]="quotation"
                                  (attachmentsFolder)="getAttachments($event)"></app-attachment-uploader>
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px">
            <mat-form-field appearance="outline" fxFlex="50">
                <mat-label>{{'QUOTATION.FEES_REFUSED_QUOTATION_WITHOUT_RESTITUTION'|translate}}</mat-label>
                <input matInput inputControl placeholder="{{'QUOTATION.FEES_REFUSED_QUOTATION_WITHOUT_RESTITUTION'|translate}}"
                       min="0" appPositiveNumberValidator
                       formControlName="feesRefusedQuotationWithoutRestitution">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="50">
                <mat-label>{{'QUOTATION.FEES_REFUSED_QUOTATION_WITH_RESTITUTION'|translate}}</mat-label>
                <input matInput inputControl placeholder="{{'QUOTATION.FEES_REFUSED_QUOTATION_WITH_RESTITUTION'|translate}}"
                       min="0" appPositiveNumberValidator
                       formControlName="feesRefusedQuotationWithRestitution">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="end end">
            <button type="submit" mat-stroked-button
                    [disabled]="stopPropagation()"
                    (click)="completeTask()"
                    [ngClass]="stopPropagation() ? 'aster_btn_disabled' : 'aster_btn'">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>
    </form>