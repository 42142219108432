import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {Address} from '../../../models/customer/customer.model';
import {CountriesUtils} from '../../data/countries-utils';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, filter, finalize, startWith, switchMap, take, tap} from 'rxjs/operators';

import {SharedService} from '../../services/shared.service';
import {FolderService} from '../../services/folder.service';
import {Accessibility} from '../../../models/accessibility.model';
import {postcodeValidator} from 'postcode-validator';
import {BackOfficeService} from '../../services/back-office.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {countryCode} from '../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../unsubscriber';
import {CustomerApiInfo} from '../../../models/customer/customer-api-info.model';

@Component({
    selector: 'app-generic-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent extends Unsubscriber implements OnInit, OnChanges {

    @Input() addressesInput: Address;
    @Input() showLoanLat: boolean;
    @Input() withAccessibility: boolean;
    @Input() validateOnLoadAddress: boolean;
    @Input() isRequiredFields = true;
    @Input() customerApiInfos = new CustomerApiInfo();

    addresses: any;
    isLoading = false;
    isApartment = false;
    isElevator = true;
    isZipCodeValid = true;
    errorMsg: string;
    countriesList = CountriesUtils.COUNTRIES_LIST;
    filteredCountriesList = new ReplaySubject<any[]>();
    countryCode: string;
    customerForm: FormGroup;

    constructor(private translateService: TranslateService,
                private _sharedService: SharedService,
                private folderService: FolderService,
                private backOfficeService: BackOfficeService,
                private rootFormGroup: FormGroupDirective,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.customerForm = this.rootFormGroup.control as FormGroup;
        this.initAddressDetailForm();
        this.updateFormForRequiredFields();
        this.anotherSubscription = this._sharedService.getUpdateResetAddressEvent()
            .subscribe(res => {
                this.addressesInput = res;
                this.init();
            });
        this.anotherSubscription = this.store$.pipe(select(countryCode))
            .subscribe(code => {
                this.countryCode = code;
                this.init();
            });
    }

    private init(): void {
        this.initAddressDetailWithData();
        this.initAutoComplete();
        this.notifyAddressFormChanged();
    }

    private updateFormForRequiredFields(): void {
        this.customerApiInfos?.requiredFields?.forEach(requiredField => {
            this.addressForm.get(requiredField)?.setValidators(Validators.required);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.addressesInput && !changes.addressesInput.isFirstChange() &&
            (!changes.addressesInput.previousValue ||
                changes.addressesInput.currentValue?.countryCode !== changes.addressesInput.previousValue.countryCode ||
                changes.addressesInput.currentValue?.city !== changes.addressesInput.previousValue.city ||
                changes.addressesInput.currentValue?.zipCode !== changes.addressesInput.previousValue.zipCode ||
                changes.addressesInput.currentValue?.address !== changes.addressesInput.previousValue.address ||
                changes.addressesInput.currentValue?.secondAddress !== changes.addressesInput.previousValue.secondAddress)) {
            this.initAddressDetailWithData();
            this.notifyAddressFormChanged();
        }
    }

    getValidator = () => this.isRequiredFields ? Validators.required : null;

    initAddressDetailForm(): void {
        this.customerForm.addControl('address', new FormGroup({
            countryCode: new FormControl(null, this.getValidator()),
            countryName: new FormControl(null, this.getValidator()),
            countryCodeCtrl: new FormControl(null),
            city: new FormControl(null, this.getValidator()),
            zipCode: new FormControl(null, this.getValidator()),
            address: new FormControl(null, this.getValidator()),
            secondAddress: new FormControl(null),
            fullAddress: new FormControl(null),
            location: new FormGroup({
                lon: new FormControl(null), lat: new FormControl(null),
            }),
            accessibility: new FormGroup({
                housing: new FormControl(null), floorCount: new FormControl(null, Validators.min(0)), stage: new FormControl(null), parking: new FormControl(null),
            })
        }));
        this.anotherSubscription = this.addressForm.get(['countryCodeCtrl']).valueChanges
            .pipe(startWith(null))
            .subscribe(input => {
                const matchedResults = !!input ?
                    this.countriesList.filter(item =>
                        item.code.toLowerCase().includes(input.toLowerCase()) || item.name.toLowerCase().includes(input.toLowerCase())
                    ) : this.countriesList;

                this.filteredCountriesList.next(matchedResults);
            });
        this.anotherSubscription = this.addressForm.get('countryCode').valueChanges
            .subscribe(countryCode => {
                if (!countryCode) {
                    this.addressForm.get(['countryCode']).setValue(this.addressesInput?.countryCode || this.countryCode);
                }
            });
    }

    get addressForm(): FormGroup {
        return this.customerForm.get('address') as FormGroup;
    }

    initAddressDetailWithData(): void {
        if (!this.addressesInput) {
            this.addressesInput = {
                countryName: '',
                countryCode: '',
                city: '',
                zipCode: '',
                address: '',
                secondAddress: '',
                outSideInformation: '',
                location: {
                    lon: '',
                    lat: ''
                },
                accessibility: {
                    house: false,
                    apartment: false,
                    floorCount: null,
                    stairs: false,
                    elevator: false,
                    easyParking: false,
                    mediumParking: false,
                    difficultParking: false
                }
            };
        }
        this.addressForm.get(['countryCode']).setValue(this.addressesInput.countryCode || this.countryCode);
        if (this.addressesInput.countryCode) {
            this.addressForm.get(['countryName']).setValue(CountriesUtils.getCountryName(this.addressesInput.countryCode));
        }
        this.addressForm.get(['city']).setValue(this.addressesInput.city);
        this.addressForm.get(['zipCode']).setValue(this.addressesInput.zipCode);
        this.addressForm.get(['address']).setValue(this.addressesInput.address);
        this.addressForm.get(['fullAddress']).setValue(this.addressesInput.fullAddress);
        this.addressForm.get(['secondAddress']).setValue(!!this.addressesInput.secondAddress ? this.addressesInput.secondAddress : '');
        if (this.addressesInput.location) {
            this.addressForm.get(['location', 'lon']).setValue(this.addressesInput.location.lon);
            this.addressForm.get(['location', 'lat']).setValue(this.addressesInput.location.lat);
        }
        if (this.withAccessibility && !!this.addressesInput.accessibility) {
            this.isApartment = this.addressesInput.accessibility.apartment;
            this.isElevator = this.addressesInput.accessibility.elevator;
            this.addressForm.get(['accessibility', 'housing']).setValue(this.isApartment);
            this.addressForm.get(['accessibility', 'floorCount']).setValue(this.isApartment && !!this.addressesInput.accessibility.floorCount ?
                this.addressesInput.accessibility.floorCount : null);
            this.addressForm.get(['accessibility', 'stage']).setValue(this.isElevator);
            this.addressForm.get(['accessibility', 'parking']).setValue(this.getParkingValue());
        }


        if (this.validateOnLoadAddress && !!this.addressForm.value && !!this.addressForm.value.address) {
            if (this.isZipCodeValid) {
                this.folderService.findInvalidControls(this.addressForm);
            }
        }
    }

    initAutoComplete(): void {
        this.anotherSubscription = this.addressForm.get(['address']).valueChanges
            .pipe(
                debounceTime(500),
                tap(() => {
                    this.errorMsg = '';
                    this.addresses = [];
                    this.isLoading = true;
                }),
                filter(value => !!value),
                distinctUntilChanged(),
                switchMap(value => this.backOfficeService.getAddressesByStringSearch(value)
                    .pipe(
                        finalize(() => this.isLoading = false),
                    )
                )
            ).subscribe(data => {
            if (data.length > 0) {
                this.isLoading = false;
                this.errorMsg = '';
                this.addresses = data;
            } else {
                this.errorMsg = '';
                this.addresses = [];
            }
        });
    }

    selectAddress(address): void {
        this.addressForm.get(['countryCode']).setValue(CountriesUtils.COUNTRIES_LIST_FR.filter(country => this.filterCountry(country, address))[0].code);
        this.addressForm.get(['countryName']).setValue(CountriesUtils.COUNTRIES_LIST_FR.filter(country => this.filterCountry(country, address))[0].name);
        this.addressForm.get(['city']).setValue(address.city);
        this.addressForm.get(['zipCode']).setValue(address.zipCode);
        this.addressForm.get(['address']).setValue(address.full_street);
        this.addressForm.get(['secondAddress']).setValue(address.secondAddress);
        this.addressForm.get(['fullAddress']).setValue(address.full_address);
        this.addressForm.get(['location', 'lon']).setValue(address.lon);
        this.addressForm.get(['location', 'lat']).setValue(address.lat);
        this.notifyAddressFormChanged();
    }

    private filterCountry(country, address) {
        return country.name === address.country || country.code === address.countryCode;
    }

    onClearAddressDetailFormControlValueByPath(path: any): void {
        this.addressForm.get(path).setValue(null);
        this.notifyAddressFormChanged();
    }

    notifyAddressFormChanged(): void {
        const countryCode = this.addressForm.get(['countryCode']).value;
        if (this.addressesInput && countryCode !== this.addressesInput.countryCode) {
            this.addressForm.get(['countryName']).setValue(this.translateService.instant('COUNTRY.NAME.' + countryCode));
        }
        if (!this.addressForm.invalid && this.isZipCodeValid) {
            const address = this.addressForm.value;
            delete address.countryCodeCtrl;
            if (this.withAccessibility) {
                address.accessibility = {
                    house: !this.isApartment,
                    apartment: this.isApartment,
                    floorCount: this.isApartment ? this.addressForm.get(['accessibility', 'floorCount']).value : null,
                    stairs: this.isApartment && !this.isElevator,
                    elevator: this.isApartment && this.isElevator,
                    easyParking: !!this.addressForm.get(['accessibility', 'parking']).value ?
                        this.addressForm.get(['accessibility', 'parking']).value.startsWith('easyParking') : false,
                    mediumParking: !!this.addressForm.get(['accessibility', 'parking']).value ?
                        this.addressForm.get(['accessibility', 'parking']).value.startsWith('mediumParking') : false,
                    difficultParking: !!this.addressForm.get(['accessibility', 'parking']).value ?
                        this.addressForm.get(['accessibility', 'parking']).value.startsWith('difficultParking') : false,
                } as Accessibility;
            }
            this._sharedService.updateAddress(address);
            this._sharedService.validateForm(true);
        } else {
            this._sharedService.updateAddress(this.addressForm.value);
            this._sharedService.validateForm(false);
        }
    }

    private getParkingValue(): string {
        if (this.addressesInput.accessibility.easyParking) {
            return 'easyParking';
        }
        if (this.addressesInput.accessibility.mediumParking) {
            return 'mediumParking';
        }
        if (this.addressesInput.accessibility.difficultParking) {
            return 'difficultParking';
        }
    }

    selectHousing(): void {
        this.isApartment = !this.addressForm.get(['accessibility', 'housing']).value;
        this.notifyAddressFormChanged();
    }

    selectStage(): void {
        this.isElevator = !this.addressForm.get(['accessibility', 'stage']).value;
        this.notifyAddressFormChanged();
    }

    validateZipCode() {
        const countryCode = this.addressForm.get(['countryCode']).value;
        const zipCodeForm = this.addressForm.get(['zipCode']);
        if (!!countryCode && postcodeValidator(zipCodeForm.value, countryCode)) {
            this.anotherSubscription = this.backOfficeService.getAddressesByStringSearch(zipCodeForm.value + ' ' + countryCode)
                .subscribe(addresses => {
                    if (addresses.length === 0) {
                        zipCodeForm.setValue('');
                        this.isZipCodeValid = false;
                    } else {
                        this.addressForm.get('city').setValue(addresses[0].city);
                        this.isZipCodeValid = true;
                    }
                });
        } else {
            zipCodeForm.setValue('');
            this.isZipCodeValid = false;
        }
    }

    getIcon = (fieldName: string, icon: string) => this.customerApiInfos?.searchableFields?.includes(fieldName) ? 'search' : icon;

    onAddressInputChange() {
        const address = this.addressForm.value;
        this.addressForm.get('fullAddress').setValue(`${address.address}, ${address.zipCode} ${address.city}, ${address.countryName}`);
        this.backOfficeService.computeGeolocation(address)
            .pipe(
                take(1),
                tap(location => this.addressForm.get('location').patchValue(location ?? {lat: null, lon: null})),
                finalize(() => this.notifyAddressFormChanged())
            ).subscribe();
    }
}
