<div>
    <h2 class="mt-20">
        <mat-icon>question_answer</mat-icon>
        {{'FOLDER.COMMUNICATIONS.TITLE'| translate}}
    </h2>

    <div fxLayout="row" fxLayoutAlign="space-between stretch" class="example-container">
        <form>
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>{{'FOLDER.COMMUNICATIONS.TITLE' | translate}}</mat-label>
                    <mat-select (selectionChange)="selectFilterType($event)" [(ngModel)]="notificationSelected"
                                ngDefaultControl
                                [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let notificationType of notificationsType" [value]="notificationType">
                            <ng-container *ngIf="!!notificationType && notificationType.toString().startsWith('ALL');else SHOW_OTHER_TYPES">
                                <mat-option value="null">
                                    {{'MOCK.ANY_LABEL' |translate}}
                                </mat-option>
                            </ng-container>
                            <ng-template #SHOW_OTHER_TYPES>
                                {{'FOLDER.NOTIFICATION.TYPE.' + notificationType |translate}}
                            </ng-template>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
        </form>
        <div *ngIf="!folderClosed">
            <div (click)="openModal()" [ngClass]="'cursor-pointer '" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end center" >
                <mat-icon>add_circle_outline</mat-icon>
                <span [ngClass]="'link-underline'">{{'FOLDER.NOTIFICATION.CALL_TYPE.TITLE' | translate}}</span>
            </div>
        </div>
    </div>

</div>
<ng-container
    *ngIf="!!notificationConfig &&!!notificationConfig.content && notificationConfig.content.length>0 else noNotification">
    <div class="mat-elevation-z4">
        <mat-card>
            <mat-card-content>
                <table mat-table [dataSource]="notificationConfig.content" class="w-100-p mt-8" multiTemplateDataRows>
                    <ng-container matColumnDef="type">
                        <th mat-header-cell
                            *matHeaderCellDef class="table-header"> {{'FOLDER.NOTIFICATION.TYPE' | translate}}</th>
                        <td mat-cell
                            *matCellDef="let element">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <mat-icon>
                                    {{getNotificationIcon(element.type)}}
                                </mat-icon>
                                <div>{{!!element?.type ? ('FOLDER.NOTIFICATION.TYPE.' + element.type |translate) : '-'}}</div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th mat-header-cell
                            *matHeaderCellDef class="table-header"> {{'FOLDER.NOTIFICATION.DATE' | translate}}</th>
                        <td mat-cell
                            *matCellDef="let element">{{ !!element.changeStatusDate ? (element.changeStatusDate|date: 'dd-MM-yyyy') : ''}}</td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell
                            *matHeaderCellDef class="table-header"> {{'FOLDER.NOTIFICATION.STATUS.TITLE' | translate}}</th>
                        <td mat-cell *matCellDef="let element">
                            {{ !!element.deliverabilityStatus ? ('FOLDER.NOTIFICATION.STATUS.' + element.deliverabilityStatus.toUpperCase() |translate) : ''}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="scheduleStatus">
                        <th mat-header-cell
                            *matHeaderCellDef class="table-header"> {{'FOLDER.NOTIFICATION.SCHEDULE.STATUS.TITLE' | translate}}</th>
                        <td mat-cell *matCellDef="let element">
                            {{ !!element.scheduleStatus ? ('FOLDER.NOTIFICATION.SCHEDULE.STATUS.' + element.scheduleStatus |translate) : ''}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="sentBy">
                        <th mat-header-cell
                            *matHeaderCellDef class="table-header"> {{'FOLDER.NOTIFICATION.SENT.BY.TITLE' | translate}}</th>
                        <td mat-cell *matCellDef="let element">
                            {{ !element.external ? ('FOLDER.NOTIFICATION.SENT.BY.ASTER'|translate)
                            :  ('FOLDER.NOTIFICATION.SENT.BY.EXTERNAL.SYSTEM'|translate)}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action" stickyEnd>
                        <th mat-header-cell
                            *matHeaderCellDef class="table-header"> {{'FOLDER.EVENT.ACTION' | translate}}</th>
                        <td mat-cell class="display-cell"
                            *matCellDef="let element; let i = index;">
                            <mat-icon color="warn" class="cursor-pointer" (click)="onDelete(element,i)">
                                delete_forever
                            </mat-icon>

                        </td>
                    </ng-container>
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let el"
                            [attr.colspan]="folderNotificationDataSource.displayedColumns.length">
                            <div class="example-element-detail"
                                 [@detailExpand]="!!folderNotificationDataSource.expandedCallElement && folderNotificationDataSource.expandedCallElement.id === el.id ? 'expanded' : 'collapsed'">
                                <app-item-notification-call-info
                                    *ngIf="!!folderNotificationDataSource.expandedCallElement && folderNotificationDataSource.expandedCallElement.id === el.id"
                                    [notificationIndex]="el"></app-item-notification-call-info>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="folderNotificationDataSource.displayedColumns"></tr>
                    <tr mat-row
                        *matRowDef="let el; columns: folderNotificationDataSource.displayedColumns"
                        class="example-element-row"
                        [class.example-expanded-row]="!!folderNotificationDataSource.expandedCallElement && folderNotificationDataSource.expandedCallElement.id === el.id"
                        (click)="folderNotificationDataSource.expandedCallElement = !!folderNotificationDataSource.expandedCallElement && folderNotificationDataSource.expandedCallElement.id === el.id ? null : el"></tr>

                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                </table>
                <mat-paginator *ngIf="notificationConfig"
                               [pageSizeOptions]="folderNotificationDataSource.pageSizeOptions" class="w-100-p"
                               [length]="notificationConfig.totalElements"
                               (page)="changePage($event)" showFirstLastButtons
                               [pageSize]="notificationConfig.size"
                               [pageIndex]="pageIndex"></mat-paginator>


            </mat-card-content>
        </mat-card>
    </div>
</ng-container>

<ng-template #noNotification>
    <strong class="mat-elevation-z0" fxLayout="row" fxLayoutAlign="center center" *ngIf="!!expandedElement">
        {{'FOLDER.NOTIFICATION.EMPTY'|translate: {type: ('FOLDER.NOTIFICATION.TYPE.' + expandedElement|translate)}
        }}</strong>
</ng-template>