import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {merge} from 'rxjs';

import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {isOrganizationCodeEmpty} from '../../../app/store/user/user.selectors';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../app/store/app.state';
import {skipWhile} from 'rxjs/operators';
import {Unsubscriber} from '../../../app/unsubscriber';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent extends Unsubscriber implements OnInit {

    @Input() layout = 'vertical';
    @Input() navigation: any;
    @Input() inRightPosition: boolean;


    constructor(private _changeDetectorRef: ChangeDetectorRef,
                private fuseNavigationService: FuseNavigationService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.store$.pipe(
            select(isOrganizationCodeEmpty),
            skipWhile(organizationCodeIsEmpty => organizationCodeIsEmpty)
        ).subscribe(() => {
            // Load the navigation either from the input or from the service
            this.navigation = this.navigation !== undefined && this.navigation.filter(nav => nav.inRightPosition === this.inRightPosition)
                || this.fuseNavigationService.getCurrentNavigation().filter(nav => nav.inRightPosition === this.inRightPosition);

            // Subscribe to the current navigation changes
            this.anotherSubscription = this.fuseNavigationService.onNavigationChanged
                .subscribe(() => {
                    // Load the navigation
                    this.navigation = this.fuseNavigationService.getCurrentNavigation().filter(nav => nav.inRightPosition === this.inRightPosition);

                    // Mark for check
                    this._changeDetectorRef.markForCheck();
                });

            // Subscribe to navigation item
            this.anotherSubscription = merge(
                this.fuseNavigationService.onNavigationItemAdded,
                this.fuseNavigationService.onNavigationItemUpdated,
                this.fuseNavigationService.onNavigationItemRemoved
            ).subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        });
    }
}
