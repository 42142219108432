import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Amount} from '../../../../../models/amount.model';
import {getCurrencySymbol} from '@angular/common';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-accept-quotation',
    templateUrl: './accept-quotation.component.html',
    styleUrls: ['./accept-quotation.component.scss']
})
export class AcceptQuotationComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();
    @Input() instructionUserTask: InstructionUserTask;

    @Input() variablesTask: any;
    @Input() folder: Folder;

    isQuotationAccepted: false;
    commentControl = new FormControl('');
    question$: Observable<string>;

    quotationAmount: Amount;

    constructor(                private _translateService: TranslateService,
    ) {
    }

    ngOnInit(): void {
        this.quotationAmount = this.getQuotationInfoFromNewVariable();
        this. buildMessage();
    }

    getQuotationInfoFromNewVariable(): Amount {
        return JSON.parse(this.variablesTask['quotationAmount']);
    }

    private buildMessage(): void {
        this.question$ = this._translateService.stream('COMPONENT.ACCEPT_QUOTATION.DESCRIPTION',
            {
                price: this.quotationAmount.value,
                currency: getCurrencySymbol(this.quotationAmount.currency || this.quotationAmount.currency, 'narrow')
            });
    }

    onSubmit(): void {
        if (this.commentControl.value.length > 0) {
            this.commentGiven.emit({value: this.commentControl.value});
        }

        this.inputMap.emit({});
    }
}
