import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {WorkflowTask} from '../../../../../models/workflowTask.model';
import {Folder} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogDataCancelInterventionComponent} from '../confirm-appointment/dialog-data-cancel-intervention/dialog-data-cancel-intervention.component';
import {MatDialog} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from '../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-progress-repair.component',
    templateUrl: './progress-repair.component.html',
    styleUrls: ['./progress-repair.component.scss']
})
export class ProgressRepairComponent implements OnInit {
    @Input() variablesTask: any;
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();

    form: FormGroup;
    folderId: string;
    model = new WorkflowTask('');
    private hasAgenda = true; // initialiser à true pour ne pas avoir de pblm avec les anciens dossiers
    showCalender = false;
    minDate = new Date();
    confirmedAppointment: {};

    constructor(private route: ActivatedRoute,
                public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.folderId = this.route.snapshot.parent.params.folderId;
        this.hasAgenda = this.variablesTask['hasAgenda'];
        if (!this.hasAgenda) {
            this.showCalender = true;
        }
        this.initForm();
    }


    private initForm(): void {
        this.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            comment: new FormControl(''),
            confirmedAppointment: new FormControl(null),
        });
    }

    completeTask(): any {
        if (this.form.value.status === 'REPAIR_DECISION_CANCEL') {
            this.delegateToCancelInterventionModal();
        } else if (this.form.value.status === 'APPOINTMENT_RESCHEDULE' && this.folder.repairCenter.publicAgenda) {
            this.delegateToConfirmationModal();
        } else {
            if (this.hasCommentValue()) {
                this.commentGiven.emit({
                        value: this.form.value.comment,
                        type: this.toCommentType(this.form.value.status),
                    }
                );
            }
            if (!!this.form.value.confirmedAppointment) {
                const date = moment(this.form.value.confirmedAppointment).format('YYYY-MM-DD');
                this.confirmedAppointment = {
                    day: date,
                    beginHour: '00:00:00',
                    endHour : '23:59:59'
                };
            }

            this.inputMap.emit({
                'decision': this.form.value.status,
                'confirmedDate': JSON.stringify(this.confirmedAppointment)
            });
        }
    }

    private delegateToConfirmationModal(): void {
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });

        dialogRef.componentInstance.title = 'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.RESCHEDULE.DIALOG.TITLE';
        dialogRef.componentInstance.message = 'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.RESCHEDULE.DIALOG.MESSAGE';

        dialogRef.afterClosed().subscribe(confirmed => {
            if (!!confirmed) {
                if (this.hasCommentValue()) {
                    this.commentGiven.emit({
                            value: this.form.value.comment,
                        }
                    );
                }

                this.inputMap.emit({
                    'decision': this.form.value.status
                });
            }
        });
    }

    private delegateToCancelInterventionModal(): void {
        this.dialog.open(DialogDataCancelInterventionComponent).afterClosed()
            .subscribe(cancelComment => {
                if (cancelComment) {
                    this.commentGiven.emit({
                        value: cancelComment,
                        type: 'COMMENT_CANCEL_INTERVENTION',
                    });
                    this.inputMap.emit({
                        'decision': this.form.value.status,
                    });
                }
            });
    }

    private hasCommentValue(): boolean {
        return this.form.value.comment.length > 0;
    }

    private toCommentType(status: string): string {
        switch (status) {
            case 'WARRANTY_DISCLAIMER':
                return 'COMMENT_WARRANTY_DISCLAIMER';
            case 'PRODUCT_NOT_REPAIRABLE':
                return 'COMMENT_NOT_REPAIRABLE';
            default:
                return undefined;
        }
    }

    private toCommentTitle(status: string): string {
        switch (status) {
            case 'WARRANTY_DISCLAIMER':
            case 'PRODUCT_NOT_REPAIRABLE':
                return 'FOLDER.COMMENT_FORM.LABEL';
            default:
                return 'FOLDER.COMMENT';
        }
    }

    getTaskDuration(): string {
        return this.variablesTask['reparationMaxDuration'];
    }

    getStatusDate(): string {
        return this.folder.currentWorkflowStatus.workflowStatusDate;
    }

    changeConfirmedAppointmentValidator($event) {
        if ($event.value === 'APPOINTMENT_RESCHEDULE' && !this.hasAgenda) {
            this.form.get('confirmedAppointment').setValidators(Validators.required);
        }else{
            this.form.get('confirmedAppointment').clearValidators();
            this.form.get('confirmedAppointment').setValue(null);
        }
        this.form.get('confirmedAppointment').updateValueAndValidity();

    }
}
