<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" class="mt-8">

    <div *ngIf="!editMode" fxLayoutGap="15px">
        <strong [ngStyle]="isPriceNull ? {'color': 'red'} : {}">
            {{ label | translate }}:
        </strong>
        <span *ngIf="isPrice && updatedValue">{{ updatedValue | currency : (currency$ | async) :'symbol-narrow' | space }}</span>
        <span *ngIf="!isPrice && updatedValue">{{ updatedValue }}</span>
    </div>

    <ng-container *ngIf="isEditable">
        <ng-container *ngIf="!editMode else UPDATE_FIELD">
            <div (click)="switchEditMode()" class="cursor-pointer">
                <mat-icon>edit</mat-icon>
            </div>
        </ng-container>
    </ng-container>

    <ng-template #UPDATE_FIELD>
        <mat-form-field appearance="outline" fxFlex="80">
            <mat-label>{{ label | translate }}</mat-label>
            <ng-container [ngSwitch]="type">
                <ng-container *ngSwitchCase="'text'">
                    <input matInput
                           type="text"
                           [placeholder]="label | translate"
                           [formControl]="valueCtrl"
                           [(ngModel)]="updatedValue">
                </ng-container>
                <ng-container *ngSwitchCase="'number'">
                    <input matInput
                           numberOnly
                           type="number"
                           [min]="minValue"
                           [max]="maxValue"
                           [decimal]="isPrice"
                           [placeholder]="label | translate"
                           [formControl]="valueCtrl"
                           [(ngModel)]="updatedValue"
                           [required]="true">
                </ng-container>
            </ng-container>
            <mat-error *ngIf="!updatedValue || updatedValue > maxValue">{{ invalidMessage }}</mat-error>
        </mat-form-field>
        <div fxFlex="20">
            <div fxLayout="row" fxLayoutAlign="end center">
                <div *ngIf="canValidate()" (click)="updateValue()" class="cursor-pointer">
                    <mat-icon>check</mat-icon>
                </div>

                <div *ngIf="!maxValue || (maxValue && value)" (click)="cancel()" class="cursor-pointer">
                    <mat-icon>clear</mat-icon>
                </div>
            </div>
        </div>
    </ng-template>
</div>
