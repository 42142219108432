import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {isLoading} from '../../../store/loader/loader.selectors';
import {AppState} from '../../../store/app.state';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, AfterViewChecked {

    isLoading$: Observable<boolean>;

    constructor(private store$: Store<AppState>, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.isLoading$ = this.store$.pipe(select(isLoading));
    }


    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
}
