import {Component, OnInit} from '@angular/core';
import {DashboardV2Service} from '../../../../shared/services/dashboard-v2.service';
import {switchMap, take, withLatestFrom} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {PageEvent} from '@angular/material/paginator';
import {FormControl, FormGroup} from '@angular/forms';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {accessSites, affectedBusinessLinks} from '../../../../store/user/user.selectors';
import {SnackBarService} from '../../../../shared/services/snack-bar.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfigExtraCodesEnum} from '../../../../models/codeLabelObjectWithExtra.model';
import {SaveDashboard} from '../../../../store/dashboard/dashboard.actions';
import {MatDialog} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {interval} from 'rxjs';

@Component({
    selector: 'config-dashboard',
    templateUrl: './config-dashboard.component.html'
})
export class ConfigDashboardComponent implements OnInit {

    dataSource = new MatTableDataSource();
    filterForm: FormGroup;
    page = {index: 0, size: 10, totalElements: 0, sizeOptions: [10, 25, 50, 100]};
    columns = ['dashboard', 'user', 'role', 'siteCode', 'businessLink', 'actions'];
    fields: any[];

    constructor(private dashboardService: DashboardV2Service,
                private store$: Store<AppState>,
                private dialog: MatDialog,
                private snackBar: SnackBarService,
                private translateService: TranslateService,
                private router: Router) {
    }

    ngOnInit() {
        this.store$.pipe(select(affectedBusinessLinks))
            .pipe(take(1),
                withLatestFrom(this.store$.select(accessSites)))
            .subscribe(([businessLinks, sites]) => {
                this.fields = [
                    {
                        name: 'dashboard',
                        display: dashboard => dashboard.dashboardName,
                        label: 'CODE_LABEL_CONFIG.LIST.GRID_HEADER_DASHBOARD_NAME'
                    },
                    {
                        name: 'businessLink',
                        label: 'CODE_LABEL_CONFIG.LIST.GRID_HEADER_BUSINESS_LINK',
                        type: 'select',
                        options: businessLinks
                    },
                    {
                        name: 'siteCode',
                        label: 'CODE_LABEL_CONFIG.LIST.GRID_HEADER_SITE',
                        type: 'select',
                        options: sites
                    },
                    {
                        name: 'user',
                        label: 'CODE_LABEL_CONFIG.LIST.GRID_HEADER_USER',
                        type: 'text'
                    },
                    {
                        name: 'role',
                        label: 'CODE_LABEL_CONFIG.LIST.GRID_HEADER_ROLE',
                        type: 'text'
                    },
                ];
                this.initFilterForm();
                this.loadData();
            });
    }

    initFilterForm(): void {
        this.filterForm = new FormGroup({});
        this.fields
            .forEach(field => this.filterForm.addControl(field.name, new FormControl()));
    }

    create() {
        this.router.navigate(['dashboard-v2/create']);
    }

    resetSearch(fieldName) {
        if (this.filterForm.value[fieldName] === '') {
            this.clearSearchForm(fieldName);
        }
    }

    clearSearchForm(fieldName: string) {
        this.filterForm.get(fieldName).setValue(null);
        this.loadData();
    }

    isFilterFormEmpty = () => this.fields.map(field => field.name)
        .map(field => this.filterForm.value[field])
        .every(value => value?.length === 0)


    resetSearchFilter() {
        this.filterForm.reset();
        this.loadData();
    }

    edit(element) {
        this.router.navigate(['dashboard-v2/edit', element.id]);
    }

    delete(index, element) {
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });

        dialogRef.componentInstance.title = 'CONFIGURATION.DASHBOARD_V2.DELETE_DIALOG.TITLE';
        dialogRef.componentInstance.message = 'CONFIGURATION.DASHBOARD_V2.DELETE_DIALOG.MESSAGE';

        dialogRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.dashboardService.deleteRule(element.id)
                    .pipe(switchMap(() => this.dashboardService.deleteDashboard(element.dashboard.dashboardId)))
                    .subscribe(() => {
                        this.snackBar.openAtStart(
                            'Success',
                            'CODE_LABEL_CONFIG.FORM.TOAST_MESSAGES.SUCCESS_DELETE',
                            {
                                configCodeName: this.translateService.instant(`CODE_LABEL_CONFIG.CONFIG_CODES_TITLES.${ConfigExtraCodesEnum.DASHBOARD_V2}`)
                            });
                        this.dataSource.data.splice(index, 1);
                        this.dataSource.connect().next(this.dataSource.data);
                        this.page.totalElements -= 1;
                        interval(500).pipe(take(1))
                            .subscribe(() => this.store$.dispatch(new SaveDashboard()));
                    });
            }
        });
    }

    paginate(event: PageEvent) {
        this.page = {
            ...this.page,
            index: event.pageIndex,
            size: event.pageSize
        };
        this.loadData();
    }

    loadData() {
        const pageCriteria = {page: this.page.index, size: this.page.size};
        const rawValue = this.filterForm.getRawValue();

        this.store$.dispatch(new StartLoading());
        this.dashboardService.search(pageCriteria, rawValue)
            .pipe(take(1))
            .subscribe(page => {
                    this.dataSource.data = page.content;
                    this.page = {
                        ...this.page,
                        size: page.size,
                        totalElements: page.totalElements
                    };
                },
                () => this.dataSource.data = [],
                () => this.store$.dispatch(new StopLoading()));
    }
}



