import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest} from 'rxjs';
import { map } from 'rxjs/operators';
import { isLeroyMerlin, isNorauto, isTechnicalAdmin, isAdminPlatana} from '../../../store/user/user.selectors';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-api-metrics',
  templateUrl: './api-metrics.component.html',
  styleUrls: ['./api-metrics.component.scss']
})
export class ApiMetricsComponent implements OnInit {
  isLeroyMerlin$: Observable<boolean>;
  isNorauto$: Observable<boolean>;
  isAdminPlatana$: Observable<boolean>;
  isTechnicalAdmin$: Observable<boolean>;
  isLeroyAndTechAdmin$: Observable<boolean>;
  isNorautoAndTechAdmin$: Observable<boolean>;
  iframeAdeoDatadogURL: SafeResourceUrl;
  iframeNorautoDatadogURL: SafeResourceUrl;

  constructor(private store: Store, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.isLeroyMerlin$ = this.store.select(isLeroyMerlin);
    this.isNorauto$ = this.store.select(isNorauto);
    this.isAdminPlatana$ = this.store.select(isAdminPlatana);
    this.isTechnicalAdmin$ = this.store.select(isTechnicalAdmin);
    this.isLeroyAndTechAdmin$ = combineLatest([
      this.isLeroyMerlin$,
      this.isTechnicalAdmin$,
      this.isAdminPlatana$
    ]).pipe(
       map(([isLeroyMerlin, isTechnicalAdmin, isAdminPlatana]) =>
              (isLeroyMerlin && isTechnicalAdmin) || (isLeroyMerlin && isAdminPlatana))
    );

    this.isNorautoAndTechAdmin$ = combineLatest([
       this.isNorauto$,
       this.isTechnicalAdmin$,
       this.isAdminPlatana$
    ]).pipe(
      map(([isNorauto, isTechnicalAdmin, isAdminPlatana]) =>
              (isNorauto && isTechnicalAdmin) || (isNorauto && isAdminPlatana))
    );
    this.iframeAdeoDatadogURL = this.sanitizer.bypassSecurityTrustResourceUrl(environment.iframeAdeoDatadogURL);
    this.iframeNorautoDatadogURL = this.sanitizer.bypassSecurityTrustResourceUrl(environment.iframeNorautoDatadogURL);

  }
}
