    <mat-card-content>
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
        <form fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <ng-container>
                <div class="mt-24">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-checkbox class="mt-24" [checked]="destructionDone" (change)="stateChanged()">
                            {{'BUTTON.DESTRUCTION_BY_CUSTOMER_FINISHED' | translate}}
                        </mat-checkbox>
                    </div>
                </div>
            </ng-container>

            <div fxLayoutAlign="end center" class="mt-24">
                <button type="submit" mat-stroked-button [disabled]="!destructionDone"
                        [ngClass]="destructionDone? 'aster_btn': 'aster_btn_disabled' ">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>