import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {GLOBAL} from '../../app-config';
import {Folder, IFolderCreateRequestSnapshot, IFolderUpdateRequest, RepairCenter} from '../../models/folder.model';
import {FolderSearchResponse} from '../../models/folder-search-response.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FileOriginEnum} from '../../models/enums/fileOrigin.enum';
import {AttachmentTypeEnum} from '../../models/enums/attachmentType.enum';
import {FormGroup} from '@angular/forms';
import {IEvent} from '../../models/events.model';
import {Quotation, QuotationLine} from '../../models/quotation.model';
import {ExportFoldersRequest} from '../../models/SearchFoldersRequest.model';
import {ReparationReport} from '../../models/reparationReport/reparation-report.model';
import {OrderedSparePartsRequest} from '../../models/visio/spare-part.model';
import {FileInfo} from '../../models/file-info.model';
import {UpdateLocationCommand} from '../../models/product.model';

@Injectable({
    providedIn: 'root'
})
export class FolderService {
    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api/folders`;
    private pathFileService = `${GLOBAL.gatewayEndpoint}/file-service`;

    constructor(public httpClient: HttpClient) {
    }

    createFolder(folderSnapshot: IFolderCreateRequestSnapshot): Observable<Folder> {
        return this.httpClient.post<Folder>(`${this.path}/create`, folderSnapshot);
    }

    updateFolder(id: string, folderSnapshot: IFolderUpdateRequest): Promise<Folder> {
        return this.httpClient.put<Folder>(`${this.path}/update/${id}`, folderSnapshot).toPromise();
    }

    getCustomFolders(folderFilterRequest: any): Observable<FolderSearchResponse> {
        return this.httpClient.post<FolderSearchResponse>(`${this.path}/search`, folderFilterRequest);
    }

    getFolder(folderId: string): Observable<Folder> {
        return this.httpClient.get<Folder>(`${this.path}/${folderId}`);
    }

    public uploadAttachment(file: Blob, event: any, folderId: string, organizationCode: string, attachmentType: string): Observable<FileInfo> {
        const fileUloaded = new File([file], event.name, {type: event.type, lastModified: Date.now()});
        const headers = new HttpHeaders();
        const formData = new FormData();
        formData.append('file', fileUloaded);
        formData.append('businessKey', folderId);
        formData.append('fileName', event.name);
        formData.append('fileType', event.type);
        formData.append('origin', FileOriginEnum.BACK_OFFICE);
        formData.append('attachmentType', attachmentType);
        formData.append('organization', organizationCode);
        return this.httpClient.post<FileInfo>(`${this.pathFileService}/api/folders/${folderId}/attachment`, formData, {
            headers: headers
        });
    }

    public getAttachmentFileFolder(fileId): Observable<Blob> {
        return this.httpClient.get(`${this.pathFileService}/api/files/${fileId}`, {responseType: 'blob'});
    }

    public deleteAttachmentFileFolder(folderId, fileId): Promise<any> {
        const headers = new HttpHeaders();
        return new Promise<any>((resolve, reject) => {
            this.httpClient.delete(`${this.pathFileService}/api/folders/${folderId}/attachment/${fileId}`, {
                headers,
                responseType: 'text'
            }).subscribe(() => {
                const headers = new HttpHeaders();
                return this.httpClient.delete(`${this.path}/${folderId}/attachment/${fileId}`, {
                    headers,
                    responseType: 'text'
                }).subscribe(() => {
                    resolve();
                }, () => reject());
            });
        });
    }

    public removeFolder(folderId): Promise<any> {
        return this.httpClient.delete(`${this.path}/${folderId}`).toPromise();
    }

    findInvalidControls(form: FormGroup): void {
        Object.values(form.controls).forEach(control => {
            if ((control as any).controls) {
                this.findInvalidControls(control as FormGroup);
            }
        });
    }

    getEventList(folderId: string): Observable<IEvent[]> {
        return this.httpClient.get<IEvent[]>(`${this.path}/${folderId}/events`);
    }

    resendToExternalSystem(folderId: string, organizationCode: string, event: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('organizationCode', organizationCode);
        params = params.append('event', event);
        return this.httpClient.put<any>(`${this.path}/integration/external-system/${folderId}/send`, null,
            {params: params});
    }

    closeFolder(folderId: string, comment: IFolderUpdateRequest): Observable<Folder> {
        return this.httpClient.put<Folder>(`${this.path}/${folderId}/close`, comment);
    }

    updateQuotationToFolder(folderId: string, attachments: any, quotation: Quotation): Observable<any> {

        const formData = new FormData();
        let fileUploaded;
        attachments.forEach(attachment => {
            fileUploaded = new File([attachment.file], attachment.info.name, {type: attachment.info.type});
            formData.append('files', fileUploaded);
        });
        delete quotation.userAction;
        formData.append('quotation', JSON.stringify(quotation));
        const headersHttp = new HttpHeaders();
        return this.httpClient.put<any>(`${this.path}/update/quotation/${folderId}`, formData, {
            observe: 'response',
            headers: headersHttp,

        }).pipe(map((response) => {
            const data = response;
            return data;
        }));
    }

    exportFileFolders(folderFilterRequest: ExportFoldersRequest): Observable<HttpResponse<any>> {
        return this.httpClient.post(`${this.path}/export`, folderFilterRequest, {
            observe: 'response',
            responseType: 'text'
        });
    }

    getOldReparations(invoiceNumber: string, invoiceLineNumber: string, productCode: string): Observable<Folder[]> {
        let params = new HttpParams();
        params = params.append('invoiceLineNumber', invoiceLineNumber);
        return this.httpClient.get<Folder[]>(`${this.path}/old-reparations/${invoiceNumber}/${productCode}`, {params: params});
    }

    createFolderFomOldReparation(folderCreateRequest: IFolderCreateRequestSnapshot) {
        return this.httpClient.post<Folder>(`${this.path}/create-from-old-folder`, folderCreateRequest);
    }

    loadReparationReport(folderId: string): Observable<ReparationReport> {
        return this.httpClient.get<ReparationReport>(`${this.path}/repairer/reparation-report/${folderId}`);
    }

    startWorkflow(folderId: string): Observable<Folder> {
        return this.httpClient.put<Folder>(`${this.path}/${folderId}/reopen`, null);
    }

    getOrderedSpareParts(folderId: string, orderedSparePartsRequest: OrderedSparePartsRequest): Observable<QuotationLine[]> {
        return this.httpClient.post<QuotationLine[]>(`${this.path}/${folderId}/ordered-spare-parts`, orderedSparePartsRequest);
    }

    updateProductPosition(folderId: string, newProductLocation: UpdateLocationCommand): Observable<Folder> {
        return this.httpClient.post<Folder>(`${this.path}/${folderId}/product/locations`, newProductLocation);
    }

    getRepairCenter(folderId: string): Observable<RepairCenter[]> {
        return this.httpClient.get<RepairCenter[]>(`${this.path}/${folderId}/repair-center`);
    }


    startSparePartsWorkflow(folderId: string): Observable<Folder> {
        return this.httpClient.post<Folder>(`${this.path}/spare-parts/start/${folderId}`, {});
    }

    fetchWorkOrderNumber(folderId: string, workOrderId: string): Observable<any> {
        return this.httpClient.get(`${this.path}/${folderId}/workorders/${workOrderId}`);
    }

    anonymize(folderId: string): Observable<Folder> {
        return this.httpClient.post<Folder>(`${this.path}/${folderId}/anonymize`, {});
    }

    changeSupplierAndNotify(folderId: string, supplierCode: string, replayNotifications: string, replayReports: string ): Observable<Folder> {
        const params = new HttpParams()
            .append('replayNotifications', replayNotifications)
            .append('replayReports', replayReports);
        return this.httpClient.put<Folder>(`${this.path}/${folderId}/supplier/${supplierCode}/change-and-notify`, {}, {params});
    }
}

