import {Component, Inject, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {TypeOfServiceEnum} from '../../../models/typeOfService.model';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {QuotationModalConfig} from './quotationModal.model';
import {Payer} from '../../../models/enums/payer.enum';
import {DataSourceLine, Quotation, QuotationLine} from '../../../models/quotation.model';
import {QuotationType} from '../../../models/enums/quotationType.enum';
import {ConfigCodeMapsEnum} from '../../services/configuration-item-enum';
import {PAYERS_CONFIGS} from '../../data/static.config';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {Amount} from '../../../models/amount.model';
import {Vat} from '../../../models/vat.model';
import {SparePartService} from '../../services/spare-part.service';
import {select, Store} from '@ngrx/store';
import {allConfiguration} from '../../../store/configuration/configuration.selectors';
import {AppState} from '../../../store/app.state';
import {Unsubscriber} from '../../../unsubscriber';
import {countryCode, currency, margin, vats} from '../../../store/organization/organization.selectors';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {TypeOfBenefitService} from '../../services/typeOfBenefit.service';
import * as accents from 'remove-accents';
import {Price, RelatedSparePart, RelatedSparePartRequest, RelatedSparePartView, Supplier} from '../../../models/spare-parts/relatedSparePart.model';
import {DatePipe} from '@angular/common';
import {SparePartStock, StockDetails} from '../../../models/spare-parts/stock.model';
import {Order, OrderItem} from '../../../models/order.model';
import {OmsService} from '../../services/oms.service';
import {IFolderUpdateRequest} from '../../../models/folder.model';
import {OrderSubjectService} from '../../services/order-subject.service';
import {FolderSubjectService} from '../../../main/folder/folder-subject.service';
import {FolderService} from '../../services/folder.service';
import {environment} from '../../../../environments/environment';
import {SnackBarService} from '../../services/snack-bar.service';
import {IEvent} from '../../../models/events.model';
import * as uuid from 'uuid';
import {map} from 'rxjs/operators';
import {SupplierDTO} from 'app/models/spare-parts/supplier.model';
import {isLeroyMerlin} from '../../../store/user/user.selectors';
import {RuleEvaluationContext} from '../../../models/rules/RuleEvaluationContext';
import {ProductFamilyType} from '../../../models/enums/productFamilyType.enum';
import {BackOfficeService} from '../../services/back-office.service';

@Component({
    selector: 'app-quotation-modal',
    templateUrl: './quotation-modal.component.html',
    styleUrls: ['./quotation-modal.component.scss']
})
export class QuotationModalComponent extends Unsubscriber implements OnInit {
    @Input() variablesTask: any;
    vatsList$: Observable<Vat[]>;
    private defaultVat;

    payers = [];
    sparePartsTypes = [];
    isLeroyMerlin = false;

    displayedColumns: string[];
    dataSource: DataSourceLine[] = [];
    discountQuotations = 0;
    computeTotalPrice: Amount;
    quotationLines = new MatTableDataSource<DataSourceLine>();
    typeOfServices = new Array<any>();
    filteredData = new ReplaySubject<any[]>(1);

    quotationForm: FormGroup = new FormGroup({});
    isSparePartAdd = false;
    isLoading = false;
    private isPayerRequired = true;
    payersData = PAYERS_CONFIGS;
    currency: string;
    private sparePartsRelatedToProduct: RelatedSparePartView[] = [];
    countryCode: string;
    stockSparePartAvailable = true;
    stockSparePartsAvailable = true;
    isAvailable = true;
    orderItems: OrderItem[] = [];
    orderExtraItems: OrderItem[] = [];
    availableQuantity = 0;
    operatingMode: string;
    order = new Order();
    events: IEvent [] = [];
    isOrderConfirmed = false;
    quotation: Quotation;

    quotationTouched = false;
    hidePurchasePrice = true;
    hideMarge = true;
    hideSupplier = true;
    filteredSuppliers: Observable<SupplierDTO[]>;
    showSupplier = false;
    displayPurchasePrice = false;
    showMargin = false;
    defaultMargin: number = 0;
    sellingPriceSubscription: Subscription;
    constructor(private dialogRef: MatDialogRef<QuotationModalComponent>,
                private matDialog: MatDialog,
                private datePipe: DatePipe,
                private translateService: TranslateService,
                private typeOfBenefitService: TypeOfBenefitService,
                private store$: Store<AppState>,
                private sparePartService: SparePartService,
                private omsService: OmsService,
                private backOfficeService: BackOfficeService,
                private orderSubjectService: OrderSubjectService,
                private folderSubjectService: FolderSubjectService,
                private folderService: FolderService,
                private snackBar: SnackBarService,
                @Inject(MAT_DIALOG_DATA) public quotationModalConfig: QuotationModalConfig) {
        super();
        this.payers = Object.keys(Payer);
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(isLeroyMerlin)).subscribe(value => this.isLeroyMerlin = value);
        this.anotherSubscription = this.store$.pipe(select(margin)).subscribe(value => { this.defaultMargin = value; });
        this.operatingMode = this.quotationModalConfig.operatingMode;
        this.anotherSubscription = this.store$.pipe(select(countryCode))
            .subscribe(code => this.countryCode = code);
        this.initDefaultVat();
        this.initTypeOfBenefitService();
        if (!!this.quotationModalConfig.hidePayers$) {
            this.anotherSubscription = this.quotationModalConfig.hidePayers$.subscribe(payerHidden => {
                this.isPayerRequired = !payerHidden;
            });
        }
        if (!!this.quotationModalConfig.showSupplier$) {
            this.anotherSubscription = this.quotationModalConfig.showSupplier$.subscribe(show => {
                this.showSupplier = show;
                this.hideSupplier = !show;
            });
        }
        if (!!this.quotationModalConfig.showPurchasePrice$) {
            this.anotherSubscription = this.quotationModalConfig.showPurchasePrice$.subscribe(show => {
                this.displayPurchasePrice = show;
                this.hidePurchasePrice = !show;
            });
        }
        if (!!this.quotationModalConfig.showMargin$) {
            this.anotherSubscription = this.quotationModalConfig.showMargin$.subscribe(show => {
                this.showMargin = show;
                this.hideMarge = !show;
            });
        }
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => this.currency = currency);
        if (this.quotationModalConfig.loadTypeOfBenefit) {
            this.initTypeOfServices();
        }
        this.initQuotationForm(TypeOfServiceEnum.SPARE_PART);
        this.computeDisplayedColumns();
        if (this.operatingMode === 'INTERNAL_REPARATION') {
            this.initOrder();
        } else if (!!this.quotationModalConfig.initialQuotation) {
            this.loadInitialQuotation();
        }

        if (this.quotationModalConfig.loadSparePartCatalog) {
            this.loadRelatedSparePartViews();
        }
        this.initSuppliers();
    }


    loadQuotationFees() {
        if (!!this.quotationModalConfig.initialQuotation && this.quotationModalConfig.initialQuotation.quotationLines?.length > 0) {
            return this.quotationModalConfig.initialQuotation.quotationLines.find(quotation => quotation.type === QuotationType.QUOTATION_FEES);
        }
    }


    private initOrder() {
        if (!!this.quotationModalConfig.initOrder?.id) {
            this.order = this.quotationModalConfig.initOrder;
            this.orderItems = this
                .order.orderItems || [];
            this.orderExtraItems = this.order.orderExtraItems || [];
            const items = [...this.orderItems, ...this.orderExtraItems];
            this.dataSource = this.fromItems(items);
            this.displayQuotationFees();
            this.refreshDataSourceTable();
            if (this.order.orderStatus !== 'NOT_CONFIRMED' && this.order.orderStatus !== 'PICKING') {
                this.showOrderErrorMessage();
                this.isOrderConfirmed = true;
            }
        } else {
            this.loadInitialQuotation();
        }

    }

    private displayQuotationFees() {
        const quotationFeesFromOrder = this.orderExtraItems?.find(item => item.type === 'QUOTATION_FEES');
        const quotationFees = this.loadQuotationFees();
        if (!!quotationFees && !quotationFeesFromOrder) {
            const quotationFeesLine = this.toDataSourceLine(quotationFees);
            this.dataSource.push(quotationFeesLine);
        }
    }

    private showOrderErrorMessage() {
        this.snackBar.openAtEnd('Error', 'UPDATING.A.CONFIRMED.ORDER.IS.NOT.ALLOWED');
    }

    private initTypeOfBenefitService() {
        const sparePart = {
            code: TypeOfServiceEnum.SPARE_PART,
            label: this.translateService.instant('MODAL.QUOTATION.TYPE.SPARE_PART')
        };
        this.sparePartsTypes.push(sparePart);
        this.typeOfBenefitService.all().subscribe(types => {
            types.forEach(value => {
                this.sparePartsTypes.push(value);
            });
        });
    }

    private loadRelatedSparePartViews() {
        this.isLoading = true;
        this.store$.dispatch(new StartLoading());
        const product = this.quotationModalConfig.product;
        const request: RelatedSparePartRequest = {
            productCode: product.code,
            eanCode: product.codeEAN,
            supplierCode : product.supplier?.code,
            brand: product.brand,
            family1: RuleEvaluationContext.getFamilyByType(product.families, ProductFamilyType.FAMILY),
            countryCode: this.countryCode,
            managementSiteCode: this.quotationModalConfig.managementSite.code,
            withMedia: false,
        };

        this.sparePartService.getRelatedSpareParts(request)
            .subscribe(spareParts => {
                spareParts.forEach(value => this.sparePartsRelatedToProduct.push(this.buildSparePartView(value)));
                if (this.quotationForm.get('type').value ===  TypeOfServiceEnum.SPARE_PART){
                    this.filteredData.next(this.sparePartsRelatedToProduct);
                }
                this.isLoading = false;
                this.store$.dispatch(new StopLoading());
            }, () => {
                this.isLoading = false;
                this.store$.dispatch(new StopLoading());
            });
    }

    private buildSparePartView(value: RelatedSparePart) {
        return {
            id: value.id,
            code: value.code,
            label: value.label,
            price: value?.approximatePrice?.value,
            purchasePrice: value.purchasePrice?.value,
            marginPercentage: value.marginPercentage,
            information: value.information,
            supplier: value.suppliers?.length > 0 ? value.suppliers[0] : null,
            images: value.images,
            weight: parseFloat(value.caracteristics?.weight),
            ean: value.ean,
            approximatePrice: value.approximatePrice
        };
    }

    private computeDisplayedColumns(): void {
        this.displayedColumns = ['type', 'code', 'label', 'price', 'quantity'];
        if (this.isSparePartForm(this.quotationForm)) {
            this.displayedColumns.push('stock');
        }
        this.displayedColumns.push('discount', 'vat', 'totalPrice', 'action');
        if (this.showMargin) {
            this.displayedColumns.splice(4, 0, 'marginPercentage');
        }
        if (this.displayPurchasePrice) {
            this.displayedColumns.splice(3, 0, 'purchasePrice');
        }


    }


    initTypeOfServices(): void {
        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.TYPE_OF_SERVICE}))
            .subscribe(it => {
                if (this.quotationModalConfig.workforcesRule?.length > 0) {
                    this.typeOfServices = it.filter(item => item.type !== TypeOfServiceEnum.WORKFORCE);
                } else {
                    this.typeOfServices = it;
                }
            });
    }

    purchasePriceValidator(type: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const purchasePrice = control.value;
            if (this.isLeroyMerlin) {
                if (this.displayPurchasePrice && (purchasePrice === null || parseFloat(purchasePrice) < 0) && type === TypeOfServiceEnum.SPARE_PART) {
                    if (parseFloat(purchasePrice) < 0) {
                        control.markAsTouched();
                    }
                    return {'purchasePriceInvalid': true};
                }
            } else {
                if (this.displayPurchasePrice && (!purchasePrice || parseFloat(purchasePrice) <= 0) && type === TypeOfServiceEnum.SPARE_PART) {
                    if (parseFloat(purchasePrice) < 0) {
                        control.markAsTouched();
                    }
                    return {'purchasePriceInvalid': true};
                }
            }
            return null;
        };
    }

    marginPercentageValidator(type: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const marginPercentage = control.value;
            if (this.showMargin && (marginPercentage === undefined  || parseFloat(marginPercentage) < 0) && type === TypeOfServiceEnum.SPARE_PART) {
                return {'marginPercentageInvalid': true};
            }
            return null;
        };
    }

    supplierValidator(type: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const supplier = control.value;
            if (this.showSupplier && !supplier && type === TypeOfServiceEnum.SPARE_PART) {
                return {'supplierInvalid': true};
            }
            return null;
        };
    }

    initQuotationForm(type: string): void {
        this.quotationForm = new FormGroup({
            code: new FormControl(null, Validators.required),
            label: new FormControl(null, Validators.required),
            payer: new FormControl(this.getPayerForType(type), this.isPayerRequired ? Validators.required : null),
            quantity: new FormControl(1, [Validators.required, Validators.min(1)]),
            price: new FormControl(0, [Validators.required, Validators.min(0)]),
            approximatePrice: new FormControl(0),
            purchasePrice: new FormControl(0, {
                validators: this.purchasePriceValidator(type)
            }),
            vat: new FormControl(this.defaultVat, Validators.required),
            marginPercentage: new FormControl(this.defaultMargin, this.marginPercentageValidator(type)),
            discount: new FormControl(0, [Validators.min(0), Validators.max(100)]),
            type: new FormControl(type, Validators.required),
            discountReason: new FormControl(null),
            supplier: new FormControl(null, this.supplierValidator(type)),
            ean: new FormControl(null),
            images: new FormControl(null),
            weight: new FormControl(null),
            totalLinePrice: new FormControl(0),
            totalUnitLinePrice: new FormControl(0, Validators.required)
        });
    }

    private initDefaultVat() {
        this.vatsList$ = this.store$.pipe(select(vats));
        this.vatsList$.subscribe(vats => {
            this.defaultVat = vats.find(value => value.standard === true).rate;
        });
    }

    loadInitialQuotation(): void {
        this.dataSource = this.toDataSourceLines(this.quotationModalConfig.initialQuotation.quotationLines);
        this.discountQuotations = this.quotationModalConfig.initialQuotation.discount ? this.quotationModalConfig.initialQuotation.discount : 0;
        this.computeTotalPrice = {
            value: this.quotationModalConfig.initialQuotation.totalPrice?.value,
            currency: this.currency
        } || {
            value: 0,
            currency: this.currency
        };
        if (!this.quotationModalConfig.showFormAddQuotation) {
            this.displayedColumns.splice(7, 0, 'payer');
        }
        this.refreshDataSourceTable();
    }

    onCloseModal(): void {
        this.dialogRef.close();
    }

    validateForm(): void {
        if (this.hasSparePart()) {
            this.store$.dispatch(new StartLoading());
            const stocksRequest = this.dataSource
                .filter(value => value.type === QuotationType.SPARE_PART)
                .map(data => ({
                    sparePartCode: data.code.trim(),
                    quantity: data.quantity
                }));
            this.sparePartService.getAvailabilityOfStocks(this.quotationModalConfig.managementSite.code, stocksRequest)
                .subscribe(stockResponse => {
                    if (!!stockResponse) {
                        if (stockResponse.availabilityStatus === 'AVAILABLE' || stockResponse.availabilityStatus === 'UNKNOWN') {
                            this.stockSparePartsAvailable = true;
                            this.getEvents();
                            this.store$.dispatch(new StopLoading());
                        } else {
                            this.dataSource.map(value => stockResponse.stocks
                                .filter(stock => value.code === stock.sparePartCode)
                                .map(stock => value.stock = stock));
                            this.refreshDataSourceTable();
                            this.stockSparePartsAvailable = false;
                            this.store$.dispatch(new StopLoading());
                        }
                    } else {
                        this.getEvents();
                        this.store$.dispatch(new StopLoading());
                    }
                });
        } else {
            this.getEvents();
        }
    }

    private onCloseQuotationModal() {
        this.dialogRef.close({
            order: this.order,
            quotationLines: this.quotation?.quotationLines,
            quotationId: this.quotation?.id,
            discount: this.discountQuotations,
            totalPrice: this.computeTotalPrice
        });

    }

    private onClose() {
        // TODO this code will be deprecated after 60 days
        const operatingModeChangedDate = new Date(this.getEventDateOfOperatingChanged());
        if (operatingModeChangedDate < new Date(environment.operatingModeChangedDate)) {
            this.onCloseOldFolder();
        } else {
            this.onCloseNewFolder();
        }


    }

    private onCloseOldFolder() {
        const folderUpdateRequest = this.prepareFolderUpdateRequest();
        this.prepareQuotation();
        folderUpdateRequest.quotation = this.quotation;
        this.updateFolder(folderUpdateRequest);

    }

    private onCloseNewFolder() {
        if (this.operatingMode === 'INTERNAL_REPARATION') {
            this.OnSubmitOrder();
        } else {
            this.prepareQuotation();
            this.onCloseQuotationModal();
        }

    }

    private hasSparePart() {
        return this.quotationModalConfig.loadSparePartCatalog && this.dataSource
            .some(value => value.type === QuotationType.SPARE_PART);
    }

    formatAndCalculateTotalQuotationLine(): void {
        const unitPrice = this.quotationForm.controls.price.value;
        this.quotationForm.controls.price.setValue(this.getPriceWithFormatAccordingToCurrency(unitPrice));
        const totalUnitLinePrice = this.getPriceWithFormatAccordingToCurrency(this.computeTotalUnitPrice());
        this.quotationForm.controls.totalUnitLinePrice.setValue(totalUnitLinePrice);
        const totalLinePrice = this.getPriceWithFormatAccordingToCurrency(this.computeTotalLinePrice());
        this.quotationForm.controls.totalLinePrice.setValue(totalLinePrice);
    }

    private computeTotalLinePrice() {
        const price = this.sparePartService.normalizeFloat(this.quotationForm.controls.price.value);
        return this.sparePartService.getTotalLinePrice(price,
            this.quotationForm.controls.quantity.value, this.quotationForm.controls.discount.value, this.quotationForm.controls.vat.value);
    }

    private computeTotalUnitPrice() {
        const price = this.sparePartService.normalizeFloat(this.quotationForm.controls.price.value);
        return this.sparePartService.getTotalLinePrice(price, 1, this.quotationForm.controls.discount.value,
            this.quotationForm.controls.vat.value);
    }

    private computeUnitPrice() {
        const totalUnitLinePrice = this.sparePartService.normalizeFloat(this.quotationForm.controls.totalUnitLinePrice.value);
        return this.sparePartService.getProductPrice(totalUnitLinePrice, this.quotationForm.controls.discount.value,
            this.quotationForm.controls.vat.value);
    }

    calculatePriceAndMargin() {
        const unitPrice = this.computeUnitPrice();
        this.quotationForm.controls.price.setValue(this.getPriceWithFormatAccordingToCurrency(unitPrice));
        const purchasePrice = this.quotationForm.controls.purchasePrice.value;
        const margin = this.getMargin(unitPrice, purchasePrice);
        this.quotationForm.controls.marginPercentage.setValue(margin);
        const totalLinePrice = this.getPriceWithFormatAccordingToCurrency(this.computeTotalLinePrice());
        this.quotationForm.controls.totalLinePrice.setValue(totalLinePrice);

    }

    private getPriceWithFormatAccordingToCurrency(price: number): string {
        return this.sparePartService.formatPriceAccordingToCurrency(price, this.currency);
    }

    changeLinePrice(): void {
        this.dataSource = this.quotationLines.data;
        this.dataSource.forEach(quotationLine => {
            quotationLine.totalLinePrice = this.sparePartService.getTotalLinePrice(quotationLine.price, quotationLine.quantity, quotationLine.discount, this.defaultVat);
        });
        this.refreshDataSourceTable();
    }

    normalize(value): number {
        return Number(this.sparePartService.normalizeFloat(value).toFixed(2));
    }

    getTotalPriceHT(): any {
        return !!this.dataSource ? this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) =>
            total + this.sparePartService.normalizeFloat(line.price) * line.quantity, 0) : 0;
    }

    getTotalDiscount(): any {
        if (!!this.dataSource) {
            const totalPriceHT = this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) =>
                total + this.sparePartService.normalizeFloat(line.price) * line.quantity, 0);
            return totalPriceHT - this.getTotalNetHT();
        }
        return 0;

    }

    getTotalNetHT(): any {
        if (!!this.dataSource) {
            let totalNetHT;
            totalNetHT = this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) =>
                line.discount > 0 ? total + (this.sparePartService.normalizeFloat(line.price) * line.quantity -
                    ((this.sparePartService.normalizeFloat(line.price) * line.quantity) * (line.discount / 100))) : total
                    + (this.sparePartService.normalizeFloat(line.price) * line.quantity), 0);
            if (this.discountQuotations > 0) {
                return totalNetHT - totalNetHT * (this.discountQuotations / 100);
            }
            return totalNetHT;
        }
        return 0;

    }

    getTotalPriceTVA(): any {
        if (!!this.dataSource) {
            return this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) => {
                const totalHT = this.sparePartService.normalizeFloat(line.price) * line.quantity;
                const discount = line.discount / 100;
                const vatRate = this.defaultVat;
                if (!!vatRate && vatRate > 0) {
                    const totalNetHT = totalHT - totalHT * discount;
                    return total + (totalNetHT + totalNetHT * (vatRate / 100)) - totalNetHT;
                }
                return total;
            }, 0);
        }
        return 0;
    }

    addIntoQuotationLines(): void {
        this.quotationTouched = true;
        if (this.isSparePartForm(this.quotationForm)) {
            this.checkAvailabilityStock();
            this.isSparePartAdd = true;
        } else {
            this.checkDiscountValue();
            const dataSourceLine = this.quotationForm.value;
            this.addAndRefreshDataSourceLine(dataSourceLine);
        }
    }

    private checkAvailabilityStock() {
        this.store$.dispatch(new StartLoading());
        const stockRequest = [{
            sparePartCode: this.quotationForm.value.code.trim(),
            quantity: this.quotationForm.value.quantity
        }];
        this.store$.dispatch(new StartLoading());
        this.sparePartService.getAvailabilityOfStocks(this.quotationModalConfig.managementSite.code, stockRequest).subscribe(response => {
            this.checkDiscountValue();
            if (!!response) {
                if (response.availabilityStatus === 'AVAILABLE' || response.availabilityStatus === 'UNKNOWN') {
                    const dataSourceLine = this.quotationForm.value;
                    this.stockSparePartAvailable = true;
                    dataSourceLine.stock = { ...(response.stocks[0] || this.getDefaultStock())};
                    this.addAndRefreshDataSourceLine(dataSourceLine);
                    this.isSparePartAdd = false;
                } else {
                    this.stockSparePartAvailable = false;
                    this.isSparePartAdd = false;
                    this.store$.dispatch(new StopLoading());
                }
            } else { // organization hasn't api availability
                const dataSourceLine = this.quotationForm.value;
                this.checkApiStock(dataSourceLine);
                this.addAndRefreshDataSourceLine(dataSourceLine);
                this.isSparePartAdd = false;
            }

        });
    }

    private checkDiscountValue() {
        if (!this.quotationForm.value.discount) {
            this.quotationForm.get(['discount']).setValue(0);
        }
    }

    private addAndRefreshDataSourceLine(dataSourceLine) {
        this.dataSource.unshift(dataSourceLine);
        this.refreshDataForm(TypeOfServiceEnum.SPARE_PART);
        this.refreshDataSourceTable();
    }

    private checkApiStock(dataSourceLine) {
        dataSourceLine.stock = {isLoading: true};
        this.sparePartService.getStocks([dataSourceLine.code.trim()], this.quotationModalConfig.managementSite.code).subscribe(stocks => {
                dataSourceLine.stock = {...stocks[0], isLoading: false};
                this.availableQuantity = stocks[0]?.totalStock;
            },
            error => dataSourceLine.stock.isLoading = false,
            () => dataSourceLine.stock.isLoading = false);
    }

    refreshDataForm(type: string): void {
        this.initQuotationForm(type);
        this.stockSparePartAvailable = true;
        if (type === TypeOfServiceEnum.SPARE_PART) {
            this.filteredData.next(this.sparePartsRelatedToProduct);
            this.hidePurchasePrice = false;
            this.hideMarge = false;
            this.hideSupplier = this.getHideSupplier();
        } else if (type === TypeOfServiceEnum.WORKFORCE && this.quotationModalConfig?.workforcesRule?.length > 0) {
            this.loadWorkforce(type);
            this.hidePurchasePrice = true;
            this.hideMarge = true;
            this.hideSupplier = true;
        } else {
            this.changeFileredTypeOfService(type);
            this.hidePurchasePrice = true;
            this.hideMarge = true;
            this.hideSupplier = true;
        }
    }

    private getHideSupplier() {
        return !this.showSupplier;
    }

    private loadWorkforce(type: string) {
        this.filteredData.next(this.quotationModalConfig.workforcesRule);
    }

    private changeFileredTypeOfService(type) {
        const typeOfService = this.typeOfServices.filter(item => item.type === type);
        this.filteredData.next(typeOfService);
    }

    deleteLine(indexLine: number): void {
        this.quotationTouched = true;
        const dialog = this.matDialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });
        dialog.componentInstance.title = this.translateService.instant('CONFIRMATION.MODAL.DELETE_DIALOG.TITLE');
        dialog.componentInstance.message = this.translateService.instant('FOLDER.FILE.DELETE_DIALOG.MESSAGE');
        dialog.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.DELETE');
        dialog.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialog.afterClosed().subscribe(isDelete => {
            if (isDelete) {
                this.dataSource = this.dataSource.filter((item, index) => index !== indexLine || this.isFeesQuotation(item));
                this.quotationLines = new MatTableDataSource<DataSourceLine>(this.dataSource);
                const totalPrice = this.sparePartService.computeTotalPrice(this.dataSource.map(quotationLine => quotationLine.totalLinePrice));
                this.computeTotalPrice = {
                    value: totalPrice,
                    currency: this.currency
                };
            }
        });

    }

    refreshDataSourceTable(): void {
        this.quotationLines.data = [];
        this.quotationLines = new MatTableDataSource<DataSourceLine>(this.dataSource);
        const totalPrice = this.sparePartService.computeTotalPrice(this.dataSource.map(quotationLine => quotationLine.totalLinePrice));
        this.computeTotalPrice = {
            value: totalPrice,
            currency: this.currency
        };
        this.store$.dispatch(new StopLoading());
    }

    private hideQuotationFees(service: string) {
        return !(!!this.dataSource.find(line => line.type === 'QUOTATION_FEES') && service === 'QUOTATION_FEES');
    }

    private filterTypeOfService(value: any, criteria: string): string[] {
        const filterValue = value[criteria]?.toLowerCase();
        return value && filterValue ? this.typeOfServices.filter(item => item.type === value.type &&
            accents.remove(item[criteria].toLowerCase()).includes(accents.remove(filterValue))) : this.typeOfServices.filter(item => item.type === value.type);
    }

    onCodeChanges(): void {
        const type = this.quotationForm.get('type').value;
        const code = this.quotationForm.get('code').value?.trim();

        if (!!type && type === TypeOfServiceEnum.SPARE_PART) {
            this.searchSpareParts('code', code);
        } else if (!!type && type === TypeOfServiceEnum.WORKFORCE && this.quotationModalConfig.workforcesRule?.length > 0) {
            this.searchWorkForce('code', code);
        } else {
            if (this.quotationModalConfig.loadTypeOfBenefit) {
                this.searchTypeOfService('code', code, type);
            }
        }
    }

    onLabelChanges(): void {
        const type = this.quotationForm.get('type').value;
        const label = this.quotationForm.get('label').value;

        if (!!type && type === TypeOfServiceEnum.SPARE_PART) {
            this.searchSpareParts('label', label);
        } else if (!!type && type === TypeOfServiceEnum.WORKFORCE && this.quotationModalConfig.workforcesRule?.length > 0) {
            this.searchWorkForce('label', label);
        } else {
            if (this.quotationModalConfig.loadTypeOfBenefit) {
                this.searchTypeOfService('label', label, type);
            }
        }
    }

    private searchTypeOfService(criteria: string, value: string, type: string) {
        const inputs = {
            'type': type
        };
        inputs[criteria] = value;

        this.isLoading = true;
        this.filteredData.next(this.filterTypeOfService(inputs, criteria));
        this.isLoading = false;
    }
    private searchWorkForce(criteria: string, value: string) {
        if (value && value.trim().length > 0) {
            const filteredByCode = this.quotationModalConfig.workforcesRule.filter(sparePart =>
                accents.remove(sparePart[criteria].toLowerCase())
                    .includes(accents.remove(value.toLowerCase()))
            );
            this.filteredData.next(filteredByCode);
        } else {
            this.filteredData.next(this.quotationModalConfig.workforcesRule);
        }
    }
    private searchSpareParts(criteria: string, value: string) {
        if (value && value.trim().length > 0) {
            const filteredByCode = this.sparePartsRelatedToProduct.filter(sparePart =>
                accents.remove(sparePart[criteria].toLowerCase())
                    .includes(accents.remove(value.toLowerCase()))
            );
            this.filteredData.next(filteredByCode);
            if (this.isEmptyResult(filteredByCode)) {
                this.supplierEditable();
            }

        } else {
            this.filteredData.next(this.sparePartsRelatedToProduct);
        }
    }

    sparePartChanged(event): void {
        this.initQuotationForm(this.quotationForm.controls.type.value);
        this.supplierNotEditable();
        if (!event.option.value.supplier) {
            this.supplierEditable();
        }
        this.calculateSellingPrice(event);

        this.quotationForm.controls.code.setValue(event.option.value.code.trim());
        this.quotationForm.controls.label.setValue(event.option.value.label);
        this.quotationForm.controls.supplier.setValue(event.option.value.supplier);


        if (event.option.value.payer) {
            this.quotationForm.controls.payer.setValue(event.option.value.payer);
        }
        this.quotationForm.controls.ean.setValue(event.option.value.ean);
        this.quotationForm.controls.approximatePrice.setValue(event.option.value.approximatePrice?.value);
        this.quotationForm.controls.images?.setValue(event.option.value.images);
        this.quotationForm.controls.weight.setValue(event.option.value.weight);
    }

    private calculateSellingPrice(event) {
        if (!!this.sellingPriceSubscription) {
            this.sellingPriceSubscription.unsubscribe();
        }
        const purchasePrice = event.option.value.purchasePrice || event.option.value.supplier?.unitPrice?.value;
        const sparePart = event.option.value;
        if (typeof purchasePrice === 'number' && purchasePrice > 0) {
            const approximatePrice = !!event.option.value.approximatePrice?.value ? event.option.value.approximatePrice.value : 0;
            this.sellingPriceSubscription = this.backOfficeService.computeSellingPrice(purchasePrice, this.defaultMargin, approximatePrice)
                .subscribe(data => {
                    if (!!data) {
                        if (this.isMarginExist(data)){
                            sparePart.price = data.sellingPrice || 0;
                            sparePart.marginPercentage = data.marginPercentage || 0;
                            sparePart.purchasePrice = data.purchasePrice || 0;
                        }
                    }
                }, () => {
                }, () => {
                    this.completeFormWithPrices(event, purchasePrice);
                });
        }else{
            this.completeFormWithPrices(event, purchasePrice);
        }
    }

    private completeFormWithPrices(event, purchasePrice: number) {
        const price = this.getSellingPrice(event);
        const margin = this.margin(event);
        this.quotationForm.controls.price.setValue(price);
        this.quotationForm.controls.purchasePrice.setValue(purchasePrice || 0);
        this.quotationForm.controls.marginPercentage.setValue(margin || this.defaultMargin);
        this.formatAndCalculateTotalQuotationLine();
    }

    private getSellingPrice(event) {
        return this.isFreeSparePart(event.option.value.supplier) ? 0 : event.option.value.price || 0;
    }

    private margin(event) {
        return event.option.value.marginPercentage === 0 && event.option.value.purchasePrice === 0 ?
            this.defaultMargin : event.option.value.marginPercentage;
    }

    isFeesQuotation(quotationLine: DataSourceLine): boolean {
        return quotationLine.type.startsWith(QuotationType.QUOTATION_FEES) && quotationLine.price < 0;
    }

    private isFreeSparePart(supplier) {
        return supplier?.freeUnderWarranty && this.isUnderWarranty();
    }

    private isUnderWarranty() {
        return this.quotationModalConfig.warrantyCode === 'SG';
    }

    toDataSourceLines(quotationLines: QuotationLine[]): DataSourceLine[] {
        const dataSourceLines: DataSourceLine[] = [];
        if (quotationLines?.length > 0) {
            quotationLines.forEach(quotationLine => {
                // map some specific values
                const line = {
                    ...quotationLine,
                    vat: Number(quotationLine.vat?.rate),
                    price: quotationLine.priceAmount.value,
                    purchasePrice: quotationLine.purchasePrice?.value,
                    totalLinePrice: quotationLine.totalLinePrice?.value,
                    payer: this.quotationModalConfig.payer ?? quotationLine.payer
                };
                if (quotationLine.type === 'SPARE_PART') {
                    line.stock = {isLoading: true};
                }
                const {priceAmount, ...dataSourceLine} = line;
                dataSourceLines.push(dataSourceLine);
            });
            this.fetchStocksOfDisplayedSpareParts(dataSourceLines);
        }
        return dataSourceLines;
    }

    toDataSourceLine(quotationLine: QuotationLine): DataSourceLine {
        return {
            ...quotationLine,
            vat: Number(this.defaultVat),
            price: quotationLine.priceAmount.value,
            purchasePrice: quotationLine.purchasePrice?.value,
            totalLinePrice: quotationLine.totalLinePrice?.value
        };
    }

    private prepareStockRequest(quotationLines) {
        return quotationLines
            .filter(value => value.type === QuotationType.SPARE_PART)
            .map(quotationLine => ({
                sparePartCode: quotationLine.code.trim(),
                quantity: quotationLine.quantity
            }));
    }

    private fetchStocksOfDisplayedSpareParts(dataSourceLines: DataSourceLine[]): void {
        const stockRequest = this.prepareStockRequest(dataSourceLines);
        if (!!stockRequest && stockRequest.length > 0) {
            this.sparePartService.getAvailabilityOfStocks(this.quotationModalConfig.managementSite.code, stockRequest).subscribe(response => {
                if (!!response) {
                    this.isAvailable = response.availabilityStatus === 'AVAILABLE';
                    this.merge(dataSourceLines, response.stocks);
                } else {
                    const sparePartCodes = dataSourceLines.map(data => data.code);
                    this.sparePartService.getStocks(sparePartCodes.map(code => code.trim()), this.quotationModalConfig.managementSite.code)
                        .subscribe(stocks => this.merge(dataSourceLines, stocks));
                }
            });
        }
    }

    private merge(quotationLines: DataSourceLine[], stocks: SparePartStock[]): void {
        const stocksByCode = new Map(stocks.map(stock => [stock.sparePartCode, stock]));
        quotationLines.forEach(quotationLine => {
            quotationLine.stock = stocksByCode.get(quotationLine.code) ??   this.getDefaultStock();
            quotationLine.stock.isLoading = false;
        });
    }

    private getDefaultStock(): SparePartStock {
        return {
            status: 'UNKNOWN',
            isLoading: false
        } as SparePartStock;
    }

    toQuotationLines(dataSourceLines: DataSourceLine[]): QuotationLine[] {
        const quotationLines: QuotationLine[] = [];
        dataSourceLines.forEach(dataSourceLine => {
            const quotationLine: QuotationLine = {
                ...dataSourceLine,
                type: dataSourceLine.type,
                payer: dataSourceLine.payer ? dataSourceLine.payer : this.completeWithPayers(dataSourceLine.type),
                vat: {rate: String(dataSourceLine.vat)},
                priceAmount: {
                    value: this.sparePartService.normalizeFloat(dataSourceLine.price),
                    currency: this.currency
                },
                purchasePrice: {
                    value: this.getPrice(dataSourceLine),
                    currency: this.currency
                },
                marginPercentage: this.getMarginPercentage(dataSourceLine),
                totalLinePrice: {
                    value: this.sparePartService.normalizeFloat(dataSourceLine.totalLinePrice),
                    currency: this.currency
                }
            };

            delete quotationLine['price'];
            quotationLines.push(quotationLine);
        });

        return quotationLines;
    }

    completeWithPayers(type) {
        if (type === 'SHIPMENT_FEES' && this.quotationModalConfig.warrantyCode === 'HG') {
            return Payer.CLIENT;
        }

    }

    hasStock(status: string): boolean {
        return status && status === 'AVAILABLE';
    }

    getStockIndicatorClass(status: string): string {
        switch (status) {
            case 'AVAILABLE':
                return 'green-color';
            case 'NOT_AVAILABLE':
                return 'red-color';
            default:
                return 'black-color';
        }
    }

    displayStockStatus(status: string, type: string): string {
        if (type !== 'SPARE_PART') {
            if ('UNKNOWN' === status || !status) {
                return 'TEXT.UNKNOWN';
            }
        } else {
            if ('UNKNOWN' === status) {
                return 'TEXT.UNKNOWN';
            }
            if (!status) {
                return 'TEXT.UNDEFINED';
            }
        }

        if (status === 'AVAILABLE') {
            return 'TEXT.AVAILABLE';
        }

        if (status === 'NOT_AVAILABLE') {
            return 'TEXT.UNAVAILABLE';
        }
    }

    stockDetailsTooltip(stocks: StockDetails[], totalStock: string): string {
        let tooltipMessage = '';
        if (totalStock) {
            tooltipMessage = this.translateService.instant('TOOLTIP.STOCK.DETAILS.STOCK', {stock: totalStock});

        } else {
            const stockDetails = stocks
                .map(stock => this.translateService.instant('TOOLTIP.STOCK.DETAILS',
                    {
                        siteCode: stock?.siteCode,
                        siteLabel: stock?.siteLabel,
                        deliveryDate: this.datePipe.transform(stock?.deliveryDate, 'dd-MM-yyyy')
                    }));

            stockDetails.map(stockDetails => tooltipMessage += stockDetails + '\n');
        }
        return tooltipMessage;
    }

    private getPayerForType(type: string): string {
        if (this.quotationModalConfig.payer) {
            return this.quotationModalConfig.payer;
        } else if (!!this.quotationModalConfig.payers) {
            if (TypeOfServiceEnum.DISPLACEMENT === type) {
                return this.quotationModalConfig.payers.displacement;
            } else if (TypeOfServiceEnum.WORKFORCE === type) {
                return this.quotationModalConfig.payers.workforce;
            }
            return this.quotationModalConfig.payers.spareParts;
        }
        return null;
    }

    isSparePartForm(form): boolean {
        return form.get('type')?.value === 'SPARE_PART' && this.quotationModalConfig.loadSparePartCatalog;
    }

    disabledForm(): boolean {
        if (this.operatingMode === 'INTERNAL_REPARATION') {
            const lines = this.dataSource?.filter(dataSourceLine => dataSourceLine?.type !== QuotationType.QUOTATION_FEES);
            return (this.computeTotalPrice?.value < 0 || lines?.length === 0) || this.isOrderConfirmed;
        } else {
            return this.quotationLines.data.length <= 0 || (this.quotationModalConfig.loadSparePartCatalog && !this.isAvailable)
                || !this.quotationLines.data.every(value => value.price ?? false) || this.computeTotalPrice?.value < 0;
        }
    }

    fromItems(orderItems: OrderItem[]): DataSourceLine[] {
        const dataSourceLines: DataSourceLine[] = [];
        if (orderItems.length > 0) {
            orderItems.forEach(orderItem => {
                const dataSourceLine = {
                    orderItemId: orderItem.id,
                    code: orderItem.code,
                    label: orderItem.label,
                    quantity: orderItem.quantityOrdered,
                    discount: orderItem.discountPercent,
                    discountReason: orderItem.discountReason,
                    type: orderItem.type,
                    payer: orderItem.payer,
                    vat: Number(orderItem.taxPercent),
                    price: orderItem.price,
                    purchasePrice: orderItem.purchasePrice,
                    marginPercentage: orderItem.marginPercentage,
                    totalLinePrice: orderItem.rowTotal,
                    stock: {isLoading: false}
                };
                if (dataSourceLine.type === 'SPARE_PART') { // todo à voir
                    dataSourceLine.stock = {isLoading: true};
                }
                dataSourceLines.push(dataSourceLine);
            });
            this.fetchStocksOfDisplayedSpareParts(dataSourceLines);
        }
        return dataSourceLines;
    }

    prepareItems() {
        this.orderItems = [];
        this.orderExtraItems = [];
        const sparePartsItems = this.dataSource.filter(dataSourceLine => dataSourceLine.type === QuotationType.SPARE_PART);
        const extraItems = this.dataSource.filter(dataSourceLine => dataSourceLine.type !== QuotationType.SPARE_PART);
        sparePartsItems.forEach((line) => {
            const item = this.toItem(line);
            this.orderItems.push(item);
        });
        extraItems.forEach((line) => {
            const item = this.toItem(line);
            this.orderExtraItems.push(item);
        });
    }

    private computeInvoicedQuantity(dataSourceLine: DataSourceLine) {
        return this.isFreeSparePart(dataSourceLine.supplier) ? 0 : dataSourceLine.quantity;
    }

    toItem(dataSourceLine: DataSourceLine): OrderItem {
        const item = new OrderItem();
        item.id = dataSourceLine.orderItemId;
        item.code = dataSourceLine.code;
        item.label = dataSourceLine.label;
        item.payer = dataSourceLine.payer;
        item.type = dataSourceLine.type;
        item.discountPercent = dataSourceLine.discount;
        item.discountReason = dataSourceLine.discountReason;
        item.taxPercent = dataSourceLine.vat;
        item.price = this.normalize(dataSourceLine.price);
        item.currency = this.currency;
        item.rowTotal = this.normalize(dataSourceLine.totalLinePrice);
        item.quantityOrdered = dataSourceLine.quantity;
        item.quantityInvoiced = this.computeInvoicedQuantity(dataSourceLine);
        if (item.type === QuotationType.SPARE_PART) {
            item.ean = dataSourceLine.ean;
            item.images = dataSourceLine.images;
            item.supplier = this.getSupplier(item.code, dataSourceLine);
            item.weight = dataSourceLine.weight;
            item.quantityAvailable = this.availableQuantity;
            item.isAvailable = this.stockSparePartAvailable;
            item.purchasePrice = this.getPrice(dataSourceLine);
            item.marginPercentage = this.getMarginPercentage(dataSourceLine);
        } else {
            item.displayType = item.type;
        }
        return item;
    }

    private getMarginPercentage(dataSourceLine: DataSourceLine) {
        return dataSourceLine.purchasePrice > 0 ? dataSourceLine.marginPercentage : 0;
    }

    private getPrice(dataSourceLine: DataSourceLine) {
        return dataSourceLine.purchasePrice > 0 ? this.sparePartService.normalizeFloat(dataSourceLine.purchasePrice) : this.sparePartService.normalizeFloat(dataSourceLine.price);
    }

// TODO we can no longer evolve this component, it becomes difficult to maintain
    getSupplier(code: string, dataSourceLine: DataSourceLine): Supplier {
        // pièce non réferencé ou ajoutée manuellement
        if (dataSourceLine.supplier) {
            return {
                id: dataSourceLine.supplier.id,
                code: dataSourceLine.supplier.code,
                name: dataSourceLine.supplier.name,
                supplierReference: code,
                freeUnderWarranty: false,
                unitPrice: {
                    value: dataSourceLine.purchasePrice,
                    currency: this.currency
                }
            };
        }

        const element: any = this.sparePartsRelatedToProduct.find(e => e.code === code);
        return element?.supplier;
    }


    private prepareOrder() {
        this.prepareItems();
        this.order.externalId = this.quotationModalConfig.folderId;
        this.order.organizationCode = this.quotationModalConfig.organizationCode;
        this.order.shippingAddress = this.quotationModalConfig.managementSite?.address;
        this.order.shippingContact = this.quotationModalConfig.managementSite?.contact;
        this.order.siteCode = this.quotationModalConfig.managementSite?.code;
        this.order.currency = this.currency;
        this.order.orderItems = this.orderItems;
        this.order.orderExtraItems = this.orderExtraItems;
    }

    private OnSubmitOrder() {
        this.prepareOrder();
        this.store$.dispatch(new StartLoading());
        if (!!this.order?.id) {
            this.updateOrder();
        } else {
            this.addOrder();
        }

    }

    private addOrder() {
        this.omsService.createOrder(this.order).subscribe((data) => {
            this.store$.dispatch(new StopLoading());
            if (!!data) {
                this.order = data;
                this.orderSubjectService.orderLoaded(this.order);
                const folderUpdateRequest = this.prepareFolderUpdateRequest();
                if (this.quotationModalConfig.warrantyCode === 'HG') {
                    this.prepareQuotation();
                    folderUpdateRequest.quotation = this.quotation;
                }
                this.updateFolder(folderUpdateRequest);

            }
        }, () => this.store$.dispatch(new StopLoading()));
    }

    private updateOrder() {
        this.omsService.updateOrder(this.order.id, this.order).subscribe((data) => {
            this.store$.dispatch(new StopLoading());
            if (!!data) {
                this.order = data;
                this.orderSubjectService.orderLoaded(this.order);
                if (this.quotationModalConfig.warrantyCode === 'HG') {
                    this.prepareQuotation();
                    const folderUpdateRequest = {
                        quotation: this.quotation
                    };
                    this.updateFolder(folderUpdateRequest);
                } else {
                    this.onCloseQuotationModal();
                }
            }
        }, () => this.store$.dispatch(new StopLoading()));
    }

    get filteredSparePartsTypes() {
        return this.sparePartsTypes.filter((service) => this.hideQuotationFees(service.code));
    }

    private prepareQuotation() {
        if (this.quotationModalConfig.initialQuotation) {
            this.quotation = this.quotationModalConfig.initialQuotation;
        } else {
            this.quotation = new Quotation();
        }
        if (!this.quotation.id) {
            this.quotation.id = uuid.v4();
             this.quotationTouched = true;
        }
        this.quotation.quotationLines = this.toQuotationLines(this.dataSource);
        this.quotation.discount = this.discountQuotations;
        this.quotation.totalPrice = this.computeTotalPrice;
        if (this.operatingMode === 'INTERNAL_REPARATION') {
            this.quotation.target = 'CLIENT';
        } else {
            this.quotation.target = 'DISTRIBUTOR';
        }
    }

    private prepareFolderUpdateRequest(): IFolderUpdateRequest {
        return {
            orderId: this.order.id
        };
    }

    private updateFolder(folderUpdateRequest: IFolderUpdateRequest) {
        if (!this.quotationTouched) {
            this.onCloseQuotationModal();
            return;
        }
        this.folderService.updateFolder(this.quotationModalConfig.folderId, folderUpdateRequest).then(folder => {
            this.folderSubjectService.folderLoaded(folder);
            this.onCloseQuotationModal();
            this.store$.dispatch(new StopLoading());
        });
    }

    private getEvents() {
        this.folderService.getEventList(this.quotationModalConfig.folderId).subscribe(data => {
            this.events = data;
            this.onClose();
        });
    }

    private getEventDateOfOperatingChanged() {
        const filteredEvents = this.events.filter((e) => e.eventType === 'FOLDER_DATA' && (e.eventName === 'OPERATING_MODE_CHANGED'));
        const filteredChooseOperatingModeEvents = this.events.filter((e) => e.eventType === 'WORKFLOW_STATUS' && (e.eventName === 'CHOOSE_OPERATING_MODE'));
        const lastEvent = filteredEvents.reduce((prev, current) =>
            prev?.userAction.actionDate > current.userAction.actionDate ? prev : current
        );
        const lastChooseOperatingModeEvent = filteredChooseOperatingModeEvents.reduce((prev, current) =>
            prev?.userAction.actionDate > current.userAction.actionDate ? prev : current
        );
        if (lastEvent.userAction.actionDate > lastChooseOperatingModeEvent.userAction.actionDate) {
            return lastEvent.userAction.actionDate;
        } else {
            return lastChooseOperatingModeEvent.userAction.actionDate;
        }

    }

    calculatePrice() {
        const marginPercentage = this.quotationForm.controls.marginPercentage.value;
        const purchasePrice = this.quotationForm.controls.purchasePrice.value;
        const price = this.computeSellingPrice(purchasePrice, marginPercentage);
        this.quotationForm.controls.price.setValue(price);
        this.formatAndCalculateTotalQuotationLine();
    }

    calculatePrices() {
        const purchasePrice = this.quotationForm.controls.purchasePrice.value;
        const approximatePrice = this.sparePartsRelatedToProduct
            .find(value => value.code === this.quotationForm.controls.code.value)
            .price;
        this.backOfficeService.computeSellingPrice(purchasePrice, this.defaultMargin, approximatePrice).subscribe(prices => {
            const sellingPrice = !this.isFreeSparePart(this.quotationForm.controls.supplier.value) ? prices.sellingPrice  || 0: 0;
            this.quotationForm.controls.price.setValue(sellingPrice);
            this.quotationForm.controls.marginPercentage.setValue(prices.marginPercentage || 0);
            this.formatAndCalculateTotalQuotationLine();
        });
    }

    private isMarginExist(prices: Price) {
        return prices.marginPercentage > 0;
    }

    private computeSellingPrice(purchasePrice: number, margin: number): number {
        const sellingPrice = this.sparePartService.normalizeFloat((purchasePrice * (1 + (margin / 100))));
        return !this.isFreeSparePart(this.quotationForm.controls.supplier.value) ? sellingPrice : 0;
    }

    calculateMarge() {
        const newPrice = this.quotationForm.controls.price.value;
        const purchasePrice = this.quotationForm.controls.purchasePrice.value;
        const margin = this.getMargin(newPrice, purchasePrice);
        this.quotationForm.controls.marginPercentage.setValue(margin);
        this.formatAndCalculateTotalQuotationLine();
    }

    private getMargin(sellingPrice: number, purchasePrice: number): number {
        return purchasePrice > 0 ? this.sparePartService.normalizeFloat((((sellingPrice / purchasePrice) - 1) * 100)) : 0;
    }

    initSuppliers() {
        this.filteredSuppliers = this.getFilteredSuppliers('');
    }


    private getFilteredSuppliers(searchValue: string): Observable<SupplierDTO[]> {
        return this.sparePartService.findSuppliers(0, 30, {code: searchValue, name: searchValue, matchingMode: 'ANY'})
            .pipe(
                map(page => {
                    return page.content;
                })
            );
    }

    filterSupplier(event): void {
        const value = event.target.value;
        if (value && value.trim().length > 0) {
            this.filteredSuppliers = this.getFilteredSuppliers(value);
        }

    }

    supplierChanged(event): void {
        this.quotationForm.controls.supplier.setValue(event.option.value);
        this.initSuppliers();

    }

    displayFn(supplier: SupplierDTO): string {
        return supplier?.name;
    }

    get supplierFormControl(): FormControl {
        return this.quotationForm.controls['supplier'] as FormControl;
    }

    private supplierEditable() {
        this.supplierFormControl.enable();
    }

    private supplierNotEditable() {
        this.supplierFormControl.disable();
    }

    private isEmptyResult(filteredByCode: RelatedSparePartView[]) {
        return !filteredByCode || filteredByCode.length === 0;
    }

    displayMargin(): string {
        const margin = this.quotationForm.controls.marginPercentage.value;
        if (margin < 0) {
            this.quotationForm.controls.marginPercentage.markAsTouched();
            return '-';
        }

        return margin?.toString();
    }

    displayMarginInTable(line: DataSourceLine): number | string {
        const type = line.type;
        switch (type) {
            case TypeOfServiceEnum.SPARE_PART:
                return this.showMargin ? line.marginPercentage || 0 : '-';
            default:
                return '-';
        }
    }

    displayPurchasePriceInTable(line: DataSourceLine): number | string {
        const type = line.type;
        switch (type) {
            case TypeOfServiceEnum.SPARE_PART:
                return this.displayPurchasePrice ? line.purchasePrice : '-';
            default:
                return '-';
        }
    }
}
