import {createSelector} from '@ngrx/store';
import {AppState} from '../app.state';
import {CustomerState} from './customer.state';
import {CustomerApiInfo} from '../../models/customer/customer-api-info.model';
import {countryCode} from '../organization/organization.selectors';
import {CustomerUtils} from './customer.utils';

export const customerState = (state: AppState) => state.customer;

export const customerSearchResult = createSelector(
    customerState,
    (customer: CustomerState) => customer.customerSearchResult
);

export const isCustomerFormValid = createSelector(
    customerState,
    (customer: CustomerState) => customer.isCustomerFormValid
);

export const isProfessional = createSelector(
    customerState,
    (state: CustomerState) => state.isProfessional
);

export const isStockClaimCreation = createSelector(
    customerState,
    (state: CustomerState) => state.isStockClaimCreation
);

export const customer = createSelector(
    customerState,
    (state: CustomerState) => state.customer
);

export const mobilePhoneInput = createSelector(
    countryCode,
    customer,
    (countryCode, customer) => {
        return {
            countryCode: countryCode,
            mobileNumber: customer?.contact?.mobileNumber ?? ''
        };
    }
);

export const landlinePhoneInput = createSelector(
    countryCode,
    customer,
    (countryCode, customer) => {
        return {
            countryCode: countryCode,
            phoneNumber: customer?.contact?.phoneNumber ?? ''
        };
    }
);

export const customerReferenceExist = createSelector(
    customer,
    customer => !!customer ? !!customer.reference : false
);

export const customerAddress = createSelector(
    customer,
    customer => CustomerUtils.getDefaultAddress(customer.addresses)
);

export const isCreationWithoutCustomer = createSelector(
    customerState,
    (customer: CustomerState) => customer.isFolderCreationWithoutCustomer
);

export const addressInput = createSelector(
    isCreationWithoutCustomer,
    customerAddress,
    (isReadOnly, address) => isReadOnly ? null : address
);

export const customerApiInfo = createSelector(
    customerState,
    (customer: CustomerState) => customer.customerApiInfo
);

export const hasSearchApi = createSelector(
    customerApiInfo,
    (apiInfo: CustomerApiInfo) => apiInfo?.hasSearchApi
);

export const isSearchFormEnabled = createSelector(
    customerApiInfo,
    customer,
    (apiInfo, customer) => apiInfo?.searchableFields
        ?.map(field => CustomerUtils.hasValue(customer, field) && CustomerUtils.allRelatedFieldsHaveValues(customer, apiInfo?.relatedFields, field))
        .reduce((x, y) => x || y, false)
);
