import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FolderSubjectService} from "../../../../folder-subject.service";
import {Folder} from "../../../../../../models/folder.model";
import {Subscription} from "rxjs";
import {Shipment} from "../../../../../../models/shipment.model";
import {ShipmentType} from "../../../../../../models/enums/shipmentType.enum";
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-product-reception',
    templateUrl: './waiting-product-reception.component.html',
    styleUrls: ['./waiting-product-reception.component.css']
})
export class WaitingProductReceptionComponent implements OnInit, OnDestroy {
    folder: Folder;
    shipment: Shipment;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    waitProductReception: any = {
        form: null,
        placeholders: []
    };
    private folderSubscription: Subscription;

    constructor(private folderSubjectService: FolderSubjectService) {
    }

    ngOnInit(): void {
        this.initForm();
        this.folderSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
            this.getCustomerToRepairerShipment();
        });
    }

    private initForm(): void {
        this.waitProductReception.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            description: new FormControl(null)
        });


        this.waitProductReception.placeholders = [
            {
                status: 'PRODUCT_RECEIVED',
                value: 'BUTTON.PRODUCT_RECEIVED',
            },
            {
                status: 'CLOSE_FOLDER',
                value: 'BUTTON.CLOSE_FOLDER',
            }
        ];
    }

    onSubmit() {
        this.inputMap.emit({
            "closeFolder": this.waitProductReception.form.value.status === 'CLOSE_FOLDER',
            "closeFolderComment": this.waitProductReception.form.value.description
        });
    }

    ngOnDestroy(): void {
        this.folderSubscription.unsubscribe();
    }

    getCustomerToRepairerShipment() {
        if (this.folder.shipments) {
            let shipments = this.folder.shipments.filter(s => s.type === ShipmentType.CUSTOMER_TO_REPAIRER || s.type === ShipmentType.STORE_TO_REPAIRER);
            if (shipments && shipments.length > 0) {
                this.shipment = shipments[0];
            }
        }
    }
}
