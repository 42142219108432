<div class="container" >
    <div #slider
         ng2-carouselamos
         [items]="images"
         [$item]="itemTemplate"
         [$prev]="prevTemplate"
         [$next]="nextTemplate"
         (onSelectedItem)="selectedItem = $event.item; selectedIndex = $event.index"
    ></div>

    <ng-template #prevTemplate>
        <ng-container *ngIf=" !!images &&  images.length > 1">
            <button
                [ngClass]="'controls controls-hover'"><span class="material-icons"> navigate_before </span></button>
        </ng-container>
    </ng-template>

    <ng-template #nextTemplate>
        <ng-container *ngIf="!!images && images.length > 1">
            <button
                [ngClass]="'controls controls-hover'"><span class="material-icons"> navigate_next </span></button>
        </ng-container>
    </ng-template>


    <ng-template let-item let-i="index" #itemTemplate>
        <div class="slide-items">
            <ng-container *ngIf="isImage(item)" >
                <img [hidden]="i !== selectedIndex" src="{{ item.src }}"
                     (click)="showDetailsFiles(item.src)"
                     [ngClass]="images.length > 1 ?'img-element' : 'h-80 w-80' ">
                <img [hidden]="i === selectedIndex" src="{{ item.src }}"
                     (click)="showDetailsFiles(item.src)"
                     [ngClass]="images.length > 1 ?'img-element' : 'h-80 w-80'">
            </ng-container>

            <ng-container  *ngIf="isVideo(item)">
                <video [hidden]="(i !== selectedIndex)" src="{{ item.src }}"
                       [ngClass]="'img-element' " controls></video>

                <video [hidden]="(i === selectedIndex)" src="{{ item.src }}"
                       [ngClass]="images.length > 1 ?'img-element' : 'h-80 w-80' " controls></video>
            </ng-container>

            <ng-container  *ngIf="isPdf(item)">
                <mat-icon
                    class="img-element survey-icon font-size-60 w-60 h-60"
                    svgIcon="pdf_icon"
                ></mat-icon>
            </ng-container>
        </div>
    </ng-template>
</div>