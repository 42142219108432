<mat-form-field appearance="outline">
    <input
        [formControl]="numberFormControl"
        matInput
        ng2TelInput
        [ng2TelInputOptions]="{initialCountry: countryCode, preferredCountries: [countryCode]}"
        (hasError)="hasError($event)"
        (ng2TelOutput)="getNumber($event)"
        (intlTelInputObject)="telInputObject($event)"
        (countryChange)="onCountryChange($event)"/>
    <mat-hint>* {{ 'COMPONENT.SEARCH_PHONE_COUNTRIES.NUMBER' | translate }}</mat-hint>
</mat-form-field>