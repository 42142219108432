    <mat-card-content *ngIf="productAtStore">
        <form [formGroup]="productAtStore.form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-radio-group formControlName="choice" class="ml-20"
                                 fxLayout="column" fxLayoutAlign="start stretch"
                                 labelPosition="after" fxLayoutGap="10px">
                    <mat-radio-button class="full-width-radio" *ngFor="let status of productAtStore.placeholders"
                                      [value]="status.choice" [disabled]="status.disabled">
                        <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div fxFlex>
                                <div>{{ status.value | translate }}</div>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        [ngClass]="productAtStore.form.invalid?'aster_btn_disabled':'aster_btn'"
                        [disabled]="productAtStore.form.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>