    <app-user-task-header [instructionUserTask]="instructionUserTask"
                          [claim]="folder"></app-user-task-header>
    <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="start none" >
            <app-edit-management-site-modal [siteSearchWithoutModal]="true" [folderDetails]="folder"
                                            [fromSiteSearchzipCode]="zipCode" (siteSelectedEmitter)="selectSiteEvent($event)"></app-edit-management-site-modal>

            <div fxLayoutAlign="end center">
                <button mat-stroked-button
                        matTooltip="{{'BUTTON.VALIDATE' | translate}}" type="submit"
                        [disabled]="stopPropagation()"
                        [ngClass]=" stopPropagation() ? 'aster_btn_disabled' : 'aster_btn' "
                        (click)="completeTask()" class="h-60 mt-4" id="validateSearchSite">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </div>
    </mat-card-content>
