import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expertise-customer-call-back',
  templateUrl: './expertise-customer-call-back.component.html',
  styleUrls: ['./expertise-customer-call-back.component.scss']
})
export class ExpertiseCustomerCallBackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
