<h1 matDialogTitle>{{'CONFIRMATION.MODAL.DELETE_DIALOG.TITLE' | translate}}</h1>

<mat-dialog-content style="min-width: 400px; max-width: 500px">
    <div [innerHTML]="'COMPONENT.FOLDER_DETAILS_CARD.CHANGE_SUPPLIER_CONFIRMATION_MESSAGE' | translate"></div>
    <ng-container *ngIf="isAdminPlatana$ | async">
        <div class="mt-24">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-checkbox class="mt-24" [(ngModel)]="replayNotifications">
                    {{'BUTTON.REPLAY_NOTIFICATION' | translate}}
                </mat-checkbox>
                <mat-checkbox class="mt-24" [(ngModel)]="replayReports">
                    {{'BUTTON.REPLAY_REPORT' | translate}}
                </mat-checkbox>
            </div>
        </div>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="mt-24 mb-0">
    <div class="w-100-p delete-popup">
        <button mat-button class="aster_btn_cancel" (click)="closeDialog(false)">
            {{'BUTTON.CANCEL' | translate}}
        </button>
        <button mat-raised-button class="aster_btn" id="confirm_delete" (click)="closeDialog(true)">
            {{'BUTTON.CONFIRM' | translate}}
        </button>
    </div>
</mat-dialog-actions>
