import {Component, HostBinding, HostListener, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

import {fuseAnimations} from '@fuse/animations';
import {FuseConfigService} from '@fuse/services/config.service';
import {FuseNavigationService} from '../../navigation.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../app/store/app.state';
import {isRoleAuthorized} from '../../../../../app/store/user/user.selectors';
import {Unsubscriber} from '../../../../../app/unsubscriber';

@Component({
    selector: 'fuse-nav-horizontal-collapsable',
    templateUrl: './collapsable.component.html',
    styleUrls: ['./collapsable.component.scss'],
    animations: fuseAnimations
})
export class FuseNavHorizontalCollapsableComponent extends Unsubscriber implements OnInit {

    @HostBinding('class')
    classes = 'nav-collapsable nav-item';

    @Input() item: any;

    fuseConfig: any;
    isOpen = false;

    userHasAccess$: Observable<boolean>;

    constructor(private _fuseConfigService: FuseConfigService,
                private fuseNavigationService: FuseNavigationService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.userHasAccess$ = this.store$.pipe(select(isRoleAuthorized, {item: this.item}));
        this.anotherSubscription = this._fuseConfigService.config
            .subscribe(
                (config) => {
                    this.fuseConfig = config;
                }
            );
    }

    @HostListener('mouseenter')
    open(): void {
        this.isOpen = true;
    }

    @HostListener('mouseleave')
    close(): void {
        this.isOpen = false;
    }
}
