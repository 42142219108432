import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DatePipe} from '@angular/common';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {Folder} from '../../../../../models/folder.model';
import {Appointment} from '../../../../../models/appointment.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppointmentType} from '../../../../../models/enums/appointmentType.enum';
import * as moment from 'moment';
import {Moment} from 'moment';
import {RepairerCalendarService} from '../../../../../shared/services/appointment/repairer-calendar.service';
import {AddressType} from '../../../../../models/enums/addressType.enum';
import {AddAllDayAppointmentRequest} from '../../../../../models/AddAllDayAppointmentRequest.model';
import * as uuid from 'uuid';
import {Context} from '../../../../../models/enums/context.enum';
import {CalendarRdvService} from '../../../../../shared/generic/calendar-rdv/calendar-rdv.service';
import {DaysName} from '../../../../../shared/generic/calendar-rdv/daynames';
import {MatCalendarCellCssClasses} from '@angular/material/datepicker';
import * as momentFerie from 'moment-ferie-fr';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {currentUser} from '../../../../../store/user/user.selectors';
import {Unsubscriber} from '../../../../../unsubscriber';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-rdv-list',
    templateUrl: './wishes-appointment.component.html',
    styleUrls: ['./wishes-appointment.component.scss'],
    providers: [CalendarRdvService, DatePipe],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class WishesAppointmentComponent extends Unsubscriber implements OnInit {

    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    @Output() previewTask = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();

    folderId: string;
    folder: Folder;
    status = false;
    form: FormGroup;
    minDate = new Date();
    wishedDateAppoitment: Appointment;
    beginHour: string;
    endHour: string;
    availableDates: Map<number, number[]>;
    confirmedAppointmentDateFormatter = 'YYYY-MM-DD';
    currentBusinessLink: string;
    numberOfDays = 4;
    selectedAppointment = [];
    listElementsDays = [];
    listDaysHoliday = [];
    commentControl = new FormControl('');

    constructor(private route: ActivatedRoute,
                private repairerCalendarService: RepairerCalendarService,
                private store$: Store<AppState>,
                private snackBar: SnackBarService) {
        super();
    }

    ngOnInit(): void {
        this.folderId = this.route.snapshot.parent.params.folderId;
        this.initForm();
        this.wishedDateAppoitment = {
            day: '',
            beginHour: '',
            endHour: '',
            type: AppointmentType.WISHED_HOME_REPAIR_APPOINTMENT,
        };
        this.anotherSubscription = this.store$.pipe(select(currentUser)).subscribe(user => {
            this.currentBusinessLink = user.businessLink;
            this.listDaysHoliday = momentFerie().getFerieList(momentFerie().format('YYYY'));
            if (user.context === Context.PRODUCT_RECALL && user.businessLink === 'STORE_BANNE') {
                this.retreiveAvailableDates();
            } else {
                this.setElementsDays();
            }
        });

    }

    initForm(): void {
        this.form = new FormGroup({
            wishedDay: new FormControl(null, Validators.required),
            beginHourIntervention: new FormControl(null, Validators.required),
            endHourIntervention: new FormControl(null, Validators.required),
            comment: new FormControl(''),
        });

        this.selectOptionDate('MORNING');
    }

    onSubmit(): void {
        this.store$.dispatch(new StartLoading());

        if (this.commentControl.value.length > 0) {
            this.commentGiven.emit({value: this.commentControl.value});
        }

        const wishedDates: Appointment[] = [];
        const date = this.form.value.wishedDay.format(this.confirmedAppointmentDateFormatter);
        this.wishedDateAppoitment = {
            appointmentId: uuid.v4(),
            day: date,
            beginHour: this.beginHour + ':00',
            endHour: this.endHour + ':00',
            type: AppointmentType.WISHED_HOME_REPAIR_APPOINTMENT,
        };
        const wishedDate = moment(this.form.value.wishedDay);
        wishedDates.push(this.wishedDateAppoitment);


        const addRequest: AddAllDayAppointmentRequest = {
            dayOfMonth: wishedDate.date().toString(),
            month: (wishedDate.month() + 1).toString(),
            year: wishedDate.year().toString(),
            department: this.extractDepartmentCodeFromCustomerAddress()
        };
        this.repairerCalendarService.addAppointment(this.folder.repairCenter.code, this.folder.organization.code, addRequest).subscribe(() => {
            this.inputMap.emit({
                'wishedDatesWrapper': JSON.stringify({'wishedDates': wishedDates}),
            });
            this.store$.dispatch(new StopLoading());
        }, error => {
            this.snackBar.openWithIcon('APPOINTMENT.DATE_ALREADY_TAKEN', 'Error');
            this.store$.dispatch(new StopLoading());
        });
    }

    selectOptionDate(option): void {
        switch (option) {
            case 'MORNING': {
                this.beginHour = '08:00';
                this.endHour = '13:00';
                this.form.controls.beginHourIntervention.setValue('08:00');
                this.form.controls.endHourIntervention.setValue('13:00');
                this.form.controls.beginHourIntervention.disable();
                this.form.controls.endHourIntervention.disable();
                break;
            }

            case 'AFTERNOON': {
                this.beginHour = '14:00';
                this.endHour = '18:00';
                this.form.controls.beginHourIntervention.setValue('14:00');
                this.form.controls.endHourIntervention.setValue('18:00');
                this.form.controls.beginHourIntervention.disable();
                this.form.controls.endHourIntervention.disable();
                break;
            }
            case 'OTHER_DATE': {
                this.form.controls.beginHourIntervention.setValue(null);
                this.form.controls.endHourIntervention.setValue(null);
                this.form.controls.beginHourIntervention.enable();
                this.form.controls.endHourIntervention.enable();
                break;
            }
        }
    }

    getPreviewTask(): void {
        this.previewTask.emit({
            'currentTask': 'WISHES_APPOINTMENT',
            'previewsTask': 'EDIT_INTERVENTION_ADDRESS'
        });
    }

    private retreiveAvailableDates(): void {
        this.store$.dispatch(new StartLoading());
        this.repairerCalendarService.getAvailableAppointment(this.folder.repairCenter.code,
            this.folder.organization.code,
            this.extractDepartmentCodeFromCustomerAddress())
            .subscribe((dates: Map<number, number[]>) => {
                this.availableDates = dates;
                this.store$.dispatch(new StopLoading());
            }, error => {
                this.store$.dispatch(new StopLoading());
            });
    }

    filterDate = (date: Moment): boolean => {
        const existIntoHoliday = this.listDaysHoliday.filter(holiday => holiday.date.toDate().getMonth() === date.month()
            && holiday.date.toDate().getDate() === date.date()).length > 0;

        return !!this.availableDates
            && !!this.availableDates[date.month() + 1]
            && this.availableDates[date.month() + 1].includes(date.date())
            && !existIntoHoliday;
    };

    private extractDepartmentCodeFromCustomerAddress(): string {
        return this.folder.customer.addresses.filter(adr => adr.type === AddressType.INTERVENTION)[0].zipCode.slice(0, 2);
    }

    getResultElementSelected($event: any): void {
        this.selectedAppointment = $event;
    }

    setElementsDays(): void {
        let dayElements = [];
        for (const dayName in DaysName) {
            dayElements = [];
            dayElements.push({beginHour: '08h00', endHour: '13h00'});
            dayElements.push({beginHour: '13h00', endHour: '18h00'});
            this.listElementsDays.push({'day': dayName, 'dayElements': dayElements});
        }
    }


    validateAppointment(): void {
        if (this.commentControl.value.length > 0) {
            this.commentGiven.emit({value: this.commentControl.value});
        }

        const wishedDates: Appointment[] = [];
        this.selectedAppointment.forEach(appointment => {
            const date = appointment.date.format(this.confirmedAppointmentDateFormatter);
            const beginHour = appointment.elementSelected.beginHour.slice(0, 2);
            const endHour = appointment.elementSelected.endHour.slice(0, 2);
            this.wishedDateAppoitment = {
                day: date,
                beginHour: beginHour + ':00:00',
                endHour: endHour + ':00:00',
                type: AppointmentType.WISHED_HOME_REPAIR_APPOINTMENT
            };
            wishedDates.push(this.wishedDateAppoitment);
        });

        this.inputMap.emit({
            'wishedDatesWrapper': JSON.stringify({'wishedDates': wishedDates})
        });
    }

    // TODO, use MatCalendarCellClassFunction in Angular version 10
    highlighter = (cellDate): MatCalendarCellCssClasses => {
        return this.isDateAvailable(cellDate) ? 'available' : '';
    };

    isDateAvailable(date: any): boolean {
        return this.filterDate(date);
    }

}

