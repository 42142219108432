import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {currency} from '../../../../store/organization/organization.selectors';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-product-editable-field',
    templateUrl: './product-editable-field.component.html'
})
export class ProductEditableFieldComponent implements OnInit {
    @Output() save = new EventEmitter();
    @Input() type: string;
    @Input() label: string;
    @Input() value: any;
    @Input() invalidMessage: string;
    @Input() isEditable = true;
    @Input() isPrice: boolean;
    @Input() minValue?: number;
    @Input() maxValue?: number;
    @Input() editMode = false;
    @Input() isPriceNull = false;

    valueCtrl: FormControl;
    updatedValue: any;
    currency$: Observable<string>;

    constructor(private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.currency$ = this.store$.pipe(select(currency));
        this.updatedValue = this.value;
        const validators = [Validators.required];
        if (this.maxValue) {
            validators.push(Validators.max(this.maxValue));
        }
        this.valueCtrl = new FormControl('', validators);
        if (this.editMode) {
            this.valueCtrl.markAsTouched();
        }
    }

    switchEditMode(): void {
        this.editMode = !this.editMode;
    }

    updateValue(): void {
        this.switchEditMode();
        this.value = this.updatedValue;
        this.save.emit(this.updatedValue);
    }

    cancel(): void {
        this.switchEditMode();
        this.updatedValue = this.value;
    }

    canValidate = () => {
        if (this.maxValue) {
            return this.updatedValue && this.updatedValue <= this.maxValue;
        }
        return this.updatedValue;
    }
}
