import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ShippingEventType} from '../../../../models/enums/shipmentEventType.enum';
import {FolderPublic} from '../../../../models/folder.public.model';

@Component({
    selector: 'app-repairer-+shipment-in-progress',
    templateUrl: './shipment-in-progress.component.html',
    styleUrls: ['./shipment-in-progress.component.scss']
})
export class ShipmentInProgressComponent implements OnInit {
    shipmentInProgress = false;
    @Input() folder: FolderPublic;
    @Output() inputMap = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        if (!this.shipmentInProgress) {
            return;
        }
        this.inputMap.emit({
            'forcedShippingStatus': ShippingEventType.SHIPMENT_DELIVERED.toString(),
        });
    }


}
