<div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxLayoutAlign="start">
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>
        </div>

        <form *ngIf="customerDetailForm.form" [formGroup]="customerDetailForm.form"
              (ngSubmit)="completeTask()" autocomplete="off" id="form-edit">


            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" class="mt-32">

                <mat-form-field class="py-4" appearance="outline" fxFlex="20" fxFlex.lt-md="100">
                    <mat-label>{{'CUSTOMER.CIVILITY'|translate}}</mat-label>
                    <mat-select formControlName="gender">
                        <mat-option *ngFor="let civility of civilitySelectBoxOptions"
                                    [value]="civility.key">
                            <span>{{ civility.label | translate}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="py-4" appearance="outline" fxFlex="40" fxFlex.lt-md="100">
                    <mat-label>{{'CUSTOMER.FIRST_NAME'|translate}}</mat-label>
                    <input matInput placeholder="{{'CUSTOMER.FIRST_NAME'|translate}}"
                           formControlName="firstName">
                </mat-form-field>

                <mat-form-field class="py-4" appearance="outline" fxFlex="40" fxFlex.lt-md="100">
                    <mat-label>{{'CUSTOMER.LAST_NAME'|translate}}</mat-label>
                    <input matInput placeholder="{{'CUSTOMER.LAST_NAME'|translate}}"
                           formControlName="lastName">
                </mat-form-field>
            </div>


            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8">
                <mobile-phone-input-form *ngIf="!!mobilePhoneOutput" fxFlex="100"
                                         [isRequiredFields] = "false"
                                         [mobilePhoneInput]="mobilePhoneOutput"></mobile-phone-input-form>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                    <mat-label>{{'CUSTOMER.EMAIL'|translate}}</mat-label>
                    <input matInput placeholder="{{'CUSTOMER.EMAIL'|translate}}" formControlName="email"
                           type="email" isEmail>
                </mat-form-field>
            </div>

            <div fxLayoutAlign="end center" *ngIf="folderHasModOp()">
                <button mat-stroked-button type="submit" class="h-60 mt-4 pl-24 pr-24"
                        [ngClass]="customerDetailForm.form.invalid?'aster_btn_disabled':'aster_btn'"
                        [disabled]="customerDetailForm.form.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>

        </form>

</div>
