    <div fxLayout="column" fxLayoutAlign="start">
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
    </div>

    <ng-container *ngIf="chooseDeliveryDestination">
        <mat-radio-group class="ml-20" fxLayout="row" fxLayoutAlign="space-between center"
                         labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
            <mat-radio-button class="full-width-radio" *ngFor="let choice of placeholders"
                              (change)="choiceChange($event)"
                              [value]="choice.value">
                <div class="my-4" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                    <div fxFlex>
                        <div>{{ choice.value | translate }}</div>
                    </div>
                </div>
            </mat-radio-button>
        </mat-radio-group>

    </ng-container>

    <mat-card-content *ngIf="showAddressForm" [formGroup]="addressForm">
        <app-generic-address-form [addressesInput]=defaultAddress></app-generic-address-form>

        <div fxLayoutAlign="end center">
            <button type="submit" mat-stroked-button
                    [ngClass]="isValidForm() ? 'aster_btn' : 'aster_btn_disabled'"
                    [disabled]="!isValidForm()"
                    (click)="completeTask()">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>
    </mat-card-content>