<div tabindex="0" class="e-card">
    <div class="e-card-header">
        <div class="e-card-header-caption">
            <div class="e-card-header-title">{{ contact?.name }}</div>
        </div>
    </div>
    <div class="e-card-content">
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon>location_city</mat-icon>
            <div>
                {{ !!(contact?.address) ? contact?.address?.address + ' ' + (!!(contact?.address?.secondAddress) ? (', ' + contact?.address?.secondAddress) : '') : '-' }}
                {{ contact?.address?.city }} -{{ contact?.address?.zipCode }}
                , {{ 'COUNTRY.NAME.' + contact?.address?.countryCode | translate }}
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8" fxFlex="45%">
                <mat-icon>phone_in_talk</mat-icon>
                <div class="phone-info">
                    {{ contact?.phone }}
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8" fxFlex="55%">
                <mat-icon>email</mat-icon>
                <div class="email-info">
                    {{ contact?.email }}
                </div>
            </div>
        </div>
    </div>
</div>