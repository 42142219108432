import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Site} from '../../../../models/site.model';

@Component({
    selector: 'app-send-to-external-system-modal',
    templateUrl: './send-to-external-system-modal.component.html',
    styleUrls: ['./send-to-external-system-modal.component.css']
})
export class SendToExternalSystemModalComponent implements OnInit {
    event: string;
    systemName: string;

    constructor(public dialogRef: MatDialogRef<SendToExternalSystemModalComponent>) {
    }

    ngOnInit(): void {
    }

    validate() {
        if (this.event) {
            this.dialogRef.close(this.event);
        }
    }
}
