<div class="text-center">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
        <h2>
            <mat-icon>class</mat-icon>
            {{'SWITCH.OPERATING.MODE.TITLE' | translate}}
        </h2>
        <button mat-icon-button class="ml-40" (click)="onCloseModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <div class="w-100-p mb-20" fxLayout="row" fxLayoutAlign="start start">
            {{'SWITCH.OPERATING.MODE.DESCRIPTION' | translate}}
        </div>
        <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start" >
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field appearance="outline" fxFlex="45">
                    <mat-label>{{'SWITCH.OPERATING.MODE.MODOP.LIST' | translate}}</mat-label>
                    <mat-select [id]="'operating_mode'" formControlName="operatingMode" required>
                        <mat-select-trigger>
                            <mat-icon class="s-20"
                                      [svgIcon]="getIconName(form.controls['operatingMode'].value)">
                            </mat-icon>
                            {{form.controls['operatingMode'].value?.code | codeToLabel  :'SAV.CONFIG.MODE_OP' | async}}
                        </mat-select-trigger>

                        <mat-option [id]="procedure.code" *ngFor="let procedure of data.operatingModeList" [value]="procedure">
                            <mat-icon class="s-20"
                                      [svgIcon]="getIconName(procedure)">
                            </mat-icon>
                            {{procedure.code | codeToLabel  :'SAV.CONFIG.MODE_OP' | async}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="45">
                    <mat-label>{{'SWITCH.OPERATING.MODE.REASON.CHANGE' | translate}}</mat-label>
                    <mat-select [id]="'reason'" (selectionChange)="changeCommentValidator($event.value)"
                                formControlName="reason" required>
                        <mat-option [id]="reason" *ngFor="let reason of reasonsChange" [value]="reason">
                            {{reason | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                <mat-label>{{'SWITCH.OPERATING.MODE.COMMENT' | translate}}</mat-label>
                <textarea matInput rows="4" cols="7"
                          [placeholder]="'SWITCH.OPERATING.MODE.COMMENT' | translate"
                          formControlName="comment"></textarea>
            </mat-form-field>
        </form>


    </mat-dialog-content>

    <mat-dialog-actions class="mt-16">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="end end">
                <button mat-button (click)="onClose()" class="aster-modal-btn_cancel" type="reset">
                    {{'BUTTON.CLOSE' | translate}}
                </button>
                <button type="submit" mat-stroked-button (click)="validateForm()"
                        [disabled]="form.invalid"
                        [ngClass]="form.invalid? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
    </mat-dialog-actions>
</div>
