    <mat-card-content>
        <form [formGroup]="selectionForm.form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <mat-card-title class="h4 mt-12 mb-24">{{'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.HEADER' | translate}}
                {{folder.appointment?.confirmedAppointment?.day | moment: ('GENERAL.DATE_FORMAT_DAY' | translate) : (organizationLocal$ | async) }}
                (
                {{folder.appointment?.confirmedAppointment?.beginHour }}
                &nbsp; - &nbsp;
                {{folder.appointment?.confirmedAppointment?.endHour }}
                )
            </mat-card-title>

            <mat-radio-group formControlName="choice" class="ml-20" fxLayout="column" fxLayoutAlign="start stretch"
                             labelPosition="after" fxLayoutGap="10px">
                <mat-radio-button class="full-width-radio" *ngFor="let choice of selectionForm.placeholders"
                                  [value]="choice.choice">
                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                        <div fxFlex>
                            <div>{{ choice.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>

            <div  fxLayout="row" fxLayoutAlign="space-between center">
                <button type="button" mat-stroked-button  (click)="startIntervention()"
                        class="h-60 mt-4 mr-8 aster_btn_cancel">
                    {{'FOLDER.WORKFLOW.START.INTERVENTION' | translate}}
                </button>
                <div fxLayoutAlign="end center" fxLayoutGap="10px">
                    <button type="button" mat-stroked-button  (click)="cancelAppointment()"
                            class="h-60 mt-4 mr-8 aster_btn_cancel">
                        {{'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.CANCEL' | translate}}
                    </button>
                    <button type="submit" mat-stroked-button [disabled]="selectionForm.form.invalid"
                            [ngClass]="selectionForm.form.invalid ? 'h-60 mt-4 mr-8 aster_btn_disabled' : 'h-60 mt-4 mr-8 aster_btn'">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </div>

        </form>
    </mat-card-content>