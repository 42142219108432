<h1 mat-dialog-title>  {{'FOLDER.WORKFLOW.CANCEL_INTERVENTION' | translate}}</h1>
<div mat-dialog-content>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

        <form [formGroup]="registerForm" class="w-100-p">
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>{{'FOLDER.WORKFLOW.CANCELLATION_DESCRIPTION' | translate}}</mat-label>
                <textarea matInput formControlName="cancelIntervention" required></textarea>
                <mat-error>{{'CUSTOMER.REQUIRED_FIELD' | translate}}</mat-error>
            </mat-form-field>
        </form>
    </div>

</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="null"
            class="h-44 mt-4 ml-8 aster_btn_cancel">{{'BUTTON.CLOSE' | translate}}</button>
    <button mat-button [mat-dialog-close]="data" [disabled]="registerForm.invalid"
            class="h-44 mt-4 ml-8  "
            [ngClass]="registerForm.invalid?'aster_btn_disabled' : 'aster_btn'"
            cdkFocusInitial>{{'BUTTON.VALIDATE' | translate}}</button>
</div>
