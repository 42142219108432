    <mat-card-content *ngIf="!!folder">
        <form *ngIf="customerAddressForm.form" [formGroup]="customerAddressForm.form" fxLayout="column"
              fxLayoutAlign="start" (ngSubmit)="onSubmit()" autocomplete="off" id="form1">

            <div fxLayout="column" fxLayoutAlign="start">
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>

            <div fxLayout="row wrap" gdColumns="repeat(auto-fit, minmax(100%, 1fr))" gdGap="10px"
                 formGroupName="countryCode"
            class="mt-25">
                <app-generic-address-form [addressesInput]="customerAddress"></app-generic-address-form>
            </div>

            <div fxLayoutAlign="end center" fxLayoutGap="10px">
                <ng-container *ngIf="!!folder.customer">

                    <button mat-stroked-button (click)="reset()" type="button"
                            class="aster_btn_cancel">
                        {{'BUTTON.CANCEL' | translate}}
                    </button>
                    <button mat-stroked-button type="submit"
                            [disabled]="isFormDisabled()"
                            [ngClass]="(isFormDisabled()?'aster_btn_disabled':' aster_btn')">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </ng-container>
            </div>
        </form>

    </mat-card-content>
