<div fxLayout="row" fxLayoutGap="15px" *ngIf="product.code !== this.UNKNOWN_PRODUCT_CODE">
    <strong> {{'PRODUCT.PREVIEW_CARD.LABELS.CODE' | translate}}:</strong>
    <span class="item-info" (click)="showProductDetail()" > {{product.code}} </span>
</div>

<app-product-editable-field
    label="PRODUCT.PREVIEW_CARD.LABELS.LABEL"
    type="text"
    [value]="product.label"
    (save)="updateLabel($event)">
</app-product-editable-field>

<app-product-supplier-field *ngIf="product.code !== this.UNKNOWN_PRODUCT_CODE"
    [supplier]="product.supplier"
    [isEditable]="true"
    (save)="updateSupplier($event)">
</app-product-supplier-field>

<app-product-editable-field
    label="PRODUCT.PREVIEW_CARD.LABELS.PURCHASE_PRICE"
    type="number"
    isPrice="true"
    minValue="0"
    [value]="product['purchasePrice']?.value"
    [isPriceNull]="!product['purchasePrice']?.value || product['purchasePrice']?.value === 0"
    (save)="updatePrice($event)">
</app-product-editable-field>
