import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RepairStatusType} from '../../../../../models/enums/repairStatusType.enum';
import {ShipmentType} from '../../../../../models/enums/shipmentType.enum';
import {ShipmentItemType} from '../../../../../models/enums/shipmentItemType.enum';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-repaired-product-control',
    templateUrl: './product-reception-and-control.component.html',
    styleUrls: ['./product-reception-and-control.component.scss']
})
export class ProductReceptionAndControlComponent implements OnInit {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();
    folderId: string;
    accessoriesLabel: string[] = [];

    validateReparation: any = {
        form: null,
        placeholders: []
    };
    private reparationStatus: string;

    constructor(private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.initForm();
        this.folderId = this.route.snapshot.parent.params.folderId;
        const shipmentItems = this.folder.shipments.find(shipment => shipment.type.endsWith('TO_REPAIRER') || shipment.type === ShipmentType.PICKING);
        if (shipmentItems.shipmentItems?.length > 0){
           shipmentItems.shipmentItems.filter(shipmentItem => shipmentItem.type === ShipmentItemType.SECONDARY)
                .forEach(it => this.accessoriesLabel.push(it.label));
        }
    }

    private initForm(): void {
        this.validateReparation.form = new FormGroup({
            choice: new FormControl(null, Validators.required),
            productReceived: new FormControl(null)
        });
        if (!this.isProductNotRepaired()) {
            this.validateReparation.placeholders = [
                {
                    choice: 'OK',
                    value: 'BUTTON.REPARATION_OK',
                },
                {
                    choice: 'UNABLE',
                    value: 'BUTTON.UNABLE_TO_TEST_PRODUCT',
                },
                {
                    choice: 'KO',
                    value: 'BUTTON.REPARATION_KO',
                }
            ];
        }
    }

    isProductNotRepaired(): boolean {
        this.reparationStatus = this.variablesTask['reparationStatus'];
        return this.reparationStatus === RepairStatusType.NOT_REPAIRABLE
            || this.reparationStatus === 'REFUSE_QUOTATION_WITH_RESTITUTION'
            || this.reparationStatus === 'RESPECT_CUSTOMER_CHOICE_NOT_TO_REPAIR'
            || this.reparationStatus === 'DO_NOTHING';
    }

    onSubmit(): void {
        if (this.isFormInvalid()) {
            return;
        }
        if (!this.isProductNotRepaired()) {
            this.inputMap.emit({'repairedProductControlKO': this.validateReparation.form.value.choice === 'KO'});
        } else {
            this.inputMap.emit({'repairedProductControlKO': false});
        }
    }

    isFormInvalid(): boolean {
        return !this.validateReparation.form.value.choice && !this.validateReparation.form.value.productReceived;
    }
}
