export class Scopes {
    static readonly FOLDER_TO_EXTERNAL_SYSTEM = 'folder.to.external.system';
    static readonly FOLDER_TO_REPAIRER = 'folder.to.repairer';
    static readonly ROLLBACK_FOLDER_STATUS = 'folder.rollback';
    static readonly FOLDER_UPDATE_SUPPLIER = 'folder.supplier';
    static readonly ANONYMIZE_CUSTOMER_DETAILS = 'folder.anonymize';
    static readonly FOLDER_REOPEN = 'folder.reopen';
    static readonly CLOSE_FOLDER = 'close.folder';
    static readonly CREATE_FOLDER = 'create.folder';
    static readonly PAYMENT_REFUND = 'payment.refund';
    static readonly CLIENT_INFO_ADMINISTRATION = 'client.info.administration';
    static readonly NOTIFICATIONS = 'notifications';
    static readonly FOLDER_PUBLIC_REPAIRER = 'folder.public.repairer';
    static readonly FOLDER_PUBLIC_SUPPLIER = 'folder.public.supplier';

}
