<div *ngIf="productList.totalElements > 1 && !product">
    <div *ngIf="!isFilteredResult">
        <app-product-form-child-table (product)="changeProduct($event)"
                                      [productsFirstPage]="productList"
                                      [invoiceId]="invoiceId"> </app-product-form-child-table>
    </div>
    <div *ngIf="isFilteredResult">
        <app-product-form-syncfusion-table
            (product)="changeProduct($event)"
            [products]="products"
            [user]="user"
            [pageSize]="this.pageSize"
            [page]="page">
        </app-product-form-syncfusion-table>
    </div>

</div>

<div *ngIf="product || productList.totalElements == 1">
    <div *ngIf="productList && productList.totalElements > 1" fxLayoutAlign="center center">
        <div class="back-btn" matTooltip="{{'FOLDER.CREATE.BACK_PRODUCT_BUTTON' | translate}}"
           (click)="backToProductList()">
          {{'FOLDER.CREATE.BACK_PRODUCT_BUTTON' | translate}}
        </div>

    </div>

    <div>
        <app-product-preview-card-all-details [product]="product"
                                              [purchaseDate]="purchaseDate"
                                              [isFromInvoice] = "!!invoiceId"
                                              [isEditableQuantity]="true"
        ></app-product-preview-card-all-details>
    </div>
</div>

<div *ngIf="productNotFound">
    <span class="product-not-found">{{"PRODUCT.NO_ITEM_CARD.DEFAULT_TEXT"|translate}}</span>
</div>