import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {FolderCreationService} from '../../folder-creation.service';
import {Product} from '../../../../../models/product.model';
import {RowSelectEventArgs} from '@syncfusion/ej2-grids';
import {UserState} from '../../../../../store/user/user.state';
import {RuleEvaluationContext} from '../../../../../models/rules/RuleEvaluationContext';
import {BackOfficeService} from '../../../../../shared/services/back-office.service';

@Component({
    selector: 'app-product-form-syncfusion-table',
    templateUrl: './product-form-syncfusion-table.component.html',
    styleUrls: ['./product-form-syncfusion-table.component.scss']
})
export class ProductFormSyncfusionTableComponent implements OnInit , OnDestroy{

    @Output() product = new EventEmitter<Product>();
    @Input() products: Product[];
    @Input() user: UserState;
    @Input() pageSize: number;
    @Input() page: number;
    fieldsToUseInSearch: string[] =
        ['code', 'label', 'codeEAN',  'manufacturerReference', 'brand',  'families.0.label'];
    toolbar = [{text: 'Search', align: 'Center'}, 'ColumnChooser'] as any;

    filterValue  = '';


    constructor(public folderCreationServices: FolderCreationService,
                private backOfficeService: BackOfficeService) {

    }
    ngOnInit() {
        this.filterValue = this.folderCreationServices.filterValue;
    }

    public rowSelected(args: RowSelectEventArgs): void {
        const selectedProduct = args.data as Product;
        if (!selectedProduct.afterSalesEligible) {
            this.emitProduct(selectedProduct);
        } else {
            const evaluationContext = RuleEvaluationContext.fromProduct(selectedProduct, this.user);
            this.backOfficeService.getEligibilities(evaluationContext).subscribe(eligibility => {
                selectedProduct.afterSalesEligible = eligibility;
                this.emitProduct(selectedProduct);
            });
        }
    }

    private emitProduct(selectedProduct: Product) {
        this.product.emit(selectedProduct);
        this.folderCreationServices.productChanged(selectedProduct);
    }

    handle($event: any) {
        if ($event.requestType === 'searching') {
            this.filterValue = $event.searchString;
            this.folderCreationServices.filterValue = this.filterValue;
        }
        if ($event.requestType === 'paging'){
            if ($event.currentPage > $event.previousPage && $event.currentPage >= this.page - 1) {
                this.folderCreationServices.productFilterPageChanged$.next($event.currentPage);
            }
        }
    }

    ngOnDestroy() {
        this.folderCreationServices.productFilterPageChanged$.next(null);
    }
}
