import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-folder-not-found',
    templateUrl: './folder-not-found.component.html',
    styleUrls: ['./folder-not-found.component.scss']
})
export class FolderNotFoundComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
