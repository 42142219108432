import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {SparePartService} from '../../../../shared/services/spare-part.service';
import {affectedSite, isLeroyMerlin} from '../../../../store/user/user.selectors';
import {finalize, take, withLatestFrom} from 'rxjs/operators';
import {RelatedSparePart, RelatedSparePartRequest} from '../../../../models/spare-parts/relatedSparePart.model';
import {SparePartStock, StockDetails} from '../../../../models/spare-parts/stock.model';
import {TranslateService} from '@ngx-translate/core';
import {GLOBAL} from '../../../../app-config';
import {MultiImageModalComponent} from '../../../multi-image-modal/multi-image-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {FileService} from '../../../../shared/services/file.service';
import {GridComponent, PageEventArgs} from '@syncfusion/ej2-angular-grids';
import {Observable, Subscription} from 'rxjs';
import {StopLoading} from '../../../../store/loader/loader.actions';
import {AmountUtils} from '../../../../shared/utils/amount-utils';
import {margin, vats} from '../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../unsubscriber';
import {DatePipe} from '@angular/common';
import {RuleEvaluationContext} from '../../../../models/rules/RuleEvaluationContext';
import {ProductFamilyType} from '../../../../models/enums/productFamilyType.enum';
import {Product} from '../../../../models/product.model';
import {BackOfficeService} from '../../../../shared/services/back-office.service';

@Component({
    selector: 'app-product-spare-parts',
    templateUrl: './product-spare-parts.component.html',
    styleUrls: ['./product-spare-parts.component.scss']
})
export class ProductSparePartsComponent extends Unsubscriber implements OnInit, OnDestroy {

    @Input() product: Product;
    @Input() managementSiteCode: string;
    @ViewChild('grid') public grid: GridComponent;

    spareParts: RelatedSparePart[];
    isStockLoading = true;
    isSparePartLoading = true;
    firstRefreshHandled = false;
    stockSubscription: Subscription;
    tva: string;
    sellingPriceSubscription: Subscription;
    defaultMargin = 0;
    constructor(private store$: Store<AppState>,
                private sparePartService: SparePartService,
                private matDialog: MatDialog,
                private backOfficeService: BackOfficeService,
                private translateService: TranslateService,
                private fileService: FileService,
                private datePipe: DatePipe) {
        super();
    }

    ngOnInit() {
        this.store$.pipe(
            select(isLeroyMerlin),
            withLatestFrom(this.store$.select(affectedSite)),
            take(1)
        ).subscribe(([isLeroyMerlin]) => {
            const request = this.buildRelatedSparePartRequest(isLeroyMerlin);
            this.sparePartService.getRelatedSpareParts(request)
                .pipe(
                    take(1),
                    finalize(() => this.isSparePartLoading = false)
                ).subscribe(spareParts => {
                this.spareParts = this.sparePartService.splitSpareParts(spareParts);
            });
        });
        this.anotherSubscription = this.store$.pipe(select(margin)).subscribe(value => { this.defaultMargin = value; });
        this.anotherSubscription = this.store$.pipe(select(vats)).subscribe(vats => {
            this.tva = vats.find(value => value.standard === true).rate;
        });
    }

    private buildRelatedSparePartRequest(isLeroyMerlin: boolean): RelatedSparePartRequest {
        return {
            productCode: this.product.code,
            supplierCode: this.product.supplier?.code,
            eanCode: this.product.codeEAN,
            brand: this.product.brand,
            family1: RuleEvaluationContext.getFamilyByType(this.product.families, ProductFamilyType.FAMILY),
            withMedia: isLeroyMerlin,
        };
    }

    ngOnDestroy() {
        this.stockSubscription.unsubscribe();
    }

    onChangeDataByPage(args: PageEventArgs) {
        if ((args.requestType === 'refresh' && !this.firstRefreshHandled) || (args.requestType === 'paging')) {
            this.firstRefreshHandled = true;
            this.fetchStocksByPage();
            this.fetchSellingPriceOfDisplayedSpareParts();
            this.fetchImagesOfDisplayedSpareParts();
        }
    }

    private fetchSellingPriceOfDisplayedSpareParts() {
        const displayedData: any[] = this.grid.getCurrentViewRecords();
        if (!!this.sellingPriceSubscription) {
            this.sellingPriceSubscription.unsubscribe();
        }
        displayedData.forEach(sparePart => {
            this.getSellingPrice(sparePart);
        });
    }

    private fetchImagesOfDisplayedSpareParts() {
        const displayedData: any[] = this.grid.getCurrentViewRecords();
        displayedData.forEach(sparePart => {
            this.getImage(sparePart);
        });
    }

    fetchStocksByPage() {
        if (!!this.stockSubscription) {
            this.stockSubscription.unsubscribe();
        }
        this.isStockLoading = true;
        const displayedData: any[] = this.grid.getCurrentViewRecords();
        const sparePartCodes = displayedData.map(data => data.code.trim());
        this.stockSubscription = this.sparePartService.getStocks(sparePartCodes, this.managementSiteCode)
            .subscribe(stocks => {
                    this.merge(displayedData, stocks);
                    this.grid.refreshColumns();
                    this.isStockLoading = false;
                },
                () => {
                    this.isStockLoading = false;
                },
                () => {
                    this.store$.dispatch(new StopLoading());
                    this.isStockLoading = false;
                });
    }

    private getSellingPrice(element) {
        const purchasePrice = element.supplier?.unitPrice?.value;
        const currency = element.supplier?.unitPrice?.currency;
        if (typeof purchasePrice === 'number' && purchasePrice > 0) {
            element.isLoding = true;
            const approximatePrice = !!element.approximatePrice.value ? element.approximatePrice.value : 0;
            this.sellingPriceSubscription = this.backOfficeService.computeSellingPrice(purchasePrice, this.defaultMargin, approximatePrice)
                .subscribe(data => {
                        element.sellingPrice = {
                            value: data.sellingPrice || 0,
                            currency: currency
                        };
                        element.marginPercentage = data.marginPercentage || 0;
                        element.purchasePrice = purchasePrice || 0;
                        element.isLoding = false;
                    },
                    () => {
                        element.isLoding = false;
                    },
                    () => {
                        this.store$.dispatch(new StopLoading());
                        element.isLoding = false;
                        this.grid.refreshColumns();
                        this.grid.refresh();
                    });
        } else {
            element.sellingPrice = element.approximatePrice || {value: 0};
            element.isLoding = false;
        }
    }

    displayStockStatus(status: string): string {
        if (!status) {
            return 'TEXT.UNDEFINED';
        }
        if ('UNKNOWN' === status) {
            return 'TEXT.UNKNOWN';
        }

        if (status === 'AVAILABLE') {
            return 'TEXT.AVAILABLE';
        }

        if (status === 'NOT_AVAILABLE') {
            return 'TEXT.UNAVAILABLE';
        }
    }

    getStockIndicatorClass(status: string): Observable<string> {
        return new Observable<string>(observer => {
            switch (status) {
                case 'AVAILABLE':
                    observer.next('green-color');
                    break;
                case 'NOT_AVAILABLE':
                    observer.next('red-color');
                    break;
                default:
                    observer.next('black-color');
            }
            observer.complete();
        });
    }

    stockDetailsTooltip(stocks: StockDetails[], totalStock: string): string {
        let tooltipMessage = '';
        if (totalStock) {
            tooltipMessage = this.translateService.instant('TOOLTIP.STOCK.DETAILS.STOCK', {stock: totalStock});

        } else {
            const stockDetails = stocks
                .map(stock => this.translateService.instant('TOOLTIP.STOCK.DETAILS',
                    {
                        siteCode: stock?.siteCode,
                        siteLabel: stock?.siteLabel,
                        deliveryDate: this.datePipe.transform(stock?.deliveryDate, 'dd-MM-yyyy')
                    }));

            stockDetails.map(stockDetails => tooltipMessage += stockDetails + '\n');
        }
        return tooltipMessage;
    }

    private merge(spareParts: RelatedSparePart[], stocks: SparePartStock[]): void {
        const stocksByCode = new Map(stocks.map(stock => [stock.sparePartCode, stock]));
        if (!!this.sellingPriceSubscription) {
            this.sellingPriceSubscription.unsubscribe();
        }
        spareParts.forEach(sparePart => {
            sparePart.stock = stocksByCode.get(sparePart.code);
            this.getImage(sparePart);
            this.getSellingPrice(sparePart);
        });
    }

    onImageError(event: ErrorEvent, image: any) {
        image.error = true;
        (event.target as HTMLImageElement).src = GLOBAL.sparePartsPlaceHolder;
    }

    onLoad(image: any) {
        image.isLoading = false;
        image.error = false;
    }

    openMultiImageModal(event: any, images: any): void {
        if (!images[0]?.error === true) {
            this.matDialog.open(MultiImageModalComponent, {
                height: 'auto',
                width: '100vh',
                minWidth: '800px',
                data: images
            });
        }
    }

    private getImage(sparePart) {
        const localImages = sparePart?.images?.filter(image => !!image.id);
        const remoteImages = sparePart?.images?.filter(image => !!image.fullUrl);

        if (localImages?.length > 0) {

            this.fileService.getAttachmentFile(localImages[0].id).subscribe(imageAsBlob => {
                sparePart.images[0].isLoading = true;
                const reader = new FileReader();
                reader.readAsDataURL(imageAsBlob);
                reader.onloadend = () => {
                    sparePart.images[0].src = reader.result;
                };
                sparePart.images[0].isLoading = false;
            });
        } else if (remoteImages?.length > 0) {
            sparePart.images[0].isLoading = true;
            sparePart.images[0].src = remoteImages[0].fullUrl;
        }
        this.grid.refreshColumns();
    }

    getSupplierName = (field, data) => data.supplier?.name;

    getPurchasePrice(data) {
        return data.supplier?.unitPrice?.value;
    }

    getPurchaseCurrency(data) {
        return data.supplier?.unitPrice?.currency;
    }

    convertHTtoTTC(data: any) {
        return AmountUtils.convertHTtoTTC(data, parseFloat(this.tva));
    }


}
