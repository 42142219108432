import {Component, OnDestroy, OnInit} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {Subscription} from 'rxjs';
import {StatusEnum} from '../../../../../../models/enums/StatusEnum';

@Component({
    selector: 'app-folder-finished.component',
    templateUrl: './folder-finished.component.html',
    styleUrls: ['./folder-finished.component.scss']
})
export class FolderFinishedComponent implements OnInit, OnDestroy {
    private folderSubscription: Subscription;

    folder: Folder;
    constructor(private  folderSubjectService: FolderSubjectService) {
    }
    ngOnInit(): void {
        this.folderSubscription = this.folderSubjectService.folder$.subscribe(res => {
            if (res) {
                this.folder = res;
            }
        });

    }

    ngOnDestroy(): void {
        this.folderSubscription.unsubscribe();
    }
    IsFolderClosedByDiagnostic(): boolean {
        return this.folder.currentStatus.status === StatusEnum.FOLDER_CLOSED_BY_DIAGNOSTIC;
    }
    getReasonClosedFolderByDiagnostic(): string {
        if (!!this.folder.statusInfo) {
            return this.folder.statusInfo.description;
        }
    }
}
