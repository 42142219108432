<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="text-bold">
        {{'DASHBOARD.WIDGET.FORM.SAVE_CHARTS_DIALOG'|translate}}</h2>
    <button mat-icon-button class="ml-40" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<form [formGroup]="chartsConfigForm" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px"
      (ngSubmit)="saveChartsConfigs()">
    <div fxLayout="column" fxLayoutAlign="space-evenly center" fxLayout.lt-md="column">
        <mat-form-field class="w-50-p">
            <mat-label>{{'DASHBOARD.WIDGET.FORM.SAVE_CHARTS_DIALOG.SITE'|translate}}</mat-label>
            <mat-select formControlName="site">
                <mat-option *ngFor="let site of sites$ | async"
                            [value]="site.code">{{site.label}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-50-p">
            <mat-label>{{'DASHBOARD.WIDGET.FORM.SAVE_CHARTS_DIALOG.USER'|translate}}</mat-label>
            <input matInput formControlName="user"
                   placeholder="{{'DASHBOARD.WIDGET.FORM.SAVE_CHARTS_DIALOG.USER'|translate}}">
        </mat-form-field>
        <mat-form-field class="w-50-p">
            <mat-label>{{'DASHBOARD.WIDGET.FORM.SAVE_CHARTS_DIALOG.ROLE'|translate}}</mat-label>
            <mat-select formControlName="role">
                <mat-option *ngFor="let role of roles"
                            [value]="role">{{role}}
                </mat-option>
            </mat-select>
            <button matSuffix mat-icon-button type="button" class="mx-8"
                    *ngIf="!!chartsConfigForm.get('role').value"
                    (click)="clearRoleForm()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayout.lt-sm="column">
        <button type="reset" mat-stroked-button class=" aster_btn_cancel" (click)="closeDialog()">
            {{'BUTTON.CANCEL' | translate}}
        </button>
        <button type="submit" mat-stroked-button
                class="aster_btn ml-8">
            {{'BUTTON.SAVE' | translate}}
        </button>

    </div>
</form>
