import {ProductFamily} from '../../../../models/productFamily.model';
import {Product} from '../../../../models/product.model';
import {Supplier} from '../../../../models/supplier.model';

export class UnknownProduct {

    public static readonly UNKNOWN_PRODUCT_CODE = 'UNKNOWN_PRODUCT_CODE';
    public static readonly UNKNOWN_PRODUCT_SUPPLIER = 'UNKNOWN_PRODUCT_SUPPLIER';

    label: string;

    model: string;

    brand: string;

    families: ProductFamily[] = [];

    supplier: Supplier;

    public toProduct(): Product {
        const product: Product = new Product();
        product.code = UnknownProduct.UNKNOWN_PRODUCT_CODE;
        product.afterSalesEligible = true;
        product.label = this.label;
        product.brand = this.brand;
        product.families = this.families.filter(family => family !== null);
        product.manufacturerReference = this.model;
        product.supplier = this.supplier;
        return product;
    }

}



