import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {QuotationLine} from '../../../../../../models/quotation.model';
import {OrderStatus} from '../../../../../../models/enums/orderStatus.enum';
import {GridComponent} from '@syncfusion/ej2-angular-grids';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {skipWhile} from 'rxjs/operators';
import {vats} from '../../../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {AmountUtils} from '../../../../../../shared/utils/amount-utils';
@Component({
    selector: 'app-ordered-content',
    templateUrl: './ordered-content.component.html',
    styleUrls: ['./ordered-content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrderedContentComponent  extends Unsubscriber implements OnInit {
    @ViewChild('grid') public grid: GridComponent;
    @Input() orderedSpareParts: QuotationLine[];
    @Input() isUnderWarranty: boolean;
    @Input() withStatus: boolean;
    @Input() withSparePartReceivedCheckbox: boolean;
    @Input() isSparePartReceived: boolean;
    @Output() sparePartReceivedEvent = new EventEmitter();
    selectedSparePart: QuotationLine;
    tva: string;
    constructor(private store$: Store<AppState>) {
        super();
    }
    ngOnInit(): void {
        this.anotherSubscription = this.store$
            .pipe(
                select(vats),
                skipWhile(vats => vats.length === 0)
            )
            .subscribe(vats => {
                this.tva = vats.find(value => value.standard === true)?.rate;
            });

    }

    isItemChecked(index: number) {
      return this.isItemReceived(index) || this.isSparePartReceived ;
    }

    detailDataBound($event){
        this.selectedSparePart = $event.data;
    }
    displaySparePartReceivedCheckbox(element: QuotationLine){
        return element.status !== OrderStatus.PICKING;
    }
    onSelectSparePartReceived(sparePart: QuotationLine, sparePartIndex: number) {
        this.orderedSpareParts[sparePartIndex] =  this.updateSparePartStatus(sparePart);
        this.sparePartReceivedEvent.emit(this.orderedSpareParts);
    }
    private updateSparePartStatus(sparePart: QuotationLine) {
        sparePart.sparePartReceived ? this.setDelivered(sparePart) : this.setOrdered(sparePart);
        return sparePart;
    }

    private setOrdered(sparePart: QuotationLine) {
        return sparePart.status = OrderStatus.ORDERED;
    }

    private setDelivered(sparePart: QuotationLine) {
        return sparePart.status = OrderStatus.DELIVERED;
    }

    displayItemStatus(status: number | string, index: number) {
        if (((status === OrderStatus.SHIPPED || status === OrderStatus.ORDERED || status === OrderStatus.CONFIRMED) && !this.isSparePartReceived ) || !this.isItemReceived(index)) {
            return 'ORDER.TEXT.ORDERED';
        }
        if (status === OrderStatus.PICKING) {
            return 'ORDER.TEXT.PICKING';
        }

        if (status === OrderStatus.DELIVERED || this.isSparePartReceived || this.isItemReceived(index)) {
            return 'ORDER.TEXT.DELIVERED';
        }
        if (status === OrderStatus.EXCEPTION) {
            return 'ORDER.TEXT.EXCEPTION';
        }
    }

    private isItemReceived(index: number) {
        return this.orderedSpareParts[index].sparePartReceived;
    }

    getTotalPrices(quotationLine: QuotationLine) {
        const priceAmount = Object.assign({}, quotationLine.priceAmount) ;
        const ttcValue = AmountUtils.convertHTtoTTC(priceAmount.value , parseFloat(this.tva));
        const quantity = quotationLine.quantity;
        priceAmount.value = ttcValue * quantity;
        return priceAmount;
    }

}
