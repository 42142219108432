<div class="page-layout simple fullwidth pb-10" *ngIf="!!folder && !!product">
    <div *ngIf="folder.productSubElement" class="ribbon ribbon-top-right">
        <span>{{'FOLDER.SUB_ELEMENT'|translate}}</span>
    </div>
    <div class="highlighted">
        <fieldset>
            <legend class="h3 title-fieldset">{{'FOLDER.PRODUCT.DIALOG.TITLE' | translate}}</legend>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <div class="h-140 w-140  mr-24 mb-16 img-uploader"
                     fxLayout="row" fxLayoutAlign="center center">
                        <app-product-image [image]="product.imageUrl"
                                           [loaderCss]="'w-60 h-60'"
                                           [imageCss]="'h-160-max'"
                        ></app-product-image>

                </div>
                <div fxFlex>
                    <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="product.code != this.UNKNOWN_PRODUCT_CODE">
                        <div fxFlex="10">
                            <span class="font-weight-600">
                                {{'FOLDER.PRODUCT.PRODUCT_CODE' | translate}} :
                            </span>
                        </div>
                        <div fxFlex>
                                <span class="info" (click)="showProductDetails()"> {{product.code}}</span>
                        </div>
                    </div>
                    <div class=" px-10 mt-8">
                        <span class="mat-subheading-2">{{product.label}}</span>
                    </div>
                    <ng-container *ngIf="!!folder.purchaseInvoice">
                        <div class="w-100-p px-10 mt-8">
                            <div fxLayout="row" fxLayoutAlign="none">
                                <mat-icon svgIcon="bar_code"></mat-icon>
                                <div>
                                    <span class="font-weight-600">{{'INVOICE.TITLE' | translate}} :</span>
                                    <span> {{folder.purchaseInvoice.number}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="w-100-p px-10 mt-12">
                            <div fxLayout="row" fxLayoutAlign="none">
                                <mat-icon>today</mat-icon>
                                <div class="mr-28">
                                    <span class="font-weight-600">{{'INVOICE.PURCHASE_DATE' | translate}} :</span>
                                    {{ folder.purchaseInvoice && folder.purchaseInvoice.purchaseDate ?
                                    (getDate(folder.purchaseInvoice.purchaseDate) |date:"dd/MM/yyyy") : '' }}
                                </div>
                                <ng-container *ngIf="hasSellingPrice()">
                                    <mat-icon>credit_card</mat-icon>
                                    <div>
                                        <span class="font-weight-600">{{'FOLDER.PRODUCT.SELLING_PRICE' | translate}} :</span>
                                        {{ folder.purchaseInvoice.sellingPrice.value | currency: folder.purchaseInvoice.sellingPrice.currency :'symbol-narrow'}}
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                    </ng-container>
                    <ng-container *ngIf="!!product.supplier && product.supplier.code !== this.UNKNOWN_PRODUCT_SUPPLIER">
                        <div class="w-100-p px-10 mt-12"
                             fxLayoutAlign="space-between center"
                             *ngIf="showSupplierActionEdit;else CHANGE_SUPPLIER">
                            <div fxLayout="row">
                                <mat-icon>local_shipping</mat-icon>
                                <div>
                                    <span class="font-weight-600"> {{'FOLDER.SUPPLIER.TITLE' | translate}} :
                                        <span [class.info]="supplierCode !== UNKNOWN_SUPPLIER"
                                              (click)="showDetailSupplier()">
                                            <ng-container
                                                *ngIf="product.supplier?.code===UNKNOWN_SUPPLIER;else SHOW_SUPPLIER_NAME">
                                                {{UNKNOWN_SUPPLIER | translate}}
                                            </ng-container>
                                            <ng-template #SHOW_SUPPLIER_NAME>
                                              <span [hideSensibleInfo]="claimSensibleHiddenField.SUPPLIER">{{product.supplier?.code + " - " + product.supplier?.label}}</span>
                                            </ng-template>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <ng-container *ngIf="((hasScope(Scopes.FOLDER_UPDATE_SUPPLIER) | async) && canEditSupplier() ) || (isAdminPlatana$ | async)">
                                <div (click)="onEditSupplier()" class="cursor-pointer"
                                     matTooltip="{{'COMPONENT.FOLDER_DETAILS_CARD.CHANGE_SUPPLIER' | translate}}">
                                    <mat-icon>edit</mat-icon>
                                </div>
                            </ng-container>
                        </div>
                        <ng-template #CHANGE_SUPPLIER>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <mat-form-field appearance="outline">
                                    <mat-select [formControl]="supplierCtrl" [placeholder]="'COMPONENT.FOLDER_DETAILS_CARD.SUPPLIER' | translate">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="supplierFilteringCtrl"
                                                                   [searching]="searching"
                                                                   [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                                                   [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate">

                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let supplier of filteredSuppliers | async" [value]="supplier">
                                            {{ supplier.code }} - {{ supplier.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div fxFlex="20">
                                    <div fxLayout="row" fxLayoutAlign="end center">
                                        <ng-container *ngIf="this.supplierCtrl.value">
                                            <div class="cursor-pointer" (click)="validateChangeSupplier()"
                                                 matTooltip="{{'COMPONENT.FOLDER_DETAILS_CARD.VALIDATE_SUPPLIER_CHANGE' | translate}}">
                                                <mat-icon>check</mat-icon>
                                            </div>
                                        </ng-container>
                                        <div class="cursor-pointer" (click)="onEditSupplier()"
                                             matTooltip="{{'COMPONENT.FOLDER_DETAILS_CARD.ABORT_SUPPLIER_CHANGE' | translate}}">
                                            <mat-icon>clear</mat-icon>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                    </ng-container>

                    <app-product-editable-field label="PRODUCT.PREVIEW_CARD.LABELS.QUANTITY"
                                                [invalidMessage]="'PRODUCT.PREVIEW_CARD.LABELS.INVALID_QUANTITY' | translate: {maxQuantity: ''}"
                                                type="number"
                                                [minValue]="1"
                                                [isEditable]="isQuantityEditable"
                                                [value]="quantity"
                                                (save)="updateQuantity($event)">
                    </app-product-editable-field>

                    <ng-container *ngIf="folder.productSubElement">
                        <div class="w-100-p px-10 mt-12">
                            <div fxLayout="row" fxLayoutAlign="none">
                                <mat-icon>extension</mat-icon>
                                <div>
                                    <span class="font-weight-600"> {{'FOLDER.SUB_ELEMENT' | translate}} :
                                           <span class="font-weight-600">
                                {{ folder.productSubElement.label}}
                                            </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!!folder?.productAccessories">
                        <div class="w-100-p px-10 mt-12">
                            <div fxLayout="row" fxLayoutAlign="none">
                                <mat-icon>style</mat-icon>
                                <div>
                                    <span
                                        class="font-weight-600"> {{'PRODUCT_ACCESSORIES.LABELS.ACCESSORIES' | translate}}
                                        :
                                           <span class="font-weight-600">{{accessoriesConverted}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!!product?.productState">
                        <div class="w-100-p px-10 mt-12">
                            <div fxLayout="row" fxLayoutAlign="none">
                                <mat-icon>star_border</mat-icon>
                                <div>
                                    <span class="font-weight-600"> {{'PRODUCT_STATE.LABELS.STATE' | translate}} :
                                           <span class="font-weight-600">
                                            {{product.productState | codeToLabel: 'SAV.CONFIG.PRODUCT_STATE' | async | translate}}
                                            </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <app-serial-number
                            [title]="'SERIAL_NUMBER1'"
                            [serialNumber]="serialNumber"
                            (serialNumberChange)="updateSerialNumberFolder($event)"
                            (serialNumberEdit)="getSerialNumberRule()"
                            [serialNumberPattern]="(serialNumberInfo$ | async)?.serialNumber1RegEx"
                            [serialNumberInfo]="serialNumberInfo$ | async">
                    </app-serial-number>
                    <div *ngIf="hasSecondSerialNumber()">
                        <app-serial-number [title]="'SERIAL_NUMBER2'" [serialNumber]="secondSerialNumber"
                                           (serialNumberChange)="updateSecondSerialNumberFolder($event)"
                                           (serialNumberEdit)="getSerialNumberRule()"
                                           [serialNumberPattern]="(serialNumberInfo$ | async)?.serialNumber2RegEx"
                                           [serialNumberInfo]="serialNumberInfo$ | async"></app-serial-number>
                    </div>
                </div>

            </div>
        </fieldset>
    </div>

    <div *ngIf="!!folder && folder.faultCode">
        <fieldset>
            <legend class="h3 title-fieldset">{{'FOLDER.SYMPTOM.TITLE' | translate}}</legend>
            <ng-container *ngIf="showSymptomActionEdit;else UPDATE_SYMPTOM">
                <div fxLayout="column" fxLayoutAlign="end end" *ngIf="!folderClosed">
                    <button mat-icon-button (click)="updateSymptom()" aria-label="more"
                            matTooltip="{{'FOLDER.ADDRESS.SUBMIT_BUTTON' | translate}}">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start">
                    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="2px">
                        <mat-icon>build</mat-icon>

                        <span class="font-weight-600"> {{folder.faultCode.label}}</span>

                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="3px" class="mt-12 mb-12">
                        <mat-icon>dehaze</mat-icon>
                        <div>
                            <span
                                class="font-weight-600"> {{folder.faultCode.condition | codeToLabel: 'SAV.CONFIG.CONDITIONS' | async | translate}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #UPDATE_SYMPTOM>
                <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="3px" class="pl-24 w-100-p">
                    <app-symptom-search-dialog [productCode]="folder.product.code" [context]="folder.context"
                                               [organizationCode]="folder.organization.code" fxFlex="100" class="mt-16"
                                               *ngIf="!!folder.product.code"
                                               (updatedFaultCode)="updateSymptomFolder($event)"
                                               (closeField)="updateSymptom()">
                    </app-symptom-search-dialog>

                </div>
            </ng-template>

            <ng-container *ngIf="getCustomerCommentPanneConfirmed()">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px" class="mt-12 mb-12">
                    <mat-icon>comment</mat-icon>
                    <div>
                        <span class="font-weight-600"> {{getCustomerCommentPanneConfirmed()}}</span>
                    </div>
                </div>
            </ng-container>

        </fieldset>
    </div>

    <ng-container *ngIf="isFolderCustomerChoicePresent()">
        <fieldset>
            <legend class="h3 title-fieldset">{{'FOLDER.CUSTOMER.CHOICE.TITLE' | translate}}</legend>
            <div class="mt-12" fxFlex="100">
                <span class="font-weight-600">{{'CUSTOMER_CHOICE.' + customerChoice | translate}}</span>
            </div>
        </fieldset>
    </ng-container>

    <fieldset *ngIf="isCustomerDetailPresent()">
        <legend class="h3 title-fieldset">{{'CUSTOMER.DETAILS' | translate}}</legend>
        <ng-container>
            <app-customer-info-card *ngIf="!!folder && folder.customer" [canUpdateReference]="isAdminPlatana$ | async"
                                    [showActionEdit]="hasScope(Scopes.CLIENT_INFO_ADMINISTRATION) | async">
            </app-customer-info-card>
        </ng-container>
    </fieldset>

    <ng-container *ngIf="!!folder.repairCenter && folder.context === 'SAV'">
        <fieldset>
            <legend class="h3 title-fieldset">{{'FOLDER.REPAIRER.LABEL' | translate}}</legend>
            <div class="mt-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <mat-icon svgIcon="assistance_black"></mat-icon>
                <div (click)="onOpenDialogRepairerDetails()" class="cursor-pointer">
                    <div class="text-bold">
                        <span class="repair">
                            {{'FOLDER.REPAIRER.LABEL' | translate}}: {{folder.repairCenter.label}}
                        </span>
                    </div>
                </div>
                <div *ngIf="technician">
                    <div class="text-bold">
                        <span class="repair">
                            {{'FOLDER.REPAIRER.TECHNICIAN.LABEL' | translate}}
                            : {{technician.firstName + ' ' + technician.lastName}}
                        </span>
                    </div>
                </div>
                <div *ngIf="service">
                    <div class="text-bold">
                        <span class="repair">
                            {{'FOLDER.REPAIRER.SERVICE.LABEL' | translate}}: {{service.label}}
                        </span>
                    </div>
                </div>
            </div>
        </fieldset>
    </ng-container>
    <ng-container *ngIf="isFolderPayersPresent()">
        <fieldset>
            <legend class="h3 title-fieldset">{{'MODAL.QUOTATION.PAYER' | translate}}</legend>
            <div class="mt-12" fxLayout="row">
                <div fxFlex="33">
                    <span class="font-weight-600">{{'MODAL.QUOTATION.TYPE.DISPLACEMENT' | translate}} :</span>
                    <span> {{!!folder.payers?.displacement ? ('RULE_WARRANTIES.CONFIGS.PAYERS_CONFIGS.' + folder.payers.displacement | translate) : '-'}} </span>
                </div>

                <div fxFlex="33">
                    <span class="font-weight-600">{{'MODAL.QUOTATION.TYPE.SPARE_PART' | translate}} :</span>
                    <span> {{!!folder.payers?.spareParts ? ('RULE_WARRANTIES.CONFIGS.PAYERS_CONFIGS.' + folder.payers.spareParts | translate) : '-'}} </span>
                </div>

                <div fxFlex="33">
                    <span class="font-weight-600">{{'MODAL.QUOTATION.TYPE.WORKFORCE' | translate}} :</span>
                    <span> {{!!folder.payers?.workforce ? ('RULE_WARRANTIES.CONFIGS.PAYERS_CONFIGS.' + folder.payers.workforce | translate) : '-'}} </span>
                </div>
            </div>
        </fieldset>
    </ng-container>

    <ng-container *ngIf="!!folder.appointment && !!folder.appointment.confirmedAppointment">
        <fieldset>
            <legend class="h3 title-fieldset">{{'FOLDER.INTERVENTION_DATE' | translate}}</legend>
            <div class="mt-20" fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-sm="column"
                 fxLayoutAlign.lt-sm="center start" fxLayoutGap="8px">
                <mat-icon>event_available</mat-icon>
                <div>
                    {{folder.appointment.confirmedAppointment.day | moment: ('GENERAL.DATE_FORMAT_DAY' | translate) :
                    (currentLanguage$ | async) }}
                    (
                    {{folder.appointment.confirmedAppointment.beginHour }}
                    &nbsp; - &nbsp;
                    {{folder.appointment.confirmedAppointment.endHour }}
                    )
                </div>
            </div>
        </fieldset>
    </ng-container>

    <ng-container *ngIf="immobilizationPresent()">
        <fieldset>
            <legend class="h3 title-fieldset">{{'FOLDER.IMMOBILIZATION' | translate}}</legend>

            <div fxLayout="row wrap" class="mt-16">
                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="start start">
                        <label class="h3 text-bold">
                            <mat-icon>event_available</mat-icon>
                            {{'FOLDER.WAITING_PRODUCT_RETRIEVAL.PRODUCT_IMMOBILIZATION.BEGIN_DATE'|translate}}:
                        </label>
                        <div class="h3 mt-4">
                            {{folder.immobilization.immobilizationBeginDate | moment:
                            ('GENERAL.DATE.IMMOBILIZATION.CREATION_DATE' | translate) : (currentLanguage$ | async) }}
                        </div>
                    </div>
                </div>
                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="start start" class="ml-12">
                        <label class="h3 text-bold">
                            <mat-icon>event_available</mat-icon>
                            {{'FOLDER.WAITING_PRODUCT_RETRIEVAL.PRODUCT_IMMOBILIZATION.END_DATE'|translate}}:
                        </label>
                        <div class="h3 mt-4">
                            <ng-container *ngIf="folder.immobilization.immobilizationEndDate;else END_DATE_EMPTY">
                                {{folder.immobilization.immobilizationEndDate | moment:
                                ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE.CREATION_DATE' | translate) : (currentLanguage$ | async)
                                }}
                            </ng-container>
                            <ng-template #END_DATE_EMPTY>
                                -
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

        </fieldset>
    </ng-container>
    <ng-container *ngIf="folder.context === 'REPARATION' && folder.originator">
        <fieldset>
            <legend class="h3 title-fieldset">{{'ORIGINATOR.DETAILS' | translate}} </legend>
            <app-customer-info-card *ngIf="!!folder && folder.originator" [isOriginator]="true"
                                    [showActionEdit]="hasScope(Scopes.CLIENT_INFO_ADMINISTRATION) | async">
            </app-customer-info-card>
        </fieldset>
    </ng-container>
</div>