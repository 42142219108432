import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder, IFolderUpdateRequest} from '../../../../../../models/folder.model';
import {UserState} from '../../../../../../store/user/user.state';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {ReparationReport} from '../../../../../../models/reparationReport/reparation-report.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {MatDialog} from '@angular/material/dialog';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {ReportModalComponent} from '../../../../../../shared/generic/report-modal/report-modal.component';
import {Quotation} from '../../../../../../models/quotation.model';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {currentUser} from '../../../../../../store/user/user.selectors';
import {currency} from '../../../../../../store/organization/organization.selectors';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {ReparationStatusEnum} from '../model/reparationStatusEnum';
import {BackOfficeService} from '../../../../../../shared/services/back-office.service';
import {RuleEvaluationContext} from '../../../../../../models/rules/RuleEvaluationContext';
import {RepairStatusType} from '../../../../../../models/enums/repairStatusType.enum';
import {OmsService} from '../../../../../../shared/services/oms.service';
import {Order} from '../../../../../../models/order.model';
import {QuotationType} from '../../../../../../models/enums/quotationType.enum';
import {Payer} from '../../../../../../models/enums/payer.enum';
import {OrderSubjectService} from '../../../../../../shared/services/order-subject.service';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';


@Component({
    selector: 'app-internal-reparation-in-progress2',
    templateUrl: './internal-reparation-in-progress2.component.html',
    styleUrls: ['./internal-reparation-in-progress2.component.scss']
})
export class InternalReparationInProgress2Component extends Unsubscriber implements OnInit {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Output() commentGiven = new EventEmitter<any>();

    currentUser: UserState;

    repairStatusForm: any = {
        form: null,
        placeholders: []
    };

    agentCommentForm: FormGroup;
    reportLineColumns: string[] = ['code', 'label', 'type', 'payer'];
    reportLineDataTable = new MatTableDataSource<any>();

    irisColumns: string[] = ['symptom', 'condition', 'repair', 'defect', 'section'];
    irisDataTable = new MatTableDataSource<any>();

    reparationReport: ReparationReport;
    currency: string;
    order = new Order();

    constructor(private store$: Store<AppState>,
                private folderService: FolderService,
                private backOfficeService: BackOfficeService,
                private folderSubjectService: FolderSubjectService,
                private orderSubjectService: OrderSubjectService,
                private matDialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        this.initForm();
        this.anotherSubscription = this.store$.pipe(select(currentUser)).subscribe(userDetails => {
            this.currentUser = userDetails;
        });
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => this.currency = currency);
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
            if (!!this.folder.orderIds && this.folder.orderIds?.length > 0) {
                this.getOrder();

            } else {
                const quotations = this.getQuotationDistributor();
                this.reportLineDataTable.data = this.convertToReportLine(!!quotations && quotations.quotationLines.length > 0 ? quotations.quotationLines : null);

            }

        });
        this.initAddCommentForm();
    }

    getOrder() {
        this.anotherSubscription = this.orderSubjectService.order$.subscribe(order => {
            this.order = order;
            this.reportLineDataTable.data = this.convertItemsToReportLine();
        });

    }

    initAddCommentForm(): void {
        this.agentCommentForm = new FormGroup({
            comment: new FormControl(null)
        });
    }

    private initForm(): void {
        this.repairStatusForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            description: new FormControl(''),
        });


        this.repairStatusForm.placeholders = [
            {
                status: 'NOT_REPAIRABLE',
                value: 'COMPONENT.FOLDER_CLOSED_HS_PRODUCT_DESTRUCTION',
            },
            {
                status: 'FINISHED',
                value: 'COMPONENT.REPARATION.FINISHED',
            }
        ];
    }

    onSubmit(): void {
        this.store$.dispatch(new StartLoading());
        if (this.repairStatusForm.form.value.status === 'NOT_REPAIRABLE') {
            this.inputMap.emit({
                reparationStatus: ReparationStatusEnum.NOT_REPAIRABLE
            });
            if (!!this.repairStatusForm.form.value.description) {
                this.commentGiven.emit({
                        value: this.repairStatusForm.form.value.description
                    }
                );
            }
        } else {
            this.prepareDataIris();
            this.updateFolder();
            this.setComment();
        }

    }

    updateFolder(): void {
        this.store$.dispatch(new StartLoading());
        const folderUpdateRequest: IFolderUpdateRequest = {
            reparationReport: this.reparationReport
        };

        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
            this.folderSubjectService.folderLoaded(folder);
            this.folder = folder;
            this.inputMap.emit({
                reparationStatus: this.repairStatusForm.form.value.status
            });
            this.store$.dispatch(new StopLoading());
        }).catch(() => {
            console.log('ERROR Update Folder with id :' + this.folder.id);
            this.store$.dispatch(new StopLoading());
        });
    }

    private setComment() {
        if (!!this.agentCommentForm.value.comment) {
            this.commentGiven.emit({
                    value: this.agentCommentForm.value.comment
                }
            );
        }
    }

    prepareDataIris(): void {
        this.reparationReport = {
            reportLine: this.reportLineDataTable.data,
            iris: []
        };
        this.irisDataTable.data.forEach(irisLine => {
            this.reparationReport.iris.push({
                symptom: irisLine.symptom,
                condition: irisLine.condition,
                reparation: irisLine.reparation,
                defect: irisLine.defect,
                section: irisLine.section
            });
        });
    }

    openQuotationModal(): void {
        const ruleEvaluationContext = RuleEvaluationContext.fromFolder(this.folder);
        this.backOfficeService.getWorkforces(ruleEvaluationContext)
            .subscribe(workforces => {
                const initData = {
                    reportLine: this.convertToReportLine(this.reportLineDataTable.data),
                    iris: this.irisDataTable.data,
                    warrantyReason: this.folder.newWarranty.warrantyReason,
                    currency: this.currency,
                    loadSparePartCatalog: true,
                    hideIris: false,
                    workforcesRule: workforces,
                    loadTypeOfBenefit: true
                };
                const dialogRef = this.matDialog.open(ReportModalComponent, {
                    height: '90vh', width: '100vh', minWidth: '80%', data: initData
                });
                dialogRef.afterClosed().subscribe(dataReport => {
                        if (!!dataReport) {
                            this.reportLineDataTable.data = dataReport.reportLine;
                            this.irisDataTable.data = dataReport.iris;
                        }
                    }
                );
            });

    }

    private convertToReportLine(quotationLines: any) {
        const reportLine = [];
        if (!!quotationLines) {
            quotationLines.forEach(value => {
                reportLine.push(
                    {
                        code: value.code,
                        label: value.label,
                        type: value.type,
                        payer: value.payer,
                        quantity: value.quantity,
                        price: !!value.priceAmount ? value.priceAmount.value : value.price,
                        tva: !!value.vat ? value.vat.rate : value.tva,
                        discountReason: value.discountReason,
                        discount: value.discount,
                        totalLinePrice: this.getTotalLinePrice(value),
                        currency: this.currency
                    }
                );
            });
        }
        return reportLine;
    }

    private getTotalLinePrice(value) {
        return !!value.totalLinePrice ? value.totalLinePrice.value : 0;
    }

    disabledButtonForm(): boolean {
        switch (this.repairStatusForm.form.value.status) {
            case RepairStatusType.NOT_REPAIRABLE: {
                return false;
            }
            case RepairStatusType.FINISHED: {
                if (this.reportLineDataTable.data.length > 0) {
                    return false;
                }
                break;
            }
        }
        return true;
    }


    getQuotationDistributor(): Quotation {
        return this.folder.quotations.pop();
    }

    public checkReparationStatus(): boolean {
        return this.repairStatusForm.form.value.status === 'NOT_REPAIRABLE';
    }

    private convertItemsToReportLine() {
        let reportLine = [];
        const reportLine1 = [];
        if (!!this.order.orderItems) {
            this.order.orderItems.forEach(value => {
                reportLine1.push(
                    {
                        code: value.code,
                        label: value.label,
                        type: value.type,
                        payer: value.payer,
                        quantity: value.quantityOrdered,
                        price: value.price,
                        tva: value.taxPercent,
                        discountReason: value.discountReason,
                        discount: value.discountPercent,
                        totalLinePrice: !!value.rowTotal ? value.rowTotal : 0,
                        currency: this.currency
                    }
                );
            });
        }
        const reportLine2 = [];
        if (!!this.order.orderExtraItems) {
            this.order.orderExtraItems.filter(item => item.type === QuotationType.WORKFORCE).forEach(value => {
                reportLine2.push(
                    {
                        code: value.code,
                        label: value.label,
                        type: value.type,
                        payer: value.payer,
                        quantity: value.quantityOrdered,
                        price: value.price,
                        tva: value.taxPercent,
                        discountReason: value.discountReason,
                        discount: value.discountPercent,
                        totalLinePrice: !!value.rowTotal ? value.rowTotal : 0,
                        currency: this.currency
                    }
                );
            });
        }
        reportLine = [...reportLine1, ...reportLine2];
        return reportLine;
    }
}
