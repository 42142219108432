<ng-container [ngSwitch]="variablesTask['quotationProcess']">
    <ng-container *ngSwitchCase="'CLIENT'">
        <app-quotation-process-customer [instructionUserTask]="instructionUserTask" [variablesTask]="variablesTask"  (inputMapSubComponent)="completeTask($event)">
        </app-quotation-process-customer>
    </ng-container>
    <ng-container *ngSwitchCase="'DISTRIBUTOR'">
        <app-quotation-details [instructionUserTask]="instructionUserTask" [variablesTask]="variablesTask" (inputMapSubComponent)="completeTask($event)">
        </app-quotation-details>
    </ng-container>
</ng-container>

