import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {Address} from '../../../../../models/customer/customer.model';
import {AddressType} from '../../../../../models/enums/addressType.enum';
import {SharedService} from '../../../../../shared/services/shared.service';
import {CustomerUtils} from '../../../../../shared/utils/customer-utils';
import {FormGroup} from '@angular/forms';
import {SiteType} from '../../../../../models/enums/siteType.enum';
import {Unsubscriber} from '../../../../../unsubscriber';
import {MatRadioChange} from '@angular/material/radio';
import {DeliveryModeEnum} from '../../../../../models/enums/deliveryMode.enum';
import {Order} from '../../../../../models/order.model';
import {OmsService} from '../../../../../shared/services/oms.service';
import {OrderSubjectService} from '../../../../../shared/services/order-subject.service';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';


@Component({
    selector: 'app-client-validate-shipment-customer-address',
    templateUrl: './client-validate-shipment-customer-address.component.html',
    styleUrls: ['./client-validate-shipment-customer-address.component.css']
})
export class ClientValidateShipmentCustomerAddressComponent extends Unsubscriber implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;

    defaultAddress: Address;
    addressForm = new FormGroup({});
    chooseDeliveryDestination = false;
    showAddressForm = false;
    placeholders: any;
    private deliveryDestination: any;
    destination: string;
    order = new Order();

    constructor(private _sharedAddressService: SharedService, private omsService: OmsService,
                private orderSubjectService: OrderSubjectService) {
        super();
    }

    completeTask(): void {
        const address = {
            'sparePartsShipmentAddress': this.addressForm.getRawValue().address,
        };
        if (this.order?.id) {
            this.updateOrder(address);
        } else {
            // TODO to be deprecated after 60 days
            this.inputMap.emit({
                'sparePartsShipmentAddress': JSON.stringify(address)
            });
        }


    }

    private updateOrder(address) {
        this.order.shippingAddress = address.sparePartsShipmentAddress;
        this.omsService.updateOrder(this.order.id, this.order).subscribe((data) => {
            if (!!data) {
                this.order = data;
                this.orderSubjectService.orderLoaded(this.order);
                this.inputMap.emit({
                    'sparePartsShipmentAddress': JSON.stringify(address) // TODO to be removed ?
                });
            }
        });
    }

    private initOrder() {
        this.anotherSubscription = this.orderSubjectService.order$.subscribe(order => {
            this.order = order;
        });
    }

    getCustomerDefaultAddress(): Address {
        if (!!this.folder.customer && !!this.folder.customer.addresses) {
            this.defaultAddress = CustomerUtils.getCustomerAddressByType(this.folder.customer.addresses, AddressType.DEFAULT);
        }
        return this.defaultAddress;
    }

    getManagementSiteAddress(): Address {
        const sites = this.folder.sites.filter(site => site.type === SiteType.MANAGEMENT_SITE);
        if (sites.length > 0) {
            this.defaultAddress = sites[0].address;
        }
        return this.defaultAddress;
    }

    ngOnInit(): void {
        this.initOrder();
        this.getPlaceholders();
        this.getDeliveryDestination();
        this.showingAddressDestination(this.deliveryDestination);
        this.anotherSubscription = this._sharedAddressService.getUpdateAddressEvent().subscribe(res => {
            if (!!res) {
                if (this.destination === DeliveryModeEnum.STORE_SHIPPING_ADDRESS && this.isValidForm() && !!this.defaultAddress) {
                    this.addressForm.disable();
                } else {
                    this.addressForm.enable();
                }
            }
        });
    }

    private getDeliveryDestination() {
        this.deliveryDestination = this.variablesTask['deliveryDestination'];
    }

    private showingAddressDestination(deliveryDestination) {
        if (deliveryDestination === 'CHOOSE_DELIVERY_DESTINATION') {
            this.chooseDeliveryDestination = true;
        } else if (deliveryDestination === DeliveryModeEnum.CUSTOMER_SHIPPING_ADDRESS) {
            this.destinationToCustomer();
        } else {
            this.destinationToStore();
        }
    }

    private destinationToStore() {
        this.defaultAddress = this.getManagementSiteAddress();
        this.showAddressForm = true;
    }

    isValidForm() {
        return !this.addressForm.invalid;
    }

    private destinationToCustomer() {
        this.getCustomerDefaultAddress();
        this.showAddressForm = true;
    }

    choiceChange($event: MatRadioChange) {
        this.showAddressForm = true;
        this.destination = $event.value;
        if (!!this.defaultAddress) {
            this.addressForm.reset();
        }
        this.showingAddressDestination($event.value);
    }

    getPlaceholders() {
        this.placeholders = [
            {
                value: DeliveryModeEnum.CUSTOMER_SHIPPING_ADDRESS,
            },
            {
                value: DeliveryModeEnum.STORE_SHIPPING_ADDRESS,
            }
        ];
    }

}
