<mat-form-field appearance="outline" id="operatingMode" fxFlex style="font-size: 14px" (click)="loadModOps()">
    <mat-label>{{'FOLDER.OPERATION_MODE.TITLE' | translate}}</mat-label>
    <mat-select
        [ngModel]="selectedOperatingMode"
        (ngModelChange)="updateSelectedOperatingMode($event)">
        <mat-select-trigger>
            <ng-container
                *ngIf="selectedOperatingMode && getIconName(selectedOperatingMode);else SHOW_ALL_MOD_OP_LABEL">
                <mat-icon class="s-20"
                          [svgIcon]="getIconName(selectedOperatingMode)">
                    {{getIconName(selectedOperatingMode)}}
                </mat-icon>
                {{labelFromCode(selectedOperatingMode)}}
            </ng-container>
            <ng-template #SHOW_ALL_MOD_OP_LABEL>
                {{'MOCK.ANY_LABEL' |translate}}
            </ng-template>
        </mat-select-trigger>
        <mat-option>{{'MOCK.ANY_LABEL' |translate}}</mat-option>
        <mat-option *ngFor="let operatingMode of operatingModes"
                    [value]="operatingMode.code">
            <mat-icon class="s-20"
                      [svgIcon]="getIconName(operatingMode.code)">
            </mat-icon>
            {{operatingMode.label}}
        </mat-option>
    </mat-select>
</mat-form-field>