<div *ngIf="!!invoice">
    <div *ngIf="invoiceList && invoiceList.data.length > 0" fxLayout="column" fxLayoutAlign="start stretch">
        <button type="button" mat-stroked-button color="accent" (click)="backToInvoiceList()"
                class="py-12 px-24 mb-8 mr-8 font-weight-900 font-size-30">
            {{'FOLDER.CREATE.BACK_INVOICE_LIST_BUTTON' | translate}}
        </button>
    </div>
    <ng-container *ngIf="!invoiceNotFound">
        <form [formGroup]="invoiceForm.form">
            <div fxLayout="row"
                 fxLayoutGap="30px"
                 fxLayoutAlign="space-between center"
                 class="w-100-p">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>{{'INVOICE.INVOICE_NUMBER'|translate}}</mat-label>
                    <input matInput placeholder="{{'INVOICE.INVOICE_NUMBER'|translate}}" formControlName="id"
                           id="invoice_number"
                           (blur)="invoiceNumberChanged()"
                           [disabled]="!editInvoice && !!invoice.id">
                    <mat-icon matSuffix class="disabled-text cursor-pointer" (click)="switchEditInvoiceNumber()">
                        edit
                    </mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>{{isRequired ? ('INVOICE.PURCHASE_DATE' | translate) + '*' : 'INVOICE.PURCHASE_DATE' | translate}}</mat-label>
                    <input matInput id='invoice_creationDate'
                           [placeholder]="isRequired ? ('INVOICE.PURCHASE_DATE' | translate ) + '*' :
                       ('INVOICE.PURCHASE_DATE'| translate )"
                           formControlName="creationDate"
                           (blur)="invoiceCreationDateChanged()"
                           (dateChange)="invoiceCreationDateChanged()"
                           [max]="maxDate"
                           [matDatepicker]="invoiceCreationDate">
                    <mat-datepicker-toggle matSuffix [for]="invoiceCreationDate"></mat-datepicker-toggle>
                    <mat-datepicker #invoiceCreationDate></mat-datepicker>
                </mat-form-field>

            </div>
        </form>
    </ng-container>
</div>
<div *ngIf="showInvoiceList()">
    <ng-container>
        <table mat-table [dataSource]="invoiceList" class="w-100-p">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> {{'INVOICE.INVOICE_NUMBER' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" (click)="selectInvoice(element)"
                    class="cursor-pointer">
                    {{element.id}}
                </td>
            </ng-container>

            <ng-container matColumnDef="creationDate">
                <th mat-header-cell *matHeaderCellDef> {{'INVOICE.PURCHASE_DATE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" (click)="selectInvoice(element)"
                    class="cursor-pointer">
                    {{element.creationDate | date : 'yyyy-mm-dd'}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </ng-container>
</div>
<div *ngIf="invoiceNotFound">

    <form [formGroup]="invoiceNotFoundForm">
        <span class="invoice-not-found">{{'INVOICE.INVOICE_NOT_FOUND'|translate}}</span>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>{{'INVOICE.INVOICE_NUMBER'|translate}}</mat-label>
                <input matInput placeholder="{{'INVOICE.INVOICE_NUMBER'|translate}}" formControlName="id"   id="invoice_code"
                       (blur)="invoiceNumberChanged(invoiceNotFoundForm.value.id)">
                <mat-icon matSuffix class="disabled-text cursor-pointer" (click)="switchEditInvoiceNumber()">edit
                </mat-icon>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>{{'INVOICE.PURCHASE_DATE' | translate }}</mat-label>
                <input matInput
                       [placeholder]="'INVOICE.PURCHASE_DATE' | translate"
                       formControlName="creationDate"  id="invoice_creationDate"
                       (blur)="invoiceCreationDateChanged(invoiceNotFoundForm.value.creationDate)"
                       (change)="invoiceCreationDateChanged(invoiceNotFoundForm.value.creationDate)"
                       [max]="maxDate"
                       [matDatepicker]="invoiceCreationDate">
                <mat-datepicker-toggle matSuffix [for]="invoiceCreationDate"></mat-datepicker-toggle>
                <mat-datepicker #invoiceCreationDate></mat-datepicker>
            </mat-form-field>
        </div>
    </form>
</div>