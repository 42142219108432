    <mat-card-content>
        <form [formGroup]="form" *ngIf="placeholders" fxLayout="column"
              fxLayoutAlign="start" (ngSubmit)="onSubmit()">

            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <div fxLayout="row" fxLayoutAlign="start center" class="mt-12">
                <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
                    <mat-radio-group formControlName="choice"  class="ml-20" fxLayoutGap="10px"
                                     labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch">
                        <mat-radio-button class="full-width-radio"
                                          *ngFor="let item of placeholders"
                                          [value]="item.choice">
                            <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                <div fxFlex>
                                    <div>{{ item.value | translate }}</div>
                                </div>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div fxLayoutAlign="end center" class="mt-24">
                <button type="submit" mat-stroked-button
                        [disabled]="form.invalid"
                        [ngClass]="form.invalid ? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>