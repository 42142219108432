
    <mat-card-content fxLayout="column" fxLayoutGap="8px" >
            <div>
                <app-workflow-status-progress-bar taskTimeoutDuration="{{getTaskDuration()}}"
                                                  currentStatusDate="{{getStatusDate()}}">
                </app-workflow-status-progress-bar>
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>
            <div fxLayout="row"  class="w-100-p px-10 mt-12" fxLayoutGap="20px">
                <div fxLayout="row"  class="flex-grow" fxLayoutGap="4px" >
                    <span class="font-weight-600">{{'ORDER.STATUS' | translate}} : </span>
                    <span  [ngClass]="isOrderExceptionPresent() ? 'red-color':'black-color'">{{displayOrderStatus(order?.orderStatus) | translate}}</span>
                </div>
                <div fxLayout="row"  fxLayoutGap="4px" class="cursor-pointer" *ngIf="isOrderExceptionPresent()">
                    <span class="link-underline" (click)="confirmUpdateStatus()">{{ 'COMPONENT.WAITING_SPARE_PARTS.EDIT.STATUS' | translate }}</span>
                    <mat-icon matTooltip="{{ 'COMPONENT.WAITING_SPARE_PARTS.HELP.TEXT' | translate }}">error_outline</mat-icon>
                </div>
            </div>
            <app-ordered-content *ngIf="!!orderedSpareParts" class="mb-24"
                                 [orderedSpareParts]="orderedSpareParts"
                                 [isUnderWarranty]="isUnderWarranty"
                                 [withStatus]="true"
                                 (sparePartReceivedEvent)="updateStatus($event)"
                                 [withSparePartReceivedCheckbox]="true"
                                 [isSparePartReceived]="allSparePartReceived"></app-ordered-content>

            <mat-checkbox
                    [(ngModel)]="allSparePartReceived"
                    (change)="updateSparePartReceived($event)">
                {{'BUTTON.SPARE_PART_RECEIVED' | translate}}
            </mat-checkbox>

            <div class="w-100-p  mt-24">
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                    <!-- si la variable n'existe pas c parcequ'il utilise l'ancien WF-->
                    <ng-container *ngIf="!!maxDurationReceptionSparePart">
                        <ng-container *ngIf="isUnderWarranty ;else IS_HG">
                            <!--todo pour visualiser le bouton il faut ajouter un test sur expirationDate de la commande (si la commande n'est pas expirer on affiche le bouton-->
                            <span class="info link"
                                  (click)="openConfirmationModal()">  {{'BUTTON.SWITCH_TO_SWAP' | translate}}</span>
                        </ng-container>
                        <ng-template #IS_HG>
                            <button mat-button (click)="closeFolder()" style="min-width: auto"
                                    class="aster-modal-btn_cancel" type="reset">
                                {{'BUTTON.CANCEL_FOLDER' | translate}}
                            </button>
                        </ng-template>
                    </ng-container>

                    <ng-container>
                        <div class="w-100-p" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
                            <button mat-stroked-button
                                    type="submit"
                                    (click)="onSubmit()"
                                    [disabled]="!enableSubmitButton()"
                                    [ngClass]="enableSubmitButton() ? 'aster_btn' : 'aster_btn_disabled'">
                                {{'BUTTON.VALIDATE' | translate}}
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
    </mat-card-content>