import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {ConfigurationReferential} from '../../../../../models/configurationReferential.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {ReportModalComponent} from '../../../../../shared/generic/report-modal/report-modal.component';
import {ReparationReport} from '../../../../../models/reparationReport/reparation-report.model';
import {Folder, IFolderUpdateRequest} from '../../../../../models/folder.model';
import {FolderSubjectService} from '../../../folder-subject.service';
import {Quotation} from '../../../../../models/quotation.model';
import {TaskVariables} from '../../task.variables';
import {FolderService} from '../../../../../shared/services/folder.service';
import {CommentType} from '../../../../../models/enums/CommentType.enum';
import {TypeOfServiceEnum} from '../../../../../models/typeOfService.model';
import {RepairStatusType} from '../../../../../models/enums/repairStatusType.enum';
import {SiteType} from '../../../../../models/enums/siteType.enum';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {AppState} from '../../../../../store/app.state';
import {select, Store} from '@ngrx/store';
import {currentUser} from '../../../../../store/user/user.selectors';
import {allConfiguration} from '../../../../../store/configuration/configuration.selectors';
import {Unsubscriber} from '../../../../../unsubscriber';
import {FileService} from '../../../../../shared/services/file.service';
import {AttachmentTypeEnum} from '../../../../../models/enums/attachmentType.enum';
import {FileInfo} from '../../../../../models/file-info.model';
import {FilesUtils} from '../../../../../shared/utils/files-utils';
import {FuseConfirmDialogComponent} from '../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {QuotationStatus} from '../../../../../models/enums/quotationStatus.enum';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-repair-in-progress',
    templateUrl: './repair-in-progress.component.html',
    styleUrls: ['./repair-in-progress.component.scss']
})
export class RepairInProgressComponent extends Unsubscriber implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;

    @ViewChild('dropzone') dropzone: ElementRef;
    carriers = [];

    repairStatusForm: any = {
        form: null,
        placeholders: []
    };

    agentCommentForm: FormGroup;
    reportLineColumns: string[] = ['code', 'label', 'type', 'payer'];
    reportLineDataTable = new MatTableDataSource<any>();

    irisColumns: string[] = ['symptom', 'condition', 'repair', 'defect', 'section'];
    irisDataTable = new MatTableDataSource<any>();

    reparationReport: ReparationReport;
    folder: Folder;
    isChooseOtherCarrier = false;
    currentOrganization: string;
    isLoading = false;
    currentUserSiteCode: string;
    private currency: string;
    attachmentReportDataTable = new MatTableDataSource<any>();
    displayedColumnsAttachment: string[] = ['name', 'action'];
    displayedColumns: string[] = ['code', 'label', 'type', 'price', 'quantity', 'discount', 'discountReason', 'vat', 'totalPrice'];
    fileUploadForm: any = {
        file: null, hasFile: false
    };
    attachments: FileInfo[] = [];

    constructor(private folderService: FolderService,
                private store$: Store<AppState>,
                private matDialog: MatDialog,
                private fileService: FileService,
                private translateService: TranslateService,
                private folderSubjectService: FolderSubjectService) {
        super();
    }

    ngOnInit(): void {
        this.initForm();
        this.initCarrierList();
        this.currency = this.variablesTask[TaskVariables.currency];
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
            if (this.folder.quotations.length > 0) {
                const quotation = this.getQuotation();
                this.reportLineDataTable.data = this.convertToReportLine(!!quotation && quotation.quotationLines.length > 0 ? quotation.quotationLines : null);
            } else if (this.variablesTask[TaskVariables.sparePartsWrapper]) {
                const sparePartsWrapperList = JSON.parse(this.variablesTask[TaskVariables.sparePartsWrapper]);
                sparePartsWrapperList.forEach(item => {
                    const reportLine = {
                        code: item.assetCatalog.code,
                        label: item.assetCatalog.label,
                        type: TypeOfServiceEnum.SPARE_PART
                    };
                    this.reportLineDataTable.data.push(reportLine);
                });
            }
            // if (!!this.folder.reparationReport) {
            //     this.irisDataTable.data = this.folder.reparationReport.iris;
            // }
            this.anotherSubscription = this.store$.pipe(select(currentUser)).subscribe(userDetails => {
                this.currentOrganization = userDetails.organizationCode;
                this.currentUserSiteCode = userDetails.affectedSite;
            });
        });
        this.initAddCommentForm();
        this.initAttachmentsReportTable();
    }

    private initAttachmentsReportTable() {
        this.attachments = this.folder.attachments.filter(value => value.attachmentType === AttachmentTypeEnum.ATTACHEMENT_REPARATION_INTERVENTION_REPORT);
        this.attachmentReportDataTable.data = this.attachments;
    }

    initAddCommentForm(): void {
        this.agentCommentForm = new FormGroup({
            comment: new FormControl(null)
        });
    }

    private initForm(): void {
        this.repairStatusForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            carrier: new FormControl(null),
            trackingNumber: new FormControl(null),
            description: new FormControl(''),
        });


        this.repairStatusForm.placeholders = [
            {
                status: 'NOT_REPAIRABLE',
                value: 'COMPONENT.FOLDER_CLOSED_HS_PRODUCT_DESTRUCTION',
            },
            {
                status: 'FINISHED',
                value: 'COMPONENT.REPARATION.FINISHED',
            }
        ];
    }

    initCarrierList(): void {
        this.carriers = [];
        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.CARRIER}))
            .subscribe(it => it.forEach((carrier: ConfigurationReferential) => {
                this.carriers.push({
                    code: carrier.code,
                    label: carrier.label
                });
            }));
    }

    onSubmit(): void {
        switch (this.repairStatusForm.form.value.status) {
            case RepairStatusType.NOT_REPAIRABLE: {
                this.inputMap.emit({
                    'reparationStatus': this.repairStatusForm.form.value.status,
                    'commentAgentNotRepairable': this.repairStatusForm.form.value.description
                });
                break;
            }
            case RepairStatusType.FINISHED: {
                this.prepareReparationReportData();
                this.updateFolder();
                break;
            }
            default: {
                break;
            }
        }
    }

    updateFolder(): void {
        this.store$.dispatch(new StartLoading());
        const folderUpdateRequest: IFolderUpdateRequest = {
            reparationReport: this.reparationReport
        };

        if (!!this.agentCommentForm.get(['comment']).value) {
            folderUpdateRequest.agentComment = {
                content: this.agentCommentForm.get(['comment']).value,
                type: CommentType.COMMENT_REPORT_AGENT,
            };
        }

        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
            this.folderSubjectService.folderLoaded(folder);
            this.folder = folder;
            this.emitWorkflowVariables();
            this.store$.dispatch(new StopLoading());
        }).catch(() => {
            console.log('ERROR Update Folder with id :' + this.folder.id);
            this.store$.dispatch(new StopLoading());
        });
    }

    private emitWorkflowVariables(): void {
        this.inputMap.emit({
            'reparationStatus': this.repairStatusForm.form.value.status,
            'carrierCode': this.isChooseOtherCarrier ? this.getCarrierCode() : 'CHRONOPOST',
            'trackingNumber': this.repairStatusForm.form.value.trackingNumber
        });
    }

    getCarrierCode(): string {
        return !!this.repairStatusForm.form.value.carrier && !!this.repairStatusForm.form.value.carrier.substr(0, this.repairStatusForm.form.value.carrier.indexOf(' - ')) ?
            this.repairStatusForm.form.value.carrier.substr(0, this.repairStatusForm.form.value.carrier.indexOf(' - ')) : '';
    }

    prepareReparationReportData(): void {
        this.reparationReport = {
            reportLine: this.reportLineDataTable.data,
            iris: []
        };
        // this.irisDataTable.data.forEach(irisLine => {
        //     this.reparationReport.iris.push({
        //         symptom: irisLine.symptom,
        //         condition: irisLine.condition,
        //         reparation: irisLine.reparation,
        //         defect: irisLine.defect,
        //         section: irisLine.section
        //     });
        // });
    }

    openQuotationModal(): void {
        const initData = {
            reportLine: this.convertToReportLine(this.reportLineDataTable.data),
            // iris: this.irisDataTable.data,
            iris: [],
            warrantyReason: this.folder.newWarranty.warrantyReason,
            currency: this.currency,
            loadSparePartCatalog: false,
            hideIris: true,
            loadTypeOfBenefit: false
        };
        const dialogRef = this.matDialog.open(ReportModalComponent, {
            height: '90vh', width: '100vh', minWidth: '80%', data: initData
        });
        dialogRef.afterClosed().subscribe(dataReport => {
                if (!!dataReport) {
                    this.reportLineDataTable.data = dataReport.reportLine;
                    // this.irisDataTable.data = dataReport.iris;
                }
            }
        );
    }

    private convertToReportLine(quotationLines: any) {
        const reportLine = [];
        if (!!quotationLines) {
            quotationLines.forEach(value => {
                reportLine.push(
                    {
                        code: value.code,
                        label: value.label,
                        type: value.type,
                        payer: value.payer,
                        quantity: value.quantity,
                        price: !!value.priceAmount ? value.priceAmount.value : value.price,
                        tva: !!value.vat ? value.vat.rate : value.tva,
                        discountReason: value.discountReason,
                        discount: value.discount,
                        totalLinePrice: this.getTotalLinePrice(value),
                        currency: this.currency
                    }
                );
            });
        }
        return reportLine;
    }

    private getTotalLinePrice(value) {
        return !!value.totalLinePrice ? value.totalLinePrice.value : 0;
    }

    disabledButtonForm(): boolean {
        return this.checkDisableButton();
    }

    checkDisableButton(): boolean {
        let disabled = true;
        switch (this.repairStatusForm.form.value.status) {
            case RepairStatusType.NOT_REPAIRABLE: {
                disabled = false;
                break;
            }

            case RepairStatusType.FINISHED: {
                if ((this.reportLineDataTable.data.length > 0 || this.attachmentReportDataTable.data.length > 0 || !!this.agentCommentForm.get(['comment']).value)
                    && this.FormCarrierIsValid()) {
                    disabled = false;
                }
                break;
            }
            default: {
                break;
            }
        }
        return disabled;
    }

    private FormCarrierIsValid() {
        return !this.isChooseOtherCarrier ||
            (this.isChooseOtherCarrier && (!!this.repairStatusForm.form.value.carrier && !!this.repairStatusForm.form.value.trackingNumber));
    }

    getQuotation(): Quotation {
        let quotationDistributorId = this.variablesTask['quotationDistributorId'];
        if (!quotationDistributorId) {
            return this.folder.quotations
                .filter(value => value.target === 'CLIENT')
                .find(quotation => quotation.status === QuotationStatus.ACCEPTED);
        }
        return this.folder.quotations.filter((x) => x.id === quotationDistributorId)[0];
    }

    chooseOtherCarrier(): void {
        this.isChooseOtherCarrier = !this.isChooseOtherCarrier;
    }

    loadReparationReport(): void {
        this.store$.dispatch(new StartLoading());
        this.folderService.loadReparationReport(this.folder.id).subscribe(report => {
            if (report) {
                this.reportLineDataTable.data = report.reportLine;
                // this.irisDataTable.data = report.iris;
            }
            this.store$.dispatch(new StopLoading());
        }, () => this.store$.dispatch(new StopLoading()));
    }

    changeCarrier(): void {
        this.isLoading = true;
        const inputCarrier = this.repairStatusForm.form.get('carrier').value;
        if (!!inputCarrier) {
            this.carriers = this.carriers.filter(value => value.label.includes(inputCarrier.label));
            this.isLoading = false;
        }
    }

    carrierChanged(event): void {
        this.repairStatusForm.form.get('carrier').setValue(event.option.value.code + ' - ' + event.option.value.label);
    }

    public getManagementSiteCode(): string {
        const sites = this.folder.sites.filter(site => site.type === SiteType.MANAGEMENT_SITE);
        if (sites.length > 0 && sites[0]) {
            return sites[0].code;
        }
        return '';
    }

    public checkReparationStatus(): boolean {
        return this.repairStatusForm.form.value.status === 'NOT_REPAIRABLE';
    }

    private onFilesAdded($event: any): void {
        this.fileUploadForm.hasFile = true;
        this.fileUploadForm.file = $event[0].name;
        const fileChange = this.fileService.onFileChange($event);
        fileChange.reader.onload = () => {
            const blobFile = this.fileService.dataURItoBlob(fileChange.reader.result.toString().split(',')[1]);
            this.uploadFileAndUpdateFolder(blobFile, $event[0]);
        };
    }

    private uploadFileAndUpdateFolder(imageBlob, event): void {
        this.folderService.uploadAttachment(imageBlob, event, this.folder.id, this.folder.organization.code, AttachmentTypeEnum.ATTACHEMENT_REPARATION_INTERVENTION_REPORT)
            .subscribe(fileInfo => {
                if (fileInfo) {
                    this.addFolderAttachment(fileInfo);
                }
            });
    }

    private addFolderAttachment(fileInfo: FileInfo) {
        const folderUpdateRequest = {
            attachment: fileInfo
        };
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(() => {
            this.folder.attachments.push(fileInfo);
            this.attachments = [...this.attachments, fileInfo];
            this.attachmentReportDataTable.data = this.attachments;
            // @ts-ignore
            this.dropzone.reset();
            this.fileUploadForm.hasFile = false;
            this.folderSubjectService.folderLoaded(this.folder);
        });
    }

    private downloadAttachmentFile(fileId, fileName): void {
        this.folderService.getAttachmentFileFolder(fileId)
            .subscribe(resFile => {
                const reader = new FileReader();
                reader.readAsDataURL(resFile);
                reader.onloadend = () => {
                    FilesUtils.saveFile(reader.result, fileName, resFile.type);
                };
            });
    }

    removeFile(file): void {
        const dialogConfirmation = this.matDialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });
        dialogConfirmation.componentInstance.title = this.translateService.instant('CONFIRMATION.MODAL.DELETE_DIALOG.TITLE');
        dialogConfirmation.componentInstance.message = this.translateService.instant('FOLDER.FILE.DELETE_DIALOG.MESSAGE');
        dialogConfirmation.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.DELETE');
        dialogConfirmation.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialogConfirmation.afterClosed().subscribe(deleteFile => {
            if (deleteFile) {
                const index = this.attachments.indexOf(file.id);
                if (index > -1) {
                    this.attachments.splice(index, 1);
                }
                this.folderService.deleteAttachmentFileFolder(this.folder.id, file.id).then(resFile => {
                    this.folderSubjectService.folderLoaded(this.folder);
                    this.attachments = this.attachments.filter(attachment => attachment.id !== file.id);
                    this.attachmentReportDataTable.data = this.attachments;
                }).catch(reason => {
                });
            }
        });
    }

    getTaskDuration(): string {
        return this.variablesTask['reparationMaxDuration'];
    }

    getStatusDate(): string {
        return this.folder.currentWorkflowStatus.workflowStatusDate;
    }
}
