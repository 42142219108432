<ng-container [formGroup]="formGroup">
    <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>{{field.label | translate}}</mat-label>
        <input matInput
               placeholder="{{field.label | translate}}"
               formControlName="{{field.name}}"
               inputControl
               [required]="formGroup.get(field.name) | hasRequiredField"
               (blur)="doOnBlur()"
               (keyup.enter)="doOnEnterKey()">
        <mat-icon matSuffix class="disabled-text">{{getIcon()}}</mat-icon>
    </mat-form-field>
</ng-container>
