
<div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center"  fxLayoutGap="10px">
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
        <ng-container *ngIf="enableToAddUnreferencedSparePart">
            <span class="info spare-part-unreferenced mb-24" (click)="openAddSparePartUnreferencedModal(null)">  {{'ADD.UNREFERENCED_SPARE_PART' | translate}}</span>
        </ng-container>
    </div>

    <mat-card-content>
        <div class="mb-16" *ngIf="!stockAvailable">
            <span class="not-available">{{"SPARE_PARTS.NOT_AVAILABLE"|translate}}</span>
        </div>
        <ng-container *ngIf="isSparePartsAvailable === true ;else no_parts">
            <div translate="yes" class="hide-translate-text">
            <table mat-table [dataSource]="dataSource" class="w-100-p mat-elevation-z4 mt-25">
                <ng-container matColumnDef="reference">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="table-header"> {{'ASSET_CATALOG.FORM.FIELDS.REFERENCE' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <span>{{element.code}}</span>

                            <ng-container *ngIf="element.information">
                            <span>
                                <mat-icon color="warn"
                                          [matTooltip]="element.information"
                                          matTooltipPosition="right">info
                                </mat-icon>
                            </span>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="label">
                    <th mat-header-cell class="table-header"
                        *matHeaderCellDef> {{'ASSET_CATALOG.FORM.FIELDS.LABEL' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.label}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="supplier">
                    <th mat-header-cell class="table-header"
                        *matHeaderCellDef> {{'ASSET_CATALOG.FORM.FIELDS.SUPPLIER' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.supplier?.name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="stock">
                    <th mat-header-cell class="table-header" *matHeaderCellDef>
                       <span  class="notranslate"> {{'ASSET_CATALOG.FORM.FORM.HEADERS.STOCK' | translate}}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer text-center">
                        <ng-container>
                            <div *ngIf="isStockLoading" class="spinner-wrapper">
                                <mat-spinner diameter="20"></mat-spinner>
                            </div>
                            <span *ngIf="!isStockLoading"
                                  [ngClass]="getStockIndicatorClass(element.stock?.status)"
                                  matTooltipClass="multi-line-tooltip"
                                  [matTooltip]="hasStock(element.stock?.status) ? stockDetailsTooltip(element.stock?.stockDetails, element.stock?.totalStock) : ''">
                            {{displayStockStatus(element.stock?.status) | translate}}
                        </span>
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="image">
                    <th mat-header-cell class="table-header"
                        *matHeaderCellDef> {{'ASSET_CATALOG.FORM.FORM.HEADERS.IMAGE' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer text-center">
                        <ng-container *ngIf="!!element.images && element.images.length > 0 else no_image">
                            <div *ngIf="element.images[0].isLoading" class="spinner-wrapper">
                                <mat-spinner diameter="20"></mat-spinner>
                            </div>
                            <img [hidden]="element.images[0].isLoading" height="50" width="50"
                                 (click)="openMultiImageModal(element.images)"
                                 class="cursor-pointer zone-file_img img-product_folder"
                                 (load)="onLoad(element.images[0])"
                                 (error)="onImageError($event)"
                                 [src]="element.images[0].src">
                        </ng-container>
                        <ng-template #no_image>
                            <img height="30" width="30"
                                 class="zone-file_img img-product_folder"
                                 src="assets/icons/spartpart.png">
                        </ng-template>
                    </td>
                </ng-container>


                <ng-container matColumnDef="pricettc">
                    <th mat-header-cell class="table-header"
                        *matHeaderCellDef> {{'ASSET_CATALOG.FORM.FIELDS.SALE.PRICE.TTC' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.isLoading" class="spinner-wrapper">
                            <mat-spinner diameter="20"></mat-spinner>
                        </div>
                        <span *ngIf="!element.isLoading">
                             {{isFreeSparePart(element) ? ('ASSET_CATALOG.FORM.FIELDS.FREE' | translate)
                            : (convertHTtoTTC(element.sellingPrice?.value) * (element.quantity || 1) | currency: element.sellingPrice?.currency :'symbol-narrow')}}
                        </span>
                                     </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell class="table-header" *matHeaderCellDef>
                        <span  class="notranslate"> {{'CODE_LABEL_CONFIG.LIST.GRID_HEADER_ACTIONS' | translate}}</span>
                    </th>
                    <td class="checkbox" mat-cell *matCellDef="let row; let i = index">
                        <mat-checkbox id="{{'spare-'+i}}"
                                      (click)="$event.stopPropagation(); "
                                      (change)="changeSelected($event, row)"
                                      [checked]="selection.isSelected(row)"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="quantity">
                    <th mat-header-cell class="table-header"
                        *matHeaderCellDef> {{'ASSET_CATALOG.FORM.FIELDS.QUANTITY' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="!isSelectedForUpdate(element)">
                            <mat-form-field appearance="outline" class="quantity">
                                <input matInput [valueAsNumber]="element.quantity"
                                       (change)="changeValue(element, $event)" min="1" type="number">
                            </mat-form-field>
                        </ng-container>

                    </td>
                </ng-container>
                <ng-container matColumnDef="edit">
                    <th mat-header-cell class="table-header"
                        *matHeaderCellDef>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <ng-container *ngIf="!isSelectedForUpdate(element) && element.isUnreferenced">
                            <div (click)="openAddSparePartUnreferencedModal(element, true, i)" class="edit-button">
                                <mat-icon class="mr-8">edit</mat-icon>
                            </div>
                        </ng-container>

                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            </div>
        </ng-container>
        <div [hidden]="!isSparePartsAvailable">
            <mat-paginator [pageSizeOptions]="[10]" showFirstLastButtons></mat-paginator>
        </div>
        <ng-template #no_parts>
            <div class="text-center pt-44 pb-44 w-100-p" *ngIf="isSparePartsAvailable === false">
                <h3 class="no-parts-text-color">{{'SPARE_PARTS_NOT_REFERENCED' | translate}}</h3>
            </div>
        </ng-template>
    </mat-card-content>
    <mat-dialog-actions class="mt-24 mb-0">
        <div class="w-100-p">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center"  fxLayoutGap="10px">
                <ng-container *ngIf="canSwitchOperatingMode()">
                   <span class="info link" (click)="openSwitchOperatingModeModal()">  {{'BUTTON.SWITCH_OPERATING_MODE' | translate}}</span>
               </ng-container>

                <ng-container>
                    <div class="w-100-p" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
                        <ng-container *ngIf="isSparePartsAvailable ;else no_parts_button">
                            <button mat-raised-button type="submit" [disabled]="isDisabled() || isLoading"
                                    [ngClass]="(isDisabled() || isLoading ) ? 'aster_btn_disabled' : 'aster_btn'"
                                    (click)="onSubmit()">
                                {{'BUTTON.VALIDATE' | translate}}
                            </button>
                        </ng-container>
                        <ng-template #no_parts_button>
                            <button mat-button class="aster-modal-btn_cancel"
                                    (click)="closeFolder()">
                                {{'BUTTON.CLOSE_FOLDER' | translate}}
                            </button>
                        </ng-template>
                    </div>
                </ng-container>
            </div>
        </div>
    </mat-dialog-actions>
