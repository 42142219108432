import {QuotationRepairerComponent} from './quotaion-repairer/quotation-repairer.component';
import {ParcelToSendComponent} from './parcel-to-send/parcel-to-send.component';
import {ShipmentInProgressComponent} from './shipment-in-progress/shipment-in-progress.component';
import {RepairInProgressComponent} from '../public-folder-workflow/repair-in-progress/repair-in-progress.component';
import {SparePartsOrderWaitingForValidationComponent} from './spare-parts-order-waiting-for-validation/spare-parts-order-waiting-for-validation.component';
import {SparePartsOrderValidatedComponent} from './spare-parts-order-validated/spare-parts-order-validated.component';
import {SparePartsOrderWaitingPreparationComponent} from './spare-parts-order-waiting-preparation/spare-parts-order-waiting-preparation.component';
import {DiagnosisInProgressComponent} from './diagnosis-in-progress/diagnosis-in-progress.component';
import {PublicWaitingPickupComponent} from './public-waiting-pickup/public-waiting-pickup.component';
import {ProgressRepairComponent} from './progress-repair/progress-repair.component';
import {DiagnosisInProgressHomeRepairComponent} from './diagnosis-in-progress-home-repair/diagnosis-in-progress-home-repair.component';
import {DetailsQuotaionRepairerComponent} from '../details-quotaion-repairer/details-quotaion-repairer.component';
import {PublicQuotationAcceptationDistributorComponent} from './public-quotation-acceptation-distributor/public-quotation-acceptation-distributor.component';
import {PublicWaitingForRepairerQuotationComponent} from './public-waiting-for-repairer-quotation/public-waiting-for-repairer-quotation.component';
import {PublicWaitingReparationAgreementComponent} from './public-waiting-reparation-agreement/public-waiting-reparation-agreement.component';
import {PublicOldWaitingReparationAgreementComponent} from './public-old-waiting-reparation-agreement/public-old-waiting-reparation-agreement.component';

export const GENERIC_REPAIRER_COMPONENTS_TASKS = {
    'WAIT_QUOTATION': QuotationRepairerComponent,
    'WAITING_FOR_REPAIRER_QUOTATION': PublicWaitingForRepairerQuotationComponent,

    'QUOTATION_ACCEPTATION_DISTRIBUTOR': PublicQuotationAcceptationDistributorComponent,
    'QUOTATION_ACCEPTATION': DetailsQuotaionRepairerComponent,

    'PARCEL_TO_SEND': ParcelToSendComponent,
    'SHIPMENT_IN_PROGRESS': ShipmentInProgressComponent,
    'DIAGNOSIS_IN_PROGRESS': DiagnosisInProgressComponent,
    'REPAIR_IN_PROGRESS': RepairInProgressComponent,
    'SPARE_PARTS_ORDER_WAITING_VALIDATION': SparePartsOrderWaitingForValidationComponent,
    'SPARE_PARTS_ORDER_VALIDATED': SparePartsOrderValidatedComponent,
    'SPARE_PARTS_ORDER_WAITING_PREPARATION': SparePartsOrderWaitingPreparationComponent,
    'WAITING_PICKUP': PublicWaitingPickupComponent,
    'DIAGNOSIS_IN_PROGRESS_HOME_REPAIR': DiagnosisInProgressHomeRepairComponent,
    'PROGRESS_REPAIR': ProgressRepairComponent,
    'WAITING_REPARATION_AGREEMENT': PublicWaitingReparationAgreementComponent,
    'OLD_WAITING_REPARATION_AGREEMENT': PublicOldWaitingReparationAgreementComponent

};
