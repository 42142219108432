import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {Router} from '@angular/router';
import {NgxImageCompressService} from 'ngx-image-compress';
import {FolderService} from '../../../../../shared/services/folder.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-store-deposit',
    templateUrl: './store-deposit.component.html',
    styleUrls: ['./store-deposit.component.scss']
})
export class StoreDepositComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();

    storeDepositStatusForm: any = {
        form: null,
        placeholders: []
    };

    constructor(private router: Router,
                private imageCompress: NgxImageCompressService,
                private folderService: FolderService) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.storeDepositStatusForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
        });
        this.storeDepositStatusForm.placeholders = [
            {
                status: 'false',
                value: 'BUTTON.PRODUCT_RECEIVED_IN_STORE',
            },
            {
                status: 'true',
                value: 'BUTTON.PRODUCT_REPARED_IN_STORE',
            }
        ];
    }

    onSubmit(): void {
        this.inputMap.emit({
            'REPAIRED_IN_STORE': this.storeDepositStatusForm.form.value.status
        });
    }
}
