<div class="text-center">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
        <h2>
            <mat-icon>note_add</mat-icon>
            {{"SPARE_PART_DETAILS" | translate}}
        </h2>
        <button mat-icon-button class="ml-40" (click)="onCloseModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <table mat-table [dataSource]="dataSource.data" class="w-100-p mat-elevation-z4 mt-25">
            <ng-container matColumnDef="reference">
                <th mat-header-cell
                    *matHeaderCellDef
                    class="table-header"> {{'ASSET_CATALOG.FORM.FIELDS.REFERENCE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <span>{{element.code}}</span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="label">
                <th mat-header-cell class="table-header"
                    *matHeaderCellDef> {{'ASSET_CATALOG.FORM.FIELDS.LABEL' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        {{element.label}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="stock">
                <th mat-header-cell class="table-header" *matHeaderCellDef>
                    {{'ASSET_CATALOG.FORM.FORM.HEADERS.STOCK' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        {{"SPARE_PART.STATUS." + element.status|translate}}

                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </mat-dialog-content>
    <mat-dialog-actions class="mt-16">
        <div class="w-100-p">
            <div fxLayout="row" fxLayoutAlign="end end">
                <div>
                    <button type="button" mat-button matTooltip="{{'BUTTON.CHOOSE_OTHER_SPARE_PARTS' | translate}}"
                            id="previewButton" (click)="onPreviewTask()"
                            class="aster-btn_border">
                        {{'BUTTON.CHOOSE_OTHER_SPARE_PARTS' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </mat-dialog-actions>
</div>
