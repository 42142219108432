import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {Unsubscriber} from '../../app/unsubscriber';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {isClaimSensibleHiddenField} from '../../app/store/organization/organization.selectors';


@Directive({
    selector: '[hideSensibleInfo]'
})
export class HideSensibleInfoDirective extends Unsubscriber implements OnInit {
    private field: string;
    @Input('hideSensibleInfo') set myField(text: string) {
        this.field = text;
    }

    constructor(
        private store$: Store<AppState>,
        private el: ElementRef) {
        super();
    }

    ngOnInit() {
        this.anotherSubscription = this.store$.pipe(select(isClaimSensibleHiddenField, {field: this.field}))
            .subscribe(isClaimSensibleHiddenField => {
                if (isClaimSensibleHiddenField) {
                    this.el.nativeElement.innerText = '***********';
                }
            });
    }
}
