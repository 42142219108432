import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TaskVariables} from '../../task.variables';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-client-swap',
    templateUrl: './waiting-client-swap.component.html',
    styleUrls: ['./waiting-client-swap.component.css']
})
export class WaitingClientSwapComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    folderId: string;

    exchangeStatusForm: any = {
        form: null,
        placeholders: []
    };

    constructor(private router: Router,
                private  route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.initForm();
        this.folderId = this.route.snapshot.parent.params.folderId;
    }

    private initForm(): void {
        this.exchangeStatusForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
        });
        this.exchangeStatusForm.placeholders = [
            {
                status: 'true',
                value: 'BUTTON.VALIDATE_EXCHANGE',
            },
            {
                status: 'false',
                value: 'BUTTON.PRODUCT_REPARED_IN_STORE',
            },
        ];
    }

    onSubmit(): void {
        const validateExchange = TaskVariables.validateExchange;
        this.inputMap.emit({
            validateExchange: this.exchangeStatusForm.form.value.status
        });
    }

}
