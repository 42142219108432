export interface ICodeLabelObjectWithExtra<T> {
    id: string;
    organizationCode?: string;
    code: string;
    label: string;
    extra: T;
}

export enum ConfigExtraCodesEnum {
    ATTACHMENT_TYPE = 'ATTACHMENT_TYPE',
    REPAIRER = 'REPAIRER',
    SITE = 'SITE',
    SUPPLIER = 'SUPPLIER',
    BRANDS = 'BRANDS',
    WIDGET = 'WIDGET',
    ASSET_CATALOG = 'ASSET_CATALOG',
    DASHBOARD = 'DASHBOARD',
    DASHBOARD_V2 = 'DASHBOARD_V2',
    PRODUCT_IDENTIFICATION_BRAND = 'PRODUCT_IDENTIFICATION_BRAND',
    PRODUCT_IDENTIFICATION_CATEGORY = 'PRODUCT_IDENTIFICATION_CATEGORY',
    REPAIR_PACKAGES = 'REPAIR_PACKAGES',

}
