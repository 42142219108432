    <mat-card-content *ngIf="checkConformityForm.form">
        <form [formGroup]="checkConformityForm.form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <mat-radio-group formControlName="status" class="ml-20" labelPosition="after" fxLayout="column"
                             fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button class="full-width-radio" *ngFor="let status of checkConformityForm.placeholders"
                                  [value]="status.status">
                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                        <div fxFlex>
                            <div>{{ status.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="checkConformityForm.form.value.status === 'PRODUCT_NOT_COMPLIANT'">
                <div fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px"  class="ml-20">
                    <label class="h3  text-bold" *ngIf="!isChooseOtherCarrier">{{'PRODUCT_RETURNED_WITH_CHRONOPOST' |translate}}</label>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center">
                        <mat-checkbox (change)="chooseOtherCarrier()"
                                      [checked]="isChooseOtherCarrier">
                            {{'CHOOSE_OTHER_CARRIER' | translate}}
                        </mat-checkbox>
                    </div>
                    <div *ngIf="isChooseOtherCarrier" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px"  class="ml-20">
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>{{'FOLDER.TRACKING_NUMBER'|translate}}</mat-label>
                            <input matInput placeholder="{{'FOLDER.TRACKING_NUMBER'|translate}}"
                                   formControlName="trackingNumber">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>{{'FOLDER.CARRIER_CODE'|translate}}</mat-label>
                            <input matInput placeholder="{{'FOLDER.CARRIER_CODE'|translate}}"
                                   formControlName="carrier">
                        </mat-form-field>
                    </div>
                    <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                         <mat-label>{{'FOLDER.COMMENT_FORM.LABEL' | translate}}</mat-label>
                         <textarea matInput rows="2" cols="7"
                                   [placeholder]="'PRODUCT_NOT_COMPLIANT_PLACEHOLDER' | translate"
                                   formControlName="description"></textarea>
                     </mat-form-field>
                </div>
            </ng-container>

            <div fxLayoutAlign="end center" class="mt-24">
                <button type="submit" mat-stroked-button
                        [ngClass]="checkConformityForm.form.invalid ? 'aster_btn_disabled' : 'aster_btn'"
                        [disabled]="checkConformityForm.form.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>