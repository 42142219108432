<mat-card class="mat-elevation-aster">
    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start center">
            <div>
                <span class="h2 ml-8  pt-4">
                    {{'COMPONENT.GO_TO_STORE.TITLE' | translate}}
                </span>
            </div>
        </div>
        <div class="mt-24">
            <div>
                <mat-checkbox [(ngModel)]="refundInStore">{{'CHECKBOX.REFUNDED_IN_STORE' | translate}}</mat-checkbox>
            </div>
            <div fxLayout="row" fxLayoutAlign="end">
                <button mat-stroked-button (click)="onSubmit()"
                        type="submit"
                        [disabled]="!refundInStore"
                        [ngClass]="!refundInStore?'h-60 mt-4  aster_btn_disabled':'h-60 mt-4 aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>