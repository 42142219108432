<div class="page-layout simple fullwidth mat-elevation-z4">
    <table class="mat-table">
        <tr>
            <th class="mat-header-cell table-header pt-12 pb-12 w-20-p">{{'FOLDER.COMMENT.TYPE' | translate}}</th>
            <th class="mat-header-cell table-header pt-12 pb-12 w-20-p">{{'FOLDER.COMMENT.CONTENT' | translate}}</th>
            <th class="mat-header-cell table-header pt-12 pb-12 w-20-p">{{'FOLDER.COMMENT.DATE' | translate}}</th>
            <th class="mat-header-cell table-header pt-12 pb-12 w-20-p">{{'FOLDER.COMMENT.USER'|translate}}</th>
        </tr>
        <ng-container *ngIf="!!comments">
            <ng-container *ngFor="let comment of comments | keyvalue:indexOrderAsc;">
                <tr *ngFor="let detail of comment.value;let i=index;" class="text-left">
                    <td *ngIf="i===0"
                        [attr.rowspan]="comment.value.length"
                        class="mat-cell pt-12 pb-12 w-20-p">{{'COMPONENT.' + comment.key.toString() | translate}}</td>
                    <td class="mat-cell pt-12 pb-12 w-20-p">{{detail.content}}</td>
                    <td class="mat-cell pt-12 pb-12 w-20-p">{{detail.createdBy.actionDate | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate) : (currentLanguage$ | async)}}</td>
                    <td class="mat-cell pt-12 pb-12 w-20-p">{{ detail.createdBy.user.fullName | translate }}
                    </td>
                </tr>
            </ng-container>
        </ng-container>
    </table>

</div>

