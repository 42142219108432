import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Unsubscriber} from '../../../../unsubscriber';
import {AppState} from '../../../../store/app.state';
import {ConfigV2Service} from '../../../../shared/services/config-v2.service';
import {CodeLabel} from '../../../../models/element.model';
import {ConfigCodesEnum} from '../../../../models/codeLabelObject.model';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {getModOpIconNameByCode} from '../../../../shared/data/static.config';
import {switchMap} from 'rxjs/operators';
import {ConfigurationUtils} from '../../../../store/configuration/configuration.utils';

@Component({
    selector: 'operating-mode-selector',
    templateUrl: './operating-mode-selector.component.html'
})
export class OperatingModeSelectorComponent extends Unsubscriber implements OnInit {

    @Input() selectedOperatingMode: string;
    @Output() selectedOperatingModeChange = new EventEmitter<string>();

    operatingModes: CodeLabel[] = [];
    firstTimeLoading = true;

    constructor(private configService: ConfigV2Service,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit() {
        if (this.selectedOperatingMode) {
            this.loadModOps();
        }
    }

    loadModOps() {
        if (this.firstTimeLoading) {
            this.triggerListeningToLanguageChanges();
            this.firstTimeLoading = false;
        }
    }

    private triggerListeningToLanguageChanges() {
        this.anotherSubscription = this.store$.pipe(
            select(currentLanguage),
            switchMap(() => this.configService.findLocalizedValuesOf([ConfigCodesEnum.MOD_OP]))
        ).subscribe(operatingModes => this.operatingModes = operatingModes);
    }

    updateSelectedOperatingMode(value) {
        this.selectedOperatingMode = value;
        this.selectedOperatingModeChange.emit(this.selectedOperatingMode);
    }

    getIconName = (operatingMode: string) => getModOpIconNameByCode(operatingMode);
    labelFromCode = (operatingMode: string) => ConfigurationUtils.labelFromCode(operatingMode, this.operatingModes);
}
