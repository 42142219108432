import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BackOfficeService} from 'app/shared/services/back-office.service';
import {ConfigExtraCodesEnum, ICodeLabelObjectWithExtra} from '../../../../../../../models/codeLabelObjectWithExtra.model';
import {FuseConfirmDialogComponent} from '../../../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../../../store/loader/loader.actions';
import {organizationCode} from '../../../../../../../store/user/user.selectors';
import {Unsubscriber} from '../../../../../../../unsubscriber';
import {PageSearchRequest} from '../../../../../../../models/page-search-request.model';
import {SnackBarService} from '../../../../../../../shared/services/snack-bar.service';

@Component({
    selector: 'app-widget-list',
    templateUrl: './widget-list.component.html',
    styleUrls: ['./widget-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class WidgetListComponent extends Unsubscriber implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    configExtraCodes = ConfigExtraCodesEnum;
    configCode: ConfigExtraCodesEnum = ConfigExtraCodesEnum.WIDGET;

    codeLabelObjectsDataSource: any = {
        dataSource: new MatTableDataSource(),
        columnsToDisplay: ['code', 'label', 'actions'],
        expandedElement: null,
        filterForm: null,
        page: null,
        pageSizeOptions: [10, 25, 50, 100],
    };

    organizationCode: string;

    constructor(private dialog: MatDialog,
                private snackBar: SnackBarService,
                private backOfficeService: BackOfficeService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private translateService: TranslateService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(
            select(organizationCode))
            .subscribe(organizationCode => {
                this.organizationCode = organizationCode;
                this.initCodeLabelObjectsDataSource();
                this.initFilterForm();
            });
    }

    paginate(event: PageEvent): void {
        this.router.navigate([], {
            queryParams: {
                page: event.pageIndex,
                size: event.pageSize
            },
            queryParamsHandling: 'merge',
        }).finally(() => {
            this.loadCodeLabelObjectsData();
        });
    }


    initCodeLabelObjectsDataSource(): void {
        this.codeLabelObjectsDataSource.dataSource.paginator = this.paginator;

        this.loadCodeLabelObjectsData();
    }

    loadCodeLabelObjectsData(): void {
        this.store$.dispatch(new StartLoading());
        const pageCriteria: PageSearchRequest = {
            page: this.getPageIndex(),
            size: this.getPageSize(),
        };
        const inputs = new Map();
        inputs.set('organizationCode', this.organizationCode);
        if (this.getCodeQuery().length > 0) {
            inputs.set('code', this.getCodeQuery());
        }
        if (this.getLabelQuery().length > 0) {
            inputs.set('label', this.getLabelQuery());
        }
        this.backOfficeService.bulkSearchExtraCodeLabelObjects(this.configCode, inputs, pageCriteria).then(page => {
            this.codeLabelObjectsDataSource.dataSource.data = page.content;
            this.codeLabelObjectsDataSource.page = page;
            this.store$.dispatch(new StopLoading());
        }).catch(() => {
            this.codeLabelObjectsDataSource.dataSource.data = [];
            this.store$.dispatch(new StopLoading());
        });
    }

    getPageIndex(): number {
        const pageIndex = this.activatedRoute.snapshot.queryParams.page;
        return pageIndex > 0 ? pageIndex : 0;
    }

    getPageSize(): number {
        const pageSize = this.activatedRoute.snapshot.queryParams.size;
        return pageSize > 0 ? pageSize : 10;
    }

    getCodeQuery(): string {
        const codeQuery = this.activatedRoute.snapshot.queryParams.codeQuery;
        return !!codeQuery ? codeQuery : '';
    }

    getLabelQuery(): string {
        const codeQuery = this.activatedRoute.snapshot.queryParams.labelQuery;
        return !!codeQuery ? codeQuery : '';
    }


    initFilterForm(): void {
        this.codeLabelObjectsDataSource.filterForm = new FormGroup({
            codeQuery: new FormControl(''),
            labelQuery: new FormControl(''),
        });

        this.codeLabelObjectsDataSource.filterForm.get(['codeQuery']).setValue(this.getCodeQuery());
        this.codeLabelObjectsDataSource.filterForm.get(['labelQuery']).setValue(this.getLabelQuery());
    }

    onSearch(): void {
        this.loadSearchResult();
    }

    onClearSearchFormFiled(fieldName: string): void {
        this.codeLabelObjectsDataSource.filterForm.get([fieldName]).setValue('');
        this.loadSearchResult();
    }

    onResetSearchFilter(): void {
        this.codeLabelObjectsDataSource.filterForm.get(['codeQuery']).setValue('');
        this.codeLabelObjectsDataSource.filterForm.get(['labelQuery']).setValue('');
        this.loadSearchResult();
    }

    loadSearchResult(): void {
        const queries: any = {};
        queries.codeQuery = this.codeLabelObjectsDataSource.filterForm.value.codeQuery;
        queries.labelQuery = this.codeLabelObjectsDataSource.filterForm.value.labelQuery;
        this.router.navigate([], {
            queryParams: queries,
            queryParamsHandling: 'merge',
        }).finally(() => {
            this.loadCodeLabelObjectsData();
        });
    }

    onCreateNew(): void {
        this.router.navigate(['dashboard/widget/add']);
    }

    onEdit(widget: ICodeLabelObjectWithExtra<any>): void {
        this.router.navigate(['dashboard/widget/update/' + widget.id]);
    }

    onDelete(index: number, symptom: any): void {
        const dialogRef: MatDialogRef<FuseConfirmDialogComponent> = this.dialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });

        dialogRef.componentInstance.title = 'CODE_LABEL_CONFIG.DELETE_DIALOG.TITLE';
        dialogRef.componentInstance.message = this.translateService.instant('CODE_LABEL_CONFIG.DELETE_DIALOG.MESSAGE', {
            code: symptom.code,
            configCodeName: this.translateService.instant(`CODE_LABEL_CONFIG.CONFIG_CODES.${this.configCode}`)
        });
        dialogRef.componentInstance.confirmButtonLabel = 'CODE_LABEL_CONFIG.DELETE_DIALOG.CONFIRM_BUTTON';
        dialogRef.componentInstance.cancelButtonLabel = 'CODE_LABEL_CONFIG.DELETE_DIALOG.CANCEL_BUTTON';

        this.anotherSubscription = dialogRef.afterClosed().subscribe(isDeleteConfirmed => {
            if (isDeleteConfirmed) {
                this.backOfficeService.deleteExtraCodeLabelObject(this.configCode, symptom.id).then(() => {
                    this.showSnackBar('SUCCESS_DELETE');
                    this.codeLabelObjectsDataSource.dataSource.data.splice(index, 1);
                    this.codeLabelObjectsDataSource.dataSource.connect().next(this.codeLabelObjectsDataSource.dataSource.data);

                    this.codeLabelObjectsDataSource.page.totalElements -= 1;
                });
            }
        });
    }

    showSnackBar(key: string): void {
        const snackBarConfigs: any[] = [
            {
                key: 'SUCCESS_CREATE',
                snackType: 'Info',
                messageI18nKey: 'CODE_LABEL_CONFIG.FORM.TOAST_MESSAGES.SUCCESS_CREATE'
            },
            {
                key: 'SUCCESS_UPDATE',
                snackType: 'Info',
                messageI18nKey: 'CODE_LABEL_CONFIG.FORM.TOAST_MESSAGES.SUCCESS_UPDATE'
            },
            {
                key: 'SUCCESS_DELETE',
                snackType: 'Error',
                messageI18nKey: 'CODE_LABEL_CONFIG.FORM.TOAST_MESSAGES.SUCCESS_DELETE'
            },
        ];
        const currentConfig = snackBarConfigs.filter(item => item.key === key)[0];
        if (!!currentConfig) {
            this.snackBar.openAtStart(currentConfig.snackType, currentConfig.messageI18nKey, {
                configCodeName: this.translateService.instant(`CODE_LABEL_CONFIG.CONFIG_CODES.${this.configCode}`)
            });
        }
    }

    resetSearch(key): void {
        if (key === '') {
            this.loadSearchResult();
        }
    }

}
