import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProductAccessory} from '../../../models/ProductAccessory';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {SelectionModel} from '@angular/cdk/collections';
import {Folder} from '../../../models/folder.model';

@Component({
  selector: 'app-product-accessories',
  templateUrl: './product-accessories.component.html',
  styleUrls: ['./product-accessories.component.scss']
})
export class ProductAccessoriesComponent {

    @Input() claim: Folder;
    @Output() productAccessoriesEmitter = new EventEmitter<ProductAccessory[]>();
    selection = new SelectionModel<ProductAccessory>(true, []);

  constructor() { }

    onSelectAccessory(accessory: ProductAccessory, $event: MatCheckboxChange){
        $event.checked ? this.selection.select(accessory) : this.selection.deselect(accessory);
        this.productAccessoriesEmitter.emit(this.selection.selected);
    }

}
