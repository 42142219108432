import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {FlexLayoutModule} from '@angular/flex-layout';

import {FuseDirectivesModule} from '@fuse/directives/directives';
import {FusePipesModule} from '@fuse/pipes/pipes.module';
import {MaterialModule} from './material/material.module';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {FuseConfirmDialogModule} from './components';
import {IconSvgModule} from './IconSvg/IconSvg.module';
import {StorageServiceModule} from 'ngx-webstorage-service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule,
        FuseConfirmDialogModule,
        MaterialModule,
        NgxMatSelectSearchModule,
        NgxDropzoneModule,
        StorageServiceModule,
        IconSvgModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule,
        FuseConfirmDialogModule,
        MaterialModule,
        NgxMatSelectSearchModule,
        NgxDropzoneModule,
        StorageServiceModule,
        IconSvgModule
    ]
})
export class FuseSharedModule {
}
