/** ISO 3166-1 alpha-2 country code to country name conversion with a simple Javascript implementation, an array and a function.
 * Link: https://gist.github.com/chlegou/2e0c643f1fcd2005f9f7cf4fb82606aa
 */
export class CountriesUtils {
    static COUNTRIES_LIST: any[] = [
        {
            code: 'AF',
            name: 'Afghanistan'
        },
        {
            code: 'AX',
            name: 'Åland Islands'
        },
        {
            code: 'AL',
            name: 'Albania'
        },
        {
            code: 'DZ',
            name: 'Algeria'
        },
        {
            code: 'AS',
            name: 'American Samoa'
        },
        {
            code: 'AD',
            name: 'Andorra'
        },
        {
            code: 'AO',
            name: 'Angola'
        },
        {
            code: 'AI',
            name: 'Anguilla'
        },
        {
            code: 'AQ',
            name: 'Antarctica'
        },
        {
            code: 'AG',
            name: 'Antigua and Barbuda'
        },
        {
            code: 'AR',
            name: 'Argentina'
        },
        {
            code: 'AM',
            name: 'Armenia'
        },
        {
            code: 'AW',
            name: 'Aruba'
        },
        {
            code: 'AU',
            name: 'Australia'
        },
        {
            code: 'AT',
            name: 'Austria'
        },
        {
            code: 'AZ',
            name: 'Azerbaijan'
        },
        {
            code: 'BS',
            name: 'Bahamas'
        },
        {
            code: 'BH',
            name: 'Bahrain'
        },
        {
            code: 'BD',
            name: 'Bangladesh'
        },
        {
            code: 'BB',
            name: 'Barbados'
        },
        {
            code: 'BY',
            name: 'Belarus'
        },
        {
            code: 'BE',
            name: 'Belgium'
        },
        {
            code: 'BZ',
            name: 'Belize'
        },
        {
            code: 'BJ',
            name: 'Benin'
        },
        {
            code: 'BM',
            name: 'Bermuda'
        },
        {
            code: 'BT',
            name: 'Bhutan'
        },
        {
            code: 'BO',
            name: 'Bolivia, Plurinational State of'
        },
        {
            code: 'BQ',
            name: 'Bonaire, Sint Eustatius and Saba'
        },
        {
            code: 'BA',
            name: 'Bosnia and Herzegovina'
        },
        {
            code: 'BW',
            name: 'Botswana'
        },
        {
            code: 'BV',
            name: 'Bouvet Island'
        },
        {
            code: 'BR',
            name: 'Brazil'
        },
        {
            code: 'IO',
            name: 'British Indian Ocean Territory'
        },
        {
            code: 'BN',
            name: 'Brunei Darussalam'
        },
        {
            code: 'BG',
            name: 'Bulgaria'
        },
        {
            code: 'BF',
            name: 'Burkina Faso'
        },
        {
            code: 'BI',
            name: 'Burundi'
        },
        {
            code: 'KH',
            name: 'Cambodia'
        },
        {
            code: 'CM',
            name: 'Cameroon'
        },
        {
            code: 'CA',
            name: 'Canada'
        },
        {
            code: 'CV',
            name: 'Cape Verde'
        },
        {
            code: 'KY',
            name: 'Cayman Islands'
        },
        {
            code: 'CF',
            name: 'Central African Republic'
        },
        {
            code: 'TD',
            name: 'Chad'
        },
        {
            code: 'CL',
            name: 'Chile'
        },
        {
            code: 'CN',
            name: 'China'
        },
        {
            code: 'CX',
            name: 'Christmas Island'
        },
        {
            code: 'CC',
            name: 'Cocos (Keeling) Islands'
        },
        {
            code: 'CO',
            name: 'Colombia'
        },
        {
            code: 'KM',
            name: 'Comoros'
        },
        {
            code: 'CG',
            name: 'Congo'
        },
        {
            code: 'CD',
            name: 'Congo, the Democratic Republic of the'
        },
        {
            code: 'CK',
            name: 'Cook Islands'
        },
        {
            code: 'CR',
            name: 'Costa Rica'
        },
        {
            code: 'CI',
            name: 'Côte d\'Ivoire'
        },
        {
            code: 'HR',
            name: 'Croatia'
        },
        {
            code: 'CU',
            name: 'Cuba'
        },
        {
            code: 'CW',
            name: 'Curaçao'
        },
        {
            code: 'CY',
            name: 'Cyprus'
        },
        {
            code: 'CZ',
            name: 'Czech Republic'
        },
        {
            code: 'DK',
            name: 'Denmark'
        },
        {
            code: 'DJ',
            name: 'Djibouti'
        },
        {
            code: 'DM',
            name: 'Dominica'
        },
        {
            code: 'DO',
            name: 'Dominican Republic'
        },
        {
            code: 'EC',
            name: 'Ecuador'
        },
        {
            code: 'EG',
            name: 'Egypt'
        },
        {
            code: 'SV',
            name: 'El Salvador'
        },
        {
            code: 'GQ',
            name: 'Equatorial Guinea'
        },
        {
            code: 'ER',
            name: 'Eritrea'
        },
        {
            code: 'EE',
            name: 'Estonia'
        },
        {
            code: 'ET',
            name: 'Ethiopia'
        },
        {
            code: 'FK',
            name: 'Falkland Islands (Malvinas)'
        },
        {
            code: 'FO',
            name: 'Faroe Islands'
        },
        {
            code: 'FJ',
            name: 'Fiji'
        },
        {
            code: 'FI',
            name: 'Finland'
        },
        {
            code: 'FR',
            name: 'France'
        },
        {
            code: 'GF',
            name: 'French Guiana'
        },
        {
            code: 'PF',
            name: 'French Polynesia'
        },
        {
            code: 'TF',
            name: 'French Southern Territories'
        },
        {
            code: 'GA',
            name: 'Gabon'
        },
        {
            code: 'GM',
            name: 'Gambia'
        },
        {
            code: 'GE',
            name: 'Georgia'
        },
        {
            code: 'DE',
            name: 'Germany'
        },
        {
            code: 'GH',
            name: 'Ghana'
        },
        {
            code: 'GI',
            name: 'Gibraltar'
        },
        {
            code: 'GR',
            name: 'Greece'
        },
        {
            code: 'GL',
            name: 'Greenland'
        },
        {
            code: 'GD',
            name: 'Grenada'
        },
        {
            code: 'GP',
            name: 'Guadeloupe'
        },
        {
            code: 'GU',
            name: 'Guam'
        },
        {
            code: 'GT',
            name: 'Guatemala'
        },
        {
            code: 'GG',
            name: 'Guernsey'
        },
        {
            code: 'GN',
            name: 'Guinea'
        },
        {
            code: 'GW',
            name: 'Guinea-Bissau'
        },
        {
            code: 'GY',
            name: 'Guyana'
        },
        {
            code: 'HT',
            name: 'Haiti'
        },
        {
            code: 'HM',
            name: 'Heard Island and McDonald Islands'
        },
        {
            code: 'VA',
            name: 'Holy See (Vatican City State)'
        },
        {
            code: 'HN',
            name: 'Honduras'
        },
        {
            code: 'HK',
            name: 'Hong Kong'
        },
        {
            code: 'HU',
            name: 'Hungary'
        },
        {
            code: 'IS',
            name: 'Iceland'
        },
        {
            code: 'IN',
            name: 'India'
        },
        {
            code: 'ID',
            name: 'Indonesia'
        },
        {
            code: 'IR',
            name: 'Iran, Islamic Republic of'
        },
        {
            code: 'IQ',
            name: 'Iraq'
        },
        {
            code: 'IE',
            name: 'Ireland'
        },
        {
            code: 'IM',
            name: 'Isle of Man'
        },
        {
            code: 'IL',
            name: 'Israel'
        },
        {
            code: 'IT',
            name: 'Italy'
        },
        {
            code: 'JM',
            name: 'Jamaica'
        },
        {
            code: 'JP',
            name: 'Japan'
        },
        {
            code: 'JE',
            name: 'Jersey'
        },
        {
            code: 'JO',
            name: 'Jordan'
        },
        {
            code: 'KZ',
            name: 'Kazakhstan'
        },
        {
            code: 'KE',
            name: 'Kenya'
        },
        {
            code: 'KI',
            name: 'Kiribati'
        },
        {
            code: 'KP',
            name: 'Korea, Democratic People\'s Republic of'
        },
        {
            code: 'KR',
            name: 'Korea, Republic of'
        },
        {
            code: 'KW',
            name: 'Kuwait'
        },
        {
            code: 'KG',
            name: 'Kyrgyzstan'
        },
        {
            code: 'LA',
            name: 'Lao People\'s Democratic Republic'
        },
        {
            code: 'LV',
            name: 'Latvia'
        },
        {
            code: 'LB',
            name: 'Lebanon'
        },
        {
            code: 'LS',
            name: 'Lesotho'
        },
        {
            code: 'LR',
            name: 'Liberia'
        },
        {
            code: 'LY',
            name: 'Libya'
        },
        {
            code: 'LI',
            name: 'Liechtenstein'
        },
        {
            code: 'LT',
            name: 'Lithuania'
        },
        {
            code: 'LU',
            name: 'Luxembourg'
        },
        {
            code: 'MO',
            name: 'Macao'
        },
        {
            code: 'MK',
            name: 'Macedonia, the Former Yugoslav Republic of'
        },
        {
            code: 'MG',
            name: 'Madagascar'
        },
        {
            code: 'MW',
            name: 'Malawi'
        },
        {
            code: 'MY',
            name: 'Malaysia'
        },
        {
            code: 'MV',
            name: 'Maldives'
        },
        {
            code: 'ML',
            name: 'Mali'
        },
        {
            code: 'MT',
            name: 'Malta'
        },
        {
            code: 'MH',
            name: 'Marshall Islands'
        },
        {
            code: 'MQ',
            name: 'Martinique'
        },
        {
            code: 'MR',
            name: 'Mauritania'
        },
        {
            code: 'MU',
            name: 'Mauritius'
        },
        {
            code: 'YT',
            name: 'Mayotte'
        },
        {
            code: 'MX',
            name: 'Mexico'
        },
        {
            code: 'FM',
            name: 'Micronesia, Federated States of'
        },
        {
            code: 'MD',
            name: 'Moldova, Republic of'
        },
        {
            code: 'MC',
            name: 'Monaco'
        },
        {
            code: 'MN',
            name: 'Mongolia'
        },
        {
            code: 'ME',
            name: 'Montenegro'
        },
        {
            code: 'MS',
            name: 'Montserrat'
        },
        {
            code: 'MA',
            name: 'Morocco'
        },
        {
            code: 'MZ',
            name: 'Mozambique'
        },
        {
            code: 'MM',
            name: 'Myanmar'
        },
        {
            code: 'NA',
            name: 'Namibia'
        },
        {
            code: 'NR',
            name: 'Nauru'
        },
        {
            code: 'NP',
            name: 'Nepal'
        },
        {
            code: 'NL',
            name: 'Netherlands'
        },
        {
            code: 'NC',
            name: 'New Caledonia'
        },
        {
            code: 'NZ',
            name: 'New Zealand'
        },
        {
            code: 'NI',
            name: 'Nicaragua'
        },
        {
            code: 'NE',
            name: 'Niger'
        },
        {
            code: 'NG',
            name: 'Nigeria'
        },
        {
            code: 'NU',
            name: 'Niue'
        },
        {
            code: 'NF',
            name: 'Norfolk Island'
        },
        {
            code: 'MP',
            name: 'Northern Mariana Islands'
        },
        {
            code: 'NO',
            name: 'Norway'
        },
        {
            code: 'OM',
            name: 'Oman'
        },
        {
            code: 'PK',
            name: 'Pakistan'
        },
        {
            code: 'PW',
            name: 'Palau'
        },
        {
            code: 'PS',
            name: 'Palestine, State of'
        },
        {
            code: 'PA',
            name: 'Panama'
        },
        {
            code: 'PG',
            name: 'Papua New Guinea'
        },
        {
            code: 'PY',
            name: 'Paraguay'
        },
        {
            code: 'PE',
            name: 'Peru'
        },
        {
            code: 'PH',
            name: 'Philippines'
        },
        {
            code: 'PN',
            name: 'Pitcairn'
        },
        {
            code: 'PL',
            name: 'Poland'
        },
        {
            code: 'PT',
            name: 'Portugal'
        },
        {
            code: 'PR',
            name: 'Puerto Rico'
        },
        {
            code: 'QA',
            name: 'Qatar'
        },
        {
            code: 'RE',
            name: 'Réunion'
        },
        {
            code: 'RO',
            name: 'Romania'
        },
        {
            code: 'RU',
            name: 'Russian Federation'
        },
        {
            code: 'RW',
            name: 'Rwanda'
        },
        {
            code: 'BL',
            name: 'Saint Barthélemy'
        },
        {
            code: 'SH',
            name: 'Saint Helena, Ascension and Tristan da Cunha'
        },
        {
            code: 'KN',
            name: 'Saint Kitts and Nevis'
        },
        {
            code: 'LC',
            name: 'Saint Lucia'
        },
        {
            code: 'MF',
            name: 'Saint Martin (French part)'
        },
        {
            code: 'PM',
            name: 'Saint Pierre and Miquelon'
        },
        {
            code: 'VC',
            name: 'Saint Vincent and the Grenadines'
        },
        {
            code: 'WS',
            name: 'Samoa'
        },
        {
            code: 'SM',
            name: 'San Marino'
        },
        {
            code: 'ST',
            name: 'Sao Tome and Principe'
        },
        {
            code: 'SA',
            name: 'Saudi Arabia'
        },
        {
            code: 'SN',
            name: 'Senegal'
        },
        {
            code: 'RS',
            name: 'Serbia'
        },
        {
            code: 'SC',
            name: 'Seychelles'
        },
        {
            code: 'SL',
            name: 'Sierra Leone'
        },
        {
            code: 'SG',
            name: 'Singapore'
        },
        {
            code: 'SX',
            name: 'Sint Maarten (Dutch part)'
        },
        {
            code: 'SK',
            name: 'Slovakia'
        },
        {
            code: 'SI',
            name: 'Slovenia'
        },
        {
            code: 'SB',
            name: 'Solomon Islands'
        },
        {
            code: 'SO',
            name: 'Somalia'
        },
        {
            code: 'ZA',
            name: 'South Africa'
        },
        {
            code: 'GS',
            name: 'South Georgia and the South Sandwich Islands'
        },
        {
            code: 'SS',
            name: 'South Sudan'
        },
        {
            code: 'ES',
            name: 'Spain'
        },
        {
            code: 'LK',
            name: 'Sri Lanka'
        },
        {
            code: 'SD',
            name: 'Sudan'
        },
        {
            code: 'SR',
            name: 'Suriname'
        },
        {
            code: 'SJ',
            name: 'Svalbard and Jan Mayen'
        },
        {
            code: 'SZ',
            name: 'Swaziland'
        },
        {
            code: 'SE',
            name: 'Sweden'
        },
        {
            code: 'CH',
            name: 'Switzerland'
        },
        {
            code: 'SY',
            name: 'Syrian Arab Republic'
        },
        {
            code: 'TW',
            name: 'Taiwan, Province of China'
        },
        {
            code: 'TJ',
            name: 'Tajikistan'
        },
        {
            code: 'TZ',
            name: 'Tanzania, United Republic of'
        },
        {
            code: 'TH',
            name: 'Thailand'
        },
        {
            code: 'TL',
            name: 'Timor-Leste'
        },
        {
            code: 'TG',
            name: 'Togo'
        },
        {
            code: 'TK',
            name: 'Tokelau'
        },
        {
            code: 'TO',
            name: 'Tonga'
        },
        {
            code: 'TT',
            name: 'Trinidad and Tobago'
        },
        {
            code: 'TN',
            name: 'Tunisia'
        },
        {
            code: 'TR',
            name: 'Turkey'
        },
        {
            code: 'TM',
            name: 'Turkmenistan'
        },
        {
            code: 'TC',
            name: 'Turks and Caicos Islands'
        },
        {
            code: 'TV',
            name: 'Tuvalu'
        },
        {
            code: 'UG',
            name: 'Uganda'
        },
        {
            code: 'UA',
            name: 'Ukraine'
        },
        {
            code: 'AE',
            name: 'United Arab Emirates'
        },
        {
            code: 'GB',
            name: 'United Kingdom'
        },
        {
            code: 'US',
            name: 'United States'
        },
        {
            code: 'UM',
            name: 'United States Minor Outlying Islands'
        },
        {
            code: 'UY',
            name: 'Uruguay'
        },
        {
            code: 'UZ',
            name: 'Uzbekistan'
        },
        {
            code: 'VU',
            name: 'Vanuatu'
        },
        {
            code: 'VE',
            name: 'Venezuela, Bolivarian Republic of'
        },
        {
            code: 'VN',
            name: 'Viet Nam'
        },
        {
            code: 'VG',
            name: 'Virgin Islands, British'
        },
        {
            code: 'VI',
            name: 'Virgin Islands, U.S.'
        },
        {
            code: 'WF',
            name: 'Wallis and Futuna'
        },
        {
            code: 'EH',
            name: 'Western Sahara'
        },
        {
            code: 'YE',
            name: 'Yemen'
        },
        {
            code: 'ZM',
            name: 'Zambia'
        },
        {
            code: 'ZW',
            name: 'Zimbabwe'
        }
    ];

    static COUNTRIES_LIST_FR: any[] = [
        {
            code: 'AF',
            name: 'Afghanistan',
        },
        {
            code: 'AX',
            name: 'Îles Åland'
        },
        {
            code: 'AL',
            name: 'Albanie'
        },
        {
            code: 'DZ',
            name: 'Algérie'
        },
        {
            code: 'AS',
            name: 'Samoa américaines'
        },
        {
            code: 'AD',
            name: 'Andorre'
        },
        {
            code: 'AO',
            name: 'Angola'
        },
        {
            code: 'AI',
            name: 'Anguilla'
        },
        {
            code: 'AQ',
            name: 'Antarctique'
        },
        {
            code: 'AG',
            name: 'Antigua-et-Barbuda'
        },
        {
            code: 'AR',
            name: 'Argentine'
        },
        {
            code: 'AM',
            name: 'Arménie'
        },
        {
            code: 'AW',
            name: 'Aruba'
        },
        {
            code: 'AU',
            name: 'Australie'
        },
        {
            code: 'AT',
            name: 'Autriche'
        },
        {
            code: 'AZ',
            name: 'Azerbaïdjan'
        },
        {
            code: 'BS',
            name: 'Bahamas'
        },
        {
            code: 'BH',
            name: 'Bahreïn'
        },
        {
            code: 'BD',
            name: 'Bangladesh'
        },
        {
            code: 'BB',
            name: 'Barbade'
        },
        {
            code: 'BY',
            name: 'Belarus'
        },
        {
            code: 'BE',
            name: 'Belgique'
        },
        {
            code: 'BZ',
            name: 'Belize'
        },
        {
            code: 'BJ',
            name: 'Bénin'
        },
        {
            code: 'BM',
            name: 'Bermudes'
        },
        {
            code: 'BT',
            name: 'Bhoutan'
        },
        {
            code: 'BO',
            name: 'Bolivie, État plurinational de'
        },
        {
            code: 'BQ',
            name: 'Bonaire, Saint-Eustache et Saba'
        },
        {
            code: 'BA',
            name: 'Bosnie-Herzégovine'
        },
        {
            code: 'BW',
            name: 'Botswana'
        },
        {
            code: 'BV',
            name: 'Île Bouvet'
        },
        {
            code: 'BR',
            name: 'Brésil'
        },
        {
            code: 'IO',
            name: 'Territoire britannique de l\'océan Indien'
        },
        {
            code: 'BN',
            name: 'Brunei Darussalam'
        },
        {
            code: 'BG',
            name: 'Bulgarie'
        },
        {
            code: 'BF',
            name: 'Burkina Faso'
        },
        {
            code: 'BI',
            name: 'Burundi'
        },
        {
            code: 'KH',
            name: 'Cambodge'
        },
        {
            code: 'CM',
            name: 'Cameroun'
        },
        {
            code: 'CA',
            name: 'Canada'
        },
        {
            code: 'CV',
            name: 'Cap-Vert'
        },
        {
            code: 'KY',
            name: 'Îles Caïmans'
        },
        {
            code: 'CF',
            name: 'République centrafricaine'
        },
        {
            code: 'TD',
            name: 'Tchad'
        },
        {
            code: 'CL',
            name: 'Chili'
        },
        {
            code: 'CN',
            name: 'Chine'
        },
        {
            code: 'CX',
            name: 'Christmas Island'
        },
        {
            code: 'CC',
            name: 'Îles Cocos (Keeling)'
        },
        {
            code: 'CO',
            name: 'Colombie'
        },
        {
            code: 'KM',
            name: 'Comores'
        },
        {
            code: 'CG',
            name: 'Congo'
        },
        {
            code: 'CD',
            name: 'Congo, République démocratique du'
        },
        {
            code: 'CK',
            name: 'Îles Cook'
        },
        {
            code: 'CR',
            name: 'Costa Rica'
        },
        {
            code: 'CI',
            name: 'Côte d \' Ivoire '
        },
        {
            code: 'HR',
            name: 'Croatie'
        },
        {
            code: 'CU',
            name: 'Cuba'
        },
        {
            code: 'CW',
            name: 'Curaçao'
        },
        {
            code: 'CY',
            name: 'Chypre'
        },
        {
            code: 'CZ',
            name: 'République tchèque'
        },
        {
            code: 'DK',
            name: 'Danemark'
        },
        {
            code: 'DJ',
            name: 'Djibouti'
        },
        {
            code: 'DM',
            name: 'Dominique'
        },
        {
            code: 'DO',
            name: 'République dominicaine'
        },
        {
            code: 'EC',
            name: 'Équateur'
        },
        {
            code: 'EG',
            name: 'Égypte'
        },
        {
            code: 'SV',
            name: 'El Salvador'
        },
        {
            code: 'GQ',
            name: 'Guinée équatoriale'
        },
        {
            code: 'ER',
            name: 'Érythrée'
        },
        {
            code: 'EE',
            name: 'Estonie'
        },
        {
            code: 'ET',
            name: 'Éthiopie'
        },
        {
            code: 'FK',
            name: 'Îles Falkland (Malvinas)'
        },
        {
            code: 'FO',
            name: 'Îles Féroé'
        },
        {
            code: 'FJ',
            name: 'Fidji'
        },
        {
            code: 'FI',
            name: 'Finlande'
        },
        {
            code: 'FR',
            name: 'France'
        },
        {
            code: 'GF',
            name: 'Guyane française'
        },
        {
            code: 'PF',
            name: 'Polynésie française'
        },
        {
            code: 'TF',
            name: 'Territoires français du Sud'
        },
        {
            code: 'GA',
            name: 'Gabon'
        },
        {
            code: 'GM',
            name: 'Gambie'
        },
        {
            code: 'GE',
            name: 'Géorgie'
        },
        {
            code: 'DE',
            name: 'Allemagne'
        },
        {
            code: 'GH',
            name: 'Ghana'
        },
        {
            code: 'GI',
            name: 'Gibraltar'
        },
        {
            code: 'GR',
            name: 'Grèce'
        },
        {
            code: 'GL',
            name: 'Groenland'
        },
        {
            code: 'GD',
            name: 'Grenade'
        },
        {
            code: 'GP',
            name: 'Guadeloupe'
        },
        {
            code: 'GU',
            name: 'Guam'
        },
        {
            code: 'GT',
            name: 'Guatemala'
        },
        {
            code: 'GG',
            name: 'Guernesey'
        },
        {
            code: 'GN',
            name: 'Guinée'
        },
        {
            code: 'GW',
            name: 'Guinée-Bissau'
        },
        {
            code: 'GY',
            name: 'Guyane'
        },
        {
            code: 'HT',
            name: 'Haïti'
        },
        {
            code: 'HM',
            name: 'Heard Island et McDonald Islands'
        },
        {
            code: 'VA',
            name: 'Saint-Siège (État de la Cité du Vatican)'
        },
        {
            code: 'HN',
            name: 'Honduras'
        },
        {
            code: 'HK',
            name: 'Hong Kong'
        },
        {
            code: 'HU',
            name: 'Hongrie'
        },
        {
            code: 'EST',
            name: 'Islande'
        },
        {
            code: 'IN',
            name: 'Inde'
        },
        {
            code: 'ID',
            name: 'Indonésie'
        },
        {
            code: 'IR',
            name: 'Iran, République islamique d \''
        },
        {
            code: 'IQ',
            name: 'Irak'
        },
        {
            code: 'IE',
            name: 'Irlande'
        },
        {
            code: 'IM',
            name: 'Ile de Man'
        },
        {
            code: 'IL',
            name: 'Israël'
        },
        {
            code: 'IT',
            name: 'Italie'
        },
        {
            code: 'JM',
            name: 'Jamaïque'
        },
        {
            code: 'JP',
            name: 'Japon'
        },
        {
            code: 'JE',
            name: 'Jersey'
        },
        {
            code: 'JO',
            name: 'Jordan'
        },
        {
            code: 'KZ',
            name: 'Kazakhstan'
        },
        {
            code: 'KE',
            name: 'Kenya'
        },
        {
            code: 'KI',
            name: 'Kiribati'
        },
        {
            code: 'KP',
            name: 'Corée, République populaire démocratique de'
        },
        {
            code: 'KR',
            name: 'Corée, République de'
        },
        {
            code: 'KW',
            name: 'Koweït'
        },
        {
            code: 'KG',
            name: 'Kirghizistan'
        },
        {
            code: 'LA',
            name: 'République démocratique populaire lao'
        },
        {
            code: 'LV',
            name: 'Lettonie'
        },
        {
            code: 'LB',
            name: 'Liban'
        },
        {
            code: 'LS',
            name: 'Lesotho'
        },
        {
            code: 'LR',
            name: 'Liberia'
        },
        {
            code: 'LY',
            name: 'Libye'
        },
        {
            code: 'LI',
            name: 'Liechtenstein'
        },
        {
            code: 'LT',
            name: 'Lituanie'
        },
        {
            code: 'LU',
            name: 'Luxembourg'
        }, {
            code: 'MO',
            name: 'Macao'
        },
        {
            code: 'MK',
            name: 'Macédoine, ancienne République yougoslave de'
        },
        {
            code: 'MG',
            name: 'Madagascar'
        },
        {
            code: 'MW',
            name: 'Malawi'
        },
        {
            code: 'MY',
            name: 'Malaisie'
        },
        {
            code: 'MV',
            name: 'Maldives'
        },
        {
            code: 'ML',
            name: 'Mali'
        },
        {
            code: 'MT',
            name: 'Malte'
        },
        {
            code: 'MH',
            name: 'Îles Marshall'
        },
        {
            code: 'MQ',
            name: 'Martinique'
        },
        {
            code: 'MR',
            name: 'Mauritanie'
        },
        {
            code: 'MU',
            name: 'Maurice'
        },
        {
            code: 'YT',
            name: 'Mayotte'
        },
        {
            code: 'MX',
            name: 'Mexique'
        },
        {
            code: 'FM',
            name: 'Micronésie, États fédérés de'
        },
        {
            code: 'MD',
            name: 'Moldova, République de'
        },
        {
            code: 'MC',
            name: 'Monaco'
        },
        {
            code: 'MN',
            name: 'Mongolie'
        },
        {
            code: 'ME',
            name: 'Monténégro'
        },
        {
            code: 'MS',
            name: 'Montserrat'
        },
        {
            code: 'MA',
            name: 'Maroc'
        },
        {
            code: 'MZ',
            name: 'Mozambique'
        },
        {
            code: 'MM',
            name: 'Myanmar'
        },
        {
            code: 'NA',
            name: 'Namibie'
        },
        {
            code: 'NR',
            name: 'Nauru'
        },
        {
            code: 'NP',
            name: 'Népal'
        },
        {
            code: 'NL',
            name: 'Pays-Bas'
        },
        {
            code: 'NC',
            name: 'Nouvelle-Calédonie'
        },
        {
            code: 'NZ',
            name: 'Nouvelle-Zélande'
        },
        {
            code: 'NI',
            name: 'Nicaragua'
        },
        {
            code: 'NE',
            name: 'Niger'
        },
        {
            code: 'NG',
            name: 'Nigeria'
        },
        {
            code: 'NU',
            name: 'Niue'
        },
        {
            code: 'NF',
            name: 'Île Norfolk'
        },
        {
            code: 'MP',
            name: 'Îles Mariannes du Nord'
        },
        {
            code: 'NON',
            name: 'Norvège'
        },
        {
            code: 'OM',
            name: 'Oman'
        },
        {
            code: 'PK',
            name: 'Pakistan'
        },
        {
            code: 'PW',
            name: 'Palau'
        },
        {
            code: 'PS',
            name: 'Palestine, État de'
        },
        {
            code: 'PA',
            name: 'Panama'
        },
        {
            code: 'PG',
            name: 'Papouasie-Nouvelle-Guinée'
        },
        {
            code: 'PY',
            name: 'Paraguay'
        },
        {
            code: 'PE',
            name: 'Pérou'
        },
        {
            code: 'PH',
            name: 'Philippines'
        },
        {
            code: 'PN',
            name: 'Pitcairn'
        },
        {
            code: 'PL',
            name: 'Pologne'
        },
        {
            code: 'PT',
            name: 'Portugal'
        },
        {
            code: 'PR',
            name: 'Puerto Rico'
        },
        {
            code: 'QA',
            name: 'Qatar'
        },
        {
            code: 'RE',
            name: 'Réunion'
        },
        {
            code: 'RO',
            name: 'Roumanie'
        },
        {
            code: 'RU',
            name: 'Fédération de Russie'
        },
        {
            code: 'RW',
            name: 'Rwanda'
        },
        {
            code: 'BL',
            name: 'Saint Barthélemy'
        },
        {
            code: 'SH',
            name: 'Sainte-Hélène, Ascension et Tristan da Cunha'
        },
        {
            code: 'KN',
            name: 'Saint-Kitts-et-Nevis'
        },
        {
            code: 'LC',
            name: 'Sainte-Lucie'
        },
        {
            code: 'MF',
            name: 'Saint Martin (partie française)'
        },
        {
            code: 'PM',
            name: 'Saint Pierre et Miquelon'
        },
        {
            code: 'VC',
            name: 'Saint-Vincent-et-les Grenadines'
        },
        {
            code: 'WS',
            name: 'Samoa'
        },
        {
            code: 'SM',
            name: 'Saint-Marin'
        },
        {
            code: 'ST',
            name: 'Sao Tomé et Principe'
        }, {
            code: 'SA',
            name: 'Arabie saoudite'
        },
        {
            code: 'SN',
            name: 'Sénégal'
        },
        {
            code: 'RS',
            name: 'Serbie'
        },
        {
            code: 'SC',
            name: 'Seychelles'
        },
        {
            code: 'SL',
            name: 'Sierra Leone'
        },
        {
            code: 'SG',
            name: 'Singapour'
        },
        {
            code: 'SX',
            name: 'Sint Maarten (partie néerlandaise)'
        },
        {
            code: 'SK',
            name: 'Slovaquie'
        },
        {
            code: 'SI',
            name: 'Slovénie'
        },
        {
            code: 'SB',
            name: 'Îles Salomon'
        },
        {
            code: 'SO',
            name: 'Somalie'
        },
        {
            code: 'ZA',
            name: 'Afrique du Sud'
        },
        {
            code: 'GS',
            name: 'Géorgie du Sud et îles Sandwich du Sud'
        },
        {
            code: 'SS',
            name: 'Soudan du Sud'
        },
        {
            code: 'ES',
            name: 'Espagne'
        },
        {
            code: 'LK',
            name: 'Sri Lanka'
        },
        {
            code: 'SD',
            name: 'Soudan'
        },
        {
            code: 'SR',
            name: 'Suriname'
        },
        {
            code: 'SJ',
            name: 'Svalbard et Jan Mayen'
        },
        {
            code: 'SZ',
            name: 'Swaziland'
        },
        {
            code: 'SE',
            name: 'Suède'
        },
        {
            code: 'CH',
            name: 'Suisse'
        },
        {
            code: 'SY',
            name: 'République arabe syrienne'
        },
        {
            code: 'TW',
            name: 'Taiwan, Province de Chine'
        },
        {
            code: 'TJ',
            name: 'Tadjikistan'
        },
        {
            code: 'TZ',
            name: 'Tanzanie, République-Unie de'
        },
        {
            code: 'TH',
            name: 'Thaïlande'
        },
        {
            code: 'TL',
            name: 'Timor-Leste'
        },
        {
            code: 'TG',
            name: 'Togo'
        },
        {
            code: 'TK',
            name: 'Tokelau'
        },
        {
            code: 'TO',
            name: 'Tonga'
        },
        {
            code: 'TT',
            name: 'Trinité-et-Tobago'
        },
        {
            code: 'TN',
            name: 'Tunisie'
        },
        {
            code: 'TR',
            name: 'Turquie'
        },
        {
            code: 'TM',
            name: 'Turkménistan'
        },
        {
            code: 'TC',
            name: 'Îles Turques et Caïques'
        },
        {
            code: 'TV',
            name: 'Tuvalu'
        },
        {
            code: 'UG',
            name: 'Ouganda'
        },
        {
            code: 'UA',
            name: 'Ukraine'
        },
        {
            code: 'AE',
            name: 'Émirats arabes unis'
        },
        {
            code: 'GB',
            name: 'Royaume-Uni'
        },
        {
            code: 'US',
            name: 'États-Unis'
        },
        {
            code: 'UM',
            name: 'Îles mineures éloignées des États-Unis'
        },
        {
            code: 'UY',
            name: 'Uruguay'
        },
        {
            code: 'UZ',
            name: 'Ouzbékistan'
        },
        {
            code: 'VU',
            name: 'Vanuatu'
        },
        {
            code: 'VE',
            name: 'Venezuela, République bolivarienne du'
        },
        {
            code: 'VN',
            name: 'Viet Nam'
        },
        {
            code: 'VG',
            name: 'Îles Vierges britanniques'
        },
        {
            code: 'VI',
            name: 'Îles Vierges américaines'
        },
        {
            code: 'WF',
            name: 'Wallis et Futuna'
        },
        {
            code: 'EH',
            name: 'Sahara occidental'
        },
        {
            code: 'YE',
            name: 'Yémen'
        },
        {
            code: 'ZM',
            name: 'Zambie'
        },
        {
            code: 'ZW',
            name: 'Zimbabwe'
        }
    ];
    static isValidCountryCode(countryCode: string): boolean {
        if (!!countryCode) {
            return CountriesUtils.COUNTRIES_LIST.filter(item => item.code.toLowerCase() === countryCode.toLowerCase()).length > 0;
        }
        return false;
    }

    static getCountryName(countryCode: string): string {
        if (!!countryCode) {
            return CountriesUtils.COUNTRIES_LIST.filter(item => item.code.toLowerCase() === countryCode.toLowerCase()).reduce((a, b) => b.name, countryCode);
        }
        return '--';
    }

    static getCountryCode(countryName: string): string {
        if (!!countryName) {
            return CountriesUtils.COUNTRIES_LIST.filter(item => item.name.toLowerCase() === countryName.toLowerCase()).reduce((a, b) => b.code, countryName);
        }
        return '--';
    }
}
