<div class="text-center">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
        <h2>
            <mat-icon>note_add</mat-icon>
            {{'MODAL.QUOTATION.EDIT_QUOTATION'|translate}}
        </h2>
        <button mat-icon-button class="ml-40" (click)="onCloseModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>

        <table mat-table [dataSource]="quotationLines" class="mat-elevation-z4 w-100-p mt-16 mb-16">
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef class="table-header">  {{'MODAL.QUOTATION.CODE' | translate}}</th>
                <td mat-cell *matCellDef="let quotationLine" class="element">{{quotationLine.code}}
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef class="table-header">{{'MODAL.QUOTATION.TYPE' | translate}}</th>
                <td mat-cell
                    class="element"
                    *matCellDef="let quotationLine">{{'MODAL.QUOTATION.TYPE.' + quotationLine.type | translate}}</td>
            </ng-container>

            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef class="table-header">{{'MODAL.QUOTATION.LABEL' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let quotationLine">{{quotationLine.label}}</td>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">  {{'MODAL.QUOTATION.QUANTITY' | translate}}</th>
                <td mat-cell *matCellDef="let quotationLine" class="element">{{quotationLine.quantity}} </td>
            </ng-container>
            <ng-container matColumnDef="purchasePrice">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">  {{'MODAL.QUOTATION.PURCHASE.PRICE' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let quotationLine">  {{normalize(quotationLine?.purchasePrice?.value)   |currency:currency :'symbol-narrow' | space}} </td>
            </ng-container>
            <ng-container matColumnDef="marginPercentage">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">  {{'MODAL.QUOTATION.MARGIN' | translate}} %</th>
                <td class="element" mat-cell *matCellDef="let quotationLine"> {{quotationLine.marginPercentage}} </td>
            </ng-container>
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef class="table-header">{{'MODAL.QUOTATION.PRICE' | translate}}</th>
                <td mat-cell *matCellDef="let quotationLine" class="element">
                    <mat-form-field appearance="outline" class="mt-8">
                        <mat-label>{{'MODAL.QUOTATION.PRICE'|translate}}</mat-label>
                        <input matInput [placeholder]="'MODAL.QUOTATION.PRICE'|translate"
                               [(ngModel)]="quotationLine.priceAmount.value"
                               [disabled]="isDisabled(quotationLine)"
                               (change)="changeLinePrice(quotationLine)"
                               min="0" required>
                    </mat-form-field>
                </td>
            </ng-container>
            <ng-container matColumnDef="vat">
                <th mat-header-cell *matHeaderCellDef class="table-header">  {{'MODAL.QUOTATION.TVA' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let quotationLine"> {{quotationLine.vat.rate}} </td>
            </ng-container>

            <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">  {{'MODAL.QUOTATION.DISCOUNT' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let quotationLine"> {{quotationLine.discount}} </td>
            </ng-container>

            <ng-container matColumnDef="payer">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header"> {{'MODAL.QUOTATION.PAYER' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let quotationLine">
                    <mat-form-field appearance="outline" class="mt-8">
                        <mat-label>{{'MODAL.QUOTATION.PAYER'|translate}}</mat-label>
                        <mat-select [(ngModel)]="quotationLine.payer" [placeholder]="'MODAL.QUOTATION.TYPE'|translate"
                                    [disabled]="isClientQuotationFees(quotationLine)"
                                    (selectionChange)="changePayer($event, quotationLine)">
                            <mat-option *ngFor="let payer of payers" [value]="payer">
                                <span> {{'MODAL.QUOTATION.PAYER_CODE.' + payer | translate}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="totalPrice">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header"> {{'MODAL.QUOTATION.TOTAL_PRICE' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let quotationLine">
                    {{normalize(quotationLine?.totalLinePrice?.value)   |currency:currency :'symbol-narrow' | space}}
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef
                    class="table-header">  {{'MODAL.QUOTATION.ACTION' | translate}}</th>
                <td class="element" mat-cell *matCellDef="let quotationLine;let indexLine = index;">
                    <button *ngIf="!isClientQuotationFees(quotationLine)" matSuffix mat-icon-button color="warn"
                            type="button" class="mx-8" (click)="deleteLine(indexLine)">
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div fxLayout="row" fxLayoutAlign="end end">
            <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="30" fxFlex.lt-md="100">
                <div fxLayout="column" class="zone-total">
                    <div fxLayoutAlign="space-between center">
                        <strong>{{'MODAL.QUOTATION.TOTAL_HT' | translate}} : </strong>
                        <div>{{normalize(totalPriceHTAfterDiscount?.value)  |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <strong>{{'MODAL.QUOTATION.TOTAL_DISCOUNT' | translate}} : </strong>
                        <div>{{normalize(totalDiscount?.value )  |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <strong>{{'MODAL.QUOTATION.TOTAL_NET_HT' | translate}}  : </strong>
                        <div>{{normalize(totalPriceHT?.value)   |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <strong>{{'MODAL.QUOTATION.TOTAL_TVA' | translate}}  : </strong>
                        <div>
                            {{normalize(totalVAT?.value )  |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <strong> {{'MODAL.QUOTATION.TOTAL_TTC' | translate}} : </strong>
                        <div>{{normalize(totalPriceTTC?.value) |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mt-16">
        <div class="w-100-p">
            <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
                <ng-container *ngIf="totalPriceTTC?.value < 0">
                    <p class="red-color">{{'FOLDER.REPAIR_IN_PROGRESS.NEGATIVE_QUOTATION' | translate}}</p>
                </ng-container>
                <button type="submit" mat-stroked-button (click)="validateForm()"
                        [disabled]=" disabledForm()"
                        [ngClass]=" disabledForm()? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </div>
    </mat-dialog-actions>
</div>
