import {Injectable} from '@angular/core';
import {MatomoTracker} from 'ngx-matomo';

@Injectable()
export class MatomoAnalyticsEventsService {
    constructor(private matomoTracker: MatomoTracker) {
    }

    public sendEvent(eventCategory: string,
                     eventAction: string,
                     eventLabel: string = null,
                     eventValue: number = null): void {
        this.matomoTracker.trackEvent(eventCategory, eventAction, eventLabel, eventValue);
        this.matomoTracker.trackPageView();

    }
}
