import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {ScheduledWaitingInterventionComponent} from './scheduled-waiting-intervention/scheduled-waiting-intervention.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {HttpLoaderFactory} from '../../../../app.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '../../../../../@fuse/components';
import {fuseConfig} from '../../../../fuse-config';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {FuseModule} from '../../../../../@fuse/fuse.module';
import {ConfirmAppointmentComponent} from './confirm-appointment/confirm-appointment.component';
import {WishesAppointmentComponent} from './wishes-appointment/wishes-appointment.component';
import {DialogDataCancelInterventionComponent} from './confirm-appointment/dialog-data-cancel-intervention/dialog-data-cancel-intervention.component';
import {DialogWaitingCancelInterventionComponent} from './scheduled-waiting-intervention/dialog-waiting-cancel-intervention/dialog-waiting-cancel-intervention.component';
import {ProgressRepairComponent} from './progress-repair/progress-repair.component';
import {FolderFinishedComponent} from './folder-closed/folder-finished/folder-finished.component';
import {WaitingPaymentComponent} from './waiting-payment/waiting-payment.component';
import {SymptomSearchComponent} from './symptoms/symptom-search/symptom-search.component';
import {SymptomPreviewCardComponent} from './symptoms/symptom-preview-card/symptom-preview-card.component';
import {PanneConfirmedComponent} from './panne-confirmed/panne-confirmed.component';
import {EditInterventionAddressComponent} from './edit-intervention-address/edit-intervention-address.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {DepositProductSiteComponent} from './deposit-product-site/deposit-product-site.component';
import {WaitingDepositSiteComponent} from './waiting-deposit-site/waiting-deposit-site.component';
import {ParcelToSendComponent} from './shipment/parcel-to-send/parcel-to-send.component';
import {ShipmentInProgressComponent} from './shipment/shipment-in-progress/shipment-in-progress.component';
import {SharedGenericModule} from '../../../../shared/generic/shared-generic.module';
import {StoreDepositComponent} from './store-deposit/store-deposit.component';
import {StorePaymentComponent} from './store-payment/store-payment.component';
import {RepairerAndCarrierConfirmationComponent} from './repairer-and-carrier-confirmation/repairer-and-carrier-confirmation.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {NotPlanedInterventionComponent} from './reparation/not-planed-intervention/not-planed-intervention.component';
import {PlannedInterventionComponent} from './reparation/planned-intervention/planned-intervention.component';
import {ReparationInProgressComponent} from './reparation/reparation-in-progress/reparation-in-progress.component';
import {ReparationQuotationAcceptationComponent} from './reparation/reparation-quotation-acceptation/reparation-quotation-acceptation.component';
import {RepairerToBeConfirmedComponent} from './repairer-to-be-confirmed/repairer-to-be-confirmed.component';
import {AddDefaultAddressComponent} from './add-default-address/add-default-address.component';
import {WaitingProductReceptionComponent} from './service-center-reparation-repairer/waiting-product-reception/waiting-product-reception.component';
import {CheckProductConformityComponent} from './service-center-reparation-repairer/check-product-conformity/check-product-conformity.component';
import {ProductDiagnosticComponent} from './service-center-reparation-repairer/product-diagnostic/product-diagnostic.component';
import {PrepareSparePartsOrderComponent} from './service-center-reparation-repairer/prepare-spare-parts-order/prepare-spare-parts-order.component';
import {SparePartsOrderInProgressComponent} from './service-center-reparation-repairer/spare-parts-order-in-progress/spare-parts-order-in-progress.component';
import {CheckSparePartsBeforeReparationComponent} from './service-center-reparation-repairer/check-spare-parts-before-reparation/check-spare-parts-before-reparation.component';
import {InterventionReportComponent} from './reparation/intervention-report/intervention-report.component';
import {FilesSliderModule} from '../../../../layout/components/files-slider/files-slider.module';
import {ReparationConfirmationComponent} from './reparation-confirmation/reparation-confirmation.component';
import {WaitingToRepairComponent} from './service-center-reparation-repairer/waiting-to-repair/waiting-to-repair.component';
import {RouterModule} from '@angular/router';
import {CustomerDecisionComponent} from './customer-decision/customer-decision.component';
import {ChooseOperatingModeComponent} from './choose-operating-mode/choose-operating-mode.component';
import {ChooseSparePartsToOrderComponent} from './spare-parts-order/choose-spare-parts-to-order/choose-spare-parts-to-order.component';
import {ClientValidateShipmentCustomerAddressComponent} from './client-validate-shipment-customer-address/client-validate-shipment-customer-address.component';
import {SparePartsOrderWaitingValidationComponent} from '../spare-parts/spare-parts-order-to-be-confirmed-component/spare-parts-order-waiting-validation.component';
import {SparePartsOrderValidatedComponent} from '../spare-parts/spare-parts-order-validated/spare-parts-order-validated.component';
import {SparePartsOrderWaitingPreparationComponent} from '../spare-parts/spare-parts-order-waiting-preparation/spare-parts-order-waiting-preparation.component';
import {RefundToCustomerComponent} from './refund-to-customer/refund-to-customer.component';
import {AcceptQuotationComponent} from './accept-quotation/accept-quotation.component';
import {AlimentaryProductPossessionCustomerComponent} from './alimentary-product-recall/alimentary-product-possession-customer/alimentary-product-possession-customer.component';
import {ProductPhotoTaskComponent} from './alimentary-product-recall/product-photo-task/product-photo-task.component';
import {GoToStoreComponent} from './alimentary-product-recall/go-to-store/go-to-store.component';
import {HealthImpactComponent} from './alimentary-product-recall/health-impact/health-impact.component';
import {ContactCustomerComponent} from './alimentary-product-recall/contact-customer/contact-customer.component';
import {GLOBAL} from '../../../../app-config';
import {SurveyComponent} from './survey/survey.component';
import {OrderByOtherPartnerInProgressComponent} from './order-by-other-partner-in-progress/order-by-other-partner-in-progress.component';
import {ManuelFolderProcessComponent} from './manuel-folder-process/manuel-folder-process.component';
import {SurveyModule} from '@sav-components/survey';
import {ManualClaimProcessingComponent} from './manual-claim-processing/manual-claim-processing.component';
import {WaitingPickupComponent} from './shipment/waiting-pickup/waiting-pickup.component';
import {OrderedContentComponent} from './internal-repair/ordered-content/ordered-content.component';
import {CustomerProductRetrievalDecisionComponent} from './internal-repair/customer-product-retrieval-decision/customer-product-retrieval-decision.component';
import {WaitingSparePartsReceptionComponent} from '../spare-parts/waiting-spare-parts-reception/waiting-spare-parts-reception.component';
import {ValidateEditWishedAppointmentComponent} from './scheduled-waiting-intervention/validate-edit-wished-appointment/validate-edit-wished-appointment.component';
import {DialogValidateEditWishedAppointmentComponent} from './confirm-appointment/dialog-validate-edit-wished-appointment/dialog-validate-edit-wished-appointment.component';
import {
    WaitingSparePartReceptionItinerantComponent
} from './internal-repair/waiting-spare-part-reception/waiting-spare-part-reception-itinerant/waiting-spare-part-reception-itinerant.component';
import {WaitingSparePartRetrievalComponent} from '../spare-parts/waiting-spare-part-retrieval/waiting-spare-part-retrieval.component';
import {CheckAndDiagnostic2Component} from './internal-repair-v3/check-and-diagnostic2/check-and-diagnostic2.component';
import {InputRequirement2Component} from './internal-repair-v3/input-requirement2/input-requirement2.component';
import {InternalReparationInProgress2Component} from './internal-repair-v3/internal-reparation-in-progress2/internal-reparation-in-progress2.component';
import {
    InternalReparationWaitingProductRetrieval2Component
} from './internal-repair-v3/internal-reparation-waiting-product-retrieval2/internal-reparation-waiting-product-retrieval2.component';
import {
    WaitingProductRetrievalQuotationRefused2Component
} from './internal-repair-v3/waiting-product-retrieval-quotation-refused2/waiting-product-retrieval-quotation-refused2.component';
import {WaitingForReparationPermission2Component} from './internal-repair-v3/waiting-for-reparation-permission2/waiting-for-reparation-permission2.component';
import {WaitingSparePartReception2Component} from './internal-repair-v3/waiting-spare-part-reception2/waiting-spare-part-reception2.component';
import {WaitingCustomerDecisionNonRepairable2Component} from './internal-repair-v3/waiting-customer-decision-non-repairable2/waiting-customer-decision-non-repairable2.component';
import {WaitingNotRepairedProductRetrieval2Component} from './internal-repair-v3/waiting-not-repaired-product-retrieval2/waiting-not-repaired-product-retrieval2.component';
import {UnreferencedSparePartModalComponent} from './spare-parts-order/unreferenced-spare-part-modal/unreferenced-spare-part-modal.component';
import {SparePartsInfoComponent} from './store-payment/spare-parts-info/spare-parts-info.component';
import {CheckAndConfirmOrderComponent} from './internal-repair-v3/check-and-confirm-order/check-and-confirm-order.component';
import {SwapConfirmationInternalRepairComponent} from './internal-repair-v3/swap-confirmation-internal-repair/swap-confirmation-internal-repair.component';
import {SwapConfirmationSparePartComponent} from './spare-parts-order/swap-confirmation-spare-part/swap-confirmation-spare-part.component';
import {CheckSparePartsComponent} from './spare-parts-order/check-spare-parts/check-spare-parts.component';
import {GridModule} from '@syncfusion/ej2-angular-grids';
import {DialogModule} from '@syncfusion/ej2-angular-popups';
import {OrderedContentInfoComponent} from './internal-repair/ordered-content/ordered-content-info/ordered-content-info/ordered-content-info.component';
import {WaitingForRepairerQuotationComponent} from './quotation-v2/waiting-for-repairer-quotation/waiting-for-repairer-quotation.component';
import {QuotationAcceptationDistributorComponent} from './quotation-v2/quotation-acceptation-distributor/quotation-acceptation-distributor.component';
import {TransformationIntoClientQuotationComponent} from './quotation-v2/transformation-into-client-quotation/transformation-into-client-quotation.component';
import {QuotationAcceptationClientComponent} from './quotation-v2/quotation-acceptation-client/quotation-acceptation-client.component';
import {WaitingForQuotationModalComponent} from './quotation-v2/waiting-for-repairer-quotation/waiting-for-quotation-modal/waiting-for-quotation-modal.component';
import {ProlongedDiagnosisComponent} from './prolonged-diagnosis/prolonged-diagnosis.component';
import {AttachmentUploaderComponent} from './quotation-v2/attachment-uploader/attachment-uploader.component';
import {EditQuotationModalComponent} from './quotation-v2/transformation-into-client-quotation/edit-quotation-modal/edit-quotation-modal.component';
import {QuotationContentComponent} from './quotation-v2/quotation-content/quotation-content.component';
import {
    WaitingProductRetrievalClosedByDiagExpressComponent
} from './internal-repair-v3/waiting-product-retrieval-repaired-by-diag-express/waiting-product-retrieval-closed-by-diag-express.component';

@NgModule({
    declarations: [
        // FolderConfigFormComponent,
        SymptomSearchComponent,
        SymptomPreviewCardComponent,
        PanneConfirmedComponent,
        SurveyComponent,
        EditInterventionAddressComponent,

        WishesAppointmentComponent,
        ConfirmAppointmentComponent,
        ScheduledWaitingInterventionComponent,
        ValidateEditWishedAppointmentComponent,

        DialogDataCancelInterventionComponent,
        DialogValidateEditWishedAppointmentComponent,
        DialogWaitingCancelInterventionComponent,

        ProgressRepairComponent,
        WaitingPaymentComponent,

        DepositProductSiteComponent,
        WaitingDepositSiteComponent,

        FolderFinishedComponent,
        RepairerAndCarrierConfirmationComponent,

        ParcelToSendComponent,
        ShipmentInProgressComponent,
        StoreDepositComponent,
        StorePaymentComponent,
        NotPlanedInterventionComponent,
        PlannedInterventionComponent,
        ReparationInProgressComponent,
        ReparationQuotationAcceptationComponent,
        RepairerToBeConfirmedComponent,
        ReparationConfirmationComponent,
        AddDefaultAddressComponent,
        WaitingProductReceptionComponent,
        CheckProductConformityComponent,
        ProductDiagnosticComponent,
        PrepareSparePartsOrderComponent,
        SparePartsOrderInProgressComponent,
        CheckSparePartsBeforeReparationComponent,
        CheckAndConfirmOrderComponent,
        InterventionReportComponent,
        WaitingToRepairComponent,
        CustomerDecisionComponent,
        ChooseOperatingModeComponent,
        ChooseSparePartsToOrderComponent,
        CheckSparePartsComponent,
        ClientValidateShipmentCustomerAddressComponent,
        SparePartsOrderWaitingValidationComponent,
        SparePartsOrderValidatedComponent,
        SparePartsOrderWaitingPreparationComponent,
        RefundToCustomerComponent,
        AcceptQuotationComponent,
        RefundToCustomerComponent,
        AlimentaryProductPossessionCustomerComponent,
        ProductPhotoTaskComponent,
        GoToStoreComponent,
        HealthImpactComponent,
        ContactCustomerComponent,
        OrderByOtherPartnerInProgressComponent,
        ManuelFolderProcessComponent,
        ManualClaimProcessingComponent,
        WaitingPickupComponent,
        OrderedContentComponent,
        CustomerProductRetrievalDecisionComponent,
        WaitingSparePartsReceptionComponent,
        WaitingSparePartReceptionItinerantComponent,
        CheckAndDiagnostic2Component,
        InputRequirement2Component,
        InternalReparationInProgress2Component,
        InternalReparationWaitingProductRetrieval2Component,
        WaitingProductRetrievalQuotationRefused2Component,
        WaitingForReparationPermission2Component,
        WaitingSparePartReception2Component,
        WaitingCustomerDecisionNonRepairable2Component,
        WaitingNotRepairedProductRetrieval2Component,
        WaitingSparePartRetrievalComponent,
        WaitingSparePartReceptionItinerantComponent,
        UnreferencedSparePartModalComponent,
        SparePartsInfoComponent,
        SwapConfirmationInternalRepairComponent,
        SwapConfirmationSparePartComponent,
        OrderedContentInfoComponent,
        WaitingForRepairerQuotationComponent,
        QuotationAcceptationDistributorComponent,
        TransformationIntoClientQuotationComponent,
        QuotationAcceptationClientComponent,
        WaitingForQuotationModalComponent,
        ProlongedDiagnosisComponent,
        AttachmentUploaderComponent,
        EditQuotationModalComponent,
        QuotationContentComponent,
        WaitingProductRetrievalClosedByDiagExpressComponent
    ],
    imports: [FormsModule,
        // Material moment date module
        MatMomentDateModule,
        // Material

        Ng2TelInputModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SurveyModule.forRoot({
            apiUrl: `${GLOBAL.gatewayEndpoint}/back-office-service`,
            projectName: 'sav-back-office-frontend'
        }),
        SharedGenericModule,
        MatFormFieldModule,
        MatIconModule,
        MatCardModule,
        MatRadioModule,
        MatDatepickerModule,
        MatButtonModule,
        MatTooltipModule,
        MatInputModule,
        MatDialogModule,
        FilesSliderModule,
        RouterModule, GridModule, DialogModule
    ],
    exports: [
        ScheduledWaitingInterventionComponent,
        ConfirmAppointmentComponent,
        WishesAppointmentComponent,
        ProgressRepairComponent,
        WaitingPaymentComponent,
        DepositProductSiteComponent,
        WaitingDepositSiteComponent,
        FolderFinishedComponent,
        ParcelToSendComponent,
        CustomerProductRetrievalDecisionComponent
    ]
})
export class WorkflowProcessModule {
}

