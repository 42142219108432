import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {SearchCustomer} from '../../../../../../store/customer/customer.actions';
import {hasSearchApi, isSearchFormEnabled} from '../../../../../../store/customer/customer.selectors';
import {Observable} from 'rxjs';

@Component({
    selector: 'customer-search',
    templateUrl: './customer-search.component.html'
})
export class CustomerSearchComponent implements OnInit {

    isSearchFormEnabled$: Observable<boolean>;
    hasSearchApi$: Observable<boolean>;

    constructor(private store$: Store<AppState>) {
    }

    ngOnInit() {
        this.isSearchFormEnabled$ = this.store$.pipe(select(isSearchFormEnabled));
        this.hasSearchApi$ = this.store$.pipe(select(hasSearchApi));
    }

    search() {
        this.store$.dispatch(new SearchCustomer());
    }

}
