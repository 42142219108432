import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'dialog-data-cancel-intervention.component',
    templateUrl: 'dialog-validate-edit-wished-appointment.component.html',
})
export class DialogValidateEditWishedAppointmentComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {

    }
}
