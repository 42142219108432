export class ConfigurationUtils {

    static labelFromCode = (code, list) =>
        list.find(e => e.code === code)?.label ?? code

    static findByCodeOrLabel = (value: string, list: any[]) =>
        list.filter(item =>
            item.code.toLowerCase().includes(value.toLowerCase()) ||
            item.label.toLowerCase().includes(value.toLowerCase())
        )

    static findConfigurationItemLabelOrDefault = (value, condition, list, defaultValue?) => {
        if (value && value !== condition) {
            const valuesList = list.filter(value => value.code === value);
            if (valuesList.length > 0) {
                return valuesList[0].label;
            }
        }
        return defaultValue ?? value;
    }
}