import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

@Injectable()
export abstract class Unsubscriber implements OnDestroy {

    private subscription = new Subscription();

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    set anotherSubscription(subscription) {
        this.subscription.add(subscription);
    }

}