<div class="page-layout blank p-24" fusePerfectScrollbar>
    <div  fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="text-bold">{{(isUpdateMode ? 'DASHBOARD.WIDGET.TITLE.UPDATE' : 'DASHBOARD.WIDGET.TITLE.ADD') | translate}}</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="10px">

        <mat-card fxFlex="50" class="w-50-p">

            <ng-container *ngIf="!!widgetForm">
                <form [formGroup]="widgetForm">

                    <div fxLayout="row wrap" gdGap="10px">
                        <fieldset formGroupName="aggregation" fxLayout="column" fxLayoutGap="8px">
                            <legend class="h3 title-fieldset">{{'DASHBOARD.WIDGET.INFO.ADD' | translate}}</legend>
                            <div fxLayout="row wrap" fxLayoutGap="8px">
                                <mat-form-field appearance="outline" fxFlex="1 2  calc(40% - 8px)" fxFlex.lt-sm="100%">
                                    <mat-label>{{'DASHBOARD.WIDGET.FORM.INFO.NAME'|translate}}</mat-label>
                                    <input matInput placeholder="{{'DASHBOARD.WIDGET.FORM.INFO.NAME'|translate}}"
                                           formControlName="name"
                                           required>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="2 2  calc(40% - 8px)" fxFlex.lt-sm="100%">
                                    <mat-label>{{'DASHBOARD.WIDGET.FORM.INFO.TYPE'|translate}}</mat-label>
                                    <mat-select formControlName="type" required>

                                        <mat-option *ngFor="let type of widgetTypeList"
                                                    [value]="type">
                                            <span>{{ 'DASHBOARD.WIDGET.FORM.WIDGET_TYPE.' + type |translate}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row wrap" fxLayoutGap="8px">
                                <mat-form-field appearance="outline" fxFlex="1 2  calc(40% - 8px)" fxFlex.lt-sm="100%">
                                    <mat-label>{{'DASHBOARD.WIDGET.FORM.AGGREGATION.TYPE'|translate}}</mat-label>
                                    <mat-select formControlName="aggregation_type" required>
                                        <mat-option *ngFor="let type of aggregationTypeList"
                                                    (onSelectionChange)="updateAggregationField(type,$event)"
                                                    [value]="type">
                                            <span>{{ 'DASHBOARD.WIDGET.FORM.AGGREGATION_TYPE.' + type |translate}}</span>
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>

                                <mat-form-field class="diagnostic-list" appearance="outline"
                                                fxFlex="2 2  calc(40% - 8px)"
                                                fxFlex.lt-sm="100%">
                                    <mat-label>{{'DASHBOARD.WIDGET.FORM.AGGREGATION.FIELD' | translate }}</mat-label>
                                    <button matSuffix mat-icon-button type="button" class="mx-8"
                                            *ngIf="!!widgetForm.get(['aggregation','aggregation_field']).value"
                                            [disabled]="isDisabledAggregationField()"
                                            (click)="onClearSelectFormControlValueByPath(['aggregation', 'aggregation_field'])">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-select
                                        [disabled]="isDisabledAggregationField()"
                                        formControlName="aggregation_field"
                                        [placeholder]="'DASHBOARD.WIDGET.FORM.FOLDER_INDEX' | translate"
                                        [required]="widgetForm.get(['aggregation', 'aggregation_field']) | hasRequiredField"
                                        (selectionChange)="changeGroupByField(['aggregation', 'aggregation_field'],$event)">
                                        <mat-option>
                                            <ngx-mat-select-search
                                                [formControl]="widgetForm.get(['aggregation','aggregationFolderIndexFilterCtrl'])"
                                                [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                                [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option
                                            *ngFor="let item of groupByFieldsList | async"
                                            [value]="item">
                                            <span>{{ 'DASHBOARD.WIDGET.FORM.FOLDER_INDEX.' + item.name.toUpperCase() |translate }}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </fieldset>

                        <fieldset formGroupName="groupBy" fxLayout="column" fxLayoutGap="8px">
                            <legend class="h3 title-fieldset">{{'DASHBOARD.WIDGET.GROUP_BY.ADD' | translate}}</legend>

                            <mat-form-field class="diagnostic-list" appearance="outline">
                                <mat-label>{{'DASHBOARD.WIDGET.FORM.FOLDER_INDEX' | translate }}</mat-label>
                                <button matSuffix mat-icon-button type="button" class="mx-8"
                                        *ngIf="!!widgetForm.get(['groupBy','field']).value"
                                        (click)="onClearSelectFormControlValueByPath(['groupBy', 'field'])">
                                    <mat-icon>close</mat-icon>
                                </button>
                                <mat-select
                                    formControlName="field"
                                    [placeholder]="'DASHBOARD.WIDGET.FORM.FOLDER_INDEX' | translate"
                                    [required]="widgetForm.get(['groupBy', 'field']) | hasRequiredField"
                                    (selectionChange)="changeGroupByField(['groupBy', 'field'],$event)">
                                    <mat-option>
                                        <ngx-mat-select-search
                                            [formControl]="widgetForm.get(['groupBy','groupByFolderIndexFilterCtrl'])"
                                            [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                            [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let item of groupByFieldsList | async"
                                        [value]="item">
                                        <span>{{'DASHBOARD.WIDGET.FORM.FOLDER_INDEX.' + item.name.toUpperCase() |translate  }}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <ng-container *ngIf="isCreationDateType()">
                                <div fxLayout="row wrap" fxLayoutGap="8px">
                                    <mat-form-field appearance="outline" fxFlex="1 3  calc(50% - 8px)"
                                                    fxFlex.lt-sm="100%">
                                        <mat-label>{{'DASHBOARD.WIDGET.FORM.GROUP_BY.LABEL'|translate}}</mat-label>
                                        <input matInput
                                               placeholder="{{'DASHBOARD.WIDGET.FORM.GROUP_BY.LABEL'|translate}}"
                                               formControlName="key">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="2 3  calc(20% - 8px)"
                                                    fxFlex.lt-sm="100%">
                                        <mat-label>{{'DASHBOARD.WIDGET.FORM.GROUP_BY.FROM'|translate}}</mat-label>
                                        <input matInput
                                               placeholder="{{'DASHBOARD.WIDGET.FORM.GROUP_BY.FROM'|translate}}"
                                               type="number"
                                               formControlName="from">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="3 3  calc(20% - 8px)"
                                                    fxFlex.lt-sm="100%">
                                        <mat-label>{{'DASHBOARD.WIDGET.FORM.GROUP_BY.TO'|translate}}</mat-label>
                                        <input matInput placeholder="{{'DASHBOARD.WIDGET.FORM.GROUP_BY.TO'|translate}}"
                                               type="number"
                                               formControlName="to">
                                    </mat-form-field>
                                    <button type="button" mat-icon-button color="accent" class="mt-12"
                                            (click)="onAddGroup()">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                                <div class="w-100-p">
                                    <mat-list class="w-100-p">
                                        <mat-list-item *ngFor="let range of rangeList;let i=index">
                                            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px"
                                                 class="w-100-p">
                                                <div fxFlex="1 4  calc(50% - 8px)" fxFlex.lt-sm="100%">
                                                    <span><strong>{{'DASHBOARD.WIDGET.FORM.GROUP_BY.LABEL'|translate}}</strong>: {{range.key}}</span>
                                                </div>
                                                <div fxFlex="2 4  calc(20% - 8px)" fxFlex.lt-sm="100%">
                                                    <span><strong>{{'DASHBOARD.WIDGET.FORM.GROUP_BY.FROM'|translate}}</strong>: {{range.from}}</span>
                                                </div>
                                                <div fxFlex="3 4  calc(20% - 8px)" fxFlex.lt-sm="100%">
                                                    <span><strong>{{'DASHBOARD.WIDGET.FORM.GROUP_BY.TO'|translate}}</strong>: {{range.to}}</span>
                                                </div>
                                                <mat-icon fxFlex="4 4  calc(10% - 8px)"
                                                          fxFlex.lt-sm="100%" color="warn" class="cursor-pointer"
                                                          (click)="removeRange(i)">
                                                    delete_forever
                                                </mat-icon>
                                            </div>
                                        </mat-list-item>
                                    </mat-list>
                                </div>
                            </ng-container>

                        </fieldset>

                        <fieldset fxLayout="column" fxLayoutGap="8px">
                            <legend class="h3 title-fieldset">{{'DASHBOARD.WIDGET.FILTER.ADD' | translate}}</legend>
                            <div fxLayout="row" fxLayoutAlign="end start">
                                <button type="button" mat-icon-button color="accent"
                                        (click)="onAddFilter()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                            <div fxLayout="column">


                                <div *ngFor="let filter of mapFilter |keyvalue:indexOrderAsc ;let j= index"
                                     formGroupName="{{'filters_'+filter.key}}"
                                     fxLayoutGap="8px">
                                    <div fxLayout="row wrap">
                                        <mat-form-field class="diagnostic-list m-12" appearance="outline"
                                                        fxFlex="30%"
                                                        fxFlex.lt-sm="100%">
                                            <mat-label>{{'DASHBOARD.WIDGET.FORM.FOLDER_INDEX' | translate }}</mat-label>
                                            <button matSuffix mat-icon-button type="button" class="mx-8"
                                                    *ngIf="!!widgetForm.get(['filters_'+filter.key,'key']).value"
                                                    (click)="onClearSelectFormControlValueByPath(['filters_'+filter.key, 'key'])">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                            <mat-select
                                                (focus)="onFilterValueChange(filter.key)"
                                                formControlName="key"
                                                [placeholder]="'DASHBOARD.WIDGET.FORM.FOLDER_INDEX' | translate"
                                                [required]="widgetForm.get(['filters_'+filter.key, 'key']) | hasRequiredField"
                                                (selectionChange)="changeGroupByField(['filters_'+filter.key, 'key'],$event,filter.key)">
                                                <mat-option>
                                                    <ngx-mat-select-search
                                                        [formControl]="widgetForm.get(['filters_'+filter.key,'filterFolderIndexFilterCtrl'])"
                                                        [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                                        [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option
                                                    *ngFor="let item of filterByFieldsList | async"
                                                    [value]="item">
                                                    <span>{{'DASHBOARD.WIDGET.FORM.FOLDER_INDEX.' + item.name.toUpperCase() |translate  }}</span>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <ng-container *ngIf="!!widgetForm.get(['filters_'+filter.key,'key']).value">
                                            <mat-form-field appearance="outline" fxFlex="10%" class="m-12"
                                                            fxFlex.lt-sm="100%">
                                                <mat-label>{{'DASHBOARD.WIDGET.FORM.INFO.TYPE.OPERATOR'|translate}}</mat-label>
                                                <mat-select formControlName="operator" required>
                                                    <mat-option *ngFor="let operator of widgetTypeOperatorList"
                                                                (onSelectionChange)="updateOperatorFilter(operator,filter.key,$event)"
                                                                [value]="operator">
                                                        <span>{{ 'DASHBOARD.WIDGET.FORM.WIDGET_TYPE_OPERATOR.' + operator |translate}}</span>
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!!widgetForm.get(['filters_'+filter.key,'operator']).value">


                                            <ng-container
                                                *ngIf="isListFilter(widgetForm.get(['filters_'+filter.key,'operator']).value,widgetForm.get(['filters_'+filter.key,'key']).value?.type)">
                                                <mat-form-field class="m-12" appearance="outline"
                                                                fxFlex="40%"
                                                                fxFlex.lt-sm="100%">
                                                    <mat-label>{{'DASHBOARD.WIDGET.FILTER.LABEL'|translate}}</mat-label>
                                                    <mat-select
                                                        [formControl]="widgetForm.get(['filters_'+filter.key,'filter'])"
                                                        (focus)="onValueChange(filter.key,j)"
                                                        [placeholder]="'DASHBOARD.WIDGET.FILTER.LABEL' | translate"
                                                        (selectionChange)="selectOneValueFromList($event.value,filter.key)">
                                                        <mat-option>
                                                            <ngx-mat-select-search
                                                                [formControl]="widgetForm.get(['filters_'+filter.key,'filterCtrl'])"
                                                                [placeholderLabel]="'DASHBOARD.WIDGET.FILTER.LABEL' | translate"
                                                                [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"
                                                            ></ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option
                                                            *ngFor="let filterValue of  (selectedFilters[j] | async)"
                                                            [value]="filterValue">
                                                            {{ getWidget(filterValue) | translate }}
                                                        </mat-option>
                                                    </mat-select>
                                                    <span matSuffix fxLayout="row" fxLayoutAlign="start center">
                            <button type="button" mat-icon-button aria-label="Clear"
                                    *ngIf="!!widgetForm.get(['filters_'+filter.key,'filter']).value"
                                    (click)="onClearFilterFilterForm(filter.key)">
                            <mat-icon>close</mat-icon>
                        </button>
                        </span>
                                                </mat-form-field>
                                            </ng-container>

                                            <ng-container
                                                *ngIf="isMultipleSelectionFilter(widgetForm.get(['filters_'+filter.key,'operator']).value,widgetForm.get(['filters_'+filter.key,'key']).value?.type)">
                                                <mat-form-field class="m-12" appearance="outline"
                                                                fxFlex="40%"
                                                                fxFlex.lt-sm="100%">
                                                    <mat-label>{{'DASHBOARD.WIDGET.FILTER.LABEL'|translate}}</mat-label>
                                                    <mat-select multiple
                                                                [formControl]="multipleSelectionValues"
                                                                (focus)="onValueChange(filter.key,j)"
                                                                [placeholder]="'DASHBOARD.WIDGET.FILTER.LABEL' | translate"
                                                                (selectionChange)="selectMultipleValues($event.value,filter.key)">
                                                        <mat-option>
                                                            <ngx-mat-select-search
                                                                [formControl]="widgetForm.get(['filters_'+filter.key,'filterCtrl'])"
                                                                [placeholderLabel]="'DASHBOARD.WIDGET.FILTER.LABEL' | translate"
                                                                [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"
                                                            ></ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option
                                                            *ngFor="let filterValue of  (selectedFilters[j] | async)"
                                                            [value]="filterValue">
                                                            {{ getWidget(filterValue) | translate }}
                                                        </mat-option>
                                                    </mat-select>
                                                    <span matSuffix fxLayout="row" fxLayoutAlign="start center">
                            <button type="button" mat-icon-button aria-label="Clear"
                                    *ngIf="!!widgetForm.get(['filters_'+filter.key,'filter']).value"
                                    (click)="onClearFilterFilterForm(filter.key)">
                            <mat-icon>close</mat-icon>
                        </button>
                        </span>
                                                </mat-form-field>
                                            </ng-container>


                                            <ng-container
                                                *ngIf="isDateFilter(widgetForm.get(['filters_'+filter.key,'operator']).value,widgetForm.get(['filters_'+filter.key,'key']).value?.type)">
                                                <mat-form-field class="m-12" appearance="outline"
                                                                fxFlex="40%"
                                                                fxFlex.lt-sm="100%">
                                                    <mat-label>{{'DASHBOARD.WIDGET.FILTER.LABEL'|translate}}</mat-label>


                                                    <ng-container
                                                        *ngIf="widgetForm.get(['filters_'+filter.key,'operator']).value == 'BETWEEN'; else SELECT_ONE_DATE">
                                                        <mat-chip-list #chipList>
                                                            <ng-container
                                                                *ngIf="!!filter.value.value && filter.value.value.length>0">
                                                                <mat-chip
                                                                    *ngFor="let widgetFilter of filter.value.value"
                                                                    [selectable]="selectable"
                                                                    [removable]="removable"
                                                                    (removed)="remove(widgetFilter,filter.key)">
                                                                    {{ getWidget(widgetFilter) | translate }}
                                                                    <mat-icon matChipRemove *ngIf="removable">cancel
                                                                    </mat-icon>
                                                                </mat-chip>
                                                            </ng-container>
                                                            <input matInput formControlName="filter"
                                                                   #filterInput
                                                                   (dateChange)="selectDateValue($event.value, filter.key, 'selectMultiDate')"
                                                                   [matChipInputFor]="chipList"
                                                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                   [satDatepicker]="picker5">
                                                            <sat-datepicker #picker5 [rangeMode]="true"
                                                                            [selectFirstDateOnClose]="true">
                                                            </sat-datepicker>
                                                            <sat-datepicker-toggle matSuffix [for]="picker5">
                                                            </sat-datepicker-toggle>
                                                        </mat-chip-list>
                                                    </ng-container>
                                                    <ng-template #SELECT_ONE_DATE>
                                                        <input matInput formControlName="filter"
                                                               [matDatepicker]="picker5"
                                                               (dateChange)="selectDateValue($event.value, filter.key, 'selectOneDate')">
                                                        <mat-datepicker #picker5></mat-datepicker>
                                                        <mat-datepicker-toggle matSuffix [for]="picker5">
                                                        </mat-datepicker-toggle>
                                                    </ng-template>


                                                </mat-form-field>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="isCheckboxFilter(widgetForm.get(['filters_'+filter.key,'operator']).value,widgetForm.get(['filters_'+filter.key,'key']).value?.type)">
                                                <mat-radio-group formControlName="filter" class="ml-20" fxLayout="row"
                                                                 fxLayoutAlign="start stretch"
                                                                 labelPosition="after" fxLayoutGap="10px"
                                                                 fxFlex="40%"
                                                                 (change)="selectOneValue($event.value, filter.key)">
                                                    <mat-radio-button class="full-width-radio"
                                                                      [checked]="isRadioChecked('false', filter)"
                                                                      [value]="'false'">
                                                        <div class="my-16" fxLayout="row" fxLayoutAlign="start center"
                                                             fxLayoutGap="30px">
                                                            <div fxFlex>
                                                                <div>{{ 'DASHBOARD.TITLE.FOLDER_IS_OPEN' | translate }}</div>
                                                            </div>
                                                        </div>
                                                    </mat-radio-button>
                                                    <mat-radio-button class="full-width-radio"
                                                                      [checked]="isRadioChecked('true', filter)"
                                                                      [value]="'true'">
                                                        <div class="my-16" fxLayout="row" fxLayoutAlign="start center"
                                                             fxLayoutGap="30px">
                                                            <div fxFlex>
                                                                <div>{{ 'DASHBOARD.WIDGET.FORM.FOLDER_INDEX.CLOSED' | translate }}</div>
                                                            </div>
                                                        </div>
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </ng-container>

                                        </ng-container>
                                        <div fxFlex="4 4  calc(5% - 8px)" fxFlex.lt-sm="100%" fxLayoutAlign="end start">
                                            <button type="button" mat-icon-button color="accent"
                                                    style="margin: 16px 0 0 10px">
                                                <mat-icon color="warn" class="cursor-pointer"
                                                          (click)="removeFilter(filter.key,j)">
                                                    delete_forever
                                                </mat-icon>
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </fieldset>

                    </div>

                    <mat-card-footer >
                        <div  fxLayoutAlign="end end" fxLayoutGap=" 8px" class="mt-12 mr-8">
                            <button mat-stroked-button (click)="onReset()" type="button"
                                    class="mb-8 mr-8  aster_btn_cancel">
                                {{'BUTTON.CANCEL' | translate}}
                            </button>
                            <div *ngIf="isSimulation">
                                <button mat-stroked-button (click)="simulate()" type="button"
                                        class="mb-8 mr-8 aster_btn_cancel">
                                    {{'BUTTON.SIMULATE' | translate}}
                                </button>
                                <button mat-stroked-button color="accent"
                                        (click)="onSubmit()" class="aster_btn mb-8 mr-8 ">
                                    {{(!isUpdateMode ? 'BUTTON.VALIDATE' : 'CODE_LABEL_CONFIG.FORM.SUBMIT_BUTTON.UPDATE') | translate}}
                                </button>
                            </div>
                            <button mat-stroked-button color="accent"
                                    *ngIf="!isSimulation"
                                    (click)="simulate()"
                                    [disabled]="widgetForm.invalid"
                                    [ngClass]="( widgetForm.invalid ? 'aster_btn_disabled' : 'aster_btn') + '  mb-8 mr-8' ">
                                {{'BUTTON.SIMULATE' | translate}}
                            </button>
                        </div>
                    </mat-card-footer>
                </form>
            </ng-container>
        </mat-card>
        <mat-card fxFlex="50" class="w-50-p" *ngIf="isSimulation">

            <ng-container>

                <legend class="h3 title-fieldset">{{'DASHBOARD.WIDGET.SIMULATION' | translate}}</legend>

                <ng-container [ngSwitch]="widgetData.type">
                    <ng-container *ngSwitchCase="'METRIC'">

                        <widget-price-count [widgetData]="widgetData"></widget-price-count>

                    </ng-container>
                    <ng-container *ngSwitchCase="'BAR_CHART'">

                        <widget-bar-chart-horizontal
                            [widgetData]="widgetData">
                        </widget-bar-chart-horizontal>

                    </ng-container>
                    <ng-container *ngSwitchCase="'PIE_CHART'">

                        <widget-pie-chart [widgetData]="widgetData">
                        </widget-pie-chart>

                    </ng-container>
                    <ng-container *ngSwitchCase="'TABLE'">

                        <widget-list-table [widgetData]="widgetData"></widget-list-table>

                    </ng-container>
                    <ng-container *ngSwitchCase="'PIE_CHART_GRID'">

                        <pie-chart-grid [widgetData]="widgetData"></pie-chart-grid>

                    </ng-container>
                    <ng-container *ngSwitchCase="'ADVANCED_PIE_CHART'">

                        <advanced-pie-chart [widgetData]="widgetData"></advanced-pie-chart>

                    </ng-container>
                </ng-container>

            </ng-container>
        </mat-card>
    </div>
</div>
