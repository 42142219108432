    <mat-card-content>
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
        <form [formGroup]="sparePartsCarrierToBeConfirmedForm.form" fxLayout="column"
              fxLayoutAlign="start">
            <mat-form-field appearance="outline" class="w-50-p">
                <mat-label> {{'FOLDER.TRACKING_NUMBER' | translate}}</mat-label>
                <input matInput placeholder="{{'FOLDER.TRACKING_NUMBER' | translate}}"
                       formControlName="trackingNumber">
            </mat-form-field>

            <mat-form-field appearance="outline"  class="w-50-p">
                <mat-label>{{'FOLDER.CARRIER_CODE'|translate}}</mat-label>
                <mat-select formControlName="carrier">
                    <mat-option *ngFor="let carrierItem of carriers$ | async"
                                [value]="carrierItem.code">
                        <span>{{  carrierItem.code + ' - ' + carrierItem.label }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <app-ordered-spare-parts class="mb-24" [orderedSpareParts]="orderedSpareParts"></app-ordered-spare-parts>

            <div fxLayoutAlign="end end" *ngIf="sparePartsCarrierToBeConfirmedForm.form">
                <button mat-stroked-button
                        [disabled]="sparePartsCarrierToBeConfirmedForm.form.invalid"
                        (click)="onSubmit()"
                        [ngClass]=" !!sparePartsCarrierToBeConfirmedForm.form.invalid? 'aster_btn_disabled' : 'aster_btn'"
                        class="h-60 mt-4">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>