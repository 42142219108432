import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../models/folder.model';
import {ProductLocationTypes} from '../../../../../models/product.model';
import {ReceptionModeEnum} from '../../../../../models/enums/receptionMode.enum';
import {OriginEnum} from '../../../../../models/enums/origin.enum';
import {ProductUtils} from "../../../../../shared/utils/product-utils";
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-client-decision-unrepairable-product',
    templateUrl: './waiting-client-decision-unrepairable-product.component.html',
    styleUrls: ['./waiting-client-decision-unrepairable-product.component.scss']
})
export class WaitingClientDecisionUnrepairableProductComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    @Output() inputMap = new EventEmitter<any>();

    returnProductForm: any = {
        form: null,
        placeholders: []
    };

    constructor() {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm(): void {
        this.returnProductForm.form = new FormGroup({
            choice: new FormControl(null, Validators.required),
        });
        this.returnProductForm.placeholders = [
            {
                choice: 'true',
                value: 'BUTTON.YES',
            },

            {
                choice: 'false',
                value: 'BUTTON.NO',
            }
        ];
    }

    getCurrentLocation = () => ProductUtils.latestLocation(this.folder.product);

    onSubmit(): void {
        const receptionMode = this.IsIgnoringReceptionShipmentProcess() ? ReceptionModeEnum.ALREADY_IN_STORE : ReceptionModeEnum.REPAIRER_TO_CUSTOMER;
        this.inputMap.emit({
            'returnProductChoice': this.returnProductForm.form.value.choice,
            'receptionMode': receptionMode
        });
    }

    private IsIgnoringReceptionShipmentProcess(): boolean {
        return this.getCurrentLocation() === ProductLocationTypes.STORE
            || (this.folder.origin === OriginEnum.BO && this.getCurrentLocation() === ProductLocationTypes.REPARATION);
    }
}
