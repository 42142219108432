<h2>
    <mat-icon class="mt-20">assignment</mat-icon>
    {{'DETAILS.INTERNAL_ORDER.CARD.TITLE' | translate}}
</h2>

<ng-container *ngIf="folder.context==='SAV' ">
    <div fxLayout="column" fxLayoutGap="40px" *ngIf="orderedSpareParts.length === 0" >
        <ng-container *ngFor="let item of orderedSparePartsGrouped | keyvalue">
            <app-ordered-spare-parts [isUnderWarranty]="isSG" [orderedSpareParts]="item.value"></app-ordered-spare-parts>
        </ng-container>
    </div>
    <div fxLayout="column" fxLayoutGap="40px" *ngIf="orderedSpareParts.length > 0" >
            <app-ordered-spare-parts [isUnderWarranty]="isSG" [orderedSpareParts]="orderedSpareParts"></app-ordered-spare-parts>
    </div>
</ng-container>
