import {CustomerType} from '../../models/enums/CustomerType.enum';
import {EmailValidatorDirective} from '../../../@fuse/directives/EmailValidatorDirective';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AddressType} from '../../models/enums/addressType.enum';
import {Address} from '../../models/customer/customer.model';

export class CustomerUtils {

    static initializeForm = (requiredFields) =>
        new FormGroup({
            type: new FormControl(CustomerType.PRIVATE_INDIVIDUAL),
            reference: new FormControl(),
            loyaltyCardNumber: new FormControl(),
            gender: new FormControl(null, CustomerUtils.isRequired(requiredFields, 'gender')),
            firstName: new FormControl(null, CustomerUtils.isRequired(requiredFields, 'firstName')),
            lastName: new FormControl(null, CustomerUtils.isRequired(requiredFields, 'lastName')),
            contact: new FormGroup({
                mobileNumber: new FormControl(null, CustomerUtils.isRequired(requiredFields, 'mobileNumber')),
                phoneNumber: new FormControl(null, CustomerUtils.isRequired(requiredFields, 'phoneNumber')),
                email: new FormControl(null, [EmailValidatorDirective.email, CustomerUtils.isRequired(requiredFields, 'email')])
            })
        })

    static isRequired = (requiredFields, field) => requiredFields?.includes(field) ? Validators.required : Validators.nullValidator;

    static hasCustomerChanged = (customer, changes) => CustomerUtils.hasCustomerInfoChanged(customer, changes)
        || CustomerUtils.hasContactChanged(customer, changes.contact)
        || CustomerUtils.hasCompanyChanged(customer, changes.company)
        || CustomerUtils.hasAddressChanged(customer, changes.address)

    static hasCustomerInfoChanged = (customer, changes) => ['type', 'reference', 'loyaltyCardNumber', 'gender', 'firstName', 'lastName']
        .some(field => changes[field] !== customer[field])

    static hasAddressChanged = (customer, changes) => ['address', 'zipCode', 'city', 'countryCode', 'countryName']
        .some(field => changes[field] !== customer[field])

    static hasContactChanged = (customer, contactChanges) => contactChanges && (
        contactChanges.email !== customer.contact.email ||
        contactChanges.mobileNumber?.split(' ').join('') !== customer.contact.mobileNumber?.split(' ').join(''))

    static hasCompanyChanged = (customer, companyChanges) => companyChanges && (
        companyChanges.legalForm !== customer.company?.legalForm ||
        companyChanges.label !== customer.company?.label)

    static getDefaultAddress = (addresses: Address[]) =>
        addresses && addresses.length > 0
            ? addresses.find(address => address.type === AddressType.DEFAULT) ?? addresses[0]
            : new Address()

    static hasValue = (customer, field) => !!customer[field]
        || Object.keys(customer).map(property => {
            if (property === 'addresses') {
                return CustomerUtils.getDefaultAddress(customer.addresses)[field];
            }
            return customer[property] ? customer[property][field] : null;
        }).some(Boolean)

    static allRelatedFieldsHaveValues = (customer, relatedFields, field) => !relatedFields ||
        !relatedFields[field] ||
        relatedFields[field]
            .map(relatedField => CustomerUtils.hasValue(customer, relatedField))
            .reduce((first, second) => first && second)
}
