<mat-card class="mat-elevation-aster">

    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutAlign="start">
            <mat-card-title class="product-title text-bold pr-24 pt-20 pl-20">
                {{'COMPONENT.PRODUCT_PHOTO_TASK.TEXT' | translate}}
            </mat-card-title>
        </div>
    </div>

    <mat-card-content>

        <div class="w-100-p mt-12" fxLayout="row wrap"
             gdColumns="repeat(auto-fit, minmax(100%, 1fr))"
             gdGap="10px">

            <ng-container *ngIf="attachmentsFiles.length === 0">
                <mat-chip-list class="w-50-p">
                    <mat-chip color="primary" class="upload-image_style" (click)="onUploadImage()">
                        <mat-icon class="mr-8">camera_alt</mat-icon>
                        <span
                            class="ml-4">{{'COMPONENT.PRODUCT_PHOTO_TASK.DESCRIPTION'|translate}}</span>
                    </mat-chip>
                </mat-chip-list>
            </ng-container>
            <div *ngIf="!isEmptyAttachmentFiles()">
                <div fxLayout="row wrap" fxLayoutAlign="space-evenly center"
                     *ngFor="let file of attachmentsFiles;let i=index ">
                    <ng-container>
                        <img (click)="showImageModal(file.src)"
                             class="cursor-pointer zone-file_img img-product_folder"
                             [src]="file.src" width="100" height="100">
                    </ng-container>
                    <h4 class="text-bold ml-44">{{file.name}}</h4>

                    <mat-icon color="warn" class="cursor-pointer"
                              (click)="removeFileFromAttachment(file.id)">
                        delete_forever
                    </mat-icon>
                </div>
                <div fxLayoutAlign="end end">
                    <button mat-stroked-button
                            matTooltip="{{'BUTTON.VALIDATE' | translate}}" type="submit"
                            (click)="completeTask()"
                            class="h-60 mt-4 pl-24 pr-24 aster_btn">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>