<form [formGroup]="contactForm" fxLayout="column"
      fxLayoutAlign="start" autocomplete="off" id="form1">
    <mat-form-field appearance="outline" fxFlex="100" floatLabel="always">
        <mat-label>{{'CODE_LABEL_CONFIG.FORM.FIELDS.PHONE_NUMBER.LABEL' | translate}}
        </mat-label>
        <input (input)="updatePhoneInput($event)"
               [required]="contactForm.get('mobileNumber') | hasRequiredField"
               matInput ng2TelInput
               numberOnly
               formControlName="mobileNumber" id="ng2TelInput_phoneNumber"
               [ng2TelInputOptions]="{ preferredCountries: [countryCode] , localizedCountries: { fr: 'France' }, utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'}"
               (countryChange)="onCountryChange($event)"
               placeholder="{{'CUSTOMER.PHONE'|translate}}"/>
        <mat-icon *ngIf="isSearchable()" matSuffix class="disabled-text">search</mat-icon>
        <mat-error
            *ngIf="mobileNumber.invalid && (mobileNumber.dirty
            || mobileNumber.touched)">{{"FROM.ERROR.MESSAGE.PHONE_NUMBER" |translate}}</mat-error>
    </mat-form-field>
</form>
