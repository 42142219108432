import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatCalendarHeader} from '@angular/material/datepicker';

/** Custom header component for datepicker.
 * https://stackoverflow.com/a/57523930/14474468
 */
@Component({
    selector: 'date-picker-custom-header',
    templateUrl: 'DatePickerCustomHeader.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})


export class DatePickerCustomHeaderComponent extends MatCalendarHeader<any> {

    /** Handles user clicks on the period label. */
    currentPeriodClicked(): void {
        this.calendar.currentView = this.calendar.currentView === 'month' ? 'multi-year' : 'month';
    }

    /** Handles user clicks on the previous button. */
    previousButton(): void {
            console.log(this.calendar.activeDate);
            this.previousClicked();
    }

    /** Handles user clicks on the next button. */
    nextButton(): void {
            console.log(this.calendar.activeDate);
            this.nextClicked();
    }

}
