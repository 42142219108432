import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {ConfigurationReferential} from '../../../../../models/configurationReferential.model';
import {FolderService} from '../../../../../shared/services/folder.service';
import {OrderedSparePartsRequest, SparePartType} from '../../../../../models/visio/spare-part.model';
import {TaskVariables} from '../../task.variables';
import {Folder} from '../../../../../models/folder.model';
import {QuotationLine} from '../../../../../models/quotation.model';
import {select, Store} from '@ngrx/store';
import {allConfiguration} from '../../../../../store/configuration/configuration.selectors';
import {AppState} from '../../../../../store/app.state';
import {Unsubscriber} from '../../../../../unsubscriber';
import {Observable} from 'rxjs';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
  selector: 'app-carrier-to-be-confirmed',
  templateUrl: './carrier-to-be-confirmed.component.html',
  styleUrls: ['./carrier-to-be-confirmed.component.css']
})
export class CarrierToBeConfirmedComponent extends Unsubscriber implements OnInit {

    @Input() variablesTask: any;
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    carriers$: Observable<ConfigurationReferential[]>;
    carrierToBeConfirmedForm: any = {
        form: null,
    };
    carrierCode: string;
    orderedSpareParts: QuotationLine[];
    private requestedServiceId: string;

    constructor(private store$: Store<AppState>,
                private folderService: FolderService) {
        super();
    }

    ngOnInit(): void {
        this.initForm();
        this.initCarrierList();
        if (this.requestedServiceId) {
            this.getOrderedSpareParts();
        }
    }

    initializeVariablesTask(): void {
        if (this.variablesTask['carrierCode']) {
            this.carrierCode = this.variablesTask['carrierCode'];
            this.carrierToBeConfirmedForm.form.get(['carrier']).setValue(this.carrierCode);
        }
        this.requestedServiceId = this.variablesTask[TaskVariables.requestedServiceId];
    }

    private initForm(): void {
        this.carrierToBeConfirmedForm.form = new FormGroup({
            trackingNumber: new FormControl(null, Validators.required),
            carrier: new FormControl(null, Validators.required),
        });
        this.initializeVariablesTask();
    }

    onSubmit(): void {
        this.inputMap.emit({
            'trackingNumber': this.carrierToBeConfirmedForm.form.get('trackingNumber').value,
            'carrierCode': this.carrierToBeConfirmedForm.form.get('carrier').value,
        });
    }


    initCarrierList(): void {
        this.carriers$ = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.CARRIER}));
    }

    getOrderedSpareParts(): void {
        const orderedSparePartsRequest: OrderedSparePartsRequest = {
            requestedServiceId: this.requestedServiceId,
            quotationLineTypes: [SparePartType.SPARE_PART]
        };
        this.folderService.getOrderedSpareParts(this.folder.id, orderedSparePartsRequest).subscribe(orderedSpareParts => {
            this.orderedSpareParts = orderedSpareParts;
        });
    }

}
