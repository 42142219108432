import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GLOBAL} from '../../app-config';
import {Observable} from 'rxjs';
import {Order, OrderUpdateRequest} from '../../models/order.model';


@Injectable({
    providedIn: 'root'
})
export class OmsService {

    private path = `${GLOBAL.gatewayEndpoint}/order-management-service/api/v1/orders`;

    constructor(public httpClient: HttpClient) {
    }

    createOrder(order: Order): Observable<Order> {
        return this.httpClient.post<Order>(`${this.path}`, order);
    }

    updateOrder(orderId: string, order: OrderUpdateRequest): Observable<Order> {
        if (orderId) {
            return this.httpClient.patch<Order>(`${this.path}/${orderId}`, order);
        }
    }

    getOrderByFolderId(folderId: string): Observable<Order[]> {
        return this.httpClient.get<Order[]>(`${this.path}/folder/${folderId}`);
    }
}
