    <mat-card-content>
        <form [formGroup]="validateReparation.form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <ng-container *ngIf="isProductNotRepaired(); else PRODUCT_REPAIRED">
                <mat-checkbox formControlName="productReceived">
                    {{'BUTTON.PRODUCT_RECEIVED' | translate}}
                </mat-checkbox>
            </ng-container>
            <ng-template #PRODUCT_REPAIRED>
                <mat-radio-group formControlName="choice" class="ml-20" fxLayout="row"
                                 fxLayoutAlign="space-between center"
                                 labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch"
                                 fxLayoutGap="10px">
                    <mat-radio-button [id]="status.choice" class="full-width-radio"
                                      *ngFor="let status of validateReparation.placeholders"
                                      [value]="status.choice">
                        <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div fxFlex>
                                <div>{{ status.value | translate }}</div>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </ng-template>
            <app-accessories-label-list *ngIf="accessoriesLabel.length>0" [accessoriesLabel]="accessoriesLabel"></app-accessories-label-list>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        [ngClass]="isFormInvalid()?'aster_btn_disabled':'aster_btn'"
                        [disabled]="isFormInvalid()">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>