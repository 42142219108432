<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="text-bold">
        <mat-icon class="icon-color">warning</mat-icon>
        {{'MODAL.ALERT.TITLE' | translate}}</h1>
    <button mat-icon-button class="ml-40" (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <fieldset *ngFor="let alert of alerts">
        <legend class="h3 title-fieldset">{{'MODAL.ALERT.CONTENT' | translate}}</legend>
        <ng-container *ngIf="alert">
            <div [innerHTML]="alert.content" ></div>
        </ng-container>

    <fieldset class="mt-16 mb-16">
        <legend class="h3 title-fieldset">{{"ATTACHMENT"|translate}}</legend>
        <ng-container>
            <div *ngIf=" !!attachmentsFiles && attachmentsFiles.length>0">
                <ng-container *ngFor="let attachment of displayAttachmentsByAlert(alert)">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="detail">
                        <a class="cursor-pointer"
                             (click)="downloadAttachment(attachment.id, attachment.name)">
                            <mat-icon>file_copy</mat-icon>
                            {{attachment.name}}
                        </a>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </fieldset>
    </fieldset>
    <div fxLayout="row" fxLayoutAlign="end end" class="mt-16 mb-16" *ngIf="data.showButton">
        <div>
            <button mat-button
                    (click)="onClose()" type="submit" mat-stroked-button aria-label="Submit"
                    color="accent"
                    class="aster_btn">
                {{'MODAL.ALERT.BUTTON' | translate}}
            </button>
        </div>
    </div>
</mat-dialog-content>
