import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {ConfigurationReferential} from '../../../../../models/configurationReferential.model';
import {FolderService} from '../../../../../shared/services/folder.service';
import {QuotationLine} from '../../../../../models/quotation.model';
import {Folder} from '../../../../../models/folder.model';
import {OrderedSparePartsRequest, SparePartType} from '../../../../../models/visio/spare-part.model';
import {TaskVariables} from '../../task.variables';
import {select, Store} from '@ngrx/store';
import {allConfiguration} from '../../../../../store/configuration/configuration.selectors';
import {AppState} from '../../../../../store/app.state';
import {Observable} from 'rxjs';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
  selector: 'app-spare-parts-order-waiting-preparation',
  templateUrl: './spare-parts-order-waiting-preparation.component.html',
  styleUrls: ['./spare-parts-order-waiting-preparation.component.css']
})
export class SparePartsOrderWaitingPreparationComponent implements OnInit {

    @Input() variablesTask: any;
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    sparePartsCarrierToBeConfirmedForm: any = {
        form: null,
    };
    carrierCode: string;
    orderedSpareParts: QuotationLine[];

    carriers$: Observable<ConfigurationReferential[]>;

    constructor(private folderService: FolderService,
                private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initCarrierList();
        this.getOrderedSpareParts();
    }

    initializeVariablesTask(): void {
        if (this.variablesTask['carrierCode']) {
            this.carrierCode = this.variablesTask['carrierCode'];
            this.sparePartsCarrierToBeConfirmedForm.form.get(['carrier']).setValue(this.carrierCode);
        }
    }

    private initForm(): void {
        this.sparePartsCarrierToBeConfirmedForm.form = new FormGroup({
            trackingNumber: new FormControl(null, Validators.required),
            carrier: new FormControl(null, Validators.required),
        });
        this.initializeVariablesTask();
    }
    onSubmit(): void {
        this.inputMap.emit({
            'trackingNumber': this.sparePartsCarrierToBeConfirmedForm.form.get('trackingNumber').value,
            'carrierCode':  this.sparePartsCarrierToBeConfirmedForm.form.get('carrier').value,
        });
    }


    initCarrierList(): void {
        this.carriers$ = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.CARRIER}));
    }

    getOrderedSpareParts(): void {
        const orderedSparePartsRequest: OrderedSparePartsRequest = {
            requestedServiceId: this.variablesTask[TaskVariables.requestedServiceId],
            quotationLineTypes: [SparePartType.SPARE_PART]
        };
        this.folderService.getOrderedSpareParts(this.folder.id, orderedSparePartsRequest).subscribe(orderedSpareParts => {
            this.orderedSpareParts = orderedSpareParts;
        });
    }

}
