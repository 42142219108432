import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FolderService} from '../../../../../shared/services/folder.service';
import {Folder, RepairCenter} from '../../../../../models/folder.model';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {GridComponent} from '@syncfusion/ej2-angular-grids';
import {Unsubscriber} from '../../../../../unsubscriber';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-confirm-repairer-home-repair',
    templateUrl: './confirm-repairer-home-repair.component.html',
    styleUrls: ['./confirm-repairer-home-repair.component.scss']
})
export class ConfirmRepairerHomeRepairComponent extends Unsubscriber implements OnInit {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    @Output() inputMap = new EventEmitter<any>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('grid') public grid: GridComponent;

    repairers: RepairCenter [];
    selectedRepairer: RepairCenter;

    constructor(private folderService: FolderService) {
        super();
    }

    ngOnInit(): void {
        this.folderService.getRepairCenter(this.folder.id).subscribe(repairers => {
            this.repairers = repairers;
            if (this.hasSingleItem(this.repairers)) {
                this.selectedRepairer = this.repairers[0];
            }
        });
    }

    private hasSingleItem(listRepairers: RepairCenter[]) {
        return !!listRepairers && listRepairers.length === 1;
    }

    isFormInvalid(): boolean {
        return !this.selectedRepairer;
    }

    onSubmit() {
        this.inputMap.emit({
            repairCenterCode: this.selectedRepairer.code
        });
    }
}
