<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="text-bold">
        {{'FOLDER.COMMENT.CONTENT' | translate}}</h2>
    <button mat-icon-button class="ml-40" (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div *ngIf="!!mergedMaps">
        <app-folder-comments-card
            [comments]="mergedMaps"></app-folder-comments-card>
    </div>

    <p class="h4 text-bold">{{'MODAL.COMMENT.TITLE_ADD_COMMENT' | translate}} </p>
    <form [formGroup]="commentForm" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="editFolderDetails()">
        <div fxLayout="row" fxLayoutAlign="start center">

            <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                <mat-label>{{'MODAL.COMMENT.DESCRIPTION' | translate}}</mat-label>
                <textarea matInput rows="4" cols="7"
                          [placeholder]="'MODAL.COMMENT.DESCRIPTION' | translate"
                          formControlName="description"></textarea>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="end end">
            <div>
                <button type="submit" mat-stroked-button aria-label="Submit" color="accent"
                        [disabled]="commentForm.invalid"
                        [ngClass]="commentForm.invalid?'aster_btn_disabled':'aster_btn'"
                        class="aster_btn">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </div>
    </form>
</mat-dialog-content>


