import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-for-technician-to-intervene',
    templateUrl: './waiting-to-intervene.component.html',
    styleUrls: ['./waiting-to-intervene.component.css']
})
export class WaitingToInterveneComponent implements OnInit {


    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    @Output() previewTask = new EventEmitter<any>();
    @Input() variablesTask: any;

    waitingToInterveneForm: any = {
        form: null,
        placeholders: []
    };

    constructor() {
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.waitingToInterveneForm.form = new FormGroup({
            choice: new FormControl(null, Validators.required),
        });
        this.waitingToInterveneForm.placeholders = [
            {
                choice: 'true',
                value: 'BUTTON.PRODUCT_REPAIRED_BY_INTERVENTION_TECHNICIAN',
            },

            {
                choice: 'false',
                value: 'BUTTON.PRODUCT_REPAIRED_BY_CALL_CENTER',
            }
        ];
    }

    onSubmit(): void {
        this.inputMap.emit({
            'technicianIntervention': this.waitingToInterveneForm.form.value.choice
        });
    }

    getPreviewTask(): void {
        let previewTask;
        if ('ableToRepair' in this.variablesTask && this.variablesTask['ableToRepair'] === 'false') {
            previewTask = {
                'currentTask': 'WAITING_TO_INTERVENE',
                'previewsTask': 'WAITING_ASSISTANCE_TO_CALL'
            };
        } else {
            previewTask = {
                'currentTask': 'WAITING_TO_INTERVENE',
                'previewsTask': 'CUSTOMER_REPAIR_IN_PROGRESS'
            };
        }
        this.previewTask.emit(previewTask);
    }
}
