import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../app/store/app.state';
import {isRoleAuthorized} from '../../../../../app/store/user/user.selectors';
import {Observable} from 'rxjs';

@Component({
    selector: 'fuse-nav-horizontal-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class FuseNavHorizontalItemComponent implements OnInit {
    @HostBinding('class')
    classes = 'nav-item';

    @Input() item: any;

    userHasAccess$: Observable<boolean>;

    constructor(private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.userHasAccess$ = this.store$.pipe(select(isRoleAuthorized, {item: this.item}));
    }
}
