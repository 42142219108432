<ng-container *ngIf="orderedSpareParts?.length>0">
    <div fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="8px">
        <table mat-table [dataSource]="orderedSpareParts" class="mat-elevation-z8">

            <!-- Code Column -->
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef><strong>{{'COMPONENT.FOLDER_CART_ITEMS_CARD.CODE'|translate}}</strong></th>
                <td mat-cell width ="20%" *matCellDef="let element"> {{element.code}} </td>
            </ng-container>

            <!-- Label Column -->
            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef><strong>{{'COMPONENT.FOLDER_CART_ITEMS_CARD.LABEL'|translate}}</strong></th>
                <td mat-cell width ="40%" *matCellDef="let element"> {{element.label}} </td>
            </ng-container>

            <!-- Quantity Column -->
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef><strong>{{'ASSET_CATALOG.FORM.FIELDS.QUANTITY'|translate}}</strong></th>
                <td mat-cell width ="10%" *matCellDef="let element"> {{element.quantity}}</td>
            </ng-container>

            <!-- Price Column -->
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef><strong>{{'COMPONENT.FOLDER_CART_ITEMS_CARD.SALE.PRICE.HT'|translate}}</strong></th>
                <td mat-cell width ="10%" *matCellDef="let element"> {{element.priceAmount?.value === 0 && isUnderWarranty ? ('ASSET_CATALOG.FORM.FIELDS.FREE' | translate) : (element.priceAmount | formatAmount)}} </td>
            </ng-container>

            <!-- VAT Column -->
            <ng-container matColumnDef="tva">
                <th mat-header-cell *matHeaderCellDef><strong>{{'COMPONENT.FOLDER_CART_ITEMS_CARD.TVA'|translate}}</strong></th>
                <td mat-cell width ="10%" *matCellDef="let element"> {{element.vat.rate}}%</td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>
    </div>

</ng-container>