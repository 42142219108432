import {Pipe, PipeTransform} from '@angular/core';
import libphonenumber from 'google-libphonenumber';
import PhoneNumberFormat = libphonenumber.PhoneNumberFormat;

@Pipe({
    name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

    private phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

    transform(number: string, countryCode: string, format: PhoneNumberFormat): string {
        const phoneNumber = number;
        const phone = this.getPhoneObject(number, countryCode);
        if (phone !== null) {
            return this.phoneUtil.format(phone, format);
        }
        return phoneNumber;
    }

    getPhoneObject(number: string, countryCode: string) {
        try {
            return this.phoneUtil.parse(number, countryCode);
        } catch (e) {
            return null;
        }

    }

    getFormattedPhone(number: string, countryCode: string, format: PhoneNumberFormat): any {
        const formattedPhone: any = {
            phoneNumber: '',
            countryCode: ''
        };
        const phone = this.getPhoneObject(number, countryCode);
        if (phone !== null) {
            formattedPhone.phoneNumber = this.phoneUtil.format(phone, format);
            formattedPhone.countryCode = this.phoneUtil.getRegionCodeForNumber(phone);
        } else {
            formattedPhone.phoneNumber = number;
            formattedPhone.countryCode = null;
        }
        return formattedPhone;
    }
    getFormattedMobilePhone(number: string, countryCode: string, format: PhoneNumberFormat): any {
        const formattedPhone: any = {
            mobileNumber: '',
            countryCode: ''
        };
        const phone = this.getPhoneObject(number, countryCode);
        if (phone !== null) {
            formattedPhone.mobileNumber = this.phoneUtil.format(phone, format);
            formattedPhone.countryCode = this.phoneUtil.getRegionCodeForNumber(phone); // cette methode ne fonctionne plus
        } else {
            formattedPhone.mobileNumber = number;
            formattedPhone.countryCode = null;
        }
        return formattedPhone;
    }
    isMobileNumber(number: string, countryCode: string): boolean {
        const phone = this.getPhoneObject(number, countryCode);
        if (phone !== null) {
            return this.phoneUtil.getNumberType(phone) === 1;
        }
        return false;
    }

    isLandLinePhone(phoneNumber: string, countryCode: string): boolean {
        const phone = this.getPhoneObject(phoneNumber, countryCode);
        if (phone !== null) {
            return this.phoneUtil.getNumberType(phone) === 0;
        }
        return false;
    }
}
