import {NgModule} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@NgModule({
    imports: [],
    exports: []
})
export class IconSvgModule {

    constructor(private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer) {
        this.matIconRegistry.addSvgIcon('assistance_white', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/assistance_white.svg'));
        this.matIconRegistry.addSvgIcon('assistance', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/assistance.svg'));
        this.matIconRegistry.addSvgIcon('user_service_white', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/user-service_white.svg'));
        this.matIconRegistry.addSvgIcon('user_service', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/user-service.svg'));
        this.matIconRegistry.addSvgIcon('email_icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/email-icon.svg'));
        this.matIconRegistry.addSvgIcon('email_icon_white', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/email-icon_white.svg'));
        this.matIconRegistry.addSvgIcon('facebook', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/facebook.svg'));
        this.matIconRegistry.addSvgIcon('instagram', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/instagram.svg'));
        this.matIconRegistry.addSvgIcon('twitter', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/twitter.svg'));
        this.matIconRegistry.addSvgIcon('slash_icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/slash-icon.svg'));
        this.matIconRegistry.addSvgIcon('organizatin_icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/organizatin-icon.svg'));
        this.matIconRegistry.addSvgIcon('supplier', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/supplier.svg'));
        this.matIconRegistry.addSvgIcon('date_purchase', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/date_purchase.svg'));
        this.matIconRegistry.addSvgIcon('marque_icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/marque.svg'));
        this.matIconRegistry.addSvgIcon('price_purchase', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/price_purchase.svg'));
        this.matIconRegistry.addSvgIcon('icon_smile', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/icon_smile.svg'));
        this.matIconRegistry.addSvgIcon('icon_symptome', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/symptome.svg'));
        this.matIconRegistry.addSvgIcon('icon_warranty', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/warranty.svg'));
        this.matIconRegistry.addSvgIcon('icon_user_black', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/user_black.svg'));
        this.matIconRegistry.addSvgIcon('icon_phone_black', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/icon_phone_black.svg'));
        this.matIconRegistry.addSvgIcon('icon_home_black', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/icon_home_black.svg'));
        this.matIconRegistry.addSvgIcon('icon_image_black', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/icon_image.svg'));
        this.matIconRegistry.addSvgIcon('icon_validate_cercle_green', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/validate_cercle_green.svg'));
        this.matIconRegistry.addSvgIcon('validate_icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/validate_icon.svg'));
        this.matIconRegistry.addSvgIcon('pdf_icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/pdf.svg'));
        this.matIconRegistry.addSvgIcon('icon_morning', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/morning.svg'));
        this.matIconRegistry.addSvgIcon('icon_afternoon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/afternoon.svg'));

        this.matIconRegistry.addSvgIcon('spare_parts', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/spare_parts.svg'));
        this.matIconRegistry.addSvgIcon('repair_shop', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/repair-shop.svg'));
        this.matIconRegistry.addSvgIcon('product_exchange', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/product-exchange.svg'));
        this.matIconRegistry.addSvgIcon('home_repair', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/home-repair.svg'));
        this.matIconRegistry.addSvgIcon('pre_diagnostic', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/pre_diagnostic.svg'));
        this.matIconRegistry.addSvgIcon('visio', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/visio.svg'));
        this.matIconRegistry.addSvgIcon('spare_parts_order', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/spare_parts_order.svg'));
        this.matIconRegistry.addSvgIcon('auto_repair', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/auto_repair.svg'));
        this.matIconRegistry.addSvgIcon('depot_store', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/depot_store.svg'));
        this.matIconRegistry.addSvgIcon('manual_processing', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/manual_processing.svg'));
        this.matIconRegistry.addSvgIcon('internal_reparation', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/internal_reparation.svg'));
        this.matIconRegistry.addSvgIcon('calendar_rdv', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/calendar.svg'));

        this.matIconRegistry.addSvgIcon('bar_code', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/bar_code.svg'));
        this.matIconRegistry.addSvgIcon('assistance_black', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/assistance_black.svg'));
        this.matIconRegistry.addSvgIcon('idea', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/idea.svg'));
        this.matIconRegistry.addSvgIcon('refund', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/cash_refund_icon.svg'));
        this.matIconRegistry.addSvgIcon('broken_document', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/broken_document.svg'));
        this.matIconRegistry.addSvgIcon('campaign', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/campaign.svg'));
        this.matIconRegistry.addSvgIcon('quantity', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/quantity.svg'));
        this.matIconRegistry.addSvgIcon('intervention_radius', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/intervention-radius.svg'));
        this.matIconRegistry.addSvgIcon('no_intervention', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/no-intervention.svg'));
        this.matIconRegistry.addSvgIcon('cancel-cart-item', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/cancel-cart-item.svg'));
        this.matIconRegistry.addSvgIcon('add_task', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/add_task.svg'));
        this.matIconRegistry.addSvgIcon('cancel_cart', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/cancel-cart.svg'));
        this.matIconRegistry.addSvgIcon('reception-box', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/box.svg'));
        this.matIconRegistry.addSvgIcon('fo-customer', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/under-customer-area.svg'));
        this.matIconRegistry.addSvgIcon('fo-he-site', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/outside-site-customer-area.svg'));
        this.matIconRegistry.addSvgIcon('fo-retailer', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/outside-customer-area.svg'));
        this.matIconRegistry.addSvgIcon('back-office', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/back-office.svg'));
        this.matIconRegistry.addSvgIcon('chatbot', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/chatbot.svg'));
        this.matIconRegistry.addSvgIcon('borne', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/borne-sav.svg'));
        this.matIconRegistry.addSvgIcon('fo_pid_he', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/fo_pid_he.svg'));
        this.matIconRegistry.addSvgIcon('refund-visio', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/refund.svg'));
        this.matIconRegistry.addSvgIcon('file-type-pdf', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/file-type-pdf.svg'));
        this.matIconRegistry.addSvgIcon('download-file', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/download-file.svg'));

    }

}
