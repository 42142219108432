    <mat-card-content>
        <form [formGroup]="returnProductForm.form" *ngIf="returnProductForm.placeholders" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <div fxLayout="colunm" fxLayoutAlign="start">
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>
            <div fxLayout="column" fxLayoutAlign="start">
                <mat-card-title class="mt-12 question">{{'FOLDER.WAITING_CLIENT_DECISION_UNREPAIRABLE_PRODUCT.QUESTION' | translate}} </mat-card-title>

            </div>
            <mat-radio-group formControlName="choice" class="ml-20" fxLayout="row" fxLayoutAlign="space-between center"
                             labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button class="full-width-radio" *ngFor="let choice of returnProductForm.placeholders"
                                  [value]="choice.choice">
                    <div class="my-4" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                        <div fxFlex>
                            <div>{{ choice.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        [disabled]="returnProductForm.form.invalid"
                        [ngClass]="returnProductForm.form.invalid ? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>