<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo">
        <img class="logo-icon" id="sav-logo-menu_sidebar" src="assets/images/logos/logo_aster.png">
        <span class="logo-text secondary-text">({{organizationLabel$ | async}})</span>
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
    <div class="user" fxLayout="column"
         [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
         *ngIf="currentUser$ | async as user">
        <div class="h3 username">
            <ng-container>{{user.firstName}} {{user.lastName}}</ng-container>
        </div>
        <div class="h5 email hint-text mt-8" *ngIf="user.email">({{user.email}})</div>
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img class="avatar" src="assets/images/avatars/admin.png">
        </div>
    </div>
    <div class="navbar-content">
        <ng-container *ngIf="roleIsRepairerAuthorized() && (hasScope(Scopes.CREATE_FOLDER) | async)">
        <a mat-stroked-button routerLink="/folder/create"
           class="py-12 px-24 mt-24 mb-8 mr-8 font-weight-900 font-size-30 nav-bar-button_new_folder ml-4">
            <mat-icon>create_new_folder</mat-icon>
            <span class="ml-16" >{{'NAV.FOLDER.CREATE'| translate}}</span>
        </a>
        </ng-container>
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>
</div>
