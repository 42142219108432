import {AbstractEntity} from './abstractEntity.model';
import {Organization} from './organization.model';
import {ProductFamily} from './productFamily.model';
import {Supplier} from './supplier.model';
import {FileInfo} from './file-info.model';
import {Amount} from './amount.model';

export class Product extends AbstractEntity {

    code: string;
    codeEAN: string;
    label: string;
    purchasePrice: Amount;
    sellingPrice: Amount;
    organization: Organization;
    brand: string;
    supplier: Supplier;
    imageUrl: string;
    families: ProductFamily[];
    documents?: FileInfo[] = [];
    afterSalesEligible?: boolean;
    manufacturerReference?: string;
    weight?: number;
    warranty?: ProductWarranty;
    repairable: Boolean;
    productType: ProductType;
    locations: ProductLocation[];
    productState: string;
}

export class ProductState {
    state: string;
    stateAttachments: FileInfo[];
}

export class ProductWarranty {
    startDate?: string;
    duration: Number;
    durationUnitEnum: string;
}

export enum ProductType {
    ALIMENTARY = 'ALIMENTARY',
    STANDARD = 'STANDARD'
}

export interface ProductLocation {
    eventTimestamp: number;
    location: ProductLocationTypes;
    coordinates: Coordinates;
    position: string;
}

export interface Coordinates {
    longitude: string;
    latitude: string;
}

export enum ProductLocationTypes {
    UNKNOWN = 'UNKNOWN',
    CLIENT = 'CLIENT',
    REPARATION = 'REPARATION',
    PROVIDER = 'PROVIDER',
    STORE = 'STORE',
}


export interface UpdateLocationCommand {
    targetLocation: string;
    targetPosition?: string;
    silentUpdate?: boolean;
}
