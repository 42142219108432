import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ProductState} from '../../../../models/product.model';
import {UploadModalComponent} from '../../../upload-modal/upload-modal.component';
import {ShowImageModalComponent} from '../../../../main/image-modal/show-image-modal.component';
import * as moment from 'moment';
import {Moment} from 'moment';
import {MatDialog} from '@angular/material/dialog';
import {FileService} from '../../../services/file.service';
import {NgxImageCompressService} from 'ngx-image-compress';
import {AttachmentTypeEnum} from '../../../../models/enums/attachmentType.enum';
import {FileInfo} from '../../../../models/file-info.model';
import {of} from 'rxjs';
import {AppState} from '../../../../store/app.state';
import {select, Store} from '@ngrx/store';
import {Unsubscriber} from '../../../../unsubscriber';
import {organizationCode} from '../../../../store/user/user.selectors';
import {CodeLabelObject} from '../../../../models/codeLabelObject.model';
import {ConfigCodeMapsEnum} from '../../../services/configuration-item-enum';
import {allConfiguration} from '../../../../store/configuration/configuration.selectors';

@Component({
    selector: 'app-product-state',
    templateUrl: './product-state.component.html',
    styleUrls: ['./product-state.component.scss']
})
export class ProductStateComponent extends Unsubscriber implements OnInit, OnChanges {
    listProductState: CodeLabelObject[];
    fileName: string;
    @Input() productState: ProductState;
    @Output() productStateChange: EventEmitter<ProductState> = new EventEmitter<ProductState>();

    public attachmentsFiles = new Array<FileInfo>();
    organizationCode: string;
    selectedValue: string;

    constructor(private matDialog: MatDialog,
                private fileService: FileService,
                private imageCompress: NgxImageCompressService,
                private store$: Store<AppState>
                ) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(organizationCode)).subscribe(organizationCode => {
            this.organizationCode = organizationCode;
        });
        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.PRODUCT_STATE}))
            .subscribe(it => {
                this.listProductState = it;
                this.selectedValue = this.listProductState[0]?.code;
                this.productState.state = this.selectedValue;
            });
    }

    changeProductStatus(status): void {
        this.productState.state = status;
    }


    onUploadImage(): void {
        const dialogRef = this.matDialog.open(UploadModalComponent, {
            width: '900px',
            minWidth: '30%',
            disableClose: true,
            data: {
                acceptedFile: 'image/png,image/jpeg',
                hasDeleteOption: false,
                showPreviews: true
            }
        });
        dialogRef.afterClosed().subscribe(
            (data) => {
                this.onFileChange(data.files);

            }
        );
    }

    private onFileChange(event): void {
        const reader = new FileReader();
        let fileEvent;
        fileEvent = event[0];
        if (event && event.length) {
            const [file] = event;
            reader.readAsDataURL(file);

            reader.onload = () => {
                if (file.type
                    .toLocaleString()
                    .toLowerCase()
                    .split('/')[0] === 'image') {
                    this.compressFile(reader.result, fileEvent);
                } else {
                    this.uploadFile(reader.result.toString().split(',')[1], fileEvent);
                }
            };
            event = null;
        }
    }

    compressFile(image, event): void {
        this.imageCompress.compressFile(image, 1, 100, 50).then(result => {
            this.uploadFile(result.toString().split(',')[1], event);
        }).catch(err => {
        });
    }

    uploadFile(file, event): void {
        const imageBlob = this.fileService.dataURItoBlob(file);
        this.fileService.uploadAttachment(imageBlob, event, this.organizationCode, null,
            AttachmentTypeEnum.PRODUCT_STATUS_IMAGE).subscribe(data => {
            if (data) {
                this.attachmentsFiles.push(data);
            }
            this.productState.stateAttachments = this.attachmentsFiles;
        });
    }


    showImageModal(srcImage): void {
        this.matDialog.open(ShowImageModalComponent, {
            height: 'auto',
            width: '90vh',
            minWidth: '50%',
            data: of([srcImage])
        });
    }

    removeFileFromAttachment(index: number): void {
        this.attachmentsFiles.splice(index, 1);
    }

    getDate(uploadDate: string): Moment {
        return moment(uploadDate);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.productState.state !== this.selectedValue) {
            this.productStateChange.emit(this.productState);

        }
        this.productState.state = this.selectedValue;
        this.productStateChange.emit(this.productState);


    }

}
