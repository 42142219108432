<div class="e-card">
    <div class="e-card-header">
        <div class="e-card-header-caption">
            <div class="e-card-title">{{'PRODUCT.WARRANTY' | translate}}</div>
        </div>
    </div>
    <div class="e-card-content" fxLayout="column" fxLayoutGap="10px">
        <card-element title="PRODUCT.MANUFACTURER_WARRANTY"
                      [value]="manufacturerWarrantyDuration ? manufacturerWarrantyDuration + ' ' + ('PRODUCT_CONFIG.DURATION_UNIT_WARRANTY.' + manufacturerWarrantyUnit | translate) : '-'"
                      [isLoading]="isLoading">
        </card-element>

        <card-element title="PRODUCT.DISTRIBUTOR_WARRANTY"
                      [value]="distributorWarrantyDuration ? distributorWarrantyDuration + ' ' + ('PRODUCT_CONFIG.DURATION_UNIT_WARRANTY.' + distributorWarrantyUnit | translate) : '-'"
                      [isLoading]="isLoading">
        </card-element>
    </div>
</div>