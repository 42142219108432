import {Routes} from '@angular/router';
import {FolderNotFoundComponent} from './folder-not-found/folder-not-found.component';
import {PublicFolderWorkflowComponent} from './public-folder-workflow/public-folder-workflow.component';
import {PublicFolderWorkflowReadOnlyComponent} from './public-folder-workflow-read-only/public-folder-workflow-read-only.component';

export const PublicRoutes: Routes = [
    {
        path: 'public',
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'notfound'
            },
            {
                path: 'repairer',
                pathMatch: 'full',
                redirectTo: 'notfound'
            },
            {
                path: 'supplier',
                pathMatch: 'full',
                redirectTo: 'notfound'
            },
            {
                path: 'repairer/:uid',
                component: PublicFolderWorkflowComponent
            },
            {
                path: 'notfound',
                component: FolderNotFoundComponent
            },
            {
                path: 'spare-parts-order/:uid/:requestedServiceId',
                component: PublicFolderWorkflowComponent
            },
            {
                path: 'supplier/:uid',
                component: PublicFolderWorkflowComponent
            },
            {
                path: ':isSupplier/read-only/:uid',
                component: PublicFolderWorkflowReadOnlyComponent
            }
        ]
    }
];
