<div *ngIf="!!supplier" fxLayout="row" fxLayoutAlign="space-between center center" fxLayoutGap="20px"
     mat-dialog-title style="font-size: medium">
    <div class="text-bold link-underline">
        {{'FOLDER.SUPPLIER.DIALOG.TITLE' | translate}}: {{supplier.supplierCode}} - {{supplier.supplierLabel}}
    </div>
    <button mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>

    <div fxLayout="column" fxLayoutAlign="start" ngStyle.gt-sm="min-width: 500px">

        <div gdAreas="contact address" gdAreas.lt-sm="contact | address" gdGap="20px"
             gdColumns="repeat(auto-fit, minmax(50%, 1fr))">
            <div gdArea="contact">
                <h4 class="text-bold">{{'CODE_LABEL_CONFIG.HEADERS.CONTACT' | translate}}</h4>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8">
                    <mat-icon>contact_phone</mat-icon>
                    <div>
                        {{supplier.phoneNumber || '-'}}
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8">
                    <mat-icon>phone_in_talk</mat-icon>
                    <div>
                        {{supplier.faxNumber || '-'}}
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8">
                    <mat-icon>headset_mic</mat-icon>
                    <div>
                        {{ supplier.hotline || '-' }}
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8">
                    <mat-icon>email</mat-icon>
                    <div>
                        {{supplier.email || '-'}}
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8">
                    <div class="mt-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                        <mat-icon>language</mat-icon>
                        <div>
                            {{supplier.website || '-'}}
                        </div>
                    </div>
                </div>
            </div>
            <div gdArea="address">
                <h4 class="text-bold">{{'CODE_LABEL_CONFIG.HEADERS.ADDRESS' | translate}}</h4>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8"
                     *ngIf="!!supplier.address">
                    <mat-icon class="mr-8">
                        <span
                            *ngIf="!!supplier.address.countryCode && isValidCountryCode(supplier.address.countryCode); else globeIcon">
                            <span style="vertical-align: sub;"
                                  [attr.class]="'flag-icon h-16 w-20 flag-icon-' + supplier.address.countryCode | lowercase"></span>
                        </span>
                        <ng-template #globeIcon>
                            public
                        </ng-template>
                    </mat-icon>
                    <div>
                        {{'COUNTRY.NAME.' + supplier.address.countryCode | translate}},
                        {{supplier.address.city}} -
                        {{supplier.address.zipCode}}
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8">
                    <mat-icon>location_city</mat-icon>
                    <div>
                        {{!!(supplier.address) ? supplier.address.address + ' ' + (!!(supplier.address.secondAddress) ? (', ' + supplier.address.secondAddress) : '') : '-'}}
                    </div>
                </div>
            </div>
        </div>

        <ejs-tab>
            <e-tabitems>
                <e-tabitem *ngFor="let supplierContactsType of supplierContactsTypesOfSupplier" [header]="{text: supplierContactsType.label}" >
                    <ng-template #content>
                        <app-supplier-contact-cards [contacts]="getSupplierContactsByRole(supplierContactsType.code)">
                        </app-supplier-contact-cards>
                    </ng-template>
                </e-tabitem>
            </e-tabitems>
        </ejs-tab>
    </div>
</mat-dialog-content>
