import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
  selector: 'app-refund-to-customer',
  templateUrl: './refund-to-customer.component.html',
  styleUrls: ['./refund-to-customer.component.css']
})
export class RefundToCustomerComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    refundConfirmation = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        if (!this.refundConfirmation) {
            return;
        }
        this.inputMap.emit({});
    }
}
