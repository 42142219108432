import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {QuotationLine} from '../../../../../models/quotation.model';
import {FolderService} from '../../../../../shared/services/folder.service';
import {OrderedSparePartsRequest, SparePartType} from '../../../../../models/visio/spare-part.model';
import {TaskVariables} from '../../task.variables';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
  selector: 'app-spare-parts-order-validated',
  templateUrl: './spare-parts-order-validated.component.html',
  styleUrls: ['./spare-parts-order-validated.component.css']
})
export class SparePartsOrderValidatedComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    sparePartsOrderPrepared = false;
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    orderedSpareParts: QuotationLine[];
    constructor(private folderService: FolderService) {
    }

    ngOnInit(): void {
        this.getOrderedSpareParts();
    }

    onSubmit(): void {
        if (!this.sparePartsOrderPrepared) {
            return;
        }
        this.inputMap.emit({});
    }

    getOrderedSpareParts(): void {
        const orderedSparePartsRequest: OrderedSparePartsRequest = {
            requestedServiceId: this.variablesTask[TaskVariables.requestedServiceId],
            quotationLineTypes: [SparePartType.SPARE_PART]
        };
        this.folderService.getOrderedSpareParts(this.folder.id, orderedSparePartsRequest).subscribe(orderedSpareParts => {
            this.orderedSpareParts = orderedSpareParts;
        });
    }


}
