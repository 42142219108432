import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-swap-in-store',
    templateUrl: './waiting-swap-in-store.component.html',
    styleUrls: ['./waiting-swap-in-store.component.css']
})
export class WaitingSwapInStoreComponent implements OnInit {
    isProductExchanged: boolean;
    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    constructor() {
    }

    ngOnInit(): void {
    }

    onSubmit() {
        if (this.isProductExchanged) {
            this.inputMap.emit({});
        }
    }
}
