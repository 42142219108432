import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FolderPublic} from '../../../../models/folder.public.model';

@Component({
    selector: 'app-public-waiting-pickup',
    templateUrl: './public-waiting-pickup.component.html',
    styleUrls: ['./public-waiting-pickup.component.scss']
})
export class PublicWaitingPickupComponent implements OnInit {
    productPickedUp: boolean;
    @Input() folder: FolderPublic;
    @Output() inputMap = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        this.inputMap.emit({});
    }
}
