/* tslint:disable:max-line-length */
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {CreateFolderComponent} from './create-form/create-folder.component';
import {CustomerInfoCardComponent} from './customer/customer-info-card/customer-info-card.component';
import {FolderSnapshotCardComponent} from './folder-snapshot-card/folder-snapshot-card.component';
import {SiteSnapshotCardComponent} from './site-snapshot-card/site-snapshot-card.component';
import {FolderListComponent} from './folder-list/folder-list.component';

import {WarrantyCardComponent} from './warranty-card/warranty-card.component';
import {FolderWorkflowComponent} from './folder-workflow/folder-workflow.component';
import {WorkflowProcessModule} from './folder-workflow/workflowProcess/workflow-process.module';
import {SingleUploadModalComponent} from './single-upload-modal/single-upload-modal.component';
import {FolderRoutes} from './folder.routing';

import {SurveySummaryComponent} from './folder-detail-tabs-card/survey-symmary/survey-summary.component';
import {FolderDetailTabsCardComponent} from './folder-detail-tabs-card/folder-detail-tabs-card.component';
import {FolderDetailsCardComponent} from './folder-detail-tabs-card/folder-details-card/folder-details-card.component';
import {FolderHistoryStatusCardComponent} from './folder-detail-tabs-card/folder-history-status-card/folder-history-status-card.component';
import {FolderFilesCardComponent} from './folder-detail-tabs-card/folder-files-card/folder-files-card.component';
import {FolderExternalReferencesDialogComponent} from './folder-external-references-dialog/folder-external-references-dialog.component';
import {FolderEventsCardComponent} from './folder-detail-tabs-card/folder-events-card/folder-events-card.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {DetailSupplierDialogComponent} from './folder-detail-tabs-card/folder-details-card/detail-supplier-dialog/detail-supplier-dialog.component';
import {ServiceCenterRepairProcessModule} from './folder-workflow/service-center-repair-process/service-center-repair-process.module';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {DynamicComponentModule} from 'ng-dynamic-component';
import {PhoneNumberPipe} from '../../../@fuse/pipes/phone-number.pipe';
import {SharedGenericModule} from '../../shared/generic/shared-generic.module';
import {CustomerFormComponent} from './create-form/customer/customer-form/customer-form.component';
import {InvoiceFormComponent} from './create-form/invoice-form/invoice-form.component';
import {ProductFormComponent} from './create-form/product-form/product-form.component';
import {SwapProcessModule} from './folder-workflow/swap-process/swap-process.module';
import {TraitementProduitHsModule} from './folder-workflow/traitement-produit-hs/traitement-produit-hs.module';
import {HandleReservationAnomalyComponent} from './folder-workflow/workflowProcess/handle-reservation-anomaly/handle-reservation-anomaly.component';
import {MdePopover, MdePopoverModule} from '@material-extended/mde';
import {DetailRepairDialogComponent} from './folder-detail-tabs-card/folder-details-card/detail-repair-dialog/detail-repair-dialog.component';

import {FolderCommentsModalComponent} from './folder-detail-tabs-card/folder-comments-modal/folder-comments-modal.component';
import {FolderSiteModalComponent} from './folder-detail-tabs-card/folder-site-modal/folder-site-modal.component';
import {FolderPaymentCardComponent} from './folder-detail-tabs-card/folder-payment-card/folder-payment-card.component';
import {FolderQuotationReportCardComponent} from './folder-detail-tabs-card/folder-quotation-report-card/folder-quotation-report-card.component';
import {SymptomSearchDialogComponent} from './folder-detail-tabs-card/folder-details-card/symptom-search-dialog/symptom-search-dialog.component';
import {FolderShipmentCardComponent} from './folder-detail-tabs-card/folder-shipment-card/folder-shipment-card.component';
import {FolderNotFoundComponent} from './folder-not-found/folder-not-found.component';
import {SendToExternalSystemModalComponent} from './folder-snapshot-card/send-to-external-system-modal/send-to-external-system-modal.component';
import {PushNotificationFolderModalComponent} from './folder-snapshot-card/push-notification-folder-modal/push-notification-folder-modal.component';
import {MaterialModule} from '../../../@fuse/material/material.module';
import {FolderErrorComponent} from './folder-error/folder-error.component';
import {ProductRecallProcessModule} from './folder-workflow/product-recall-process/product-recall-process.module';
import {FilesSliderModule} from '../../layout/components/files-slider/files-slider.module';
import {OldFolderReparationsComponent} from './create-form/old-folder-reparations/old-folder-reparations.component';
import {AutoRepairProcessModule} from './folder-workflow/auto-repair-process/auto-repair-process.module';
import {FolderNotificationsCardComponent} from './folder-detail-tabs-card/folder-details-card/folder-notifications-card/folder-notifications-card.component';
import {DetailRemoteAssistanceDialogComponent} from './folder-detail-tabs-card/detail-remote-assistance-dialog/detail-remote-assistance-dialog.component';
import {AddExternalReferencesDialogComponent} from './add-external-references-dialog/add-external-references-dialog.component';
import {
    ItemNotificationCallInfoComponent
} from './folder-detail-tabs-card/folder-details-card/folder-notifications-card/item-notification-call-info/item-notification-call-info.component';
import {FolderCartItemsCardComponent} from './folder-detail-tabs-card/folder-cart-items-card/folder-cart-items-card.component';
import {ChangeCurrentStatusDialogComponent} from './change-current-status-dialog/change-current-status-dialog.component';
import {CreateFolderRepairerComponent} from './create-form/create-folder-repairer/create-folder-repairer.component';
import {InvoiceFolderRepairerComponent} from './create-form/invoice-folder-repairer/invoice-folder-repairer.component';
import {VisioProcessModule} from './folder-workflow/visio-process/visio-process.module';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerModule} from 'saturn-datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MatMomentDateModule, MomentDateAdapter} from '@angular/material-moment-adapter';
import {RefundProtocolSentComponent} from './folder-workflow/refund-product-recall/refund-protocol-sent/refund-protocol-sent.component';
import {RefundProtocolToReturnToClientComponent} from './folder-workflow/refund-product-recall/refund-protocol-to-return-to-client/refund-protocol-to-return-to-client.component';
import {RefundProtocolReturnedToClientComponent} from './folder-workflow/refund-product-recall/refund-protocol-returned-to-client/refund-protocol-returned-to-client.component';
import {WaitingRefundComponent} from './folder-workflow/refund-product-recall/waiting-refund/waiting-refund.component';
import {RepairPackageModule} from './folder-workflow/repair-package/repair-package.module';
import {SendingCommunicationDialogComponent} from './sending-communication-dialog/sending-communication-dialog.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {NgxImageCompressService} from 'ngx-image-compress';
import {SendingProtocolNotificationComponent} from './sending-protocol-notification/sending-protocol-notification.component';
import {SerialNumberComponent} from './folder-detail-tabs-card/folder-details-card/serial-number/serial-number.component';
import {FolderProductLocationComponent} from './folder-detail-tabs-card/folder-product-location/folder-product-location.component';
import {FormatLocationPipe} from './folder-detail-tabs-card/folder-product-location/format-location.pipe';
import {ProductFormChildTableComponent} from './create-form/product-form/product-form-child-table/product-form-child-table.component';
import {FolderCommentsCardComponent} from './folder-detail-tabs-card/folder-comments-card/folder-comments-card.component';
import {DiagExpertDecisionComponent} from './folder-workflow/diag-expert/diag-expert-decision/diag-expert-decision.component';
import {ExpertiseCustomerCallBackComponent} from './folder-workflow/diag-expert/expertise-customer-call-back/expertise-customer-call-back.component';
import {ScheduleExpertiseAtHomeComponent} from './folder-workflow/diag-expert/schedule-expertise-at-home/schedule-expertise-at-home.component';
import {DiagExpertDecisionConfirmationComponent} from './folder-workflow/diag-expert/diag-expert-decision-confirmation/diag-expert-decision-confirmation.component';
import {ValidateSupplierDelayComponent} from './folder-workflow/validate-agreement-delay/validate-supplier-delay/validate-supplier-delay.component';
import {WaitingOnAsyncProcessComponent} from './folder-workflow/waiting-on-async-process/waiting-on-async-process.component';
import {AlertFolderModalComponent} from './folder-snapshot-card/alert-folder-modal/alert-folder-modal.component';
import {AnonymizeDialogComponent} from './anonymize-dialog/anonymize-dialog.component';
import {SwapConfirmationHomeRepairComponent} from './folder-workflow/home-repair/swap-confirmation-home-repair/swap-confirmation-home-repair.component';
import {CustomerSearchListComponent} from './create-form/customer/customer-search-list/customer-search-list.component';
import {CustomerComponent} from './create-form/customer/customer.component';
import {CustomerTypeComponent} from './create-form/customer/customer-form/customer-type/customer-type.component';
import {CustomerSearchComponent} from './create-form/customer/customer-form/customer-search/customer-search.component';
import {CustomerContactComponent} from './create-form/customer/customer-form/customer-contact/customer-contact.component';
import {SelectWorkOrderDialogComponent} from './folder-snapshot-card/select-work-order-dialog/select-work-order-dialog.component';
import {ProductIdentificationComponent} from './create-form/product-identification/product-identification.component';
import {ProductSearchComponent} from './create-form/product-search/product-search.component';
import {BrandsComponent} from './brands/brands.component';
import {AddCompanyFormDirective} from '../../directives/add-company-form.directive';
import {ConnectFormToStoreDirective} from '../../directives/connect-form-to-store.directive';
import {WarrantyReasonSelectorComponent} from './folder-list/warranty-reason-selector/warranty-reason-selector.component';
import {OperatingModeSelectorComponent} from './folder-list/operating-mode-selector/operating-mode-selector.component';
import {DiagnosticInProgressComponent} from './folder-workflow/home-repair/diagnostic-in-progress/diagnostic-in-progress.component';
import {ProductDetailComponent} from './product-detail/product-detail.component';
import {ProductGeneralInfoComponent} from './product-detail/product-general-info/product-general-info.component';
import {ProductNomenclatureComponent} from './product-detail/product-nomenclature/product-nomenclature.component';
import {ProductDocumentsComponent} from './product-detail/product-documents/product-documents.component';
import {ProductWarrantyComponent} from './product-detail/product-warranty/product-warranty.component';
import {ProductSubElementWarrantyComponent} from './product-detail/product-sub-element-warranty/product-sub-element-warranty.component';
import {ProductRepairabilityComponent} from './product-detail/product-reparability/product-repairability.component';
import {ProductSparePartsComponent} from './product-detail/product-spare-parts/product-spare-parts.component';
import {TabModule} from '@syncfusion/ej2-angular-navigations';
import {
    ColumnChooserService,
    ColumnMenuService,
    CommandColumnService,
    DetailRowService,
    FilterService,
    GridModule,
    GroupService,
    PageService,
    ResizeService,
    SearchService,
    SortService,
    ToolbarService
} from '@syncfusion/ej2-angular-grids';
import {SpinnerComponent} from './product-detail/product-spare-parts/spinner/spinner.component';
import {ProductFormSyncfusionTableComponent} from './create-form/product-form/product-form-syncfusion-table/product-form-syncfusion-table.component';
import {UnknownProductComponent} from './create-form/unknown-product/unknown-product.component';
import {SupplierContactCardsComponent} from './folder-detail-tabs-card/folder-details-card/detail-supplier-dialog/supplier-contact-cards/supplier-contact-cards.component';
import {SupplierContactCardComponent} from './folder-detail-tabs-card/folder-details-card/detail-supplier-dialog/supplier-contact-card/supplier-contact-card.component';
import {InvoicesItemsListComponent} from './create-form/invoices-items-list/invoices-items-list.component';
import {DialogModule} from '@syncfusion/ej2-angular-popups';
import {DropDownListAllModule} from '@syncfusion/ej2-angular-dropdowns';
import {PreferredLanguagesComponent} from './create-form/customer/customer-form/preferred-languages/preferred-languages.component';
import { ConfirmRepairerHomeRepairComponent } from './folder-workflow/home-repair/confirm-repairer-home-repair/confirm-repairer-home-repair.component';
import {SearchPhoneCountriesComponent} from './folder-list/search-phone-countries/search-phone-countries.component';
import {SupplierConfirmDialogComponent} from './folder-detail-tabs-card/folder-details-card/confirm-dialog-for-supplier/confirm-dialog-for-supplier.component';
import {ChipListModule} from '@syncfusion/ej2-angular-buttons';

@NgModule({
    declarations: [
        CreateFolderComponent,
        CustomerInfoCardComponent,

        FolderSnapshotCardComponent,
        SiteSnapshotCardComponent,

        FolderListComponent,
        SearchPhoneCountriesComponent,
        WarrantyReasonSelectorComponent,
        OperatingModeSelectorComponent,
        SupplierConfirmDialogComponent,
        FolderEventsCardComponent,
        FolderExternalReferencesDialogComponent,
        AddExternalReferencesDialogComponent,
        FolderFilesCardComponent,
        FolderHistoryStatusCardComponent,
        FolderDetailsCardComponent,
        FolderDetailTabsCardComponent,
        SingleUploadModalComponent,

        FolderCommentsModalComponent,
        FolderSiteModalComponent,
        FolderPaymentCardComponent,
        FolderShipmentCardComponent,
        FolderQuotationReportCardComponent,

        WarrantyCardComponent,
        SurveySummaryComponent,

        CustomerComponent,
        CustomerFormComponent,
        CustomerTypeComponent,
        CustomerContactComponent,
        CustomerSearchComponent,
        CustomerSearchListComponent,

        FolderWorkflowComponent,
        DetailSupplierDialogComponent,
        InvoiceFormComponent,
        ProductFormComponent,
        HandleReservationAnomalyComponent,
        DetailRepairDialogComponent,
        SymptomSearchDialogComponent,
        FolderNotFoundComponent,
        FolderErrorComponent,
        SendToExternalSystemModalComponent,
        PushNotificationFolderModalComponent,
        OldFolderReparationsComponent,
        FolderNotificationsCardComponent,
        DetailRemoteAssistanceDialogComponent,
        ItemNotificationCallInfoComponent,
        ChangeCurrentStatusDialogComponent,
        FolderCartItemsCardComponent,
        CreateFolderRepairerComponent,
        InvoiceFolderRepairerComponent,
        RefundProtocolSentComponent,
        RefundProtocolToReturnToClientComponent,
        RefundProtocolReturnedToClientComponent,
        WaitingRefundComponent,
        SendingCommunicationDialogComponent,
        SendingProtocolNotificationComponent,
        SerialNumberComponent,
        FolderProductLocationComponent,
        FormatLocationPipe,
        ProductFormChildTableComponent,
        ProductFormChildTableComponent,
        ProductFormSyncfusionTableComponent,
        FolderCommentsCardComponent,
        DiagExpertDecisionComponent,
        ExpertiseCustomerCallBackComponent,
        ScheduleExpertiseAtHomeComponent,
        DiagExpertDecisionConfirmationComponent,
        ValidateSupplierDelayComponent,
        WaitingOnAsyncProcessComponent,
        AlertFolderModalComponent,
        SwapConfirmationHomeRepairComponent,
        AnonymizeDialogComponent,
        ProductIdentificationComponent,
        ProductSearchComponent,
        BrandsComponent,
        SelectWorkOrderDialogComponent,
        ConnectFormToStoreDirective,
        AddCompanyFormDirective,
        DiagnosticInProgressComponent,
        ProductDetailComponent,
        ProductGeneralInfoComponent,
        ProductNomenclatureComponent,
        ProductDocumentsComponent,
        ProductWarrantyComponent,
        ProductSubElementWarrantyComponent,
        ProductRepairabilityComponent,
        ProductSparePartsComponent,
        SpinnerComponent,
        UnknownProductComponent,
        SupplierContactCardsComponent,
        SupplierContactCardComponent,
        InvoicesItemsListComponent,
        PreferredLanguagesComponent,
        ConfirmRepairerHomeRepairComponent
    ],
    imports: [
        RouterModule.forChild(FolderRoutes),
        TranslateModule,
        FuseSharedModule,
        Ng2TelInputModule,

        // WORKFLOW PROCESS
        WorkflowProcessModule,
        ProductRecallProcessModule,
        AutoRepairProcessModule,
        ServiceCenterRepairProcessModule,
        SwapProcessModule,
        TraitementProduitHsModule,
        VisioProcessModule,
        RepairPackageModule,
        MatCardModule,
        MatDividerModule,
        MatRadioModule,
        MatDialogModule,
        MatButtonModule,
        NgxDropzoneModule,
        MatProgressBarModule,
        MatIconModule,
        MatFormFieldModule,
        MatTableModule,
        MatPaginatorModule,
        DynamicComponentModule,
        SharedGenericModule,
        MdePopoverModule,
        MaterialModule,
        SatDatepickerModule,
        MatMomentDateModule,
        MatNativeDateModule,
        FilesSliderModule,
        AngularEditorModule,
        TabModule,
        GridModule,
        ChipListModule,
        DialogModule,
        DropDownListAllModule,
    ],
    providers: [
        PhoneNumberPipe,
        NgxImageCompressService,
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},

        PageService,
        SortService,
        FilterService,
        SearchService,
        ToolbarService,
        CommandColumnService,
        ColumnMenuService,
        ColumnChooserService,
        DetailRowService,
         GroupService,
        ResizeService
    ],
    exports: [
        DetailSupplierDialogComponent,
        WarrantyCardComponent
    ],
    entryComponents: [
        SingleUploadModalComponent,
        FolderExternalReferencesDialogComponent,
        DetailSupplierDialogComponent,
        SendToExternalSystemModalComponent,
        FolderCommentsModalComponent,
        FolderSiteModalComponent,

        DetailRepairDialogComponent,
        MdePopover
    ]
})
export class FolderModule {
}
