import {FuseNavigation} from '@fuse/types';
import {Context} from '../models/enums/context.enum';
import {environment} from '../../environments/environment';

export const navigation: FuseNavigation[] = [
    {
        id: 'nav_dashboard',
        title: 'dashboard',
        translate: 'NAV.DASHBOARD',
        type: 'group',
        context: [Context.PRODUCT_RECALL, Context.SAV, Context.REPARATION],
        icon: 'dashboard',
        url: '/dashboard',
        inRightPosition: false,
        scope: 'dashboard'
    },
    {
        id: 'nav_dashboard-v2',
        title: 'Dashboard V2',
        translate: 'NAV.DASHBOARD.V2',
        type: 'group',
        context: [Context.PRODUCT_RECALL, Context.SAV, Context.REPARATION],
        icon: 'dashboard',
        url: '/dashboard-v2',
        inRightPosition: false,
        scope: 'view.dashboard-v2'
    },
    {
        id: 'folders',
        title: 'Folders',
        translate: 'NAV.FOLDER.HEADER',
        context: [Context.PRODUCT_RECALL, Context.SAV, Context.REPARATION],
        type: 'group',
        icon: 'folder',
        url: '/folder/list',
        inRightPosition: false,
    },
    {
        id: 'PLANNING',
        title: 'Planning',
        context: [Context.REPARATION],
        translate: 'NAV.ITINERANT.PLANNING',
        type: 'group',
        icon: 'calendar_view_day',
        url: '/itinerant/dashboard',
        inRightPosition: false,
    },
    {
        id: 'config',
        title: 'Config',
        context: [Context.PRODUCT_RECALL, Context.SAV, Context.REPARATION, Context.DIAGNOSTIC],
        type: 'collapsable',
        icon: 'settings',
        inRightPosition: true,
        styleIcon: 's-24',
        oneOfScopes: ['configuration', 'spares', 'diagnostic', 'dashboard'],
        children: [
            {   id: 'configurations',
                title: 'configurations',
                translate: 'NAV.FOLDER.CONFIG',
                icon: 'perm_data_setting',
                type: 'item',
                externalUrl: true,
                openInNewTab: true,
                inRightPosition: true,
                url: environment.savBackOfficeConfigFrontend,
                scope: 'configuration'
            },
            {
                id: 'sparePart',
                title: 'sparePart',
                translate: 'NAV.SPARE.SPARE_PART',
                type: 'item',
                icon: 'shopping_cart',
                externalUrl: true,
                openInNewTab: true,
                inRightPosition: true,
                url:  environment.savBackOfficeSparePartFrontend,
                scope: 'spares'
            },
            {
                id: 'diagnostic',
                title: 'diagnostic',
                translate: 'NAV.DIAGNOSTIC',
                type: 'item',
                icon: 'question_answer',
                externalUrl: true,
                openInNewTab: true,
                inRightPosition: true,
                url:  environment.diagnosticFrontend,
                scope: 'diagnostic'
            },
            {
                id: 'dashboard',
                title: 'dashboard',
                translate: 'NAV.CONFIGURATIONS.DASHBOARD',
                type: 'collapsable',
                icon: 'assignment',
                inRightPosition: true,
                context: [Context.PRODUCT_RECALL, Context.SAV, Context.REPARATION, Context.DIAGNOSTIC],
                oneOfScopes: ['configuration.widget', 'configuration.dashboard', 'configuration.dashboard-v2'],
                children: [
                    {
                        id: 'widget',
                        inRightPosition: true,
                        title: 'widget',
                        translate: 'NAV.CONFIGURATIONS.WIDGET',
                        type: 'item',
                        openInNewTab: false,
                        icon: 'report_problem',
                        url: 'dashboard/widget',
                        scope: 'configuration.widget'
                    },
                    {
                        id: 'dashboard',
                        inRightPosition: true,
                        title: 'dashboard',
                        translate: 'NAV.CONFIGURATIONS.DASHBOARD',
                        type: 'item',
                        openInNewTab: false,
                        icon: 'filter_1',
                        url: 'dashboard/list',
                        scope: 'configuration.dashboard'
                    },
                    {
                        id: 'rule_dashboard-v2',
                        inRightPosition: true,
                        title: 'dashboard v2',
                        translate: 'NAV.CONFIGURATIONS.DASHBOARD.V2',
                        type: 'item',
                        openInNewTab: false,
                        icon: 'filter_1',
                        url: 'dashboard-v2/config',
                        scope: 'configuration.dashboard-v2'
                    }
                ]
            }]

    }
];
