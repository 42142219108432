import {Component, Input, OnInit} from '@angular/core';
import {Attachment, Notification} from '../../../../../../models/notification.model';
import {NotificationService} from '../../../../../../shared/services/notification.service';
import {NotificationType} from '../../../../../../models/enums/NotificationType.enum';
import {FileService} from '../../../../../../shared/services/file.service';
import {FilesUtils} from '../../../../../../shared/utils/files-utils';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';

@Component({
    selector: 'app-item-notification-call-info',
    templateUrl: './item-notification-call-info.html',
    styleUrls: ['./item-notification-call-info.component.scss']
})
export class ItemNotificationCallInfoComponent implements OnInit {
    @Input() notificationIndex: any;

    notification: Notification;
    isNotCall = false;
    attachments: Attachment[] = [];

    constructor(private notificationService: NotificationService,
                private fileService: FileService,
                private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.isNotCall = this.notificationIndex.type === NotificationType.MAIL || this.notificationIndex.type === NotificationType.SMS;
        this.getDetailNotification(this.notificationIndex.id);
    }

    getDetailNotification(id: string): void {
        this.notificationService.getNotification(id).then(res => {
            this.notification = res;
            if (this.isMailType()) {
                this.getAttachments();
            }
        });
    }

    isMailType(): boolean {
        return this.notificationIndex.type === NotificationType.MAIL;
    }

    getAttachments(): void {
        if (!!this.notification && this.notification.content && !!this.notification.content['attachments']) {
            this.attachments = this.notification.content['attachments'];
        }
    }

    downloadAttachment(attachmentId, attachmentName): void {
        this.store$.dispatch(new StartLoading());
        this.fileService.getAttachmentFile(attachmentId).subscribe(resFile => {
            this.store$.dispatch(new StopLoading());
            this.downloadFile(resFile, attachmentName);
        }, () => {
            this.store$.dispatch(new StopLoading());
        });
    }

    downloadFile(resFile: any, fileName: string): void {
        const reader = new FileReader();
        reader.readAsDataURL(resFile);
        reader.onloadend = () => {
            console.log(reader);
            FilesUtils.saveFile(reader.result, fileName, resFile.type);
        };
    }

    getCustomerContact(): string {
        if (!!this.notification && this.notification.content && this.notification.content['to']) {
            return (!!this.isMailType() ? this.getMails() : this.notification.content['to']);
        } else {
            return ('-');
        }
    }

    getMails(): string {
        const mails = [...this.notification.content['to'].values()].map(value => value.email);
        return mails.length > 1 ? mails.join(', ') : mails[0] || '';
    }

}
