import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GLOBAL} from '../../app-config';
import {Site, SiteSearchRequest, SiteWrapperRequest} from '../../models/site.model';
import {Observable} from 'rxjs';
import {Page} from '../../models/page.model';

export const CODE = 'code';
export const LABEL = 'label';

@Injectable({
    providedIn: 'root'
})
export class SiteService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api/integration`;

    constructor(private http: HttpClient) {

    }

    findNearestSites(siteWrapperRequest: SiteWrapperRequest): Observable<any> {
        return this.http.post<any>(`${this.path}/sites`, siteWrapperRequest);
    }

    findNearestSiteByCode(siteCode: string): Observable<Site> {
        return this.http.get<Site>(`${this.path}/site/${siteCode}`);
    }

    getAllSites(siteSearchRequest: SiteSearchRequest): Observable<Page<Site>> {
        const params = new HttpParams({fromObject: {...siteSearchRequest}});
        return this.http.get<Page<Site>>(`${this.path}/sites`, {params});
    }
}
