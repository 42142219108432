<div class="page-layout blank p-24" fusePerfectScrollbar>
    <!--    les mat-card au centre -->
    <div fxLayoutGap="20px" fxLayout.gt-md="row" fxLayout.lt-sm="column">

        <div fxFlex.gt-md="60"
             fxFlex.lt-sm="100"
             fxLayout="column"
             fxLayoutGap="30px"
             *ngIf="!isProductRecallContext()">
            <!-- Invoice Matcard -->
            <mat-card>
                <div fxLayout="row" fxLayoutAlign="end end" *ngIf="hasInvoiceHint$ | async">
                    <mat-hint id="invoice-info-modal">
                        <label
                            class="h4 cursor-pointer bt-12 link-underline"
                            (click)="showImageModal()">
                            {{'INFO.LINK.INVOICE-INFO' | translate}}
                        </label>
                    </mat-hint>
                </div>
                <mat-form-field class="filter w-100-p" floatLabel="never">
                    <input matInput [placeholder]="'FOLDER.CREATE.INVOICE.TITLE' | translate"
                           [(ngModel)]="searchForm.invoice" id="create_folder_invoice"
                           (keyup.enter)="searchWithInvoice(true)">
                    <mat-icon matSuffix id="create_folder_invoice_search" class="disabled-text cursor-pointer"
                              (click)="searchWithInvoice(true)">
                        search
                    </mat-icon>
                </mat-form-field>
                <mat-card-content class="text-center">
                    <app-invoice-form [isPurchaseDateRequired] ="isPurchaseDateRequired" [clearInvoice]="this.clearInvoice" [invoiceNotFound]="invoiceNotFound"></app-invoice-form>
                </mat-card-content>
            </mat-card>

            <!-- Product Matcard -->
            <mat-card>
                <ng-container *ngIf="!productSelected" >
                    <!-- Other existing code -->
                    <app-product-search
                        [user]="currentUser"
                        [invoiceNotFound]="invoiceNotFound"
                        [clearSearch$]="clearSearch$"
                        [isSearchProductPerLabelActive]="isSearchProductPerLabelActive"
                        [pageSize]="this.PAGE_SIZE"
                        (productNotFound)="sendProductNotFound($event)"
                        (showProductState)="setShowProductState($event)"
                        (resetProductState)="resetProductStateInChild()"
                    ></app-product-search>

                </ng-container>

                <mat-card-content fxLayout="row">
                    <app-product-form fxFlex [productNotFound]="productNotFound"
                                      [invoiceId]="invoice?.id"
                                      [purchaseDate]="computePurchaseDate()"
                                      (productListEmitter)="handleBackButton($event)"
                                      (showProductStateChange)="changeShowProductState($event)"
                                      [user]="currentUser"
                                      [pageSize]="this.PAGE_SIZE">
                    </app-product-form>


                    <div *ngIf="isProductSelected()" fxLayout="column" fxLayoutGap="10px" [style.margin-top]="'22px'"
                         fxFlex="27">
                        <fieldset *ngIf="subElements.length>0; else NO_SUB_ELEMENTS">
                            <legend class="h3 title-fieldset">{{'COMPONENT.SUB_ELEMENTS.TITLE'|translate}}</legend>
                            <app-sub-elements [subElements]="subElements"
                                              [invoice]="invoice"
                                              [product]="product"
                                              (subElementEmitter)="getSelectedSubElement($event)"></app-sub-elements>
                        </fieldset>
                        <ng-template #NO_SUB_ELEMENTS>
                            <fieldset>
                                <legend class="h3 title-fieldset">{{'COMPONENT.SUB_ELEMENTS.TITLE'|translate}}</legend>
                                {{'COMPONENT.SUB_ELEMENTS.UNAVAILABLE'|translate}}
                            </fieldset>
                        </ng-template>

                        <fieldset *ngIf="accessories.length>0; else NO_ACCESSORIES">
                            <legend class="h3 title-fieldset">{{'COMPONENT.ACCESSORIES.TITLE'|translate}}</legend>
                            <app-accessories [accessories]="accessories"
                                             (accessoriesEmitter)="getSelectedAccessories($event)"></app-accessories>
                        </fieldset>
                        <ng-template #NO_ACCESSORIES>
                            <fieldset>
                                <legend class="h3 title-fieldset">{{'COMPONENT.ACCESSORIES.TITLE'|translate}}</legend>
                                {{'COMPONENT.ACCESSORIES.UNAVAILABLE'|translate}}
                            </fieldset>
                        </ng-template>
                    </div>
                </mat-card-content>

                <app-product-state [(productState)]="productState" *ngIf="showProductState"></app-product-state>
                <div fxLayout="column" fxLayoutAlign="center stretch">
                    <ng-container *ngIf="product?.code">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'MODAL.COMMENT.TITLE_ADD_COMMENT'|translate}}</mat-label>
                            <textarea matInput inputControl [(ngModel)]="comment"></textarea>
                        </mat-form-field>
                    </ng-container>
                </div>
            </mat-card>

        </div>

        <!-- bloc product in product_recall-->
        <mat-card *ngIf="isProductRecallContext()"
                  fxFlex.gt-md="50"
                  fxFlex.lt-sm="100">
            <mat-card-content
                class="text-center">
                <app-product-form
                    [user]="currentUser"
                    [productNotFound]="productNotFound"></app-product-form>
            </mat-card-content>
        </mat-card>

        <!-- bloc customer -->
        <mat-card [fxFlex.gt-md]="isProductRecallContext() ? '50' : '40'"
                  fxFlex.lt-sm="100">
            <mat-card-content class="text-center">
                <app-customer></app-customer>
            </mat-card-content>
        </mat-card>
    </div>

    <div fxLayoutAlign="end center" fxLayoutGap="10px" class="mt-12">
        <a (click)="cleanAllForms()"
           class="cursor-pointer px-24 mr-8">
            {{'FOLDER.CREATE.BUTTON_CANCEL' | translate}}
        </a>
        <button type="button" mat-stroked-button color="accent" (click)="createFolder()"
                id="create_folder_submitButton" [disabled]="!isCreateFolderEnabled()"
                class="py-12 px-24 mb-8 mr-8 font-weight-900 font-size-30">
            {{'FOLDER.CREATE.BUTTON' | translate}}
        </button>
    </div>
</div>
