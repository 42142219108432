<div *ngIf="currentBusinessLink === 'STORE_BANNE'; else NOT_STORE_BANNE">
    <mat-card-content >

        <div fxLayout="column" fxLayoutAlign="start">

            <div fxLayout="column" fxLayoutAlign="start">
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>

            <label class="h4 mt-16 mb-8  ">{{'FOLDER.SCHEDULE.DESCRIPTION_SELECT_DAYS' | translate}}</label>
        </div>
        <div class=" mt-8 mb-8">
            <mat-radio-group aria-label="Select an option" (change)="selectOptionDate($event.value)">
                <mat-radio-button value="MORNING" checked>{{'FOLDER.WORKFLOW.MORNING' | translate}}</mat-radio-button>
                <mat-radio-button value="AFTERNOON"
                                  class="ml-8">{{'FOLDER.WORKFLOW.AFTERNOON' | translate}}</mat-radio-button>
                <mat-radio-button value="OTHER_DATE"
                                  class="ml-8">{{'FOLDER.WORKFLOW.OTHER_DATE' | translate}}</mat-radio-button>
            </mat-radio-group>
        </div>

        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="w-100-p">
            <div>
                <div fxLayout="column" fxLayout.gt-xs="row">
                    <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="100">
                        <mat-label>{{'FOLDER.SCHEDULE.DAY_OF_INTERVENTION' | translate}}</mat-label>
                        <input matInput [matDatepicker]="wishedDay" [min]="minDate" formControlName="wishedDay"
                               [matDatepickerFilter]="filterDate">
                        <mat-datepicker-toggle matSuffix [for]="wishedDay"></mat-datepicker-toggle>
                        <mat-datepicker #wishedDay [dateClass]="highlighter"></mat-datepicker>
                    </mat-form-field>
                </div>

                <div fxLayout="column" fxLayout.gt-xs="row">
                    <mat-form-field appearance="outline" class=" no-errors-spacer" fxFlex="50">
                        <mat-label>{{'FOLDER.SCHEDULE.BEGIN_HOUR_INTERVENTION' | translate}}</mat-label>
                        <input matInput type="time" formControlName="beginHourIntervention" [(ngModel)]="beginHour">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                        <mat-label>{{'FOLDER.SCHEDULE.END_HOUR_INTERVENTION' | translate}}</mat-label>
                        <input matInput type="time" formControlName="endHourIntervention" [(ngModel)]="endHour">
                    </mat-form-field>
                </div>
            </div>

            <app-comment-area [commentForm]="form.get('comment')"></app-comment-area>

            <div fxLayout="row" fxLayoutAlign="space-between end">
                <div>
                    <button mat-button matTooltip="{{'BUTTON.EDIT.INTERVENTION' | translate}}" type="button"
                            (click)="getPreviewTask()"
                            class="ster-btn_border">
                        <mat-icon>keyboard_return</mat-icon>
                        {{'BUTTON.EDIT.INTERVENTION' | translate}}
                    </button>
                </div>
                <div>
                    <button mat-stroked-button
                            matTooltip="{{'FOLDER.ADDRESS.SUBMIT_BUTTON' | translate}}" type="submit"
                            [disabled]="form.invalid"
                            [ngClass]="form.invalid?'aster_btn_disabled':'aster_btn'">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </div>
        </form>
    </mat-card-content>
</div>

<ng-template #NOT_STORE_BANNE>
        <div fxLayout="column" fxLayoutAlign="start">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
        </div>
        <mat-card-content>
            <app-calendar-rdv [nbrDays]="numberOfDays"
                              [listElementsDays]="listElementsDays"
                              [isStaticWeekData]=true
                              (elementSelected)="getResultElementSelected($event)"
                              [maxElementsSelected]=1
                              [daysChecked]="[]"
                              [descriptionCalender]="'GENERAL.CALENDAR.DESCRIPTION' | translate"
                              [listDaysIgnored]="listDaysHoliday"></app-calendar-rdv>

        </mat-card-content>

        <app-comment-area [commentForm]="commentControl"></app-comment-area>

        <div fxLayoutAlign="end end">
            <button type="submit" mat-stroked-button
                    (click)="validateAppointment()"
                    [ngClass]="selectedAppointment.length === 0?'aster_btn_disabled':'aster_btn'"
                    [disabled]="selectedAppointment.length === 0">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>
</ng-template>
