import {KeycloakConfig} from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
    url: 'https://login.develop.platana.fr/auth',
    realm: 'SAV-DIGITAL',
    clientId: 'SAV-PUBLIC-ACCESS-TYPE',
};

export const environment = {
    production: true,
    hmr       : false,
	keycloakConfig,
    savFrontOfficeFrontend: 'https://sav-front-office-frontend.develop.platana.fr',
    gatewayEndpoint: 'https://api.develop.platana.fr',
    pushNotificationWebSocketEndpoint: 'https://sav-websocket.develop.platana.fr/ws/push-notifications/',
    localizationApiKey: '1a9c4d10-36b4-4603-8a84-cfce8277f6f2',
    openstreetmap_routing_url : 'https://api.develop.platana.fr/localization/routing',
    matomoUrl: `https://matomo.develop.platana.fr/`,
    matomoUrlSiteID: 7,
    savBackOfficeConfigFrontend: 'https://sav-bo-config-frontend.develop.platana.fr',
    savBackOfficeSparePartFrontend: 'https://sav-bo-sparepart-frontend.develop.platana.fr',
    diagnosticFrontend: 'https://diagnostic.develop.platana.fr/',
    sparePartApiKey: '2e6b2b09-60df-4759-bae1-cb6d8811d3fd',
    operatingModeChangedDate: '2023-12-01T00:00:00',
    savBackOfficeFrontend: 'https://sav-back-office-frontend.develop.platana.fr',
    iframeAdeoDatadogURL: 'https://p.datadoghq.eu/sb/f20357de-2697-11ec-80a9-da7ad0900005-4213bd4088f5192f47392af648760954',
    iframeNorautoDatadogURL: 'https://p.datadoghq.eu/sb/f20357de-2697-11ec-80a9-da7ad0900005-375b6231c3768d4f1e0d79f07627d364',
    // TODO this may lead us to some security vulnerabilities, we should inject these kind of information at the server level
    growthBook: {
        apiHost: 'https://growthbook-api.platana.fr',
        clientKey: 'sdk-RqPhrPhOcoPvGfLh',
        devMode: true
    }


};
