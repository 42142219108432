import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AddressType} from '../../../../../models/enums/addressType.enum';
import {Folder} from '../../../../../models/folder.model';
import {Subject, Subscription} from 'rxjs';
import {Address} from '../../../../../models/customer/customer.model';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SharedService} from '../../../../../shared/services/shared.service';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-add-default-address',
    templateUrl: './add-default-address.component.html',
    styleUrls: ['./add-default-address.component.css']
})
export class AddDefaultAddressComponent implements OnInit, OnDestroy {
    protected _onDestroy = new Subject<void>();

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    customerAddress: Address;
    addresses: any;
    customerAddressForm: any = {
        form: null,
    };
    addressSubscription;
    addressOutput: Address;
    validateAddressSubscription: Subscription;
    validateFormAddress: boolean;


    constructor(private translateService: TranslateService,
                private sharedService: SharedService) {
    }

    ngOnInit(): void {
        this.validateAddressSubscription = this.sharedService.getValidateForm().subscribe(validate => {
            this.validateFormAddress = validate;
        });
        this.initCustomerDetailForm();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
        this.addressSubscription.unsubscribe();
        this.validateAddressSubscription.unsubscribe();
    }

    initCustomerDetailForm(): void {
        this.customerAddressForm.form = new FormGroup({
            countryCode: new FormControl(null, Validators.required),
        });
        this.addressSubscription = this.sharedService.getUpdateAddressEvent().subscribe(res => {
            if (!res) {
                this.customerAddressForm.form.get(['countryCode']).setErrors({'incorrect': true});
                return;
            }
            this.customerAddressForm.form.get(['countryCode']).setValue(res.countryCode);
            this.addressOutput = res;
        });
    }

    onSubmit(): void {
        if (this.customerAddressForm.form.invalid || !this.validateFormAddress) {
            return;
        }

        this.customerAddress = {
            countryName: this.translateService.instant('COUNTRY.NAME.' + this.addressOutput.countryCode),
            countryCode: this.addressOutput.countryCode,
            city: this.addressOutput.city,
            zipCode: this.addressOutput.zipCode,
            address: this.addressOutput.address,
            secondAddress: this.addressOutput.secondAddress,
            type: AddressType.DEFAULT
        };

        this.inputMap.emit({
            'customerDefaultAddress': JSON.stringify(this.customerAddress)
        });

    }

    reset(): void {
        this.customerAddress = null;
        this.sharedService.updateResetAddress(this.customerAddress);
    }

    isFormDisabled(): boolean {
        return this.customerAddressForm.form.invalid || !this.validateFormAddress;
    }
}
