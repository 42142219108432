<div fxLayout="row" fxLayoutAlign="start center" class="mt-12">
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
        <mat-radio-group class="ml-20" fxLayoutGap="10px"
                         labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch">
            <mat-radio-button class="full-width-radio"
                              *ngFor="let c of notRepairedProductRetrieval.placeholders"
                              (change)="selectionChanged($event)"
                              [value]="c.choice">
                <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                    <div fxFlex>
                        <div>{{ c.value | translate }}</div>
                    </div>
                </div>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>