    <mat-card-content>
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
        <div fxLayout="column" fxLayoutAlign="start stretch">
            <mat-form-field appearance="outline">
                <mat-label>{{'MODAL.COMMENT.TITLE_ADD_COMMENT'|translate}}</mat-label>
                <textarea matInput [(ngModel)]="agentComment"></textarea>
            </mat-form-field>
        </div>
        <div fxLayoutAlign="end center">
            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        (click)="completeTask()"
                        class="aster_btn">
                    {{'BUTTON.CLOSE_FOLDER' | translate}}
                </button>
            </div>
        </div>
    </mat-card-content>