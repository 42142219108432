import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
  selector: 'app-reparation-delay',
  templateUrl: './reparation-delay.component.html',
  styleUrls: ['./reparation-delay.component.scss']
})
export class ReparationDelayComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    form: FormGroup;
    buttons: any[];

    constructor() {
    }

    ngOnInit(): void {
        this.initForm();
        this.initButtons();
    }

    private initForm(): void {
        this.form = new FormGroup({
            status: new FormControl(null, Validators.required),
        });
    }

    private initButtons(): void {
        this.buttons = [
            {
                status: false,
                value: 'BUTTON.NO_OUT_OF_TIME', // response received from repairer
            },
            {
                status: true,
                value: 'BUTTON.IS_OUT_OF_TIME',
            }
        ];
    }

    onSubmit(): void {
        this.inputMap.emit({'reparationIsOutOfTime': this.form.value.status});
    }

    disabledButtonForm(): boolean {
        return this.form.invalid;
    }

}
