import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SurveyService} from '../../../shared/services/survey.service';
import {Ng2CarouselamosComponent} from 'ng2-carouselamos';
import {of, Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ShowImageModalComponent} from '../../../main/image-modal/show-image-modal.component';

@Component({
    selector: 'app-files-slider',
    templateUrl: './files-slider.component.html',
    styleUrls: ['./files-slider.component.scss']
})
export class FilesSliderComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy  {
    protected _onDestroy = new Subject<void>();

    @ViewChild('slider') slider: Ng2CarouselamosComponent;
    images: Array<any> = [];
    selectedItem: any;
    selectedIndex: number;

    @Input() filesId: string[] = [];
    @Input() sliderWidth: number;
    constructor(public surveyService: SurveyService, private modalController: MatDialog
        , private changeDetector: ChangeDetectorRef ) {
        this.images = [];
    }

    ngOnInit(): void {
        this.getAllFiles();
    }


    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    private getAllFiles(): void {
        if (!!this.filesId && this.filesId.length > 0) {
            this.filesId.forEach(fileID => {
                this.getFileById(fileID);
            });
        }
    }

    ngAfterViewChecked(): void {
        this.changeDetector.detectChanges();
    }
    ngAfterViewInit(): void {
        this.initializeSlider();
    }

    public getFileById(fileID): void {
        this.surveyService.getFile(fileID)
            .subscribe(resFile => {
                const reader = new FileReader();
                reader.readAsDataURL(resFile);
                reader.onloadend = () => {
                    this.images.push({type: reader.result.toString().split('/')[0], src: reader.result});
                };
            });
    }

    showDetailsFiles(selectedImage: any ): void {
        const dialogRef = this.modalController.open(ShowImageModalComponent, {
            maxHeight: '400px',
            maxWidth: '400px',
            data: of([selectedImage])
        });
        dialogRef.afterClosed().subscribe(
            data => {
                console.log('Dialog output:', data);
            }
        );
    }

    isImage(item): boolean {
        return item.type.toString().includes('image');
    }

    isVideo(item): boolean {
        return item.type.toString().includes('video');
    }
    isPdf(item): boolean {
        return item.type.toString().includes('application');
    }
    private initializeSlider(): void {
        if (!!this.filesId && !!this.sliderWidth && !!this.slider) {
            this.slider['width'] = (this.filesId.length > 1 ) ? this.sliderWidth : this.sliderWidth / 2;
        }
    }
}