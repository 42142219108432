import {Component, Input, OnInit} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CountriesUtils} from '../../../../../shared/data/countries-utils';
import {FolderService} from '../../../../../shared/services/folder.service';
import {Folder, IFolderUpdateRequest} from '../../../../../models/folder.model';
import {MobilePhoneInput} from '../../../../../models/customer/customer.model';
import {SharedService} from '../../../../../shared/services/shared.service';
import {FolderSubjectService} from '../../../folder-subject.service';
import {OperationModeEnum} from '../../../../../models/enums/operationMode.enum';
import {ProductType} from '../../../../../models/product.model';
import {AppState} from 'app/store/app.state';
import {select, Store} from '@ngrx/store';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {countryCode} from '../../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../../unsubscriber';
import {CIVILITY_OPTIONS} from "../../../../../models/customer/customer.constants";
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'introduce-campaign',
    templateUrl: './introduce-campaign.component.html',
    styleUrls: ['./introduce-campaign.component.scss']
})
export class IntroduceCampaignComponent extends Unsubscriber implements OnInit {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    mobilePhoneOutput: MobilePhoneInput;
    customerDetailForm: any = {
        form: null,
        countriesList: CountriesUtils.COUNTRIES_LIST,
        filteredCountriesList: new ReplaySubject<any[]>()
    };
    civilitySelectBoxOptions = CIVILITY_OPTIONS;
    addDetailsInProgress = false;

    constructor(private translateService: TranslateService,
                private folderService: FolderService,
                private http: HttpClient,
                private sharedService: SharedService,
                private folderSubjectService: FolderSubjectService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.initCustomerDetailForm();
    }

    initCustomerDetailForm(): void {
        this.customerDetailForm.form = new FormGroup({
            mobileNumber: new FormControl(!!this.folder.customer.contact ? this.folder.customer.contact.mobileNumber : null),
            email: new FormControl(!!this.folder.customer.contact ? this.folder.customer.contact.email : null, Validators.email),
            gender: new FormControl(!!this.folder.customer ? this.folder.customer.gender : null),
            firstName: new FormControl(!!this.folder.customer.firstName ? this.folder.customer.firstName : null),
            lastName: new FormControl(!!this.folder.customer.lastName ? this.folder.customer.lastName : null)
        });

        this.mobilePhoneOutput = new MobilePhoneInput();


        this.anotherSubscription = this.store$.pipe(select(countryCode)).subscribe(organizationLocal =>
            this.mobilePhoneOutput = {
                countryCode: organizationLocal,
                mobileNumber: !!this.folder.customer.contact ? this.folder.customer.contact.mobileNumber : null
            }
        );

        this.sharedService.updateMobilePhone(this.mobilePhoneOutput);

        this.anotherSubscription = this.sharedService.getUpdateMobilePhoneEvent().subscribe(res => {
            if (!res) {
                this.customerDetailForm.form.get(['mobileNumber']).setErrors({'incorrect': true});
                return;
            }
            this.customerDetailForm.form.get(['mobileNumber']).setValue(res.mobileNumber);
            this.mobilePhoneOutput = res;
        });
    }

    completeTask(): void {
        if (this.customerDetailForm.form.invalid || !this.folderHasModOp()) {
            return;
        }
        this.addDetailsInProgress = true;
        this.store$.dispatch(new StartLoading());
        this.folderService.startWorkflow(this.folder.id).subscribe((folder) => {
            if (this.isCustomerDetailsChanged()) {
                this.updateCustomerInfo();
            }
            this.folderSubjectService.refreshWorkflowStatusLoaded(folder);
            this.folderSubjectService.folderLoaded(folder);
            this.store$.dispatch(new StopLoading());
        }, () => this.store$.dispatch(new StopLoading()));
    }

    private updateCustomerInfo() {
        let folderUpdateRequest: IFolderUpdateRequest = {
            customer: this.folder.customer
        };
        folderUpdateRequest.customer.lastName = this.customerDetailForm.form.value.lastName;
        folderUpdateRequest.customer.gender = this.customerDetailForm.form.value.gender;
        folderUpdateRequest.customer.firstName = this.customerDetailForm.form.value.firstName;
        folderUpdateRequest.customer.contact.mobileNumber = this.customerDetailForm.form.value.mobileNumber;
        folderUpdateRequest.customer.contact.email = this.customerDetailForm.form.value.email;
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(data => {
            this.folderSubjectService.folderLoaded(data);
            this.store$.dispatch(new StopLoading());
        }).catch(() => {
            this.store$.dispatch(new StopLoading());
        });
    }

    private isCustomerDetailsChanged() {
        return this.customerDetailForm.form.value.gender !== this.folder.customer.gender ||
            this.customerDetailForm.form.value.lastName !== this.folder.customer.lastName ||
            this.customerDetailForm.form.value.firstName !== this.folder.customer.firstName ||
            (!!this.folder.customer.contact && (
                this.customerDetailForm.form.value.mobileNumber !== this.folder.customer.contact.mobileNumber ||
                this.customerDetailForm.form.value.email !== this.folder.customer.contact.email));
    }

    folderHasModOp(): boolean {
        if (this.isAlimentaryProduct()) {
            return true;
        } else {
            return this.folder.operationMode && (this.folder.operationMode !== OperationModeEnum.NO_MODOP || this.folder.businessLink !== 'TRIGANO');
        }
    }

    isAlimentaryProduct(): boolean {
        if (!!this.folder.product.productType) {
            return this.folder.product.productType === ProductType.ALIMENTARY;
        }
        return false;
    }
}
