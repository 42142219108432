<mat-form-field class="filter w-100-p" floatLabel="never">
    <mat-select [formControl]="productCtrl"
                [placeholder]="'FOLDER.CREATE.PRODUCT_LABEL.TITLE' | translate"
                (selectionChange)="selectProduct($event)">
        <mat-option>
            <ngx-mat-select-search [formControl]="productsFilteringCtrl"
                                   [searching]="searching"
                                   [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                   [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let product of filteredProducts | async" [value]="product">
            {{product.label}}
        </mat-option>
    </mat-select>
</mat-form-field>
