import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AppState} from '../../store/app.state';
import {Store} from '@ngrx/store';
import {isAuthenticated, isUserAllowed, userContextNotReady} from '../../store/user/user.selectors';
import {combineLatest, Observable} from 'rxjs';
import {map, skipWhile} from 'rxjs/operators';

@Injectable()
export class AppAuthGuard implements CanActivate {

    constructor(protected router: Router,
                private store$: Store<AppState>) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return combineLatest([
                this.store$.select(isUserAllowed, {
                    requiredRole: this.findFirstRequiredData(route, 'role'),
                    requiredContext: this.findFirstRequiredData(route, 'context'),
                    requiredScope: this.findFirstRequiredData(route, 'scope')
                }),
                this.store$.select(isAuthenticated),
                this.store$.select(userContextNotReady),
            ]
        ).pipe(
            skipWhile(([, isAuthenticated, userContextNotReady]) => !isAuthenticated || userContextNotReady),
            map(([isAllowed]) => {
                if (!isAllowed) {
                    this.router.navigate(['/error']);
                    return false;
                }
                return true;
            })
        );
    }

    private findFirstRequiredData(route: ActivatedRouteSnapshot, dataName: string): any {
        if (route.data && route.data[dataName]) {
            return route.data[dataName];
        }
        if (route.parent) {
            return this.findFirstRequiredData(route.parent, dataName);
        }
        return undefined;
    }
}
