
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
        <div>
            <button mat-stroked-button class="aster-btn_border" (click)="openTransformationModal()">
                <mat-icon class="mr-8">edit</mat-icon>
                {{"BUTTON.EDIT" | translate}}
            </button>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px">
        <table mat-table [dataSource]="quotationSummary" class="mat-elevation-z2 w-100-p mt-16 mb-16" fxFlex="70"
               fxFlex.lt-md="100">
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center"> {{'MODAL.QUOTATION.TYPE' | translate}}</th>
                <td mat-cell *matCellDef="let quotationLine"
                    class="text-center"> {{'MODAL.QUOTATION.TYPE.' + quotationLine.type | translate}}</td>
            </ng-container>
            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center"> {{'MODAL.QUOTATION.NUMBER' | translate}}</th>
                <td mat-cell *matCellDef="let quotationLine" class="text-center"> {{quotationLine.number}} </td>
            </ng-container>
            <ng-container matColumnDef="totalPriceHT">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center"> {{'QUOTATION.CONTENT.TOTAL_HT' | translate}}</th>
                <td mat-cell *matCellDef="let quotationLine"
                    class="text-left"> {{quotationLine.totalHT |currency:currency :'symbol-narrow' | space}} </td>
            </ng-container>
            <ng-container matColumnDef="totalPrice">
                <th mat-header-cell *matHeaderCellDef
                    class="text-center"> {{'MODAL.QUOTATION.TOTAL_TTC' | translate}}</th>
                <td mat-cell *matCellDef="let quotationLine"
                    class="text-center"> {{quotationLine.totalTTC |currency:currency :'symbol-narrow' | space}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div fxFlex="30" fxFlex.lt-md="100">
            <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex.lt-md="100">
                <div fxLayout="column" class="zone-total">
                    <div fxLayoutAlign="space-between center" fxLayoutGap="8px">
                        <strong>{{'QUOTATION.CONTENT.TOTAL_HT' | translate}} : </strong>
                        <div>{{ quotation?.totalPriceHTAfterDiscount?.value  |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center" fxLayoutGap="8px">
                        <strong> {{'QUOTATION.CONTENT.TOTAL_TTC' | translate}} : </strong>
                        <div>{{ quotation?.totalPrice?.value |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                </div>

            </mat-card>
        </div>
    </div>

    <app-attachment-uploader  class="mb-24"
                              [folder]="folder"
                              [quotation]="quotation"
                              (attachmentsFolder)="getAttachments($event)"></app-attachment-uploader>


    <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
        <ng-container *ngIf="quotation?.totalPrice?.value < 0">
            <p class="red-color">{{'FOLDER.REPAIR_IN_PROGRESS.NEGATIVE_QUOTATION' | translate}}</p>
        </ng-container>
        <button type="submit" mat-stroked-button
                (click)="completeTask()"
                [ngClass]="stopPropagation()?'aster_btn_disabled':'aster_btn'"
                [disabled]="stopPropagation()">
            {{'BUTTON.VALIDATE' | translate}}
        </button>
    </div>