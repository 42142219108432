import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Unsubscriber} from '../../../unsubscriber';
import {FolderPublic} from '../../../models/folder.public.model';
import {PublicService} from '../../../shared/services/public.service';
import {AppState} from '../../../store/app.state';
import {select, Store} from '@ngrx/store';
import {currentLanguage} from '../../../store/organization/organization.selectors';
@Component({
    selector: 'app-public-detail-tabs-card',
    templateUrl: './public-detail-tabs-card.component.html',
    styleUrls: ['./public-detail-tabs-card.component.scss']
})
export class PublicDetailTabsCardComponent extends Unsubscriber implements OnInit {
    protected _onDestroy = new Subject<void>();

    @Input() folder: FolderPublic;
    activeSection: string;

    constructor(
        private publicService: PublicService,
        private store$: Store<AppState>
               ) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(currentLanguage))
            .subscribe(language => {
                this.getFolder(this.folder.externalUid, language);
            });
    }
    getFolder(externalUid: string, locale: string): void {
        this.publicService.getFolderByUid(externalUid, false, locale).subscribe(res => {
            this.folder = res;
        });
    }
    updateSection(section: string): void {
        this.activeSection = section;
    }

    hasPublicQuotations(): boolean {
        return this.folder?.publicQuotations?.length > 0;
    }
}
