import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-product-quantity-check',
    templateUrl: './product-quantity-check.component.html',
    styleUrls: ['./product-quantity-check.component.css']
})
export class ProductQuantityCheckComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    folderId: string;

    quantityForm: FormGroup;

    constructor() {
    }

    ngOnInit(): void {
        this.quantityForm = new FormGroup({
            quantity: new FormControl(null, [Validators.required, Validators.min(1)])
        });
    }

    onSubmit(): void {
        this.inputMap.emit({
            'productQuantity': this.quantityForm.value.quantity
        });
    }
}
