import {NgModule} from '@angular/core';
import {WaitingSupplierConfirmationComponent} from './waiting-supplier-confirmation/waiting-supplier-confirmation.component';
import {FormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {FuseModule} from '../../../../../@fuse/fuse.module';
import {fuseConfig} from '../../../../fuse-config';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '../../../../../@fuse/components';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../../../../app.module';
import {HttpClient} from '@angular/common/http';
import {SharedGenericModule} from '../../../../shared/generic/shared-generic.module';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {WaitingForHsProductDestructionComponent} from './waiting-for-hs-product-destruction/waiting-for-hs-product-destruction.component';
import {WaitingCreditSupplierComponent} from './waiting-credit-supplier/waiting-credit-supplier.component';
import { RefundRefusalValidationComponent } from './refund-refusal-validation/refund-refusal-validation.component';
import {WorkflowProcessModule} from '../workflowProcess/workflow-process.module';
import { ManualCreditAgreementRequestComponent } from './manual-credit-agreement-request/manual-credit-agreement-request.component';


@NgModule({
    entryComponents: [
        WaitingSupplierConfirmationComponent,
        WaitingForHsProductDestructionComponent,
        WaitingCreditSupplierComponent,
    ],
    declarations: [
        WaitingSupplierConfirmationComponent,
        WaitingForHsProductDestructionComponent,
        WaitingCreditSupplierComponent,
        RefundRefusalValidationComponent,
        ManualCreditAgreementRequestComponent,
    ],
    imports: [FormsModule,
        // Material moment date module
        MatMomentDateModule,
        // Material
        Ng2TelInputModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedGenericModule,
        MatCardModule, MatIconModule, MatTableModule, NgxDropzoneModule, WorkflowProcessModule,
    ],
    exports: [
        WaitingSupplierConfirmationComponent,
        WaitingForHsProductDestructionComponent,
        WaitingCreditSupplierComponent,
    ]
})
export class TraitementProduitHsModule {
}
