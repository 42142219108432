import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-folder-error',
    templateUrl: './folder-error.component.html',
    styleUrls: ['./folder-error.component.scss']
})
export class FolderErrorComponent implements OnInit {

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    gotToFolderList(): void {
        this.router.navigate(['folder', 'list']);
    }
}
