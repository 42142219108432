import {Component, Input, EventEmitter, OnInit, Output} from '@angular/core';
import {DatePipe} from '@angular/common';
import {NgxImageCompressService} from 'ngx-image-compress';
import {TaskVariables} from '../../task.variables';
import {Folder} from '../../../../../models/folder.model';

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss'],
    providers: [NgxImageCompressService, DatePipe]
})
export class SurveyComponent implements OnInit {
    symptomSelected: any;
    surveyResponseId: string;
    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() variablesTask: any;

    getInputMap(inputMapEvent: any): void {
        this.inputMap.emit(inputMapEvent);
    }
    ngOnInit(): void {
        this.symptomSelected = JSON.parse(this.variablesTask[TaskVariables.symptomSelected]);
        this.surveyResponseId = JSON.parse(this.variablesTask[TaskVariables.surveyResponseId]);
    }
}
