import {Injectable} from '@angular/core';
import * as introJs from 'intro.js/intro';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class IntroJsService {

    introJS = introJs();

    constructor(private translateService: TranslateService) {
    }

    showGuideFolderList(): void {
        this.introJS.setOptions({
            steps: [
                {
                    element: '#zoneSearch',
                    intro: this.translateService.instant('INTRO.FOLDER_LIST.ZONE_SEARCH'),
                    position: 'bottom'
                }, {
                    element: '#searchValue',
                    intro: this.translateService.instant('INTRO.FOLDER_LIST.INPUT_SEARCH'),
                    position: 'bottom'
                }, {
                    element: '#warranty',
                    intro: this.translateService.instant('INTRO.FOLDER_LIST.CHOICE_WARRANTY'),
                    position: 'right'
                },
                 {
                    element: '#warrantyReason',
                    intro: this.translateService.instant('INTRO.FOLDER_LIST.CHOICE_WARRANTY_REASON'),
                    position: 'right'
                },
                {
                    element: '#operatingMode',
                    intro: this.translateService.instant('INTRO.FOLDER_LIST.CHOICE_MOD_OP'),
                    position: 'left'
                },
                {
                    element: '#choiceStatusFolder',
                    intro: this.translateService.instant('INTRO.FOLDER_LIST.CHOICE_CLOSE_OPEN_FOLDER'),
                    position: 'right'
                },
                {
                    element: '#advancedSearch',
                    intro: this.translateService.instant('INTRO.FOLDER_LIST.ADVANCED_SEARCH'),
                    position: 'right'
                },
                {
                    element: '#searchButton',
                    intro: this.translateService.instant('INTRO.FOLDER_LIST.VALIDATE_FILTER'),
                    position: 'right'
                }, {
                    element: '#resetButton',
                    intro: this.translateService.instant('INTRO.FOLDER_LIST.RESET_FILTER'),
                    position: 'right'
                }, {
                    element: '#visibilityColumns',
                    intro: this.translateService.instant('INTRO.FOLDER_LIST.DYNAMIC_COLUMNS'),
                    position: 'right'
                }, {
                    element: '#rowTable',
                    intro: this.translateService.instant('INTRO.FOLDER_LIST.FOLDER_DETAILS'),
                    position: 'right'
                }
            ],
            showProgress: true
        }).start().onafterchange(value => {
            document.querySelector('.introjs-skipbutton').innerHTML = this.translateService.instant('BUTTON.CANCEL');
        });
        document.querySelector('.introjs-nextbutton').innerHTML = this.translateService.instant('BUTTON.NEXT');
        document.querySelector('.introjs-prevbutton').innerHTML = this.translateService.instant('BUTTON.BACK');
        document.querySelector('.introjs-skipbutton').innerHTML = this.translateService.instant('BUTTON.CANCEL');
    }

}
