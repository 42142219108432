import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {TranslateModule} from '@ngx-translate/core';

import {FuseNavigationComponent} from './navigation.component';
import {FuseNavHorizontalItemComponent} from './horizontal/item/item.component';
import {FuseNavHorizontalCollapsableComponent} from './horizontal/collapsable/collapsable.component';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FuseNavVerticalCollapsableComponent} from './vertical/collapsable/collapsable.component';
import {FuseNavVerticalItemComponent} from './vertical/item/item.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatDividerModule,

        MatIconModule,
        MatRippleModule,

        FlexLayoutModule,
        TranslateModule.forChild(),
        MatButtonModule
    ],
    exports     : [
        FuseNavigationComponent
    ],
    declarations: [
        FuseNavigationComponent,
        FuseNavHorizontalItemComponent,
        FuseNavHorizontalCollapsableComponent,
        FuseNavVerticalItemComponent,
        FuseNavVerticalCollapsableComponent
    ]
})
export class FuseNavigationModule
{
}
