import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {allConfiguration} from '../../../../store/configuration/configuration.selectors';
import {Unsubscriber} from '../../../../unsubscriber';
import {AppState} from '../../../../store/app.state';
import {UnknownProduct} from './unknown.product';
import {ProductFamily} from '../../../../models/productFamily.model';
import {ProductFamilyType} from '../../../../models/enums/productFamilyType.enum';
import {ConfigurationReferential} from '../../../../models/configurationReferential.model';
import {debounceTime, distinctUntilChanged, filter, map, mergeMap, startWith, switchMap, tap} from 'rxjs/operators';
import {NomenclatureFeatureData} from '../../../../shared/features/app-features';
import {ConfigCodeMapsEnum} from '../../../../shared/services/configuration-item-enum';
import {EMPTY, Observable, of} from 'rxjs';
import {Supplier} from '../../../../models/supplier.model';
import {MatOptionSelectionChange} from '@angular/material/core';
import {currentUser} from '../../../../store/user/user.selectors';
import {UserState} from '../../../../store/user/user.state';
import {SupplierService} from '../../../../shared/services/supplier.service';
import {SUPPLIER_CODE, SUPPLIER_LABEL} from '../../../../shared/generic/product-preview-card-all-details/product-supplier-field/product-supplier-field.component';
import {MapService} from '../../../../shared/utils/map-service';
import {Constants} from '../../../../Constants';
import {ClaimSensibleHiddenField} from '../../../../models/claim-sensible/claim-sensible-hidden-field.model';

@Component({
    selector: 'app-unknown-product',
    templateUrl: './unknown-product.component.html',
    styleUrls: ['./unknown-product.component.scss']
})
export class UnknownProductComponent extends Unsubscriber implements OnInit {


    readonly LABEL_CONTROL = 'label';
    readonly MODEL_CONTROL = 'model';
    readonly BRAND_CONTROL = 'brand';
    readonly SUPPLIER_CONTROL = 'supplier';


    familiesValuesMap: Map<string, ConfigurationReferential[]> = new Map();
    filteredfamiliesValuesMap: Map<string, ConfigurationReferential[]> = new Map();

    unknownProductForm: FormGroup;

    rows: any[][];
    supplierCtrl: FormControl;
    filteredSuppliers: Observable<Supplier[]>;
    suppliers: Supplier[] = [];
    currentUser: UserState;
    initialSupplier: Supplier;
    editMode = true;
    claimSensibleHiddenField = ClaimSensibleHiddenField;


    constructor(private supplierService: SupplierService,
                private formBuilder: FormBuilder,
                private dialog: MatDialogRef<UnknownProductComponent>,
                private store$: Store<AppState>,
                private mapService: MapService,
                @Inject(MAT_DIALOG_DATA) public data: { nomenclatureFields: NomenclatureFeatureData[] }) {
        super();
    }

    ngOnInit(): void {
        this.initSuppliersAutocomplete();
        this.getSuppliers();
        this.initialSupplier = new Supplier();
        this.orderFields();
        this.createFormGroup();
        this.prepareMap();
        this.prepareFieldsRelations();
    }
    private getSuppliers(): void {
        this.anotherSubscription = this.store$.pipe(
            select(currentUser),
            tap(user => {
                this.currentUser = user;
            })
        ).subscribe(suppliers => {
            this.handleSuppliersAutocompleteSearch(this.currentUser.organizationCode, this.currentUser.context);
        });
    }
    switchEditModeSupplier(): void {
        this.editMode = !this.editMode;
    }
    private initSuppliersAutocomplete(): void {
        this.filteredSuppliers = new Observable<Supplier[]>();
        this.supplierCtrl = new FormControl(null, this.supplierValidator());
    }
    onSelectSupplier($event: MatOptionSelectionChange): void {
        this.initialSupplier = this.suppliers.find(x => x.code === $event.source.value);
    }
    supplierValidator(): ValidatorFn {
        return (control: FormControl) => {
            return this.suppliers.find(x => x.code === control.value) ? null : { invalidOption: true };
        };
    }
    private handleSuppliersAutocompleteSearch(organizationCode: string, context: string): void {
        this.filteredSuppliers = this.supplierCtrl.valueChanges
            .pipe(
                startWith(''),
                debounceTime(500),
                distinctUntilChanged(),
                switchMap(searchValue => this.filterSuppliers(searchValue || '', organizationCode, context))
            );
    }
    filterSuppliers(searchValue: string, organizationCode: string, context: string): Observable<Supplier[]> {
        const filterValue = searchValue.toLowerCase();
        const filters: Map<string, any> = new Map<string, any>();
        if (!!filterValue) {
            filters.set(SUPPLIER_CODE, filterValue);
            filters.set(SUPPLIER_LABEL, filterValue);
        }
        return this.supplierService.search(0, 30, organizationCode, context, this.mapService.mapToObj(filters))
            .pipe(
                map(page => {
                    this.suppliers = page.content;
                    return page.content;
                })
            );
    }
    cancelEditSupplier(): void {
        this.switchEditModeSupplier();
    }

    displaySupplierFn = (supplierCode: string) => {
        const supplier = this.suppliers.find(x => x.code === supplierCode);
        return supplier ? supplier.label : '';
    }
    private prepareMap() {
        const code = this.data.nomenclatureFields[0].code;
        this.anotherSubscription = this.store$.pipe(select(allConfiguration,
            {configurationItemCode: this.getProductConfigCodeFromString(code)}))
            .subscribe(it => {
                this.familiesValuesMap.set(code, it);
                this.filteredfamiliesValuesMap.set(code, it);
                this.unknownProductForm.controls[code]
                    .setValidators(
                        [this.unknownProductForm.controls[code].validator, this.isInListValidator(this.familiesValuesMap.get(code))]
                    );
            });
    }

    private prepareFieldsRelations() {
        const validFieldCodes = this.data.nomenclatureFields
            .map(field => field.code);

        for (let i = 0; i <= validFieldCodes.length - 1; i++) {
            const currentField = validFieldCodes[i];
            const nextField = validFieldCodes[i + 1];
            this.anotherSubscription = this.unknownProductForm.controls[currentField].valueChanges.pipe(
                debounceTime(100),
                distinctUntilChanged(),
                switchMap(data  => {
                    const currentCode = (data && data.code) ? data.code.toLowerCase() : data.toLowerCase();
                    const currentLabel = (data && data.label) ? data.label.toLowerCase() : data.toLowerCase();
                    this.filteredfamiliesValuesMap
                        .set(currentField, this.familiesValuesMap.get(currentField).filter(family => {
                                return family.code.toLowerCase().includes(currentCode) ||
                                    family.label.toLowerCase().includes(currentLabel);
                            }
                        ));
                        if (nextField) {
                            return this.store$.pipe(
                                select(allConfiguration, {configurationItemCode: this.getProductConfigCodeFromString(nextField)}),
                                map(it => data && currentCode.trim() ? it.filter(item => item.code.startsWith(currentCode)) : it)
                            );
                        }
                        return of(EMPTY);
                    }
                )
            ).subscribe(filteredSubFamilies => {
                if (nextField) {
                    this.familiesValuesMap.set(nextField, filteredSubFamilies);
                    this.filteredfamiliesValuesMap.set(nextField, filteredSubFamilies);
                    this.unknownProductForm.controls[nextField].patchValue('');
                }

            });
        }
    }


    onSubmit() {
        this.dialog.close(this.mapFormToUnknownProduct());
    }

    private getProductFamilyTypeFromString(code: string): ProductFamilyType | null {
        switch (code) {
            case 'FAMILY_1':
                return ProductFamilyType.FAMILY;
            case 'FAMILY_2':
                return ProductFamilyType.SUB_FAMILY;
            case 'FAMILY_3':
                return ProductFamilyType.TYPE;
            case 'FAMILY_4':
                return ProductFamilyType.SUB_TYPE;
            case 'FAMILY_5':
                return ProductFamilyType.SEGMENT;
            default:
                return null;
        }
    }

    private getProductConfigCodeFromString(code: string): ConfigCodeMapsEnum | null {
        switch (code) {
            case 'FAMILY_1':
                return ConfigCodeMapsEnum.FAMILY;
            case 'FAMILY_2':
                return ConfigCodeMapsEnum.SUB_FAMILY;
            case 'FAMILY_3':
                return ConfigCodeMapsEnum.TYPE;
            case 'FAMILY_4':
                return ConfigCodeMapsEnum.SUB_TYPE;
            case 'FAMILY_5':
                return ConfigCodeMapsEnum.SEGMENT;
            default:
                return null;
        }
    }

    private mapFormToUnknownProduct(): UnknownProduct {
        const unknownProduct: UnknownProduct = new UnknownProduct();
        for (const nomenclatureField of this.data.nomenclatureFields) {
            const productFamilyType = this.getProductFamilyTypeFromString(nomenclatureField.code);
            const familyFromMap = this.findInFamilyByCode(
                this.unknownProductForm.value[nomenclatureField.code],
                this.familiesValuesMap.get(nomenclatureField.code)
            );
            unknownProduct.families.push(
                this.mapConfigToProductFamily(familyFromMap, productFamilyType)
            );
        }
        unknownProduct.model = this.unknownProductForm.value.model;
        unknownProduct.brand = this.unknownProductForm.value.brand;
        unknownProduct.label = this.unknownProductForm.value.label;
        unknownProduct.supplier = this.initialSupplier;

        return unknownProduct;
    }

    private findInFamilyByCode(family: ConfigurationReferential, families: ConfigurationReferential[]): ConfigurationReferential {
        return family ? families.find(item => item.code === family.code) || null : null;
    }

    private mapConfigToProductFamily(configValue: ConfigurationReferential, productFamilyType: ProductFamilyType): ProductFamily {
        if (configValue) {
            const productFamily: ProductFamily = new ProductFamily();
            productFamily.id = configValue.id;
            productFamily.code = configValue.code;
            productFamily.type = productFamilyType;
            productFamily.label = configValue.label;
            return productFamily;
        }
        return null;

    }


    private createFormGroup() {
        this.unknownProductForm = this.formBuilder.group({});
        this.unknownProductForm.addControl(this.LABEL_CONTROL, new FormControl('', [Validators.required, this.noWhitespaceValidator]));
        this.unknownProductForm.addControl(this.MODEL_CONTROL, new FormControl(''));
        this.unknownProductForm.addControl(this.BRAND_CONTROL, new FormControl(''));
        this.unknownProductForm.addControl(this.SUPPLIER_CONTROL, this.supplierCtrl);

        for (const nomenclatureField of this.data.nomenclatureFields) {
            const controlValidators = [];
            if (nomenclatureField.required) {
                controlValidators.push(Validators.required);
            }
            this.unknownProductForm.addControl(nomenclatureField.code, new FormControl(null, controlValidators));
        }
    }
    noWhitespaceValidator(control: FormControl): ValidationErrors | null {
        const isWhitespace = (control.value || '').trim().length === 0;
        return isWhitespace ? { whitespace: true } : null;
    }
    public extractNumber(code: string) {
        const match = code.match(/\d+$/);
        // tslint:disable-next-line:radix
        return match ? parseInt(match[0]) : null;
    }

    private orderFields() {
        this.data.nomenclatureFields
            .sort((a, b) => this.extractNumber(a.code) - this.extractNumber(b.code));
        this.getRows();
    }

    private getRows() {
        const fields = this.data.nomenclatureFields;
        this.rows = [];
        for (let i = 0; i < fields.length; i += 2) {
            this.rows.push(fields.slice(i, i + 2));
        }
    }

    private isInListValidator(allowedValues: ConfigurationReferential[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value?.code;
            if (value && allowedValues.some(item => item.code === value)) {
                return null;
            } else {
                return {isInList: true, allowedValues: allowedValues};
            }
        };
    }

    public valueMapper(family: ConfigurationReferential){
        return family ? family.label : '';
    }

}
