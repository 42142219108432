<div class="e-card">
    <div class="e-card-header">
        <div class="e-card-header-caption">
            <div class="e-card-title">{{'PRODUCT.PREVIEW_CARD.LABELS.NOMENCLATURE' | translate}}</div>
        </div>
    </div>
    <div class="e-card-content" fxLayout="column" fxLayoutGap="10px">
        <ng-container *ngFor="let family of familiesType">
            <card-element [title]="'PRODUCT.' + family"
                          [value]="findFamily(family) | codeToLabel: findProductConfig(family) | async"
            ></card-element>
        </ng-container>
    </div>
</div>