<mat-card class="w-100-p h-100-p transparent-mat-card">
    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <label class="h3  text-bold">{{widgetData.name |translate}}</label>
            <ng-container *ngIf="!!columnCount">
                <mat-form-field appearance="outline" [style.width.px]=60>
                    <input matInput
                           type="number"
                           numberOnly
                           min="1"
                           [(ngModel)]="columnCount"
                           oninput="validity.valid||(value='');"
                           matTooltip="{{'DASHBOARD.WIDGET.FORM.WIDGET_COLUMN' | translate }}"
                           (blur)="update()">
                </mat-form-field>
                <button matSuffix mat-icon-button color="warn" type="button"
                        matTooltip="{{'DASHBOARD.WIDGET.DELETE'|translate}}"
                        (click)="deleteWidget()">
                    <mat-icon>delete_forever</mat-icon>
                </button>
                <button matSuffix mat-icon-button color="primary" type="button"
                        matTooltip="{{'DASHBOARD.WIDGET.UPDATE'|translate}}"
                        (click)="editWidget()">
                    <mat-icon>edit</mat-icon>
                </button>
            </ng-container>
        </div>
        <div class="p-16 pb-0" fxLayout="row wrap" fxLayoutAlign="start end">
            <div class="pr-16">
                    <div class="font-size-32 font-weight-300 line-height-1 mt-8" (click)="showDetails()">
                        {{totalPrice }}
                    </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>