    <div>
        <h2>
            <mat-icon class="mt-20">history</mat-icon>
            {{'FOLDER.STATUS.HISTORY'| translate}}
        </h2>
    </div>
        <div class="mat-elevation-z4 w-100-p">
            <table mat-table [dataSource]="dataSourceStatusHistory" matSort fxFill>
                <ng-container matColumnDef="taskName">
                    <th mat-header-cell *matHeaderCellDef class="table-header">{{'FOLDER.STATUS.HISTORY' | translate}}</th>
                    <td mat-cell
                        *matCellDef="let element">{{!!((findUserTask(element.eventName) | async).label) ? (findUserTask(element.eventName) | async).label : 'COMPONENT.' + element.eventName | translate}}</td>
                </ng-container>
                <ng-container matColumnDef="startTime">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        (click)="updateSort()"
                        class="table-header">{{'FOLDER.STATUS.DATE' | translate}}</th>
                    <td mat-cell *matCellDef="let element" class="text-center">{{element.userAction.actionDate | date: ('GENERAL.DATE_TIME_FORMAT' | translate) : (currentLanguage$ | async)}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 20, 30]" showFirstLastButtons></mat-paginator>


        </div>

