import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-customer-decision',
    templateUrl: './waiting-customer-decision-out-of-warranty.component.html',
    styleUrls: ['./waiting-customer-decision-out-of-warranty.component.scss']
})
export class WaitingCustomerDecisionOutOfWarrantyComponent implements OnInit{

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    form: FormGroup;
    placeholders: any [];
    constructor() {
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            choice: new FormControl(null, Validators.required),
        });

        this.placeholders = [
            {
                choice: 'RETRIEVE_PRODUCT',
                value: 'CUSTOMER_DECISION.RETRIEVE_PRODUCT',
            },
            {
                choice: 'DESTROY_PRODUCT',
                value: 'CUSTOMER_DECISION.DESTROY_PRODUCT',
            },
            {
                choice: 'PRODUCT_TO_REPAIR',
                value: 'CUSTOMER_DECISION.TO_REPAIR',
            }
        ];
    }

    onSubmit(): void {
        if (this.form.invalid) {
            return;
        }
        this.inputMap.emit({
            customerDecision: this.form.value.choice
        });
    }

}
