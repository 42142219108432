export enum CommentType {
    COMMENT_CHOOSE_APPOINTMENT = 'COMMENT_CHOOSE_APPOINTMENT',
    COMMENT_CANCEL_INTERVENTION = 'COMMENT_CANCEL_INTERVENTION',
    COMMENT_PANNE_CONFIRMED = 'COMMENT_PANNE_CONFIRMED',
    COMMENT_GENERAL_AGENT = 'COMMENT_GENERAL_AGENT',
    COMMENT_GENERAL_REPAIRER = 'COMMENT_GENERAL_REPAIRER',
    COMMENT_GENERAL_SUPPLIER = 'COMMENT_GENERAL_SUPPLIER',
    COMMENT_WARRANTY_DISCLAIMER = 'COMMENT_WARRANTY_DISCLAIMER',
    COMMENT_NOT_REPAIRABLE = 'COMMENT_NOT_REPAIRABLE',
    COMMENT_REPAIRABLE = 'COMMENT_REPAIRABLE',
    COMMENT_DIAG_EXPERT = 'COMMENT_DIAG_EXPERT',
    COMMENT_REPORT_AGENT = 'COMMENT_REPORT_AGENT', //  COMPTE RENDU,
    AGENT_COMMENT_CUSTOMER_CHOICE_MODOP = 'AGENT_COMMENT_CUSTOMER_CHOICE_MODOP',
    CLAIM_CREATION_COMMENT = 'CLAIM_CREATION_COMMENT',
    COMMENT_NO_BREAKDOWN = 'COMMENT_NO_BREAKDOWN',
    REASON_OF_REFUS_REPARATION = 'REASON_OF_REFUS_REPARATION',
    REASON_OF_REFUS_REPARATION_SUPPLIER = 'REASON_OF_REFUS_REPARATION_SUPPLIER',
    REASON_OF_REFUS_REPARATION_REPAIRER = 'REASON_OF_REFUS_REPARATION_REPAIRER',

}
