import {Component, Inject, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {CountriesUtils} from '../../../../../shared/data/countries-utils';
import libphonenumber from 'google-libphonenumber';
import {PhoneNumberPipe} from '../../../../../../@fuse/pipes/phone-number.pipe';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import PhoneNumberFormat = libphonenumber.PhoneNumberFormat;
import {TranslateService} from '@ngx-translate/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

@Component({
    selector: 'detail-repair-dialog',
    templateUrl: './detail-repair-dialog.component.html',
    styleUrls: ['./detail-repair-dialog.component.scss']
})
export class DetailRepairDialogComponent implements OnInit  {


    formattedPhoneNumber;
    displayedColumns: string[] = ['position', 'name', 'weight'];
    dataSource = new MatTableDataSource<any>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    formattedMobileNumber;

    constructor(private dialogRef: MatDialogRef<DetailRepairDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private phoneNumberPipe: PhoneNumberPipe,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        if (!!this.data && !!this.data.repairer) {
            this.initializePhoneNumber();
        }
    }

    private initializePhoneNumber(): void {
        if (!!this.data.repairer.contact && !!this.data.repairer.completeAddress) {
            this.formattedPhoneNumber = this.phoneNumberPipe.getFormattedPhone(this.data.repairer.contact.phoneNumber, this.data.repairer.completeAddress.countryCode
                , PhoneNumberFormat.E164);
            this.formattedMobileNumber = this.phoneNumberPipe.getFormattedPhone(this.data.repairer.contact.mobileNumber, this.data.repairer.completeAddress.countryCode
                , PhoneNumberFormat.E164);
        }
    }

    isValidCountryCode(countryCode: string): boolean {
        return CountriesUtils.isValidCountryCode(countryCode);
    }

    getCountryCodeByPhoneNumber(countryName: string): string {
        return CountriesUtils.getCountryCode(countryName);
    }


    getNationalPhoneNumber(number: string, coutryCode: any): string {
        if (number === '') {
            return '-';
        }
        return this.phoneNumberPipe.transform(number, coutryCode, PhoneNumberFormat.INTERNATIONAL);
    }

    onClose(): void {
        this.dialogRef.close();
    }

    getAddressRepairer(): string {
        return (!!this.data?.repairer?.completeAddress?.countryCode ?
            this.translateService.instant('COUNTRY.NAME.' + this.data.repairer.completeAddress.countryCode) + '-' : '-') +
            (!!this.data?.repairer?.completeAddress?.city ? this.data.repairer.completeAddress.city + '-' : '-') +
            (!!this.data?.repairer?.completeAddress?.zipCode ? this.data.repairer.completeAddress.zipCode + '-' : '-');
    }
    getCompleteAddress(): string {
        return (!!this.data?.repairer?.completeAddress?.address ? this.data.repairer.completeAddress.address
            : '-') + ', ' + (!!this.data?.repairer?.completeAddress?.secondAddress ?
                this.data.repairer.completeAddress.secondAddress : '-');
    }
    getUserDetails(): string{
        return (!!this.data?.repairer?.contact ? this.data.repairer.contact.firstName : '-' ) + '-' +
            (!!this.data?.repairer?.contact ? this.data.repairer.contact.lastName : '-');
    }
    getFormattedPhone(number: string, coutryCode: any): string {
        return !!number ? this.getNationalPhoneNumber(number, coutryCode) : '';
    }
}
