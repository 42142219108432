<div class="dl-search-result">
    <div class="w-100-p ">
        <div class="calendar-menu ">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <button [disabled]="disabledPreview" class="prevDays btn mat-elevation-z0" (click)="previewWeek()">
                    <svg width="10" height="16"
                         [ngClass]="disabledPreview?'availabilities-pagination-arrow float-left ml-8 disabled-icon font-weight-600':'availabilities-pagination-arrow float-left ml-8 font-weight-600'">
                        <path
                            d="M6.7.5c.2-.3.5-.5 1-.5s.8.2 1.2.5c.5.6.5 1.4 0 2.2L3.5 8 9 13.5c.6.8.6 1.5 0 2.2-.4.3-.8.4-1.3.4-.4 0-.8 0-1-.3L.4 9C0 9 0 8.6 0 8c0-.4 0-.8.4-1L6.7.5z"></path>
                    </svg>
                    <span
                        [ngClass]="disabledPreview?'text float-left prev-day_span disabled-text':'text float-left prev-day_span'"
                        fxShow.lt-sm="false">{{'BUTTON.BACK' | translate}}</span>
                </button>
                <mat-form-field class="display-none">
                    <input matInput [matDatepicker]="picker" placeholder="Choose a date" [min]="minDate"
                           (dateChange)="changeBeginDay('change', $event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker></mat-datepicker>
                </mat-form-field>
                <mat-card class="chooseDate btn text-center mat-elevation-z0" (click)="picker.open()" fxLayout="row"
                          fxLayoutAlign="space-around center" id="selectDate">
                    <mat-icon svgIcon="calendar_rdv"></mat-icon>
                    <span class="text pl-8 font-weight-700"
                          fxShow.lt-md="false"> {{"GENERAL.DATE_FORMAT_CALENDAR_SELECT_DATE" | translate}}</span>
                </mat-card>
                <mat-card class="nextDays btn mat-elevation-z0" (click)="nextWeek()">
                    <svg class="availabilities-pagination-arrow" width="10" height="16">
                        <path
                            d="M2.7.5c-.2-.3-.5-.5-1-.5S1 .2.5.5C-.2 1-.2 2 .5 2.7L5.7 8 .5 13.5c-.7.8-.7 1.5 0 2.2.3.3.7.4 1.2.4.4 0 .8 0 1-.3L9 9c.3-.2.4-.5.4-1 0-.4 0-.8-.4-1L2.7.5z"></path>
                    </svg>
                    <span class="text next-day_span font-weight-700"
                          fxShow.lt-sm="false">{{'BUTTON.NEXT' | translate}}</span>
                </mat-card>
            </div>
        </div>
        <div class="dl-desktop-availabilities dl-desktop-availabilities-flat mt-16">
            <div class="dl-desktop-availabilities-inner p-16">
                <div class="dl-desktop-availabilities-days">

                    <ng-container *ngFor="let day of listDays">
                        <div class="availabilities-day">
                            <div class="availabilities-day-title">
                                <div class="availabilities-day-name">
                                    {{dayUpperCase(day.date  | moment: ('GENERAL.DATE_FORMAT_CALENDAR_WEEK_DAY' | translate) : language)}}
                                </div>
                                <div class="availabilities-day-date font-weight-600">
                                    {{day.date  | moment: ('GENERAL.DATE_FORMAT_CALENDAR_DAY' | translate) : language}}
                                    {{dayUpperCase(day.date  | moment: ('GENERAL.DATE_FORMAT_CALENDAR_MONTH' | translate) : language) }}
                                </div>
                            </div>
                            <div class="availabilities-slots">

                                <ng-container *ngFor="let element of day.dayElements">
                                    <ng-container *ngIf="element===null else DAY_ELEMENT_NOT_NULL">
                                        <div class="availabilities-empty-slot">
                                            <div class="availabilities-empty-slot-dash"></div>
                                        </div>
                                    </ng-container>
                                    <ng-template #DAY_ELEMENT_NOT_NULL>

                                        <ng-container
                                            *ngIf="checkDayIntoDaysChecked(day, element) ;else not_check_MorningRDV">

                                            <div class="availabilities-slot calendar-rdv-checked"
                                                 matTooltip="{{'FOLDER.SCHEDULE.MATTOOLTIP' | translate}}"
                                                 (click)="rdvChecked(day.date,element)">
                                                <span class="font-weight-700">{{ element.beginHour}}</span>
                                                <ng-container *ngIf="element.endHour">
                                                    <span class="divider-elemnt">-</span>
                                                    <span class="font-weight-700">{{ element.endHour}}</span>
                                                </ng-container>
                                            </div>

                                        </ng-container>
                                        <ng-template #not_check_MorningRDV>
                                            <div id="{{day.date | date : 'dd-MM-yyyy'}}-{{element.beginHour}}"
                                                 class="availabilities-slot"
                                                 matTooltip="{{'FOLDER.SCHEDULE.MATTOOLTIP' | translate}}"
                                                 (click)="rdvChecked(day.date,element)">
                                                <span class="font-weight-700">{{ element.beginHour}}</span>
                                                <ng-container *ngIf="element.endHour">
                                                    <span class="divider-elemnt">-</span>
                                                    <span class="font-weight-700">{{ element.endHour}}</span>
                                                </ng-container>
                                            </div>

                                        </ng-template>

                                    </ng-template>
                                </ng-container>

                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>

        <div class="mt-16">
            <div fxLayout="column" fxLayoutAlign="start" *ngIf="descriptionCalender">
                <label class="h4 mb-16">{{descriptionCalender}}</label>
            </div>
            <mat-chip-list>
                <div fxLayout="column">
                    <ng-container *ngFor="let date of daysChecked">
                        <div>
                            <mat-chip>
                                <div class="date-selected">
                                    {{date.date.toISOString() | moment: ('GENERAL.DATE_FORMAT_CONFIRMATION' | translate) : (currentLanguage$ | async) }}
                                    ,  {{date.elementSelected.beginHour }}
                                    <ng-container *ngIf="date.elementSelected.endHour">
                                        {{' - ' + date.elementSelected.endHour}}
                                    </ng-container>
                                </div>
                                <mat-icon matChipRemove class="icon-remove_element"
                                          (click)="rdvChecked(date.date,date.elementSelected)">
                                    cancel
                                </mat-icon>
                            </mat-chip>
                        </div>
                    </ng-container>
                </div>
            </mat-chip-list>
        </div>

    </div>
</div>
