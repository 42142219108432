import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Site} from '../../../../models/site.model';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-folder-site-modal',
    templateUrl: './folder-site-modal.component.html',
    styleUrls: ['./folder-site-modal.component.scss']
})
export class FolderSiteModalComponent {

    displayedColumns: string[] = ['code', 'name', 'type'];
    dataSourceSite = new MatTableDataSource<Site[]>();

    constructor(
        public dialogRef: MatDialogRef<FolderSiteModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data) {
        this.dataSourceSite.data = data.sites;
    }


    onClose(): void {
        this.dialogRef.close();
    }

}
