import {ConfigurationState, initialConfigurationState} from './configuration.state';
import {ConfigurationActionType} from './configuration.actions';

export function configurationReducer(state: ConfigurationState = initialConfigurationState, action): ConfigurationState {
    switch (action.type) {
        case ConfigurationActionType.SUCCESS_LOAD_CONFIGURATION:
            return {
                ...state,
                configurationMap: action.configurationMap
            };

        default:
            return state;
    }
}

