import {Component, Input, OnInit} from '@angular/core';
import {SupplierContact} from '../../../../../../models/SupplierDocument';

@Component({
  selector: 'app-supplier-contact-cards',
  templateUrl: './supplier-contact-cards.component.html',
  styleUrls: ['./supplier-contact-cards.component.scss']
})
export class SupplierContactCardsComponent implements OnInit {
    @Input() contacts: SupplierContact[];

  constructor() { }

  ngOnInit(): void {
  }

}
