import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {Invoice} from '../../../../models/invoice.model';
import {Product} from '../../../../models/product.model';
import {FolderCreationService} from '../folder-creation.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Customer} from '../../../../models/customer/customer.model';
import {BackOfficeService} from '../../../../shared/services/back-office.service';
import {ProductService} from '../../../../shared/services/product.service';
import {filter, finalize, map, startWith, switchMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {IFolderCreateRequestSnapshot} from '../../../../models/folder.model';
import {OriginEnum} from '../../../../models/enums/origin.enum';
import {SiteType} from '../../../../models/enums/siteType.enum';
import {DatePipe} from '@angular/common';
import {FolderService} from '../../../../shared/services/folder.service';
import {Originator} from '../../../../models/originator.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {currentUser} from '../../../../store/user/user.selectors';
import {UserState} from '../../../../store/user/user.state';
import {Unsubscriber} from '../../../../unsubscriber';
import * as moment from 'moment';
import {RuleEvaluationContext} from '../../../../models/rules/RuleEvaluationContext';
import {ProductFamilyType} from '../../../../models/enums/productFamilyType.enum';
import {customer, isCreationWithoutCustomer, isCustomerFormValid} from 'app/store/customer/customer.selectors';
import {ResetCustomer, ResetCustomerSearchResult} from '../../../../store/customer/customer.actions';
import {PageSearchRequest} from '../../../../models/page-search-request.model';
import {Constants} from '../../../../Constants';

@Component({
    selector: 'app-create-folder-repairer',
    templateUrl: './create-folder-repairer.component.html',
    styleUrls: ['./create-folder-repairer.component.scss']
})
export class CreateFolderRepairerComponent extends Unsubscriber implements OnInit, AfterViewInit, AfterViewChecked {

    searchForm = {
        client: '',
        invoice: '',
        originator: '',
        product: ''
    };

    folderCreationForm = {
        form: null,
        filteredProductsList: new ReplaySubject<any[]>(),
        filteredOriginatorsList: new ReplaySubject<any[]>(),
    };
    currentUser: UserState;
    customer: Customer;
    invoice: Invoice;
    originator: Originator;
    public searching = false;
    invoiceNotFound = false;
    createFolderInProgress = false;
    isCustomerCorporation = false;
    isFolderCreationWithoutCustomer: boolean;
    isCustomerFormValid: boolean;
    productNotFound: boolean;
    product: Product;
    showProductState: boolean;
    activeIconImage = {
        productSearchIcon: 'search',
        invoiceSearchIcon: 'search',
    };

    constructor(private folderCreationService: FolderCreationService,
                private backOfficeService: BackOfficeService,
                private folderService: FolderService,
                private activatedRoute: ActivatedRoute,
                private store$: Store<AppState>,
                private productService: ProductService,
                private router: Router,
                private datePipe: DatePipe,
                private cdRef: ChangeDetectorRef) {
        super();
    }

    ngAfterViewInit(): void {
        this.cdRef.detectChanges();
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(customer)).subscribe(customer => {
            this.customer = customer;
        });
        this.anotherSubscription = this.folderCreationService.invoice$.subscribe(invoice => {
            this.invoice = invoice;
        });

        this.anotherSubscription = this.folderCreationService.getNewProduct().subscribe(product => {
            this.product = product;
        });
        this.anotherSubscription = this.store$.pipe(select(currentUser)).subscribe(currentUser => {
            this.currentUser = currentUser;
        });
        this.initFolderCreationForm();

        this.anotherSubscription = this.store$.pipe(select(isCreationWithoutCustomer))
            .subscribe(isCreationWithoutCustomer => this.isFolderCreationWithoutCustomer = isCreationWithoutCustomer);

        this.anotherSubscription = this.store$.pipe(select(isCustomerFormValid)).subscribe(isValid => {
            this.isCustomerFormValid = isValid;
        });
    }

    initFolderCreationForm(): void {
        this.folderCreationForm.form = new FormGroup({
            product: new FormControl(null, [Validators.required]),
            invoice: new FormControl(null),
            symptomSelected: new FormControl(null, [Validators.required]),
            originator: new FormControl(null),
            productCtrl: new FormControl(null),
            originatorCtrl: new FormControl(null)
        });
        this.initConfigsSearchFilters();
    }

    cleanAllForms(): void {
        this.resetClient();
        this.folderCreationService.invoiceChanged(new Invoice());
        this.searchForm = {
            client: '',
            invoice: '',
            product: '',
            originator: ''
        };
        this.folderCreationForm.form.reset();
    }

    resetClient(): void {
        this.searchForm.client = '';
        this.store$.dispatch(new ResetCustomer());
        this.store$.dispatch(new ResetCustomerSearchResult());
    }

    createFolder(): void {
        this.createFolderInProgress = true;
        this.callCreateFolder(this.prepareFolderCreateRequest());
        this.store$.dispatch(new StopLoading());
        this.createFolderInProgress = false;

    }

    private callCreateFolder(folderCreateRequest: IFolderCreateRequestSnapshot): void {
        this.store$.dispatch(new StartLoading());
        this.folderService.createFolder(folderCreateRequest).subscribe(folder => {
            this.router.navigate(['folder', folder.id]).then();
        }, () => {
            this.store$.dispatch(new StopLoading());
            this.createFolderInProgress = false;
        });
    }

    private prepareFolderCreateRequest(): any {
        const reference = this.customer.reference;
        this.customer = {
            ...this.customer,
            reference: reference,
            addresses: [this.customer.address]
        };
        const folderCreateRequest: IFolderCreateRequestSnapshot = {
            originator: undefined,
            customer: <Customer>this.customer,
            faultCode: {
                code: this.getCode(this.folderCreationForm.form.get(['symptomSelected']).value),
                label: this.folderCreationForm.form.get(['symptomSelected']).value,
                condition: ''
            },
            product: this.product,
            origin: OriginEnum.BO,
            stockClaim: false,
            context: this.currentUser.context,
            businessLink: this.currentUser.businessLink ?? null,
            organization: {
                code: this.currentUser.organizationCode,
                label: this.currentUser.organizationCode,
                id: ''
            },
            quantity: 1,
            purchaseInvoice: {
                number: !!this.invoice ? this.invoice.id : '',
                invoiceLine: '1',
                quantity: 1,
                underWarranty: null,
                purchaseDate: this.computePurchaseDate()
            },
            site: {
                code: this.currentUser.organizationCode === 'AUCHAN' ? 'LILLE' : this.currentUser.affectedSite,
                type: SiteType.CREATION_SITE
            }
        };
        if (this.folderCreationForm.form.get(['originator']).value) {
            const originator: any = {
                gender: this.folderCreationForm.form.get(['originator']).value.gender,
                firstName: this.folderCreationForm.form.get(['originator']).value.firstName,
                lastName: this.folderCreationForm.form.get(['originator']).value.lastName,
                contact: this.folderCreationForm.form.get(['originator']).value.contact,
                addresses: [this.folderCreationForm.form.get(['originator']).value.address]
            };
            folderCreateRequest.originator = originator;
        }
        return folderCreateRequest;
    }
    private computePurchaseDate(): string {
        return this.datePipe.transform(this.invoice.creationDate, 'yyyyMMdd') || '19900101';
    }
    private getCode(code: string): string {
        return code.toLocaleUpperCase().replace(/[.*+?^${} \-'()|[\]\\]/g, '_');
    }

    isCreateFolderDisabled(): any {
        if (this.isFolderCreationWithoutCustomer) {
            return this.isProductSelected() && this.isInvoicePresent() && !this.createFolderInProgress;
        }
        return this.isCustomerPresent() &&
            this.isCustomerFormValid &&
            this.isInvoicePresent() &&
            this.isProductSelected() &&
            !this.createFolderInProgress;
    }
    private isInvoicePresent(): boolean {
        return !!this.invoice && !!this.invoice.id && !!this.invoice.creationDate && this.isValidCreationDate();
    }


    isInvoiceSelected(): boolean {
        return (!!this.invoice && !!this.invoice.id && !!this.invoice.creationDate)
            || this.invoice === undefined
            || ((this.invoice.id === null || this.invoice.id === undefined)
                && (this.invoice.creationDate === null || this.invoice.creationDate === undefined));
    }

    initConfigsSearchFilters(): void {
        this.anotherSubscription = this.folderCreationForm.form.get(['productCtrl']).valueChanges
            .pipe(startWith(null))
            .subscribe(input => {
                if (!!input) {
                    this.searchProductsWithAutoComplete(input);
                }
            }, () => {
                this.searching = false;
            });
        this.anotherSubscription = this.folderCreationForm.form.get(['originatorCtrl']).valueChanges
            .pipe(startWith(null))
            .subscribe(input => {
                if (!!input && input.length > 1) {
                    this.searchWithOriginator(input);
                }
            }, () => {
                this.searching = false;
            });
    }

    private searchProductsWithAutoComplete(input: string): void {
        this.searching = true;
        const inputsLabel = new Map;
        inputsLabel.set('organizationCode', this.currentUser.organizationCode);
        if (input.length > 0) {
            inputsLabel.set('label', input);
        }
        this.searchProductsWithParams(inputsLabel);
        const inputsCode = new Map;
        inputsCode.set('organizationCode', this.currentUser.organizationCode);
        if (input.length > 0) {
            inputsCode.set('code', input);
        }
        this.searchProductsWithParams(inputsCode);
    }

    private isCustomerPresent(): any {
        return this.customer && this.customer.firstName && (!this.isCustomerCorporation ? this.customer.lastName && this.customer.gender : true)
            && this.customer.contact && this.customer.contact.email && (!this.isCustomerCorporation ? this.customer.contact.mobileNumber : this.customer.contact.phoneNumber)
            && this.customer.address
            && this.customer.address.address
            && this.customer.address.city
            && this.customer.address.zipCode
            && this.customer.address.countryCode;
    }



    private isProductSelected = () =>
        this.product
        && this.product.code
        && !!this.folderCreationForm.form.get(['symptomSelected']).value

    private searchProductsWithParams(mapInput: any): void {
        const pageCriteria: PageSearchRequest = {
            page: 0,
            size: 100,
        };
        this.anotherSubscription = this.productService.getProductList(pageCriteria, mapInput).subscribe(data => {
            this.searching = false;
            this.folderCreationForm.filteredProductsList.next(data.content);
        }, () => {
            this.searching = false;
        });
    }


    searchWithOriginator(input: string): void {
        if (input.length > 0) {
            this.store$.dispatch(new StartLoading());
            this.searching = true;
            const inputsFirstName = new Map;
            if (input.length > 0) {
                inputsFirstName.set('firstName', input);
            }
            this.searchOriginatorsWithParams(inputsFirstName);
            const inputsLastName = new Map;
            if (input.length > 0) {
                inputsLastName.set('lastName', input);
            }
            this.searchOriginatorsWithParams(inputsLastName);
            const inputsEmail = new Map;
            if (input.length > 0) {
                inputsEmail.set('email', input);
            }
            this.searchOriginatorsWithParams(inputsEmail);
            this.store$.dispatch(new StopLoading());
        }
    }

    private searchOriginatorsWithParams(mapInput: any): void {
        const pageCriteria: PageSearchRequest = {
            page: 0,
            size: 100,
        };
        mapInput.set('organizationCode', this.currentUser.organizationCode);
        this.anotherSubscription = this.backOfficeService.searchAllOriginatorsByCriterias(pageCriteria, mapInput).subscribe(data => {
            this.searching = false;
            if (data) {
                this.folderCreationForm.filteredOriginatorsList.next(data.content);
            }
        }, () => {
            this.searching = false;
        });
    }

    private isValidCreationDate = () => moment(this.invoice.creationDate).isBefore(moment());

    searchWithInvoice(): void {
        this.invoiceNotFound = false;
        const searchedInvoice = this.searchForm.invoice;
        if (!!searchedInvoice && searchedInvoice.length > 0) {
            if (searchedInvoice !== this.invoice?.id) {
                this.folderCreationService.productChanged(null);
                this.sendSearchInvoiceRequest();
            }
        }
        else {
            this.folderCreationService.invoiceChanged(null);
        }
    }
    searchWithProduct(): void {
        this.productNotFound = false;
        if (!this.searchForm.product || (this.product && this.product.code === this.searchForm.product)) {
            this.folderCreationService.productChanged(null);
        }
        if (this.searchForm.product.length > 0) {
            this.showProductState = false;
            this.folderCreationService.productChanged(null);
            this.store$.dispatch(new StartLoading());
            this.searchForm.product = this.searchForm.product.trim();
            let product;

            const filters = new Map<string, any>();
            filters.set(Constants.PRODUCT_CODE, this.searchForm.product);
            filters.set(Constants.ORGANIZATION_CODE, this.currentUser.organizationCode);

            this.productService.search(filters)
                .pipe(
                    map(page => page.content),
                    filter(products => products.length > 0),
                    map(products => products[0]),
                    switchMap((productResponse: Product) => {
                            product = productResponse;
                            if (!product) {
                                this.productNotFound = true;
                            }
                            if (!!product) {
                                const evaluationContext: RuleEvaluationContext = {
                                    organizationCode: this.currentUser.organizationCode,
                                    context: this.currentUser.context,
                                    productCode: product.code,
                                    family: RuleEvaluationContext.getFamilyByType(product.families, ProductFamilyType.FAMILY),
                                    subFamily: RuleEvaluationContext.getFamilyByType(product.families, ProductFamilyType.SUB_FAMILY),
                                    type: RuleEvaluationContext.getFamilyByType(product.families, ProductFamilyType.TYPE),
                                    subType: RuleEvaluationContext.getFamilyByType(product.families, ProductFamilyType.SUB_TYPE),
                                    segment: RuleEvaluationContext.getFamilyByType(product.families, ProductFamilyType.SEGMENT),
                                    brand: product.brand,
                                    supplierCode: product.supplier?.code
                                };
                                return this.backOfficeService.getEligibilities(evaluationContext);
                            }
                        }
                    )
                )
                .pipe(finalize(() => {
                    this.store$.dispatch(new StopLoading());
                })).subscribe(eligibility => {
                    product.afterSalesEligible = !!product.afterSalesEligible ? (eligibility && product.afterSalesEligible) : eligibility;
                    this.folderCreationService.productChanged(product);
                    this.showProductState = true;
                }, () => {
                    this.productNotFound = true;
                });
        }
    }

    private sendSearchInvoiceRequest(): void {
        this.store$.dispatch(new StartLoading());
        this.backOfficeService.searchInvoice(this.searchForm.invoice)
            .pipe(finalize(() => this.store$.dispatch(new StopLoading())))
            .subscribe(invoice => {
                if (!invoice) {
                    this.invoice = new Invoice();
                } else {
                    // TODO I don't think the repairer has an invoice API !!
                    // no search invoice in the repairer page
                    this.invoice = invoice;
                    this.backOfficeService.searchInvoiceProducts(this.searchForm.invoice, '0', '5')
                        .subscribe(data => {
                            if (data.totalElements === 1) {
                                this.folderCreationService.productFromInvoiceChanged(data.content[0]);
                                this.folderCreationService.productChanged(data.content[0]);
                                this.showProductState = true;
                            }
                            this.folderCreationService.invoiceChanged(this.invoice);
                            this.folderCreationService.invoiceProductsPageChanged(data);
                            this.store$.dispatch(new StopLoading());
                        });
                }
            }, () => {
                this.invoiceNotFound = true;
            });
    }

    resetSearchField(searchFormField: string): void {
        Object.keys(this.searchForm)
            .filter(key => this.searchForm[key] === searchFormField)
            .map(it => {
                this.searchForm[it] = '';
                if (it === 'product'){
                    this.activeIconImage.productSearchIcon = 'search';
                }
                if (it === 'invoice'){
                    this.activeIconImage.invoiceSearchIcon = 'search';
                }
            });

    }
    activeInvoiceSearchIcon(searchFormField: string): string{
        if (searchFormField.length > 0) {
            this.activeIconImage.invoiceSearchIcon = 'close';
        }
        return this.activeIconImage.invoiceSearchIcon;
    }
    activeProductSearchIcon(searchFormField: string): string{
        if (searchFormField.length > 0) {
            this.activeIconImage.productSearchIcon = 'close';
        }
        return this.activeIconImage.productSearchIcon;
    }
}
