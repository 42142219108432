<mat-card class="" *ngIf="!!site">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
        <mat-icon class=" s-32">location_city</mat-icon>
        <mat-card-title class="mb-0">{{'SITE.TITLE'| translate}}</mat-card-title>
    </div>
    <mat-card-content>
        <div class="">
            <div>
                <span class="font-weight-900">{{'SITE.SIGN'| translate}} :</span>
                {{site.sign}}
            </div>
            <div>
                <span class="font-weight-900">{{'SITE.CORPORATENAME'| translate}} :</span>
                {{site.corporateName}}
            </div>
            <div>
                <span class="font-weight-900">{{'SITE.CODE'| translate}} :</span>
                {{site.code}}
            </div>
        </div>
    </mat-card-content>
</mat-card>

