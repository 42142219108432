import {Component, Inject, OnInit} from '@angular/core';
import {FileService} from '../../../../shared/services/file.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PushNotificationService} from '../../../../shared/services/pushNotification.service';
import {Alert} from '../../../../models/Alert.model';
import {ShowImageModalComponent} from '../../../image-modal/show-image-modal.component';
import {FilesUtils} from '../../../../shared/utils/files-utils';
import {of} from 'rxjs';

@Component({
    selector: 'app-alert-folder-modal',
    templateUrl: './alert-folder-modal.component.html',
    styleUrls: ['./alert-folder-modal.component.scss']
})
export class AlertFolderModalComponent implements OnInit {
    alerts: Alert[];
    initAttachmentsFiles = [];
    attachmentsFiles = [];

    constructor(public dialogRef: MatDialogRef<AlertFolderModalComponent>,
                private alertService: PushNotificationService,
                private fileService: FileService,
                private matDialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.getAlertInfo();
    }
    getAlertInfo(): void {
        this.alerts = this.data.alerts;
        this.alerts.map(alert => {
            if (!!alert.attachmentIds && alert.attachmentIds.length > 0) {
                this.initAttachmentsFiles = alert.attachmentIds;
                this.initAttachmentsFiles.forEach(attachmentId => {
                    this.fileService.getAttachmentFileAllInfo(attachmentId).subscribe(fileDetails => {
                        this.getDataFile(attachmentId, fileDetails);
                    });
                });
            }
        });
    }

    getDataFile(imageId: string, fileDetails: any): void {
        this.fileService.getAttachmentFile(imageId).subscribe(resFile => {
            const reader = new FileReader();
            reader.readAsDataURL(resFile);
            reader.onloadend = () => {
                this.attachmentsFiles.push({
                    id: imageId,
                    name: fileDetails.fileName,
                    uploadDate: fileDetails.uploadDate,
                    src: reader.result
                });
            };
        });
    }
    displayAttachmentsByAlert(alert: Alert){
        return  this.attachmentsFiles.filter(item => alert.attachmentIds?.includes(item.id));
    }

    showImageModal(srcImage): void {
        const dialogRef = this.matDialog.open(ShowImageModalComponent, {
            height: 'auto',
            width: '100vh',
            minWidth: '800px',
            data: of([srcImage])
        });
        dialogRef.afterClosed().subscribe(
            data => {
                console.log('Dialog output:', data);
            }
        );
    }

    downloadAttachment(attachmentId, attachmentName): void {
        this.fileService.getAttachmentFile(attachmentId).subscribe(resFile => {
            this.downloadFile(resFile, attachmentName);
        }, () => {
        });
    }

    downloadFile(resFile: any, fileName: string): void {
        const reader = new FileReader();
        reader.readAsDataURL(resFile);
        reader.onloadend = () => {
            console.log(reader);
            FilesUtils.saveFile(reader.result, fileName, resFile.type);
        };
    }

    onClose(): void {
        this.dialogRef.close();
    }
}
