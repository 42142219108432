import {Component, Input} from '@angular/core';
import {GLOBAL} from '../../app-config';

@Component({
    selector: 'app-product-image',
    templateUrl: './product-image.component.html',
    styleUrls: ['./product-image.component.scss']
})
export class ProductImageComponent {

    @Input() image: string;
    @Input() loaderCss: any;
    @Input() imageCss: any;

    placeholder = GLOBAL.imagePlaceholder;
    loader = GLOBAL.loader;
    loading = true;

    constructor() {
    }

    onLoad() {
        this.loading = false;
    }

    onImageError(event: ErrorEvent) {
        (event.target as HTMLImageElement).src = this.placeholder;
    }

}
