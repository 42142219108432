import {Directive, ElementRef, forwardRef, HostListener, Renderer2, StaticProvider} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export const CUSTOM_INPUT_NUMBER_CONTROL_VALUE_ACCESSOR: StaticProvider = {
    provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NumberOnlyNotIncludeZerosDirective), multi: true
};

@Directive({
    selector: '[NumberOnlyNotIncludeZeros]',
    providers: [CUSTOM_INPUT_NUMBER_CONTROL_VALUE_ACCESSOR]

})
export class NumberOnlyNotIncludeZerosDirective implements ControlValueAccessor {
    private onChange: (val: string) => void;
    private onTouched: () => void;
    private value: string;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    }
    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {
        const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter'];
        if (allowedKeys.includes(e.key)) {
            return;
        }
        if (e.key === ' ' || isNaN(Number(e.key))) {
            e.preventDefault();
        }
    }
    @HostListener('input', ['$event.target.value']) onInputChange(value: string): void {
        const filteredValue: string = this.filterValue(value);
        this.updateTextInput(filteredValue, this.value !== filteredValue);
    }

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
        const pastedInput: string = event.clipboardData.getData('text/plain');
        const filteredValue: string = this.filterValue(pastedInput);
        if (Number(filteredValue) <= 0) {
            event.preventDefault();
        }
    }

    @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
        const textData = event.dataTransfer.getData('text');
    }

    @HostListener('blur') onBlur(): void {
        this.onTouched();
    }

    private updateTextInput(value: string, propagateChange: boolean): void {
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
        if (propagateChange) {
            this.onChange(value);
        }
        this.value = value;
    }

    // ControlValueAccessor Interface
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    }

    writeValue(value: any): void {
        value = value ? String(value) : '';
        this.updateTextInput(value, false);
    }

    filterValue(value): string {
        return value.replace(/^0[1-9]*/g, '');
    }

}