import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {QuotationLine} from '../../../../../../models/quotation.model';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {TypeOfBenefitService} from '../../../../../../shared/services/typeOfBenefit.service';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {currency} from '../../../../../../store/organization/organization.selectors';
import {TypeOfServiceEnum} from '../../../../../../models/typeOfService.model';

@Component({
    selector: 'app-quotation-content',
    templateUrl: './quotation-content.component.html',
    styleUrls: ['./quotation-content.component.scss']
})
export class QuotationContentComponent extends Unsubscriber implements OnInit {

    @Input() quotationLines: QuotationLine[];

    quotationSummary = new MatTableDataSource<any>();
    displayedColumns: string[] = ['type', 'number', 'totalPriceHT', 'totalPrice'];
    currency: string;
    private typeOfBenefits = [];

    constructor(private typeOfBenefitService: TypeOfBenefitService,
                private translateService: TranslateService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.initCurrency();
        this.initTypeOfBenefit();
    }

    private initCurrency() {
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => {
            this.currency = currency;
        });
    }

    private initTypeOfBenefit() {
        const sparePart = {
            code: TypeOfServiceEnum.SPARE_PART,
            label: this.translateService.instant('MODAL.QUOTATION.TYPE.SPARE_PART')
        };
        this.typeOfBenefits.push(sparePart);
        this.typeOfBenefitService.all().subscribe(types => {
            types.forEach(value => {
                this.typeOfBenefits.push(value);
            });
            this.initQuotationDataTable();
        });
    }

    private initQuotationDataTable(): void {
        const dataSource = [];
        let number = 0;
        let totalTTC = 0;
        let totalHT = 0;
        for (const typeOfBenefit of this.typeOfBenefits) {
            const type = typeOfBenefit.code;
            number = this.getNumberOfQuotationLineByType(type);
            totalTTC = this.getTotalPriceByType(type);
            totalHT = this.getTotalPriceHTByType(type);
            if (number !== 0) {
                dataSource.push({
                    type: type,
                    number: number,
                    totalHT: totalHT,
                    totalTTC: totalTTC
                });
            }
        }
        this.quotationSummary = new MatTableDataSource<any>(dataSource);
    }

    getNumberOfQuotationLineByType(type: string): number {
        return this.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === type ? total + 1 : total, 0);
    }

    getTotalPriceByType(type: string): any {
        return this.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === type ? total + line.totalLinePrice?.value : total, 0);
    }

    getTotalPriceHTByType(type: string): any {
        return this.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === type ? total + line.totalPriceHTAfterDiscount?.value : total, 0);
    }
}
