import {createSelector} from '@ngrx/store';
import {AppState} from '../app.state';
import {DashboardState} from './dashboard.state';

const dashboardState = (state: AppState) => state.dashboard;

export const selectedDashboardMode = createSelector(
    dashboardState,
    (dashboard: DashboardState) => dashboard.mode
);

export const userDashboards = createSelector(
    dashboardState,
    (dashboard: DashboardState) => dashboard.dashboards
);
