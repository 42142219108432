import {Contact} from './contact.model';
import {Address} from './customer/customer.model';

export class Supplier {
    id?: string;
    code: string;
    label: string;
    contact?: Contact;
    address?: Address;
}

