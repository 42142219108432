import {Component, OnInit, ViewChild} from '@angular/core';
import {AppointmentService} from '../../../../shared/services/appointment/appointment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {MatTableDataSource} from '@angular/material/table';
import {Observable, Subject} from 'rxjs';
import {IDashBoardDayResponse} from '../../../../models/repair/dashBoardDayResponse.model';
import {CountriesUtils} from '../../../../shared/data/countries-utils';
import {MdePopoverTrigger} from '@material-extended/mde';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Detail} from '../model/repair/detail.model';
import {AppState} from '../../../../store/app.state';
import {select, Store} from '@ngrx/store';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {isAdminPlatana, organizationCode} from '../../../../store/user/user.selectors';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../unsubscriber';
import {ReOptimizeConfirmDialogComponent} from './reoptimize-confirm-dialog/reoptimize-confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CloseModal} from '../../../../shared/modal/CloseModal';
import {HttpParams} from '@angular/common/http';
import {IDetail} from '../../../../models/repair/detail.model';
import * as _ from 'lodash';
import {finalize, takeUntil} from 'rxjs/operators';
import {ExportFoldersRequest, FieldsHeaders} from '../../../../models/SearchFoldersRequest.model';
import {PRODUCT_RECALL_CSV_HEADER} from '../../../folder/folder-list/folder-list.component';
import {Filter} from '../../../../models/folder-search-response.model';
import {ExportRequest} from '../../../../models/ExportRequest';
import {FolderService} from '../../../../shared/services/folder.service';
import * as fileSaver from 'file-saver';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-dash-board-day',
    templateUrl: './dash-board-day.component.html',
    styleUrls: ['./dash-board-day.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class DashBoardDayComponent extends Unsubscriber implements OnInit {
    @ViewChild('appPopover', {static: false}) eventContentPopover: MdePopoverTrigger;

    protected _onDestroy = new Subject<void>();
    private closeModal = new CloseModal();
    viewDate: Date;
    view = 'day';
    mode = 'determinate';
    dateFormat = 'yyyy-MM-dd';
    selectedDay: Date;
    site: string;
    private organizationCode: string;
    dashBoardDay: IDashBoardDayResponse[];
    selectedInterventions;

    dayDataSource: any = {
        dataSource: new MatTableDataSource(),
        columnsToDisplay: ['planned', 'client', 'folderReference', 'service', 'schedules', 'realSchedules'],
        expandedElement: null,
    };
    technicianSelected: IDashBoardDayResponse;
    currentTechnicianId: string;
    currentTechnicianTripId: string;
    currentSiteCode: string;
    buttonToggleSelected = 'underline';
    displayMap = true;
    currentLanguage$: Observable<string>;
    allDashBoardDayActivitiesDetails: IDetail[];
    hasRoleAdminPlatana = false;

    constructor(private appointmentService: AppointmentService,
                private activatedRoute: ActivatedRoute,
                public dialog: MatDialog,
                private datePipe: DatePipe,
                private router: Router,
                private translateService: TranslateService,
                private folderService: FolderService,
                private store$: Store<AppState>) {
        super();
        this.viewDate = new Date();
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.anotherSubscription = this.store$.pipe(select(isAdminPlatana))
            .subscribe(hasAccess => this.hasRoleAdminPlatana = hasAccess);
        if (!!this.activatedRoute.snapshot.params.id) {
            this.currentTechnicianId = this.activatedRoute.snapshot.params.id;
        }
        this.anotherSubscription = this.activatedRoute.params.subscribe(() => {
            this.anotherSubscription = this.store$.pipe(select(organizationCode)).subscribe(currentOrganizationCode => {
                this.organizationCode = currentOrganizationCode;
                this.selectedDay = new Date();
                this.activatedRoute.queryParams.subscribe(params => {
                    this.viewDate = new Date(params['date']);
                    this.site = params['site'];
                    this.loadDashBoardDay();
                });
            });
        });
    }

    loadDashBoardDay(): void {
        this.store$.dispatch(new StartLoading());
        const inputs = this.prepareInputForDashboardDay();

        this.appointmentService.getDashBoardDay(inputs).then((result) => {
            if (!!result) {
                this.dashBoardDay = result.filter(value => value.details.length > 0);
                this.allDashBoardDayActivitiesDetails = this.getAllDashboardDaysDetails(this.dashBoardDay);
                const current = this.dashBoardDay.find(f => f.technician.id === this.currentTechnicianId);
                this.currentTechnicianTripId = !!current?.interventions[0] ? current.interventions[0].tripId : null;
                this.selectedInterventions = current.interventions;
                this.currentSiteCode = this.dashBoardDay[0]?.technician.site;
                this.dashBoardDay.sort((a, b) => {
                    return b.dayDashboardDto.availabilityPercent - a.dayDashboardDto.availabilityPercent;
                });
                if (!this.currentTechnicianId) {
                    this.currentTechnicianId = this.dashBoardDay[0].technician.id;
                    this.currentTechnicianTripId = !!this.dashBoardDay[0]?.interventions[0] ? this.dashBoardDay[0].interventions[0].tripId : null;
                }
                this.dayDataSource.dataSource = this.dashBoardDay
                    .filter((value) => (!!value.technician && !!value.technician.id &&
                        value.technician.id.startsWith(this.currentTechnicianId)))[0].details;
            }
            this.store$.dispatch(new StopLoading());

        }).catch(error => {
            this.store$.dispatch(new StopLoading());
        });


    }

    private getAllDashboardDaysDetails(result: IDashBoardDayResponse[]): IDetail[] {
        return !!result ? _.flatMap(result.map(it => it.details)) : [];
    }

    private prepareInputForDashboardDay(): Map<string, string> {
        const inputs = new Map();
        inputs.set('date', this.datePipe.transform(this.viewDate, this.dateFormat));
        if (this.site) {
            inputs.set('site', this.site);
        }
        inputs.set('organizationCode', this.organizationCode);
        return inputs;
    }

    onChangeDate(): void {
        this.selectedInterventions = null;
        this.router.navigate([], {
            queryParams: {
                date: this.datePipe.transform(this.viewDate, this.dateFormat)
            },
            queryParamsHandling: 'merge',
        });
    }

    changeTechnician(currentTechnician: IDashBoardDayResponse): void {
        this.selectedInterventions = null;
        this.currentTechnicianTripId = null;
        setTimeout(() => {
            this.currentTechnicianId = currentTechnician.technician.id;
            this.currentTechnicianTripId = currentTechnician.interventions.length > 0 ? currentTechnician.interventions[0].tripId : null;
            this.dayDataSource.dataSource = currentTechnician.details;
            this.selectedInterventions = currentTechnician.interventions;
            const el = document.getElementById('targetscroll');
            el.scrollIntoView();
        }, 0);
    }

    getIconPlanned(dayFilterEnum: string): string {
        switch (dayFilterEnum) {
            case 'MORNING': {
                return 'icon_morning';
            }
            case 'AFTERNOON': {
                return 'icon_afternoon';
            }
            default: {
                return '';
            }
        }
    }

    getClient(element: Detail): string {
        if (element.customer) {
            return (element.customer.firstName.toUpperCase()).concat(' '
                .concat(!!element.customer.lastName ? element.customer.lastName.toUpperCase() : ''));
        } else {
            if (element.type === 'BREAK') {
                return 'WINDOW.BREAK';
            }
            if (element.type === 'END') {
                return 'WINDOW.END';
            }

        }

    }

    getTechnicianFullAddress(technicianItem): string {
        return !!technicianItem.technician.address ? technicianItem.technician.address.address + (!!technicianItem.technician.address.secondAddress ? +', '
            + technicianItem.technician.address.secondAddress : '') : '-';

    }


    codeColor(percent): any {
        if (percent === 0) {
            return 'custom-spinner-white';
        } else {
            return 'custom-spinner-blue';
        }
    }

    isValidCountryCode(countryCode: string): boolean {
        return CountriesUtils.isValidCountryCode(countryCode);
    }


    showDetails(technicianItem: IDashBoardDayResponse): void {
        this.technicianSelected = technicianItem;
    }

    closeDetails(): void {
        this.eventContentPopover.closePopover();
    }


    getClientTime(technicianItem: IDashBoardDayResponse): string {
        if (technicianItem.details.length === 0 || !technicianItem.details[0].beginTime) {
            return '';
        }
        return technicianItem.details[0].beginTime + '-' + technicianItem.details[(technicianItem.details.length - 1)].endTime;
    }

    isBreakTime(detail: Detail): boolean {
        const availability = ['MORNING', 'AFTERNOON'];
        return !availability.includes(detail.dayFilterEnum);
    }

    rowPlannedBorderLeftColorAndBreakTimeStyle(detail: Detail): string {
        switch (detail.status) {
            case 'STARTED': {
                return this.isBreakTime(detail) ? 'hour-schedule' : 'intervention-started';
            }
            case 'MISSING': {
                return this.isBreakTime(detail) ? 'hour-schedule' : 'intervention-missing';
            }
            case 'ENDED': {
                return this.isBreakTime(detail) ? 'hour-schedule' : 'intervention-ended';
            }
            default: {
                return this.isBreakTime(detail) ? 'hour-schedule' : 'intervention-waiting';
            }
        }
    }

    showMap(): void {
        this.displayMap = true;
    }

    showDetailTrips(): void {
        this.displayMap = false;
    }

    sendToMobile(): void {
        const inputs = this.prepareInputForDashboardDay();
        this.store$.dispatch(new StartLoading());
        this.appointmentService.sendToMobile(inputs).subscribe(
            () => this.store$.dispatch(new StopLoading()),
            () => this.store$.dispatch(new StopLoading()));
    }


    getFolderRef = (el) => !!el ? el.externalId : '*';


    reOptimizeTripsDialogConfirmation(): void {
        const minIteration = 256;
        const maxIteration = 2024;
        const dialogRef = this.dialog.open(ReOptimizeConfirmDialogComponent, {
            minWidth: '30%',
            width: '900px',
        });
        dialogRef.componentInstance.maxIteration = maxIteration;
        dialogRef.componentInstance.minIteration = minIteration;
        dialogRef.afterClosed().pipe(takeUntil(this._onDestroy)).subscribe(iterationNumber => {
            if (iterationNumber > 0) {
                this.reOptimize(iterationNumber);
            }
        });
        this.closeModal.closeWithEscape(dialogRef);
    }

    reOptimize(maxIteration: string): void {
        this.store$.dispatch(new StartLoading());
        const queryParams = this.buildReOptimizeParams(maxIteration);
        this.appointmentService.reOptimizeTrip(queryParams).subscribe(result => {
            if (result) {
                this.refreshDashBoardDay();
            }
            this.store$.dispatch(new StopLoading());
        }, () => {
            this.store$.dispatch(new StopLoading());
        });
    }

    refreshDashBoardDay(): void {
        this.loadDashBoardDay();
        this.showDetailTrips();
        this.buttonToggleSelected = 'trips';
    }

    buildReOptimizeParams(maxIteration: string): HttpParams {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('organizationCode', this.organizationCode);
        queryParams = queryParams.append('siteId', this.currentSiteCode);
        queryParams = queryParams.append('deliveryDate', this.datePipe.transform(this.viewDate, this.dateFormat));
        queryParams = queryParams.append('maxIteration', maxIteration);
        queryParams = queryParams.append('daysCount', '1');
        return queryParams;
    }

    isAuthorizedToReOptimizeTrip(): boolean {
        const now = new Date();
        const todayDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        return (this.viewDate.getTime() >= todayDate.getTime() && this.hasRoleAdminPlatana);
    }

    haveDashBoardDayInterventions(): boolean {
        return !!this.dashBoardDay && this.dashBoardDay.length > 0
            && this.dayDataSource.dataSource && this.dayDataSource.dataSource.length > 0;
    }

    exportCSVFolders(): void {
        const inputHeaders = this.prepareInputHeadersQuery(PRODUCT_RECALL_CSV_HEADER);
        const exportRequestBuilder = this.getSearchFilterRequest();
        const exportFileRequest: ExportFoldersRequest = {
            fieldsHeaders: inputHeaders,
            searchFoldersRequest: exportRequestBuilder

        };
        this.store$.dispatch(new StartLoading());
        this.folderService.exportFileFolders(exportFileRequest).pipe(finalize(() => {
            this.store$.dispatch(new StopLoading());
        })).subscribe(response => {
            const blob = new Blob(['\uFEFF' + response.body], {type: 'text/csv;charset=utf-8'});
            fileSaver.saveAs(blob, 'Export-' + this.datePipe.transform(this.viewDate, 'dd-MM-yyyy') + '.csv');
            this.store$.dispatch(new StopLoading());
        });
    }

    private prepareInputHeadersQuery(dataHeadersFile: string[]): FieldsHeaders[] {
        const inputHeaders = [];
        dataHeadersFile.forEach(titleHeaders => {
            inputHeaders.push({
                code: titleHeaders,
                label: this.translateService.instant('FOLDER.TABLE.FILTER.TREE.' + titleHeaders.toUpperCase())
            });
        });
        return inputHeaders;
    }

    private getSearchFilterRequest(): ExportRequest {
        const exportRequest: ExportRequest = {
            indexName: 'folderindex',
            filters: new Map()
        };
        exportRequest.filters['closed'] = new Filter(false);
        if (!!this.viewDate){
            exportRequest.filters['appointmentDate'] = new Filter(this.viewDate.getTime());
        }
        return exportRequest;
    }
}
