export enum Roles {
    ROLE_USER = 'ROLE_USER',
    ROLE_AFTERSALES_USER = 'ROLE_AFTERSALES_USER',
    ROLE_BUSINESS_REFUND = 'ROLE_BUSINESS_REFUND',
    ROLE_DISTRIBUTOR = 'ROLE_DISTRIBUTOR',
    ROLE_HOTLINE = 'ROLE_HOTLINE',
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_STORE_ADMIN = 'ROLE_STORE_ADMIN',
    ROLE_BUSINESS_ADMIN = 'ROLE_BUSINESS_ADMIN',
    ROLE_ADMIN_PLATANA = 'ROLE_ADMIN_PLATANA',
    ROLE_ADMIN_MOBILE = 'ROLE_ADMIN_MOBILE',
    ROLE_ORGA_ADMIN = 'ROLE_ORGA_ADMIN',
    ROLE_QUALITY = 'ROLE_QUALITY',
    ROLE_AFTERSALES_ADMIN = 'ROLE_AFTERSALES_ADMIN',
    ROLE_LOGISTIC = 'ROLE_LOGISTIC',
    ROLE_DISTRIBUTOR_ADMIN = 'ROLE_DISTRIBUTOR_ADMIN',
    ROLE_ACCOUNTABILITY_ADMIN = 'ROLE_ACCOUNTABILITY_ADMIN',
    ROLE_AUDIT = 'ROLE_AUDIT',
    ROLE_DISTRIBUTOR_USER = 'ROLE_DISTRIBUTOR_USER',
    ROLE_VIEWER = 'ROLE_VIEWER',
    ROLE_TECHNICAL_ADMIN ='ROLE_TECHNICAL_ADMIN'
}
