    <mat-card-content fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center">
            <div>
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>
        </div>

        <mat-checkbox [(ngModel)]="productRetrieved">{{'BUTTON.PRODUCT_RETRIEVED' | translate}}</mat-checkbox>

        <div class="mt-24">
            <div fxLayout="row" fxLayoutAlign="end end">
                <button mat-stroked-button
                        type="submit"
                        (click)="onSubmit()"
                        [disabled]="!productRetrieved"
                        [ngClass]="!productRetrieved ? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </div>
    </mat-card-content>