import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {QuotationModalComponent} from '../../../../../shared/generic/quotation-modal/quotation-modal.component';
import {TypeOfServiceEnum} from '../../../../../models/typeOfService.model';
import {Folder, IFolderUpdateRequest} from '../../../../../models/folder.model';
import {FolderSubjectService} from '../../../folder-subject.service';
import {FolderService} from '../../../../../shared/services/folder.service';
import {WorkflowService} from '../../../../../shared/services/workflow.service';
import {TaskVariables} from '../../task.variables';
import {FileService} from '../../../../../shared/services/file.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Quotation, QuotationLine} from '../../../../../models/quotation.model';
import * as uuid from 'uuid';
import {QuotationService} from '../../../../../shared/services/quotation.service';
import {QuotationModalConfig} from '../../../../../shared/generic/quotation-modal/quotationModal.model';
import {Payer} from '../../../../../models/enums/payer.enum';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FuseConfirmDialogComponent} from '../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {Vat} from '../../../../../models/vat.model';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {AppState} from '../../../../../store/app.state';
import {select, Store} from '@ngrx/store';
import {currency} from '../../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../../unsubscriber';
import {SiteType} from '../../../../../models/enums/siteType.enum';
import {of} from 'rxjs';
import {isSAV} from '../../../../../store/user/user.selectors';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-wait-quotation',
    templateUrl: './wait-quotation.component.html',
    styleUrls: ['./wait-quotation.component.scss']
})
export class WaitQuotationComponent extends Unsubscriber implements OnInit {

    @ViewChild('dropzone') dropzone: ElementRef;
    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;
    folder: Folder;
    displayedColumns: string[] = ['type', 'number', 'totalPrice'];
    displayedColumnsAttachment: string[] = ['name', 'action'];

    private sparePartsTypes = Object.keys(TypeOfServiceEnum);
    attachmentQuotationDataTable = new MatTableDataSource<any>();
    quotationSummary = new MatTableDataSource<any>();
    waitQuotationInprogress = false;
    quotation: Quotation;
    quotationSuggestionId: String;
    quotationDraftId: string;
    attachments = new Array<any>();
    vat: Vat;
    currency: string;
    operatingMode: string;
    warrantyCode: string;
    isSAV: boolean;
    waitQuotationForm: any = {
        form: null,
        placeholders: []
    };

    fileUploadForm: any = {
        file: null,
        hasFile: false
    };

    constructor(private folderSubjectService: FolderSubjectService,
                private folderService: FolderService,
                private workflowService: WorkflowService,
                private fileService: FileService,
                private translateService: TranslateService,
                private quotationService: QuotationService,
                private store$: Store<AppState>,
                private matDialog: MatDialog) {
        super();
    }


    ngOnInit(): void {
        this.vat = JSON.parse(this.variablesTask[TaskVariables.vat]);
        this.anotherSubscription = this.store$.pipe(select(isSAV)).subscribe(isSAV => this.isSAV = isSAV);
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => {
            this.currency = currency;
            this.quotation = Quotation.getEmptyQuotation(currency);
            this.quotationSuggestionId = this.variablesTask[TaskVariables.quotationSuggestionId];
            if (this.quotationSuggestionId) {
                this.quotation = this.folder.quotations.filter(quotation => quotation.id === this.quotationSuggestionId)[0];
                this.attachmentQuotationDataTable.data = this.quotation.quotationAttachments;
            }
            this.initDataTable();
            this.initWaitQuotationForm(this.quotation);
        });
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
            this.operatingMode = this.folder.operationMode;
            this.warrantyCode = this.folder.newWarranty.warranty;
        });
    }

    initWaitQuotationForm(quotation: Quotation): void {
        this.waitQuotationForm.form = new FormGroup({
            quotationCode: new FormControl(quotation ? quotation.code : '', Validators.required),
        });
    }

    private initDataTable(): void {
        const dataSource = [];
        let numberSpareParts = 0;
        let totalTTC = 0;
        this.quotation.totalPrice = {
            value: 0,
            currency: this.currency
        };
        for (const sparePartsCode of this.sparePartsTypes) {
            numberSpareParts = this.getNumberSparePartsOfQuotation(sparePartsCode);
            totalTTC = this.getTotalPriceSparePartsOfQuotation(sparePartsCode);
            if (numberSpareParts !== 0) {
                dataSource.push({
                    type: sparePartsCode,
                    number: numberSpareParts,
                    totalTTC: totalTTC
                });
                this.quotation.totalPrice.value += totalTTC;
            }
        }
        this.quotationSummary = new MatTableDataSource<any>(dataSource);
    }

    getNumberSparePartsOfQuotation(sparePartsCode): number {
        return this.quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === sparePartsCode ? total + 1 : total, 0);
    }

    getTotalPriceSparePartsOfQuotation(sparePartsCode): any {
        return this.quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === sparePartsCode ? total + this.quotationService.computeTotalPriceQuotationLine(line) : total, 0);
    }

    completeTask(): void {
        this.waitQuotationInprogress = true;
        if (!this.isEnabledQuotation()) {
            return;
        }
        this.store$.dispatch(new StartLoading());
        this.quotation.code = this.waitQuotationForm.form.value.quotationCode;
        this.folderService.updateQuotationToFolder(this.folder.id, this.attachments, this.quotation).subscribe(value => {

            this.inputMap.emit(
                {
                    'quotationSuggestionId': this.quotation.id,
                    'quotationDistributorId': this.quotation.id,
                    'quotationId': this.quotation.id
                }
            );
            this.waitQuotationInprogress = false;
            this.store$.dispatch(new StopLoading());
        }, () => {
            this.waitQuotationInprogress = false;
            this.store$.dispatch(new StopLoading());
        });

    }

    openQuotationModal(): void {
        const quotationModalConfig: QuotationModalConfig = {
            showFormAddQuotation: true,
            initialQuotation: this.quotation,
            warrantyCode: this.warrantyCode,
            operatingMode: this.operatingMode,
            titleModal: 'MODAL.QUOTATION.ADD_QUOTATION',
            folderId: this.folder.id,
            product: this.folder.product,
            organizationCode: this.folder.organization.code,
            context: this.folder.context,
            managementSite: this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE),
            hideTva$: of(true),
            loadSparePartCatalog: false,
            loadTypeOfBenefit: false,
            payer: this.isSAV ? Payer.DISTRIBUTOR : null
        };
        const dialogRef = this.matDialog.open(QuotationModalComponent, {
            height: '90vh', width: '100vh', minWidth: '80%', data: quotationModalConfig
        });
        dialogRef.afterClosed().subscribe(quotation => {
                if (!!quotation) {
                    if (!this.variablesTask['quotationSuggestionId']) {
                        this.quotationDraftId = uuid.v4();
                    } else {
                        this.quotationDraftId = this.quotation.id;
                        this.quotation = Quotation.getEmptyQuotation(this.currency);
                    }
                    this.quotation.id = this.quotationDraftId;
                    this.quotation.quotationLines = quotation.quotationLines;
                    this.quotation.discount = quotation.discount;
                    this.quotation.totalPrice = quotation.totalPrice;
                    this.quotation.target = Payer.DISTRIBUTOR;
                    this.store$.dispatch(new StartLoading());
                    this.updateQuotation();
                    this.initDataTable();

                }
            }
        );
    }

    updateQuotation(): void {
        if (!this.variablesTask['quotationSuggestionId']) {
            this.quotation.id = uuid.v4();
        }

        const folderUpdateRequest: IFolderUpdateRequest = {
            quotation: this.quotation
        };
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
            this.folderSubjectService.folderLoaded(folder);
            this.folder = folder;
            this.workflowService.updateQuotationSuggestionId(this.folder.id, this.quotation.id).subscribe(() => {
                this.variablesTask['quotationSuggestionId'] = this.quotation.id;
                this.store$.dispatch(new StopLoading());
            }, () => {
                this.store$.dispatch(new StopLoading());
            });
        }).catch(reason => {
            console.log('ERROR Update Folder with id :' + this.folder.id);
            this.store$.dispatch(new StopLoading());
        });

    }

    onFilesAdded($event: any): void {
        this.fileUploadForm.hasFile = true;
        this.fileUploadForm.file = $event[0].name;
        const fileChange = this.fileService.onFileChange($event);
        fileChange.reader.onload = () => {
            const blobFile = this.fileService.dataURItoBlob(fileChange.reader.result.toString().split(',')[1]);
            this.attachments.push({file: blobFile, info: $event[0]});
            this.attachmentQuotationDataTable.data = this.attachments;
            // @ts-ignore
            this.dropzone.reset();
            this.fileUploadForm.hasFile = false;
        };
    }

    removeFile(fileId): void {
        const dialog = this.matDialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });
        dialog.componentInstance.title = this.translateService.instant('CONFIRMATION.MODAL.DELETE_DIALOG.TITLE');
        dialog.componentInstance.message = this.translateService.instant('FOLDER.FILE.DELETE_DIALOG.MESSAGE');
        dialog.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.DELETE');
        dialog.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialog.afterClosed().subscribe(isDelete => {
            if (isDelete) {
                const index = this.attachments.indexOf(fileId);
                if (index > -1) {
                    this.attachments.splice(index, 1);
                }
                this.attachmentQuotationDataTable.data = this.attachments;
            }
        });

    }

    isEnabledQuotation(): boolean {
        return this.quotation.totalPrice.value >= 0 && this.quotation.quotationLines.length > 0 && this.waitQuotationForm.form?.valid;
    }

    stopPropagation(): boolean {
        return this.waitQuotationInprogress || !this.isEnabledQuotation();
    }
}
