<div mat-dialog-content>
    <fieldset>
        <legend class="title-fieldset">
            <h1 class="text-bold">
                <mat-icon class="icon-color">warning</mat-icon>
                {{'MODAL.WARNING.TITLE' | translate}}</h1>
        </legend>

        <ng-container>
            <h2 [innerHTML]="getDescription() | async"></h2>

            <h3>{{'CONFIRMATION.QUESTION' | translate}}</h3>
        </ng-container>
    </fieldset>
</div>
<div mat-dialog-actions fxLayoutAlign="end center" fxLayoutGap="10">
    <button mat-button [mat-dialog-close]="null"
            class="aster_btn_cancel">{{'BUTTON.NO' | translate}}</button>
    <button mat-raised-button class="aster_btn"
            (click)="validate()">{{'BUTTON.YES' | translate}}</button>
</div>
