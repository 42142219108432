import { Pipe, PipeTransform } from '@angular/core';
import { Coordinates } from 'app/models/product.model';

@Pipe({
  name: 'formatLocation'
})
export class FormatLocationPipe implements PipeTransform {

    transform(coordinates: Coordinates, ...args: any[]): string {
        const lat = coordinates.latitude;
        const lon = coordinates.longitude;
        if (!coordinates || lat === null || lon === null) {
            return '-';
        }
        return `${lat} , ${lon}`;
    }
}
