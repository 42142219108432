export class AmountUtils {


    static convertTTCtoHT(ttcValue: number, rate: number): number {
        return Number((ttcValue / (1 + rate / 100)));
    }
    static convertHTtoTTC(htValue: number, rate: number): number {
        return Number((htValue * (1 + rate / 100)));
    }

}
