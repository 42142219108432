import {Component, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {CustomerType} from '../../../../../../models/enums/CustomerType.enum';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {CustomerTypeChanged} from '../../../../../../store/customer/customer.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';

@Component({
    selector: 'customer-type',
    templateUrl: './customer-type.component.html'
})
export class CustomerTypeComponent extends Unsubscriber implements OnInit {

    customerTypes = [CustomerType.PRIVATE_INDIVIDUAL, CustomerType.PROFESSIONAL];

    customerForm: FormGroup;

    constructor(private rootFormGroup: FormGroupDirective,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.customerForm = this.rootFormGroup.control;
        this.anotherSubscription = this.customerForm.get('type').valueChanges
            .subscribe(value => this.store$.dispatch(new CustomerTypeChanged(value)));
    }


}
