<div class="page-layout blank p-24" fusePerfectScrollbar>
    <div fxLayout="row"
         fxLayout.lt-lg="column"
         fxLayoutAlign.lt-md="start start"
         fxLayoutGap="20px"
         fxLayoutAlign="space-between stretch">

        <div class="w-100-p"
             fxLayout="column"
             fxLayoutGap="20px"
             fxFlex.lt-md="100"
             fxFlex="60">

            <mat-card class="w-100-p">
                <div>
                    <mat-form-field class="filter w-100-p" floatLabel="never">
                        <input matInput [placeholder]="'FOLDER.CREATE.INVOICE.TITLE' | translate"
                               id="create_folder_invoice"
                               [(ngModel)]="searchForm.invoice"
                               (keyup.enter)="searchWithInvoice()">
                        <mat-icon matSuffix id="create_folder_invoice_search"
                                  class="disabled-text cursor-pointer"
                                  (click)="this.activeIconImage.invoiceSearchIcon === 'search' ? searchWithProduct() : resetSearchField(searchForm.invoice)">
                            {{activeInvoiceSearchIcon(searchForm.invoice)}}
                        </mat-icon>
                    </mat-form-field>
                    <mat-card-content class="text-center">
                        <app-invoice-folder-repairer
                            [invoiceNotFound]="invoiceNotFound"></app-invoice-folder-repairer>
                    </mat-card-content>
                </div>
            </mat-card>


            <mat-card class="w-100-p h-50">
                <ng-container>
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                        <mat-form-field class="filter w-100-p" floatLabel="never" fxFlex>
                            <input matInput [placeholder]="'FOLDER.CREATE.PRODUCT_CODE.TITLE' | translate"
                                   [(ngModel)]="searchForm.product"
                                   id="create_folder_product_code"
                                   (keyup.enter)="searchWithProduct()">
                            <mat-icon matSuffix class="disabled-text"
                                      [ngClass]="searchForm.product.length > 0 ?'cursor-pointer':''"
                                      id="create_folder_product_code_search"
                                      (click)="this.activeIconImage.productSearchIcon === 'search' ? searchWithProduct() : resetSearchField(searchForm.product)">
                                {{activeProductSearchIcon(searchForm.product)}}
                            </mat-icon>
                        </mat-form-field>
                    </div>
                </ng-container>
                <mat-card-content fxLayout="column" fxLayoutGap="10px">
                    <app-product-form fxFlex [productNotFound]="productNotFound"></app-product-form>
                    <div *ngIf="product?.code">
                        <mat-form-field fxFlex="1 5 calc(20% - 8px)"
                                        fxFlex.lt-md="100"
                                        fxFlex.lt-sm="100%" appearance="outline">
                            <mat-label>{{'FOLDER.REPAIRER.SYMPTOMS' | translate}}</mat-label>
                            <textarea matInput rows="3" cols="7"
                                      [placeholder]="'FOLDER.REPAIRER.SYMPTOMS' | translate"
                                      [formControl]="folderCreationForm.form.get(['symptomSelected'])"
                                      required></textarea>
                        </mat-form-field>
                    </div>

                </mat-card-content>

            </mat-card>
        </div>

        <!-- bloc customer -->
        <mat-card class="w-100-p"
                  fxFlex="40"
                  fxFlex.lt-md="100">
            <mat-card-content class="customer-form-content">
                <app-customer></app-customer>
            </mat-card-content>
        </mat-card>
    </div>

    <div fxLayoutAlign="end end" fxLayoutGap="10px" class="mt-12">
        <button type="button" mat-stroked-button color="accent" (click)="cleanAllForms()"
                id="create_folder_resetButton" class="py-12 px-24 mb-8 mr-8 font-weight-900 font-size-30">
            {{'FOLDER.CREATE.BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" mat-stroked-button color="accent" (click)="createFolder()"
                id="create_folder_submitButton" [disabled]="!isCreateFolderDisabled()"
                class="py-12 px-24 mb-8 mr-8 font-weight-900 font-size-30">
            {{'FOLDER.CREATE.BUTTON' | translate}}
        </button>
    </div>
</div>
