import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {WorkflowAction} from '../../../../../models/enums/workflowAction.enum';
import {FolderService} from '../../../../../shared/services/folder.service';
import {finalize} from 'rxjs/operators';
import {FolderSubjectService} from '../../../folder-subject.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

export const INVALID_CHOICE = 'Invalid choice';


@Component({
    selector: 'app-customer-decision',
    templateUrl: './customer-decision.component.html',
    styleUrls: ['./customer-decision.component.css']
})
export class CustomerDecisionComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    @Output() inputMap = new EventEmitter<any>();

    customerDecisionForm: any = {
        form: null,
        placeholders: []
    };
    userTaskCode = 'CUSTOMER_DECISION';

    constructor(private store$: Store<AppState>,
                private folderService: FolderService,
                private folderSubjectService: FolderSubjectService) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.customerDecisionForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
        });
        this.customerDecisionForm.placeholders = [
            {
                status: WorkflowAction.DECLARE_BREAKDOWN,
                value: 'BUTTON.DECLARE_BREAKDOWN',
            },
            {
                status: WorkflowAction.SPARE_PARTS_ORDER,
                value: 'BUTTON.SPARE_PARTS_ORDER',
            }
        ];
    }

    onSubmit(): void {
        this.store$.dispatch(new StartLoading());
        const selection = this.customerDecisionForm.form.value.status;
        if (selection === WorkflowAction.DECLARE_BREAKDOWN) {
            this.handleDeclareBreakdown();
        } else if (selection === WorkflowAction.SPARE_PARTS_ORDER) {
            this.handleSparePartsOrder();
        } else {
            this.store$.dispatch(new StopLoading());
            throw INVALID_CHOICE;
        }
    }

    private handleSparePartsOrder(): void {
        this.folderService.startSparePartsWorkflow(this.folder.id)
            .pipe(finalize(() => this.store$.dispatch(new StopLoading())))
            .subscribe(folder => this.navigateSilently(folder),
                () => console.error('Error during starting spare parts workflow'));
    }

    private handleDeclareBreakdown(): void {
        this.folderService.startWorkflow(this.folder.id)
            .pipe(finalize(() => this.store$.dispatch(new StopLoading())))
            .subscribe(folder => this.navigateSilently(folder),
                () => console.error('Error during starting folder workflow'));
    }

    private navigateSilently(folder: Folder): void {
        this.folderSubjectService.folderLoaded(folder); // update folder details tab card, folder snapshot card, ..
        this.folderSubjectService.refreshWorkflowStatusLoaded(folder); // fire the subject to get the current task in order to update the generic component
    }
}
