import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as L from 'leaflet';
import {Control} from 'leaflet';
import 'leaflet.markercluster';
import {ChartsResponse} from '../../model/charts-response.model';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-leaflet-markercluster',
    templateUrl: './leaflet-markercluster.component.html',
    styleUrls: ['./leaflet-markercluster.component.scss']
})
export class LeafletMarkerclusterComponent implements OnInit {

    @Input() widgetData: ChartsResponse;
    @Input() columnCount: number = null;
    @Input() id: string = null;
    @Output() updatedColumnCount = new EventEmitter<number>();
    @Output() dropWidget = new EventEmitter<number>();

    LAYER_OSM = {
        id: 'openstreetmap',
        name: 'Open Street Map',
        enabled: false,
        layer: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 18,
            attribution: 'Open Street Map'
        })
    };

    // Values to bind to Leaflet Directive
    layersControlOptions: Control.LayersOptions = {position: 'bottomright'};
    baseLayers = {
        'Open Street Map': this.LAYER_OSM.layer
    };
    options = {
        zoom: 5,
        center: L.latLng([47.34035834900857, 2.4030247656745862])
    };

    // Marker cluster stuff
    markerClusterGroup: L.MarkerClusterGroup;
    markerClusterData: L.Marker[] = [];
    markerClusterOptions: L.MarkerClusterGroupOptions;


    constructor(private translateService: TranslateService, private router: Router) {
    }

    ngOnInit(): void {
        this.refreshData();
    }

    markerClusterReady(group: L.MarkerClusterGroup) {
        this.markerClusterGroup = group;
    }

    refreshData(): void {
        this.markerClusterData = this.prepareData();
    }

    prepareData(): L.Marker[] {
        const data: L.Marker[] = [];
        this.widgetData.data.forEach(chartData => {
            const icon = L.icon({
                iconUrl: require('leaflet/dist/images/marker-icon.png'),
                shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
            });

            let locations = chartData.value.split(';');
            var popup =
                '<b>' + this.translateService.instant('DASHBOARD.WIDGET.FORM.FOLDER_INDEX.CUSTOMERLASTNAME') + ': </b>' + (!!chartData.customerLastName ? chartData.customerLastName : '-') +
                '<br/><b>' + this.translateService.instant('DASHBOARD.WIDGET.FORM.FOLDER_INDEX.CUSTOMERFIRSTNAME') + ': </b>' + (!!chartData.customerFirstName ? chartData.customerFirstName : '-') +
                '<br/><b>' + this.translateService.instant('DASHBOARD.WIDGET.FORM.FOLDER_INDEX.CUSTOMERMOBILEPHONE') + ': </b>' + (!!chartData.customerMobilePhone ? chartData.customerMobilePhone : '-') +
                '<br/><b>' + this.translateService.instant('DASHBOARD.WIDGET.FORM.FOLDER_INDEX.INCREMENTALNUMBER') + ': </b> <a href="/folder/' + chartData.folderId + '">' + chartData.incrementalReference + '</a>';

            var m = L.marker([Number.parseFloat(locations[0]), Number.parseFloat(locations[1])], {icon: icon})
                .bindPopup(popup);
            data.push(m);
        });
        return data;
    }

    update(): void {
        if (!!this.columnCount) {
            this.updatedColumnCount.emit(this.columnCount);
        }
    }


    deleteWidget(): void {
        this.dropWidget.emit();
    }

    editWidget(): void {
       this.router.navigate(['dashboard/widget/update/' + this.id]);
    }
}
