import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FilterFolderListService {
    nodeTreeSelected = new ReplaySubject<string[]>(1);

    constructor() {
        this.initialize();
    }

    initialize(): void {
        this.nodeTreeSelected.next([]);
    }

    public getNodeTreeSelected(): Observable<string[]> {
        return this.nodeTreeSelected.asObservable();
    }
}
