import {Component, Input} from '@angular/core';

@Component({
    selector: 'card-element',
    templateUrl: './card-element.component.html',
    styleUrls: ['./card-element.component.scss']
})
export class CardElementComponent {

    @Input() title: string;
    @Input() value: string | number;
    @Input() isLoading = false;
}
