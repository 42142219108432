import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'app-folder-not-found',
    templateUrl: './folder-not-found.component.html',
    styleUrls: ['./folder-not-found.component.scss']
})
export class FolderNotFoundComponent implements OnInit {

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    gotToFolderList() {
        this.router.navigate(['folder', 'list']);
    }
}
