import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Address, Customer} from '../../../../models/customer/customer.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {PublicService} from '../../../../shared/services/public.service';
import {ActivatedRoute} from '@angular/router';
import {AddressType} from '../../../../models/enums/addressType.enum';
import {FolderPublic} from '../../../../models/folder.public.model';
import {QuotationLine} from '../../../../models/quotation.model';

@Component({
    selector: 'app-supplier-order-tracking',
    templateUrl: './spare-parts-order-waiting-for-validation.component.html',
    styleUrls: ['./spare-parts-order-waiting-for-validation.component.css']
})
export class SparePartsOrderWaitingForValidationComponent implements OnInit {

    @Input() folder: FolderPublic;
    @Output() inputMap = new EventEmitter<any>();

    customer: Customer;
    customerSparePartsShipmentAddress: Address;
    computeTotalPrice = 0;
    discountQuotations = 0;
    reportLineDataTable = new MatTableDataSource<QuotationLine>();
    reportLineColumns: string[] = ['type', 'code', 'label', 'payer', 'quantity', 'price', 'tva', 'discount', 'totalLinePrice', 'discountReason'];
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

    uidFolder: string;
    requestedServiceId: string;
    currency: string;
    constructor(private publicService: PublicService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.currency = this.folder.currency;
        if (this.activatedRoute.snapshot.params.uid) {
            this.uidFolder = this.activatedRoute.snapshot.params.uid;
            this.requestedServiceId = this.activatedRoute.snapshot.params.requestedServiceId;
            this.getSupplierFolder();
        }
    }

    private getSupplierFolder(): void {
        this.publicService.getSparePartsOrderSupplier(this.uidFolder, this.requestedServiceId).subscribe(folderSupplierPublic => {
            if (!!folderSupplierPublic) {
                if (!!folderSupplierPublic.services && folderSupplierPublic.services.length > 0) {
                    const quotation = folderSupplierPublic.services[0].quotation;
                    if (!!quotation && quotation.quotationLines.length > 0) {
                        this.reportLineDataTable.data = quotation.quotationLines;
                        this.computeTotalPrice = quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) => total + line.totalLinePrice.value, 0);
                    }
                }
                if (!!folderSupplierPublic.customer) {
                    this.customer = folderSupplierPublic.customer;
                    this.customerSparePartsShipmentAddress = this.customer.addresses.find(value => value.type === AddressType.SPARE_PARTS_SHIPMENT_RECEIVER);
                }
            }
        });
    }

    getTotalPriceHT(): number {
        return this.reportLineDataTable.data.filter(quotationLine => quotationLine).reduce((total, line) => total + line.priceAmount.value * line.quantity, 0);
    }

    getTotalDiscount(): number {
        const totalPriceHT = this.reportLineDataTable.data.filter(quotationLine => quotationLine).reduce((total, line) => total + line.priceAmount.value * line.quantity, 0);
        return totalPriceHT - this.getTotalNetHT();
    }

    getTotalNetHT(): number {
        let totalNetHT;
        totalNetHT = this.reportLineDataTable.data.filter(quotationLine => quotationLine).reduce((total, line) => line.discount > 0 ? total + (line.priceAmount.value * line.quantity - ((line.priceAmount.value * line.quantity) * (line.discount / 100))) : total + (line.priceAmount.value * line.quantity), 0);
        if (this.discountQuotations > 0) {
            return totalNetHT - totalNetHT * (this.discountQuotations / 100);
        }
        return totalNetHT;
    }

    getTotalPriceTVA(): number {
        return this.reportLineDataTable.data.filter(quotationLine => quotationLine).reduce((total, line) => {
            const totalHT = line.priceAmount.value * line.quantity;
            const discount = line.discount / 100;
            const vatRate = Number(line.vat.rate);
            if (vatRate > 0) {
                const totalNetHT = totalHT - totalHT * discount;
                return total + (totalNetHT + totalNetHT * (vatRate / 100)) - totalNetHT;
            }
            return total;
        }, 0);
    }

    onSubmit(): void {
        this.inputMap.emit({});
    }


}
