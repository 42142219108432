<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="text-bold">
        {{'FOLDER.PENDING.REASON' | translate}}
    </h2>
    <button mat-icon-button class="ml-40" (click)="closeDialog()" type="reset">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div>
    <form (ngSubmit)="onSubmit()" [formGroup]="suspendingFolderReasonForm.form" fxLayout="column" fxLayoutAlign="start">
        <mat-card-content fxLayout="column">
            <mat-radio-group class="ml-20" formControlName="reason" fxLayout="column"
                             fxLayoutAlign="start stretch" fxLayoutGap="10px"
                             labelPosition="after">
                <mat-radio-button *ngFor="let reason of suspendingFolderReasonForm.placeholders" [value]="reason.code"
                                  class="full-width-radio">
                    <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div fxFlex>
                            <div>{{ reason.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>

            <mat-form-field appearance="outline" class="ml-20 mt-24" fxFlex="100">
                <mat-label>
                    {{'FOLDER.COMMENT'|translate}}
                </mat-label>
                <textarea formControlName="comment" matInput placeholder="{{'FOLDER.COMMENT'|translate}}"></textarea>
            </mat-form-field>
        </mat-card-content>

            <mat-dialog-actions fxLayoutAlign="end end">

                <button (click)="closeDialog()" class="aster_btn_cancel" mat-stroked-button
                        type="reset">
                    {{'BUTTON.CANCEL' | translate}}
                </button>
                <button [disabled]="suspendingFolderReasonForm.form.invalid"
                        [ngClass]="suspendingFolderReasonForm.form.invalid?'aster_btn_disabled':'aster_btn'"
                        mat-stroked-button
                        type="submit">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </mat-dialog-actions>

    </form>
</div>