import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../models/folder.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {StartLoading} from '../../../../../store/loader/loader.actions';
import {TaskVariables} from '../../task.variables';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

export const BREAKDOWN_CONFIRMED = 'BREAKDOWN_CONFIRMED';
export const NO_BREAKDOWN = 'NO_BREAKDOWN';

@Component({
    selector: 'app-prolonged-diagnosis',
    templateUrl: './prolonged-diagnosis.component.html',
    styleUrls: ['./prolonged-diagnosis.component.scss']
})
export class ProlongedDiagnosisComponent implements OnInit {

    @Output() commentGiven = new EventEmitter<any>();
    @Input() instructionUserTask: InstructionUserTask;
    @Input() folder: Folder;
    @Input() inputMap = new EventEmitter<any>();

    options: string[] = [BREAKDOWN_CONFIRMED, NO_BREAKDOWN];
    form: FormGroup;

    constructor(private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.form = new FormGroup({
            decision: new FormControl(null, [Validators.required]),
            comment: new FormControl(null)
        });
    }

    private hasCommentValue(): boolean {
        return this.form.get('comment').value?.trim() !== '';
    }

    onSubmit(): void {
        this.store$.dispatch(new StartLoading());
        if (this.hasCommentValue()) {
            this.commentGiven.emit({
                    value: this.form.value.comment,
                }
            );
        }

        this.inputMap.emit({
            [TaskVariables.DIAGNOSTIC_DECISION]: this.form.value.decision
        });
    }

}
