    <app-workflow-status-progress-bar [taskTimeoutDuration]="nbreDays"
                                          [currentStatusDate]="getStatusDate()">
    </app-workflow-status-progress-bar>
    <div fxLayout="column" fxLayoutAlign="start">
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
    </div>
    <mat-card-content>
        <form [formGroup]="form" fxLayout="column"
              fxLayoutAlign="start" (ngSubmit)="onSubmit()" class="mt-25">
            <mat-radio-group formControlName="status" class="ml-20"
                             fxLayout="row" fxLayoutAlign="space-between center"
                             labelPosition="after" fxLayout="column"
                             fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button class="full-width-radio"
                                  *ngFor="let choice of choices"
                                  [value]="choice.status"
                                  id="{{choice.status}}">
                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                        <div fxFlex>
                            <div>{{ choice.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="form.value.status ==='APPOINTMENT_RESCHEDULE' && showCalender">
                <mat-form-field appearance="outline" class="pr-sm-8"  [style.width.px]=327>
                    <mat-label>{{'FOLDER.SCHEDULE.DAY_OF_INTERVENTION' | translate}}</mat-label>
                    <input matInput [matDatepicker]="confirmedDay" [min]="minDate"
                           formControlName="confirmedAppointment">
                    <mat-datepicker-toggle matSuffix
                                           [for]="confirmedDay"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #confirmedDay></mat-datepicker>
                </mat-form-field>
            </ng-container>
            <app-comment-area
                *ngIf="form.value.status !== 'REPAIR_DECISION_CANCEL'"
                [commentForm]="form.get('comment')"></app-comment-area>


            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        [ngClass]="form.invalid ? 'aster_btn_disabled' : 'aster_btn'"
                        [disabled]="form.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>