import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../../../shared/services/notification.service';
import {Notification} from '../../../../models/notification.model';
import {NotificationType} from '../../../../models/enums/NotificationType.enum';
import {currentUser} from '../../../../store/user/user.selectors';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {UserState} from '../../../../store/user/user.state';
import {Unsubscriber} from '../../../../unsubscriber';

@Component({
    selector: 'detail-remote-assistance-dialog',
    templateUrl: './detail-remote-assistance-dialog.html',
    styleUrls: ['./detail-remote-assistance-dialog.component.scss']
})
export class DetailRemoteAssistanceDialogComponent extends Unsubscriber implements OnInit {
    remoteAssistanceObjectForm = {
        form: null,
        showSubmitLoader: false
    };
    notificationsType = [NotificationType.CALL_IN, NotificationType.CALL_OUT, NotificationType.REMOTE_ASSISTANCE];
    currentUser: UserState;

    constructor(private dialogRef: MatDialogRef<DetailRemoteAssistanceDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public FolderId: string,
                private store$: Store<AppState>,
                private notificationService: NotificationService) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(currentUser)).subscribe(currentUser => {
            this.currentUser = currentUser;
            this.initRemoteAssistanceObjectForm();
        });
    }

    initRemoteAssistanceObjectForm(): void {
        this.remoteAssistanceObjectForm.form = new FormGroup({
            type: new FormControl(null, [Validators.required]),
            comment: new FormControl(null, [Validators.required]),
            duration: new FormControl(null, [Validators.required]),
        });
    }

    onSubmit(): void {
        const notification: Notification = {
            type: this.remoteAssistanceObjectForm.form.value.type,
            organizationCode: this.currentUser.organizationCode,
            businessId: this.FolderId,
            context: this.currentUser.context,
            businessLink: this.currentUser.businessLink,
            content: new Map<string, any>()

        };
        notification.content['duration'] = this.remoteAssistanceObjectForm.form.value.duration;
        notification.content['comment'] = this.remoteAssistanceObjectForm.form.value.comment;

        this.anotherSubscription = this.notificationService.saveNotificationCall([notification]).subscribe(res => {
            if (!!res && res.length > 0) {
                this.onCloseAfterSuccessfulSubmit(res[0]);
            }
        });
    }

    onCloseAfterSuccessfulSubmit(data: any): void {
        this.dialogRef.close(data);
    }

    onClose(): void {
        this.dialogRef.close(null);
    }
}
