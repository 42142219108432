import {Action} from '@ngrx/store';
import {CountryConfiguration} from '../../models/country.model';
import {ClaimSensibleInfo} from '../../models/claim-sensible/claim-sensible-api-info.model';

export enum OrganizationActionType {
    SET_OPERATION_MODE = '[ORGANIZATION] Set Operation Mode',
    OPERATION_MODE_SET_SUCCESSFULLY = '[ORGANIZATION] Operation Mode was set successfully',
    RESET_OPERATION_MODE = '[ORGANIZATION] Reset Operation Mode',
    SET_COUNTRY_CONFIGURATION = '[ORGANIZATION] Set Country Configuration',
    COUNTRY_CONFIGURATION_SET_SUCCESSFULLY = '[ORGANIZATION] Country Configuration was set successfully',
    SET_TEMPLATE_ID = '[ORGANIZATION] Set Template ID',
    SET_ENABLE_STOCK_CLAIM_STATUS = '[ORGANIZATION] set Enable Stock Claim Status',
    ENABLE_CLAIM_CREATION_WITHOUT_CUSTOMER = '[ORGANIZATION] Enable Claim creation without customer',
    SET_MARGIN = '[ORGANIZATION] Set margin',
    SET_DASHBOARD_SERVER_INFO = '[ORGANIZATION] Set Dashboard server info',
    INITIALIZE_LANGUAGE = '[ORGANIZATION] Initialize Language',
    UPDATE_LANGUAGE = '[ORGANIZATION] Update Language',
    LOAD_CLAIM_SENSIBLE_INFO = '[ORGANIZATION] Load Claim Sensible Info',
    SUCCESS_LOAD_CLAIM_SENSIBLE_INFO = '[ORGANIZATION] Success Load Claim Sensible Info'
}

export class OperationModeSetSuccessfully implements Action {
    readonly type = OrganizationActionType.OPERATION_MODE_SET_SUCCESSFULLY;

    constructor(public operationMode: string) {
    }
}

export class ResetOperationMode implements Action {
    readonly type = OrganizationActionType.RESET_OPERATION_MODE;
}

export class SetCountryConfiguration implements Action {
    readonly type = OrganizationActionType.SET_COUNTRY_CONFIGURATION;

    constructor(public countryCode: string) {
    }
}

export class CountryConfigurationSetSuccessfully implements Action {
    readonly type = OrganizationActionType.COUNTRY_CONFIGURATION_SET_SUCCESSFULLY;

    constructor(public countryConfiguration: CountryConfiguration) {
    }
}

export class SetTemplateId implements Action {
    readonly type = OrganizationActionType.SET_TEMPLATE_ID;

    constructor(public templateId: string) {
    }
}
export class SetEnableStockClaimStatus implements Action {
    readonly type = OrganizationActionType.SET_ENABLE_STOCK_CLAIM_STATUS;

    constructor(public enableStockClaim: boolean) {
    }
}

export class ClaimWithoutCustomer implements Action {
    readonly type = OrganizationActionType.ENABLE_CLAIM_CREATION_WITHOUT_CUSTOMER;

    constructor(public claimWithoutCustomer: boolean) {
    }
}

export class SetMargin implements Action {
    readonly type = OrganizationActionType.SET_MARGIN;

    constructor(public margin: number) {
    }
}

export class SetDashboardServerInfo implements Action {
    readonly type = OrganizationActionType.SET_DASHBOARD_SERVER_INFO;

    constructor(public dashboardDataSourceId: string, public dashboardSiteId: string ) {
    }
}
export class InitializeLanguage implements Action {
    readonly type = OrganizationActionType.INITIALIZE_LANGUAGE;
}

export class UpdateLanguage implements Action {
    readonly type = OrganizationActionType.UPDATE_LANGUAGE;

    constructor(public language: string) {
    }
}
export class LoadClaimSensibleInfo implements Action {
    readonly type = OrganizationActionType.LOAD_CLAIM_SENSIBLE_INFO;
}

export class SuccessLoadClaimSensibleInfo implements Action {
    readonly type = OrganizationActionType.SUCCESS_LOAD_CLAIM_SENSIBLE_INFO;

    constructor(public claimSensibleAPIInfo: ClaimSensibleInfo) {
    }
}

export type OrganizationActions =
    ResetOperationMode | OperationModeSetSuccessfully |
    SetCountryConfiguration | CountryConfigurationSetSuccessfully |
    SetTemplateId |SetEnableStockClaimStatus | SetMargin | ClaimWithoutCustomer | InitializeLanguage | UpdateLanguage
    | LoadClaimSensibleInfo | SuccessLoadClaimSensibleInfo | SetDashboardServerInfo ;
