import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-site-snapshot-card',
  templateUrl: './site-snapshot-card.component.html',
  styleUrls: ['./site-snapshot-card.component.scss']
})
export class SiteSnapshotCardComponent implements OnInit {
    @Input() site: any;

  constructor() { }

  ngOnInit(): void {
  }

}
