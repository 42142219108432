import {Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-customer-product-retrieval-decision',
    templateUrl: './customer-product-retrieval-decision.component.html',
    styleUrls: ['./customer-product-retrieval-decision.component.scss']
})
export class CustomerProductRetrievalDecisionComponent implements OnInit {

    @Output() retrieveProduct = new EventEmitter<boolean>();
    @Output() choiceDone = new EventEmitter<boolean>();
    notRepairedProductRetrieval: any = {
        placeholders: []
    };

    constructor() {
    }

    selectionChanged($event): void {
        this.retrieveProduct.emit($event.value);
        this.choiceDone.emit(true);
    }

    ngOnInit(): void {
        this.notRepairedProductRetrieval.form = new FormGroup({
            productRetrieval: new FormControl(null, Validators.required),
        });
        this.notRepairedProductRetrieval.placeholders = [
            {
                choice: true,
                value: 'NOT_REPAIRED_PRODUCT_RETRIEVAL.ACCEPT',
            },
            {
                choice: false,
                value: 'NOT_REPAIRED_PRODUCT_RETRIEVAL.REFUSED',
            }
        ];
    }
}
