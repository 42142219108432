<app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>
<mat-card-content class="mt-25">

    <app-choose-carrier [folder]="folder"
                        (carrierCode)="changeCarrierCode($event)"
                        (carrierExtraDataEvent)="changeCarrierExtraEvent($event)"
                        (selectedTransportModeEvent)="changeTransportModeEvent($event)"
    ></app-choose-carrier>
    <div class="h3 mt-24 mb-12"><strong>{{'LIST.REPAIRERS' | translate}}</strong></div>
    <div class="mat-elevation-z4 w-100-p mt-12 mb-16">
        <ejs-grid #grid [dataSource]="listRepairers"
                  [allowPaging]="true"
                  [pageSettings]="{pageSize: 5}"
                  [filterSettings]="{type: 'CheckBox'}"
                  [selectionSettings]="{type: 'Multiple', checkboxOnly: true }"
        >
            <e-columns>
                <e-column
                    field="code"
                    [headerText]="'TABLE.CODE' | translate">
                    <ng-template #template let-data>
                        <span>{{data.code}}</span>
                    </ng-template>
                </e-column>
                <e-column
                    field="label"
                    [headerText]="'TABLE.LABEL' | translate">
                    <ng-template #template let-data>
                        <span>{{data.label}}</span>
                    </ng-template>
                </e-column>
                <e-column
                    field="distance"
                    [headerText]="'COMPONENT.CONFIRM_CARRIER_AND_REPAIRER.REPAIRER_DISTANCE' | translate">
                    <ng-template #template let-data>
                        <ng-container *ngIf="data.completeAddress?.location?.distance; else updateAddress">
                            <span>{{ data.completeAddress?.location?.distance }}</span>
                        </ng-container>
                        <ng-template #updateAddress>
                                    <span class="material-symbols-outlined refresh-icon"
                                          (click)="updateAddressRepairer(data)">
                                        Refresh
                                    </span>
                        </ng-template>
                    </ng-template>
                </e-column>
                <e-column field='localRepaires'
                          [headerText]="'BUTTON.TYPE_REPAIRER' | translate">
                    <ng-template #template let-data>
                        <ejs-chiplist>
                            <e-chips>
                                <e-chip [text]="isLocalRepairer(data.code) ? ('LOCAL_TYPE_REPAIRER' | translate) : ('NATIONAL_TYPE_REPAIRER' | translate)"
                                        [cssClass]="isLocalRepairer(data.code) ? 'e-success' : 'e-default'"></e-chip>
                            </e-chips>
                        </ejs-chiplist>
                    </ng-template>
                </e-column>
                <e-column
                    field="selection"
                    [headerText]="'BUTTON.SELECTION' | translate">
                    <ng-template #template let-data>
                        <mat-radio-group [(ngModel)]="selectedRepairer" fxLayout="row" fxLayoutGap="30px"
                                         fxLayoutAlign="space-between center">
                            <mat-radio-button [id]="REPAIR + data.code" [value]="data"></mat-radio-button>
                        </mat-radio-group>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>

    </div>
    <div fxLayoutAlign="end center">
        <button (click)="onSubmit()" mat-stroked-button
                class="py-8 px-24 font-weight-900 font-size-30"
                [ngClass]="isFormInvalid() ? 'aster_btn_disabled':'aster_btn'"
                [disabled]="isFormInvalid() || isLoading">
                <span class="button-content">
                    {{'BUTTON.VALIDATE' | translate}}
                    <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
                </span>
        </button>
    </div>

</mat-card-content>