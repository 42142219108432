import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GLOBAL} from '../../app-config';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class RepairCenterService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api/integration`;

    constructor(private http: HttpClient) {

    }

    sendAsychroneToRepairCenter(folderId: string): Observable<any> {
        return this.http.post(`${this.path}/repairCenter/resendToRepairer/${folderId}`, null);
    }

}
