export enum WarrantyReasonEnum {
    // SG
    ANY = 'any',
    PRODUCT_RECALL = 'PRODUCT_RECALL',
    SG_IN_MANUFACTURER_WARRANTY_PERIOD = 'SG_IN_MANUFACTURER_WARRANTY_PERIOD', //    En période de garantie constructeur,
    SG_IN_WARRANTY_EXTENSION_PERIOD = 'SG_IN_WARRANTY_EXTENSION_PERIOD', //    En période d’extension de garantie
    SG_COVERED_BY_MONEY_BACK_GUARANTEE = 'SG_COVERED_BY_MONEY_BACK_GUARANTEE', //    Il est couvert par une garantie remboursement
    SG_COMMERCIAL_GESTURE = 'SG_COMMERCIAL_GESTURE', //    Il a eu un geste commerciale (l’agent en magasin décide de lui passer en sous garantie)
    SG_IMMOBILIZATION_IN_WARRANTY_EXTENSION_PERIOD = 'SG_IMMOBILIZATION_IN_WARRANTY_EXTENSION_PERIOD',//    En période d’extension de garantie par immobilization
    SG_IMMOBILIZATION_COVERED_BY_INSURANCE = 'SG_IMMOBILIZATION_COVERED_BY_INSURANCE',//    Il est couvert par une garantie remboursement par immobilization
    SG_IN_AFTER_SALES_WARRANTY_PERIOD='SG_IN_AFTER_SALES_WARRANTY_PERIOD',
    LEGAL_WARRANTY='LEGAL_WARRANTY',
    // HG
    HG_WARRANTY_PERIOD_EXCEEDED = 'HG_WARRANTY_PERIOD_EXCEEDED', //    Durée de garantie dépassée
    HG_CUSTOMER_DEFAULT = 'HG_CUSTOMER_DEFAULT',//    Défaut client
    SWITCH_HG_WARRANTY_BY_SURVEY = 'SWITCH_HG_WARRANTY_BY_SURVEY',//    passer en HG par invoice
}
