import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ConfigurationActionType, LoadConfiguration, SuccessLoadConfiguration} from './configuration.actions';
import {catchError, concatMap, skipWhile, switchMap, withLatestFrom} from 'rxjs/operators';
import {ConfigurationService} from '../../shared/services/store/configuration.service';
import {StartLoading, StopLoading} from '../loader/loader.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {concat, of} from 'rxjs';
import {userContextNotReady} from '../user/user.selectors';

@Injectable()
export class ConfigurationEffects {

    constructor(private actions$: Actions,
                private store$: Store<AppState>,
                private configurationService: ConfigurationService) {
    }

    @Effect()
    loadConfiguration$ = this.actions$.pipe(
        ofType(ConfigurationActionType.LOAD_CONFIGURATION),
        withLatestFrom(this.store$.select(userContextNotReady)),
        skipWhile(([, userContextNotReady]) => userContextNotReady),
        concatMap(([action,]: [LoadConfiguration, boolean]) => concat(
            of(new StartLoading()),
            this.configurationService.load(action.cached)
                .pipe(
                    switchMap(configReferential => of(new SuccessLoadConfiguration(configReferential.configurationMap))),
                    catchError(() => of(new StopLoading()))
                ),
            of(new StopLoading())
        ))
    );
}
