    <mat-card-content>
        <form [formGroup]="reparationAgreementDelayForm"
              fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px"
              (ngSubmit)="onSubmit()">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>

            <mat-radio-group fxLayout="column" fxLayoutGap="20px"
                             formControlName="choice"
                             (change)="onChoiceChange($event.value)">
                <mat-radio-button [value]="false" [id]="'response_received_from_supplier'">
                    {{'COMPONENT.REPARATION_AGREEMENT_DELAY.RESPONSE_RECEIVED_FROM_SUPPLIER'|translate}}
                </mat-radio-button>
                <mat-radio-button [value]="true" [id]="'accept_time_limit'">
                    {{'COMPONENT.REPARATION_AGREEMENT_DELAY.ACCEPT_TIME_LIMIT'|translate}}
                </mat-radio-button>
            </mat-radio-group>

            <ng-container *ngIf="displayComment()">
                <mat-form-field appearance="outline" class="w-100-p">
                    <mat-label>{{'MODAL.COMMENT.TITLE_ADD_COMMENT'|translate}}</mat-label>
                    <textarea matInput rows="4" cols="7" [id]="'comment'"
                              [placeholder]="'MODAL.COMMENT.TITLE_ADD_COMMENT'|translate"
                              formControlName="comment"></textarea>
                </mat-form-field>
            </ng-container>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        [ngClass]="reparationAgreementDelayForm.invalid ?'aster_btn_disabled':'aster_btn'"
                        [disabled]="reparationAgreementDelayForm.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>

        </form>
    </mat-card-content>