import {NgModule} from '@angular/core';
import {DashboardFolderComponent} from './dashboard-folder/dashboard-folder.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ChartsModule} from 'ng2-charts';
import {FuseThemeOptionsModule} from '../../../@fuse/components';
import {WidgetPieChartComponent} from './dashboard-folder/widgets/pie-chart/widget-pie-chart.component';
import {BarChartHorizontalComponent} from './dashboard-folder/widgets/bar-chart-horizontal/bar-chart-horizontal.component';
import {ListTableComponent} from './dashboard-folder/widgets/list-table/list-table.component';
import {PriceCountComponent} from './dashboard-folder/widgets/price-count/price-count.component';
import {FolderListComponentModal} from './folder-list-modal/folder-list.component-modal';
import {MdePopoverModule} from '@material-extended/mde';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {LeafletMarkerClusterModule} from '@asymmetrik/ngx-leaflet-markercluster';
import {LeafletMarkerclusterComponent} from './dashboard-folder/widgets/leaflet-markercluster/leaflet-markercluster.component';
import {AdvancedPieChartComponent} from './dashboard-folder/widgets/advanced-pie-chart/advanced-pie-chart.component';
import {PieChartGridComponent} from './dashboard-folder/widgets/pie-chart-grid/pie-chart-grid.component';
import {CountUpModule} from 'ngx-countup';
import {WidgetListComponent} from './dashboard-folder/config/dashboard/widget/widget-list/widget-list.component';
import {UpdateWidgetComponent} from './dashboard-folder/config/dashboard/widget/update-widget/update-widget.component';
import {DashboardListComponent} from './dashboard-folder/config/dashboard/dashboard-list/dashboard-list.component';
import {DashboardConfigComponent} from './dashboard-folder/config/dashboard/dashboard-config/dashboard-config.component';
import {AddChartsConfigDialogComponent} from './dashboard-folder/config/dashboard/add-charts-config-dialog/add-charts-config-dialog.component';
import {DashboardRouting} from './dashboard.routing';
import {DashBoardDayComponent} from './itinerant/dash-board-day/dash-board-day.component';
import {TechnicianInterventionMapComponent} from './itinerant/dash-board-map/technician-intervention-map.component';
import {PlanningComponent} from './itinerant/planning/planning.component';
import {CalendarModule as AngularCalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {ItemClientInfosComponent} from './itinerant/dash-board-day/item-client-infos/item-client-infos.component';
import {ReOptimizeConfirmDialogComponent} from './itinerant/dash-board-day/reoptimize-confirm-dialog/reoptimize-confirm-dialog.component';
import {DashboardHomePageComponent} from './dashboard-v2/dashboard-home-page.component';
import {CreateDashboardComponent} from './dashboard-v2/crud/create-dashboard.component';
import {ConfigDashboardComponent} from './dashboard-v2/config/config-dashboard.component';
import {EditDashboardComponent} from './dashboard-v2/crud/edit-dashboard.component';
import {ViewDashboardComponent} from './dashboard-v2/crud/view-dashboard.component';


@NgModule({
    declarations: [
        DashboardFolderComponent,
        DashboardHomePageComponent,
        CreateDashboardComponent,
        EditDashboardComponent,
        ViewDashboardComponent,
        ConfigDashboardComponent,
        WidgetPieChartComponent,
        BarChartHorizontalComponent,
        ListTableComponent,
        PriceCountComponent,
        FolderListComponentModal,
        LeafletMarkerclusterComponent,
        AdvancedPieChartComponent,
        PieChartGridComponent,
        WidgetListComponent,
        UpdateWidgetComponent,
        DashboardListComponent,
        DashboardConfigComponent,
        AddChartsConfigDialogComponent,
        DashBoardDayComponent,
        ItemClientInfosComponent,
        TechnicianInterventionMapComponent,
        PlanningComponent,
        ReOptimizeConfirmDialogComponent
    ],
    imports: [
        RouterModule.forChild(DashboardRouting),

        TranslateModule,
        LeafletModule,
        LeafletMarkerClusterModule,
        FuseSharedModule,
        FuseThemeOptionsModule,
        CountUpModule,

        NgxChartsModule,
        ChartsModule,
        MdePopoverModule,
        AngularCalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
    ], exports: [
        CountUpModule,
        TranslateModule,
        WidgetPieChartComponent,
        BarChartHorizontalComponent,
        ListTableComponent,
        PriceCountComponent,
        PieChartGridComponent,
        AdvancedPieChartComponent,
        LeafletMarkerclusterComponent
    ]
})
export class DashboardModule {
}
