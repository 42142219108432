<div fxLayout="row" fxLayout.lt-lg="row" fxLayout.lt-md="column" fxLayoutGap="8px" fusePerfectScrollbar>
    <div fxFlex="50">
        <app-public-detail-tabs-card [folder]="folder"></app-public-detail-tabs-card>
    </div>
    <div fxFlex="50">
        <mat-card class="mat-elevation-aster">
            <mat-card-title class="product-title text-bold pr-24 pt-20 pl-10">
                {{'MODAL.QUOTATION.ADD_QUOTATION'|translate}}
            </mat-card-title>
            <mat-card-content>

                <form [formGroup]="waitQuotationForm.form" fxLayout="row" *ngIf="waitQuotationForm.placeholders"
                      fxLayoutAlign="start">
                    <mat-form-field class="py-4" appearance="outline" fxFlex="40" fxFlex.lt-md="100">
                        <mat-label>{{'FOLDER.QUOTATION.CODE'|translate}}</mat-label>
                        <input matInput placeholder="{{'FOLDER.QUOTATION.CODE'|translate}}"
                               formControlName="quotationCode" required>
                    </mat-form-field>
                </form>

                <form *ngIf="quotationForm.form" [formGroup]="quotationForm.form" #ngFormQuotation="ngForm"
                      (ngSubmit)="addIntoQuotationLines()" autocomplete="off">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                        <div fxFlex="20" fxFlex.lt-md="100">
                            <mat-form-field appearance="outline" class="w-100-p">
                                <mat-label>{{'MODAL.QUOTATION.TYPE'|translate}}</mat-label>
                                <mat-select formControlName="type" [placeholder]="'MODAL.QUOTATION.TYPE'|translate"
                                            required>
                                    <mat-option *ngFor="let type of serviceTypes" [value]="type">
                                        <span> {{'MODAL.QUOTATION.TYPE.' + type | translate}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="20" fxFlex.lt-md="100">
                            <mat-form-field appearance="outline" class="w-100-p">
                                <mat-label>{{'MODAL.QUOTATION.CODE' | translate }}</mat-label>
                                <input matInput placeholder="{{'MODAL.QUOTATION.CODE' | translate }}"
                                       formControlName="code" required>
                            </mat-form-field>
                        </div>

                        <div fxFlex="60" fxFlex.lt-md="100">
                            <mat-form-field appearance="outline" class="w-100-p">
                                <mat-label>{{'MODAL.QUOTATION.LABEL'|translate}}</mat-label>
                                <input matInput [placeholder]="'MODAL.QUOTATION.LABEL'|translate"
                                       formControlName="label" type="text" required>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="8px">
                        <div fxFlex="20" fxFlex.lt-md="100">
                            <mat-form-field appearance="outline" class="w-100-p">
                                <mat-label>{{'MODAL.QUOTATION.QUANTITY'|translate}}</mat-label>
                                <input matInput [placeholder]="'MODAL.QUOTATION.QUANTITY'|translate"
                                       formControlName="quantity"
                                       min="1" numberOnly type="number" required (blur)="calculationQuotationLine()">
                            </mat-form-field>
                        </div>
                        <div fxFlex="20" fxFlex.lt-md="100">
                            <mat-form-field appearance="outline" class="w-100-p">
                                <mat-label>{{'MODAL.QUOTATION.PRICE'|translate}}</mat-label>
                                <input matInput [placeholder]="'MODAL.QUOTATION.PRICE'|translate"
                                       formControlName="price" (blur)="calculationQuotationLine()" min="0" required>
                            </mat-form-field>
                        </div>
                        <div fxFlex="20" fxFlex.lt-md="100">
                            <mat-form-field appearance="outline" class="w-100-p">
                                <mat-label> {{'MODAL.QUOTATION.TVA'|translate}}</mat-label>
                                <mat-select formControlName="vat" required
                                            (selectionChange)="calculationQuotationLine()">
                                    <mat-option *ngFor="let vat of vatsList$ | async" [value]="vat.rate">
                                        {{ vat.rate }} %
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="20" fxFlex.lt-md="100">
                            <mat-form-field appearance="outline" class="w-100-p">
                                <mat-label>{{'MODAL.QUOTATION.DISCOUNT'|translate}}</mat-label>
                                <input matInput [placeholder]="'MODAL.QUOTATION.DISCOUNT'|translate"
                                       formControlName="discount"
                                       min="0" numberOnly type="number" (blur)="calculationQuotationLine()">
                                <mat-icon class="s-16" matSuffix>%</mat-icon>
                            </mat-form-field>
                        </div>
                        <div fxFlex="20" fxFlex.lt-md="100">
                            <mat-form-field appearance="outline" class="w-100-p">
                                <mat-label>{{'MODAL.QUOTATION.TOTAL_PRICE'|translate}}</mat-label>
                                <input matInput [placeholder]="'MODAL.QUOTATION.TOTAL_PRICE'|translate" required
                                       [value]="quotationForm.form.get('totalLinePrice').value" disabled
                                       [readonly]="true">

                            </mat-form-field>
                        </div>
                    </div>
                    <ng-container *ngIf="this.quotationForm.form.value.discount>0">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                            <div fxFlex="100" fxFlex.lt-md="100">
                                <mat-form-field appearance="outline" class="w-100-p">
                                    <mat-label>{{'MODAL.QUOTATION.DISCOUNT_REASON'|translate}}</mat-label>
                                    <input matInput [placeholder]="'MODAL.QUOTATION.DISCOUNT_REASON' | translate"
                                           formControlName="discountReason" type="text">
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                    <div fxLayoutAlign="end center">
                        <div>
                            <button mat-stroked-button class="aster-btn_border"
                                    [disabled]="quotationForm.form.invalid"
                                    [ngClass]="quotationForm.form.invalid ? 'aster-btn_border-disabled' : 'aster-btn_border'">

                                {{'BUTTON.ADD' | translate}}
                            </button>
                        </div>
                    </div>
                </form>
                <div *ngIf="!!quotationLines">
                    <table mat-table [dataSource]="quotationLines" class="mat-elevation-z2 w-100-p mt-16 mb-16">
                        <ng-container matColumnDef="code">
                            <th mat-header-cell *matHeaderCellDef
                                class="text-center">  {{'MODAL.QUOTATION.CODE' | translate}}</th>
                            <td mat-cell *matCellDef="let quotationLine"> {{quotationLine.code}} </td>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef
                                class="text-center">  {{'MODAL.QUOTATION.TYPE' | translate}}</th>
                            <td mat-cell
                                *matCellDef="let quotationLine"> {{'MODAL.QUOTATION.TYPE.' + quotationLine.type | translate}} </td>
                        </ng-container>
                        <ng-container matColumnDef="label">
                            <th mat-header-cell *matHeaderCellDef
                                class="text-center">  {{'MODAL.QUOTATION.LABEL' | translate}}</th>
                            <td mat-cell *matCellDef="let quotationLine"> {{quotationLine.label}} </td>
                        </ng-container>
                        <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef
                                class="text-center">  {{'MODAL.QUOTATION.QUANTITY' | translate}}</th>
                            <td mat-cell *matCellDef="let quotationLine"> {{quotationLine.quantity}} </td>
                        </ng-container>
                        <ng-container matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef
                                class="text-center">  {{'MODAL.QUOTATION.PRICE' | translate}}</th>
                            <td mat-cell *matCellDef="let quotationLine">
                                {{normalize(quotationLine.price)   |currency:currency :'symbol-narrow' | space}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="vat">
                            <th mat-header-cell *matHeaderCellDef
                                class="text-center">  {{'MODAL.QUOTATION.TVA' | translate}}</th>
                            <td mat-cell *matCellDef="let quotationLine"> {{quotationLine.vat}}</td>
                        </ng-container>
                        <ng-container matColumnDef="discount">
                            <th mat-header-cell *matHeaderCellDef
                                class="text-center">  {{'MODAL.QUOTATION.DISCOUNT' | translate}}</th>
                            <td mat-cell *matCellDef="let quotationLine"> {{quotationLine.discount}} </td>
                        </ng-container>

                        <ng-container matColumnDef="totalPrice">
                            <th mat-header-cell *matHeaderCellDef
                                class="text-center"> {{'MODAL.QUOTATION.TOTAL_PRICE' | translate}}</th>
                            <td mat-cell *matCellDef="let quotationLine">
                                {{normalize(quotationLine.totalLinePrice)   |currency:currency :'symbol-narrow' | space}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="action" >
                            <th mat-header-cell *matHeaderCellDef
                                class="text-center">  {{'MODAL.QUOTATION.ACTION' | translate}}</th>
                            <td mat-cell *matCellDef="let quotationLine;let indexLine = index;">
                                <button matSuffix mat-icon-button color="warn"
                                        type="button" class="mx-8" (click)="deleteLine(indexLine)">
                                    <mat-icon>delete_forever</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>


                <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="12px">
                    <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="20" fxFlex.lt-md="100">
                        <ngx-dropzone
                            #dropzone
                            label="{{fileUploadForm.hasFile ? fileUploadForm.file: 'FOLDER.FILE.UPLOAD_MSG' | translate}}"
                            [showPreviews]="true" [multiple]="false" [accept]="'*'" (filesAdded)="onFilesAdded($event)">
                        </ngx-dropzone>
                    </mat-card>

                    <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="50" fxFlex.lt-md="100">
                        <table mat-table [dataSource]="attachmentQuotationDataTable" class="w-100-p mt-8">
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> {{'FOLDER.FILE.TITLE' | translate}}</th>
                                <td mat-cell *matCellDef="let element" class="text-left">
                                        <a (click)="downloadFile(element)" class="cursor-pointer">
                                            <p class="file_name">
                                                <mat-icon>file_copy</mat-icon>
                                                {{!!element.info ? element.info.name : element.name}}
                                            </p>
                                        </a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> {{'BUTTON.ACTION' | translate}}</th>
                                <td mat-cell
                                    *matCellDef="let element">
                                    <mat-icon color="warn" class="cursor-pointer"
                                              (click)="removeFile(element)">
                                        delete_forever
                                    </mat-icon>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsAttachment"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsAttachment;"></tr>
                        </table>
                    </mat-card>

                    <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="30" fxFlex.lt-md="100">
                        <div fxLayout="column" class="zone-total">
                            <div fxLayoutAlign="space-between center">
                                <strong>{{'MODAL.QUOTATION.TOTAL_HT' | translate}} : </strong>
                                <div>{{getTotalPriceHT()  |currency:currency :'symbol-narrow' | space}}
                                </div>
                            </div>
                            <div fxLayoutAlign="space-between center">
                                <strong>{{'MODAL.QUOTATION.TOTAL_DISCOUNT' | translate}} : </strong>
                                <div>{{getTotalDiscount()  |currency:currency :'symbol-narrow' | space}}
                                </div>
                            </div>
                            <div fxLayoutAlign="space-between center">
                                <strong>{{'MODAL.QUOTATION.TOTAL_NET_HT' | translate}}  : </strong>
                                <div>{{getTotalNetHT()  |currency:currency :'symbol-narrow' | space}}
                                </div>
                            </div>
                            <div fxLayoutAlign="space-between center">
                                <strong>{{'MODAL.QUOTATION.TOTAL_TVA' | translate}}  : </strong>
                                <div>
                                    {{getTotalPriceTVA()  |currency:currency :'symbol-narrow' | space}}
                                </div>
                            </div>
                            <div *ngIf="!!computeTotalPrice" fxLayoutAlign="space-between center">
                                <strong> {{'MODAL.QUOTATION.TOTAL_TTC' | translate}} : </strong>
                                <div>{{computeTotalPrice?.value |currency:computeTotalPrice?.currency :'symbol-narrow' | space}}
                                </div>
                            </div>
                        </div>

                    </mat-card>
                </div>
                <form [formGroup]="waitQuotationForm.form" fxLayout="row" *ngIf="waitQuotationForm.placeholders"
                      fxLayoutAlign="start">
                    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px">
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>{{'QUOTATION.FEES_REFUSED_QUOTATION_WITHOUT_RESTITUTION'|translate}}</mat-label>
                            <input matInput inputControl placeholder="{{'QUOTATION.FEES_REFUSED_QUOTATION_WITHOUT_RESTITUTION'|translate}}"
                                   min="0" appPositiveNumberValidator
                                   formControlName="feesRefusedQuotationWithoutRestitution">
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>{{'QUOTATION.FEES_REFUSED_QUOTATION_WITH_RESTITUTION'|translate}}</mat-label>
                            <input matInput inputControl placeholder="{{'QUOTATION.FEES_REFUSED_QUOTATION_WITH_RESTITUTION'|translate}}"
                                   min="0" appPositiveNumberValidator
                                   formControlName="feesRefusedQuotationWithRestitution">
                        </mat-form-field>
                    </div>
                </form>

                <div fxLayout="row" fxLayoutAlign="start center" class="mt-12">
                    <form [formGroup]="quotationDescriptionForm.form" class="w-100-p"
                          (ngSubmit)="validateQuotationChoiceForm()">
                        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px" >
                            <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                                <mat-label>{{'MODAL.COMMENT.DESCRIPTION' | translate}}</mat-label>
                                <textarea matInput rows="4" cols="7"
                                          [placeholder]="'MODAL.COMMENT.DESCRIPTION' | translate"
                                          formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end end">
                            <button type="submit" mat-stroked-button
                                    [disabled]=" quotationLines.data.length===0 || waitQuotationForm.form.invalid "
                                    [ngClass]="quotationLines.data.length===0 || waitQuotationForm.form.invalid ? 'aster_btn_disabled':'aster_btn'">

                                {{'BUTTON.VALIDATE' | translate}}
                            </button>
                        </div>
                    </form>
                </div>

            </mat-card-content>
        </mat-card>
    </div>
</div>