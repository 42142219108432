<div class="e-card" fxFlexFill>
    <div class="e-card-header">
        <div class="e-card-header-caption">
            <div class="e-card-title">{{'PRODUCT.DOCUMENTS' | translate}}</div>
        </div>
    </div>
    <div class="e-card-content scrollable-content" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" fxFlex>
        <ng-container *ngFor="let doc of documents">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8">
                <a [href]="doc.url" target="_blank" class="cursor-pointer" fxLayoutAlign="center center">
                    <mat-icon>file_copy</mat-icon>
                    <span class="file_name">{{ doc.name }}</span>
                </a>
            </div>
        </ng-container>
    </div>
</div>