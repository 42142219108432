    <mat-card-content>
        <div fxLayout="row" style="justify-content: space-between" >
            <div fxLayoutAlign="start space-between " fxLayoutGap="10px" class="mt-12">
                <button type="button"
                        mat-stroked-button color="accent" (click)="loadPreviousSuggest()"
                        [id]="'INTERVENTION_SELECTED'"
                        [disabled]="isPreviousDisabled()"
                        [ngClass]="isPreviousDisabled() ? 'aster_btn_disabled':'aster_btn'">
                    {{'BUTTON.BACK' | translate}}
                </button>
            </div>
            <div fxLayoutAlign="end space-between " fxLayoutGap="10px" class="mt-12"  style="margin-right: 15px">
                <button type="button"
                        mat-stroked-button color="accent" (click)="loadNextSuggest()"
                        [disabled]="isNextDisabled()"
                        [id]="'INTERVENTION_SELECTED'"
                        [ngClass]="isNextDisabled() ? 'aster_btn_disabled':'aster_btn'">
                    {{'BUTTON.NEXT' | translate}}
                </button>
            </div>
        </div>
        <div *ngIf="!!fullResponse && fullResponse.length >0; else EMPTY">
            <div style="display: flex; flex-wrap: wrap; grid-gap: 10px">
                <div *ngFor="let item of fullResponse" style="flex: 1 1 40%">
                    <div class="w-95-p mt-12 ml-10" (click)="selectDate(item)" style="cursor: pointer">
                        <mat-card class="mat-elevation-z2" [ngStyle]="{'background-color': item.selected ? '#e0e0e0':''}">
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <div class="mt-12" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2px">
                                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2px">
                                        <mat-icon svgIcon="calendar_rdv"></mat-icon>
                                        <strong
                                            class="capitalize">{{getInterventionPropositionDay(item) | moment: ('GENERAL.DATE_FORMAT_CONFIRMATION' | translate) : "FR-fr"}}</strong>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2px">
                                        <p>{{getInterventionPropositionTiming(item)}}</p>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #EMPTY>
          <span *ngIf="loadFinished" style="text-align: center">
                            <h2><strong class="capitalize">{{'NO_SCHEDULE_AVAILABLE'|translate}}</strong> </h2>
                      </span>
        </ng-template>
        <mat-chip-list style="margin: 12px">
            <div fxLayout="column">
                <ng-container *ngFor="let date of selectedAppointment">
                    <div>
                        <mat-chip>
                            <div class="date-selected">
                                {{date.date.toISOString() | moment: ('GENERAL.DATE_FORMAT_CONFIRMATION' | translate) : (currentLanguage$ | async)}}
                                ,  {{date.elementSelected.beginHour }}
                                <ng-container *ngIf="date.elementSelected.endHour">
                                    {{' - ' + date.elementSelected.endHour}}
                                </ng-container>
                            </div>
                            <mat-icon matChipRemove class="icon-remove_element"
                                      (click)="removeCheck(date.date)">
                                cancel
                            </mat-icon>
                        </mat-chip>
                    </div>
                </ng-container>
            </div>
        </mat-chip-list>
        <app-comment-area [commentForm]="commentControl"></app-comment-area>
    </mat-card-content>
    <mat-card-footer>
        <div fxLayoutAlign="end end" fxLayoutGap="10px" class="mt-12">
            <button type="button" mat-stroked-button color="accent"
                    [disabled]="selectedAppointment.length === 0"
                    appDebounceClick (debounceClick)="validateAppointment()"
                    [ngClass]="'mb-8 mr-8 font-weight-900 font-size-30 ' + (selectedAppointment.length === 0 ? 'aster_btn_disabled' : 'aster_btn')">
                {{'GENERAL.CALENDAR.VALIDATED_APPOINTMENT' | translate}}
            </button>
        </div>
    </mat-card-footer>