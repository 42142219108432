<div fxLayout="row" fxLayout.lt-lg="row" fxLayout.lt-md="column" fxLayoutGap="8px" fusePerfectScrollbar *ngIf="!!folder">
        <div fxFlex="50">
            <app-public-detail-tabs-card [folder]="folder"></app-public-detail-tabs-card>
        </div>
        <div fxFlex="50">
            <mat-card class="mat-elevation-aster">
        <mat-card-content>
            <div fxLayout="column" fxLayoutAlign="start center">
                <div>

                    <mat-icon class="shipping-icon">local_shipping</mat-icon>
                </div>
                <div>
                    <div class="h3 ml-8 secondary-text text-capitalized pt-4">
                        {{'COMPONENT.SHIPMENT_IN_PROGRESS' | translate}}
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start">
                <div>
                    <mat-checkbox [(ngModel)]="shipmentInProgress">{{'BUTTON.SHIPMENT_DELIVERED' | translate}}</mat-checkbox>
                </div>
            </div>

            <div class="mt-24">
                <div fxLayout="row" fxLayoutAlign="end end">
                    <div>
                        <button mat-stroked-button (click)="onSubmit()" type="submit" [disabled]="!shipmentInProgress"
                                [ngClass]="!shipmentInProgress?'aster_btn_disabled':'aster_btn'">
                            {{'BUTTON.SHIPMENT_DELIVERED' | translate}}
                        </button>
                    </div>
                </div>

            </div>
        </mat-card-content>
    </mat-card>
        </div>
</div>