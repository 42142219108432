<div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="column" fxLayoutAlign="start">
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
    </div>
</div>

<mat-card-content>
    <form [formGroup]="productAtCustomerForm.form" fxLayout="column" fxLayoutAlign="start"
              fxLayoutGap="8px"
              (ngSubmit)="completeTask()">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
                <mat-radio-group formControlName="productAtCustomer" class="ml-20" fxLayoutGap="10px"
                                 labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch">
                    <mat-radio-button class="full-width-radio"
                                      *ngFor="let quotationChoice of productAtCustomerForm.placeholders"
                                      [value]="quotationChoice.productAtCustomer">
                        <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                            <div fxFlex>
                                <div>{{ quotationChoice.value | translate }}</div>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxLayoutAlign="end center">
                <button mat-stroked-button
                        matTooltip="{{'BUTTON.VALIDATE' | translate}}" type="submit"
                        class="h-60 mt-4 pl-24 pr-24"
                        [disabled]="productAtCustomerForm.form.invalid"
                        [ngClass]="productAtCustomerForm.form.invalid?' aster_btn_disabled':' aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
</mat-card-content>