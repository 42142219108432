import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-not-repaired-product-retrieval2',
    templateUrl: './waiting-not-repaired-product-retrieval2.component.html',
    styleUrls: ['./waiting-not-repaired-product-retrieval2.component.scss']
})
export class WaitingNotRepairedProductRetrieval2Component {

    productRetrieved: boolean;
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    constructor() {
    }

    onSubmit(): void {
        if (!this.productRetrieved) {
            return;
        }
        this.inputMap.emit({});
    }
}
