
export enum ReparationStatusEnum {
    REPAIRABLE = 'REPAIRABLE',
    NOT_REPAIRABLE = 'NOT_REPAIRABLE',
    TO_BE_REPAIRED_EXTERNALLY = 'TO_BE_REPAIRED_EXTERNALLY',
    REPAIRED = 'REPAIRED',
    INTERNAL_REPAIR = 'INTERNAL_REPAIR',
    CLOSED_BY_DIAG_EXPRESS = 'CLOSED_BY_DIAG_EXPRESS',

}
