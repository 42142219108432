import {Component, Input} from '@angular/core';
import {Product} from '../../../../models/product.model';

@Component({
    selector: 'app-product-repairability',
    templateUrl: './product-repairability.component.html',
    styleUrls: ['./product-repairability.component.scss']
})
export class ProductRepairabilityComponent {

    readonly REPAIRABLE_PRODUCT_ICON: string = 'assets/images/logos/product-repairable.png';
    readonly UNREPAIRABLE_PRODUCT_ICON: string = 'assets/images/logos/product-unrepairable.png';

    @Input() product: Product;

    computeReparability = (): string => {
        if (this.hasRepairabilityInfo()) {
            if (this.product.repairable.valueOf()) {
                return 'PRODUCT.REPAIRABLE';
            } else {
                return 'PRODUCT.UNREPAIRABLE';
            }
        }
        return '-';
    };

    hasRepairabilityInfo = (): boolean => {
        return this.product.repairable !== undefined && this.product.repairable !== null;
    };

}
