<div id="error-404" fxLayout="column" fxLayoutAlign="center center">

    <div class="content" fxLayout="column" fxLayoutAlign="center center">

        <div class="error-code">
            <mat-icon [svgIcon]="'broken_document'" class="broken-folder"></mat-icon>
        </div>

        <div class="message mb-20">{{"FOLDER.ERROR" |translate}}</div>
        <div class="message-description mb-12">{{"FOLDER.ERROR.DESCRIPTION" |translate}}</div>

        <button mat-button (click)="gotToFolderList()"
                class="mt-48 nav-bar-button_new_folder">{{"FOLDER.GO_BACK_LIST" |translate}}
        </button>
    </div>

</div>
