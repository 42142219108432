<mat-card-content>
        <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start">
            <div fxLayout="row" fxLayoutAlign="start center">
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>
            <fieldset>
                <legend class="h3 title-fieldset">{{ 'COMPONENT.PROLONGED_DIAGNOSIS.BREAKDOWN' | translate }}</legend>
                <ng-container>
                    <mat-radio-group formControlName="decision" class="ml-20" labelPosition="after" fxLayout="column"
                                     fxLayoutAlign="start stretch" fxLayoutGap="10px">

                        <ng-container *ngFor=" let option of options">
                            <mat-radio-button [id]="option" class="full-width-radio"
                                              [value]=option>
                                <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                    <div fxFlex>
                                        <div>{{ option | translate }}</div>
                                    </div>
                                </div>
                            </mat-radio-button>
                        </ng-container>
                    </mat-radio-group>
                </ng-container>
            </fieldset>

            <app-comment-area [commentForm]="form.get('comment')"></app-comment-area>

        </form>
        <div fxLayoutAlign="end center" class="mt-24">
            <button type="submit" mat-stroked-button
                    (click)="onSubmit()"
                    [disabled]="form.invalid"
                    [ngClass]="form.invalid ? 'aster_btn_disabled' : 'aster_btn'">
                {{ 'BUTTON.VALIDATE' | translate }}
            </button>
        </div>
    </mat-card-content>