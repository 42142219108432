import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {ConfigurationReferential} from '../../../../../models/configurationReferential.model';
import {TaskVariables} from '../../task.variables';
import {MatOptionSelectionChange} from '@angular/material/core';
import {select, Store} from '@ngrx/store';
import {allConfiguration} from '../../../../../store/configuration/configuration.selectors';
import {AppState} from '../../../../../store/app.state';
import {Unsubscriber} from '../../../../../unsubscriber';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-repairer-and-carrier-confirmation',
    templateUrl: './repairer-and-carrier-confirmation.component.html',
    styleUrls: ['./repairer-and-carrier-confirmation.component.scss']
})
export class RepairerAndCarrierConfirmationComponent extends Unsubscriber implements OnInit {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;

    repairers = [];
    carriers = [];
    confirmed = false;
    selectedRepairer = {
        'code': '',
        'label': '',
    };
    selectedCarrier = {
        'code': '',
        'label': ''
    };

    constructor(private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {

        this.selectedRepairer.code = this.variablesTask[TaskVariables.repairCenterCode];
        this.selectedCarrier.code = this.variablesTask[TaskVariables.carrierCode];

        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.REPAIRER}))
            .subscribe(it => it.forEach((rep: ConfigurationReferential) => {
                this.repairers.push({
                    code: rep.code,
                    label: rep.label,
                    contact: rep.extra['contact'],
                    completeAddress: rep.extra['completeAddress'],
                    interventionDepartments: rep.extra['interventionDepartments'],
                    publicAgenda: rep.extra['publicAgenda'],

                });
            }));

        this.repairers.filter(repairer => repairer.code === this.selectedRepairer.code ? this.selectedRepairer = repairer : null);
        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.CARRIER}))
            .subscribe(it => {
                it.forEach((carrier: ConfigurationReferential) => {
                    this.carriers.push({
                        code: carrier.code,
                        label: carrier.label
                    });
                });
                this.carriers.filter(carrier => carrier.code === this.selectedCarrier.code ? this.selectedCarrier = carrier : null);
            });

    }

    setRepairer($event: MatOptionSelectionChange, repairerItem: any): void {
        this.selectedRepairer = repairerItem;
    }

    onSubmit(): void {
        this.confirmed = true;
        let dataToComplete;
        dataToComplete = {
            'repairCenterCode': this.selectedRepairer.code,
            'carrierCode': this.selectedCarrier.code
        };
        this.inputMap.emit(dataToComplete);
    }

    setCarrier($event: MatOptionSelectionChange, carrierItem: any): void {
        this.selectedCarrier = carrierItem;
    }
}
