import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DatePipe, KeyValue} from '@angular/common';
import {NgxImageCompressService} from 'ngx-image-compress';
import {SurveyService} from '../../../../shared/services/survey.service';
import {SurveyElement} from '../../../../models/element.model';
import {DisplayedFilesType} from '../../../../models/enums/displayedFilesType.enum';
import {Observable, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-survey-summary',
    templateUrl: './survey-summary.component.html',
    styleUrls: ['./survey-summary.component.scss'],
    providers: [NgxImageCompressService, DatePipe]
})

export class SurveySummaryComponent implements OnInit, OnChanges {

    @Input() surveyResponseId;
    mapSummarySurvey: Map<string, { question: SurveyElement, answers: Map<string, SurveyElement> }> = new Map();
    validationDate: string;
    sliderWidth: number;

    private readonly destroy$ = new Subject();
    currentLanguage$: Observable<string>;

    constructor(public surveyService: SurveyService,
                private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        switch (true) {
            case window.innerWidth <= 375: {
                this.sliderWidth = 200;
                break;
            }
            case window.innerWidth > 375: {
                this.sliderWidth = 400;
                break;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initializeSurvey();
    }

    initializeSurvey(): void {
        this.mapSummarySurvey = new Map();
        this.getSurveySummary();
    }


    getFileType(fileType): DisplayedFilesType {
        switch (fileType) {
            case DisplayedFilesType.IMAGE :
                return DisplayedFilesType.IMAGE;
                break;
            case DisplayedFilesType.PDF:
                return DisplayedFilesType.PDF;
                break;
            case DisplayedFilesType.VIDEO:
                return DisplayedFilesType.VIDEO;
                break;
        }
    }

    indexOrderAsc = (firstElement: KeyValue<string, SurveyElement>, secendElement: KeyValue<string, SurveyElement>): number => {
        let a = 0;
        let b = 0;
        if (!!firstElement.value && !!secendElement.value) {
            a = firstElement.value.order;
            b = secendElement.value.order;
        }
        return a > b ? -1 : (b > a ? 1 : 0);
    };


    private getSurveySummary(): void {
        this.surveyService.getSummarySurvey(this.surveyResponseId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(elements => {
                this.updateSummarySurveyQuestion(elements);
            });
    }

    updateSummarySurveyQuestion(survey: SurveyElement[]): void {
        this.mapSummarySurvey = new Map();
        const surveyQuestions = survey.filter(value => value.elementType &&
            value.elementType === 'QUESTION');
        const surveyAnswers = survey.filter(value => value.elementType &&
            value.elementType === 'ANSWER' && value.displayType !== 'LABEL');
        surveyQuestions.forEach((elementSurvey, index) => {
            elementSurvey.order = index;
            this.mapSummarySurvey.set(elementSurvey.code, {question: elementSurvey, answers: new Map()});
            elementSurvey.nextElements.forEach(codeElement => {
                let answerValue = surveyAnswers.filter(data => data.code === codeElement)[0];
                if (!!answerValue) {
                    this.mapSummarySurvey.get(elementSurvey.code).answers
                        .set(codeElement, answerValue);
                }
            });
        });
    }

    isFilesFound(survey: SurveyElement): boolean {
        return !survey.hidden && !!survey.displayedFiles && survey.displayedFiles.length > 0;
    }
}
