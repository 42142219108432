import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
    selector: 'app-comment-area',
    templateUrl: './comment-area.component.html'
})
export class CommentAreaComponent implements OnInit {
    @Input() commentForm: AbstractControl;
    @Input() title = 'FOLDER.COMMENT';
    @Input() isRequired: boolean;
    constructor() {
    }

    ngOnInit(): void {
    }
}
