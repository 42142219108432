import {OperationModeEnum} from '../../../../models/enums/operationMode.enum';

export const STATUS_FOLDER_COMMON: string[] = [
    // Diagnostic Expert
    'WAITING_DIAGNOSTIC_EXPERT_DECISION',

    // Folder
    'SYMPTOM_SELECTED',
    'SURVEY',
    'PANNE_CONFIRMED',
    'ADD_CUSTOMER_DETAILS',
    'CHOOSE_OPERATING_MODE',
    'DIAG_EXPERT_DECISION_CONFIRMATION',
    'NOT_PLANNED_INTERVENTION',

    // Carrier
    'PARCEL_TO_SEND',
    'SHIPMENT_IN_PROGRESS',
    'HANDLE_RESERVATION_ANOMALY',
    'WAITING_PICKUP',

    // Payment
    'SERVICE_QUOTATION_ACCEPTATION',
    'STORE_PAYMENT',
    'WAITING_PAYMENT',

    // Quotation
    'QUOTATION_ACCEPTATION',
    'WAIT_QUOTATION'
];

export const STATUS_FOLDER_SAV: string[] = [
    ...STATUS_FOLDER_COMMON,

    // Home Repair
    'PROGRESS_REPAIR',
    'REPAIRER_TO_BE_CONFIRMED',
    'WISHES_APPOINTMENT',
    'WAITING_FOR_INTERVENTION',
    'EDIT_INTERVENTION_ADDRESS',
    'CONFIRM_APPOINTMENT',
    'REPARATION_CONFIRMATION',
    'ACCEPT_QUOTATION',
    'SWAP_CONFIRMATION_HOME_REPAIR',

    // Auto Reparation
    'SURVEY_AUTO_REPAIR',
    'VALIDATE_SHIPMENT_ADDRESS',
    'WAITING_AUTO_REPAIR',
    'CHOOSE_NEXT_SOLUTION',
    'PROPOSE_SPARE_PARTS_AND_TUTO',
    'ORDER_BY_OTHER_PARTNER_IN_PROGRESS',

    // Broken Product Processing
    'WAITING_SUPPLIER_CONFIRMATION',
    'WAITING_FOR_HS_PRODUCT_DESTRUCTION',
    'WAITING_CREDIT_SUPPLIER',
    'HS_PRODUCT_DESTRUCTION',
    'VALIDATE_AGREEMENT_DELAY',
    'WAITING_FOR_HS_PRODUCT_DESTRUCTION_CONFIRMATION',
    'REFUND_REFUSAL_VALIDATION',

    // Swap By Customer
    'WAITING_FOR_HS_PRODUCT_DESTRUCTION_BY_CUSTOMER',
    'REFUND_CUSTOMER',
    'CARRIER_TO_BE_CONFIRMED',
    'VALIDATE_CUSTOMER_ADDRESS',
    'WAITING_EXCHANGE_IN_STORE',

    // Swap
    'VALIDATE_SWAP_IN_STORE',
    'CHOOSING_SWAP_LOCATION',

    // Store Deposit
    'DEPOSIT_PRODUCT_SITE',
    'WAITING_DEPOSIT_SITE',
    'MANUEL_FOLDER_PROCESS',

    // Spare Parts By Customer
    'CHOOSE_SPARE_PART_TO_ORDER',

    // Spare Parts Order
    'WAITING_SPARE_PART_RECEPTION',

    // Visio
    'BOOK_APPOINTMENT',
    'WAITING_VISIO_QUALIFICATION',
    'WAITING_VISIO_REPAIR',
    'REPAIR_IN_PROGRESS_BY_CUSTOMER',
    'ADD_DEFAULT_ADDRESS',

    // Product Delivery
    'REPAIRER_AND_CARRIER_TO_BE_CONFIRMED',
    'PRODUCT_AT_STORE',
    'CHOOSE_PRODUCT_SHIPMENT_MODE',
    'CONFIRM_CARRIER_AND_REPAIRER',
    'WAITING_REPARATION_AGREEMENT',
    'REPARATION_AGREEMENT_DELAY',

    // Reparation Package
    'CHOOSE_REPAIR_PACKAGE',

    // Service Center Repair
    'DIAGNOSIS_IN_PROGRESS',
    'WAITING_PRODUCT_RETRIEVAL',
    'WAITING_CLIENT_DECISION_UNREPAIRABLE_PRODUCT',
    'SWAP_CONFIRMATION',
    'PRODUCT_RECEPTION_AND_CONTROL_AT_STORE',
    'REPAIR_IN_PROGRESS',

    // Refund
    'REFUND_TO_CUSTOMER',
    'WAITING_REFUND_SAV',

    // Manual Claim Processing
    'MANUAL_CLAIM_PROCESS',

    // Internal Reparation
    'WAITING_FOR_REPARATION_PERMISSION',

    // DELAY
    'DIAGNOSTIC_DELAY',
    'REPARATION_DELAY'

];

export const STATUS_FOLDER_PRODUCT_RECALL: string[] = [
    ...STATUS_FOLDER_COMMON,

    // Product Recall
    'PRODUCT_CHECK_AT_CUSTOMER',
    'NEW_CONTACT_OF_PRODUCT',
    'PRODUCT_QUANTITY_CHECK',

    // Auto Reparation
    'SUGGEST_PIECE_AND_REPAIR_TUTO',
    'WAITING_ASSISTANCE_TO_CALL',
    'VALIDATE_CUSTOMER_ADDRESS',
    'CUSTOMER_REPAIR_IN_PROGRESS',
    'WAITING_TO_INTERVENE',
    'CARRIER_TO_BE_CONFIRMED',

    // Home Repair
    'PROGRESS_REPAIR',
    'REPAIRER_TO_BE_CONFIRMED',
    'WISHES_APPOINTMENT',
    'WAITING_FOR_INTERVENTION',
    'EDIT_INTERVENTION_ADDRESS',
    'CONFIRM_APPOINTMENT',
    'REPARATION_CONFIRMATION',

    // Refund
    'REFUND_PROTOCOL_SENT',
    'REFUND_PROTOCOL_TO_RETURN_TO_CLIENT',
    'REFUND_PROTOCOL_RETURNED_TO_CLIENT',
    'WAITING_REFUND',

    // Store Deposit
    'DEPOSIT_PRODUCT_SITE',
    'ADD_DEFAULT_ADDRESS',

    // Swap
    'CHOOSE_PRODUCT_SHIPMENT_MODE',
    'WAITING_FOR_HS_PRODUCT_DESTRUCTION_BY_CUSTOMER',
    'REFUND_CUSTOMER',
    'WAITING_EXCHANGE_IN_STORE',
];


export const STATUS_FOLDER_REPARATION: string[] = [
    ...STATUS_FOLDER_COMMON,

    // Reparation Itinerant
    'PLANNED_INTERVENTION',
    'INTERVENTION_IN_PROGRESS',
    'REPARATION_QUOTATION_ACCEPTATION',
    'INTERVENTION_REPORT',

    // Service Center Repair For Repairer
    'CHECK_PRODUCT_CONFORMITY',
    'REPAIR_IN_PROGRESS',
    'WAITING_FOR_HS_PRODUCT_DESTRUCTION',
    'WAITING_PRODUCT_RECEPTION',
    'PRODUCT_DIAGNOSTIC',
    'CHECK_SPARE_PARTS_NEED',
    'SPARE_PARTS_ORDER_IN_PROGRESS',
    'PREPARE_SPARE_PARTS_ORDER',
    'WAITING_TO_REPAIR',

    // Supplier Order Tracking
    'SPARE_PARTS_ORDER_WAITING_VALIDATION',
    'SPARE_PARTS_ORDER_VALIDATED',
    'SPARE_PARTS_ORDER_WAITING_PREPARATION',
];


export const IN_PROGRESS_FOLDER_STATUS_FOR_CARRIER_WF: string[] = [
    'WAITING_PICKUP',
    'HANDLE_RESERVATION_ANOMALY',
    'PARCEL_TO_SEND',
    'SHIPMENT_IN_PROGRESS'
];

export const IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON: string[] = [
    'PRODUCT_CHECK_AT_CUSTOMER',
    'NEW_CONTACT_OF_PRODUCT',
    'PRODUCT_QUANTITY_CHECK',
    'SURVEY',
    'CUSTOMER_DID_NOT_RESPOND'
];


export const IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_HOME_REPAIR_MOD_OP: string[] = [
    'EDIT_INTERVENTION_ADDRESS',
    'REPAIRER_TO_BE_CONFIRMED',
    'REPARATION_CONFIRMATION',
    'WISHES_APPOINTMENT',
    'NOT_PLANNED_INTERVENTION',
    'CONFIRM_APPOINTMENT',
    'WAITING_FOR_INTERVENTION',
    'PROGRESS_REPAIR'
];

export const IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_REFUND_MOD_OP: string[] = [
    'REFUND_PROTOCOL_SENT',
    'REFUND_PROTOCOL_TO_RETURN_TO_CLIENT',
    'REFUND_PROTOCOL_RETURNED_TO_CLIENT',
    'WAITING_REFUND',
];

export const IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_AUTO_REPAIR_MOD_OP: string[] = [
    'SUGGEST_PIECE_AND_REPAIR_TUTO',
    'WAITING_ASSISTANCE_TO_CALL',
    'VALIDATE_CUSTOMER_ADDRESS',
    'CARRIER_TO_BE_CONFIRMED',
    'WAITING_PICKUP',
    'HANDLE_RESERVATION_ANOMALY',
    'SHIPMENT_IN_PROGRESS',
    'PARCEL_TO_SEND',
    'CUSTOMER_REPAIR_IN_PROGRESS',
    'WAITING_TO_INTERVENE',
    ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_HOME_REPAIR_MOD_OP,
    ...IN_PROGRESS_FOLDER_STATUS_FOR_CARRIER_WF
];

export const IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_STORE_DEPOSIT_MOD_OP: string[] = [
    'ADD_DEFAULT_ADDRESS',
    'DEPOSIT_PRODUCT_SITE',
];
export const IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_SWAP_MOD_OP: string[] = [
    'CHOOSE_PRODUCT_SHIPMENT_MODE',
    'WAITING_FOR_HS_PRODUCT_DESTRUCTION_BY_CUSTOMER',
    'VALIDATE_CUSTOMER_ADDRESS',
    'REFUND_CUSTOMER',
    'WAITING_EXCHANGE_IN_STORE',
    'CARRIER_TO_BE_CONFIRMED',
    ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_STORE_DEPOSIT_MOD_OP,
    ...IN_PROGRESS_FOLDER_STATUS_FOR_CARRIER_WF
];


export const CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON: string[] = [
    'FOLDER_CLOSED_PRODUCT_ASSOCIATED_OTHER_CUSTOMER',
    'FOLDER_CLOSED_CUSTOMER_DOESNT_HAVE_PRODUCT',
    'FOLDER_CLOSED_BY_DIAGNOSTIC',
    'FOLDER_CLOSED_REPARATION_FINISHED'
];

export const CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_HOME_REPAIR_MOD_OP: string[] = [
    'FOLDER_CLOSED_REPAIR_CENTER_CONFIRMED',
    'FOLDER_CLOSED_CANCEL_INTERVENTION',
    'FOLDER_CLOSED_FINISHED',
];


export const CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_REFUND_MOD_OP: string[] = [
    'FOLDER_CLOSED_ONLINE_REFUND',
];


export const CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_AUTO_REPAIR_MOD_OP: string[] = [
    'FOLDER_CLOSED_CUSTOMER_REPAIR_PRODUCT',
    'FOLDER_CLOSED_CALL_CENTER_ASSISTANCE',
    'FOLDER_CLOSED_TECHNICIAN_INTERVENE',
    ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_HOME_REPAIR_MOD_OP,
];




export const CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_STORE_DEPOSIT_MOD_OP: string[] = [
    'FOLDER_CLOSED_DEPOSED_FINISHED_WITHOUT_STORE_SELECTION',
    'FOLDER_CLOSED_DEPOSED_FINISHED_WITH_STORE_SELECTION',

];



export const CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_SWAP_MOD_OP: string[] = [
    'FOLDER_CLOSED_SWAP_IN_STORE',
    'FOLDER_CLOSED_ONLINE_REFUND',
    'FOLDER_CLOSED_SEND_NEW_PRODUCT'
];


export const CLOSED_FOLDER_STATUS_LIST_PRODUCT_RECALL_CONTEXT: string[] = [
    ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_SWAP_MOD_OP,
    ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_STORE_DEPOSIT_MOD_OP,
    ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_AUTO_REPAIR_MOD_OP,
    ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_REFUND_MOD_OP,
    ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_HOME_REPAIR_MOD_OP,
    'PLANNED_INTERVENTION',
];

export const FOLDER_STATUS_MAP: Map<string, Map<string, String[]>> = new Map()
    .set('opened', new Map()
        .set(OperationModeEnum.NO_MODOP, [...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_STORE_DEPOSIT_MOD_OP, ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON])
        .set(OperationModeEnum.SWAP, [...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_SWAP_MOD_OP, ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON])
        .set(OperationModeEnum.HOME_REPAIR, [...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON, ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_HOME_REPAIR_MOD_OP])
        .set(OperationModeEnum.AUTO_REPAIR, [...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON, ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_AUTO_REPAIR_MOD_OP])
        .set(OperationModeEnum.REFUND, [...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON, ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_REFUND_MOD_OP])
    )
    .set('closed', new Map()
        .set(OperationModeEnum.NO_MODOP, [...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON, ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_STORE_DEPOSIT_MOD_OP])
        .set(OperationModeEnum.SWAP, [...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON, ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_SWAP_MOD_OP])
        .set(OperationModeEnum.HOME_REPAIR, [...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON, ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_HOME_REPAIR_MOD_OP])
        .set(OperationModeEnum.AUTO_REPAIR, [...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON, ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_AUTO_REPAIR_MOD_OP])
        .set(OperationModeEnum.REFUND, [...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON, ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_REFUND_MOD_OP]))
    .set('all', new Map()
        .set(OperationModeEnum.NO_MODOP, [...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON, ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_STORE_DEPOSIT_MOD_OP,
            ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_STORE_DEPOSIT_MOD_OP, ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON])
        .set(OperationModeEnum.SWAP, [...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON, ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_SWAP_MOD_OP,
            ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_SWAP_MOD_OP, ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON])
        .set(OperationModeEnum.HOME_REPAIR, [...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON, ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_HOME_REPAIR_MOD_OP,
            ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_HOME_REPAIR_MOD_OP, ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON])
        .set(OperationModeEnum.AUTO_REPAIR, [...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON, ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_AUTO_REPAIR_MOD_OP,
            ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_AUTO_REPAIR_MOD_OP, ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON])
        .set(OperationModeEnum.REFUND, [...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON, ...CLOSED_FOLDER_STATUS_FOR_PRODUCT_RECALL_REFUND_MOD_OP,
            ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_REFUND_MOD_OP, ...IN_PROGRESS_FOLDER_STATUS_FOR_PRODUCT_RECALL_COMMON]));

export const TREE_DATA = {
    folder: {
        externalReferences: null,
        externalRepairerSystemReference: null,
        stockClaim: null,
    },
    statusNode: {
        currentWorkFlowStatus: null,
        tags: null
    },
    date: {
        dateRange: null,
        workflowStatusDate: null
    },
    site: {
        creationSite: null,
        managementSite: null
    },
    repairCenter: null,
    supplier: null,
    origin: null,
    product: {
        productCode: null,
        productLabel: null,
        family1Code: null,
        family2Code: null,
        family3Code: null,
        family4Code: null,
        productSegment: null,
        invoiceNumber: null,
        serialNumber1: null,
    },
    customerAddress: {
        customerZipCode: null,
        customerDepartmentCode: null
    }
};
