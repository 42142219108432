import {ProductFamilyType} from './enums/productFamilyType.enum';


export class ProductFamily {
    id: string;
    code: string;
    label: string;
    type: ProductFamilyType;

}
