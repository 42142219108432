import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {createSpinner, hideSpinner, showSpinner} from '@syncfusion/ej2-popups';

@Component({
    selector: 'ejs-spinner',
    template: '<div id="spinner"></div>'
})
export class SpinnerComponent implements OnChanges {

    @Input() isLoading: boolean;
    @Input() width: string;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isLoading.isFirstChange()) {
            createSpinner({
                target: document.getElementById('spinner'),
                width: this.width
            });
            this.showSpinner();
        }

        this.isLoading ? this.showSpinner() : this.hideSpinner();
    }

    hideSpinner = () => hideSpinner(document.getElementById('spinner'));
    showSpinner = () => showSpinner(document.getElementById('spinner'));
}
