import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-confirmation-without-client-quotation',
    templateUrl: './confirmation-without-client-quotation.component.html',
    styleUrls: ['./confirmation-without-client-quotation.component.scss']
})
export class ConfirmationWithoutClientQuotationComponent {

    form: FormGroup;

    constructor(public dialogRef: MatDialogRef<ConfirmationWithoutClientQuotationComponent>,
                private translateService: TranslateService) {

    }

    validate() {
        return this.dialogRef.close(true);
    }

    onClose(): void {
        this.dialogRef.close();
    }

    getDescription = () => {
        return this.translateService.stream('CONFIRMATION.MESSAGE');
    }
}
