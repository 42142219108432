import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {HttpLoaderFactory} from '../../../../app.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '../../../../../@fuse/components';
import {fuseConfig} from '../../../../fuse-config';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {FuseModule} from '../../../../../@fuse/fuse.module';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {ChooseProductShipmentModeComponent} from './choose-product-shipment-mode/choose-product-shipment-mode.component';

import {AddCustomerDetailsComponent} from './add-customer-details/add-customer-details.component';
import {RepairInProgressComponent} from './repair-in-progress/repair-in-progress.component';
import {WaitingProductRetrievalComponent} from './waiting-product-retrieval/waiting-product-retrieval.component';
import {WaitingClientDecisionUnrepairableProductComponent} from './waiting-client-decision-unrepairable-product/waiting-client-decision-unrepairable-product.component';
import {SharedGenericModule} from '../../../../shared/generic/shared-generic.module';
import {NgxDropzoneModule} from 'ngx-dropzone';

import {WaitQuotationComponent} from './wait-quotation/wait-quotation.component';
import {QuotationAcceptationComponent} from './quotation-acceptation/quotation-acceptation.component';
import {QuotationProcessCustomerComponent} from './quotation-acceptation/quotation-process-customer/quotation-process-customer.component';
import {QuotationDetailsComponent} from './quotation-acceptation/quotation-details/quotation-details.component';
import {AddReturnInfoComponent} from './add-return-info/add-return-info.component';
import {ProductAtStoreComponent} from './product-at-store/product-at-store.component';
import {SwapConfirmationComponent} from './swap-confirmation/swap-confirmation.component';
import {ProductReceptionAndControlComponent} from './repaired-product-control/product-reception-and-control.component';
import {ConfirmCarrierAndRepairerComponent} from './confirm-carrier-and-repairer/confirm-carrier-and-repairer.component';
import {WaitingReparationAgreementComponent} from './waiting-reparation-agreement/waiting-reparation-agreement.component';
import {ReparationAgreementDelayComponent} from './reparation-agreement-delay/reparation-agreement-delay.component';
import {DiagnosisInProgressComponent} from './diagnosis-in-progress/diagnosis-in-progress.component';
import {WaitingClientDecisionProductWorksComponent} from './waiting-client-decision-product-works/waiting-client-decision-product-works.component';
import { DiagnosticDelayComponent } from './diagnostic-delay/diagnostic-delay.component';
import { ReparationDelayComponent } from './reparation-delay/reparation-delay.component';
import { ConfirmationWithoutClientQuotationComponent } from './quotation-acceptation/confirmation-without-client-quotation/confirmation-without-client-quotation.component';
import {GridModule} from '@syncfusion/ej2-angular-grids';
import { ValidateRefusReparationByStoreComponent } from './validate_refus_reparation_by_store/validate-refus-reparation-by-store.component';
import { OldWaitingReparationAgreementComponent } from './old-waiting-reparation-agreement/old-waiting-reparation-agreement.component';
import { WaitingCustomerDecisionOutOfWarrantyComponent } from './waiting-customer-decision-out-of-warranty/waiting-customer-decision-out-of-warranty.component';
import {WorkflowProcessModule} from '../workflowProcess/workflow-process.module';
import {DetailAddressRepairerDialogComponent} from './detail-address-repairer-dialog/detail-address-repairer-dialog.component';
import {ChipListModule} from '@syncfusion/ej2-angular-buttons';


@NgModule({
    entryComponents: [
        ChooseProductShipmentModeComponent,
        AddCustomerDetailsComponent,
        RepairInProgressComponent,
        WaitingClientDecisionUnrepairableProductComponent,
        WaitQuotationComponent,
        QuotationAcceptationComponent,
        WaitingProductRetrievalComponent,
        QuotationProcessCustomerComponent,
        QuotationDetailsComponent,
        AddReturnInfoComponent,
        ProductAtStoreComponent
    ],
    declarations: [
        ChooseProductShipmentModeComponent,
        AddCustomerDetailsComponent,
        RepairInProgressComponent,
        WaitingClientDecisionUnrepairableProductComponent,
        WaitQuotationComponent,
        QuotationAcceptationComponent,
        WaitingProductRetrievalComponent,
        QuotationProcessCustomerComponent,
        QuotationDetailsComponent,
        AddReturnInfoComponent,
        ProductAtStoreComponent,
        SwapConfirmationComponent,
        ProductReceptionAndControlComponent,
        ConfirmCarrierAndRepairerComponent,
        WaitingReparationAgreementComponent,
        ReparationAgreementDelayComponent,
        DiagnosisInProgressComponent,
        WaitingClientDecisionProductWorksComponent,
        DiagnosticDelayComponent,
        ReparationDelayComponent,
        ConfirmationWithoutClientQuotationComponent,
        DetailAddressRepairerDialogComponent,
        ValidateRefusReparationByStoreComponent,
        OldWaitingReparationAgreementComponent,
        WaitingCustomerDecisionOutOfWarrantyComponent
    ],
    imports: [FormsModule,
        // Material moment date module
        MatMomentDateModule,
        // Material
        Ng2TelInputModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedGenericModule,
        NgxDropzoneModule, GridModule, WorkflowProcessModule, ChipListModule,

    ],
    exports: [
        ChooseProductShipmentModeComponent,
        AddCustomerDetailsComponent,
        RepairInProgressComponent,
        WaitingClientDecisionUnrepairableProductComponent,
        WaitQuotationComponent,
        QuotationAcceptationComponent,
        WaitingProductRetrievalComponent,
        QuotationProcessCustomerComponent,
        QuotationDetailsComponent,
        AddReturnInfoComponent,
        ProductAtStoreComponent
    ]
})
export class ServiceCenterRepairProcessModule {
}
