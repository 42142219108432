<ng-container [formGroup]="customerForm"
              addCompanyForm>
    <mat-radio-group
        formControlName="type"
        fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="space-between center">
        <mat-radio-button class="example-radio-button"
                          *ngFor="let customerType of customerTypes"
                          [value]="customerType">
            {{customerType | translate}}
        </mat-radio-button>
    </mat-radio-group>
</ng-container>
