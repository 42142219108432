import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProductLocation, ProductLocationTypes, UpdateLocationCommand} from '../../../../models/product.model';
import {MatTableDataSource} from '@angular/material/table';
import {FolderService} from 'app/shared/services/folder.service';
import {Folder} from 'app/models/folder.model';
import {MatPaginator} from '@angular/material/paginator';
import {AppState} from '../../../../store/app.state';
import {Store} from '@ngrx/store';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {ProductUtils} from '../../../../shared/utils/product-utils';

@Component({
    selector: 'app-folder-product-location',
    templateUrl: './folder-product-location.component.html',
    styleUrls: ['./folder-product-location.component.scss']
})
export class FolderProductLocationComponent implements OnInit, AfterViewInit {

    displayedColumns: string[] = ['Date', 'Location', 'Coordinates', 'Position'];

    dataSourceLocations = new MatTableDataSource<ProductLocation>();
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    folder: Folder;

    productLocations = Object.keys(ProductLocationTypes).filter(productLocationType => typeof productLocationType === 'string' && productLocationType !== 'UNKNOWN') as string[];
    currentLocation = ProductLocationTypes.UNKNOWN;
    newLocation: ProductLocationTypes;
    currentPosition: string;
    newPosition: string;


    constructor(public dialogRef: MatDialogRef<FolderProductLocationComponent>,
                @Inject(MAT_DIALOG_DATA) public data,
                private _folderService: FolderService,
                private store$: Store<AppState>) {

    }

    onClose(): void {
        this.dialogRef.close(this.folder);
    }

    ngOnInit(): void {
        const locations = this.data.folder.product.locations;
        this.loadDataSource(locations);
        this.folder = this.data.folder;
        this.currentLocation = this.data.currentLocation;
        this.newLocation = this.currentLocation;
        if (this.newLocation === 'STORE') {
            this.currentPosition = ProductUtils.latestPosition(this.data.folder.product);
            this.newPosition = this.currentPosition;
        }
        this.closeWithEscape();
    }

    private closeWithEscape(): void {
        this.dialogRef.keydownEvents().subscribe(event => {
            if (event.key === 'Escape' || event.key === 'Esc') {
                this.onClose();
            }
        });
    }

    onChangeLocation(location: ProductLocationTypes): void {
        if (location !== this.currentLocation || (this.newLocation === 'STORE' && this.newPosition !== this.currentPosition)) {
            const newProductLocation: UpdateLocationCommand = {
                targetLocation: location,
                targetPosition: this.newPosition
            };
            this._folderService.updateProductPosition(this.folder.id, newProductLocation).subscribe(folder => {
                    this.store$.dispatch(new StartLoading());
                    this.folder = folder;
                    this.loadDataSource(folder.product.locations);
                    this.currentLocation = location;
                    this.store$.dispatch(new StopLoading());
                },
                err => {
                    console.log(err);
                    this.store$.dispatch(new StopLoading());
                });
        }
    }

    sorted(locations: ProductLocation[]): ProductLocation[] {
        return locations ? locations.sort((x, y) => x.eventTimestamp > y.eventTimestamp ? -1 : 1) : locations;
    }

    private loadDataSource(locations): void {
        this.dataSourceLocations.data = this.sorted(locations);
    }

    ngAfterViewInit(): void {
        this.dataSourceLocations.paginator = this.paginator;
    }

    getFormatDate() {
        return 'GENERAL.DATE_TIME_FORMAT';
    }

    clearPosition(value: any) {
        this.newPosition = null;

    }
}
