import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from '../../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {BackOfficeService} from '../../../../../../shared/services/back-office.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TaskVariables} from '../../../task.variables';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {Observable} from 'rxjs';
import {countryCode} from '../../../../../../store/organization/organization.selectors';

@Component({
    selector: 'app-planned-intervention',
    templateUrl: './planned-intervention.component.html',
    styleUrls: ['./planned-intervention.component.css']
})
export class PlannedInterventionComponent implements OnInit {

    selectionForm: any = {
        form: null,
        placeholders: []
    };

    organizationLocal$: Observable<string>;

    @Input() folder: Folder;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();

    constructor(private backOfficeService: BackOfficeService,
                private dialog: MatDialog,
                private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.organizationLocal$ = this.store$.pipe(select(countryCode));
        this.initForm();
    }

    private initForm(): void {
        this.selectionForm.form = new FormGroup({
            choice: new FormControl(null, Validators.required),
        });
        this.selectionForm.placeholders = [
            {
                choice: 'NEED_QUOTATION',
                value: !this.variablesTask[TaskVariables.quotationId] ? 'COMPONENT.BUTTON.WAIT_QUOTATION' : 'COMPONENT.BUTTON.CHANGE_QUOTATION',
              //  value: !this.variablesTask[TaskVariables.quotationId] ? 'COMPONENT.NEED_QUOTATION' : 'COMPONENT.CHANGE_QUOTATION',
            },
            {
                choice: 'RESCHEDULE',
                value: 'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.RESCHEDULE',
            }
        ];
    }

    rescheduleAppointment(): void {
        const dialogRef = this.createNewConfirmationModal();

        this.putModalMessage(dialogRef, 'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.RESCHEDULE.DIALOG.MESSAGE', 'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.RESCHEDULE.DIALOG.TITLE');

        dialogRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.store$.dispatch(new StartLoading());
                this.backOfficeService.deleteAppointment(this.folder.appointment.confirmedAppointment.appointmentId).subscribe((data) => {
                    this.store$.dispatch(new StopLoading());
                    this.inputMap.emit({
                        rescheduleAppointment: 'true',
                        confirmedAppointmentId: this.folder.appointment.confirmedAppointment.appointmentId
                    });
                }, () => this.store$.dispatch(new StopLoading()));
            }
        });
    }

    cancelAppointment(): void {
        const dialogRef = this.createNewConfirmationModal();

        this.putModalMessage(dialogRef, 'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.CANCEL.DIALOG.MESSAGE', 'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.CANCEL.DIALOG.TITLE');

        dialogRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.store$.dispatch(new StartLoading());
                this.backOfficeService.deleteAppointment(this.folder.appointment.confirmedAppointment.appointmentId).subscribe((data) => {
                    this.store$.dispatch(new StopLoading());
                    this.inputMap.emit({
                        cancelAppointment: 'true',
                        confirmedAppointmentId: this.folder.appointment.confirmedAppointment.appointmentId
                    });
                }, () => this.store$.dispatch(new StopLoading()));
            }
        });
    }

    private putModalMessage(dialogRef: MatDialogRef<FuseConfirmDialogComponent>, modalMessageKey: string, modalTitleKey: string ): void {
        dialogRef.componentInstance.title = modalTitleKey;
        dialogRef.componentInstance.message = modalMessageKey;
    }

    private createNewConfirmationModal(): MatDialogRef<FuseConfirmDialogComponent> {
        return this.dialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });
    }

    sparePartsOrder(): void {
        this.inputMap.emit({
            needQuotation: 'true',
        });
    }

    onSubmit(): void {
        if (this.selectionForm.form.get('choice').value === 'NEED_QUOTATION') {
            this.sparePartsOrder();
        } else if (this.selectionForm.form.get('choice').value === 'RESCHEDULE') {
            this.rescheduleAppointment();
        }
    }

    startIntervention(): void {
        const dialogRef = this.createNewConfirmationModal();

        this.putModalMessage(dialogRef, 'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.START.DIALOG.MESSAGE', 'FOLDER.WORKFLOW.REPARATION.APPOINTMENT.START.DIALOG.TITLE');
        dialogRef.afterClosed().subscribe(confirm => {
            if (!!confirm) {
                this.startInterventionWF();
            }
        });
    }

    startInterventionWF = () => this.inputMap.emit({'startIntervention': true});
}
