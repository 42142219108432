import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {Observable} from 'rxjs';
import {IEvent} from '../../../../models/events.model';
import {select, Store} from '@ngrx/store';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {AppState} from '../../../../store/app.state';
import {cacheConfigObject} from '../../../../store/configuration/configuration.selectors';
import {ConfigurationReferential} from '../../../../models/configurationReferential.model';

@Component({
    selector: 'app-folder-history-status-card',
    templateUrl: './folder-history-status-card.component.html',
    styleUrls: ['./folder-history-status-card.component.scss']
})
export class FolderHistoryStatusCardComponent implements OnInit, AfterViewInit, OnChanges {

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    @Input() events = [];

    displayedColumns: string[] = ['taskName', 'startTime'];
    isSortAsc = true;
    dataSourceStatusHistory = new MatTableDataSource<IEvent>([]);

    currentLanguage$: Observable<string>;

    constructor(private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.dataSourceStatusHistory = new MatTableDataSource<IEvent>();
        this.fetchEvents();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes.events.currentValue) {
            this.fetchEvents();
        }
    }

    ngAfterViewInit(): void {
        this.dataSourceStatusHistory.paginator = this.paginator;
    }

    public sortByDate(): void {
        this.dataSourceStatusHistory.data.sort((a: IEvent, b: IEvent) => {
            let date1 = new Date(a.userAction.actionDate);
            let date2 = new Date(b.userAction.actionDate);
            if (this.isSortAsc) {

                return +date2.getTime() - +date1.getTime();
            } else {
                return +date1.getTime() - +date2.getTime();
            }
        });
    }
    findUserTask(taskCode: string): Observable<ConfigurationReferential> {
       return  this.store$.pipe(select(cacheConfigObject, {configurationItemCode: 'INSTRUCTION_USER_TASK', code: taskCode}));
    }
    public updateSort(): void {
        this.isSortAsc = !this.isSortAsc;
        this.sortByDate();
    }

    fetchEvents(): void {
        this.dataSourceStatusHistory.data = this.events;
        if (!!this.events) {
            this.sortByDate();
        }
        this.dataSourceStatusHistory.paginator = this.paginator;
        this.dataSourceStatusHistory.sort = this.sort;
    }
}
