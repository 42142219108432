    <app-user-task-header [instructionUserTask]="instructionUserTask"
                          [claim]="folder"></app-user-task-header>
    <mat-card-content class="mt-25">
        <ng-container>
            <div class="mt-24">
                <mat-card-subtitle>{{('FOLDER.WORKFLOW.REPAIRER_TO_BE_CONFIRMED.DESCRIPTION') |translate}}</mat-card-subtitle>

                <app-comment-area [commentForm]="commentControl"></app-comment-area>

                <div fxLayout="row" fxLayoutAlign="end">
                    <button mat-stroked-button (click)="onSubmit()"
                            type="submit"
                            class="aster_btn">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </div>
        </ng-container>
    </mat-card-content>
