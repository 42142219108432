import {environment} from '../environments/environment';

export const GLOBAL = {
    appName: 'SAV',
    savFrontOfficeFrontend: environment.savFrontOfficeFrontend,
    gatewayEndpoint: environment.gatewayEndpoint,
    savBackOfficeConfigFrontend: environment.savBackOfficeConfigFrontend,
    keycloakConfig: environment.keycloakConfig,
    sparePartApiKey: environment.sparePartApiKey,
    savBackOfficeFrontend: environment.savBackOfficeFrontend,
    imagePlaceholder: 'assets/images/ecommerce/icon-product-12.jpg',
    sparePartsPlaceHolder: 'assets/icons/spartpart.png',
    imageNotFound : 'assets/icons/svg/icon_image.svg',
    loader: 'assets/icons/loader.png',

    validationPatterns: {
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        serialNumber: /^(?!\s)[A-Za-z0-9\s\W_]+$/,
        boulangerSiteCode : /^F\d{3}$/
    }
};
