import {NgModule} from '@angular/core';
import {SuggestPieceAndRepairTutoComponent} from './suggest-piece-and-repair-tuto/suggest-piece-and-repair-tuto.component';
import {WaitingAssistanceToCallComponent} from './waiting-assistance-to-call/waiting-assistance-to-call.component';
import {CustomerRepairInProgressComponent} from './customer-repair-in-progress/customer-repair-in-progress.component';
import {FormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {FuseModule} from '../../../../../@fuse/fuse.module';
import {fuseConfig} from '../../../../fuse-config';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '../../../../../@fuse/components';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../../../../app.module';
import {HttpClient} from '@angular/common/http';
import {SharedGenericModule} from '../../../../shared/generic/shared-generic.module';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {WaitingToInterveneComponent} from './waiting-to-intervene/waiting-to-intervene.component';
import { ValidateCustomerAddressComponent } from './validate-customer-address/validate-customer-address.component';
import { CarrierToBeConfirmedComponent } from './carrier-to-be-confirmed/carrier-to-be-confirmed.component';
import { CustomerRepairSavInProgressComponent } from './customer-repair-sav-in-progress/customer-repair-sav-in-progress.component';
import { DisplaySparePartsAndTutorialsComponent } from './display-spare-parts-and-tutorials/display-spare-parts-and-tutorials.component';


@NgModule({
    entryComponents: [
        SuggestPieceAndRepairTutoComponent,
        WaitingAssistanceToCallComponent,
        CustomerRepairInProgressComponent,
        ValidateCustomerAddressComponent
    ],
    declarations: [
        SuggestPieceAndRepairTutoComponent,
        WaitingAssistanceToCallComponent,
        CustomerRepairInProgressComponent,
        WaitingToInterveneComponent,
        ValidateCustomerAddressComponent,
        CarrierToBeConfirmedComponent,
        CustomerRepairSavInProgressComponent,
        DisplaySparePartsAndTutorialsComponent
    ],
    imports: [FormsModule,
        // Material moment date module
        MatMomentDateModule,
        // Material
        Ng2TelInputModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedGenericModule,
        MatCardModule, MatIconModule, MatTableModule, NgxDropzoneModule,
    ],
    exports: []
})
export class AutoRepairProcessModule {
}
