import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkflowService} from '../../../../../shared/services/workflow.service';
import {Folder} from '../../../../../models/folder.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Appointment} from '../../../../../models/appointment.model';
import {DialogDataCancelInterventionComponent} from './dialog-data-cancel-intervention/dialog-data-cancel-intervention.component';
import {DialogValidateEditWishedAppointmentComponent} from './dialog-validate-edit-wished-appointment/dialog-validate-edit-wished-appointment.component';
import {WorkflowAction} from '../../../../../models/enums/workflowAction.enum';
import {Permission} from '../../../../../models/permession.model';
import {AppointmentType} from '../../../../../models/enums/appointmentType.enum';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {currentLanguage} from '../../../../../store/organization/organization.selectors';
import {CommentType} from '../../../../../models/enums/CommentType.enum';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-confirm-appointment',
    templateUrl: './confirm-appointment.component.html',
    styleUrls: ['./confirm-appointment.component.scss']
})
export class ConfirmAppointmentComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    @Input() variablesTask: any;
    @Input() permission: Permission;

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();

    wishesAppointments = [];
    wishesAppointmentSelected;
    otherDateSeleced;
    minDate = new Date().getDay() + 1;
    registerForm: FormGroup;
    beginHour: string;
    endHour: string;
    currentLanguage$: Observable<string>;
    commentControl = new FormControl('');

    constructor(private router: Router,
                private route: ActivatedRoute,
                private workflowService: WorkflowService,
                private formBuilder: FormBuilder,
                private store$: Store<AppState>,
                public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.initFormWishesAppointment();
        this.initializeVariablesTask();
    }

    initializeVariablesTask(): void {
        this.wishesAppointments = JSON.parse(this.variablesTask['wishedDatesWrapper']).wishedDates;
    }

    initFormWishesAppointment(): void {
        this.registerForm = this.formBuilder.group({
            wishedDay: [null, Validators.required],
            beginHourIntervention: [null, Validators.required],
            endHourIntervention: [null, Validators.required]
        });
    }

    getHours(date): number {
        return moment(date.day + ' ' + date.beginHour).hour();
    }

    redirectToEditWishedDate(): void {
        this.dialog.open(DialogValidateEditWishedAppointmentComponent).afterClosed().subscribe(result => {
            if (result) {
                this.inputMap.emit({
                    'action': WorkflowAction.APPOINTMENT_RESCHEDULE
                });
            }
        });
    }

    confirmIntervention(): void {
        if (this.commentControl.value.length > 0) {
            this.commentGiven.emit({
                value: this.commentControl.value
            });
        }

        let confirmedDate: Appointment;
        if (this.wishesAppointmentSelected === 'autre') {
            if (this.otherDateSeleced === 'OTHER_DATE') {
                confirmedDate = {
                    day: this.registerForm.value.wishedDay.format('YYYY-MM-DD'),
                    beginHour: this.registerForm.value.beginHourIntervention,
                    endHour: this.registerForm.value.endHourIntervention,
                    type: AppointmentType.CONFIRMED_HOME_REPAIR_APPOINTMENT
                };
            } else {
                confirmedDate = {
                    day: this.registerForm.value.wishedDay.format('YYYY-MM-DD'),
                    beginHour: this.beginHour,
                    endHour: this.endHour,
                    type: AppointmentType.CONFIRMED_HOME_REPAIR_APPOINTMENT
                };
            }
        } else {
            confirmedDate = this.wishesAppointmentSelected;
            confirmedDate.type = AppointmentType.CONFIRMED_HOME_REPAIR_APPOINTMENT;
        }
        this.inputMap.emit({
            'action': WorkflowAction.APPOINTMENT_CONFIRMATION,
            'confirmedDate': JSON.stringify(confirmedDate)
        });
    }

    cancelIntervention(): void {
        this.dialog.open(DialogDataCancelInterventionComponent).afterClosed().subscribe(cancelComment => {
            if (cancelComment) {
                this.commentGiven.emit({
                    value: cancelComment,
                    type: CommentType.COMMENT_CANCEL_INTERVENTION
                });

                this.inputMap.emit({
                    'action': WorkflowAction.APPOINTMENT_CANCELLATION,
                    'agentCommentsCancelIntervention': cancelComment,
                });
            }
        });
    }

    selectOptionDate(option): void {
        switch (option.value) {
            case 'MORNING': {
                this.beginHour = '08:00:00';
                this.endHour = '13:00:00';
                this.registerForm.controls.beginHourIntervention.setValue('08:00:00');
                this.registerForm.controls.endHourIntervention.setValue('13:00:00');
                this.registerForm.controls.beginHourIntervention.disable();
                this.registerForm.controls.endHourIntervention.disable();
                break;
            }

            case 'AFTERNOON': {
                this.beginHour = '14:00:00';
                this.endHour = '18:00:00';
                this.registerForm.controls.beginHourIntervention.setValue('14:00:00');
                this.registerForm.controls.endHourIntervention.setValue('18:00:00');
                this.registerForm.controls.beginHourIntervention.disable();
                this.registerForm.controls.endHourIntervention.disable();
                break;
            }
            case 'OTHER_DATE': {
                this.registerForm.controls.beginHourIntervention.setValue(null);
                this.registerForm.controls.endHourIntervention.setValue(null);
                this.registerForm.controls.beginHourIntervention.enable();
                this.registerForm.controls.endHourIntervention.enable();
                break;
            }
        }
    }

}
