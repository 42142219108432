import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-accessories-label-list',
    templateUrl: './accessories-label-list.component.html',
    styleUrls: ['./accessories-label-list.component.scss']
})
export class AccessoriesLabelListComponent {

    @Input() accessoriesLabel: string[];

}
