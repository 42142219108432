import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-visio-modal',
    templateUrl: './visio-modal.component.html',
    styleUrls: ['./visio-modal.component.css']
})
export class VisioModalComponent implements OnInit {

    @ViewChild('ngFormQuotation') ngFormQuotation: NgForm;
    protected _onDestroy = new Subject<void>();

    constructor(private dialogRef: MatDialogRef<VisioModalComponent>,
                @Inject(MAT_DIALOG_DATA) public roomUrl: any) {
    }

    ngOnInit(): void {
    }

    onClose(): void {
        this.dialogRef.close(null);
    }
}
