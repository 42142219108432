import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {FolderPublic} from '../../../../models/folder.public.model';
import {NgForm} from '@angular/forms';
import {PublicQuotation, PublicQuotationLine} from '../../../../models/quotation.model';
import {Amount} from '../../../../models/amount.model';
import {TypeOfServicePublicRepairerEnum} from '../../../../models/typeOfService.model';
import {Observable} from 'rxjs';
import {Vat} from '../../../../models/vat.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {TranslateService} from '@ngx-translate/core';
import {SparePartService} from '../../../../shared/services/spare-part.service';
import {currentLanguage, vats} from '../../../../store/organization/organization.selectors';
import {QuotationStatus} from '../../../../models/enums/quotationStatus.enum';
import {CountryConfigurationSetSuccessfully} from '../../../../store/organization/organization.actions';
import {PublicService} from '../../../../shared/services/public.service';
import {FileInfo} from '../../../../models/file-info.model';
import {FileService} from '../../../../shared/services/file.service';
import {saveAs} from 'file-saver';

@Component({
    selector: 'app-public-quotation-acceptation-distributor',
    templateUrl: './public-quotation-acceptation-distributor.component.html',
    styleUrls: ['./public-quotation-acceptation-distributor.component.scss']
})
export class PublicQuotationAcceptationDistributorComponent implements OnInit {

    @Input() folder: FolderPublic;
    @ViewChild('dropzone') dropzone: ElementRef;
    @ViewChild('ngFormQuotation') ngFormQuotation: NgForm;

    attachments = new Array<any>();

    displayedColumnsAttachment: string[] = ['name'];
    displayedColumns: string[] = ['code', 'label', 'type', 'price', 'quantity', 'discount', 'vat', 'totalPrice'];

    quotation: PublicQuotation;
    quotationLines = new MatTableDataSource<PublicQuotationLine>();

    serviceTypes = Object.keys(TypeOfServicePublicRepairerEnum);
    attachmentQuotationDataTable = new MatTableDataSource<any>();
    isLoading = false;
    currency: string;
    vatsList$: Observable<Vat[]>;
    totalPriceTTC: Amount;
    totalPriceHTAfterDiscount: Amount;
    totalDiscount: Amount;
    totalPriceHT: Amount;
    totalVAT: Amount;

    constructor(private store$: Store<AppState>,
                private _translateService: TranslateService,
                private publicService: PublicService,
                private fileService: FileService,
                private sparePartService: SparePartService) {
        this.serviceTypes = Object.keys(TypeOfServicePublicRepairerEnum);

    }

    ngOnInit(): void {
        this.loadCountryConfiguration();
        this.initLanguage();
        this.initVat();
        this.currency = this.folder.currency;
        this.initQuotation();
    }

    private initVat() {
        this.vatsList$ = this.store$.pipe(select(vats));
    }

    private initLanguage() {
        this.store$.pipe(select(currentLanguage)).subscribe(
            language => {
                this._translateService.use(language);
            }
        );
    }

    private loadCountryConfiguration() {
        this.publicService.getByCountryCode(this.folder.externalUid).subscribe(countryConfiguration => {
            this.store$.dispatch(new CountryConfigurationSetSuccessfully(countryConfiguration));
        });
    }

    private initQuotation(): void {
        this.quotation = this.folder.publicQuotations.find(quotation => quotation.status === QuotationStatus.DRAFT);
        if (this.quotation) {
            this.attachmentQuotationDataTable.data = this.quotation.quotationAttachments;
            this.quotationLines = new MatTableDataSource<PublicQuotationLine>(this.quotation.quotationLines);
            this.totalPriceTTC = !!this.quotation?.totalPrice ? this.quotation.totalPrice : this.getZeroAmount();
            this.totalPriceHTAfterDiscount = !!this.quotation?.totalPriceHTAfterDiscount ? this.quotation.totalPriceHTAfterDiscount : this.getZeroAmount();
            this.totalDiscount = !!this.quotation?.totalDiscount ? this.quotation.totalDiscount : this.getZeroAmount();
            this.totalPriceHT = !!this.quotation?.totalPriceHT ? this.quotation.totalPriceHT : this.getZeroAmount();
            this.totalVAT = !!this.quotation?.totalVAT ? this.quotation.totalVAT : this.getZeroAmount();

        }
    }

    private getZeroAmount() {
        return {
            value: 0,
            currency: this.currency
        };
    }

    normalize(value): number {
        return this.sparePartService.normalizeFloat(value);
    }

    downloadFile(file: FileInfo): void {
        this.fileService.getPublicAttachmentFile(file.id, this.folder.externalUid).subscribe(value => {
            saveAs(value, file.name);
        });
    }

}
