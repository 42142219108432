import {Directive, ElementRef, HostListener} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {GLOBAL} from "../../app/app-config";

@Directive({
    selector: '[alphanumeric]',
})
export class AlphanumericDirective {

    public constructor(private el: ElementRef) {
    }

    private specialKeys: Array<string> = ['Backspace', 'Space', 'Tab', 'End', 'Home'];

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(event.key);
        if (next && !String(next).match( GLOBAL.validationPatterns.serialNumber)) {
            event.preventDefault();
        }
    }
}