<div [ngClass]="sendingInProgress ? 'read-only' : ''">
    <form [formGroup]="notificationForm.form" fxLayout="column"
          fxLayoutAlign="start">
        <mat-card-title class="h3 mt-12 mb-24">
            {{'FOLDER.NOTIFICATION.SEND_PROTOCOL_NOTIFICATION' | translate}}
        </mat-card-title>
        <mat-form-field class="py-4" appearance="outline" fxFlex="50">
            <mat-label>{{'FOLDER.NOTIFICATION.FORMULA'|translate}}</mat-label>
            <input matInput placeholder="{{'NOTIFICATION.FORMULA'|translate}}"
                   formControlName="formula" required>
        </mat-form-field>
        <fieldset class="w-100-p">
            <legend class="h3 mt-20 title-fieldset">
                {{'FOLDER.NOTIFICATION.UPLOAD_TEMPLATE'|translate}}</legend>
            <div class="w-100-p mt-12" fxLayout="row wrap"
                 gdColumns="repeat(auto-fit, minmax(100%, 1fr))"
                 gdGap="10px">
                <ng-container *ngIf="this.notificationForm.template=== null else printFile">
                        <mat-card class="mat-elevation-z2 mt-4 mb-16" fxFlex="30" fxFlex.lt-md="100">
                            <ngx-dropzone
                                #dropzone class="h-120"
                                label="{{!!this.fileDetail && !!this.fileDetail.info ? this.fileDetail.info.name: 'FOLDER.FILE.UPLOAD_MSG' | translate}}"
                                [showPreviews]="true" [multiple]="false" [accept]="'*'" (filesAdded)="onUploadFile($event)">
                            </ngx-dropzone>
                        </mat-card>

                </ng-container>
                <ng-template #printFile>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxLayoutAlign="end center" fxLayoutGap="20px">
                            <ng-container *ngIf="!!fileDetail">
                                <h4 class="text-bold">{{fileDetail.info.name}}</h4>
                            </ng-container>
                            <mat-icon color="warn" class="cursor-pointer"
                                      (click)="deleteImage()">
                                delete_forever
                            </mat-icon>
                        </div>
                    </div>
                </ng-template>

            </div>
        </fieldset>

        <div *ngIf="sendingInProgress">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <mat-card-footer>
            <div class="mb-20" fxLayout="row" fxLayoutAlign="end center">
                <button [disabled]="!isFormValid()"
                        [ngClass]="!isFormValid() || sendingInProgress ?'aster_btn_disabled':'aster_btn'"
                        class="mt-4 button-height"
                        mat-stroked-button
                        (click)="onSubmit()">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
                <button (click)="closeDialog()" class="aster_btn_cancel mt-4 button-height ml-8" mat-stroked-button
                        type="reset">
                    {{'BUTTON.CANCEL' | translate}}
                </button>
            </div>
        </mat-card-footer>
    </form>
</div>