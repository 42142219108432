import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {WorkflowAction} from '../../../../../models/enums/workflowAction.enum';
import {MatRadioChange} from '@angular/material/radio';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-repair-in-progress-by-customer',
    templateUrl: './repair-in-progress-by-customer.component.html',
    styleUrls: ['./repair-in-progress-by-customer.component.css']
})
export class RepairInProgressByCustomerComponent implements OnInit {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();
    reparationInProgress: any = {
        form: null,
        placeholders: []
    };

    additionalServices = [];

    constructor() {
    }

    ngOnInit(): void {
        this.initForm();
        this.initAdditionalServices();
    }

    private initForm(): void {
        this.reparationInProgress.form = new FormGroup({
            productRepaired: new FormControl(null, Validators.required),
        });
        this.reparationInProgress.placeholders = [
            {status: WorkflowAction.PRODUCT_REPAIRED_BY_CUSTOMER}, {status: WorkflowAction.CUSTOMER_REQUEST_NEW_SERVICE}
        ];

    }

    private initAdditionalServices(): void {
        this.additionalServices = ['VISIO_REPAIR', 'HOME_REPAIR'];
    }

    onSubmit(): void {
        const reparationResult = this.reparationInProgress.form.value.productRepaired;
        if (reparationResult === WorkflowAction.PRODUCT_REPAIRED_BY_CUSTOMER) {
            // close the folder
            this.inputMap.emit({
                'productRepaired': true
            });
        } else {
            // pass to the list of available services
            this.inputMap.emit({
                'productRepaired': false,
                'additionalService': this.reparationInProgress.form.get('service').value
            });
        }

    }

    handleChange($event: MatRadioChange): void {
        if ($event.value === WorkflowAction.CUSTOMER_REQUEST_NEW_SERVICE) {
            this.reparationInProgress.form.addControl('service', new FormControl(null, Validators.required));
        } else {
            this.reparationInProgress.form.removeControl('service');
        }


    }

    serviceChanged($event: MatRadioChange): void {
        console.log($event);
    }

}
