import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BackOfficeService} from '../../../../../shared/services/back-office.service';
import {FolderSubjectService} from '../../../folder-subject.service';
import {Unsubscriber} from '../../../../../unsubscriber';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

export const AGREEMENT_NUMBER = 'agreementNumber';
export const CHOICE = 'choice';
@Component({
  selector: 'app-old-waiting-reparation-agreement',
  templateUrl: './old-waiting-reparation-agreement.component.html',
  styleUrls: ['./old-waiting-reparation-agreement.component.scss']
})
export class OldWaitingReparationAgreementComponent  extends Unsubscriber implements OnInit {
    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;
    folder: Folder;
    public reparationAgreementForm: FormGroup;

    constructor(private backOfficeService: BackOfficeService,
                private folderSubjectService: FolderSubjectService) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
        });
        this.createForm();
    }

    onSubmit(): void {
        this.inputMap.emit({
            'confirmSwap' : !this.reparationAgreementForm.value.choice, // refused => confirmSwap
            'reparationAgreementNumber' : this.reparationAgreementForm.value.agreementNumber
        });
    }

    private createForm(): void {
        this.reparationAgreementForm = new FormGroup({
            choice: new FormControl(null, [Validators.required])
        });
    }

    onChoiceChange(value: boolean): void {
        this.reparationAgreementForm.get(CHOICE).setValue(value);
        if (value) {
            this.reparationAgreementForm.addControl(AGREEMENT_NUMBER, new FormControl(null, [Validators.required]));
        } else {
            this.reparationAgreementForm.removeControl(AGREEMENT_NUMBER);
        }
    }

    displayAgreementNumber(): boolean {
        return this.reparationAgreementForm.contains(AGREEMENT_NUMBER);
    }
    getTaskDuration(): string {
        return  this.variablesTask['reparationAgreementDate'];
    }

    getStatusDate(): string {
        return this.folder.currentWorkflowStatus.workflowStatusDate;
    }
}
