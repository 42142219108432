import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {SparePartsOrderModalComponent} from '../../../../../shared/generic/spare-parts-order-modal/spare-parts-order-modal.component';
import {VisioReport} from '../../../../../models/visio/visio-report.model';
import {SelectionModel} from '@angular/cdk/collections';
import {ServiceType} from '../../../../../models/enums/serviceType.enum';
import * as moment from 'moment';
import {TaskVariables} from '../../task.variables';
import {TranslateService} from '@ngx-translate/core';
import {AppState} from '../../../../../store/app.state';
import {select, Store} from '@ngrx/store';
import {currentUser} from '../../../../../store/user/user.selectors';
import {Unsubscriber} from '../../../../../unsubscriber';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-visio',
    templateUrl: './waiting-visio.component.html',
    styleUrls: ['./waiting-visio.component.css']
})
export class WaitingVisioComponent extends Unsubscriber implements OnInit {

    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    visioQualificationForm: any = {
        form: null,
        placeholders: [],
        services: [],
    };

    visioReport: VisioReport;
    sparePartLineColumns: string[] = ['reference', 'label', 'price'];
    sparePartDataTable = new MatTableDataSource<any>();
    private organizationCode: string;
    sparePartsIsSelected = false;
    selection = new SelectionModel<any>(true, []);

    taskDefinitionKey: string;
    context: string;
    currency: string;

    constructor(private translateService: TranslateService,
                private store$: Store<AppState>,
                private matDialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(currentUser)).subscribe(currentUser => {
            this.organizationCode = currentUser.organizationCode;
            this.context = currentUser.context;
        });
        if (!!this.variablesTask[TaskVariables.serviceType]) {
            this.taskDefinitionKey = this.variablesTask[TaskVariables.serviceType];
        }
        this.currency = this.variablesTask[TaskVariables.currency];

        this.initForm();
    }

    changeModeOp(event: any, modeOpSelected: string): void {
        if (event) {
            if (modeOpSelected === ServiceType.SPARE_PARTS) {
                this.selection.toggle(modeOpSelected);
            } else {
                this.selection.selected.forEach(element => {
                    if (element !== ServiceType.SPARE_PARTS) {
                        this.selection.deselect(element);
                    }
                });
                this.selection.select(modeOpSelected);
            }
        }
        this.sparePartsIsSelected = this.selection.isSelected(ServiceType.SPARE_PARTS);
    }

    private initForm(): void {
        this.visioQualificationForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
        });

        this.visioQualificationForm.placeholders = [
            {
                status: 'FINISHED',
                value: 'COMPONENT.PRODUCT_REPAIRED',
            },
            {
                status: 'APPOINTMENT_RESCHEDULE',
                value: 'COMPONENT.APPOINTMENT_RESCHEDULE',
            },
            {
                status: 'APPOINTMENT_CANCELLED',
                value: 'COMPONENT.APPOINTMENT_CANCELLED',
            },
            {
                status: 'NOT_REPAIRED',
                value: 'COMPONENT.NEW_SERVICES',
            }
        ];

        this.visioQualificationForm.services = [
            {
                status: ServiceType.VISIO_REPAIR,
                value: 'COMPONENT.SERVICE.VISIO_REPAIR',
            },
            {
                status: ServiceType.HOME_REPAIR,
                value: 'COMPONENT.SERVICE.HOME_REPAIR',
            },
            {
                status: ServiceType.SPARE_PARTS,
                value: 'COMPONENT.SERVICE.SPARE_PARTS',
            }
        ];
    }

    onSubmit(): void {
        this.visioReport = {
            requestedServices: this.selection.selected,
            spareParts: this.sparePartsIsSelected && this.sparePartDataTable ? this.sparePartDataTable.data : null,
        };

        this.inputMap.emit({
            reparationStatus: this.visioQualificationForm.form.value.status,
            visioReport: JSON.stringify(this.visioReport)
        });
    }

    openSparePartOrderModal(): void {
        const initData = {
            sparePartLine: this.sparePartDataTable.data,
            organizationCode: this.organizationCode,
            context: this.context,
            currency: this.currency
        };
        const dialogRef = this.matDialog.open(SparePartsOrderModalComponent, {
            height: '70vh', width: '100vh', minWidth: '80%', data: initData
        });
        dialogRef.afterClosed().subscribe(dataReport => {
                if (!!dataReport) {
                    this.sparePartDataTable.data = dataReport.spareLine;
                }
            }
        );
    }

    disabledButtonForm(): boolean {
        if (!!this.visioQualificationForm.form.value.status && this.visioQualificationForm.form.value.status !== 'NOT_REPAIRED') {
            return false;
        }
        if (this.visioQualificationForm.form.value.status === 'NOT_REPAIRED') {
            if (this.sparePartsIsSelected && this.sparePartDataTable.data.length > 0) {
                return false;
            }

            if (this.selection.selected.length > 0 && this.selection.selected.filter(value => value === ServiceType.SPARE_PARTS).length === 0) {
                return false;
            }
        }
        return true;
    }

    checkIfSparePart(service: string): void {
        if (service === ServiceType.SPARE_PARTS) {
            this.sparePartsIsSelected = true;
        }
    }

    getDescription = () => {
        const beginDate = moment(this.variablesTask[TaskVariables.BeginDateAppointmentVisio], 'YYYY-MM-DD HH:mm');
        return this.translateService.stream('COMPONENT.WAITING_VISIO.DESCRIPTION', {
            startDate: beginDate.format('YYYY-MM-DD'),
            startHour: beginDate.format('HH:mm')
        });
    };
}
