import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-repairer-to-be-confirmed',
    templateUrl: './repairer-to-be-confirmed.component.html',
    styleUrls: ['./repairer-to-be-confirmed.component.css']
})
export class RepairerToBeConfirmedComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();
    @Input() instructionUserTask: InstructionUserTask;
    @Input() folder: Folder;

    commentControl = new FormControl('');

    constructor() {
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        if (this.commentControl.value.length > 0) {
            this.commentGiven.emit({value: this.commentControl.value});
        }
        this.inputMap.emit({});
    }
}
