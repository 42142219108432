import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GLOBAL} from '../../app-config';
import {IWorkflowProcess} from '../../models/workflow.model';
import {Survey} from '../../models/survey.model';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class WorkflowService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api`;
    public refreshSurveyFolder: EventEmitter<Survey> = new EventEmitter<Survey>();

    constructor(private http: HttpClient) {
    }

    /* Current Task Workflow */
    getCurrentTaskWorkflow(folderId: string): Promise<IWorkflowProcess> {
        return this.http.get<IWorkflowProcess>(`${this.path}/workflow/task/current/${folderId}`).toPromise();
    }

    /* Complete Task Workflow */
    getCompleteTaskWorkflow(folderId: string, mapInput: any): Promise<any> {
        return this.http.put<any>(`${this.path}/workflow/task/complete/${folderId}`, mapInput).toPromise();
    }


    /* get Preview task Workflow */
    getPreviewsTaskWorkflow(folderId: string, activityMap: any): Promise<IWorkflowProcess> {
        return this.http.put<IWorkflowProcess>(`${this.path}/workflow/task/previews/${folderId}`, activityMap).toPromise();
    }

    switchPaymentMode(folderId: string): Observable<any> {
        return this.http.put(`${this.path}/workflow/task/switch-payment-mode/${folderId}`, {});
    }

    /* update QuotationId variable process Workflow */
    updateQuotationSuggestionId(folderId: string, quotationId: string): Observable<any> {
        return this.http.put(`${this.path}/workflow/task/variables/update/quotationSuggestionId/${folderId}/${quotationId}`, {});
    }

    updateRepairCenterCode(folderId: string, repairCenterCode: string): Observable<any> {
        return this.http.put(`${this.path}/workflow/task/variables/repairCenterCode/${folderId}/${repairCenterCode}`, {});
    }

    updateQuotationDistributorId(folderId: string, quotationId: string): Observable<any> {
        return this.http.put(`${this.path}/workflow/task/variables/update/quotationDistributorId/${folderId}/${quotationId}`, {});
    }

    getPreviousWorkflowTaskList(savFolderId: string, currentTask: string): Observable<string[]> {
        return this.http.get<string[]>(`${this.path}/workflow/task/list/previous/${savFolderId}?currentTask=${currentTask}`);
    }

    updateQuotationId(folderId: string, quotationId: string): Observable<any> {
        return this.http.put(`${this.path}/workflow/task/variables/update/quotationId/${folderId}/${quotationId}`, {});
    }
}
