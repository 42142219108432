<form [formGroup]="formGroup">
    <div class="unknown-product" *ngIf="this.unknownProductFeatureActive" fxLayout="row" fxLayoutAlign="center center">
        <span class="link-underline" (click)="unknownProduct()" style="margin-right: 5px;">
        {{ 'PRODUCT.SEARCH.UNKNOWN' | translate }}
        </span>
        <mat-icon matTooltip="{{ 'UNKNOWN.PRODUCT.HELP_TEXT' | translate }}">error_outline</mat-icon>
    </div>


    <ng-container *ngIf="prefixOrganizationCode === 'LM'">
        <div fxLayout="row">
            <mat-form-field class="filter w-100-p"
                            floatLabel="never" fxFlex appearance="outline">
                <mat-label>{{this.fields.productCode.label | translate}}</mat-label>
                <input matInput formControlName="productCode" [placeholder]="this.fields.productCode.label | translate"
                       (change)="fieldSelected('productCode', $event)" >
            </mat-form-field>

            <div class="search-container">
                <div class="icon-container">
                    <mat-icon (click)="toggleAdditionalSearchInputs()"
                              class="plus-icon">{{ showAdditionalSearchInputs ? 'remove' : 'add' }}</mat-icon>
                </div>
                <label class="additional-criteria-label">{{ "COMPONENT.PRODUCT_SEARCH.ADDITIONAL_CRITERIA" | translate }}</label>
            </div>
        </div>

        <ng-container *ngIf="showAdditionalSearchInputs">
            <div fxLayout="column" fxLayoutAlign="space-around none">
                <div fxLayout="row">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>{{this.fields.eanCode.label | translate}}</mat-label>
                        <input matInput formControlName="eanCode" [placeholder]="this.fields.eanCode.label | translate"
                               (change)="fieldSelected('eanCode', $event)">
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>{{this.fields.manufacturerReference.label | translate}}</mat-label>
                        <input matInput formControlName="manufacturerReference"
                               [placeholder]="this.fields.manufacturerReference.label | translate"
                               (change)="fieldSelected('manufacturerReference', $event)">
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>{{this.fields.productLabel.label | translate}}</mat-label>
                        <input matInput formControlName="productLabel"
                               [placeholder]="this.fields.productLabel.label | translate"
                               (change)="fieldSelected('productLabel', $event)">
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                    <div class="dropdown-container">
                        <app-product-supplier-field
                            [isSearch]="true"
                            (save)="updateSupplier($event)"
                            [disable$]="this.disableSupplier$" ></app-product-supplier-field>
                    </div>

                    <div class="dropdown-container" style="margin-left: 10px;">
                        <app-product-brand
                            [disable$]="this.disableBrand$"
                            (selectedBrand)="updateBrand($event)"></app-product-brand>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="prefixOrganizationCode === 'NR'">
        <div fxLayout="row">
            <mat-form-field class="filter w-100-p" floatLabel="never">
                <input matInput formControlName="productCode" [placeholder]="this.fields.productCode.label | translate"
                       (keyup.enter)="searchWithProductCode()">
                <mat-icon matSuffix class="disabled-text cursor-pointer"
                          (click)="searchWithProductCode()">
                    search
                </mat-icon>
            </mat-form-field>
            <app-product-identification *ngIf="isSearchProductPerLabelActive" fxFlex="50"
                                        (productCtrlEmitter)="identificationProductChanged($event)">
            </app-product-identification>
        </div>
    </ng-container>

    <ng-container *ngIf="prefixOrganizationCode === 'OTHERS'">
        <mat-form-field class="filter w-100-p" floatLabel="never">
            <input matInput formControlName="productCode" [placeholder]="this.fields.productCode.label | translate"
                   (keyup.enter)="searchWithProductCode()">
            <mat-icon matSuffix class="disabled-text cursor-pointer"
                      (click)="searchWithProductCode()">
                search
            </mat-icon>
        </mat-form-field>
    </ng-container>

    <ng-container *ngIf="prefixOrganizationCode === 'LM'">
        <div fxLayoutAlign="end center" fxLayoutGap="10px" class="mt-12">
            <a (click)="cleanAllForms()"
               class="cursor-pointer px-24 mr-8">
                {{'FOLDER.CREATE.BUTTON_CANCEL' | translate}}
            </a>
            <button type="submit" mat-stroked-button color="accent" (click)="searchProduct(0)"
                    id="create_folder_submitButton" [disabled]="this.disableSearch"
                    class="py-12 px-24 mb-8 mr-8 font-weight-900 font-size-30">
                {{'COMPONENT.PRODUCT_SEARCH.SEARCH_BUTTON' | translate}}
            </button>
        </div>
    </ng-container>
</form>
