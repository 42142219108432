<div fxLayout="row" fxLayoutAlign="stretch" fxLayoutGap="10px">
    <fieldset *ngIf="!!product" fxFlex>
        <legend class="h3 title-fieldset">{{'FOLDER.PRODUCT.LABELS'|translate}}</legend>
        <div fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutAlign.lt-md="start start">
            <div class="w-160 h-160 zone-product_img mat-elevation-aster"
                 fxLayout="row" fxLayoutAlign="center center">

                <app-product-image [image]="product.imageUrl"
                                   [loaderCss]="'w-60 h-60'"
                                   [imageCss]="'h-160-max'"></app-product-image>

            </div>
            <div fxLayout="column" class="m-24" fxFlex>
                <ng-container *ngIf="!( ( isLeroyMerlin$ | async) && isSupplierUnknown ) else GENERIC_PRODUCT">
                    <app-product-preview-card
                        [purchaseDate]="purchaseDate"
                        [product]="product">
                    </app-product-preview-card>
                </ng-container>
                <ng-template #GENERIC_PRODUCT>
                    <app-generic-product-preview-card
                        [product]="product">
                    </app-generic-product-preview-card>
                </ng-template>

                <div *ngIf="product.brand" fxLayout="row" fxLayoutGap="15px" class="mt-8">
                    <strong> {{'PRODUCT.BRAND' | translate}}: </strong>
                    <span>{{product.brand}}</span>
                </div>
                <ng-container *ngIf="!!product.manufacturerReference">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8">
                        <strong> {{'PRODUCT.MANUFACTURER_REFERENCE' | translate}}:</strong>
                        <span>{{product.manufacturerReference}}</span>
                    </div>
                </ng-container>

                <app-product-editable-field label="PRODUCT.PREVIEW_CARD.LABELS.QUANTITY"
                                            [invalidMessage]="'PRODUCT.PREVIEW_CARD.LABELS.INVALID_QUANTITY' | translate: {maxQuantity: maxQuantityMessage()}"
                                            type="number"
                                            [minValue]="1"
                                            [maxValue]="maxQuantity"
                                            [isEditable]="isEditableQuantity"
                                            [value]="1"
                                            [editMode]="quantity > 1"
                                            (save)="updateQuantity($event)">
                </app-product-editable-field>

                <ng-container *ngIf="!!product.sellingPrice">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="mt-8">
                        <strong> {{'FOLDER.PRODUCT.SELLING_PRICE' | translate}}:</strong>
                        <span>{{product.sellingPrice.value | currency: product.sellingPrice.currency :'symbol-narrow'}}</span>
                    </div>
                </ng-container>

                <ng-container *ngIf="isNeitherProductRecallNorReparationContext$ | async">
                    <ng-container *ngIf="(warranty$ | async)?.regimeWarrantyCode === 'SG'; else OUT_OF_WARRANTY">
                        <div fxLayout="row" class="mt-8">
                            <mat-icon class="s-20 teal-600-fg">beenhere</mat-icon>
                            {{'SG' |translate}}
                        </div>
                    </ng-container>

                    <ng-template #OUT_OF_WARRANTY>
                        <div fxLayout="row" class="mt-8">
                            <mat-icon class="s-20 rotate-270 warn-A700-fg">
                                backspace
                            </mat-icon>
                            {{'HG' |translate}}
                        </div>
                    </ng-template>
                </ng-container>
                <div fxLayout="column" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px"
                     class="mt-8"
                     *ngIf="!product.afterSalesEligible">
                        <span
                            class="product-text-color ml-12 at-body-1">{{"PRODUCT.ITEM_CARD_NOT_SALES_ELIGIBLE"|translate}}</span>
                </div>
            </div>

        </div>
    </fieldset>
</div>