<div fxLayout="row" fxLayout.lt-lg="row" fxLayout.lt-md="column" fxLayoutGap="8px" fusePerfectScrollbar>
    <div fxFlex="50">
        <app-public-detail-tabs-card [folder]="folder"></app-public-detail-tabs-card>
    </div>
    <div fxFlex="50">
        <mat-card class="mat-elevation-aster">

            <mat-card-content *ngIf="repairStatusForm.form">
                <form [formGroup]="repairStatusForm.form" fxLayout="column" fxLayoutAlign="start"
                      (ngSubmit)="onSubmit()">
                    <mat-card-title
                        class="h3 mt-12 mb-24">{{'COMPONENT.REPAIR_IN_PROGRESS' | translate}}</mat-card-title>

                    <mat-radio-group formControlName="status" class="ml-20" labelPosition="after" fxLayout="column"
                                     fxLayoutAlign="start stretch" fxLayoutGap="10px">
                        <mat-radio-button class="full-width-radio" *ngFor="let status of repairStatusForm.placeholders"
                                          [value]="status.status">
                            <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                <div fxFlex>
                                    <div>{{ status.value | translate }}</div>
                                </div>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>

                    <ng-container *ngIf="repairStatusForm.form.value.status === 'FINISHED'">

                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch"
                             fxLayoutGap="12px">
                            <mat-form-field appearance="outline" fxFlex="50">
                                <mat-label>{{'FOLDER.TRACKING_NUMBER'|translate}}</mat-label>
                                <input matInput placeholder="{{'FOLDER.TRACKING_NUMBER'|translate}}"
                                       formControlName="trackingNumber">
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50">
                                <mat-label>{{'FOLDER.CARRIER_CODE'|translate}}</mat-label>
                                <input matInput placeholder="{{'FOLDER.CARRIER_CODE'|translate}}"
                                       formControlName="carrierCode">
                            </mat-form-field>

                        </div>

                        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                            <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="12px">
                                <label class="h3 text-bold">
                                    {{'FOLDER.REPAIR_IN_PROGRESS.LIST_SPARE_PARTS' | translate}}
                                </label>
                                <button type="button" mat-stroked-button class="aster-btn_border"
                                        (click)="openQuotationModal()">
<!--                                    <ng-container-->
<!--                                        *ngIf="reportLineDataTable.data.length>0 || irisDataTable.data.length>0;else SHOW_ADD_BUTTON">-->
                                    <ng-container
                                        *ngIf="reportLineDataTable.data.length>0;else SHOW_ADD_BUTTON">
                                        {{'BUTTON.EDIT' | translate}}
                                    </ng-container>
                                    <ng-template #SHOW_ADD_BUTTON>
                                        {{'BUTTON.ADD' | translate}}
                                    </ng-template>

                                </button>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                                <table mat-table [dataSource]="reportLineDataTable"
                                       class="mat-elevation-z2 w-100-p mt-16 mb-16">
                                    <ng-container matColumnDef="code">
                                        <th mat-header-cell *matHeaderCellDef
                                            class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.CODE' | translate}}  </th>
                                        <td mat-cell *matCellDef="let sparePart"
                                            class="text-center"> {{sparePart.code}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="type">
                                        <th mat-header-cell *matHeaderCellDef
                                            class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.TYPE' | translate}}  </th>
                                        <td mat-cell
                                            *matCellDef="let sparePart"
                                            class="text-center"> {{'MODAL.QUOTATION.TYPE.' + sparePart.type | translate}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="label">
                                        <th mat-header-cell *matHeaderCellDef
                                            class="text-center">  {{'FOLDER.REPAIR_IN_PROGRESS.LABEL' | translate}}  </th>
                                        <td mat-cell *matCellDef="let sparePart"
                                            class="text-center"> {{sparePart.label}} </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="reportLineColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: reportLineColumns;"></tr>
                                </table>

                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="isNotRepairable()">
                        <div fxLayout="column" fxLayoutAlign="space-between none">
                            <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                                <mat-label>{{'FOLDER.COMMENT_FORM.LABEL' | translate}}</mat-label>
                                <textarea matInput rows="10" cols="7"
                                          [placeholder]="'FOLDER.COMMENT_FORM.PLACEHOLDER' | translate"
                                          formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- attachments -->
                    <app-files-list
                        [displayedColumns]="displayedColumns"
                        [attachmentsTypes]="allowedAttachmentTypes"
                        [(data)]="attachments"></app-files-list>

                    <div fxLayoutAlign="end center" class="mt-24">
                        <button type="submit" mat-stroked-button [disabled]="disabledButtonForm()"
                                [ngClass]="disabledButtonForm() ? 'aster_btn_disabled' : 'aster_btn'">
                            {{'BUTTON.VALIDATE' | translate}}
                        </button>
                    </div>
                </form>

                <mat-chip-list class="w-50-p">
                    <mat-chip color="primary" class="upload-image_style"
                              (click)="onFileUpload()">
                        <mat-icon class="mr-8">camera_alt</mat-icon>
                        <span
                            class="ml-4">{{'COMPONENT.REPAIR_IN_PROGRESS.UPLOAD_ATTACHMENTS'|translate}}</span>
                    </mat-chip>
                </mat-chip-list>

            </mat-card-content>
        </mat-card>
    </div>
</div>