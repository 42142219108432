<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="text-bold">
        {{('CONFIRMATION.SEND.EXTERNALSYSTEM.TITLE' | translate) }} {{ systemName}}
    </h2>
    <button mat-icon-button class="ml-40" (click)="dialogRef.close(false)">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mt-25">
    <div fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="w-100-p">
            <mat-label>{{'CONFIRMATION.SEND.EXTERNALSYSTEM.LABEL' | translate}}</mat-label>
            <input matInput [placeholder]="'CONFIRMATION.SEND.EXTERNALSYSTEM.PLACEHOLDER' | translate"
                   [(ngModel)]="event">
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end end">
    <div fxLayout="row">
        <button class="aster_btn_cancel" (click)="dialogRef.close(false)">
            {{'CONFIRMATION.SEND.EXTERNALSYSTEM.CANCEL' | translate}}
        </button>
        <button [disabled]="!event" (click)="validate()"
                [ngClass]="event? 'aster_btn' : 'aster_btn_disabled'">
            {{'CONFIRMATION.SEND.EXTERNALSYSTEM.VALIDATE' | translate}}
        </button>
    </div>
</mat-dialog-actions>

