import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../models/folder.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from '../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-assistance-to-call',
    templateUrl: './waiting-assistance-to-call.component.html',
    styleUrls: ['./waiting-assistance-to-call.component.css']
})
export class WaitingAssistanceToCallComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    @Output() previewTask = new EventEmitter<any>();

    ableToRepairForm: any = {
        form: null,
        placeholders: []
    };

    constructor(private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.ableToRepairForm.form = new FormGroup({
            choice: new FormControl(null, Validators.required),
        });
        this.ableToRepairForm.placeholders = [
            {
                choice: 'true',
                value: 'BUTTON.CUSTOMER_ABLE_TO_REPAIR',
            },

            {
                choice: 'false',
                value: 'BUTTON.CUSTOMER_NEED_TECHNICIAN',
            }
        ];
    }

    onSubmit(): void {
        if (this.ableToRepairForm.form.value.choice === 'true') {
            this.completeTask();
        } else {
            this.confirmedChoiceCustomerNeedTechnician();
        }
    }

    completeTask(): void {
        this.inputMap.emit({
            'ableToRepair': this.ableToRepairForm.form.value.choice
        });
    }

    confirmedChoiceCustomerNeedTechnician(): void {
        const dialogRef: MatDialogRef<FuseConfirmDialogComponent> = this.dialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });
        dialogRef.componentInstance.title = 'FOLDER.MODAL.CUSTOMER_NEED_TECHNICIAN.TITLE';
        dialogRef.componentInstance.message = 'FOLDER.MODAL.CUSTOMER_NEED_TECHNICIAN.MESSAGE';
        dialogRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.completeTask();
            }
        });
    }


    getPreviewTask(): void {
        this.previewTask.emit({
            'currentTask': 'WAITING_ASSISTANCE_TO_CALL',
            'previewsTask': 'SUGGEST_PIECE_AND_REPAIR_TUTO'
        });
    }

}
