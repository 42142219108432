import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-refund-protocol-sent',
    templateUrl: './refund-protocol-sent.component.html',
    styleUrls: ['./refund-protocol-sent.component.css']
})
export class RefundProtocolSentComponent implements OnInit {
    signedProtocolReceived: boolean;
    @Output() inputMap = new EventEmitter<any>();
    @Input() instructionUserTask: InstructionUserTask;
    @Input() folder: Folder;

    constructor() {
    }

    ngOnInit(): void {
    }

    onSubmit() {
        this.inputMap.emit({})
    }
}
