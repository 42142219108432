import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SubElement} from '../../../models/SubElement';
import {ProductWarrantyRequest, RegimeWarrantyEnum, WarrantyRule} from '../../../models/warrantyRule.model';
import {Invoice} from '../../../models/invoice.model';
import {Product} from '../../../models/product.model';
import {finalize} from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {select, Store} from '@ngrx/store';
import {UserState} from '../../../store/user/user.state';
import {BackOfficeService} from '../../../shared/services/back-office.service';
import {AppState} from '../../../store/app.state';
import {currentUser} from '../../../store/user/user.selectors';
import {Unsubscriber} from '../../../unsubscriber';
import {RuleEvaluationContext} from '../../../models/rules/RuleEvaluationContext';
import {ProductFamilyType} from '../../../models/enums/productFamilyType.enum';

@Component({
    selector: 'app-sub-elements',
    templateUrl: './sub-elements.component.html',
    styleUrls: ['./sub-elements.component.scss']
})
export class SubElementsComponent extends Unsubscriber implements OnInit {

    @Input() subElements: SubElement[] = [];
    @Input() invoice: Invoice;
    @Input() product: Product;
    @Output() subElementEmitter = new EventEmitter<SubElement>();

    subElementsWarranties: Map<SubElement, WarrantyRule> = new Map<SubElement, WarrantyRule>();

    selectedSubElement: SubElement;
    maxNumberOfSubElement = 20;
    constructor(private store$: Store<AppState>,
                private backOfficeService: BackOfficeService,
                private datePipe: DatePipe) {
        super();
    }

    ngOnInit(): void {
        this.computeWarranties();
    }

    private computeWarranties(): void {
        this.store$.dispatch(new StartLoading());
        this.subElements.forEach(subElement => this.getWarranty(subElement));
    }

    getWarranty(subElement: SubElement): void {
        this.anotherSubscription = this.store$.pipe(select(currentUser)).subscribe(user => {
            const productWarrantyRequest = this.buildRequestFor(subElement, user);
            this.anotherSubscription = this.backOfficeService.getSubElementWarrantyRule(productWarrantyRequest)
                .pipe(finalize(() => this.store$.dispatch(new StopLoading())))
                .subscribe(warranty => this.subElementsWarranties.set(subElement, warranty));
        });
    }

    private buildRequestFor(subElement: SubElement, user: UserState): ProductWarrantyRequest {
        return {
            organizationCode: user.organizationCode,
            context: user.context,
            productCode: this.product.code,
            invoiceNumber: this.invoice.id,
            invoiceLine: this.product['lineNumber'],
            purchaseDate: this.datePipe.transform(this.invoice.creationDate, 'yyyyMMdd'),
            productSubElementCode: subElement.code,
            family: RuleEvaluationContext.getFamilyByType(this.product.families, ProductFamilyType.FAMILY),
            subFamily: RuleEvaluationContext.getFamilyByType(this.product.families, ProductFamilyType.SUB_FAMILY),
            type: RuleEvaluationContext.getFamilyByType(this.product.families, ProductFamilyType.TYPE),
            subType: RuleEvaluationContext.getFamilyByType(this.product.families, ProductFamilyType.SUB_TYPE),
            segment: RuleEvaluationContext.getFamilyByType(this.product.families, ProductFamilyType.SEGMENT),
            brand: this.product.brand,
            supplierCode: this.product.supplier?.code
        };
    }

    isUnderWarranty(value: WarrantyRule): boolean {
        return value.regimeWarrantyCode === RegimeWarrantyEnum.SG;
    }

    // a hack to select/deselect a radio button
    checkStateAndEmit(event, el): void {
        event.preventDefault();
        if (this.selectedSubElement && this.selectedSubElement === el.value) {
            el.checked = false;
            this.selectedSubElement = null;
        } else {
            this.selectedSubElement = el.value;
            el.checked = true;
        }
        this.subElementEmitter.emit(this.selectedSubElement);
    }
}
