<div fxLayout="row" fxLayout.lt-lg="row" fxLayout.lt-md="column" fxLayoutGap="8px" fusePerfectScrollbar>
    <div fxFlex="50">
        <app-public-supplier-header [folder]="folder"></app-public-supplier-header>
    </div>

    <div fxFlex="50">
        <mat-card class="mat-elevation-aster">
            <mat-card-content>
                <form [formGroup]="refundStatusForm.form" fxLayout="column" fxLayoutAlign="start"
                      (ngSubmit)="onSubmit()">
                    <mat-card-title
                        class="h3 mt-12 mb-24">{{'COMPONENT.WAITING_SUPPLIER_CONFIRMATION' | translate}}</mat-card-title>
                    <mat-radio-group formControlName="status" class="ml-20" fxLayout="row"
                                     fxLayoutAlign="space-between center" (change)="choiceChanged()"
                                     labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch"
                                     fxLayoutGap="10px">
                        <mat-radio-button [id]="status.status" class="full-width-radio"
                                          *ngFor="let status of refundStatusForm.placeholders"
                                          [value]="status.status">
                            <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div fxFlex>
                                    <div>{{ status.value | translate }}</div>
                                </div>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>

                    <ng-container [ngSwitch]="refundStatusForm.form.value.status">
                        <div *ngSwitchCase="'true'" class="ml-48">
                            <mat-form-field class="py-4 w-70-p" appearance="outline">
                                <mat-label>{{'FOLDER.WAITING_SUPPLIER_CONFIRMATION.AGREEMENT_NUMBER'|translate}}</mat-label>
                                <input [id]="'refund_agreement_number'" matInput
                                       placeholder="{{'FOLDER.WAITING_SUPPLIER_CONFIRMATION.AGREEMENT_NUMBER'|translate}}"
                                       inputControl
                                       formControlName="refundAgreementNumber" required>
                            </mat-form-field>
                            <mat-radio-group formControlName="sendProduct" fxLayout="row" fxLayoutAlign="space-between center"
                                             labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                                <mat-radio-button  [id]="'product_destruction'" class="full-width-radio" value="false">
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                        <div fxFlex>
                                            {{'FOLDER.WAITING_SUPPLIER_CONFIRMATION.DESTRUCTION_PRODUCT' | translate}}
                                        </div>
                                    </div>
                                </mat-radio-button>
                                <mat-radio-button  [id]="'send_product_to_supplier'" class="full-width-radio" value="true">
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                        <div fxFlex>
                                            {{'FOLDER.WAITING_SUPPLIER_CONFIRMATION.SHIPMENT_TO_SUPPLIER' | translate}}
                                        </div>
                                    </div>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </ng-container>
                    <!-- Supplier comment -->
                    <div fxLayout="row" fxLayoutAlign="space-between" class="mt-12">
                        <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                            <mat-label>{{'MODAL.SUPPLIER.COMMENT.DESCRIPTION' | translate}}</mat-label>
                            <textarea matInput rows="4" cols="7"
                                      [placeholder]="'MODAL.SUPPLIER.COMMENT.DESCRIPTION' | translate"
                                      formControlName="supplierComment"></textarea>
                        </mat-form-field>
                    </div>
                    <div fxLayoutAlign="end center">
                        <button type="submit" mat-stroked-button
                                class="py-8 px-24 font-weight-900 font-size-30"
                                [ngClass]="!isFormValid() ?'aster_btn_disabled':'aster_btn'"
                                [disabled]="!isFormValid()">
                            {{'BUTTON.VALIDATE' | translate}}
                        </button>
                    </div>
                </form>

            </mat-card-content>
        </mat-card>
    </div>
</div>