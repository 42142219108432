export class I18nUtils {

    /** get cross browser language
     * link: https://stackoverflow.com/a/29106129/4771750
     */
    static getBrowserLanguage(): string {
        const nav = window.navigator;
        const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];

        if (!!nav.languages && nav.languages.length > 0 && nav.languages[0].length > 0) {
            return nav.languages[0];
        }
        for (const property of browserLanguagePropertyKeys) {
            if (!!nav[property]) {
                return nav[property];
            }
        }
        return null;
    }

    static getBrowserLanguageOriginKey(): string {
        const language = I18nUtils.getBrowserLanguage();
        // if browser language not resolved or country code wasn't included, we return the default language of the app.
        return language?.split('-')[0];
    }
}
