<div class="mat-elevation-z1" *ngIf="data.length > 0">
    <mat-card>
        <mat-card-content>
            <table mat-table aria-describedby="files datatable"
                   [dataSource]="data" fxFill>
                <ng-container matColumnDef="file">
                    <th mat-header-cell *matHeaderCellDef
                        class="table-header"> {{'FOLDER.FILE.TITLE' | translate}}</th>
                    <td mat-cell *matCellDef="let element" class="text-left">
                        <a (click)="downloadFile(element.id,element.name)" class="cursor-pointer">
                            <p class="file_name">
                                <mat-icon>file_copy</mat-icon>
                                {{element.name}}
                            </p>
                        </a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef
                        class="table-header"> {{'FOLDER.FILE.DATE' | translate}}</th>
                    <td mat-cell
                        *matCellDef="let element"> {{element.uploadDate  | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate) : (currentLanguage$ | async)}} </td>
                </ng-container>
                <ng-container matColumnDef="attachmentType">
                    <th mat-header-cell *matHeaderCellDef
                        class="table-header"> {{'FOLDER.FILE.ATTACHMENT_TYPE' | translate}}</th>
                    <td mat-cell
                        *matCellDef="let element"> {{getLabelAttachmentType(element.attachmentType)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> {{'BUTTON.ACTION' | translate}}</th>
                    <td mat-cell
                        *matCellDef="let element">
                        <mat-icon (click)="removeFile(element.id)"
                                  [ngClass]="canRemoveFile(element)? 'trash': 'disable'">
                            delete_forever
                        </mat-icon>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row
                    *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </mat-card-content>
    </mat-card>
</div>