export class HttpUtils{

    static buildRequestParamFromInputs(inputs: Map<string, string>): any {
        let requestParam = '';
        inputs.forEach((value: string, key: string) => {
            if (!value || value === '*') {
                requestParam += `${key}=any&`;
            } else {
                requestParam += `${key}=${value}&`;
            }
        });
        requestParam = requestParam.substring(0, requestParam.length - 1);
        return requestParam;
    }

}