<div>
    <div fxLayout="row" class="cursor-pointer mb-20" fxLayoutAlign="end end" >
        <mat-icon [matTooltip]="'FOLDER.ADDRESS.CLOSE_BUTTON' | translate" (click)="close()">clear</mat-icon>
    </div>
    <form *ngIf="!!symptomsGrid.symptomSelectForm" [formGroup]="symptomsGrid.symptomSelectForm">
        <div fxLayout="column">
            <div class="example-form" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px" >
                <mat-form-field class="w-100-p " appearance="outline">
                    <mat-label>{{folder.faultCode.label}}</mat-label>
                    <button matSuffix mat-icon-button type="button" class="mx-8"
                            *ngIf="!!symptomsGrid.symptomSelectForm.get(['symptomPlaceholder', 'symptomCode']).value"
                            (click)="onClearSymptomsFilterForm()">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-select
                        [formControl]="symptomsGrid.symptomSelectForm.get(['symptomPlaceholder', 'symptomCode'])"
                        [placeholder]="'FOLDER.SYMPTOM.SEARCH_FORM.PLACEHOLDER' | translate"
                        aria-label="">
                        <mat-option>
                            <ngx-mat-select-search
                                [formControl]="symptomsGrid.symptomSelectForm.get(['symptomFilterCtrl']) "
                                [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of symptomsGrid.filteredSymptomsList | async ; let index = index;"
                                    (onSelectionChange)="onSymptomSelect(index)" [value]="item.code">
                            <span>{{ item.code }} - {{ item.code | codeToLabel  :'SAV.CONFIG.SYMPTOMS' | async }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                            <span fxFlex="25">
                                {{'FORM.TITLE.CONDITION' | translate}}
                            </span>
                <mat-form-field fxFlex appearance="outline" id="condition">
                    <mat-select [formControl]="symptomsGrid.symptomSelectForm.get(['condition'])"
                                [placeholder]="'FORM.TITLE.CONDITION' | translate"
                                (selectionChange)="conditionChanged($event.value)">
                        <mat-option *ngFor="let item of conditionList"
                                    [value]="!!item.code ? item.code : ''">
                            <span> {{item.label | translate}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>


</div>
<div fxLayout="row" fxLayoutAlign="end end">
    <button mat-stroked-button aria-label="Submit"
            class="aster-btn_border"
            (click)="valideModification()">{{'BUTTON.EDIT' | translate}}</button>
</div>