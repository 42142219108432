import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BackOfficeService} from '../../services/back-office.service';
import {getModOpIconName} from '../../data/static.config';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-switch-operating-mode',
    templateUrl: './switch-operating-mode-modal.component.html',
    styleUrls: ['./switch-operating-mode-modal.component.scss']
})
export class SwitchOperatingModeModalComponent implements OnInit {

    form: FormGroup;

    reasonsChange = ['PARTS_NOT_REFERENCED',
                    'PARTS_ZERO_STOCK'
                  , 'OTHER'];

    constructor(private dialogRef: MatDialogRef<SwitchOperatingModeModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data) {
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            operatingMode: new FormControl(null, [Validators.required]),
            reason: new FormControl(null, [Validators.required]),
            comment: new FormControl(null),
        });
    }

    getIconName(value: any): void {
        return getModOpIconName(value);
    }

    onCloseModal(): void {
        this.dialogRef.close();
    }

    validateForm(): void {
        this.dialogRef.close({
            operatingMode: this.form.value.operatingMode,
            reason: this.form.value.reason,
            comment: this.form.value.comment
        });
    }

    onClose(): void {
        this.dialogRef.close();
    }

    changeCommentValidator(value: any) {
        if (value === 'OTHER' ) {
            this.form.get('comment').setValidators(Validators.required);
        }else{
            this.form.get('comment').clearValidators();
            this.form.get('comment').setValue(null);
        }
        this.form.get('comment').updateValueAndValidity();
    }

}
