export enum CheckAndDiagDecision {
    IRREPARABLE = 'IRREPARABLE',
    SUBCONTRACT_THE_REPAIR = 'SUBCONTRACT_THE_REPAIR',
    DO_THE_REPAIR = 'DO_THE_REPAIR',
    CLOSE_BY_DIAG_EXPRESS = 'CLOSE_BY_DIAG_EXPRESS'
}


export enum CheckAndDiagConditions {
    IRREPARABLE = 'IRREPARABLE',
    NEW = 'NEW',
    DAMAGED = 'DAMAGED',
    CLOSE_BY_DIAG_EXPRESS = 'CLOSE_BY_DIAG_EXPRESS',
}
