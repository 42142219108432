export const CUSTOMER_COLUMNS = [
    {
        label: 'CUSTOMER.REFERENCE',
        name: 'reference'
    },
    {
        label: 'CUSTOMER.FIRST_NAME',
        name: 'firstName'
    },
    {
        label: 'CUSTOMER.LAST_NAME',
        name: 'lastName'
    },
    {
        label: 'CUSTOMER.PHONE',
        name: 'mobileNumber'
    },
    {
        label: 'ADDRESS.ZIPCODE',
        name: 'zipCode'
    },
];

export const CUSTOMER_FIELDS = {
    registrationInfo: [
        {
            name: 'reference',
            label: 'CUSTOMER.REFERENCE',
            size: 48
        },
        {
            name: 'loyaltyCardNumber',
            label: 'CUSTOMER.LOYALTY_CARD_NUMBER',
            size: 48
        }
    ],
    nameInfo: [
        {
            name: 'lastName',
            label: 'CUSTOMER.LAST_NAME',
            icon: 'person',
            size: 35
        },
        {
            name: 'firstName',
            label: 'CUSTOMER.FIRST_NAME',
            icon: 'person',
            size: 35
        }
    ]
};

export const EMAIL_FIELD = {
    name: 'email',
    label: 'CUSTOMER.EMAIL',
    icon: 'email'
};

export const CIVILITY_OPTIONS = [
    {
        key: 'MR',
        label: 'CUSTOMER.CIVILITY.MR'
    },
    {
        key: 'MS',
        label: 'CUSTOMER.CIVILITY.MS'
    }];
