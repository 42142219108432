import {Action} from '@ngrx/store';
import {CustomerApiInfo} from '../../models/customer/customer-api-info.model';
import {Customer} from '../../models/customer/customer.model';
import {PageEvent} from '@angular/material/paginator';
import {PagedResult} from '../../models/paged-result.model';
import {CustomerType} from '../../models/enums/CustomerType.enum';

export enum CustomerActionType {
    LOAD_CUSTOMER_API_INFO = '[CUSTOMER] Load Customer Api Info',
    CUSTOMER_API_INFO_LOADED_SUCCESSFULLY = '[CUSTOMER] Customer Api Info loaded successfully',
    RESET_CUSTOMER_STATE = '[CUSTOMER] Reset Customer State',
    SEARCH_CUSTOMER = '[CUSTOMER] Search customer',
    UPDATE_CUSTOMER = '[CUSTOMER] Update customer',
    RESET_CUSTOMER = '[CUSTOMER] Reset customer',
    RESET_CUSTOMER_SEARCH_RESULT = '[CUSTOMER] Reset customer search result',
    UPDATE_CUSTOMER_SEARCH_RESULT = '[CUSTOMER] Update customer search result',
    SET_CREATION_WITHOUT_CUSTOMER = '[CUSTOMER] Set creation without customer',
    CUSTOMER_PAGE_EVENT = '[CUSTOMER] Search page event',
    UPDATE_FORM = '[CUSTOMER] Update form',
    UPDATE_FORM_VALIDITY = '[CUSTOMER] Update form validity',
    CUSTOMER_TYPE_CHANGED = '[CUSTOMER] Customer type changed',
    SET_STOCK_CLAIM_CREATION = '[CUSTOMER] set Stock Claim Creation',
}

export class LoadCustomerApiInfo implements Action {
    readonly type = CustomerActionType.LOAD_CUSTOMER_API_INFO;
}

export class CustomerApiInfoLoadedSuccessfully implements Action {
    readonly type = CustomerActionType.CUSTOMER_API_INFO_LOADED_SUCCESSFULLY;

    constructor(public customerApiInfo: CustomerApiInfo) {
    }
}

export class ResetCustomerState implements Action {
    readonly type = CustomerActionType.RESET_CUSTOMER_STATE;
}

export class UpdateCustomer implements Action {
    readonly type = CustomerActionType.UPDATE_CUSTOMER;

    constructor(public customer: Customer) {
    }
}

export class SearchCustomer implements Action {
    readonly type = CustomerActionType.SEARCH_CUSTOMER;

    constructor(public customer?: Customer, public pageEvent?: PageEvent) {
    }
}

export class ResetCustomer implements Action {
    readonly type = CustomerActionType.RESET_CUSTOMER;

    constructor(public keepFilters?: boolean) {
    }
}

export class ResetCustomerSearchResult implements Action {
    readonly type = CustomerActionType.RESET_CUSTOMER_SEARCH_RESULT;
}

export class UpdateForm implements Action {
    readonly type = CustomerActionType.UPDATE_FORM;

    constructor(public customer: Customer) {
    }
}

export class CustomerTypeChanged implements Action {
    readonly type = CustomerActionType.CUSTOMER_TYPE_CHANGED;

    constructor(public value: CustomerType) {
    }
}

export class SetCreationWithoutCustomer implements Action {
    readonly type = CustomerActionType.SET_CREATION_WITHOUT_CUSTOMER;

    constructor(public isWithoutCustomer: boolean) {
    }
}

export class SetStockClaimCreation implements Action {
    readonly type = CustomerActionType.SET_STOCK_CLAIM_CREATION;

    constructor(public isStockClaimCreation: boolean) {
    }
}

export class UpdateFormValidity implements Action {
    readonly type = CustomerActionType.UPDATE_FORM_VALIDITY;

    constructor(public isValid: boolean) {
    }
}

export class CustomerPageEvent implements Action {
    readonly type = CustomerActionType.CUSTOMER_PAGE_EVENT;

    constructor(public pageEvent: PageEvent) {
    }
}

export class UpdateCustomerSearchResult implements Action {
    readonly type = CustomerActionType.UPDATE_CUSTOMER_SEARCH_RESULT;

    constructor(public customers: PagedResult<Customer>) {
    }
}

export type CustomerActions =
    LoadCustomerApiInfo | CustomerApiInfoLoadedSuccessfully | UpdateCustomerSearchResult |
    UpdateCustomer | CustomerPageEvent | SearchCustomer | ResetCustomerState |
    ResetCustomerSearchResult | ResetCustomer | SetCreationWithoutCustomer |
    UpdateForm | CustomerTypeChanged | UpdateFormValidity | SetStockClaimCreation;
