import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationType} from '../../../models/enums/NotificationType.enum';
import {Receiver} from '../../../models/enums/receiver.enum';
import {ANGULAR_EDITOR_CONFIG} from '../../../shared/generic/angular-editor/angularEditorConfig';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {Folder} from '../../../models/folder.model';
import {MobilePhoneInput} from '../../../models/customer/customer.model';
import {SharedService} from '../../../shared/services/shared.service';
import {NotificationService} from '../../../shared/services/notification.service';
import {select, Store} from '@ngrx/store';
import {countryCode, templateId} from '../../../store/organization/organization.selectors';
import {AppState} from '../../../store/app.state';
import {Unsubscriber} from '../../../unsubscriber';
import {SnackBarService} from '../../../shared/services/snack-bar.service';
import {FolderSubjectService} from '../folder-subject.service';
import {FileInfo} from '../../../models/file-info.model';
import {ReplaySubject} from 'rxjs';
import {SiteType} from '../../../models/enums/siteType.enum';

@Component({
    selector: 'app-sending-communication-dialog',
    templateUrl: './sending-communication-dialog.component.html',
    styleUrls: ['./sending-communication-dialog.component.css']
})
export class SendingCommunicationDialogComponent extends Unsubscriber implements OnInit {
    MAX_CHAR_NUMBER = 160;
    sendingCommunicationForm: any = {
        form: null,
        communicationTypeList: [],
        receiverList: []
    };
    editorConfig: AngularEditorConfig = ANGULAR_EDITOR_CONFIG;
    mailContentValue = '';
    templateId: string;
    mobilePhoneOutput: MobilePhoneInput;
    sendingInProgress = false;
    attachments: FileInfo[] = [];
    selectedAttachment: FileInfo;
    filteredAttachments = new ReplaySubject<FileInfo[]>();
    chosenAttachments: FileInfo[] = [];
    attachmentSize = 0;
    sizeAttachmentExceeded: boolean;
    storeEmail: string[] = [];
    constructor(public dialogRef: MatDialogRef<SendingCommunicationDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public folder: Folder,
                private sharedService: SharedService,
                private notificationService: NotificationService,
                private snackBar: SnackBarService,
                private store$: Store<AppState>,
                private folderSubjectService: FolderSubjectService) {
        super();
    }
    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(templateId)).subscribe(id => this.templateId = id);
        this.initForm();
        this.initFormValidators();
        this.initMobileInputPhone();
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(data => {
             this.attachments = data.attachments.filter(it => it.id != null);
             this.filteredAttachments.next(this.attachments);
        });
        this.storeEmail = this.folder.sites.filter(site => site.type === SiteType.MANAGEMENT_SITE).map(site => site.contact?.email);
    }

    private initForm(): void {
        this.sendingCommunicationForm.communicationTypeList = [NotificationType.MAIL, NotificationType.SMS];
        this.sendingCommunicationForm.receiverList = [Receiver.CUSTOMER, Receiver.SUPPLIER, Receiver.REPAIRER];

        this.sendingCommunicationForm.form = new FormGroup({
            communicationType: new FormControl(null, Validators.required),
            receiver: new FormControl(null, Validators.required),
            smsContent: new FormControl(null, Validators.required),
            mail: new FormControl(null, Validators.required),
            mailObject: new FormControl(null, Validators.required),
            mobileNumber: new FormControl(null, Validators.required),
            cc: new FormControl(true)
        });

    }

    private initFormValidators() {
        const smsContentControl = this.sendingCommunicationForm.form.controls['smsContent'];
        const mobilePhoneControl = this.sendingCommunicationForm.form.controls['mobileNumber'];
        const mailControl = this.sendingCommunicationForm.form.controls['mail'];
        const mailObjectControl = this.sendingCommunicationForm.form.controls['mailObject'];
        this.anotherSubscription = this.sendingCommunicationForm.form.get('communicationType').valueChanges
            .subscribe((value) => {
                if (value === NotificationType.MAIL) {
                    smsContentControl.setValidators(null);
                    smsContentControl.reset();

                    mobilePhoneControl.setValidators(null);
                    mobilePhoneControl.reset();

                    mailControl.setValidators(Validators.required);
                    mailControl.reset();

                    mailObjectControl.setValidators(Validators.required);
                    mailObjectControl.reset();

                } else {
                    mobilePhoneControl.setValidators(Validators.required);
                    mobilePhoneControl.reset();

                    mailControl.setValidators(null);
                    mailControl.reset();

                    mailObjectControl.setValidators(null);
                    mailObjectControl.reset();

                    if (value === NotificationType.SMS) {
                        smsContentControl.setValidators(Validators.required);
                        smsContentControl.reset();
                    } else {
                        smsContentControl.setValidators(null);
                        smsContentControl.reset();
                    }
                }
                smsContentControl.updateValueAndValidity();
                mobilePhoneControl.updateValueAndValidity();
                mailControl.updateValueAndValidity();
            });
    }

    private initMobileInputPhone(): void {
        this.mobilePhoneOutput = new MobilePhoneInput();

        this.anotherSubscription = this.store$.pipe(select(countryCode)).subscribe(
            organizationLocal => this.mobilePhoneOutput = {
                countryCode: organizationLocal,
                mobileNumber: null
            });
        this.anotherSubscription = this.sharedService.getUpdateMobilePhoneEvent().subscribe(res => {
            if (!res) {
                this.sendingCommunicationForm.form.get(['mobileNumber']).setErrors({'incorrect': true});
                return;
            }
            this.sendingCommunicationForm.form.get(['mobileNumber']).setValue(res.mobileNumber);
            this.mobilePhoneOutput = res;
        });
    }

    isSMS(): boolean {
        return this.sendingCommunicationForm.form.get(['communicationType']).value === NotificationType.SMS;
    }

    isMail(): boolean {
        return this.sendingCommunicationForm.form.get(['communicationType']).value === NotificationType.MAIL;
    }

    getContactValue(): void {
        if (this.isMail()) {
            switch (this.sendingCommunicationForm.form.get(['receiver']).value) {
                case Receiver.CUSTOMER: {
                    this.sendingCommunicationForm.form.get(['mail']).setValue(this.folder?.customer?.contact?.email);
                    break;
                }
                case Receiver.SUPPLIER: {
                    this.sendingCommunicationForm.form.get(['mail']).setValue(this.folder?.product?.supplier?.contact?.email);
                    break;
                }
                case Receiver.REPAIRER: {
                    this.sendingCommunicationForm.form.get(['mail']).setValue(this.folder?.repairCenter?.contact?.email);
                    break;
                }
            }
        } else {
            switch (this.sendingCommunicationForm.form.get(['receiver']).value) {
                case Receiver.CUSTOMER: {
                    this.mobilePhoneOutput.mobileNumber = this.folder?.customer?.contact?.mobileNumber;
                    break;
                }
                case Receiver.SUPPLIER: {
                    this.mobilePhoneOutput.mobileNumber = this.folder?.product?.supplier?.contact?.mobileNumber;
                    break;
                }
                case Receiver.REPAIRER: {
                    this.mobilePhoneOutput.mobileNumber = this.folder?.repairCenter?.contact?.mobileNumber;
                    break;
                }
            }
            this.sharedService.updateMobilePhone(this.mobilePhoneOutput);
        }
    }

    isSelectedValues(): boolean {
        return !!this.sendingCommunicationForm.form && !!this.sendingCommunicationForm.form.get(['communicationType']).value
            && !!this.sendingCommunicationForm.form.get(['receiver']).value;
    }

    isFormValid(): boolean {
        if (this.isMail()) {
            if (this.mailContentValue === '') {
                return false;
            }
            return this.sendingCommunicationForm.form.valid;
        }
        return this.sendingCommunicationForm.form.valid;
    }

    onSubmit(): void {
        this.sendingInProgress = true;
        let communicationToSend: any;
        if (this.isMail()) {
            communicationToSend = {
                'type': NotificationType.MAIL,
                'receiver': this.sendingCommunicationForm.form.get(['mail']).value,
                'content': this.mailContentValue,
                'mailObject': this.sendingCommunicationForm.form.get(['mailObject']).value,
                'attachments': this.chosenAttachments,
                'genericMailTemplateId': this.templateId
            };

            if (this.sendingCommunicationForm.form.get('cc').value) {
                const ccList = this.folder.sites
                    .filter(site => site.type === SiteType.MANAGEMENT_SITE && site.contact?.email)
                    .map(site => ({
                        'firstName': site.code || '',
                        'lastName': site.name || '',
                        'email': site.contact?.email || ''
                    }));
                communicationToSend.ccList = ccList;
            }
        } else if (this.isSMS()) {
            communicationToSend = {
                'type': NotificationType.SMS,
                'receiver': this.mobilePhoneOutput.mobileNumber,
                'content': this.sendingCommunicationForm.form.get(['smsContent']).value,
            };
        } else {
            this.sendingInProgress = false;
            return;
        }
        this.notificationService.sendNotification(communicationToSend, this.folder.id).subscribe(res => {
            this.sendingInProgress = false;
            if (!!res) {
                this.snackBar.openWithIcon('FOLDER.SEND_COMMUNICATION_SUCCESS', 'Success');
                this.closeDialog();
            }
        }, error => {
            this.snackBar.openWithIcon('ERRORS.TECHNICAL.COMMON', 'Error');
            this.sendingInProgress = false;
        });
    }

    closeDialog(): void {
        this.dialogRef.close(null);
    }

    onAddAttachment() {
        this.sizeAttachmentExceeded = (this.convertToMb(this.selectedAttachment.size) + this.attachmentSize) > 10;
        if (!this.sizeAttachmentExceeded) {
            this.attachmentSize += this.convertToMb(this.selectedAttachment.size);
            this.chosenAttachments = [...this.chosenAttachments, this.selectedAttachment];
            this.attachments = this.attachments.filter(it => it.id !== this.selectedAttachment.id);
            this.filteredAttachments.next(this.attachments);
        }
        this.selectedAttachment = null;
    }

    onDeleteAttachment(attachment: FileInfo) {
        this.attachmentSize -= this.convertToMb(attachment.size);
        this.chosenAttachments = this.chosenAttachments.filter(it => it.id !== attachment.id);
        this.attachments.push(attachment);
        this.filteredAttachments.next(this.attachments);
        this.sizeAttachmentExceeded = false;
    }
    private convertToMb(size: number): number{
        return size / 1024 / 1024;
    }
    isLimitNear(content: HTMLTextAreaElement): boolean {
        return (content.value ? (this.MAX_CHAR_NUMBER - content.value.length) : this.MAX_CHAR_NUMBER) <= 5;
    }
    remainingCharacters(content: HTMLTextAreaElement): number {
        return content.value ? (this.MAX_CHAR_NUMBER - content.value.length) : this.MAX_CHAR_NUMBER;
    }
}

