<div *ngIf="!!wishesAppointments">
    <mat-card-content>

        <div fxLayout="column" fxLayoutAlign="start">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
            <div class="bg-card_gris text-center pt-44 pb-44 w-100-p">
                <mat-icon svgIcon="validate_icon" class="h-36 w-36"></mat-icon>
                <div fxLayoutAlign="center center">
                    <div>
                        <label class="h1  text-bold">
                            {{'FOLDER.WORKFLOW.TITLE_VALIDATE' | translate}}
                        </label>

                        <div class="barre_title"></div>
                        <div class="h3 w-100-p confirmation_description ">
                            {{'FOLDER.WORKFLOW.SELECT_DATE' | translate}}
                            <mat-radio-group aria-labelledby="example-radio-group-label"
                                             class="confirmation-date" [(ngModel)]="wishesAppointmentSelected">
                                <ng-container *ngFor="let wishesAppointment of wishesAppointments">
                                    <mat-radio-button class="confirmation-date_radio" [value]="wishesAppointment">
                                        {{ wishesAppointment.day | moment: ('GENERAL.DATE_FORMAT_CONFIRMATION' | translate) : (currentLanguage$ | async)}}
                                        <ng-container *ngIf=" getHours(wishesAppointment)<=13 ;else rdvTow_morning">
                                            ,{{'FOLDER.WORKFLOW.MORNING' | translate}}
                                            à {{wishesAppointment.beginHour }}
                                        </ng-container>
                                        <ng-template #rdvTow_morning>
                                            , {{'FOLDER.WORKFLOW.AFTERNOON' | translate}}
                                            à {{wishesAppointment.beginHour }}
                                        </ng-template>
                                    </mat-radio-button>
                                </ng-container>
                                <mat-radio-button class="confirmation-date_radio" value="autre">
                                    {{'FOLDER.WORKFLOW.OTHER' | translate}}
                                </mat-radio-button>
                                <ng-container *ngIf="wishesAppointmentSelected==='autre'">
                                    <div class="bg-white p-12  mat-elevation-aster mt-8">

                                        <div class=" mt-8 mb-8">
                                            <mat-radio-group [(ngModel)]="otherDateSeleced"
                                                             (change)="selectOptionDate($event)">
                                                <mat-radio-button
                                                    value="MORNING">{{'FOLDER.WORKFLOW.MORNING' | translate}}</mat-radio-button>
                                                <mat-radio-button value="AFTERNOON"
                                                                  class="ml-8">{{'FOLDER.WORKFLOW.AFTERNOON' | translate}}</mat-radio-button>
                                                <mat-radio-button value="OTHER_DATE"
                                                                  class="ml-8">{{'FOLDER.WORKFLOW.OTHER_DATE' | translate}}</mat-radio-button>
                                            </mat-radio-group>
                                        </div>

                                        <form [formGroup]="registerForm"   class="w-100-p">

                                            <div fxLayout="column">
                                                <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="100">
                                                    <mat-label>{{'FOLDER.SCHEDULE.DAY_OF_INTERVENTION' | translate}}</mat-label>
                                                    <input matInput [matDatepicker]="wishedDay" [min]="minDate"
                                                           formControlName="wishedDay">
                                                    <mat-datepicker-toggle matSuffix
                                                                           [for]="wishedDay"></mat-datepicker-toggle>
                                                    <mat-datepicker touchUi #wishedDay></mat-datepicker>
                                                </mat-form-field>
                                            </div>

                                            <div fxLayout="column">
                                                <mat-form-field appearance="outline" class=" no-errors-spacer"
                                                                fxFlex="50">
                                                    <mat-label>{{'FOLDER.SCHEDULE.BEGIN_HOUR_INTERVENTION' | translate}}</mat-label>
                                                    <input matInput type="time" formControlName="beginHourIntervention">
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class=" no-errors-spacer"
                                                                fxFlex="50">
                                                    <mat-label>{{'FOLDER.SCHEDULE.END_HOUR_INTERVENTION' | translate}}</mat-label>
                                                    <input matInput type="time" formControlName="endHourIntervention">
                                                </mat-form-field>
                                            </div>

                                        </form>
                                    </div>
                                </ng-container>

                            </mat-radio-group>
                        </div>
                    </div>
                </div>

                <app-comment-area [commentForm]="commentControl"></app-comment-area>

                <div class="mt-16">
                    <button mat-stroked-button (click)="redirectToEditWishedDate()"
                            matTooltip="{{'BUTTON.EDIT' | translate}}"
                            class="h-44 mt-4 pl-20 button_color-gris-white">
                        {{'BUTTON.EDIT' | translate}}
                    </button>

                    <button mat-stroked-button (click)="confirmIntervention()"
                            matTooltip="{{'BUTTON.VALIDATE' | translate}}"
                            [ngClass]="!wishesAppointmentSelected?'aster_btn_disabled' : 'aster_btn'"
                            class="h-44 mt-4 ml-8  ">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>

                    <button mat-stroked-button (click)="cancelIntervention()"
                            matTooltip="{{'BUTTON.CANCEL_INTERVENTION' | translate}}"
                            class="h-44 mt-4 ml-8 aster_btn">
                        {{'BUTTON.CANCEL_INTERVENTION' | translate}}
                    </button>
                </div>
            </div>

        </div>
    </mat-card-content>
</div>