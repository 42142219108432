<h1 matDialogTitle>{{'RE_OPTIMIZE.DIALOG.TITLE' | translate }}</h1>

<mat-dialog-content class="dialog-container">

    <form [formGroup]="reOptimizeForm.form" fxLayout="column" fxLayoutAlign="start">
        <mat-form-field appearance="outline" class="w-100-p">
            <mat-label>{{'RE_OPTIMIZE.DIALOG.MAX_ITERATION_PLACEHOLDER' | translate : {
                min: minIteration,
                max: maxIteration
            }
                }}</mat-label>
            <input matInput placeholder="{{'RE_OPTIMIZE.DIALOG.MAX_ITERATION_PLACEHOLDER' | translate : {min: minIteration, max: maxIteration}
                }}"
                   formControlName="iterationNumber" id="iterationNumber" type="number" min="minIteration"
                   max="maxIteration"/>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="mt-24 mb-0">
    <div class="w-100-p delete-popup">
        <button mat-button class="aster_btn_cancel"
                (click)="dialogRef.close()">{{'RE_OPTIMIZE.DIALOG.BUTTON.CANCEL' |translate}}</button>
        <button (click)="onSubmit()" [disabled]="reOptimizeForm.form.invalid"
                [ngClass]="reOptimizeForm.form.valid? 'aster_btn' : 'aster_btn_disabled'"
                class="aster_btn" id="confirm_delete" mat-raised-button
                type="submit">{{'RE_OPTIMIZE.DIALOG.BUTTON.LAUNCH' | translate}}</button>

    </div>
</mat-dialog-actions>

