import {NgModule} from '@angular/core';

import {FuseSharedModule} from '@fuse/shared.module';

import {NavbarComponent} from 'app/layout/components/navbar/navbar.component';
import {NavbarHorizontalStyle1Module} from './horizontal/style-1/style-1.module';
import {NavbarVerticalStyle1Module} from "./vertical/style-1.module";

@NgModule({
    declarations: [
        NavbarComponent
    ], imports: [FuseSharedModule,
        NavbarHorizontalStyle1Module,
        NavbarVerticalStyle1Module],
    exports: [
        NavbarComponent
    ]
})
export class NavbarModule {
}
