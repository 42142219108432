import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ProductAccessory} from '../../../models/ProductAccessory';

@Component({
    selector: 'app-accessories',
    templateUrl: './accessories.component.html',
    styleUrls: ['./accessories.component.scss']
})
export class AccessoriesComponent {

    @Input() accessories: ProductAccessory [] = [];
    @Output() accessoriesEmitter = new EventEmitter<ProductAccessory[]>();

    selection = new SelectionModel<ProductAccessory>(true, []);

    constructor() {
    }

    onSelectAccessory(accessory: ProductAccessory, $event: MatCheckboxChange): void {
        $event.checked ? this.selection.select(accessory) : this.selection.deselect(accessory);
        this.accessoriesEmitter.emit(this.selection.selected);
    }


}
