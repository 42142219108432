import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Folder} from '../../../../models/folder.model';
import {FolderSubjectService} from '../../folder-subject.service';
import {InstructionUserTask} from '../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-on-async-process',
    templateUrl: './waiting-on-async-process.component.html',
    styleUrls: ['./waiting-on-async-process.component.scss']
})
export class WaitingOnAsyncProcessComponent implements OnInit, OnDestroy {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    registeredInternal ;
    constructor(private folderSubjectService: FolderSubjectService) {
    }

    ngOnInit(): void {
        // try to refresh the folder manually each 500 ms
        // we must understand the scenario in which the websoket is not working
       this.registeredInternal = setInterval(() => {
            this.folderSubjectService.refreshWorkflowStatusLoaded(this.folder);
        }, 1000);
    }

    ngOnDestroy(): void {
        clearInterval(this.registeredInternal);
    }

}
