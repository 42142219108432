import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';


import {SearchBarComponent} from './search-bar.component';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {TranslateModule} from "@ngx-translate/core";
import {MaterialModule} from "../../../../@fuse/material/material.module";
import {MdePopoverModule} from "@material-extended/mde";

@NgModule({
    declarations: [
        SearchBarComponent
    ],
    imports: [
        CommonModule,
        RouterModule,

        MatButtonModule,
        MatIconModule,
        MaterialModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        MdePopoverModule
    ],
    exports: [
        SearchBarComponent
    ]
})
export class SearchBarModule {
}
