import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../../../../models/product.model';
import {MatDialog} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {currency} from '../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../unsubscriber';
import {Item} from '../../../../models/invoice.model';
import {ProductDetailComponent} from '../../../../main/folder/product-detail/product-detail.component';
import {UnknownProduct} from '../../../../main/folder/create-form/unknown-product/unknown.product';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {Constants} from '../../../../Constants';
import {filter, map} from 'rxjs/operators';
import {organizationCode} from '../../../../store/user/user.selectors';
import {ProductService} from '../../../services/product.service';

@Component({
    selector: 'app-generic-product-preview-card',
    templateUrl: './generic-product-preview-card.component.html',
    styleUrls: ['./generic-product-preview-card.component.scss']
})
export class GenericProductPreviewCardComponent extends Unsubscriber implements OnInit {

    public readonly UNKNOWN_PRODUCT_CODE = UnknownProduct.UNKNOWN_PRODUCT_CODE;
    @Input() product: Product | Item;
    currentOrganizationCode: string;
    constructor(private dialog: MatDialog,
                private productService: ProductService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(data =>
            this.product['purchasePrice'] = this.product['purchasePrice']
                ? this.product['purchasePrice']
                : {currency: data});
        this.anotherSubscription = this.store$.pipe(select(organizationCode))
            .subscribe(organizationCode => this.currentOrganizationCode = organizationCode);
    }

    updateSupplier(supplier): void {
        this.product.supplier = supplier;
    }

    updateLabel(label): void {
        this.product.label = label;
    }

    updatePrice(price): void {
        this.product['purchasePrice'].value = price;
    }


    showProductDetail(): void {
        this.store$.dispatch(new StartLoading());

        const filters = new Map<string, any>();
        filters.set(Constants.ORGANIZATION_CODE, this.currentOrganizationCode);
        filters.set(Constants.PRODUCT_CODE, this.product.code);
        filters.set(Constants.WITH_SELLING_PRICE, true);
        filters.set(Constants.WITH_PURCHASE_PRICE, true);
        filters.set(Constants.WITH_DOCUMENTS, true);

        this.productService.search(filters)
            .pipe(
                map(page => page.content),
                filter(products => products.length > 0),
                map(products => products[0]),
            )
            .subscribe(product => {
                    this.dialog.open(ProductDetailComponent, {
                        width: '80%',
                        hasBackdrop: true,
                        disableClose: false,
                        autoFocus: false,
                        data: {
                            product: product
                        }
                    });
                },
                error => this.store$.dispatch(new StopLoading()),
                () => this.store$.dispatch(new StopLoading())
            );
    }
}
