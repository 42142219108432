export const WARRANTY_CONFIGS: any[] = [
    {
        key: 'HG',
        icon: {
            code: 'backspace',
            class: 'rotate-270 warn-A700-fg'
        },
        label: {
            default: 'Out of Warranty',
            i18nKey: 'FOLDER.WARRANTY.ENUM_LABELS.HG'
        }
    },
    {
        key: 'SG',
        icon: {
            code: 'beenhere',
            class: 'teal-600-fg'
        },
        label: {
            default: 'Under Warranty',
            i18nKey: 'FOLDER.WARRANTY.ENUM_LABELS.SG'
        }
    }
];


export const OPERATING_MODES_CONFIGS: any[] = [
    {
        key: 'SERVICE_CENTER_REPAIR',
        icon: 'repair_shop'
    },
    {
        key: 'HOME_REPAIR',
        icon: 'home_repair'
    },
    {
        key: 'SWAP',
        icon: 'product_exchange'
    },
    {
        key: 'SPARE_PARTS_ORDER',
        icon: 'spare_parts_order'
    },
    {
        key: 'NO_MODOP',
        icon: 'depot_store'
    },
    {
        key: 'MANUAL_PROCESSING',
        icon: 'manual_processing'
    },
    {
        key: 'INTERNAL_REPARATION',
        icon: 'internal_reparation'
    },
    {
        key: 'AUTO_REPAIR',
        icon: 'auto_repair'
    },
    {
        key: 'VISIO',
        icon: 'visio'
    },
    {
        key: 'REFUND',
        icon: 'refund'
    },
    {
        key: 'PRE_DIAGNOSTIC',
        icon: 'pre_diagnostic'
    }

];

export function getModOpIconName(value: any) {
    if (value) {
        return getModOpIconNameByCode(value.code);
    }
    return '';
}

export function getModOpIconNameByCode(modOpCode: string) {
    if (modOpCode) {
        const foundModOp = OPERATING_MODES_CONFIGS.filter(e => e.key === modOpCode);
        if (foundModOp && foundModOp.length > 0) {
            return foundModOp[0].icon;
        }
    }
    return '';
}

export const noModeOp = {
    'code': 'NO_MODOP'
};

export const PAYERS_CONFIGS: any[] = [
    {
        key: 'SUPPLIER',
        label: {
            default: 'Supplier',
            i18nKey: 'RULE_WARRANTIES.CONFIGS.PAYERS_CONFIGS.SUPPLIER'
        },
    },
    {
        key: 'DISTRIBUTOR',
        label: {
            default: 'Distributor',
            i18nKey: 'RULE_WARRANTIES.CONFIGS.PAYERS_CONFIGS.DISTRIBUTOR'
        },
    },
    {
        key: 'CLIENT',
        label: {
            default: 'Client',
            i18nKey: 'RULE_WARRANTIES.CONFIGS.PAYERS_CONFIGS.CLIENT'
        },
    },
    {
        key: 'REPAIRER',
        label: {
            default: 'Repairer',
            i18nKey: 'RULE_WARRANTIES.CONFIGS.PAYERS_CONFIGS.REPAIRER'
        },
    },

];

export const ORIGIN_TYPE: any[] = [
    {
        key: 'FO_CUSTOMER',
        icon: {
            code: 'fo-customer',
            class: ''
        },
        label: {
            default: 'Sous Espace client',
            i18nKey: 'FOLDER.ORIGIN.ENUM_LABELS.FO_CUSTOMER'
        }
    },
    {
        key: 'FO_HE_SITE',
        icon: {
            code: 'fo-he-site',
            class: ''
        },
        label: {
            default: 'Hors Espace client magasin',
            i18nKey: 'FOLDER.ORIGIN.ENUM_LABELS.FO_HE_SITE'
        }
    },
    {
        key: 'FO_RETAILER',
        icon: {
            code: 'fo-retailer',
            class: ''
        },
        label: {
            default: 'Hors Espace client',
            i18nKey: 'FOLDER.ORIGIN.ENUM_LABELS.FO_RETAILER'
        }
    },
    {
        key: 'BO',
        icon: {
            code: 'back-office',
            class: ''
        },
        label: {
            default: 'Aster',
            i18nKey: 'FOLDER.ORIGIN.ENUM_LABELS.BO'
        }
    },
    {
        key: 'BORNE',
        icon: {
            code: 'borne',
            class: ''
        },
        label: {
            default: 'Borne SAV',
            i18nKey: 'FOLDER.ORIGIN.ENUM_LABELS.BORNE'
        }
    },
    {
        key: 'CHATBOT',
        icon: {
            code: 'chatbot',
            class: ''
        },
        label: {
            default: 'ChatBot',
            i18nKey: 'FOLDER.ORIGIN.ENUM_LABELS.CHATBOT'
        }
    },
    {
        key: 'FO_PID_HE',
        icon: {
            code: 'fo_pid_he',
            class: ''
        },
        label: {
            default: 'Hors espace client identification par produit',
            i18nKey: 'FOLDER.ORIGIN.ENUM_LABELS.FO_PID_HE'
        }
    }
];
