    <mat-card-content>
        <mat-card-title class="h3 mt-12 mb-24">{{'CHECK_AND_CONFIRM_ORDER' | translate}}</mat-card-title>
        <div class="mat-elevation-z4 w-100-p mt-12 mb-16">
            <mat-card-subtitle style="color: black;" fxLayoutAlign="none" class=" ml-80 mt-4">
                <div class="w-100-p px-10 mt-12">
                    <div fxLayout="row" fxLayoutAlign="none">
                        <mat-icon >timelapse</mat-icon>
                        <span class="font-weight-600">{{'ORDER.STATUS' | translate}} :
                        <span>{{displayOrderStatus(order?.orderStatus) | translate}}</span></span>

                    </div>
                </div>
            </mat-card-subtitle>
            <div translate="yes" class="hide-translate-text">
            <table mat-table [dataSource]="orderLines" class="w-100-p">
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef
                        class="table-header">  {{'CHECK.AND.CONFIRM.ORDER.CODE' | translate}}</th>
                    <td mat-cell *matCellDef="let orderLine" class="element">{{orderLine.code}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="label">
                    <th mat-header-cell *matHeaderCellDef
                        class="table-header">{{'CHECK.AND.CONFIRM.ORDER.LABEL' | translate}}</th>
                    <td class="element" mat-cell *matCellDef="let orderLine">{{orderLine.label}}</td>
                </ng-container>

                <ng-container matColumnDef="stock">
                    <th mat-header-cell *matHeaderCellDef class="table-header">
                        <span class="notranslate">
                            {{'CHECK.AND.CONFIRM.ORDER.STOCK' | translate}}
                        </span>
                    </th>
                    <td mat-cell *matCellDef="let orderLine">
                        <div *ngIf="orderLine.stock?.isLoading" class="spinner-wrapper">
                            <mat-spinner diameter="20"></mat-spinner>
                        </div>
                        <span *ngIf="!orderLine.stock?.isLoading"
                              [ngClass]="getStockIndicatorClass(orderLine.stock?.status)"
                              matTooltipClass="multi-line-tooltip"
                              [matTooltip]="hasStock(orderLine.stock?.status) ? stockDetailsTooltip(orderLine.stock?.stockDetails, orderLine.stock?.totalStock) : ''">
                              {{displayStockStatus(orderLine.stock?.status) | translate}}
                          </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef
                        class="table-header">  {{'CHECK.AND.CONFIRM.ORDER.QUANTITY' | translate}}</th>
                    <td mat-cell *matCellDef="let orderLine" class="element">{{orderLine.quantityOrdered}} </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header" >
                        <mat-checkbox [disabled]="isNorauto$ | async"  [(ngModel)]="isAllSelected" (change)="confirmAllRows()" id="mat-checkbox-commande"></mat-checkbox>
                            {{'SPARE_PART_STATUS' | translate}}
                    </th>
                    <td class="element" mat-cell *matCellDef="let orderLine; let indexLine = index;">
                        <mat-checkbox [disabled]="isNorauto$ | async"  [(ngModel)]="orderLine.isSelected"
                                      (change)="updateItemStatus(orderLine)" class="mat-element"></mat-checkbox>
                        <span class="notranslate">{{displayItemStatus(orderLine.itemStatus) | translate }}</span>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            </div>
        </div>
        <div fxLayoutAlign="end center" class="mt-24">
            <button type="submit" mat-stroked-button
                    id="submitButton"
                    (click)="OnSubmit()"
                    [ngClass]="'aster_btn'">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>
    </mat-card-content>