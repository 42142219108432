    <mat-card-content>
        <form [formGroup]="reparationAgreementForm"
              fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px"
              (ngSubmit)="onSubmit()">
            <app-workflow-status-progress-bar taskTimeoutDuration="{{getTaskDuration()}}"
                                              currentStatusDate="{{getStatusDate()}}">
            </app-workflow-status-progress-bar>

            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>

            <mat-radio-group fxLayout="column" fxLayoutGap="20px"
                             formControlName="choice">
                <mat-radio-button [id]="'accept_agreement'" [value]="true">
                    {{'COMPONENT.WAITING_REPARATION_AGREEMENT.ACCEPTED'|translate}}
                </mat-radio-button>
                <ng-container *ngIf="reparationAgreementForm.value.choice === true">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>{{'COMPONENT.WAITING_REPARATION_AGREEMENT.NUMBER'|translate}}</mat-label>
                        <input matInput inputControl [placeholder]="'COMPONENT.WAITING_REPARATION_AGREEMENT.NUMBER'|translate"
                               [id]="'agreement_number'"
                               formControlName="agreementNumber" required>
                    </mat-form-field>
                    <mat-radio-group fxLayout="column" fxLayoutGap="20px" class="ml-20"
                                     formControlName="productState">
                        <mat-radio-button [id]="'toRepair'" [value]="true">
                            {{'COMPONENT.PRODUCT_TO_REPAIR'|translate}}
                        </mat-radio-button>
                        <ng-container *ngIf="reparationAgreementForm.value.productState === true">
                            <div class="text-bold" >
                                <span class="repair" fxFlex="70">
                                    {{'FOLDER.REPAIRER.LABEL' | translate}}: {{folder.repairCenter.label}}
                                </span>
                                <span fxFlex="30" class="info link pl-12" (click)="showRepairers()">  {{'UPDATE.REPAIRER' | translate}}</span>
                            </div>

                            <ng-container *ngIf="hideRepairers===false">
                                <mat-form-field appearance="outline" fxFlex="50">
                                    <mat-label>{{'COMPONENT.OTHER_REPAIRER'|translate}}</mat-label>
                                    <mat-select formControlName="otherRepairer" [id]="'repairer'">
                                        <mat-option *ngFor="let repairerItem of repairers"
                                                    [value]="repairerItem">
                                            <span>{{  repairerItem.code + ' - ' + repairerItem.label }}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </ng-container>

                        <mat-radio-button [id]="'unRepairable'" [value]="false">
                            {{'COMPONENT.PRODUCT_NOT_REPARABLE'|translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-container>
                <mat-radio-button [id]="'refuse_agreement'" [value]="false">
                    {{'COMPONENT.WAITING_REPARATION_AGREEMENT.REFUSED'|translate}}
                </mat-radio-button>
                <ng-container *ngIf="reparationAgreementForm.value.choice === false">
                    <mat-radio-group fxLayout="column" fxLayoutGap="20px" class="ml-20"
                                     formControlName="outOfWarranty">
                        <mat-radio-button [id]="'missingElements'" [value]="'MISSING_ELEMENTS'">
                            {{'COMPONENT.MISSING_ELEMENTS'|translate}}
                        </mat-radio-button>
                        <mat-radio-button [id]="'outOfWarranty'" [value]="'OUT_OF_WARRANTY'">
                            {{'COMPONENT.OUT_OF_WARRANTY'|translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                    <app-comment-area [commentForm]="reparationAgreementForm.get('comment')"
                                      [isRequired]="true"></app-comment-area>
                </ng-container>
            </mat-radio-group>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        [ngClass]="reparationAgreementForm.invalid ?'aster_btn_disabled':'aster_btn'"
                        [disabled]="reparationAgreementForm.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>

        </form>
    </mat-card-content>