<div *ngIf="!!invoice">
    <div *ngIf="invoiceList && invoiceList.data.length > 0" fxLayout="column" fxLayoutAlign="start stretch">
        <button type="button" mat-stroked-button color="accent" (click)="backToInvoiceList()"
                class="py-12 px-24 mb-8 mr-8 font-weight-900 font-size-30">
            {{'FOLDER.CREATE.BACK_INVOICE_LIST_BUTTON' | translate}}
        </button>
    </div>

    <div *ngIf="!invoiceNotFound" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <mat-form-field appearance="outline" fxFlex="33">
            <mat-label>{{'INVOICE.INVOICE_NUMBER'|translate}}</mat-label>
            <input matInput placeholder="{{'INVOICE.INVOICE_NUMBER'|translate}}" [(ngModel)]="invoice.id"
                   id="invoice_number"
                   (blur)="invoiceNumberChanged(invoice.id)"
                   [disabled]="!editInvoice">
            <mat-icon matSuffix class="disabled-text cursor-pointer" (click)="switchEditInvoiceNumber()">edit
            </mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33">
            <mat-label>{{'INVOICE.PURCHASE_DATE' | translate }}</mat-label>
            <input matInput id="invoice_creationDate"
                   [placeholder]="'INVOICE.PURCHASE_DATE' | translate"
                   [(ngModel)]="invoice.creationDate"
                   (blur)="invoiceCreationDateChanged(invoice.creationDate)"
                   (dateChange)="invoiceCreationDateChanged(invoice.creationDate)"
                   [max]="maxDate"
                   [required]="isPurchaseDateRequired"
                   [matDatepicker]="invoiceCreationDate">
            <mat-datepicker-toggle matSuffix [for]="invoiceCreationDate"></mat-datepicker-toggle>
            <mat-datepicker #invoiceCreationDate></mat-datepicker>
        </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="33">
                <mat-label>{{'INVOICE.SITE'|translate}}</mat-label>
                <input type="text"
                       [id]="'site_code'"
                       [placeholder]="'INVOICE.SITE'|translate"
                       matInput
                        [(ngModel)]="invoice.siteCode"
                       [value]="siteLabel"
                       [formControl]="siteCtrl"
                       [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="valueMapper">
                    <mat-option *ngFor="let site of filteredSites | async"
                                [id]="site.code"
                                [value]="site">
                        {{site.code}} - {{site.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>


    </div>
</div>
<div *ngIf="invoiceNotFound">

    <form [formGroup]="invoiceNotFoundForm">
        <span class="invoice-not-found">{{'INVOICE.INVOICE_NOT_FOUND'|translate}}</span>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-form-field appearance="outline" fxFlex="33">
                <mat-label>{{'INVOICE.INVOICE_NUMBER'|translate}}</mat-label>
                <input matInput placeholder="{{'INVOICE.INVOICE_NUMBER'|translate}}" formControlName="id"   id="invoice_code"
                       (blur)="invoiceNumberChanged(invoiceNotFoundForm.value.id)">
                <mat-icon matSuffix class="disabled-text cursor-pointer" (click)="switchEditInvoiceNumber()">edit
                </mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="33">
                <mat-label>{{'INVOICE.PURCHASE_DATE' | translate }}</mat-label>
                <input matInput
                       [placeholder]="'INVOICE.PURCHASE_DATE' | translate"
                       formControlName="creationDate"  id="invoice_creationDate"
                       (blur)="invoiceCreationDateChanged(invoiceNotFoundForm.value.creationDate)"
                       (change)="invoiceCreationDateChanged(invoiceNotFoundForm.value.creationDate)"
                       (dateChange)="invoiceCreationDateChanged(invoiceNotFoundForm.value.creationDate)"
                       [max]="maxDate"
                       [matDatepicker]="invoiceCreationDate">
                <mat-datepicker-toggle matSuffix [for]="invoiceCreationDate"></mat-datepicker-toggle>
                <mat-datepicker #invoiceCreationDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="33">
                <mat-label>{{'INVOICE.SITE'|translate}}</mat-label>
                <input type="text"
                       [placeholder]="'INVOICE.SITE'|translate"
                       [id]="'site_code'"
                       matInput
                       formControlName="siteCode"
                       (blur)="siteCodeChanged(invoiceNotFoundForm.value.siteCode)"
                       [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="valueMapper">
                    <mat-option *ngFor="let site of filteredSites | async"
                                [id]="site.code"
                                [value]="site">
                        {{site.code}} - {{site.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

        </div>
    </form>
</div>