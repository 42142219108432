import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GLOBAL} from '../../../app-config';
import {AddAllDayAppointmentRequest} from '../../../models/AddAllDayAppointmentRequest.model';


@Injectable({
    providedIn: 'root'
})
export class RepairerCalendarService {
    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/v1/repairer/`;

    constructor(public  httpClient: HttpClient) {
    }

    addAppointment(repairerCode: string, organizationCode, request: AddAllDayAppointmentRequest): Observable<void> {
        const queryParams = {organizationCode};
        return this.httpClient.post<void>(this.path + repairerCode + '/appointment', request, {params: queryParams});
    }

    deleteAppointment(repairerCode: string, organizationCode, request: AddAllDayAppointmentRequest): Observable<void> {
        const queryParams = {organizationCode};
        const options = {
            body: request,
            params: queryParams
        };
        return this.httpClient.delete<void>(this.path + repairerCode + '/appointment', options);
    }

    getAvailableAppointmentPerMonth(repairerCode: string, organizationCode, depNum: string, year: string, month: string): Observable<any> {
        const queryParams = {organizationCode, year, month};
        return this.httpClient.get(this.path + repairerCode + '/appointment', {params: queryParams});
    }

    getAvailableAppointment(repairerCode: string, organizationCode: string, departmentCode: string): Observable<any> {
        const queryParams = {organizationCode};
        return this.httpClient.get(this.path + repairerCode + '/appointment/all/' + departmentCode, {params: queryParams});
    }
}
