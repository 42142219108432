import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder, IFolderUpdateRequest} from '../../../../../models/folder.model';
import {Shipment} from '../../../../../models/shipment.model';
import {FolderService} from '../../../../../shared/services/folder.service';
import {FolderSubjectService} from '../../../folder-subject.service';
import {ReservationTicketChoice} from '../../../../../models/enums/reservationTicketChoice.enum';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ProductUtils} from '../../../../../shared/utils/product-utils';
import {BackOfficeService} from '../../../../../shared/services/back-office.service';
import {Carrier} from '../../../../../models/product-delivery-rule';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-handle-reservation-anomaly',
    templateUrl: './handle-reservation-anomaly.component.html'
})
export class HandleReservationAnomalyComponent implements OnInit {

    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    folder: Folder;
    handleReservationAnomalyForm: FormGroup;
    reservationTicketChoiceForm: FormGroup;
    reservationTicketChoiceList = [];
    existingShipment: Shipment;
    anomalyTitle$: Observable<string>;
    carrierCode: string;
    shipmentErrorMessage: string;
    carriers$: Observable<Carrier[]>;

    constructor(private translateService: TranslateService,
                private folderService: FolderService,
                private backOfficeService: BackOfficeService,
                private folderSubjectService: FolderSubjectService,
                private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.checkForTickets();
        this.initReservationTicketChoiceForm();
        this.initHandleReservationAnomaly();
        this.initCarrierList();
    }

    initReservationTicketChoiceForm() {
        this.reservationTicketChoiceForm = new FormGroup({
            status: new FormControl(null, Validators.required),
        });
        this.reservationTicketChoiceList = [{
            value: 'COMPONENT.HANDLE_RESERVATION_ANOMALY.CHOICE1', status: ReservationTicketChoice.USE_EXISTING_TICKET
        }, {
            value: 'COMPONENT.HANDLE_RESERVATION_ANOMALY.CHOICE2', status: ReservationTicketChoice.GENERATE_NEW_TICKET
        }];
    }

    initializeVariablesTask(): void {
        if (this.variablesTask['carrierCode']) {
            this.carrierCode = this.variablesTask['carrierCode'];
            this.handleReservationAnomalyForm.get(['carrier']).setValue(this.carrierCode);
        }
        if (this.variablesTask['shipmentErrorMessage']) {
            this.shipmentErrorMessage = this.variablesTask['shipmentErrorMessage'];
        }
        else{
            this.shipmentErrorMessage = this.translateService.instant('FOLDER.HANDLE_RESERVATION_ANOMALY.GENERIC_ERROR_MESSAGE');
        }
    }

    private initHandleReservationAnomaly(): void {
        this.handleReservationAnomalyForm = new FormGroup({
            trackingNumber: new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]),
            carrier: new FormControl(null, Validators.required),
        });
        this.initializeVariablesTask();
    }

    onSubmit(): void {
        if (this.reservationTicketChoiceForm.value.status === ReservationTicketChoice.USE_EXISTING_TICKET) {
            this.inputMap.emit({
                'trackingNumber': this.existingShipment.trackingNumber, 'carrierCode': this.existingShipment.carrier,
                'shippingStatus': 'SHIPMENT_HANDLED',
            });
        } else {
            this.deleteShipment(this.existingShipment);
            this.inputMap.emit({
                'trackingNumber': this.handleReservationAnomalyForm.get('trackingNumber').value, 'carrierCode': this.handleReservationAnomalyForm.get('carrier').value,
                'shippingStatus': 'SHIPMENT_HANDLED',
            });
        }
    }

    tryAgain(): void {
        this.inputMap.emit({
            'carrierCode': this.handleReservationAnomalyForm.get('carrier').value,
            'forceGeneration': true
        });
    }

    initCarrierList(): void {
        this.carriers$ = this.backOfficeService.getCarriers();
    }

    checkForTickets() {
        this.existingShipment = this.getShipmentByType(this.variablesTask['shipmentMode']);
        if (this.folder.shipments.length > 0 && !!this.existingShipment) {
            this.anomalyTitle$ = this.translateService.stream('COMPONENT.HANDLE_RESERVATION_ANOMALY.WARNING_MESSAGE', {
                trackingNumber: this.existingShipment.trackingNumber, carrierCode: this.existingShipment.carrier
            });
        } else {
            this.anomalyTitle$ = this.translateService.stream('FOLDER.HANDLE_RESERVATION_ANOMALY.TITLE');
        }
    }

    getShipmentByType(shipmentType: string): Shipment {
        return this.folder.shipments.find(shipment => shipment.type === shipmentType);
    }

    deleteShipment(existingShipment: Shipment): void {
        this.store$.dispatch(new StartLoading());
        const folderUpdateRequest: IFolderUpdateRequest = {
            shipmentToBeRemoved: existingShipment
        };
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
            this.folderSubjectService.folderLoaded(folder);
            this.folder = folder;
        }).catch(reason => {
            this.store$.dispatch(new StopLoading());
        });
    }

    isDisabledSubmit(): boolean {
        if (this.handleReservationAnomalyForm.invalid && this.reservationTicketChoiceForm.invalid) {
            return true;
        }
        if (!this.reservationTicketChoiceForm.invalid && this.reservationTicketChoiceForm.value.status === ReservationTicketChoice.GENERATE_NEW_TICKET) {
            return this.handleReservationAnomalyForm.invalid;
        }
    }

    displayHandleReservationAnomalyForm(): boolean {
        return !!this.reservationTicketChoiceForm
            && this.reservationTicketChoiceForm.value
            && (this.reservationTicketChoiceForm.value.status === ReservationTicketChoice.GENERATE_NEW_TICKET || this.existingShipment == null);
    }

    isDisabledTryAgainButton() {
        if (this.existingShipment == null) {
            return !this.handleReservationAnomalyForm.get(['carrier']).value || this.handleReservationAnomalyForm.get(['trackingNumber']).value;
        } else {
            return !this.handleReservationAnomalyForm.get(['carrier']).value || this.handleReservationAnomalyForm.get(['trackingNumber']).value
                || this.reservationTicketChoiceForm.value.status !== ReservationTicketChoice.GENERATE_NEW_TICKET;
        }
    }

    fetchProductLocationInfo(): string {
        return ProductUtils.latestLocation(this.folder.product) === 'REPARATION' ? this.translateService.instant('TASK.REPAIRER_RESPONSIBILITY') : '';
    }
}
