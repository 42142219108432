import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TaskVariables} from '../../task.variables';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-choose-repair-package',
    templateUrl: './choose-repair-package.component.html',
    styleUrls: ['./choose-repair-package.component.css']
})
export class ChooseRepairPackageComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();

    chooseRepairPackageForm: any = {
        form: null
    };
    availableRepairPackages: any[] = [];
    REPAIR_PACKAGE_REFUSED: 'REPAIR_PACKAGE_REFUSED';

    constructor() {
    }

    ngOnInit(): void {
        this.initVariables();
        this.initForm();
    }

    initVariables(): void {
        this.availableRepairPackages = JSON.parse(this.variablesTask[TaskVariables.availableRepairPackages]);
    }

    private initForm(): void {
        this.chooseRepairPackageForm.form = new FormGroup({
            decision: new FormControl(null, Validators.required),
        });
    }

    onSubmit(): void {
        this.inputMap.emit({
            decision: this.chooseRepairPackageForm.form.value.decision === 'DEVIS' ? 'REPAIR_PACKAGE_REFUSED' : 'REPAIR_PACKAGE_ACCEPTED',
            repairPackageCode: this.chooseRepairPackageForm.form.value.decision
        });
    }
}
