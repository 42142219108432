import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-credit-supplier',
    templateUrl: './waiting-credit-supplier.component.html',
    styleUrls: ['./waiting-credit-supplier.component.css']
})
export class WaitingCreditSupplierComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();
    commentTitle: string;
    folderId: string;

    creditSupplierStatusForm: any = {
        form: null,
        placeholders: []
    };

    constructor(private router: Router,
                private  route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.commentTitle = 'FOLDER.REFUSED_CREDIT_SUPPLIER.REASON';
        this.initForm();
        this.folderId = this.route.snapshot.parent.params.folderId;
    }

    private initForm(): void {
        this.creditSupplierStatusForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            comment: new FormControl(null),
        });
        this.creditSupplierStatusForm.placeholders = [
            {
                status: 'FOLDER_CLOSED_CREDIT_SUPPLIER_ACCEPTED',
                value: 'FOLDER.WAITING_CREDIT_SUPPLIER.ACCEPTED'
            },
            {
                status: 'FOLDER_CLOSED_CREDIT_SUPPLIER_REFUSED',
                value: 'FOLDER.WAITING_CREDIT_SUPPLIER.REFUSED'
            },
            {
                status: 'FOLDER_CLOSED_CREDIT_SUPPLIER_CANCELED',
                value: 'FOLDER.WAITING_CREDIT_SUPPLIER.CANCELED',
            },
        ];
        this.creditSupplierStatusForm.form.get('status').valueChanges.subscribe(requestCreditSupplier => {
            if ( requestCreditSupplier == null)
            {
                return;
            }
            if (requestCreditSupplier === 'FOLDER_CLOSED_CREDIT_SUPPLIER_CANCELED') {
                this.creditSupplierStatusForm.form.get('comment').setValidators(Validators.required);
            }
            else{
                this.resetValidator(this.creditSupplierStatusForm.form.get('comment'));
            }
            this.creditSupplierStatusForm.form.get('comment').updateValueAndValidity();

        });
    }
    private resetValidator(ValidatorValue: AbstractControl) {
        ValidatorValue.setValue(null);
        ValidatorValue.clearValidators();
    }
    onSubmit(): void {
        if (!!this.creditSupplierStatusForm.form.value.comment && this.creditSupplierStatusForm.form.value.comment.length > 0) {
            this.commentGiven.emit({
                value: this.creditSupplierStatusForm.form.value.comment,
                type: 'REASON_FOR_CANCEL_CREDIT_SUPPLIER'
            });
        }
        this.inputMap.emit({
            closingFolderReason: this.creditSupplierStatusForm.form.value.status
        });
    }
}
