import {Component, Input, OnInit} from '@angular/core';
import {Invoice} from '../../../../models/invoice.model';
import {FolderCreationService} from '../folder-creation.service';
import {forkJoin, Observable, Subject} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FolderService} from '../../../../shared/services/folder.service';
import {debounceTime, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import {CODE, LABEL, SiteService} from '../../../../shared/services/site.service';
import {Site, SiteSearchRequest} from '../../../../models/site.model';
import {Page} from '../../../../models/page.model';
import {currentUser} from '../../../../store/user/user.selectors';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {Unsubscriber} from '../../../../unsubscriber';


@Component({
    selector: 'app-invoice-form',
    templateUrl: './invoice-form.component.html',
    styleUrls: ['./invoice-form.component.css']
})
export class InvoiceFormComponent extends Unsubscriber implements OnInit {

    @Input() invoiceNotFound: boolean;

    @Input() clearInvoice: Subject<boolean> = new Subject<boolean>();
    @Input() isPurchaseDateRequired: boolean;


    invoice = new Invoice();
    editInvoice = true;
    maxDate: Date;
    invoiceNotFoundForm: FormGroup;
    public siteCtrl: FormControl;
    filteredSites: Observable<Site[]>;
    siteLabel: string;
    constructor(private folderCreationService: FolderCreationService,
                private folderService: FolderService,
                private siteService: SiteService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.initInvoiceNotFoundForm();
        this.maxDate = new Date();
        this.anotherSubscription = this.folderCreationService.invoice$
            .subscribe(invoice => {
                if (invoice?.id) {
                    this.editInvoice = false;
                    this.invoice = invoice;
                }
                else {
                        this.invoice = invoice;
                        this.editInvoice = true;
                        this.initInvoiceNotFoundForm();
                        this.folderService.findInvalidControls(this.invoiceNotFoundForm);
                    }

            });

        this.anotherSubscription = this.store$.pipe(
            select(currentUser))
            .subscribe(currentUser => this.initSitesFilter(currentUser.organizationCode, currentUser.context));

        this.anotherSubscription = this.clearInvoice.subscribe(data => {
            this.invoice = new Invoice();
        });
    }


    initInvoiceNotFoundForm(): void {
        this.invoiceNotFoundForm = new FormGroup({
            id: new FormControl(null, Validators.required),
            creationDate: new FormControl(null, Validators.required),
            siteCode: new FormControl(null, Validators.required),
        });
    }

    switchEditInvoiceNumber(): void {
        this.editInvoice = !this.editInvoice;
    }

    invoiceNumberChanged(invoiceId): void {
        this.invoice.id = invoiceId;
        this.folderCreationService.invoiceChanged(this.invoice);
    }


    invoiceCreationDateChanged(invoicePurchaseDate): void {
        this.invoice.creationDate = invoicePurchaseDate;
        this.folderCreationService.invoiceChanged(this.invoice);
    }

    backToInvoiceList(): void {
        this.invoice = null;
    }

    initSitesFilter(organizationCode: string, context: string): void {
        this.siteCtrl = new FormControl(null);
        this.filteredSites = this.siteCtrl.valueChanges
            .pipe(
                startWith(''),
                // when we select a site from the dropdown list, valueChanges() will be fired with a value of type 'object' instead of a 'string'
                // in this case, we map the new value
                map(x => typeof x === 'object' ? x.code : x),
                debounceTime(500),
                distinctUntilChanged(),
                switchMap(searchValue => this.filter(searchValue || '', organizationCode, context))
            );
    }

    filter(searchValue: string, organizationCode: string, context: string): Observable<Site[]> {
        const siteSearchRequest: SiteSearchRequest = {
            organizationCode: organizationCode,
            context: context,
            page: '0',
            size: '30'
        };

        const combined = forkJoin([
            this.siteService.getAllSites(this.computeFilters(CODE, searchValue, siteSearchRequest)),
            this.siteService.getAllSites(this.computeFilters(LABEL, searchValue, siteSearchRequest))]
        );
        return combined.pipe(
            map((pagedSites: [Page<Site>, Page<Site>]) => {
                const sites = pagedSites.reduce((all: Site[], page: Page<Site>) => all.concat(page.content), []);
                return [...new Map(sites.map(site => [site.code, site])).values()];
            }),
            map(results => [...results].sort((x, y) => x.code > y.code ? 1 : -1))
        );
    }

    computeFilters(filterName: string, filterValue: string, siteSearchRequest: SiteSearchRequest): SiteSearchRequest {
        return !!filterValue ? {...siteSearchRequest, [filterName]: filterValue} : siteSearchRequest;
    }

    public valueMapper = (site) => {
        return !!site && (typeof site === 'object') ? site.code + ' - ' + site.name : site;
    }

    siteCodeChanged(siteCode: string): void {
        this.invoice.siteCode = siteCode;
        this.folderCreationService.invoiceChanged(this.invoice);
    }

}
