import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
    STATUS_CLOSED_FOLDER_AUTO_REPAIR_REASON,
    STATUS_CLOSED_FOLDER_HOME_REPAIR_REASON,
    STATUS_CLOSED_FOLDER_REFUND_REASON,
    STATUS_CLOSED_FOLDER_SERVICE_CENTER_REPAIR_REASON,
    STATUS_CLOSED_FOLDER_STORE_DEPOSIT_REASON,
    STATUS_CLOSED_FOLDER_SWAP_REASON
} from '../../../app/main/dashboard/dashboard-folder/widget.data';
import {OperationModeEnum} from '../../../app/models/enums/operationMode.enum';
import {Context} from '../../../app/models/enums/context.enum';
import {Folder} from '../../../app/models/folder.model';

@Component({
    selector: 'app-close-folder-modal',
    templateUrl: './confirm-dialog-with-comment.component.html',
    styleUrls: ['./confirm-dialog-with-comment.component.scss']
})
export class ConfirmDialogWithCommentComponent implements OnInit {

    public title = 'Confirm';
    public message = 'Are you sure?';
    public commentMessage = 'Are you sure?';
    public confirmButtonLabel = 'Confirm';
    public cancelButtonLabel = 'Cancel';
    statusList: string[] = [];
    commentForm = {
        form: null
    };

    constructor(public dialogRef: MatDialogRef<ConfirmDialogWithCommentComponent>,
                @Inject(MAT_DIALOG_DATA) public folder: Folder) {
    }

    ngOnInit(): void {
        if (!!this.folder) {
            this.getStatusListWithModOp();
        }
        this.initCommentForm();
        this.closeWithEscape();
    }
    private closeWithEscape(): void {
        this.dialogRef.keydownEvents().subscribe(event => {
            if (event.key === 'Escape' || event.key === 'Esc') {
                this.onClose();
            }
        });
    }
    getStatusListWithModOp(): void {
        const swapHandler = () => { this.statusList = STATUS_CLOSED_FOLDER_SWAP_REASON; };
        const autoRepairHandler = () => {this.statusList = STATUS_CLOSED_FOLDER_AUTO_REPAIR_REASON; };
        const homeRepairHandler = () => {  this.statusList = STATUS_CLOSED_FOLDER_HOME_REPAIR_REASON; };
        const serviceCenterRepairHandler = () => {  this.statusList = STATUS_CLOSED_FOLDER_SERVICE_CENTER_REPAIR_REASON; };
        const storeDepositHandler = () => {  this.statusList = STATUS_CLOSED_FOLDER_STORE_DEPOSIT_REASON; };
        const refundHandler = () => {  this.statusList = STATUS_CLOSED_FOLDER_REFUND_REASON; };

        const Handlers = {
            [OperationModeEnum.SWAP]: swapHandler,
            [OperationModeEnum.AUTO_REPAIR]: autoRepairHandler,
            [OperationModeEnum.HOME_REPAIR]: homeRepairHandler,
            [OperationModeEnum.SERVICE_CENTER_REPAIR]: serviceCenterRepairHandler,
            [OperationModeEnum.NO_MODOP]: storeDepositHandler,
            [OperationModeEnum.REFUND]: refundHandler
        };

        const handler = Handlers[this.folder.operationMode];
        handler && handler.call();
    }

    initCommentForm(): void {
        this.commentForm.form = new FormGroup({
            currentStatus: new FormControl('', this.isProductRecallContext() ? [Validators.required]: null),
            comment: new FormControl(null, [Validators.required])
        });
    }

    onClose(): void {
        this.dialogRef.close();
    }
    onSubmit(): void {
        if (this.commentForm.form.invalid) {
            return;
        }
        if (!this.commentForm.form.value.currentStatus) {
            this.commentForm.form.get(['currentStatus']).setValue('FOLDER_CLOSED_ALREADY_REPAIRED');
        }
        this.dialogRef.close(this.commentForm.form.value);
    }

    isProductRecallContext(): boolean {
        return !!this.folder && this.folder.context === Context.PRODUCT_RECALL;
    }

}
