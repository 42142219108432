<mat-card class="w-100-p">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="font-weight-900" fxLayoutAlign=" start">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px">
                <div fxLayout="column" fxLayoutAlign=" center">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-icon class="font-size-40  w-40  h-40 pt-8"
                                  [ngClass]="'font-size-40 w-40  h-40 pt-8 ' + lastWarranty.icon.class">
                            {{lastWarranty.icon.code}}
                        </mat-icon>
                        <span class="mt-4 ml-8 h3 secondary-text text-capitalized">
                                {{
                            folder.newWarranty.warranty === 'SG'
                                ? (folder.newWarranty.warrantyReason| codeToLabel: 'SAV.CONFIG.WARRANTY_REASON_SG' | async)
                                : (folder.newWarranty.warrantyReason| codeToLabel: 'SAV.CONFIG.WARRANTY_REASON_HG' | async)
                            }}
                         </span>
                        <div class="ml-8 mt-8 origin-warranty"
                             *ngIf=" !!folder.warrantyOrigin.warranty && folder.warrantyOrigin.warranty.toString() != lastWarranty.key ">
                            {{'FOLDER.WARRANTY.TYPE.ORIGIN' |translate}}
                            {{('FOLDER.WARRANTY.ENUM_LABELS.' + folder.warrantyOrigin.warranty.toString()) |translate}}
                            <br>
                            {{ folder.warrantyOrigin.warranty === 'SG'
                            ? (folder.warrantyOrigin.warrantyReason| codeToLabel: 'SAV.CONFIG.WARRANTY_REASON_SG' | async)
                            : folder.warrantyOrigin.warrantyReason| codeToLabel: 'SAV.CONFIG.WARRANTY_REASON_HG' | async}}
                        </div>
                    </div>


                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-icon class="font-size-40 w-40  h-40 pt-8"
                              [svgIcon]="getIconName(folder.operationMode)">
                    </mat-icon>
                    <div>
                            <span class="h3 ml-8 secondary-text text-capitalized pt-4">
                                {{ folder.operationMode| codeToLabel: 'SAV.CONFIG.MODE_OP' | async}}
                            </span>
                        <br>
                        <ng-container *ngIf="!!folder.oldOperationMode else FROM_OLD_REPARATION_LIST">
                            <div class="ml-8 origin-warranty">
                                {{'FOLDER.WARRANTY.TYPE.ORIGIN' |translate}}  {{ folder.oldOperationMode| codeToLabel: 'SAV.CONFIG.MODE_OP' | async}}
                            </div>
                        </ng-container>
                        <ng-template #FROM_OLD_REPARATION_LIST>
                            <div *ngIf="!!lastOperationMode" class="ml-8 origin-warranty">
                                {{'FOLDER.WARRANTY.TYPE.ORIGIN' |translate}}  {{ lastOperationMode.operationMode| codeToLabel: 'SAV.CONFIG.MODE_OP' | async}}
                                <br>
                                <ng-container *ngIf="!!lastOperationMode.switchOperationModeReason">
                                    {{'FOLDER.OPERATING_MODE.REASON' |translate}} {{ 'SWITCH.REASON.' + lastOperationMode.switchOperationModeReason| translate}}
                                </ng-container>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div *ngIf="isProductRecall">
                <button mat-icon-button (click)="openFolderInFOF()">
                    <mat-icon class="mat-24">stay_primary_landscape</mat-icon>
                </button>
            </div>
            <div *ngIf="isSAV && (canOpenFolderInFOF() || (hasScope(Scopes.FOLDER_PUBLIC_REPAIRER) | async) || (hasScope(Scopes.FOLDER_PUBLIC_SUPPLIER) | async))">
                <mat-button class="iconButton" [matMenuTriggerFor]="menu">
                    <mat-icon class="mat-24">stay_primary_landscape</mat-icon>
                </mat-button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngIf="canOpenFolderInFOF()" (click)="openFolderInFOF()">
                        {{ 'SELECTED.OPTIONS.FOF' | translate }}
                    </button>
                        <button mat-menu-item
                                *ngIf="(hasScope(Scopes.FOLDER_PUBLIC_REPAIRER) | async)" (click)="openPublicPage('repairer')">
                            {{ 'SELECTED.OPTIONS.REPAIRER.VIEW' | translate }}
                        </button>
                        <button mat-menu-item
                                *ngIf="(hasScope(Scopes.FOLDER_PUBLIC_SUPPLIER) | async)"  (click)="openPublicPage('supplier')">
                            {{ 'SELECTED.OPTIONS.SUPPLIER.VIEW' | translate }}
                        </button>
                </mat-menu>
            </div>
        </div>

    </div>
</mat-card>
