<div class="page-layout blank p-24" fusePerfectScrollbar>
    <ng-container *ngIf="chartsResponse">
        <!-- Header Form -->
        <div fxLayout="column" fxLayoutAlign="end end" class="dashboard-header mr-8 " >
            <form fxLayoutGap="10px" class="mr-60 mb-24" [formGroup]="siteForm">
                <mat-form-field  appearance="outline" *ngIf="isCurrentUserAdmin$ | async" class="form-add-button">
                    <mat-label>{{'DASHBOARD.SITE.LABEL' | translate }}</mat-label>
                    <mat-select
                        formControlName="siteCode"
                        (selectionChange)="loadDashboard()"
                        [placeholder]="'DASHBOARD.SITE.LABEL.PLACEHOLDER' | translate"
                        aria-label="">
                        <mat-option>
                            <ngx-mat-select-search
                                formControlName="siteFilterCtrl"
                                [placeholderLabel]="'GENERAL.SEARCH_PLACEHOLDER' | translate"
                                [noEntriesFoundLabel]="'GENERAL.SEARCH_NO_ITEM' | translate"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of filteredSiteList$ | async"
                                    [value]="item.code">
                            <span>{{ item.code }} - {{ item.label }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>

        <!-- Chart -->
        <div fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
            <ng-container *ngFor="let chart of charts | keyvalue ;let i=index;">
                <div gdGap="20px"
                     [gdAreas]="getAreasChart(chart.key)">
                    <ng-container *ngFor="let widgetData of getChartResponse(chart.key);let i=index">
                        <div [gdArea]="getChartName(widgetData.name)">
                            <ng-container [ngSwitch]="widgetData.type">
                                <ng-container *ngSwitchCase="'METRIC'">
                                    <div class="mb-48 cursor-pointer" fxFlex>
                                        <widget-price-count [widgetData]="widgetData"
                                                            (elementSelected)="getFolderList($event, widgetData.name)"></widget-price-count>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'MAP'">
                                    <div class="mb-48 example-box"
                                         *ngIf="widgetData && widgetData.data.length > 0">
                                        <app-leaflet-markercluster class="mt-24"
                                                                   [widgetData]="widgetData"></app-leaflet-markercluster>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'BAR_CHART'">
                                    <div class="mb-48 cursor-pointer" fxFlex>
                                        <widget-bar-chart-horizontal
                                            [widgetData]="widgetData"
                                            (elementSelected)="getFolderList($event, widgetData.name)"></widget-bar-chart-horizontal>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'PIE_CHART'">
                                    <div class="mb-48 cursor-pointer" fxFlex>
                                        <widget-pie-chart [widgetData]="widgetData"
                                                          (elementSelected)="getFolderList($event, widgetData.name)"></widget-pie-chart>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'ADVANCED_PIE_CHART'">
                                    <div class="mb-48 cursor-pointer" fxFlex>
                                        <advanced-pie-chart [widgetData]="widgetData"
                                                            (elementSelected)="getFolderList($event, widgetData.name)"></advanced-pie-chart>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'PIE_CHART_GRID'">
                                    <div class="mb-48 cursor-pointer" fxFlex>
                                        <pie-chart-grid [widgetData]="widgetData"
                                                        (elementSelected)="getFolderList($event, widgetData.name)"></pie-chart-grid>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'TABLE'">
                                    <div class="mb-48 cursor-pointer" fxFlex>
                                        <widget-list-table [widgetData]="widgetData"
                                                           (elementSelected)="getFolderList($event, widgetData.name)"></widget-list-table>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>