import {Injectable} from '@angular/core';
import {SnackBarService} from '../../../shared/services/snack-bar.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {PushNotificationPayload} from '../folder-workflow/push-notification-payload';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import {FolderSubjectService} from '../folder-subject.service';
import {FolderService} from '../../../shared/services/folder.service';
import {Folder} from '../../../models/folder.model';

export const TOPIC = '/topic/bo-notifications/';

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    stompClient: any;

    constructor(private snackBar: SnackBarService, private translateService: TranslateService,
                private folderSubjectService: FolderSubjectService, private folderService: FolderService) {
    }

    initWebsocket(claimId: string): void {
        const ws = new SockJS(environment.pushNotificationWebSocketEndpoint);
        this.stompClient = Stomp.over(ws);
        this.stompClient.connect({}, () => {
            this.stompClient.subscribe(TOPIC + claimId, (pushNotificationEvent) => {
                const pushNotificationPayload = JSON.parse(pushNotificationEvent.body) as PushNotificationPayload;

                if (!!pushNotificationPayload.data.events && (pushNotificationPayload.data.events.filter(e => e.eventName === 'FOLDER_ATTACHMENT_ADDED').length > 0
                    || pushNotificationPayload.data.events.filter(e => e.eventName === 'SPARE_PARTS_ORDER_REFERENCE_ADDED').length > 0)) {
                    this.folderService.getFolder(claimId).subscribe(claim => {
                        this.publishClaim(claim);
                        this.displaySnackBar();
                    });
                }
            });
        });
    }

    private publishClaim(claim: Folder) {
        this.folderSubjectService.folderLoaded(claim);
    }

    private displaySnackBar() {
        const message = this.translateService.instant('FOLDER.EVENT.ENUM_LABELS.FOLDER_ATTACHMENT_ADDED');
        this.snackBar.openAtEnd('Info', message);
    }

    disconnect(claimId: string): void {
        if (!!this.stompClient && this.stompClient.connected) {
            this.stompClient.disconnect(() => {
                console.log(`Websocket disconnected for claim ${claimId}.`);
            });
        }
    }
}
