import {Component, Input} from '@angular/core';
import {ProductFamilyType} from '../../../../models/enums/productFamilyType.enum';
import {ConfigCodeMapsEnum} from '../../../../shared/services/configuration-item-enum';
import {Product} from '../../../../models/product.model';

@Component({
    selector: 'app-product-nomenclature',
    templateUrl: './product-nomenclature.component.html',
    styleUrls: ['./product-nomenclature.component.scss']
})
export class ProductNomenclatureComponent {


    @Input() product: Product;

    familiesType = Object.values(ProductFamilyType);

    findProductConfig = (familyType: string) => ConfigCodeMapsEnum[familyType];

    findFamily = (familyType: string) => {
        const family = this.product?.families.find(it => it.type === familyType);
        if (this.product?.organization?.code?.startsWith('LM_')) {
            return family?.code;
        } else {
            return family?.label ?? family?.code;
        }
    }
}
