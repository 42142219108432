import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DetailRemoteAssistanceDialogComponent} from '../../detail-remote-assistance-dialog/detail-remote-assistance-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {NotificationService} from '../../../../../shared/services/notification.service';
import {Folder} from '../../../../../models/folder.model';
import {FolderSubjectService} from '../../../folder-subject.service';
import {SearchNotificationRequest} from '../../../../../models/SearchNotificationsRequest.model';
import {NotificationType} from '../../../../../models/enums/NotificationType.enum';
import {Notification, NotificationSearchResponse} from '../../../../../models/notification.model';
import {FuseConfirmDialogComponent} from '../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {isAdminPlatana} from '../../../../../store/user/user.selectors';
import {Unsubscriber} from '../../../../../unsubscriber';

@Component({
    selector: 'app-folder-notifications-card',
    templateUrl: './folder-notifications-card.component.html',
    styleUrls: ['./folder-notifications-card.component.scss'],
    animations: [trigger('detailExpand', [state('collapsed', style({
        height: '0px', minHeight: '0', display: 'none'
    })), state('expanded', style({height: '*'})), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))])],
})
export class FolderNotificationsCardComponent extends Unsubscriber implements OnInit {

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    @Input() notificationCount;

    notificationConfig: NotificationSearchResponse;
    expandedElement: string;

    folder: Folder;
    notificationsType = [NotificationType.ALL, NotificationType.MAIL, NotificationType.SMS, NotificationType.CALL];
    CALL = NotificationType.CALL;
    notificationCall = [NotificationType.CALL_IN, NotificationType.CALL_OUT, NotificationType.REMOTE_ASSISTANCE];
    currentNotificationType: NotificationType;
    pageIndex = 0;
    pageSize = 10;
    folderClosed: boolean;
    notificationSelected;
    folderNotificationDataSource: any = {
        displayedColumns: ['type', 'date', 'status', 'scheduleStatus', 'sentBy'],
        expandedCallElement: null,
        filterForm: null,
        page: null,
        pageSizeOptions: [5, 10, 25],
    };

    constructor(private dialog: MatDialog,
                private notificationService: NotificationService,
                private store$: Store<AppState>,
                private folderSubjectService: FolderSubjectService) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(isAdminPlatana)).subscribe(isAdminPlatana => {
            if (isAdminPlatana) {
                this.folderNotificationDataSource.displayedColumns.push('action');
            }
        });
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
            this.folderClosed = folder.currentStatus.status.toString().startsWith('FOLDER_CLOSED');

        });
        this.getFolderNotification();
    }


    getFolderNotification(notificationType?: NotificationType): void {
        if (!this.folder) {
            return;
        }
        const searchNotificationRequest: SearchNotificationRequest = {
            page: this.pageIndex, size: this.pageSize, filters: new Map()
        };
        if (!!notificationType) {
            this.currentNotificationType = notificationType;
            searchNotificationRequest.filters['type'] = this.currentNotificationType;
        }
        this.anotherSubscription = this.notificationService.getNotificationsFolder(searchNotificationRequest, this.folder.id).subscribe(res => {
            this.notificationConfig = res;
            this.notificationService.updateNotificationCount(this.notificationConfig.totalElements);
        });
    }

    openModal(): void {
        if (this.folderClosed) {
            return;
        }
        const dialogRef = this.dialog.open(DetailRemoteAssistanceDialogComponent, {
            hasBackdrop: true, disableClose: false, autoFocus: false, width: '600px', data: this.folder.id
        });
        dialogRef.afterClosed().subscribe(data => {
            if (!!data) {
                this.notificationSelected = NotificationType.ALL;
                const notification = {
                    deliverabilityStatus: data.deliverability.deliverabilityStatus,
                    id: data.id,
                    businessId: data.businessId,
                    businessLink: data.businessLink,
                    organizationCode: data.organizationCode,
                    changeStatusDate: data.deliverability.deliverabilityStatusDate,
                    type: data.type,
                    context: data.context,
                    scheduleStatus: data.scheduleStatus,
                };
                this.notificationConfig.content.push(notification);
                this.notificationConfig.content = JSON.parse(JSON.stringify(this.notificationConfig.content));
                this.notificationCount++;
                this.notificationService.updateNotificationCount(this.notificationCount);
            }
        });
    }

    selectFilterType(event): void {
        event.value = event.value === 'ALL' ? null : event.value;
        let element: any = this.expandedElement = event.value;
        if (element === NotificationType.CALL) {
            element = this.notificationCall;
        }
        this.getFolderNotification(element);
    }


    changePage(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getFolderNotification();
    }

    onDelete(notification: Notification, notificationIndex: number): void {
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });

        dialogRef.componentInstance.title = 'CONFIRMATION.MODAL.DELETE_DIALOG.TITLE';
        dialogRef.componentInstance.message = 'FOLDER.NOTIFICATION.DELETE_DIALOG.MESSAGE';

        dialogRef.afterClosed().subscribe(isDeleteConfirmed => {
            if (isDeleteConfirmed) {
                this.notificationService.deleteNotification(notification.id).then(() => {
                    this.notificationConfig.content.splice(notificationIndex, 1);
                    this.notificationConfig.content = JSON.parse(JSON.stringify(this.notificationConfig.content));
                    this.notificationConfig.totalElements--;
                    this.notificationCount--;
                    this.notificationService.updateNotificationCount(this.notificationCount);
                });
            }
        });
    }

    getNotificationIcon(notificationType?: NotificationType): string {
        switch (notificationType) {
            case NotificationType.REMOTE_ASSISTANCE:
                return 'contact_phone';
            case NotificationType.CALL_IN:
                return 'phone_callback';
            case NotificationType.CALL_OUT:
                return 'phone_forwarded';
            case NotificationType.MAIL:
                return 'email';
            case NotificationType.SMS:
                return 'sms';
            default:
                return 'notification_important';
        }
    }
}


