import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {FolderIndex} from '../../../models/folder.index.model';
import {OPERATING_MODES_CONFIGS, WARRANTY_CONFIGS} from '../../../shared/data/static.config';
import {ConfigDisplayColumnsTableService} from '../../../shared/services/config-display-columns-table.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DashboardService} from '../../../shared/services/dashboard.service';
import {FolderSearchResponse} from '../../../models/folder-search-response.model';
import {ConfigurationReferential} from '../../../models/configurationReferential.model';
import {AppState} from '../../../store/app.state';
import {select, Store} from '@ngrx/store';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {multipleConfiguration} from '../../../store/configuration/configuration.selectors';
import {ConfigCodeMapsEnum} from '../../../shared/services/configuration-item-enum';
import {Unsubscriber} from '../../../unsubscriber';


@Component({
    selector: 'app-folder-list-modal',
    templateUrl: './folder-list.component-modal.html',
    styleUrls: ['./folder-list.component-modal.scss']
})
export class FolderListComponentModal extends Unsubscriber implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSourceFolder = {
        columnsToDisplay: ['INCREMENTAL_REFERENCE', 'REFERENCE', 'LAST_NAME', 'FIRST_NAME',
            'CREATE_DATE', 'STATUS', 'PRODUCT_LABELS', 'INDICATOR'],
        allColumnsName: ['INCREMENTAL_REFERENCE', 'REFERENCE', 'LAST_NAME', 'FIRST_NAME', 'CUSTOMER_MOBILE_PHONE', 'CREATE_DATE', 'STATUS',
            'DATE_WORKFLOW_STATUS', 'PRODUCT_LABELS', 'PRODUCT_CODE', 'INDICATOR']
    };

    folderList = new MatTableDataSource<FolderIndex>();
    pageSizeOptions: number[] = [5, 10, 25, 50, 100];
    pageConfig: FolderSearchResponse;
    folderFilterRequest: any = {};
    warrantyReasonList: ConfigurationReferential[];

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FolderListComponentModal>,
                private configDisplayColumnsTableService: ConfigDisplayColumnsTableService,
                private store$: Store<AppState>,
                private dashboardService: DashboardService) {
        super();
        this.folderFilterRequest = {page: 0, size: 10};
    }

    ngOnInit(): void {
        this.folderList.paginator = this.paginator;
        this.showConfigDisplayedColumns();
        this.getFolders();
        this.folderList.paginator = this.paginator;

        this.anotherSubscription = this.store$.pipe(select(multipleConfiguration, {configurationItemCodes: [ConfigCodeMapsEnum.WARRANTY_REASON_HG, ConfigCodeMapsEnum.WARRANTY_REASON_SG]}))
            .subscribe(it => this.warrantyReasonList = it);
    }

    private showConfigDisplayedColumns(): void {
        this.configDisplayColumnsTableService.showConfigDisplayedColumns(this.dataSourceFolder, 'folderTable');
    }

    goToFolderDetails(folder): void {
        this.dialogRef.close(folder);
    }

    private completeFolderWithWorkflowStatus(folderList: FolderIndex[]): void {
        folderList.forEach(folder => {
            folder = this.initializeSelectedItems(folder);
            if (folder.status && !folder.status.toString().startsWith('FOLDER_CLOSED')) {
                folder['currentStatusWithTranslation'] = 'COMPONENT.' + folder.currentWorkFlowStatus;
            } else {
                folder['closed'] = true;
            }
        });
        this.folderList.data = folderList;
    }

    initializeSelectedItems(folder): FolderIndex {
        if (!!folder.newWarranty && !!folder.operationMode) {
            const selectedWarranty = WARRANTY_CONFIGS.filter(el => el.key === folder.newWarranty.toString())[0];
            const selectedModOp = OPERATING_MODES_CONFIGS.filter(el => el.key === folder.operationMode.toString())[0];
            folder.newWarranty = selectedWarranty;
            folder.operationMode = selectedModOp;
        }
        return folder;
    }

    changePage(event: PageEvent): void {
        this.folderFilterRequest.size = event.pageSize;
        this.folderFilterRequest.page = event.pageIndex;
        this.getFolders();
    }

    onClose(): void {
        this.dialogRef.close();
    }

    private getFolders(): void {
        this.store$.dispatch(new StartLoading());
        this.dashboardService.findFolders(this.data, this.folderFilterRequest).subscribe(foldersResponse => {
            this.store$.dispatch(new StopLoading());
            this.pageConfig = foldersResponse;
            this.completeFolderWithWorkflowStatus(foldersResponse.content);
        }, () => this.store$.dispatch(new StopLoading()));
    }

    getWarrantyRaisonLabel(warrantyReason: string): string {
        const reason = this.warrantyReasonList.find(e => e.code === warrantyReason);
        return !!reason ? reason.label : '';
    }
}
