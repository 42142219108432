import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {TranslateService} from '@ngx-translate/core';
import {TaskVariables} from '../../task.variables';
import {WorkflowService} from '../../../../../shared/services/workflow.service';
import {getCurrencySymbol} from '@angular/common';
import {PaymentRequest} from '../../../../../models/payment.request';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {Observable} from 'rxjs';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-payment.component',
    templateUrl: './waiting-payment.component.html',
    styleUrls: ['./waiting-payment.component.scss']
})
export class WaitingPaymentComponent implements OnInit {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() refreshWorkflowStatus = new EventEmitter();
    titleCustomerToPay$: Observable<string>;
    paymentRequest: PaymentRequest;
    currency: string;

    constructor(private translateService: TranslateService,
                private store$: Store<AppState>,
                private workflowService: WorkflowService) {
    }

    ngOnInit(): void {
        this.buildPaymentRequest();
        this.getCurrency();
        this.buildMessage();
    }

    private buildMessage(): void {
        this.titleCustomerToPay$ = this.translateService.stream('FOLDER.WAITING_PAYMENT.CUSTOMER_TO_PAY',
            {
                price: this.paymentRequest.priceAmount.value,
                currency: getCurrencySymbol(this.currency || this.paymentRequest.priceAmount.currency, 'narrow')
            });
    }

    private getCurrency(): void {
        this.currency = this.variablesTask[TaskVariables.currency];
    }

    // temporary fix for 60 days
    private buildPaymentRequest(): void {
        const paymentRequest = JSON.parse(this.variablesTask[TaskVariables.paymentRequest]);
        if (paymentRequest.hasOwnProperty('amount')) {
            this.paymentRequest = {
                priceAmount: {
                    value: paymentRequest['amount'],
                    currency: 'EUR'
                },
                reason: paymentRequest['reason']
            };
        } else {
            this.paymentRequest = paymentRequest;
        }
    }

    switchPaymentMode(): void {
        this.store$.dispatch(new StartLoading());
        this.workflowService.switchPaymentMode(this.folder.id).subscribe(() => {
            this.store$.dispatch(new StopLoading());
            this.refreshWorkflowStatus.emit();
        }, () => {
            this.store$.dispatch(new StopLoading());

        });
    }
}
