import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
  selector: 'app-manuel-folder-process',
  templateUrl: './manuel-folder-process.component.html',
  styleUrls: ['./manuel-folder-process.component.scss']
})
export class ManuelFolderProcessComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    manuelFolderProcess: {
        placeholders: any;
        form: FormGroup;
    };

    constructor() {}

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.manuelFolderProcess = {
            form: new FormGroup({
                choice: new FormControl(null, Validators.required),
            }),
            placeholders: [
                {
                    choice: 'CLOSE_FOLDER',
                    value: 'BUTTON.CLOSE_FOLDER',
                },

                {
                    choice: 'REEVALUATE_CUSTOMER_SOLUTION',
                    value: 'BUTTON.REEVALUATE_CUSTOMER_SOLUTION',
                }
            ]
        };
    }


    onSubmit(): void {
        this.inputMap.emit({
            closeFolder: this.manuelFolderProcess.form.value.choice === 'CLOSE_FOLDER'
        });
    }

}
