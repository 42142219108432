import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ShippingEventType} from '../../../../models/enums/shipmentEventType.enum';
import {FolderPublic} from '../../../../models/folder.public.model';

@Component({
    selector: 'app-repairer-parcel-to-send', templateUrl: './parcel-to-send.component.html', styleUrls: ['./parcel-to-send.component.scss']
})
export class ParcelToSendComponent implements OnInit {
    shipmentInProgress = false;
    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: FolderPublic;

    constructor() {
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        if (!this.shipmentInProgress) {
            return;
        }
        this.inputMap.emit({
            'forcedShippingStatus': ShippingEventType.SHIPMENT_IN_PROGRESS.toString(),
        });
    }

}
