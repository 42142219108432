import {NgModule} from '@angular/core';

import {FuseIfOnDomDirective} from '@fuse/directives/fuse-if-on-dom/fuse-if-on-dom.directive';
import {FuseInnerScrollDirective} from '@fuse/directives/fuse-inner-scroll/fuse-inner-scroll.directive';
import {FusePerfectScrollbarDirective} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {FuseMatSidenavHelperDirective, FuseMatSidenavTogglerDirective} from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.directive';
import {NumberOnlyDirective} from './number-only.directive';
import {EmailValidatorDirective} from './EmailValidatorDirective';
import {ClickOutsideDirective} from './ClickOutsideDirective';
import {TextCopyDirective} from './copy-text.directive';
import {NumberOnlyNotIncludeZerosDirective} from './number-only-not-include-zeros.directive';
import {AlphanumericDirective} from './AlphanumericDirective';
import {PercentageDirective} from './PercentageDirective.directive';
import {HideSensibleInfoDirective} from './hide-sensible-info.directive';
import {DebounceClickDirective} from './DebounceClickDirective ';
import {InputControlDirective} from './input-control.directive';
import {NumberValidatorDirective} from './number-validator.directive';

@NgModule({
    declarations: [
        FuseIfOnDomDirective,
        FuseInnerScrollDirective,
        FuseMatSidenavHelperDirective,
        FuseMatSidenavTogglerDirective,
        FusePerfectScrollbarDirective,
        NumberOnlyDirective,
        HideSensibleInfoDirective,
        NumberOnlyNotIncludeZerosDirective,
        EmailValidatorDirective,
        DebounceClickDirective,
        ClickOutsideDirective,
        TextCopyDirective,
        AlphanumericDirective,
        PercentageDirective,
        InputControlDirective,
        NumberValidatorDirective
    ],
    imports     : [],
    exports     : [
        FuseIfOnDomDirective,
        FuseInnerScrollDirective,
        FuseMatSidenavHelperDirective,
        FuseMatSidenavTogglerDirective,
        FusePerfectScrollbarDirective,
        NumberOnlyDirective,
        HideSensibleInfoDirective,
        NumberOnlyNotIncludeZerosDirective,
        EmailValidatorDirective,
        DebounceClickDirective,
        ClickOutsideDirective,
        TextCopyDirective,
        AlphanumericDirective,
        PercentageDirective,
        InputControlDirective,
        NumberValidatorDirective
    ]
})
export class FuseDirectivesModule
{
}
