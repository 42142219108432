<mat-card-content>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxLayoutAlign="start">
                <app-user-task-header [instructionUserTask]="instructionUserTask"
                                      [claim]="folder"></app-user-task-header>
            </div>
        </div>

        <form [formGroup]="SymptomDescForm.form" *ngIf="!!SymptomDescForm.form" fxLayout="column"
              fxLayoutAlign="start" (ngSubmit)="onSubmit()" class="mt-25">

            <mat-form-field appearance="outline" fxFlex="40">
                <mat-label>{{'FOLDER.SERIAL_NUMBER1'|translate}}</mat-label>
                <input matInput inputControl placeholder="{{'FOLDER.SERIAL_NUMBER1'|translate}}"
                       id="panne_confirmed_serialNumber" formControlName="serialNumber1"
                       type="text"
                       [pattern]="serialNumberInfo?.serialNumber1RegEx"
                       [required]="serialNumberInfo?.requiredSerialNumber"
                       >
            </mat-form-field>
            <ng-container *ngIf="!!serialNumberInfo && serialNumberInfo.requiredSecondSerialNumber">
                <mat-form-field appearance="outline" fxFlex="40">
                    <mat-label>{{'FOLDER.SERIAL_NUMBER2'|translate}}</mat-label>
                    <input matInput inputControl placeholder="{{'FOLDER.SERIAL_NUMBER2'|translate}}"
                       formControlName="serialNumber2" [pattern]="serialNumberInfo?.serialNumber2RegEx">
                </mat-form-field>

            </ng-container>
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                    <mat-label>{{'FOLDER.COMMENT_FORM.LABEL' | translate}}</mat-label>
                    <textarea matInput inputControl rows="10" cols="7"
                              id="panne_confirmed_description"
                              [placeholder]="'FOLDER.COMMENT_FORM.PLACEHOLDER' | translate"
                              formControlName="description"></textarea>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between end">
                <div class="py-8 px-24 aster_link_cancel" matTooltip="{{'BUTTON.CHOOSE_OTHER_SYMPTOM' | translate}}"
                     id="panne_confirmed_previewButton" (click)="getPreviewTask()">
                     {{'BUTTON.CHOOSE_OTHER_SYMPTOM' | translate}}
                </div>
                <div >
                    <button type="submit" mat-stroked-button aria-label="Submit" color="accent"
                            [disabled]="disabledValidateButton()"
                            [ngClass]="disabledValidateButton() ? 'aster_btn_disabled' : 'aster_btn'"
                            id="panne_confirmed_submitButton">
                        {{'BUTTON.VALIDATE' | translate}}
                    </button>
                </div>
            </div>
        </form>
    </mat-card-content>
