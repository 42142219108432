    <mat-card-content>
        <form [formGroup]="reparationInProgress.form" fxLayout="column" fxLayoutAlign="start"
              (ngSubmit)="onSubmit()">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <div *ngIf="!!reparationInProgress.placeholders">
                <mat-radio-group formControlName="productRepaired" class="ml-20"

                                 labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch"
                                 fxLayoutGap="10px">
                    <mat-radio-button class="full-width-radio"
                                      *ngFor="let solution of reparationInProgress.placeholders"
                                      [value]="solution.status"
                                      (change)="handleChange($event)">
                        <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div fxFlex>
                                <div>{{ 'COMPONENT.REPAIR_IN_PROGRESS_BY_CUSTOMER.' + solution.status | translate }}</div>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <!-- Additional services  -->
            <ng-container *ngIf="!!reparationInProgress.form.controls.service">
                <mat-card-content>
                    <mat-card-title
                        class="h3 mt-12 mb-24">{{'COMPONENT.REPAIR_IN_PROGRESS_BY_CUSTOMER.ADDITIONAL_SERVICES'|translate}}</mat-card-title>
                    <mat-radio-group formControlName="service" class="ml-20"
                                     labelPosition="after" fxLayout="row"
                                     fxLayoutGap="10px">
                        <mat-radio-button class="full-width-radio"
                                          *ngFor="let service of additionalServices"
                                          [value]="service"
                                          (change)="serviceChanged($event)">
                            <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div fxFlex>
                                    <div>{{'COMPONENT.REPAIR_IN_PROGRESS_BY_CUSTOMER.SERVICE.' + service | translate}}</div>
                                </div>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>
                </mat-card-content>
            </ng-container>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        class="py-8 px-24 font-weight-900 font-size-30"
                        [ngClass]="reparationInProgress.form.invalid?'aster_btn_disabled':'aster_btn'"
                        [disabled]="reparationInProgress.form.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>