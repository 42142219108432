import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {TranslateService} from '@ngx-translate/core';
import {COLOR_SCHEME_DOMAIN} from '../../widget.data';
import {Router} from '@angular/router';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {select, Store} from '@ngrx/store';
import {configurationItem} from '../../../../../store/configuration/configuration.selectors';
import {AppState} from '../../../../../store/app.state';
import {Unsubscriber} from '../../../../../unsubscriber';

@Component({
    selector: 'widget-bar-chart-horizontal',
    templateUrl: './bar-chart-horizontal.component.html',
    styleUrls: ['./bar-chart-horizontal.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class BarChartHorizontalComponent extends Unsubscriber implements OnInit {


    @Input() widgetData;
    @Output() elementSelected = new EventEmitter();
    @Input() columnCount: number = null;
    @Input() id: string = null;
    @Output() updatedColumnCount = new EventEmitter<number>();
    @Output() dropWidget = new EventEmitter<number>();
    chartBarConfig = {
        data: [],
        view: [400, 300],
        showXAxis: true,
        showYAxis: true,
        gradient: false,
        showLegend: false,
        showXAxisLabel: true,
        xAxisLabel: '',
        yAxisLabel: '',
        showYAxisLabel: true,
        colorScheme: {
            domain: COLOR_SCHEME_DOMAIN
        }
    };


    constructor(private translateService: TranslateService,
                private router: Router,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.widgetData.data.forEach(widgetData => {
                this.anotherSubscription = this.store$.pipe(select(configurationItem, {configurationItemCode: ConfigCodeMapsEnum.DASHBOARD_LABEL, code: widgetData.name}))
                    .subscribe(name => {
                        widgetData['code'] = widgetData.name;
                        widgetData.name = name;
                    });
            }
        );
        this.chartBarConfig.data = this.widgetData.data.reverse();

        this.translateService.get('DASHBOARD.TITLE.WIDGET_BAR_CHAT.COUNT_FOLDER').subscribe(dataTranslate => {
            this.chartBarConfig.xAxisLabel = dataTranslate;
        });
    }

    onResize(event): void {
        if (event.target.innerWidth > 600) {

            this.chartBarConfig.view = [event.target.innerWidth / 3, 400];
        } else {
            this.chartBarConfig.view = [];
        }
    }

    showDetails(event): void {
        this.elementSelected.emit({code: this.widgetData.data.filter(value => value.name == event.name)[0].name});
    }

    update(): void {
        if (!!this.columnCount) {
            this.updatedColumnCount.emit(this.columnCount);
        }
    }

    deleteWidget(): void {
        this.dropWidget.emit();
    }

    editWidget(): void {
        this.router.navigate(['dashboard/widget/update/' + this.id]);
    }
}



