    <mat-card-content>
        <form [formGroup]="customerDecisionForm.form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <mat-radio-group formControlName="status" class="ml-20" fxLayout="row" fxLayoutAlign="space-between center"
                             labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button class="full-width-radio" *ngFor="let status of customerDecisionForm.placeholders"
                                  [value]="status.status" id="{{status.status}}">
                    <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div fxFlex>
                            <div>{{ status.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>

            <div fxLayoutAlign="end center">
                <button type="submit" mat-stroked-button
                        id="submit"
                        class="py-8 px-24 font-weight-900 font-size-30"
                        [ngClass]="customerDecisionForm.form.invalid?'aster_btn_disabled':'aster_btn'"
                        [disabled]="customerDecisionForm.form.invalid">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>

</mat-card-content>
