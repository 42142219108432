import {Injectable} from '@angular/core';
import {GLOBAL} from '../../../app-config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {IDashBoardWeek} from '../../../models/repair/dashBoardWeek.model';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {IDashBoardDayResponse} from '../../../models/repair/dashBoardDayResponse.model';
import {Technician} from '../../../models/itinerant/technician.model';

@Injectable({
    providedIn: 'root'
})
export class AppointmentService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/repair/appointment/api`;
     dayDetails: any []  = [];
     private midday = moment('13:00', 'HH:mm');


    constructor(public  httpClient: HttpClient) {
    }


    getDashBoardWeek(inputs: Map<string, string>): Observable<IDashBoardWeek []>{
        const requestParam = this.buildRequestParamFromInputs(inputs);
        return this.httpClient.get<IDashBoardWeek[]>(`${this.path}/dashBoardWeek?${requestParam}`);
    }

    getDashBoardDay(inputs: Map<string, string>): Promise<IDashBoardDayResponse []>{
        const requestParam = this.buildRequestParamFromInputs(inputs);
        return this.httpClient.get<IDashBoardDayResponse[]>(`${this.path}/dashBoardDay?${requestParam}`).toPromise();
    }

    getTechnicianByAppointmentId(appointmentId: string): Observable<Technician> {
        return this.httpClient.get<Technician>(`${this.path}/technician/${appointmentId}`);
    }

    sendToMobile(inputs: Map<string, string>): Observable<any> {
        return this.httpClient.post<any>(`${this.path}/send-to-mobile?${this.buildRequestParamFromInputs(inputs)}`, null);
    }

    private buildRequestParamFromInputs(inputs: Map<string, string>): any {
        let requestParam = '';
        inputs.forEach((value: string, key: string) => {
            if (!value || value === '*') {
                requestParam += `${key}=any&`;
            } else {
                requestParam += `${key}=${value}&`;
            }
        });
        requestParam = requestParam.substring(0, requestParam.length - 1);
        return requestParam;
    }

    reOptimizeTrip(queryParams: HttpParams): Observable<any> {
        return this.httpClient.post<void>(this.path + '/interventions/reoptimize', {}, {params: queryParams});
    }


}
