import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../models/folder.model';
import {Company, MobilePhoneInput} from '../../../../../models/customer/customer.model';
import {SharedService} from '../../../../../shared/services/shared.service';
import {CustomerType} from '../../../../../models/enums/CustomerType.enum';
import {MatRadioChange} from '@angular/material/radio';
import {Context} from '../../../../../models/enums/context.enum';
import {takeUntil} from 'rxjs/operators';
import {CIVILITY_OPTIONS} from '../../../../../models/customer/customer.constants';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-add-customer-details.component',
    templateUrl: './add-customer-details.component.html',
    styleUrls: ['./add-customer-details.component.scss']
})
export class AddCustomerDetailsComponent implements OnInit, OnDestroy {

    @Output() inputMap = new EventEmitter<any>();
    @Output() previewTask = new EventEmitter<any>();
    @Input() instructionUserTask: InstructionUserTask;

    @Input() folder: Folder;

    mobilePhoneOutput: MobilePhoneInput;
    company: Company;
    protected _onDestroy = new Subject<void>();

    customerDetailForm: FormGroup;
    mobilePhoneSubscription: Subscription;
    addDetailsInProgress = false;
    isProfessionalFolder = false;

    folderTypeList = [CustomerType.PRIVATE_INDIVIDUAL, CustomerType.PROFESSIONAL];
    civilitySelectBoxOptions = CIVILITY_OPTIONS;

    constructor(private sharedService: SharedService) {
    }

    ngOnInit(): void {
        this.initCustomerDetailForm();
    }

    initCustomerDetailForm(): void {
        this.customerDetailForm = new FormGroup({
            type: new FormControl(CustomerType.PRIVATE_INDIVIDUAL),
            mobileNumber: new FormControl(null),
            email: new FormControl(null),
            gender: new FormControl(null),
            firstName: new FormControl(null, Validators.required),
            lastName: new FormControl(null, Validators.required)
        });
        this.customerDetailForm.get(['email']).setValidators(this.folder.context === Context.PRODUCT_RECALL ? Validators.required : null);

        this.mobilePhoneOutput = new MobilePhoneInput();

        this.mobilePhoneOutput = {
            countryCode: null,
            mobileNumber: null
        };
        this.company = {
            legalForm: null,
            label: null
        };
        this.sharedService.updateMobilePhone(this.mobilePhoneOutput);
        this.sharedService.updateCompany(this.company);

        this.sharedService.getUpdateMobilePhoneEvent()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(res => {
                if (!res) {
                    this.customerDetailForm.get(['mobileNumber']).setErrors({'incorrect': true});
                    return;
                }
                this.customerDetailForm.get(['mobileNumber']).setValue(res.mobileNumber);
                this.mobilePhoneOutput = res;
            });
    }
    completeTask(): void {
        this.addDetailsInProgress = true;
        if (this.customerDetailForm.get('type').value === CustomerType.PROFESSIONAL){
            const professionalCustomer = {
                type: this.customerDetailForm.value.type,
                gender: this.customerDetailForm.value.gender,
                lastName: this.customerDetailForm.value.lastName,
                firstName: this.customerDetailForm.value.firstName,
                mobileNumber: this.mobilePhoneOutput.mobileNumber,
                email: this.customerDetailForm.value.email,
                company: this.customerDetailForm.value.company
            };
            this.inputMap.emit({'customerDetails': JSON.stringify(professionalCustomer)});
        }
        else{
            const particularCustomer = {
                type: this.customerDetailForm.value.type,
                gender: this.customerDetailForm.value.gender,
                lastName: this.customerDetailForm.value.lastName,
                firstName: this.customerDetailForm.value.firstName,
                mobileNumber: this.mobilePhoneOutput.mobileNumber,
                email: this.customerDetailForm.value.email

            };
            this.inputMap.emit({'customerDetails': JSON.stringify(particularCustomer)});
        }
    }

    ngOnDestroy(): void {
        if (!!this.mobilePhoneSubscription) {
            this.mobilePhoneSubscription.unsubscribe();
        }
    }

    stopPropagation(): boolean{
        return this.addDetailsInProgress || this.customerDetailForm.invalid;
    }

    getPreviewTask(): void {
        this.previewTask.emit({
            'currentTask': 'ADD_CUSTOMER_DETAILS',
            'previewsTask': 'PRODUCT_CHECK_AT_CUSTOMER'
        });
    }

    customerTypeChange($event: MatRadioChange): void {
        if ($event.value === CustomerType.PROFESSIONAL) {
            this.customerDetailForm.get('type').setValue(CustomerType.PROFESSIONAL);
            this.customerDetailForm.addControl('company',
                new FormGroup({
                    label: new FormControl('', Validators.required),
                    legalForm: new FormControl('', Validators.required)
                }));
            this.isProfessionalFolder = true;
        }
        else {
            this.isProfessionalFolder = false;
            this.customerDetailForm.removeControl('company');
        }

    }
}
