import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuotationLine} from '../../../../../../models/quotation.model';
import {Folder, IFolderUpdateRequest} from '../../../../../../models/folder.model';
import {QuotationType} from '../../../../../../models/enums/quotationType.enum';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {OmsService} from '../../../../../../shared/services/oms.service';
import {Order, OrderItem} from '../../../../../../models/order.model';
import {TaskVariables} from '../../../task.variables';
import {FolderSubjectService} from 'app/main/folder/folder-subject.service';
import {OrderSubjectService} from '../../../../../../shared/services/order-subject.service';
import {OrderStatus} from '../../../../../../models/enums/orderStatus.enum';
import {MatDialog} from '@angular/material/dialog';
import {
    ConfirmationWithCommentComponent
} from '../../../../../../shared/generic/switch-to-swap/confirmation-with-comment.component';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment/moment';
import {SnackBarService} from '../../../../../../shared/services/snack-bar.service';
import {AppState} from '../../../../../../store/app.state';
import {Store} from '@ngrx/store';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {
    ConfirmDialogWithInputComponent
} from '../../../../../../../@fuse/components/confirm-dialog-with-input/confirm-dialog-with-input.component';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-spare-part-reception2',
    templateUrl: './waiting-spare-part-reception2.component.html',
    styleUrls: ['./waiting-spare-part-reception2.component.scss']
})
export class WaitingSparePartReception2Component extends Unsubscriber implements OnInit {

    orderedSpareParts: QuotationLine[] = [];
    order = new Order();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();

    sparePartReceived: boolean;
    quotationId: string;
    isSG: boolean;
    orderIsExpired: boolean;
    maxDurationReceptionSparePart: string;
    statusDate: string;
    hasException: boolean;

    constructor(private omsService: OmsService,
                private folderSubjectService: FolderSubjectService,
                private folderService: FolderService,
                private matDialog: MatDialog,
                private translateService: TranslateService,
                private orderSubjectService: OrderSubjectService,
                private snackBar: SnackBarService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        // TODO this code will be removed, quotation id only to old folders
        this.quotationId = this.variablesTask[TaskVariables.quotationId];
        this.isSG = this.folder.newWarranty.warranty === 'SG';
        this.getTaskDuration();
        this.getStatusDate();
        if (!!this.folder.orderIds && this.folder.orderIds?.length > 0) {
            this.buildDataFromOrder();
        } else {
            this.buildDataFromQuotation();
        }
    }

    private buildDataFromQuotation() {
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
            this.orderedSpareParts = this.folder.quotations
                .filter(quotation => quotation.id === this.quotationId)[0]
                .quotationLines
                .filter(e => e.type === QuotationType.SPARE_PART);
            this.orderIsExpired = false;
        });
    }

    private buildDataFromOrder() {
        this.anotherSubscription = this.orderSubjectService.order$.subscribe(order => {
            this.order = order;
            this.hasException = this.isOrderExceptionPresent();
            this.sparePartReceived = this.isSparePartReceived();
            this.buildDataLines();
        });
    }

    private isSparePartReceived() {
        return this.order.orderStatus === OrderStatus.DELIVERED || this.order.orderStatus === OrderStatus.PICKING;
    }

    isOrderExceptionPresent() {
        return this.order.orderStatus === OrderStatus.EXCEPTION;
    }

    buildDataLines() {
        this.orderedSpareParts = [];
        this.order.orderItems.forEach(orderItem => {
            const dataSourceLine = new QuotationLine();
            dataSourceLine.orderItemId = orderItem.id;
            dataSourceLine.code = orderItem.code;
            dataSourceLine.label = orderItem.label;
            dataSourceLine.quantity = orderItem.quantityOrdered;
            dataSourceLine.priceAmount = {
                value: orderItem.price,
                currency: orderItem.currency
            };
            dataSourceLine.stock = orderItem.stock;
            dataSourceLine.type = QuotationType.SPARE_PART;
            dataSourceLine.supplierName = orderItem.supplier?.name;
            dataSourceLine.status = orderItem.itemStatus;
            dataSourceLine.totalLinePrice = {
                value: orderItem.rowTotal,
                currency: orderItem.currency
            };
            dataSourceLine.vat = {
                rate: orderItem.taxPercent.toString()
            };
            dataSourceLine.discount = orderItem.discountPercent;
            dataSourceLine.discountReason = orderItem.discountReason;
            this.orderedSpareParts.push(dataSourceLine);
        });
        this.orderIsExpired = this.orderExpired();
    }

    onSubmit(): void {
        this.inputMap.emit({
            userChoice: 'underRepair',
            sparePartReceived: this.sparePartReceived
        });
    }

    updateOrderStatus() {
        if (!!this.order?.id) {
            const orderUpdateRequest = this.prepareOrder(OrderStatus.DELIVERED);
            this.omsService.updateOrder(this.order.id, orderUpdateRequest).subscribe((order) => {
                this.order = order;
                this.orderSubjectService.orderLoaded(order);
            });
        }
    }

    private prepareOrder(targetStatus: OrderStatus) {
        return {
            orderItemsStatuses: this.toItems(targetStatus)
        };
    }

    private toItems(targetStatus: OrderStatus) {
        const items: OrderItem[] = [];
        this.orderedSpareParts.forEach((sparePart) => {
            const item: OrderItem = {
                id: sparePart.orderItemId,
                itemStatus: this.computeStatus(sparePart, targetStatus)
            };
            if (item.itemStatus === OrderStatus.DELIVERED) {
                item.quantityDelivered = sparePart.quantity;
            }
            items.push(item);
        });
        return items;
    }

    private computeStatus(sparePart: QuotationLine, targetStatus: OrderStatus): OrderStatus {
        return this.forceToOrdered(targetStatus, sparePart) ? OrderStatus.ORDERED : this.markAsDelivered(sparePart);

    }

    private forceToOrdered(targetStatus: OrderStatus, sparePart: QuotationLine) {
        return (targetStatus === OrderStatus.ORDERED && sparePart.status === OrderStatus.EXCEPTION) || !this.sparePartReceived;
    }

    private markAsDelivered(sparePart: QuotationLine) {
        return (this.sparePartReceived && sparePart.status !== OrderStatus.PICKING)
            ? OrderStatus.DELIVERED
            : sparePart.status;
    }

    confirmUpdateStatus(): void {
        const dialog = this.matDialog.open(ConfirmDialogWithInputComponent, {
            hasBackdrop: true,
            disableClose: false,
            data: {inputValueRequired: true}
        });
        dialog.componentInstance.title = this.translateService.instant('COMPONENT.WAITING_SPARE_CONFIRMATION.MODAL.UPDATE.STATUS_DIALOG.TITLE');
        dialog.componentInstance.message = this.translateService.instant('COMPONENT.WAITING_SPARE_CONFIRMATION.MODAL.UPDATE.STATUS_DIALOG.MESSAGE');
        dialog.componentInstance.inputTitle = this.translateService.instant('COMPONENT.WAITING_SPARE_CONFIRMATION.MODAL.UPDATE.STATUS_DIALOG.INPUT.TITLE');
        dialog.componentInstance.inputPlaceholder = this.translateService.instant('COMPONENT.WAITING_SPARE_CONFIRMATION.MODAL.UPDATE.STATUS_DIALOG.INPUT.PLACEHOLDER');
        dialog.componentInstance.confirmButtonLabel = this.translateService.instant('COMPONENT.WAITING_SPARE_CONFIRMATION.MODAL.BUTTON.UPDATE');
        dialog.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialog.afterClosed().subscribe(data => {
            console.log('close data ', data);
            if (!!data) {
                this.addExternalOrderReference(data.inputValue);
            }
        });

    }

    prepareUpdateFolderRequest(reference: string): IFolderUpdateRequest {
        const references = new Map<string, string>();
        references['SPARE_PARTS_ORDER_REFERENCE'] = reference;
        return {
            externalReferences: references
        };

    }

    addExternalOrderReference(reference: string) {
        this.folderService.updateFolder(this.folder.id, this.prepareUpdateFolderRequest(reference))
            .then(data => {
                this.folderSubjectService.folderLoaded(data);
                this.manuallyUpdateOrderStatus();
            }).catch(() => {
            this.store$.dispatch(new StopLoading());
        });
    }

    manuallyUpdateOrderStatus() {
        if (!!this.order?.id) {
            const orderUpdateRequest = this.prepareOrder(OrderStatus.ORDERED);
            this.omsService.updateOrder(this.order.id, orderUpdateRequest).subscribe((order) => {
                this.order = order;
                this.orderSubjectService.orderLoaded(this.order);
                this.buildDataLines();
                this.snackBar.openWithIcon('ORDER.SUCCESS_UPDATE', 'Success');
                this.store$.dispatch(new StopLoading());
            }, () => {
                this.snackBar.openWithIcon('ORDER.ERROR_UPDATE', 'Error');
                this.store$.dispatch(new StopLoading());
            });

        }
    }

    openConfirmationModal(): void {
        const dialogRef = this.matDialog.open(ConfirmationWithCommentComponent, {
            height: 'auto',
            width: '100vh',
            minWidth: '800px'
        });
        dialogRef.componentInstance.id = 'CASE_SG_RI';
        dialogRef.componentInstance.reasonTitle = 'SWITCH.OPERATING.MODE.REASON.CHANGE';
        dialogRef.componentInstance.title = this.translateService.instant('FOLDER.SWITCH_SWAP.TITLE');
        dialogRef.componentInstance.description = this.translateService.instant('FOLDER.SWITCH_SWAP.DESCRIPTION');
        dialogRef.componentInstance.commentMessage = this.translateService.instant('FOLDER.COMMENT_FORM.SWITCH_SWAP.LABEL');
        dialogRef.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.VALIDATE');
        dialogRef.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialogRef.componentInstance.withCommentReason = true;
        dialogRef.afterClosed().subscribe(data => {
                if (!!data) {
                    if (!!data.reason || !!data.comment) {
                        this.commentGiven.emit({
                            value: this.handleCommentContent(data.comment, data.reason),
                            type: 'SWITCH_SWAP_REASON_WAITING_SPARE_PARTS_RECEPTION',
                        });
                    }
                    this.inputMap.emit({
                        'userChoice': 'switchSwap'
                    });
                }
            }
        );
    }

    handleCommentContent(comment: String, reason: String): String {
        const reasonContent = this.translateService.instant('SPARE_PART.SWAP_OR_CLOSE.' + reason);
        return !!comment ?  reasonContent + ' ' + comment : reasonContent ;
    }

    cancelOrder() {
        const dialogRef = this.matDialog.open(ConfirmationWithCommentComponent, {
            height: 'auto', width: '300px', minWidth: '50%'
        });
        dialogRef.componentInstance.id = 'CASE_HG_RI';
        dialogRef.componentInstance.reasonTitle = 'FOLDER.CLOSE_FORM.STATUS';
        dialogRef.componentInstance.title = this.translateService.instant('FOLDER.CANCEL_ORDER_FORM.TITLE');
        dialogRef.componentInstance.commentMessage = this.translateService.instant('FOLDER.COMMENT_FORM.CANCEL_ORDER_FORM.LABEL');
        dialogRef.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.VALIDATE');
        dialogRef.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialogRef.componentInstance.withCommentReason = true;
        dialogRef.afterClosed().subscribe(data => {
                if (!!data) {
                    if (!!data.reason || !!data.comment) {
                        this.commentGiven.emit({
                            value: this.handleCommentContent(data.comment, data.reason),
                            type: 'CANCEL_ORDER_REASON_WAITING_SPARE_PARTS_RECEPTION',
                        });
                    }

                    this.inputMap.emit({
                        'userChoice': 'cancelOrder'
                    });
                }
            }
        );

    }

    orderExpired = () => !!this.order && !!this.order.expirationDate ? moment(this.order.expirationDate).isAfter(moment()) : false;


    getTaskDuration() {
        this.maxDurationReceptionSparePart = this.variablesTask['maxDurationReceptionSparePart'];
    }

    getStatusDate() {
        this.statusDate = this.folder.currentWorkflowStatus.workflowStatusDate;
    }
}
