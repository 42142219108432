import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AddressRepairer, RepairCenter} from '../../../../../models/folder.model';
import {Address} from '../../../../../models/customer/customer.model';
import {AddressType} from '../../../../../models/enums/addressType.enum';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfigWithExtra} from '../../../../../models/configuration.value';
import {ConfigV2Service} from '../../../../../shared/services/config-v2.service';
import {ConfigExtraCodesEnum} from '../../../../../models/codeLabelObjectWithExtra.model';
import {SharedService} from '../../../../../shared/services/shared.service';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-detail-address-repairer-dialog',
  templateUrl: './detail-address-repairer-dialog.component.html',
})
export class DetailAddressRepairerDialogComponent implements OnInit {
    protected _onDestroy = new Subject<void>();
    repairer: RepairCenter;
    addressForm: any = {
        form: null,
    };
    addressOutput: Address;
    repairAddress: Address;
    validateFormAddress: boolean;

  constructor(private dialogRef: MatDialogRef<DetailAddressRepairerDialogComponent>,
              private configV2Service: ConfigV2Service,
              private sharedService: SharedService,
              private cdr: ChangeDetectorRef,
              private snackBar: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.addressForm.form = new FormGroup({
        countryCode: new FormControl(null, Validators.required),
    });
      this.repairer = this.data.repairer;
      if (this.repairer.completeAddress){
        this.initRepairerDetailForm();
    }
  }
  initRepairerDetailForm(): void {
        this.addressForm.form = new FormGroup({
            countryCode: new FormControl(null, Validators.required),
        });
        this.sharedService.getUpdateAddressEvent().subscribe(res => {
            if (!res) {
                this.addressForm.form.get(['countryCode']).setErrors({'incorrect': true});
                return;
            }
            this.addressForm.form.get(['countryCode']).setValue(res.countryCode);
            this.addressOutput = res;
        });
      this.sharedService.getValidateForm().subscribe(validate => {
          this.validateFormAddress = validate;
          this.cdr.detectChanges();
      });
    }
    onClose(): void {
        this.dialogRef.close();
    }
    isInvalidForm(): boolean {
        return this.addressForm.form?.invalid || !this.validateFormAddress;
    }
    validateAddress(): void {
        if (this.addressForm.form.invalid || !this.validateFormAddress) {
            return;
        }
        this.repairAddress = {
            countryName: this.addressOutput.countryName,
            countryCode: this.addressOutput.countryCode,
            city: this.addressOutput.city,
            zipCode: this.addressOutput.zipCode,
            address: this.addressOutput.address,
            location: this.addressOutput.location,
            secondAddress: this.addressOutput.secondAddress,
            type: AddressType.DEFAULT
        };
        const addressRepairerConfig: ConfigWithExtra<AddressRepairer> = {
            id: this.data.repairer.id,
            code: this.repairer.code,
            label: this.repairer.label,
            extra: {
                contact: {
                    firstName: this.repairer.contact.firstName,
                    lastName: this.repairer.contact.lastName,
                    phoneNumber: this.repairer.contact.phoneNumber,
                    mobileNumber: this.repairer.contact.mobileNumber,
                    email: this.repairer.contact.email,
                    website: this.repairer.contact.website,
                },
                completeAddress:
                    {
                        countryCode: this.repairAddress.countryCode,
                        countryName: this.repairAddress.countryName,
                        city: this.repairAddress.city,
                        zipCode: this.repairAddress.zipCode,
                        address: this.repairAddress.address,
                        fullAddress: this.repairAddress.fullAddress,
                        location: {
                            lat: this.repairAddress.location.lat,
                            lon: this.repairAddress.location.lon,
                            distance: this.repairAddress.location.distance
                        }
                    },
            }
        };
        const location = addressRepairerConfig.extra?.completeAddress?.location;
        if (location && location.lon && location.lat){
            this.configV2Service.update(ConfigExtraCodesEnum.REPAIRER, addressRepairerConfig).subscribe(value => {
                this.showAddressSuccessUpdate();
                this.dialogRef.close(value.extra?.completeAddress);
                }
            );
        }
        else {
            this.showAddressErrorMessage();
        }

    }
    private showAddressErrorMessage() {
        this.snackBar.openAtEnd('Error', 'ERRORS.BUSINESS.DETAILED.REPAIR_CENTER_ADDRESS');
    }
    showAddressSuccessUpdate() {
        this.snackBar.openAtEnd('Success', 'REPAIRER.ADDRESS.TOAST_MESSAGES.SUCCESS_UPDATE');
    }
}
