import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {FolderService} from '../../../shared/services/folder.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Filter, FilterOperator, FolderSearchResponse} from '../../../models/folder-search-response.model';
import {TranslateService} from '@ngx-translate/core';
import {FolderIndex} from '../../../models/folder.index.model';
import {Observable, ReplaySubject} from 'rxjs';
import {OPERATING_MODES_CONFIGS, ORIGIN_TYPE, WARRANTY_CONFIGS} from '../../../shared/data/static.config';
import {ConfigDisplayColumnsTableService} from '../../../shared/services/config-display-columns-table.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {IntroJsService} from '../../../shared/services/intro-js.service';
import {MatSelect} from '@angular/material/select';
import {FOLDER_STATUS_MAP, STATUS_FOLDER_REPARATION, STATUS_FOLDER_SAV, TREE_DATA} from './config/folderList.filter.data';
import {ExportFoldersRequest, FieldsHeaders, SearchFoldersRequestModel} from '../../../models/SearchFoldersRequest.model';
import {TagEnums} from '../../../models/enums/tag.enums';
import {FilterFolderListService} from './config/filter-folder-list.service';
import {skipWhile, startWith} from 'rxjs/operators';
import * as moment from 'moment';

import * as fileSaver from 'file-saver';
import {Context} from '../../../models/enums/context.enum';
import {PushNotificationSubjectService} from '../../../models/pushNotification/pushNotification-subject.service';
import {PushNotification} from '../../../models/pushNotification/pushNotification.model';
import {ConfigurationReferential} from '../../../models/configurationReferential.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {currentUser, isUserAssignedToSite} from '../../../store/user/user.selectors';
import {cacheConfigObject, multipleConfiguration} from '../../../store/configuration/configuration.selectors';
import {Unsubscriber} from '../../../unsubscriber';
import {currentLanguage, operationMode} from '../../../store/organization/organization.selectors';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {SnackBarService} from '../../../shared/services/snack-bar.service';
import {WarrantyReasonSelectorComponent} from './warranty-reason-selector/warranty-reason-selector.component';
import {BackOfficeService} from '../../../shared/services/back-office.service';
import {InstructionUserTask} from '../../../models/instruction-user-task.model';
import {ConfigCodeMapsEnum} from '../../../shared/services/configuration-item-enum';

export const STANDARD_SAV_CSV_HEADER = ['reference', 'incrementalNumber', 'creationDate', 'customerFirstName', 'customerLastName', 'customerMobilePhone',
    'productCode', 'productLabel', 'family1Label', 'family2Label', 'family3Label', 'family4Label', 'productSegment', 'invoiceNumber', 'purchaseDate',
    'status', 'currentWorkFlowStatus', 'newWarranty', 'warrantyReason', 'operationMode',
    'customerCountryName', 'customerCountryCode', 'customerCity', 'customerZipCode', 'customerAddress', 'customerSecondAddress', 'customerEmail', 'appointmentDate',
    'managementSiteCode', 'managementSiteName', 'stockClaim'];

export const ADEO_SAV_CSV_HEADER = ['reference', 'incrementalNumber', 'creationDate', 'customerFirstName', 'customerLastName', 'customerMobilePhone',
    'productCode', 'productLabel', 'family1Label', 'family2Label', 'family3Label', 'family4Label', 'productSegment', 'invoiceNumber', 'purchaseDate',
    'status', 'currentWorkFlowStatus', 'newWarranty', 'warrantyReason', 'operationMode',
    'customerCountryName', 'customerCountryCode', 'customerCity', 'customerZipCode', 'customerAddress', 'customerSecondAddress', 'customerEmail', 'appointmentDate',
    'managementSiteCode', 'managementSiteName', 'brokenProductExitVoucherNumber', 'productExistVoucherNumber', 'markdownNumber'];

export const PRODUCT_RECALL_CSV_HEADER = ['id', 'reference', 'incrementalNumber', 'creationDate', 'customerFirstName', 'customerLastName', 'customerMobilePhone',
    'productCode', 'productLabel', 'quantity', 'family1Label', 'family2Label', 'family3Label', 'family4Label', 'productSegment', 'invoiceNumber', 'purchaseDate',
    'status', 'currentWorkFlowStatus', 'newWarranty', 'warrantyReason', 'operationMode',
    'customerCode', 'customerCountryName', 'customerCountryCode', 'customerCity', 'customerZipCode',
    'customerAddress', 'customerSecondAddress', 'customerEmail', 'appointmentDate', 'managementSiteCode', 'managementSiteName'];

@Component({
    selector: 'app-folder-list',
    templateUrl: './folder-list.component.html',
    styleUrls: ['./folder-list.component.scss']
})
export class FolderListComponent extends Unsubscriber implements OnInit {

    @ViewChild(WarrantyReasonSelectorComponent) warrantyReasonSelectorComponent: WarrantyReasonSelectorComponent;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSourceFolder = {
        columnsToDisplay: ['INCREMENTAL_REFERENCE', 'LAST_NAME', 'FIRST_NAME', 'CREATE_DATE', 'STATUS', 'PRODUCT_LABELS', 'INDICATOR'],
        allColumnsName: ['INCREMENTAL_REFERENCE', 'LAST_NAME', 'FIRST_NAME', 'CUSTOMER_MOBILE_PHONE', 'CREATE_DATE', 'STATUS',
            'DATE_WORKFLOW_STATUS', 'PRODUCT_LABELS', 'PRODUCT_CODE', 'INDICATOR']
    };

    customerContactNumber: string;
    folderList = new MatTableDataSource<FolderIndex>();
    filterForm: FormGroup;
    reverse = false;
    queryParams: any;
    pageConfig: FolderSearchResponse;
    pageSizeOptions: number[] = [10, 25, 50, 100];

    private organizationCode: string;
    listWarranty = WARRANTY_CONFIGS;
    originTypesList = ORIGIN_TYPE;
    filterFoldersForms: FormGroup;

    statusListFolder = [];
    warrantyReasonsToFilter = [];
    operatingModeToFilter: string;
    filteredStatusListFolder = new ReplaySubject<string[]>(1);
    filterTreeData = [];
    numberInputOfLine = 4;
    LIST_INPUT_RANG_DATE: string[] = ['workflowStatusDate', 'dateRange'];
    LIST_INPUT_STRING: string[] = ['externalReferences', 'externalRepairerSystemReference', 'creationSite', 'managementSite', 'repairCenter',
        'supplier', 'productCode', 'productLabel', 'ProductFamily', 'ProductSubFamily', 'ProductType', 'ProductSubType', 'ProductSegment',
        'invoiceNumber', 'productCode', 'productLabel', 'family1Code', 'family2Code', 'family3Code', 'family4Code', 'productSegment',
        'purchaseDate', 'invoiceNumber', 'serialNumber1'];

    optionsFilters = TREE_DATA;
    optionFilterFormGroup: FormGroup;
    fieldsFilterSearch: string[];
    event: Event;
    private currentContext: string;
    maxExportSize = 9999;
    instructionUserTasks: InstructionUserTask[] = [];
    warrantyReasonList: ConfigurationReferential[];

    constructor(private store$: Store<AppState>,
                private activatedRoute: ActivatedRoute,
                private folderService: FolderService,
                private formBuilder: FormBuilder,
                private router: Router,
                private translateService: TranslateService,
                private configDisplayColumnsTableService: ConfigDisplayColumnsTableService,
                private filterFolderListService: FilterFolderListService,
                private introJsService: IntroJsService,
                @Inject(LOCAL_STORAGE) private localStorage: StorageService,
                private pushNotificationSubjectService: PushNotificationSubjectService,
                private snackBar: SnackBarService,
                private backOfficeService: BackOfficeService) {
        super();
    }

    initFormQueryParams(): void {
        const queryParamsKeys = Object.keys(this.queryParams);
        if (queryParamsKeys.length === 0) {
            this.resetDataFormAndFilters();
        }
        queryParamsKeys.forEach(paramsKeys => {
            switch (paramsKeys) {
                case 'tags': {
                    this.filterFoldersForms.controls['tags'].setValue(TagEnums.FAVORITES);
                    break;
                }
                case 'newWarranty': {
                    this.filterFoldersForms.controls['newWarranty'].setValue(
                        WARRANTY_CONFIGS.filter(el => el.key === this.queryParams[paramsKeys])[0]);
                    break;
                }
                case 'warrantyReason': {
                    this.warrantyReasonsToFilter = this.getArrayValueFromQueryParam('warrantyReason');
                    break;
                }
                case 'currentWorkFlowStatus': {
                    this.filterFoldersForms.controls['currentWorkFlowStatus'].setValue(this.getArrayValueFromQueryParam('currentWorkFlowStatus'));
                    break;
                }
                case 'operationMode': {
                    this.operatingModeToFilter = this.queryParams[paramsKeys];
                    break;
                }
                case 'origin': {
                    this.filterFoldersForms.controls['origin'].setValue(
                        ORIGIN_TYPE.filter(el => el.key === this.queryParams[paramsKeys])[0]);
                    break;
                }

                case  'startEditRangeDate':
                case  'endEditRangeDate': {
                    this.filterFoldersForms.controls['workflowStatusDate'].setValue({
                        begin: new Date(Number(this.queryParams['startEditRangeDate'])),
                        end: new Date(Number(this.queryParams['endEditRangeDate']))
                    });
                    break;
                }
                case  'startRangeDate':
                case  'endRangeDate': {
                    this.filterFoldersForms.controls['dateRange'].setValue({
                        begin: new Date(Number(this.queryParams['startRangeDate'])),
                        end: new Date(Number(this.queryParams['endRangeDate']))
                    });
                    break;
                }
                case  'closed': {
                    if (JSON.parse(this.queryParams['closed']) !== null) {
                        this.filterFoldersForms.controls['closed'].setValue(JSON.parse(this.queryParams['closed']));
                    } else {
                        delete this.queryParams['closed'];
                    }
                    break;
                }
                case  'stockClaim': {
                    if (JSON.parse(this.queryParams['stockClaim']) !== null) {
                        this.filterFoldersForms.controls['stockClaim'].setValue(JSON.parse(this.queryParams['stockClaim']));
                    }
                    break;
                }

                case  'customerContactNumber': {
                    const phone = this.queryParams['customerContactNumber'];
                    if (phone) {
                        this.customerContactNumber = phone;
                    }
                    break;
                }
                default: {
                    if (paramsKeys in this.queryParams && paramsKeys !== 'businessLink' && paramsKeys !== 'page' && paramsKeys !== 'size' && paramsKeys !== 'searchBar') {
                        this.filterFoldersForms.controls[paramsKeys].setValue(this.queryParams[paramsKeys]);
                    }
                }
            }
        });
    }

    fieldFilterSelected(optionKey): void {
        if (optionKey === 'currentWorkFlowStatus') {
            this.getInstructionUserTask();
        }
        if (this.filterTreeData.indexOf(optionKey) > -1) {
            this.filterTreeData.splice(this.filterTreeData.indexOf(optionKey), 1);
            this.filterFoldersForms.controls[optionKey].setValue(null);
            this.optionFilterFormGroup.controls[optionKey].setValue(false);
        } else {
            this.filterTreeData.push(optionKey);
        }
        this.filterFolderListService.nodeTreeSelected.next(this.filterTreeData);
    }

    private getInstructionUserTask() {
        this.store$.dispatch(new StartLoading());
        this.anotherSubscription = this.backOfficeService.getInstructionUserTask().subscribe(response => {
            this.instructionUserTasks = response;
            this.getFolderStatusFilteredListByContext();
            this.store$.dispatch(new StopLoading());
        });
    }

    ngOnInit(): void {
        this.initOptionsFilter();
        this.folderList.paginator = this.paginator;
        this.filterForm = this.formBuilder.group({
            searchValue: '',
            page: 0,
            size: 10,
            sortBy: '',
            order: this.reverse ? 'DESC' : 'ASC'
        });
        this.initFilterForm();
        this.store$.pipe(select(currentUser),
            skipWhile(user => user.organizationCode === '' || user.organizationCode === 'ALL'))
            .subscribe(currentUser => {
                this.currentContext = currentUser.context;
                this.organizationCode = currentUser.organizationCode;
                this.getFolderDetails();
                this.getFolderStatusFilteredListByContext();
                this.initFormQueryParams();
            });
        this.showConfigDisplayedColumns();
        this.anotherSubscription = this.filterFolderListService.getNodeTreeSelected().subscribe(itemNodeTreeSelected => {
            if (!!itemNodeTreeSelected && itemNodeTreeSelected.length > 0) {
                this.filterTreeData = itemNodeTreeSelected;
                itemNodeTreeSelected.forEach(fieldName => {
                    if (!fieldName.startsWith('searchValue')) {
                        this.optionFilterFormGroup.controls[fieldName].setValue(true);
                    }
                });
            }
        });
        this.pushNotificationSubjectService.refreshPushNotification(new PushNotification());
        this.store$.pipe(select(isUserAssignedToSite)).subscribe(result => {
                if (!result) {
                    this.snackBar.openWithIcon(
                        'ERRORS.USER.WITHOUT.SITES', 'Error'
                    );
                }
            }
        );
        this.anotherSubscription = this.store$.pipe(select(currentLanguage)).subscribe(
            language => {
                this.getInstructionUserTask();
            }
        );
        this.anotherSubscription = this.store$.pipe(select(multipleConfiguration, {configurationItemCodes: [ConfigCodeMapsEnum.WARRANTY_REASON_HG, ConfigCodeMapsEnum.WARRANTY_REASON_SG]}))
            .subscribe(it => this.warrantyReasonList = it);

    }

    private getFolderStatusFilteredListByContext(): void {

        switch (this.currentContext) {
            case Context.SAV.toString(): {
                this.statusListFolder = this.instructionUserTasks.map(it => it.label ? it.label.toLowerCase() : '');
                this.filteredStatusListFolder.next(this.statusListFolder);
                break;
            }
            case Context.PRODUCT_RECALL.toString(): {
                this.getFolderStatusFilteredListByModOpCampaign();
                break;
            }
            case Context.REPARATION.toString(): {
                this.statusListFolder = STATUS_FOLDER_REPARATION;
                this.filteredStatusListFolder.next(this.statusListFolder);
                break;
            }
            default : {
                this.statusListFolder = STATUS_FOLDER_SAV;
                this.filteredStatusListFolder.next(this.statusListFolder);

            }
        }
    }

    private getFolderStatusFilteredListByModOpCampaign(isClosed?: string): void {
        this.anotherSubscription = this.store$
            .pipe(select(operationMode))
            .subscribe(modOp => {
                if (!!modOp) {
                    const filterFolderStatus = !!isClosed ? isClosed : this.filterFoldersForms.value.closed;
                    this.getStatusByCampaignModOp(filterFolderStatus, JSON.parse(modOp));
                }
            });
    }

    private initFilterForm(): void {
        this.filterFoldersForms = new FormGroup({
            searchValue: new FormControl(null),
            reference: new FormControl(null),
            externalReferences: new FormControl(null),
            externalRepairerSystemReference: new FormControl(null),
            stockClaim: new FormControl(false),
            newWarranty: new FormControl(null),
            origin: new FormControl(null),
            currentWorkFlowStatus: new FormControl(null),
            currentWorkFlowStatusCtrl: new FormControl(null),
            closed: new FormControl(false),
            tags: new FormControl(null),
            dateRange: new FormControl(null),
            workflowStatusDate: new FormControl(null),
            customerZipCode: new FormControl(null),
            customerDepartmentCode: new FormControl(null),
            creationSite: new FormControl(null),
            managementSite: new FormControl(null),
            repairCenter: new FormControl(null),
            supplier: new FormControl(null),

            productCode: new FormControl(null),
            productLabel: new FormControl(null),
            family1Code: new FormControl(null),
            family2Code: new FormControl(null),
            family3Code: new FormControl(null),
            family4Code: new FormControl(null),
            productSegment: new FormControl(null),
            purchaseDate: new FormControl(null),
            invoiceNumber: new FormControl(null),
            serialNumber1: new FormControl(null)
        });
        this.anotherSubscription = this.filterFoldersForms.get(['closed']).valueChanges
            .subscribe(isClosed => {
                if (isClosed) {
                    Context.PRODUCT_RECALL === this.currentContext ?
                        this.getFolderStatusFilteredListByModOpCampaign(isClosed) : this.getFolderStatusFilteredListByContext();
                }

            });
        this.anotherSubscription = this.filterFoldersForms.get(['currentWorkFlowStatusCtrl']).valueChanges
            .pipe(startWith(null))
            .subscribe(input => {
                const matchedResults = !!input ?
                    this.statusListFolder.filter(item =>
                        item.includes(input.toLowerCase())
                    ) : this.statusListFolder;
                this.filteredStatusListFolder.next(matchedResults);
            });

        this.showConfigDisplayedColumns();
    }

    refreshFolderList(): void {
        if (!this.organizationCode) {
            return;
        }
        const folderFilterRequest = this.getSearchFilterRequest();
        this.store$.dispatch(new StartLoading());
        this.folderService.getCustomFolders(folderFilterRequest).subscribe(folderList => {
                if (folderList) {
                    this.completeFolderWithWorkflowStatus(folderList);
                }
                this.store$.dispatch(new StopLoading());
            },
            reason => {
                console.log('ERROR get Folder details' + reason);
                this.store$.dispatch(new StopLoading());
            });
    }

    private getSearchFilterRequest(): SearchFoldersRequestModel {
        const folderFilterRequest: SearchFoldersRequestModel = {
            searchValue: null,
            filters: new Map(),
            page: this.queryParams.page ? this.queryParams.page : 0,
            size: this.queryParams.size ? this.queryParams.size : 10
        };
        if (!!this.queryParams.searchValue) {
            folderFilterRequest.searchValue = this.queryParams.searchValue;
        }
        if ('closed' in this.queryParams) {
            if (this.queryParams['closed'] !== 'null') {
                folderFilterRequest.filters['closed'] = new Filter(JSON.parse(this.queryParams['closed']));
            } else {
                Reflect.deleteProperty(this.queryParams, 'closed');
                Reflect.deleteProperty(folderFilterRequest.filters, 'closed');
            }
        } else {
            folderFilterRequest.filters['closed'] = new Filter(false);
            this.filterFoldersForms.controls['closed'].setValue(false);
        }
        if (!!this.queryParams.newWarranty) {
            folderFilterRequest.filters['newWarranty'] = new Filter(this.queryParams['newWarranty']);
        }
        if (!!this.queryParams.customerContactNumber) {
            folderFilterRequest.filters['customerContactNumber'] = new Filter(this.queryParams['customerContactNumber']);
        }
        if (!!this.queryParams.warrantyReason) {
            folderFilterRequest.filters['warrantyReason'] = new Filter(this.getArrayValueFromQueryParam('warrantyReason'), FilterOperator.IN);
        }
        if (!!this.queryParams['currentWorkFlowStatus']) {
            folderFilterRequest.filters['currentWorkFlowStatus'] = new Filter(this.getArrayValueFromQueryParam('currentWorkFlowStatus'), FilterOperator.IN);
        }
        if (!!this.queryParams.operationMode) {
            folderFilterRequest.filters['operationMode'] = new Filter(this.queryParams['operationMode']);
        }
        if (!!this.queryParams.origin) {
            folderFilterRequest.filters['origin'] = new Filter(this.queryParams['origin']);
        }

        if (!!this.queryParams.startEditRangeDate && !!this.queryParams.endEditRangeDate) {
            folderFilterRequest.filters['workflowStatusDate'] = new Filter({
                fromDate: this.getTimeStampStartDate(this.queryParams['startEditRangeDate']),
                toDate: this.getTimeStampEndDate(this.queryParams['endEditRangeDate'])
            }, FilterOperator.BETWEEN);
        }
        if (!!this.queryParams.startRangeDate && !!this.queryParams.endRangeDate) {
            folderFilterRequest.filters['creationDate'] = new Filter({
                fromDate: this.getTimeStampStartDate(this.queryParams['startRangeDate']),
                toDate: this.getTimeStampEndDate(this.queryParams['endRangeDate'])
            }, FilterOperator.BETWEEN);
        }

        if (!!this.queryParams['externalReferences']) {
            folderFilterRequest.filters['externalReferences'] = new Filter(this.queryParams['externalReferences'], FilterOperator.EQUAL);
        }
        if (!!this.queryParams['stockClaim']) {
            folderFilterRequest.filters['stockClaim'] = new Filter(JSON.parse(this.queryParams['stockClaim']));
        }

        this.addressFilters(folderFilterRequest);

        const inputHeaders = ['searchValue', 'newWarranty', 'warrantyReason', 'currentWorkFlowStatus', 'operationMode', 'origin', 'closed',
            'workflowStatusDate', 'dateRange', 'customerDepartmentCode', 'customerZipCode'];
        Object.keys(this.filterFoldersForms.controls).forEach(control => {
            if (inputHeaders.indexOf(control) === -1 && !!this.queryParams[control] && this.queryParams[control].length > 0) {
                switch (control) {
                    case 'tags': {
                        folderFilterRequest.filters[control] = new Filter(TagEnums.FAVORITES);
                        break;
                    }
                    case 'supplier': {
                        folderFilterRequest.filters[control] = new Filter({
                            value: this.queryParams[control],
                            indexedFields: ['supplierCode', 'supplierLabel']
                        });
                        break;
                    }
                    case 'repairCenter': {
                        folderFilterRequest.filters[control] = new Filter({
                            value: this.queryParams[control],
                            indexedFields: ['repairCenterCode', 'repairCenterName']
                        });
                        break;
                    }
                    case 'creationSite': {
                        folderFilterRequest.filters[control] = new Filter({
                            value: this.queryParams[control],
                            indexedFields: ['creationSiteCode', 'creationSiteName']
                        });
                        break;
                    }
                    case 'managementSite': {
                        folderFilterRequest.filters[control] = new Filter({
                            value: this.queryParams[control],
                            indexedFields: ['managementSiteCode', 'managementSiteName']
                        });
                        break;
                    }
                    default: {
                        folderFilterRequest.filters[control] = new Filter(this.queryParams[control]);
                    }
                }
            }
        });
        return folderFilterRequest;
    }

    private addressFilters(folderFilterRequest: SearchFoldersRequestModel): void {
        if (!!this.queryParams['customerDepartmentCode']) {
            folderFilterRequest.filters['customerZipCode'] = new Filter(this.queryParams['customerDepartmentCode'], FilterOperator.START_WITH);
        }
        if (!!this.queryParams['customerZipCode']) {
            folderFilterRequest.filters['customerZipCode'] = new Filter(this.queryParams['customerZipCode'], FilterOperator.EQUAL);
        }
    }

    private getArrayValueFromQueryParam = (arrayField: string) =>
        this.queryParams[arrayField] instanceof Array ? this.queryParams[arrayField] : [this.queryParams[arrayField]]

    getFolderDetails(): void {
        this.anotherSubscription = this.activatedRoute.queryParams
            .subscribe(paramsValue => {
                this.queryParams = paramsValue;
                if (!this.organizationCode || this.organizationCode === 'ALL' || this.organizationCode === '') {
                    return;
                }
                const folderFilterRequest = this.getSearchFilterRequest();
                this.anotherSubscription = this.folderService.getCustomFolders(folderFilterRequest).subscribe(folderList => {
                    if (folderList) {
                        this.completeFolderWithWorkflowStatus(folderList);
                    }
                }, reason => {
                    console.log('ERROR get Folder details' + reason);
                });
            });
    }

    initializeSelectedItems(folder): FolderIndex {
        if (!!folder.newWarranty && !!folder.operationMode) {
            const selectedWarranty = WARRANTY_CONFIGS.filter(el => el.key === folder.newWarranty.toString())[0];
            const selectedModOp = OPERATING_MODES_CONFIGS.filter(el => el.key === folder.operationMode.toString())[0];
            const selectedOrigin = !!folder.origin ? ORIGIN_TYPE.filter(el => el.key === folder.origin.toString())[0] : null;
            folder.newWarranty = selectedWarranty;
            folder.operationMode = selectedModOp;
            folder.origin = selectedOrigin;
        }
        return folder;
    }

    private completeFolderWithWorkflowStatus(folderList: FolderSearchResponse): void {
        folderList.content.forEach(folder => {
            folder = this.initializeSelectedItems(folder);
            if (folder.status && !folder.status.toString().startsWith('FOLDER_CLOSED')) {
                folder['currentStatusWithTranslation'] = 'COMPONENT.' + folder.currentWorkFlowStatus;
            } else {
                folder['closed'] = true;
            }
        });
        this.folderList.data = folderList.content;

        this.pageConfig = folderList;

        if (this.canRedirectToFolderDetails(folderList.content.length)) {
            this.goToFolderDetails(folderList.content[0]);
        }
    }

    canRedirectToFolderDetails(folderListCount: number): boolean {
        return folderListCount === 1 && this.activatedRoute.snapshot.queryParams.searchBar;
    }

    changePage(event: PageEvent): void {
        this.router.navigate([], {
            queryParams: {
                page: event.pageIndex,
                size: event.pageSize,
                searchBar: null
            },
            queryParamsHandling: 'merge',
        });
    }

    getPageIndex(): number {
        const pageIndex = this.activatedRoute.snapshot.queryParams.page;
        return pageIndex > 0 ? pageIndex : 0;
    }

    goToFolderDetails(folder): void {
        this.router.navigate(['folder', folder.id]);
    }

    openNewTab(folder, event): void {
        // to prevent showing the contextual menu in some browsers after clicking
        event.preventDefault();
        event.stopPropagation();

        window.open('folder/' + folder.id, '_blank');
    }

    changeWarranty(warranty): void {
        this.filterFoldersForms.controls['newWarranty'].setValue(warranty);
    }

    changeOriginType(OriginType): void {
        this.filterFoldersForms.controls['origin'].setValue(OriginType);
    }

    selectStatusWorkflow(currentWorkFlowStatus): void {
        this.filterFoldersForms.controls['currentWorkFlowStatus'].setValue(currentWorkFlowStatus);
    }

    selectAll(select: MatSelect): void {
        const filteredStatus = [];
        select.options.toArray().filter(opt => opt.value != null).forEach(item => {
            filteredStatus.push(item.value);
        });
        this.filterFoldersForms.controls['currentWorkFlowStatus'].setValue(filteredStatus);
    }

    deselectAll(select: MatSelect): void {
        this.filterFoldersForms.controls['currentWorkFlowStatus'].setValue([]);
        select.value = [];
    }

    onSubmit(): void {
        const queryParams = this.mergeFiltersToQueryParam();
        this.router.navigate([], {
            queryParams: queryParams,
            queryParamsHandling: 'merge'
        });
    }

    getCodeFromLabel(currentWorkFlowStatus) {
        return currentWorkFlowStatus.map(label => this.instructionUserTasks.find(it => it.label?.toLowerCase() === label).code);
    }

    private mergeFiltersToQueryParam() {
        const queryParams = {
            searchValue: !!this.filterFoldersForms.value.searchValue ? this.filterFoldersForms.value.searchValue : null,
            reference: !!this.filterFoldersForms.value.reference ? this.filterFoldersForms.value.reference : null,
            newWarranty: !!this.filterFoldersForms.value.newWarranty ? this.filterFoldersForms.value.newWarranty.key : null,
            customerContactNumber: !!this.customerContactNumber ? this.customerContactNumber : null,
            warrantyReason: !!this.warrantyReasonsToFilter && this.warrantyReasonsToFilter.length > 0
                ? this.warrantyReasonsToFilter : null,
            currentWorkFlowStatus: !!this.filterFoldersForms.value.currentWorkFlowStatus && this.filterFoldersForms.value.currentWorkFlowStatus.length > 0
                ? this.getCodeFromLabel(this.filterFoldersForms.value.currentWorkFlowStatus) : null,
            customerZipCode: !!this.filterFoldersForms.value.customerZipCode ? this.filterFoldersForms.value.customerZipCode : null,
            customerDepartmentCode: !!this.filterFoldersForms.value.customerDepartmentCode ? this.filterFoldersForms.value.customerDepartmentCode : null,
            operationMode: this.operatingModeToFilter,
            origin: !!this.filterFoldersForms.value.origin ? this.filterFoldersForms.value.origin.key : null,
            tags: !!this.filterFoldersForms.value.tags ? TagEnums.FAVORITES : null,
            externalReferences: !!this.filterFoldersForms.value.externalReferences ? this.filterFoldersForms.value.externalReferences : null,
            externalRepairerSystemReference: !!this.filterFoldersForms.value.externalRepairerSystemReference ? this.filterFoldersForms.value.externalRepairerSystemReference : null,
            creationSite: !!this.filterFoldersForms.value.creationSite ? this.filterFoldersForms.value.creationSite : null,
            managementSite: !!this.filterFoldersForms.value.managementSite ? this.filterFoldersForms.value.managementSite : null,
            repairCenter: !!this.filterFoldersForms.value.repairCenter ? this.filterFoldersForms.value.repairCenter : null,
            supplier: !!this.filterFoldersForms.value.supplier ? this.filterFoldersForms.value.supplier : null,
            productCode: !!this.filterFoldersForms.value.productCode ? this.filterFoldersForms.value.productCode : null,
            productLabel: !!this.filterFoldersForms.value.productLabel ? this.filterFoldersForms.value.productLabel : null,
            family1Code: !!this.filterFoldersForms.value.family1Code ? this.filterFoldersForms.value.family1Code : null,
            family2Code: !!this.filterFoldersForms.value.family2Code ? this.filterFoldersForms.value.family2Code : null,
            family3Code: !!this.filterFoldersForms.value.family3Code ? this.filterFoldersForms.value.family3Code : null,
            family4Code: !!this.filterFoldersForms.value.family4Code ? this.filterFoldersForms.value.family4Code : null,
            productSegment: !!this.filterFoldersForms.value.productSegment ? this.filterFoldersForms.value.productSegment : null,
            purchaseDate: !!this.filterFoldersForms.value.purchaseDate ? this.filterFoldersForms.value.purchaseDate : null,
            invoiceNumber: !!this.filterFoldersForms.value.invoiceNumber ? this.filterFoldersForms.value.invoiceNumber : null,
            serialNumber1: !!this.filterFoldersForms.value.serialNumber1 ? this.filterFoldersForms.value.serialNumber1 : null,
            stockClaim: !!this.filterFoldersForms.value.stockClaim ? this.filterFoldersForms.value.stockClaim : null
        };
        if (this.filterFoldersForms.value.closed != null) {
            queryParams['closed'] = this.filterFoldersForms.value.closed;
        } else {
            Reflect.deleteProperty(queryParams, 'closed');
        }
        if (!!this.filterFoldersForms.value.dateRange) {
            queryParams['startRangeDate'] = this.getTimeStampStartDate(this.filterFoldersForms.value.dateRange.begin.toDate().getTime());
            queryParams['endRangeDate'] = this.getTimeStampEndDate(this.filterFoldersForms.value.dateRange.end.toDate().getTime());
        } else {
            queryParams['startRangeDate'] = null;
            queryParams['endRangeDate'] = null;
        }
        if (!!this.filterFoldersForms.value.workflowStatusDate) {
            queryParams['startEditRangeDate'] = this.getTimeStampStartDate(this.filterFoldersForms.value.workflowStatusDate.begin.toDate().getTime());
            queryParams['endEditRangeDate'] = this.getTimeStampEndDate(this.filterFoldersForms.value.workflowStatusDate.end.toDate().getTime());
        } else {
            queryParams['startEditRangeDate'] = null;
            queryParams['endEditRangeDate'] = null;
        }
        queryParams['searchBar'] = null;
        return queryParams;
    }

    getTimeStampStartDate(dateTimeStamp): string {
        const dateRang = new Date(Number(dateTimeStamp));
        return new Date(dateRang.setHours(0, 0, 0, 0)).getTime().toString();
    }

    getTimeStampEndDate(dateTimeStamp): string {
        const dateRang = new Date(Number(dateTimeStamp));
        return new Date(dateRang.setHours(23, 59, 59, 59)).getTime().toString();
    }

    disableSearch(): boolean {
        return this.filterFoldersForms.value.tags == null && this.filterFoldersForms.value.searchValue == null &&
            this.filterFoldersForms.value.reference == null && this.filterFoldersForms.value.externalReferences == null &&
            this.filterFoldersForms.value.dateRange == null && this.filterFoldersForms.value.workflowStatusDate == null &&
            this.filterFoldersForms.value.creationSite == null && this.filterFoldersForms.value.managementSite == null &&
            this.filterFoldersForms.value.repairCenter == null && this.filterFoldersForms.value.supplier == null &&
            this.filterFoldersForms.value.externalRepairerSystemReference == null && this.filterFoldersForms.value.newWarranty == null &&
            (!!this.warrantyReasonsToFilter || this.warrantyReasonsToFilter.length === 0) &&
            (this.filterFoldersForms.value.currentWorkFlowStatus == null || this.filterFoldersForms.value.currentWorkFlowStatus.length === 0) &&
            this.filterFoldersForms.value.closed == null && this.operatingModeToFilter == null &&
            this.filterFoldersForms.value.origin == null
            && this.filterFoldersForms.value.productCode == null && this.filterFoldersForms.value.productLabel == null
            && this.filterFoldersForms.value.family1Code == null && this.filterFoldersForms.value.family2Code == null
            && this.filterFoldersForms.value.family3Code == null && this.filterFoldersForms.value.family4Code == null
            && this.filterFoldersForms.value.productSegment == null && this.filterFoldersForms.value.purchaseDate == null
            && this.filterFoldersForms.value.invoiceNumber == null
            && this.filterFoldersForms.value.customerDepartmentCode == null
            && this.filterFoldersForms.value.customerZipCode == null
            && this.filterFoldersForms.value.stockClaim == null
            && this.filterFoldersForms.value.serialNumber1 == null;
    }

    onResetSearchFilter(): void {
        this.resetDataFormAndFilters();
        this.router.navigate(['folder']);
    }

    private resetDataFormAndFilters(): void {
        Object.keys(this.filterFoldersForms.controls).forEach(control => {
            this.filterFoldersForms.controls[control].setValue(control === 'closed' ? false : null);
        });
        Object.keys(this.optionFilterFormGroup.controls).forEach(key => {
            if (key !== 'searchOption') {
                this.optionFilterFormGroup.controls[key].setValue(false);
            }
        });
        this.warrantyReasonsToFilter = [];
        this.operatingModeToFilter = null;
        this.filterTreeData = [];
        this.queryParams = {};
        this.customerContactNumber = null;
    }

    showColumnsTable(columnsName): void {
        if (this.dataSourceFolder.columnsToDisplay.includes(columnsName)) {
            this.dataSourceFolder.columnsToDisplay.splice(this.dataSourceFolder.columnsToDisplay.indexOf(columnsName), 1);
        } else {
            if (columnsName === 'INDICATOR') {
                this.dataSourceFolder.columnsToDisplay.splice(this.dataSourceFolder.columnsToDisplay.length, 0, columnsName);
            } else {
                this.dataSourceFolder.columnsToDisplay.splice(this.dataSourceFolder.columnsToDisplay.length - 1, 0, columnsName);
            }
        }
        this.configDisplayColumnsTableService.setConfigDisplayColumns('folderTable', this.dataSourceFolder.columnsToDisplay);
    }

    private showConfigDisplayedColumns(): void {
        this.configDisplayColumnsTableService.showConfigDisplayedColumns(this.dataSourceFolder, 'folderTable');
    }

    changePositionColumn(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.dataSourceFolder.allColumnsName, event.previousIndex, event.currentIndex);
        this.dataSourceFolder.columnsToDisplay = this.dataSourceFolder.allColumnsName.filter(column =>
            this.dataSourceFolder.columnsToDisplay.includes(column));
        this.dataSourceFolder.columnsToDisplay.splice(this.dataSourceFolder.columnsToDisplay.indexOf('INDICATOR'), 1);
        this.dataSourceFolder.columnsToDisplay.push('INDICATOR');
        this.configDisplayColumnsTableService.setConfigDisplayColumns('folderTable', this.dataSourceFolder.columnsToDisplay);
    }

    showGuideFolderList(): void {
        this.introJsService.showGuideFolderList();
    }


    convertToArray(number): Number[] {
        const dataArray = [];
        for (let item = 0; item <= number; item = item + 4) {
            dataArray.push(item);
        }
        return dataArray;
    }

    searchValueChanged(): void {
        this.onSubmit();
    }

    removeFieldSearch(fieldName: string): void {
        this.filterTreeData.splice(this.filterTreeData.indexOf(fieldName), 1);
        this.optionFilterFormGroup.controls[fieldName].setValue(false);
        this.filterFoldersForms.controls[fieldName].setValue(null);
        this.onSubmit();
    }

    private initOptionsFilter(): void {
        this.optionFilterFormGroup = this.formBuilder.group({
            searchOption: new FormControl(null)
        });
        Object.keys(TREE_DATA).forEach((option: any) => {
            if (TREE_DATA[option]) {
                Object.keys(TREE_DATA[option]).forEach((node: any) => {
                    this.optionFilterFormGroup.addControl(node, this.formBuilder.control(false));
                });
            } else {
                this.optionFilterFormGroup.addControl(option, this.formBuilder.control(false));
            }
        });
        const keysOptionFilterForm = Object.keys(this.optionFilterFormGroup.controls);
        this.anotherSubscription = this.optionFilterFormGroup.get(['searchOption']).valueChanges
            .pipe(startWith(null))
            .subscribe((input: string) => {
                this.fieldsFilterSearch = [];
                if (!!input) {
                    keysOptionFilterForm.forEach(key => {
                        const translateOption = this.translateService.instant('FOLDER.TABLE.FILTER.TREE.' + key.toUpperCase());
                        if (translateOption.toUpperCase().indexOf(input.toUpperCase()) > -1) {
                            this.fieldsFilterSearch.push(key);
                        }
                    });
                } else {
                    keysOptionFilterForm.forEach(key => {
                        this.fieldsFilterSearch.push(key);
                    });
                }
            });
    }

    onClearFormControlValueByPath(path: any): void {
        this.optionFilterFormGroup.controls[path].setValue(null);
        this.event.stopPropagation();
    }

    checkIfEmptyListChildOption(keyOption: string): boolean {
        let hideTitleMenu = false;
        Object.keys(TREE_DATA[keyOption]).forEach(fieldKey => {
            if (this.fieldsFilterSearch.indexOf(fieldKey) > -1) {
                hideTitleMenu = true;
            }
        });
        return hideTitleMenu;
    }

    exportClaims(): void {
        if (this.pageConfig?.totalElements <= this.maxExportSize) {
            this.exportClaimsAsCSV();
        }
    }

    private exportClaimsAsCSV() {
        this.store$.dispatch(new StartLoading());
        this.queryParams = this.mergeFiltersToQueryParam();
        const inputHeaders = this.prepareInputHeadersQuery(this.getDataHeadersFile());
        const folderFilterRequest = this.getSearchFilterRequest();
        const exportFoldersRequest: ExportFoldersRequest = {
            fieldsHeaders: inputHeaders,
            searchFoldersRequest: folderFilterRequest
        };

        this.folderService.exportFileFolders(exportFoldersRequest).subscribe(response => {
            const blob: any = new Blob(['\uFEFF' + response.body], {type: 'text/csv;charset=utf-8'});
            const timestamp = moment().format('YYYYMMDDHHMMSS');
            fileSaver.saveAs(blob, 'folders' + ('_') + timestamp + '.csv');
            this.store$.dispatch(new StopLoading());
        }, () => {
            this.store$.dispatch(new StopLoading());
        });
    }

    private getDataHeadersFile(): string[] {
        return this.currentContext === Context.SAV.toString() ? (this.organizationCode.startsWith('LM_') ? ADEO_SAV_CSV_HEADER : STANDARD_SAV_CSV_HEADER)
            : PRODUCT_RECALL_CSV_HEADER;
    }

    private prepareInputHeadersQuery(dataHeadersFile: string[]): FieldsHeaders[] {
        const inputHeaders = [];
        dataHeadersFile.forEach(titleHeaders => {
            inputHeaders.push({
                code: titleHeaders,
                label: this.translateService.instant('FOLDER.TABLE.FILTER.TREE.' + titleHeaders.toUpperCase())
            });
        });
        return inputHeaders;
    }

    getWarrantyReasonLabel(warrantyReason: string): string {
        const reason = this.warrantyReasonList.find(e => e.code === warrantyReason);
        return !!reason ? reason.label : '';
    }


    private getStatusByCampaignModOp(isClosed: any, campagaignOperatingMode: any): void {
        let correspondentMap;
        if (isClosed === true || isClosed === false) {
            isClosed = JSON.stringify(isClosed);
        }
        switch (isClosed) {
            case 'null':
                correspondentMap = FOLDER_STATUS_MAP.get('all');
                break;
            case 'false':
                correspondentMap = FOLDER_STATUS_MAP.get('opened');
                break;
            case 'true':
                correspondentMap = FOLDER_STATUS_MAP.get('closed');
                break;
        }
        if (Array.isArray(campagaignOperatingMode)) {
            let modOps = [];
            campagaignOperatingMode.forEach(modOp => {
                modOps = [...correspondentMap.get(modOp)];
            });
            this.statusListFolder = [...new Set(modOps)];
            this.filteredStatusListFolder.next(this.statusListFolder);
        }
    }

    findUserTask(taskCode: string): Observable<ConfigurationReferential> {
        return this.store$.pipe(select(cacheConfigObject, {configurationItemCode: 'INSTRUCTION_USER_TASK', code: taskCode}));
    }

    isCsvExportDisabled = () => this.pageConfig?.totalElements > this.maxExportSize;

}
