import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, ReplaySubject} from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {TranslateService} from '@ngx-translate/core';
import {map, startWith} from 'rxjs/operators';
import {KeyValue} from '@angular/common';
import * as moment from 'moment';
import * as uuid from 'uuid';
import {ActivatedRoute, Router} from '@angular/router';
import {AGGREGATION_TYPE, FILTER_BY_FIELDS, GROUP_BY_FIELDS} from './widget.data';
import {ConfigCodeMapsEnum} from 'app/shared/services/configuration-item-enum';
import {OriginEnum} from '../../../../../../../models/enums/origin.enum';
import {ChartsResponse, Range, WidgetFilter} from '../../../../model/charts-response.model';
import {ChartRequest} from '../../../../model/dashboard.model';
import {DashboardService} from '../../../../../../../shared/services/dashboard.service';
import {GENERIC_COMPONENTS_TASKS} from '../../../../../../folder/folder-workflow/generic-components-tasks';
import {StartLoading, StopLoading} from '../../../../../../../store/loader/loader.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../../store/app.state';
import {allConfiguration, multipleConfiguration} from '../../../../../../../store/configuration/configuration.selectors';
import {WIDGET_TYPE, WIDGET_TYPE_OPERATOR} from '../../../../widget.data';
import {ConfigurationReferential} from '../../../../../../../models/configurationReferential.model';
import {Unsubscriber} from '../../../../../../../unsubscriber';


@Component({
    selector: 'app-update-widget',
    templateUrl: './update-widget.component.html',
    styleUrls: ['./update-widget.component.scss']
})
export class UpdateWidgetComponent extends Unsubscriber implements OnInit {

    @ViewChildren('filterInput') filterInput: QueryList<any>;
    @ViewChildren('auto') matAutocomplete: QueryList<MatAutocomplete>;

    widgetForm: FormGroup;
    widgetTypeList = WIDGET_TYPE;
    widgetTypeOperatorList = WIDGET_TYPE_OPERATOR;
    aggregationTypeList = AGGREGATION_TYPE;
    workflowStatusList = Object.keys(GENERIC_COMPONENTS_TASKS);
    originList = Object.keys(OriginEnum);
    operationModeList: ConfigurationReferential[];
    warrantyReasonList: ConfigurationReferential[];
    family1List: ConfigurationReferential[];
    family2List: ConfigurationReferential[];
    family3List: ConfigurationReferential[];
    family4List: ConfigurationReferential[];
    brandList: ConfigurationReferential[];
    rangeList: Range[];
    mapFilter: Map<string, WidgetFilter> = new Map();
    selectedFilters: Observable<any[]>[];

    groupByFieldsList = new ReplaySubject<any[]>();
    filterByFieldsList = new ReplaySubject<any[]>();

    selectable = true;
    removable = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    filters: string[] = [];
    isSimulation = false;
    widgetData: ChartsResponse;
    widget: ChartRequest;
    widgetId: string;
    isUpdateMode = false;
    multipleSelectionValues = new FormControl();

    constructor(public dashboardService: DashboardService,
                private store$: Store<AppState>,
                public translateService: TranslateService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        // @ts-ignore
        this.isUpdateMode = this.activatedRoute.url.value.map(val => val.path).includes('update');

        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.MOD_OP}))
            .subscribe(it => this.operationModeList = it.concat(['NO_MODOP']));

        this.anotherSubscription = this.store$.pipe(select(multipleConfiguration, {configurationItemCodes: [ConfigCodeMapsEnum.WARRANTY_REASON_HG, ConfigCodeMapsEnum.WARRANTY_REASON_SG]}))
            .subscribe(it => this.warrantyReasonList = it);

        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.FAMILY}))
            .subscribe(it => this.family1List = it);

        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.SUB_FAMILY}))
            .subscribe(it => this.family2List = it);

        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.TYPE}))
            .subscribe(it => this.family3List = it);

        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.SUB_TYPE}))
            .subscribe(it =>  this.family4List = it);

        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.BRANDS}))
            .subscribe(it =>  this.brandList = it);

        this.initWidgetForm();
        if (this.isUpdateMode) {
            this.anotherSubscription = this.activatedRoute.params.subscribe(params => {
                this.widgetId = params.id;
                this.loadWidget(params.id);
            });
        }
    }


    initWidgetForm(): void {
        this.widgetForm = new FormGroup({
            aggregation: new FormGroup({
                aggregationFolderIndexFilterCtrl: new FormControl(null),
                name: new FormControl(null, [Validators.required]),
                type: new FormControl(null, [Validators.required]),
                aggregation_type: new FormControl(null, [Validators.required]),
                aggregation_field: new FormControl(null, [Validators.required])
            }),
            groupBy: new FormGroup({
                groupByFolderIndexFilterCtrl: new FormControl(null),
                field: new FormControl(),
                key: new FormControl(),
                from: new FormControl(),
                to: new FormControl()
            }),
        });
        this.rangeList = [];
        this.mapFilter = new Map<string, WidgetFilter>();
        this.selectedFilters = [];
        this.anotherSubscription = this.widgetForm.get(['aggregation', 'aggregationFolderIndexFilterCtrl']).valueChanges
            .pipe(startWith(null))
            .subscribe(input => {
                this.groupByFieldsList.next(this.filterList(GROUP_BY_FIELDS, input));
            });

        this.anotherSubscription = this.widgetForm.get(['groupBy', 'groupByFolderIndexFilterCtrl']).valueChanges
            .pipe(startWith(null))
            .subscribe(input => {
                this.groupByFieldsList.next(this.filterList(GROUP_BY_FIELDS, input));
            });
    }

    filterList(list: any[], input: string): any {
        if (!!input) {
            return list.filter(el =>
                el.name.toLowerCase().includes(input.toLowerCase())
                || (this.translateService.instant('DASHBOARD.WIDGET.FORM.FOLDER_INDEX.' + el.name.toUpperCase()).toLowerCase().includes(input.toLowerCase()))
            );
        }
        return list;
    }

    onClearSelectFormControlValueByPath(path: any): void {
        this.widgetForm.get(path).setValue(null);
        if (path[0].indexOf('groupBy') >= 0) {
            this.rangeList = [];
        }
    }

    remove(filter: string, filterIndex: string): void {
        if (!this.mapFilter.has(filterIndex)) {
            return;
        }
        const widgetFilter = this.mapFilter.get(filterIndex);
        const index = widgetFilter.value.indexOf(filter);
        if (index < -1) {
            return;
        }

        widgetFilter.value.splice(index, 1);

        this.mapFilter.set(filterIndex, widgetFilter);
    }

    selectOneValue(value, filterIndex: string): void {
        if (!value || !this.mapFilter.has(filterIndex)) {
            return;
        }
        const widgetFilter = this.mapFilter.get(filterIndex);
        widgetFilter.value = [];
        widgetFilter.value.push(value);
        this.mapFilter.set(filterIndex, widgetFilter);
    }

    selectDateValue(value, filterIndex: string, type: string): void {
        if (!value || !this.mapFilter.has(filterIndex)) {
            return;
        }
        const widgetFilter = this.mapFilter.get(filterIndex);
        if (type === 'selectMultiDate') {
            widgetFilter.value = [{
                fromDate: moment(value.begin).toDate().getTime(),
                toDate: moment(value.end).toDate().getTime()
            }];
        } else {
            widgetFilter.value.push(moment(value).toDate().getTime());
        }
        this.cleanChipValue();
        this.mapFilter.set(filterIndex, widgetFilter);
    }

    selectMultipleValues(value, filterIndex: string): void {
        if (!value || !this.mapFilter.has(filterIndex)) {
            return;
        }
        const widgetFilter = this.mapFilter.get(filterIndex);
        value.forEach(item => {
            if (!widgetFilter.value.includes(item)) {
                widgetFilter.value.push(item);
            }
        });
        this.mapFilter.set(filterIndex, widgetFilter);
    }

    selectOneValueFromList(value, filterIndex: string): void {
        if (!value || !this.mapFilter.has(filterIndex)) {
            return;
        }
        const widgetFilter = this.mapFilter.get(filterIndex);
        widgetFilter.value.push(value.code);
        this.mapFilter.set(filterIndex, widgetFilter);
    }

    private cleanChipValue(): void {
        this.filterInput.forEach(it => {
            it.nativeElement.value = '';
        });
    }

    private _filter(value: any, listName: string): string[] {
        const statusValue = value.code ? value.code.toLowerCase : value.toLowerCase();
        return this[listName].concat(this[listName + '_translated']).filter(status => status.code ? (status.code.toLowerCase().indexOf(statusValue) === 0) : (status.toLowerCase().indexOf(statusValue) === 0)
            || (this.translateService.instant('DASHBOARD.WIDGET.FORM.STATUS_FOLDER.' + status.toUpperCase()).toLowerCase().indexOf(statusValue) === 0)
        );
    }

    onSubmit(): void {
        this.store$.dispatch(new StartLoading());
        const widget = this.createWidgetRequest();
        if (this.isUpdateMode) {
            this.dashboardService.updateWidget(this.widgetId, widget).subscribe(value => {
                this.refreshWidgets();
            }, () => this.store$.dispatch(new StopLoading()));
        } else {
            this.dashboardService.createWidget(widget).subscribe(value => {
                this.refreshWidgets();
            }, () => this.store$.dispatch(new StopLoading()));
        }
    }

    refreshWidgets(): void {
        setTimeout(() => {
            this.store$.dispatch(new StopLoading());
            this.router.navigate(['dashboard/widget']);
        }, 500);
    }

    private createWidgetRequest(): any {
        const widget = {
            name: this.widgetForm.value.aggregation.name,
            type: this.widgetForm.value.aggregation.type,
            aggregation: {
                type: this.widgetForm.value.aggregation.aggregation_type,
                name: 'agg',
                field: (!!this.widgetForm.value.aggregation.aggregation_field
                    && !!this.widgetForm.value.aggregation.aggregation_field.name) ? this.widgetForm.value.aggregation.aggregation_field.name : '_id',
                suffix: (!!this.widgetForm.value.aggregation.aggregation_field
                    && !!this.widgetForm.value.aggregation.aggregation_field.type) ? this.widgetForm.value.aggregation.aggregation_field.type : '_id'
            },
        };
        if (!!this.widgetForm.value.groupBy.field) {
            const groupBy = {
                field: this.widgetForm.value.groupBy.field.name
            };
            if (!!this.rangeList && this.rangeList.length > 0) {
                groupBy['ranges'] = this.rangeList;
            }
            widget['groupBy'] = groupBy;
        }

        if (this.mapFilter.size > 0) {
            widget['filters'] = Array.from(this.mapFilter).reduce((obj, [key, value]) => {
                obj[value.key] = {
                    operator: value.operator,
                    value: this.getFilterValue(value, this.widgetForm.get(['filters_' + key, 'key']).value.type)
                };
                return obj;
            }, {});
        }
        return widget;
    }

    getFilterValue(value: WidgetFilter, type: string): any {
        if (!value.value) {
            return '';
        }
        switch (value.operator) {
            case   'IN':
            case   'NOT_IN':
                return value.value;
            case   'BETWEEN':
                return (!!type && type === 'Date' ? value.value[0] : value.value);
            default:
                return value.value[0];
        }
    }

    onAddGroup(): void {
        this.rangeList.push({
            key: this.widgetForm.value.groupBy.key,
            color: '',
            from: this.widgetForm.value.groupBy.from,
            to: this.widgetForm.value.groupBy.to
        });
        this.widgetForm.get(['groupBy', 'key']).setValue(null);
        this.widgetForm.get(['groupBy', 'from']).setValue(null);
        this.widgetForm.get(['groupBy', 'to']).setValue(null);
    }

    onAddFilter(): void {
        const uud = uuid.v4();
        this.mapFilter.set(uud, {
            key: '',
            value: [],
            index: this.mapFilter.size,
            operator: ''
        });

        const filters = new FormGroup({
            filterFolderIndexFilterCtrl: new FormControl(),
            key: new FormControl(null, [Validators.required]),
            filter: new FormControl(),
            filterCtrl: new FormControl(null),
            operator: new FormControl(null, [Validators.required])
        });


        this.widgetForm.addControl('filters_' + uud, filters);
        const selectedFilters: Observable<any[]> = null;
        this.selectedFilters.push(selectedFilters);

    }

    onValueChange(filterIndex: string, index: number): void {
        this.selectedFilters[index] = this.widgetForm.get(['filters_' + filterIndex, 'filterCtrl']).valueChanges
            .pipe(
                startWith(null),
                map((folderStatus: string | null) => {
                    if (folderStatus) {
                        return this._filter(folderStatus, this.widgetForm.get(['filters_' + filterIndex, 'key']).value.type);
                    } else {
                        return this[this.widgetForm.get(['filters_' + filterIndex, 'key']).value.type];
                    }
                }));
    }

    onFilterValueChange(filterIndex: string): void {
        this.anotherSubscription = this.widgetForm.get(['filters_' + filterIndex, 'filterFolderIndexFilterCtrl']).valueChanges
            .pipe(startWith(null))
            .subscribe(input => {
                // clear filter value input
                this.onClearFilterFilterForm(filterIndex);
                this.filterByFieldsList.next(this.filterList(FILTER_BY_FIELDS, input));
            });
    }

    removeFilter(key: string, index: number): void {
        this.mapFilter.delete(key);
        this.widgetForm.removeControl('filters_' + key);
        this.selectedFilters.splice(index, 1);
    }

    updateKey(key: string): void {
        const widgetFilter = this.mapFilter.get(key);
        widgetFilter.key = this.widgetForm.get(['filters_' + key, 'key']).value.name;
        widgetFilter.value = [];
        this.mapFilter.set(key, widgetFilter);
    }

    isCreationDateType(): boolean {
        if (!!this.widgetForm.value.groupBy.field) {
            return this.widgetForm.value.groupBy.field.type === 'Date';
        }
        return false;
    }

    changeGroupByField(path: string[], value: any, filterIndex?: string): void {
        this.widgetForm.get(path).setValue(value.value);
        if (path[0].indexOf('filters') >= 0) {
            this.updateKey(filterIndex);
            return;
        }
        this.rangeList = [];
    }

    updateAggregationField(aggregationType: string, event: any): void {
        if (event.isUserInput) {    // ignore on deselection of the previous option
            if (aggregationType.startsWith('COUNT')) {
                this.widgetForm.get(['aggregation', 'aggregation_field']).setValue('_id');
            }
        }


    }

    updateOperatorFilter(operator: string, filterIndex: string, event: any): void {
        if (event.isUserInput) {    // ignore on deselection of the previous option
            if (!operator || !this.mapFilter.has(filterIndex)) {
                return;
            }
            const widgetFilter = this.mapFilter.get(filterIndex);
            widgetFilter.operator = operator;
            this.mapFilter.set(filterIndex, widgetFilter);

        }
    }


    isDisabledAggregationField(): boolean {
        return !this.widgetForm.get(['aggregation', 'aggregation_type']).value
            || this.widgetForm.get(['aggregation', 'aggregation_type']).value.startsWith('COUNT');
    }

    removeRange(rangeIndex: number): void {
        this.rangeList.splice(rangeIndex, 1);
    }

    indexOrderAsc = (firstElement: KeyValue<string, WidgetFilter>, secendElement: KeyValue<string, WidgetFilter>): number => {
        let a = 0;
        let b = 0;
        if (!!firstElement.value && !!secendElement.value) {
            a = firstElement.value.index;
            b = secendElement.value.index;
        }
        return a > b ? 1 : (b > a ? -1 : 0);
    };

    getWidget(widgetFilter): string {
        if (this.workflowStatusList.includes(widgetFilter)) {
            return 'COMPONENT.' + widgetFilter.toUpperCase();
        }
        if (widgetFilter.fromDate && widgetFilter.toDate) {
            return moment(widgetFilter.fromDate).format('DD/MM/YYYY') + '-' + moment(widgetFilter.toDate).format('DD/MM/YYYY');
        }
        if (widgetFilter.begin && widgetFilter.end) {
            return moment(widgetFilter.begin).format('DD/MM/YYYY') + '-' + moment(widgetFilter.end).format('DD/MM/YYYY');
        }
        if (widgetFilter.label) {
            return widgetFilter.label;
        }
        return widgetFilter;
    }

    simulate(): void {
        if (this.isSimulation === true) {
            this.isSimulation = false;
        }
        this.store$.dispatch(new StartLoading());
        const widget = this.createWidgetRequest();
        this.dashboardService.simulateWidget(widget).subscribe(value => {
            this.store$.dispatch(new StopLoading());
            this.isSimulation = true;
            if (!!value) {
                this.widgetData = value;
            }
        }, () => this.store$.dispatch(new StopLoading()));
    }

    onReset(): void {
        this.router.navigate(['dashboard/widget']);
    }

    private loadWidget(widgetId: string): void {
        this.store$.dispatch(new StartLoading());
        this.dashboardService.getWidget(widgetId).subscribe(widget => {
            this.store$.dispatch(new StopLoading());
            if (!!widget) {
                this.widget = widget;
                this.initWidgetFormWithData();
                this.simulate();
            }

        }, error => {
            this.store$.dispatch(new StopLoading());
        });
    }

    onClearFilterFilterForm(key: string): void {
        this.widgetForm.get(['filters_' + key, 'filter']).setValue(null);
        this.widgetForm.get(['filters_' + key, 'filterCtrl']).setValue(null);
    }

    isListFilter(operationMode: string, filterType: string): boolean {
        if (!filterType) {
            return false;
        }
        const listFilterType = ['DATE', 'CHECKBOX'];
        const listOperationMode = ['EQUAL', 'NOT_EQUAL', 'LET', 'LT', 'GET', 'GT'];
        return listOperationMode.includes(operationMode) && !listFilterType.includes(filterType.toUpperCase());
    }

    isCheckboxFilter(operationMode: string, filterType: string): boolean {
        const listFilterType = ['CHECKBOX'];
        return listFilterType.includes(filterType.toUpperCase());
    }

    isMultipleSelectionFilter(operationMode: string, filterType: string): boolean {
        const listFilterType = ['DATE', 'CHECKBOX', 'STRING'];
        const listOperationMode = ['IN', 'NOT_IN'];
        return listOperationMode.includes(operationMode) && !listFilterType.includes(filterType?.toUpperCase());
    }


    isDateFilter(operationMode: string, filterType: string): boolean {
        const listFilterType = ['DATE'];
        return listFilterType.includes(filterType?.toUpperCase());
    }

    private initWidgetFormWithData(): void {
        this.initWidgetForm();

        this.widgetForm = new FormGroup({
            aggregation: new FormGroup({
                aggregationFolderIndexFilterCtrl: new FormControl(null),
                name: new FormControl(this.widget.name, [Validators.required]),
                type: new FormControl(this.widget.type, [Validators.required]),
                aggregation_type: new FormControl(this.widget.aggregation ? this.widget.aggregation.type : null, [Validators.required]),
                aggregation_field: new FormControl(this.getGroupByField(this.widget.aggregation ? this.widget.aggregation.field : null), [Validators.required])
            }),
            groupBy: new FormGroup({
                groupByFolderIndexFilterCtrl: new FormControl(),
                field: new FormControl(this.getGroupByField(this.widget.groupBy ? this.widget.groupBy.field : null)),
                key: new FormControl(),
                from: new FormControl(),
                to: new FormControl()
            }),
        });
        if (!!this.widget.groupBy && !!this.widget.groupBy.ranges && this.widget.groupBy.ranges.length > 0) {
            this.rangeList = [...this.widget.groupBy.ranges];
        }
        if (!!this.widget.filters) {
            this.initializeFilter();
        }

    }

    getGroupByField(field: string): any {
        return GROUP_BY_FIELDS.find(value => value.name.startsWith(field));
    }

    initializeFilter(): void {
        let i = 0;
        Object.keys(this.widget.filters).forEach(key => {
            const uud = uuid.v4();
            const filter = FILTER_BY_FIELDS.find(value => value.name.startsWith(key));
            this.mapFilter.set(uud, {
                key: key,
                value: this.widget.filters[key].value instanceof Array ? this.widget.filters[key].value : [this.widget.filters[key].value],
                index: this.mapFilter.size,
                operator: this.widget.filters[key].operator
            });
            const filters = new FormGroup({
                filterFolderIndexFilterCtrl: new FormControl(filter),
                key: new FormControl(filter, [Validators.required]),
                filter: new FormControl(this.widget.filters[key].value, [Validators.required]),
                filterCtrl: new FormControl(),
                operator: new FormControl(this.widget.filters[key].operator, [Validators.required])
            });

            this.widgetForm.addControl('filters_' + uud, filters);
            const selectedFilters: Observable<any[]> = null;
            this.selectedFilters.push(selectedFilters);
            this.onFilterValueChange(uud);
            this.onValueChange(uud, i);
            this.selectedFilters[i].subscribe(value => {
                if (value) {
                    this.widgetForm.get(['filters_' + uud, 'filter']).setValue(value.find(value1 => !!value1?.code ?
                        value1.code.startsWith(this.widget.filters[key].value) : value1.startsWith(this.widget.filters[key].value)));
                }
            });
            i++;
        });
    }

    isRadioChecked(expected: string, filter: any): any {
        return this.widgetForm.get(['filters_' + filter.key]).value.filter === expected;
    }
}
