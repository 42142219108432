import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {FuseModule} from '../../../../../@fuse/fuse.module';
import {fuseConfig} from '../../../../fuse-config';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '../../../../../@fuse/components';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../../../../app.module';
import {HttpClient} from '@angular/common/http';
import {SharedGenericModule} from '../../../../shared/generic/shared-generic.module';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {IntroduceCampaignComponent} from './introduce-compaign/introduce-campaign.component';
import {ProductCheckCustomerComponent} from './product-check-customer/product-check-customer.component';
import {NewContactOfProductComponent} from './new-contact-of-product/new-contact-of-product.component';


@NgModule({
    declarations: [
        IntroduceCampaignComponent,
        NewContactOfProductComponent,
        ProductCheckCustomerComponent
    ],
    imports: [FormsModule,
        // Material moment date module
        MatMomentDateModule,
        // Material
        Ng2TelInputModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedGenericModule,
        MatCardModule, MatIconModule, MatTableModule, NgxDropzoneModule,
    ],
    exports: [
    ]
})
export class ProductRecallProcessModule {
}
