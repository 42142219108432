    <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
    </div>

    <mat-card-content *ngIf="sparePartsToBeOrdered?.length > 0" >
        <form [formGroup]="sparePartForm" fxLayout="column" fxLayoutGap="10px">
            <div translate="yes" class="hide-translate-text">
            <table mat-table formArrayName="spareParts" [dataSource]="sparePartsToBeOrdered"
                   class="w-100-p mat-elevation-z4 mt-25">

                <ng-container matColumnDef="reference">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="table-header"> {{'ASSET_CATALOG.FORM.FIELDS.REFERENCE' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <span>{{element.code}}</span>

                            <ng-container *ngIf="element.information">
                            <span>
                                <mat-icon color="warn"
                                          [matTooltip]="element.information"
                                          matTooltipPosition="right">info
                                </mat-icon>
                            </span>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="label">
                    <th mat-header-cell class="table-header"
                        *matHeaderCellDef> {{'ASSET_CATALOG.FORM.FIELDS.LABEL' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <span>{{ element.label }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="supplier">
                    <th mat-header-cell class="table-header"
                        *matHeaderCellDef> {{'ASSET_CATALOG.FORM.FIELDS.SUPPLIER' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.supplier?.name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="stock">
                    <th mat-header-cell class="table-header" *matHeaderCellDef>
                      <span class="notranslate">{{'ASSET_CATALOG.FORM.FORM.HEADERS.STOCK' | translate}}</span>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index" class="cursor-pointer text-center">
                        <ng-container>
                            <div *ngIf="isStockLoading" class="spinner-wrapper">
                                <mat-spinner diameter="20"></mat-spinner>
                            </div>
                            <span *ngIf="!isStockLoading"
                                  [ngClass]="getStockIndicatorClass(element.stock?.status)"
                                  matTooltipClass="multi-line-tooltip"
                                  [matTooltip]="hasStock(element.stock?.status) ? stockDetailsTooltip(element.stock?.stockDetails, element.stock?.totalStock) : ''">
                              {{displayStockStatus(element.stock?.status) | translate}}
                          </span>
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="image">
                    <th mat-header-cell class="table-header"
                        *matHeaderCellDef> {{'ASSET_CATALOG.FORM.FORM.HEADERS.IMAGE' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index" class="cursor-pointer text-center">
                        <ng-container *ngIf="!!element.images && element.images.length > 0 else no_image">
                            <div *ngIf="element.images[0].isLoading" class="spinner-wrapper">
                                <mat-spinner diameter="20"></mat-spinner>
                            </div>
                            <img [hidden]="element.images[0].isLoading" height="50" width="50"
                                 (click)="openMultiImageModal(element.images)"
                                 class="cursor-pointer zone-file_img img-product_folder"
                                 (load)="onLoad(element.images[0])"
                                 (error)="onImageError($event)"
                                 [src]="element.images[0].src">
                        </ng-container>
                        <ng-template #no_image>
                            <img height="30" width="30"
                                 class="zone-file_img img-product_folder"
                                 src="assets/icons/spartpart.png">
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="price">
                    <th mat-header-cell class="table-header"
                        *matHeaderCellDef> {{'ASSET_CATALOG.FORM.FIELDS.SALE.PRICE.TTC' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <span *ngIf="isFree(element)">{{ 'ASSET_CATALOG.FORM.FIELDS.FREE' | translate }}</span>
                        <mat-form-field [formGroupName]="i" appearance="outline" class="price"  *ngIf="!isFree(element)">
                            <input   matInput [valueAsNumber]="element.priceUnitwithTax"
                                     min="0" type="number" required
                                     (change)="changePrice($event, i)"
                                     [ngClass]="{ 'error': sparePartsFormArray.controls[i].get('priceUnitwithTax')?.hasError('required')}"
                                     formControlName="priceUnitwithTax">
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="quantity" >
                    <th mat-header-cell class="table-header" *matHeaderCellDef>
                        {{'ASSET_CATALOG.FORM.FIELDS.QUANTITY' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <span *ngIf="isNorauto$ | async">{{element.quantityOrdered}}</span>
                        <ng-container [formGroupName]="i"  *ngIf="!(isNorauto$ | async)">
                            <mat-form-field appearance="outline" class="quantity" >
                                <input   matInput [valueAsNumber]="element.quantityOrdered"
                                       min="1" type="number" required
                                       (change)="changeQuantity($event,i)"
                                         NumberOnlyNotIncludeZeros
                                       [ngClass]="{ 'error': sparePartsFormArray.controls[i].get('quantityOrdered')?.hasError('required')}"
                                       formControlName="quantityOrdered">
                            </mat-form-field>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell class="table-header" *matHeaderCellDef>
                        <mat-checkbox   [(ngModel)]="isAllSelected" [ngModelOptions]="{standalone: true}" (change)="confirmAllRows()" [disabled]="isNorauto$ | async">
                           </mat-checkbox>
                      <span > {{'SPARE_PART_STATUS' | translate}} </span>

                    </th>
                    <td class="checkbox" mat-cell *matCellDef="let row; let i = index" fxLayout="row" fxLayoutGap="15px">
                        <mat-checkbox [disabled]="isNorauto$ | async" [ngModelOptions]="{standalone: true}" [(ngModel)]="row.isSelected" (change)="updateItemStatus(row,i)"></mat-checkbox>
                        <span class="notranslate">{{displayItemStatus(row.itemStatus) | translate }}</span>
                    </td>
                </ng-container>
                <ng-container   matColumnDef="totalPrice">
                    <th mat-header-cell class="table-header"
                        *matHeaderCellDef> {{'ASSET_CATALOG.FORM.FIELDS.SALE.TOTAL.PRICE.TTC' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <span>{{ displayTotalPriceTTC(element) | currency: element.currency :'symbol-narrow'}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell class="table-header" *matHeaderCellDef>
                        {{'TABLE.ACTION' | translate}}
                    </th>
                    <td class="element" mat-cell *matCellDef="let row;let i = index;">
                        <button matSuffix mat-icon-button color="warn"
                                type="button" class="mx-8"
                                (click)="deleteLine(i)">
                            <mat-icon>delete_forever</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>

            </table>
            </div>
            <fieldset  *ngIf="warrantyCode === 'HG'">
                <legend class="h3 title-fieldset">{{ 'COMPONENT.CHECK_SPARE_PARTS.SHIPMENT.FEES.TITLE'|translate }}</legend>
                <div class="mt-12" fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">

                        <mat-form-field fxFlex="50" appearance="outline" >
                            <mat-label>{{ 'COMPONENT.CHECK_SPARE_PARTS.SHIPMENT.FEES.LABEL'|translate }}</mat-label>
                            <input  type="text" matInput [id]="'shipmentFeesLabels'"
                                    [placeholder]="'COMPONENT.CHECK_SPARE_PARTS.SHIPMENT.FEES.LABEL'|translate"
                                    [formControl]="shipmentFeesLabelControl"
                                    [matAutocomplete]="auto">

                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayShipmentFee" (optionSelected)="onSelectShipmentFee($event)" >
                                <mat-option  *ngFor="let shipmentFee of shipmentFeesList"
                                             [id]="shipmentFee.code"
                                             [value]="shipmentFee.code">
                                    {{shipmentFee.code}} - {{shipmentFee.label}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                    <ng-container fxFlex="40">
                        <mat-form-field appearance="outline" class="price">
                            <mat-label>{{ 'COMPONENT.CHECK_SPARE_PARTS.SHIPMENT.FEES.PRICE'|translate }}</mat-label>
                            <input matInput [valueAsNumber]="selectedShipmentFee?.price"
                                   (change)="formatAmount($event)"
                                   min="0" type="number" required
                                   [ngClass]="{ 'error': shipmentFeesPriceControl?.hasError('required')}"
                                   [formControl]="shipmentFeesPriceControl"
                            >
                        </mat-form-field>
                    </ng-container>

                </div>
            </fieldset>

        </form>
    </mat-card-content>
    <mat-dialog-actions class="mt-24 mb-0">
        <div class="w-100-p">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                <ng-container>
                    <span class="info link"
                          (click)="backToChooseSpareParts()">  {{'COMPONENT.CHECK_SPARE_PARTS.BUTTON.GO.BACK' | translate}}</span>
                </ng-container>
                <ng-container>
                    <div class="w-100-p" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
                        <ng-container>
                            <button mat-raised-button type="submit"
                                    (click)="onSubmit()"
                                    [ngClass]="sparePartForm?.invalid || sparePartsToBeOrdered?.length == 0? 'aster_btn_disabled' : 'aster_btn'"
                                    [disabled]="sparePartForm?.invalid || sparePartsToBeOrdered?.length == 0"> {{'BUTTON.VALIDATE' | translate}}</button>
                        </ng-container>

                    </div>
                </ng-container>
            </div>
        </div>
    </mat-dialog-actions>