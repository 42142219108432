import {Component, Input, OnInit} from '@angular/core';
import {Symptom} from '../../../../../../models/symptom.model';

@Component({
    selector: ' app-symptom-preview-card',
    templateUrl: './symptom-preview-card.component.html',
    styleUrls: ['./symptom-preview-card.component.scss']
})
export class SymptomPreviewCardComponent implements OnInit {
    @Input() symptom: Symptom;

    // @Output() triggerConfirmedSymptom = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
    }

}
