import {Injectable, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
    providedIn: 'root'
})
export class MatPaginatorI18nIntl extends MatPaginatorIntl implements OnDestroy {
    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();

    paginatorOf = 'of';

    constructor(private translateService: TranslateService){
        super();
        this.loadPaginatorIntl();

        this.translateService.onLangChange.pipe(takeUntil(this._onDestroy)).subscribe( () => {
            this.loadPaginatorIntl();
        });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    loadPaginatorIntl(): void {
        this.paginatorOf = this.translateService.instant('MAT_PAGINATOR_INTL.PAGINATOR_OF');

        this.itemsPerPageLabel = this.translateService.instant('MAT_PAGINATOR_INTL.ITEMS_PER_PAGE_LABEL');

        this.firstPageLabel = this.translateService.instant('MAT_PAGINATOR_INTL.FIRST_PAGE_LABEL');
        this.lastPageLabel = this.translateService.instant('MAT_PAGINATOR_INTL.LAST_PAGE_LABEL');
        this.previousPageLabel = this.translateService.instant('MAT_PAGINATOR_INTL.PREVIOUS_PAGE_LABEL');
        this.nextPageLabel = this.translateService.instant('MAT_PAGINATOR_INTL.NEXT_PAGE_LABEL');


        this.getRangeLabel = (page: number, pageSize: number, length: number) => {
            if (length === 0 || pageSize === 0) {
                return `0 ${this.paginatorOf} ${length}`;
            }
            length = Math.max(length, 0);
            const startIndex = page * pageSize;
            const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
            return `${startIndex + 1} - ${endIndex} ${this.paginatorOf} ${length}`;
        };

        this.changes.next();
    }

}
