
    <app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>

    <mat-card-content *ngIf="visioQualificationForm.form">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div class="h4 mb-8  description-font-ars text-center">
                <div [innerHTML]="getDescription() | async"></div>
            </div>
        </div>
        <form [formGroup]="visioQualificationForm.form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <mat-radio-group name="otherServices" formControlName="status" class="ml-20" labelPosition="after" fxLayout="column"
                             fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button name="otherServices" class="full-width-radio" *ngFor="let status of visioQualificationForm.placeholders"
                                  [value]="status.status">
                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                        <div fxFlex>
                            <div>{{ status.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="visioQualificationForm.form.value.status === 'NOT_REPAIRED'">
                <br>
                <div class="h-48 ml-40" fxLayout="column" fxLayoutAlign="space-around start" fxLayoutGap="10px">
                    <mat-radio-group name="changeModop" fxLayout="column" fxLayoutAlign="space-around start" fxLayoutGap="10px">
                            <mat-radio-button name="changeModop" value="value1" class="full-width-radio" (click)="$event.stopPropagation(); checkIfSparePart(visioQualificationForm.services[0].status);"
                                              (change)="changeModeOp($event,visioQualificationForm.services[0].status)">
                                {{visioQualificationForm.services[0].value | translate }}
                            </mat-radio-button>
                            <mat-radio-button name="changeModop" value="value2" class="full-width-radio" (click)="$event.stopPropagation(); checkIfSparePart(visioQualificationForm.services[1].status);"
                                              (change)="changeModeOp($event,visioQualificationForm.services[1].status)">
                                {{visioQualificationForm.services[1].value | translate }}
                            </mat-radio-button>
                    </mat-radio-group>
                    <mat-checkbox class="ml-2" (click)="$event.stopPropagation(); checkIfSparePart(visioQualificationForm.services[2].status);"
                                      (change)="changeModeOp($event,visioQualificationForm.services[2].status)"
                                      [checked]="selection.isSelected(visioQualificationForm.services[2].status)">
                        {{visioQualificationForm.services[2].value | translate }}
                    </mat-checkbox>
                </div>
                  <div *ngIf="sparePartsIsSelected" class="mt-30">
                      <div fxLayout="column" fxLayoutAlign="space-between stretch">
                          <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="12px">
                              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                                  <label class="h3 text-bold">
                                      {{'FOLDER.LIST_SPARE_PARTS' | translate}}
                                  </label>
                              </div>

                          </div>
                          <div [ngClass]="'cursor-pointer pr-30'" fxLayout="row" fxLayoutGap="5px"
                               fxLayoutAlign="end center"
                               (click)="openSparePartOrderModal()">
                              <ng-container *ngIf="sparePartDataTable.data.length==0;else SHOW_ADD_BUTTON">
                                  <mat-icon>add_circle_outline</mat-icon>
                                  <span [ngClass]="'link-underline'">{{'BUTTON.ADD' | translate}}</span>
                              </ng-container>
                              <ng-template #SHOW_ADD_BUTTON>
                                  <ng-container>
                                      <mat-icon >edit</mat-icon>
                                      {{'BUTTON.EDIT' | translate}}
                                  </ng-container>
                              </ng-template>

                          </div>

                          <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                              <table mat-table [dataSource]="sparePartDataTable"
                                     class="mat-elevation-z4 w-100-p mt-16 mb-16">
                                  <ng-container matColumnDef="reference">
                                      <th mat-header-cell *matHeaderCellDef
                                          class="table-header">  {{'FOLDER.REPAIR_IN_PROGRESS.CODE' | translate}}  </th>
                                      <td mat-cell *matCellDef="let sparePart">{{sparePart.reference}}</td>
                                  </ng-container>
                                  <ng-container matColumnDef="label">
                                      <th mat-header-cell *matHeaderCellDef class="table-header">{{'FOLDER.REPAIR_IN_PROGRESS.LABEL' | translate}}</th>
                                      <td mat-cell *matCellDef="let sparePart">{{sparePart.label}}</td>
                                  </ng-container>
                                  <ng-container matColumnDef="price">
                                      <th mat-header-cell *matHeaderCellDef
                                          class="table-header">{{'FOLDER.REPAIR_IN_PROGRESS.TOTAL_PRICE' | translate}}</th>
                                      <td mat-cell *matCellDef="let sparePart">{{sparePart.totalLinePrice | currency: currency :'symbol-narrow' | space}}</td>
                                  </ng-container>
                                  <tr mat-header-row *matHeaderRowDef="sparePartLineColumns"></tr>
                                  <tr mat-row *matRowDef="let row; columns: sparePartLineColumns;"></tr>
                              </table>
                          </div>
                      </div>
                  </div>
              </ng-container>
              <div fxLayoutAlign="end center" class="mt-24">
                  <button type="submit" mat-stroked-button [disabled]="disabledButtonForm()"
                          [ngClass]="disabledButtonForm() ? 'aster_btn_disabled' : 'aster_btn'">
                      {{'BUTTON.VALIDATE' | translate}}
                  </button>
              </div>
          </form>
      </mat-card-content>