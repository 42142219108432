import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {MatTableDataSource} from '@angular/material/table';
import {Observable} from 'rxjs';
import {FileService} from '../../../../../../shared/services/file.service';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {Quotation} from '../../../../../../models/quotation.model';
import {FileInfo} from '../../../../../../models/file-info.model';
import {saveAs} from 'file-saver';
import {FuseConfirmDialogComponent} from '../../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {MatDialog} from '@angular/material/dialog';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {currentLanguage} from '../../../../../../store/organization/organization.selectors';
import * as moment from 'moment/moment';

@Component({
    selector: 'app-attachment-uploader',
    templateUrl: './attachment-uploader.component.html',
    styleUrls: ['./attachment-uploader.component.scss']
})
export class AttachmentUploaderComponent extends Unsubscriber implements OnInit {
    @ViewChild('dropzone') dropzone: ElementRef;
    @Input() folder: Folder;
    @Input() quotation: Quotation;
    @Output() attachmentsFolder = new EventEmitter<any[]>();
    fileUploadForm: any = {
        file: null,
        hasFile: false
    };
    attachments = new Array<any>();
    attachmentsToAdded = new Array<any>();
    attachmentQuotationDataTable = new MatTableDataSource<any>();
    displayedColumnsAttachment: string[] = ['name', 'date', 'action'];
    currentLanguage$: Observable<string>;
    currentDate: string = moment().format();

    constructor(private fileService: FileService,
                private translateService: TranslateService,
                private folderService: FolderService,
                private store$: Store<AppState>,
                private matDialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        this.getCurrentLanguage();
        this.initAttachmentDataTable();
    }

    private getCurrentLanguage() {
        this.anotherSubscription = this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
    }

    private initAttachmentDataTable(): void {
        if (this.quotation?.quotationAttachments.length > 0) {
            this.quotation.quotationAttachments.forEach(fileId => {
                const attachment = this.folder.attachments.find(file => file.id.startsWith(fileId));
                if (attachment && attachment?.name){
                    const newAttachment = {file: attachment.name, info: attachment};
                    this.attachments.push(newAttachment);
                }
            });
        }
        this.attachmentQuotationDataTable.data = this.attachments;
    }

    onFilesAdded($event: any): void {
        this.fileUploadForm.hasFile = true;
        this.fileUploadForm.file = $event[0].name;
        const fileChange = this.fileService.onFileChange($event);
        fileChange.reader.onload = () => {
            const blobFile = this.fileService.dataURItoBlob(fileChange.reader.result.toString().split(',')[1]);
            const newAttachment = {file: blobFile, info: $event[0]};
            this.attachments.push(newAttachment);
            this.attachmentQuotationDataTable.data = this.attachments;
            this.attachmentsToAdded.push(newAttachment);
            this.attachmentsFolder.emit(this.attachmentsToAdded);
            // @ts-ignore
            this.dropzone.reset();
            this.fileUploadForm.hasFile = false;
        };
    }


    removeFile(fileId): void {
        const dialog = this.matDialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });
        dialog.componentInstance.title = this.translateService.instant('CONFIRMATION.MODAL.DELETE_DIALOG.TITLE');
        dialog.componentInstance.message = this.translateService.instant('FOLDER.FILE.DELETE_DIALOG.MESSAGE');
        dialog.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.DELETE');
        dialog.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialog.afterClosed().subscribe(isDelete => {
            if (isDelete) {
                if (this.quotation.quotationAttachments.length !== 0 && fileId.info.id) {
                    this.folderService.deleteAttachmentFileFolder(this.folder.id, fileId.info.id).then(resFile => {
                        this.folder.attachments = this.folder.attachments.filter(file => file.id !== fileId.info.id);
                        this.quotation.quotationAttachments = this.quotation.quotationAttachments.filter(file => file !== fileId.info.id);
                        this.attachmentQuotationDataTable.data = this.attachmentQuotationDataTable.data.filter(attachment => attachment !== fileId);
                        this.attachments = this.attachments.filter(attachment => attachment !== fileId);
                        this.attachmentsFolder.emit(this.attachmentQuotationDataTable.data);
                    });
                } else {
                    this.attachmentQuotationDataTable.data = this.attachmentQuotationDataTable.data.filter(attachment => attachment !== fileId);
                    this.attachments = this.attachments.filter(attachment => attachment !== fileId);
                    this.attachmentsToAdded = this.attachmentsToAdded.filter(attachment => attachment !== fileId);
                    this.attachmentsFolder.emit(this.attachmentQuotationDataTable.data);
                }
            }
        });
    }

    getFileInfo(fileId: string): FileInfo {
        return this.folder.attachments.find(file => file.id.startsWith(fileId));
    }

    downloadFile(fileId: string): void {
        this.fileService.getAttachmentFile(fileId).subscribe(value => {
            saveAs(value, this.getFileInfo(fileId).name);
        });
    }
}
