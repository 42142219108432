import {Component, Inject, OnInit} from '@angular/core';
import {CountriesUtils} from '../../../../../shared/data/countries-utils';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {allConfiguration} from '../../../../../store/configuration/configuration.selectors';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {AppState} from '../../../../../store/app.state';
import {Unsubscriber} from '../../../../../unsubscriber';
import {currentUser} from '../../../../../store/user/user.selectors';
import {SupplierDocument} from '../../../../../models/SupplierDocument';

@Component({
    selector: 'detail-supplier-dialog',
    templateUrl: './detail-supplier-dialog.component.html',
    styleUrls: ['./detail-supplier-dialog.component.scss']
})
export class DetailSupplierDialogComponent extends Unsubscriber implements OnInit {

    supplier: SupplierDocument;
    supplierContactsTypes = new Array<any>();
    supplierContactsTypesOfSupplier = new Array<any>();
    supplierContacts = new Array<any>();

    constructor(private dialogRef: MatDialogRef<DetailSupplierDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private store$: Store<AppState>) {
        super();
    }


    ngOnInit(): void {
        if (!!this.data) {
            this.supplier = this.data.supplier;
            this.getSupplierContactsType();
            this.getSupplierContacts();
        }
    }


    onClose(): void {
        this.dialogRef.close();
    }

    isValidCountryCode(countryCode: string): boolean {
        return CountriesUtils.isValidCountryCode(countryCode);
    }

    getSupplierContactsByRole(role: string): any[] {
        return this.supplierContacts.filter(value => value.role === role);
    }

    getSupplierContactsType(): void {
        this.anotherSubscription = this.store$.pipe(select(allConfiguration, {configurationItemCode: ConfigCodeMapsEnum.SUPPLIER_CONTACTS}))
            .subscribe(it => this.supplierContactsTypes = it);
    }

    getSupplierContacts(): void {
        this.anotherSubscription = this.store$.pipe(select(currentUser)).subscribe(currentUser => {
            this.supplierContacts = Object.assign([], this.supplier.supplierContacts);
            this.buildSupplierContactsTypesOfSupplier();
        });
    }

    buildSupplierContactsTypesOfSupplier() {
        const roles = this.supplier.supplierContacts.map(it => it.role);
        this.supplierContactsTypesOfSupplier = this.supplierContactsTypes.filter(value => roles.includes(value.code));
    }

}
