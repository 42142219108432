import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-cancel-intervention-modal',
    templateUrl: './cancel-intervention-modal.component.html',
    styleUrls: ['./cancel-intervention-modal.component.scss']
})
export class CancelInterventionModalComponent implements OnInit {

    form: FormGroup;

    constructor(public dialogRef: MatDialogRef<CancelInterventionModalComponent>,
                private dialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private formBuilder: FormBuilder) {
        this.initFormCancelIntervention();
    }

    ngOnInit(): void {
    }

    initFormCancelIntervention(): void {
        this.form = this.formBuilder.group({
            cancelIntervention: [null, Validators.required]
        });
    }

    close() {
        return this.dialogRef.close(this.form.value.cancelIntervention);
    }
}
