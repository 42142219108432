<mat-card *ngIf="!!symptom" class="mat-elevation-aster w-100-p">
    <div fxLayout="row">
        <!--        <mat-icon class="mr-20" style="vertical-align: sub;">build</mat-icon>-->
        <h1 class="text-bold"> {{'FOLDER.SYMPTOM.TITLE' | translate}} </h1>
    </div>
    <mat-card-content>
        <h3>
            {{symptom.label}}
        </h3>
    </mat-card-content>
</mat-card>
