import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ProductService} from '../../../../../shared/services/product.service';
import {SiteType} from '../../../../../models/enums/siteType.enum';
import {StockResponse} from './model/stock-response.model';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {Unsubscriber} from '../../../../../unsubscriber';
import {GrowthbookService} from '../../../../../shared/services/growthbook.service';
import {GrowthbookAttributes} from '../../../../../shared/features/growthbook-attributes';
import {currentUser} from '../../../../../store/user/user.selectors';
import {switchMap, take} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs';
import {UserState} from '../../../../../store/user/user.state';
import {UserTasks} from '../../../../../shared/features/user-tasks';
import {AppFeatures} from '../../../../../shared/features/app-features';
import {TaskVariables} from '../../task.variables';
import {TranslateService} from '@ngx-translate/core';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

export const CHOICE_CONTROL_NAME = 'choice';
export const CUSTOMER_VOUCHER_REASON_CONTROL_NAME = 'customerVoucherReason';

export const VOUCHER = 'VOUCHER';
export const SWAP_WITH_EQUIVALENT_PRODUCT = 'SWAP_WITH_EQUIVALENT_PRODUCT';
export const REFUND_CLIENT = 'REFUND_CLIENT';

export interface CustomerVoucherReason {
    code: string;
    label: string;
}

export interface FormOption {
    choice: string;
    value: string;
    extraOptions?: any;
    isLoading: boolean;
}

// All the options as a fallback value when growthbook is down
export const FALLBACK_OPTIONS: FormOption[] = [
    {choice: VOUCHER, value: 'BUTTON.VOUCHER', isLoading: false},
    {choice: SWAP_WITH_EQUIVALENT_PRODUCT, value: 'BUTTON.SWAP_WITH_EQUIVALENT_PRODUCT', isLoading: false},
    {choice: REFUND_CLIENT, value: 'BUTTON.REFUND_CLIENT', isLoading: false}
];

@Component({
    selector: 'app-validate-swap-in-store',
    templateUrl: './validate-swap-in-store.component.html',
    styleUrls: ['./validate-swap-in-store.component.scss']
})
export class ValidateSwapInStoreComponent extends Unsubscriber implements OnInit {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    form: FormGroup;
    placeholders: FormOption[] = [];
    formSubmitted = false;

    stockResponse: StockResponse;

    // begin customer voucher controls
    public customerVoucherReasons: CustomerVoucherReason[] = [];
    public customerVoucherReasonCtrl: FormControl = new FormControl(null, [Validators.required]);
    public customerVoucherReasonFilterCtrl: FormControl = new FormControl();
    public filteredCustomerVoucherReasons: ReplaySubject<CustomerVoucherReason[]> = new ReplaySubject<CustomerVoucherReason[]>(1);

    // end customer voucher controls

    constructor(private store$: Store<AppState>,
                private growthbookService: GrowthbookService,
                private translateService: TranslateService,
                private productService: ProductService) {
        super();
    }

    ngOnInit(): void {
        this.prepareForm();
    }

    private prepareForm() {
        this.store$.dispatch(new StartLoading());
        // create the form
        this.form = new FormGroup({
            choice: new FormControl(null, Validators.required),
        });

        this.anotherSubscription = this.store$.pipe(select(currentUser), take(1)).pipe(
            switchMap((user: UserState) => {
                const attributes: GrowthbookAttributes = {
                    organizationCode: user.organizationCode,
                    context: user.context,
                    userTask: UserTasks.VALIDATE_SWAP_IN_STORE
                };

                return this.growthbookService.getFeatureValue(AppFeatures.USER_TASK_VALIDATE_SWAP_IN_STORE, attributes, FALLBACK_OPTIONS);
            }),
        ).subscribe((formPlaceholders: FormOption[]) => {
                this.handleFormOptions(formPlaceholders);
                this.store$.dispatch(new StopLoading());
            },
            () => {
                this.store$.dispatch(new StopLoading());
            });
    }

    private handleFormOptions(formOptions: FormOption[]) {
        // populate the form options from feature flag response
        this.placeholders = formOptions;

        const swapOption = this.placeholders.find(formOption => formOption.choice === SWAP_WITH_EQUIVALENT_PRODUCT);

        if (swapOption) {
            this.setStockInfo(swapOption);
        }

        // customizing the logic per user choice
        this.anotherSubscription = this.form.get(CHOICE_CONTROL_NAME).valueChanges.subscribe((choice: string) => {
                if (choice === VOUCHER) {
                    const voucherOption = formOptions.find(formOption => formOption.choice === VOUCHER);

                    const voucherExtraOptions = (voucherOption && voucherOption.extraOptions as CustomerVoucherReason[]) || [];
                    if (voucherOption && voucherExtraOptions.length > 0) {
                        this.form.addControl(CUSTOMER_VOUCHER_REASON_CONTROL_NAME, this.customerVoucherReasonCtrl);
                        this.customerVoucherReasons = voucherOption.extraOptions;
                        this.filteredCustomerVoucherReasons.next(this.customerVoucherReasons.slice());
                        this.listenToSearchChanges();
                    }
                } else if (this.form.contains(CUSTOMER_VOUCHER_REASON_CONTROL_NAME)) {
                    this.customerVoucherReasonCtrl.reset();
                    this.form.removeControl(CUSTOMER_VOUCHER_REASON_CONTROL_NAME);
                }
            }
        );
    }

    onSubmit(): void {
        this.formSubmitted = true;
        this.store$.dispatch(new StartLoading());
        const completeTaskBody = {
            [TaskVariables.FINAL_CUSTOMER_SOLUTION]: this.form.value.choice
        };

        if (this.customerVoucherReasonCtrl.value) {
            completeTaskBody[TaskVariables.CUSTOMER_VOUCHER_REASON] = this.customerVoucherReasonCtrl.value;
        }

        this.inputMap.emit(completeTaskBody);
    }


    setStockInfo(swapOption: FormOption): void {
        swapOption.isLoading = true;

        this.productService.getAvailableStock(this.folder.organization.code, this.folder.product.code, this.getManagementSiteCode())
            .subscribe((stockResponse: StockResponse) => {
                    if (!!stockResponse) {
                        this.stockResponse = stockResponse;

                        if (stockResponse.errorCode == null || stockResponse.errorCode === '') {
                            swapOption.value = this.translateService.instant('BUTTON.SWAP_WITH_EQUIVALENT_PRODUCT_AVAILABLE_STOCK', {stock: stockResponse.availableStock});
                        } else {
                            swapOption.value = this.translateService.instant('BUTTON.SWAP_WITH_EQUIVALENT_PRODUCT_UNAVAILABLE_STOCK_INFO');
                        }
                    }
                    swapOption.isLoading = false;
                },
                () => {
                    swapOption.isLoading = false;
                });
    }

    /**
     * listen for search field value changes
     */
    private listenToSearchChanges() {
        this.anotherSubscription = this.customerVoucherReasonFilterCtrl.valueChanges
            .subscribe(() => {
                this.filterCustomerVoucherReasons();
            });
    }

    private filterCustomerVoucherReasons() {
        if (!this.customerVoucherReasons) {
            return;
        }
        // get the search keyword
        let search = this.customerVoucherReasonFilterCtrl.value;
        if (!search) {
            this.filteredCustomerVoucherReasons.next(this.customerVoucherReasons.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the customer voucher reasons
        this.filteredCustomerVoucherReasons.next(
            this.customerVoucherReasons.filter(voucherReason =>
                voucherReason.code.toLowerCase().indexOf(search) > -1 || voucherReason.label.toLowerCase().indexOf(search) > -1)
        );
    }

    public getManagementSiteCode(): string {
        const sites = this.folder.sites.filter(site => site.type === SiteType.MANAGEMENT_SITE);
        if (sites.length > 0 && sites[0]) {
            return sites[0].code;
        }
        return '';
    }

    invalidForm = () => this.form.invalid || this.formSubmitted || this.placeholders.some(formOption => formOption.isLoading);

}
