export class LocalizationUtils {

    /**
     * compute distance in KM using Haversines law
     */
    static computeDistance(fromLat: number, fromLong: number, toLat: number, toLong: number): number {
        const R = 6371e3;
        const p1 = fromLat * Math.PI / 180;
        const p2 = toLat * Math.PI / 180;
        const deltaP = p2 - p1;
        const deltaLon = toLong - fromLong;
        const deltaLambda = (deltaLon * Math.PI) / 180;
        const a = Math.sin(deltaP / 2) * Math.sin(deltaP / 2) +
            Math.cos(p1) * Math.cos(p2) *
            Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
        const distance = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * R;
        return Number((distance / 1000).toFixed(1));
    }

}
