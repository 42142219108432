import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ProductUtils} from '../../../../../shared/utils/product-utils';
import {ReceptionModeEnum} from '../../../../../models/enums/receptionMode.enum';
import {ProductLocationTypes} from '../../../../../models/product.model';
import {OriginEnum} from '../../../../../models/enums/origin.enum';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
  selector: 'app-waiting-client-decision-product-works',
  templateUrl: './waiting-client-decision-product-works.component.html',
  styleUrls: ['./waiting-client-decision-product-works.component.scss']
})
export class WaitingClientDecisionProductWorksComponent implements OnInit {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    form: any = {
        form: null,
        placeholders: []
    };

  constructor() { }

  ngOnInit(): void {
      this.initForm();
  }


    private initForm(): void {
        this.form.form = new FormGroup({
            choice: new FormControl(null, Validators.required),
        });
        this.form.placeholders = [
            {
                choice: 'true',
                value: 'BUTTON.YES',
            },

            {
                choice: 'false',
                value: 'BUTTON.NO',
            }
        ];
    }

    getCurrentLocation = () => ProductUtils.latestLocation(this.folder.product);

    onSubmit(): void {
        const receptionMode = this.IsIgnoringReceptionShipmentProcess() ? ReceptionModeEnum.ALREADY_IN_STORE : ReceptionModeEnum.REPAIRER_TO_CUSTOMER;
        this.inputMap.emit({
            'returnProductChoice': this.form.form.value.choice,
            'receptionMode': receptionMode
        });
    }

    private IsIgnoringReceptionShipmentProcess(): boolean {
        return this.getCurrentLocation() === ProductLocationTypes.STORE
            || (this.folder.origin === OriginEnum.BO && this.getCurrentLocation() === ProductLocationTypes.REPARATION);
    }
}
