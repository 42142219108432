import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {Unsubscriber} from '../../../unsubscriber';
import {PublicQuotation, Quotation} from '../../../models/quotation.model';
import {AppState} from '../../../store/app.state';
import {currentLanguage} from '../../../store/organization/organization.selectors';
import {FolderPublic} from '../../../models/folder.public.model';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-public-quotation-report-card',
    templateUrl: 'public-quotation-report-card.component.html',
    styleUrls: ['public-quotation-report-card.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])
    ]
})
export class PublicQuotationReportCardComponent extends Unsubscriber implements OnInit {
    @Input() folder: FolderPublic;
    displayedColumns: string[] = ['code', 'label', 'type', 'totalPrice'];
    expandedElement: PublicQuotation | null;
    currentLanguage$: Observable<string>;

    constructor(
        private store$: Store<AppState>,
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
    }

    getPublicQuotationTitle(quotation: Quotation) {
        return quotation.code + '-' + this.getTraductionOfTarget(quotation.target) + this.getTraductionOfStatus(quotation.status);
    }

    getTraductionOfTarget(target): string {
        return !!target ? this.translateService.instant('TARGET.DISTRIBUTOR') : '';
    }

    getTraductionOfStatus(status): string {
        return !!status ? this.translateService.instant('QUOTATION.STATUS.' + status) : '';
    }
}
