import {FuseConfig} from '@fuse/types';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login_spec.ts/login_spec.ts.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const fuseConfig_horizontal: FuseConfig = {
    // Color themes can be defined in src/app/app.theme.scss
    colorTheme: 'theme-default',
    customScrollbars: true,
    layout: {
        width: 'fullwidth',
        style: 'horizontal-layout-1',
        isMobile: false,
        navbar: {
            primaryBackground: 'fuse-navy-700',
            secondaryBackground: 'fuse-navy-900',
            folded: false,
            hidden: false,
            position: 'top',
            variant: 'horizontal-layout-1'
        },
        toolbar: {
            background: 'fuse-white-500',
            customBackgroundColor: false,
            hidden: false,
            position: 'above'
        },
        footer: {
            customBackgroundColor: true,
            background           : 'cyan-A700',
            hidden               : false,
            position             : 'below-static' // below-fixed || below-static
        },
        sidepanel: {
            hidden: false,
            position: 'right'
        }
    }
};
