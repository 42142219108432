import {UserAction} from './userAction.model';

export interface IAbstractEntity {
    id: string;
    isActive: boolean;
    isDeleted: boolean;
    createdBy: UserAction;
    modifiedBy: UserAction[];
}

export class AbstractEntity implements IAbstractEntity {
    id: string;
    isActive: boolean;
    isDeleted: boolean;
    createdBy: UserAction;
    modifiedBy: UserAction[];
}
