<h2 mat-dialog-title>{{'FOLDER.DIALOG.TITLE.ANONYMIZE' | translate}}</h2>
<mat-dialog-content class="mb-20">
    {{'FOLDER.DIALOG.CONTENT.ANONYMIZE' | translate}}
    {{data.customer.id}} ({{data.customer.lastName}} {{data.customer.firstName}})
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end end">
    <button mat-stroked-button type="reset" class="aster_btn_cancel"
            [mat-dialog-close]="false">{{'BUTTON.CANCEL' | translate}}</button>
    <button mat-stroked-button type="submit" class="aster_btn"
            [mat-dialog-close]="true">{{'BUTTON.VALIDATE' | translate}}</button>
</mat-dialog-actions>
