<div>
    <h2>
        <mat-icon class="mt-20">all_inbox</mat-icon>
        {{'FOLDER.SHIPMENT.TITLE'| translate}}
    </h2>
</div>

<table mat-table [dataSource]="dataSourceShipments" multiTemplateDataRows class="mat-elevation-z4 w-100-p mt-16 mb-16">
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="table-header"> {{'COMPONENT.SHIPMENT.TYPE' | translate}}</th>
        <td mat-cell *matCellDef="let shipmentLine"> {{ displayShipmentType(shipmentLine) | translate}} </td>
    </ng-container>
    <ng-container matColumnDef="carrier">
        <th mat-header-cell *matHeaderCellDef class="table-header"> {{'COMPONENT.SHIPMENT.CARRIER' | translate}}</th>
        <td mat-cell *matCellDef="let shipmentLine"> {{  shipmentLine.carrier }} </td>
    </ng-container>
    <ng-container matColumnDef="trackingNumber">
        <th mat-header-cell *matHeaderCellDef class="table-header">  {{'COMPONENT.SHIPMENT.TRACKING_NUMBER' | translate}}</th>
        <td mat-cell *matCellDef="let shipmentLine"><a [href]="shipmentLine.trackingURL" target="_blank" class="cursor-pointer" >
            {{shipmentLine.trackingNumber}}
        </a></td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="shipment-line-element-detail"
                 [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="w-100-p p-20">


                    <div fxLayout="row" fxLayoutAlign="space-between">
                        <div>
                            <label class="text-bold h3">{{'COMPONENT.SHIPMENT.SENDER_CONTACT' | translate}}</label>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-between" class="mt-4">
                        <div fxFlex="40">
                            <mat-icon>person</mat-icon>
                            {{(element.senderContact?.firstName ?  element.senderContact.firstName : '')  + ' ' + (element.senderContact?.lastName ? element.senderContact.lastName : '') }}
                        </div>
                        <div fxFlex="60">
                            <mat-icon>mail</mat-icon>
                            {{element.senderContact?.email}}
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-between">
                        <div fxFlex="40">
                            <mat-icon>local_phone</mat-icon>
                            {{element.senderContact?.phoneNumber}}
                        </div>
                        <ng-container *ngIf="!!element.senderContact?.address">
                            <div fxFlex="60">
                                <mat-icon>location_city</mat-icon>
                                {{'COUNTRY.NAME.' + element.senderContact.address.countryCode | translate}},
                                {{element.senderContact.address.city}} -
                                {{element.senderContact.address.zipCode}}
                            </div>
                        </ng-container>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-between"  class="mt-12">
                        <div>
                            <label class="text-bold h3">{{'COMPONENT.SHIPMENT.RECEIVER_CONTACT' | translate}}</label>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-between" class="mt-4">
                        <div fxFlex="40">
                            <mat-icon>person</mat-icon>
                            {{(element.receiverContact?.firstName ?  element.receiverContact.firstName : '')  + ' ' + (element.receiverContact?.lastName ? element.receiverContact.lastName : '')}}
                        </div>
                        <div fxFlex="60">
                            <mat-icon>mail</mat-icon>
                            {{element.receiverContact?.email}}
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-between">
                        <div fxFlex="40">
                            <mat-icon>local_phone</mat-icon>
                            {{element.receiverContact?.phoneNumber}}
                        </div>
                        <ng-container *ngIf="!!element.receiverContact?.address">
                            <div fxFlex="60">
                                <mat-icon>location_city</mat-icon>
                                {{'COUNTRY.NAME.' + element.receiverContact.address.countryCode | translate}},
                                {{element.receiverContact.address.city}} -
                                {{element.receiverContact.address.zipCode}}
                            </div>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="shouldDisplayDownloadButton()">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" fxLayoutGap="12px" class="mt-8">
                            <div  class="text-left cursor-pointer link-underline"
                                 (click)="getShipmentAttachment()">
                                <mat-icon>file_copy</mat-icon>
                                {{'FOLDER.PARCEL_TO_SEND.DOWNLOAD.DOCUMENT' | translate}}
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="element?.events?.length > 0">
                        <table class="w-100-p  mat-elevation-z4  mt-24">
                            <thead>
                            <tr>
                                <th class="mat-header-cell table-header">{{'COMPONENT.SHIPMENT.EVENT_TYPE' | translate}}</th>
                                <th class="mat-header-cell table-header">{{'COMPONENT.SHIPMENT.LABEL' | translate}}</th>
                                <th class="mat-header-cell table-header">{{'COMPONENT.SHIPMENT.DATE' | translate}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container *ngFor="let event of element.events">
                                <tr>
                                    <td class="mat-cell">{{'COMPONENT.SHIPMENT.EVENT_TYPE.' + event.eventType | translate}}</td>
                                    <td class="mat-cell">{{ event.label }}</td>
                                    <td class="mat-cell">{{event.date| date: ('GENERAL.DATE_TIME_FORMAT' | translate) : (currentLanguage$ | async)}}</td>
                                </tr>
                            </ng-container>
                            </tbody>
                        </table>
                    </ng-container>
                 </div>
             </div>
         </td>
     </ng-container>

     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
     <tr mat-row *matRowDef="let shipmentLine; columns: displayedColumns;"
         class="shipment-line-element-row cursor-pointer"
         [ngClass]="{'row-selected text-bold': expandedElement === shipmentLine}"
         (click)="expandedElement = expandedElement === shipmentLine ? null : shipmentLine">
     </tr>
     <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

 </table>



