<div class="page-layout blank p-24" fxLayout="column" fxLayoutAlign="start center" fusePerfectScrollbar>

    <form [formGroup]="dashboardDataSource.filterForm" class="w-100-p"
          (ngSubmit)="onSearch()">
        <mat-card fxLayout="row" fxLayoutAlign="space-between center" >

            <div   class="mt-12" >

                <mat-form-field appearance="outline">
                    <mat-label>{{'ITINERANT_LABEL_PLANNING.FIELDS.DATE.PLACEHOLDER' | translate}}</mat-label>
                    <input matInput [matDatepicker]="picker" [min]="mindate"
                           [formControl]="dashboardDataSource.filterForm.get(['selectedDate'])"

                           (dateChange)="onChangeDatePicker()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="mt-12">
            <div [ngClass]="viewDate | date:'MMM'" fxLayout="row" fxLayoutAlign="center"
                 class="mb-12 mt-12">

                <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">


                    <!-- HEADER BOTTOM -->
                    <div class="header-bottom" fxLayout="row" fxLayoutAlign="center center">

                        <button mat-icon-button class="arrow"
                                mwlCalendarPreviousView
                                [view]="view"
                                [(viewDate)]="viewDate"
                                (viewDateChange)="selectedDay = {date:$event}"
                                (click)="toggleWeek()"
                                aria-label="Previous">
                            <mat-icon>chevron_left</mat-icon>
                        </button>

                        <div>
                                            <span
                                                class="mat-title">  {{ viewDate | calendarDate:(view + 'ViewTitle'):'fr'  : startWeek}}</span>
                        </div>

                        <button mat-icon-button class="arrow"
                                mwlCalendarNextView
                                [view]="view"
                                [(viewDate)]="viewDate"
                                (viewDateChange)="selectedDay = {date:$event}"
                                (click)="toggleWeek()"
                                aria-label="Next">
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                    </div>
                    <!-- / HEADER BOTTOM -->
                </div>
            </div>
            </div>
            <div   class="mt-12" >
                <mat-form-field class="w-200" appearance="outline">
                    <mat-select [formControl]="dashboardDataSource.filterForm.get(['siteQuery'])"
                                [placeholder]="'ITINERANT_LABEL_PLANNING.FIELDS.SITES.PLACEHOLDER' | translate">

                        <mat-option>
                        <ngx-mat-select-search
                            formControlName="siteCodeCtrl"
                            [placeholderLabel]="'ITINERANT_LABEL_PLANNING_SITE_SEARCH' | translate"
                            [noEntriesFoundLabel]="'ITINERANT_LABEL_PLANNING_SITE_EMPTY' | translate">
                        </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let item of filteredSiteList$ | async" [value]="item.code">
                            <span>{{ item.label }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-card>
    </form>
            <table mat-table [dataSource]="dashboardDataSource.data" class="w-100-p  mt-16">


                <ng-container matColumnDef="technician">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element"
                        class="custom-td"> {{element.technicianName + " " + element.technicianLastName}} </td>
                </ng-container>


                <ng-container matColumnDef="monday">
                    <th mat-header-cell *matHeaderCellDef> {{'WEEK.DAY.MONDAY' | translate}} {{dayOfWeek(0)}} </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="center">
                            <div class="custom-a" (click)="openDashboardDay(element,element.dayDashboardList[0])"
                                 *ngIf="element.dayDashboardList[0].availableDuration !== 0">
                                <div class="zone-progress-spinner">
                                    <mat-progress-spinner class="spinner mt-20"
                                                          [ngClass]="codeColor(element.dayDashboardList[0].availabilityPercent)"
                                                          [mode]="mode"
                                                          [value]="element.dayDashboardList[0].availabilityPercent"
                                                          aria-label="Rating">
                                    </mat-progress-spinner>
                                    <div class="availability-percent">
                                        {{element.dayDashboardList[0].availabilityPercent}} %
                                    </div>
                                    <div class="zone-user_delivery_count">
                                        <mat-icon>person</mat-icon>
                                        <span class="delivery-count">
                                            {{element.dayDashboardList[0].interventionCount}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </td>
                </ng-container>


                <ng-container matColumnDef="tuesday">
                    <th mat-header-cell *matHeaderCellDef> {{'WEEK.DAY.TUESDAY' | translate}} {{dayOfWeek(1)}} </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="center">
                            <div class="custom-a" (click)="openDashboardDay(element,element.dayDashboardList[1])"
                                 *ngIf="element.dayDashboardList[1].availableDuration !== 0">
                                <div class="zone-progress-spinner">
                                    <mat-progress-spinner class="spinner mt-20"
                                                          [ngClass]="codeColor(element.dayDashboardList[1].availabilityPercent)"
                                                          [mode]="mode"
                                                          [value]="element.dayDashboardList[1].availabilityPercent"
                                                          aria-label="Rating">
                                    </mat-progress-spinner>
                                    <div class="availability-percent">
                                        {{element.dayDashboardList[1].availabilityPercent}}  %
                                    </div>
                                    <div class="zone-user_delivery_count">
                                        <mat-icon>person</mat-icon>
                                        <span class="delivery-count">
                                            {{element.dayDashboardList[1].interventionCount}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </td>
                </ng-container>


                <ng-container matColumnDef="wednesday">
                    <th mat-header-cell *matHeaderCellDef> {{'WEEK.DAY.WEDNESDAY' | translate}} {{dayOfWeek(2)}} </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="center">
                            <div class="custom-a" (click)="openDashboardDay(element,element.dayDashboardList[2])"
                                 *ngIf="element.dayDashboardList[2].availableDuration !== 0">
                                <div class="zone-progress-spinner">
                                    <mat-progress-spinner class="spinner mt-20"
                                                          [ngClass]="codeColor(element.dayDashboardList[2].availabilityPercent)"
                                                          [mode]="mode"
                                                          [value]="element.dayDashboardList[2].availabilityPercent"
                                                          aria-label="Rating">
                                    </mat-progress-spinner>
                                    <div class="availability-percent">
                                        {{element.dayDashboardList[2].availabilityPercent}}  %
                                    </div>
                                    <div class="zone-user_delivery_count">
                                        <mat-icon>person</mat-icon>
                                        <span class="delivery-count">
                                            {{element.dayDashboardList[2].interventionCount}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </td>
                </ng-container>
                <ng-container matColumnDef="thursday">
                    <th mat-header-cell *matHeaderCellDef> {{'WEEK.DAY.THURSDAY' | translate}} {{dayOfWeek(3)}} </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="center">
                            <div class="custom-a" (click)="openDashboardDay(element,element.dayDashboardList[3])"
                                 *ngIf="element.dayDashboardList[3].availableDuration !== 0">
                                <div class="zone-progress-spinner">
                                    <mat-progress-spinner class="spinner mt-20"
                                                          [ngClass]="codeColor(element.dayDashboardList[3].availabilityPercent)"
                                                          [mode]="mode"
                                                          [value]="element.dayDashboardList[3].availabilityPercent"
                                                          aria-label="Rating">
                                    </mat-progress-spinner>
                                    <div class="availability-percent">
                                        {{element.dayDashboardList[3].availabilityPercent}} %
                                    </div>
                                    <div class="zone-user_delivery_count">
                                        <mat-icon>person</mat-icon>
                                        <span class="delivery-count">
                                            {{element.dayDashboardList[3].interventionCount}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </td>
                </ng-container>
                <ng-container matColumnDef="friday">
                    <th mat-header-cell *matHeaderCellDef> {{'WEEK.DAY.FRIDAY' | translate}}  {{dayOfWeek(4)}} </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="center">
                            <div class="custom-a" (click)="openDashboardDay(element,element.dayDashboardList[4])"
                                 *ngIf="element.dayDashboardList[4].availableDuration !== 0">
                                <div class="zone-progress-spinner">
                                    <mat-progress-spinner class="spinner mt-20"
                                                          [ngClass]="codeColor(element.dayDashboardList[4].availabilityPercent)"
                                                          [mode]="mode"
                                                          [value]="element.dayDashboardList[4].availabilityPercent"
                                                          aria-label="Rating">
                                    </mat-progress-spinner>
                                    <div class="availability-percent">
                                        {{element.dayDashboardList[4].availabilityPercent}}  %
                                    </div>
                                    <div class="zone-user_delivery_count">
                                        <mat-icon>person</mat-icon>
                                        <span class="delivery-count">
                                            {{element.dayDashboardList[4].interventionCount}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </td>
                </ng-container>
                <ng-container matColumnDef="saturday">
                    <th mat-header-cell *matHeaderCellDef> {{'WEEK.DAY.SATURDAY' | translate}} {{dayOfWeek(5)}} </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="center">
                            <div class="custom-a" (click)="openDashboardDay(element,element.dayDashboardList[5])"
                                 *ngIf="element.dayDashboardList[5].availableDuration !== 0">
                                <div class="zone-progress-spinner">
                                    <mat-progress-spinner class="spinner mt-20"
                                                          [ngClass]="codeColor(element.dayDashboardList[5].availabilityPercent)"
                                                          [mode]="mode"
                                                          [value]="element.dayDashboardList[5].availabilityPercent"
                                                          aria-label="Rating">
                                    </mat-progress-spinner>
                                    <div class="availability-percent">
                                        {{element.dayDashboardList[5].availabilityPercent}}  %
                                    </div>
                                    <div class="zone-user_delivery_count">
                                        <mat-icon>person</mat-icon>
                                        <span class="delivery-count">
                                            {{element.dayDashboardList[5].interventionCount}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </td>
                </ng-container>

                <ng-container matColumnDef="sunday">
                    <th mat-header-cell *matHeaderCellDef> {{'WEEK.DAY.SUNDAY' | translate}} {{dayOfWeek(6)}} </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="center">
                            <div class="custom-a" (click)="openDashboardDay(element,element.dayDashboardList[6])"
                                 *ngIf="element.dayDashboardList[6].availableDuration !== 0">
                                <div class="zone-progress-spinner">
                                    <mat-progress-spinner class="spinner mt-20" cava
                                                          [ngClass]="codeColor(element.dayDashboardList[6].availabilityPercent)"
                                                          [mode]="mode"
                                                          [value]="element.dayDashboardList[6].availabilityPercent"
                                                          aria-label="Rating">
                                    </mat-progress-spinner>
                                    <div class="availability-percent">
                                        {{element.dayDashboardList[6].availabilityPercent}} %
                                    </div>
                                    <div class="zone-user_delivery_count">
                                        <mat-icon>person</mat-icon>
                                        <span class="delivery-count">
                                            {{element.dayDashboardList[6].interventionCount}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="dashboardDataSource.columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: dashboardDataSource.columnsToDisplay;"></tr>
            </table>
    </div>

